import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CustomRegisterNonCustomerComponent } from './custom-register-non-customer.component';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { CustomRegisterNonCustomerFormComponent } from './custom-register-non-customer-form/custom-register-non-customer-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '@spartacus/storefront';
import { CustomPinModule } from 'src/app/common/components/custom-pin-dialog/custom-pin-dialog.module';
import { CustomRegisterSharedModule } from '../custom-register-shared/custom-register-shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomRegisterNonCustomerDocsComponent } from './custom-register-non-customer-docs/custom-register-non-customer-docs.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import { CustomDocItemComponent } from './custom-register-non-customer-docs/custom-doc-item/custom-doc-item.component';
import { SecurityValidationModule } from './security-validation/security-validation.module';
import { CustomRegisterNonCustomerDocsReadonlyComponent } from './custom-register-non-customer-docs-readonly/custom-register-non-customer-docs-readonly.component';
import { RouterModule } from '@angular/router';




@NgModule({
  declarations: [
    CustomRegisterNonCustomerComponent,
    CustomRegisterNonCustomerFormComponent,
    CustomRegisterNonCustomerDocsComponent,
    CustomDocItemComponent,
    CustomRegisterNonCustomerDocsReadonlyComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    IconModule,
    UrlModule,
    CustomRegisterSharedModule,
    CustomPinModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    SecurityValidationModule,
    RouterModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromesaNonRegisterCustomerComponent: {
          component: CustomRegisterNonCustomerComponent,
        },
      },
    })
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'es-AR'},
    DatePipe,
  ]
})
export class CustomRegisterNonCustomerModule { }

