import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-review-progress',
  templateUrl: './custom-review-progress.component.html',
  styleUrls: ['./custom-review-progress.component.scss'],
})
export class CustomReviewProgressComponent {
  @Input() progress: number;
}
