import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Cart, RoutingService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { CustomLoadingSpinnerService } from 'src/app/cms-components/custom/services/custom-loading-spinner.service';
import { CustomQuoteModalService } from '../services/custom-quote-modal.service';
import { CustomQuoteOrchestratorService } from '../services/custom-quote-orchestrator.service';
import { CustomQuotesService } from '../services/custom-quotes.service';
import { QUOTE_STATE } from '../constansts/quotes-constansts';

@Component({
  selector: 'app-custom-quotes-summary-action',
  templateUrl: './custom-quotes-summary-action.component.html',
  styleUrls: ['./custom-quotes-summary-action.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomQuotesSummaryActionComponent {
  @Input() quote: Cart;
  blockButton: boolean = false;
  isActive$: Observable<boolean> =
    this.customQuoteOrchestrator.buttonIsActive();
  isInReview$: Observable<boolean> =
    this.customQuoteOrchestrator.getGoToSummary();
  isInDetail$: Observable<boolean> =
    this.customQuoteOrchestrator.getInQuoteDetail();
  isInOffer$: Observable<boolean> =
    this.customQuoteOrchestrator.getInQuoteOffer();
  activeCheckoutButton$: Observable<boolean> =
    this.customQuoteOrchestrator.getActiveCheckoutButton();
  subscription: Subscription = new Subscription();
  QUOTE_STATE = QUOTE_STATE;
  constructor(
    private customQuoteOrchestrator: CustomQuoteOrchestratorService,
    private customQuotesService: CustomQuotesService,
    private customQuoteModalService: CustomQuoteModalService,
    private routingService: RoutingService,
    private spinnerService: CustomLoadingSpinnerService
  ) {}

  goToSummary(isInReview: boolean) {
    if (!isInReview) {
      this.customQuoteOrchestrator.setGoToSummary(true);
      window.scrollTo(0, 0);
    } else {
      this.blockButton = true;
      this.spinnerService.showSpinner(this.quote.code || '');
      this.subscription.add(
        this.customQuotesService
          .sendQuoteProposal(this.quote)
          .subscribe((quote: Cart) => {
            if (quote.code) {
              this.customQuoteModalService.openInfoModal(quote.code);
            }
            this.blockButton = false;
            this.spinnerService.hideSpinner();
          })
      );
    }
  }

  goToQuotesList() {
    this.routingService.go({ cxRoute: 'quotes' });
  }

  goToCheckout() {
    this.customQuoteModalService.openCheckoutModal(this.quote.code!);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openRemoveConfirmationModal(code: string) {
    this.customQuoteModalService.openRemoveModal(code);
  }
}
