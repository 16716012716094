import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@spartacus/storefront';
import { CustomRegisterStepOrchestratorService } from '../../services/custom-register-step-orchestrator.service';

@Component({
  selector: 'app-custom-register-customer-error-info',
  templateUrl: './custom-register-customer-error-info.component.html',
  styleUrls: ['./custom-register-customer-error-info.component.scss'],
})
export class CustomRegisterCustomerErrorInfoComponent implements OnDestroy {
  closedModal: boolean = false;

  constructor(
    private modalService: ModalService,
    private router: Router,
    private customRegisterStepOrchestratorService: CustomRegisterStepOrchestratorService
  ) {}

  closeModal(reason: string): void {
    this.customRegisterStepOrchestratorService.setStepThreeStatus(false);
    this.customRegisterStepOrchestratorService.setStepTwoStatus(false);
    this.customRegisterStepOrchestratorService.setStepOneStatus(true);
    this.closedModal = true;
    if (reason == 'close') {
      this.router.navigateByUrl('/').then((navigate) => {
        if (navigate) {
          window.location.reload();
        }
      });
    }
    this.modalService.closeActiveModal(reason);
  }

  ngOnDestroy(): void {
    if (!this.closedModal) {
      this.customRegisterStepOrchestratorService.setStepThreeStatus(false);
      this.customRegisterStepOrchestratorService.setStepTwoStatus(false);
      this.customRegisterStepOrchestratorService.setStepOneStatus(true);
      this.router.navigateByUrl('/').then((navigate) => {
        if (navigate) {
          window.location.reload();
        }
      });
    }
  }
}
