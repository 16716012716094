import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomLoginFormComponentService } from 'src/app/cms-components/user/custom-login-form/services/custom-login-form-component.service';

@Injectable({
  providedIn: 'root',
})
export class CustomTwilioValidatorService {
  validationStatus$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
    private customLoginFormComponentService: CustomLoginFormComponentService
  ) {}

  sendCodeToPhone(cellphone: string) {
    const url = this.occEndpointService.buildUrl('sendCodeToCellphone', {
      queryParams: {
        phone: cellphone,
      },
    });
    return this.http.post(url, {});
  }

  sendCodeToPhoneUsingEmail(email: string) {
    const url = this.occEndpointService.buildUrl(
      'sendCodeToCellPhoneUsingEmail',
      {
        queryParams: {
          email,
        },
      }
    );
    return this.http.post(url, {}).pipe(
      catchError((error: any) => {
        this.customLoginFormComponentService.onLoginSuccess(false);
        return of('FALSE');
      })
    );
  }

  checkCode(cellphone: string, code: string): Observable<string> {
    const url = this.occEndpointService.buildUrl('checkCode', {
      queryParams: {
        code,
        to: cellphone,
      },
    });
    return this.http
      .post<string>(url, {})
      .pipe(catchError((error) => of('FALSE')));
  }
  checkCodeCustomer(email: string, code: string): Observable<string> {
    const url = this.occEndpointService.buildUrl('checkCodeCustomer', {
      queryParams: {
        code,
        email: email,
      },
    });
    return this.http
      .post<string>(url, {})
      .pipe(catchError((error) => of('FALSE')));
  }

  setValidationStatus(status: string) {
    this.validationStatus$.next(status);
  }

  resetValidationStatus() {
    this.validationStatus$.next('');
  }

  getValidationStatus() {
    return this.validationStatus$.asObservable();
  }
}
