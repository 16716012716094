import { Component, Input } from '@angular/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { CustomReturnOrderCancelDialogComponent } from '../custom-return-order-cancel-dialog/custom-return-order-cancel-dialog.component';

@Component({
  selector: 'app-custom-return-order-actions-cancel',
  templateUrl: './custom-return-order-actions-cancel.component.html',
  styleUrls: ['./custom-return-order-actions-cancel.component.scss'],
})
export class CustomReturnOrderActionsCancelComponent {
  modalRef: ModalRef;
  @Input() orderCode: string;

  constructor(
    protected orderDetailsService: OrderDetailsService,
    protected modalService: ModalService
  ) {}

  onCancelDevolution() {
    let modalInstance: any;
    this.modalRef = this.modalService.open(
      CustomReturnOrderCancelDialogComponent,
      {
        centered: true,
        size: 'lg',
      }
    );
    modalInstance = this.modalRef.componentInstance;
    modalInstance.orderCode = this.orderCode;
  }
}
