import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-component-title',
  templateUrl: './custom-component-title.component.html',
  styleUrls: ['./custom-component-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomComponentTitleComponent implements OnInit {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;

  @Input() title: string | null = null;
  @Input() hasFirsWordInBold: boolean = false;
  boldTitle: string;

  constructor(
    protected customBreakpointService: CustomBreakpointService
  ) { }

  ngOnInit(): void {
    (!!this.title && this.hasFirsWordInBold)
      ? this.boldTitle = this.convertFirstWordToBold(this.title)
      : null;
  }

  convertFirstWordToBold(title: string): string {
    let splittedTitle: string[] = title.split(' ');
    const boldWord: string = splittedTitle[0];
    if (splittedTitle.length > 1) {
      splittedTitle.shift();
      this.title = splittedTitle.join(' ');
      return boldWord;
    }
    this.title = null;
    return boldWord;
  }
}
