import { Component, ViewEncapsulation } from '@angular/core';
import { Order, PaginationModel, RoutingService } from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomBreakpointService } from '../../../../../services/custom-breakpoint.service';
import { CustomQuoteRejectDialogComponent } from '../custom-quotes-shared/custom-quote-reject-dialog/custom-quote-reject-dialog.component';
import { CustomQuotesService } from '../services/custom-quotes.service';

@Component({
  selector: 'app-custom-quotes-history',
  templateUrl: './custom-quotes-history.component.html',
  styleUrls: ['./custom-quotes-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomQuotesHistoryComponent {
  modalRef: ModalRef;
  quantities: any[] = [
    { id: 25, name: 25, disabled: true },
    { id: 50, name: 50, disabled: true },
    { id: 100, name: 100, disabled: true },
    { id: 200, name: 200, disabled: true },
    { id: 10000, name: 'Todos', disabled: true },
  ];
  pageSize: number = 10000;
  sortType: string;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  selectedPage: number = 1;
  quotes$: Observable<any> = this.customQuotesService
    .getList(this.pageSize)
    .pipe(
      tap((orders: any | undefined) => {
        if (orders?.pagination?.sort) {
          this.sortType = orders.pagination.sort;
        }
      })
    );
  constructor(
    private customBreakpointService: CustomBreakpointService,
    private customQuotesService: CustomQuotesService,
    private routing: RoutingService,
    private modalService: ModalService
  ) {}

  openModal() {
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomQuoteRejectDialogComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'reject-quote',
    });
  }

  customPageChangeSelected(page: number, pagination?: PaginationModel): void {
    this.selectedPage = page;
    this.pageChange(page);
    this.quotes$ = this.customQuotesService.getList(this.pageSize, page).pipe(
      tap((orders: any | undefined) => {
        if (orders?.pagination?.sort) {
          this.sortType = orders.pagination.sort;
        }
      })
    );
  }

  pageChange(page: number): void {
    const event: { sortCode: string; currentPage: number } = {
      sortCode: this.sortType,
      currentPage: page,
    };
    this.fetchOrders(event);
  }

  private fetchOrders(event: { sortCode: string; currentPage: number }): void {
    this.quotes$ = this.customQuotesService.getList(this.pageSize).pipe(
      tap((orders: any | undefined) => {
        if (orders?.pagination?.sort) {
          this.sortType = orders.pagination.sort;
        }
      })
    );
  }

  filterQuantitySelected(filter: any) {
    this.pageSize = filter;
    this.selectedPage = 0;
    this.quotes$ = this.customQuotesService.getList(this.pageSize).pipe(
      tap((orders: any | undefined) => {
        if (orders?.pagination?.sort) {
          this.sortType = orders.pagination.sort;
        }
      })
    );
  }

  goToQuoteDetail(order: Order): void {
    this.routing.go({
      cxRoute: 'quoteDetail',
      params: order,
    });
  }
}
