import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SmartEditLauncherService } from '@spartacus/smartedit/root';

@Injectable()
export class CustomSmartEditInterceptor implements HttpInterceptor {
    
    constructor(
      private service: SmartEditLauncherService
    ) { }

    intercept(req : HttpRequest<any>, next : HttpHandler) : Observable<HttpEvent<any>> {
      // Send cmsTicketId for categories endpoint to show Staged or Online images according to nabigation experience
      if (this.service.cmsTicketId && req.url.includes('/categories/')) {
        req = req.clone({
          setParams: {
            cmsTicketId: this.service.cmsTicketId,
          },
        });
      }

      return next.handle(req);
    }
}
