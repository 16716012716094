import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomModule } from './custom/custom.module';
import { CustomContentModule } from './content/custom-content.module';
import { UserModule } from './user/user.module';
import { NavigationModule } from './navigation/navigation.module';
import { CartModule } from './cart/cart.module';
import { ProductModule } from './product/product.module';
import { HeaderModule } from './header/header.module';
import { CustomSharedModule } from './shared/custom-shared.module';
import { CustomOrderConfirmationModule } from './order-confirmation/order-confirmation.module';
import { MyAccountModule } from './my-account/my-account.module';
import { CustomWishlistModule } from './custom-wishlist/custom-wishlist.module';
import { AsmModule } from './asm/asm.module';

// Module for new and custom "Cms" Components of Spartacus core components (Spartacus/projects/storefrontlib/cms-components)
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CustomModule,
    CartModule,
    ProductModule,
    CustomContentModule,
    UserModule,
    NavigationModule,
    HeaderModule,
    CustomSharedModule,
    CustomOrderConfirmationModule,
    MyAccountModule,
    CustomWishlistModule,
    AsmModule,
  ],
})
export class CmsComponentsModule {}
