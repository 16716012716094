import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { ActiveCartService, AuthService } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CustomQuoteUtilService } from '../../my-account/components/custom-quotes/services/custom-quote-util.service';
import { CustomCart } from '../model/custom-cart';

@Injectable({
  providedIn: 'root',
})
export class CustomCartFromQuoteGuard implements CanActivate {
  constructor(
    private activeCartService: ActiveCartService,
    private authService: AuthService,
    private customQuoteUtilService: CustomQuoteUtilService
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return combineLatest([
      this.activeCartService.getActive(),
      this.activeCartService.isStable(),
      this.authService.isUserLoggedIn(),
    ]).pipe(
      filter(
        ([_, loaded, isLoggedIn]) => (loaded && isLoggedIn) || !isLoggedIn
      ),
      map(([cart, loaded, isLoggedIn]) => {
        if (!isLoggedIn) {
          return true;
        }
        return this.customQuoteUtilService.handleCustomCartFromQuote(
          cart as CustomCart
        );
      })
    );
  }
}
