import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { CustomOrderOverviewModule } from 'src/app/cms-components/custom/components/custom-order-overview/custom-order-overview.module';
import { CustomTitleMyAccountModule } from 'src/app/cms-components/custom/components/custom-title-my-account/custom-title-my-account.module';
import { CustomOrderDetailActionsModule } from '../custom-order-detail-actions/custom-order-detail-actions.module';
import { CustomOrderDetailShippingComponent } from './custom-order-detail-shipping.component';



@NgModule({
  declarations: [
    CustomOrderDetailShippingComponent
  ],
  imports: [
    CommonModule,
    CustomOrderOverviewModule,
    CustomOrderDetailActionsModule,
    CustomTitleMyAccountModule,
    I18nModule,
    UrlModule,
    RouterModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        AccountOrderDetailsShippingComponent: {
          component: CustomOrderDetailShippingComponent,
        },
      },
    }),
  ],
  exports: [
    CustomOrderDetailShippingComponent
  ]
})
export class CustomOrderDetailShippingModule { }
