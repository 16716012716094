<ng-container *ngIf="{ isInPromeclub: isPromeclub$ | async } as data">
  <div class="pre-add">
    <div class="pre-add__header">
      <h4 class="pre-add__header-title">
        {{ "addToCart.addToCart" | cxTranslate }}
      </h4>
      <cx-icon type="CLOSE" (click)="closeModal()"></cx-icon>
    </div>
    <div class="pre-add__body">
      <app-custom-pre-add-to-cart-item
        [productCodeProps]="productCode"
        [quantitySelected]="quantitySelected"
      ></app-custom-pre-add-to-cart-item>
    </div>
    <div class="pre-add__footer">
      <button
        class="pre-add__footer-button"
        (click)="addToCartItem()"
        [disabled]="isOutOfStock"
      >
        {{ "addToCart.addToCart" | cxTranslate }}
      </button>
      <button class="pre-add__footer-link" (click)="closeModal()">
        {{
          (data.isInPromeclub
            ? "addToCart.continueBuyingPromeclub"
            : "addToCart.continueBuyingMobile"
          ) | cxTranslate
        }}
      </button>
    </div>
  </div>
</ng-container>
