import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalService } from '@spartacus/storefront';
import {
  ForgotPasswordComponent,
  ForgotPasswordComponentService,
} from '@spartacus/user/profile/components';
import { CustomForgotPasswordService } from './custom-forgot-password.service';
import { CustomBreakpointService } from '../../../services/custom-breakpoint.service';
import { Observable } from 'rxjs';
import { RoutingService } from '@spartacus/core';

@Component({
  selector: 'app-custom-forgot-password',
  templateUrl: './custom-forgot-password.component.html',
  styleUrls: ['./custom-forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CustomForgotPasswordComponent
  extends ForgotPasswordComponent
  implements OnDestroy
{
  constructor(
    protected service: ForgotPasswordComponentService,
    private customService: CustomForgotPasswordService,
    private modalService: ModalService,
    private customBreakpointService: CustomBreakpointService,
    private routingService: RoutingService,
  ) {
    super(service);
  }

  form: FormGroup = this.customService.form;
  isUpdating$ = this.customService.isUpdating$;
  showMessage$ = this.customService.showMessage$;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  showEmail: string;
  invalidForm: boolean = false;
  isRegisterUser: boolean = false;

  onSubmit(): void {
    if (this.form.invalid) {
      this.invalidForm = this.form.valid ? false : true;
    } else {
      this.showEmail = this.form.get('userEmail')?.value;
      this.customService.requestEmail();
    }
  }

  closePopup() {
    this.showMessage$.next(false);
    this.form.reset();
    this.modalService.closeActiveModal();
    if (this.isRegisterUser) {
      this.routingService.go({
        cxRoute: 'home',
      });
    }
  }

  ngOnDestroy(): void {
    this.showMessage$.next(false);
  }
}
