<div class="custom-reorder">
  <app-custom-modal-generic></app-custom-modal-generic>

  <div class="custom-reorder_body-footer">
    <div class="custom-reorder_body">
      <div
        class="custom-reorder_body-text"
        [innerHTML]="
          'orderDetails.reorder.textReorder' | cxTranslate | safeHtml
        "
      ></div>
    </div>
    <div class="custom-reorder__footer">
      <a
        [routerLink]="{ cxRoute: 'cart' } | cxUrl"
        type="button"
        class="btn btn-primary"
        cxModal="dismiss"
        cxModalReason="Cross click"
      >
        <span aria-hidden="true">
          {{ "miniCart.showCart" | cxTranslate }}
        </span>
      </a>
    </div>
  </div>
</div>
