<ng-container
  *ngIf="{
    isMobile: isMobile$ | async,
    showFloatingAddToCart: showFloatingAddToCart$ | async,
    hasStock: hasStock$ | async
  } as data"
>
  <ng-container *ngIf="data.isMobile">
    <div
      [floatingSelector]="data.showFloatingAddToCart"
      class="floating-add-to-cart"
      [ngClass]="{
        'floating-add-to-cart--active': data.showFloatingAddToCart,
        'out-of-stock': !data.hasStock
      }"
      cannonFixedInMobile
    >
      <custom-add-to-cart
        [floatingSelector]="true"
        [floatingSelectorLoginForm]="true"
      ></custom-add-to-cart>
    </div>
  </ng-container>
</ng-container>
