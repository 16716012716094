import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { CustomModalGenericModule } from 'src/app/cms-components/custom/components/custom-modal/custom-modal-generic.module';
import { CustomSafeHtmlModule } from 'src/app/pipes/custom-safe-html/custom-safe-html.module';
import { CustomStockInfoDialogComponent } from './custom-stock-info-dialog.component';
import { CustomStockInfoEntryComponent } from './custom-stock-info-entry/custom-stock-info-entry.component';

@NgModule({
  declarations: [CustomStockInfoDialogComponent, CustomStockInfoEntryComponent],
  imports: [
    CommonModule,
    IconModule,
    I18nModule,
    RouterModule,
    MediaModule,
    CustomSafeHtmlModule,
    CustomModalGenericModule
  ],
  exports: [CustomStockInfoDialogComponent, CustomStockInfoEntryComponent],
})
export class CustomStockInfoModule {}
