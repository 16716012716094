import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CurrencyService, SiteContextActions } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  LoadBaseSitesSuccessI,
  CustomBaseSite,
} from '../interface/load-base-sites-success.interface';
import { SetActiveBaseSiteI } from '../interface/set-active-base-site.interface';

@Injectable()
export class CustomBaseSiteEffects {
  baseSiteInfo: CustomBaseSite[];
  @Effect({ dispatch: false })
  customLoadBaseSite$: Observable<any> = this.actions$.pipe(
    ofType(SiteContextActions.LOAD_BASE_SITES_SUCCESS),
    map((action: LoadBaseSitesSuccessI) => {
      this.baseSiteInfo = [...action.payload];
    })
  );

  @Effect({ dispatch: false })
  customSetBaseSite$: Observable<any> = this.actions$.pipe(
    ofType(SiteContextActions.SET_ACTIVE_BASE_SITE),
    map((action: SetActiveBaseSiteI) => {
      let currentBaseSiteCurrency: string = '';
      this.baseSiteInfo.forEach((baseSite) => {
        if (baseSite.uid == action.payload) {
          currentBaseSiteCurrency = baseSite.baseStore.defaultCurrency.isocode;
        }
      });
      this.currencyService.setActive(currentBaseSiteCurrency);
    })
  );

  constructor(
    private actions$: Actions,
    private currencyService: CurrencyService
  ) {}
}
