import { Injectable } from '@angular/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { CustomQuoteCancelCheckoutDialogComponent } from '../custom-quotes-shared/custom-quote-cancel-checkout/custom-quote-cancel-checkout-dialog.component';
import { CustomQuoteCheckoutInfoDialogComponent } from '../custom-quotes-shared/custom-quote-checkout-info/custom-quote-checkout-info-dialog.component';
import { CustomQuoteCreateInfoComponent } from '../custom-quotes-shared/custom-quote-create-info-dialog/custom-quote-create-info.component';
import { CustomQuoteInCourseDialogComponent } from '../custom-quotes-shared/custom-quote-in-course-dialog/custom-quote-in-course-dialog.component';
import { CustomQuoteInfoDialogComponent } from '../custom-quotes-shared/custom-quote-info-dialog/custom-quote-info-dialog.component';
import { CustomQuoteRejectDialogComponent } from '../custom-quotes-shared/custom-quote-reject-dialog/custom-quote-reject-dialog.component';
import { CustomQuotesRemoveConfirmationDialogComponent } from '../custom-quotes-shared/custom-quotes-remove-confirmation-dialog/custom-quotes-remove-confirmation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class CustomQuoteModalService {
  modalRef: ModalRef;
  constructor(private modalService: ModalService) {}

  openInfoModal(code: string) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomQuoteInfoDialogComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'quote-info-modal',
    });

    modalInstance = this.modalRef.componentInstance;
    modalInstance.code = code;
  }

  openRemoveModal(code: string) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(
      CustomQuotesRemoveConfirmationDialogComponent,
      {
        centered: true,
        size: 'lg',
        windowClass: 'quote-remove-modal',
      }
    );

    modalInstance = this.modalRef.componentInstance;
    modalInstance.code = code;
  }

  openCheckoutModal(code: string) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(
      CustomQuoteCheckoutInfoDialogComponent,
      {
        centered: true,
        size: 'lg',
        windowClass: 'quote-checkout-modal',
      }
    );

    modalInstance = this.modalRef.componentInstance;
    modalInstance.code = code;
  }

  openCancelCheckoutModal(code: string) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(
      CustomQuoteCancelCheckoutDialogComponent,
      {
        centered: true,
        size: 'lg',
        windowClass: 'quote-cancel-checkout-modal',
      }
    );

    modalInstance = this.modalRef.componentInstance;
    modalInstance.code = code;
  }

  openQuoteInCourseModal() {
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomQuoteInCourseDialogComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'quote-in-course-modal',
    });

    modalInstance = this.modalRef.componentInstance;
  }

  openRejectModal(code: string) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomQuoteRejectDialogComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'quote-reject-modal',
    });

    modalInstance = this.modalRef.componentInstance;
    modalInstance.code = code;
  }

  openCreateQuoteModal(code: string) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomQuoteCreateInfoComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'add-to-cart quote-reject-modal',
    });

    modalInstance = this.modalRef.componentInstance;
    modalInstance.code = code;
  }
}
