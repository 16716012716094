import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSessionExpiredDialogComponent } from './custom-session-expired-dialog.component';
import { IconModule } from '@spartacus/storefront';
import { AuthHttpHeaderService, I18nModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { CustomAuthHttpHeaderService } from 'src/app/common/services/custom-auth-http-header.service';
import { CustomModalGenericModule } from '../../custom/components/custom-modal/custom-modal-generic.module';



@NgModule({
  declarations: [
    CustomSessionExpiredDialogComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    RouterModule,
    UrlModule,
    CustomModalGenericModule
  ],
  exports: [
    CustomSessionExpiredDialogComponent
  ],
  providers: [
    {
      provide: AuthHttpHeaderService,
      useClass: CustomAuthHttpHeaderService
    },
  ]
})
export class CustomSessionExpiredDialogModule { }
