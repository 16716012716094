import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomDocData, CustomDoc, DocRemoved } from '../models/custom-doc-data.interface';

@Injectable({
  providedIn: 'root'
})
export class CustomRegisterDocsService {

  docData$: BehaviorSubject<Array<CustomDoc> | null> = new BehaviorSubject<Array<CustomDoc> | null>(null);
  docsArray: CustomDoc[] = [];
  removedDoc$: BehaviorSubject<DocRemoved> = new BehaviorSubject<DocRemoved>({removed: false});
  validDocs$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  constructor() { }

  setDocData(doc: CustomDocData, docType: string){
    let docObject: CustomDoc;
    docObject= {[docType]: doc};
    this.docsArray.push(docObject)
    this.docData$.next(
      this.docsArray
    );
  }

  setDocDataArray(docs: Array<CustomDoc>){
    this.docsArray = docs;
    this.docData$.next(docs);
  }

  getDocsData(): Observable<CustomDoc[] | null>{
    return this.docData$.asObservable();
  }

  setRemovedDoc(value: DocRemoved){
    let docsData: CustomDoc[];
    docsData = this.docsArray.filter(doc => doc[value!.docType!]?.uuid !== value.uuid);
    this.setDocDataArray(docsData);
    this.removedDoc$.next(value);
  }

  getRemovedDoc(): Observable<DocRemoved>{
    return this.removedDoc$.asObservable();
  }

  setValidDocs(value: number){
    this.validDocs$.next(
      this.validDocs$.value + value
    );
  }

  resetValidDocs(){
    this.validDocs$.next(0);
  }

  getValidDocs(): Observable<number>{
    return this.validDocs$.asObservable();
  }
}
