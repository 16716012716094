<ng-container
  *ngIf="{
    savedCarts: savedCarts$ | async,
    isMobile: isMobile$ | async
  } as data"
>
  <ng-container *ngIf="!(isLoading$ | async); else loading">
    <div class="shopping-list-title">
      <app-custom-title-my-account
        [title]="'shoppingList.title' | cxTranslate"
        [lessMargin]="true"
      ></app-custom-title-my-account>
    </div>

    <ng-container *ngIf="data.savedCarts?.length > 0">
      <div class="shopping-list__filter">
        <app-custom-filter
          [label]="'orderHistory.filterResults' | cxTranslate"
          [classSelect]="'small-filter'"
          [entries]="quantities"
          [valueDefault]="pageSize"
          (filterSelected)="filterQuantitySelected($event)"
        ></app-custom-filter>
        <app-custom-pagination
          *ngIf="true"
          [pagination]="pagination"
          (viewPageEvent)="customPageChange($event)"
          [pageSelected]="selectedPage"
        >
        </app-custom-pagination>
      </div>
    </ng-container>

    <ng-container *ngIf="data.savedCarts?.length > 0; else noSavedCarts">
      <table class="shopping-list-table">
        <thead class="shopping-list-table__thead">
          <th class="shopping-list-table__thead__th">
            <span class="shopping-list-table__thead__th-dataSaved">{{
              "shoppingList.dateSaved" | cxTranslate
            }}</span>
          </th>
          <th class="shopping-list-table__thead__th">
            <span
              *ngIf="!data.isMobile"
              class="shopping-list-table__thead__th-cartName"
              >{{ "savedCartList.cartName" | cxTranslate }}</span
            >
            <span
              *ngIf="data.isMobile"
              class="shopping-list-table__thead__th-cartName"
            >
              {{ "savedCartList.cartName" | cxTranslate }}
            </span>
          </th>
          <th class="shopping-list-table__thead__th">
            <span class="shopping-list-table__thead__th-total">{{
              "savedCartList.total" | cxTranslate
            }}</span>
          </th>
        </thead>
        <tbody class="shopping-list-table__tbody">
          <tr
            class="shopping-list-table__tbody__tr"
            *ngFor="let savedCart of cartsSavedShow"
            (click)="goToSavedCartDetails(savedCart)"
          >
            <td class="shopping-list-table__tbody__tr__td-date">
              <span>{{ savedCart?.saveTime | date : "dd/MM/yyyy" }}</span>
            </td>
            <td class="shopping-list-table__tbody__tr__td-name">
              <a
                *ngIf="!data.isMobile"
                [routerLink]="
                  {
                    cxRoute: 'savedCartsDetails',
                    params: { savedCartId: savedCart?.code }
                  } | cxUrl
                "
                class="cx-saved-cart-list-value"
              >
                {{ savedCart?.name | slice : 0 : 34 }}
                {{ savedCart?.name.length > 33 ? "..." : "" }}
              </a>
              <a
                *ngIf="data.isMobile"
                [routerLink]="
                  {
                    cxRoute: 'savedCartsDetails',
                    params: { savedCartId: savedCart?.code }
                  } | cxUrl
                "
                class="cx-saved-cart-list-value"
              >
                {{ savedCart?.name | slice : 0 : 14 }}
                {{ savedCart?.name.length > 13 ? "..." : "" }}
              </a>
            </td>
            <td class="shopping-list-table__tbody__tr__td-total">
              <span>{{ savedCart?.totalPrice?.value | customCurrency }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>

    <ng-container *ngIf="data.savedCarts?.length > 0">
      <div class="shopping-list__pagination">
        <app-custom-pagination
          *ngIf="true"
          [pagination]="pagination"
          (viewPageEvent)="customPageChangeSelected($event)"
          [pageSelected]="selectedPage"
        >
        </app-custom-pagination>
      </div>
    </ng-container>

    <ng-template #noSavedCarts>
      <div class="not-found-container">
        <app-custom-empty-cart [fromMyAccount]="true"></app-custom-empty-cart>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #loading>
    <div class="cx-spinner">
      <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
    </div>
  </ng-template>
</ng-container>
