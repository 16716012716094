import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomInnerHeaderContactComponent } from './custom-inner-header-contact.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { GenericLinkModule, IconModule } from '@spartacus/storefront';

@NgModule({
  declarations: [CustomInnerHeaderContactComponent],
  imports: [
    CommonModule,
    GenericLinkModule,
    IconModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        InnerHeaderContactComponent: {
          component: CustomInnerHeaderContactComponent,
        },
      },
    }),
  ],
})
export class CustomInnerHeaderContactModule {}
