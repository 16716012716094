<ng-container *ngIf="!isPromeclub; else promeclubAccountStatus">
  <div class="container-order-history">
    <!-- HEADER -->
    <div class="cx-order-history-header">
      <app-custom-title-my-account
        [title]="'orderHistory.orderHistory' | cxTranslate"
        [lessMargin]="true"
      ></app-custom-title-my-account>
    </div>
    <ng-container
      *ngIf="{
        orderHistory: orders$ | async,
        isMobile: isMobile$ | async
      } as type"
    >
      <!-- BODY -->
      <ng-container *ngIf="type.orderHistory; else spinnerLoading">
        <div class="cx-order-history-body">
          <ng-container
            *ngIf="type.orderHistory.pagination?.totalResults > 0; else noOrder"
          >
            <!-- Select Form and Pagination Top -->
            <span class="cx-order-history-subtitle">
              {{ "orderHistory.filterBy" | cxTranslate }}
            </span>
            <div class="cx-order-history-filters top">
              <div class="cx-order-history-form-group">
                <app-custom-filter
                  [label]="'orderHistory.year' | cxTranslate"
                  [placeholder]="'orderHistory.placeholderYear' | cxTranslate"
                  [entries]="years"
                  [classSelect]="'big-filter'"
                  (filterSelected)="filterYearSelected($event)"
                  [valueDefault]="yearSelected ? yearSelected : null"
                  #customFilterComponent
                >
                </app-custom-filter>
                <div *ngIf="yearSelected" class="cx-order-history-show-results">
                  <span class="cx-order-history-results" *ngIf="!type.isMobile">
                    {{ "orderHistory.resultsFor" | cxTranslate }}
                  </span>
                  <div class="cx-order-filter-applicated">
                    <span *ngIf="!type.isMobile"
                      >{{ "orderHistory.year" | cxTranslate }} </span
                    >{{ yearSelected }}
                    <a (click)="clearFilter()">
                      <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
                    </a>
                  </div>
                  <a
                    class="cx-order-filter-link"
                    (click)="clearFilter()"
                    *ngIf="!type.isMobile"
                    >{{ "productList.clearFilters" | cxTranslate }}</a
                  >
                </div>
              </div>
              <div class="cx-order-history-pagination">
                <app-custom-filter
                  [label]="'orderHistory.filterResults' | cxTranslate"
                  [entries]="quantities"
                  [classSelect]="'small-filter'"
                  [valueDefault]="pageSize"
                  (filterSelected)="filterQuantitySelected($event)"
                >
                </app-custom-filter>
                <app-custom-pagination
                  *ngIf="type.orderHistory.pagination.totalPages >= 1"
                  [pagination]="type.orderHistory.pagination"
                  (viewPageEvent)="customPageChangeSelected($event)"
                  [pageSelected]="selectedPage"
                ></app-custom-pagination>
              </div>
            </div>
            <!-- TABLE -->
            <app-custom-account-table
              [orders]="type.orderHistory"
              (goToDetails)="goToOrderDetail($event)"
            ></app-custom-account-table>
            <!-- Select Form and Pagination Bottom -->
            <div
              class="cx-order-history-sort"
              *ngIf="type.orderHistory.pagination?.totalResults > 0"
            >
              <div
                *ngIf="type.orderHistory.pagination.totalPages >= 1"
                class="cx-order-history-pagination"
              >
                <app-custom-pagination
                  [pagination]="type.orderHistory.pagination"
                  (viewPageEvent)="customPageChangeSelected($event)"
                  [pageSelected]="selectedPage"
                ></app-custom-pagination>
              </div>
            </div>
          </ng-container>

          <!-- NO ORDER CONTAINER -->
          <ng-template #noOrder>
            <div
              class="cx-order-history-no-order"
              *ngIf="isLoaded$ | async; else spinnerLoading"
            >
              <app-custom-empty-cart
                [fromMyAccount]="true"
              ></app-custom-empty-cart>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
<ng-template #spinnerLoading>
  <div class="cx-order-history-body cx-order-history-body__loading">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
<ng-template #promeclubAccountStatus>
  <app-custom-account-status-promeclub></app-custom-account-status-promeclub>
</ng-template>
