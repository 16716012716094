<ng-container *ngIf="facetList$ | async as facetList">
  <div class="facets-active" [ngClass]="{ menu: fromMenu }">
    <div *ngFor="let facet of facetList?.activeFacets">
      <span
        *ngIf="
          facet.facetName != 'Puntos' && facet.facetCode != 'priceValue';
          else priceFacetValue
        "
      >
        <ng-container>
          {{ formatFacetName(facet.facetValueName) | titlecase }}
        </ng-container>
      </span>
      <ng-template #priceFacetValue>
        <ng-container *ngIf="activePriceFacet$ | async as price">
          <span *ngIf="price.length && !isInPromeclub">
            {{ price[0] | customCurrency }} - {{ price[1] | customCurrency }}
          </span>
          <span *ngIf="price.length && isInPromeclub">
            {{ price[0] | number : "1.0-0" : "es-AR" }} -
            {{ price[1] | number : "1.0-0" : "es-AR" }}
            {{ "cartItems.points" | cxTranslate }}
          </span>
        </ng-container>
      </ng-template>

      <a
        [cxFocus]="getFocusKey(facetList, facet)"
        routerLink="./"
        [queryParams]="getLinkParams(facet)"
      >
        <cx-icon aria-hidden="true" [type]="closeIcon"></cx-icon>
      </a>
    </div>
  </div>
  <button
    *ngIf="facetList?.activeFacets.length > 0 && !fromMenu"
    (click)="cancelFacets()"
    class="cx-action-link"
  >
    {{ "productList.clearFilters" | cxTranslate }}
  </button>
</ng-container>
