import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-gift-product',
  templateUrl: './custom-gift-product.component.html',
  styleUrls: ['./custom-gift-product.component.scss'],
})
export class CustomGiftProductComponent implements OnInit {
  @Input() wideLabel: boolean;
  @Input() label: string;
  @Input() template: string;
  constructor() {}

  ngOnInit(): void {}
}
