import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})

export class CustomNewsletterService {
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService
  ) {}

  sendEmailToNewsletter(email: string) {
    const body = {
      email: `"${email}"`
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = this.occEndpointService.buildUrl('registerNewsletter');
    return this.http.post(url, body.email, {headers})
  }
}
