import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActiveCartService, Cart } from '@spartacus/core';
import { Observable, Subject, Subscription, combineLatest, of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { CustomCreditInfo } from '../../../models/custom-credit-info.interface';
import { CustomCredit } from '../../../models/custom-credit.interface';
import { CustomUser } from '../../../models/custom-user';
import { CustomCheckoutPaymentStepService } from '../../../services/custom-checkout-payment-step.service';
import { CustomPaymentConditionsService } from '../../../services/custom-payment-conditions.service';

@Component({
  selector: 'app-custom-payment-credit',
  templateUrl: './custom-payment-credit.component.html',
  styleUrls: ['./custom-payment-credit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomPaymentCreditComponent implements OnInit, OnDestroy {
  @Input() user: CustomUser;
  @Input() customCheckoutPaymentStepService: CustomCheckoutPaymentStepService;
  @Input() cart: Cart | any;
  lineCreditSelector: FormControl = this.fb.control(null, [
    Validators.required,
  ]);
  customCredit: CustomCredit[];
  defaultCreditCondition: CustomCredit;
  creditInfo$: Observable<CustomCreditInfo>;
  subscription: Subscription = new Subscription();
  expiredCredit: boolean;
  isAvailableCredit: boolean;
  unsubscribe$: Subject<void> = new Subject();
  constructor(
    private fb: FormBuilder,
    private activeCartService: ActiveCartService,
    private customPaymentConditionsService: CustomPaymentConditionsService
  ) {}

  ngOnInit(): void {
    if (!this.user?.isEmployee)
      this.subscription.add(this.selectFirstPaymentConditionByDefault());
    this.subscription.add(
      this.customPaymentConditionsService
        .setCreditPaymentMode(this.user?.uid!)
        .subscribe()
    );
    this.creditInfo$ = this.customPaymentConditionsService
      .getCreditInfo(this.user?.uid!)
      .pipe(
        catchError((error) => of(error)),
        tap((credit) => {
          if (!this.user?.isEmployee) {
            this.expiredCredit = credit.bloqueo || credit.carteraVencida;
            if (this.expiredCredit) {
              this.customCheckoutPaymentStepService.setGoToNextStep(false);
              this.lineCreditSelector.disable();
            }

            if (!credit.availableAmount) {
              this.customCheckoutPaymentStepService.setGoToNextStep(false);
              return;
            }
            this.isAvailableCredit = Number(credit.newAmount) > 0;
            if (!this.isAvailableCredit) {
              this.customCheckoutPaymentStepService.setGoToNextStep(false);
              this.lineCreditSelector.disable();
            }
          } else {
            this.customCheckoutPaymentStepService.setGoToNextStep(true);
          }
        })
      );
  }

  selectFirstPaymentConditionByDefault() {
    return combineLatest([
      this.customPaymentConditionsService.getPaymentConditions(this.user?.uid!),
      this.customCheckoutPaymentStepService.getCreditConditionSelectedCode(),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([payments, selected]) => {
        this.customCredit = payments;
        this.setInitialSelectorData(payments, selected);
      });
  }

  onChange(e: any) {
    this.customCheckoutPaymentStepService.setCreditConditionSelectedCode(
      e?.code
    );

    this.customCheckoutPaymentStepService.setCreditConditionSelectedValue(
      e?.name
    );
    this.updatePaymentCondition(e?.code);
  }

  setInitialSelectorData(payments: CustomCredit[], selected: string) {
    if (this.expiredCredit) {
      this.lineCreditSelector.disable();
      return;
    }
    if (
      this.cart?.paymentCondition &&
      this.cart?.isFromQuote &&
      !this.user.isEmployee
    ) {
      this.defaultCreditCondition = payments?.filter(
        (type) => type.name === this.cart?.paymentCondition
      )?.[0];
      this.updatePaymentCondition(this.defaultCreditCondition.code);
      this.lineCreditSelector.patchValue(this.defaultCreditCondition?.name);
      this.lineCreditSelector.disable();
      return;
    }
    if (payments?.length && !selected) {
      this.defaultCreditCondition = payments?.[0];

      this.customCheckoutPaymentStepService.setCreditConditionSelectedCode(
        payments?.[0].code
      );
      this.lineCreditSelector.patchValue(this.defaultCreditCondition?.name);
      this.updatePaymentCondition(payments?.[0].code);
      return;
    } else if (selected) {
      this.defaultCreditCondition = payments?.filter(
        (type) => type.code === selected
      )?.[0];

      this.lineCreditSelector.patchValue(this.defaultCreditCondition?.name);
      this.updatePaymentCondition(selected);
      return;
    } else {
      this.lineCreditSelector.disable();
    }
  }

  updatePaymentCondition(paymentCode: string) {
    this.subscription.add(
      this.customPaymentConditionsService
        .putPaymentCondition(this.user?.uid!, paymentCode)
        .pipe(catchError((error) => of(error)))
        .subscribe((error) => {
          if (error) {
            this.customCheckoutPaymentStepService.setGoToNextStep(false);
            return;
          }

          this.activeCartService.reloadActiveCart();
          this.creditInfo$ = this.customPaymentConditionsService
            .getCreditInfo(this.user?.uid!)
            .pipe(
              catchError((error) => of(error)),
              tap((credit) => {
                this.expiredCredit = credit.bloqueo || credit.carteraVencida;
                if (this.cart?.paymentCondition || this.cart?.isFromQuote) {
                  this.customCheckoutPaymentStepService.setGoToNextStep(true);
                }
                if (this.expiredCredit) {
                  this.customCheckoutPaymentStepService.setGoToNextStep(false);
                  this.lineCreditSelector.disable();
                }

                if (!credit.availableAmount) {
                  this.customCheckoutPaymentStepService.setGoToNextStep(false);
                  return;
                }
                this.isAvailableCredit = Number(credit.newAmount) > 0;
                if (!this.isAvailableCredit) {
                  this.customCheckoutPaymentStepService.setGoToNextStep(false);
                }
              })
            );
        })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscription.unsubscribe();
  }
}
