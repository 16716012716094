import { NgModule } from '@angular/core';
import { CustomFirstLetterPipe } from './custom-first-letter.pipe';

@NgModule({
  declarations: [
      CustomFirstLetterPipe
   ],
   exports: [CustomFirstLetterPipe]
})
export class CustomFirstLetterModule { }
