import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, NotAuthGuard } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomLoadingSpinnerModule } from '../../custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomRegisterComponent } from './custom-register.component';
import { CustomRegisterValidationComponent } from './custom-register-validation/custom-register-validation.component';



@NgModule({
  declarations: [
    CustomRegisterComponent,
    CustomRegisterValidationComponent,
  ],
  imports: [
    CommonModule,
    IconModule,
    I18nModule,
    RouterModule,
    CustomLoadingSpinnerModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromesaRegisterClientComponent: {
          component: CustomRegisterComponent,
          guards: [NotAuthGuard],
        },
      },
    })
  ],
  exports: [
    CustomRegisterComponent,
  ],
})
export class CustomRegisterModule { }
