import { Injectable } from '@angular/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { CustomRemoveCartComponent } from '../custom-add-to-saved-cart/custom-remove-cart/custom-remove-cart.component';

@Injectable({
  providedIn: 'root',
})
export class CustomCartActionsModalService {
  modalRef: ModalRef;

  constructor(private modalService: ModalService) {}

  openRemoveCartDialog(
    cartId: string,
    userId: string,
    fromWishlist: boolean = false
  ): void {
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomRemoveCartComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'add-to-cart',
    });

    modalInstance = this.modalRef.componentInstance;
    modalInstance.userId = userId;
    modalInstance.cartId = cartId;
    modalInstance.fromWishlist = fromWishlist;
  }
}
