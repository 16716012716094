<ng-container *ngIf="{ balance: balance$ | async } as data">
  <ng-container *ngIf="data.balance != null; else spinnerLoading">
    <div class="payment-point">
      <div *ngIf="data.balance < 0" class="payment-point__error">
        <app-custom-error
          [notAvailableCredit]="true"
          [isPromeclub]="isPromeclub"
        >
        </app-custom-error>
      </div>
      <h3 class="payment-point__title" [ngClass]="{ error: false }">
        {{ "creditPayment.totalPoints" | cxTranslate }}
      </h3>
      <div class="payment-point__content">
        <div class="payment-point__content-value">
          <div class="payment-point__content-value-text">
            <p>{{ "creditPayment.available" | cxTranslate }}</p>
            <span *ngIf="available">
              {{ (available | number : "" : "es-AR") + " " }}
              {{ "creditPayment.points" | cxTranslate }}
            </span>
          </div>
        </div>
        <div class="payment-point__content-value">
          <div class="payment-point__content-value-text">
            <p>{{ "creditPayment.orderValue" | cxTranslate }}</p>
            <span *ngIf="orderTotal">
              {{ orderTotal | number : "" : "es-AR" }}
              {{ " " + ("creditPayment.points" | cxTranslate) }}
            </span>
          </div>
        </div>
        <div class="payment-point__content-value">
          <div class="payment-point__content-value-text">
            <p>{{ "creditPayment.newBalance" | cxTranslate }}</p>
            <span
              [ngClass]="{ negative: data.balance < 0 }"
              *ngIf="data.balance != null"
            >
              {{ (data.balance | number : "" : "es-AR") + " " }}
              {{ "creditPayment.points" | cxTranslate }}
            </span>
          </div>
        </div>
        <div class="spacing"></div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #spinnerLoading>
  <div class="payment-point">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
