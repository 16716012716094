import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomReviewProgressComponent } from './custom-review-progress.component';

@NgModule({
  declarations: [CustomReviewProgressComponent],
  imports: [CommonModule],
  exports: [CustomReviewProgressComponent],
})
export class CustomReviewProgressModule {}
