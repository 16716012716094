import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomFilterAutomotrizService {
  public appliedFilter$: Subject<any> = new Subject<any>();
  public changeSectionLocalStorage$: Subject<any> = new Subject<any>();

  setFilterLocalStorage() {
    this.appliedFilter$.next(true);
  }

  setChangeLocalStorage() {
    this.changeSectionLocalStorage$.next(true);
  }
}
