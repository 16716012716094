<form
  (ngSubmit)="submitForm()"
  class="cx-place-order-form form-check"
  [formGroup]="checkoutSubmitForm"
>
  <button
    type="submit"
    class="btn btn-primary btn-block"
    [disabled]="
      termsAndConditionInvalid ||
      (daysOfWeekNotChecked$ | async) ||
      (blockButton$ | async) ||
      !(isReviewSubmitActive$ | async)
    "
    #submitButton
  >
    {{ "checkoutReview.customPlaceOrder" | cxTranslate }}
    <cx-icon class="fas fa-chevron-right"></cx-icon>
  </button>

  <div class="form-group">
    <label>
      <div class="custom-place-order-input">
        <input
          formControlName="termsAndConditions"
          class="scaled-input form-check-input"
          type="checkbox"
          [disabled]="!(isReviewSubmitActive$ | async)"
        />
      </div>
      <span class="form-check-label">
        <ng-container *ngIf="isReviewSubmitActive$ | async; else termsDisabled">
          <a
            [ngClass]="{ active: !termsAndConditionInvalid }"
            [routerLink]="{ cxRoute: 'termsAndConditions' } | cxUrl"
            class="cx-tc-link"
            target="_blank"
          >
            {{ "checkoutReview.termsAndConditions" | cxTranslate }}
          </a>
        </ng-container>
        <ng-template #termsDisabled>
          <a class="cx-tc-link">
            {{ "checkoutReview.termsAndConditions" | cxTranslate }}
          </a>
        </ng-template>
      </span>
    </label>
  </div>
</form>
