<ng-container *ngIf="productBundle$ | async as productBundle">
  <ng-container *ngIf="productBundle.length > 0">
    <ng-container *ngIf="!(isMobile$ | async); else mobile">
      <div class="product-bundle-header">
        <div class="product-bundle-header__title">
          {{ "productDetails.title" | cxTranslate }}
        </div>
        <ng-container *ngIf="productBundle.length > 4">
          <div class="product-bundle-header__showAll">
            <div *ngIf="productBundle.length > maxComboItems" (click)="maxComboItems = productBundle.length">
              {{ "productDetails.seeAll" | cxTranslate }}
              <cx-icon class="fas fa-chevron-down"></cx-icon>
            </div>
            <div *ngIf="maxComboItems > 4" (click)="maxComboItems = 4">
              {{ "productDetails.seeLess" | cxTranslate }}
              <cx-icon class="fas fa-chevron-up"></cx-icon>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="product-bundle-container">
        <div class="product-bundle-container__cards" *ngFor="
                let bundle of productBundle | slice: 0:maxComboItems;
                let i = index
              ">
          <div class="product-bundle-container__cards__content">
            <cx-media [container]="bundle.target.images" class="product-bundle-container__cards__content__img"
              [routerLink]="bundle.target.url"></cx-media>
            <div class="product-bundle-container__cards__content__info">
              <span class="product-bundle-container__cards__content__info__name">
                {{ bundle.quantity }} x
                {{ bundle.target.name }}
              </span>
              <span *ngIf="
                        showComboItem === i
                          ? false
                          : true && !(isSmallDesktop$ | async)
                      " class="product-bundle-container__cards__content__info__summary">
                {{ bundle.target.summary | slice: 0:68 }}
                {{ bundle.target.summary.length >= 67 ? "..." : "" }}
              </span>
              <span *ngIf="
                        showComboItem === i
                          ? false
                          : true && (isSmallDesktop$ | async)
                      " class="product-bundle-container__cards__content__info__summary">
                {{ bundle.target.summary | slice: 0:31 }}
                {{ bundle.target.summary.length >= 30 ? "..." : "" }}
              </span>
              <span *ngIf="showComboItem === i ? true : false"
                class="product-bundle-container__cards__content__info__summary">
                {{ bundle.target.summary }}
              </span>
            </div>
          </div>
          <ng-container *ngIf="
                  (bundle.target.summary.length > 67 && !(isSmallDesktop$ | async)) ||
                  (bundle.target.summary.length > 30 && (isSmallDesktop$ | async))
                ">
            <div class="product-bundle-container__cards__showMore">
              <span *ngIf="showComboItem === i ? false : true" (click)="showMore(i)">
                {{ "productList.seeMore" | cxTranslate }}
              </span>
              <span *ngIf="showComboItem === i ? true : false" (click)="showLess()">{{ "productList.seeLess" |
                cxTranslate
                }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-template #mobile>
      <app-custom-product-attributes-accordion [titleAttribute]="'productDetails.title' | cxTranslate" attributes>

        <div class="product-bundle-container">
          <div class="product-bundle-container__cards" *ngFor="
            let bundle of productBundle;
            let i = index
          ">
            <div class="product-bundle-container__cards__content">
              <div class="product-bundle-container__cards__content__header"
                [ngClass]="{'without-description': !bundle?.target.summary}">
                <cx-media [container]="bundle.target.images" class="product-bundle-container__cards__content__img"
                  [routerLink]="bundle.target.url"></cx-media>
                <div class="product-bundle-container__cards__content__info">
                  <span class="product-bundle-container__cards__content__info__name">
                    {{ bundle.quantity }} x {{ bundle.target.name }}
                  </span>
                </div>
              </div>
              <div *ngIf="bundle?.target.summary">
                <div class="product-bundle-container__cards__showMore">
                  <span *ngIf="showComboItem === i ? false : true" (click)="showMore(i)">
                    {{ "productList.seeMore" | cxTranslate }}
                  </span>
                  <span *ngIf="showComboItem === i ? true : false" (click)="showLess()">{{ "productList.seeLess" |
                    cxTranslate
                    }}</span>
                </div>
              </div>
            </div>

            <div class="product-bundle-container__cards__content__description"
              *ngIf="(showComboItem === i ? true : false)">
              <span class="product-bundle-container__cards__content__description__title">{{
                "productList.descriptionMobile" |
                cxTranslate }}</span>
              <span class="product-bundle-container__cards__content__description__summary">
                {{ bundle.target.summary }}
              </span>
            </div>
          </div>
        </div>
      </app-custom-product-attributes-accordion>
    </ng-template>
  </ng-container>
</ng-container>
