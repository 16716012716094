<ng-container
  *ngIf="{
    product: product$ | async,
    isMobile: isMobile$ | async,
    page: template$ | async,
    isUserLoggedIn: isUserLoggedIn$ | async,
    userId: userId$ | async
  } as data"
>
  <div
    class="product-info"
    *ngIf="data?.product?.name?.toLowerCase() != ECOVALOR"
  >
    <!-- <app-custom-automotive-label></app-custom-automotive-label> -->
    <!-- *** CUCARDAS PDP *** -->
    <ng-container
      *ngIf="
        data.product?.stock?.stockLevelStatus === 'outOfStock' && !isInPromeclub
      "
    >
      <div class="product-info__badges">
        <app-custom-gift-product
          label="{{ 'addToCart.outOfStock' | cxTranslate }}"
          [template]="data.page?.template"
        >
        </app-custom-gift-product>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        data.product?.stock?.stockLevelStatus != 'outOfStock' &&
        data.product?.badge
      "
    >
      <div class="product-info__badges">
        <app-custom-gift-product
          [label]="data.product?.badge"
          [template]="data.page?.template"
        >
        </app-custom-gift-product>
      </div>
    </ng-container>
    <!-- *** CUCARDAS PDP *** -->

    <div class="product-info__brand" *ngIf="data?.product?.brand?.name">
      <h3 class="m-0">{{ data.product.brand.name }}</h3>
      <app-custom-wishlist
        [product]="data?.product"
        [userId]="data?.userId"
        [isUserLoggedIn]="data?.isUserLoggedIn"
        *ngIf="!data?.isMobile"
      ></app-custom-wishlist>
    </div>
    <div class="product-info__name" *ngIf="data?.product?.nameHtml">
      <h1 [innerHTML]="data.product.nameHtml | safeHtml" class="m-0"></h1>
    </div>
    <div class="product-info__code">
      <app-custom-product-reviews-overview
        *ngIf="data.isMobile && data?.product?.averageRating"
        [averageRating]="data?.product?.averageRating"
        [count]="data?.product?.numberOfReviews"
        [fromProductIntro]="true"
        [currentUrl]="currentUrl"
      ></app-custom-product-reviews-overview>
      <span *ngIf="data?.product?.averageRating" class="pipe"></span>
      <span *ngIf="!isInPromeclub">
        {{ "productList.sku" | cxTranslate }} {{ data?.product?.code }}</span
      >
      <span *ngIf="isInPromeclub"
        >{{ "productList.code" | cxTranslate }}
        {{ data?.product?.promotickWebCode }}</span
      >
      <span *ngIf="data?.product?.averageRating" class="pipe"></span>
    </div>
    <div class="product-info__description" *ngIf="!data?.isMobile">
      <h2
        *ngIf="data?.product?.description"
        [innerHTML]="data?.product?.description | safeHtml"
        class="summary m-0"
      ></h2>
    </div>
  </div>
  <div
    class="product-share"
    *ngIf="
      !data?.isMobile && !data?.product?.simpleVariantSelectors?.length > 0
    "
  >
    <app-custom-product-social-share
      [product]="data?.product"
      [userId]="data?.userId"
      *ngIf="data?.product?.name?.toLowerCase() != ECOVALOR"
    >
    </app-custom-product-social-share>
  </div>
  <yrs-custom-product-variants-container></yrs-custom-product-variants-container>
</ng-container>
