import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomComponentTitleComponent } from './custom-component-title.component';

@NgModule({
  declarations: [CustomComponentTitleComponent],
  imports: [CommonModule],
  exports: [CustomComponentTitleComponent],
})
export class CustomComponentTitleModule {}
