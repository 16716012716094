import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CmsParagraphComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-static-block',
  templateUrl: './custom-static-block.component.html',
  styleUrls: ['./custom-static-block.component.scss']
})
export class CustomStaticBlockComponent implements OnInit {
  isOpen: boolean;

  constructor(
    public component: CmsComponentData<CmsParagraphComponent>,
    protected router?: Router
  ) {
  }

  ngOnInit(): void {
    this.isOpen = false;
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

}
