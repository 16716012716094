<ng-container
  *ngIf="{
    isUserLoggedIn: isUserLoggedIn$ | async,
    isMobile: isMobile$ | async
  } as data"
>
  <div
    class="has-media-product"
    [ngClass]="{ logged: data.isUserLoggedIn }"
    *ngIf="!!product"
  >
    <cx-media
      *ngIf="!!product.images"
      [container]="product.images?.PRIMARY"
      format="thumbnail"
      role="presentation"
      class="img-product"
      [routerLink]="
        {
          cxRoute: 'product',
          params: product
        } | cxUrl
      "
      (click)="productClickEvent()"
    ></cx-media>
    <div class="product-info" [ngClass]="{ anonymous: !data.isUserLoggedIn }">
      <div
        class="product-info__category"
        *ngIf="!data.isMobile && product?.firstCategoryName"
      >
        {{ product?.firstCategoryName }}
      </div>
      <div class="product-info__sku" *ngIf="!isInPromeClub">
        {{ "searchBox.sku" | cxTranslate }}{{ product?.code }}
      </div>
      <div
        class="product-info__name"
        [innerHTML]="product.nameHtml | safeHtml"
        [routerLink]="
          {
            cxRoute: 'product',
            params: product
          } | cxUrl
        "
        (click)="productClickEvent()"
      ></div>
      <div class="product-info__price" *ngIf="data.isUserLoggedIn">
        <span
          class="product-info__price-previous"
          *ngIf="!!product?.previousPrice?.value"
          >{{ product?.previousPrice?.value | customCurrency }}
        </span>
        <span class="product-info__price-current" *ngIf="!isInPromeClub"
          >{{ product?.priceWithIVA?.value | customCurrency }}
          <span class="product-info__price-current-iva">
            {{ "productList.plusIva" | cxTranslate }}
          </span>
        </span>
        <span
          class="product-info__price-current promeclub"
          *ngIf="isInPromeClub"
          >{{ product?.price?.value | number : "1.0-0" : "es-AR" }}
          <span class="product-info__price-current-iva promeclub">
            {{ "productList.points" | cxTranslate }}
          </span>
        </span>
      </div>
    </div>
    <div class="product-action">
      <custom-add-to-cart
        *ngIf="data.isUserLoggedIn"
        [product]="product"
        [fromProductCard]="true"
        [clickedFrom]="clickedFrom"
      ></custom-add-to-cart>
      <button
        *ngIf="!data.isUserLoggedIn"
        [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
        class="custom-button primary logged-out"
        (click)="productClickEvent()"
      >
        <span class="button-text" *ngIf="data.isMobile">
          {{ "productList.seeMore" | cxTranslate }}
        </span>
        <cx-icon class="fas fa-chevron-right"></cx-icon>
      </button>
    </div>
  </div>
</ng-container>
