<ng-container
  *ngIf="(stepTwo$ | async) || (!isAddClaim && (order$ | async)) as orderClaim"
>
  <ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
    <div class="add-claim-container">
      <div class="d-flex align-items-center">
        <app-custom-title-my-account
        *ngIf="isAddClaim"
        [title]="'orderDetails.claim.detailTitleAdd' | cxTranslate"
        [isBackActive]="true"
        (onBackAction)="returnPage()"
        [lessMargin]="true"
      ></app-custom-title-my-account>
      <app-custom-title-my-account
        *ngIf="!isAddClaim"
        [title]="'orderDetails.claim.detailTitleReview' | cxTranslate : { orderCode: orderClaim?.id }"
        [lessMargin]="true"
      ></app-custom-title-my-account>
      </div>
      <div
        *ngIf="isAddClaim && !data.isMobile"
        class="add-claim-container__subtitle-text-container"
        [ngClass]="!isAddClaim && 'review-subtitle-container'"
      >
        <span>{{
          "orderDetails.claim.detailOrder"
            | cxTranslate
              : {
                  orderCode:
                    order?.code || splitStringCode(orderClaim?.associatedTo)
                }
        }}</span>
        <div class="add-claim-container__detail-text">
          {{ "orderDetails.claim.detailReviewDataText" | cxTranslate }}
        </div>
      </div>

      <hr
        *ngIf="!data.isMobile && isAddClaim"
        class="separation-order-overview"
      />

      <div
        *ngIf="!data.iMobile"
        class="detail-order-overview__text"
        [ngClass]="!isAddClaim && 'detail-order-overview__text-review'"
      >
        {{
          isAddClaim
            ? ("orderDetails.claim.detailOrderSubtitle" | cxTranslate)
            : ("orderDetails.claim.detailOrderMobile" | cxTranslate)
        }}
      </div>

      <app-custom-order-overview
        [isClaim]="true"
        [order]="order || orderClaim"
        class="detail-order-overview"
      >
        <button
          actions
          (click)="openModal(orderClaim)"
          *ngIf="!isAddClaim && !data.isMobile"
          class="button btn bt-secondary delete-button"
        >
          {{ "orderDetails.claim.delete" | cxTranslate }}
        </button>
      </app-custom-order-overview>

      <button
        (click)="openModal(orderClaim)"
        *ngIf="!isAddClaim && data.isMobile"
        class="button btn bt-secondary delete-button-mobile"
      >
        {{ "orderDetails.claim.delete" | cxTranslate }}
      </button>
      <ng-container
        *ngIf="isAddClaim && (formData$ | async) as form; else templateDetail"
      >
        <div
          class="add-claim-container__review-text-container add-claim-container__add-review-text-container"
        >
          <div>
            <h5>{{ "orderDetails.claim.label.motive" | cxTranslate }}</h5>
            <span>{{ form.subject }}</span>
          </div>
          <div>
            <h5>{{ "orderDetails.claim.label.comment" | cxTranslate }}</h5>
            <span>{{ form.message }}</span>
          </div>
        </div>
        <hr />
        <div class="add-claim-container__btns">
          <button (click)="returnPage()" class="button btn bt-secondary">
            {{ "orderDetails.cancellationAndReturn.btnReturn" | cxTranslate }}
          </button>

          <button
            (click)="submitForm(form)"
            class="button btn btn-primary generate-claim-button"
          >
            {{ "orderDetails.claim.title" | cxTranslate }}
          </button>
        </div></ng-container
      >
      <ng-template #templateDetail>
        <div class="add-claim-container__review-text-container">
          <div>
            <h5>{{ "orderDetails.claim.label.motive" | cxTranslate }}</h5>
            <span>{{ orderClaim.subject }}</span>
          </div>
          <div>
            <h5>{{ "orderDetails.claim.label.comment" | cxTranslate }}</h5>
            <ng-container
              *ngFor="let message of orderClaim.ticketEvents; index as i"
            >
              <span *ngIf="i == orderClaim.ticketEvents.length - 1">{{
                message.text
              }}</span>
            </ng-container>
          </div>
        </div>
        <hr [ngClass]="!data.isMobile && 'hr-desktop-detail'" />
        <div
          *ngIf="orderClaim.ticketEvents.length > 1"
          class="add-claim-container__response"
        >
          <ng-container
            *ngFor="
              let message of ticketsEvents(orderClaim.ticketEvents);
              index as i
            "
          >
            <div class="d-flex flex-column mb-4" *ngIf="i > 0">
              <h5 class="add-claim-container__response-title">
                {{
                  message.addedByAgent
                    ? ("orderDetails.claim.agentResponse" | cxTranslate)
                    : message.author
                }}
              </h5>
              <span class="add-claim-container__response-date">{{
                message.startDateTime | date : "dd/MM/yyyy HH:mm"
              }}</span>
              <span class="add-claim-container__response-text">{{
                message.text
              }}</span>
            </div>
          </ng-container>
          <ng-container
            *ngIf="{
              newTicket: obsNewTicket$ | async
            } as dataNewTicket"
          >
            <ng-container *ngIf="dataNewTicket.newTicket?.length > 0">
              <div
                class="d-flex flex-column mb-4"
                *ngFor="let ticket of dataNewTicket.newTicket"
              >
                <!-- no se permite acceder a un solo objeto dentro del array para sacar la info del autor -->
                <ng-container
                  *ngFor="let message of orderClaim.ticketEvents; index as i"
                >
                  <h5
                    *ngIf="i == orderClaim.ticketEvents.length - 1"
                    class="add-claim-container__response-title"
                  >
                    {{ message.author }}
                  </h5>
                </ng-container>

                <span class="add-claim-container__response-date">{{
                  dateNow() | date : "dd/MM/yyyy HH:mm"
                }}</span>
                <span>{{ ticket.message }} </span>
              </div>
            </ng-container>
          </ng-container>

          <form
            *ngIf="orderClaim.status.id !== 'CLOSED'"
            class="mt-4"
            [formGroup]="formNewMessage"
            (ngSubmit)="submitForm(orderClaim)"
          >
            <h5>{{ "orderDetails.claim.answer" | cxTranslate }}</h5>
            <textarea
              name="response"
              id="response"
              class="add-claim-container__textarea"
              maxlength="255"
              placeholder="{{
                'orderDetails.claim.claimTextarea' | cxTranslate
              }}"
              formControlName="textareaControl"
            ></textarea>
            <button
              type="submit"
              class="button btn btn-primary send-response-button"
              [disabled]="formNewMessage.invalid"
            >
              {{ "orderDetails.claim.sendAnswer" | cxTranslate }}
            </button>
          </form>
          <hr />
        </div>

        <div class="add-claim-container__review-buttons">
          <button (click)="returnPage()" class="button btn bt-secondary">
            {{ "orderDetails.claim.btnReturnDetail" | cxTranslate }}
          </button>
        </div>
      </ng-template>
    </div>
  </ng-container>
</ng-container>
