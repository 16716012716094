import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Images, OccConfig } from '@spartacus/core';
import {
  CmsComponentData,
  CurrentProductService,
  ProductImagesComponent,
} from '@spartacus/storefront';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { concatMap, map, pluck, take, tap } from 'rxjs/operators';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';
import { CustomGtmEcommerceDatalayerService } from 'src/app/common/services/custom-gtm-ecommerce-datalayer.service';
import { CustomGtmInteractionDatalayerService } from 'src/app/common/services/custom-gtm-interaction-datalayer.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import SwiperCore, {
  Navigation,
  Swiper,
  SwiperOptions,
  Thumbs,
  Virtual,
} from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { EngagementProducto } from '../../model/custom-gtmProduct.model';
import { VideoProduct } from '../../model/custom-product-video';
import { CustomProduct } from '../../model/custom-product.interface';
SwiperCore.use([Virtual, Navigation, Thumbs]);

@Component({
  selector: 'app-custom-product-images',
  templateUrl: './custom-product-images.component.html',
  styleUrls: ['./custom-product-images.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomProductImagesComponent
  extends ProductImagesComponent
  implements OnInit, OnDestroy
{
  isMobile$: Observable<boolean>;
  videosThumbs$: Observable<any>;
  videosand360Thumbs$: Observable<any>;
  videos$: Observable<VideoProduct[]>;
  image360$: Observable<any>;
  configMobile: SwiperOptions;
  swiperMobileItems: SwiperOptions;
  swiperMobileThumbs: Swiper;
  configDesktop: SwiperOptions;
  thumbsArray: any[];
  mainImageActive: number;
  videosArray: VideoProduct[] = [];
  baseSiteUrl: string | undefined;
  openVideo: boolean = false;
  openImage360: boolean = false;
  customProducts: CustomProduct;
  videoMain: VideoProduct | null;
  isInPromeclub: boolean = this.service.isInPromeClub();
  componentName: string;
  subscription: Subscription = new Subscription();
  is360: boolean = true;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @ViewChild('swiperMobile') swiperMobile?: SwiperComponent;
  @ViewChild('swiperMob') swiperMob?: SwiperComponent;

  constructor(
    currentProductService: CurrentProductService,
    private customBreakpointService: CustomBreakpointService,
    private customGtmEcommerceDatalayerService: CustomGtmEcommerceDatalayerService,
    private customGtmInteractionDatalayerService: CustomGtmInteractionDatalayerService,
    private dataComponent: CmsComponentData<any>,
    private occConfig: OccConfig,
    private service: CustomNavigationService
  ) {
    super(currentProductService);
  }

  ngOnInit(): void {
    this.mainImageActive = 0;
    this.baseSiteUrl = this.occConfig?.backend?.occ?.baseUrl;
    this.isMobile$ = this.customBreakpointService.isMobile$;
    this.configMobile = {
      pagination: {
        clickable: true,
      },
      watchOverflow: true,
      thumbs: { swiper: this.swiperMob?.swiperRef },
    };

    this.swiperMobileItems = {
      spaceBetween: 8,
      slidesPerView: 4,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      allowSlideNext: true,
      allowSlidePrev: true,
      allowTouchMove: true,
      observer: true,
      observeParents: true,
    };

    this.configDesktop = {
      direction: 'vertical',
      slidesPerView: 4,
      observer: true,
      observeParents: true,
      allowTouchMove: false,
      spaceBetween: 20,
    };

    this.videos$ = this.currentProductService
      .getProduct()
      .pipe(pluck('productVideos'));

    this.image360$ = this.currentProductService
      .getProduct()
      .pipe(pluck('webRotatingXml'));

    this.videos$
      .pipe(
        take(1),
        map((videos: any[]) => {
          const arrVideos = [...videos];
          return arrVideos.sort((a, b) =>
            a.order != undefined && b.order != undefined ? a.order - b.order : 1
          );
        }),
        tap((productVideos) => (this.videosArray = productVideos))
      )
      .subscribe();

    this.videosThumbs$ = this.videos$.pipe(
      map((videos: VideoProduct[]) => {
        return videos.map((video) => of(video));
      }),
      concatMap((videos: Observable<VideoProduct>[]) =>
        this.thumbs$.pipe(
          map((thumbs) => (thumbs.length > 0 ? thumbs : of([]))),
          take(1),

          tap(
            (thumbs: any) =>
              thumbs.length > 0 &&
              thumbs[0]
                .pipe(
                  take(1),
                  map((thumb: Images) =>
                    this.openImageAndIndex(thumb.container, 0)
                  )
                )
                .subscribe()
          ),
          map((thumbs) => thumbs.length > 0 && thumbs.concat(videos)),

          tap((thumbs) => (this.thumbsArray = thumbs)),
          tap(() => (this.videoMain = null))
        )
      )
    );

    this.dataComponent.data$.subscribe(
      (data) => (this.componentName = data.name)
    );
    this.subscription.add(
      this.product$.subscribe((product) => {
        this.customGtmEcommerceDatalayerService.detailEvent(
          product,
          undefined,
          this.componentName
        );
      })
    );

    this.videosand360Thumbs$ = this.videoAnd360();
  }

  videoAnd360(): Observable<any> {
    return combineLatest([this.videosThumbs$, this.image360$]).pipe(
      map(([video, image360]) => {
        if (image360) {
          const img = of(image360);
          return video.length > 0 && video.concat(img);
        } else {
          return video;
        }
      }),
      tap((thumbs) => (this.thumbsArray = thumbs))
    );
  }

  slideNext() {
    if (this.mainImageActive <= this.thumbsArray.length - 1) {
      this.mainImageActive = this.mainImageActive + 1;
      this.openNextImage(this.mainImageActive);
    }
    if (this.thumbsArray.length > 4 && this.mainImageActive > 3) {
      this.swiper?.swiperRef.slideNext();
    }
  }

  slidePrev() {
    if (this.mainImageActive > 0) {
      this.mainImageActive = this.mainImageActive - 1;
      this.openNextImage(this.mainImageActive);
    }
    if (this.thumbsArray.length > 4) {
      this.swiper?.swiperRef.slidePrev();
    }
  }

  openImageMain(item: any) {
    this.customGtmInteractionDatalayerService.productInteractionEvent(
      EngagementProducto.PRODUCT_PHOTO
    );
    this.openImage(item);
    this.openVideo = false;
    this.openImage360 = false;
  }

  open360() {
    this.openVideo = false;
    this.openImage360 = true;
    this.videoMain = null;
  }

  openImageAndIndex(item: any, index: number) {
    this.openImage360 = false;
    this.mainImageActive = index;
    this.openImageMain(item);
  }

  openNextImage(index: number) {
    this.thumbsArray[index]
      .pipe(
        take(1),
        tap((image: any) => {
          image['container']
            ? this.openImageMain(image?.container)
            : image?.name.includes('.xml')
            ? this.open360()
            : this.openVideoMain(image);
        })
      )
      .subscribe();
  }

  openVideoMain(item: VideoProduct | any, index?: number) {
    this.customGtmInteractionDatalayerService.productInteractionEvent(
      EngagementProducto.PRODUCT_PHOTO
    );
    index ? (this.mainImageActive = index) : null;
    this.openImage360 = false;
    this.openVideo = true;
    this.videoMain = item;
  }

  isVideoActive(product: any): Observable<boolean> {
    return this.mainImageActive == product ? of(true) : of(false);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
