import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import {
  AuthService,
  CmsNavigationComponent,
  CmsService,
  RoutingService,
} from '@spartacus/core';
import { CmsComponentData, NavigationNode } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';
import {
  CustomUser,
  POINTS,
  ROLE,
} from 'src/app/cms-components/user/models/custom-user.interface';
import { CustomBaseSiteCheckerService } from '../../../../services/custom-base-site-checker.service';
import { CustomModalService } from '../../../shared/components/custom-modal/services/custom-modal.service';
import { CustomPromeclubUserService } from '../../services/custom-promeclub-user.service';
import { CustomQuoteOrchestratorService } from '../custom-quotes/services/custom-quote-orchestrator.service';

@Component({
  selector: 'app-custom-my-account',
  templateUrl: './custom-my-account.component.html',
  styleUrls: ['./custom-my-account.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomMyAccountComponent implements OnInit, OnDestroy {
  static readonly ACCOUNT_SLIDE_OPEN_CLASS = 'account-slide-open';
  static readonly MIN_HEIGHT_MY_ACCOUNT_MENU = 'min-height-my-account-menu';

  @Input() fromHeader: boolean = false;
  @Input() instance: string;
  user: CustomUser = this.customPromeclubUserService.getUser();
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  currentAccountStatusDetailsUrl: boolean;
  isSlideOpen: boolean = false;
  isForceSlideOpen: boolean = false;
  ROLE = ROLE;
  POINTS = POINTS;
  node$: Observable<NavigationNode | undefined> =
    this.service._createNavigation(this.componentData.data$, this.user);
  checkIsInQuoteDetail$: Observable<boolean> =
    this.customQuoteOrchestratorService.checkIfIsInQuoteDetail();
  isSeller: boolean;
  constructor(
    private customModal: CustomModalService,
    private cmsService: CmsService,
    private authService: AuthService,
    private routingService: RoutingService,
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: CustomNavigationService,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    private customQuoteOrchestratorService: CustomQuoteOrchestratorService,
    protected renderer: Renderer2,
    private customPromeclubUserService: CustomPromeclubUserService
  ) {}

  ngOnInit(): void {
    if (this.fromHeader) {
      this.node$ = this.service._createNavigation(
        this.cmsService.getComponentData<CmsNavigationComponent>(
          this.isPromeclub
            ? 'PromeclubMyAccountComponent'
            : 'PromesaMyAccountComponent'
        ),
        this.user
      );
    } else {
      this.renderer.addClass(
        document.body,
        CustomMyAccountComponent.MIN_HEIGHT_MY_ACCOUNT_MENU
      );
    }
    this.currentAccountStatusDetailsUrl =
      window.location.href.includes('order/');
    this.isSeller = this.user?.roles?.includes(ROLE.SELLER) || false;
  }

  toggleForceOpenSlide(): void {
    this.isForceSlideOpen
      ? this.removeSlideOpenClass()
      : this.addSlideOpenClass();
    this.isForceSlideOpen = !this.isForceSlideOpen;
  }

  addSlideOpenClass(): void {
    this.renderer.addClass(
      document.body,
      CustomMyAccountComponent.ACCOUNT_SLIDE_OPEN_CLASS
    );
  }

  removeSlideOpenClass(): void {
    this.renderer.removeClass(
      document.body,
      CustomMyAccountComponent.ACCOUNT_SLIDE_OPEN_CLASS
    );
  }

  openSlide(): void {
    if (!this.isForceSlideOpen && !this.isSlideOpen) {
      this.isSlideOpen = true;
      this.addSlideOpenClass();
    }
  }

  closeSlide(): void {
    if (!this.isForceSlideOpen && this.isSlideOpen) {
      this.isSlideOpen = false;
      this.removeSlideOpenClass();
    }
  }

  logout(): void {
    this.authService.coreLogout().finally(() => {
      this.routingService.go({
        cxRoute: '/',
      });
    });
    this.hideModal();
  }

  hideModal(): void {
    if (!this.fromHeader) {
      return;
    }
    this.customModal.hide(this.instance);
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(
      document.body,
      CustomMyAccountComponent.MIN_HEIGHT_MY_ACCOUNT_MENU
    );
  }
}
