import { NgModule } from '@angular/core';
import { HttpErrorHandler } from '@spartacus/core';
import { CustomBadRequestHandler } from './custom-bad-request/custom-bad-request.handler';
import { CustomOrganizationConflictHandler } from './custom-conflict-handler/custom-conflict.handler';
import { CustomInternalServerErrorHandler } from './custom-indernal-server/custom-internal-server.handler';

@NgModule({
  providers: [
    {
      provide: HttpErrorHandler,
      useExisting: CustomBadRequestHandler,
      multi: true,
    },
    {
      provide: HttpErrorHandler,
      useExisting: CustomOrganizationConflictHandler,
      multi: true,
    },
    {
      provide: HttpErrorHandler,
      useExisting: CustomInternalServerErrorHandler,
      multi: true,
    },
  ],
})
export class HandlersModule { }
