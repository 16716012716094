import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FacetValue } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-searchbox-category-item',
  templateUrl: './custom-searchbox-category-item.component.html',
  styleUrls: ['./custom-searchbox-category-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomSearchboxCategoryItemComponent {
  @Input() category: FacetValue;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;

  constructor(private customBreakpointService: CustomBreakpointService) {}
}
