<div class="custom-talleres-finder">
  <div class="custom-talleres-finder__title">
    <p>{{ "talleresFinder.title" | cxTranslate }}</p>
  </div>
  <div class="custom-talleres-finder__body">
    <form
      class="custom-talleres-finder__body-form"
      [formGroup]="findTalleresForm"
    >
      <div class="custom-talleres-finder__body-form-icon">
        <cx-icon [type]="'SEARCH'"></cx-icon>
        <input
          formControlName="value"
          type="text"
          class="form-control"
          (input)="onChange(); open = true"
          placeholder="{{ 'talleresFinder.placeholder' | cxTranslate }}"
        />
        <button class="reset" *ngIf="open == true" (click)="close($event)">
          <cx-icon [type]="'CLOSE'"></cx-icon>
        </button>
      </div>

      <div *ngIf="open" class="custom-talleres-finder__body-form-results">
        <ul *ngIf="talleres$ | async as talleres">
          <li *ngFor="let taller of talleres" (click)="onSelectTaller(taller)">
            <span>{{ taller.name }}</span>
          </li>
        </ul>
      </div>

      <ng-container *ngIf="brands$ | async as brands">
        <div class="custom-talleres-finder__body-brands">
          <div *ngFor="let brand of brands" class="form-check">
            <input
              value="brand.code"
              class="form-check-input"
              type="checkbox"
              formControlName="brands"
              (change)="enableOption($event.target.checked, brand)"
            />
            <label class="form-check-label" for="flexCheckDefault">
              {{ brand.name | titlecase }}
            </label>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
</div>
