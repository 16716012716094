<ng-container
  *ngIf="{
    isUpdating: isUpdating$ | async,
    isMobile: isMobile$ | async,
    showMessage: showMessage$ | async
  } as data"
>
  <app-custom-loading-spinner-item
    class="overlay"
    *ngIf="data.isUpdating"
  ></app-custom-loading-spinner-item>
  <ng-container *ngIf="!data.showMessage; else message">
    <div class="forgot-password-container">
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="forgot-password-container__show-message__icon">
          <cx-icon class="fas fa-times" (click)="closePopup()"></cx-icon>
        </div>
        <label class="forgot-password-container__title">
          <span>{{
            "forgottenPassword.forgottenPasswordMessage" | cxTranslate
          }}</span>
          <input
            aria-required="true"
            type="email"
            class="form-control forgot-password-container__title__input"
            placeholder="{{
              'forgottenPassword.emailAddress.customPlaceHolder' | cxTranslate
            }}"
            formControlName="userEmail"
          />

          <div
            *ngIf="invalidForm"
            class="forgot-password-container__title__errors"
          >
            <p *ngIf="form.get('userEmail')?.errors?.required">
              {{ "customRegister.enterEmail" | cxTranslate }}
            </p>
            <p
              *ngIf="
                form.get('userEmail')?.errors?.cxInvalidEmail &&
                !form.get('userEmail')?.errors?.required
              "
            >
              {{ "customRegister.invalidUserEmail" | cxTranslate }}
            </p>
          </div>
        </label>

        <div class="forgot-password-container__btn">
          <button class="btn btn-primary" [disabled]="form.disabled">
            {{ "common.submit" | cxTranslate }}
            <ng-container *ngIf="!data.isMobile">
              <cx-icon class="fas fa-chevron-right"></cx-icon>
            </ng-container>
          </button>
        </div>
      </form>
    </div>
  </ng-container>
  <ng-template #message>
    <div class="show-message">
      <div class="show-message__icon">
        <cx-icon class="fas fa-times" (click)="closePopup()"></cx-icon>
      </div>
      <div class="show-message__text">
        <p
          [innerHTML]="
            'forgottenPassword.firstEmailMessage'
              | cxTranslate : { email: showEmail }
              | safeHtml
          "
        ></p>
        <p>
          {{ "forgottenPassword.secondEmailMessage" | cxTranslate }}
        </p>
      </div>
    </div>
  </ng-template>
</ng-container>
