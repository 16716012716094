import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CmsService, ReturnRequest } from '@spartacus/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CustomReturnRequestService } from '../../../services/custom-return-request.service';
import { CurrentPage } from '../model/custom-return-order';

@Component({
  selector: 'app-custom-return-order-detail',
  templateUrl: './custom-return-order-detail.component.html',
  styleUrls: ['./custom-return-order-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomReturnOrderDetailComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  currentPage: string = CurrentPage.REVISION_ORDER_RETURN;
  rma: string;
  constructor(
    protected orderDetailsService: OrderDetailsService,
    protected activatedroute: ActivatedRoute,
    protected returnRequestService: CustomReturnRequestService,
    protected cmsService: CmsService,
  ) {
  }
  returnRequest$: Observable<ReturnRequest> = this.returnRequestService.getOrderReturnDetail();

  ngOnInit(): void {
    this.returnRequestService.reloadOrderDevolution$.pipe(
      takeUntil(this.unsubscribe$),
      tap(() => this.cmsService.refreshPageById('ReturnDetailOrderPage')),
      tap(() => this.returnRequest$ = this.returnRequestService.getOrderReturnDetail())
    ).subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
