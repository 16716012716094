<div class="cx-dialog-header modal-header">
  <button type="button" class="close" attr.aria-label="{{ 'addToCart.closeModal' | cxTranslate }}"
    (click)="closeModal('close')">
    <span aria-hidden="true">
      <cx-icon [type]="'CLOSE'"></cx-icon>
    </span>
  </button>
</div>
<div class="already-exists" [ngClass]="{ ruc: isRucMessage, uid: isUidMessage, rucEmployee: isRucMessageEmployee }">
  <p class="already-exists__message" *ngIf="!isRucMessage && !isUidMessage && !isRucMessageEmployee; else rucMessage">
    {{ "customRegister.customerAlreadyExists" | cxTranslate }}
    <a class="already-exists__message-link" (click)="openModal('redirect')">
      {{ "customRegister.customerAlreadyExistsLink" | cxTranslate }}
    </a>
  </p>
  <ng-template #rucMessage>
    <ng-container *ngIf="isRucMessage || isRucMessageEmployee; else uidMessage">
      <ng-container *ngIf="isRucMessage; else isRucMessageEmployee">
        <p class="already-exists__message" [innerHTML]="
          'nonCustomerRegister.rucAlreadyExists' | cxTranslate | safeHtml
        "></p>
        <a class="already-exists__message-link" [routerLink]="{ cxRoute: 'register' } | cxUrl"
          (click)="closeModal('redirect')">
          {{ "nonCustomerRegister.registerLink" | cxTranslate }}
        </a>
      </ng-container>
      <ng-template #isRucMessageEmployee>
        <p class="already-exists__message" [innerHTML]="
          'nonCustomerRegister.rucAlreadyExistsEmployee' | cxTranslate | safeHtml
        "></p>
      </ng-template>
    </ng-container>
    <ng-template #uidMessage>
      <div class="already-exists__message-uid" *ngIf="isUidMessage" [innerHTML]="
          'nonCustomerRegister.uidAlreadyExists' | cxTranslate | safeHtml
        "></div>
    </ng-template>
  </ng-template>
</div>
