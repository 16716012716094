<div class="cx-dialog-header modal-header">
  <button
    type="button"
    class="close"
    attr.aria-label="{{ 'addToCart.closeModal' | cxTranslate }}"
    (click)="closeModal('close')"
  >
    <span aria-hidden="true">
      <cx-icon [type]="'CLOSE'"></cx-icon>
    </span>
  </button>
</div>
<div class="register-confirmation">
  <p class="register-confirmation__message">
    {{ "customRegister.customerError" | cxTranslate }}
  </p>
  <a class="register-confirmation__message-link" (click)="closeModal('close')">
    {{ "customRegister.goToHome" | cxTranslate }}
  </a>
</div>
