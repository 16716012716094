import { Component } from '@angular/core';
import { CustomMyCompanyService } from '../../services/custom-my-company.service';
import { CustomCompany } from '../models/custom-company.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-my-company',
  templateUrl: './custom-my-company.component.html',
  styleUrls: ['./custom-my-company.component.scss'],
})
export class CustomMyCompanyComponent {
  company$: Observable<CustomCompany> =
    this.customMyCompanyService.getCompanyData();
  constructor(private customMyCompanyService: CustomMyCompanyService) {}
}
