import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  CartNotEmptyGuard,
  CheckoutAuthGuard
} from '@spartacus/checkout/components';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { CartValidationGuard } from '@spartacus/storefront';
import { CustomCheckButtonModule } from 'src/app/cms-components/custom/components/custom-check-button/custom-check-button.module';
import { CustomCenterDistributionModule } from './custom-center-distribution/custom-center-distribution.module';
import { CustomCheckoutDeliveryMethodComponent } from './custom-checkout-delivery-method.component';
import { CustomDeliveryModeModule } from './custom-delivery-mode/custom-delivery-mode.module';
import { CustomShippingAddressModule } from './custom-shipping-address/custom-shipping-address.module';

import { CustomAddAuthorizedPersonModule } from './custom-add-authorized-person/custom-add-authorized-person.module';
import { CustomAuthorizedPersonModule } from './custom-authorized-person/custom-authorized-person.module';

import { RouterModule } from '@angular/router';
import { CustomCheckoutOrderSummaryModule } from 'src/app/cms-components/custom/components/custom-checkout-order-summary/custom-checkout-order-summary.module';
import { CustomPlaceOrderModule } from '../custom-place-order/custom-place-order.module';
import { CustomTitleStepMobileModule } from '../custom-title-step-mobile/custom-title-step-mobile.module';

@NgModule({
  declarations: [CustomCheckoutDeliveryMethodComponent],
  imports: [
    CommonModule,
    I18nModule,
    UrlModule,
    RouterModule,
    CustomCheckButtonModule,
    CustomDeliveryModeModule,
    CustomShippingAddressModule,
    CustomCenterDistributionModule,
    CustomAuthorizedPersonModule,
    CustomAddAuthorizedPersonModule,
    CustomTitleStepMobileModule,
    CustomPlaceOrderModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutDeliveryMethod: {
          component: CustomCheckoutDeliveryMethodComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, CartValidationGuard],
        },
      },
    }),
  ],
})
export class CustomCheckoutDeliveryMethodModule {}
