import { Component, Input } from '@angular/core';
import { PromesaRegisterClient } from '../../models/custom-cms-register.interface';

@Component({
  selector: 'app-custom-register-account-data',
  templateUrl: './custom-register-account-data.component.html',
  styleUrls: ['./custom-register-account-data.component.scss'],
})
export class CustomRegisterAccountDataComponent {
  @Input() clientInfo: PromesaRegisterClient;
}
