import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, pluck, tap } from 'rxjs/operators';
import { CustomWishList, CustomWishlistEntries, ItemUpdated } from '../models/custom-wishlist.interface';

@Injectable({
  providedIn: 'root'
})
export class CustomWishlistService {
  initialUpdatedState: ItemUpdated = {value: false, loading: false}
  updated$: BehaviorSubject<ItemUpdated> = new BehaviorSubject<ItemUpdated>(this.initialUpdatedState);

  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
  ) {}

  updateddResult(): Observable<ItemUpdated>{
    return this.updated$.asObservable();
  }
  resetRemoveState(){
    this.updated$.next(this.initialUpdatedState);
  }

  getWishlist(): Observable<CustomWishList[]>{
    this.updated$.next({value: false, loading: true});
    const url = this.occEndpointService.buildUrl('getWishList', {
      urlParams: {
        userId: 'current'
      }
    });

    return this.http.get<CustomWishlistEntries>(url).pipe(
      pluck('entries'),
      tap((list) => {
        this.updated$.next(this.initialUpdatedState);}
      ),
      catchError(error => {
        this.updated$.next(this.initialUpdatedState)
       return of([])
    })
    );
  }

  removeItem(productCode: string){
    const url = this.occEndpointService.buildUrl('deleteWishList', {
      urlParams: {
        userId: 'current'
      },
      queryParams: {
        productCode
      }
    });

    return this.http.put(url, {}).pipe(
      catchError(error => {
          this.updated$.next(this.initialUpdatedState)
         return EMPTY
      })
    );
  }

  clearWishlist(){
    this.updated$.next({value: false, loading: true});
    const url = this.occEndpointService.buildUrl('clearWishlist', {
      urlParams: {
        userId: 'current'
      },
    });

    return this.http.put(url, {}).pipe(
      tap(() => this.updated$.next({value: true, loading: false})),
      catchError(error => {
          this.updated$.next(this.initialUpdatedState)
         return EMPTY
      })
    );
  }


}
