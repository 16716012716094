<form class="quotes-form" [formGroup]="quotesForm">
  <div class="quotes-form__inputs">
    <label class="quotes-form__inputs-label proposal">
      <div class="quotes-form__inputs-label-title">
        <span class="label-content">
          {{ "accountQuotes.form.proposalReference" | cxTranslate }}
        </span>
        <cx-icon
          class="fas fa-info-circle"
          ngbTooltip="{{ 'accountQuotes.form.inputRequired' | cxTranslate }}"
          placement="top-right"
          tooltipClass="tooltip-color"
          [triggers]="isMobile ? 'click' : 'hover'"
        ></cx-icon>
      </div>
      <input
        aria-required="true"
        formControlName="reference"
        type="text"
        class="form-control"
        placeholder="{{
          'accountQuotes.form.proposalReferencePlaceholder' | cxTranslate
        }}"
      />
    </label>
    <div class="quotes-form__inputs-label payment-method">
      <div class="quotes-form__inputs-label-title payment-method">
        <span class="label-content">
          {{ "accountQuotes.form.paymentMethod" | cxTranslate }} </span
        ><cx-icon
          class="fas fa-info-circle"
          ngbTooltip="{{ 'accountQuotes.form.inputRequired' | cxTranslate }}"
          placement="top-right"
          tooltipClass="tooltip-color"
          [triggers]="isMobile ? 'click' : 'hover'"
        ></cx-icon>
      </div>
      <div class="quotes-form__radios">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            formControlName="credit"
            (change)="paymentTypeChange('credit')"
            [checked]="paymentTypeSelected === 'credit'"
          />
          <label class="form-check-label radio">
            {{ "accountQuotes.form.credit" | cxTranslate }}
          </label>
        </div>
        <div class="form-check last">
          <input
            class="form-check-input"
            type="radio"
            formControlName="counted"
            (change)="paymentTypeChange('counted')"
            [checked]="paymentTypeSelected === 'counted'"
          />
          <label class="form-check-label radio">
            {{ "accountQuotes.form.counted" | cxTranslate }}
          </label>
        </div>
      </div>
    </div>

    <div
      class="quotes-form__inputs-label selector selector-label"
      *ngIf="paymentTypeSelected === 'credit'"
    >
      <div class="quotes-form__inputs-label-title payment-method">
        <span class="label-content">
          {{ "accountQuotes.form.creditSelector" | cxTranslate }}
        </span>
        <cx-icon
          class="fas fa-info-circle"
          ngbTooltip="{{ 'accountQuotes.form.inputRequired' | cxTranslate }}"
          placement="top-right"
          tooltipClass="tooltip-color"
          [triggers]="isMobile ? 'click' : 'hover'"
        ></cx-icon>
      </div>
      <ng-select
        formControlName="creditCondition"
        [searchable]="false"
        [clearable]="false"
        placeholder="{{ 'customRegister.select' | cxTranslate }}"
        required
        dropdownPosition="bottom"
        [items]="creditPayments$ | async"
        bindLabel="name"
      >
      </ng-select>
    </div>

    <label
      class="quotes-form__inputs-label selector"
      *ngIf="paymentTypeSelected === 'counted'"
    >
      <div class="quotes-form__inputs-label-title payment-method">
        <span class="label-content">
          {{ "accountQuotes.form.countedSelector" | cxTranslate }}
        </span>
        <cx-icon
          class="fas fa-info-circle"
          ngbTooltip="{{ 'accountQuotes.form.inputRequired' | cxTranslate }}"
          placement="top-right"
          tooltipClass="tooltip-color"
          [triggers]="isMobile ? 'click' : 'hover'"
        ></cx-icon>
      </div>
      <ng-select
        formControlName="countedMethod"
        [searchable]="false"
        [clearable]="false"
        placeholder="{{ 'customRegister.select' | cxTranslate }}"
        required
        dropdownPosition="bottom"
        [items]="countedPayments$ | async"
        bindLabel="displayName"
      >
      </ng-select>
    </label>

    <label class="quotes-form__inputs-label text-area">
      <div class="quotes-form__inputs-label-title">
        <span class="label-content">
          {{ "accountQuotes.form.comments" | cxTranslate }}
        </span>
        <cx-icon
          class="fas fa-info-circle"
          ngbTooltip="{{ 'accountQuotes.form.inputRequired' | cxTranslate }}"
          placement="top-right"
          tooltipClass="tooltip-color"
          [triggers]="isMobile ? 'click' : 'hover'"
        ></cx-icon>
      </div>
      <textarea
        class="form-control"
        [autofocus]="false"
        formControlName="comment"
        rows="3"
        placeholder="{{
          'accountQuotes.form.commentsPlaceholder' | cxTranslate
        }}"
      ></textarea>
    </label>
  </div>
</form>
