export const customUserBloqued = {
  userBloqued: {
    userBloqued: {
      textBloqued: 'Su usuario está bloqueado, reestablezca su contraseña ',
      hereBloqued: 'aquí.',
    },
  },
  b2bUnit: {
    b2bUnit: {
      inactiveCode: 'El código de cliente no se encuentra activo.',
      contactSupport:
        'Comunícate con tu oficial de crédito para poder continuar.',
      goBack: 'Regresar',
      textMobile:
        'El código de cliente no se encuentra activo. Comunícate con tu oficial de crédito para poder continuar.',
    },
  },
};
