import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { CheckoutOrderSummaryComponent } from '@spartacus/checkout/components';
import { ActiveCartService, OrderEntry } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CustomGtmInteractionDatalayerService } from 'src/app/common/services/custom-gtm-interaction-datalayer.service';
import { CustomCheckoutCartService } from 'src/app/feature-libs/checkout/services/custom-checkout-cart.service';
import { CustomReviewSubmitService } from 'src/app/feature-libs/checkout/services/custom-review-submit.service';
import { CustomBaseSiteCheckerService } from 'src/app/services/custom-base-site-checker.service';
import { CustomLoadingSpinnerService } from '../../services/custom-loading-spinner.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-checkout-order-summary',
  templateUrl: './custom-checkout-order-summary.component.html',
  styleUrls: ['./custom-checkout-order-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CustomCheckoutOrderSummaryComponent
  extends CheckoutOrderSummaryComponent
  implements OnInit, OnDestroy {
  scrollActive: boolean = false;
  showColapse: boolean = false;
  entriesQuantity: number = 0;
  cantMaxItems: number;
  unsubscribe$: Subject<any> = new Subject();
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  quantity$: Observable<number>;

  isReviewSubmitActive$: Observable<boolean> =
    this.customReviewSubmitService.getIsReviewSubmitActive();
  entriesCart: OrderEntry[] = [];
  constructor(
    activeCartService: ActiveCartService,
    private customReviewSubmitService: CustomReviewSubmitService,
    private userAccount: UserAccountFacade,
    private customCheckoutCartService: CustomCheckoutCartService,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    private customGtmDatalayerService: CustomGtmInteractionDatalayerService,
    private router: Router,
    private spinnerService: CustomLoadingSpinnerService,
    private cd: ChangeDetectorRef,
    protected customBreakpointService: CustomBreakpointService,
  ) {
    super(activeCartService);
  }

  ngOnInit(): void {
    if (this.isPromeclub) {
      this.quantity$ = this.getEntriesCart().pipe(
        startWith({ deliveryItemsQuantity: 0 }),
        map((cart) => cart?.deliveryItemsQuantity || 0)
      );
    }
    if (!this.isPromeclub) {
      this.quantity$ = this.getEntriesCart().pipe(
        startWith({ deliveryItemsQuantity: 0 }),
        map((cart) => cart.deliveryItemsQuantity || 0)
      );
    }

    this.cart$

    combineLatest([
      this.getEntriesCart(),
      this.isMobile$
    ]).pipe(
      takeUntil(this.unsubscribe$),
      map(([entries, isMobile]) => {
        isMobile ? this.cantMaxItems = 3 : this.cantMaxItems = 4;
        this.entriesQuantity = 0;
        return entries;
      }),
      tap((cart) => (this.entriesQuantity = cart?.entries?.length)),
      map((cart) => cart?.entries?.slice(0, this.cantMaxItems)),
      tap((data) => {
        this.entriesCart = data;
        this.cd.detectChanges();
        if (data?.length == 0) {
          this.router.navigateByUrl('/').then((navigated) => {
            if (navigated) {
              this.activeCartService.reloadActiveCart();
            }
          });
        }
      })
    )
      .subscribe({
        next: () => this.spinnerService.hideSpinner(),
        error: () => this.spinnerService.hideSpinner(),
        complete: () => this.spinnerService.hideSpinner(),
      });
  }

  getEntriesCart(): Observable<any> {
    return combineLatest([
      this.userAccount.get(),
      this.activeCartService.getActiveCartId(),
      this.activeCartService.getEntries(),
    ]).pipe(
      switchMap(([user, cart, entries]) => {
        return this.customCheckoutCartService.getCartUpdated(user?.uid!, cart);
      })
    );
  }

  onShowMore(event: any) {
    this.scrollActive = event;
  }

  showCartEvent() {
    this.customGtmDatalayerService.editShowOrderEvent(true);
  }

  toggleShowCollapse(event: any): void {
    this.showColapse = !this.showColapse;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
