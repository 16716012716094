import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  AppRoutingModule,
  ImageLoadingStrategy,
  MediaConfig,
} from '@spartacus/storefront';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { CmsComponentsModule } from './cms-components/cms-components.module';
import { CustomCommonModule } from './common/custom-common.module';
import { ConfigModule } from './config/config.module';
import { CustomSharedModule } from './custom-shared/custom-shared.module';
import { FeatureLibsModule } from './feature-libs/feature-libs.module';
import { SpartacusModule } from './spartacus/spartacus.module';
import { OutletsModule } from './outlets/outlets.module';
import { CustomSectionInterceptor } from './services/custom-section.interceptor';
import { CustomSmartEditInterceptor } from './services/custom-smartedit.interceptor';
import { MetaResolversModule } from './meta-resolvers/meta-resolvers.module';
import {
  AuthRedirectService,
  CustomerCouponAdapter,
  PRODUCT_SEARCH_PAGE_NORMALIZER,
  provideConfig,
  SiteAdapter,
  AuthHttpHeaderService,
  AuthStorageService,
  AuthService,
} from '@spartacus/core';
import { CustomOccProductSearchPageNormalizer } from './common/adapters/product/converters/custom-occ-product-search-page-normalizer';
import { CustomOccModule } from './custom-occ/custom-occ.module';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { CustomAuthRedirectService } from './services/custom-auth-redirect.service';
import { CustomOccSiteAdapterService } from './services/custom-occ-site-adapter.service';
import { SchemaInjectorModule } from './common/components/schema-injector/schema-injector.module';
import { CustomOccCustomerCouponAdapterService } from './services/custom-occ-customer-coupon-adapter.service';

import {
  AsmAuthHttpHeaderService,
  AsmAuthStorageService,
  AsmAuthService,
} from '@spartacus/asm/root';
import { customOnNavigateConfig } from './config/custom-routing/custom-on-navigating.config';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    AppRoutingModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    SpartacusModule,
    //NgRx Dev-tools
    ...(environment.production ? [] : [StoreDevtoolsModule.instrument()]),

    //Custom Modules
    CmsComponentsModule,
    CustomCommonModule,
    ConfigModule,
    CustomSharedModule,
    FeatureLibsModule,
    BrowserTransferStateModule,
    OutletsModule,
    MetaResolversModule,
    CustomOccModule,
    NoopAnimationsModule,
    SchemaInjectorModule,
  ],
  providers: [
    provideConfig(customOnNavigateConfig),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomSectionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomSmartEditInterceptor,
      multi: true,
    },
    {
      provide: PRODUCT_SEARCH_PAGE_NORMALIZER,
      useClass: CustomOccProductSearchPageNormalizer,
      multi: true,
    },
    {
      provide: AuthRedirectService,
      useExisting: CustomAuthRedirectService,
    },
    {
      provide: SiteAdapter,
      useClass: CustomOccSiteAdapterService,
    },
    {
      provide: CustomerCouponAdapter,
      useClass: CustomOccCustomerCouponAdapterService,
    },
    provideConfig({
      imageLoadingStrategy: ImageLoadingStrategy.LAZY,
    } as MediaConfig),
    {
      provide: AuthHttpHeaderService,
      useExisting: AsmAuthHttpHeaderService,
    },
    {
      provide: AuthStorageService,
      useExisting: AsmAuthStorageService,
    },
    {
      provide: AuthService,
      useExisting: AsmAuthService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
