<div class="product" *ngIf="product$ | async as product; else loading">
  <div class="product__image">
    <cx-media
      [container]="product?.images?.PRIMARY"
      format="product"
      [alt]="product?.name"
    ></cx-media>
  </div>
  <div class="product__info">
    <app-custom-gift-product
      *ngIf="product?.badge && isLoggedIn"
      [label]="product?.badge"
    ></app-custom-gift-product>
    <p class="product__info-sku" [ngClass]="{ badge: product.badge }">
      {{ "productList.sku" | cxTranslate }}
      {{ product?.code }}
    </p>
    <h3 class="product__info-name">
      {{ product?.name }}
    </h3>
    <p
      class="product__info-summary"
      [innerHTML]="product?.description | safeHtml"
    ></p>
    <a
      [routerLink]="product?.url"
      class="product__info-details"
      *ngIf="isLoggedIn"
      (click)="productClickEvent(product)"
    >
      {{ "productList.seeDetails" | cxTranslate }}
    </a>
  </div>
  <div class="product__action" [ngClass]="{ 'no-logged': !isLoggedIn }">
    <custom-add-to-cart
      [product]="product"
      [fromProductVariantPlpDesktop]="true"
      *ngIf="isLoggedIn"
    ></custom-add-to-cart>
    <button
      *ngIf="!isLoggedIn"
      [routerLink]="product?.url"
      (click)="productClickEvent(product)"
      class="custom-button primary"
    >
      <span class="button-text">
        {{ "productList.seeMore" | cxTranslate }}
      </span>
      <cx-icon class="fas fa-chevron-right"></cx-icon>
    </button>
  </div>
</div>
<ng-template #loading>
  <div class="custom-loading-product">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
