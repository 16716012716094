<ng-container *ngIf="order$ | async as order">
  <div class="justify-content-end">
    <div class="cx-summary">
      <app-custom-order-summary
        [customCart]="order"
        [section]="'order'"
        [showCoupon]="order.appliedVouchers?.length > 0 ? true : false"
      ></app-custom-order-summary>
    </div>
  </div>
</ng-container>
