import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductListComponent } from './custom-product-list.component';
import { RouterModule } from '@angular/router';
import {
  UrlModule,
  I18nModule,
  FeaturesConfigModule,
  CmsConfig,
  provideDefaultConfig,
} from '@spartacus/core';
import {
  MediaModule,
  AddToCartModule,
  ItemCounterModule,
  ListNavigationModule,
  StarRatingModule,
  IconModule,
  OutletModule,
  ViewConfig,
} from '@spartacus/storefront';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CustomProductCardModule } from '../../../custom/components/custom-product-card/custom-product-card.module';
import { CustomProductFacetNavigationModule } from './custom-product-facet-navigation/custom-product-facet-navigation.module';
import { CustomViewSelectorsModule } from 'src/app/cms-components/custom/components/custom-view-selectors/custom-view-selectors.module';
import { CustomProductScrollComponent } from './container/custom-product-scroll/custom-product-scroll.component';
import { CustomProductVariantListModule } from '../custom-product-variant-list/custom-product-variant-list.module';
import { CustomLoadingSpinnerModule } from 'src/app/cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';

@NgModule({
  declarations: [CustomProductListComponent, CustomProductScrollComponent],
  imports: [
    CommonModule,
    RouterModule,
    MediaModule,
    AddToCartModule,
    ItemCounterModule,
    ListNavigationModule,
    UrlModule,
    I18nModule,
    StarRatingModule,
    IconModule,
    InfiniteScrollModule,
    FeaturesConfigModule,
    OutletModule,
    CustomProductCardModule,
    CustomProductFacetNavigationModule,
    CustomViewSelectorsModule,
    CustomProductVariantListModule,
    CustomLoadingSpinnerModule,
  ],
  providers: [
    provideDefaultConfig(<ViewConfig>{
      view: {
        defaultPageSize: 24,
        infiniteScroll: {
          active: false,
          productLimit: 0,
          showMoreButton: false,
        },
      },
    }),
    provideDefaultConfig(<CmsConfig>{
      pagination: {
        addFirst: true,
        addLast: true,
        addDots: true,
        addNext: true,
        addStart: false,
        rangeCount: 3,
        addEnd: false,
        addPrevious: true,
      },
      cmsComponents: {
        CMSProductListComponent: {
          component: CustomProductListComponent,
        },
        ProductGridComponent: {
          component: CustomProductListComponent,
        },
        SearchResultsListComponent: {
          component: CustomProductListComponent,
        },
      },
    }),
  ],
  exports: [CustomProductListComponent, CustomProductScrollComponent],
})
export class CustomProductListModule {}
