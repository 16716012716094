<ng-template cxOutletRef="MiniCart" cxOutletPos="before">
  <ng-container *ngIf="{
      isMobile: isMobile$ | async,
      isCheckout: isCheckout$ | async
    } as data"
  >
  <div class="secure-checkout" *ngIf="data.isCheckout">
    <ng-container *ngIf="data.isMobile; else headerDesktop">
      <cx-icon type="LOCK"></cx-icon>
      <p class="secure-checkout__text">{{
        ("miniCart.checkout" | cxTranslate)
        }}
      </p>
    </ng-container>
    <ng-template #headerDesktop>
      <p class="secure-checkout__text">{{
        ("miniCart.secureCheckout" | cxTranslate)
        }}
      </p>
      <cx-icon type="LOCK"></cx-icon>
    </ng-template>
  </div>
  </ng-container>

</ng-template>
