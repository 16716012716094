<ng-container *ngIf="{
    isInPromeclub: isInPromeclub
  } as data">
  <div class="category-item" *ngIf="!!category">
    <cx-generic-link (click)="navigate(category?.url)">
      <div class="category-item__img">
        <img [src]="getImageUrl(category?.image?.url)" [alt]="category?.name" class="category-item__img-detail"
          [ngClass]="{
            'category-item__img-detail-promeclub': data.isInPromeclub
          }" loading="lazy" />
      </div>
      <div class="category-item__name">
        <h3 class="category-item__name-title" *ngIf="isCategory$ | async as isCategory; else notCategory">
          {{ category?.name | slice: 0:50 | titlecase }}
          {{ category?.name?.length >= 50 ? "..." : "" }}
        </h3>
        <ng-template #notCategory>
          <h2 class="category-item__name-title">
            {{ category?.name | slice: 0:50 | titlecase }}
            {{ category?.name?.length >= 50 ? "..." : "" }}
          </h2>
        </ng-template>
      </div>
    </cx-generic-link>
  </div>
</ng-container>
