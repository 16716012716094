import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule
} from '@spartacus/core';
import { IconModule, ListNavigationModule } from '@spartacus/storefront';
import { CustomAccountTableModule } from 'src/app/cms-components/custom/components/custom-account-table/custom-account-table.module';
import { CustomEmptyCartModule } from 'src/app/cms-components/custom/components/custom-empty-cart/custom-empty-cart.module';
import { CustomFilterModule } from 'src/app/cms-components/custom/components/custom-filter/custom-filter.module';
import { CustomLoadingSpinnerModule } from 'src/app/cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomPaginationModule } from 'src/app/cms-components/custom/components/custom-pagination/custom-pagination.module';
import { CustomTitleMyAccountModule } from 'src/app/cms-components/custom/components/custom-title-my-account/custom-title-my-account.module';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomOrderCancelHistoryComponent } from './custom-order-cancel-history.component';

@NgModule({
  declarations: [CustomOrderCancelHistoryComponent],
  imports: [
    CommonModule,
    CustomTitleMyAccountModule,
    I18nModule,
    NgSelectModule,
    UrlModule,
    IconModule,
    ListNavigationModule,
    CustomPaginationModule,
    CustomFilterModule,
    CustomCurrencyModule,
    CustomLoadingSpinnerModule,
    CustomAccountTableModule,
    CustomEmptyCartModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CancelOrderComponent: {
          component: CustomOrderCancelHistoryComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ],
  exports: [CustomOrderCancelHistoryComponent],
})
export class CustomOrderCancelHistoryModule {}
