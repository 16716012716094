<ng-template cxOutletRef="cx-header">
  <header
    #header
    cxSkipLink="cx-header"
    [cxFocus]="{ disableMouseFocus: true }"
    [class.is-expanded]="isExpanded$ | async"
    (keydown.escape)="collapseMenu()"
    (click)="collapseMenuIfClickOutside($event)"
  >
    <cx-page-slot position="TopHeaderSlot"></cx-page-slot>
    <cx-page-layout section="header"></cx-page-layout>
    <cx-page-layout section="navigation"></cx-page-layout>
  </header>
  <cx-page-slot position="BottomHeaderSlot"></cx-page-slot>
  <!-- se deja comentado el global message por no tener necesidad de uso por ahora -->
  <!-- <cx-global-message
    aria-atomic="true"
    aria-live="assertive"
  ></cx-global-message> -->
  <app-custom-loading-spinner></app-custom-loading-spinner>
</ng-template>
