<ng-container
  *ngIf="{
    isMobile: isMobile$ | async
  } as data"
>
  <div class="terms-modal">
    <div class="terms-modal__close">
      <cx-icon type="CLOSE" (click)="closeModal()"></cx-icon>
    </div>
    <img
      *ngIf="data.isMobile; else desktopImage"
      [src]="MOBILE_IMAGE"
      alt="terms-and-conditions"
      class="terms-modal__img"
    />
    <ng-template #desktopImage>
      <img
        [src]="DESKTOP_IMAGE"
        alt="terms-and-conditions"
        class="terms-modal__img"
      />
    </ng-template>
    <form class="terms-modal__form" [formGroup]="form" (ngSubmit)="submit()">
      <div class="terms-modal__form-group">
        <label class="terms-modal__form-group__label">
          <div class="terms-modal__form-group__label__container">
            <input
              formControlName="termsAndConditions"
              class="terms-modal__form-group__label__container-input form-check-input"
              type="checkbox"
            />
          </div>
          <span class="form-check-label">
            <a
              [href]="TERMS_AND_CONDITIONS_URL"
              class="cx-tc-link"
              target="_blank"
            >
              {{ "termsForm.terms" | cxTranslate }}
            </a>
          </span>
        </label>
      </div>
      <button
        class="btn btn-primary btn-block"
        type="submit"
        [disabled]="!form.get('termsAndConditions')?.value"
      >
        {{ "termsForm.accept" | cxTranslate }}
      </button>
    </form>
  </div>
</ng-container>
