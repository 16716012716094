import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomRegisterRegionsService {

  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
  ) { }

  getRegionsPrefixes(): Observable<string[]>{
    const url = this.occEndpointService.buildUrl('regionsPrefix');
    return this.http.get<string[]>(url);
  };

  getCountryCode(): Observable<string>{
    const url = this.occEndpointService.buildUrl('getCountryCode');
    return this.http.get<string>(url);

  }
}
