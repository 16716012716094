<ng-container *ngIf="order">
  <div class="justify-content-end">
    <div class="cx-summary">
      <div class="summary-title">
        {{'orderDetails.promeclubDetail.exchangeSummary' | cxTranslate}}
      </div>
      <div class="summary-points">
        <h4 class="summary-points-text">
          {{'orderDetails.promeclubDetail.total' | cxTranslate}}
        </h4>
        <h4 class="summary-points-value">
          {{order?.totalPrice?.value | number:'':'es-AR'}}
          <span class="summary-points-value-inline">
            {{'orderDetails.promeclubDetail.points' | cxTranslate}}
          </span>
        </h4>
      </div>
    </div>
  </div>
</ng-container>
