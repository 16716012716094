<ng-container *ngIf="orderCode; else orderDetail">
  <div class="custom-return-action-buttons">
    <div
      class="custom-return-action-buttons__back"
      *ngIf="backRoute || currentPage == 'EDIT_ORDER_RETURN'"
    >
      <a
        *ngIf="backRoute"
        [routerLink]="
          {
            cxRoute: backRoute,
            params: { code: orderCode }
          } | cxUrl
        "
        class="button-action"
      >
        {{ "returnRequest.goBack" | cxTranslate }}
      </a>
      <a *ngIf="!backRoute" (click)="onBack()" class="button-action">
        {{ "returnRequest.goBack" | cxTranslate }}
      </a>
    </div>
    <div
      class="custom-return-action-buttons__conf"
      *ngIf="!forwardRoute && currentPage == 'EDIT_ORDER_RETURN'"
    >
      <button
        class="btn btn-block btn-primary"
        [disabled]="!isEnabled"
        (click)="onRevisionOrder()"
      >
        {{ "returnRequest.goDevolution" | cxTranslate }}
      </button>
    </div>

    <div
      class="custom-return-action-buttons__back"
      *ngIf="currentPage == 'REVISION_ORDER_RETURN'"
    >
      <a (click)="onBack()" class="button-action">
        {{ "returnRequest.goBack" | cxTranslate }}
      </a>
    </div>
    <div *ngIf="currentPage == 'REVISION_ORDER_RETURN'">
      <button
        [disabled]="onPressed"
        class="btn btn-block btn-primary-cancellations"
        (click)="onConfirmOrder()"
      >
        {{ "returnRequest.confirmDevolution" | cxTranslate }}
      </button>
    </div>
  </div>
</ng-container>
<ng-template #orderDetail>
  <div class="custom-return-action-buttons button_only" *ngIf="forwardRoute">
    <div class="custom-return-action-buttons__show-devolutions">
      <button
        class="btn btn-block btn-primary"
        [routerLink]="
          {
            cxRoute: forwardRoute
          } | cxUrl
        "
      >
        {{ "returnRequest.showDevolutions" | cxTranslate }}
      </button>
    </div>
  </div>
</ng-template>
