import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ProductVariantsContainerComponent } from '@spartacus/product/variants/components';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CustomProductVariantsService } from './product-variants.service';
import {
  CustomVariantValueCategory,
  SimpleVariantSelectorsList,
} from '../../model/custom-variant-element.interface';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';
import { CustomLoadingSpinnerService } from 'src/app/cms-components/custom/services/custom-loading-spinner.service';
import { PageType, Product, RoutingService } from '@spartacus/core';
import { CustomProduct } from '../../model/custom-product.interface';
import { CustomBaseSiteCheckerService } from '../../../../services/custom-base-site-checker.service';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'yrs-custom-product-variants-container',
  templateUrl: './custom-product-variants-container.component.html',
  styleUrls: ['./custom-product-variants-container.component.scss'],
})
export class CustomProductVariantsContainerComponent
  extends ProductVariantsContainerComponent
  implements OnInit, OnChanges
{
  @Input() product$: Observable<CustomProduct>;
  @Input() fromCartItem: boolean = false;
  unsubscribe$: Subject<any> = new Subject<any>();
  simpleVariantSelectors$: Observable<SimpleVariantSelectorsList[]>;
  seeMoreWithBullet: boolean = false;
  seeMore: boolean = false;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  productCode: string;
  customProduct$: Observable<Product | null>;
  isPromeclub$: Observable<boolean> =
    this.customBaseSiteCheckerService.isPromeclub$;
  userId$: Observable<string> = this.userAccountFacade
    .get()
    .pipe(map((user) => user?.uid!));
  constructor(
    private _currentProductService: CurrentProductService,
    protected variantsService: CustomProductVariantsService,
    protected customBreakpointService: CustomBreakpointService,
    protected customLoadingSpinnerService: CustomLoadingSpinnerService,
    protected routingService: RoutingService,
    protected customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    private userAccountFacade: UserAccountFacade
  ) {
    super(_currentProductService);
  }

  ngOnInit(): void {
    this.customProduct$ = this.product$
      ? this.product$
      : this._currentProductService.getProduct();
    this.showSpinnerWhenProductChanged();

    this.simpleVariantSelectors$ = this.getVariantOptions(this.product$);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes?.['product$']?.firstChange &&
      changes?.['product$']?.currentValue
    ) {
      this.product$ = changes?.['product$']?.currentValue;
      this.simpleVariantSelectors$ = this.getVariantOptions(this.product$);
    }
  }
  getIsVarintWithColorBullet(
    variantCategory: CustomVariantValueCategory
  ): boolean {
    return !!variantCategory.colorHexCode;
  }
  toggleSeeMore(): void {
    this.seeMore = !this.seeMore;
  }

  toggleSeeMoreWithBullet(): void {
    this.seeMoreWithBullet = !this.seeMoreWithBullet;
  }

  showSpinnerWhenProductChanged() {
    this.routingService
      .isNavigating()
      .pipe(
        switchMap((isNavigating) =>
          isNavigating ? this.routingService.getNextPageContext() : of()
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((nextPageContext: any) => {
        nextPageContext?.type === PageType.PRODUCT_PAGE &&
          nextPageContext?.id !== this.productCode &&
          this.customLoadingSpinnerService.showSpinner('');
      });
  }

  getVariantOptions(
    product$: Observable<CustomProduct>
  ): Observable<SimpleVariantSelectorsList[]> {
    return this.variantsService
      .getVariantOptionsMapped(product$)
      .pipe(
        tap((variantsSelectors: SimpleVariantSelectorsList[]) => {
          variantsSelectors.map((variant) => {
            if (
              variant?.variantSelectors?.length! > 12 &&
              !!variant?.variantSelectors?.[0].variantValueCategory
                ?.colorHexCode
            ) {
              this.seeMoreWithBullet = true;
            }
            if (
              variant?.variantSelectors?.length! > 20 &&
              !!!variant?.variantSelectors?.[0].variantValueCategory
                ?.colorHexCode
            ) {
              this.seeMore = true;
            }
          });
        })
      )
      .pipe(tap(() => this.customLoadingSpinnerService.hideSpinner()));
  }
  setProductVariantCode(productCode: string): void {
    this.variantsService.setLoading(true);
    this.variantsService.setVariantCode(productCode);
  }
}
