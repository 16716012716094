import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchemaInjectorComponent } from './schema-injector.component';




@NgModule({
  declarations: [
    SchemaInjectorComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SchemaInjectorComponent
  ]
})
export class SchemaInjectorModule { }
