import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { GenericLinkModule, MediaModule } from '@spartacus/storefront';
import { CustomSimpleResponsiveBanner } from './custom-simple-responsive-banner.component';

@NgModule({
  declarations: [CustomSimpleResponsiveBanner],
  imports: [
    CommonModule,
    RouterModule,
    GenericLinkModule,
    MediaModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        SimpleResponsiveBannerComponent: {
          component: CustomSimpleResponsiveBanner,
        },
      },
    }),
  ],
})
export class CustomSimpleResponsiveBannerModule {}
