export const storeFinder = {
  storeFinder: {
    openUntil: 'Abierto hasta',
    closed: 'Cerrado',
    back: 'Atrás',
    call: 'Llamar',
    getDirections: 'Obtener Direcciones',
    ariaLabelGetDirections:
      'Obtener indicaciones, este enlace se abre en una ventana nueva',
    listView: 'Vista de Lista',
    mapView: 'Vista de Mapa',
    noStoresFound: 'No se encontraron tiendas.',
    storeHours: 'Horarios de la tienda',
    storeFeatures: 'Características de la tienda',
    fromStoresFound: 'de {{ count }} tienda encontrada',
    fromStoresFound_plural: 'de {{ count }} tiendas encontradas',
    findStore: 'Encontrar tienda',
    useMyLocation: 'Usar mi ubicación',
    viewAllStores: 'Ver todas las tiendas',
    contactUs: 'Contactenos',
    searchBox: 'Ingrese un código postal, ciudad o dirección',
    backToList: 'Volver a la lista',
    noStoresMessage: 'No se encontraron tiendas en la base de datos...',
    geolocationNotEnabled:
      'No se encontraron tiendas cercanas. No se ha habilitado el consentimiento de geolocalización',
  },
};
