<div class="promeclub">
  <div class="promeclub__button">
      <button
        class="promeclub__button-button"
        type="button"
        (click)="onSubmit()"
      >
        <span>{{ "customPromeclub.form.submitButton" | cxTranslate }}</span>
        <cx-icon class="fas fa-chevron-right"></cx-icon>
      </button>
  </div>
</div>
