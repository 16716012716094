import { Component } from '@angular/core';
import { CustomRegisterValidationComponent } from '../../custom-register-validation/custom-register-validation.component';

@Component({
  selector: 'app-custom-register-customer-validation',
  templateUrl: './custom-register-customer-validation.component.html',
  styleUrls: ['./custom-register-customer-validation.component.scss'],
})
export class CustomRegisterCustomerValidationComponent extends CustomRegisterValidationComponent {

}
