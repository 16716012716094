<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entries$ | async as entries">
    <app-custom-order-summary [customCart]="cart"></app-custom-order-summary>

    <ng-container *cxFeatureLevel="'4.2'">
      <ng-container *ngIf="isMobile$ | async as isMobile; else onDesktop">
        <div class="cx-btn-cart-totals">
          <div class="cx-summary-row cx-summary-total">
            <ng-container *ngIf="pageCurrent$ | async as pageCurrent">
              <span class="text">{{ "orderCost.total" | cxTranslate }}</span>
              <ng-container *ngIf="!isInPromeclub">
                {{
                  pageCurrent == "cartPage"
                    ? (cart.subTotalWithTax?.value | customCurrency)
                    : (cart.totalPriceWithTax?.value | customCurrency)
                }}
              </ng-container>
              <ng-container *ngIf="isInPromeclub">
                {{ cart.totalPrice?.value }}
                <span class="cx-summary-total__iva">
                  {{ "productList.points" | cxTranslate }}
                </span>
              </ng-container>
            </ng-container>
          </div>
          <app-custom-progress-button
            *ngIf="entries.length"
            [routerLink]="{ cxRoute: 'checkout' } | cxUrl"
            [loading]="cartValidationInProgress"
            [disabled]="cartValidationInProgress"
            [class]="'btn btn-primary btn-block'"
            (clikEvent)="disableButtonWhileNavigation()"
          >
            <cx-icon
              type="LOCK_ALT"
              *ngIf="!cartValidationInProgress"
            ></cx-icon>

            {{
              (!cartValidationInProgress ? "cartDetails.proceedToCheckout" : "")
                | cxTranslate
            }}
          </app-custom-progress-button>
        </div>
      </ng-container>
      <ng-template #onDesktop>
        <app-custom-progress-button
          *ngIf="entries.length"
          [routerLink]="{ cxRoute: 'checkout' } | cxUrl"
          [loading]="cartValidationInProgress"
          [disabled]="cartValidationInProgress"
          [class]="'btn btn-primary btn-block'"
          (clikEvent)="disableButtonWhileNavigation()"
        >
          {{
            (!cartValidationInProgress ? "cartDetails.proceedToCheckout" : "")
              | cxTranslate
          }}
        </app-custom-progress-button>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
