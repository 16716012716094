<ng-container
  *ngIf="{
    navigation: navigationNode$ | async,
    isMobile: isMobile$ | async
  } as data"
>
  <ng-container *ngIf="footerData$ | async as footer">
    <div class="footer-bar-navigation">
      <div class="footer-bar-social__item">
        <div class="cx-social">
          <a *ngIf="footer.linkedinUrl" [href]="footer.linkedinUrl">
            <cx-icon [type]="'LINKEDIN'"></cx-icon>
          </a>
          <a *ngIf="footer.instagramUrl" [href]="footer.instagramUrl">
            <cx-icon [type]="'INSTAGRAM'"></cx-icon>
          </a>
          <a *ngIf="footer.facebookUrl" [href]="footer.facebookUrl">
            <cx-icon [type]="'FACEBOOK_SQUARE'"></cx-icon>
          </a>
        </div>
      </div>

      <div *ngIf="data.isMobile" class="footer-bar-notice">
        <div *ngIf="footer.rightsText" class="footer-bar-notice__item">
          <div [innerHtml]="footer.rightsText | safeHtml"></div>
          <div class="cx-notice">|</div>
        </div>
        <div *ngIf="footer.conditionsText" class="footer-bar-notice__item">
          <div [innerHtml]="footer.conditionsText | safeHtml"></div>
        </div>
        <div
          *ngIf="footer.noticeText"
          [innerHtml]="footer.noticeText | safeHtml"
          class="footer-bar-notice__item conditions-text"
        ></div>
      </div>

      <div *ngIf="!data.isMobile" class="footer-bar-notice">
        <div *ngIf="footer.noticeText" class="footer-bar-notice__item">
          <div [innerHtml]="footer.noticeText | safeHtml"></div>
          <div class="cx-notice">|</div>
        </div>
        <div *ngIf="footer.rightsText" class="footer-bar-notice__item">
          <div [innerHtml]="footer.rightsText | safeHtml"></div>
          <div class="cx-notice">|</div>
        </div>
        <div
          *ngIf="footer.conditionsText"
          [innerHtml]="footer.conditionsText | safeHtml"
          class="footer-bar-notice__item"
        ></div>
      </div>
    </div>
  </ng-container>
</ng-container>
