import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ICON_TYPE } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomRebateService } from '../../services/custom-rebate.service';
import { RebateList } from '../../models/custom-rebate.interface';

@Component({
  selector: 'app-custom-rebate-list',
  templateUrl: './custom-rebate-list.component.html',
  styleUrls: ['./custom-rebate-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomRebateListComponent implements OnInit, OnDestroy {
  public rebate$: Observable<RebateList | null>;
  public isMobile$: Observable<boolean>;
  public itemCodeSelected: number | boolean = 0;
  public detailState: boolean = false;
  iconTypes = ICON_TYPE;

  userId$: Observable<string> = this.userAccountFacade
    .get()
    .pipe(map((user) => user?.uid!));
  user: string;

  quantities: any[] = [
    { id: 25, name: 25, disabled: true },
    { id: 50, name: 50, disabled: true },
    { id: 100, name: 100, disabled: true },
    { id: 200, name: 200, disabled: true },
    { id: 10000, name: 'Todos', disabled: true },
  ];
  pageSize: number = 10000;

  yearsAndMonths: any[] = [];

  selectedPage: number = 1;

  yearsAndMonthsSelected: string | undefined = undefined;

  unsubscribe$: Subject<void> = new Subject();

  constructor(
    private customBreakpointService: CustomBreakpointService,
    private customRebateService: CustomRebateService,
    protected userAccountFacade: UserAccountFacade
  ) {}

  setItem(e: Event, code: number) {
    this.detailState = !this.detailState;

    e.stopPropagation();
    this.itemCodeSelected = this.itemCodeSelected == code + 1 ? 0 : code + 1;
  }

  ngOnInit(): void {
    this.loadYearsAndMonths();
    this.isMobile$ = this.customBreakpointService.isMobile$;
    this.userId$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((userId) => (this.user = userId));
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    this.rebate$ = this.customRebateService.getRebate(
      this.user,
      undefined,
      undefined,
      year,
      month + 1
    );
  }
  loadYearsAndMonths() {
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    //January gives 0
    this.yearsAndMonthsSelected = this.getMonths(month + 1) + ` ${year}`;

    const min = 2022;

    const bucleMeses = (indexMonth: number, año: number) => {
      for (let index = indexMonth; index >= 0; index--) {
        this.yearsAndMonths.push({
          id: `${index + 1}/${año}`,
          name: this.getMonths(index + 1) + ` ${año}`,
        });
      }
    };

    for (let i = year; i >= min; i--) {
      /* en el ultimo año recorro hasta el ultimo mes */
      if (i == year) {
        bucleMeses(month, i);
      } else {
        bucleMeses(11, i);
      }
    }
  }

  valueMinProgressBar(arrayScale: any[], totalFacturado: string): number {
    return this.customRebateService.valueMinProgressBar(
      arrayScale,
      totalFacturado
    );
  }

  valueProgressBar(arrayScale: any[], totalFacturado: string): number {
    return this.customRebateService.valueProgressBar(
      arrayScale,
      totalFacturado
    );
  }

  valueMaxProgressBar(arrayScale: any[]): number {
    return this.customRebateService.valueMaxProgressBar(arrayScale);
  }

  filterYearAndMonthSelected(filter: string) {
    const year = filter.split('/');
    const filteredData = this.yearsAndMonths.find((data) => {
      return data.id == filter;
    });

    this.yearsAndMonthsSelected = filteredData.name;

    this.rebate$ = this.customRebateService.getRebate(
      this.user,
      undefined,
      undefined,
      year[1],
      year[0]
    );
  }
  deleteYearAndMonth() {
    this.yearsAndMonthsSelected = undefined;

    this.rebate$ = this.customRebateService.getRebate(
      this.user,
      undefined,
      undefined,
      undefined,
      undefined
    );
  }

  filterQuantitySelected(filter: any) {
    this.pageSize = filter;
    this.selectedPage = 0;
    this.rebate$ = this.customRebateService.getRebate(
      this.user,
      this.selectedPage,
      this.pageSize,
      undefined,
      undefined
    );
  }

  customPageChangeSelected(page: number): void {
    this.selectedPage = page;
    this.rebate$ = this.customRebateService.getRebate(
      this.user,
      this.selectedPage,
      undefined,
      undefined,
      undefined
    );
  }

  getMonths(month: number): string | undefined {
    let monthString;
    switch (month) {
      case 1:
        return (monthString = 'Enero');
        break;
      case 2:
        return (monthString = 'Febrero');
        break;
      case 3:
        return (monthString = 'Marzo');
        break;
      case 4:
        return (monthString = 'Abril');
        break;
      case 5:
        return (monthString = 'Mayo');
        break;
      case 6:
        return (monthString = 'Junio');
        break;
      case 7:
        return (monthString = 'Julio');
        break;

      case 8:
        return (monthString = 'Agosto');
        break;
      case 9:
        return (monthString = 'Septiembre');
        break;
      case 10:
        return (monthString = 'Octubre');
        break;
      case 11:
        return (monthString = 'Noviembre');
        break;
      case 12:
        return (monthString = 'Diciembre');
        break;

      default:
        break;
    }

    return monthString;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
