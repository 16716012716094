<div class="filter-reviews-facets">
  <label>
    {{
      (isMobile
        ? "filterProductsReviews.labelMobile"
        : "filterProductsReviews.labelFilterProductsReviews"
      ) | cxTranslate
    }}
    <cx-icon
      *ngIf="isMobile"
      [class]="(showFacet$ | async) ? 'fas fa-minus' : 'fas fa-plus'"
      (click)="toggleFacet()"
    ></cx-icon>
  </label>
  <div
    *ngFor="let item of this.facetReviews | async"
    class="filter-reviews-facets__stars mb-2"
    [ngClass]="{ hide: !(showFacet$ | async) }"
  >
    <ng-container *ngIf="item.count > 0">
      <a
        (click)="getUrl(item.query?.query?.value)"
        class="d-flex justify-content-between w-100"
      >
        <app-custom-product-reviews-overview
          [fromFacet]="true"
          [reviewRatingAverage]="item.name"
          [fromMenu]="isMobile"
          [selected]="item.selected"
          [count]="item.count"
        ></app-custom-product-reviews-overview>
      </a>
    </ng-container>
  </div>
</div>
