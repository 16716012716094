import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomWishlistNavigationComponent } from './custom-wishlist-navigation.component';
import { IconModule } from '@spartacus/storefront';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CustomWishlistNavigationComponent],
  imports: [
    CommonModule,
    IconModule,
    I18nModule,
    RouterModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        InnerHeaderWishlistComponent: {
          component: CustomWishlistNavigationComponent,
        },
      },
    }),
  ],
})
export class CustomWishlistNavigationModule {}
