import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CheckoutProgressComponent,
  CheckoutStepService,
} from '@spartacus/checkout/components';
import { CheckoutStep } from '@spartacus/checkout/root';
import { ActiveCartService, RoutingService } from '@spartacus/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { CustomGtmEcommerceDatalayerService } from 'src/app/common/services/custom-gtm-ecommerce-datalayer.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomBaseSiteCheckerService } from '../../../../services/custom-base-site-checker.service';
import { CustomReviewSubmitService } from '../../services/custom-review-submit.service';

@Component({
  selector: 'app-custom-checkout-progress',
  templateUrl: './custom-checkout-progress.component.html',
  styleUrls: ['./custom-checkout-progress.component.scss'],
})
export class CustomCheckoutProgressComponent
  extends CheckoutProgressComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private __steps$: BehaviorSubject<CheckoutStep[]> =
    this.checkoutStepService.steps$;

  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  subscription: Subscription = new Subscription();
  isPromeclub$: Observable<boolean> =
    this.customBaseSiteCheckerService.isPromeclub$;
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  unsubscribe$: Subject<void> = new Subject();
  constructor(
    protected checkoutStepService: CheckoutStepService,
    private customBreakpointService: CustomBreakpointService,
    private routingService: RoutingService,
    private activeCartService: ActiveCartService,
    private activated: ActivatedRoute,
    private customReviewSubmitService: CustomReviewSubmitService,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    private customLayerService: CustomGtmEcommerceDatalayerService
  ) {
    super(checkoutStepService);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.activeCartService.getEntries().subscribe((entries) => {
        if (!(entries.length > 0 && !this.isPromeclub)) {
          if (
            !this.subscription.add(
              this.customReviewSubmitService
                .getIsReviewSubmitActive()
                .subscribe((value) => value)
            )
          ) {
            this.routingService.go({ cxRoute: 'cart' });
          }
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.activeCartService
      .getActive()
      .pipe(take(1))
      .subscribe((cart) => {
        this.customLayerService.checkoutEvent(cart.entries, undefined, {
          step: this.activeStepIndex + 1,
          revenue: Number(cart.totalPriceWithTax?.value),
          tax: Number(cart.totalTax?.value),
          discounts: Number(cart.totalDiscounts?.value),
        });
      });
  }

  activeStepIndex: number;
  activeStepIndex$: Observable<number> =
    this.checkoutStepService.activeStepIndex$.pipe(
      tap((index) => (this.activeStepIndex = index))
    );

  get steps$(): Observable<CheckoutStep[]> {
    return this.__steps$.asObservable();
  }

  getTabIndex(stepIndex: number): number {
    return !this.isActive(stepIndex) && !this.isDisabled(stepIndex) ? 0 : -1;
  }

  isActive(index: number): boolean {
    return index === this.activeStepIndex;
  }

  isDisabled(index: number): boolean {
    return index > this.activeStepIndex;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscription.unsubscribe();
  }
}
