
<ng-container *ngIf="{
  wishlist: wishlist$ | async,
  isMobile: isMobile$ | async,
  updated: updated$ | async
  } as data"
>
<ng-container *ngIf="!data.updated.loading; else loading">
  <div class="custom-wish" *ngIf="itemsQuantity > 0; else emptyWishlist">
    <h3
      *ngIf="!data.isMobile"
      class="custom-wish__title"
      [innerHTML]="'customWishlist.title' | cxTranslate : {count: itemsQuantity}"
    ></h3>
    <h3
      *ngIf="data.isMobile"
      class="custom-wish__title"
      [innerHTML]="'customWishlist.mobileTitle' | cxTranslate"
    ></h3>
    <h4
      *ngIf="data.isMobile && itemsQuantity > 0"
      class="custom-wish__subtitle"
      [innerHTML]="'customWishlist.mobileSubtitle' | cxTranslate : {count: itemsQuantity}"
    ></h4>
    <app-custom-wishlist-header *ngIf="!data.isMobile"></app-custom-wishlist-header>

    <ng-container *ngFor="let entry of data.wishlist">
      <app-custom-wishlist-item
        [entry]="entry"
        [isMobile]="data.isMobile"
      ></app-custom-wishlist-item>
    </ng-container>
    <div class="custom-wish__empty" (click)="clearWishlist()">
        <div class="custom-wish__empty-action">
          {{'customWishlist.empty' | cxTranslate}}
        </div>
        <cx-icon class="far fa-trash-alt"></cx-icon>
    </div>
  </div>

  <ng-template #emptyWishlist>
    <ng-container *ngIf="itsEmpty || itemsQuantity == 0;">
      <h3
        class="custom-wish__title"
        [innerHTML]="'customWishlist.mobileTitle' | cxTranslate"
      ></h3>
      <app-custom-wishlist-header *ngIf="!data.isMobile"></app-custom-wishlist-header>
      <app-custom-empty-cart [fromWishlist]="true"></app-custom-empty-cart>
    </ng-container>
  </ng-template>
</ng-container>
<ng-template #loading>
  <div class="loading">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>

</ng-container>

