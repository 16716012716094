import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomWishlistService {
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService
  ) {}

  postWishlist(userId: string, productCode: string): Observable<any> {
    const url = this.occEndpointService.buildUrl('postWishList', {
      urlParams: {
        userId,

      },
      queryParams: {
        productCode
      }
    });
    return this.http.post<any>(url, {});
  }

  getWishList(userId: string): Observable<any> {
    const url = this.occEndpointService.buildUrl('getWishList', {
     urlParams: {
      userId
     }
    });
    return this.http.get<any>(url);
  }

  deleteWishList(userId: string, productCode: string): Observable<any> {
    const url = this.occEndpointService.buildUrl('deleteWishList', {
      urlParams: {
        userId,
      },
      queryParams: {
        productCode
      }
    });
    return this.http.put<any>(url, {});
  }

  productCheckWishList(userId: string, productCode: string): Observable<HttpResponse<any>> {
    const url = this.occEndpointService.buildUrl('productCheckWishList', {
      urlParams: {
        userId,
      },
      queryParams: {
        productCode
      }
    });
    return this.http.get<HttpResponse<any>>(url);
  }
}
