<div
  *ngIf="{
    componentData: componentData$ | async,
    isMobile: isMobile$ | async
  } as info"
  class="custom-secondary-banner"
>
  <ng-container *ngIf="!!info.componentData">
    <ng-container *ngFor="let item$ of items$ | async; index as i">
      <div
        class="custom-secondary-banner__img"
        [ngClass]="{
          'custom-secondary-banner__img-promeclub': isPromeclub,
          first: info.isMobile && i == 0,
          second: info.isMobile && i == 1,
          third: info.isMobile && i == 2
        }"
        *ngIf="item$ | async as item"
        tabindex="0"
      >
        <div
          class="custom-secondary-banner__info"
          *ngIf="!!!item.video && !!item.headline || !!!item.video && !!item.mobileHeadline "
        >
          <h4
            *ngIf="!!item.headline && !item.video && !info.isMobile"
            class="custom-secondary-banner__info-title"
            [ngClass]="{ secondary: info.isMobile && i > 0 }"
            [innerHTML]="item.headline | safeHtml"
          ></h4>
          <h4
          *ngIf="!!item.mobileHeadline && !item.video && info.isMobile"
          class="custom-secondary-banner__info-title"
          [ngClass]="{ secondary: info.isMobile && i > 0 }"
          [innerHTML]="item.mobileHeadline | safeHtml"
        ></h4>
          <a
            class="custom-button-anchor primary"
            *ngIf="!!item.cmsLink?.section && !info.isMobile"
            [ngClass]="!!!item.linkTitle ? 'without-text' : ''"
            [routerLink]="item.cmsLink?.url"
            (click)="
              changeSection(item.cmsLink); navigateToUrl(item?.cmsLink?.url)
            "
          >
            {{ !!item.linkTitle ? item.linkTitle : "" }}

            <cx-icon class="fas fa-chevron-right"></cx-icon>
          </a>
          <a
            class="custom-button-anchor primary"
            *ngIf="!item.cmsLink?.section && !info.isMobile"
            [ngClass]="!!!item.linkTitle ? 'without-text' : ''"
            (click)="navigateToUrl(item.urlLink)"
          >
            {{ !!item.linkTitle ? item.linkTitle : "" }}

            <cx-icon class="fas fa-chevron-right"></cx-icon>
          </a>
          <a
          class="custom-button-anchor primary"
          *ngIf="!!item.cmsLink?.section && info.isMobile && !!item.mobileLinkTitle "
          [ngClass]="!!!item.mobileLinkTitle ? 'without-text' : ''"
          [routerLink]="item.cmsLink?.url"
          (click)="
            changeSection(item.cmsLink); navigateToUrl(item?.cmsLink?.url)
          "
        >
        {{ !!item.mobileLinkTitle ? item.mobileLinkTitle : "" }}

          <cx-icon class="fas fa-chevron-right"></cx-icon>
        </a>
        <a
          class="custom-button-anchor primary"
          *ngIf="!item.cmsLink?.section && info.isMobile && !!item.mobileLinkTitle "
          [ngClass]="!!!item.mobileLinkTitle ? 'without-text' : ''"
          (click)="navigateToUrl(item.urlLink)"
        >
          {{ !!item.mobileLinkTitle ? item.mobileLinkTitle : "" }}

          <cx-icon class="fas fa-chevron-right"></cx-icon>
        </a>
        </div>

        <cx-generic-link
          (click)="
            !(!!item.headline || !!item.linkTitle)
              ? navigateToUrl(item.urlLink)
              : null
          "
          *ngIf="!!!item.video; else videoTemplate"
        >
          <ng-container *ngIf="item.image | async as itemImage">
            <img
              [src]="getImageUrl(itemImage.url)"
              [alt]="itemImage.altText"
              loading="eager"
              fetchPriority="high"
              [style]="(item?.activateLinearGradientEffect && item?.desktopLinearGradientEffect && !info.isMobile || item?.activateLinearGradientEffect && item?.mobileLinearGradientEffect && info.isMobile) ? 'opacity: 0' : 'opacity: 1'"
            />
            <div *ngIf="item?.activateLinearGradientEffect && item?.desktopLinearGradientEffect && !info.isMobile || item?.activateLinearGradientEffect && item?.mobileLinearGradientEffect && info.isMobile
            " [class]="'img-backdrop --' +i"[style]="getStyles(i, info.isMobile, itemImage?.url, item)"
            ></div>
          </ng-container>
        </cx-generic-link>
        <ng-template #videoTemplate>
          <video
            *ngIf="!!item?.video?.srcVideo"
            class="custom-secondary-banner__img-video"
            width="100%"
            [src]="item?.video?.srcVideo + '#t=0.5'"
            [autoplay]="false"
            [loop]="true"
            [controls]="true"
            [muted]="false"
          ></video>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
</div>
