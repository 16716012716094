import { Injectable } from '@angular/core';
import { MultiCartService } from '@spartacus/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomReloadCartService {
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();
  private currentUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public currentUrl$: Observable<string> = this.currentUrl.asObservable();

  constructor(private multiCartService: MultiCartService) {}

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }
  setcurrentUrl(currentUrl: string) {
    this.currentUrl.next(currentUrl);
  }

  currentAndPrevious(): Observable<[string, string]> {
    return combineLatest([this.previousUrl$, this.currentUrl$]);
  }

  reloadCart(cartId: string): void {
    let currentAndPrevious = this.currentAndPrevious();
    currentAndPrevious.pipe(take(1)).subscribe(([previousUrl, currentUrl]) => {
      if (
        !(previousUrl === 'undefined' && currentUrl.slice(0, 5) === '/cart')
      ) {
        this.multiCartService.reloadCart(cartId);
      }
    });
  }
}
