import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomEntriesMiniCartComponent } from './custom-entries-mini-cart.component';
import { ProductSummaryComponent } from './product-summary/product-summary.component';

@NgModule({
  declarations: [
    CustomEntriesMiniCartComponent,
    ProductSummaryComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    RouterModule,
    I18nModule,
    UrlModule,
    CustomCurrencyModule,
    MediaModule,
  ],
  exports: [
    CustomEntriesMiniCartComponent
  ]
})
export class CustomEntriesMiniCartModule { }
