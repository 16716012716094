import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { GenericLinkModule, IconModule } from '@spartacus/storefront';
import { CustomSafeHtmlModule } from '../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomValueListComponent } from './custom-values-list.component';

@NgModule({
  declarations: [CustomValueListComponent],
  imports: [
    CommonModule,
    CustomSafeHtmlModule,
    GenericLinkModule,
    IconModule,
    RouterModule,
    I18nModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromesaValuesListComponent: {
          component: CustomValueListComponent,
        },
      },
    }),
  ],
})
export class CustomValueListModule {}
