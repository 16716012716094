import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Region } from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { CustomTwilioValidatorService } from 'src/app/common/services/custom-twilio-validator.service';
import { CustomAddress } from '../../../models/custom-address.interface';
import { CustomAddressOrchestratorService } from '../../../services/custom-address-orchestrator.service';
import { CustomPromeclubAddressService } from '../../../services/custom-promeclub-address.service';
import { CustomPromeclubAddressAlertDialogComponent } from '../custom-promeclub-address-alert/custom-promeclub-address-alert-dialog.component';

@Component({
  selector: 'app-custom-promeclub-address-form',
  templateUrl: './custom-promeclub-address-form.component.html',
  styleUrls: ['./custom-promeclub-address-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomPromeclubAddressFormComponent implements OnInit {
  @Input() userAddress: CustomAddress;
  @Input() addressToEdit: CustomAddress;
  addressTypes$: Observable<string[]> = this.customPromeclubAddressService
    .getAvailableAddressTypes()
    .pipe(
      tap((types) => {
        if (types?.length === 0) {
          this.customAddressOrchestratorService.setAvailableTypes(false);
          return;
        }
        this.customAddressOrchestratorService.setAvailableTypes(true);
      })
    );
  provinces$: Observable<Region[]> =
    this.customPromeclubAddressService.getProvinces();
  districts$: Observable<Region[]>;
  addressId: string;
  readonly$: Observable<boolean> =
    this.customAddressOrchestratorService.getReadOnlyMode();
  readOnly: boolean = false;
  edit: boolean = false;
  addressForm: FormGroup = this.fb.group(
    {
      promotickAddressType: [null, [Validators.required]],
      region: [null, [Validators.required]],
      district: [null, [Validators.required]],
      line1: ['', [Validators.required, Validators.maxLength(50)]],
      line2: ['', [Validators.maxLength(20)]],
    },
    { updateOn: 'change' }
  );
  addressSubmited: boolean = false;
  subscription: Subscription = new Subscription();
  modalRef: ModalRef;
  validationStatus$: Observable<boolean> = this.customTwilioValidatorService
    .getValidationStatus()
    .pipe(map((status) => status === 'OK'));
  constructor(
    private fb: FormBuilder,
    private customPromeclubAddressService: CustomPromeclubAddressService,
    private customAddressOrchestratorService: CustomAddressOrchestratorService,
    private modalService: ModalService,
    private customTwilioValidatorService: CustomTwilioValidatorService
  ) {}

  ngOnInit(): void {
    this.initializeUserAddress();
    this.subscription.add(
      this.validationStatus$.pipe().subscribe((isValid) => {
        if (isValid && !this.readOnly) {
          this.submitAddress();
        }
      })
    );
  }

  submitAddress() {
    let address = this.addressForm.value;
    this.addressSubmited = false;
    if (this.edit) {
      address = {
        ...address,
        promotickAddressType: this.addressToEdit.promotickAddressType,
      };
      this.customPromeclubAddressService
        .updateAddress(address, this.addressToEdit.id!)
        .pipe(take(1))
        .subscribe(() => this.openSuccessModal(false, false, true));
      return;
    }
    this.customPromeclubAddressService
      .addAddress(address)
      .pipe(take(1))
      .subscribe((value) => {
        if (value) {
          this.openSuccessModal(true, false, false);
        }
      });
  }

  manageDistrictControl(province: Region): void {
    this.addressForm.get('district')?.setValue(null);
    this.addressForm.get('district')?.disable();
    this.districts$ = this.customPromeclubAddressService
      .getDistricts(province.isocode!)
      .pipe(
        tap((value) => {
          if (value) {
            this.addressSubmited = false;
            this.addressForm.get('district')?.enable();
          }
        })
      );
  }

  regionControlSubscription() {
    this.subscription.add(
      this.addressForm
        .get('region')
        ?.valueChanges.subscribe((province: Region) =>
          this.manageDistrictControl(province)
        )
    );
  }

  initializeUserAddress() {
    if (this.userAddress?.promotickAddressType) {
      this.readOnly = true;
      this.customAddressOrchestratorService.setReadOnlyMode(true);
      this.addressId = this.userAddress.id!;
      this.addressForm
        .get('promotickAddressType')
        ?.setValue(
          this.customPromeclubAddressService.typeTranslation(this.userAddress)
        );
      this.addressForm.get('region')?.setValue(this.userAddress.region);
      this.addressForm.get('district')?.setValue(this.userAddress.district);
      this.addressForm.get('line1')?.setValue(this.userAddress.line1);
      this.addressForm.get('line2')?.setValue(this.userAddress.line2);
      this.addressForm.disable();
    } else if (this.addressToEdit?.promotickAddressType) {
      this.addressForm
        .get('promotickAddressType')
        ?.setValue(
          this.customPromeclubAddressService.typeTranslation(this.addressToEdit)
        );
      this.addressForm.get('promotickAddressType')?.disable();
      this.addressForm.get('region')?.setValue(this.addressToEdit.region);
      this.districts$ = this.customPromeclubAddressService
        .getDistricts(this.addressToEdit.region?.isocode!)
        .pipe(
          tap((districts) => {
            const districtSelected = districts.filter(
              (district) => district.name === this.addressToEdit.district
            );
            if (districtSelected) {
              this.addressForm.get('district')?.setValue(districtSelected[0]);
            } else {
              this.addressForm
                .get('district')
                ?.setValue(this.addressToEdit.district);
            }
          })
        );
      this.addressForm.get('line1')?.setValue(this.addressToEdit.line1);
      this.addressForm.get('line2')?.setValue(this.addressToEdit.line2);
      this.regionControlSubscription();
      this.edit = true;
    } else {
      this.addressForm.get('district')?.disable();
      this.regionControlSubscription();
    }
  }

  deleteAddress() {
    this.openSuccessModal(false, true);
  }

  toggleEditMode() {
    this.customAddressOrchestratorService.setReadOnlyMode(false);
    this.customAddressOrchestratorService.setEditMode(true);
    this.customAddressOrchestratorService.setAddressToEdit(this.userAddress);
    window.scroll(0, 0);
  }

  toggleAddMode(value: boolean) {
    this.customAddressOrchestratorService.setAddNewMode(value);
    this.customAddressOrchestratorService.setEditMode(value);
    window.scroll(0, 0);
  }

  openSuccessModal(
    add: boolean = false,
    remove: boolean = false,
    edit: boolean = false
  ) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(
      CustomPromeclubAddressAlertDialogComponent,
      {
        centered: false,
        size: 'lg',
        windowClass: 'address'
      }
    );
    modalInstance = this.modalRef.componentInstance;
    modalInstance.addAddress = add;
    modalInstance.removeAddress = remove;
    modalInstance.id = this.addressId;
    modalInstance.editAddress = edit;
    modalInstance.type = `promeclubAddress.${
      this.addressForm.get('promotickAddressType')?.value
    }`;
    if (edit) {
      modalInstance.type = `promeclubAddress.${this.addressToEdit.promotickAddressType}`;
    } else if (remove) {
      modalInstance.type = `promeclubAddress.${this.userAddress.promotickAddressType}`;
    }
  }

  openTwilioValidation() {
    if (this.addressForm.invalid) {
      this.addressSubmited = true;
      return;
    }
    this.customPromeclubAddressService.submitValidation();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
