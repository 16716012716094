import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { Facet, FacetValue, TranslationService } from '@spartacus/core';
import {
  FacetCollapseState,
  FacetComponent,
  FacetGroupCollapsedState,
} from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomFacetService } from 'src/app/cms-components/product/services/custom-facet.service';
import { CustomBaseSiteCheckerService } from 'src/app/services/custom-base-site-checker.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { OnDestroy } from '@angular/core';

@Component({
  selector: 'app-custom-facet',
  templateUrl: './custom-facet.component.html',
  styleUrls: ['./custom-facet.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomFacetComponent
  extends FacetComponent
  implements OnInit, OnDestroy
{
  customState$: Observable<FacetCollapseState>;
  FACETGROUP = FacetGroupCollapsedState.EXPANDED;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  static readonly TOP_VISIBLE = 5;
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  subscription: Subscription = new Subscription();
  showFacet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  groupCount: number | undefined;

  scrollActive: boolean = false;
  withoutResults: boolean = false;
  currentFacetArray: BehaviorSubject<Facet> = new BehaviorSubject<Facet>({});
  currentFacetArray$: Observable<Facet> = this.currentFacetArray.asObservable();
  facetValuesArray: Facet;

  constructor(
    facetService: CustomFacetService,
    elementRef: ElementRef<HTMLElement>,
    cd: ChangeDetectorRef,
    protected translation: TranslationService,
    private customBreakpointService: CustomBreakpointService,
    private router: Router,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService
  ) {
    super(facetService, elementRef, cd);
  }

  ngOnInit() {
    this.currentFacetArray.next(this.facet);
    this.setCurrentFacet();

    this.state$.pipe(
      map(
        (f) =>
          (f = {
            ...f,
            maxVisible: CustomFacetComponent.TOP_VISIBLE,
            topVisible: CustomFacetComponent.TOP_VISIBLE,
          })
      )
    );
    this.subscription.add(
      this.isMobile$.subscribe((isMobile) =>
        !isMobile ? this.decreaseVisibleValues() : null
      )
    );
    this.groupCount = this.facet?.values
      ?.map((value) => value?.count)
      ?.reduce((accumulator, currentValue) => accumulator! + currentValue!, 0);
  }

  increaseVisibleValues(): void {
    this.facetService.increaseVisibleValues(this.facetValuesArray);
    this.scrollActive = true;
  }

  decreaseVisibleValues(): void {
    this.facetService.decreaseVisibleValues(this.facetValuesArray);
    this.scrollActive = false;
  }

  getLinkParams(value: FacetValue) {
    const linkParams = this.facetService.getLinkParams(
      value?.query?.query?.value!
    );

    if (this.router.url.includes('variant')) {
      linkParams.plpType = 'variant';
      linkParams.searchQueryContext = 'VARIANTS';
    }
    if (this.router.url.includes('classic')) {
      linkParams.plpType = 'classic';
    }

    return linkParams;
  }

  toggleFacet(): void {
    this.showFacet$.next(!this.showFacet$.value);
  }

  setCurrentFacet(newArrayValues?: FacetValue[]) {
    this.facetValuesArray = {
      category: this.facet?.category,
      multiSelect: this.facet?.multiSelect,
      name: this.facet?.name,
      priority: this.facet?.priority,
      topValueCount: this.facet?.topValueCount,
      values: newArrayValues ? newArrayValues : this.facet?.values,
      visible: this.facet?.visible,
    };
  }

  searchFacet(inputText: string) {
    let newArreyValues: FacetValue[] = [];
    if (inputText === '') {
      this.currentFacetArray.next(this.facet);
      this.setCurrentFacet();
      this.withoutResults = false;
    } else {
      for (let i = 0; i < this.facet?.values?.length!; i++) {
        let name: string = this.facet
          ?.values![i]?.name!.toLowerCase()
          .split(/\s+/)
          .join('');
        if (name.includes(inputText.toLowerCase())) {
          newArreyValues.push(this.facet?.values![i]);
        }
      }

      this.setCurrentFacet(newArreyValues);

      this.currentFacetArray.next(this.facetValuesArray);
      this.withoutResults = this.facetValuesArray?.values?.length === 0;
    }
  }

  ngOnDestroy(): void {
    this.decreaseVisibleValues();
    this.subscription.unsubscribe();
  }
}

export class FacetsName {
  name: string;
  title: Observable<string>;
}
