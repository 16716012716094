<ng-container
  *ngIf="{
    value: isMobile$ | async,
    mobileCustomNode: mobileCustomNode$ | async,
    desktopCustomNode: desktopCustomNode$ | async
  } as isMobile"
>
  <app-custom-category-navigation-ui
    *ngIf="data$ | async as data"
    [node]="
      isMobile.value ? isMobile.mobileCustomNode : isMobile.desktopCustomNode
    "
    [ngClass]="data.styleClass"
    [wrapAfter]="data.wrapAfter"
    [resetMenuOnClose]="data.resetMenuOnClose"
    [isPromeclub]="isInPromeclub"
  >
  </app-custom-category-navigation-ui>
  <app-custom-wishlist-header></app-custom-wishlist-header>
</ng-container>
