import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { CardModule, IconModule } from '@spartacus/storefront';
import { CustomTitleMyAccountModule } from 'src/app/cms-components/custom/components/custom-title-my-account/custom-title-my-account.module';
import { CustomCurrencyModule } from '../../../../../pipes/custom-currency/custom-currency.module';
import { CustomPaginationModule } from '../../../../custom/components/custom-pagination/custom-pagination.module';
import { CustomOrderDetailItemsModule } from '../custom-order-detail-items/custom-order-detail-items.module';
import { CustomPromeclubOrderDetailsTotalComponent } from './custom-promeclub-order-details-total/custom-promeclub-order-details-total.component';
import { CustomPromeclubOrderDetailsComponent } from './custom-promeclub-order-details.component';
import { CustomPromeclubOrderEntryItemComponent } from './custom-promeclub-order-entry-item/custom-promeclub-order-entry-item.component';
import { CustomPromeclubOrderEntryItemsContainerComponent } from './custom-promeclub-order-entry-items-container/custom-promeclub-order-entry-items-container.component';
import { CustomPromeclubOrderOverviewComponent } from './custom-promeclub-order-overview/custom-promeclub-order-overview.component';



@NgModule({
  declarations: [
    CustomPromeclubOrderDetailsComponent,
    CustomPromeclubOrderOverviewComponent,
    CustomPromeclubOrderEntryItemComponent,
    CustomPromeclubOrderEntryItemsContainerComponent,
    CustomPromeclubOrderDetailsTotalComponent
  ],
  imports: [
    CommonModule,
    CardModule,
    I18nModule,
    IconModule,
    CustomCurrencyModule,
    CustomOrderDetailItemsModule,
    RouterModule,
    UrlModule,
    CustomPaginationModule,
    CustomTitleMyAccountModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromeclubOrderDetailsComponent: {
          component: CustomPromeclubOrderDetailsComponent,
        },
      },
    }),
  ]
})
export class CustomPromeclubOrderDetailsModule { }
