export const address = {
  addressForm: {
    title: 'Libreta de direcciones',
    defaultTitle: 'Libreta de direcciones',
    manageDelivery: 'Encargados del retiro',
    firstName: {
      label: 'Nombres y apellidos:',
      placeholder: 'Nombres y apellidos',
      tooltip: 'Ingresa los nombres y apellidos como consta en la cédula'
    },
    lastName: {
      label: 'Apellido:',
      placeholder: 'Apellido',
      tooltip: 'Ingresa los apellidos como consta en la cédula'
    },
    identificationCard: {
      label: 'Cédula:',
      placeholder: '1234567890',
    },
    licensePlate: {
      label: 'Placa del vehículo:',
      placeholder: 'AAB1234',
      resume: 'Placa:'
    },
    addManage: 'Agregar nuevo encargado de retiro',
    editManage: 'Editar encargado de retiro',
    addManageButton: 'Agregar encargado de retiro',
    address1: 'Dirección 1',
    address2: 'Dirección 2 (opcional)',
    country: 'País',
    city: {
      label: 'Localidad',
      placeholder: 'Ingresá una localidad',
    },
    state: 'Estado',
    zipCode: {
      label: 'Código postal',
      placeholder: 'Código Postal / ZIP',
    },
    phoneNumber: {
      label: 'Número de teléfono (opcional)',
      placeholder: '(555) 555 - 0123',
    },
    saveAsDefault: 'Guardar por defecto',
    chooseAddress: 'Elegir dirección',
    streetAddress: 'Calle',
    aptSuite: 'Departamento',
    selectOne: 'Seleccione uno...',
    setAsDefault: 'Seleccionar como dirección principal',
    titleRequired: 'Título obligatorio.',
    userAddressAddSuccess: '¡La nueva dirección se agregó correctamente!',
    userAddressUpdateSuccess: '¡Dirección actualizada correctamente!',
    userAddressDeleteSuccess: '¡Dirección eliminada correctamente!',
    invalidAddress: 'Dirección inválida',
  },
  addressBook: {
    addNewShippingAddress: 'Agregar una nueva dirección de envío',
    editShippingAddress: 'Editar dirección de envío',
    areYouSureToDeleteAddress:
      '¿Está seguro de que desea eliminar esta dirección?',
    addNewAddress: 'Agregar nueva dirección',
    addAddress: 'Agregar dirección',
    updateAddress: 'Actualizar dirección',
    backToAddressList: 'Volver a la lista de direcciones',
  },
  addressCard: {
    default: 'PREDETERMINADO',
    selected: 'Seleccionado',
    setAsDefault: 'Establecer como predeterminado',
    shipTo: 'Dirección de envío',
    billTo: 'Cobrar a',
  },
  addressSuggestion: {
    verifyYourAddress: 'Verifica tu direccion',
    ensureAccuracySuggestChange:
      'Para garantizar la precisión de la entrega, sugerimos el cambio seleccionado a continuación.',
    chooseAddressToUse: 'Elija la dirección que desea utilizar:',
    suggestedAddress: 'Dirección sugerida',
    enteredAddress: 'Dirección ingresada',
    editAddress: 'Editar dirección',
    saveAddress: 'Guardar dirección',
  },
};
