<ng-container *ngIf="pageCurrent$ | async as pageCurrent">
  <ng-container *ngIf="item && refundsReasons">
    <form [formGroup]="itemReturnForm" class="custom-return-product-item__form">
      <ng-container *ngIf="!(isMobile$ | async); else cardReturnMobile">
        <div
          class="custom-return-product-item"
          [ngClass]="{
            'custom-return-product-item-revision':
              currentPage == 'REVISION_ORDER_RETURN'
          }"
        >
          <div class="custom-return-product-item__image-info">
            <div class="custom-return-product-item__image form-check">
              <input
                *ngIf="
                  currentPage == 'EDIT_ORDER_RETURN' &&
                  item?.product?.productType != EcvProductType
                "
                class="form-check-input"
                type="checkbox"
                (change)="enableOption()"
                id="itemSelected"
                formControlName="itemSelected"
              />
              <cx-media
                *ngIf="item.product?.images?.PRIMARY"
                [container]="item.product?.images?.PRIMARY"
                format="product"
                role="presentation"
              ></cx-media>
              <cx-media
                *ngIf="!item.product?.images?.PRIMARY"
                [container]="item.product?.images"
                format="product"
                role="presentation"
              ></cx-media>
            </div>
            <div class="custom-return-product-item__info">
              <p class="custom-return-product-item__info-brand">
                {{ item.product?.brand?.name }}
              </p>
              <p class="custom-return-product-item__info-name">
                {{ item.product?.name | titlecase }}
              </p>
              <div
                *ngIf="item.product?.code"
                class="custom-return-product-item__info-code"
              >
                {{ "cartItems.id" | cxTranslate }} {{ item.product?.code }}
              </div>
            </div>
          </div>

          <div
            class="custom-return-product-item__quantity"
            *ngIf="currentPage == 'EDIT_ORDER_RETURN'"
          >
            <label class="custom-return-product-item__quantity-value">{{
              item.quantity
            }}</label>
          </div>

          <div
            class="custom-return-product-item__quantity"
            *ngIf="
              currentPage == 'REVISION_ORDER_RETURN' &&
              pageCurrent !== 'ReturnDetailOrderPage'
            "
          >
            <label class="custom-return-product-item__quantity-value">{{
              itemReturnForm.controls["quantity"].value
            }}</label>
          </div>

          <div
            class="custom-return-product-item__quantity"
            *ngIf="pageCurrent == 'ReturnDetailOrderPage'"
          >
            <label class="custom-return-product-item__quantity-value">{{
              expectedQuantityReturn
            }}</label>
          </div>

          <div
            class="custom-return-product-item__quantity-counter"
            *ngIf="
              currentPage == 'EDIT_ORDER_RETURN' &&
              pageCurrent !== 'ReturnDetailOrderPage'
            "
          >
            <div class="quantity-container">
              <app-custom-item-counter
                [productStockLevel]="item?.returnableQuantity"
                [min]="0"
                [allowZero]="false"
                [max]="item?.returnableQuantity"
                [control]="itemReturnForm.get('quantity')"
                [ngClass]="{
                  'with-error':
                    itemReturnForm.get('quantity').invalid ||
                    itemReturnForm.get('quantity').value >
                      item.returnableQuantity ||
                    itemReturnForm.get('quantity').value == 0
                }"
                [blurEvent]="true"
                (blurCounter)="sendQuantityReturn(false)"
              >
              </app-custom-item-counter>
              <span class="custom-return-product-item__quantity-counter-maxim">
                {{
                  "returnRequest.maxQuantityReturn"
                    | cxTranslate : { count: item.returnableQuantity }
                }}
              </span>
            </div>
          </div>

          <div class="custom-return-product-item__price">
            <label class="custom-return-product-item__price-value">{{
              item.basePrice?.value | customCurrency
            }}</label>
          </div>

          <div class="custom-return-product-item__reason">
            <ng-select
              *ngIf="currentPage == 'EDIT_ORDER_RETURN'"
              id="reasonReturn"
              formControlName="reasonReturn"
              [searchable]="false"
              [clearable]="false"
              (change)="sendDataProductReturn(true, $event)"
              placeholder="{{
                'returnRequest.placeholderReason' | cxTranslate
              }}"
              required
              dropdownPosition="bottom"
            >
              <div *ngFor="let option of refundsReasons">
                <ng-option *ngIf="!option.name" [value]="option">
                  <span [ngClass]="{ 'gray-option': option.grayOut }">{{
                    option
                  }}</span>
                </ng-option>
                <ng-option
                  [disabled]="option.grayOut"
                  *ngIf="option.name"
                  [value]="option.code"
                >
                  <span [ngClass]="{ 'gray-option': option.grayOut }">{{
                    option.name
                  }}</span>
                </ng-option>
              </div>
            </ng-select>

            <div
              class="custom-return-product-item__reason-revision"
              *ngIf="currentPage == 'REVISION_ORDER_RETURN'"
            >
              <label
                class="custom-return-product-item__reason-revision-value"
                >{{ reasonSelected.name }}</label
              >
            </div>
          </div>
          <ng-container
            *ngIf="{ valueReason: getValueReasonBoolean() | async } as value"
          >
            <div
              class="custom-return-product-item__images"
              [ngClass]="{
                'images-disabled':
                  !value?.valueReason ||
                  itemReturnForm.get('images')['controls']?.length > 2,
                'custom-return-product-item__images-product':
                  currentPage == 'EDIT_ORDER_RETURN',
                'custom-return-product-item__images-big-area':
                  currentPage == 'EDIT_ORDER_RETURN' &&
                  itemReturnForm.get('images')['controls']?.length > 2
              }"
            >
              <ng-container
                formArrayName="images"
                *ngIf="itemReturnForm.get('images')['controls']?.length > 0"
              >
                <div class="custom-return-product-item__labels">
                  <div
                    class="custom-return-product-item__labels-images"
                    *ngFor="
                      let image of itemReturnForm.get('images')['controls'];
                      let i = index
                    "
                    [formGroupName]="i"
                  >
                    <p>{{ image?.value?.files?.name }}</p>

                    <button
                      type="button"
                      (click)="onRemoveImage(image?.value?.images, i)"
                      *ngIf="currentPage == 'EDIT_ORDER_RETURN'"
                    >
                      <cx-icon type="CLOSE_CIRCLE"></cx-icon>
                    </button>
                  </div>
                </div>
              </ng-container>

              <label *ngIf="currentPage == 'EDIT_ORDER_RETURN'">
                <cx-icon type="FILE_ICON"></cx-icon>
                <span>
                  {{ "returnRequest.attachImage" | cxTranslate }}
                </span>
                <form [formGroup]="inputFileForm">
                  <input
                    type="file"
                    class="custom-return-product-item__images-product-input"
                    [ngClass]="{
                      'input-labels':
                        itemReturnForm.get('images')['controls']?.length > 0
                    }"
                    (change)="getFile($event)"
                    [accept]="acceptFile"
                    formControlName="inputFile"
                  />
                </form>
              </label>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #cardReturnMobile>
        <div
          class="custom-return-product-item"
          [ngClass]="{
            'custom-return-product-item-revision':
              currentPage == 'REVISION_ORDER_RETURN',
            'custom-return-product-item__extended':
              itemReturnForm.controls['itemSelected'].value == true,
            'custom-return-product-item__extended-images':
              itemReturnForm.controls['itemSelected'].value == true &&
              itemReturnForm.get('images')['controls'] > 0
          }"
        >
          <div class="custom-return-product-item__image form-check">
            <input
              *ngIf="
                currentPage == 'EDIT_ORDER_RETURN' &&
                item?.product?.productType != EcvProductType
              "
              class="form-check-input"
              type="checkbox"
              (change)="enableOption()"
              id="itemSelected"
              formControlName="itemSelected"
            />
            <cx-media
              *ngIf="item.product?.images?.PRIMARY"
              [container]="item.product?.images?.PRIMARY"
              format="product"
              role="presentation"
            ></cx-media>
            <cx-media
              *ngIf="!item.product?.images?.PRIMARY"
              [container]="item.product?.images"
              format="product"
              role="presentation"
            ></cx-media>
          </div>

          <div class="custom-return-product-item__info_ppal">
            <div class="custom-return-product-item__info">
              <p class="custom-return-product-item__info-brand">
                {{ item.product?.brand?.name }}
              </p>
              <p class="custom-return-product-item__info-name">
                {{ item.product?.name | titlecase }}
              </p>
              <div
                *ngIf="item.product?.code"
                class="custom-return-product-item__info-code"
              >
                {{ "cartItems.id" | cxTranslate }} {{ item.product?.code }}
              </div>
            </div>

            <div
              class="custom-return-product-item__quantity-price"
              *ngIf="currentPage == 'EDIT_ORDER_RETURN'"
            >
              <div class="custom-return-product-item__quantity">
                <label class="custom-return-product-item__quantity-label">
                  {{ "returnRequest.recived" | cxTranslate }}
                </label>
                <label class="custom-return-product-item__quantity-value">{{
                  item.quantity
                }}</label>
              </div>

              <div class="custom-return-product-item__quantity-counter">
                <label
                  class="custom-return-product-item__quantity-counter-label"
                >
                  {{ "returnRequest.toDevolutions" | cxTranslate }}
                </label>
                <div class="quantity-container">
                  <app-custom-item-counter
                    [productStockLevel]="item.returnableQuantity"
                    [min]="
                      item.returnableQuantity > 1 ? 1 : item.returnableQuantity
                    "
                    [max]="item.returnableQuantity"
                    [control]="itemReturnForm.get('quantity')"
                    [ngClass]="{
                      'with-error':
                        itemReturnForm.get('quantity').invalid ||
                        itemReturnForm.get('quantity').value >
                          item.returnableQuantity
                    }"
                    [blurEvent]="true"
                    (blurCounter)="sendDataProductReturn(false)"
                  >
                  </app-custom-item-counter>
                  <span
                    class="custom-return-product-item__quantity-counter-maxim"
                  >
                    {{
                      "returnRequest.maxQuantityReturn"
                        | cxTranslate : { count: item.returnableQuantity }
                    }}
                  </span>
                </div>
              </div>

              <div class="custom-return-product-item__price">
                <label class="custom-return-product-item__price-label">
                  {{ "returnRequest.unitPrice" | cxTranslate }}
                </label>
                <label class="custom-return-product-item__price-value">{{
                  item.basePrice?.value | customCurrency
                }}</label>
              </div>
            </div>

            <div
              class="custom-return-product-item__quantity-price"
              *ngIf="
                currentPage == 'REVISION_ORDER_RETURN' &&
                pageCurrent !== 'ReturnDetailOrderPage'
              "
            >
              <div class="custom-return-product-item__quantity">
                <label class="custom-return-product-item__quantity-label">
                  {{ "returnRequest.devolutionTitle" | cxTranslate }}
                </label>
                <label class="custom-return-product-item__quantity-value">{{
                  itemReturnForm.controls["quantity"].value
                }}</label>
              </div>

              <div class="custom-return-product-item__price">
                <label class="custom-return-product-item__price-label">
                  {{ "returnRequest.unitPriceMobile" | cxTranslate }}
                </label>
                <label class="custom-return-product-item__price-value">{{
                  item.basePrice?.value | customCurrency
                }}</label>
              </div>
            </div>

            <div
              class="custom-return-product-item__quantity-price"
              *ngIf="
                currentPage == 'REVISION_ORDER_RETURN' &&
                pageCurrent == 'ReturnDetailOrderPage'
              "
            >
              <div class="custom-return-product-item__quantity">
                <label class="custom-return-product-item__quantity-label">
                  {{ "returnRequest.devolutionTitle" | cxTranslate }}
                </label>
                <label class="custom-return-product-item__quantity-value">{{
                  expectedQuantityReturn
                }}</label>
              </div>

              <div class="custom-return-product-item__price">
                <label class="custom-return-product-item__price-label">
                  {{ "returnRequest.unitPriceMobile" | cxTranslate }}
                </label>
                <label class="custom-return-product-item__price-value">{{
                  item.basePrice?.value | customCurrency
                }}</label>
              </div>
            </div>

            <div
              *ngIf="
                itemReturnForm.controls['itemSelected'].value == true ||
                currentPage == 'REVISION_ORDER_RETURN'
              "
              class="custom-return-product-item__reason-image"
            >
              <div *ngIf="currentPage == 'EDIT_ORDER_RETURN'">
                <div class="custom-return-product-item__reason-label text-left">
                  {{ "returnRequest.reasonDevolution" | cxTranslate }}
                </div>
                <div class="custom-return-product-item__reason">
                  <ng-select
                    *ngIf="currentPage == 'EDIT_ORDER_RETURN'"
                    id="reasonReturn"
                    formControlName="reasonReturn"
                    [searchable]="false"
                    [clearable]="false"
                    (change)="sendDataProductReturn(true, $event)"
                    placeholder="{{
                      'returnRequest.placeholderReason' | cxTranslate
                    }}"
                    required
                    dropdownPosition="bottom"
                  >
                    <div *ngFor="let option of refundsReasons">
                      <ng-option *ngIf="!option.name" [value]="option">
                        {{ option }}
                      </ng-option>
                      <ng-option *ngIf="option.name" [value]="option.code">
                        {{ option.name }}
                      </ng-option>
                    </div>
                  </ng-select>
                </div>
              </div>

              <div *ngIf="currentPage == 'REVISION_ORDER_RETURN'">
                <div class="custom-return-product-item__reason-revision">
                  <div
                    class="custom-return-product-item__reason-revision-label text-left"
                  >
                    {{ "returnRequest.reasonDevolutionMobile" | cxTranslate }}
                  </div>
                  <label
                    class="custom-return-product-item__reason-revision-value"
                    >{{ reasonSelected.name }}</label
                  >
                </div>
              </div>
              <div>
                <div
                  class="custom-return-product-item__images-product"
                  [ngClass]="{
                    'images-disabled':
                      this.inputFileForm.controls['inputFile'].disabled ||
                      itemReturnForm.get('images')['controls']?.length > 2
                  }"
                >
                  <ng-container
                    formArrayName="images"
                    *ngIf="itemReturnForm.get('images')['controls']?.length > 0"
                  >
                    <div class="custom-return-product-item__labels">
                      <div
                        class="custom-return-product-item__labels-images"
                        *ngFor="
                          let image of itemReturnForm.get('images')['controls'];
                          let i = index
                        "
                        [formGroupName]="i"
                      >
                        <p>{{ image?.value?.files?.name }}</p>

                        <button
                          type="button"
                          (click)="onRemoveImage(image?.value?.images, i)"
                          *ngIf="currentPage == 'EDIT_ORDER_RETURN'"
                        >
                          <cx-icon type="CLOSE_CIRCLE"></cx-icon>
                        </button>
                      </div>
                    </div>
                  </ng-container>
                  <div
                    class="custom-return-product-item__images-product-label text-left"
                    *ngIf="currentPage == 'REVISION_ORDER_RETURN'"
                  >
                    {{ "returnRequest.productImagesMobile" | cxTranslate }}
                  </div>
                  <label *ngIf="currentPage == 'EDIT_ORDER_RETURN'">
                    <cx-icon type="FILE_ICON"></cx-icon>
                    <span>
                      {{ "returnRequest.attachImage" | cxTranslate }}
                    </span>
                    <form [formGroup]="inputFileForm">
                      <input
                        type="file"
                        class="custom-return-product-item__images-product-input"
                        [ngClass]="{
                          'input-labels':
                            itemReturnForm.get('images')['controls']?.length > 0
                        }"
                        (change)="getFile($event)"
                        [accept]="acceptFile"
                        formControlName="inputFile"
                      />
                    </form>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </form>
  </ng-container>
</ng-container>
