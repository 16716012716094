
<ng-container *ngIf="registerLinks$ | async as links$; else loading">
  <div class="register-links">

    <h3 class="register-links__title">{{'customRegister.title' | cxTranslate}}</h3>
    <div class="register-links__action">


        <button
          *ngIf="(links$[1] | async) as linkNonClient"
          class="register-links__action-button-secondary"
          [routerLink]="linkNonClient?.url"
        >
          <span>{{'customRegister.noCustomer' | cxTranslate}}</span>
          <cx-icon class="fas fa-chevron-right"></cx-icon>
        </button>
        <button
          *ngIf="(links$[0] | async) as linkClient"
          class="register-links__action-button-primary"
          [routerLink]="linkClient?.url"
        >
          <span>{{'customRegister.customer' | cxTranslate}}</span>
          <cx-icon class="fas fa-chevron-right"></cx-icon>
        </button>


  </div>

  </div>
</ng-container>

<ng-template #loading>
  <div class="register-links">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
