<ng-container
  *ngIf="{ cart: cart$ | async, isMobile: isMobile$ | async } as data"
>
  <div
    class="cx-add-to-saved-cart-container"
    [ngClass]="{ promeclub: isInPromeclub }"
    *ngIf="data.cart.entries?.length > 0"
  >
    <button
      *ngIf="!isInPromeclub"
      #newCartElement
      class="link cx-action-link button-save-cart"
      (click)="saveCart(data.cart)"
    >
      <cx-icon class="far fa-bookmark" *ngIf="data.isMobile"></cx-icon>
      <span>{{ "addToSavedCart.savedCarts" | cxTranslate }}</span>
      <cx-icon class="far fa-bookmark" *ngIf="!data.isMobile"></cx-icon>
    </button>

    <button
      class="link cx-action-link empty-cart"
      (click)="removeCart(data.cart.code)"
    >
      <cx-icon class="far fa-trash-alt" *ngIf="data.isMobile"></cx-icon>
      <span>{{ "saveForLaterItems.emptyCart" | cxTranslate }}</span>
      <cx-icon class="far fa-trash-alt" *ngIf="!data.isMobile"></cx-icon>
    </button>
  </div>
</ng-container>
