import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  CmsComponent,
  CmsResponsiveBannerComponentMedia,
  CmsService
} from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomCmsSqareListComponent } from './model/custom-square-list';

@Component({
  selector: 'app-custom-square-item-list',
  templateUrl: './custom-square-item-list.component.html',
  styleUrls: ['./custom-square-item-list.component.scss'],
})
export class PromesaSquareItemListComponent implements OnInit {
  componentData$: Observable<CustomCmsSqareListComponent> =
    this.component.data$;
  items$: Observable<Observable<any>[]>;
  isInWarrantyPage: boolean;
  constructor(
    public component: CmsComponentData<CustomCmsSqareListComponent>,
    protected cmsService: CmsService,
    protected customBreakpoinService: CustomBreakpointService,
    protected el: ElementRef,
    protected router?: Router
  ) {}

  ngOnInit(): void {
    this.items$ = this.componentData$.pipe(
      map((data) => {
        this.isInWarrantyPage = data.uid === 'warrantySqareListComponent';
        if (this.isInWarrantyPage) {
          this.el?.nativeElement?.classList?.add('warranty');
        }
        return data.items.trim().split(' ');
      }),
      map((codes) =>
        codes.map((code) =>
          this.cmsService.getComponentData(code).pipe(
            map((bannerData: CmsComponent | null) => ({
              ...bannerData,
              image: this.customBreakpoinService?.getImageByBreakpoint(
                <CmsResponsiveBannerComponentMedia>bannerData
              ),
            }))
          )
        )
      )
    );
  }
}
