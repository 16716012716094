import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomWishlistComponent } from './custom-wishlist.component';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import {
  ItemCounterModule,
  MediaModule,
  StarRatingModule,
  IconModule,
} from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { CustomSafeHtmlModule } from 'src/app/pipes/custom-safe-html/custom-safe-html.module';
import { CustomWishlistHeaderComponent } from './custom-wishlist-header/custom-wishlist-header.component';
import { CustomEmptyCartModule } from '../custom/components/custom-empty-cart/custom-empty-cart.module';
import { CustomAddToCartModule } from '../cart/custom-add-to-card/custom-add-to-cart.module';
import { CustomWishlistItemComponent } from './custom-wishlist-item/custom-wishlist-item.component';
import { CustomItemCounterModule } from '../shared/components/custom-item-counter/custom-item-counter.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomLoadingSpinnerModule } from '../custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomPreAddToCartModule } from '../cart/custom-add-to-card/custom-pre-add-to-cart/custom-pre-add-to-cart.module';
import { CustomGiftProductModule } from '../custom/components/custom-gift-product/custom-gift-product.module';

@NgModule({
  declarations: [
    CustomWishlistComponent,
    CustomWishlistHeaderComponent,
    CustomWishlistItemComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    MediaModule,
    RouterModule,
    StarRatingModule,
    UrlModule,
    ItemCounterModule,
    CustomSafeHtmlModule,
    CustomEmptyCartModule,
    CustomAddToCartModule,
    CustomItemCounterModule,
    IconModule,
    ReactiveFormsModule,
    CustomLoadingSpinnerModule,
    CustomPreAddToCartModule,
    CustomGiftProductModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        WishListComponent: {
          component: CustomWishlistComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ],
})
export class CustomWishlistModule {}
