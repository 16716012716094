<div
  class="variant-section"
  [ngClass]="{
    promeclub: isPromeclub$ | async,
    variants: data?.simpleVariantSelectors?.length > 0
  }"
  *ngIf="{
    simpleVariantSelectors: simpleVariantSelectors$ | async,
    isMobile: isMobile$ | async,
    product: customProduct$ | async
  } as data"
>
  <ng-container *ngFor="let variantSelector of data.simpleVariantSelectors">
    <div class="variant-section__selector">
      <h4
        class="variant-section__selector-title"
        *ngIf="!fromCartItem && !data.isMobile"
      >
        {{ variantSelector?.variantSelectors?.length }}
        {{ variantSelector?.variantCategory?.name | lowercase }}
        {{
          variantSelector?.variantSelectors?.length > 1
            ? ("productDetails.pluralAvailable" | cxTranslate | lowercase)
            : ("productDetails.available" | cxTranslate | lowercase)
        }}
      </h4>
      <h4
        class="variant-section__selector-title"
        *ngIf="fromCartItem || data.isMobile"
      >
        {{ variantSelector?.variantCategory?.name }}
      </h4>
      <div
        class="variant-section__selector__value"
        [ngClass]="{
          'bullet': getIsVarintWithColorBullet(variantSelector?.variantSelectors?.[0].variantValueCategory),
          'see-more': seeMoreWithBullet
        }"
      >
        <ng-container
          *ngFor="
            let variantValueSelector of variantSelector?.variantSelectors;
            index as i
          "
        >
          <ng-container
            *ngIf="
              !getIsVarintWithColorBullet(
                variantValueSelector?.variantValueCategory
              );
              else bullet
            "
          >
            <ng-container *ngIf="!fromCartItem; else fromCartItemTemplate">
              <button
                *ngIf="(seeMore && i < (data.isMobile ? 11 : 19)) || !seeMore"
                class="variant-section__selector__value-button"
                [ngClass]="{
                  selected: variantValueSelector?.selected,
                  disabled: !variantValueSelector?.product
                }"
                [routerLink]="variantValueSelector?.product?.url"
                [disabled]="!variantValueSelector?.product"
              >
                {{ variantValueSelector?.variantValueCategory?.name }}
              </button>
            </ng-container>
            <ng-template #fromCartItemTemplate>
              <button
                *ngIf="(seeMore && i < (data.isMobile ? 11 : 19)) || !seeMore"
                class="variant-section__selector__value-button"
                [ngClass]="{
                  selected: variantValueSelector?.selected,
                  disabled: !variantValueSelector?.product
                }"
                (click)="
                  setProductVariantCode(variantValueSelector?.product?.code)
                "
                [disabled]="!variantValueSelector?.product"
              >
                {{ variantValueSelector?.variantValueCategory?.name }}
              </button>
            </ng-template>

            <p
              (click)="toggleSeeMore()"
              *ngIf="
                (seeMore && i === 19) ||
                (!seeMore &&
                  i === variantSelector?.variantSelectors.length - 1 &&
                  variantSelector?.variantSelectors.length >
                    (data.isMobile ? 12 : 20))
              "
              class="see-all"
            >
              {{
                seeMore
                  ? ("productDetails.seeAll" | cxTranslate)
                  : ("productDetails.seeLess" | cxTranslate)
              }}
            </p>
          </ng-container>

          <ng-template #bullet>
            <button
              *ngIf="
                (seeMoreWithBullet && i < 11 && !fromCartItem) ||
                (!seeMoreWithBullet && !fromCartItem)
              "
              class="variant-section__selector__value-button"
              [ngClass]="{
                selected: variantValueSelector?.selected,
                disabled: !variantValueSelector?.product,
                bullet: variantValueSelector?.variantValueCategory?.colorHexCode
              }"
              [routerLink]="variantValueSelector?.product?.url"
              [disabled]="!variantValueSelector?.product"
            >
              <span
                class="variant-section__selector__value-button__color"
                [ngStyle]="
                  variantValueSelector?.variantValueCategory?.colorHexCode && {
                    backgroundColor:
                      variantValueSelector?.variantValueCategory?.colorHexCode
                  }
                "
              ></span>
              <span
                class="variant-section__selector__value-button__text"
                *ngIf="!data.isMobile"
              >
                {{ variantValueSelector?.variantValueCategory?.name }}
              </span>
            </button>
            <button
              *ngIf="
                (seeMoreWithBullet && i < 11 && fromCartItem) ||
                (!seeMoreWithBullet && fromCartItem)
              "
              class="variant-section__selector__value-button"
              [ngClass]="{
                selected: variantValueSelector?.selected,
                disabled: !variantValueSelector?.product,
                bullet: variantValueSelector?.variantValueCategory?.colorHexCode
              }"
              (click)="
                setProductVariantCode(variantValueSelector?.product?.code)
              "
              [disabled]="!variantValueSelector?.product"
            >
              <span
                class="variant-section__selector__value-button__color"
                [ngStyle]="
                  variantValueSelector?.variantValueCategory?.colorHexCode && {
                    backgroundColor:
                      variantValueSelector?.variantValueCategory?.colorHexCode
                  }
                "
              ></span>
            </button>
            <p
              (click)="toggleSeeMoreWithBullet()"
              *ngIf="
                (seeMoreWithBullet && i === 11) ||
                (!seeMoreWithBullet &&
                  i === variantSelector?.variantSelectors.length - 1 &&
                  variantSelector?.variantSelectors.length > 12)
              "
              class="see-all"
            >
              {{
                seeMoreWithBullet
                  ? ("productDetails.seeAll" | cxTranslate)
                  : ("productDetails.seeLess" | cxTranslate)
              }}
            </p>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div
    class="product-share"
    *ngIf="
      (!fromCartItem && data.isMobile) ||
      (!data.isMobile && data?.product?.simpleVariantSelectors?.length > 0)
    "
  >
    <app-custom-product-social-share
      [product]="data?.product"
      [isMobile]="data?.isMobile"
    ></app-custom-product-social-share>
  </div>
  <div class="fav-mobile" *ngIf="(isPromeclub$ | async) && data.isMobile">
    <app-custom-wishlist
      [product]="data?.product"
      [userId]="userId$ | async"
      [isUserLoggedIn]="true"
      [isMobilePdp]="true"
    >
    </app-custom-wishlist>
  </div>
</div>
