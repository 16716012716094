<!-- Item Start Outlet -->
<ng-container
  *ngIf="{
    message: promesaCartUpdatedPriceMessageComponentData$ | async,
    clickedFrom: clickedFrom$ | async
  } as cartMessageData"
>
  <ng-template [cxOutlet]="CartOutlets.ITEM">
    <ng-container *cxFeatureLevel="'4.2'">
      <app-custom-cart-item-validation-warning
        [code]="item.product?.code"
      ></app-custom-cart-item-validation-warning>
    </ng-container>

    <div
      class="cart-item-gift"
      *ngIf="{
        isMobile: isMobile$ | async,
        nameGift: nameGift$ | async
      } as data"
    >
      <div
        class="cart-item"
        [ngClass]="{ 'cart-item__only': !data?.nameGift?.name }"
      >
        <ng-container *ngIf="!data.isMobile; else mobileCartItem">
          <div class="cart-item__rebate-icon">
            <div class="icon_rappel">
              <cx-icon
                *ngIf="item.hasRappel && item?.product?.productType != 'ZPOP'"
                type="TAG_BLUE"
              ></cx-icon>
            </div>
            <cx-generic-link
              [url]="
                item?.product?.productType != EcvProductType
                  ? item.product?.url
                  : null
              "
              class="cart-item__image"
              (click)="productClickEvent(cartMessageData?.clickedFrom)"
            >
              <cx-media
                *ngIf="item.product.images?.PRIMARY"
                [container]="item.product.images?.PRIMARY"
                format="mobile"
              >
              </cx-media>
            </cx-generic-link>
          </div>

          <div
            class="cart-item sku-cart"
            [ngClass]="{
              'sku-cart': !isInPromeclub,
              'sku-cart-promeclub': isInPromeclub
            }"
          >
            <p
              *ngIf="item.product.code"
              class="cart-item"
              [ngClass]="{
                'sku-cart-value': !isInPromeclub,
                'sku-cart-promeclub-value': isInPromeclub
              }"
            >
              {{
                !isInPromeclub
                  ? item?.product?.code
                  : item?.product?.promotickWebCode
              }}
            </p>
          </div>
          <cx-generic-link
            [url]="
              item?.product?.productType != EcvProductType
                ? item.product?.url
                : null
            "
            class="cart-item"
            *ngIf="item.product.name"
            [ngClass]="{
              'name-cart': !isInPromeclub,
              'name-cart-promeclub': isInPromeclub
            }"
            (click)="productClickEvent(cartMessageData?.clickedFrom)"
          >
            <h3
              class="cart-item"
              [ngClass]="{
                'name-cart-value': !isInPromeclub,
                'name-cart-promeclub-value': isInPromeclub
              }"
            >
              {{ item.product.name }}
            </h3>
          </cx-generic-link>
          <div class="cart-item__unity" *ngIf="!isInPromeclub">
            <p class="cart-item__unity-value">
              {{ "cartItems.unity" | cxTranslate
              }}{{ item.product?.unit?.toLocaleString() }}
            </p>
          </div>
          <div
            class="cart-item__price"
            [ngClass]="{
              'price-message': cart?.modifiedPreviousPriceMap.length > 0,
              'price-promeclub': isInPromeclub
            }"
          >
            <p
              *ngIf="item.basePrice && !outOfStock"
              class="cart-item__price-value"
              [ngClass]="{
                'cart-item__price-value-promeclub': isInPromeclub,
                center: item.basePrice?.value === 0 && !isInPromeclub
              }"
            >
              <ng-container *ngIf="!isInPromeclub">
                {{
                  item.basePrice?.value !== 0
                    ? (item.basePrice?.value | customCurrency)
                    : "-"
                }}
              </ng-container>
              <ng-container *ngIf="isInPromeclub">
                {{
                  item.basePrice?.value !== 0
                    ? (item.basePrice?.value | number : "1.0-0" : "es-AR")
                    : "-"
                }}
                <span class="regular-points">{{
                  "cartItems.points" | cxTranslate
                }}</span>
              </ng-container>
            </p>
            <p
              *ngIf="outOfStock"
              class="cart-item__price-value"
              [ngClass]="{ 'cart-item__price-value-promeclub': isInPromeclub }"
            >
              -
            </p>
            <ng-container
              *ngIf="
                cart &&
                cart?.modifiedPreviousPriceMap.length > 0 &&
                !isInPromeclub
              "
            >
              <ng-container
                *ngFor="
                  let previousPrice of cart.modifiedPreviousPriceMap;
                  let i = index
                "
              >
                <div class="cart-item__price-value-message">
                  <div
                    class="cart-item__price-value-message__tooltip"
                    *ngIf="item.product.code === previousPrice.key"
                  >
                    <cx-icon
                      class="fas fa-info-circle"
                      ngbTooltip="{{
                        cartMessageData?.message?.previousPrice +
                          ' ' +
                          (previousPrice?.value | customCurrency)
                      }}"
                      placement="top"
                      tooltipClass="tooltip-color"
                    ></cx-icon>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>

          <ng-container
            *ngIf="outOfStock || item?.product?.productType == EcvProductType"
          >
            <div class="cart-item__quantity">
              <div class="custom-counter-fake">
                <button disabled="true">-</button>
                <input
                  type="text"
                  [value]="
                    item?.product?.productType == EcvProductType
                      ? item.quantity
                      : '-'
                  "
                  disabled="true"
                />
                <button disabled="true">+</button>
              </div>
              <div class="cart-item__quantity-action">
                {{ "cartItems.update" | cxTranslate }}
              </div>
            </div>
          </ng-container>

          <ng-container
            *ngIf="!outOfStock && item?.product?.productType != EcvProductType"
          >
            <div
              class="cart-item__quantity"
              [ngClass]="{
                'pt-0': !item.updateable,
                'quantity-promeclub': isInPromeclub
              }"
            >
              <cx-item-counter
                [control]="quantityControl"
                [readonly]="
                  !item.updateable || readonly || options.isSaveForLater
                "
                [max]="item.product.stock?.stockLevel"
                [allowZero]="true"
                (click)="setIsUpdated()"
              >
              </cx-item-counter>
              <span
                *ngIf="item.updateable && !isUpdated"
                class="cart-item__quantity-action"
                (click)="emitUpdate(quantityControl?.value != item?.quantity)"
                [ngClass]="{
                  changed: quantityControl?.value != item?.quantity
                }"
              >
                {{ "cartItems.update" | cxTranslate }}
              </span>
              <span
                *ngIf="isUpdated"
                class="cart-item__quantity-availableStock"
              >
                {{
                  "addToCart.availableStock"
                    | cxTranslate : { stock: item.product.stock?.stockLevel }
                }}</span
              >
            </div>
          </ng-container>
          <div
            class="cart-item"
            [ngClass]="{
              total: !isInPromeclub,
              'total-promeclub': isInPromeclub
            }"
          >
            <p
              *ngIf="item.totalPrice && !outOfStock"
              class="cart-item total-value"
              [ngClass]="{
                'cart-item total-value_free': item.totalPrice.value === 0
              }"
            >
              <ng-container *ngIf="!isInPromeclub">
                {{
                  item.totalPrice.value !== 0
                    ? (item.totalPrice.value | customCurrency)
                    : ("cartItems.freeItem" | cxTranslate)
                }}
              </ng-container>
              <ng-container *ngIf="isInPromeclub">
                {{
                  item.totalPrice.value !== 0
                    ? (item.totalPrice.value | number : "1.0-0" : "es-AR")
                    : ("cartItems.freeItem" | cxTranslate)
                }}
                <span
                  class="regular-points"
                  *ngIf="item.totalPrice.value !== 0"
                  >{{ "cartItems.points" | cxTranslate }}</span
                >
              </ng-container>
            </p>
            <p *ngIf="outOfStock" class="cart-item__total-outOfStock">-</p>
          </div>
          <div class="cart-item__action">
            <cx-icon
              class="far fa-trash-alt"
              (click)="openModal()"
              *ngIf="
                !data?.nameGift?.name &&
                item.totalPrice.value !== 0 &&
                item?.product?.productType != EcvProductType
              "
            ></cx-icon>
          </div>
        </ng-container>
      </div>

      <div class="cart-item-gift__label" *ngIf="outOfStock && !data.isMobile">
        <app-custom-gift-product
          label="{{ 'addToCart.outOfStock' | cxTranslate }}"
        ></app-custom-gift-product>
      </div>

      <div
        class="cart-item-gift__label"
        *ngIf="item.promotions[0]?.description && !outOfStock && !data.isMobile"
      >
        <app-custom-gift-product
          label="{{ item.promotions[0].description }}"
        ></app-custom-gift-product>
      </div>

      <div
        class="cart-item-gift__label"
        *ngIf="
          isUpdated &&
          !data.isMobile &&
          !outOfStock &&
          !item.promotions[0]?.description
        "
      >
        <app-custom-gift-product
          label="{{ 'addToCart.updated' | cxTranslate }}"
        ></app-custom-gift-product>
      </div>
    </div>

    <ng-template #mobileCartItem>
      <div class="cart-mobile-row">
        <div class="mobile-row">
          <cx-generic-link
            [url]="
              item?.product?.productType != EcvProductType
                ? item.product?.url
                : null
            "
            [ngClass]="{
              'mobile-row__image': item.product.images?.PRIMARY,
              'mobile-row__image-empty': !item.product.images?.PRIMARY
            }"
            (click)="productClickEvent(cartMessageData?.clickedFrom)"
          >
            <div
              *ngIf="item?.product?.productType != 'ZPOP'"
              class="rebate-icon"
            >
              <cx-icon *ngIf="item.hasRappel" type="TAG_BLUE"></cx-icon>
            </div>
            <cx-media
              *ngIf="item.product.images?.PRIMARY"
              [routerLink]="
                item?.product?.productType != EcvProductType
                  ? item.product?.url
                  : null
              "
              [container]="item.product.images?.PRIMARY"
              format="mobile"
            >
            </cx-media>
          </cx-generic-link>
          <div
            class="mobile-row__quantity"
            [ngClass]="{ free: item?.totalPrice?.value === 0 }"
          >
            <ng-container
              *ngIf="outOfStock || item?.product?.productType == EcvProductType"
            >
              <div class="custom-counter-fake">
                <button disabled="true">-</button>
                <input
                  type="text"
                  [value]="
                    item?.product?.productType == EcvProductType
                      ? item?.quantity
                      : '-'
                  "
                  disabled="true"
                />
                <button disabled="true">+</button>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                !outOfStock && item?.product?.productType != EcvProductType
              "
            >
              <cx-item-counter
                [control]="quantityControl"
                [readonly]="
                  !item.updateable || readonly || options.isSaveForLater
                "
                [max]="item.product.stock?.stockLevel"
                [allowZero]="true"
              >
              </cx-item-counter>
            </ng-container>
            <div *ngIf="isUpdated" class="cart-item__quantity-availableStock">
              {{
                "addToCart.availableStock"
                  | cxTranslate : { stock: item.product.stock?.stockLevel }
              }}
            </div>
          </div>
        </div>
        <div class="mobile-row">
          <cx-generic-link
            [url]="
              item?.product?.productType != EcvProductType
                ? item.product?.url
                : null
            "
            class="mobile-row__info"
            [ngClass]="{
              'mt-0':
                (nameGift$ | async) ||
                item.promotions[0]?.description ||
                outOfStock
            }"
            (click)="productClickEvent(cartMessageData?.clickedFrom)"
          >
            <p
              class="mobile-row__info-brand"
              *ngIf="item?.product?.brand?.name"
            >
              {{ item?.product?.brand?.name }}
            </p>
            <p *ngIf="item.product.code" class="mobile-row__info-sku">
              <ng-container *ngIf="!isInPromeclub">
                {{ "productList.sku" | cxTranslate }}
                {{ item.product.code }}</ng-container
              >
              <ng-container *ngIf="isInPromeclub"
                >{{ "cartDetails.code" | cxTranslate }}
                {{ item?.product?.promotickWebCode }}</ng-container
              >
            </p>
            <h3 class="mobile-row__info-name">
              {{ item.product.name }}
            </h3>
          </cx-generic-link>

          <div class="mobile-row__label" *ngIf="outOfStock">
            <app-custom-gift-product
              label="{{ 'addToCart.outOfStock' | cxTranslate }}"
            ></app-custom-gift-product>
          </div>

          <div
            class="cart-item-gift__label"
            *ngIf="item.promotions[0]?.description && !outOfStock"
          >
            <app-custom-gift-product
              label="{{ item.promotions[0].description }}"
            ></app-custom-gift-product>
          </div>

          <div
            class="mobile-row__label"
            *ngIf="isUpdated && !outOfStock && !item.promotions[0]?.description"
          >
            <app-custom-gift-product
              label="{{ 'addToCart.updated' | cxTranslate }}"
            ></app-custom-gift-product>
          </div>

          <div class="mobile-row__total" [ngClass]="{ updated: isUpdated }">
            <p
              *ngIf="item.totalPrice && !outOfStock"
              class="mobile-row__total-price"
              [ngClass]="{
                'mobile-row__total-price_free': item.totalPrice.value === 0,
                'promotion': item?.promotions?.[0]?.description
              }"
            >
              <ng-container *ngIf="!isInPromeclub">
                <ng-container *ngIf="nameGift$ | async as nameGift">
                  <span class="mobile-row__total-price_free-custom">
                    {{
                      item.totalPrice.value !== 0
                        ? (item.totalPrice.value | customCurrency)
                        : ("cartItems.freeItem" | cxTranslate)
                    }}
                  </span>
                </ng-container>
                <ng-container *ngIf="!(nameGift$ | async)">
                  {{
                    item.totalPrice.value !== 0
                      ? (item.totalPrice.value | customCurrency)
                      : ("cartItems.freeItem" | cxTranslate)
                  }}
                </ng-container>
              </ng-container>
              <ng-container *ngIf="isInPromeclub">
                {{
                  item.totalPrice.value !== 0
                    ? (item.totalPrice.value | number : "1.0-0" : "es-AR")
                    : ("cartItems.freeItem" | cxTranslate)
                }}
                <span class="regular-points">
                  {{ "cartItems.points" | cxTranslate }}</span
                >
              </ng-container>
              <ng-container
                *ngIf="
                  cart &&
                  cart?.modifiedPreviousPriceMap.length > 0 &&
                  !isInPromeclub
                "
              >
                <ng-container
                  *ngFor="
                    let previousPrice of cart.modifiedPreviousPriceMap;
                    let i = index
                  "
                >
                  <div
                    style="display: flex"
                    *ngIf="item.product.code === previousPrice.key"
                  >
                    <cx-icon
                      class="fas fa-exclamation-circle"
                      ngbTooltip="{{
                        cartMessageData?.message?.previousPrice +
                          ' ' +
                          (previousPrice?.value | customCurrency)
                      }}"
                      placement="right"
                      tooltipClass="tooltip-color"
                      triggers="click:blur"
                    ></cx-icon>
                  </div>
                </ng-container>
              </ng-container>
            </p>
            <p *ngIf="outOfStock" class="mobile-row__total-outOfStock">-</p>
          </div>
        </div>
      </div>
      <div class="mobile-row trash">
        <cx-icon
          class="far fa-trash-alt"
          (click)="openModal()"
          *ngIf="
            !(nameGift$ | async) &&
            item.totalPrice.value !== 0 &&
            item?.product?.productType != EcvProductType
          "
        ></cx-icon>
      </div>
    </ng-template>
  </ng-template>
</ng-container>
