import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CheckoutDeliveryFacade } from '@spartacus/checkout/root';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CustomCheckoutDeliveryStepsService } from '../../../services/custom-checkout-delivery-steps.service';
import { CmsService, CmsComponent } from '@spartacus/core';

@Component({
  selector: 'app-custom-center-distribution',
  templateUrl: './custom-center-distribution.component.html',
  styleUrls: ['./custom-center-distribution.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomCenterDistributionComponent implements OnInit {
  centerDistributionForm: FormGroup = this.fb.group({
    addressId: ['', Validators.required],
  });
  centerDistributions = [
    {
      name: 'Centro de Distribución Guayaquil',
      description:
        'Dirección: Av. Terminal Pascuales S/N junto a la gasolinera Terpel',
      code: 'pickup_cd',
    },
  ];
  showDescription: boolean = false;
  isCenterDistributionSelected$: Observable<boolean> =
    this.customCheckoutStepsService.getIsCenterDistributionSelected();
  componentData$: Observable<CmsComponent> = this.cmsService.getComponentData('promesaInfoPickupCDComponent');

  constructor(
    private fb: FormBuilder,
    private customCheckoutDeliveryStepsService: CustomCheckoutDeliveryStepsService,
    private _checkoutDeliveryService: CheckoutDeliveryFacade,
    private customCheckoutStepsService: CustomCheckoutDeliveryStepsService,
    private cmsService: CmsService
  ) {}

  ngOnInit(): void {
    this.isCenterDistributionSelected$
      .pipe(
        take(1),
        map((isCenterDistributionSelected) => {
          if (isCenterDistributionSelected) {
            this.centerDistributionForm.controls['addressId'].setValue(
              'pickup_cd'
            );
            this.showDescription = true;
            this.customCheckoutDeliveryStepsService.setIsCenterDistributionSelected(
              true
            );
          }
        })
      )
      .subscribe();
  }

  changeMode(centerDistribution: string): void {
    this.showDescription = !this.showDescription;
    this.customCheckoutDeliveryStepsService.setIsCenterDistributionSelected(
      true
    );
    this._checkoutDeliveryService.setDeliveryMode(centerDistribution);
  }
}
