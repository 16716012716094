<div class="company">
  <app-custom-title-my-account
    [title]="'myCompany.myCompanyTitle' | cxTranslate"
  ></app-custom-title-my-account>
  <ng-container *ngIf="company$ | async as company; else spinnerLoading">
    <ng-container *ngIf="company; else noOrder">
      <h5 class="company__subtitle">
        {{ "myCompany.myCompanySubtitle" | cxTranslate }}
      </h5>
      <div class="company__info">
        <div class="company__info-data" *ngIf="company?.businessName">
          <p class="company__info-data-key">
            {{ "myCompany.socialReason" | cxTranslate }}
          </p>
          <p class="company__info-data-value">{{ company?.businessName }}</p>
        </div>
        <div class="company__info-data" *ngIf="company?.commercialName">
          <p class="company__info-data-key">
            {{ "myCompany.comercialName" | cxTranslate }}
          </p>
          <p class="company__info-data-value">{{ company?.commercialName }}</p>
        </div>
        <div class="company__info-data" *ngIf="company?.ruc">
          <p class="company__info-data-key">
            {{ "myCompany.ruc" | cxTranslate }}
          </p>
          <p class="company__info-data-value">{{ company?.ruc }}</p>
        </div>
        <div class="company__info-data" *ngIf="company?.clientCode">
          <p class="company__info-data-key">
            {{ "myCompany.customerNumber" | cxTranslate }}
          </p>
          <p class="company__info-data-value">{{ company?.clientCode }}</p>
        </div>
        <div class="company__info-data" *ngIf="company?.phone">
          <p class="company__info-data-key">
            {{ "myCompany.phone" | cxTranslate }}
          </p>
          <p class="company__info-data-value">{{ company?.phone }}</p>
        </div>
        <div class="company__info-data" *ngIf="company?.clientEmail">
          <p class="company__info-data-key">
            {{ "myCompany.email" | cxTranslate }}
          </p>
          <p class="company__info-data-value">{{ company?.clientEmail }}</p>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
<ng-template #spinnerLoading>
  <div class="company">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
<ng-template #noOrder>
  <div class="cx-order-history-no-order">
    <app-custom-empty-cart [fromMyAccount]="true"></app-custom-empty-cart>
  </div>
</ng-template>
