import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { PaginationModel } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CustomOrderPaginationService } from 'src/app/cms-components/my-account/services/custom-order-pagination.service';

@Component({
  selector: 'app-custom-promeclub-order-entry-items-container',
  templateUrl: './custom-promeclub-order-entry-items-container.component.html',
  styleUrls: ['./custom-promeclub-order-entry-items-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomPromeclubOrderEntryItemsContainerComponent implements OnInit {

  @Input() order: any;

  selectedPage: number;
  orderDetails: any[] = [];
  orderDetailsShow: any[];
  PAGE_SIZE: number = 5;
  pagination: PaginationModel = {
    currentPage: 0,
    pageSize: this.PAGE_SIZE,
    sort: "",
    totalPages: 0,
    totalResults: 0
  }
  orderDetail$: Observable<any>;
  constructor(
    private customOrderPaginationService: CustomOrderPaginationService,
    ) {

  }

  ngOnInit(): void {
    if(this.order){
      this.selectedPage = this.pagination.currentPage!;
      this.pagination.totalResults = this.order?.entries?.length;
      this.pagination.totalPages = Math.round(this.order?.entries?.length! / this.pagination.pageSize!);
      this.entries(this.order?.entries!);
    }
  }

  entries(entries?: any[], value?: number){
    entries ? this.orderDetails = entries : this.orderDetails;
    if (this.pagination.currentPage == 0 ) {
      this.orderDetailsShow = this.orderDetails?.slice(0, this.PAGE_SIZE);
    } else {
      this.orderDetailsShow = this.orderDetails?.slice(value, value! + this.PAGE_SIZE);
    }
  }

  customPageChange(event: any) {
    this.pageChange(event);
    this.selectedPage < this.pagination.pageSize! ? this.selectedPage = this.pagination.currentPage!
     : this.selectedPage = this.pagination.currentPage!;
    this.entries(this.orderDetails, this.PAGE_SIZE*event)
  }

  pageChange(page: number) {
    this.pagination.currentPage = page;
    this.customOrderPaginationService.onPageChange(this.pagination)
  }

}
