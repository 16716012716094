import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@spartacus/storefront';
import { CustomStarRatingComponent } from './custom-star-rating.component';

@NgModule({
  declarations: [CustomStarRatingComponent],
  imports: [CommonModule, IconModule],
  exports: [CustomStarRatingComponent],
})
export class CustomStarRatingModule {}
