<ng-container *ngIf="!entryRemoved">
  <div class="product-summary" [ngClass]="{ 'product-summary--checkout': isInCheckout }"
    *ngIf="entry && { isMobile: isMobile$ | async } as isMobile">
    <div class="product-summary__image" [ngClass]="{ 'product-summary__image--checkout': isInCheckout}">
      <cx-media [hidden]="entry?.product?.productType == EcvProductType" class="product-summary__image__size" [ngClass]="{
          'product-summary__image__size--promeclub': isInPromeclub,
          'product-summary__image__size--checkout': isInCheckout
        }" [container]="entry.product.images?.PRIMARY || entry.product.images" format="thumbnail"></cx-media>
    </div>
    <div class="product-summary__name" [ngClass]="{
        'product-summary__name--promeclub': isInPromeclub,
        'product-summary__name--checkout': isInCheckout
      }">
      <ng-container *ngIf="enableRedirect; else disableRedirect">
        <a [ngClass]="{ 'product-summary__name--checkout': isInCheckout }" [routerLink]="
              entry?.product?.productType != EcvProductType
                ? ({ cxRoute: 'product', params: entry.product } | cxUrl)
                : null
            " (click)="productClickEvent(entry?.product?.code)">
          {{ entry.product.name }}
        </a>
      </ng-container>
      <ng-template #disableRedirect>
        <p [ngClass]="{ 'product-summary__name--checkout': isInCheckout }">
          {{ entry.product.name }}
        </p>
      </ng-template>
      <span class="product-summary__name_quantity"
        [ngClass]="{ 'product-summary__name_quantity--checkout': isInCheckout }">{{ "cartItems.quantity" | cxTranslate
        }}: {{ entry.quantity
        }}</span>
      <ng-container *ngIf="isMobile.isMobile">
        <div class="product-summary__price_number" *ngIf="!isInPromeclub && !cart.isFromQuote">
          <span class="product-summary__price_prod" [ngClass]="{
            'product-summary__price_prod--checkout': isInCheckout
          }">
            {{ entry.totalPrice.value | customCurrency }}
          </span>
        </div>
        <div class="product-summary__price_number" [ngClass]="{
          'product-summary__price_number--promeclub': isInPromeclub,
          'product-summary__price_number--checkout': isInCheckout
        }" *ngIf="isInPromeclub">
          <span class="product-summary__price_prod" [ngClass]="{
            'product-summary__price_prod--checkout': isInCheckout
          }">{{
            entry.totalPrice.value | number : "1.0-0" : "es-AR"
            }}</span>
          <span class="product-summary__price_iva"
            [ngClass]="{ 'product-summary__price_iva--promeclub': isInPromeclub }">{{ "productList.points" | cxTranslate
            }}</span>
        </div>
      </ng-container>
    </div>
    <div class="product-summary__price" [ngClass]="{
        'product-summary__price_major':
          entry.totalPrice.value > 999999 && !isInPromeclub,
        'product-summary__price--promeclub': isInPromeclub
      }">
      <ng-container *ngIf="!isMobile.isMobile">
        <div class="product-summary__price_number" *ngIf="!isInPromeclub" [ngClass]="{
            'product-summary__price_number--checkout': isInCheckout
          }">
          <span class="product-summary__price_prod" [ngClass]="{
              'product-summary__price_prod--checkout': isInCheckout
            }">
            {{ entry?.totalWithIVA?.value | customCurrency }}
          </span>
        </div>
        <div class="product-summary__price_number" [ngClass]="{
            'product-summary__price_number--promeclub': isInPromeclub
          }" *ngIf="isInPromeclub">
          <span class="product-summary__price_prod">{{
            entry.totalPrice.value | number : "1.0-0" : "es-AR"
            }}</span>
          <span class="product-summary__price_iva" [ngClass]="{
              'product-summary__price_iva--promeclub': isInPromeclub
            }">{{ "productList.points" | cxTranslate }}</span>
        </div>
      </ng-container>
      <div class="product-summary__price_delete" [ngClass]="{
          'product-summary__price_delete--checkout': isInCheckout,
          'product-summary__price_delete--min-height': !(
            entry?.quoteAcceptedStatus == 'ACCEPTED'
          )
        }">
        <button (click)="removeItem()" *ngIf="
            !cart.isFromQuote &&
            !entry.totalPrice.value == 0 &&
            entry?.product?.productType != EcvProductType
          ">
          <cx-icon class="far fa-trash-alt"></cx-icon>
        </button>

        <div class="product-summary__price_delete_number"
          *ngIf="!isInPromeclub && isMobile.isMobile && cart.isFromQuote">
          <span class="product-summary__price_delete_number_prod">
            {{ entry.totalPrice.value | customCurrency }}
          </span>
        </div>

        <div class="product-summary__price_quote" *ngIf="entry?.quoteAcceptedStatus == 'ACCEPTED'">
          <div class="product-summary__price_quote__status-icon">
            <cx-icon class="fas fa-check"></cx-icon>
          </div>
          <p class="product-summary__price_quote__status-text">
            {{ "accountQuotes.item.priceAccepted" | cxTranslate }}
          </p>
        </div>
      </div>
    </div>

  </div>
</ng-container>
