import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CmsParagraphComponent } from '@spartacus/core';
import { CmsComponentData, ParagraphComponent } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-paragraph',
  templateUrl: './custom-paragraph.component.html',
  styleUrls: ['./custom-paragraph.component.scss']
})
export class CustomParagraphComponent extends ParagraphComponent implements OnInit {

  constructor(
    public component: CmsComponentData<CmsParagraphComponent>,
    protected router?: Router
  ) {
    super(component);
   }

  ngOnInit(): void {
  }

}
