import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomLoadingSpinnerComponent } from './custom-loading-spinner.component';
import { CustomLoadingSpinnerItemComponent } from './custom-loading-spinner-item/custom-loading-spinner-item.component';



@NgModule({
  declarations: [
    CustomLoadingSpinnerComponent,
    CustomLoadingSpinnerItemComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [CustomLoadingSpinnerComponent, CustomLoadingSpinnerItemComponent]
})
export class CustomLoadingSpinnerModule { }
