import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Order, RoutingService } from '@spartacus/core';
import { ICON_TYPE, ModalService } from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomClaimAddService } from '../../../services/custom-claim-add.service';

@Component({
  selector: 'app-custom-claim-dialog',
  templateUrl: './custom-claim-dialog.component.html',
  styleUrls: ['./custom-claim-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomClaimDialogComponent implements OnInit, OnDestroy {
  order: Order;
  modalIsOpen: boolean;
  isGenerateClaim: boolean;
  iconTypes = ICON_TYPE;
  @HostBinding('class.delete-modal') style: Boolean = false;
  unsubscribe$: Subject<void> = new Subject();
  constructor(
    protected modalService: ModalService,
    private addClaimService: CustomClaimAddService,
    private routingService: RoutingService
  ) {}

  ngOnInit(): void {
    this.style = !this.isGenerateClaim;
  }

  customDismissModal(reason?: any): void {
    this.modalService.dismissActiveModal(reason);
  }

  acceptGenerateClaim(reason?: any): void {
    this.modalService.dismissActiveModal(reason);
    this.routingService.go({
      cxRoute: 'claimTickets',
    });
  }

  deleteClaim(ticketId: string, reason?: any): void {
    this.addClaimService
      .deleteClaim(ticketId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res) {
          this.modalService.dismissActiveModal(reason);
          this.routingService.go({
            cxRoute: 'claimTickets',
          });
        }
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.isGenerateClaim &&
      this.routingService.go({
        cxRoute: 'claimTickets',
      });
  }
}
