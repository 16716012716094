<ng-container *ngIf="dataLogo$ | async as item">
  <ng-container *ngIf="isHomepage$ | async as isHomepage; else notHomePage">
    <h1 *ngIf="isHomepage" [ngClass]="{ isPromeclub: isInPromeclub }">
      <a *ngIf="dataLogo$ | async as item" [href]="item?.urlLink">
        <img
          [src]="config?.backend?.occ?.baseUrl + item?.media?.url"
          class="img-logo"
          alt="{{ item?.name }}"
        />
      </a>
      <span class="sr-only">{{ item?.name }}</span>
    </h1>
  </ng-container>
  <ng-template #notHomePage>
    <a [href]="item?.urlLink" [ngClass]="{ isPromeclub: isInPromeclub }">
      <img
        [src]="config?.backend?.occ?.baseUrl + item?.media?.url"
        class="img-logo"
      />
    </a>
  </ng-template>
</ng-container>
