import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Optional,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  ActiveCartService,
  AuthService,
  CmsAddToCartComponent,
  OccEndpointsService,
  Product,
  isNotNullable,
} from '@spartacus/core';
import {
  AddToCartComponent,
  CmsComponentData,
  CurrentProductService,
  ModalService,
} from '@spartacus/storefront';
import { Observable, Subscription, of } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CustomScrollService } from 'src/app/services/custom-scroll.service';
import { CustomBreakpointService } from '../../../services/custom-breakpoint.service';
import { CustomPromeclubPointsService } from '../../custom/components/custom-promeclub-product-points/services/custom-promeclub-product-points.service';
import { CustomQuoteModalService } from '../../my-account/components/custom-quotes/services/custom-quote-modal.service';
import { CustomNavigationService } from '../../navigation/services/custom-navigation.service';
import { EcvProductType } from '../../product/constants/product-type';
import { CustomFutureStockDialogComponent } from '../custom-future-stock-dialog/custom-future-stock-dialog.component';
import { CustomFutureStockService } from '../services/custom-future-stock.service';
import { CustomAddedToCartDialogComponent } from './custom-added-to-cart-dialog/custom-added-to-cart-dialog.component';
import { CustomPreAddToCartComponent } from './custom-pre-add-to-cart/custom-pre-add-to-cart.component';
import { CustomPreAddToCartService } from './custom-pre-add-to-cart/custom-pre-add-to-cart.service';
import { CustomPromeclubAddedToCartDialogComponent } from './custom-promeclub-added-to-cart-dialog/custom-promeclub-added-to-cart-dialog.component';

@Component({
  selector: 'custom-add-to-cart',
  templateUrl: './custom-add-to-cart.component.html',
  styleUrls: ['./custom-add-to-cart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomAddToCartComponent
  extends AddToCartComponent
  implements OnInit
{
  private readonly OUT_OF_STOCK: string = 'outOfStock';
  @Input() fromProductCard: boolean = false;
  @Input() fromContent: boolean = false;
  @Input() fromProductVariantPlp: boolean = false;
  @Input() fromProductVariantPlpDesktop: boolean = false;
  @Input() isRebate: boolean = false;
  @Input() clickedFrom: string;
  isUserLoggedIn$: Observable<boolean> = this.auth.isUserLoggedIn();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  isInPromeclub: boolean = this.service.isInPromeClub();
  subscription: Subscription = new Subscription();
  isFromQuote: boolean = false;
  EcvProductType: string = EcvProductType;
  addToCartForm: FormGroup;

  btnSubmitInitialCoords: DOMRect;
  btnSubmitInitialCoordsBottom: number;
  checkedCoords: boolean = false;
  scrollPositionY: number = 0;
  @Input() floatingSelector: boolean = false;
  @Input() floatingSelectorLoginForm: boolean = false;
  @ViewChild('btnSubmit') btnSubmit: ElementRef;
  @ViewChild('outOfStock') outOfStockBtn: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkedCoords = false;
    if (this.btnSubmit?.nativeElement) {
      this.btnSubmitInitialCoords =
        this.btnSubmit.nativeElement.getBoundingClientRect();
      this.checkAddToCartCoords(this.btnSubmitInitialCoords, window.scrollY);
    }
    if (this.outOfStockBtn?.nativeElement) {
      this.btnSubmitInitialCoords =
        this.outOfStockBtn.nativeElement.getBoundingClientRect();
      this.checkAddToCartCoords(this.btnSubmitInitialCoords, window.scrollY);
    }
  }

  constructor(
    protected modalService: ModalService,
    protected currentProductService: CurrentProductService,
    protected cd: ChangeDetectorRef,
    protected activeCartService: ActiveCartService,
    private auth: AuthService,
    private customBreakpointService: CustomBreakpointService,
    private service: CustomNavigationService,
    private customQuoteModalService: CustomQuoteModalService,
    private el: ElementRef,
    private customPromeclubPointsService: CustomPromeclubPointsService,
    private customPreAddToCartService: CustomPreAddToCartService,
    private customScrollService: CustomScrollService,
    private occEndpointService: OccEndpointsService,
    private http: HttpClient,
    private customFutureStockService: CustomFutureStockService,
    @Optional() protected component?: CmsComponentData<CmsAddToCartComponent>
  ) {
    super(modalService, currentProductService, cd, activeCartService);
  }

  ngOnInit(): void {
    this.addToCartForm = new FormGroup({
      quantity: new FormControl(1, {
        updateOn: this.fromProductVariantPlpDesktop ? 'change' : 'blur',
      }),
    });
    this.subscription.add(
      this.activeCartService.getActive().subscribe((cart: any) => {
        if (cart?.isFromQuote) {
          this.isFromQuote = true;
        }
      })
    );
    if (this.product) {
      this.productCode = this.product.code ?? '';
      this.setStockInfo(this.product);
      this.cd.markForCheck();
    } else if (this.productCode) {
      // force hasStock and quantity for the time being, as we do not have more info:
      this.quantity = 1;
      this.hasStock = true;
      this.cd.markForCheck();
    } else {
      this.subscription = this.currentProductService
        .getProduct()
        .pipe(filter(isNotNullable))
        .subscribe((product) => {
          this.product = product;
          this.productCode = product.code ?? '';
          this.setStockInfo(product);
          this.cd.markForCheck();
        });
    }
    this.subscription.add(
      this.customPromeclubPointsService
        .getIsActiveStatus()
        .subscribe((isActive) => {
          if (isActive) {
            this.el?.nativeElement?.classList?.add('promeclub-points');
          }
        })
    );

    this.customScrollService.getScrollY().subscribe((scrollY) => {
      if (this.btnSubmit?.nativeElement) {
        this.checkAddToCartCoords(
          this.btnSubmit?.nativeElement.getBoundingClientRect(),
          scrollY
        );
      }
      if (this.outOfStockBtn?.nativeElement) {
        this.checkOutStockBtnCoords(
          this.outOfStockBtn?.nativeElement.getBoundingClientRect(),
          scrollY
        );
      }
    });
  }

  protected openModal() {
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomAddedToCartDialogComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'add-to-cart',
    });

    modalInstance = this.modalRef.componentInstance;
    modalInstance.entry$ = this.activeCartService.getLastEntry(
      this.productCode
    );
    modalInstance.cart$ = this.activeCartService.getActive();
    modalInstance.loaded$ = this.activeCartService.isStable();
    modalInstance.quantity = this.addToCartForm.get('quantity')!.value;
    modalInstance.numberOfEntriesBeforeAdd = this.numberOfEntriesBeforeAdd;
    modalInstance.isInPromeclub = this.isInPromeclub;
    modalInstance.clickedFrom = this.fromProductCard
      ? this.clickedFrom
      : 'productDetails';
  }

  getTotal(num: number): number {
    const roundedNumber = Math.round(num * 100) / 100;
    return this.addToCartForm.get('quantity')!.value * roundedNumber;
  }

  addToCart(isMobile: boolean = false): void {
    if (!isMobile || (!this.fromProductCard && isMobile)) {
      if (this.isFromQuote && !this.isInPromeclub) {
        this.customQuoteModalService.openQuoteInCourseModal();
        return;
      }

      if (
        !this.isInPromeclub ||
        !this.customPreAddToCartService.validatePromeclubProductType(
          this.product as Product
        )
      ) {
        super.addToCart();
      }
      if (
        this.isInPromeclub &&
        this.customPreAddToCartService.validatePromeclubProductType(
          this.product as Product
        )
      ) {
        this.openModalPromeclub();
      }
    } else {
      this.openPreAddToCart();
    }
  }

  protected openModalPromeclub() {
    let modalInstance: any;
    this.modalRef = this.modalService.open(
      CustomPromeclubAddedToCartDialogComponent,
      {
        centered: true,
        size: 'lg',
        windowClass: 'promeclub-add-to-cart',
      }
    );

    modalInstance = this.modalRef.componentInstance;
    modalInstance.entry$ = of({
      product: this.product,
      basePrice: { value: this.product?.price?.value },
    });
    modalInstance.cart$ = this.activeCartService.getActive();
    modalInstance.loaded$ = this.activeCartService.isStable();
    modalInstance.quantity = this.addToCartForm.get('quantity')!.value;
    modalInstance.numberOfEntriesBeforeAdd = this.numberOfEntriesBeforeAdd;
    modalInstance.isInPromeclub = this.isInPromeclub;
    modalInstance.product = this.product;
  }

  openPreAddToCart(): void {
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomPreAddToCartComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'pre-add-to-cart',
    });

    modalInstance = this.modalRef.componentInstance;
    modalInstance.productCode = this.productCode;
    modalInstance.quantitySelected = this.addToCartForm.get('quantity')?.value;
    modalInstance.isOutOfStock =
      (this.product?.stock?.stockLevelStatus == this.OUT_OF_STOCK) &&
      !this.isInPromeclub;
    modalInstance.isFromQuote = this.isFromQuote;
    modalInstance.isPromeclub = this.isInPromeclub;
  }

  checkAddToCartCoords(
    btnSubmitCoords: DOMRect,
    currentScrollPosition: number
  ): void {
    let currentClientHeightHeader =
      document.getElementsByTagName('header')?.[0]?.clientHeight;
    if (
      this.btnSubmitInitialCoords?.bottom !== btnSubmitCoords.bottom &&
      !this.checkedCoords &&
      !this.floatingSelector
    ) {
      this.scrollPositionY = window.scrollY;
      this.btnSubmitInitialCoords =
        this.btnSubmit?.nativeElement.getBoundingClientRect();
      this.btnSubmitInitialCoordsBottom =
        this.btnSubmitInitialCoords.bottom - currentClientHeightHeader;
      this.checkedCoords = true;
    } else {
      if (!this.floatingSelector) {
        this.customScrollService.showFloatingAddToCartBottom(
          this.btnSubmitInitialCoordsBottom,
          this.scrollPositionY,
          currentScrollPosition
        );
      }
    }
  }

  checkOutStockBtnCoords(
    btnSubmitCoords: DOMRect,
    currentScrollPosition: number
  ): void {
    if (
      this.btnSubmitInitialCoords?.bottom !== btnSubmitCoords.bottom &&
      !this.checkedCoords
    ) {
      this.checkedCoords = true;
      this.scrollPositionY = window.scrollY;
      this.btnSubmitInitialCoords =
        this.outOfStockBtn?.nativeElement.getBoundingClientRect();
      this.btnSubmitInitialCoordsBottom =
        this.btnSubmitInitialCoords.bottom -
        document.getElementsByTagName('header')?.[0]?.clientHeight;
    } else {
      if (!this.floatingSelector) {
        this.customScrollService.showFloatingAddToCartBottom(
          this.btnSubmitInitialCoordsBottom,
          this.scrollPositionY,
          currentScrollPosition
        );
      }
    }
  }

  addAlert(): void {
    if (this.product?.code !== undefined) {
      this.modalRef = this.modalService.open(CustomFutureStockDialogComponent, {
        centered: true,
        size: 'lg',
        windowClass: 'future-stock',
      });
      this.subscription.add(
        this.customFutureStockService
          .addStockAlert(this.product?.code)
          .subscribe()
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
