import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CustomRebateService } from 'src/app/cms-components/my-account/services/custom-rebate.service';

@Component({
  selector: 'app-custom-pdp-rebate-detail',
  templateUrl: './custom-pdp-rebate-detail.component.html',
  styleUrls: ['./custom-pdp-rebate-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomPdpRebateDetailComponent implements OnInit {
  @Input() isMobile: boolean;
  @Input() open: boolean;
  @Input() rappels: any[];

  constructor(private customRebateService: CustomRebateService) {}

  ngOnInit(): void {}

  valueMinProgressBar(arrayScale: any[], totalFacturado: string): number {
    return this.customRebateService.valueMinProgressBar(
      arrayScale,
      totalFacturado
    );
  }

  valueProgressBar(arrayScale: any[], totalFacturado: string): number {
    return this.customRebateService.valueProgressBar(
      arrayScale,
      totalFacturado
    );
  }

  valueMaxProgressBar(arrayScale: any[]): number {
    return this.customRebateService.valueMaxProgressBar(arrayScale);
  }
}
