import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomProductAttributesModule } from './custom-product-attributes/custom-product-attributes.module';
import { CustomProductReviewsModule } from './custom-product-reviews/custom-product-reviews.module';



@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    CustomProductReviewsModule,
    CustomProductAttributesModule
  ]
})
export class CustomProductTabsModule { }
