import { Injectable } from '@angular/core';
import { Facet } from '@spartacus/core';
import {
  FacetCollapseState,
  FacetService,
  ProductFacetService,
} from '@spartacus/storefront';
import { BehaviorSubject } from 'rxjs';

/**
 * Provides access to the facets as well as their UI state. The UI state
 * represents user related changes on the facets, such as expanding or
 * collapsing a facet group or expanding the number of _visible_ facet values.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomFacetService extends FacetService {
  constructor(protected productFacetService: ProductFacetService) {
    super(productFacetService);
  }
  protected initialize(facet: Facet): void {
    const topFacets =
      facet.topValueCount! > 0
        ? facet.topValueCount
        : facet.values?.length || 0;
    if (!this.hasState(facet)) {
      this.facetState.set(
        facet.name!,
        new BehaviorSubject({
          topVisible: 5,
          maxVisible: topFacets,
        } as FacetCollapseState)
      );
    }
  }
}
