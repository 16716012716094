export const customCreditLine = {
  creditLine: {
    creditLine: {
      myCupo: 'Mi cupo',
      availableCredit: 'Cupo disponible',
      total: 'Total: {{count}}',
      creditLineTitle: 'Línea de crédito',
      myAvailableCredit: 'Mi cupo disponible',
      requestExtension: 'Solicitar ampliación',
      msgReceiptRequest: 'Hemos recibido tu solicitud.',
      msgReceiptRequestComincation: 'Un oficial de crédito se estará comunicando contigo.'
    }
  }
}
