import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomErrorComponent } from './custom-error.component';



@NgModule({
  declarations: [
    CustomErrorComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    I18nModule
  ],
  exports: [
    CustomErrorComponent
  ]
})
export class CustomErrorModule { }
