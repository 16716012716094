<ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
  <ng-container *ngIf="order">
    <div class="promeclub-order">
      <div *ngIf="data.isMobile">
        <a
          class="promeclub-order__back-mobile"
          [routerLink]="{ cxRoute: 'orders' } | cxUrl"
        >
          <cx-icon class="fas fa-chevron-left"></cx-icon>
          <h4 class="promeclub-order__back-mobile-text">
            {{ "orderDetails.promeclubDetail.goBack" | cxTranslate }}
          </h4>
        </a>
        <h4 class="promeclub-order__back-text">
          {{ "orderDetails.promeclubDetail.exchangeDetail" | cxTranslate }}
        </h4>
      </div>
      <a class="promeclub-order__back" *ngIf="!data.isMobile">
        <app-custom-title-my-account
          [title]="
            'orderDetails.promeclubDetail.exchangeDetail' | cxTranslate
          "
          [routerlink]="{ cxRoute: 'orders' }"
        ></app-custom-title-my-account>
      </a>
      <div class="promeclub-order__details">
        <div class="promeclub-order__details-item">
          <div class="promeclub-order__details-item-key">
            {{ "orderDetails.promeclubDetail.transactionNumber" | cxTranslate }}
          </div>
          <div class="promeclub-order__details-item-value">
            {{ order?.code }}
          </div>
        </div>
        <div class="promeclub-order__details-item">
          <div class="promeclub-order__details-item-key">
            {{ "orderDetails.promeclubDetail.creationDate" | cxTranslate }}
          </div>
          <div class="promeclub-order__details-item-value">
            {{ order?.created | date : "dd/MM/yyyy" }}
          </div>
        </div>
        <div class="promeclub-order__details-item">
          <div class="promeclub-order__details-item-key">
            {{ "orderDetails.promeclubDetail.deliveryDay" | cxTranslate }}
          </div>
          <div class="promeclub-order__details-item-value">
            {{ order?.formattedDeliveryDate }}
          </div>
        </div>
        <div class="promeclub-order__details-item">
          <div class="promeclub-order__details-item-key">
            {{ "orderDetails.promeclubDetail.pointsQuantity" | cxTranslate }}
          </div>
          <div class="promeclub-order__details-item-value">
            {{ order?.totalPrice?.value | number : "" : "es-AR" }}
          </div>
        </div>
      </div>
      <div class="promeclub-order__delivery">
        <div class="promeclub-order__delivery-item">
          <div class="promeclub-order__delivery-item-key">
            {{
              "orderDetails.promeclubDetail.deliveryToMyAddress" | cxTranslate
            }}
          </div>
          <div class="promeclub-order__delivery-item-value">
            <p class="promeclub-order__delivery-item-value-line">
              {{
                customPromeclubAddressService.typeTranslation(
                  order?.deliveryAddress
                )
              }}
            </p>
            <p class="promeclub-order__delivery-item-value-line">
              {{
                order?.deliveryAddress?.line1 +
                  " " +
                  (order?.deliveryAddress?.line2
                    ? order?.deliveryAddress?.line2
                    : "
              ")
              }}
            </p>
            <p
              class="promeclub-order__delivery-item-value-line"
              *ngIf="order?.deliveryAddress?.district"
            >
              {{ order?.deliveryAddress?.region?.name }}
            </p>
            <p class="promeclub-order__delivery-item-value-line">
              {{ order?.deliveryAddress?.region?.province?.name }}
            </p>
          </div>
        </div>
      </div>
      <app-custom-promeclub-order-entry-items-container
        [order]="order"
      ></app-custom-promeclub-order-entry-items-container>
    </div>
  </ng-container>
</ng-container>
