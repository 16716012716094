<ng-container *ngIf="node$ | async as nodes">
  <button
    class="my-account-container-toggle-button"
    (click)="toggleForceOpenSlide()"
  >
    <cx-icon class="fas fa-chevron-right"></cx-icon>
  </button>
  <div
    class="my-account-container"
    [ngClass]="{ 'my-account-mobile': fromHeader }"
    (mouseenter)="openSlide()"
    (mouseleave)="closeSlide()"
  >
    <h1
      *ngIf="!fromHeader"
      class="my-account-container__title"
      [innerHTML]="'customMyAccount.title' | cxTranslate | safeHtml"
    ></h1>
    <div class="my-account-container__menu">
      <ng-container *ngFor="let node of nodes.children">
        <ng-container *ngFor="let category of node.children">
          <ng-container
            *ngIf="
              category.title === 'Pedidos' ||
              (isPromeclub && category.title === 'Mis puntos')
            "
          >
            <div
              class="my-account-container__menu__orders"
              [ngClass]="{ promeclub: isPromeclub, employee: user?.isEmployee }"
            >
              <h2 class="my-account-container__menu__orders__title">
                <b>{{ category.title }}</b>
              </h2>
              <div>
                <nav
                  *ngFor="let subcategory of category.children; let i = index"
                  [routerLinkActive]="'active'"
                  [ngClass]="{
                    active:
                      (isPromeclub && currentAccountStatusDetailsUrl) ||
                      (subcategory?.url == '/my-account/quotes' &&
                        (checkIsInQuoteDetail$ | async))
                  }"
                  (click)="hideModal()"
                >
                  <a [routerLink]="subcategory.url" *ngIf="subcategory?.url">
                    {{ subcategory.title }}
                  </a>
                </nav>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="category.title === 'Configuraciones'">
            <div class="my-account-container__menu__configuration">
              <h2 class="my-account-container__menu__configuration__title">
                <b>{{ category.title }}</b>
              </h2>
              <div>
                <nav
                  *ngFor="let subcategory of category.children; let i = index"
                  [routerLinkActive]="'active'"
                  (click)="hideModal()"
                >
                  <ng-container
                    *ngIf="!isSeller && user?.isEmployee; else regularAnchor"
                  >
                    <a
                      [routerLink]="subcategory.url"
                      *ngIf="
                        !subcategory?.title?.includes('Puntos') &&
                        subcategory?.url
                      "
                    >
                      {{ subcategory.title }}
                    </a>
                  </ng-container>

                  <ng-template #regularAnchor>
                    <a
                      [routerLink]="subcategory.url"
                      *ngIf="
                        !user?.isEmployee && subcategory?.url;
                        else pointsAnchor
                      "
                    >
                      {{ subcategory.title }}
                    </a>
                  </ng-template>

                  <ng-template #pointsAnchor>
                    <a
                      [routerLink]="subcategory.url"
                      *ngIf="isSeller && subcategory?.url"
                    >
                      {{
                        subcategory?.title?.includes("Puntos")
                          ? subcategory.title.replace(
                              POINTS.PROMECLUB,
                              POINTS.PROMETAS
                            )
                          : subcategory.title
                      }}
                    </a>
                  </ng-template>
                </nav>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div class="my-account-container__sign-off">
      <a (click)="logout()">
        <span>{{ "customMyAccount.logout" | cxTranslate }}</span>
        <cx-icon type="LOGOUT"></cx-icon>
      </a>
    </div>
  </div>
</ng-container>
