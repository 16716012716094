import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { CustomOrderDetailActionsComponent } from './custom-order-detail-actions.component';



@NgModule({
  declarations: [
    CustomOrderDetailActionsComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    UrlModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        AccountOrderDetailsActionsComponent: {
          component: CustomOrderDetailActionsComponent,
        },
      },
    }),
  ],
  exports: [
    CustomOrderDetailActionsComponent
  ]
})
export class CustomOrderDetailActionsModule { }
