<ul class="suggestions" attr.aria-label="{{ 'searchBox.ariaLabelSuggestions' | cxTranslate }}" tabindex="0" *ngIf="!!suggestions?.length">
  <h3 class="suggestions__title" *ngIf="!!suggestions?.length && !isInPromeclub">
    {{ "searchBox.suggestions" | cxTranslate }}
  </h3>
  <h3 class="suggestions__title" *ngIf="!!suggestions?.length && isInPromeclub">
    {{ "searchBox.suggestionsBySearch" | cxTranslate }}
  </h3>
  <li *ngFor="let suggestion of suggestions">
    <a [innerHTML]="(suggestion | cxHighlight : searchInputValue | titlecase)" [routerLink]="
        {
          cxRoute: 'search',
          params: { query: suggestion }
        } | cxUrl
      ">
    </a>
  </li>
</ul>
<ng-container *ngIf="!(isMobile$ | async); else categoriesMobile">
  <ng-container *ngIf="!!categories?.length">
    <ul class="suggestions categories" attr.aria-label="{{ 'searchBox.ariaLabelSuggestions' | cxTranslate }}"
      tabindex="0">
      <h3 class="suggestions__title" *ngIf="!!categories.length">
        {{ "searchBox.categories" | cxTranslate }}
      </h3>
      <li *ngFor="let category of categories | slice : 0 : 4" class="categories">
        <a [innerHTML]="category.name | cxHighlight : category.name | titlecase" (click)="navigateToUrl(category)"
          (mouseover)="onCategoryMouseOver(category)">
        </a>
      </li>
    </ul>
  </ng-container>

  <ng-container *ngIf="!!brands?.length">
    <ul class="suggestions brands" attr.aria-label="{{ 'searchBox.ariaLabelSuggestions' | cxTranslate }}" tabindex="0">
      <h3 class="suggestions__title" *ngIf="!!brands.length">
        {{ "searchBox.brands" | cxTranslate }}
      </h3>
      <li *ngFor="let brand of brands | slice : 0 : 3" class="brands">
        <a [innerHTML]="brand.name | cxHighlight : brand.name | titlecase" (click)="navigateToUrl(brand)"
          (mouseover)="onBrandMouseOver(brand)">
        </a>
      </li>
    </ul>
  </ng-container>

</ng-container>
<ng-template #categoriesMobile>
  <ng-container *ngIf="!!categories?.length">
    <app-custom-searchbox-list-items [isCategory]="true" [isSuggest]="searchInputValue" [categories]="categories" [url]=""></app-custom-searchbox-list-items>
  </ng-container>
  <ng-container *ngIf="!!brands?.length">
    <app-custom-searchbox-list-items [isCategory]="false" [isSuggest]="searchInputValue" [categories]="brands"></app-custom-searchbox-list-items>
  </ng-container>
</ng-template>
