import { ElementRef, Injectable } from '@angular/core';
import { CustomBreakpointService } from './custom-breakpoint.service';
import { BehaviorSubject, Observable, fromEvent } from 'rxjs';
import { filter, map, withLatestFrom, tap } from 'rxjs/operators';
import { WindowRef } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class CustomScrollService {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  lastScrollPosition$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  showFloatingAddToCart$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  moveToReviews$ = new BehaviorSubject<boolean>(false);

  constructor(
    private customBreakpointService: CustomBreakpointService,
    private winRef: WindowRef
  ) {}

  getScrollY(): Observable<number> {
    return fromEvent(window, 'scroll').pipe(
      withLatestFrom(this.isMobile$),
      filter(([_, isMobile]) => isMobile),
      map(([event, _]) => window.scrollY),
      tap((scrollYPosition) => this.lastScrollPosition$.next(scrollYPosition))
    );
  }

  getLastScrollPosition(): Observable<number> {
    return this.lastScrollPosition$.asObservable();
  }

  showFloatingAddToCartBottom(
    elementBottomPosition: number,
    scrollPosition: number,
    currentScrollPosition: number
  ) {
    if (
      elementBottomPosition +
        scrollPosition -
        this.getCurrentClientHeightHeader() <
      currentScrollPosition
    ) {
      this.showFloatingAddToCart$.next(true);
    } else {
      this.showFloatingAddToCart$.next(false);
    }
  }

  getCurrentClientHeightHeader(): number {
    const header = document.getElementsByTagName('header');
    let clientHeightHeader: number = 0;
    for (let i = 0; i < header?.[0]?.children?.length; i++) {
      clientHeightHeader +=
        document.getElementsByTagName('header')?.[0]?.children?.[i]
          ?.clientHeight;
    }

    return header?.[0]?.clientHeight > 0
      ? header?.[0]?.clientHeight
      : clientHeightHeader;
  }

  setMoveToReviews(activate: boolean): void {
    this.moveToReviews$.next(activate);
  }

  public scrollIntoView(
    behavior: ScrollBehavior = 'auto',
    block: ScrollLogicalPosition = 'start',
    ref: ElementRef
  ) {
    this.winRef.nativeWindow?.scrollBy(0, 1);
    this.winRef.nativeWindow?.scrollBy(0, -1);

    ref.nativeElement.scrollIntoView({
      behavior: behavior,
      block: block,
    });
  }

  resetScrollPosition(): void {
    window.scrollTo(0, 0);
  }
}
