import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalService } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-reorder-dialog',
  templateUrl: './custom-reorder-dialog.component.html',
  styleUrls: ['./custom-reorder-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomReorderDialogComponent implements OnInit {
  orderCode: string;

  constructor(protected modalService: ModalService) {}

  ngOnInit(): void {
    window.scroll(0, 0);
  }
}
