import { Component, OnInit } from '@angular/core';
import {
  ActiveCartService, AuthService,
  RoutingService, SelectiveCartService
} from '@spartacus/core';
import { CartDetailsComponent } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from '../../../services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-cart-details',
  templateUrl: './custom-cart-details.component.html',
  styleUrls: ['./custom-cart-details.component.scss'],
})
export class CustomCartDetailsComponent
  extends CartDetailsComponent
  implements OnInit
{
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;

  constructor(
    protected activeCartService: ActiveCartService,
    protected selectiveCartService: SelectiveCartService,
    protected authService: AuthService,
    protected routingService: RoutingService,
    private customBreakpointService: CustomBreakpointService
  ) {
    super(activeCartService, selectiveCartService, authService, routingService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
