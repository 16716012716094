<ng-container *ngIf="!titlePopUp && !isPopUpV2; else popUpMobileV2">
  <div class="cx-dialog-header modal-header">
    <button *ngIf="isClose" type="button" class="close" attr.aria-label="{{ 'addToCart.closeModal' | cxTranslate }}"
      (click)="onCloseModal()">
      <span aria-hidden="true">
        <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
      </span>
    </button>
    <button *ngIf="!isClose" type="button" class="close" cxModal="dismiss" cxModalReason="Cross click"
      attr.aria-label="{{ 'addToCart.closeModal' | cxTranslate }}" (click)="customDismissModal($event)">
      <span aria-hidden="true">
        <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
      </span>
    </button>
  </div>
</ng-container>
<ng-template #popUpMobileV2>
  <div class="modal-v2-header">
    <div class="modal-v2-header__title">
      <cx-icon *ngIf="iconHeaderClass" [class]="iconHeaderClass"></cx-icon>
      <cx-icon [type]="iconHeaderType" *ngIf="iconHeaderType"></cx-icon>
      <h4 class="modal-v2-header__title-text">
        {{titlePopUp}}
      </h4>
    </div>
    <cx-icon type="CLOSE" (click)="isClose ? onCloseModal() : customDismissModal($event)"></cx-icon>
  </div>
</ng-template>
