import { Component, OnInit } from '@angular/core';
import { CustomTermsAndConditionsModalService } from '../custom-terms-and-conditions-modal.service';

@Component({
  selector: 'app-custom-terms-and-conditions',
  templateUrl: './custom-terms-and-conditions.component.html',
  styleUrls: ['./custom-terms-and-conditions.component.scss'],
})
export class CustomTermsAndConditionsComponent implements OnInit {
  constructor(
    private customTermsAndConditionsModalService: CustomTermsAndConditionsModalService
  ) {}

  ngOnInit(): void {
    this.customTermsAndConditionsModalService.openTermsAndConditionsModal();
  }
}
