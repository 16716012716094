<div *ngIf="content" class="cx-card">
  <ng-container *ngIf="content.header && !editMode; else noCheck">
    <app-custom-check-button
      [isActive]="true"
      [isIconActive]="true"
      [isIconCircle]="(isMobile$ | async)"
    ></app-custom-check-button>
  </ng-container>
  <ng-template #noCheck>
    <app-custom-check-button
      [isActive]="false"
      [isIconActive]="false"
      [isIconCircle]="(isMobile$ | async)"
    ></app-custom-check-button>
  </ng-template>

  <div class="card-body cx-card-body">
    <h3 *ngIf="content.title" class="cx-card-title">
      {{ content.title }}
    </h3>

    <div class="cx-card-container" *ngIf="content.header && !editMode">
      <div class="cx-card-label-container">
        <div *ngFor="let line of content.text">
          <div class="cx-card-label">
            {{ line }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
