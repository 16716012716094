import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCartCouponComponent } from './custom-cart-coupon.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormErrorsModule, IconModule } from '@spartacus/storefront';
import { CustomAppliedCouponsComponent } from './custom-applied-coupons/custom-applied-coupons.component';



@NgModule({
  declarations: [
    CustomCartCouponComponent,
    CustomAppliedCouponsComponent
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    IconModule,
    FormErrorsModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CartApplyCouponComponent: {
          component: CustomCartCouponComponent,
        },
      },
    }),
  ],
  exports: [
    CustomCartCouponComponent,
    CustomAppliedCouponsComponent
  ]
})
export class CustomCartCouponModule { }
