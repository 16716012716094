export const customPersonalData = {
  personalData: {
    personalData:{
      title: 'Datos personales',
      subtitle: 'Mis datos',
      fullname: 'Nombre completo: ',
      cellphone: 'Teléfono móvil: ',
      email: 'E-mail de usuario: ',
      addressTitle: 'Direcciones de envío',
      addressSubtitle: 'Mis direcciones',
      addAddress: 'Agregar dirección de envío',
      addNewAddress: 'Agregar nueva dirección',
      editAddress: 'Editar dirección'
    }
  }
};
