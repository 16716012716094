import { Component, Inject, OnInit } from '@angular/core';
import { Config } from '@spartacus/core';

@Component({
  selector: 'app-custom-display-config',
  templateUrl: './custom-display-config.component.html',
  styleUrls: ['./custom-display-config.component.scss'],
})
export class CustomDisplayConfigComponent implements OnInit {
  constructor(@Inject(Config) protected config: any) {}

  ngOnInit(): void {
    console.log('CONFIG: ', this.config);
  }
}
