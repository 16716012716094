import { Component, ViewEncapsulation } from '@angular/core';

import { Order } from '@spartacus/core';
import {
  OrderDetailsService
} from '@spartacus/order/components';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-order-cancel',
  templateUrl: './custom-order-cancel.component.html',
  styleUrls: ['./custom-order-cancel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomOrderCancelComponent {
  orderCode: string;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  order$: Observable<Order> = this.orderDetailsService.getOrderDetails();
  constructor(
    protected orderDetailsService: OrderDetailsService,
    private customBreakpointService: CustomBreakpointService
  ) {}
}
