import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, ListNavigationModule } from '@spartacus/storefront';
import { CustomAccountTableModule } from 'src/app/cms-components/custom/components/custom-account-table/custom-account-table.module';
import { CustomEmptyCartModule } from 'src/app/cms-components/custom/components/custom-empty-cart/custom-empty-cart.module';
import { CustomFilterModule } from 'src/app/cms-components/custom/components/custom-filter/custom-filter.module';
import { CustomLoadingSpinnerModule } from 'src/app/cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomPaginationModule } from 'src/app/cms-components/custom/components/custom-pagination/custom-pagination.module';
import { CustomTitleMyAccountModule } from 'src/app/cms-components/custom/components/custom-title-my-account/custom-title-my-account.module';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomAccountStatusPromeclubComponent } from './custom-account-status-promeclub/custom-account-status-promeclub.component';
import { CustomOrderHistoryComponent } from './custom-order-history.component';



@NgModule({
  declarations: [
    CustomOrderHistoryComponent,
    CustomAccountStatusPromeclubComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    UrlModule,
    I18nModule,
    IconModule,
    ListNavigationModule,
    CustomPaginationModule,
    CustomFilterModule,
    CustomCurrencyModule,
    CustomLoadingSpinnerModule,
    CustomAccountTableModule,
    CustomTitleMyAccountModule,
    RouterModule,
    CustomEmptyCartModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CMSTabParagraphContainer: {
          component: CustomOrderHistoryComponent,
        },
      },
    }),
  ],
  exports: [
    CustomOrderHistoryComponent
  ]
})
export class CustomOrderHistoryModule { }
