import { Component, ViewContainerRef, OnDestroy, Input } from '@angular/core';
import {
  SavedCartDetailsActionComponent,
  SavedCartDetailsService,
} from '@spartacus/cart/saved-cart/components';
import { SavedCartFormType } from '@spartacus/cart/saved-cart/root';
import { Cart } from '@spartacus/core';
import {
  LaunchDialogService,
  ModalRef,
  ModalService,
} from '@spartacus/storefront';
import { CustomSavedCartModalComponent } from '../custom-saved-cart-modal/custom-saved-cart-modal.component';

@Component({
  selector: 'app-custom-saved-cart-details-action',
  templateUrl: './custom-saved-cart-details-action.component.html',
  styleUrls: ['./custom-saved-cart-details-action.component.scss'],
})
export class CustomSavedCartDetailsActionComponent
  extends SavedCartDetailsActionComponent
  implements OnDestroy
{
  modalRef: ModalRef;
  @Input() cartSavedName: string;
  @Input() cartSaved: Cart;
  isRestoreSavedCart: boolean = false;

  constructor(
    protected savedCartDetailsService: SavedCartDetailsService,
    protected vcr: ViewContainerRef,
    protected launchDialogService: LaunchDialogService,
    private modalService: ModalService
  ) {
    super(savedCartDetailsService, vcr, launchDialogService);
  }

  openDialog(cart: Cart, type: SavedCartFormType): void {
    let modalInstance: any;

    if (type == 'restore') {
      this.modalRef = this.modalService.open(CustomSavedCartModalComponent, {
        centered: true,
        size: 'lg',
      });
      modalInstance = this.modalRef.componentInstance;
      modalInstance.restoreCart = true;
      modalInstance.cartSavedName = this.cartSavedName;
      modalInstance.cartSaved = this.cartSaved;
      modalInstance.isRestoreSavedCart = true;
    } else {
      this.modalRef = this.modalService.open(CustomSavedCartModalComponent, {
        centered: true,
        size: 'lg',
      });

      modalInstance = this.modalRef.componentInstance;
      modalInstance.restoreCart = false;
      modalInstance.cartSavedName = this.cartSavedName;
      modalInstance.cartSaved = this.cartSaved;
      modalInstance.isRestoreSavedCart = false;
    }
  }

  restoreSavedCart(): void {
    this.isRestoreSavedCart = true;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
