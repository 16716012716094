<ng-container *ngIf="product">
  <div class="product-card">
    <div class="product-card__img">
      <cx-media [container]="product.images?.PRIMARY" format="product" [alt]="product?.name"></cx-media>
    </div>
    <div class="product-card__product-info">
      <!-- MOSTRAR CUCARDA SI NO HAY STOCK O SI HAY PROMO -->
      <ng-container *ngIf="!isMobile; else isMobileSection">
        <ng-container *ngTemplateOutlet="badge"></ng-container>
        <p class="product-card__product-info__brand">{{ product.brand?.name }}</p>
        <p class="product-card__product-info__name">
          {{ product?.name | titlecase }}
        </p>
        <p class="product-card__product-info__sku">
          <ng-container *ngIf="!isInPromeclub">
            {{ "productList.sku" | cxTranslate }}{{ product.code }}
          </ng-container>
          <ng-container *ngIf="isInPromeclub">
            {{ "productList.code" | cxTranslate }}{{ product?.promotickWebCode }}
          </ng-container>
        </p>
      </ng-container>

      <ng-template #isMobileSection>
        <p class="product-card__product-info__brand">{{ product.brand?.name }}</p>
        <p class="product-card__product-info__sku">
          <ng-container *ngIf="!isInPromeclub">
            {{ "productList.sku" | cxTranslate }}{{ product.code }}
          </ng-container>
          <ng-container *ngIf="isInPromeclub">
            {{ "productList.code" | cxTranslate }}{{ product?.promotickWebCode }}
          </ng-container>
        </p>
        <p class="product-card__product-info__name">
          {{ product?.name | titlecase }}
        </p>
        <ng-container *ngTemplateOutlet="badge"></ng-container>
      </ng-template>
    </div>
  </div>
</ng-container>

<ng-template #badge>
  <ng-container *ngIf="product?.stock?.stockLevelStatus === 'outOfStock'">
    <div class="product-info__badges">
      <app-custom-gift-product label="{{ 'addToCart.outOfStock' | cxTranslate }}"></app-custom-gift-product>
    </div>
  </ng-container>
  <ng-container *ngIf="product?.stock?.stockLevelStatus != 'outOfStock' && product?.badge">
    <app-custom-gift-product [label]="product?.badge" class="product-card__product-info__badge">
    </app-custom-gift-product>
  </ng-container>
</ng-template>
