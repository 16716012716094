import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomOrderCancellationService {

  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
  ) {
  }

  getCancelOrder(orderId: string, cancellationRequestEntryInputs: any[]): Observable<any> {
    const url = this.occEndpointService.buildUrl('orderCancellation', {
      urlParams: {
        code: orderId
      }
    });
    return this.http.post(url, {cancellationRequestEntryInputs})
  }


}
