import { Component, ViewEncapsulation, ElementRef } from '@angular/core';
import { AuthService, CmsNavigationComponent, CmsService } from '@spartacus/core';
import {
  CmsComponentData,
  FooterNavigationComponent,
  NavigationNode
} from '@spartacus/storefront';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { CustomGtmSearchSelectionDatalayerService } from 'src/app/common/services/custom-gtm-search-selection-datalayer.service';
import { CustomGetImageUrlService } from 'src/app/services/custom-get-image-url.service';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';
import { CustomNavigationService } from '../../../navigation/services/custom-navigation.service';
import { map } from 'rxjs/operators';
import { CustomBaseSiteCheckerService } from 'src/app/services/custom-base-site-checker.service';

@Component({
  selector: 'app-custom-footer-bar-navigation',
  templateUrl: './custom-footer-bar-navigation.component.html',
  styleUrls: ['./custom-footer-bar-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomFooterBarNavigationComponent extends FooterNavigationComponent {
  navigationNode$: Observable<NavigationNode | null> =
    this.service.customGetNavigationNode(this.componentData.data$);
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  footerData$: Observable<CmsNavigationComponent> = this.componentData.data$;
  subscription: Subscription = new Subscription();
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: CustomNavigationService,
    protected customGetImageUrl: CustomGetImageUrlService,
    protected customBreakpointService: CustomBreakpointService,
    protected cmsService: CmsService,
    private customGtmDatalayerService: CustomGtmSearchSelectionDatalayerService,
    private auth: AuthService,
    private el: ElementRef,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService,
  ) {
    super(componentData, service);
  }

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([this.auth.isUserLoggedIn(), this.cmsService.getCurrentPage(), this.isMobile$]).pipe(
        map(([isUserLoggedIn, currentPage, isMobile]) => {
          if(!isUserLoggedIn && currentPage?.pageId === 'productDetails' && !this.isPromeclub && isMobile){
            this.el?.nativeElement?.parentElement?.classList?.add('not-logged');
          }else{
            this.el?.nativeElement?.parentElement?.classList?.remove('not-logged');
          }
        })
      ).subscribe()
    )
  }
  getImageUrl(url: string): string {
    return this.customGetImageUrl.getImageUrl(url);
  }

  footerEventClick(event: string) {
    event ? this.customGtmDatalayerService.footerEvent(event) : false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
