
<div class="custom-my-points-account__title">
  <app-custom-title-my-account
    [title]="(!isSeller ? 'creditPayment.myPointsTitle': 'creditPayment.myPointsTitleSeller') | cxTranslate"
    [lessMargin]="true"
  ></app-custom-title-my-account>

  <ng-container *ngIf="availablePoints$ | async as points">
    <div class="custom-my-points-account">
      <div class="custom-my-points-account__credit">
        <label>{{ (!isSeller ? "creditPayment.myPromeclubPoints" : "creditPayment.myPrometasPoints") | cxTranslate }}</label>
        <span>{{ points | number: "":"es-AR" }}</span>
      </div>
      <div class="custom-my-points-account__btn">
        <a [href]="promeClubOrders" target="_self">
          {{ "creditPayment.promeclubStatusAccount" | cxTranslate }}
        </a>
      </div>
    </div>
  </ng-container>
</div>
