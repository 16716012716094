import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomInformationComponent } from './custom-information.component';
import { IconModule } from '@spartacus/storefront';



@NgModule({
  declarations: [
    CustomInformationComponent
  ],
  imports: [
    CommonModule,
    IconModule
  ],
  exports: [
    CustomInformationComponent
  ]
})
export class CustomInformationModule { }
