export const customCart = {
  cart: {
    saveForLaterItems: {
      savedCartNamePlaceholder: 'Ingresar el nombre de la lista',
      listCreated:
        'Para visualizar tus listas de compras creadas dirígete a la sección Mi cuenta.',
      linkCartList: 'Ir a mis listas de compras',
      listCreatedTitle: 'Lista de compra creada',
      emptyCart: 'Vaciar carrito',
      emptyWish: 'Vaciar favoritos',
      salePercentage: 'Venta',
      cancel: 'Cancelar',
      areYouSure: '¿Seguro quieres vaciar el carrito?',
      areYouSureWishlist: '¿Seguro quieres vaciar tus favoritos?',
    },
    orderCost: {
      products: 'Productos',
    },
    cartItems: {
      giftItem: 'Por la compra de {{name}}',
      freeItem: 'Gratis',
    },
  },
  updatedPrice: {
    updatedPrice: {
      priceVariation: 'Variaciones de precios',
      importantMessages: 'Mensajes importantes sobre artículos en tu carrito',
      text: 'Los productos de tu carrito reflejan el precio más reciente.',
      updatedProducts:
        '{{count}} productos de tu carrito han cambiado el precio.',
      updatedProduct: '{{count}} producto de tu carrito ha cambiado el precio.',
      previousPrice: 'Precio anterior {{count}}',
    },
  },
  entryPromeclub: {
    entryPromeclub: {
      email: 'E-mail de destino:',
      invalidEmail: 'E-mail no válido.',
      emailPlaceholder: 'Ingresar e-mail',
      cellphone: 'Celular de destino:',
      cellphonePlaceholder: 'Ingresar número de celular',
      invalidCellphone: 'Número de celular no válido.',
      decoder: 'Decodificador de destino:',
      decoderPlaceholder: 'Ingresar número de decodificador',
      invalidDecoder: 'Número de decodificador no válido.',
    }
  },
  futureStock: {
    futureStock: {
      btnProductDetail: 'Notificarme cuando este disponible',
      msgDateFutureStock: 'Fecha tentativa de reposición del producto: <span>{{ date }}</span>',
      title: '¡Alerta creada con éxito!',
      text: 'Te enviaremos un e-mail cuando contemos nuevamente con stock disponible para este producto.'
    }
  }
};
