import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomModalModule } from 'src/app/cms-components/shared/components/custom-modal/custom-modal.module';
import { CustomPointsHeaderComponent } from './custom-points-header.component';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    CustomModalModule,
    I18nModule
  ],
  declarations: [CustomPointsHeaderComponent],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        MyPointsComponent : {
          component: CustomPointsHeaderComponent
        }
      }
    })
  ]
})
export class CustomPointsHeaderModule { }
