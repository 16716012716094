export const customProduct = {
  product: {
    productList: {
      sku: 'SKU: ',
      code: 'Cod. ',
      plusIva: 'Incluye IVA',
      points: 'Puntos',
      add: 'Agregar',
      seeMore: 'Ver más',
      seeLess: 'Ver menos',
      clearFilters: 'Limpiar filtros',
      name: 'Nombre',
      price: 'Precio',
      seeDetails: 'Ver detalle del producto',
      description: 'Descripción',
      seeDescription: 'Ver descripción',
      descriptionMobile: 'Descripción:',
    },
    productDetails: {
      available: 'Disponible',
      pluralAvailable: 'Disponibles',
      seeAll: 'Ver todos',
      seeLess: 'Ver menos',
      title: 'Incluye',
      shareProduct: 'Compartir ficha de producto',
      shareProductPromeclub: 'Compartir ficha del premio',
      copyLink: 'Copiar link',
      email: 'Email',
      whatsapp: 'WhatsApp',
      facebook: 'Facebook',
      automotiveApplications: 'Aplicaciones Automotrices',
    },
    TabPanelContainer: {
      downloadsDocuments: 'Guías y Manuales',
      download: 'Descargar',
      manualProducts: 'Manual de uso',
      technicalDocuments: 'Ficha técnica',
      termsAndConditions: 'Términos y condiciones',
      productTermsAndConditions:
        'Aplica 1 año de garantía por defecto de fábrica. Para mayor información contactarse al 1800-CANJES (226537)',
    },
    productReview: {
      createReview: 'Crear',
      newReview: 'Nueva reseña',
      lastReviews: 'Últimas reseñas',
      showMoreReviews: 'Cargar más reseñas',
      showLessReviews: 'Mostrar menos reseñas',
      addReview: 'Agregar <b>Reseña<b>',
      addReviewMobile: 'Agregar reseña',
      productExperience: '¿Quieres compartir algún comentario y/o experiencia?',
      placeholderComment: 'Escribir comentario',
      valoration: '¿Qué valoración le otorgas?',
      sendReview: 'Enviar reseña',
      globalRating: 'calificaciones globales',
      title: 'Incluye',
      reviewSent: 'Reseña enviada',
    },
    addToCart: {
      updated: 'Actualizado',
      customOutOfStock: 'Stock no disponible',
      outOfStockMessage:
        'Lo sentimos, actualmente no contamos con stock disponible para este producto.',
    },
    automotiveApplications: {
      title: 'Aplicaciones Automotrices',
    },
  },
};
