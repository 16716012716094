import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Facet, ProductSearchPage, WindowRef } from '@spartacus/core';
import {
  FacetListComponent,
  FacetService,
  ProductListComponentService,
} from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { delay, filter, map, pluck, tap } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-facet-list',
  templateUrl: './custom-facet-list.component.html',
  styleUrls: ['./custom-facet-list.component.scss'],
})
export class CustomFacetListComponent
  extends FacetListComponent
  implements OnInit, OnDestroy
{
  isMobile$: Observable<boolean>;
  facets$: Observable<Facet[] | undefined>;
  @Input() model: ProductSearchPage;
  isVariantPlpType: boolean = this.router.url.includes('plpType=variant');
  topValueCount: number = 5;
  subscription: Subscription = new Subscription();
  showFacet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    facetService: FacetService,
    elementRef: ElementRef,
    renderer: Renderer2,
    private customBreakpointService: CustomBreakpointService,
    private productListComponentService: ProductListComponentService,
    private router: Router,
    protected winRef: WindowRef
  ) {
    super(facetService, elementRef, renderer);
  }

  ngOnInit(): void {
    this.isMobile$ = this.customBreakpointService.isMobile$.pipe(
      tap((isMobile) => {
        if (isMobile) {
          const el = this.getTopComponent();
          if (el) {
            setTimeout(() => {
              el.scrollIntoView();
            }, 0);
          }
        }
      })
    );
    this.facets$ = this.facetList$.pipe(
      pluck('facets'),
      map((facets) =>
        facets.map((f) => (f = { ...f, topValueCount: this.topValueCount }))
      ),
      map((facet) =>
        facet.sort((a, b) =>
          a.priority != undefined && b.priority != undefined
            ? a.priority - b.priority
            : 1
        )
      )
    );
    this.subscription.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe((nav) => {
          this.loading$.next(true);
        })
    );

    this.subscription.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          delay(1000)
        )
        .subscribe((nav) => {
          this.loading$.next(false);
        })
    );
  }

  private getTopComponent(): Element | null {
    const activeFacets = this.winRef.document.querySelector('.active-facets');
    const defaultFacet = this.winRef.document.querySelector(
      '.filter-price-facets__custom'
    );
    return activeFacets != null ? activeFacets : defaultFacet;
  }

  ngOnDestroy(): void {
    this.close();
  }

  sortList(sortCode: string): void {
    this.productListComponentService.sort(sortCode);
  }

  toggleFacet(): void {
    this.showFacet$.next(!this.showFacet$.value);
  }

  cancelFacets() {
    const arrQuery = this.router.url.includes('query')
      ? this.router.url.split('?query')
      : this.router.url.split('?sortCode');
    this.router.navigateByUrl(arrQuery[0]);
  }
}
