import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import {
  ResetPasswordComponentService,
  UpdatePasswordComponentService,
  UpdateProfileComponentService
} from '@spartacus/user/profile/components';
import { CustomSafeHtmlModule } from 'src/app/pipes/custom-safe-html/custom-safe-html.module';
import { CustomClaimModule } from './components/custom-claim/custom-claim.module';
import { CustomConfirmationPasswordModule } from './components/custom-confirmation-password/custom-confirmation-password.module';
import { CustomCreditLineModule } from './components/custom-credit-line/custom-credit-line.module';
import { CustomMyAccountModule } from './components/custom-my-account/custom-my-account.module';
import { CustomMyPointsModule } from './components/custom-my-points/custom-my-points.module';
import { CustomOrderCancelConfirmationModule } from './components/custom-order-cancel-confirmation/custom-order-cancel-confirmation.module';
import { CustomOrderCancelHistoryModule } from './components/custom-order-cancel-history/custom-order-cancel-history.module';
import { CustomOrderCancelModule } from './components/custom-order-cancel/custom-order-cancel.module';
import { CustomOrderDetailsModule } from './components/custom-order-details/custom-order-details.module';
import { CustomOrderHistoryModule } from './components/custom-order-history/custom-order-history.module';
import { CustomPaymentRecordModule } from './components/custom-payment-record/custom-payment-record.module';
import { CustomPromeclubAddressModule } from './components/custom-promeclub-address/custom-promeclub-address.module';
import { CustomPromeclubUpdateProfileModule } from './components/custom-promeclub-update-profile/custom-promeclub-update-profile.module';
import { CustomQuotesModule } from './components/custom-quotes/custom-quotes.module';
import { CustomRebateListModule } from './components/custom-rebate-list/custom-rebate-list.module';
import { CustomReturnOrderListModule } from './components/custom-return-order-list/custom-return-order-list.module';
import { CustomReturnOrderModule } from './components/custom-return-order/custom-return-order.module';
import { CustomSavedCartModule } from './components/custom-shopping-list-details/custom-saved-cart.module';
import { CustomShoppingListModule } from './components/custom-shopping-list/custom-shopping-list.module';
import { CustomUpdateProfileModule } from './components/custom-update-profile/custom-update-profile.module';

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule,
    IconModule,
    RouterModule,
    I18nModule,
    CustomSafeHtmlModule,
    CustomUpdateProfileModule,
    CustomOrderHistoryModule,
    CustomOrderDetailsModule,
    CustomOrderCancelModule,
    CustomOrderCancelConfirmationModule,
    CustomMyAccountModule,
    CustomConfirmationPasswordModule,
    CustomPromeclubUpdateProfileModule,
    CustomOrderCancelHistoryModule,
    CustomCreditLineModule,
    CustomMyPointsModule,
    CustomPromeclubAddressModule,
    CustomPaymentRecordModule,
    CustomRebateListModule,
    CustomReturnOrderModule,
    CustomReturnOrderListModule,
    CustomQuotesModule,
    CustomClaimModule,
    CustomShoppingListModule,
    CustomSavedCartModule,
  ],
  providers: [
    {
      provide: UpdateProfileComponentService,
    },
    {
      provide: ResetPasswordComponentService,
    },
    {
      provide: UpdatePasswordComponentService,
    },
  ],
})
export class MyAccountModule {}
