import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ConverterService,
  OccEndpointsService,
  OccProductSearchAdapter,
  PRODUCT_SEARCH_PAGE_NORMALIZER,
  ProductSearchPage,
  SearchConfig,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { CustomProductListComponentService } from '../../../cms-components/product/components/custom-product-list/services/custom-product-list-component.service';

const DEFAULT_SEARCH_CONFIG: SearchConfig = {
  pageSize: 20,
};
@Injectable({
  providedIn: 'root',
})
export class CustomOccProductSearchAdapter extends OccProductSearchAdapter {
  readonly PAGE_SIZE_SEARCHBOX: string | number = '5';
  constructor(
    http: HttpClient,
    occEndpoints: OccEndpointsService,
    converter: ConverterService,
    private route: ActivatedRoute,
    private customProductListComponentService: CustomProductListComponentService
  ) {
    super(http, occEndpoints, converter);
  }

  search(
    query: string,
    searchConfig: SearchConfig = DEFAULT_SEARCH_CONFIG,
    calculatePrices: boolean = false
  ): Observable<ProductSearchPage> {
    const calculate =
      searchConfig?.pageSize == this.PAGE_SIZE_SEARCHBOX
        ? true
        : calculatePrices;
    if (!query?.includes('allCategories:')) {
      this.customProductListComponentService.setCurrentView('grid');
    }

    return this._customGetSearchEndpoint(query, searchConfig, calculate).pipe(
      switchMap((endpoint) => this.http.get(endpoint)),
      this.converter.pipeable(PRODUCT_SEARCH_PAGE_NORMALIZER)
    );
  }

  protected _customGetSearchEndpoint(
    query: string,
    searchConfig: SearchConfig,
    calculatePrices: boolean
  ): Observable<string> {
    return this.route.queryParams.pipe(
      take(1),
      map(({ plpType, searchQueryContext }) =>
        this.occEndpoints.buildUrl('productSearch', {
          queryParams: {
            query,
            plpType,
            calculatePrices,
            searchQueryContext,
            ...searchConfig,
          },
        })
      )
    );
  }
}
