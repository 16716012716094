import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ICON_TYPE, ModalService } from '@spartacus/storefront';
import { Product } from '@spartacus/core';
import { Router } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';
import {
  EngagementProducto,
  SocialMedia,
} from '../../../model/custom-gtmProduct.model';
import { CustomGtmInteractionDatalayerService } from 'src/app/common/services/custom-gtm-interaction-datalayer.service';

@Component({
  selector: 'app-custom-social-share-content-dialog',
  templateUrl: './custom-social-share-content-dialog.component.html',
  styleUrls: ['./custom-social-share-content-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomSocialShareContentDialogComponent implements OnInit {
  iconTypes = ICON_TYPE;
  domainUrl: string = window.location.href;
  socialIcons = [
    {
      class: 'fa-regular fa-envelope',
      type: 'EMAIL',
      translation: 'productDetails.email',
      url: '',
    },
    {
      class: 'fa-regular  fa-envelope',
      type: 'WHATSAPP',
      translation: 'productDetails.whatsapp',
      url: '',
    },
    {
      class: 'fa-regular  fa-envelope',
      type: 'FACEBOOK',
      translation: 'productDetails.facebook',
      url: '',
    },
  ];
  product: Product;
  isInPromeclub: boolean = this.service.isInPromeClub();
  isMobile: boolean = false;
  productUrl: string;
  private ngbModalElement: HTMLElement;
  constructor(
    private router: Router,
    private location: Location,
    private modalService: ModalService,
    private service: CustomNavigationService,
    @Inject(DOCUMENT) private document: Document,
    private customGtmDatalayerService: CustomGtmInteractionDatalayerService
  ) {}

  ngOnInit(): void {
    this.productUrl = `${this.domainUrl?.replace(
      this.location?.path(),
      ''
    )}${this.isInPromeclub?'/promeclubSite':''}/p/${this.product?.code}`;

    this.productUrl = encodeURI(this.productUrl);
    this.productUrl = this.productUrl.split('"').join('');
    this.productUrl = this.productUrl.split('%22').join('');

    this.socialIcons[0].url = this.generateMailUrl();
    this.socialIcons[1].url = this.generateWhatsAppUrl();
    this.socialIcons[2].url = this.generateFacebookUrl();
    this.ngbModalElement = <HTMLElement>(
      this.document.getElementsByClassName('modal-content')[0]
    );
    this.setModalContentStyle();
  }

  generateMailUrl(): string {
    return `mailto:?subject=${this.product.name}&body=${this.productUrl}`;
  }

  generateFacebookUrl(): string {
    return `http://www.FACEBOOK.com/sharer.php?u=${this.productUrl}`;
  }

  generateWhatsAppUrl(): string {
    return `https://api.whatsapp.com/send/?text=${this.productUrl}&type=phone_number&app_absent=0`;
  }

  copyProductUrlToClipboard() {
    this.customGtmDatalayerService.productInteractionEvent(
      EngagementProducto.SHARE_CARD,
      SocialMedia.COPY_LINK
    );
    navigator.clipboard.writeText(this.productUrl);
  }

  onCopySocialMedia(event: string) {
    switch (event) {
      case 'EMAIL':
        this.customGtmDatalayerService.productInteractionEvent(
          EngagementProducto.SHARE_CARD,
          SocialMedia.EMAIL
        );
        break;
      case 'FACEBOOK':
        this.customGtmDatalayerService.productInteractionEvent(
          EngagementProducto.SHARE_CARD,
          SocialMedia.FACEBOOK
        );
        break;
      case 'WHATSAPP':
        this.customGtmDatalayerService.productInteractionEvent(
          EngagementProducto.SHARE_CARD,
          SocialMedia.WHATSAPP
        );
        break;
    }
  }

  closePopup() {
    this.modalService.closeActiveModal();
  }

  setModalContentStyle() {
    this.ngbModalElement.style.height = 'fit-content';
    this.ngbModalElement.style.width = 'fit-content';
    this.ngbModalElement.style.maxWidth = '97%';
    this.ngbModalElement.style.margin = 'auto';
    if (this.isMobile) {
      this.ngbModalElement.style.marginTop = '88px';
    }
  }
}
