import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, ModalModule } from '@spartacus/storefront';
import { CustomSafeHtmlModule } from '../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomLoadingSpinnerModule } from '../../custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomModalGenericModule } from '../../custom/components/custom-modal/custom-modal-generic.module';
import { CustomAddToCartModule } from '../custom-add-to-card/custom-add-to-cart.module';
import { CustomRemoveFromCartDialogComponent } from './custom-remove-from-cart-dialog.component';



@NgModule({
  declarations: [
    CustomRemoveFromCartDialogComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    UrlModule,
    CustomSafeHtmlModule,
    RouterModule,
    CustomAddToCartModule,
    ModalModule,
    CustomLoadingSpinnerModule,
    CustomModalGenericModule
  ],
  exports: [
    CustomRemoveFromCartDialogComponent
  ]
})
export class CustomRemoveFromCartDialogModule { }
