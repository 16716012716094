import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  ActiveCartService,
  Cart,
  CmsService,
  OrderEntry,
  WindowRef,
} from '@spartacus/core';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';
import { CustomGtmEcommerceDatalayerService } from 'src/app/common/services/custom-gtm-ecommerce-datalayer.service';
import { CustomCart } from '../../../cart/model/custom-cart';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-entries-mini-cart',
  templateUrl: './custom-entries-mini-cart.component.html',
  styleUrls: ['./custom-entries-mini-cart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomEntriesMiniCartComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  entries$: Observable<OrderEntry[]>;
  @Input() cantMaxItems: number;
  @Input() entriesQuantity: number;
  @Input() entriesCart: OrderEntry[] = [];
  @Input() clickedFrom: string;
  @Input() isInCheckout: boolean = false;
  @Output() scrollShowMore = new EventEmitter<any>();
  isInPromeclub: boolean = this.service.isInPromeClub();
  scrollActive: boolean = false;
  cantIncrDesc: number;
  cart$: Observable<Cart | CustomCart> = this.activeCartService.getActive();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  private showCartSummary = new Subject<boolean>();
  public showCartSummary$: Observable<boolean> =
    this.showCartSummary.asObservable();

  constructor(
    private activeCartService: ActiveCartService,
    private winRef: WindowRef,
    private service: CustomNavigationService,
    protected cmsService: CmsService,
    private gmtService: CustomGtmEcommerceDatalayerService,
    protected customBreakpointService: CustomBreakpointService
  ) {}

  ngOnInit(): void {
    this.cantIncrDesc = this.cantMaxItems;
    this.setShowCartSummary(false);
  }

  getEntriesCartActive(): Observable<OrderEntry[]> {
    return this.activeCartService.getEntries();
  }

  setShowCartSummary(show: boolean): Observable<OrderEntry[]> {
    const addedToCartIsOpen = this.winRef.document.body.classList.contains(
      'cart-pop-up-wrapper'
    );
    if (!addedToCartIsOpen) {
      this.showCartSummary.next(show);
    }
    return (this.entries$ = this.getEntriesCartActive().pipe(
      tap(() => (this.entriesQuantity = 0)),
      tap((entries) => (this.entriesQuantity = entries.length)),
      map((entries) => entries.slice(0, this.cantMaxItems))
    ));
  }

  onShowMore() {
    this.cantMaxItems = this.cantMaxItems + this.cantIncrDesc;
    this.scrollShowMore.emit(true);
    this.entries$ = this.getEntriesCartActive().pipe(
      map((entries) => [...entries!] ?? []),
      map((entries) => entries.slice(0, this.cantMaxItems)),
      tap((data) => (this.entriesCart = data))
    );
    if (this.entriesCart?.length > 0) {
      this.subscription.add(this.entries$.subscribe());
    }
  }

  onShowAll() {
    this.entries$ = this.getEntriesCartActive().pipe(
      map((entries) => [...entries!] ?? []),
      map((entries) => entries),
      tap((data) => (this.entriesCart = data))
    );
    if (this.entriesCart?.length > 0) {
      this.subscription.add(this.entries$.subscribe());
      this.cantMaxItems = this.entriesCart?.length;
    }
  }

  onShowLess() {
    this.scrollShowMore.emit(false);
    this.entries$ = this.getEntriesCartActive().pipe(
      map((entries) => [...entries!] ?? []),
      map((entries) => entries.slice(0, this.cantIncrDesc)),
      tap((data) => (this.entriesCart = data))
    );
    if (this.entriesCart?.length > 0) {
      this.subscription.add(this.entries$.subscribe());
      this.cantMaxItems = this.cantIncrDesc;
    }
  }

  removeItem(entry: OrderEntry) {
    this.activeCartService.removeEntry(entry);
    this.activeCartService.reloadActiveCart();
    this.activeCartService
      .getEntries()
      .pipe(
        take(1),
        map((entries) => [...entries!] ?? []),
        map((entries) =>
          entries.filter((ent) => ent.product?.code !== entry.product?.code)
        ),
        tap((entries) =>
          entries.length < this.cantMaxItems - this.cantIncrDesc &&
          this.cantMaxItems > this.cantIncrDesc
            ? (this.cantMaxItems = this.cantMaxItems - this.cantIncrDesc)
            : this.cantMaxItems
        ),
        map(
          (entries) => (this.entries$ = of(entries.slice(0, this.cantMaxItems)))
        )
      )
      .subscribe();
    this.gmtService.removeFromCartEvent(entry);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
