import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule, UrlModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomAccountTableComponent } from './custom-account-table.component';



@NgModule({
  declarations: [
    CustomAccountTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    UrlModule,
    I18nModule,
    IconModule,
    CustomCurrencyModule,
  ],
  exports: [
    CustomAccountTableComponent
  ]
})
export class CustomAccountTableModule { }
