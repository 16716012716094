import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-loading-spinner-item',
  templateUrl: './custom-loading-spinner-item.component.html',
  styleUrls: ['./custom-loading-spinner-item.component.scss']
})
export class CustomLoadingSpinnerItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
