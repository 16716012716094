<div class="customer-employee__personal-data">
  <h3 class="customer-employee__personal-data-title">
    {{'registerEmployee.personalData' | cxTranslate}}
  </h3>
  <div class="customer-employee__personal-data-container">
    <div class="customer-employee__personal-data-container-item">
      <p class="customer-employee__personal-data-container-item__text name-employee" *ngIf="clientInfo?.businessName">
        {{'registerEmployee.firstNameEmployee' | cxTranslate}} {{clientInfo?.businessName}}
      </p>

      <!-- <p class="customer-employee__personal-data-container-item__text" *ngIf="clientInfo?.lastName">
        {{'registerEmployee.lastNameEmployee' | cxTranslate}} {{clientInfo?.lastName}}
      </p> -->
    </div>

    <div class="customer-employee__personal-data-container-item">
      <p class="customer-employee__personal-data-container-item__text" *ngIf="clientInfo?.ruc">
        {{'registerEmployee.idEmployee' | cxTranslate}} {{clientInfo?.ruc}}
      </p>

      <p class="customer-employee__personal-data-container-item__text" *ngIf="clientInfo?.clientEmail">
        {{'registerEmployee.emailEmployee' | cxTranslate}} {{clientInfo?.clientEmail | customEmailRegisterMask: true}}
      </p>
    </div>
  </div>
</div>
