<ng-container *ngIf="{ form: form$ | async, order: order$ | async, isMobile: isMobile$ | async } as data">
  <div class="custom-order-cancel-conf">
    <app-custom-title-my-account
      [title]="
        'orderDetails.cancellationAndReturn.confirmCancelTitle'
          | cxTranslate: { orderCode: orderCode }
      "
      *ngIf="orderCode"
    ></app-custom-title-my-account>
    <label class="custom-order-cancel-conf__subtitle"
      >{{ data.isMobile ? ('orderDetails.cancellationAndReturn.detailCancelMobile' | cxTranslate) :
      ('orderDetails.cancellationAndReturn.detailCancel' | cxTranslate )}}
    </label>

    <app-custom-order-detail-shipping
    [showTitle]="false"
    ></app-custom-order-detail-shipping>
    <div class="custom-order-cancel-conf__details">
      <app-custom-order-detail-items></app-custom-order-detail-items>
      <app-custom-order-detail-totals></app-custom-order-detail-totals>
    </div>
  </div>
  <app-custom-amend-order-actions
    *ngIf="orderCode"
    [orderCode]="orderCode"
    [amendOrderForm]="data.form"
    backRoute="ordersCancel"
    ></app-custom-amend-order-actions>
  </ng-container>
