import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomRecaptchaService } from '../../services/custom-recaptcha.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-security-validation',
  templateUrl: './security-validation.component.html',
  styleUrls: ['./security-validation.component.scss']
})
export class SecurityValidationComponent implements OnInit {

  subscription: Subscription = new Subscription();

  captchaForm: FormGroup = this.fb.group(
    {
      recaptchaReactive: [null, [Validators.required]],
    }
  );

  constructor(
    private fb: FormBuilder,
    private customRecaptchaService: CustomRecaptchaService
  ) {}

  ngOnInit(): void {
   this.subscription.add( this.captchaForm.valueChanges.subscribe(
      value =>
          this.customRecaptchaService.setCaptchaStatus(
            !!(value?.recaptchaReactive && value?.recaptchaReactive !== null)
            )
    ));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
