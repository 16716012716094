import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customEmailRegisterMask',
})
export class CustomEmailRegisterMaskPipe implements PipeTransform {

  transform(email: string, doubleEncrypt: boolean = false ): any {
    let parsedEmail = email.split('@')[0];
    let domain = email.split('@')[1];
    let parsedEmailLength = parsedEmail.length - 1;
    parsedEmail = parsedEmail[0];
    for (let index = 0; index < parsedEmailLength; index++) {
      parsedEmail = parsedEmail + '*';
    }
    if (doubleEncrypt) {
      let domainEncryption = domain.split('.')[0];
      let domainSlow = domain.split('.')[1];
      let domainLength = domainEncryption.length - 1;
      domainEncryption = domainEncryption[0];
      for (let index = 0; index < domainLength; index++) {
        domainEncryption = domainEncryption + '*';
      }
      domain = `${domainEncryption}.${domainSlow}`;
    }
    return `${parsedEmail}@${domain}`;

  }
}
