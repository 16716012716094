import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { delay, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AddToCartErrorsService {
  private errorSubject = new Subject<any>();
  private error$ = this.errorSubject.asObservable();

  public next(error: any) {
    this.errorSubject.next(error);
  }

  public getErrors(): Observable<string> {
    return this.error$.pipe(
      delay(0),
      map((error) => {
        let errorCode = error.message ? error.message : '';
        return errorCode;
      })
    );
  }
}
