import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  OccEndpointsService,
  ReturnRequest
} from '@spartacus/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomReturnRequestService {

  constructor(
    private occEndpointService: OccEndpointsService,
    private http: HttpClient,
    protected activatedroute: ActivatedRoute,
    private router: Router
  ) {
  }

  reloadOrderDevolution$: Subject<any> = new Subject<any>();

  onReloadOrderDevolution() {
    this.reloadOrderDevolution$.next();
  }

  public getOrderReturnDetail(): Observable<ReturnRequest> {
    const route = this.router.url.split('/')[3].split('?')[0];
    const url = this.occEndpointService.buildUrl('orderReturnDetail', {
      urlParams: {
        userId: 'current',
        returnRequestCode: route,
      },
    });
    return this.http.get<ReturnRequest>(url)
  }

}
