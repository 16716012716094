import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CustomRegisterEmployeeValidationDialogComponent } from './custom-register-employee-validation-dialog/custom-register-employee-validation-dialog.component';
import { IconModule } from '@spartacus/storefront';
import { CustomRegisterEmployeeValidationComponent } from './custom-register-employee-validation/custom-register-employee-validation.component';
import { CustomRegisterEmployeeComponent } from './custom-register-employee.component';
import { CustomRegisterEmployeeAccountComponent } from './custom-register-employee-account/custom-register-employee-account.component';
import { CustomRegisterSharedModule } from '../custom-register-shared/custom-register-shared.module';
import { CustomRegisterEmployeeAccountDialogComponent } from './custom-register-employee-account/custom-register-employee-account-dialog/custom-register-employee-account-dialog.component';
import { CustomRegisterCustomerModule } from '../custom-register-customer/custom-register-customer.module';



@NgModule({
  declarations: [
    CustomRegisterEmployeeComponent,
    CustomRegisterEmployeeValidationDialogComponent,
    CustomRegisterEmployeeValidationComponent,
    CustomRegisterEmployeeAccountComponent,
    CustomRegisterEmployeeAccountDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    IconModule,
    CustomRegisterSharedModule,
    CustomRegisterCustomerModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromesaRegisterInternalCustomerComponent: {
          component: CustomRegisterEmployeeComponent,
        },
      },
    }),
  ],
  exports: [
    CustomRegisterEmployeeComponent,
    CustomRegisterEmployeeValidationDialogComponent,
    CustomRegisterEmployeeValidationComponent,
    CustomRegisterEmployeeAccountDialogComponent
  ]
})
export class CustomRegisterEmployeeModule { }
