import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CmsComponent, CmsService, OccEndpointsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { map, pluck, take } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { environment } from 'src/environments/environment';
import { CustomTopLinkService } from '../../../../services/custom-top.link.service';
import {
  CustomCmsLinkComponent,
  CustomTopLink,
} from './model/custom-top-link.interface';
import { PromesaSection } from 'src/app/common/models/promesa-section';

@Component({
  selector: 'app-custom-top-link',
  templateUrl: './custom-top-link.component.html',
  styleUrls: ['./custom-top-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomTopLinkComponent implements OnInit, OnDestroy {
  isPromeclub: boolean = this.occEndpointService
    .getBaseUrl()
    .includes('promeclubSite');
  items$: Observable<Observable<CmsComponent | null>[]>;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  unsubscribe$: Subject<any> = new Subject();
  currentSection: string | null;
  PromesaSection = PromesaSection;
  constructor(
    private componentData: CmsComponentData<CustomTopLink>,
    private cmsService: CmsService,
    private customBreakpointService: CustomBreakpointService,
    private router: Router,
    private customTopLinkService: CustomTopLinkService,
    protected occEndpointService: OccEndpointsService
  ) {}

  ngOnInit(): void {
    this.items$ = this.componentData.data$.pipe(
      take(1),
      pluck('links'),
      map((data: string) => {
        return data
          .split(' ')
          .map((uid: string) =>
            this.cmsService.getComponentData<CustomCmsLinkComponent>(uid)
          );
      })
    );
    if (!this.isPromeclub) {
      this.currentSection =
        window.location.pathname == '/'
          ? environment.defaultSection
          : this.customTopLinkService.getCurrentSection();
    }
  }

  changeSection(item: any) {
    this.customTopLinkService.changeCurrentSection(item.section);
    this.currentSection = item.section;
    if (!this.isPromeclub) {
      this.router.navigateByUrl(item.url).then((navigate) => {
        if (navigate) {
          window.location.reload();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
