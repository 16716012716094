import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import {
  CmsComponentData,
  PageLayoutService,
  ProductListComponent,
  ProductListComponentService,
  ViewConfig,
  ViewModes,
} from '@spartacus/storefront';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { tap, take, debounceTime } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomHeaderCategoriesService } from 'src/app/services/custom-header-categories.service';
import { CustomProductListComponentService } from './services/custom-product-list-component.service';
import { CategoriesBreadcrumb } from '../../../navigation/model/categoriesBreadcrumb';
import { CustomGtmEcommerceDatalayerService } from 'src/app/common/services/custom-gtm-ecommerce-datalayer.service';
import { CustomBreadcrumbService } from '../../../navigation/services/custom-breadcrumb.service';
import { AutomotiveFilterService } from 'src/app/services/automotive-filter.service';

@Component({
  selector: 'app-custom-product-list',
  templateUrl: './custom-product-list.component.html',
  styleUrls: ['./custom-product-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomProductListComponent
  extends ProductListComponent
  implements OnInit, OnDestroy
{
  isMobile$: Observable<boolean>;
  isMobile: boolean;
  viewModes = ViewModes;
  customSubscription: Subscription = new Subscription();
  clickedFrom: string;
  isSearchResultsList: boolean = false;
  activeQuery: string;
  automotiveFilterQuery: string;
  constructor(
    pageLayoutService: PageLayoutService,
    productListComponentService: ProductListComponentService,
    scrollConfig: ViewConfig,
    protected breakpointService: CustomBreakpointService,
    protected cd: ChangeDetectorRef,
    protected router: Router,
    protected customHeaderCategoriesService: CustomHeaderCategoriesService,
    protected customProductListComponentService: CustomProductListComponentService,
    protected componentData: CmsComponentData<any>,
    private customGtmDatalayerService: CustomGtmEcommerceDatalayerService,
    private customBreadcrumbService: CustomBreadcrumbService,
    private automotiveFilterService: AutomotiveFilterService,
    private activatedRoute: ActivatedRoute
  ) {
    super(pageLayoutService, productListComponentService, scrollConfig);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initializeAutomotiveQueryData();
    this.isMobile$ = this.breakpointService.isMobile$.pipe(
      tap((isMobile) => {
        if (this.router.url.includes('plpType=variant')) {
          this.viewMode$.next(ViewModes.List);
          this.customProductListComponentService.setActualViewSelectors(
            ViewModes.List
          );
        } else {
          this.viewMode$.next(ViewModes.Grid);
          this.customProductListComponentService.setActualViewSelectors(
            ViewModes.Grid
          );
        }
        this.isInfiniteScroll = isMobile || this.viewMode$.value === 'list';
        this.isMobile = isMobile;
        this.cd.detectChanges();
      })
    );

    this.customSubscription.add(
      combineLatest([
        this.customHeaderCategoriesService.getCategoryData(),
        this.customProductListComponentService.getViewChange(),
        this.customProductListComponentService.getLastCategoryCode(),
      ])
        .pipe(
          debounceTime(400),
          take(1),
          tap(([category, viewChange, lastCategoryCode]) => {
            this.validatePlpType(category!, viewChange, lastCategoryCode);
          })
        )
        .subscribe()
    );

    this.customSubscription.add(
      this.componentData.data$.subscribe((data) => {
        this.initializeAutomotiveQueryData();
        if (data?.uid == 'SearchResultsList') {
          this.isSearchResultsList = true;
        } else {
          this.isSearchResultsList = false;
        }
        this.clickedFrom = data.name;
      })
    );

    this.customSubscription.add(
      this.model$.subscribe((data) => {
        this.customBreadcrumbService.setEmptySearchPage(
          this.clickedFrom == 'Search Result List Component' &&
            data.products?.length! == 0
        );
        this.customGtmDatalayerService.viewProductEvent(
          data.products,
          this.clickedFrom
        );
        this.customBreadcrumbService.setZeroResults(
          data.products?.length! == 0
        );
      })
    );
  }

  setViewMode(mode: ViewModes): void {
    super.setViewMode(mode);
    this.cd.detectChanges();
    const url = this.router.url.split('?')[0];
    if (this.isMobile) {
      if (mode == 'list') {
        this.customProductListComponentService.setActualViewSelectors(mode);
        this.router.navigateByUrl(
          url + '?plpType=variant&searchQueryContext=VARIANTS'
        );
      } else {
        this.customProductListComponentService.setActualViewSelectors(mode);
        this.router.navigateByUrl(url + '?plpType=classic');
      }
      return;
    }

    if (mode == 'list') {
      this.isInfiniteScroll = true;
      this.router.navigateByUrl(
        url + '?plpType=variant&searchQueryContext=VARIANTS'
      );
      this.customProductListComponentService.setViewChange(false);
    } else {
      this.isInfiniteScroll = false;
      this.router.navigateByUrl(url + '?plpType=classic');
      this.customProductListComponentService.setViewChange(true);
    }
  }

  validatePlpType(
    category: CategoriesBreadcrumb,
    viewChange: boolean,
    lastCategoryCode: string
  ) {
    if (
      (category?.defaultPlpType === 'VARIANT_PLP' &&
        !viewChange &&
        !!lastCategoryCode) ||
      (category?.defaultPlpType === 'VARIANT_PLP' &&
        category?.code !== lastCategoryCode)
    ) {
      this.viewMode$.next(ViewModes.List);
      this.customProductListComponentService.setLastCategoryCode(
        category?.code
      );
      this.customProductListComponentService.setViewChange(true);
      const url = this.router.url.split('?')[0];
      this.isInfiniteScroll = true;
      this.router.navigateByUrl(
        url + '?plpType=variant&searchQueryContext=VARIANTS'
      );
    }
    this.customProductListComponentService.setLastCategoryCode(category?.code!);
  }

  initializeAutomotiveQueryData(): void {
    this.activeQuery = this.activatedRoute?.snapshot?.queryParams?.['query'];
    this.automotiveFilterQuery =
      this.automotiveFilterService.getAutomotiveFilterQueryApplied();
  }

  ngOnDestroy(): void {
    this.customBreadcrumbService.setZeroResults(false);
    this.customBreadcrumbService.setEmptySearchPage(false);
    super.ngOnDestroy();
    this.customSubscription.unsubscribe();
  }
}
