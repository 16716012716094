<div
  class="product-card"
  [ngClass]="{
    'dark-card': colorCard === 'dark',
    'light-card': colorCard === 'light'
  }"
  *ngIf="feature"
>
  <p class="product-card__title">{{ feature.name }}</p>
  <h3 class="product-card__details" *ngIf="feature.name == 'Marca'; else noMarca">{{ feature.featureValues[0].value | slice:0:30 }}</h3>
  <ng-template #noMarca>
    <p class="product-card__details">
       {{ feature.featureValues[0].value | slice:0:30 }}
    </p>
  </ng-template>
</div>
