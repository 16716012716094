import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CustomCustomerRegisterSuccessfulComponent } from '../custom-register-shared/custom-customer-register-successful/custom-customer-register-successful.component';
import { CustomRegisterEmployee, EmployeeFormData, EmployeeRegisterFormData, } from '../models/custom-register-employee.interface';
import { PromesaRegisterClient } from '../models/custom-cms-register.interface';

@Injectable({
  providedIn: 'root'
})
export class CustomRegisterEmployeeService {

  employeeData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  modalRef: ModalRef;
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
    private modalService: ModalService
  ) { }

  submitForm(clientCode: string, RUC: string) {
    const url = this.occEndpointService.buildUrl('promesaInternalClientInfo', {
      queryParams: {
        clientCode,
        RUC
      },
    });
    return this.http.get<PromesaRegisterClient>(url).pipe(
      catchError(error => { return of(error?.error?.errors?.[0]?.type) })
    );
  }

  validatePromesaClient(employee: CustomRegisterEmployee) {
    const url = this.occEndpointService.buildUrl('validatePromesaInternalClientInfo', {
      queryParams: {
        email: employee?.email!.toLowerCase(),
        phone: employee?.cellphone,
        customerMail: employee?.customerMail,
        clientCode: employee?.clientCode,
        RUC: employee?.RUC
      },
    });
    this.employeeData$.next(employee);
    return this.http.get(url).pipe(
      catchError((error) => {
        return of(error?.error?.errors?.[0]?.type);
      })
    );
  }

  registerPromesaInternalClient(form: EmployeeRegisterFormData, clientCode: string) {
    const url = this.occEndpointService.buildUrl('registerPromesaInternalClient');
    const customer: EmployeeFormData = {
      orgUnit: {
        uid: clientCode,
      },
      ruc: form?.ruc,
      email: form?.email.toLowerCase(),
      phone: form?.phone
    };
    return this.http.post(url, customer).pipe(
      catchError((error) => {
        return of(error?.error?.errors?.[0]?.type);
      }),
      tap((value) => {
        if (!value?.error) {
          this.openSuccessfulModal(form?.email.toLowerCase());
        }
      })
    );
  }

  openSuccessfulModal(email: string) {
    let modalInstance: any;

    this.modalRef = this.modalService.open(
      CustomCustomerRegisterSuccessfulComponent,
      {
        centered: true,
        size: 'lg',
      }
    );
    modalInstance = this.modalRef.componentInstance;
    modalInstance.isRegisterEmployee = true;
    modalInstance.email = email;
  }

}
