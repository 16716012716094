import { ChangeDetectorRef, Component } from '@angular/core';
import { CustomRegisterValidationComponent } from '../../custom-register-validation/custom-register-validation.component';
import { FormBuilder } from '@angular/forms';
import { CustomRegisterCustomerValidatorService } from '../../services/custom-register-customer-validator.service';
import { CustomRegisterStepOrchestratorService } from '../../services/custom-register-step-orchestrator.service';
import { CustomGtmLoginService } from 'src/app/common/services/custom-gtm-login-register-datalayer.service';
import { CustomLoadingSpinnerService } from 'src/app/cms-components/custom/services/custom-loading-spinner.service';
import { ModalService } from '@spartacus/storefront';
import { CustomRegisterEmployeeService } from '../../services/custom-register-employee.service';

@Component({
  selector: 'app-custom-register-employee-validation',
  templateUrl: './custom-register-employee-validation.component.html',
  styleUrls: ['./custom-register-employee-validation.component.scss']
})
export class CustomRegisterEmployeeValidationComponent extends CustomRegisterValidationComponent {

  constructor(
    fb: FormBuilder,
    customRegisterCustomerValidatorService: CustomRegisterCustomerValidatorService,
    customRegisterStepOrchestrator: CustomRegisterStepOrchestratorService,
    protected cd: ChangeDetectorRef,
    protected dataLayerRegister: CustomGtmLoginService,
    modalService: ModalService,
    protected spinnerService: CustomLoadingSpinnerService,
    private customRegisterEmployeeService: CustomRegisterEmployeeService
  ) {
    super(fb, customRegisterCustomerValidatorService, customRegisterStepOrchestrator, cd, dataLayerRegister, modalService, spinnerService);
    this.isFormEmployee = true;
  }

  customSubmitForm() {
    this.spinnerService.showSpinner('');
    this.submited = true;
    this.cd.detectChanges();
    if (this.customerValidatorForm.invalid) {
      return;
    }
    this.subscription.add(
      this.customRegisterEmployeeService
        .submitForm(
          this.customerValidatorForm?.value?.customerCode,
          this.customerValidatorForm?.value?.ruc
        )
        .subscribe((clientInfo) => {
          this.spinnerService.hideSpinner();
          if (clientInfo === 'PromesaInternalCustomerError') {
            this.openEmployeeInactiveModal(clientInfo);
          } else if (clientInfo === 'AlreadyExistsError') {
            this.openAlreadyExistsModal();
          }
          this.dataLayerRegister.createOldAccountEvent(
            'Crear_Cuenta_Cliente_Paso1',
            clientInfo.ruc
          );
          this.clientInfo = clientInfo;
          this.formValidations(clientInfo);
        })
    );
  }
}
