import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { isNotNullable } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { CustomBaseSiteCheckerService } from '../../../../services/custom-base-site-checker.service';
import { CustomProduct } from '../../../product/model/custom-product.interface';
import { CustomPromeclubPointsService } from './services/custom-promeclub-product-points.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-custom-promeclub-product-points',
  templateUrl: './custom-promeclub-product-points.component.html',
  styleUrls: ['./custom-promeclub-product-points.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPromeclubPointsComponent {
  product$: Observable<CustomProduct> = this.currentProductService
    .getProduct()
    .pipe(
      filter(isNotNullable),
      tap(
        (product) =>
          (this.hasStock = Boolean(
            product.stock?.stockLevelStatus !== 'outOfStock'
          ))
      )
    );
  points$: Observable<any> = this.customPromeclubPointsService.getPoints();

  @Input() addToCartPdp: boolean = false;
  hasStock: boolean;
  isInPromeclub: boolean;
  constructor(
    protected currentProductService: CurrentProductService,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    private customPromeclubPointsService: CustomPromeclubPointsService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.isInPromeclub = this.customBaseSiteCheckerService.isInPromeClub();
    this.customPromeclubPointsService.setActiveStatus(!this.isInPromeclub);
  }

  ngOnInit(): void {
    this.points$.pipe(take(1)).subscribe((points) => {
      if (points == 0) {
        this.addClassToFooterElement();
      }
    });
  }
  addClassToFooterElement(destroy: boolean = false): void {
    const floating = this.document.getElementsByTagName(
      'app-custom-floating-add-to-cart'
    )[0];
    const footer = this.document.getElementsByClassName('Footer')[0];
    if (!destroy) {
      floating?.classList?.add('no-points');
      footer?.classList?.add('no-points');
    } else {
      floating?.classList?.remove('no-points');
      footer?.classList?.remove('no-points');
    }
  }

  ngOnDestroy(): void {
    this.addClassToFooterElement(true);
  }
}
