import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { ModalService } from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { CustomLoadingSpinnerService } from 'src/app/cms-components/custom/services/custom-loading-spinner.service';
import { CustomOrderReturnService } from '../../../services/custom-order-return.service';
import { CustomReturnRequestService } from '../../../services/custom-return-request.service';

@Component({
  selector: 'app-custom-return-order-cancel-dialog',
  templateUrl: './custom-return-order-cancel-dialog.component.html',
  styleUrls: ['./custom-return-order-cancel-dialog.component.scss']
})
export class CustomReturnOrderCancelDialogComponent implements OnInit, OnDestroy {
  private ngbModalElement: HTMLElement;

  orderCode: string;
  @ViewChild('dialogOrderReturn', { read: ElementRef })
  dialogOrderCancel: ElementRef;
  private unsubscribe$ = new Subject<void>();
  constructor(
    protected modalService: ModalService,
    @Inject(DOCUMENT) private document: Document,
    private customOrderReturnService: CustomOrderReturnService,
    protected routingService: RoutingService,
    protected returnRequestService: CustomReturnRequestService,
    protected customLoadingSpinnerService: CustomLoadingSpinnerService
  ) { }

  ngOnInit(): void {
    this.ngbModalElement = <HTMLElement>(
      this.document.getElementsByClassName('modal-content')[0]
    );
  }

  dismissModal(reason?: any): void {
    this.modalService.dismissActiveModal(reason);
  }

  onCancelDevolution() {
    this.customLoadingSpinnerService.showSpinner('');
    this.customOrderReturnService.cancelDevolution(this.orderCode).pipe(
      takeUntil(this.unsubscribe$),
      tap(() => this.returnRequestService.onReloadOrderDevolution()),
      finalize(() => this.customLoadingSpinnerService.hideSpinner()),
      tap(() => this.dismissModal()),
    ).subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
