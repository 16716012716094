import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ConfigModule,
  DeferLoadingStrategy,
  I18nModule,
} from '@spartacus/core';
import { FormErrorsModule, IconModule } from '@spartacus/storefront';
import { CustomComponentTitleModule } from 'src/app/cms-components/custom/components/custom-component-title/custom-component-title.module';
import { CustomModalGenericModule } from 'src/app/cms-components/custom/components/custom-modal/custom-modal-generic.module';
import { CustomProductCardReviewModule } from 'src/app/cms-components/custom/components/custom-product-card-review/custom-product-card-review.module';
import { CustomProductReviewsOverviewModule } from 'src/app/cms-components/custom/components/custom-product-reviews-overview/custom-product-reviews-overview.module';
import { CustomSessionExpiredDialogModule } from 'src/app/cms-components/user/custom-session-expired-dialog/custom-session-expired-dialog.module';
import { CustomSafeHtmlModule } from '../../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomReviewProgressModule } from '../../../../custom/components/custom-review-progress/custom-review-progress.module';
import { CustomStarRatingModule } from '../../../../custom/components/custom-star-rating/custom-star-rating.module';
import { CustomProductAttributesAccordionModule } from '../custom-product-attributes-accordion/custom-product-attributes-accordion.module';
import { CustomProductCardDetailsModule } from '../custom-product-card-details/custom-product-card-details.module';
import { CustomAddReviewDialogComponent } from './custom-add-review/custom-add-review-dialog.component';
import { CustomConfirmatedReviewDialogComponent } from './custom-confirmated-review-dialog/custom-confirmated-review-dialog.component';
import { CustomProductReviewsComponent } from './custom-product-reviews.component';
import { CustomRoundNumberModule } from 'src/app/pipes/custom-round-number/custom-round-number.module';

@NgModule({
  declarations: [
    CustomProductReviewsComponent,
    CustomAddReviewDialogComponent,
    CustomConfirmatedReviewDialogComponent,
  ],
  imports: [
    CommonModule,
    IconModule,
    I18nModule,
    ReactiveFormsModule,
    FormsModule,
    CustomProductCardDetailsModule,
    CustomComponentTitleModule,
    ReactiveFormsModule,
    FormErrorsModule,
    CustomProductCardReviewModule,
    CustomReviewProgressModule,
    CustomStarRatingModule,
    CustomProductReviewsOverviewModule,
    CustomSafeHtmlModule,
    CustomSessionExpiredDialogModule,
    CustomModalGenericModule,
    CustomProductAttributesAccordionModule,
    CustomRoundNumberModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductReviewsTabComponent: {
          component: CustomProductReviewsComponent,
          deferLoading: DeferLoadingStrategy.INSTANT,
        },
      },
    }),
  ],
  exports: [
    CustomProductReviewsComponent,
    CustomConfirmatedReviewDialogComponent,
  ],
})
export class CustomProductReviewsModule {}
