import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { OrderEntry, Cart } from '@spartacus/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { CustomQuotesService } from '../services/custom-quotes.service';
import { CustomQuoteOrchestratorService } from '../services/custom-quote-orchestrator.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QUOTE_STATE } from '../constansts/quotes-constansts';
import { EcvProductType } from 'src/app/cms-components/product/constants/product-type';

@Component({
  selector: 'app-custom-quotes-entry-item',
  templateUrl: './custom-quotes-entry-item.component.html',
  styleUrls: ['./custom-quotes-entry-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomQuotesEntryItemComponent implements OnInit, OnDestroy {
  @Input() entry: OrderEntry | any;
  @Input() quoteCode: string;
  @Input() isMobile: boolean = false;
  @Input() isInReview: boolean = false;
  @Input() isInDetail: boolean = false;
  @Input() isInOffer: boolean = false;
  @Input() formOpened: boolean = false;
  QUOTE_STATE = QUOTE_STATE;
  editItem: boolean = false;
  viewNote: boolean = false;
  comment: FormControl = this.fb.control('', []);
  priceGoal: FormControl = this.fb.control('', [Validators.required]);
  invalidPriceFormat: boolean = false;
  invalidPriceNumber: boolean = false;
  invalidZeroPrice: boolean = false;
  totalGoalValue: number = 0;
  result: string = 'mayor';
  editOffer: boolean = false;
  unsubscribe$: Subject<void> = new Subject();
  EcvProductType: string = EcvProductType;
  constructor(
    private fb: FormBuilder,
    private customQuotesService: CustomQuotesService,
    private customQuoteOrchestrator: CustomQuoteOrchestratorService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.entry?.requestedPrice) {
      this.priceGoal.setValue(
        String(Number(this.entry?.requestedPrice).toFixed(2)).replace('.', ',')
      );
      this.totalGoalValue = this.entry?.requestedPrice * this.entry?.quantity;
    }
    if (this.entry?.quoteComments) {
      this.comment.setValue(this.entry?.quoteComments);
    }
    this.priceGoal.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        const pattern = /[^0-9-,-.\ ]/;
        let newValue = value;
        while (newValue !== (newValue = newValue.replace(pattern, ''))) {
          this.priceGoal.setValue(newValue);
          return;
        }
        if (value) {
          this.validatePrice(value);
        }
      });
  }

  toggleEditItem() {
    this.editItem = !this.editItem;
    this.viewNote = false;
    if (this.isInOffer) {
      this.comment.setValue('');
      this.editItem ? this.toggleEditOffer() : null;
    }
    if (!this.editItem) {
      this.invalidPriceFormat = false;
      this.invalidPriceNumber = false;
      this.invalidZeroPrice = false;
      this.viewNote = false;
      if (!this.entry?.requestedPrice) {
        this.priceGoal.setValue('');
        this.invalidPriceFormat = false;
        this.invalidPriceNumber = false;
        this.invalidZeroPrice = false;
        this.viewNote = false;
        this.totalGoalValue = 0;
      } else {
        this.priceGoal.setValue(
          String(Number(this.entry?.requestedPrice).toFixed(2)).replace(
            '.',
            ','
          )
        );
        this.totalGoalValue = this.entry?.requestedPrice * this.entry?.quantity;
        this.invalidPriceFormat = false;
        this.invalidPriceNumber = false;
        this.invalidZeroPrice = false;
        this.viewNote = false;
        this.customQuotesService
          .requestPriceForEntry(
            this.quoteCode,
            this.entry.entryNumber!,
            null,
            this.comment.value
          )
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((value: Cart) => {
            if (value?.entries?.length) {
              value?.entries?.map((entry) => {
                if (this.entry.entryNumber == entry.entryNumber) {
                  this.entry = entry;
                  this.priceGoal.setValue('');
                  this.comment.setValue('');
                  this.totalGoalValue = 0;
                  this.invalidPriceFormat = false;
                  this.invalidPriceNumber = false;
                  this.viewNote = false;
                  this.cd.detectChanges();
                }
              });
            }
          });
      }
    }
  }

  toggleNote() {
    this.viewNote = !this.viewNote;
  }

  submitEntryRequest() {
    this.customQuotesService
      .requestPriceForEntry(
        this.quoteCode,
        this.entry.entryNumber!,
        this.priceGoal.value.replace(',', '.'),
        this.comment.value
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: Cart) => {
        if (value?.entries?.length) {
          value?.entries?.map((entry) => {
            if (this.entry.entryNumber == entry.entryNumber) {
              if (!this.entry?.requestedPrice) {
                this.customQuoteOrchestrator.setItemsInReview(1);
              }
              this.entry = entry;
              this.viewNote = false;
              this.editItem = false;
              this.priceGoal.setValue(
                String(Number(this.entry.requestedPrice).toFixed(2)).replace(
                  '.',
                  ','
                )
              );
              this.invalidPriceFormat = false;
              this.invalidPriceNumber = false;
              if (this.entry?.quoteComments) {
                this.comment.setValue(this.entry?.quoteComments);
                this.invalidPriceFormat = false;
                this.invalidPriceNumber = false;
              }
              this.cd.detectChanges();
            }
          });
        }
      });
  }

  validatePrice(value: string) {
    const pattern = /^[0-9]+([,][0-9]{2})?$/;

    if (!pattern.test(value)) {
      this.invalidPriceFormat = true;
    } else {
      this.totalGoalValue =
        Number(value.replace(',', '.')) * this.entry.quantity;
      this.invalidPriceFormat = false;
    }
    if (
      Number(value.replace(',', '.')) >
      Number(this.entry?.basePrice?.value.toFixed(2))
    ) {
      this.invalidPriceNumber = true;
      this.result = 'mayor';
    } else if (
      Number(value.replace(',', '.')) ==
      Number(this.entry?.basePrice?.value.toFixed(2))
    ) {
      this.invalidPriceNumber = true;
      this.result = 'igual';
    } else {
      this.invalidPriceNumber = false;
    }
    if (Number(value.replace(',', '.')) == 0) {
      this.invalidZeroPrice = true;
    } else {
      this.invalidZeroPrice = false;
    }
  }

  rejectEntryOffer() {
    let quoteData = {
      entryNumber: this.entry.entryNumber,
      accept: false,
      comments: this.comment.value,
    };
    this.customQuotesService
      .acceptRejectQuoteProposal(this.quoteCode, quoteData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((quote: Cart | any) => {
        let entryChanged;
        quote.entries?.map((entry: any) => {
          if (entry.entryNumber === this.entry.entryNumber) {
            entryChanged = entry;
          }
        });
        this.entry = entryChanged;
        this.editOffer = false;
        this.editItem = false;
        this.viewNote = false;
        this.customQuoteOrchestrator.setActiveCheckoutButton(
          quote?.allEntriesRevised
        );
        this.cd.detectChanges();
        this.customQuoteOrchestrator.setRefreshQuoteDetail(true);
      });
  }

  acceptEntryOffer() {
    let quoteData = {
      entryNumber: this.entry.entryNumber,
      accept: true,
    };
    this.customQuotesService
      .acceptRejectQuoteProposal(this.quoteCode, quoteData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((quote: Cart | any) => {
        let entryChanged;
        quote.entries?.map((entry: any) => {
          if (entry.entryNumber === this.entry.entryNumber) {
            entryChanged = entry;
          }
        });
        this.entry = entryChanged;
        this.editOffer = false;
        this.viewNote = false;
        this.customQuoteOrchestrator.setActiveCheckoutButton(
          quote?.allEntriesRevised
        );
        this.cd.detectChanges();
        this.customQuoteOrchestrator.setRefreshQuoteDetail(true);
      });
  }

  toggleEditOffer() {
    this.editOffer = !this.editOffer;
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
