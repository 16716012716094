import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Order, RoutingService } from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { Observable, Subject, Subscription } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomClaimForm } from '../../../models/custom-claim-form.interface';
import { CustomClaimAddService } from '../../../services/custom-claim-add.service';
import { CustomClaimDialogComponent } from '../custom-claim-dialog/custom-claim-dialog.component';

@Component({
  selector: 'app-custom-claim-detail',
  templateUrl: './custom-claim-detail.component.html',
  styleUrls: ['../custom-claim.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomClaimDetailComponent implements OnInit, OnDestroy {
  @Input() order: Order;
  @Input() isAddClaim: boolean;
  @Input() userId: string;

  formNewMessage: FormGroup = new FormGroup({
    textareaControl: new FormControl('', Validators.required),
  });

  subscription: Subscription = new Subscription();
  order$: Observable<any>;
  modalRef: ModalRef;
  serviceBoolean$ = new Subject<boolean>();
  orderCode: string;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  formData$: Observable<CustomClaimForm | null> =
    this.addClaimService.getclaimFormData();

  stepTwo$: Observable<boolean> = this.addClaimService.getStepTwoStatus();
  tickets: CustomClaimForm[] = [];

  newTickets$: Subject<CustomClaimForm[]> = new Subject();
  public obsNewTicket$: Observable<CustomClaimForm[]> =
    this.newTickets$.asObservable();

  constructor(
    private addClaimService: CustomClaimAddService,
    private routingService: RoutingService,
    private activedRoute: ActivatedRoute,
    private customBreakpointService: CustomBreakpointService,
    protected modalService: ModalService
  ) {}

  ngOnInit(): void {
    if (!this.isAddClaim) {
      this.subscription.add(
        this.activedRoute.url.subscribe((data) => {
          this.orderCode = data[2]?.path;
        })
      );
      this.order$ = this.addClaimService.getClaim(this.orderCode);
    }
  }

  submitForm(form: CustomClaimForm): void {
    if (this.isAddClaim) {
      this.addClaimService
        .postClaim({
          ...form,
          associatedTo: `Order=${this.order?.code}`,
          customerId: this.order?.user?.uid,
        })
        .subscribe((value) => {
          if (value) {
            this.openModal(value);
            this.addClaimService.reset();
          }
        });
    } else {
      this.formNewMessage.valid &&
        this.addClaimService
          .postClaim({
            ticketCategory: form.ticketCategory,
            status: form.status,
            associatedTo: form?.associatedTo,
            id: form?.id,
            message: this.formNewMessage.get('textareaControl')?.value,
          })
          .subscribe((value) => {
            if (value) {
              this.formNewMessage.reset();
              this.tickets.push(value);
              this.newTickets$.next(this.tickets);
            }
          });
    }
  }

  dateNow() {
    return Date.now();
  }
  ticketsEvents(tickets: any[]): any[] {
    return [...tickets].reverse();
  }

  protected openModal(order: Order) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomClaimDialogComponent, {
      centered: true,
      size: 'lg',
    });
    this.modalRef.componentInstance.serviceBoolean$ = this.serviceBoolean$;
    modalInstance = this.modalRef.componentInstance;
    modalInstance.order = order;
    this.isAddClaim && (modalInstance.isGenerateClaim = true);
  }

  splitStringCode(associatedTo: string): string {
    const newString = associatedTo.split(' ')[1];
    const length = newString.length;
    return newString.slice(0, length - 1);
  }

  returnPage(): void {
    if (this.isAddClaim) {
      this.addClaimService.reset();
    } else {
      this.routingService.go({
        cxRoute: 'claimTickets',
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.addClaimService.reset();
  }
}
