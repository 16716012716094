import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomAddAuthorizedPersonComponent } from './custom-add-authorized-person.component';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [CustomAddAuthorizedPersonComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    IconModule,
    NgbModule,
  ],
  exports: [CustomAddAuthorizedPersonComponent],
})
export class CustomAddAuthorizedPersonModule {}
