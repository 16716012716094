<app-custom-loading-spinner-item
  class="overlay"
  *ngIf="(isUpdating$ | async) || (isUpdatingPassword$ | async)"
></app-custom-loading-spinner-item>
<form [formGroup]="form" class="custom-form">
  <app-custom-title-my-account
    [title]="'customUpdateProfileForm.title' | cxTranslate"
  ></app-custom-title-my-account>
  <h2 class="custom-form__subtitle">
    {{ "customUpdateProfileForm.subtitle" | cxTranslate }}
  </h2>
  <div class="custom-form__horizontal">
    <label class="custom-form__horizontal__inputs">
      <span>{{ "customUpdateProfileForm.firstName.label" | cxTranslate }}</span>
      <input
        aria-required="true"
        type="text"
        class="form-control"
        name="firstName"
        placeholder="{{
          'customUpdateProfileForm.firstName.placeholder' | cxTranslate
        }}"
        formControlName="firstName"
        readonly
      />
    </label>

    <label class="custom-form__horizontal__inputs">
      <span>{{ "customUpdateProfileForm.lastName.label" | cxTranslate }}</span>
      <input
        aria-required="true"
        type="text"
        class="form-control"
        name="lastName"
        placeholder="{{
          'customUpdateProfileForm.lastName.placeholder' | cxTranslate
        }}"
        formControlName="lastName"
        readonly
      />
    </label>

    <label class="custom-form__horizontal__inputs">
      <span>
        {{ "customUpdateProfileForm.mobilePhone.label" | cxTranslate }}
      </span>
      <input
        aria-required="true"
        type="text"
        class="form-control"
        name="phone"
        placeholder="{{
          'customUpdateProfileForm.mobilePhone.placeholder' | cxTranslate
        }}"
        formControlName="phone"
        readonly
      />
    </label>

    <label class="custom-form__horizontal__inputs">
      <span>
        {{ "customUpdateProfileForm.userEmail.label" | cxTranslate }}
      </span>
      <input
        aria-required="true"
        type="text"
        class="form-control"
        name="email"
        placeholder="{{
          'customUpdateProfileForm.userEmail.placeholder' | cxTranslate
        }}"
        formControlName="email"
        readonly
      />
    </label>
  </div>
</form>

<app-custom-update-password></app-custom-update-password>
