import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import { CustomEndpointsConfig } from './custom-endpoints.config';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [provideConfig(CustomEndpointsConfig)],
})
export class CustomEndpointsModule {}
