<ng-container
  *ngIf="{
    isMobile: isMobile$ | async,
    currentPage: currentPage$ | async
  } as data"
>
  <ng-container>
    <div class="carousel-title">
      <h4
        class="custom-component-title"
        *ngIf="title$ | async as title"
        [innerHTML]="title | safeHtml"
      ></h4>
      <a
        *ngIf="seeMoreLink$ | async as seeMore"
        class="carousel-link"
        [routerLink]="seeMore?.url"
      >
        {{ seeMore?.linkName }}
        <cx-icon class="fas fa-chevron-right"></cx-icon>
      </a>
    </div>

    <ng-container *ngIf="items$ | async as items; else loading">
      <div class="product-items-container">
        <ng-container *ngIf="data.isMobile === false; else mobile">
          <ng-container *ngFor="let item$ of items.slice(0, 5); let i = index">
            <app-custom-product-card
              [fromContentPage]="
                data?.currentPage?.template != 'LandingPage2Template'
              "
              [product]="item$ | async"
              [productPosition]="i"
              [clickedFrom]="clickedFrom"
              [resetScrollPosition]="RESET_SCROLL"
            ></app-custom-product-card>
          </ng-container>
        </ng-container>
        <ng-template #mobile>
          <ng-container *ngFor="let item$ of items.slice(0, 4)">
            <app-custom-product-card
              [fromContentPage]="
                data?.currentPage?.template != 'LandingPage2Template'
              "
              [product]="item$ | async"
              [productPosition]="i"
              [clickedFrom]="clickedFrom"
              [resetScrollPosition]="RESET_SCROLL"
            ></app-custom-product-card>
          </ng-container>
        </ng-template>
      </div>
    </ng-container>

    <ng-template #loading>
      <div
        class="custom-loading-product"
        *ngIf="data?.currentPage?.template != 'ProductDetailsPageTemplate'"
      >
        <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
