import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { FormErrorsModule, IconModule, ModalModule } from '@spartacus/storefront';
import { CustomModalGenericModule } from 'src/app/cms-components/custom/components/custom-modal/custom-modal-generic.module';
import { CustomSavedCartFormDialogComponent } from './custom-saved-cart-form-dialog.component';



@NgModule({
  declarations: [CustomSavedCartFormDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormErrorsModule,
    IconModule,
    RouterModule,
    I18nModule,
    UrlModule,
    ModalModule,
    CustomModalGenericModule
  ],
  exports: [
    CustomSavedCartFormDialogComponent
  ]
})
export class CustomSavedCartFormDialogModule { }
