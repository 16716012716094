import { customProduct } from './es/custom-product';
import { customNewsLetterForm } from './es/custom-newsletter-form';
import { customCommon } from './es/custom-common';
import { customUserBloqued } from './es/custom-user-bloqued';
import { customCart } from './es/custom-cart';
import { customSearchFacets } from './es/custom-search-facets';
import { customSearchRefiner } from './es/custom-search-refiner';
import { customPaymentType } from './es/custom-payment';
import { customCheckout } from './es/custom-checkout';
import { customCustomerRegisterValidationForm } from './es/custom-register-customer-validation-form';
import { customRegisterSelector } from './es/custom-register-selector';
import { customCreditLine } from './es/custom-credit-line';
import { customNonCustomerRegister } from './es/custom-non-customer-register';
import { customMyCompany } from './es/custom-my-company';
import { customMyAccount } from './es/custom-my-account.i18n';
import { customRebate } from './es/custom-rebate';
import { customPersonalData } from './es/custom-personal-data';
import { customPromeclubAddress } from './es/custom-promeclub-address.i18n';
import { customWishlist } from './es/custom-wishlist';
import { myAccountQuotes } from './es/custom-my-account-quotes';
import { customCartQuotes } from './es/custom-cart-quotes';
import { customTalleres } from './es/custom-talleres';
import { checkoutStockTranslation } from './es/custom-checkout-stock';
import { customPromeclubLanding } from './es/custom-promeclub-landing';
import { customRegisterEmployeeForm } from './es/custom-register-employee-form';
import { customTermsAndConditions } from './es/custom-terms-and-conditions';

export const customTranslations = {
  es: {
    ...customProduct,
    ...customNewsLetterForm,
    ...customCommon,
    ...customCart,
    ...customSearchFacets,
    ...customSearchRefiner,
    ...customPaymentType,
    ...customCheckout,
    ...customRegisterSelector,
    ...customCustomerRegisterValidationForm,
    ...customRegisterEmployeeForm,
    ...customCreditLine,
    ...customNonCustomerRegister,
    ...customUserBloqued,
    ...customMyCompany,
    ...customMyAccount,
    ...customPersonalData,
    ...customPromeclubAddress,
    ...customRebate,
    ...customPersonalData,
    ...customWishlist,
    ...myAccountQuotes,
    ...customCartQuotes,
    ...customTalleres,
    ...checkoutStockTranslation,
    ...customPromeclubLanding,
    ...customTermsAndConditions,
  },
};
