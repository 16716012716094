import { Component, Input } from '@angular/core';
import { PromesaRegisterClient } from '../../models/custom-cms-register.interface';

@Component({
  selector: 'app-custom-register-employee-personal-data',
  templateUrl: './custom-register-employee-personal-data.component.html',
  styleUrls: ['./custom-register-employee-personal-data.component.scss']
})
export class CustomRegisterEmployeePersonalDataComponent {

  @Input() clientInfo: PromesaRegisterClient;

}
