import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CustomCategoryData } from '../cms-components/custom/components/custom-category-list/model/custom-category-list.interface';


@Injectable({
  providedIn: 'root',
})
export class CustomBrandsAndCategoriesService  {


  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
  ) {}

  getCategoryDataFromApi(categoryCode: string): Observable<CustomCategoryData>{
    const url = this.occEndpointService.buildUrl('categoryData', {
      urlParams: {
        categoryCode
      }
    });
    return this.http.get<CustomCategoryData>(url);
  }
}
