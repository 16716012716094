import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { ProductSearchService, AuthService } from '@spartacus/core';
import { FacetComponent, FacetCollapseState } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomFacetService } from 'src/app/cms-components/product/services/custom-facet.service';
import { Input } from '@angular/core';

@Component({
  selector: 'app-custom-price-facet',
  templateUrl: './custom-price-facet.component.html',
  styleUrls: ['./custom-price-facet.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomPriceFacetComponent extends FacetComponent {
  @Input() isMobile: boolean = false;
  min: number;
  max: number;
  data$: Observable<any> = this.productSearchService.getResults();
  state$: Observable<FacetCollapseState>;
  isUserLoggedIn$: Observable<boolean> = this.auth.isUserLoggedIn();
  showFacet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  constructor(
    protected facetService: CustomFacetService,
    protected elementRef: ElementRef<HTMLElement>,
    protected cd: ChangeDetectorRef,
    protected productSearchService: ProductSearchService,
    protected auth: AuthService
  ) {
    super(facetService, elementRef, cd);
  }

  decreaseVisibleValuesCustom() {
    this.facetService.decreaseVisibleValues(this.facet);
  }

  increaseVisibleValuesCustom() {
    this.facetService.increaseVisibleValues(this.facet);
  }

  toggleFacet(): void {
    this.showFacet$.next(!this.showFacet$.value);
  }
}
