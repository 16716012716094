import { Injectable } from '@angular/core';
import {
  BasePageMetaResolver,
  BreadcrumbMeta,
  PageLinkService,
  ProductPageMetaResolver,
  ProductService,
  RoutingService,
  TranslationService,
} from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PromesaSection } from '../common/models/promesa-section';

@Injectable({
  providedIn: 'root',
})
export class CustomProductPageMetaResolver extends ProductPageMetaResolver {
  PROMESA_SECTION = PromesaSection;
  constructor(
    routingService: RoutingService,
    productService: ProductService,
    translation: TranslationService,
    basePageMetaResolver: BasePageMetaResolver,
    pageLinkService: PageLinkService
  ) {
    super(
      routingService,
      productService,
      translation,
      basePageMetaResolver,
      pageLinkService
    );
  }

  resolveBreadcrumbs(): Observable<BreadcrumbMeta[]> {
    return combineLatest([
      this.product$.pipe(),
      this.translation.translate('common.home'),
    ]).pipe(
      map(([product, label]) => {
        const breadcrumbs = [];
        breadcrumbs.push({ label, link: this.homeBySection() });
        for (const { name, code, url } of product.categories || []) {
          breadcrumbs.push({
            label: name || code,
            link: url,
          } as BreadcrumbMeta);
        }
        return breadcrumbs;
      })
    );
  }

  homeBySection(): string {
    const currentSection = localStorage.getItem('currentSection')!;
    const isAutomotiveSection =
      currentSection == this.PROMESA_SECTION.AUTOMOTRIZ.toLowerCase();
    const automotiveHomeUrl = `/${this.PROMESA_SECTION.AUTOMOTRIZ.toLowerCase()}`;

    return isAutomotiveSection ? automotiveHomeUrl : '/';
  }
}
