export const customSearchFacets = {
  searchFacets: {
    searchBoxFacets: {
      placeholder: 'Buscar resultados',
      placeholderFacets: 'Buscar',
      filterBy: 'Filtrar por',
      showCategory: 'Ver categoría',
      showBrand: 'Ver marca',
      included: 'Incluye',
      filterAndOrder: 'Filtrar y ordenar ({{count}}) ',
    },
    filterPrice: {
      labelFilterPrice: 'Filtrar por precio',
      labelFilterPoints: 'Filtrar por puntos',
      labelFilterPointsMobile: 'Puntos',
      min: 'Min',
      max: 'Máx',
    },
    filterBrands: {
      labelFilterBrands: 'Filtrar por marcas',
    },
    filterOptions: {
      labelFilterOptions: 'Filtrar por categorías',
    },
    filterProductsReviews: {
      labelFilterProductsReviews: 'Filtrar por valoraciones de producto',
      labelMobile: 'Valoración',
      averageReview: '{{count}}/5',
    },
    searchPageBreadcrumb: {
      home: 'Home',
      search: 'Búsqueda',
      zeroResults: 'Lo sentimos, no hemos encontrado resultados',
    },
  },
};
