import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutletHeaderModule } from './outlet-header/outlet-header.module';
import { GenericLinkModule } from '@spartacus/storefront';
import { HeaderSecureCheckoutOutletModule } from './header-secure-checkout-outlet/header-secure-checkout-outlet.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    OutletHeaderModule,
    GenericLinkModule,
    HeaderSecureCheckoutOutletModule,
  ],
  exports: [OutletHeaderModule, HeaderSecureCheckoutOutletModule],
})
export class OutletsModule {}
