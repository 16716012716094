import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Attempts, CustomLoginAttempts } from '../../models/custom-login-attemps.interface';

@Injectable({
  providedIn: 'root'
})
export class CustomLoginAttempsHandlerService {


  email$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  attemps$: BehaviorSubject<Attempts | null> = new BehaviorSubject<Attempts | null>(null);
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
  ) { }

  getLoginAttemps(): Observable<CustomLoginAttempts> {
    const url = this.occEndpointService.buildUrl('loginAttemps', {
      urlParams: {
        email: this.email$.value
      }
    });
    return this.http.get<CustomLoginAttempts>(url);
  }

  setEmail(value: string){
    this.email$.next(value);
  }

  setAttempts(attempts: number, maxAttempts: number){
    this.attemps$.next({attempts, maxAttempts});
  }

  getAttempts(): Observable<Attempts | null>{
    return this.attemps$.asObservable();
  }

  resetAttempts(){
    this.attemps$.next(null);
  }
}
