export const myAccountQuotes = {
  accountQuotes: {
    accountQuotes: {
      title: 'Propuesta de negocios',
      titleList: 'Propuestas de negocios',
      titleReview: 'Revisión de propuesta de negocios',
      titleReviewMobile: 'Revisión de propuesta',
      titleDetail: 'Propuesta de negocios {{code}}',
      titleDetailOffer: 'Revisión de propuesta de negocios {{code}}',
      titleDetailOfferMobile: 'Revisión de propuesta {{code}}',
      detailBreadcrumb: 'Resumen de propuesta',
      offerBreadcrumb: 'Propuesta pendiente',
      alertInfoTitle: 'Campos obligatorios',
      alertInfoSubtitle:
        'Para poder continuar es necesario completar todos los campos obligatorios.',
      list: {
        title: 'Productos de mi pedido',
        subtitle:
          'Para poder revisar la propuesta es necesario editar al menos un precio objetivo de los productos.',
        subtitleRequirement:
          'Para poder revisar la propuesta es necesario editar al menos un precio objetivo de los productos.',
        quantity: '{{count}} producto pedido',
        quantity_plural: '{{count}} productos pedidos',
        review: '{{count}} producto en revisión',
        review_plural: '{{count}} productos en revisión',
        reviewOffer: '{{count}} precio de producto revisado',
        reviewOffer_plural: '{{count}} precios de productos revisados',
        filterAll: 'Todos',
        filterByReview: 'A revisar',
        rejectAll: 'Rechazar todas',
        acceptAll: 'Aceptar todas',
      },
      overview: {
        title: 'Detalle de la propuesta de negocios',
        order: 'Orden',
        creationDate: 'Fecha de creación',
        dateUpdate: 'Fecha de modificación',
        proposalName: 'Nombre de la propuesta',
        paymentMethod: 'Método de pago',
        status: 'Estado',
        commentOfProposal: 'Comentario a la propuesta de negocios',
        credit: 'Crédito',
        counted: 'Contado',
        removeProposal: 'Eliminar propuesta de negocios',
      },
      form: {
        proposalReference: 'Referencia de la propuesta:',
        proposalReferencePlaceholder: 'Ingresar nombre de referencia',
        paymentMethod: 'Método de pago:',
        credit: 'Crédito',
        counted: 'Contado',
        comments: 'Comentario a la propuesta de negocios:',
        commentsPlaceholder: 'Escribir comentario',
        creditSelector: 'Condición de pago',
        selectorPlaceholder: 'Seleccionar',
        countedSelector: 'Forma de pago',
        inputRequired: 'Campo obligatorio',
        requiredFields:
          '<h4 class="quotes-form__subtitle">Los campos marcados con <span class="quotes-form__subtitle-span">*</span> son obligatorios</h4>',
      },
      summary: {
        title: 'Resumen de pedido',
        subtotal: 'Subtotal',
        iva: 'IVA',
        total: 'Total',
        reviewProposal: 'Revisar propuesta',
        confirmProposal: 'Confirmar propuesta',
        seeProposals: 'Ver propuestas',
        checkout: 'Checkout',
        tooltipReviewProposal:
          'Para poder continuar es necesario completar los campos obligatorios',
      },
      item: {
        quantity: 'Cantidad',
        sku: 'SKU ',
        presentation: 'Presentación: ',
        price: 'Precio',
        total: 'Total',
        priceGoal: 'Precio objetivo',
        reviewPrice: 'Revisar precio',
        reviewPriceInfo: 'Precio objetivo de compra',
        note: 'Nota',
        cancel: 'Cancelar',
        save: 'Guardar',
        inReviewBadge: 'En revisión de precio',
        reviewedBadge: 'Precio revisado',
        viewNote: 'Ver nota',
        invalidPriceFormat: 'Formato inválido',
        invalidPriceNumber:
          'Su precio requerido es {{result}} al precio inicial',
        invalidZeroPrice: 'Su precio requerido no puede ser cero',
        originalPrice: 'Precio original',
        requestedPrice: 'Precio solicitado',
        approvedPrice: 'Precio aprobado',
        acceptPrice: 'Aceptar precio',
        rejectPrice: 'Rechazar precio',
        rejectReason: 'Motivo de rechazo',
        priceAccepted: 'Precio aceptado',
        priceRefused: 'Precio rechazado',
        modify: 'Modificar',
        viewRejectedReason: 'Ver motivo de rechazo',
        viewRejectedReasonMobile: 'Ver motivo',
      },
      quotesTable: {
        dateCreate: 'Fecha de creación',
        dateUpdate: 'Fecha de modificación',
        quoteNumber: 'Número de propuesta',
        quoteName: 'Nombre de la propuesta',
        status: 'Estado',
        statusDisplay_BUYER_DRAFT: 'Pendiente',
        statusDisplay_BUYER_SUBMITTED: 'En revisión',
        statusDisplay_CANCELLED: 'Cancelada',
        statusDisplay_BUYER_OFFER: 'A confirmar por el cliente',
      },
      reject: {
        title: 'Rechazar todas las propuestas',
        text: 'Para poder rechazar todas las propuestas de negocio necesitas indicar el motivo.',
        reason: 'Motivo de rechazo',
        reasonPlaceholder: 'Escribir comentario',
        cancel: 'Cancelar',
        button: 'Rechazar todas',
      },
      success: {
        message:
          '<p><b>Solicitud de propuesta de negocios {{code}} creada exitosamente</b></p><p>El equipo comercial ya se encuentra trabajando en tu solicitud.</p><p>Una vez concluida la revisión, recibirás un e-mail con la resolución.</p>',
        messageMobile:
          '<p><b>Solicitud de propuesta de negocios</b></p><p><b>{{code}} creada exitosamente</b></p><p>El equipo comercial ya se encuentra</p><p>trabajando en tu solicitud.</p><p>Una vez concluida la revisión, recibirás</p><p>un e-mail con la resolución.</p>',
        continue: 'Continuar',
      },
      remove: {
        message:
          '<p>Deseas eliminar de forma permanente</p><p>la<b> propuesta de negocios {{code}}</b></p>',
        delete: 'Eliminar',
        cancel: 'Cancelar',
      },
      checkout: {
        title: '<b>Tienes productos en tu carrito</b>',
        message:
          '<p>Si decides continuar con el checkout de esta propuesta</p><p>de negocio perderás los productos del carrito.</p>',
        messageMobile:
          '<p>Si decides continuar con el checkout de</p><p>esta propuesta de negocio perderás los</p><p>productos del carrito.</p>',
        messageTwo:
          '<p>Puedes concluir la compra de los productos del carrito o</p><p>guardarlo en Listas de Compras para retomarlo después y</p><p>poder realizar posteriormente el checkout de esta</p><p>propuesta de negocio.</p>',
        messageTwoMobile:
          '<p>Puedes concluir la compra de los</p><p>productos del carrito o guardarlo en</p><p>Listas de Compras para retomarlo</p><p>después y poder realizar posteriormente</p><p>el checkout de esta propuesta</p><p>de negocio.</p>',
        cancel: 'Cancelar',
        continue: 'Continuar al checkout',
      },
      checkoutCancel: {
        title: '<b>Cancelar pedido</b>',
        message:
          '<p>Si decides cancelar el pedido perderás</p><p>la propuesta de negocio realizada.</p>',
        cancel: 'Cancelar pedido',
        continueCheckout: 'Regresar al checkout',
      },
      inCourse: {
        title: '<b>Propuesta de negocio en curso</b>',
        message:
          '<p>Para poder agregar productos al carrito primero necesitas</p><p>finalizar tu propuesta de negocio en curso.</p>',
        messageMobile:
          '<p>Para poder agregar productos al carrito</p><p>primero necesitas finalizar tu propuesta</p><p>de negocio en curso.</p>',
        seeProposals: 'Ver propuestas',
      },
      create: {
        message:
          '<p><b>Propuesta de negocios</b></p><p>¿Deseas mover los productos de tu carrito</p><p>a propuestas de negocios?</p>',
        mobileMessage:
          '¿Deseas mover los productos de tu carrito a propuestas de negocios?',
        cancel: 'Cancelar',
        continue: 'Continuar',
      },
    },
  },
};
