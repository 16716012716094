import { Injectable, OnDestroy } from '@angular/core';
import {
  AuthHttpHeaderService,
  AuthRedirectService,
  AuthService,
  AuthStorageService,
  GlobalMessageService,
  OAuthLibWrapperService,
  OccEndpointsService,
  RoutingService,
} from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { CustomSessionExpiredDialogComponent } from 'src/app/cms-components/user/custom-session-expired-dialog/custom-session-expired-dialog.component';

/**
 * Extendable service for `AuthInterceptor`.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomAuthHttpHeaderService
  extends AuthHttpHeaderService
  implements OnDestroy
{
  modalRef: ModalRef;

  constructor(
    protected authService: AuthService,
    protected authStorageService: AuthStorageService,
    protected oAuthLibWrapperService: OAuthLibWrapperService,
    protected routingService: RoutingService,
    protected occEndpoints: OccEndpointsService,
    protected globalMessageService: GlobalMessageService,
    protected authRedirectService: AuthRedirectService,
    protected modalService: ModalService
  ) {
    super(
      authService,
      authStorageService,
      oAuthLibWrapperService,
      routingService,
      occEndpoints,
      globalMessageService,
      authRedirectService
    );
  }

  /**
   * Logout user, redirected to login page and informs about expired session.
   */
  public handleExpiredRefreshToken(): void {
    const isFirstLoad = document.getElementById('storefrontTagId') === null;

    // There might be 2 cases:
    // 1. when user is already on some page (router is stable) and performs an UI action
    // that triggers http call (i.e. button click to save data in backend)
    // 2. when user is navigating to some page and a route guard triggers the http call
    // (i.e. guard loading cms page data)
    //
    // In the second case, we want to remember the anticipated url before we navigate to
    // the login page, so we can redirect back to that URL after user authenticates.
    this.authRedirectService.saveCurrentNavigationUrl();

    // Logout user
    // TODO(#9638): Use logout route when it will support passing redirect url
    this.authService.coreLogout().finally(() => {
      this.modalService.closeActiveModal('expired sessión');
      if (!isFirstLoad) {
        this.modalRef = this.modalService.open(
          CustomSessionExpiredDialogComponent,
          {
            centered: true,
            size: 'lg',
          }
        );
      }
    });
  }
}
