<ng-container #sessionExpired>
  <div class="session-expired_content">
    <app-custom-modal-generic (closeModal)="dismissModal($event)" [isClose]="true"></app-custom-modal-generic>
    <div class="session-expired_body">
      <div class="session-expired_body__header">
        <cx-icon class="far fa-clock"></cx-icon>
        <div class="session-expired_body__header-labels">
          <span class="session-expired_body__header-title">{{
            "asm.expiredSession.title" | cxTranslate
          }}</span>
          <span class="session-expired_body__header-subtitle">{{
            "asm.expiredSession.label" | cxTranslate
          }}</span>
        </div>
      </div>
      <div class="session-expired_body__footer">
        <button class="btn btn-primary btn-block" type="submit" (click)="goLogin($event)">
          <div>
            {{ "asm.loginForm.submit" | cxTranslate }}
          </div>
        </button>
      </div>
    </div>
  </div>
</ng-container>
