import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { UploadResponse } from 'src/app/cms-components/user/custom-register/models/custom-upload.interface';
import { PaymentRecord, PaymentBanks } from './model/payment-banks.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomPaymentBanksService {
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService
  ) {}

  getPaymentBanks(): Observable<PaymentBanks[]> {
    const url = this.occEndpointService.buildUrl('paymentBanks');
    return this.http.get<PaymentBanks[]>(url);
  }

  addPaymentRecord(addPayment: PaymentRecord) {
    const url = this.occEndpointService.buildUrl('addPaymentRecord');

    return this.http.post<any>(url, addPayment, {});
  }

  uploadDocument(
    file: File,
    orderCode: string,
    paymentType: string
  ): Observable<UploadResponse> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const url = this.occEndpointService.buildUrl('uploadRegisterDocument', {
      queryParams: {
        altText: `${paymentType} document`,
        code: orderCode,
        description: paymentType,
      },
    });
    return this.http.post<UploadResponse>(url, formData);
  }

  deleteDocument(uuid: string): Observable<boolean> {
    const url = this.occEndpointService.buildUrl('deleteRegisterDocument', {
      queryParams: {
        uid: uuid,
      },
    });
    return this.http.post<boolean>(url, {});
  }

  customVaucherAndAmoutValidation(
    control: FormControl
  ): ValidationErrors | null {
    const value: string = control.value;
    if (!!!value) {
      return null;
    }
    if (isNaN(Number(value))) {
      return { invalidVoucherOrAmout: true };
    }
    return null;
  }


  customVaucher(control: FormControl): ValidationErrors | null {
    const value: string = control.value;
    if (!value) {
      return null;
    }
    const pattern = /^[0-9]+$/;
    if (!pattern.test(value)) {
      return { invalidVoucherOrAmout: true };
    }
    return null;
  }

  customAmount(control: FormControl): ValidationErrors | null {
    const value: string = control.value;
    if (!value) {
      return null;
    }
    const pattern = /^[0-9]+([,][0-9]+)?$/;
    if (!pattern.test(value)) {
      return { invalidVoucherOrAmout: true };
    }

    return null;
  }

}
