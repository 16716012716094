<ng-container
  *ngIf="{
    customPaymentTypes: customPaymentTypes$ | async,
    user: user$ | async,
    cartPoNumber: cartPoNumber$ | async,
    typeSelected: typeSelected$ | async,
    cart: cart$ | async,
    isMobile: isMobile$ | async
  } as data"
>
  <div class="po-number">
    <label>
      <span class="label-content">{{
        "checkoutPO.poNumber" | cxTranslate
      }}</span>
      <input
        #poNumber
        class="form-control"
        type="text"
        placeholder="{{ 'checkoutPO.placeholder' | cxTranslate }}"
        value="{{ data.cartPoNumber }}"
      />
    </label>
  </div>
  <ng-container *ngIf="data.isMobile">
    <app-custom-review-submit [hideContent]="true"></app-custom-review-submit>
  </ng-container>
  <div class="payment" [ngClass]="{ prome: isPromeclub }">
    <ng-container *ngIf="data?.customPaymentTypes?.length; else loading">
      <ng-container *ngIf="data.isMobile">
        <div class="payment__step-two">
          {{ "paymentTypes.stepTwo" | cxTranslate }}
        </div>
      </ng-container>
      <div class="d-flex">
        <ng-container *ngIf="data.isMobile">
          <div class="payment__step-two-circle">
            <span>2</span>
          </div>
        </ng-container>
        <h3 class="payment__title">
          {{
            isPromeclub
              ? ("paymentTypes.promeclubTitle" | cxTranslate)
              : ("paymentTypes.title" | cxTranslate)
          }}
        </h3>
      </div>
      <div class="payment__selectors">
        <div *ngFor="let type of data.customPaymentTypes">
          <div [ngClass]="{ payment__selectors__container: data.isMobile }">
            <div
              class="form-check"
              [ngClass]="{ quote: data?.cart?.isFromQuote }"
            >
              <input
                id="paymentType-{{ type.code }}"
                class="form-check-input"
                role="radio"
                type="radio"
                aria-checked="true"
                (change)="changeType(type.code)"
                [value]="type.code"
                [checked]="type.code == data?.typeSelected"
              />
              <label
                class="cx-payment-type-label form-check-label form-radio-label"
                for="paymentType-{{ type.code }}"
              >
                <div class="cx-payment-type">
                  {{ type?.displayName }}
                </div>
              </label>
            </div>
          </div>
          <ng-container
            *ngIf="data.isMobile && type.code == data?.typeSelected"
          >
            <div class="payment__selectors__typeSelected-container">
              <ng-container *ngTemplateOutlet="typeSelected"></ng-container>
              <ng-container *ngTemplateOutlet="actionButton"></ng-container>
            </div>
          </ng-container>
        </div>
      </div>

      <ng-container *ngIf="!data.isMobile">
        <ng-container *ngTemplateOutlet="typeSelected"></ng-container>
        <ng-container *ngTemplateOutlet="actionButton"></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="data.isMobile">
    <div class="custom-checkout-payment__place-order">
      <app-custom-place-order></app-custom-place-order>
    </div>
  </ng-container>

  <ng-template #typeSelected>
    <app-custom-payment-point
      *ngIf="showPointsComponent"
      [isPromeclub]="isPromeclub"
      [userId]="data?.user?.uid"
      [customCheckoutPaymentStepService]="customCheckoutPaymentStepService"
    ></app-custom-payment-point>

    <app-custom-payment-credit
      *ngIf="showCreditComponent"
      [user]="data?.user"
      [customCheckoutPaymentStepService]="customCheckoutPaymentStepService"
      [cart]="data?.cart"
    ></app-custom-payment-credit>

    <app-custom-payment-cash
      *ngIf="showCashComponent"
      [user]="data?.user"
      [paymentTypes]="customCountedPaymentTypes"
      [customCheckoutPaymentStepService]="customCheckoutPaymentStepService"
      [cart]="data?.cart"
    ></app-custom-payment-cash>
  </ng-template>
  <ng-template #actionButton>
    <div class="payment__action" *ngIf="showActionButton">
      <div class="payment__action-button">
        <button
          class="btn btn-primary btn-container__custom-btn"
          (click)="onSubmit(data?.user)"
          [disabled]="!(goToNextStep$ | async)"
        >
          {{
            data.isMobile
              ? ("common.confirm" | cxTranslate)
              : ("common.continue" | cxTranslate)
          }}
        </button>
      </div>
    </div>
  </ng-template>
</ng-container>
<ng-template #loading>
  <div class="cx-spinner">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
