import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CmsComponent, CmsService } from "@spartacus/core";
import { Observable } from 'rxjs';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-cart-list-header',
  templateUrl: './custom-cart-list-header.component.html',
  styleUrls: ['./custom-cart-list-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomCartListHeaderComponent {
  @Input() cart: any;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  isInPromeclub = this.service.isInPromeClub()
  promesaCartUpdatedPriceMessageComponentData$: Observable<CmsComponent> = this.cmsService.getComponentData('promesaCartUpdatedPriceMessageComponent');

  constructor(
    private customBreakpointService: CustomBreakpointService,
    private service: CustomNavigationService,
    private cmsService: CmsService) {
  }
}
