<ng-container *ngIf="order$ | async as order">
  <div class="custom-amend-action-buttons" *ngIf="forwardRoute">
    <a
      [routerLink]="
        {
          cxRoute: backRoute
        } | cxUrl
      "
      class="button-action"
    >
      {{ "orderDetails.cancellationAndReturn.btnReturn" | cxTranslate }}
    </a>
  </div>
  <div class="custom-amend-action-buttons__conf" *ngIf="forwardRoute">
    <button
      class="btn btn-block btn-primary"
      (click)="cancelOrder($event, order)"
    >
      {{ "orderDetails.cancellationAndReturn.btnConfirm" | cxTranslate }}
    </button>
  </div>

  <div *ngIf="!forwardRoute">
    <button
      class="btn btn-block btn-primary-cancellations"
      [routerLink]="
        {
          cxRoute: backRoute
        } | cxUrl
      "
    >
      {{ "orderDetails.cancellationAndReturn.showCancellations" | cxTranslate }}
    </button>
  </div>
</ng-container>
