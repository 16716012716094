import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { CustomProductCarouselModule } from './components/custom-product-carousel/custom-product-carousel.module';
import { CustomProductImagesModule } from './components/custom-product-images/custom-product-images.module';
import { CustomProductIntroModule } from './components/custom-product-intro/custom-product-intro.module';
import { CustomProductListModule } from './components/custom-product-list/custom-product-list.module';
import { CustomProductSocialShareModule } from './components/custom-product-social-share/custom-product-social-share.module';
import { CustomProductSummaryModule } from './components/custom-product-summary/custom-product-summary.module';
import { CustomProductTabParagraphContainerModule } from './components/custom-product-tab-paragraph-container/custom-product-tab-paragraph-container.module';
import { CustomProductTabsModule } from './components/custom-product-tabs/custom-product-tabs.module';
import { CustomProductVariantsContainerModule } from './components/custom-product-variants-container/custom-product-variants-container.module';
import { CustomProductDataEffects } from './effects/custom-product-data.effect';
import { CustomProductReviewsEffects } from './effects/custom-product-reviews.effect';
import { CustomProductsSearchEffects } from './effects/custom-product-search.effect';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CustomProductCarouselModule,
    CustomProductListModule,
    CustomProductVariantsContainerModule,
    CustomProductSummaryModule,
    CustomProductIntroModule,
    CustomProductTabsModule,
    CustomProductTabParagraphContainerModule,
    CustomProductImagesModule,
    CustomProductSocialShareModule,
    EffectsModule.forFeature([
      CustomProductReviewsEffects,
      CustomProductDataEffects,
      CustomProductsSearchEffects
    ]),
  ]
})
export class ProductModule { }
