import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CmsService, Order, PaginationModel } from '@spartacus/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { Observable, Subscription } from 'rxjs';
import { pluck, switchMap, tap } from 'rxjs/operators';
import { CustomOrderPaginationService } from '../../../services/custom-order-pagination.service';
import { CustomOrderReturnService } from '../../../services/custom-order-return.service';
import { CustomPaymentRecordService } from '../../../services/custom-payment-record.service';
import { ORDER_STATE } from '../../custom-order-history/constansts/order-constansts';
import {
  CustomReturnOrder,
  CustomReturnOrderDetail,
} from '../model/custom-return-order';

@Component({
  selector: 'app-custom-return-order-header-detail',
  templateUrl: './custom-return-order-header-detail.component.html',
  styleUrls: ['./custom-return-order-header-detail.component.scss'],
})
export class CustomReturnOrderHeaderDetailComponent
  implements OnInit, OnDestroy
{
  order$: Observable<any>;
  selectedPage: number;
  orderDetails: any[] = [];
  orderDetailsShow: any[];
  pageSize: number = 10000;
  pagination: PaginationModel = {
    currentPage: 0,
    pageSize: this.pageSize,
    sort: '',
    totalPages: 1,
    totalResults: 0,
  };
  quantities: any[] = [
    { id: 5, name: 5, disabled: true },
    { id: 50, name: 50, disabled: true },
    { id: 100, name: 100, disabled: true },
    { id: 200, name: 200, disabled: true },
    { id: 10000, name: 'Todos', disabled: true },
  ];
  itemsSelected: any[] = [];
  refundsReasons$: Observable<any>;
  sendDataEnabled: boolean = false;
  itemsForm: CustomReturnOrder = {
    returnRequestEntryInputs: [],
    comments: '',
    orderCode: '',
  };
  returnRequestEntryInputs: CustomReturnOrderDetail[] = [];
  @Input() orderReturn: Observable<any>;
  @Input() currentPage: string;
  @Output() nextPage = new EventEmitter<any>();
  @Output() confirmOrder = new EventEmitter<any>();
  subscription: Subscription = new Subscription();
  pageCurrent$: Observable<string | undefined> = this.cmsService
    .getCurrentPage()
    .pipe(pluck('pageId'));
  ORDER_STATE = ORDER_STATE;
  constructor(
    protected orderDetailsService: OrderDetailsService,
    protected customOrderDetailsService: CustomPaymentRecordService,
    protected activatedroute: ActivatedRoute,
    private customOrderPaginationService: CustomOrderPaginationService,
    private customOrderReturnService: CustomOrderReturnService,
    private cmsService: CmsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.orderReturn) {
      this.order$ = this.orderReturn.pipe(
        tap((order) => (this.itemsForm.orderCode = order.order.code)),
        tap((d) => {
          this.refundsReasons$ = this.customOrderReturnService
            .getRefundReasonsDevolutions(this.itemsForm.orderCode)
            .pipe(pluck('refundReasons'));
        }),
        tap((order) => this.entries(order.returnEntries!))
      );
    } else {
      this.order$ = this.orderDetailsService.getOrderDetails();
      this.subscription.add(
        this.order$
          .pipe(
            tap((order) => (this.itemsForm.orderCode = order.code)),
            tap((d) => {
              this.refundsReasons$ = this.customOrderReturnService
                .getRefundReasonsDevolutions(this.itemsForm.orderCode)
                .pipe(pluck('refundReasons'));
            }),
            tap((order) => this.entries(order.entries!))
          )
          .subscribe()
      );
    }

    this.subscription.add(
      this.customOrderReturnService.sendOrderReturn$
        .pipe(
          switchMap((value) => {
            if (value) {
              return this.order$.pipe(
                tap((order: Order) => {
                  let itemsOrderReturn: any[] = [];
                  for (let item of this.itemsForm.returnRequestEntryInputs) {
                    itemsOrderReturn.push(
                      order.entries!.find(
                        (entry) => entry.entryNumber == item.orderEntryNumber
                      )
                    );
                  }
                  this.resetPagination(itemsOrderReturn);
                  this.confirmOrder.emit(this.itemsForm);
                })
              );
            } else {
              return this.order$.pipe(
                tap((order: Order) => {
                  this.resetPagination(order.entries!);
                })
              );
            }
          })
        )
        .subscribe()
    );
    this.cd.detectChanges();
  }

  resetPagination(entries: any[]) {
    this.pagination.totalPages =
      Math.round(entries?.length! / this.pageSize! + 0.4) > 1
        ? Math.round(entries?.length! / this.pageSize! + 0.4)
        : 1;
    this.pagination.currentPage = 0;
    this.selectedPage = this.pagination.currentPage!;
    this.pagination.totalResults = entries.length;
    this.pageChange(0);
    this.entries(entries);
  }

  entries(entries?: any[], value?: number) {
    this.selectedPage = this.pagination.currentPage!;
    this.pagination.totalResults = entries!.length;
    entries ? (this.orderDetails = entries) : this.orderDetails;
    if (this.pagination.currentPage == 0) {
      this.orderDetailsShow = this.orderDetails.slice(0, this.pageSize);
    } else {
      this.orderDetailsShow = this.orderDetails.slice(
        value,
        value! + this.pageSize
      );
    }
    this.pagination.pageSize = this.pageSize;
    this.pagination.totalPages =
      Math.round(entries?.length! / this.pageSize! + 0.4) > 1
        ? Math.round(entries?.length! / this.pageSize! + 0.4)
        : 1;
  }

  customPageChange(event: any) {
    this.pageChange(event);
    this.selectedPage < this.pagination.pageSize!
      ? (this.selectedPage = this.pagination.currentPage!)
      : (this.selectedPage = this.pagination.currentPage!);
    this.entries(this.orderDetails, this.pageSize * event);
  }

  pageChange(page: number) {
    this.pagination.currentPage = page;
    this.customOrderPaginationService.onPageChange(this.pagination);
  }

  filterQuantitySelected(filter: any) {
    this.pageSize = filter;
    this.pagination.currentPage! = 0;
    this.entries(this.orderDetails, filter);
    this.pageChange(0);
  }

  productsSelected(event: any) {
    if (
      event.enable == true &&
      !this.itemsSelected.find((item) => item == event.item)
    ) {
      this.itemsSelected.push(event.item);
    } else if (
      event.enable == false &&
      this.itemsSelected.find((item) => item == event.item)
    ) {
      this.itemsSelected = this.itemsSelected.filter(
        (item) => item !== event.item
      );
      this.itemsForm.returnRequestEntryInputs =
        this.itemsForm.returnRequestEntryInputs.filter(
          (item) => item.codeProd !== event.item
        );
      this.submitForm();
    }
  }

  commentReturnOrder(comment: FormGroup) {
    this.itemsForm.comments = comment.controls['comment'].value;
  }

  submitForm(items?: FormGroup) {
    if (items && items.controls['itemSelected'].value == true) {
      this.itemsForm.returnRequestEntryInputs =
        this.itemsForm.returnRequestEntryInputs.filter(
          (item) => item.orderEntryNumber !== items.controls['orderEntry'].value
        );

      const item: CustomReturnOrderDetail = {
        itemSelected: items.controls['itemSelected'].value,
        codeProd: items.controls['codeProd'].value,
        orderEntryNumber: items.controls['orderEntry'].value,
        quantity: items.controls['quantity'].value,
        reason: items.controls['reasonReturn'].value,
        images: items.controls['files'].value,
        files: items.controls['images'].value,
        isValid: items.valid,
      };

      this.itemsForm.returnRequestEntryInputs.push(item);
    }
    const itemsFiltered = this.itemsForm.returnRequestEntryInputs.filter(
      (item) => item.isValid == false
    );
    if (
      itemsFiltered.length > 0 ||
      this.itemsForm.returnRequestEntryInputs.length < 1
    ) {
      this.sendDataEnabled = false;
    } else if (
      items &&
      items.controls['reasonReturn'].value == 'PROMESA_DV4' &&
      (items.controls['images'].value == null ||
        items.controls['images'].value.length == 0)
    ) {
      this.sendDataEnabled = false;
    } else {
      this.sendDataEnabled = true;
    }

    this.sendDataEnabled === true
      ? this.nextPage.emit(true)
      : this.nextPage.emit(false);
  }

  itemLoaded(item: any): any {
    let itemFound = this.itemsForm.returnRequestEntryInputs.find(
      (itemForm) => itemForm.orderEntryNumber == item.entryNumber
    );
    return itemFound;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
