import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CmsBannerComponent, isNotNullable } from '@spartacus/core';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomHeaderService {
  headerLogoData$: BehaviorSubject<CmsBannerComponent | null> =
    new BehaviorSubject<CmsBannerComponent | null>(null);
  constructor() {}

  toggleHeaderPositionFixed(fixed: boolean = false): void {
    let headerElement: any = document.getElementsByClassName('header')[0];

    fixed
      ? headerElement?.classList?.add('fixed')
      : headerElement?.classList?.remove('fixed');
  }

  toggleHeaderHeight(height: boolean): void {
    let headerElement: any = document.getElementsByClassName('header')[0];

    height
      ? headerElement?.classList?.add('height')
      : headerElement?.classList?.remove('height');
  }

  toggleVisibilitySearchbox(hide: boolean = false): void {
    let siteLogo: any = document.getElementsByClassName('SiteLogo')[0];
    if (hide) {
      siteLogo?.classList?.add('hide');
      document
        .getElementsByClassName('SearchBox')?.[0]
        ?.classList?.add('searchbox-order-sup');
      document
        .getElementsByClassName('results')?.[0]
        ?.classList?.add('results-scroll');
      document?.body?.classList?.add('searchbox-active-scroll');
    } else {
      siteLogo?.classList?.remove('hide');
      document
        .getElementsByClassName('SearchBox')?.[0]
        ?.classList?.remove('searchbox-order-sup');
      document
        ?.getElementsByClassName('results')?.[0]
        ?.classList?.remove('results-scroll');
      document?.body?.classList?.remove('searchbox-active-scroll');
    }
  }

  toggleVisibilityTopHeaderSlot(hide: boolean = false): void {
    let topBarelement: any =
      document?.getElementsByClassName('TopHeaderSlot')?.[0];

    hide
      ? topBarelement?.classList?.add('hide')
      : topBarelement?.classList?.remove('hide');
  }

  setHeaderLogoData(value: CmsBannerComponent): void {
    if (this.headerLogoData$.value == null) {
      this.headerLogoData$.next(value);
    }
  }

  getHeaderLogoData(): Observable<CmsBannerComponent> {
    return this.headerLogoData$.asObservable().pipe(filter(isNotNullable));
  }

  fixHeaderAndHideTopBar(): void {
    this.toggleVisibilityTopHeaderSlot(true);
    this.toggleHeaderPositionFixed(true);
  }

  hideSearchboxAndChangeHeight(): void {
    this.toggleVisibilitySearchbox(true);
    this.toggleHeaderHeight(true);
  }

  resetHeader(): void {
    this.toggleVisibilityTopHeaderSlot(false);
    this.toggleVisibilitySearchbox(false);
    this.toggleHeaderPositionFixed(false);
    this.toggleHeaderHeight(false);
  }
}
