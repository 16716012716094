<app-custom-modal-generic (closeModal)="customDismissModal($event, restoreCart)" [isClose]="true"></app-custom-modal-generic>

<ng-container *ngIf="restoreCart; else deleteCart">
  <div class="restore-cart-container">
    <div class="restore-cart-container__text">
      <ng-container *ngIf="cartSavedName.length < 14; else nameWithSlice">
        <p>
          {{
            "shoppingList.restoreText"
              | cxTranslate : { cartName: cartSavedName }
          }}
        </p>
      </ng-container>
      <ng-template #nameWithSlice>
        <p>
          {{
            "shoppingList.restoreText"
              | cxTranslate : { cartName: cartSavedName.slice(0, 14) + "..." }
          }}
        </p>
      </ng-template>
    </div>
    <div class="restore-cart-container__actions">
      <a
        class="btn btn-primary"
        [routerLink]="{ cxRoute: 'cart' } | cxUrl"
        (click)="customDismissModal($event)"
      >
        <span> {{ "addToCart.viewCart" | cxTranslate }} </span>
      </a>
    </div>
  </div>
</ng-container>
<ng-template #deleteCart>
  <div class="delete-cart-container">
    <div class="delete-cart-container__icon">
      <cx-icon type="EXCLAMATION_TRIANGLE"></cx-icon>
    </div>
    <div class="delete-cart-container__text">
      <ng-container *ngIf="cartSavedName.length < 14; else nameWithSliceDelete">
        <p
          [innerHTML]="
            'shoppingList.deleteText'
              | cxTranslate : { cartName: cartSavedName }
              | safeHtml
          "
        ></p>
      </ng-container>
      <ng-template #nameWithSliceDelete>
        <p
          [innerHTML]="
            'shoppingList.deleteText'
              | cxTranslate : { cartName: cartSavedName.slice(0, 14) + '...' }
              | safeHtml
          "
        ></p>
      </ng-template>
    </div>
    <div class="delete-cart-container__actions">
      <button class="btn btn-primary delete" (click)="customDeleteCart($event)">
        <span> {{ "common.delete" | cxTranslate }} </span>
      </button>
      <button class="btn btn-primary" (click)="customDismissModal($event)">
        <span> {{ "common.cancel" | cxTranslate }} </span>
      </button>
    </div>
  </div>
</ng-template>
