<ng-container *ngIf="!fromFacet && !reviewsMobilePDP">
  <div
    class="custom-product-reviews-overview"
    [ngClass]="{ intro: fromProductIntro }"
    *ngIf="reviews$ | async as reviews"
  >
    <ng-container *ngIf="reviewStars$ | async as reviewStars">
      <ng-container *ngFor="let star of reviewStars">
        <ng-container [ngSwitch]="star">
          <cx-icon
            *ngSwitchCase="starStyle.FULL"
            class="custom-product-reviews-overview__full-star"
            type="STAR"
          ></cx-icon>

          <cx-icon
            *ngSwitchCase="starStyle.HALF"
            class="custom-product-reviews-overview__half-star"
            type="HALF_STAR"
          ></cx-icon>

          <cx-icon
            *ngSwitchCase="starStyle.EMPTY"
            class="custom-product-reviews-overview__empty-star"
            type="EMPTY_STAR"
          ></cx-icon>
        </ng-container> </ng-container
    ></ng-container>
  </div>
  <a
    *ngIf="fromProductIntro"
    class="custom-product-reviews-overview__intro-count"
    (click)="moveToElementRefReviews()"
    >{{ "productDetails.reviewsCount" | cxTranslate : { count: count } }}</a
  >
</ng-container>

<ng-container *ngIf="reviewsMobilePDP">
  <div class="custom-product-reviews-overview">
    <ng-container *ngIf="facetReviewPDP">
      <ng-container *ngFor="let star of facetReviewPDP">
        <ng-container [ngSwitch]="star">
          <cx-icon
            *ngSwitchCase="starStyle.FULL"
            class="custom-product-reviews-overview__full-star"
            type="STAR"
          ></cx-icon>

          <cx-icon
            *ngSwitchCase="starStyle.HALF"
            class="custom-product-reviews-overview__half-star"
            type="HALF_STAR"
          ></cx-icon>

          <cx-icon
            *ngSwitchCase="starStyle.EMPTY"
            class="custom-product-reviews-overview__empty-star"
            type="EMPTY_STAR"
          ></cx-icon>
        </ng-container> </ng-container
    ></ng-container>
  </div>
</ng-container>

<ng-container *ngIf="fromFacet && !reviewsMobilePDP && reviewRatingAverage">
  <div class="custom-product-reviews-overview" [ngClass]="{ menu: fromMenu }">
    <div
      class="custom-product-reviews-overview__selector"
      *ngIf="fromMenu"
      [ngClass]="{ selected: selected }"
    >
      <cx-icon type="CHECK_MENU" *ngIf="selected"></cx-icon>
    </div>
    <span class="custom-product-reviews-overview__average" *ngIf="fromMenu"
      >{{
        "filterProductsReviews.averageReview"
          | cxTranslate : { count: reviewRatingAverage }
      }}
    </span>
    <ng-container *ngFor="let star of facetReviewRating">
      <ng-container [ngSwitch]="star">
        <cx-icon
          *ngSwitchCase="starStyle.FULL"
          class="custom-product-reviews-overview__full-star"
          type="STAR"
        ></cx-icon>

        <cx-icon
          *ngSwitchCase="starStyle.HALF"
          class="custom-product-reviews-overview__half-star"
          type="HALF_STAR"
        ></cx-icon>

        <cx-icon
          *ngSwitchCase="starStyle.EMPTY"
          class="custom-product-reviews-overview__empty-star"
          type="EMPTY_STAR"
        ></cx-icon>
      </ng-container>
    </ng-container>
    <span class="custom-product-reviews-overview__count" *ngIf="fromMenu">{{
      "(" + count + ")"
    }}</span>
  </div>
</ng-container>
