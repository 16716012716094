import { RoutingConfig } from '@spartacus/core';

export const CustomRoutingConfig: RoutingConfig = {
  routing: {
    routes: {
      register: {
        paths: ['/register']
      },
      ordersCancel: {
        paths: ['my-account/order/cancels'],
      },
      claimOrder: {
        paths: ['my-account/add-claim/:orderCode'],
        paramsMapping: { orderCode: 'code' },
      },
      claimOrderDetail: {
        paths: ['my-account/claim-detail/:claimCode'],
        paramsMapping: { claimCode: 'id' },
      },
      claimTickets: {
        paths: ['my-account/claims'],
      },
      paymentRecord: {
        paths: ['/my-account/paymentRecord/:orderCode'],
        paramsMapping: { orderCode: 'code' },
      },
      returnOrderDetail: {
        paths: ['my-account/return-detail/:orderCode'],
        paramsMapping: { orderCode: 'code' },
      },
      returnOrder: {
        paths: ['my-account/return/:orderCode'],
        paramsMapping: { orderCode: 'code' },
      },
      ordersDevolution: {
        paths: ['my-account/returns'],
      },
      quoteDetail: {
        paths: ['my-account/quote-detail/:quoteCode'],
        paramsMapping: { quoteCode: 'code' },
      },
      quotes: {
        paths: ['my-account/quotes'],
      },
      wishlist: {
        paths: ['my-account/wishlist'],
      },
      registerEmployees: {
        paths: ['/register-internal-promesa-client'],
      },
    },
  },
};
