import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveCartService, CmsService } from '@spartacus/core';
import { CartTotalsComponent } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomNavigationService } from '../../navigation/services/custom-navigation.service';
import { pluck } from 'rxjs/operators';
import { CustomPromeclubUserService } from '../../my-account/services/custom-promeclub-user.service';
import { CustomUser } from '../../user/models/custom-user.interface';

@Component({
  selector: 'app-custom-cart-totals',
  templateUrl: './custom-cart-totals.component.html',
  styleUrls: ['./custom-cart-totals.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomCartTotalsComponent
  extends CartTotalsComponent
  implements OnInit
{
  isMobile$: Observable<boolean>;
  isInPromeclub = this.service.isInPromeClub();
  pageCurrent$: Observable<string | undefined> = this.cmsService
    .getCurrentPage()
    .pipe(pluck('pageId'));
  user: CustomUser;
  constructor(
    private cmsService: CmsService,
    protected activeCartService: ActiveCartService,
    private service: CustomNavigationService,
    private customBreakpointService: CustomBreakpointService,
    private customPromeclubUserService: CustomPromeclubUserService,
    protected router?: Router
  ) {
    super(activeCartService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isMobile$ = this.customBreakpointService.isMobile$;
    this.user = this.customPromeclubUserService.getUser();
  }
}
