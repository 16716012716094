import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-order-registered-successfully-dialog',
  templateUrl: './custom-order-registered-successfully-dialog.component.html',
  styleUrls: ['./custom-order-registered-successfully-dialog.component.scss'],
})
export class CustomOrderRegisteredSuccessfullyDialogComponent {
  order: string;
  showFileTypeError: boolean;
  showUnknowErrorFile: boolean;
  isFromPaymentRecord: boolean = false;
  constructor(protected modalService: ModalService, private router: Router) {}

  closeModal(reason?: any) {
    this.modalService.dismissActiveModal(reason);
    this.order
      ? this.router.navigateByUrl(`/my-account/order/${this.order}`)
      : false;
  }
}
