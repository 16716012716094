export const product = {
  productDetails: {
    id: 'ID',
    quantity: 'Cant',
    productDetails: 'Detalles de producto',
    specification: 'Especificaciones',
    reviews: 'Reseñas',
    shipping: 'Envío',
    share: 'Compartir',
    showReviews: 'Mostrar reseñas',
    noReviews: 'Aún no hay reseñas',
    productPrice: 'Precio del producto',
    reviewsCount: '{{count}} Reseña',
    reviewsCount_plural: '{{count}} Reseñas',
    productDetailsTitle: 'Detalle de producto',
    showMore: 'Ver más',
    showLess: 'Ver menos',
  },
  productList: {
    filterBy: {
      label: 'Filtrar',
      action: 'Filtros',
    },
    appliedFilter: 'Filtros activos',
    showLess: 'Ver menos',
    showMore: 'Ver más',
    showMoreProducts: 'Ver más productos',
    showLessProducts: 'Ver menos productos',
    showAll: 'Ver todo',
    sortBy: 'Ordenar por:',
    sortByMobile: 'Ordenar',
    backToTopBtn: 'VOLVER ARRIBA',
    showMoreBtn: 'MOSTRAR MÁS',
    productSearchPagination: 'Paginación de búsqueda de productos',
    points: 'Puntos',
    seeResults: 'Ver resultados ({{count}})',
    cleanFilters: 'Limpiar filtros',
  },
  productFacetNavigation: {
    filterBy: {
      label: 'Filtrar / Ordenar productos',
      action: 'Filtrar por',
    },
    appliedFilter: 'Filtro aplicado:',
    showLess: 'Mostrar menos...',
    showMore: 'Mostrar más...',
    sortBy: 'Ordenar por: ',
  },
  productSummary: {
    id: 'ID',
    showReviews: 'Mostrar reseñas',
    showReviewsDetailed:
      'Mostrar {{count}} reseñas, puntuado {{rating}} de 5 estrellas',
    share: 'Compartir',
    newItemPrice: 'Precio de artículo nuevo',
  },
  productReview: {
    overallRating: 'Calificación general',
    reviewTitle: 'Título de Reseña',
    writeYourComments: 'Escribe tus comentarios',
    rating: 'Clasificación',
    reviewerName: 'Nombre del revisor (opcional)',
    writeReview: 'Escribe una reseña',
    more: 'Más',
    less: 'Menos',
    thankYouForReview: '¡Gracias por brindarnos tu opinión!',
  },
  addToCart: {
    itemsAddedToYourCart: 'Producto <b>Agregado al Carrito</b>',
    itemsAddedToYourCartMobile: 'Producto agregado al carrito',
    itemsAddedToYourCartPromeclub: 'Premio <b>Agregado al Carrito</b>',
    itemsAddedToYourCartPromeclubMobile: 'Premio agregado al carrito',
    completeEntryPromeclub: 'Completar <b>premio</b>',
    completeEntryPromeclubMobile: 'Completar premio',
    itemsIncrementedInYourCart:
      'Este Producto ya Estaba en tu Carrito de Compras. <b>Se Actualizó La Cantidad.</b>',
    items: 'artículos',
    updatingCart: 'Actualizando carrito ...',
    addToCart: 'Agregar al carrito',
    addToCartMobile: 'Agregar al carrito',
    viewCart: 'Ver carrito',
    proceedToCheckout: 'Proceder al pago',
    continueBuying: 'Seguir comprando',
    continueBuyingPromeclub: 'Seguir canjeando',
    continueBuyingMobile: 'Continuar comprando',
    quantity: 'Cantidad',
    outOfStock: 'Sin stock',
    inStock: 'En stock',
    selectStyleAndSize: 'Seleccione estilo y tamaño para comprobar stock',
    removeFromCart: 'Eliminar producto del carrito',
    closeModal: 'Cerrar modal',
    unitPrice: 'Precio x ',
    unitPricePromeclub: 'Puntos x ',
    total: 'Total',
    emptyTotalMobile: 'Total:',
    bottomSubmitText:
      'Suma {{points}} puntos PromeClub comprando este producto',
    availableStock: 'Stock disponible: {{stock}}',
    removeItem: '¿Seguro quieres <b>eliminar el siguiente producto?</b>',
    removeItemMobile: '¿Seguro quieres eliminar el siguiente producto?',
    confirmRemoveItem: 'Si, eliminar producto',
    confirmRemoveItemMobile: 'Eliminar producto',
    confirmRemoveItemPromeclub: 'Si, eliminar premio',
    confirmRemoveItemPromeclubMobile: 'Eliminar premio',
    cancelRemove: 'Cancelar',
  },
  TabPanelContainer: {
    tabs: {
      ProductDetailsTabComponent: 'Detalles de producto',
      ProductSpecsTabComponent: 'Especificaciones técnicas',
      ProductReviewsTabComponent: 'Reseñas de clientes',
      deliveryTab: 'Envío',
      SparePartsTabComponent: ' Piezas de repuesto',
    },
  },
  addToWishList: {
    add: 'Añadir a la Lista de Deseos',
    remove: 'Eliminar de la Lista de Deseos',
    anonymous: 'Inicie sesión para agregar a la lista de deseos',
  },
  stockNotification: {
    notifyMe: 'NOTIFICARME',
    stopNotify: 'DETENER NOTIFICACION',
    getNotify: 'Ser notificado cuando este producto está disponible.',
    getNotifySuffix:
      'para recibir una notificación cuando este producto esté disponible.',
    activateChannelsPrefix: 'Para ser notificado, debe activar el',
    channelsLink: 'Canales de Notificación',
    activateChannelsSuffix: '.',
    notified:
      'Se le notificará cuando este producto vuelva a estar disponible.',
    getNotified:
      'Reciba una notificación cuando este producto vuelva a estar disponible.',
    unsubscribeSuccess:
      'No recibirá una notificación de stock de este producto.',
    subscriptionDialog: {
      header: 'Suscripción de Stock Agotado',
      notifiedPrefix: 'Se le notificará el:',
      notifiedSuffix:
        'tan pronto como este producto vuelva a estar disponible.',
      manageChannelsPrefix:
        'Administre sus canales de notificación preferidos en el',
      manageChannelsLink: 'Preferencia de notificación',
      manageChannelsSuffix: ' página.',
      manageSubscriptionsPrefix: 'Puede administrar sus suscripciones en',
      manageSubscriptionsLink: 'Mis intereses',
      manageSubscriptionsSuffix: ' página.',
      okBtn: 'OK',
      subscribing:
        'Suscribiéndote a notificaciones de stock agotado para este producto',
    },
  },
  itemCounter: {
    removeOne: 'Quitar uno',
    addOneMore: 'Añadir uno más',
    quantity: 'Cantidad',
  },
  productView: {
    gridView: 'Cuadrícula',
    listView: 'Tabla',
  },
};
