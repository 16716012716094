<ng-container
  *ngIf="{
    model: model$ | async,
    pageTemplate: pageTemplate$ | async,
    actualViewSelectors: actualViewSelectors$ | async
  } as data"
>
  <ng-container *ngIf="data?.model?.products.length > 0">
    <button
      *ngIf="!(isMobile$ | async)"
      #trigger
      class="btn btn-action btn-block dialog-trigger"
      (click)="launch()"
    >
      <cx-icon [type]="iconTypes.FILTER"></cx-icon>
      {{ "productList.filterBy.label" | cxTranslate }}
    </button>

    <app-custom-active-facets
      *ngIf="
        data?.model?.breadcrumbs.length > 0 &&
        !(
          data?.model?.breadcrumbs.length == 1 &&
          data?.model?.breadcrumbs[0]?.facetCode === 'allCategories'
        ) &&
        !(isMobile$ | async)
      "
    ></app-custom-active-facets>

    <div class="search-facets">
      <label
        [class.dirty]="!!searchInput.value"
        *ngIf="data?.model?.pagination.totalResults == 1"
        >{{ data?.model?.pagination.totalResults }} producto disponible</label
      >
      <label
        [class.dirty]="!!searchInput.value"
        *ngIf="data?.model?.pagination.totalResults !== 1"
        >{{ data?.model?.pagination.totalResults }} productos disponibles</label
      >
      <!-- *** selector -->
      <ng-container *ngIf="isMobile$ | async">
        <app-custom-product-variant-list-header
          *ngIf="data.actualViewSelectors == 'list'"
          [showSubCategories]="false"
        ></app-custom-product-variant-list-header>
      </ng-container>
      <!-- *** selector -->
      <div
        class="search-facets-word d-flex"
        [ngClass]="{
          'search-page':
            data?.pageTemplate?.template === 'SearchResultsListPageTemplate' ||
            isPromeclub
        }"
      >
        <cx-icon type="SEARCH" class="search-facets-word__icon"></cx-icon>
        <input
          class="search-facets-word__input"
          #searchInput
          [placeholder]="'searchBoxFacets.placeholder' | cxTranslate"
          autocomplete="off"
          aria-describedby="initialDescription"
          aria-controls="results"
          [attr.aria-label]="'common.search' | cxTranslate"
          (keyup)="inputSearch = $event.target.value"
          (keydown.enter)="
            searchProducts(data?.model?.currentQuery, inputSearch)
          "
        />

        <button
          [attr.aria-label]="'searchBoxFacets.placeholder' | cxTranslate"
          class="custom-button primary search-facets__button"
          (click)="searchProducts(data?.model?.currentQuery, inputSearch)"
        >
          <cx-icon class="fas fa-chevron-right"></cx-icon>
        </button>
      </div>
      <button
        [attr.aria-label]="'searchBoxFacets.placeholder' | cxTranslate"
        class="custom-button primary search-facets__button-filter dialog-trigger"
        (click)="launch()"
        #trigger
        *ngIf="isMobile$ | async"
      >
        <cx-icon type="FILTER_MOBILE"></cx-icon>
        <span>{{
          "searchBoxFacets.filterAndOrder"
            | cxTranslate : { count: data?.model?.pagination?.totalResults }
        }}</span>
      </button>
    </div>
    <div
      class="backdrop"
      *ngIf="(isOpen$ | async) && (isMobile$ | async)"
      (click)="close()"
    ></div>
    <app-custom-facet-list
      *ngIf="isOpen$ | async"
      [model]="data?.model"
      [isDialog]="hasTrigger"
      (closeList)="close()"
      [class.active]="isActive$ | async"
      [class.dialog]="hasTrigger"
    ></app-custom-facet-list>
  </ng-container>
</ng-container>
