import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomPaginationBuilder } from './custom-pagination.builder';
import { CustomPaginationComponent } from './custom-pagination.component';

@NgModule({
  declarations: [CustomPaginationComponent],
  imports: [CommonModule, IconModule, ReactiveFormsModule, I18nModule],
  exports: [CustomPaginationComponent],
  providers: [CustomPaginationBuilder],
})
export class CustomPaginationModule {}
