<ng-container *ngIf="pageCurrent$ | async as pageCurrent">
  <table class="table table-hover custom-order-history-table" *ngIf="orders">
    <thead>
      <tr *ngIf="pageCurrent !== 'QuotePage'; else quoteTr">
        <th scope="col" colspan="1">
          {{
            pageCurrent == "CancelOrderPage"
              ? ("orderHistory.dateCancelled" | cxTranslate)
              : currentPage == "returns"
              ? ("returnRequestList.dateCreate" | cxTranslate)
              : currentPage == "claimsPage"
              ? ("orderDetails.claim.list.lastDate" | cxTranslate)
              : ("orderHistory.date" | cxTranslate)
          }}
        </th>
        <th *ngIf="pageCurrent == 'claimsPage'" scope="col" colspan="1">
          {{ "orderDetails.claim.list.creationDate" | cxTranslate }}
        </th>
        <th scope="col" colspan="1">
          {{
            pageCurrent == "CancelOrderPage"
              ? ("orderHistory.orderCancelId" | cxTranslate)
              : currentPage == "returns"
              ? ("returnRequestList.numberReturn" | cxTranslate)
              : currentPage == "claimsPage"
              ? ("orderDetails.claim.list.number" | cxTranslate)
              : ("orderHistory.orderId" | cxTranslate)
          }}
        </th>
        <th *ngIf="currentPage != 'claimsPage'" scope="col" colspan="1">
          {{ "orderHistory.total" | cxTranslate }}
        </th>
        <th scope="col" colspan="1" *ngIf="pageCurrent !== 'CancelOrderPage'">
          {{ "orderHistory.status" | cxTranslate }}
        </th>
      </tr>
      <ng-template #quoteTr>
        <tr>
          <th scope="col" colspan="1">
            {{ "accountQuotes.quotesTable.dateUpdate" | cxTranslate }}
          </th>
          <th scope="col" colspan="1">
            {{ "accountQuotes.quotesTable.quoteNumber" | cxTranslate }}
          </th>
          <th scope="col" colspan="1">
            {{ "accountQuotes.quotesTable.quoteName" | cxTranslate }}
          </th>
          <th scope="col" colspan="1" *ngIf="pageCurrent !== 'CancelOrderPage'">
            {{ "accountQuotes.quotesTable.status" | cxTranslate }}
          </th>
        </tr>
      </ng-template>
    </thead>
    <tbody>
      <tr
        *ngFor="let order of ordersItems"
        (click)="goToOrderDetail(order)"
        [ngClass]="{ 'tr-bold': order.isRead == false }"
        [attr.data-cy]="order?.paymentMode?.code"
      >
        <!-- Order last modification date -->
        <td *ngIf="pageCurrent == 'claimsPage'" class="cx-order-history-placed">
          <a class="cx-order-history-value">
            {{
              order.creationTime || order.lastModificationDate
                | date : "dd/MM/yyyy"
            }}
          </a>
        </td>

        <!-- Order date -->
        <ng-container *ngIf="!cancellationsPage; else formattedCancelDate">
          <td class="cx-order-history-placed">
            <a
              [routerLink]="
                {
                  cxRoute:
                    currentPage == 'returns'
                      ? 'returnOrderDetail'
                      : 'orderDetails',
                  params: currentPage == 'returns' ? order.code : order
                } | cxUrl
              "
              class="cx-order-history-value"
              *ngIf="!fromQuote"
            >
              {{
                order?.placed
                  ? (order?.placed | date : "dd/MM/yyyy")
                  : (order.creationTime || order.creationDate
                    | date : "dd/MM/yyyy")
              }}
            </a>
            <a
              [routerLink]="
                {
                  cxRoute:
                    currentPage == 'returns'
                      ? 'returnOrderDetail'
                      : 'orderDetails',
                  params: currentPage == 'returns' ? order.code : order
                } | cxUrl
              "
              class="cx-order-history-value"
              *ngIf="fromQuote"
              >{{ order?.updatedTime | date : "dd/MM/yyyy" }}</a
            >
          </td>
        </ng-container>
        <ng-template #formattedCancelDate>
          <td class="cx-order-history-placed">
            <a
              [routerLink]="
                {
                  cxRoute:
                    currentPage == 'returns'
                      ? 'returnOrderDetail'
                      : 'orderDetails',
                  params: currentPage == 'returns' ? order.code : order
                } | cxUrl
              "
              class="cx-order-history-value"
            >
              {{ order?.formattedCancelDate }}
            </a>
          </td>
        </ng-template>

        <!-- Order number -->

        <td class="cx-order-history-code">
          <a
            [routerLink]="
              {
                cxRoute:
                  currentPage == 'returns'
                    ? 'returnOrderDetail'
                    : currentPage == 'claimsPage'
                    ? 'claimOrderDetail'
                    : 'orderDetails',
                params: currentPage == 'returns' ? order.code : order
              } | cxUrl
            "
            class="cx-order-history-value"
          >
            {{ order?.code || order?.id }}</a
          >
        </td>

        <!-- Order total -->
        <td *ngIf="currentPage != 'claimsPage'" class="cx-order-history-total">
          <a
            *ngIf="!fromQuote"
            [routerLink]="
              {
                cxRoute:
                  currentPage == 'returns'
                    ? 'returnOrderDetail'
                    : 'orderDetails',
                params: currentPage == 'returns' ? order.code : order
              } | cxUrl
            "
            class="cx-order-history-value total"
          >
            {{
              order?.total?.value
                ? (order?.total?.value | customCurrency)
                : (order?.totalPrice?.value | customCurrency)
            }}</a
          >
          <a
            *ngIf="fromQuote"
            [routerLink]="
              {
                cxRoute:
                  currentPage == 'returns'
                    ? 'returnOrderDetail'
                    : 'orderDetails',
                params: currentPage == 'returns' ? order.code : order
              } | cxUrl
            "
            class="cx-order-history-value"
          >
            {{ order?.name }}</a
          >
        </td>

        <!-- Order status -->
        <td
          class="cx-order-history-status"
          *ngIf="pageCurrent !== 'CancelOrderPage'"
        >
          <a
            *ngIf="!fromQuote"
            [routerLink]="
              {
                cxRoute:
                  currentPage == 'returns'
                    ? 'returnOrderDetail'
                    : 'orderDetails',
                params: currentPage == 'returns' ? order.code : order
              } | cxUrl
            "
            class="cx-order-history-value"
          >
            {{
              currentPage == "returns"
                ? ("returnRequestList.statusDisplay_" + order?.status
                  | cxTranslate)
                : currentPage == "claimsPage"
                ? ("orderDetails.statusDisplay_" + order?.status.id
                  | cxTranslate)
                : ("orderDetails.statusDisplay_" + order?.status | cxTranslate)
            }}</a
          >
          <a
            *ngIf="fromQuote"
            [routerLink]="
              {
                cxRoute: 'quoteDetail',
                params: order
              } | cxUrl
            "
            class="cx-order-history-value"
          >
            {{
              "accountQuotes.quotesTable.statusDisplay_" + order?.state
                | cxTranslate
            }}
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
