import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-register-employee-account-dialog',
  templateUrl: './custom-register-employee-account-dialog.component.html',
  styleUrls: ['./custom-register-employee-account-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomRegisterEmployeeAccountDialogComponent implements OnInit {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;

  constructor(
    private modalService: ModalService,
    private customBreakpointService: CustomBreakpointService
  ) { }

  ngOnInit(): void { }

  closeModal(reason: string) {
    this.modalService.dismissActiveModal(reason);
  }

}
