import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductImagesComponent } from './custom-product-images.component';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { CarouselModule, MediaModule } from '@spartacus/storefront';
import { SwiperModule } from 'swiper/angular';
import { CustomImage360Module } from './custom-image360.component/custom-image360.module';

@NgModule({
  declarations: [
    CustomProductImagesComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    MediaModule,
    CarouselModule,
    SwiperModule,
    CustomImage360Module,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductImagesComponent: {
          component: CustomProductImagesComponent
        },
      },
    } as CmsConfig),
  ],
  exports: [
    CustomProductImagesComponent
  ]
})
export class CustomProductImagesModule { }
