import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CheckoutDeliveryFacade } from '@spartacus/checkout/root';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomAuthorizedPerson } from '../../../models/custom-authorized-person';
import { CustomAuthorizedPersonService } from '../../../services/custom-authorized-person.service';
import { CustomCheckoutDeliveryStepsService } from '../../../services/custom-checkout-delivery-steps.service';

@Component({
  selector: 'app-custom-authorized-person',
  templateUrl: './custom-authorized-person.component.html',
  styleUrls: ['./custom-authorized-person.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomAuthorizedPersonComponent implements OnInit, OnDestroy {
  authorizedPersonForm: FormGroup = this.fb.group({
    authorizedPersonId: ['', Validators.required],
  });
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  editPerson: boolean = false;
  authorizedPeople$: Observable<CustomAuthorizedPerson[]>;
  personSelected: any;
  personSelectedEditable: CustomAuthorizedPerson | null;
  @Input() userId: string;

  showAddPerson$: Observable<boolean> =
    this.customAuthorizedPersonService.getShowAddAuthorizedPerson();

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private customAuthorizedPersonService: CustomAuthorizedPersonService,
    protected userAccountFacade: UserAccountFacade,
    protected checkoutDeliveryService: CheckoutDeliveryFacade,
    private customBreakpointService: CustomBreakpointService,
    private customCheckoutStepsService: CustomCheckoutDeliveryStepsService
  ) {}

  ngOnInit(): void {
    this.authorizedPeople$ = this.customAuthorizedPersonService
      .getListAuthorizedPeople(this.userId)
      .pipe(
        tap((list) => {
          if (!list?.length) {
            this.customCheckoutStepsService.setAuthorizedPersonSelected(false);
          } else {
            this.customCheckoutStepsService.setAuthorizedPersonSelected(true);
          }
        }),
        tap((list) =>
          list.length > 3 ? list.slice(list.length - 3, list.length) : list
        ),
        switchMap(() =>
          this.customAuthorizedPersonService.listPersonAuthorized$.pipe(
            tap((list) =>
              list.length > 3 ? list.slice(list.length - 3, list.length) : list
            )
          )
        )
      );
  }

  addAuthorizedPerson() {
    this.personSelectedEditable = null;
    this.editPerson = false;
    this.customAuthorizedPersonService.setEditAuthorizedPerson(null);
    this.customAuthorizedPersonService.setShowAddAuthorizedPerson(true);
  }

  editPersonAuthorized(person: CustomAuthorizedPerson, isMobile: boolean) {
    this.editPerson = true;
    if (!isMobile)
      this.customAuthorizedPersonService.setEditAuthorizedPerson(person);
    this.customAuthorizedPersonService.setShowAddAuthorizedPerson(true);
  }

  deletePersonAuthorized(
    person: CustomAuthorizedPerson,
    authorizedPeople: CustomAuthorizedPerson[]
  ) {
    this.customAuthorizedPersonService
      .deleteAuthorizedPerson(this.userId, person)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (authorizedPeople?.length == 1) {
          this.customCheckoutStepsService.setAuthorizedPersonSelected(false);
        }
      });
  }

  ngOnDestroy() {
    this.customAuthorizedPersonService.setShowAddAuthorizedPerson(false);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
