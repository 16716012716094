import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomPinDialogComponent } from './custom-pin-dialog.component';



@NgModule({
  declarations: [
    CustomPinDialogComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    I18nModule,
    ReactiveFormsModule,
    NgbModule
  ],
  exports: [
    CustomPinDialogComponent
  ],
})
export class CustomPinModule { }
