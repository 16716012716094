import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { OrderEntry, ProductService, Cart } from '@spartacus/core';
import { OnInit } from '@angular/core';
import { CustomGtmEcommerceDatalayerService } from 'src/app/common/services/custom-gtm-ecommerce-datalayer.service';
import { CustomCart } from '../../../../cart/model/custom-cart';
import { Observable, Subject } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { takeUntil } from 'rxjs/operators';
import { EcvProductType } from 'src/app/cms-components/product/constants/product-type';
import { CustomLoadingSpinnerService } from '../../../services/custom-loading-spinner.service';

@Component({
  selector: 'app-product-summary',
  templateUrl: './product-summary.component.html',
  styleUrls: ['./product-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductSummaryComponent implements OnInit, OnDestroy {
  @Input('entry') entry: OrderEntry;
  @Input('enableRedirect') enableRedirect: boolean = false;
  @Input() isInPromeclub: boolean;
  @Output() onRemoveItem = new EventEmitter<OrderEntry>();
  @Input() cart: Cart | CustomCart;
  @Input() productPosition: number;
  @Input() isInCheckout: boolean = false;
  @Input() clickedFrom: string;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  unsubscribe$: Subject<void> = new Subject();
  EcvProductType: string = EcvProductType;
  entryRemoved: boolean = false;
  constructor(
    private productService: ProductService,
    private customGtmDatalayerService: CustomGtmEcommerceDatalayerService,
    private customBreakpointService: CustomBreakpointService,
    private spinnerService: CustomLoadingSpinnerService
  ) {}

  ngOnInit(): void {}

  removeItem() {
    this.onRemoveItem.emit(this.entry);
    this.entryRemoved = true;
    if (this.isInCheckout) {
      this.spinnerService.showSpinner('');
    }
  }

  productClickEvent(code: string) {
    this.productService
      .get(code)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((product) => {
        this.customGtmDatalayerService.productClickEvent(
          product,
          this.productPosition,
          this.clickedFrom
        );
      });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
