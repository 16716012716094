import { Component, OnInit } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map, pluck, switchMap } from 'rxjs/operators';
import { CustomCategoryData } from 'src/app/cms-components/custom/components/custom-category-list/model/custom-category-list.interface';
import { CustomSearchBoxExtras } from 'src/app/cms-components/navigation/components/custom-searchbox/custom-searchbox-extras/model/custom-search-box-extras.interface';
import { CustomBrandsAndCategoriesService } from 'src/app/services/custom-brands-and-categories.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-searchbox-extras',
  templateUrl: './custom-searchbox-extras.component.html',
  styleUrls: ['./custom-searchbox-extras.component.scss'],
})
export class CustomSearchboxExtrasComponent implements OnInit {
  categories$: Observable<Observable<CustomCategoryData>[]>;
  brands$: Observable<Observable<CustomCategoryData>[]>;
  componentData$: Observable<any> = this.componentData.data$.pipe(
    pluck('extras')
  );
  success: boolean = false;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;

  constructor(
    private componentData: CmsComponentData<CustomSearchBoxExtras>,
    private customBreakpointService: CustomBreakpointService,
    private cmsService: CmsService,
    private customBrandsAndCategoriesService: CustomBrandsAndCategoriesService
  ) {}

  ngOnInit(): void {
    this.categories$ = this.componentData$.pipe(
      switchMap((data) => {
        return this.cmsService.getComponentData<any>(data).pipe(
          switchMap((data) => {
            return this.cmsService
              .getComponentData<any>(data?.categoryList?.uid)
              .pipe(
                pluck('categories'),
                map((categories) => {
                  const codeCategories: string[] = categories.split(' ');
                  let categoriesData: Observable<CustomCategoryData>[] = [];
                  if (codeCategories?.length > 0) {
                    for (let code of codeCategories) {
                      categoriesData.push(
                        this.customBrandsAndCategoriesService.getCategoryDataFromApi(
                          code
                        )
                      );
                    }
                  }
                  return categoriesData;
                })
              );
          })
        );
      })
    );
    this.brands$ = this.componentData$.pipe(
      switchMap((data) => {
        return this.cmsService.getComponentData<any>(data).pipe(
          switchMap((data) => {
            return this.cmsService
              .getComponentData<any>(data?.brandCategoryList?.uid)
              .pipe(
                pluck('categories'),
                map((categories) => {
                  const codeCategories: string[] = categories?.split(' ');
                  let categoriesData: Observable<CustomCategoryData>[] = [];
                  if (codeCategories?.length > 0) {
                    for (let code of codeCategories) {
                      categoriesData.push(
                        this.customBrandsAndCategoriesService.getCategoryDataFromApi(
                          code
                        )
                      );
                    }
                  }
                  return categoriesData;
                })
              );
          })
        );
      })
    );
  }
}
