<ng-container>
  <div
    class="custom-account-comment"
    [ngClass]="{
      'custom-account-comment-revision': currentPage == 'REVISION_ORDER_RETURN'
    }"
  >
    <p>{{ label }}</p>
    <form [formGroup]="commentForm" class="custom-account-comment__form">
      <textarea
        [placeholder]="placeholder"
        formControlName="comment"
        *ngIf="currentPage == 'EDIT_ORDER_RETURN'"
        (keyup)="onSendComment()"
      ></textarea>
      <p *ngIf="currentPage == 'REVISION_ORDER_RETURN' && valueDefault">
        {{ valueDefault }}
      </p>
    </form>
  </div>
</ng-container>
