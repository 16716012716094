import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  SavedCartActions,
  SavedCartConnector,
} from '@spartacus/cart/saved-cart/core';
import {
  CartActions,
  Cart,
  normalizeHttpError,
  ActiveCartService,
  GlobalMessageService,
} from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map, withLatestFrom, switchMap, catchError } from 'rxjs/operators';
import { CustomSavedCartActions } from '../actions';

@Injectable()
export class CustomSavedCartEffects {
  @Effect()
  customRestoreSavedCart$: Observable<
    | SavedCartActions.RestoreSavedCartFail
    | SavedCartActions.RestoreSavedCartSuccess
    | SavedCartActions.LoadSavedCarts
    | SavedCartActions.SaveCart
    | CartActions.LoadCartSuccess
    | CartActions.SetActiveCartId
  > = this.actions$.pipe(
    ofType(CustomSavedCartActions.CUSTOM_RESTORE_SAVED_CART),
    map(
      (action: CustomSavedCartActions.CustomRestoreSavedCart) => action.payload
    ),
    withLatestFrom(this.activeCartService.getActive()),
    switchMap(([{ userId, cartId }, activeCart]) => {
      const actions: any[] = [];
      if ((activeCart?.entries ?? []).length > 0) {
        if (activeCart.code) {
          /**
           * Instead of calling the SaveCartAction, we are calling the edit saved cart
           * because we do not want to clear the state when we swap carts between active and saved cart
           */
          actions.push(
            new SavedCartActions.EditSavedCart({
              userId,
              cartId: activeCart.code,
              saveCartName: '',
              saveCartDescription: '',
            })
          );
        }
      }

      return this.savedCartConnector.restoreSavedCart(userId, cartId).pipe(
        switchMap((savedCart: Cart) => {
          return [
            ...actions,
            new CartActions.SetActiveCartId(cartId),
            new CartActions.LoadCartSuccess({
              userId,
              cartId,
              cart: savedCart,
            }),
            new SavedCartActions.RestoreSavedCartSuccess({ userId, cartId }),
          ];
        }),
        catchError((error: HttpErrorResponse) =>
          of(
            new SavedCartActions.RestoreSavedCartFail({
              userId,
              cartId,
              error: normalizeHttpError(error),
            })
          )
        )
      );
    })
  );

  constructor(
    private actions$: Actions,
    private savedCartConnector: SavedCartConnector,
    private activeCartService: ActiveCartService,
    private globalMessageService: GlobalMessageService
  ) {}
}
