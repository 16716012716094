<app-custom-modal-generic></app-custom-modal-generic>

<div class="quote-reject">
  <h4 class="quote-reject__title">
    {{ "accountQuotes.reject.title" | cxTranslate }}
  </h4>
  <p class="quote-reject__text">
    {{ "accountQuotes.reject.text" | cxTranslate }}
  </p>

  <div class="quote-reject__form">
    <div class="quote-reject__form-input">
      <div class="quote-reject__form-input-label">
        {{ "accountQuotes.reject.reason" | cxTranslate }}
      </div>
      <textarea
        class="form-control"
        [autofocus]="false"
        [formControl]="comment"
        rows="3"
        placeholder="{{
          'accountQuotes.reject.reasonPlaceholder' | cxTranslate
        }}"
      >
      </textarea>
    </div>
    <div class="quote-reject__form-action">
      <a (click)="closeModal('close')" class="quote-reject__form-action-link">
        {{ "accountQuotes.reject.cancel" | cxTranslate }}
      </a>
      <button
        class="quote-reject__form-action-button"
        [disabled]="!comment?.value?.length"
        (click)="submitComment()"
      >
        {{ "accountQuotes.reject.button" | cxTranslate }}
      </button>
    </div>
  </div>
</div>
