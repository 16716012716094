import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomOrderConfirmationThanYouMessageComponent } from './components/custom-order-confirmation-than-you-message/custom-order-confirmation-than-you-message.component';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomSafeHtmlModule } from 'src/app/pipes/custom-safe-html/custom-safe-html.module';
import { CustomPaymentTypeModule } from 'src/app/feature-libs/checkout/components/custom-payment-type/custom-payment-type.module';
import { RouterModule } from '@angular/router';
import { OrderConfirmationGuard } from '@spartacus/checkout/components';

@NgModule({
  declarations: [CustomOrderConfirmationThanYouMessageComponent],
  imports: [
    CommonModule,
    I18nModule,
    ReactiveFormsModule,
    CustomSafeHtmlModule,
    CustomPaymentTypeModule,
    UrlModule,
    RouterModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromesaOrderConfirmationThankMessageComponent: {
          component: CustomOrderConfirmationThanYouMessageComponent,
          guards: [OrderConfirmationGuard],
        },
      },
    }),
  ],
  exports: [
    CustomOrderConfirmationThanYouMessageComponent
  ]
})
export class CustomOrderConfirmationModule { }
