<div *ngIf="{
    componentData: componentData$ | async,
    isMobile: isMobile$ | async
  } as info" class="custom-splitted-banner">
  <ng-container *ngIf="!!info.componentData">
    <ng-container *ngFor="let item$ of items$ | async; index as i">
      <ng-container *ngIf="!info.isMobile || (info.isMobile && i == 2)">
        <div class="custom-splitted-banner__img" *ngIf="item$ | async as item" tabindex="0">
          <div class="custom-splitted-banner__info" *ngIf="!!!item.video && !!item.headline">
            <h5 *ngIf="!!item.headline && !item.video" class="custom-splitted-banner__info-title"
              [innerHTML]="item.headline | safeHtml"></h5>
            <span *ngIf="!!item.content && !item.video" class="custom-splitted-banner__info-subtitle"
              [innerHTML]="item.content | safeHtml">
            </span>
            <a class="custom-button-anchor primary" [ngClass]="
                !!!item.linkTitle || info.isMobile ? 'without-text' : ''
              " [routerLink]="item.urlLink">
              <div class="grid-banner-display">
                <span [innerHTML]="item.linkTitle | safeHtml"></span>
                <img [src]="config.backend.occ.baseUrl + item.miscMedia.url" class="icon-banner" loading="lazy" />
              </div>
            </a>
          </div>

          <cx-generic-link [url]="!(!!item.headline || !!item.linkTitle) ? item.urlLink : null"
            *ngIf="!!!item.video; else videoTemplate">
            <ng-container *ngIf="item.image | async as itemImage">
              <img [src]="getImageUrl(itemImage.url)" [alt]="itemImage.altText" loading="lazy" />
            </ng-container>
          </cx-generic-link>
          <ng-template #videoTemplate>
            <video *ngIf="!!item?.video?.srcVideo" class="custom-splitted-banner__img-video" width="100%"
              [src]="item?.video?.srcVideo + '#t=0.5'" [autoplay]="false" [loop]="true" [controls]="true"
              [muted]="false"></video>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
