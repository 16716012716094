import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomRegisterCustomerComponent } from './custom-register-customer.component';
import { CustomRegisterCustomerValidationComponent } from './custom-register-customer-validation/custom-register-customer-validation.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CustomRegisterCustomerAccountComponent } from './custom-register-customer-account/custom-register-customer-account.component';
import { CustomRegisterSharedModule } from '../custom-register-shared/custom-register-shared.module';
import { CustomRegisterCustomerPasswordComponent } from './custom-register-customer-password/custom-register-customer-password.component';
import { IconModule } from '@spartacus/storefront';
import { CustomPinModule } from '../../../../common/components/custom-pin-dialog/custom-pin-dialog.module';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  ResetPasswordComponentService,
  UpdateProfileComponentService,
} from '@spartacus/user/profile/components';

@NgModule({
  declarations: [
    CustomRegisterCustomerComponent,
    CustomRegisterCustomerValidationComponent,
    CustomRegisterCustomerAccountComponent,
    CustomRegisterCustomerPasswordComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    IconModule,
    CustomRegisterSharedModule,
    CustomPinModule,
    NgSelectModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromesaRegisterCustomerComponent: {
          component: CustomRegisterCustomerComponent,
          providers: [
            {
              provide: ResetPasswordComponentService,
            },
            {
              provide: UpdateProfileComponentService,
            },
          ],
        },
      },
    }),
  ],
  exports: [
    CustomRegisterCustomerComponent,
    CustomRegisterCustomerValidationComponent,
    CustomRegisterCustomerAccountComponent,
    CustomRegisterCustomerPasswordComponent,
  ],
})
export class CustomRegisterCustomerModule {}
