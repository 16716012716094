import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule
} from '@spartacus/core';

import { RouterModule } from '@angular/router';
import {
  CartNotEmptyGuard,
  CheckoutAuthGuard,
  CheckoutStepsSetGuard
} from '@spartacus/checkout/components';
import { CustomPromeclubGuard } from 'src/app/guards/custom-promeclub.guard';
import { CustomCheckoutProgressComponent } from './custom-checkout-progress.component';
import { CustomMultilineTitlePipe } from './custom-multiline-title.pipe';

@NgModule({
  declarations: [CustomCheckoutProgressComponent, CustomMultilineTitlePipe],
  exports: [CustomCheckoutProgressComponent],
  imports: [
    CommonModule,
    UrlModule,
    I18nModule,
    RouterModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutProgress: {
          component: CustomCheckoutProgressComponent,
          guards: [CustomPromeclubGuard,CheckoutAuthGuard, CartNotEmptyGuard, CheckoutStepsSetGuard],
        },
      },
    }),
  ],
})
export class CustomCheckoutProgressModule {}
