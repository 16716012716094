import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  Order,
  OrderHistoryList,
  PaginationModel,
  RoutingService,
  TranslationService,
} from '@spartacus/core';
import { OrderHistoryComponent } from '@spartacus/order/components';
import { OrderFacade, ReplenishmentOrderFacade } from '@spartacus/order/root';
import { ICON_TYPE } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomFilterComponent } from 'src/app/cms-components/custom/components/custom-filter/custom-filter.component';
import { CustomBaseSiteCheckerService } from 'src/app/services/custom-base-site-checker.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomOrderHistoryService } from '../../services/custom-order-history.service';
import { ORDER_STATE } from './constansts/order-constansts';

@Component({
  selector: 'app-custom-order-history',
  templateUrl: './custom-order-history.component.html',
  styleUrls: ['./custom-order-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomOrderHistoryComponent
  extends OrderHistoryComponent
  implements OnInit
{
  @ViewChild('customFilterComponent')
  customFilterComponent: CustomFilterComponent;
  iconTypes = ICON_TYPE;
  selectedPage: number = 1;
  years: number[] = [];
  quantities: any[] = [
    { id: 25, name: 25, disabled: true },
    { id: 50, name: 50, disabled: true },
    { id: 100, name: 100, disabled: true },
    { id: 200, name: 200, disabled: true },
    { id: 10000, name: 'Todos', disabled: true },
  ];
  yearSelected: number | null;
  pageSize: number = 10000;
  customCurrentPage: number;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  constructor(
    routing: RoutingService,
    userOrderService: OrderFacade,
    translation: TranslationService,
    userReplenishmentOrderService: ReplenishmentOrderFacade,
    private customOrderHistoryService: CustomOrderHistoryService,
    private customBreakpointService: CustomBreakpointService,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService
  ) {
    super(
      routing,
      userOrderService,
      translation,
      userReplenishmentOrderService
    );
  }

  orders$: Observable<OrderHistoryList | undefined> = this.userOrderService
    .getOrderHistoryList(this.pageSize)
    .pipe(
      tap((orders: OrderHistoryList | undefined) => {
        if (orders?.pagination?.sort) {
          this.sortType = orders.pagination.sort;
        }
      })
    );
  ngOnInit(): void {
    this.loadYears();
  }

  loadYears() {
    const max = new Date().getFullYear();
    const min = 2022;

    for (let i = max; i >= min; i--) {
      this.years.push(i);
    }
  }

  customPageChangeSelected(page: number, pagination?: PaginationModel): void {
    this.selectedPage = page;
    this.pageChange(page);
    this.orders$ = this.customOrderHistoryService.getOrderFilterYear(
      this.pageSize,
      page
    );
  }

  filterYearSelected(filter: any) {
    this.yearSelected = filter;
    this.orders$ = this.customOrderHistoryService.getOrderFilterYear(
      this.pageSize,
      0,
      filter
    );
  }

  goToOrderDetail(order: Order): void {
    if (order.status !== ORDER_STATE.CANCELLED) {
      this.routing.go({
        cxRoute: 'orderDetails',
        params: order,
      });
    } else {
      this.routing.go({
        cxRoute: 'orderCancelConfirmation',
        params: order,
      });
    }
  }

  clearFilter() {
    this.customFilterComponent.resetForm();
    this.yearSelected = null;
    this.orders$ = this.customOrderHistoryService.getOrderFilterYear(
      this.pageSize,
      0
    );
  }

  filterQuantitySelected(filter: any) {
    this.pageSize = filter;
    this.selectedPage = 0;
    this.orders$ = this.customOrderHistoryService.getOrderFilterYear(filter, 0);
  }
}
