import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { CustomTalleresService } from 'src/app/services/custom-talleres.service';

@Component({
  selector: 'app-custom-talleres-finder',
  templateUrl: './custom-talleres-finder.component.html',
  styleUrls: ['./custom-talleres-finder.component.scss'],
})
export class CustomTalleresFinderComponent implements OnInit {
  brands$: Observable<any> = this.customTalleresService?.getBrands();
  talleres$: Observable<any>;
  open: boolean = false;
  brandsSelected: string[] = [];
  findTalleresForm: FormGroup = this.fb.group(
    {
      value: [''],
      brands: [null],
    },
    { updateOn: 'change' }
  );

  @Output() workshops: EventEmitter<Observable<any>> = new EventEmitter<
    Observable<any>
  >();
  @Output() selectedWorkshop: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected customTalleresService: CustomTalleresService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {}

  onChange(): void {
    let value = this.findTalleresForm.get('value')!.value;
    let brands = this.brandsSelected;
    this.talleres$ = this.customTalleresService
      ?.findTalleres(value, brands)
      .pipe(tap((talleres) => this.workshops.emit(talleres)));
  }

  close(event: any) {
    this.open = false;
    this.findTalleresForm.controls['value'].setValue('');

    this.customTalleresService
      ?.findTalleres(
        this.findTalleresForm.controls['value'].value,
        this.brandsSelected
      )
      .pipe(
        take(1),
        tap((talleres) => this.workshops.emit(talleres))
      )
      .subscribe();
  }

  enableOption(event: any, brandSel: any) {
    let value = this.findTalleresForm.get('value')!.value;
    let brands = this.brandsSelected;

    if (event == true) {
      this.brandsSelected.push(brandSel.code);

      this.customTalleresService
        .findTalleres(value, brands)
        .pipe(
          take(1),
          tap((talleres) => this.workshops.emit(talleres))
        )
        .subscribe();
    } else {
      this.brandsSelected = this.brandsSelected.filter((brand: any) => {
        return brand !== brandSel.name;
      });

      this.customTalleresService
        .findTalleres(value, this.brandsSelected)
        .pipe(
          take(1),
          tap((talleres) => this.workshops.emit(talleres))
        )
        .subscribe();
    }
  }

  onSelectTaller(taller: any) {
    this.selectedWorkshop.emit(taller);
    this.findTalleresForm.controls['value'].setValue(taller?.name);
    this.open = false;
  }
}
