import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { OrderEntry } from '@spartacus/core';
import { ModalService } from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CustomGtmEcommerceDatalayerService } from 'src/app/common/services/custom-gtm-ecommerce-datalayer.service';
import { CustomBreakpointService } from '../../../services/custom-breakpoint.service';
import { CustomWishlistService } from '../../custom-wishlist/services/custom-wishlist.service';
import { CustomLoadingSpinnerService } from '../../custom/services/custom-loading-spinner.service';
import { CustomNavigationService } from '../../navigation/services/custom-navigation.service';

@Component({
  selector: 'app-custom-remove-from-cart-dialog',
  templateUrl: './custom-remove-from-cart-dialog.component.html',
  styleUrls: ['./custom-remove-from-cart-dialog.component.scss'],
})
export class CustomRemoveFromCartDialogComponent implements OnInit, OnDestroy {

  cartEntry: OrderEntry;
  quantity: number;
  modalIsOpen = false;
  quantityControl: FormControl;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  @ViewChild('dialog', { read: ElementRef })
  dialog: ElementRef;
  ngbModalElement: HTMLElement;
  isInPromeclub = this.service.isInPromeClub();
  fromWish: boolean = false;
  unsubscribe$: Subject<void> = new Subject();
  constructor(
    protected modalService: ModalService,
    protected customBreakpointService: CustomBreakpointService,
    private service: CustomNavigationService,
    private gtmService: CustomGtmEcommerceDatalayerService,
    private customWishlistService: CustomWishlistService,
    @Inject(DOCUMENT) private document: Document,
    private spinnerService: CustomLoadingSpinnerService
  ) { }

  ngOnInit(): void {
    this.ngbModalElement = <HTMLElement>(
      this.document.getElementsByClassName('modal-content')[0]
    );
    this.setModalContentStyle();
  }

  dismissModal(reason?: any): void {
    this.modalService.dismissActiveModal(reason);
  }

  customRemoveItemFromModal() {
    this.spinnerService.showSpinner('');
    if (this.fromWish) {
      this.customWishlistService
        .removeItem(this.cartEntry.product?.code!)
        .pipe(takeUntil(this.unsubscribe$), finalize(() => {
          this.dismissModal();
          this.customWishlistService.updated$.next(({value: true, loading: false}));
          this.spinnerService.hideSpinner();
        }))
        .subscribe();
      return;
    } else {
      this.dismissModal();
      this.spinnerService.hideSpinner();
    }
    this.quantityControl.setValue(0);
    this.quantityControl.markAsDirty();
    this.gtmService.removeFromCartEvent(this.cartEntry);
  }

  setModalContentStyle() {
    this.ngbModalElement.style.height = 'fit-content';
    this.ngbModalElement.style.margin = 'auto .5rem';
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
