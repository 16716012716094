import { Component, ViewEncapsulation } from '@angular/core';
import {
  AuthService,
  CmsService,
  Product,
  ProductService,
} from '@spartacus/core';
import { CmsComponentData, CurrentProductService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { CustomProductCarouselComponent } from 'src/app/cms-components/product/components/custom-product-carousel/custom-product-carousel.component';
import { CustomCmxProductCarousel } from 'src/app/cms-components/product/model/custom-product-carousel.interface';
import { CustomGtmEcommerceDatalayerService } from 'src/app/common/services/custom-gtm-ecommerce-datalayer.service';
import { CustomZagitaProductService } from 'src/app/services/custom-zagita-product-.service';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';
import { CustomProductScope } from 'src/app/cms-components/product/model/custom-product-scope';

@Component({
  selector: 'app-custom-zagita-product-carousel',
  templateUrl: './custom-zagita-product-carousel.component.html',
  styleUrls: ['./custom-zagita-product-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomZagitaProductCarouselComponent extends CustomProductCarouselComponent {
  readonly RESET_SCROLL: boolean = true;
  constructor(
    protected componentData: CmsComponentData<CustomCmxProductCarousel>,
    protected productService: ProductService,
    protected customBreakpointService: CustomBreakpointService,
    protected cms: CmsService,
    protected customZagitaProductService: CustomZagitaProductService,
    protected currentProductService: CurrentProductService,
    protected customGtmDatalayerService: CustomGtmEcommerceDatalayerService,
    protected auth: AuthService
  ) {
    super(
      componentData,
      productService,
      customBreakpointService,
      cms,
      customGtmDatalayerService
    );
  }

  items$: Observable<Observable<Product>[]> = this.auth.isUserLoggedIn().pipe(
    distinctUntilChanged(),
    switchMap((isLoggedIn) => this.getItems$(isLoggedIn))
  );

  getItems$(isLoggedIn: boolean = false) {
    const SCOPE = isLoggedIn ? [CustomProductScope.URL] : this.PRODUCT_SCOPE;
    return this.currentProductService.getProduct().pipe(
      map((product: any) =>
        this.customZagitaProductService.getZagitaProductsByProduct(
          product.code!
        )
      ),
      switchMap((products: any) => {
        return products.pipe(map((p: any) => p.map((pp: any) => pp)));
      }),
      map((products: any) => {
        return products.map((p: any) => this.productService.get(p, SCOPE));
      })
    );
  }
}
