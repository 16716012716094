import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  ActiveCartService,
  CartVoucherService,
  CmsService,
  CustomerCouponService,
  GlobalMessageService,
  GlobalMessageType,
  Translatable,
} from '@spartacus/core';
import { CartCouponComponent } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { pluck, take } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
@Component({
  selector: 'app-custom-cart-coupon',
  templateUrl: './custom-cart-coupon.component.html',
  styleUrls: ['./custom-cart-coupon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomCartCouponComponent
  extends CartCouponComponent
  implements OnInit
{
  @Input() customCartVouchers: any[] = [];
  @Input() isReadOnlyApplied: boolean = false;
  error$: Observable<Translatable[]>;
  messageType: typeof GlobalMessageType = GlobalMessageType;
  submitCoupon: boolean = false;
  pageCurrent$: Observable<string | undefined> = this.cmsService
    .getCurrentPage()
    .pipe(pluck('template'));
  showCouponInput: boolean = false;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    cartVoucherService: CartVoucherService,
    formBuilder: FormBuilder,
    customerCouponService: CustomerCouponService,
    activeCartService: ActiveCartService,
    protected globalMessageService: GlobalMessageService,
    private cmsService: CmsService,
    private customBreakpointService: CustomBreakpointService
  ) {
    super(
      cartVoucherService,
      formBuilder,
      customerCouponService,
      activeCartService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.cartVoucherService
      .getAddVoucherResultError()
      .pipe(take(1))
      .subscribe((err) => {
        this.error$ = this.globalMessageService
          .get()
          .pipe(pluck(this.messageType.MSG_TYPE_ERROR));
      });
  }

  customApplyvouchers() {
    this.submitCoupon = true;
    this.applyVoucher();
  }

  changeText(event: any) {
    if (this.submitCoupon) {
      this.submitCoupon = false;
      this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
    }
  }

  toggleInputVisibility(): void {
    this.showCouponInput = !this.showCouponInput;
  }
}
