import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ViewEncapsulation,
} from '@angular/core';
import {
  SavedCartDetailsItemsComponent,
  SavedCartDetailsService,
} from '@spartacus/cart/saved-cart/components';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import {
  Cart,
  EventService,
  GlobalMessageService,
  PaginationModel,
  RoutingService,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomOrderPaginationService } from '../../../services/custom-order-pagination.service';

@Component({
  selector: 'app-custom-saved-cart-details-items',
  templateUrl: './custom-saved-cart-details-items.component.html',
  styleUrls: ['./custom-saved-cart-details-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CustomSavedCartDetailsItemsComponent
  extends SavedCartDetailsItemsComponent
  implements OnInit, OnDestroy
{
  selectedPage: number = 1;
  pageSize: number = 25;
  cartsSaved: any[] = [];
  cartsSavedShow: any[];

  pagination: PaginationModel = {
    currentPage: 0,
    pageSize: this.pageSize,
    sort: '',
    totalPages: 1,
    totalResults: 0,
  };

  savedCart$: Observable<Cart | undefined> = this.savedCartDetailsService
    .getCartDetails()
    .pipe(
      tap((carts) => {
        this.carts(carts?.entries);
      }),
      tap((carts) => {
        this.selectedPage = this.pagination.currentPage!;
        this.pagination.totalResults = carts?.entries?.length;
        this.pagination.totalPages =
          Math.round(
            carts?.entries?.length! / this.pagination.pageSize! + 0.4
          ) > 1
            ? Math.round(
                carts?.entries?.length! / this.pagination.pageSize! + 0.4
              )
            : 1;
      }),
      tap((cart) => {
        if ((cart?.entries ?? []).length <= 0 && !!cart?.code) {
          this.savedCartService.deleteSavedCart(cart.code);
        }
      })
    );

  constructor(
    protected savedCartDetailsService: SavedCartDetailsService,
    protected savedCartService: SavedCartFacade,
    protected eventSercvice: EventService,
    protected globalMessageService: GlobalMessageService,
    protected routingService: RoutingService,
    private customOrderPaginationService: CustomOrderPaginationService
  ) {
    super(
      savedCartDetailsService,
      savedCartService,
      eventSercvice,
      globalMessageService,
      routingService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  carts(carts?: any[], value?: number) {
    this.selectedPage = this.pagination.currentPage!;
    carts ? (this.cartsSaved = carts) : this.cartsSaved;
    if (this.pagination.currentPage == 0) {
      this.cartsSavedShow = this.cartsSaved.slice(0, this.pageSize);
    } else {
      this.cartsSavedShow = this.cartsSaved.slice(
        value,
        value! + this.pageSize
      );
    }
    this.pagination.totalResults = carts?.length;
    this.pagination.pageSize = this.pageSize;
    this.pagination.totalPages =
      Math.round(carts?.length! / this.pagination.pageSize! + 0.4) > 1
        ? Math.round(carts?.length! / this.pagination.pageSize! + 0.4)
        : 1;
    this.customOrderPaginationService.onPageChange(this.pagination);
  }

  customPageChangeSelected(event: any): void {
    this.pageChange(event);
    this.selectedPage < this.pagination.pageSize!
      ? (this.selectedPage = this.pagination.currentPage!)
      : (this.selectedPage = this.pagination.currentPage!);
    this.carts(this.cartsSaved, this.pageSize * event);
  }

  pageChange(page: number) {
    this.pagination.currentPage = page;
    this.customOrderPaginationService.onPageChange(this.pagination);
  }

  onDeleteComplete(success: boolean): void {
    if (success) {
      this.routingService.go({ cxRoute: 'savedCarts' });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
