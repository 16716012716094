import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSavedCartDetailsActionComponent } from './custom-saved-cart-details-action.component';
import { IconModule } from '@spartacus/storefront';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  I18nModule,
} from '@spartacus/core';
import { CustomSavedCartModalModule } from '../custom-saved-cart-modal/custom-saved-cart-modal.module';

@NgModule({
  declarations: [CustomSavedCartDetailsActionComponent],
  exports: [CustomSavedCartDetailsActionComponent],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    CustomSavedCartModalModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        SavedCartDetailsActionComponent: {
          component: CustomSavedCartDetailsActionComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ],
})
export class CustomSavedCartDetailsActionModule {}
