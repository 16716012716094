import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  CmsConfig,
  ConfigModule,
  I18nModule
} from '@spartacus/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IconModule } from '@spartacus/storefront';
import { CustomFilterModule } from 'src/app/cms-components/custom/components/custom-filter/custom-filter.module';
import { CustomLoadingSpinnerModule } from 'src/app/cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomPaginationModule } from 'src/app/cms-components/custom/components/custom-pagination/custom-pagination.module';
import { CustomProductCardModule } from 'src/app/cms-components/custom/components/custom-product-card/custom-product-card.module';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomRebateDetailComponent } from './custom-rebate-detail/custom-rebate-detail.component';
import { CustomRebateListComponent } from './custom-rebate-list.component';
import { CustomTitleMyAccountModule } from 'src/app/cms-components/custom/components/custom-title-my-account/custom-title-my-account.module';

@NgModule({
  declarations: [CustomRebateListComponent, CustomRebateDetailComponent],
  imports: [
    CommonModule,
    CustomFilterModule,
    CustomPaginationModule,
    CustomProductCardModule,
    CustomLoadingSpinnerModule,
    CustomTitleMyAccountModule,
    I18nModule,
    NgbModule,
    IconModule,
    CustomCurrencyModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        RebateComponent: {
          component: CustomRebateListComponent,
        },
      },
    }),
  ],
})
export class CustomRebateListModule {}
