<ng-container #confirmatedReview>
  <div class="confirmated-review_content">
    <ng-container *ngIf="isMobile$ | async; else desktop">
      <app-custom-modal-generic [iconHeaderClass]="'fas fa-check'" [isPopUpV2]="true" [isClose]="false" [titlePopUp]="'productReview.reviewSent' | cxTranslate"></app-custom-modal-generic>
      <div class="confirmated-review_body">
        <div class="confirmated-review_body__header">
          <span class="confirmated-review_body__header-title">{{
            "productReview.thankYouForReview" | cxTranslate
          }}</span>
        </div>
        <div class="confirmated-review_body__footer">
          <button class="btn btn-primary btn-block" type="submit" (click)="dismissModal($event)">
            <div>
              {{ "common.close" | cxTranslate }}
            </div>
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #desktop>
      <app-custom-modal-generic></app-custom-modal-generic>
      <div class="confirmated-review_body">
        <div class="confirmated-review_body__header">
          <cx-icon class="fas fa-check-circle"></cx-icon>
          <span class="confirmated-review_body__header-title">{{
            "productReview.thankYouForReview" | cxTranslate
          }}</span>
        </div>
        <div class="confirmated-review_body__footer">
          <button class="btn btn-primary btn-block" type="submit" (click)="dismissModal($event)">
            <div>
              {{ "common.continue" | cxTranslate }}
            </div>
          </button>
        </div>
      </div>
    </ng-template>


  </div>
</ng-container>
