import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  CmsSearchBoxComponent,
  Product,
  RoutingService,
  SearchboxService,
  WindowRef,
} from '@spartacus/core';
import {
  CmsComponentData,
  PageLayoutService,
  SearchBoxComponent,
} from '@spartacus/storefront';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import {
  distinct,
  filter,
  map,
  pluck,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { CustomCategoryData } from 'src/app/cms-components/custom/components/custom-category-list/model/custom-category-list.interface';
import { CustomQueryFilter } from 'src/app/cms-components/custom/components/custom-search-refiner/model/custom-search-refiner.interface';
import { CustomFilterAutomotrizService } from 'src/app/cms-components/custom/services/custom-filter-automotriz.service';
import { CustomGtmEcommerceDatalayerService } from 'src/app/common/services/custom-gtm-ecommerce-datalayer.service';
import { CustomBrandsAndCategoriesService } from 'src/app/services/custom-brands-and-categories.service';
import { CustomGetImageUrlService } from 'src/app/services/custom-get-image-url.service';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';
import { CustomSearchResults } from '../../models/custom-product-search-results.model';
import {
  CustomProductSearchFacets,
  FacetValue,
} from '../../models/custom-search-facets.model';
import { CustomDialogCartService } from '../../services/custom-dialog-cart.service';
import { CustomNavigationService } from '../../services/custom-navigation.service';
import { CustomSearchboxService } from './services/custom-searchbox.service';
import { AutomotiveFilterService } from 'src/app/services/automotive-filter.service';

@Component({
  selector: 'cx-searchbox',
  templateUrl: './custom-searchbox.component.html',
  styleUrls: ['./custom-searchbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomSearchboxComponent
  extends SearchBoxComponent
  implements OnInit, OnDestroy
{
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  isAutomotriz: string;
  isInPromeclub: boolean = this.service.isInPromeClub();
  openResultsMobile: boolean = false;
  customResults$: Observable<CustomSearchResults> = this.config$.pipe(
    switchMap((config) =>
      this.searchBoxComponentService.customGetResults(config)
    )
  );
  categories$: Observable<FacetValue[] | null> = this.customResults$.pipe(
    map((results: CustomSearchResults) => {
      return results?.facets?.length
        ? this.getValuesFromFacet(results.facets, 'Categorías')
        : null;
    })
  );
  brands$: Observable<FacetValue[] | null> = this.customResults$.pipe(
    map((results: CustomSearchResults) => {
      return results?.facets?.length
        ? this.getValuesFromFacet(results.facets, 'Marcas')
        : null;
    })
  );
  products$: Observable<Product[] | null> = this.customResults$.pipe(
    map((results: CustomSearchResults) => {
      return results?.products?.length ? results.products.slice(0, 3) : null;
    })
  );
  customSubscription: Subscription = new Subscription();
  @ViewChild('searchInput') searchInput: ElementRef;
  categoriesSubscription: Subscription;
  categoriesToShow: FacetValue[] | null;
  brandsSubscription: Subscription;
  productsSubscription: Subscription;
  brandsToShow: FacetValue[] | null;
  suggestionSubscription: Subscription;
  suggestionToShow: string[] | null;
  brandSelected: FacetValue | null;
  categoryFacetSelected: FacetValue | null;
  iconFilter: boolean = true;
  changeClassFilter: boolean = true;
  filterAutomotriz: CustomQueryFilter[] = [];
  filteredSelected: string | null;
  unsubscribe$: Subject<any> = new Subject();
  selectedCategory$: Observable<CustomCategoryData> | null;
  typeOverFacet$: Observable<string>;
  queryToSaved: string;
  changed: boolean = false;
  changedSelected: string = '';
  clickedClearSelected: boolean = false;
  constructor(
    protected searchBoxComponentService: CustomSearchboxService,
    @Optional()
    protected componentData: CmsComponentData<CmsSearchBoxComponent>,
    protected winRef: WindowRef,
    protected routingService: RoutingService,
    protected customBreakpointService: CustomBreakpointService,
    protected router: Router,
    private customFilterAutomotrizService: CustomFilterAutomotrizService,
    @Inject(DOCUMENT) private document: Document,
    private customDialogCartService: CustomDialogCartService,
    protected pageLayoutService: PageLayoutService,
    private service: CustomNavigationService,
    protected customBrandsAndCategoriesService: CustomBrandsAndCategoriesService,
    protected customGetImageUrl: CustomGetImageUrlService,
    private customGtmDatalayerService: CustomGtmEcommerceDatalayerService,
    protected searchboxService: SearchboxService,
    protected cd: ChangeDetectorRef,
    protected automotiveFilterService: AutomotiveFilterService
  ) {
    super(searchBoxComponentService, componentData, winRef, routingService);
    this.isAutomotriz = localStorage.getItem('currentSection')!;
  }

  ngOnInit(): void {
    this.getFilterAutomotriz();
    this.customSubscription.add(
      combineLatest([
        this.router.events.pipe(
          filter((event: any) => event instanceof NavigationEnd)
        ),
        this.isMobile$,
      ])
        .pipe(
          tap(([event, isMobile]) => {
            this.searchBoxComponentService.toggleBodyClass(
              'searchbox-is-active',
              false
            );
            this.customClose(event, isMobile);
            this.searchBoxComponentService.clearResults();
            this.searchInput.nativeElement.value = '';
          })
        )
        .subscribe()
    );

    this.customFilterAutomotrizService.appliedFilter$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => this.getFilterAutomotriz())
      )
      .subscribe();

    this.customFilterAutomotrizService.changeSectionLocalStorage$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => (this.isAutomotriz = localStorage.getItem('currentSection')!))
      )
      .subscribe();

    combineLatest([this.isMobile$, this.customDialogCartService.closeDialog$])
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(([isMobile, event]: [boolean, UIEvent]) => {
          this.customClose(event, isMobile, false);
          this.searchInput.nativeElement.value = '';
          this.searchBoxComponentService.clearResults();
        })
      )
      .subscribe();

    this.customResults$
      .pipe(
        pluck('products'),
        distinct(),
        tap((products) => {
          this.customGtmDatalayerService.viewProductEvent(
            products?.slice(0, 3),
            'Buscador'
          );
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getFilterAutomotriz() {
    const filterAutomotriz = localStorage.getItem('queryFilterAutomotriz');
    if (
      this.isAutomotriz == 'automotriz' &&
      filterAutomotriz &&
      JSON.parse(filterAutomotriz)?.length > 0
    ) {
      this.filterAutomotriz = JSON.parse(filterAutomotriz);
      this.filteredSelected = this.filterAutomotriz?.[0]?.name;
    }
  }

  clearFilter() {
    if (this.clickedClearSelected) {
      this.clickedClearSelected = false;
      return;
    }
    this.automotiveFilterService.setAutomotiveFilterPdp(false);
    this.filterAutomotriz = [];
    this.filteredSelected = null;
    this.changed = false;
    this.changedSelected = '';
    localStorage.setItem(
      'queryFilterAutomotriz',
      JSON.stringify(this.filterAutomotriz)
    );
    this.cd.detectChanges();
  }

  getValuesFromFacet(
    facets: CustomProductSearchFacets[] = [],
    filterString: string
  ): FacetValue[] | null {
    const facetValues: CustomProductSearchFacets[] | null = facets?.filter(
      (facet: CustomProductSearchFacets) => facet?.name === filterString
    );

    return !!facetValues.length && !!facetValues?.[0].values?.length
      ? facetValues?.[0].values
      : null;
  }

  /**
   * Closes the searchBox and opens the search result page.
   */
  customSearch(query: string, isMobile: boolean): void {
    if (isMobile)
      this.document.getElementById('results')!.style.display = 'block';
    this.reSubscribeSearch();
    this.search(query);
  }

  lookCategoryProducts(value: FacetValue, type: string) {
    this.typeOverFacet$ = of(type);
    this.selectedCategory$ = this.customBrandsAndCategoriesService
      .getCategoryDataFromApi(value?.code)
      .pipe(
        tap((cateogory) =>
          cateogory?.superCategories?.sort((a, b) =>
            a.code != undefined && b.code != undefined
              ? Number(a.code) - Number(b.code)
              : 1
          )
        )
      );
  }

  openSearchbox(isMobile: boolean) {
    if (isMobile) {
      this.document.getElementById('results')!.style.display = 'block';
      this.searchBoxComponentService.customOpenSearchbox();
      this.openResultsMobile = true;
    }
    this.open();
  }

  customClose(event: UIEvent, isMobile?: boolean, force?: boolean) {
    if (isMobile && this.document.getElementById('results')) {
      this.document.getElementById('results')!.style.display = 'none';
      this.openResultsMobile = false;
    }
    this.close(event, force);
  }

  resetTextSearch() {
    this.selectedCategory$ = null;
  }

  reSubscribeSearch() {
    this.brandSelected = null;
    this.categoryFacetSelected = null;
    if (!this.categoriesSubscription || this.categoriesSubscription.closed) {
      this.categoriesSubscription = this.categories$.subscribe((facet) => {
        this.categoriesToShow = facet;
      });
    }
    if (!this.brandsSubscription || this.brandsSubscription.closed) {
      this.brandsSubscription = this.brands$.subscribe((facet) => {
        this.brandsToShow = facet;
      });
    }

    if (!this.suggestionSubscription || this.suggestionSubscription.closed) {
      this.suggestionSubscription = this.customResults$.subscribe((res) => {
        this.suggestionToShow = res?.suggestions!;
      });
    }
  }

  getImageUrl(url: string): string {
    return this.customGetImageUrl.getImageUrl(url);
  }

  clearFromSelected() {
    const automotiveFilterQuery = JSON.parse(
      localStorage.getItem('queryFilterAutomotriz')!
    )?.[0]?.query;

    this.clickedClearSelected = true;
    this.filterAutomotriz = [
      ...this.filterAutomotriz.filter((filter) => {
        if (filter.query == automotiveFilterQuery) {
          this.automotiveFilterService.setAutomotiveFilterPdp(false);
        }
        return filter.name != this.filteredSelected;
      }),
    ];
    this.filteredSelected = this.filterAutomotriz?.[0]?.name;
    this.changed = this.filterAutomotriz.length == 1;
    this.iconFilter = true;
    localStorage.setItem(
      'queryFilterAutomotriz',
      JSON.stringify(this.filterAutomotriz)
    );
    this.changedSelected = this.filteredSelected;
    this.cd.detectChanges();
  }

  onChangeFiltered(event: any) {
    let queryWithFilters: string;
    this.document.getElementById('filteredSelected')!.classList.add('filtered');
    this.iconFilter = true;
    this.filteredSelected = event;
    const filteredQuery = this.filterAutomotriz.find(
      (filter) => filter.name == this.filteredSelected
    );
    this.filterAutomotriz = [
      filteredQuery!,
      ...this.filterAutomotriz.filter(
        (filter) => filter.name != filteredQuery?.name
      ),
    ];
    localStorage.setItem(
      'queryFilterAutomotriz',
      JSON.stringify(this.filterAutomotriz)
    );
    queryWithFilters = `?query=${filteredQuery?.query}`;
    this.router.navigateByUrl(`/search/${queryWithFilters}`);
  }

  customLaunchSearchResult(event: UIEvent, query: string): void {
    this.queryToSaved = query;
    let queryWithFilters: string;
    let filter: CustomQueryFilter | undefined;
    if (this.isAutomotriz == 'automotriz') {
      this.filteredSelected
        ? (filter = this.filterAutomotriz.find(
            (filter) => filter.name == this.filteredSelected
          ))
        : false;
      if ((!query || query.trim().length === 0) && !this.filteredSelected) {
        return;
      }
      this.customClose(event, false);
      if (query && filter) {
        queryWithFilters = `${query}?query=${query}${filter?.query}`;
      } else if (!query && filter) {
        queryWithFilters = `?query=${filter?.query}`;
      } else {
        queryWithFilters = `${query}?query=${query}`;
      }
      this.router.navigateByUrl(`/search/${queryWithFilters}`);
    } else {
      if (!query) {
        return;
      }
      this.router.navigateByUrl(`/search/${query}?query=${query}`);
    }
  }
}
