<ng-container *ngIf="savedCart$ | async as cart">
  <div class="custom-cart-details-header">
    <app-custom-title-my-account
        [title]="'shoppingList.detailTitle' | cxTranslate"
        [routerlink]="{cxRoute: 'savedCarts'}"
        [lessMargin]="true"
      ></app-custom-title-my-account>
  </div>
  <div class="custom-cart-details-overview">
    <div class="custom-cart-details-overview__data">
      <div class="custom-cart-details-overview-name">
        <p>{{ "savedCartList.cartName" | cxTranslate }}</p>
        <span>{{ cart?.name }}</span>
      </div>
      <div class="custom-cart-details-overview-dateSaved">
        <p>{{ "shoppingList.dateSaved" | cxTranslate }}</p>
        <span>{{ cart?.saveTime | date : "dd/MM/yyyy" }}</span>
      </div>
    </div>
    <div class="custom-cart-details-overview__actions">
      <app-custom-saved-cart-details-action
        [cartSavedName]="cart?.name"
        [cartSaved]="cart"
      ></app-custom-saved-cart-details-action>
    </div>
  </div>
</ng-container>
