import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CartVoucherService, CmsService, Page } from '@spartacus/core';
import { AppliedCouponsComponent } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-applied-coupons',
  templateUrl: './custom-applied-coupons.component.html',
  styleUrls: ['./custom-applied-coupons.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomAppliedCouponsComponent extends AppliedCouponsComponent implements OnInit {

  pageTemplate$: Observable<Page> = this.cmsService.getCurrentPage();
  constructor(cartVoucherService: CartVoucherService,
    private cmsService: CmsService) {
    super(cartVoucherService);
  }

  ngOnInit(): void {
  }

}
