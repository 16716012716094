import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, ModalModule } from '@spartacus/storefront';
import { CustomEmptyCartModule } from 'src/app/cms-components/custom/components/custom-empty-cart/custom-empty-cart.module';
import { CustomLoadingSpinnerModule } from 'src/app/cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomModalGenericModule } from 'src/app/cms-components/custom/components/custom-modal/custom-modal-generic.module';
import { CustomTitleMyAccountModule } from 'src/app/cms-components/custom/components/custom-title-my-account/custom-title-my-account.module';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomCreditLineDialogComponent } from './custom-credit-line-dialog/custom-credit-line-dialog.component';
import { CustomCreditLineComponent } from './custom-credit-line.component';



@NgModule({
  declarations: [
    CustomCreditLineComponent,
    CustomCreditLineDialogComponent
  ],
  imports: [
    CommonModule,
    CustomTitleMyAccountModule,
    I18nModule,
    CustomCurrencyModule,
    RouterModule,
    UrlModule,
    IconModule,
    ModalModule,
    CustomLoadingSpinnerModule,
    CustomEmptyCartModule,
    CustomModalGenericModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CreditLineComponent: {
          component: CustomCreditLineComponent
        },
      },
    }),
  ],
  exports: [
    CustomCreditLineComponent,
    CustomCreditLineDialogComponent
  ]
})
export class CustomCreditLineModule { }
