import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-custom-product-attributes-accordion',
  templateUrl: './custom-product-attributes-accordion.component.html',
  styleUrls: ['./custom-product-attributes-accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomProductAttributesAccordionComponent {
  @Input() titleAttribute: string;
  @Input() collapseFromIcon: boolean = false;
  showColapse: boolean = false;

  toggleShowCollapse(event: any): void {
    if (this.collapseFromIcon) {
      event.stopPropagation();
      return;
    }
    this.showColapse = !this.showColapse;
  }
}
