<ng-container>
  <div #dialogConfCreditLine class="custom-credit-line-dialog">
    <app-custom-modal-generic></app-custom-modal-generic>
    <div class="custom-credit-line-dialog_body-footer">
      <div class="custom-credit-line-dialog_body">
        <span>{{
          "creditLine.msgReceiptRequest" | cxTranslate
        }}</span>
        <span>{{
          "creditLine.msgReceiptRequestComincation" | cxTranslate
        }}</span>
      </div>
      <div class="custom-credit-line-dialog__footer">
        <button
          type="button"
          class="close"
          cxModal="dismiss"
          cxModalReason="Click continue"
        >
          <span aria-hidden="true">
            {{
              "common.continue" | cxTranslate
            }}
          </span>
        </button>
      </div>
    </div>
  </div>
</ng-container>
