<div class="modal-container">
  <ng-container *ngIf="!isMobile">
    <ng-container *ngTemplateOutlet="closeModal"></ng-container>
  </ng-container>
  <div class="modal-container-body">
    <div [ngClass]="{ 'title-mobile-container': isMobile }">
      <h3 class="modal-container-body__title">
        <ng-container *ngIf="!isInPromeclub">{{
          "productDetails.shareProduct" | cxTranslate
        }}</ng-container>
        <ng-container *ngIf="isInPromeclub">{{
          "productDetails.shareProductPromeclub" | cxTranslate
        }}</ng-container>
      </h3>
      <ng-container *ngIf="isMobile">
        <ng-container *ngTemplateOutlet="closeModal"></ng-container>
      </ng-container>
    </div>
    <div class="modal-container-body__product">
      <app-custom-product-card-review
        [product]="product"
        [isMobile]="isMobile"
      ></app-custom-product-card-review>
    </div>
    <div class="modal-container-body__icon">
      <div
        *ngFor="let item of socialIcons"
        class="modal-container-body__icon-item"
      >
        <a
          [href]="item.url"
          class="modal-container-body__icon-item-type"
          rel="nofollow"
          target="_blank"
          (click)="onCopySocialMedia(item.type)"
        >
          <cx-icon [type]="item.type" *ngIf="item.type"></cx-icon>
          <cx-icon
            *ngIf="item.class && !item.type"
            [class]="item.class"
          ></cx-icon>
        </a>
        <a
          class="modal-container-body__icon-item-name"
          rel="nofollow"
          [href]="item.url"
          target="_blank"
          (click)="onCopySocialMedia(item.type)"
        >
          {{ item.translation | cxTranslate }}
        </a>
      </div>
    </div>
    <div class="modal-container-body__form">
      <h4 class="modal-container-body__form-text">
        {{ "productDetails.copyLink" | cxTranslate }}
      </h4>
      <div class="modal-container-body__form-link">
        <div class="modal-container-body__form-link-text">
          <span>{{ productUrl }}</span>
        </div>
        <div class="modal-container-body__form-link-button">
          <button
            class="btn btn-block btn-primary"
            (click)="copyProductUrlToClipboard()"
          >
            <cx-icon class="far fa-copy"></cx-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="divider" *ngIf="isMobile"></div>
</div>

<ng-template #closeModal>
  <app-custom-modal-generic
    (closeModal)="closePopup()"
    [isClose]="true"
  ></app-custom-modal-generic>
</ng-template>
