import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActiveCartService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { CustomProduct } from 'src/app/cms-components/product/model/custom-product.interface';
import { CustomPromeclubAddToCartService } from '../../services/custom-promeclub-add-to-cart.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { ModalService } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-promeclub-add-to-cart-form',
  templateUrl: './custom-promeclub-add-to-cart-form.component.html',
  styleUrls: ['./custom-promeclub-add-to-cart-form.component.scss'],
})
export class CustomPromeclubAddToCartFormComponent implements OnInit {
  @Input() quantity: number;
  @Input() product: CustomProduct;
  @Output() success = new EventEmitter<boolean>();
  @Output() addFail = new EventEmitter<boolean>();
  inputLabel: string;
  inputPlaceholder: string;
  errorMessage: string;
  isEmailType: boolean;
  isCellphoneType: boolean;
  isDecoderType: boolean;
  formValid: boolean = true;
  subscription: Subscription = new Subscription();
  entryForm: FormGroup = this.fb.group(
    {
      value: [''],
    },
    { updateOn: 'change' }
  );
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    private fb: FormBuilder,
    private customPromeclubAddToCartService: CustomPromeclubAddToCartService,
    private activeCartService: ActiveCartService,
    private customBreakpointService: CustomBreakpointService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.setFormTexts();
    this.subscription.add(
      this.entryForm.valueChanges.subscribe((changes) => {
        if (changes) {
          this.formValid = true;
        }
      })
    );
  }

  submitForm() {
    this.formValid = this.formValidator(this.entryForm.get('value')!.value);
    if (!this.formValid) {
      return;
    }
    this.customPromeclubAddToCartService.setLoading(true);
    this.customPromeclubAddToCartService
      .addEntry(
        this.quantity,
        this.product?.code!,
        this.entryForm.get('value')!.value
      )
      .pipe(
        take(1),
        catchError((error) => {
          this.addFail.emit(true);
          return error;
        })
      )
      .subscribe((result: any) => {
        if (result?.statusCode! === 'success') {
          this.customPromeclubAddToCartService.setProductAdded(true);
          this.activeCartService.reloadActiveCart();
          this.success.emit(true);
          this.addFail.emit(false);
        } else {
          this.addFail.emit(true);
          this.customPromeclubAddToCartService.setProductAdded(false);
        }
        this.customPromeclubAddToCartService.setLoading(false);
      });
  }

  formValidator(value: string) {
    if (this.isCellphoneType) {
      if (isNaN(Number(value))) {
        return false;
      }
    }

    if (this.isDecoderType) {
      if (isNaN(Number(value)) || value.length > 15) {
        return false;
      }
    }

    if (this.isEmailType) {
      const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!expression.test(value.toLowerCase())) {
        return false;
      }
    }
    return true;
  }

  setFormTexts() {
    switch (this.product?.tipoRecargaPromotick) {
      case 3:
        this.inputLabel = 'entryPromeclub.cellphone';
        this.inputPlaceholder = 'entryPromeclub.cellphonePlaceholder';
        this.errorMessage = 'entryPromeclub.invalidCellphone';
        this.isCellphoneType = true;
        break;
      case 4:
        this.inputLabel = 'entryPromeclub.decoder';
        this.inputPlaceholder = 'entryPromeclub.decoderPlaceholder';
        this.errorMessage = 'entryPromeclub.invalidDecoder';
        this.isDecoderType = true;
        break;
      case 7:
        this.inputLabel = 'entryPromeclub.email';
        this.inputPlaceholder = 'entryPromeclub.emailPlaceholder';
        this.errorMessage = 'entryPromeclub.invalidEmail';
        this.isEmailType = true;
        break;
      default:
        break;
    }
  }

  closeModal(): void {
    this.modalService.closeActiveModal();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
