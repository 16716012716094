import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FeaturesConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import {
  CartCouponModule, GenericLinkModule, IconModule, ItemCounterModule, MediaModule, ModalModule,
  OutletModule, PromotionsModule
} from '@spartacus/storefront';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomSafeHtmlModule } from 'src/app/pipes/custom-safe-html/custom-safe-html.module';
import { CustomCartListHeaderModule } from '../../custom/components/custom-cart-list-header/custom-cart-list-header.module';
import { CustomGiftProductModule } from '../../custom/components/custom-gift-product/custom-gift-product.module';
import { CustomCartCouponModule } from '../custom-cart-coupon/custom-cart-coupon.module';
import { CustomRemoveFromCartDialogModule } from '../custom-remove-from-cart-dialog/custom-remove-from-cart-dialog.module';
import { CustomCartImportantMessagesComponent } from './custom-cart-important-messages/custom-cart-important-messages.component';
import { CustomCartItemListComponent } from './custom-cart-item-list/custom-cart-item-list.component';
import { CustomCartItemValidationWarningComponent } from './custom-cart-item-validation-warning/custom-cart-item-validation-warning.component';
import { CustomCartItemComponent } from './custom-cart-item/custom-cart-item.component';
import { CustomOrderSummaryComponent } from './custom-order-summary/custom-order-summary.component';
import { CustomProgressButtonComponent } from './custom-progress-button/custom-progress-button.component';

@NgModule({
  declarations: [
    CustomCartItemComponent,
    CustomCartItemListComponent,
    CustomOrderSummaryComponent,
    CustomCartItemValidationWarningComponent,
    CustomCartImportantMessagesComponent,
    CustomProgressButtonComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CartCouponModule,
    ReactiveFormsModule,
    UrlModule,
    NgbModule,
    PromotionsModule,
    I18nModule,
    IconModule,
    MediaModule,
    GenericLinkModule,
    ItemCounterModule,
    FeaturesConfigModule,
    ModalModule,
    OutletModule,
    CustomRemoveFromCartDialogModule,
    CustomSafeHtmlModule,
    CartCouponModule,
    CustomCartCouponModule,
    CustomCartListHeaderModule,
    CustomCurrencyModule,
    CustomGiftProductModule,
  ],
  exports: [
    CustomCartItemComponent,
    CustomCartItemListComponent,
    CustomOrderSummaryComponent,
    CustomCartItemValidationWarningComponent,
    CustomCartImportantMessagesComponent,
    CustomProgressButtonComponent,
  ],
})
export class CustomCartSharedModule {}
