import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CustomSubcategory } from '../../../model/custom-subcategory.interface';

@Injectable({
  providedIn: 'root'
})
export class CustomSubcategoriesService {

  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
  ) {}

  getCategoryDataFromApi(categoryCode: string): Observable<CustomSubcategory[]>{
    const url = this.occEndpointService.buildUrl('subcategoriesData', {
      urlParams: {
        categoryCode
      }
    });
    return this.http.get<CustomSubcategory[]>(url);
  }
  getFirstLevelSubCategoryDataFromApi(categoryCode: string, categoryCode2: string | null): Observable<CustomSubcategory[]>{
    const url = this.occEndpointService.buildUrl('firstLevelSubcategoriesData', {
      urlParams: {
        categoryCode
      },
      queryParams: {
        'code2': categoryCode2
      },
    });
    return this.http.get<CustomSubcategory[]>(url);
  }
}
