import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { CmsBannerComponent, Config } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { PageTypeService } from '../../services/page-type.service';
import { tap } from 'rxjs/operators';
import { CustomHeaderService } from '../../../../services/custom-header.service';
import { CustomScrollService } from 'src/app/services/custom-scroll.service';

@Component({
  selector: 'app-custom-header-logo',
  templateUrl: './custom-header-logo.component.html',
  styleUrls: ['./custom-header-logo.component.scss'],
})
export class CustomHeaderLogoComponent implements OnInit {
  dataLogo$: Observable<CmsBannerComponent>;
  isInPromeclub: boolean;
  isHomepage$: Observable<boolean> = this.pageTypeService.isHomepage();
  @Input() fromInnerHeader: boolean = false;
  constructor(
    private componentData: CmsComponentData<CmsBannerComponent>,
    private config: Config,
    private pageTypeService: PageTypeService,
    private customHeaderService: CustomHeaderService,
    private customScrollService: CustomScrollService,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.dataLogo$ = !this.fromInnerHeader
      ? this.componentData.data$.pipe(
          tap((cmsBanner) =>
            this.customHeaderService.setHeaderLogoData(cmsBanner)
          )
        )
      : this.customHeaderService.getHeaderLogoData();
    this.componentData.uid == 'siteHeaderPromeclubLogo'
      ? (this.isInPromeclub = true)
      : (this.isInPromeclub = false);

    this.customScrollService.getScrollY().subscribe((scrollYPosition) => {
      let header = document.getElementsByClassName('header')[0] as HTMLElement;
      let body = document.getElementsByTagName('body')[0] as HTMLElement;
      if (document.getElementById('results') !== null) {
        if (scrollYPosition >= 40 && body?.offsetHeight >= 1000) {
          this.customHeaderService.fixHeaderAndHideTopBar();
          document.getElementById(
            'results'
          )!.style.top = `${header.offsetHeight}px`;
          document.getElementById(
            'results'
          )!.style.minHeight = `calc(100vh - ${header.offsetHeight}px)`;
        }
        if (scrollYPosition >= 112) {
          this.customHeaderService.hideSearchboxAndChangeHeight();
          document.getElementById('results')!.style.top = '56px';
          document.getElementById('results')!.style.minHeight =
            'calc(100vh - 56px)';
        } else if (scrollYPosition < 40) {
          this.customHeaderService.resetHeader();
          document.getElementById('results')!.style.top = '152px';
          document.getElementById('results')!.style.minHeight = `calc(100vh - ${
            152 - scrollYPosition
          }px)`;
        }
      } else {
        this.customHeaderService.resetHeader();
      }
    });
    if (this.isInPromeclub) {
      this.el.nativeElement?.parentNode?.classList?.add('promeclub');
    }
  }
}
