import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomPaymentRecordService {
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
    private routingService: RoutingService
  ) {}

  getOrderDetails(orderCode: string): Observable<any> {
    const url = this.occEndpointService.buildUrl('orderDetail', {
      urlParams: {
        userId: 'current',
        orderId: orderCode,
      },
    });
    return this.http.get(url);
  }

  getOrderDetailsByCode() {
    return this.routingService.getRouterState().pipe(
      switchMap((routingData) => {
        const url = this.occEndpointService.buildUrl('orderDetail', {
          urlParams: {
            userId: 'current',
            orderId: routingData.state.params.orderCode,
          },
        });
        return this.http.get<any>(url);
      }),
      distinctUntilChanged()
    );
  }
}
