import { Injectable } from '@angular/core';
import {
  BasePageMetaResolver,
  CategoryPageMetaResolver,
  CmsService,
  ProductSearchService,
  TranslationService,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomCategoryPageMetaResolver extends CategoryPageMetaResolver {
  constructor(
    protected productSearchService: ProductSearchService,
    protected cmsService: CmsService,
    protected translation: TranslationService,
    protected basePageMetaResolver: BasePageMetaResolver
  ) {
    super(productSearchService, cmsService, translation, basePageMetaResolver);
  }

  getSearchTerm(): Observable<string> {
    let searchTerm$ = this.productSearchService
      .getResults()
      .pipe(
        map((searchResults) =>
          searchResults.freeTextSearch ? searchResults.freeTextSearch : ''
        )
      );

    return searchTerm$;
  }
}
