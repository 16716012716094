<ng-container *ngIf="{ stepOne: stepOne$ | async } as data">
  <div [ngClass]="{ hide: !data.stepOne }" class="add-claim-container" *ngIf="order">
    <div class="d-flex align-items-center">
      <app-custom-title-my-account
        [title]="'orderDetails.claim.title' | cxTranslate"
        [routerlink]="'orderDetails'"
        [params]="order"
      ></app-custom-title-my-account>
    </div>

    <app-custom-order-overview
      [isClaim]="true"
      [order]="order"
    ></app-custom-order-overview>

    <form [formGroup]="claimForm" (ngSubmit)="submitForm()">
      <label for="motive">{{
        "orderDetails.claim.label.motive" | cxTranslate
      }}</label>
      <ng-select
        formControlName="motive"
        [clearable]="false"
        id="motive"
        class="add-claim-container__select-container select-blue-arrow"
        placeholder="{{ 'orderDetails.claim.claimSelect' | cxTranslate }}"
      >
        <ng-container *ngIf="reasons$ | async as reasons">
          <ng-option
            *ngFor="let reason of reasons | keyvalue"
            [value]="reason"
            >{{ reason.value }}</ng-option
          >
        </ng-container>
      </ng-select>
      <label class="mt-4" for="textarea">{{
        "orderDetails.claim.label.comment" | cxTranslate
      }}</label>

      <textarea
        formControlName="comments"
        placeholder="{{ 'orderDetails.claim.claimTextarea' | cxTranslate }}"
        name="textarea"
        id="textarea"
        maxlength="255"
        class="add-claim-container__textarea"
      ></textarea>
      <hr />
      <div class="add-claim-container__btns">
        <button (click)="returnPage()" class="button btn bt-secondary">
          {{ "orderDetails.cancellationAndReturn.btnReturn" | cxTranslate }}
        </button>
        <button
          type="submit"
          class="button btn btn-primary"
          [disabled]="claimForm.invalid"
        >
          {{ "orderDetails.claim.checkClaim" | cxTranslate }}
        </button>
      </div>
    </form>
  </div>
</ng-container>
