import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomConfirmationPasswordService {
  constructor(
    private occEndpointService: OccEndpointsService,
    private http: HttpClient
  ) {}

  getConfirmationToken(token: string): Observable<any> {
    const url = this.occEndpointService.buildUrl('confirmationAccount', {
      urlParams: {
        token,
      },
    });
    return this.http.get<any>(url);
  }
}
