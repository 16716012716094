import { NgModule } from '@angular/core';
import { AsmCoreModule } from '@spartacus/asm/core';
import { AsmOccModule } from '@spartacus/asm/occ';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { AsmComponentsModule } from './components/asm-components.module';
import { CustomCsAgentAuthService } from './services/custom-csagent-auth.service';

@NgModule({
  imports: [AsmComponentsModule, AsmCoreModule, AsmOccModule],
  providers: [
    {
      provide: CsAgentAuthService,
      useClass: CustomCsAgentAuthService,
    },
  ],
})
export class AsmModule {}
