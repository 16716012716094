import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { CardModule } from '@spartacus/storefront';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomOrderOverviewComponent } from './custom-order-overview.component';



@NgModule({
  declarations: [
    CustomOrderOverviewComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    CardModule,
    CustomCurrencyModule,
  ],
  exports: [
    CustomOrderOverviewComponent
  ]
})
export class CustomOrderOverviewModule { }
