import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserPoints } from '../common/models/user-points.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomUserPointsService {
  userPoints$: BehaviorSubject<UserPoints> = new BehaviorSubject<UserPoints>(
    {}
  );
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService
  ) {}

  getUserPoints(): Observable<UserPoints> {
    const url = this.occEndpointService.buildUrl('customerPoints');
    if (this.userPoints$?.value.code == 100) {
      return this.getUserPointsFromObservable();
    }
    return this.http.get(url);
  }

  setUserPoints(userPoints: UserPoints) {
    this.userPoints$.next(userPoints);
  }

  getUserPointsFromObservable(): Observable<UserPoints> {
    return this.userPoints$.asObservable();
  }
}
