import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { CmsLinkComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, combineLatest } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomGetImageUrlService } from 'src/app/services/custom-get-image-url.service';
import { CustomBrandsAndCategoriesService } from '../../../../services/custom-brands-and-categories.service';
import {
  CustomCategoryData,
  CustomCategoryList,
} from './model/custom-category-list.interface';
import { PageTypeService } from '../../services/page-type.service';

@Component({
  selector: 'app-custom-category-list',
  templateUrl: './custom-category-list.component.html',
  styleUrls: ['./custom-category-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CustomCategoryListComponent implements OnInit {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  seeMoreLink$: Observable<CmsLinkComponent | null>;
  title$: Observable<string>;
  items$: Observable<Observable<CustomCategoryData>[]>;
  isAutomotive: boolean;
  topMax: number = 12;
  sliceBy: number;
  isHomePage$: Observable<boolean> = this.pageTypeService.isHomepage();
  showCategoryText$: Observable<boolean> =
    this.pageTypeService.showCategoryText();
  constructor(
    private componentData: CmsComponentData<CustomCategoryList>,
    private customBreakpointService: CustomBreakpointService,
    private customGetImageUrl: CustomGetImageUrlService,
    private customBrandsAndCategoriesService: CustomBrandsAndCategoriesService,
    private router: Router,
    private pageTypeService: PageTypeService
  ) {}

  ngOnInit(): void {
    combineLatest([this.isHomePage$, this.isMobile$])
      .pipe(
        take(1),
        tap(
          ([isHomepage, isMobile]) =>
            (this.topMax = isHomepage && isMobile ? 1000 : 12)
        )
      )
      .subscribe();
    this.seeMoreLink$ = this.componentData.data$.pipe(
      map((data: CustomCategoryList) =>
        !!data?.seeMoreLink ? data.seeMoreLink : null
      )
    );
    this.title$ = combineLatest([
      this.componentData.data$,
      this.isMobile$,
    ]).pipe(
      tap(([data, isMobile]) => {
        this.isAutomotive =
          data.uid?.toLowerCase().includes('automotriz') ||
          this.router?.url?.includes('automotriz') ||
          data.uid?.toLowerCase().includes('promeclub') ||
          this.router?.url?.includes('promeclub')
            ? true
            : false;
      }),
      map(([data, isMobile]: [CustomCategoryList, boolean]) =>
        isMobile && data?.titleMobile ? data?.titleMobile : data?.title
      )
    );

    this.items$ = this.componentData.data$.pipe(
      map((data) => data?.categories?.trim()?.split(' ')),
      map((codes) =>
        codes?.map((code) =>
          this.customBrandsAndCategoriesService.getCategoryDataFromApi(code)
        )
      )
    );

    this.sliceBy = this.topMax;
  }

  getImageUrl(url: string): string {
    return this.customGetImageUrl.getImageUrl(url);
  }

  expandCategories(): void {
    if (this.sliceBy == this.topMax) {
      this.sliceBy = 1000;
    } else {
      this.sliceBy = this.topMax;
    }
  }
}
