import { Injectable } from '@angular/core';
import {
  CustomGTMEvent,
  CustomGTMHeaderEvent,
  CustomGTMSearchEvent,
  CustomGTMSearchRefinerEvent,
  EventType,
} from '../../cms-components/product/model/custom-gtmProduct.model';
import { CustomGtmDatalayerService } from './custom-gtm-datalayer.service';
@Injectable({
  providedIn: 'root',
})
export class CustomGtmSearchSelectionDatalayerService {
  constructor(private dataLayer: CustomGtmDatalayerService) {}

  searchEvent(keyword: string, result: boolean) {
    let searchRefinerEvent: CustomGTMSearchEvent;
    searchRefinerEvent = {
      event: EventType.search_result,
      keyword,
      search_result: result ? 'si' : 'no',
    };
    this.dataLayer.pushEvent(searchRefinerEvent);
  }

  searchRefinerEvent(
    brand: string,
    model: string,
    year: string,
    motor?: string
  ): void {
    let searchRefinerEvent: CustomGTMSearchRefinerEvent;
    searchRefinerEvent = {
      event: EventType.search_refiner,
      marca: brand,
      modelo: model,
      anio: year,
      motor: motor ? motor : null,
    };
    this.dataLayer.pushEvent(searchRefinerEvent);
  }

  footerEvent(footer: string) {
    let footerEvent: CustomGTMEvent;
    footerEvent = {
      event: EventType.eventClick,
      footer: footer,
    };
    this.dataLayer.pushEvent(footerEvent);
  }

  headerEvent(
    CategoriaHeader: string,
    MenuHeader: string,
    subMenuHeader: string
  ) {
    let headerEvent: CustomGTMHeaderEvent;
    headerEvent = {
      event: EventType.eventClick,
      CategoriaHeader,
      MenuHeader,
      subMenuHeader,
    };
    this.dataLayer.pushEvent(headerEvent);
  }
}
