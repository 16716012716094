import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  CheckoutConfigService,
  CheckoutStepService,
  DeliveryModeComponent,
} from '@spartacus/checkout/components';
import { CheckoutDeliveryFacade } from '@spartacus/checkout/root';
import { DeliveryMode } from '@spartacus/core';

import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeWhile,
  withLatestFrom,
} from 'rxjs/operators';
import { CustomBaseSiteCheckerService } from '../../../../../services/custom-base-site-checker.service';
import { CustomBreakpointService } from '../../../../../services/custom-breakpoint.service';
import { CustomCheckoutDeliveryStepsService } from '../../../services/custom-checkout-delivery-steps.service';

@Component({
  selector: 'app-custom-delivery-mode',
  templateUrl: './custom-delivery-mode.component.html',
  styleUrls: ['./custom-delivery-mode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDeliveryModeComponent
  extends DeliveryModeComponent
  implements OnInit, OnDestroy
{
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  constructor(
    private _fb: FormBuilder,
    private customCheckoutStepsService: CustomCheckoutDeliveryStepsService,
    private _checkoutDeliveryService: CheckoutDeliveryFacade,
    private _checkoutConfigService: CheckoutConfigService,
    private _activatedRoute: ActivatedRoute,
    private customBreakpointService: CustomBreakpointService,
    protected checkoutStepService: CheckoutStepService,
    protected customBaseSiteCheckerService: CustomBaseSiteCheckerService
  ) {
    super(
      _fb,
      _checkoutDeliveryService,
      _checkoutConfigService,
      _activatedRoute,
      checkoutStepService
    );
  }

  ngOnInit(): void {
    if (!this.isPromeclub) {
      this._checkoutDeliveryService.loadSupportedDeliveryModes();
    }

    this.supportedDeliveryModes$ = this._checkoutDeliveryService
      .getSupportedDeliveryModes()
      .pipe(
        filter((deliveryModes: DeliveryMode[]) => !!deliveryModes?.length),
        map((deliveryModes: DeliveryMode[]) =>
          deliveryModes?.filter(
            (mode: DeliveryMode) => mode?.code !== 'pickup_cd'
          )
        ),
        distinctUntilChanged(
          (current: DeliveryMode[], previous: DeliveryMode[]) => {
            return JSON.stringify(current) === JSON.stringify(previous);
          }
        )
      );

    this._checkoutDeliveryService
      .getLoadSupportedDeliveryModeProcess()
      .pipe(takeWhile((state) => state?.success === false))
      .subscribe((state) => {
        if (state.error && !state.loading) {
          this._checkoutDeliveryService.loadSupportedDeliveryModes();
        }
      });

    this.deliveryModeSub = this.supportedDeliveryModes$
      .pipe(
        withLatestFrom(
          this._checkoutDeliveryService
            .getSelectedDeliveryMode()
            .pipe(
              map(
                (deliveryMode: DeliveryMode | null | undefined) =>
                  deliveryMode?.code
              )
            ),
          this.customCheckoutStepsService.getIsDeliveryModeSelected()
        ),
        debounceTime(500)
      )
      .subscribe(
        ([deliveryModes, code, isDeliveryModeSelected]: [
          DeliveryMode[],
          string | undefined,
          boolean
        ]) => {
          if (
            !(
              code &&
              !!deliveryModes.find((deliveryMode) => deliveryMode.code === code)
            )
          ) {
            code =
              this._checkoutConfigService.getPreferredDeliveryMode(
                deliveryModes
              );
          }
          if (code && isDeliveryModeSelected) {
            this.mode.controls['deliveryModeId'].setValue(code);
            this._checkoutDeliveryService.setDeliveryMode(code);
            this.customCheckoutStepsService.setIsDeliveryModeSelected(true);
          }
          if (code && this.isPromeclub && !isDeliveryModeSelected) {
            this.mode.controls['deliveryModeId'].setValue(code);
            this._checkoutDeliveryService.setDeliveryMode(code);
            this.customCheckoutStepsService.setIsDeliveryModeSelected(true);
          }
        }
      );
  }

  changeMode(code: string): void {
    this._checkoutDeliveryService.setDeliveryMode(code);
    this.customCheckoutStepsService.setIsDeliveryModeSelected(true);
  }

  ngOnDestroy(): void {
    this.deliveryModeSub.unsubscribe();
    super.ngOnDestroy();
  }
}
