import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { SavedCartService } from '@spartacus/cart/saved-cart/core';
import {
  StateWithMultiCart,
  StateWithProcess,
  UserIdService,
  UserService,
  MultiCartService,
  EventService,
} from '@spartacus/core';
import { CustomSavedCartActions } from '../store/actions';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomSavedCartService
  extends SavedCartService
  implements OnDestroy
{
  unsubscribe$: Subject<void> = new Subject();
  constructor(
    protected store: Store<StateWithMultiCart | StateWithProcess<void>>,
    protected userIdService: UserIdService,
    protected userService: UserService,
    protected multiCartService: MultiCartService,
    protected eventService: EventService,
    private userAccount: UserAccountFacade
  ) {
    super(store, userIdService, userService, multiCartService, eventService);
  }

  restoreSavedCart(cartId: string): void {
    this.userIdService
      .takeUserId(true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (userId) => {
          return this.store.dispatch(
            new CustomSavedCartActions.CustomRestoreSavedCart({
              userId,
              cartId,
            })
          );
        },
        () => {
          // TODO: for future releases, refactor this part to thrown errors
        }
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
