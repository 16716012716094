import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PaginationModel } from '@spartacus/core';
import { OrderDetailItemsComponent, OrderDetailsService } from '@spartacus/order/components';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomOrderPaginationService } from '../../../services/custom-order-pagination.service';

@Component({
  selector: 'app-custom-order-detail-items',
  templateUrl: './custom-order-detail-items.component.html',
  styleUrls: ['./custom-order-detail-items.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomOrderDetailItemsComponent extends OrderDetailItemsComponent implements OnInit {
  selectedPage: number;
  orderDetails: any[] = [];
  orderDetailsShow: any[];
  PAGE_SIZE: number = 6;
  pagination: PaginationModel = {
    currentPage: 0,
    pageSize: this.PAGE_SIZE,
    sort: "",
    totalPages: 0,
    totalResults: 0
  }
  orderDetail$: Observable<any>;
  constructor(orderDetailsService: OrderDetailsService,
    private customOrderPaginationService: CustomOrderPaginationService) {
    super(orderDetailsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.orderDetail$ = this.orderDetailsService.getOrderDetails().pipe(
      tap((order) => {
          this.entries(order.entries!);
      }),
      tap(order => {
        this.selectedPage = this.pagination.currentPage!;
        this.pagination.totalResults = order.entries?.length;
        this.pagination.totalPages = Math.round((order.entries?.length! / this.pagination.pageSize!) + 0.4) > 1 ? Math.round((order.entries?.length! / this.pagination.pageSize!) + 0.4) : 1;
      })
    );
  }

  entries(entries?: any[], value?: number){
    entries ? this.orderDetails = entries : this.orderDetails;
    if (this.pagination.currentPage == 0 ) {
      this.orderDetailsShow = this.orderDetails.slice(0, this.PAGE_SIZE);
    } else {
      this.orderDetailsShow = this.orderDetails.slice(value, value! + this.PAGE_SIZE);
    }
    this.customOrderPaginationService.onPageChange(this.pagination);
  }

  customPageChange(event: any) {
    this.pageChange(event);
    this.selectedPage < this.pagination.pageSize! ? this.selectedPage = this.pagination.currentPage!
     : this.selectedPage = this.pagination.currentPage!;
    this.entries(this.orderDetails, this.PAGE_SIZE*event)
  }

  pageChange(page: number) {
    this.pagination.currentPage = page;
    this.customOrderPaginationService.onPageChange(this.pagination)
  }

}
