<ng-container *ngIf="!(isMobile$ | async)">
  <app-custom-modal-generic
    (closeModal)="closeModal($event)"
    [isClose]="true"
  ></app-custom-modal-generic>
</ng-container>
<div class="promeclub-alert">
  <div class="promeclub-alert__icon">
    <cx-icon type="EXCLAMATION_TRIANGLE"></cx-icon>
  </div>
  <ng-container *ngIf="errorMessage$ | async as error; else genericError">
    <div class="promeclub-alert__body">
      <ng-container *ngIf="{ type: errorType$ | async } as data">
        <h4 class="promeclub-alert__title">
          <ng-container *ngIf="error == errorCart">
            {{ "checkoutOrderConfirmation.withoutStock" | cxTranslate }}
          </ng-container>
          <ng-container *ngIf="!(error == errorCart)">
            {{
              data?.type === "PromeclubStockError"
                ? ("checkoutOrderConfirmation.withoutStock" | cxTranslate)
                : ("checkoutOrderConfirmation.genericErrorTitle" | cxTranslate)
            }}
          </ng-container>
        </h4>
      </ng-container>

      <p class="promeclub-alert__message">
        {{
          error == errorCart
            ? ("checkoutOrderConfirmation.promeclubAlertMessage" | cxTranslate)
            : error
        }}
      </p>
    </div>
  </ng-container>
  <ng-template #genericError>
    <h4 class="promeclub-alert__title">
      {{ "checkoutOrderConfirmation.withoutStock" | cxTranslate }}
    </h4>
    <p class="promeclub-alert__message">
      {{ "checkoutOrderConfirmation.promeclubAlertMessage" | cxTranslate }}
    </p>
  </ng-template>
  <button
    class="promeclub-alert__message-button"
    (click)="closeModal('navigate')"
  >
    {{ "checkoutOrderConfirmation.reviewCart" | cxTranslate }}
  </button>
</div>
