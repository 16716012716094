import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomAutomotiveApplicationsComponent } from './custom-automotive-applications.component';
import { CustomComponentTitleModule } from '../custom-component-title/custom-component-title.module';
import { I18nModule } from '@spartacus/core';
import { CustomProductAttributesAccordionModule } from '../../../product/components/custom-product-tabs/custom-product-attributes-accordion/custom-product-attributes-accordion.module';

@NgModule({
  declarations: [CustomAutomotiveApplicationsComponent],
  imports: [
    CommonModule,
    CustomComponentTitleModule,
    I18nModule,
    CustomProductAttributesAccordionModule,
  ],
  exports: [CustomAutomotiveApplicationsComponent],
})
export class CustomAutomotiveApplicationsModule {}
