import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
  AuthGuard,
} from '@spartacus/core';
import { AmendOrderActionsModule } from '@spartacus/order/components';
import { IconModule, ModalModule } from '@spartacus/storefront';
import { CustomModalGenericModule } from 'src/app/cms-components/custom/components/custom-modal/custom-modal-generic.module';
import { CustomTitleMyAccountModule } from 'src/app/cms-components/custom/components/custom-title-my-account/custom-title-my-account.module';
import { CustomOrderDetailItemsModule } from '../custom-order-details/custom-order-detail-items/custom-order-detail-items.module';
import { CustomOrderDetailShippingModule } from '../custom-order-details/custom-order-detail-shipping/custom-order-detail-shipping.module';
import { CustomOrderDetailTotalsModule } from '../custom-order-details/custom-order-detail-totals/custom-order-detail-totals.module';
import { CustomAmendOrderActionsComponent } from './custom-amend-order-actions/custom-amend-order-actions.component';
import { CustomOrderCancelDialogComponent } from './custom-order-cancel-dialog/custom-order-cancel-dialog.component';
import { CustomOrderCancelComponent } from './custom-order-cancel.component';
import { CustomOrderCancelGuard } from './guards/custom-order-cancel.guard';

@NgModule({
  declarations: [
    CustomOrderCancelComponent,
    CustomAmendOrderActionsComponent,
    CustomOrderCancelDialogComponent,
  ],
  imports: [
    CommonModule,
    CustomOrderDetailItemsModule,
    CustomOrderDetailTotalsModule,
    CustomOrderDetailShippingModule,
    CustomTitleMyAccountModule,
    AmendOrderActionsModule,
    I18nModule,
    UrlModule,
    RouterModule,
    IconModule,
    ModalModule,
    CustomModalGenericModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CancelDetailOrderComponent: {
          component: CustomOrderCancelComponent,
          guards: [AuthGuard, CustomOrderCancelGuard],
        },
      },
    }),
  ],
  exports: [CustomAmendOrderActionsComponent],
})
export class CustomOrderCancelModule {}
