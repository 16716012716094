import { Component, Input, ViewEncapsulation } from '@angular/core';
import { OrderEntry } from '@spartacus/core';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';

@Component({
  selector: 'app-custom-added-to-cart-entry',
  templateUrl: './custom-added-to-cart-entry.component.html',
  styleUrls: ['./custom-added-to-cart-entry.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomAddedToCartEntryComponent {
  @Input() entry: OrderEntry;
  @Input() quantity: number;
  @Input() isPdp: boolean = false;
  @Input() fromWish: boolean = false;
  @Input() isMobile: boolean = false;
  isInPromeclub: boolean = this.service.isInPromeClub();

  constructor(private service: CustomNavigationService) {}
}
