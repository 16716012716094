import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { ProductReviewService, Review } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { CustomLoadingSpinnerService } from '../../services/custom-loading-spinner.service';
import { CustomScrollService } from 'src/app/services/custom-scroll.service';

export enum STAR_STYLE {
  FULL = 'full-star',
  HALF = 'half-star',
  EMPTY = 'empty-star',
}

@Component({
  selector: 'app-custom-product-reviews-overview',
  templateUrl: './custom-product-reviews-overview.component.html',
  styleUrls: ['./custom-product-reviews-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomProductReviewsOverviewComponent
  implements OnInit, OnDestroy
{
  @Input() reviewRatingAverage: number;
  @Input() count: number;
  @Input() reviewStars: number;
  @Input() fromFacet: boolean = false;
  @Input() reviewsMobilePDP: boolean = false;
  @Input() fromMenu: boolean = false;
  @Input() selected: boolean = false;
  @Input() fromProductIntro: boolean = false;
  @Input() currentUrl: string;

  TOTAL_OF_STARS = 5;
  starStyle = STAR_STYLE;
  reviews$: Observable<Review[]>;
  reviewStars$: Observable<STAR_STYLE[]>;
  average: number;
  facetReviewRating: STAR_STYLE[];
  facetReviewPDP: STAR_STYLE[];
  subscription: Subscription = new Subscription();
  constructor(
    protected reviewService: ProductReviewService,
    protected currentProductService: CurrentProductService,
    protected customLoadingSpinnerService: CustomLoadingSpinnerService,
    private scrollService: CustomScrollService
  ) {}

  ngOnInit(): void {
    if (!this.fromFacet) {
      this.reviews$ = this.currentProductService.getProduct().pipe(
        filter((currentProduct) => !!currentProduct),
        map((p: any) => p.code),
        distinctUntilChanged(),
        switchMap((productCode) =>
          this.reviewService.getByProductCode(productCode)
        ),
        filter((reviews) => !!reviews && !!reviews.length)
      );
      this.reviewStars$ = this.reviews$.pipe(
        map((reviews: Review[]) => {
          let total = 0;
          reviews.map((review: any) => (total = total + review.rating));
          const average = total / reviews.length;

          return this.setStyledStarsFromAverageRating(average);
        })
      );
    } else {
      this.facetReviewRating = this.setStyledStarsFromAverageRating(
        this.reviewRatingAverage
      );
    }

    if (this.reviewsMobilePDP) {
      this.facetReviewPDP = this.setStyledStarsFromAverageRating(
        this.reviewStars
      );
    }
  }

  setStyledStarsFromAverageRating(averageRating: number) {
    const average = averageRating;
    const integerAverage = average | 0;
    const averageRange = average - integerAverage;

    const complete = Array(
      averageRange >= 0.7 ? integerAverage + 1 : integerAverage
    ).fill(STAR_STYLE.FULL);
    const half = Array(averageRange >= 0.3 && averageRange < 0.7 ? 1 : 0).fill(
      STAR_STYLE.HALF
    );
    const empty = Array(
      averageRange < 0.3
        ? this.TOTAL_OF_STARS - integerAverage
        : this.TOTAL_OF_STARS - integerAverage - 1
    ).fill(STAR_STYLE.EMPTY);

    return [...complete, ...half, ...empty];
  }

  moveToElementRefReviews() {
    this.scrollService.setMoveToReviews(true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
