import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActiveCartService, MultiCartService, OccEndpointsService, OCC_CART_ID_CURRENT, RoutingService } from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';

import { Observable, of } from 'rxjs';
import { catchError, delay, switchMap, take, tap } from 'rxjs/operators';
import { CustomReloadCartService } from '../../cart/services/custom-reload-cart.service';

@Injectable({
  providedIn: 'root',
})
export class CustomReorderService {
  customLoadingSpinnerService: any;
  modalRef: ModalRef;
  orderCode: string;
  static readonly USER_ID = 'current';
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
    private activeCartService: ActiveCartService,
    private multicartService: MultiCartService,
    protected customReloadCartService: CustomReloadCartService,
    protected modalService: ModalService,
    private routingService: RoutingService,
  ) { }

  reorder(orderCode: string): Observable<any> {
    const url = this.occEndpointService.buildUrl('reorder', {
      urlParams: {
        userId: CustomReorderService.USER_ID,
      },
      queryParams: {
        orderCode
      }
    });

    return this.activeCartService.getActiveCartId().pipe(
      take(1),
      switchMap((cartId) => {
        return this.http.post(url, {}).pipe(
          switchMap((cart) => of(cart).pipe(
            delay(2500),
            tap(() => {
              this.activeCartService.reloadActiveCart();
            })
          )),
        )
      }),
      catchError((error) => {
        this.routingService.goByUrl('/not-found');
        return error;
      }),
    );
  }

}
