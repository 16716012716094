<ng-container
  *ngIf="{
    cart: cart$ | async,
    user: user$ | async,
    isMobile: isMobile$ | async
  } as data"
>
  <div class="custom-review" [ngClass]="{ prome: isPromeclub }">
    <div class="row">
      <ng-container *ngIf="!data.isMobile">
        <ng-container *ngTemplateOutlet="information"></ng-container>
      </ng-container>
      <div
        class="col-12 col-md-12 col-lg-4 custom-review__information"
        [ngClass]="{ 'custom-review__delivery-mode': !isPromeclub }"
      >
        <div class="delivery-mode" [ngClass]="{ prome: isPromeclub }">
          <div class="custom-review__top-content">
            <div class="steps" *ngIf="data.isMobile">
              {{ "paymentTypes.stepOne" | cxTranslate }}
            </div>
            <div class="d-flex justify-content-between w-100">
              <div [ngClass]="{ 'check-with-title': data.isMobile }">
                <cx-icon type="CHECK_CHECKOUT" *ngIf="data.isMobile"></cx-icon>
                <h2 class="custom-review__top-content__title-delivery-method">
                  {{ "checkoutShipping.deliveryMethod" | cxTranslate }}
                </h2>
              </div>
              <div class="custom-review__top-content__actions">
                <a
                  [attr.aria-label]="
                    'checkoutReview.editDeliveryMode' | cxTranslate
                  "
                  [routerLink]="
                    {
                      cxRoute: getCheckoutStepUrl(
                        checkoutStepType.DELIVERY_MODE
                      )
                    } | cxUrl
                  "
                  (click)="editEvent(checkoutStepType.DELIVERY_MODE)"
                >
                  <cx-icon
                    aria-hidden="true"
                    class="fas fa-edit"
                    *ngIf="!data.isMobile"
                  ></cx-icon>
                  <span>{{ "common.edit" | cxTranslate }}</span>
                </a>
              </div>
            </div>
          </div>
          <div class="custom-review__body-content">
            <ng-container
              *ngIf="
                data?.cart?.deliveryAddress &&
                  data?.cart?.deliveryMode?.code !== 'pickup_cd';
                else centerDistribution
              "
            >
              <div
                class="custom-review__body-content__shipping-address"
                [ngClass]="{ promeclub: isPromeclub }"
              >
                <h2>{{ "checkoutProgress.myAdress" | cxTranslate }}</h2>
                <span
                  *ngIf="data?.cart?.deliveryAddress?.firstName && !isPromeclub"
                  [ngClass]="{ 'first-name': data.isMobile }"
                >
                  {{ data?.cart?.deliveryAddress.firstName }}
                </span>
                <span
                  *ngIf="isPromeclub"
                  [ngClass]="{ 'first-name': data.isMobile }"
                >
                  {{
                    customPromeclubAddressService.typeTranslation(
                      data?.cart?.deliveryAddress
                    )
                  }}
                </span>
                <span *ngIf="data?.cart?.deliveryAddress?.line1">
                  {{
                    data?.cart?.deliveryAddress.line1 +
                      " " +
                      (data?.cart?.deliveryAddress.line2
                        ? data?.cart?.deliveryAddress.line2
                        : "")
                  }}
                </span>
                <span
                  *ngIf="data?.cart?.deliveryAddress.district && isPromeclub"
                >
                  {{ data?.cart?.deliveryAddress.district }}
                </span>
                <span *ngIf="data?.cart?.deliveryAddress.region?.name">
                  {{ data?.cart?.deliveryAddress.region.name }}
                </span>
                <span
                  *ngIf="data?.cart?.deliveryAddress.region?.province?.name"
                >
                  {{ data?.cart?.deliveryAddress.region?.province?.name }}
                </span>
              </div>
              <div
                class="custom-review__body-content__delivery-mode"
                *ngIf="!isPromeclub"
              >
                <h2>{{ "checkoutShipping.shippingMethod" | cxTranslate }}</h2>
                <span *ngIf="data?.cart?.deliveryMode?.name">
                  {{ data?.cart?.deliveryMode?.name }}
                </span>
                <span *ngIf="cart?.formattedDeliveryDate">
                  {{ "checkoutShipping.deliveryOn" | cxTranslate
                  }}{{ data?.cart?.formattedDeliveryDate }}
                </span>
              </div>
            </ng-container>
            <ng-template #centerDistribution>
              <ng-container *ngIf="!isPromeclub">
                <div class="custom-review__body-content__center-distribution">
                  <h2>{{ data?.cart?.deliveryMode?.name }}</h2>
                  <div
                    class="custom-review__body-content__center-distribution__message"
                  >
                    <cx-icon class="fas fa-exclamation-circle"></cx-icon>
                    <div
                      class="custom-review__body-content__center-distribution__message__text"
                    >
                      <p
                        class="custom-review__body-content__center-distribution__message__text-title"
                      >
                        Aviso de retiro:
                      </p>
                      <p
                        *ngIf="
                          promesaInfoPickupCDComponentData$ | async as message
                        "
                      >
                        {{ message?.description }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="custom-review__body-content__center-distribution__content"
                  >
                    <p>Centro de Distribución Guayaquil</p>
                    <p>
                      Av. Terminal Pascuales S/N junto a la gasolinera Terpel
                    </p>
                  </div>
                </div>
              </ng-container>
            </ng-template>
          </div>
        </div>
      </div>

      <div
        class="col-12 col-md-12 col-lg-4"
        [ngClass]="{
          'custom-review__payment-type': !isPromeclub,
          'custom-review__payment-type-promeclub': isPromeclub
        }"
        *ngIf="!hideContent"
      >
        <div class="payment-type">
          <div class="custom-review__top-content">
            <div class="steps" *ngIf="data.isMobile">
              {{ "paymentTypes.stepTwo" | cxTranslate }}
            </div>
            <div class="d-flex justify-content-between w-100">
              <div [ngClass]="{ 'check-with-title': data.isMobile }">
                <cx-icon type="CHECK_CHECKOUT" *ngIf="data.isMobile"></cx-icon>
                <h2 class="custom-review__top-content__title-payment-type">
                  {{
                    isPromeclub
                      ? ("paymentTypes.promeclubTitle" | cxTranslate)
                      : ("paymentTypes.title" | cxTranslate)
                  }}
                </h2>
              </div>
              <div
                class="custom-review__top-content__actions"
                *ngIf="!isPromeclub && !data?.cart?.isFromQuote"
              >
                <a
                  [attr.aria-label]="
                    'checkoutReview.editPaymentType' | cxTranslate
                  "
                  [routerLink]="
                    {
                      cxRoute: getCheckoutStepUrl(checkoutStepType.PAYMENT_TYPE)
                    } | cxUrl
                  "
                  (click)="editEvent(checkoutStepType.PAYMENT_TYPE)"
                >
                  <cx-icon
                    aria-hidden="true"
                    class="fas fa-edit"
                    *ngIf="!data.isMobile"
                  ></cx-icon>
                  <span> {{ "common.edit" | cxTranslate }} </span>
                </a>
              </div>
            </div>
          </div>
          <div class="custom-review__body-content">
            <ng-container *ngIf="data?.cart?.paymentType">
              <div
                *ngIf="
                  data?.cart?.paymentType.code === 'CREDIT_LINE' || isPromeclub
                "
                class="custom-review__body-content__payment-type"
              >
                <h2
                  *ngIf="
                    isPromeclub && data?.cart?.paymentType.code == 'PROMECLUB'
                  "
                >
                  {{ data?.cart?.paymentType?.displayName }}
                </h2>
                <h2 *ngIf="!isPromeclub">
                  {{ data?.cart?.paymentType?.displayName }}
                </h2>
                <span *ngIf="!isPromeclub && !data?.user.isEmployee">
                  {{ data?.cart?.paymentCondition }}
                </span>
                <div
                  class="custom-review__body-content__payment-type__message w-100"
                  *ngIf="!isPromeclub && data?.user?.isEmployee"
                >
                  <cx-icon class="fas fa-exclamation-circle"></cx-icon>
                  <div
                    class="custom-review__body-content__payment-type__message__text"
                    *ngIf="
                      promesaInfoCreditPaymentEmployeeComponent$
                        | async as message
                    "
                  >
                    <p
                      class="custom-review__body-content__payment-type__message__text-title"
                    >
                      {{ message?.title }}
                    </p>
                    <p>
                      {{ message?.description }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                *ngIf="
                  data?.cart?.paymentType.code === 'DEPOSIT' ||
                  data?.cart?.paymentType.code === 'TRANSFER'
                "
                class="custom-review__body-content__payment-type"
              >
                <h2>{{ "checkout.cashTitle" | cxTranslate }}</h2>
                <div class="custom-review__body-content__payment-type__message">
                  <cx-icon class="fas fa-exclamation-circle"></cx-icon>
                  <div
                    class="custom-review__body-content__payment-type__message__text"
                  >
                    <p
                      class="custom-review__body-content__payment-type__message__text-title"
                    >
                      {{ "checkout.cashPayment" | cxTranslate }}
                    </p>
                    <p
                      *ngIf="
                        (data?.user.isEmployee
                          ? promesaInfoCashPaymentEmployeeComponent$
                          : promesaInfoCashPaymentComponentData$
                        ) | async as message
                      "
                    >
                      {{ message?.description }}
                    </p>
                  </div>
                </div>
                <h2 class="paymentType-name">
                  {{ data?.cart?.paymentType?.displayName }}
                </h2>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <ng-container *ngIf="data.isMobile && !hideContent">
        <ng-container *ngTemplateOutlet="information"></ng-container>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="data.isMobile && !hideContent">
    <app-custom-place-order></app-custom-place-order>
  </ng-container>

  <ng-template #information>
    <div
      class="col-12 col-md-12 col-lg-4 custom-review__information"
      *ngIf="!isPromeclub"
    >
      <div class="information">
        <div class="custom-review__top-content">
          <cx-icon type="BILLING_CHECKOUT" *ngIf="data.isMobile"></cx-icon>
          <h2 class="custom-review__top-content__title-information">
            {{ "checkout.billingInformation" | cxTranslate }}
          </h2>
        </div>
        <div
          class="custom-review__body-content"
          *ngIf="data?.cart?.paymentAddress"
        >
          <div class="custom-review__body-content__information">
            <span *ngIf="data?.cart?.b2bUnit?.businessName">
              {{ data?.cart?.b2bUnit.businessName }}
            </span>

            <span *ngIf="data?.cart?.b2bUnit?.ruc">
              {{ data?.cart?.b2bUnit.ruc }}
            </span>

            <span
              *ngIf="
                data?.cart?.paymentAddress?.line1 &&
                data?.cart?.paymentAddress?.line2
              "
              >{{
                data?.cart?.paymentAddress.line1 +
                  " " +
                  data?.cart?.paymentAddress.line2
              }}</span
            >
            <span
              *ngIf="
                data?.cart?.paymentAddress.line1 &&
                !data?.cart?.paymentAddress.line2
              "
              >{{ data?.cart?.paymentAddress.line1 }}</span
            >
            <span *ngIf="data?.cart?.paymentAddress?.line1">{{
              data?.cart?.paymentAddress.region?.name
            }}</span>

            <span *ngIf="data?.cart?.b2bUnit?.phone">
              {{ data?.cart?.b2bUnit.phone }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
