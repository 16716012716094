import { Injectable } from '@angular/core';
import { CheckoutStepService } from '@spartacus/checkout/components';
import { CheckoutStepType } from '@spartacus/checkout/root';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomReviewSubmitService {
  isReviewSubmitActive$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(private checkoutStepService: CheckoutStepService) {}

  getIsReviewSubmitActive(): Observable<boolean> {
    return this.isReviewSubmitActive$.asObservable();
  }
  setIsReviewSubmitActive(value: boolean): void {
    this.isReviewSubmitActive$.next(value);
  }

  getCheckoutLastStepUrl(
    stepType = CheckoutStepType.REVIEW_ORDER
  ): string | undefined {
    const step = this.checkoutStepService.getCheckoutStep(stepType);
    return step && step.routeName;
  }
}
