<ng-container *ngIf="{
      centerDistribution: centerDistribution$ | async,
      isCenterDistributionActive: isCenterDistributionActive$ | async,
      isCenterDistributionSelected: isCenterDistributionSelected$ | async,
      isAddressActive: isAddressActive$ | async,
      isAddressSelected: isAddressSelected$ | async,
      isDeliveryModeSelected: isDeliveryModeSelected$ | async,
      isAuthorizedPersonSelected: isAuthorizedPersonSelected$ | async,
      showButton: showButton$ | async,
      userId: userId$ | async,
      isMobile: isMobile$ | async
    } as data">

  <div class="custom-checkout-delivery">
    <ng-container *ngIf="data.isMobile; else titleDesktop">
      <app-custom-title-step-mobile [numberStep]="numberStep"></app-custom-title-step-mobile>
    </ng-container>

    <ng-template #titleDesktop>
      <h2 class="custom-checkout-delivery__progress-title d-lg-block d-xl-block">
        {{ "checkoutShipping.deliveryMethod" | cxTranslate }}
      </h2>
    </ng-template>

    <div class="custom-checkout-delivery-method">
      <div class="custom-checkout-delivery-method__method">
        <div class="custom-checkout-delivery-method__method-address">
          <label class="custom-checkout-delivery-method__method-address-label" (click)="toggleAddressActive(
          data.isAddressActive,
          data.isCenterDistributionActive
        )">
            <app-custom-check-button [isActive]="data.isAddressActive" [isIconActive]="data.isAddressActive"
              [isIconCircle]="data.isMobile ? true : false"></app-custom-check-button>
            <span>{{ "checkoutProgress.myAdress" | cxTranslate }}</span>
          </label>
          <ng-container *ngIf="data.isMobile">
            <ng-container *ngIf="data.isAddressActive">
              <div class="custom-checkout-delivery-method__method-address-info">
                <!-- SHIPPING ADDRESS -->
                <app-custom-shipping-address></app-custom-shipping-address>
                <!-- DELIVERY MODE -->
                <ng-container *ngIf="data.isAddressSelected">
                  <app-custom-delivery-mode [ngClass]="{ prome: isPromeclub }"></app-custom-delivery-mode>
                </ng-container>
                <ng-container *ngIf="(data.isAddressSelected && data.isDeliveryModeSelected)">
                  <ng-container *ngTemplateOutlet="button"></ng-container>
                </ng-container>
              </div>
            </ng-container>

          </ng-container>
        </div>
        <ng-container *ngIf="data.centerDistribution">
          <div class="custom-checkout-delivery-method__method-distribution">
            <label class="custom-checkout-delivery-method__method-distribution-label" (click)="toggleCenterDistributionActive(
            data.isCenterDistributionActive,
            data.isAddressActive)">
              <app-custom-check-button [isActive]="data.isCenterDistributionActive"
                [isIconActive]="data.isCenterDistributionActive"
                [isIconCircle]="data.isMobile ? true : false"></app-custom-check-button>
              <span>{{ "checkoutProgress.centerDistribution" | cxTranslate }}</span>
            </label>
            <ng-container *ngIf="data.isMobile">
              <ng-container *ngIf="data.isCenterDistributionActive && data.centerDistribution">
                <div class="custom-checkout-delivery-method__method-distribution-info">
                  <!-- CENTER DISTRIBUTION -->
                  <app-custom-center-distribution></app-custom-center-distribution>
                  <!-- AUTHORIZED PERSON -->
                  <ng-container *ngIf="data.isCenterDistributionSelected && data.centerDistribution">
                    <app-custom-authorized-person [userId]="data.userId"></app-custom-authorized-person>
                  </ng-container>
                  <ng-container *ngIf="(data.isCenterDistributionSelected && data.isAuthorizedPersonSelected)">
                    <ng-container *ngTemplateOutlet="button"></ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="!data.isMobile">
      <!-- SHIPPING ADDRESS -->
      <ng-container *ngIf="data.isAddressActive">
        <app-custom-shipping-address></app-custom-shipping-address>
      </ng-container>
      <!-- DELIVERY MODE -->
      <ng-container *ngIf="data.isAddressSelected">
        <app-custom-delivery-mode [ngClass]="{ prome: isPromeclub }"></app-custom-delivery-mode>
      </ng-container>
      <!-- CENTER DISTRIBUTION -->
      <ng-container *ngIf="data.isCenterDistributionActive && data.centerDistribution">
        <app-custom-center-distribution></app-custom-center-distribution>
      </ng-container>
      <!-- AUTHORIZED PERSON -->
      <ng-container *ngIf="data.isCenterDistributionSelected && data.centerDistribution">
        <app-custom-authorized-person [userId]="data.userId"></app-custom-authorized-person>
      </ng-container>

      <ng-container *ngTemplateOutlet="button"></ng-container>
    </ng-container>
    <ng-template #button>
      <div class="row cx-checkout-btns" *ngIf="data.showButton">
        <div class="col-md-12 col-lg-12 btn-container">
          <button class="btn btn-primary btn-container__custom-btn" [disabled]="
              !(
                (data.isAddressSelected && data.isDeliveryModeSelected) ||
                (data.isCenterDistributionSelected &&
                  data.isAuthorizedPersonSelected)
              )
            " (click)="
              next(
                data.isAddressSelected,
                data.isDeliveryModeSelected,
                data.isCenterDistributionSelected
              )
            ">

            {{ data.isMobile ? ("common.confirm" | cxTranslate) : ("common.continue" | cxTranslate) }}
          </button>
        </div>
      </div>
    </ng-template>

  </div>
  <ng-container *ngIf="data.isMobile">
    <div class="custom-checkout-delivery__step-disabled">
      <app-custom-title-step-mobile [numberStep]="numberNextStep" [titleDisabled]="true"></app-custom-title-step-mobile>
    </div>
    <div class="custom-checkout-delivery__place-order">
      <app-custom-place-order></app-custom-place-order>
    </div>
  </ng-container>


</ng-container>
