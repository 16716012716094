import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ModalService } from '@spartacus/storefront';
import { CustomUpdateProfileService } from '../../custom-update-profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-updated-password-modal',
  templateUrl: './custom-updated-password-modal.component.html',
  styleUrls: ['./custom-updated-password-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomUpdatedPasswordModalComponent implements OnDestroy {
  constructor(
    private modalService: ModalService,
    private customUpdateProfileService: CustomUpdateProfileService,
    private router: Router
  ) {}

  PasswordMismatchError: boolean = false;
  fromReset: boolean = false;

  closeModal(reason: any) {
    this.customUpdateProfileService.resetForms();
    this.modalService.dismissActiveModal(reason);
  }
  ngOnDestroy(): void {
    if (this.fromReset) {
      this.router
        .navigateByUrl(!this.PasswordMismatchError ? '/login' : '/')
        .then((value) => {
          if (!value) {
            window.location.reload();
          }
        });
    }
  }
}
