<div
  *ngIf="{
    componentData: componentData$ | async,
    isMobile: isMobile$ | async
  } as info"
  class="custom-banner-carousel"
>
  <swiper [config]="swiperConfig$ | async" *ngIf="info?.componentData">
    <ng-template
      swiperSlide
      *ngFor="let item$ of items$ | async; let i = index"
      let-data
    >
      <div
        class="custom-banner-carousel__img"
        *ngIf="item$ | async as item"
        tabindex="0"
      >
        <div
          class="custom-banner-carousel__info"
          *ngIf="
            (!item.video && item?.headline) ||
            (!item.video && item?.mobileHeadline)
          "
        >
          <h3
            *ngIf="item?.headline && !item?.video && !info.isMobile"
            class="custom-banner-carousel__info-title"
            [innerHTML]="item.headline | safeHtml"
          ></h3>
          <h3
            *ngIf="item?.mobileHeadline && !item?.video && info.isMobile"
            class="custom-banner-carousel__info-title"
            [innerHTML]="item.mobileHeadline | safeHtml"
          ></h3>
          <span
            *ngIf="item?.content && !item?.video && !info.isMobile"
            class="custom-banner-carousel__info-subtitle"
            [ngClass]="{
              promeclub: isInPromeclub
            }"
            [innerHTML]="item.content | safeHtml"
          >
          </span>
          <span
            *ngIf="item?.mobileContent && !item?.video && info.isMobile"
            class="custom-banner-carousel__info-subtitle"
            [ngClass]="{
              promeclub: isInPromeclub
            }"
            [innerHTML]="item.mobileContent | safeHtml"
          >
          </span>
          <cx-generic-link
            *ngIf="!info.isMobile || (!info.isMobile && item.linkTitle)"
            class="custom-button primary"
            [ngClass]="!item.linkTitle || info.isMobile ? 'without-text' : ''"
            [url]="item.urlLink"
            (click)="onEventClick(item, i)"
          >
            {{ item.linkTitle && !info.isMobile ? item.linkTitle : "" }}
            <cx-icon class="fas fa-chevron-right"></cx-icon>
          </cx-generic-link>
          <cx-generic-link
            *ngIf="info.isMobile || (info.isMobile && item?.mobileLinkTitle)"
            class="custom-button primary"
            [ngClass]="!item.mobileLinkTitle ? 'without-text' : ''"
            [url]="item.urlLink"
            (click)="onEventClick(item, i)"
          >
            {{ item.mobileLinkTitle ? item.mobileLinkTitle : "" }}
            <cx-icon class="fas fa-chevron-right"></cx-icon>
          </cx-generic-link>
        </div>

        <cx-generic-link
          [url]="!(item.headline && item.linkTitle) ? item.urlLink : null"
          (click)="onEventClick(item, i)"
          *ngIf="!item?.video; else videoTemplate"
        >
          <ng-container *ngIf="item?.image | async as itemImage">
            <img
              [src]="getImageUrl(itemImage?.url)"
              [alt]="itemImage.altText"
              [loading]="i > 1 ? 'lazy' : 'eager'"
              fetchPriority="high"
              [style]="
                (item?.activateLinearGradientEffect &&
                  item?.desktopLinearGradientEffect && !info.isMobile) ||
                (item?.activateLinearGradientEffect &&
                  item?.mobileLinearGradientEffect && info.isMobile)
                  ? 'opacity: 0'
                  : 'opacity: 1'
              "
            />
            <div
              *ngIf="
                (item?.activateLinearGradientEffect &&
                  item?.desktopLinearGradientEffect && !info.isMobile) ||
                (item?.activateLinearGradientEffect &&
                  item?.mobileLinearGradientEffect && info.isMobile)
              "
              [class]="'img-backdrop --' + i"
              [style]="getStyles(i, info.isMobile, itemImage?.url, item)"
            ></div>
          </ng-container>
        </cx-generic-link>

        <ng-template #videoTemplate>
          <video
            *ngIf="item?.video?.srcVideo && data?.isActive"
            class="custom-banner-carousel__img-video"
            width="100%"
            [src]="item?.video?.srcVideo"
            [autoplay]="item?.video?.autoPlay === 'true' && data?.isActive"
            [loop]="true"
            controls
            [muted]="true"
          ></video>
        </ng-template>
      </div>
    </ng-template>
  </swiper>
</div>
