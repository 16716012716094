<ng-container
  *ngIf="{ entries: entries$ | async, isMobile: isMobile$ | async } as data"
>
  <ng-container *ngIf="!data.isMobile">
    <app-custom-modal-generic
      (closeModal)="closeModal($event)"
      [isClose]="true"
    ></app-custom-modal-generic>
  </ng-container>

  <div class="stock-info">
    <div class="stock-info__body">
      <div class="stock-info__body-icon">
        <cx-icon class="fas fa-exclamation-triangle"></cx-icon>
      </div>
      <div class="stock-info__body-text">
        <h2 class="stock-info__body-text__title">
          {{ "checkoutStock.title" | cxTranslate }}
        </h2>
        <p
          class="stock-info__body-text__paragraph"
          *ngIf="!data.isMobile && data?.entries?.length"
        >
          {{ "checkoutStock.text" | cxTranslate }}
          <span class="stock-info__body-text__title">{{
            (data.entries.length < 2
              ? "checkoutStock.textProducts"
              : "checkoutStock.textProducts_plural"
            ) | cxTranslate : { quantity: data?.entries?.length }
          }}</span>
        </p>
        <p
          class="stock-info__body-text__paragraph"
          *ngIf="data.isMobile && data?.entries?.length"
        >
          {{ "checkoutStock.textMobile" | cxTranslate }}
        </p>
      </div>
      <div
        class="stock-info__body-list"
        [ngClass]="{
          'one-entry': data.entries.length === 1,
          scroll: data.entries.length > 1 && data.isMobile
        }"
      >
        <ng-container *ngFor="let entry of data.entries; index as i">
          <app-custom-stock-info-entry
            [entry]="entry"
            [onlyOne]="data.entries.length === 1"
            [isMobile]="data.isMobile"
          ></app-custom-stock-info-entry>
        </ng-container>
      </div>
    </div>
    <div class="stock-info__action">
      <button class="stock-info__action__btn" (click)="closeModal('redirect')">
        {{ "checkoutStock.reviewCart" | cxTranslate }}
      </button>
    </div>
  </div>
</ng-container>
