import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  CmsBannerComponent,
  CmsComponent,
  CmsResponsiveBannerComponentMedia,
  CmsService,
} from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomCmsBannerCarouselComponent } from 'src/app/cms-components/content/components/custom-banner-carousel/model/custom-banner-carousel';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomGetImageUrlService } from 'src/app/services/custom-get-image-url.service';
import { SwiperOptions } from 'swiper';
import { CustomBaseSiteCheckerService } from '../../../../services/custom-base-site-checker.service';
import { CustomTopLinkService } from '../../../../services/custom-top.link.service';
import { BannerGradient } from '../models/custom-banner-gradient.interface';

@Component({
  selector: 'app-custom-secondary-banner',
  templateUrl: './custom-secondary-banner.component.html',
  styleUrls: ['./custom-secondary-banner.component.scss'],
})
export class CustomSecondaryBannerComponent implements OnInit {
  componentData$: Observable<CustomCmsBannerCarouselComponent> =
    this.componentData.data$;
  items$: Observable<Observable<CmsBannerComponent>[]>;
  swiperConfig$ = new BehaviorSubject<SwiperOptions>({});
  isMobile$: Observable<boolean> = this.customBreakpoinService.isMobile$;
  mobileIndex: number = 0;
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();

  constructor(
    protected componentData: CmsComponentData<CustomCmsBannerCarouselComponent>,
    protected cmsService: CmsService,
    protected customGetImageUrl: CustomGetImageUrlService,
    protected customBreakpoinService: CustomBreakpointService,
    protected customTopLinkService: CustomTopLinkService,
    protected router: Router,
    protected customBaseSiteCheckerService: CustomBaseSiteCheckerService
  ) {}

  ngOnInit(): void {
    if (this.isPromeclub) {
      this.mobileIndex = 2;
    }
    this.items$ = this.componentData$.pipe(
      map((data) => data.responsiveBanners.trim().split(' ')),
      map((codes) =>
        codes.map((code) =>
          this.cmsService.getComponentData(code).pipe(
            map((bannerData: CmsComponent | null) => ({
              ...bannerData,
              image: this.customBreakpoinService?.getImageByBreakpoint(
                <CmsResponsiveBannerComponentMedia>bannerData
              ),
            }))
          )
        )
      ),
      map((items) => items?.slice(0, 3))
    );
  }

  getImageUrl(url: string): string {
    return this.customGetImageUrl.getImageUrl(url);
  }

  changeSection(item: any) {
    this.customTopLinkService.changeCurrentSection(item.section);
  }

  navigateToUrl(url: string) {
    if (url.includes('http')) {
      window.open(url, '_self');
      return;
    }
    this.router.navigateByUrl(url).then((navigate) => {
      if (!navigate) {
        window.location.reload();
      }
    });
  }

  getStyles(
    i: number,
    isMobile: boolean,
    imageUrl: string,
    item: BannerGradient
  ): string {
    const gradientValue: string | undefined = !isMobile
      ? item?.desktopLinearGradientEffect?.trim()
      : item?.mobileLinearGradientEffect?.trim();
    const property = `--gradient${i}:${gradientValue} --bg-url${i}:url(${this.getImageUrl(
      imageUrl
    )})`;
    return property;
  }
}
