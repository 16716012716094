import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActiveCartService } from '@spartacus/core';
import {
  AddedToCartDialogComponent,
  ModalService,
} from '@spartacus/storefront';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, switchMapTo, takeUntil } from 'rxjs/operators';
import { CustomDialogCartService } from 'src/app/cms-components/navigation/services/custom-dialog-cart.service';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';
import { CustomProduct } from '../../../product/model/custom-product.interface';
import { AddToCartErrorsService } from '../../services/add-to-cart-errors.service';
import { CustomPromeclubAddToCartService } from '../../services/custom-promeclub-add-to-cart.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-promeclub-added-to-cart-dialog',
  templateUrl: './custom-promeclub-added-to-cart-dialog.component.html',
  styleUrls: ['./custom-promeclub-added-to-cart-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomPromeclubAddedToCartDialogComponent
  extends AddedToCartDialogComponent
  implements OnInit, OnDestroy
{
  private errors: Array<string> = [];
  private unsubscribe$ = new Subject<void>();
  private ngbModalElement: HTMLElement;
  suscription: Subscription;
  isInPromeclub: boolean = this.service.isInPromeClub();
  product: CustomProduct;
  isAdded: boolean = false;
  failAdded: boolean = false;
  fromWish: boolean = false;
  addSuccess$: Observable<boolean> =
    this.customPromeclubAddToCartService.getProductAdded();
  loading$: Observable<boolean> =
    this.customPromeclubAddToCartService.getLoading();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    protected modalService: ModalService,
    protected cartService: ActiveCartService,
    protected addToCartErrorsService: AddToCartErrorsService,
    @Inject(DOCUMENT) private document: Document,
    private customDialogCartService: CustomDialogCartService,
    private service: CustomNavigationService,
    private customPromeclubAddToCartService: CustomPromeclubAddToCartService,
    private customBreakpointService: CustomBreakpointService
  ) {
    super(modalService, cartService);
  }

  setModalContentStyle() {
    this.ngbModalElement.style.height = 'fit-content';
    this.ngbModalElement.style.margin = 'auto 0';
  }

  ngOnInit(): void {
    this.addToCartErrorsService
      .getErrors()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((errorMessage) => {
        this.errors.push(errorMessage);
      });

    this.ngbModalElement = <HTMLElement>(
      this.document.getElementsByClassName('modal-content')[0]
    );
    this.setModalContentStyle();

    this.addedEntryWasMerged$ = this.loaded$.pipe(
      filter((loaded) => loaded),
      switchMapTo(this.cartService.getEntries()),
      map((entries) => entries.length === this.numberOfEntriesBeforeAdd)
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.customPromeclubAddToCartService.setProductAdded(false);
  }

  customDismissModal(reason?: any): void {
    this.modalService.dismissActiveModal(reason);
    this.customDialogCartService.onCloseDialogToCart(reason);
  }
}
