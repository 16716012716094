import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { CustomAccountCommentComponent } from './custom-account-comment.component';



@NgModule({
  declarations: [
    CustomAccountCommentComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    ReactiveFormsModule,
  ],
  exports: [
    CustomAccountCommentComponent
  ]
})
export class CustomAccountCommentModule { }
