import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ActiveCartService, AuthActions } from '@spartacus/core';
import { LoginLogoutAction } from '@spartacus/core/src/auth/user-auth/store/actions/login-logout.action';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { CustomTwilioValidatorService } from 'src/app/common/services/custom-twilio-validator.service';
import { CustomCart } from '../../cart/model/custom-cart';
import { CustomQuoteUtilService } from '../../my-account/components/custom-quotes/services/custom-quote-util.service';
import { CustomNavigationService } from '../../navigation/services/custom-navigation.service';
import { CustomUser } from '../models/custom-user.interface';
import { CustomHeaderService } from 'src/app/services/custom-header.service';
import { CustomLoadingSpinnerService } from '../../custom/services/custom-loading-spinner.service';
import { ModalService } from '@spartacus/storefront';

@Injectable()
export class CustomUserEffects {
  @Effect({ dispatch: false })
  CustomUserLogout$: Observable<any> = this.actions$.pipe(
    ofType(AuthActions.LOGOUT),
    map((action) => {
      const isPromeclub: boolean = this.customNavigationService.isInPromeClub();
      if (isPromeclub) {
        window.location.reload();
      }
      this.customLoadingSpinnerService.hideSpinner();
      this.modalService.closeActiveModal();
      this.customHeaderService.resetHeader();
      this.customTwilioValidatorService.resetValidationStatus();
    })
  );

  @Effect({ dispatch: false })
  CustomUserLogin$: Observable<any> = this.actions$.pipe(
    ofType(AuthActions.LOGIN),
    mergeMap((action: LoginLogoutAction) => {
      return this.userService.get().pipe(
        filter((user) => !!user),
        tap((user) => {
          const customUser = user as CustomUser;
          if (action.type === '[Auth] Login') {
            if (!!customUser?.accessDetails?.redirectUri) {
              window.open(customUser?.accessDetails?.redirectUri, '_self');
            }
          }
        })
      );
    })
  );

  @Effect({ dispatch: false })
  CustomUserQuoteLogin$: Observable<any> = this.actions$.pipe(
    ofType(AuthActions.LOGIN),
    mergeMap((action: LoginLogoutAction) => {
      return combineLatest([
        this.activeCartService.getActive(),
        this.activeCartService.isStable(),
      ]).pipe(
        filter(([_, loaded]) => loaded),
        map(([cart]) => {
          if (!window.location.href.includes('cart')) {
            return;
          }
          return this.customQuoteUtilService.handleCustomCartFromQuote(
            cart as CustomCart
          );
        })
      );
    })
  );
  constructor(
    private actions$: Actions,
    private customNavigationService: CustomNavigationService,
    private userService: UserAccountFacade,
    private activeCartService: ActiveCartService,
    private customQuoteUtilService: CustomQuoteUtilService,
    private customTwilioValidatorService: CustomTwilioValidatorService,
    private customHeaderService: CustomHeaderService,
    private customLoadingSpinnerService: CustomLoadingSpinnerService,
    private modalService: ModalService
  ) {}
}
