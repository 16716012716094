import { Pipe, PipeTransform } from '@angular/core';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';

@Pipe({
  name: 'changerText'
})
export class TextChangerPipe implements PipeTransform {
  isInPromeclub: boolean = this.service.isInPromeClub();
  constructor(private service: CustomNavigationService) {
  }

  transform(value: string): any {
    if(value === 'Productos' && this.isInPromeclub){
      let textChanged = value.replace('Productos', 'Premios')
      return textChanged
    } else {
      let firstLetter = value.charAt(0).toUpperCase();
      let otherLetters = value.slice(1).toLowerCase();
      let valueFull = firstLetter + otherLetters
      return valueFull
    }
  }


}
