import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HandlersModule } from './handlers/handlers.module';
import { CmsPageGuardService } from '@spartacus/storefront';
import { CustomCmsPageGuardService } from './services/custom-cms-page-guard.service';
import { CustomBaseSiteEffects } from './store/effects/custom-base-site.effect';
import { EffectsModule } from '@ngrx/effects';
import { CustomPageEffects } from './store/effects/custom-page.effects';

// Module for new "Non-Cms" Components
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HandlersModule,
    EffectsModule.forFeature([CustomBaseSiteEffects, CustomPageEffects]),
  ],
  providers: [
    {
      provide: CmsPageGuardService,
      useClass: CustomCmsPageGuardService,
    },
  ],
})
export class CustomCommonModule {}
