import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';
import { CustomSafeHtmlModule } from '../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomNewsletterComponent } from './custom-newsletter.component';

@NgModule({
  declarations: [CustomNewsletterComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormErrorsModule,
    I18nModule,
    CustomSafeHtmlModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromesaNewsletterComponent: {
          component: CustomNewsletterComponent,
        },
      },
    }),
  ],
})
export class CustomNewsletterModule {}
