import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UrlModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomTitleMyAccountComponent } from './custom-title-my-account.component';



@NgModule({
  declarations: [
    CustomTitleMyAccountComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    UrlModule,
    RouterModule,
  ],
  exports: [
    CustomTitleMyAccountComponent
  ]
})
export class CustomTitleMyAccountModule { }
