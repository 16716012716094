<ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
  <div class="quote-create">
    <div class="quote-create__close">
      <h4 class="quote-create__close-title" *ngIf="data.isMobile">
        {{ "accountQuotes.title" | cxTranslate }}
      </h4>
      <cx-icon (click)="closeModal('close')" [type]="iconTypes.CLOSE"></cx-icon>
    </div>
    <div class="quote-create__icon" *ngIf="!data.isMobile">
      <cx-icon class="fas fa-exclamation-triangle"></cx-icon>
    </div>
    <div
      class="quote-create__message"
      *ngIf="!data.isMobile"
      [innerHTML]="'accountQuotes.create.message' | cxTranslate | safeHtml"
    ></div>
    <div
      class="quote-create__message"
      *ngIf="data.isMobile"
      [innerHTML]="
        'accountQuotes.create.mobileMessage' | cxTranslate | safeHtml
      "
    ></div>
    <div class="quote-create__action">
      <div class="quote-create__action-link" (click)="closeModal('continue')">
        {{ "accountQuotes.create.cancel" | cxTranslate }}
      </div>
      <button class="quote-create__button" (click)="createQuote()">
        {{ "accountQuotes.create.continue" | cxTranslate }}
      </button>
    </div>
  </div>
</ng-container>
