<ng-container *ngIf="subcategories?.length > 0">
  <ng-container *ngIf="!(isMobile$ | async); else mobileCarousel">
    <div class="carousel-container">
      <swiper [config]="swiperConfigDesktop">
        <ng-template swiperSlide *ngFor="let item of subcategories">
          <div class="variant">
            <div
              class="variant-card"
              [ngClass]="{ active: isActiveUrl(item?.code) }"
            >
              <div class="variant-card__image">
                <img [src]="getImage(item?.image?.url)" [alt]="item?.name" />
              </div>
            </div>
            <div class="variant-card__text">
              <a
                class="variant-card__text-value"
                [ngClass]="{ active: isActiveUrl(item?.code) }"
                [routerLink]="item?.url"
              >
                {{ item?.name }}
              </a>
            </div>
          </div>
        </ng-template>
      </swiper>
    </div>

    <ng-template #mobileCarousel>
    <div class="carousel-container">
      <swiper [config]="swiperConfig">
        <ng-template swiperSlide *ngFor="let item of subcategories">
          <div class="variant">
            <div
              class="variant-card"
              [ngClass]="{ active: isActiveUrl(item?.code) }"
            >
              <div class="variant-card__image">
                <img [src]="getImage(item?.image?.url)" [alt]="item?.name" />
              </div>
            </div>
            <div class="variant-card__text">
              <a
                class="variant-card__text-value"
                [ngClass]="{ active: isActiveUrl(item?.code) }"
                [routerLink]="item?.url"
              >
                {{ item?.name }}
              </a>
            </div>
          </div>
        </ng-template>
      </swiper>
    </div>
    </ng-template>
  </ng-container>
</ng-container>
