<div class="filter-price-facets" *ngIf="isUserLoggedIn$ | async">
  <label class="filter-price-facets__title" *ngIf="facet.name === 'Precio'">{{
    "filterPrice.labelFilterPrice" | cxTranslate
  }}</label>
  <label class="filter-price-facets__title" *ngIf="facet.name === 'Puntos'"
    >{{
      (isMobile
        ? "filterPrice.labelFilterPointsMobile"
        : "filterPrice.labelFilterPoints"
      ) | cxTranslate
    }}
    <cx-icon
      *ngIf="isMobile"
      [class]="(showFacet$ | async) ? 'fas fa-minus' : 'fas fa-plus'"
      (click)="toggleFacet()"
    ></cx-icon
  ></label>
  <ng-container *ngIf="data$ | async as data">
    <app-range-slider
      *ngIf="facet.name === 'Precio' || facet.name === 'Puntos'"
      [facet]="facet"
      [min]="data.statsInfo.min"
      [max]="data.statsInfo.max"
      [name]="data.statsInfo.name"
      [startValues]="[min, max]"
      [ngClass]="{ hide: !(showFacet$ | async) }"
    ></app-range-slider>
  </ng-container>
</div>
