import { NgModule } from '@angular/core';
import { CustomAdaptersModule } from './custom-adapters/custom-adapters.module';



@NgModule({
  imports: [
    CustomAdaptersModule
  ]
})
export class CustomOccModule { }
