import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ICON_TYPE, ModalService } from '@spartacus/storefront';
import { CustomQuotesService } from '../../services/custom-quotes.service';
import { CustomQuoteOrchestratorService } from '../../services/custom-quote-orchestrator.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-custom-quote-reject-dialog',
  templateUrl: './custom-quote-reject-dialog.component.html',
  styleUrls: ['./custom-quote-reject-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomQuoteRejectDialogComponent implements OnDestroy {
  code: string;
  iconTypes = ICON_TYPE;
  comment: FormControl = this.fb.control('', []);
  unsubscribe$: Subject<void> = new Subject();
  constructor(
    private modalService: ModalService,
    private fb: FormBuilder,
    private customQuotesService: CustomQuotesService,
    private customQuoteOrchestrator: CustomQuoteOrchestratorService
  ) {}

  closeModal(reason: string) {
    this.modalService.closeActiveModal(reason);
  }

  submitComment() {
    let quoteData = {
      accept: false,
      comments: this.comment.value,
    };
    this.customQuotesService
      .acceptRejectQuoteProposal(this.code, quoteData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() =>
        this.customQuoteOrchestrator.setRefreshQuoteDetail(true)
      );
    this.closeModal('quotes proposal rejected');
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
