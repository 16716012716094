import { Injectable } from '@angular/core';
import { SavedCartDetailsService } from '@spartacus/cart/saved-cart/components';
import {
  BreadcrumbMeta,
  CmsService,
  TranslationService,
} from '@spartacus/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  tap,
} from 'rxjs/operators';
import { CustomCategoryPageMetaResolver } from 'src/app/meta-resolvers/custom-category-page-meta.resolver';
import { CustomProductPageMetaResolver } from 'src/app/meta-resolvers/custom-product-page-meta.resolver';
import { CustomBaseSiteCheckerService } from '../../../services/custom-base-site-checker.service';
import { CustomQuoteOrchestratorService } from '../../my-account/components/custom-quotes/services/custom-quote-orchestrator.service';
import { CustomPromeclubAccountStatusService } from '../../my-account/services/custom-promeclub-account-status.service';
import { PromesaSection } from 'src/app/common/models/promesa-section';
import { CustomCategoryPageBreadcrumbService } from './custom-category-page-breadcrumb.service';

@Injectable({
  providedIn: 'root',
})
export class CustomBreadcrumbService {
  title: Observable<BreadcrumbMeta[]> | undefined;
  subtitleOrder: string;
  emptySearchPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  zeroResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  categoryPage$: BehaviorSubject<BreadcrumbMeta[]> = new BehaviorSubject<
    BreadcrumbMeta[]
  >([]);
  PROMESA_SECTION = PromesaSection;
  constructor(
    private savedCartDetailsService: SavedCartDetailsService,
    private cmsService: CmsService,
    private customCategoryPageMetaResolver: CustomCategoryPageMetaResolver,
    private translation: TranslationService,
    private customProductsPageMetaResolver: CustomProductPageMetaResolver,
    private customQuoteOrchestrator: CustomQuoteOrchestratorService,
    protected orderDetailsService: OrderDetailsService,
    protected customPromeclubAccountStatusService: CustomPromeclubAccountStatusService,
    protected customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    protected customCategoryPageBreadcrumbService: CustomCategoryPageBreadcrumbService
  ) {}

  resolvePrincipalTitle(): Observable<Observable<string> | string> {
    return this.cmsService.getCurrentPage().pipe(
      filter((page) => !!page?.template),
      distinctUntilChanged(),
      pluck('template'),
      delay(500),
      map((template) => this.resolveTitleBreadcrumb(template!))
    );
  }

  resolveTitleBreadcrumb(template: string): Observable<string> | string {
    this.title = undefined;
    if (
      template === 'ProductDetailsPageTemplate' ||
      template === 'ProductGridPageTemplate' ||
      template === 'ProductListPageTemplate'
    ) {
      this.title = this.categoryPage$.asObservable();
    } else if (template === 'SearchResultsListPageTemplate') {
      this.title = this.resolveSearchBreadcrumb();
    } else if (template === 'CartPageTemplate') {
      return this.translation.translate('cartDetails.title');
    } else if (template === 'MultiStepCheckoutSummaryPageTemplate') {
      return this.translation.translate('cartDetails.proceedToCheckout');
    } else if (template === 'AccountPageTemplate') {
      return this.translation.translate('customMyAccount.breadcrumb');
    }
    return this.title !== undefined
      ? this.title.pipe(
          map((breadcrumb) => breadcrumb[breadcrumb.length - 1].label)
        )
      : '';
  }

  resolveSearchBreadcrumb(): Observable<BreadcrumbMeta[]> {
    return combineLatest([
      this.customCategoryPageMetaResolver.getSearchTerm(),
      this.translation.translate('searchPageBreadcrumb.home'),
      this.translation.translate('searchPageBreadcrumb.search'),
    ]).pipe(
      map(([term, homeTranslate, searchTranslate]) => {
        let crumbs: BreadcrumbMeta[];
        if (!!term) {
          crumbs = [
            { label: homeTranslate, link: this.homeLinkBySection() },
            { label: searchTranslate, link: '' },
            { label: term, link: '' },
          ];
        } else {
          crumbs = [
            { label: homeTranslate, link: this.homeLinkBySection() },
            { label: searchTranslate, link: '' },
          ];
        }
        return crumbs;
      })
    );
  }

  resolveCartPageBreadcrumb(): Observable<BreadcrumbMeta[]> {
    return combineLatest([
      this.translation.translate('common.home'),
      this.translation.translate('common.cart'),
    ]).pipe(
      map(([homeTranslate, cartTranslate]) => {
        let crumbs: BreadcrumbMeta[] = [
          { label: homeTranslate, link: this.homeLinkBySection() },
          { label: cartTranslate, link: '' },
        ];

        return crumbs;
      })
    );
  }
  resolveCheckoutPageBreadcrumb(): Observable<BreadcrumbMeta[]> {
    return combineLatest([
      this.translation.translate('common.home'),
      this.translation.translate('common.cart'),
      this.translation.translate('cartDetails.proceedToCheckout'),
    ]).pipe(
      map(([homeTranslate, cartTranslate, checkoutTranslate]) => {
        let crumbs: BreadcrumbMeta[] = [
          { label: homeTranslate, link: this.homeLinkBySection() },
          { label: cartTranslate, link: 'cart' },
          { label: checkoutTranslate, link: '' },
        ];

        return crumbs;
      })
    );
  }
  resolveAccountOrderDetailsBreadcrumb(
    title: string | null = null
  ): Observable<BreadcrumbMeta[]> {
    const isPromeclub = this.customBaseSiteCheckerService.isInPromeClub();
    return combineLatest([
      this.translation.translate('common.home'),
      this.translation.translate('customMyAccount.breadcrumb'),
      this.translation.translate('orderHistory.orderHistory'),
      isPromeclub
        ? this.customPromeclubAccountStatusService.getOrderCode()
        : this.orderDetailsService.getOrderDetails().pipe(pluck('code')),
    ]).pipe(
      map(([homeTranslate, myAccountTranslate, historyTitle, order]) => {
        let crumbs: BreadcrumbMeta[] = [
          { label: homeTranslate, link: this.homeLinkBySection() },
          { label: myAccountTranslate, link: '/my-account/orders' },
        ];
        if (title !== null) {
          crumbs.push({
            label: isPromeclub ? title : historyTitle,
            link: '/my-account/orders',
          });
        }
        if (order) {
          const label = isPromeclub ? `Transacción ${order}` : `Orden ${order}`;
          crumbs.push({
            label: label,
            link: '',
          });
        }
        return crumbs;
      })
    );
  }

  resolveAccountPageBreadcrumb(
    title: string | null = null
  ): Observable<BreadcrumbMeta[]> {
    const isPromeclub = this.customBaseSiteCheckerService.isInPromeClub();
    return combineLatest([
      this.translation.translate('common.home'),
      this.translation.translate('customMyAccount.breadcrumb'),
    ]).pipe(
      map(([homeTranslate, myAccountTranslate]) => {
        let crumbs: BreadcrumbMeta[] = [
          { label: homeTranslate, link: this.homeLinkBySection() },
          { label: myAccountTranslate, link: '/my-account/orders' },
        ];
        if (title !== null) {
          let promeclubTitle = title.toLowerCase();
          promeclubTitle =
            promeclubTitle[0].replace(
              promeclubTitle[0],
              promeclubTitle[0].toUpperCase()
            ) + promeclubTitle.slice(1, promeclubTitle.length);
          crumbs.push({
            label: isPromeclub ? promeclubTitle : title,
            link: '',
          });
        }
        return crumbs;
      })
    );
  }

  resolvePaymentRecordPage(
    title: string | null = null
  ): Observable<BreadcrumbMeta[]> {
    const isPromeclub = this.customBaseSiteCheckerService.isInPromeClub();
    return combineLatest([
      this.translation.translate('common.home'),
      this.translation.translate('customMyAccount.breadcrumb'),
      this.translation.translate('orderHistory.orderHistory'),
      this.translation.translate('orderDetails.registerPaid.registerAction'),
    ]).pipe(
      map(
        ([homeTranslate, myAccountTranslate, orderHistory, PaymentRecord]) => {
          let crumbs: BreadcrumbMeta[] = [
            { label: homeTranslate, link: this.homeLinkBySection() },
            { label: myAccountTranslate, link: '/my-account/orders' },
            { label: orderHistory, link: '/my-account/orders' },
            { label: PaymentRecord, link: '' },
          ];
          if (title !== null) {
            let promeclubTitle = title.toLowerCase();
            promeclubTitle =
              promeclubTitle[0].replace(
                promeclubTitle[0],
                promeclubTitle[0].toUpperCase()
              ) + promeclubTitle.slice(1, promeclubTitle.length);
            crumbs.push({
              label: isPromeclub ? promeclubTitle : title,
              link: '',
            });
          }
          return crumbs;
        }
      )
    );
  }

  resolveWishlistPageBreadcrumb(): Observable<BreadcrumbMeta[]> {
    return combineLatest([
      this.translation.translate('common.home'),
      this.translation.translate('customWishlist.breadTitle'),
    ]).pipe(
      map(([homeTranslate, myAccountTranslate]) => {
        let crumbs: BreadcrumbMeta[] = [
          { label: homeTranslate, link: this.homeLinkBySection() },
          { label: myAccountTranslate, link: this.homeLinkBySection() },
        ];
        return crumbs;
      })
    );
  }

  resolveErrorPageBreadcrumb(title: string): Observable<BreadcrumbMeta[]> {
    return this.translation.translate('common.home').pipe(
      map((homeTranslate) => {
        let crumbs: BreadcrumbMeta[] = [
          { label: homeTranslate, link: this.homeLinkBySection() },
          {
            label: title?.includes('Landing')
              ? title?.replace('Landing ', '')
              : title,
            link: this.homeLinkBySection(),
          },
        ];
        return crumbs;
      })
    );
  }

  resolveQuoteDetailBreadcrumb(title: string): Observable<BreadcrumbMeta[]> {
    return combineLatest([
      this.translation.translate('common.home'),
      this.customQuoteOrchestrator.getInQuoteDetail(),
      this.customQuoteOrchestrator.getInQuoteOffer(),
      this.translation.translate('customMyAccount.breadcrumb'),
      this.translation.translate('accountQuotes.detailBreadcrumb'),
      this.resolveAccountPageBreadcrumb(title),
    ]).pipe(
      map(
        ([
          homeTranslate,
          inDetail,
          inOffer,
          myAccountTranslate,
          quoteDetailTranslate,
          accountPageBreadcrumb,
        ]) => {
          if (!inDetail && !inOffer) {
            return accountPageBreadcrumb;
          }
          let crumbs: BreadcrumbMeta[] = [
            { label: homeTranslate, link: this.homeLinkBySection() },
            { label: myAccountTranslate, link: '/my-account/orders' },
            {
              label: inDetail ? quoteDetailTranslate : 'Propuesta pendiente',
              link: '',
            },
          ];
          return crumbs;
        }
      )
    );
  }

  resolveShoppingListPage(
    title: string | null = null
  ): Observable<BreadcrumbMeta[]> {
    const isPromeclub = this.customBaseSiteCheckerService.isInPromeClub();
    return combineLatest([
      this.translation.translate('common.home'),
      this.translation.translate('customMyAccount.breadcrumb'),
      this.translation.translate('shoppingList.title'),
    ]).pipe(
      map(([homeTranslate, myAccountTranslate, shoppingListTranslate]) => {
        let crumbs: BreadcrumbMeta[] = [
          { label: homeTranslate, link: this.homeLinkBySection() },
          { label: myAccountTranslate, link: '/my-account/orders' },
          { label: shoppingListTranslate, link: '/my-account/shoppingList' },
        ];

        if (title !== null) {
          let promeclubTitle = title.toLowerCase();
          promeclubTitle =
            promeclubTitle[0].replace(
              promeclubTitle[0],
              promeclubTitle[0].toUpperCase()
            ) + promeclubTitle.slice(1, promeclubTitle.length);
          crumbs.push({
            label: isPromeclub ? promeclubTitle : title,
            link: '',
          });
        }
        return crumbs;
      })
    );
  }

  resolveSavedCartDetailsPage(
    title: string | null = null
  ): Observable<BreadcrumbMeta[]> {
    const isPromeclub = this.customBaseSiteCheckerService.isInPromeClub();
    return combineLatest([
      this.translation.translate('common.home'),
      this.translation.translate('customMyAccount.breadcrumb'),
      this.translation.translate('shoppingList.title'),
      this.savedCartDetailsService.getCartDetails().pipe(pluck('name')),
    ]).pipe(
      map(
        ([
          homeTranslate,
          myAccountTranslate,
          shoppingListTranslate,
          savedCart,
        ]) => {
          let crumbs: BreadcrumbMeta[] = [
            { label: homeTranslate, link: this.homeLinkBySection() },
            { label: myAccountTranslate, link: '/my-account/orders' },
            { label: shoppingListTranslate, link: '/my-account/shopping-list' },
          ];

          if (title !== null) {
            let promeclubTitle = title.toLowerCase();
            promeclubTitle =
              promeclubTitle[0].replace(
                promeclubTitle[0],
                promeclubTitle[0].toUpperCase()
              ) + promeclubTitle.slice(1, promeclubTitle.length);
            crumbs.push({
              label: isPromeclub ? promeclubTitle : title,
              link: '',
            });
          }
          if (savedCart) {
            if (savedCart) {
              const label = `${savedCart}`;
              crumbs.push({
                label: label,
                link: '',
              });
            }
          }
          return crumbs;
        }
      )
    );
  }

  getCurrentPage(): Observable<Observable<
    BreadcrumbMeta[] | undefined
  > | null> {
    return this.cmsService.getCurrentPage().pipe(
      distinctUntilChanged(),
      map((data) => {
        if (data?.template === 'SearchResultsListPageTemplate') {
          return this.resolveSearchBreadcrumb();
        } else if (data?.template === 'ProductDetailsPageTemplate') {
          return this.customProductsPageMetaResolver.resolveBreadcrumbs();
        } else if (data?.template === 'CartPageTemplate') {
          return this.resolveCartPageBreadcrumb();
        } else if (data?.template === 'MultiStepCheckoutSummaryPageTemplate') {
          return this.resolveCheckoutPageBreadcrumb();
        } else if (data?.template === 'AccountPageTemplate') {
          if (data?.pageId == 'order') {
            return this.resolveAccountOrderDetailsBreadcrumb(data?.title);
          } else if (data?.pageId == 'paymentRecordPage') {
            return this.resolvePaymentRecordPage(data?.title);
          } else if (data?.pageId == 'WishListPage') {
            return this.resolveWishlistPageBreadcrumb();
          } else if (data?.pageId == 'QuoteDetailOrderPage') {
            return this.resolveQuoteDetailBreadcrumb(data?.title!);
          } else if (data?.pageId == 'saved-carts') {
            return this.resolveShoppingListPage();
          } else if (data?.pageId == 'savedCartDetailsPage') {
            return this.resolveSavedCartDetailsPage();
          } else {
            return this.resolveAccountPageBreadcrumb(data?.title);
          }
        } else if (data?.template === 'ErrorPageTemplate') {
          return this.resolveErrorPageBreadcrumb(data?.title!);
        } else if (data?.template === 'ContentPage1Template') {
          return this.resolveErrorPageBreadcrumb(data?.title!);
        }
        return this.customCategoryPageBreadcrumbService
          .resolveCategoryPageBreadcrumbs(data)
          .pipe(tap((news) => this.categoryPage$.next(news)));
      })
    );
  }

  setEmptySearchPage(value: boolean): void {
    this.emptySearchPage$.next(value);
  }
  getEmptySearchPage(): Observable<boolean> {
    return this.emptySearchPage$.asObservable();
  }

  setZeroResults(value: boolean): void {
    this.zeroResults$.next(value);
  }

  getZeroResults(): Observable<boolean> {
    return this.zeroResults$.asObservable();
  }

  homeLinkBySection(): string {
    return this.customProductsPageMetaResolver.homeBySection();
  }
}
