import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ItemCounterComponent } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-custom-item-counter',
  templateUrl: './custom-item-counter.component.html',
  styleUrls: ['./custom-item-counter.component.scss'],
})
export class CustomItemCounterComponent
  extends ItemCounterComponent
  implements OnInit
{
  controlSubscription: Subscription;
  @Input() productStockLevel: number;
  @Input() blurEvent: boolean = false;
  @Output() blurCounter = new EventEmitter<any>();
  @Input() fromProductVariantPlpDesktop: boolean = false;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.control.addValidators([this.notEnoughStock(this.productStockLevel)]);
    this.controlSubscription = this.control.valueChanges
      .pipe(startWith(this.control.value))
      .subscribe((value) => {
        this.control.setValue(this.customGetValidCount(value), {
          emitEvent: false,
        });
      });
  }

  customGetValidCount(value: number) {
    if (this.fromProductVariantPlpDesktop) {
      return value;
    }
    if (value < this.min && !(value === 0 && this.allowZero)) {
      value = this.min;
    }
    return value;
  }

  notEnoughStock(stock: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value > stock) {
        return { notEnoughStock: true };
      }
      return null;
    };
  }
  onBlurCounter() {
    this.blurCounter.emit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.controlSubscription.unsubscribe();
  }
}
