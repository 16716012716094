import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@spartacus/storefront';
import { CustomRegisterStepOrchestratorService } from '../../services/custom-register-step-orchestrator.service';

@Component({
  selector: 'app-custom-customer-register-successful',
  templateUrl: './custom-customer-register-successful.component.html',
  styleUrls: ['./custom-customer-register-successful.component.scss'],
})
export class CustomCustomerRegisterSuccessfulComponent {
  email: string;
  closedModal: boolean = false;
  isRegisterEmployee: boolean = false;
  constructor(
    private modalService: ModalService,
    private router: Router,
    private customRegisterStepOrchestratorService: CustomRegisterStepOrchestratorService
  ) {}

  closeModal(reason: string) {
    this.closedModal = true;
    if (reason == 'close') {
      this.router.navigateByUrl('/').then((navigate: boolean) => {
        if (navigate) {
          window.location.reload();
        }
      });
      this.customRegisterStepOrchestratorService.setStepThreeStatus(false);
      this.customRegisterStepOrchestratorService.setStepTwoStatus(false);
      this.customRegisterStepOrchestratorService.setStepOneStatus(true);
    }
    this.modalService.closeActiveModal(reason);
  }

  ngOnDestroy(): void {
    if (!this.closedModal) {
      this.customRegisterStepOrchestratorService.setStepThreeStatus(false);
      this.customRegisterStepOrchestratorService.setStepTwoStatus(false);
      this.customRegisterStepOrchestratorService.setStepOneStatus(true);
      this.router.navigateByUrl('/').then((navigate: boolean) => {
        if (navigate) {
          window.location.reload();
        }
      });
    }
  }
}
