<ng-container *ngIf="order">
  <div #dialog>
    <app-custom-modal-generic
      (closeModal)="
        isGenerateClaim
          ? acceptGenerateClaim($event)
          : customDismissModal($event)
      "
      [isClose]="true"
    ></app-custom-modal-generic>

    <ng-container>
      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body">
        <span *ngIf="!isGenerateClaim" aria-hidden="true">
          <cx-icon [type]="'EXCLAMATION_TRIANGLE'"></cx-icon>
        </span>
        <div class="cx-dialog-row">
          <!-- Total container -->
          <span *ngIf="isGenerateClaim">{{
            "orderDetails.claim.detailSuccess"
              | cxTranslate : { orderCode: order.id }
          }}</span>

          <div *ngIf="!isGenerateClaim">
            {{ "orderDetails.claim.deleteOrder" | cxTranslate
            }}<span>{{
              "orderDetails.claim.detailOrderCode"
                | cxTranslate : { orderCode: "reclamo " + order.id }
            }}</span>
          </div>
          <div class="cx-dialog-actions">
            <!-- Actions -->
            <div *ngIf="isGenerateClaim" class="cx-dialog-buttons">
              <button
                (click)="acceptGenerateClaim($event)"
                class="button btn btn-primary"
              >
                {{ "common.continue" | cxTranslate }}
              </button>
            </div>
            <div *ngIf="!isGenerateClaim" class="cx-dialog-buttons">
              <button
                (click)="deleteClaim(order.id, $event)"
                class="button btn btn-secondary"
              >
                {{ "common.delete" | cxTranslate }}
              </button>
              <button
                (click)="customDismissModal($event)"
                class="button btn btn-primary"
              >
                {{ "common.cancel" | cxTranslate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
