import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { CustomCreditInfo } from 'src/app/feature-libs/checkout/models/custom-credit-info.interface';
import { CustomPaymentConditionsService } from 'src/app/feature-libs/checkout/services/custom-payment-conditions.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-credit-line-dialog',
  templateUrl: './custom-credit-line-dialog.component.html',
  styleUrls: ['./custom-credit-line-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomCreditLineDialogComponent implements OnInit {
  private ngbModalElement: HTMLElement;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  creditInfo$: Observable<CustomCreditInfo>;
  @Input() user: string;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    protected modalService: ModalService,
    private customBreakpointService: CustomBreakpointService,
    protected userAccountFacade: UserAccountFacade,
    private customPaymentConditionsService: CustomPaymentConditionsService
  ) { }

  ngOnInit(): void {
    this.ngbModalElement = <HTMLElement>(
      this.document.getElementsByClassName('modal-content')[0]
    );

    this.creditInfo$ = this.customPaymentConditionsService.getCreditInfoWithoutCart(this.user)

  }

}
