import { Component } from '@angular/core';
import { ModalService } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-register-error-dialog',
  templateUrl: './custom-register-error-dialog.component.html',
  styleUrls: ['./custom-register-error-dialog.component.scss'],
})
export class CustomRegisterErrorDialogComponent {
  constructor(private modalService: ModalService) {}

  closeModal(reason: string) {
    this.modalService.dismissActiveModal(reason);
  }
}
