import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ModalService } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-order-cancel-dialog',
  templateUrl: './custom-order-cancel-dialog.component.html',
  styleUrls: ['./custom-order-cancel-dialog.component.scss'],
})
export class CustomOrderCancelDialogComponent implements OnInit {
  private ngbModalElement: HTMLElement;
  orderCode: string;
  @ViewChild('dialogOrderCancel', { read: ElementRef })
  dialogOrderCancel: ElementRef;
  constructor(
    protected modalService: ModalService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.ngbModalElement = <HTMLElement>(
      this.document.getElementsByClassName('modal-content')[0]
    );
  }
}
