import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomBreadcrumbModule } from './components/custom-breadcrumb/custom-breadcrumb.module';
import { CustomCategoryNavigationModule } from './components/custom-category-navigation/custom-category-navigation.module';
import { CustomFooterNavigationModule } from './components/custom-footer-navigation/custom-footer-navigation.module';
import { CustomSearchboxModule } from './components/custom-searchbox/custom-searchbox.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CustomSearchboxModule,
    CustomFooterNavigationModule,
    CustomCategoryNavigationModule,
    CustomBreadcrumbModule,
  ]
})
export class NavigationModule { }
