import { Component, ElementRef, HostBinding, HostListener, OnInit, ViewChild } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { SkipLinkComponent, FocusConfig, HamburgerMenuService, KeyboardFocusService } from '@spartacus/storefront';
import { Subscription, Observable } from 'rxjs';

@Component({
  selector: 'app-outlet-header',
  templateUrl: './outlet-header.component.html',
  styleUrls: ['./outlet-header.component.scss']
})
export class OutletHeaderComponent implements OnInit {
  navigateSubscription: Subscription;
  isExpanded$: Observable<boolean> = this.hamburgerMenuService.isExpanded;
  logoPromesa: string = '../../assets/icons/logo_promesa.svg';
  private keyboardFocusConfig: FocusConfig = {
    focusOnEscape: true,
    focusOnDoubleEscape: true,
  };

  @HostListener('keydown.escape', ['$event'])
  handleEscape(event: KeyboardEvent): void {
    this.keyboardFocusService.handleEscape(
      this.elementRef.nativeElement,
      this.keyboardFocusConfig,
      event
    );
  }
  constructor(
    private hamburgerMenuService: HamburgerMenuService,
    protected elementRef: ElementRef<HTMLElement>,
    protected keyboardFocusService: KeyboardFocusService
  ) { }

  ngOnInit(): void {

  }

  collapseMenuIfClickOutside(event: any): void {
    const element = event.target;
    if (
      element.nodeName.toLowerCase() === 'header' &&
      element.className.includes('is-expanded')
    ) {
      this.collapseMenu();
    }
  }

  collapseMenu(): void {
    this.hamburgerMenuService.toggle(true);
  }

  ngOnDestroy(): void {
    if (this.navigateSubscription) {
      this.navigateSubscription.unsubscribe();
    }
  }

}
