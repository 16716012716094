import { Component, OnInit } from '@angular/core';
import {
  AuthService,
  CmsService,
  Page,
  TranslationService,
  WindowRef,
  isNotNullable,
} from '@spartacus/core';
import {
  CurrentProductService,
  ProductIntroComponent,
} from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { filter, map, pluck, switchMap, tap } from 'rxjs/operators';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { ECOVALOR } from '../../constants/product-type';
import { Router } from '@angular/router';
import { CustomPromeclubPointsService } from 'src/app/cms-components/custom/components/custom-promeclub-product-points/services/custom-promeclub-product-points.service';

@Component({
  selector: 'app-custom-product-intro',
  templateUrl: './custom-product-intro.component.html',
  styleUrls: ['./custom-product-intro.component.scss'],
})
export class CustomProductIntroComponent
  extends ProductIntroComponent
  implements OnInit
{
  isMobile$: Observable<boolean>;
  isInPromeclub: boolean = this.service.isInPromeClub();
  template$: Observable<Page>;
  isUserLoggedIn$: Observable<boolean> = this.auth.isUserLoggedIn();
  userId$: Observable<string> = this.userAccountFacade
    .get()
    .pipe(map((user) => user?.uid!));
  ECOVALOR: string = ECOVALOR;
  currentUrl: string;
  points$: Observable<number>;
  constructor(
    protected currentProductService: CurrentProductService,
    translationService: TranslationService,
    protected winRef: WindowRef,
    private customBreakpointService: CustomBreakpointService,
    private cmsService: CmsService,
    private auth: AuthService,
    private userAccountFacade: UserAccountFacade,
    private service: CustomNavigationService,
    private router: Router,
    private customPromeclubPointsService: CustomPromeclubPointsService
  ) {
    super(currentProductService, translationService, winRef);
  }

  ngOnInit(): void {
    this.isMobile$ = this.customBreakpointService.isMobile$;
    this.template$ = this.cmsService.getCurrentPage();
    this.currentUrl = this.router.url;
    window.scrollTo(0, 0);
    this.product$ = this.currentProductService
      .getProduct()
      .pipe(filter(isNotNullable));
    this.points$ = this.product$.pipe(
      switchMap((product) =>
        this.customPromeclubPointsService.getPointsByProductCode(product.code!)
      ),
      pluck('points')
    );
    this.points$.subscribe((points) =>
      this.customPromeclubPointsService.setPoints(points)
    );
  }
}
