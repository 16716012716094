<div class="search-facets-container">
  <cx-icon type="SEARCH"></cx-icon>
  <input
    #txtInput
    type="text"
    [placeholder]="placeholder"
    [attr.aria-label]="ariaLabel"
    autocomplete="off"
    aria-describedby="initialDescription"
    aria-controls="results"
    (keyup)="inputText(txtInput.value)"
  />
  <button [attr.aria-label]="ariaLabelBtn" (click)="inputText(txtInput.value)">
    <cx-icon class="fas fa-chevron-right"></cx-icon>
  </button>
</div>
