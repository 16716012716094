<ng-container *ngIf="{product: product$ | async, isMobile: isMobile$ | async} as data">
  <form [formGroup]="addReviewForm" (ngSubmit)="addReview(data?.product)">
    <div class="modal-container">
      <app-custom-modal-generic *ngIf="!data?.isMobile" (closeModal)="closePopup()"
        [isClose]="true"></app-custom-modal-generic>
      <app-custom-modal-generic *ngIf="data?.isMobile" (closeModal)="closePopup()" [isClose]="false" [isPopUpV2]="true"
        [titlePopUp]="'productReview.addReviewMobile' | cxTranslate"></app-custom-modal-generic>
      <div class="modal-container-body">
        <ng-container *ngIf="!data?.isMobile">
          <div class="modal-container-body__title" [innerHTML]="'productReview.addReview' | cxTranslate | safeHtml">
          </div>
        </ng-container>

        <div class="modal-container-body__product-card">
          <app-custom-product-card-review [product]="data?.product"
            [isMobile]="data?.isMobile"></app-custom-product-card-review>
        </div>
        <div class="modal-container-body__valoration">
          <p>{{ "productReview.valoration" | cxTranslate }}</p>
          <input type="number" formControlName="rating" min="1" max="5" style="display: none" />
          <div class="modal-container-body__valoration__score">
            <app-custom-star-rating
              [disabled]="false"
              [rating]="0"
              (change)="setRating($event)"
            ></app-custom-star-rating>
            <cx-form-errors
              aria-live="assertive"
              aria-atomic="true"
              [control]="addReviewForm.get('rating')"
              prefix="rating"
              *ngIf="isSubmit"
            ></cx-form-errors>
          </div>
        </div>
        <div class="modal-container-body__comment">
          <p>{{ "productReview.productExperience" | cxTranslate }}</p>
          <textarea class="form-control" placeholder="{{ 'productReview.placeholderComment' | cxTranslate }}" autofocus="false" formControlName="comment"></textarea>
          <cx-form-errors aria-live="assertive" aria-atomic="true" [control]="addReviewForm.get('comment')"
            prefix="rattingComment" *ngIf="isSubmit"></cx-form-errors>
        </div>
        <div class="modal-container-body__actions">

          <button type="button" class="btn modal-container-body__actions-cancel" (click)="closePopup()"
            *ngIf="!data?.isMobile">
            <span>{{ "common.cancel" | cxTranslate }}</span>
          </button>
          <button class="btn btn-primary" type="submit">
            <span>{{ "productReview.sendReview" | cxTranslate }}</span>
          </button>
          <a class="btn btn-secondary" (click)="closePopup($event)" *ngIf="data?.isMobile">
            <span>{{ "common.cancel" | cxTranslate }}</span>
          </a>
        </div>

      </div>
    </div>
  </form>
</ng-container>
