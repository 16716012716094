import { Component, ViewEncapsulation } from '@angular/core';
import {
  PaginationModel,
  ReturnRequestList,
  RoutingService,
  TranslationService,
} from '@spartacus/core';
import { OrderReturnRequestListComponent } from '@spartacus/order/components';
import { OrderReturnRequestService } from '@spartacus/order/core';
import { OrderReturnRequestFacade } from '@spartacus/order/root';
import { ICON_TYPE } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomOrderReturnService } from '../../services/custom-order-return.service';

@Component({
  selector: 'app-custom-return-order-list',
  templateUrl: './custom-return-order-list.component.html',
  styleUrls: ['./custom-return-order-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomReturnOrderListComponent extends OrderReturnRequestListComponent {
  iconTypes = ICON_TYPE;
  selectedPage: number = 1;
  quantities: any[] = [
    { id: 25, name: 25, disabled: true },
    { id: 50, name: 50, disabled: true },
    { id: 100, name: 100, disabled: true },
    { id: 200, name: 200, disabled: true },
    { id: 10000, name: 'Todos', disabled: true },
  ];

  pageSize: number = 10000;
  customCurrentPage: number;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    private routing: RoutingService,
    private customBreakpointService: CustomBreakpointService,
    returnRequestService: OrderReturnRequestFacade,
    translation: TranslationService,
    private orderReturnRequestService: OrderReturnRequestService,
    private customOrderReturnService: CustomOrderReturnService
  ) {
    super(returnRequestService, translation);
  }

  returnRequests$: Observable<ReturnRequestList | undefined> =
    this.orderReturnRequestService
      .getOrderReturnRequestList(this.pageSize)
      .pipe(
        tap((requestList: ReturnRequestList | undefined) => {
          if (requestList?.pagination?.sort) {
            this.sortType = requestList.pagination.sort;
          }
        })
      );

  goToOrderDetail(event: any) {
    this.routing.go({
      cxRoute: 'returnOrderDetail',
      params: { code: event.code },
    });
  }
  customPageChangeSelected(page: number, pagination?: PaginationModel): void {
    this.selectedPage = page;
    this.pageChange(page);
    this.returnRequests$ = this.customOrderReturnService.getOrdersReturnFilter(
      this.pageSize,
      page
    );
  }

  filterQuantitySelected(filter: any) {
    this.pageSize = filter;
    this.selectedPage = 0;
    this.returnRequests$ = this.customOrderReturnService.getOrdersReturnFilter(
      filter,
      0
    );
  }
}
