<ng-container *ngIf="order$ | async as order">
  <ng-container *ngIf="order.returnable == true">
    <app-custom-title-my-account
      [title]="
        currentPage == 'EDIT_ORDER_RETURN'
          ? ('returnRequest.newDevolution' | cxTranslate)
          : ('returnRequest.revisionDevolution' | cxTranslate)
      "
      [isBackActive]="true"
      (onBackAction)="onBackEditReturn()"
    ></app-custom-title-my-account>

    <p
      class="custom-return-order__subtitle"
      *ngIf="currentPage == 'REVISION_ORDER_RETURN'"
    >
      {{ "returnRequest.subtitleRevision" | cxTranslate }}
    </p>

    <app-custom-order-overview [order]="order"></app-custom-order-overview>
    <div class="custom-return-products">
      <ng-container>
        <app-custom-return-order-header-detail
          [currentPage]="currentPage"
          (nextPage)="sendOrderDevolution($event)"
          (confirmOrder)="getOrderReturn($event)"
        ></app-custom-return-order-header-detail>
      </ng-container>
    </div>

    <app-custom-account-comment
      [label]="
        currentPage == 'EDIT_ORDER_RETURN'
          ? ('returnRequest.addComment' | cxTranslate)
          : ('returnRequest.commentDevolution' | cxTranslate)
      "
      [placeholder]="'returnRequest.placeholderComment' | cxTranslate"
      (sendComment)="commentReturnOrder($event)"
      [valueDefault]="commentForm"
      [currentPage]="currentPage"
    ></app-custom-account-comment>

    <app-custom-return-order-actions
      *ngIf="order.code && currentPage == 'EDIT_ORDER_RETURN'"
      [orderCode]="order.code"
      (onBackAction)="onBackEditReturn()"
      [isEnabled]="sendDataEnabled"
      currentPage="EDIT_ORDER_RETURN"
      (goToRevisionOrder)="goDevolutionConfirm()"
    ></app-custom-return-order-actions>

    <app-custom-return-order-actions
      *ngIf="order.code && currentPage == 'REVISION_ORDER_RETURN'"
      [orderCode]="order.code"
      (onBackAction)="onBackEditReturn()"
      [forwardRoute]="'returnOrderDetail'"
      [isEnabled]="sendDataEnabled"
      currentPage="REVISION_ORDER_RETURN"
      (confirmOrder)="setOrderReturn()"
    ></app-custom-return-order-actions>
  </ng-container>
</ng-container>
