<ng-container
  *ngIf="{
    product: product$ | async,
    isMobile: isMobile$ | async,
    showMore: showMore$ | async,
    addToggler: addToggler$ | async,
    addTermsToggler: addTermsToggler$ | async,
    showMoreTerms: showMoreTerms$ | async,
    isPromeclub: isPromeclub$ | async
  } as summary"
>
  <ng-container *ngIf="summary?.isMobile">
    <!-- <app-custom-automotive-applications
      *ngIf="!summary.isPromeclub"
    ></app-custom-automotive-applications> -->
    <app-custom-product-attributes-accordion
      *ngIf="summary?.product?.description"
      [titleAttribute]="'productDetails.productDetailsTitle' | cxTranslate"
      [collapseFromIcon]="true"
      attributes
    >
      <div class="product-summary" *ngIf="summary?.product?.description">
        <p
          [innerHTML]="summary?.product?.description | safeHtml"
          class="summary"
          [ngClass]="{ expand: summary.showMore }"
        ></p>
        <a
          (click)="toggleShowMore()"
          class="product-summary__toggle"
          *ngIf="summary.addToggler"
          >{{
            (summary.showMore
              ? "productDetails.showLess"
              : "productDetails.showMore"
            ) | cxTranslate
          }}</a
        >
      </div>
    </app-custom-product-attributes-accordion>
    <div
      #summaryElement
      class="product-summary"
      [ngClass]="{ hide: hideElement, opacity: summary.isMobile }"
      *ngIf="summary?.product?.description"
    >
      <p
        [innerHTML]="summary?.product?.description | safeHtml"
        class="summary"
        [ngClass]="{ expand: summary.showMore }"
      ></p>
      <a
        (click)="toggleShowMore()"
        class="product-summary__toggle"
        *ngIf="summary.addToggler"
        >{{
          (summary.showMore
            ? "productDetails.showLess"
            : "productDetails.showMore"
          ) | cxTranslate
        }}</a
      >
    </div>
    <div
      #termsElement
      class="product-summary"
      [ngClass]="{ hide: hideElement }"
      *ngIf="summary?.product?.termsAndConditions && summary.isPromeclub"
    >
      <p
        [innerHTML]="summary?.product?.termsAndConditions | safeHtml"
        class="summary"
        [ngClass]="{ expand: summary.showMoreTerms }"
      ></p>
      <a
        (click)="toggleShowMoreTerms()"
        class="product-summary__toggle"
        *ngIf="summary.addTermsToggler"
        >{{
          (summary.showMoreTerms
            ? "productDetails.showLess"
            : "productDetails.showMore"
          ) | cxTranslate
        }}</a
      >
    </div>
    <app-custom-product-attributes-accordion
      *ngIf="summary?.product?.termsAndConditions && summary.isPromeclub"
      [titleAttribute]="'TabPanelContainer.termsAndConditions' | cxTranslate"
      [collapseFromIcon]="true"
      attributes
    >
      <div
        class="product-summary terms"
        *ngIf="summary?.product?.termsAndConditions"
      >
        <p
          [innerHTML]="summary?.product?.termsAndConditions | safeHtml"
          class="summary"
          [ngClass]="{ expand: summary.showMoreTerms }"
        ></p>
        <a
          (click)="toggleShowMoreTerms()"
          class="product-summary__toggle"
          *ngIf="summary.addTermsToggler"
          >{{
            (summary.showMoreTerms
              ? "productDetails.showLess"
              : "productDetails.showMore"
            ) | cxTranslate
          }}</a
        >
      </div>
    </app-custom-product-attributes-accordion>
  </ng-container>
</ng-container>
