import { Component, ElementRef, ViewChild } from '@angular/core';
import { ModalService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-client-not-active-dialog',
  templateUrl: './custom-client-not-active-dialog.component.html',
  styleUrls: ['./custom-client-not-active-dialog.component.scss'],
})
export class CustomClientNotActiveDialogComponent {
  @ViewChild('dialogClientInactive', { read: ElementRef })
  dialogClientInactive: ElementRef;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    private modalService: ModalService,
    private customBreakpointService: CustomBreakpointService
  ) {}

  dismissModal(reason?: any): void {
    this.modalService.dismissActiveModal(reason);
  }
}
