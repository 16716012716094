export const order = {
  orderDetails: {
    orderTitle: 'Detalle de pedido',
    orderNumber: 'Orden',
    replenishmentId: 'Reabastecimiento #',
    purchaseOrderId: 'Orden de pedido #',
    purchaseOrderNumber: 'Número de orden de pedido',
    emptyPurchaseOrderId: 'Ninguno',
    none: 'Ningún {{value}}',
    placed: 'Realizada',
    placedBy: 'Realizada por',
    unit: 'Unidad',
    costCenter: 'Centro de costo',
    costCenterAndUnit: 'Centro de costo / unidad',
    costCenterAndUnitValue: '{{costCenterName}} / {{unitName}}',
    methodOfPayment: 'Forma de pago',
    payByAccount: 'Pagar con Cuenta Bancaria',
    paidByCreditCard: '(Pagado con tarjeta de crédito)',
    status: 'Estado',
    active: 'Activo',
    informationPaid: 'Información de facturación',
    shippedOn: 'Enviado el',
    shippingMethod: 'Método de entrega',
    placedOn: 'Fecha pedido',
    placedOff: 'Fecha de cancelación',
    placedOffMobile: 'Fecha cancelación',
    startOn: 'Empieza el',
    nextOrderDate: 'Próxima fecha de pedido',
    frequency: 'Frecuencia',
    cancelled: ' Cancelado',
    total: 'Total',
    quantity: 'Cantidad:',
    itemPrice: 'Precio unit:',
    totalItemPrice: 'Precio total',
    myProducts: 'Mis productos',
    deliveryStatus_IN_TRANSIT: 'En tránsito',
    deliveryStatus_READY_FOR_PICKUP: 'Listo para Retirar',
    deliveryStatus_READY_FOR_SHIPPING: 'Listo para Enviar',
    deliveryStatus_WAITING: 'En espera',
    deliveryStatus_DELIVERING: 'En Entrega',
    deliveryStatus_PICKPACK: 'Preparación para su envío',
    deliveryStatus_PICKUP_COMPLETE: 'Retiro Completo',
    deliveryStatus_DELIVERY_COMPLETED: 'Entrega Completa',
    deliveryStatus_PAYMENT_NOT_CAPTURED: 'Problemas con en el Pago',
    deliveryStatus_READY: 'En Proceso',
    deliveryStatus_DELIVERY_REJECTED: 'Entrega Rechazada',
    deliveryStatus_SHIPPED: 'Enviado',
    deliveryStatus_TAX_NOT_COMMITTED: 'Problema de Impuestos',
    deliveryStatus_CANCELLED: 'Cancelada',
    statusDisplay_ORDER_SENT_TO_ERP: 'En bloqueo',
    statusDisplay_ERROR_SEND_ORDER_TO_ERP: 'Pendiente',
    statusDisplay_PAYMENT_CAPTURED: 'En proceso',
    statusDisplay_PICKING: 'En preparación',
    statusDisplay_IN_TRANSIT: 'En tránsito',
    statusDisplay_COMPLETED: 'Entregado',
    statusDisplay_CREATED: 'Creada',
    statusDisplay_Cancelled: 'Cancelada',
    statusDisplay_cancelled: 'Cancelada',
    statusDisplay_CANCELLED: 'Cancelada',
    statusDisplay_cancelling: 'Cancelando',
    statusDisplay_CANCELLING: 'Cancelando',
    statusDisplay_completed: 'Terminado',
    // statusDisplay_COMPLETED: 'Terminado',
    statusDisplay_created: 'Creado',
    statusDisplay_error: 'Pendiente',
    statusDisplay_Error: 'Pendiente',
    statusDisplay_processing: 'Pendiente',
    statusDisplay_INPROCESS: 'En proceso',
    statusDisplay_CLOSED: 'Cerrada',
    statusDisplay_open: 'Abierto',
    statusDisplay_OPEN: 'Abierto',
    statusDisplay_pending: {
      approval: 'Pendiente de Aprobación',
      merchant: {
        approval: 'Pendiente de Aprobación por el Comerciante',
      },
    },
    statusDisplay_PENDING_APPROVAL: 'Pendiente de Aprobación',
    statusDisplay_approved: 'Aprobado',
    statusDisplay_rejected: 'Rechazaoa',
    statusDisplay_merchant: {
      approved: 'Aprobado por el comerciante',
      rejected: 'Rechazado por el comerciante',
    },
    statusDisplay_assigned: {
      admin: 'Asignado a un administrador',
    },
    consignmentTracking: {
      action: 'Seguimiento del Paquete',
      dialog: {
        header: 'Información de Seguimiento',
        shipped: 'Enviado',
        estimate: 'Entrega Estimada',
        carrier: 'Servicio de entrega',
        trackingId: 'Número de Seguimiento',
        noTracking:
          'El paquete no se ha enviado desde el almacén. La información de seguimiento estará disponible después de que se envíe el paquete.',
        loadingHeader: 'Seguimiento de Envíos',
      },
    },
    reorder: {
      reorderAction: 'Repetir orden',
      textReorder: 'Productos agregados al carrito',
      fromCart: {
        title: '<b>Tienes productos en tu carrito</b>',
        message:
          '<p>Si decides continuar con los productos de esta orden</p><p>perderás los productos del carrito.</p>',
        messageMobile:
          '<p>Si decides continuar con los productos</p><p>de esta orden perderás los</p><p>productos del carrito.</p>',
        messageTwo:
          '<p>Puedes concluir la compra de los productos del carrito o</p><p>guardarlo en Listas de Compras para retomarlo después.</p>',
        messageTwoMobile:
          '<p>Puedes concluir la compra de los</p><p>productos del carrito o guardarlo en</p><p>Listas de Compras para retomarlo después.</p>',
        cancel: 'Cancelar',
        continue: 'Agregar al carrito',
      },
    },
    registerPaid: {
      registerAction: 'Registrar pago',
      addPaymentModes: 'Agregar método de pago',
      transfer: 'Transferencia bancaria',
      deposit: 'Depósito',
      bank: 'Banco de destino:',
      amount: 'Importe del pago:',
      voucherNumber: 'Número de comprobante:',
      transferDate: 'Fecha de transferencia:',
      depositDate: 'Fecha de depósito:',
      addDocument: 'Adjuntar documento',
      addBank: '*Ingresar un banco',
      addAmount: '*Ingresar un importe',
      addVoucher: '*Ingresar un comprobante',
      addDate: '*Ingresar una fecha',
      addFile: '*Ingresar un documento',
      successfulRegistration:
        'Pago de la orden {{order}} registrado exitosamente',
      submitRegister: 'Enviar registro de pago',
      invalidVoucher: '*Ingresar números',
      errorFileType: '*Solo documentos PDF/JPEG/PNG',
      uploadFileError: '*El documento es muy pesado',
    },
    cancellationAndReturn: {
      cancelHistory: 'Cancelaciones',
      cancelTitle: 'Revisión de cancelación',
      detailCancel: 'Detalle de cancelación',
      detailCancelMobile: 'Detalle cancelación',
      btnReturn: 'Regresar',
      btnConfirm: 'Confirmar cancelación',
      returnAction: 'Solicitar una Devolución',
      cancelAction: 'Cancelar pedido',
      item: 'Articulo',
      itemPrice: 'Precio del Articulo',
      quantity: 'Cantidad:',
      returnQty: 'Cantidad a Devolver',
      cancelQty: 'Cantidad a Cancelar',
      confirmCancelTitle: 'Cancelación {{orderCode}}',
      showCancellations: 'Ver cancelaciones',
      setAll: 'Establecer todas las cantidades al máximo',
      totalPrice: 'Total',
      submit: 'Enviar Petición',
      returnSuccess: 'Se envió su solicitud de devolución ({{rma}})',
      cancelSuccess:
        'Solicitud de cancelación {{orderCode}} creada exitosamente',
    },
    claim: {
      title: 'Generar Reclamo',
      claim: 'Reclamo',
      claims: 'Reclamos',
      claimSelect: 'Seleccionar',
      checkClaim: 'Revisar reclamo',
      claimTextarea: 'Escribir comentario',
      delete: 'Eliminar reclamo',
      detailOrderCode: '{{orderCode}}',
      deleteOrder: 'Deseas eliminar de forma permanente el ',
      agentResponse: 'Respuesta Promesa:',
      answer: 'Responder:',
      sendAnswer: 'Enviar respuesta',
      btnReturnDetail: 'Regresar',
      dateCreation: 'Fecha creación',
      label: {
        motive: 'Motivo:',
        comment: 'Comentario:',
      },
      list: {
        lastDate: 'Última modificación',
        creationDate: 'Fecha de creación',
        number: 'Número de reclamo',
      },
      detailTitleAdd: 'Revisión de reclamo',
      detailTitleReview: 'Reclamo {{orderCode}}',
      detailReviewDataText:
        'Revisa que los datos de tu solicitud de devolución sean los correctos.',
      detailOrder: 'Detalle del reclamo de orden {{orderCode}}',
      detailOrderMobile: 'Detalle del reclamo',
      detailOrderSubtitle: 'Detalle de pedido',
      detailSuccess: 'Reclamo {{orderCode}} creado exitosamente',
    },
    cancelReplenishment: {
      title: 'Cancelar Reabastecimiento',
      description: '¿Cancelar algún pedido de reabastecimiento futuro?',
      accept: 'Si',
      reject: 'No',
      cancelSuccess:
        'El pedido de reposición #{{replenishmentOrderCode}} se canceló correctamente',
    },
    promeclubDetail: {
      exchangeDetail: 'Detalle del canje',
      transactionNumber: 'Número de transacción',
      creationDate: 'Fecha creación',
      deliveryDay: 'Fecha de entrega',
      pointsQuantity: 'Cantidad de puntos',
      reason: 'Motivo',
      deliveryToMyAddress: 'Envío a mi dirección',
      exchangeSummary: 'Resumen de canje',
      unitPoints: 'Puntos Unit.',
      totalPoints: 'Puntos totales',
      myProducts: 'Mis productos',
      goBack: 'Regresar',
      code: 'Cod: ',
      points: 'Puntos',
      total: 'Total',
      sku: 'SKU',
    },
  },
  orderHistory: {
    orderHistory: 'Historial de pedidos',
    orderHistoryPromeclub: 'Estado de cuenta',
    orderIdPromeclub: 'Número de transacción',
    orderId: 'Número de orden',
    orderCancelId: 'Número de cancelación',
    emptyPurchaseOrderId: 'Ninguno',
    year: 'Año:',
    placeholderYear: 'Seleccionar',
    filterResults: 'Resultados por página:',
    date: 'Fecha de pedido',
    datePromeclub: 'Fecha',
    dateCancelled: 'Fecha de cancelación',
    status: 'Estado',
    statusPromeclub: 'Motivo',
    totalPoints: 'Puntos disponibles: ',
    total: 'Total',
    totalPromeclub: 'Cantidad de puntos',
    noOrders: 'No tenemos registros de pedidos para esta cuenta.',
    noReplenishmentOrders:
      'No tenemos registros de reabastecimientos para esta cuenta.',
    startShopping: 'Empezar a Comprar',
    return: 'Regresar al inicio',
    noRegisters: 'No contamos con registros para esta cuenta',
    filterBy: 'Filtrar por:',
    resultsFor: 'Resultados para:',
    replenishmentOrderHistory: 'Historial de pedidos de reabastecimiento',
    replenishmentOrderId: 'Reabastecimiento #',
    purchaseOrderNumber: 'Orden de pedido #',
    startOn: 'Empieza el',
    frequency: 'Frecuencia',
    nextOrderDate: 'Próxima fecha de pedido',
    cancel: 'Cancelar',
    cancelled: 'Cancelado',
    replenishmentHistory: 'Historial de reabastecimiento',
    notFound: 'No se encontraron pedidos',
    exchanges: 'Canjes',
    uploads: 'Cargas',
    downloads: 'Descargas',
    downloadStatus: 'Descargar estado de cuenta',
  },
  AccountOrderHistoryTabContainer: {
    tabs: {
      AccountOrderHistoryComponent: 'TODAS LAS ÓRDENES ({{param}})',
      OrderReturnRequestListComponent: 'DEVOLUCIONES ({{param}})',
    },
  },
  returnRequestList: {
    returnRequestId: 'Devolución #',
    returnTitle: 'Devoluciones',
    dateCreate: 'Fecha de creación',
    dateUpdate: 'Fecha de modificación',
    numberReturn: 'Número de devolución',
    statusDisplay_APPROVAL_PENDING: 'Pendiente',
    statusDisplay_CANCELED: 'Cancelada',
    statusDisplay_CANCELLING: 'Cancelando',
    statusDisplay_WAIT: 'En Espera',
    statusDisplay_RECEIVED: 'En revisión',
    statusDisplay_RECEIVING: 'Recibiendo',
    statusDisplay_APPROVING: 'En Curso',
    statusDisplay_REVERSING_PAYMENT: 'Revirtiendo Pago',
    statusDisplay_PAYMENT_REVERSED: 'Pago Revertido',
    statusDisplay_PAYMENT_REVERSAL_FAILED: 'Error en Reversion de pago',
    statusDisplay_REVERSING_TAX: 'Revirtiendo Impuesto',
    statusDisplay_TAX_REVERSED: 'Impuesto Revertido',
    statusDisplay_TAX_REVERSAL_FAILED: 'Error en Reversion de Impuesto',
    statusDisplay_COMPLETED: 'Concluido',
    statusDisplay_undefined: 'Creada',
  },
  returnRequest: {
    selectedItems: 'Seleccionados',
    descriptionSelectedItems: '({{param1}}/{{param2}})',
    productsReturn: 'Productos',
    recived: 'Recibido',
    toDevolutions: 'Por devolver',
    devolutionTitle: 'Devueltos',
    unitPrice: 'Precio Unit',
    unitPriceMobile: 'Precio Unit:',
    reasonDevolution: 'Motivo de devolución',
    reasonDevolutionMobile: 'Motivo de devolución:',
    placeholderReason: 'Seleccionar',
    goBack: 'Regresar',
    goDevolution: 'Revisar devolución',
    confirmDevolution: 'Confirmar devolución',
    productImages: 'Imágenes de producto',
    productImagesMobile: 'Imágenes de producto:',
    returnAction: 'Devolución',
    newDevolution: 'Generar devolución',
    revisionDevolution: 'Revisión de devolución',
    subtitleRevision: 'Detalle',
    subtitleDetailReturn: 'Detalle de devolución',
    commentDevolution: 'Comentario de la devolución',
    addComment: 'Agregar comentario a la devolución',
    productsToReturn: 'Selecciona los productos que deseas devolver',
    productsRevision: 'Productos de devolución',
    attachImage: 'Adjuntar imagen',
    placeholderComment: 'Escribir comentario',
    devolutionSuccess:
      'Solicitud de devolución {{orderCode}} creada exitosamente',
    confirmReturnTitle: 'Devolución {{orderCode}}',
    subtitleRevisionDetail: 'Detalle devolución de orden {{orderCode}}',
    descriptionRevisionDetail:
      'Un asesor comercial se estará comunicando contigo para coordinar la devolución.',
    showDevolutions: 'Ver devoluciones',
    creationDate: 'Fecha creación',
    creationDateMobile: 'Fecha de creación',
    maxQuantityReturn: 'Máx: {{count}}',
    cancelDevolution: 'Cancelar devolución',
    cancelDevolutionText:
      'Deseas cancelar la<b> devolución de orden {{code}}</b>',
  },
};
