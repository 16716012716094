import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFutureStockDialogComponent } from './custom-future-stock-dialog.component';
import { CustomModalGenericModule } from '../../custom/components/custom-modal/custom-modal-generic.module';
import { ModalModule } from '@spartacus/storefront';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';



@NgModule({
  declarations: [
    CustomFutureStockDialogComponent
  ],
  imports: [
    CommonModule,
    CustomModalGenericModule,
    ModalModule,
    I18nModule,
    IconModule
  ],
  exports: [
    CustomFutureStockDialogComponent
  ]
})
export class CustomFutureStockDialogModule { }
