import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomPromeclubPointsComponent } from './custom-promeclub-product-points.component';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';

@NgModule({
  declarations: [CustomPromeclubPointsComponent],
  exports: [CustomPromeclubPointsComponent],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    CustomCurrencyModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromeclubPointsComponent: {
          component: CustomPromeclubPointsComponent,
        },
      },
    }),
  ],
})
export class CustomPromeclubPointsModule {}
