import { Component } from '@angular/core';
import { ModalService } from '@spartacus/storefront';
import { Router } from '@angular/router';
import { CustomRegisterStepOrchestratorService } from '../../services/custom-register-step-orchestrator.service';
import { CustomRegisterDocsService } from '../../services/custom-register-docs.service';
import { CustomRegisterNonClientFormService } from '../../services/custom-register-non-client-form.service';

@Component({
  selector: 'app-custom-register-non-customer-confirmation',
  templateUrl: './custom-register-non-customer-confirmation.component.html',
  styleUrls: ['./custom-register-non-customer-confirmation.component.scss'],
})
export class CustomRegisterNonCustomerConfirmationComponent {
  closedModal: boolean = false;
  constructor(
    private modalService: ModalService,
    private router: Router,
    private customRegisterStepOrchestratorService: CustomRegisterStepOrchestratorService,
    private customRegisterDocsService: CustomRegisterDocsService,
    private customRegisterNonClientFormService: CustomRegisterNonClientFormService
  ) {}

  closeModal(reason: string) {
    this.customRegisterStepOrchestratorService.setStepThreeStatus(false);
    this.customRegisterStepOrchestratorService.setStepTwoStatus(false);
    this.customRegisterStepOrchestratorService.setStepOneStatus(true);
    this.customRegisterDocsService.resetValidDocs();
    this.customRegisterNonClientFormService.resetNonCustomerFormData();
    this.closedModal = true;
    if (reason == 'close') {
      this.router.navigateByUrl('/').then((navigate) => {
        if (navigate) {
          window.location.reload();
        }
      });
    }
    this.modalService.closeActiveModal(reason);
  }

  ngOnDestroy(): void {
    if (!this.closedModal) {
      this.customRegisterStepOrchestratorService.setStepThreeStatus(false);
      this.customRegisterStepOrchestratorService.setStepTwoStatus(false);
      this.customRegisterStepOrchestratorService.setStepOneStatus(true);
      this.customRegisterDocsService.resetValidDocs();
      this.customRegisterNonClientFormService.resetNonCustomerFormData();
      this.router.navigateByUrl('/').then((navigate) => {
        if (navigate) {
          window.location.reload();
        }
      });
    }
  }
}
