import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  CheckoutStepService,
  ReviewSubmitComponent,
} from '@spartacus/checkout/components';
import {
  CheckoutCostCenterFacade,
  CheckoutDeliveryFacade,
  CheckoutPaymentFacade,
  PaymentTypeFacade,
} from '@spartacus/checkout/root';
import {
  ActiveCartService,
  Cart,
  CmsComponent,
  CmsService,
  TranslationService,
  UserAddressService,
  UserCostCenterService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { CustomPromeclubAddressService } from 'src/app/cms-components/my-account/services/custom-promeclub-address.service';
import { CustomGtmInteractionDatalayerService } from 'src/app/common/services/custom-gtm-interaction-datalayer.service';
import { CustomBaseSiteCheckerService } from 'src/app/services/custom-base-site-checker.service';
import { CustomUser } from '../../models/custom-user';
import { CustomReviewSubmitService } from '../../services/custom-review-submit.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-review-submit',
  templateUrl: './custom-review-submit.component.html',
  styleUrls: ['./custom-review-submit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CustomReviewSubmitComponent
  extends ReviewSubmitComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  promesaInfoCashPaymentComponentData$: Observable<CmsComponent> =
    this.cmsService.getComponentData('promesaInfoCashPaymentComponent');
  promesaInfoCashPaymentEmployeeComponent$: Observable<CmsComponent> =
    this.cmsService.getComponentData('promesaInfoCashPaymentEmployeeComponent');
  promesaInfoCreditPaymentEmployeeComponent$: Observable<CmsComponent> =
    this.cmsService.getComponentData(
      'promesaInfoCreditPaymentEmployeeComponent'
    );
  promesaInfoPickupCDComponentData$: Observable<CmsComponent> =
    this.cmsService.getComponentData('promesaInfoPickupCDComponent');
  user$: Observable<CustomUser | undefined> = this.userAccountFacade.get();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  @Input() hideContent: boolean = false;
  constructor(
    protected customReviewSubmitService: CustomReviewSubmitService,
    protected checkoutDeliveryService: CheckoutDeliveryFacade,
    protected checkoutPaymentService: CheckoutPaymentFacade,
    protected userAddressService: UserAddressService,
    protected activeCartService: ActiveCartService,
    protected translation: TranslationService,
    protected checkoutStepService: CheckoutStepService,
    protected paymentTypeService: PaymentTypeFacade,
    protected checkoutCostCenterService: CheckoutCostCenterFacade,
    protected userCostCenterService: UserCostCenterService,
    protected customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    private customGtmDatalayerService: CustomGtmInteractionDatalayerService,
    private cmsService: CmsService,
    protected customPromeclubAddressService: CustomPromeclubAddressService,
    protected userAccountFacade: UserAccountFacade,
    private customBreakpointService: CustomBreakpointService
  ) {
    super(
      checkoutDeliveryService,
      checkoutPaymentService,
      userAddressService,
      activeCartService,
      translation,
      checkoutStepService,
      paymentTypeService,
      checkoutCostCenterService,
      userCostCenterService
    );
  }

  get cart$(): Observable<Cart> {
    return this.activeCartService.getActive();
  }

  ngOnInit(): void {
    this.activeCartService.reloadActiveCart();
  }

  ngAfterViewInit(): void {
    if (!this.hideContent) {
      Promise.resolve().then(() => {
        this.customReviewSubmitService.setIsReviewSubmitActive(true);
      });
    }
  }

  editEvent(step: string) {
    this.customReviewSubmitService.setIsReviewSubmitActive(false);
    this.customGtmDatalayerService.editShowOrderEvent(false, step);
  }

  ngOnDestroy(): void {
    this.customReviewSubmitService.setIsReviewSubmitActive(false);
  }
}
