<div class="custom-credit-line-account__title">
  <app-custom-title-my-account
    [title]="'creditLine.creditLineTitle' | cxTranslate"
  ></app-custom-title-my-account>
  <ng-container *ngIf="creditInfo$ | async as credit; else noOrder">
    <ng-container *ngIf="credit; else noOrder">
      <div class="custom-credit-line-account">
        <div class="custom-credit-line-account__credit">
          <label>{{ "creditLine.myAvailableCredit" | cxTranslate }}</label>
          <span>{{ credit | customCurrency }}</span>
        </div>
        <div class="custom-credit-line-account__btn">
          <a (click)="requestExtension($event)">
            {{ "creditLine.requestExtension" | cxTranslate }}
          </a>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noOrder>
    <div class="cx-order-history-no-order">
      <app-custom-empty-cart [fromMyAccount]="true"></app-custom-empty-cart>
    </div>
  </ng-template>
</div>

<ng-template #spinnerLoading>
  <div class="cx-order-history-body cx-order-history-body__loading">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
