export const user = {
  anonymousConsents: {
    preferences: 'Preferencias de consentimiento',
    dialog: {
      title: 'Gestión del consentimiento',
      legalDescription:
        'Utilizamos cookies / almacenamiento del navegador para personalizar el contenido y mejorar la experiencia del usuario. También podemos compartir los datos sobre el uso de su sitio con nuestras redes sociales. Para obtener más información, consulte nuestra política de privacidad',
      selectAll: 'Seleccionar todo',
      clearAll: 'Limpiar todo',
    },
    banner: {
      title: 'Este sitio web utiliza cookies',
      description:
        'Usamos cookies/almacenamiento del navegador para personalizar el contenido y mejorar la experiencia del usuario.',
      allowAll: 'Permitir todo',
      viewDetails: 'Ver detalles',
    },
  },
  checkoutLogin: {
    emailAddress: {
      label: 'Dirección de correo electrónico',
      placeholder: 'Ingrese correo electrónico',
    },
    confirmEmail: {
      label: 'Confirmar correo electrónico',
      placeholder: 'Confirmar correo electrónico',
    },
    continue: 'Continuar',
    emailIsRequired: 'Formato de correo inválido',
    emailsMustMatch: 'El email no coincide',
  },
};
