import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomSafeHtmlModule } from '../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomLoadingSpinnerModule } from '../../../custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomPromeclubAddressAlertDialogComponent } from './custom-promeclub-address-alert/custom-promeclub-address-alert-dialog.component';
import { CustomPromeclubAddressFormComponent } from './custom-promeclub-address-form/custom-promeclub-address-form.component';
import { CustomPromeclubAddressComponent } from './custom-promeclub-address.component';
import { CustomTitleMyAccountModule } from 'src/app/cms-components/custom/components/custom-title-my-account/custom-title-my-account.module';
import { CustomModalGenericModule } from 'src/app/cms-components/custom/components/custom-modal/custom-modal-generic.module';



@NgModule({
  declarations: [
    CustomPromeclubAddressComponent,
    CustomPromeclubAddressFormComponent,
    CustomPromeclubAddressAlertDialogComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    NgSelectModule,
    ReactiveFormsModule,
    IconModule,
    CustomSafeHtmlModule,
    CustomLoadingSpinnerModule,
    CustomTitleMyAccountModule,
    CustomModalGenericModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromeclubAccountAddressBookComponent: {
          component: CustomPromeclubAddressComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ]
})
export class CustomPromeclubAddressModule { }
