import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  CmsBannerComponentMedia,
  CmsResponsiveBannerComponentMedia
} from '@spartacus/core';
import { BannerComponent, CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-static-responsive-banner',
  templateUrl: './custom-static-responsive-banner.component.html',
  styleUrls: ['./custom-static-responsive-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomStaticResponsiveBanner
  extends BannerComponent
  implements OnInit
{
  imageByBreakpoint$: Observable<CmsBannerComponentMedia>;
  isMobile$: Observable<boolean> = this.breakpointService.isMobile$;

  constructor(
    component: CmsComponentData<CustomStaticResponsiveBanner>,
    public breakpointService: CustomBreakpointService
  ) {
    super(component);
  }

  ngOnInit() {
    this.imageByBreakpoint$ = this.data$.pipe(
      switchMap((data) =>
        this.breakpointService.getImageByBreakpoint(
          <CmsResponsiveBannerComponentMedia>data
        )
      )
    );
  }
}
