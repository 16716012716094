<div
  class="empty-cart"
  [ngClass]="{ 'from-my-account-container': fromMyAccount, wish: fromWishlist }"
>
  <app-custom-cart-list-header
    *ngIf="!(isMobile$ | async) && !fromMyAccount && !fromWishlist"
    [ngClass]="{ promeclub: isInPromeclub }"
  ></app-custom-cart-list-header>
  <div
    class="empty-cart__summary"
    *ngIf="!fromMyAccount && !fromWishlist && !(isMobile$ | async)"
  >
    <app-custom-cart-list-summary></app-custom-cart-list-summary>
  </div>
  <div
    class="empty-cart__content"
    [ngClass]="{
      'from-my-account-container__content': fromMyAccount,
      promeclub: !fromMyAccount && isInPromeclub
    }"
  >
    <div class="empty-cart__content-box">
      <h4
        *ngIf="!fromWishlist && !fromMyAccount && (isMobile$ | async)"
        class="empty-cart__content-box-title"
      >
        {{ "cartItems.myEmptyCart" | cxTranslate }}
      </h4>
      <div class="empty-cart__content-box__image" *ngIf="!fromMyAccount">
        <img src="./assets/icons/cart-empty.svg" loading="lazy" />
      </div>
      <div
        class="empty-cart__content-box__image"
        [ngClass]="{ 'from-my-account-container__content__img': fromMyAccount }"
        *ngIf="fromMyAccount"
      >
        <img src="./assets/icons/empty-folder.svg" loading="lazy" />
      </div>
      <div class="empty-cart__content-box__text" *ngIf="!fromWishlist">
        <span
          class="empty-cart__content-box__text-count"
          *ngIf="!fromMyAccount"
        >
          0
        </span>
        <span *ngIf="!fromMyAccount">
          {{ "cartDetails.noProducts" | cxTranslate }}
        </span>
        <span
          *ngIf="fromMyAccount"
          class="from-my-account-container__content__text"
        >
          {{ "orderHistory.noRegisters" | cxTranslate }}
        </span>
      </div>
      <div class="empty-cart__content-box__text" *ngIf="fromWishlist">
        {{ "customWishlist.noProducts" | cxTranslate }}
      </div>
      <div class="empty-cart__content-box__button">
        <button
          class="custom-button-primary"
          routerLink="/"
          [ngClass]="{
            'from-my-account-container__content__button': fromMyAccount,
            wish: fromWishlist,
            isInPromeclub: isInPromeclub
          }"
        >
          <span *ngIf="!isInPromeclub && !fromMyAccount && !fromWishlist">{{
            "cartDetails.continueBuying" | cxTranslate
          }}</span>
          <span *ngIf="isInPromeclub && !fromMyAccount && !fromWishlist">{{
            "cartDetails.continueBuyingPromeclub" | cxTranslate
          }}</span>
          <span *ngIf="fromMyAccount && !fromWishlist">{{
            "orderHistory.return" | cxTranslate
          }}</span>
          <span *ngIf="fromWishlist">{{
            "customWishlist.returnToHome" | cxTranslate
          }}</span>
          <cx-icon class="fas fa-chevron-right"></cx-icon>
        </button>
      </div>
    </div>
  </div>
</div>
