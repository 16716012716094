import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';

import { Observable, Subject } from 'rxjs';
import { UploadResponse } from '../../user/custom-register/models/custom-upload.interface';
import { CustomReturnOrder } from '../components/custom-return-order/model/custom-return-order';

@Injectable({
  providedIn: 'root',
})
export class CustomOrderReturnService {
  public sendOrderReturn$: Subject<any> = new Subject<any>();

  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
  ) { }

  onSendOrderReturn(isRevision: boolean) {
    this.sendOrderReturn$.next(isRevision);
  }

  getRefundReasonsDevolutions(orderCode: string): Observable<any> {
    const url = this.occEndpointService.buildUrl('getRefundReasonsDevolutions' ,{
      queryParams: {
        orderCode,
      },
    });
    return this.http.get(url)
  }

  setOrderReturn(requestOrderReturn: CustomReturnOrder): Observable<any> {
    const url = this.occEndpointService.buildUrl('setOrderReturn');
    return this.http.post(url, requestOrderReturn);
  }

  cancelDevolution(orderCode: string) {
    const url = this.occEndpointService.buildUrl('cancelOrderReturn', {
      urlParams: {
        returnId: orderCode,
      },
    });
    return this.http.post(url, {})
  }

  getOrdersReturnFilter(pageSize: number, currentPage: number, year?: string): Observable<any> {
    const url = this.occEndpointService.buildUrl('orderReturns', {
      queryParams: {
        year: year,
        pageSize: pageSize,
        currentPage: currentPage
      }
    });
    return this.http.get(url)
  }

  uploadDocument(
    file: File,
    orderCode: string,
    paymentType: string
  ): Observable<UploadResponse> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const url = this.occEndpointService.buildUrl('uploadReturnDocument', {
      queryParams: {
        altText: `${paymentType} document`,
        code: orderCode,
        description: paymentType,
      },
    });
    return this.http.post<UploadResponse>(url, formData);
  }

  deleteDocument(uuid: string): Observable<boolean> {
    const url = this.occEndpointService.buildUrl('deleteRegisterDocument', {
      queryParams: {
        uid: uuid,
      },
    });
    return this.http.post<boolean>(url, {});
  }
}
