<ng-container *ngIf="productDetail$ | async as productDetails; else loading">
  <ng-container
    *ngIf="{ isUserLoggedIn: isLoggedIn, isMobile: isMobile$ | async } as data"
  >
    <div
      *ngIf="!!product"
      class="product-card"
      [ngClass]="{ 'logged-out': !data.isUserLoggedIn }"
    >
      <a
        tabindex="0"
        class="product-card__image"
        [ngClass]="{ 'logged-out': !data.isUserLoggedIn }"
        [routerLink]="product?.url"
      >
        <cx-media
          [container]="product?.images"
          format="product"
          [alt]="product?.name"
        ></cx-media>
      </a>

      <div
        class="product-card__info"
        [ngClass]="{ 'logged-out': !data.isUserLoggedIn }"
      >
        <p class="product-card__info-sku">
          {{ "productList.sku" | cxTranslate }}
          {{ product?.code }}
        </p>
        <a
          tabindex="0"
          class="product-card__info-name"
          [routerLink]="product?.url"
        >
          <p>
            {{ product?.name }}
          </p>
        </a>

        <p
          class="product-card__info-summary"
          [ngClass]="{ 'logged-out': !data.isUserLoggedIn }"
          [innerHTML]="productDetails?.description | safeHtml"
          *ngIf="!data.isMobile"
        ></p>
        <a
          [routerLink]="product?.url"
          class="product-card__info-details"
          *ngIf="data.isUserLoggedIn"
        >
          <p>{{ "productList.seeDetails" | cxTranslate }}</p>
        </a>
        <div class="product-card__info-badge" *ngIf="product?.badge">
          <app-custom-gift-product
            *ngIf="product?.badge"
            [label]="product?.badge"
          ></app-custom-gift-product>
        </div>
        <div class="product-card__info-price" *ngIf="data.isUserLoggedIn">
          <p
            class="product-card__info-price__previous"
            *ngIf="!!product?.previousPrice?.value"
          >
            {{ product?.previousPrice?.value | customCurrency }}
          </p>

          <a
            tabindex="0"
            class="product-card__info-price__current"
            [ngClass]="!!product?.previousPrice?.value ? 'with-previous' : ''"
            [routerLink]="product?.url"
          >
            {{ product?.priceWithIVA?.value | customCurrency }}
            <span
              class="product-card__info-price__current-iva"
              *ngIf="!!product?.price?.value"
            >
              {{ "productList.plusIva" | cxTranslate }}
            </span>
          </a>
        </div>
      </div>
      <div
        class="product-card__action"
        [ngClass]="{ 'logged-out': !data.isUserLoggedIn }"
      >
        <!-- cuando no estamos logueados -->
        <div
          class="product-card__action-button"
          *ngIf="!data.isUserLoggedIn; else addToCartTemplate"
        >
          <button
            [routerLink]="product?.url"
            class="custom-button primary logged-out"
          >
            <span class="button-text">
              {{ "productList.seeMore" | cxTranslate }}
            </span>
            <cx-icon class="fas fa-chevron-right"></cx-icon>
          </button>
        </div>
        <!-- cuando estamos logueados -->
        <ng-template #addToCartTemplate>
          <custom-add-to-cart
            [product]="productDetails"
            [fromProductCard]="true"
            [fromProductVariantPlp]="true"
          ></custom-add-to-cart>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #loading>
  <div class="custom-loading-product">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
