import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  ActiveFacetsComponent,
  FacetList,
  FacetService,
} from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';

@Component({
  selector: 'app-custom-active-facets',
  templateUrl: './custom-active-facets.component.html',
  styleUrls: ['./custom-active-facets.component.scss'],
})
export class CustomActiveFacetsComponent extends ActiveFacetsComponent {
  @Input() fromMenu: boolean = false;
  isInPromeclub: boolean = this.service.isInPromeClub();
  activePriceFacet$: Observable<number[] | null> =
    this.facetService.facetList$.pipe(
      map((facets: FacetList) => {
        const priceFacet = facets?.activeFacets?.filter(
          (active) =>
            active?.facetCode == 'priceValue' || active?.facetName == 'Puntos'
        );

        if (priceFacet?.length && !this.isInPromeclub) {
          const activeMin = priceFacet?.[0].facetValueName
            ?.split?.('[')[1]
            .split?.(' TO ')?.[0];
          const activeMax = priceFacet?.[0].facetValueName
            ?.split?.('[')[1]
            .split?.(' TO ')[1]
            .split?.(']')?.[0];
          return [Number(activeMin), Number(activeMax)];
        }

        if (priceFacet?.length && this.isInPromeclub) {
          const promeActiveMin = priceFacet?.[0]?.facetValueName
            ?.split?.('[')[1]
            .split?.(' TO ')?.[0];
          const promeActiveMax = priceFacet?.[0]?.facetValueName
            ?.split?.('[')[1]
            .split?.(' TO ')[1]
            .split?.(']')?.[0];
          return [Number(promeActiveMin), Number(promeActiveMax)];
        }
        return null;
      })
    );
  constructor(
    facetService: FacetService,
    private router: Router,
    private service: CustomNavigationService
  ) {
    super(facetService);
  }

  cancelFacets() {
    const arrQuery = this.router.url.split('?query');
    this.router.navigateByUrl(arrQuery[0]);
  }
  formatFacetName(facetName: string) {
    const activeFacetName = facetName.split('##SPLIT##');

    if (!!activeFacetName && activeFacetName?.length > 1) {
      return activeFacetName[1];
    } else {
      return !!activeFacetName ? activeFacetName[0] : '';
    }
  }
}
