import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  CustomFormValidators,
  ModalRef,
  ModalService,
} from '@spartacus/storefront';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomUpdatedPasswordModalComponent } from './custom-updated-password-modal/custom-updated-password-modal.component';
import { RoutingService } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class CustomUpdatePasswordService {
  constructor(
    private modalService: ModalService,
    protected routingService: RoutingService,
    protected userPasswordService: UserPasswordFacade
  ) {}

  modalRef: ModalRef;

  protected busy$ = new BehaviorSubject(false);

  isUpdating$ = this.busy$.pipe(
    tap((state) => (state === true ? this.form.disable() : this.form.enable()))
  );

  form: FormGroup = new FormGroup(
    {
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', [
        Validators.required,
        CustomFormValidators.passwordValidator,
      ]),
      newPasswordConfirm: new FormControl('', Validators.required),
    },
    {
      validators: CustomFormValidators.passwordsMustMatch(
        'newPassword',
        'newPasswordConfirm'
      ),
    }
  );

  /**
   * Updates the password for the user.
   */
  updatePassword(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.busy$.next(true);

    const oldPassword = this.form.get('oldPassword')?.value;
    const newPassword = this.form.get('newPassword')?.value;

    this.userPasswordService.update(oldPassword, newPassword).subscribe({
      next: () => this.onSuccess(),
      error: (error: Error) => this.onError(error),
    });
  }

  protected onSuccess(): void {
    this.busy$.next(false);
    this.form.reset();
    this.routingService.go({ cxRoute: 'home' });
    this.openModal();
  }

  openModal(error?: boolean, fromReset: boolean = false): void {
    let modalInstance: any;
    this.modalRef = this.modalService.open(
      CustomUpdatedPasswordModalComponent,
      {
        centered: true,
        size: 'lg',
      }
    );
    modalInstance = this.modalRef.componentInstance;
    modalInstance.PasswordMismatchError = error;
    modalInstance.fromReset = fromReset;
  }

  protected onError(_error: Error): void {
    this.busy$.next(false);
    this.form.reset();
  }
}
