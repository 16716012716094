<ng-container
  *ngIf="{
    seeMore: seeMoreLink$ | async,
    isMobile: isMobile$ | async,
    items: items$ | async,
    title: title$ | async,
    isHomePage: isHomePage$ | async,
    showCategoryText: showCategoryText$ | async
  } as data"
>
  <div class="category-list">
    <div
      class="category-list__title"
      *ngIf="data.showCategoryText || !data.isMobile"
    >
      <app-custom-component-title
        [title]="data.title"
        [hasFirsWordInBold]="data?.isMobile ? false : true"
        *ngIf="!!data.title"
      >
      </app-custom-component-title>

      <a
        *ngIf="
          data.items?.length > topMax && topMax == sliceBy && !data.isMobile
        "
        class="category-link"
        (click)="expandCategories()"
        >{{ "common.showAllCategories" | cxTranslate
        }}<cx-icon class="fas fa-chevron-right"></cx-icon
      ></a>
      <a
        *ngIf="
          data.items?.length > topMax && topMax != sliceBy && !data.isMobile
        "
        class="category-link"
        (click)="expandCategories()"
        >{{ "common.showLessCategories" | cxTranslate
        }}<cx-icon class="fas fa-chevron-right"></cx-icon>
      </a>
    </div>
    <ng-container *ngIf="!!data.items">
      <div
        class="category-items-container"
        [ngClass]="isAutomotive ? 'automotive' : ''"
      >
        <ng-container *ngFor="let item$ of data.items.slice(0, sliceBy)">
          <app-custom-category-item
            [category]="item$ | async"
          ></app-custom-category-item>
        </ng-container>
      </div>
    </ng-container>

    <a
      *ngIf="data.items?.length > topMax && topMax == sliceBy && data.isMobile"
      class="category-link"
      (click)="expandCategories()"
      >{{ "common.showAllCategories" | cxTranslate
      }}<cx-icon class="fas fa-chevron-right"></cx-icon>
    </a>
    <a
      *ngIf="data.items?.length > topMax && topMax != sliceBy && data.isMobile"
      class="category-link"
      (click)="expandCategories()"
      >{{ "common.showLessCategories" | cxTranslate
      }}<cx-icon class="fas fa-chevron-right"></cx-icon>
    </a>
  </div>
</ng-container>
