import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MultiCartService } from '@spartacus/core';
import { ModalService } from '@spartacus/storefront';
import { take } from 'rxjs/operators';
import { CustomWishlistService } from 'src/app/cms-components/custom-wishlist/services/custom-wishlist.service';

@Component({
  selector: 'app-custom-remove-cart',
  templateUrl: './custom-remove-cart.component.html',
  styleUrls: ['./custom-remove-cart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomRemoveCartComponent implements OnInit {
  cartId: string;
  userId: string;
  fromWishlist: boolean = false;
  constructor(
    private multiCartService: MultiCartService,
    private modalService: ModalService,
    private customWishlistService: CustomWishlistService
  ) {}

  ngOnInit(): void {}

  closeModal(): void {
    this.modalService.closeActiveModal();
  }

  removeCart(): void {
    if (this.fromWishlist) {
      this.removeWishlist();
    } else {
      this.multiCartService.deleteCart(this.cartId, this.userId);
      this.closeModal();
    }
  }

  removeWishlist(): void {
    this.customWishlistService
      .clearWishlist()
      .pipe(take(1))
      .subscribe({
        next: () => this.closeModal(),
        error: () => this.closeModal(),
        complete: () => this.closeModal(),
      });
  }
}
