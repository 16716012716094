import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomGetImageUrlService } from 'src/app/services/custom-get-image-url.service';
import { FacetValue } from '../../../models/custom-search-facets.model';
import { Router } from '@angular/router';
import { CustomCategoryData } from 'src/app/cms-components/custom/components/custom-category-list/model/custom-category-list.interface';

@Component({
  selector: 'app-custom-searchbox-item',
  templateUrl: './custom-searchbox-item.component.html',
  styleUrls: ['./custom-searchbox-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomSearchboxItemComponent {

  @Input() isCategory: boolean = false;
  @Input() category: Observable<CustomCategoryData> | FacetValue;
  @Input() isSuggest: string = '';

  constructor(private router: Router, private customGetImageUrl: CustomGetImageUrlService) { }

  getImageUrl(url: string): string {
    return this.customGetImageUrl.getImageUrl(url);
  }

  navigateToUrl(value: string) {
    if (this.isSuggest !== '') {
      this.router.navigateByUrl(`/search/${this.isSuggest}?query=${value}`);
    } else {
      this.router.navigateByUrl(value)
    }
  }


}
