import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomOrderHistoryService {
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
  ) { }

  getOrderFilterYear(pageSize: number, currentPage: number, year?: string): Observable<any> {
    const url = this.occEndpointService.buildUrl('orderHistory', {
      queryParams: {
        year: year,
        pageSize: pageSize,
        currentPage: currentPage
      }
    });
    return this.http.get(url)
  }

  getOrderFilterCancelled(pageSize: number, currentPage: number, year?: string): Observable<any> {
    const url = this.occEndpointService.buildUrl('orderHistory', {
      queryParams: {
        year: year,
        pageSize: pageSize,
        currentPage: currentPage,
        statuses: 'CANCELLED'
      }
    });
    return this.http.get(url)
  }

}
