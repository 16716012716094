import { Component, ViewEncapsulation } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { ICON_TYPE, ModalService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-quote-in-course-dialog',
  templateUrl: './custom-quote-in-course-dialog.component.html',
  styleUrls: ['./custom-quote-in-course-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomQuoteInCourseDialogComponent {
  iconTypes = ICON_TYPE;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    private modalService: ModalService,
    private customBreakpointService: CustomBreakpointService,
    private routingService: RoutingService
  ) {}

  closeModal(reason: string) {
    this.modalService.closeActiveModal(reason);
  }

  goToQuoteList() {
    this.routingService.go({ cxRoute: 'quotes' });
    this.closeModal('redirect to quote list');
  }
}
