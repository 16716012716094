<ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
  <div class="cart-quotes" *ngIf="!data.isMobile; else mobileLink">
    <p class="cart-quotes__message">
      {{ "cartQuotes.message" | cxTranslate }}
    </p>
    <div class="cart-quotes__link" (click)="createQuoteFromCart()">
      <a class="cart-quotes__link-text">
        {{ "cartQuotes.linkText" | cxTranslate }}
      </a>
      <cx-icon class="fas fa-chevron-right"></cx-icon>
    </div>
  </div>
  <ng-template #mobileLink>
    <div class="cart-quotes__link" (click)="createQuoteFromCart()">
      <a class="cart-quotes__link-text">
        {{ "cartQuotes.linkText" | cxTranslate }}
      </a>
    </div>
  </ng-template>
</ng-container>
