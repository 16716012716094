import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductSummaryComponent } from './custom-product-summary.component';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { CustomSafeHtmlModule } from 'src/app/pipes/custom-safe-html/custom-safe-html.module';
import { CustomProductSocialShareModule } from '../custom-product-social-share/custom-product-social-share.module';
import { CustomWishlistModule } from 'src/app/cms-components/custom/components/custom-wishlist/custom-wishlist.module';
import { CustomProductAttributesAccordionModule } from '../custom-product-tabs/custom-product-attributes-accordion/custom-product-attributes-accordion.module';
import { CustomAutomotiveApplicationsModule } from '../../../custom/components/custom-automotive-applications/custom-automotive-applications.module';

@NgModule({
  declarations: [CustomProductSummaryComponent],
  imports: [
    CommonModule,
    UrlModule,
    I18nModule,
    CustomSafeHtmlModule,
    CustomProductSocialShareModule,
    CustomWishlistModule,
    CustomProductAttributesAccordionModule,
    CustomAutomotiveApplicationsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductSummaryComponent: {
          component: CustomProductSummaryComponent,
          guards: [],
        },
      },
    } as CmsConfig),
  ],
  exports: [CustomProductSummaryComponent],
})
export class CustomProductSummaryModule {}
