export const customRebate = {
  customRebate: {
    customRebate: {
      title: 'Programa Rebate',
      subtitle: 'Campañas disponibles',
      cartCarouselTitle: 'Productos Destacados',
      filter: {
        text: 'Filtrar por:',
        label: 'Fecha:',
        results: 'Resultados para:',
        page: 'Resultados por página:',
      },
      firstColumn: 'Campaña',
      secondColumn: 'Escalas del acuerdo',
      thirdColumn: 'Total facturado',
      fourthColumn: 'Rebate acumulado',
      fifthColumn: 'Estado',
      description: 'Descripción',
      recommendedProducts: 'Productos recomendados de la campaña',
      availableUntil: 'Disponible hasta: {{date}}',
      customPDPRebate: {
        titlePdp: 'Producto participante en Rebate',
        titleCart: 'Productos participantes en Rebate',
      },
    },
  },
};
