import { Component, Input, OnDestroy } from '@angular/core';
import { Order, RoutingService } from '@spartacus/core';
import {
  AmendOrderActionsComponent,
  OrderDetailsService
} from '@spartacus/order/components';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CustomOrderCancellationService } from '../../../services/custom-order-cancellation.service';
import { CustomOrderCancelDialogComponent } from '../custom-order-cancel-dialog/custom-order-cancel-dialog.component';

@Component({
  selector: 'app-custom-amend-order-actions',
  templateUrl: './custom-amend-order-actions.component.html',
  styleUrls: ['./custom-amend-order-actions.component.scss'],
})
export class CustomAmendOrderActionsComponent
  extends AmendOrderActionsComponent
  implements OnDestroy
{
  modalRef: ModalRef;
  unsubscribe$: Subject<any> = new Subject();
  order$: Observable<Order> = this.orderDetailsService.getOrderDetails();
  @Input() order: Order;
  constructor(
    protected routingService: RoutingService,
    protected modalService: ModalService,
    private customOrderCancellationService: CustomOrderCancellationService,
    protected orderDetailsService: OrderDetailsService
  ) {
    super(routingService);
  }

  cancelOrder(event: Event, order: any) {
    let body: any[] = [];
    this.customOrderCancellationService
      .getCancelOrder(this.orderCode, body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.openModal(event);
        },
        (err) => {
          if (
            err.error?.errors[0]?.message == 'amount must be greater than zero.'
          ) {
            this.openModal(event);
          }
        }
      );
  }

  openModal(event: Event) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomOrderCancelDialogComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = this.modalRef.componentInstance;
    modalInstance.orderCode = this.orderCode;
    this.modalRef.dismissed
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((result) => {
          if (result == 'Click continue') {
            this.routingService.go({
              cxRoute: this.forwardRoute,
              params: { code: this.orderCode },
            });
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
