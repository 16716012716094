import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoginComponent } from '@spartacus/user/account/components';
import {
  AuthService,
  CmsService,
  CmsLinkComponent,
  CmsNavigationNode,
  CmsNavigationComponent,
  ContentSlotData,
  ActiveCartService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subject } from 'rxjs';
import { switchMap, pluck, map, startWith, share } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomModalService } from '../../shared/components/custom-modal/services/custom-modal.service';
import { ModalService } from '@spartacus/storefront';
import { CustomNavigationService } from '../../navigation/services/custom-navigation.service';
import { CustomGtmLoginService } from 'src/app/common/services/custom-gtm-login-register-datalayer.service';
import { CustomUserPointsService } from 'src/app/services/custom-user-points.service';

@Component({
  selector: 'app-custom-login',
  templateUrl: './custom-login.component.html',
  styleUrls: ['./custom-login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomLoginComponent extends LoginComponent implements OnInit {
  myAccountComponent$: Observable<
    (Observable<CmsLinkComponent | null> | null)[] | undefined
  >;
  showMyAccount = new Subject<boolean>();
  isInPromeclub: boolean = this.navigationService.isInPromeClub();
  showMyAccount$: Observable<boolean> = this.showMyAccount
    .asObservable()
    .pipe(share());
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  quantity$: Observable<number> = this.activeCartService.getActive().pipe(
    startWith({ deliveryItemsQuantity: 0 }),
    map((cart) => cart.deliveryItemsQuantity || 0)
  );
  instance = 'Login_Form';
  availablePoints$: Observable<number> = this.customUserPointsService
    .getUserPointsFromObservable()
    .pipe(map((userPoints) => userPoints?.pointsData?.availablePoints!));
  modalVisibility$: Observable<boolean> = this.customModalService.visibility$;
  constructor(
    auth: AuthService,
    userAccount: UserAccountFacade,
    private cmsService: CmsService,
    private customBreakpointService: CustomBreakpointService,
    protected activeCartService: ActiveCartService,
    public customModalService: CustomModalService,
    protected modalService: ModalService,
    private navigationService: CustomNavigationService,
    private customLayerService: CustomGtmLoginService,
    private customUserPointsService: CustomUserPointsService
  ) {
    super(auth, userAccount);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.myAccountComponent$ = this.cmsService
      .getContentSlot('HeaderLinks')
      .pipe(
        switchMap((data: ContentSlotData) =>
          this.cmsService.getComponentData<CmsNavigationComponent>(
            data?.components?.[0].uid!
          )
        ),
        pluck('navigationNode'),
        map((data: CmsNavigationNode | undefined) =>
          data?.children
            ?.map((child: CmsNavigationNode) =>
              !!child?.entries?.length
                ? this.cmsService.getComponentData<CmsLinkComponent>(
                    child?.entries?.[0].itemId!
                  )
                : null
            )
            .filter((data) => !!data)
        )
      );
  }

  showMyAccountModal() {
    this.customModalService.show('myAccount');
  }

  setShowModalLoginForm(event: string) {
    this.signInEvent(event);
    this.customModalService.show(this.instance);
  }

  signInEvent(event: string) {
    this.customLayerService.signInEvent(event);
  }

  public setShowMyAccount(show: boolean) {
    this.showMyAccount.next(show);
  }

  setShowModalPoints() {
    this.customModalService.show('points');
  }
}
