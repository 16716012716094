<div class="cx-rebate-container">
  <app-custom-title-my-account
    [title]="'customRebate.title' | cxTranslate"
    [lessMargin]="true"
  ></app-custom-title-my-account>
  <h4>{{ "customRebate.subtitle" | cxTranslate }}</h4>

  <div class="cx-rebate-container__filters d-flex flex-column mt-4 mb-2">
    <span>{{ "customRebate.filter.text" | cxTranslate }}</span>
    <app-custom-filter
      [label]="'customRebate.filter.label' | cxTranslate"
      [entries]="yearsAndMonths"
      [valueDefault]="yearsAndMonthsSelected"
      (filterSelected)="filterYearAndMonthSelected($event)"
    ></app-custom-filter>
    <div>
      <ng-container *ngIf="isMobile$ | async; else desktopSelector">
        <ng-container *ngTemplateOutlet="yearsSelected"></ng-container>
        <ng-container *ngTemplateOutlet="pagination"></ng-container>
      </ng-container>

      <ng-template #desktopSelector>
        <div
          class="d-flex justify-content-between cx-rebate-container__filters-selectors"
        >
          <div
            class="d-flex align-items-center cx-rebate-container__filters-selectors-group"
          >
            <span>{{ "customRebate.filter.results" | cxTranslate }}</span>
            <ng-container *ngTemplateOutlet="yearsSelected"></ng-container>
          </div>

          <ng-container *ngTemplateOutlet="pagination"></ng-container>
        </div>
      </ng-template>
    </div>
  </div>

  <ng-template #yearsSelected>
    <div
      *ngIf="yearsAndMonthsSelected"
      class="cx-rebate-container__applied-filter"
    >
      <span>{{ yearsAndMonthsSelected }}</span>
      <cx-icon
        (click)="deleteYearAndMonth(yearsAndMonthsSelected)"
        [type]="iconTypes.CLOSE"
      ></cx-icon>
    </div>
  </ng-template>

  <ng-template #pagination>
    <ng-container *ngIf="rebate$ | async as rebate">
      <div class="cx-rebate-container__filters-container">
        <app-custom-filter
          *ngIf="rebate.pagination.totalPages >= 1"
          class="cx-rebate-container__page-filter"
          [label]="'customRebate.filter.page' | cxTranslate"
          [entries]="quantities"
          [valueDefault]="pageSize"
          (filterSelected)="filterQuantitySelected($event)"
          [classSelect]="'small-filter'"
        ></app-custom-filter>

        <app-custom-pagination
          *ngIf="rebate.pagination.totalPages >= 1"
          [pagination]="rebate.pagination"
          (viewPageEvent)="customPageChangeSelected($event)"
          [pageSelected]="selectedPage"
        ></app-custom-pagination></div
    ></ng-container>
  </ng-template>

  <ng-container
    *ngIf="{ isMobile: isMobile$ | async, rebate: rebate$ | async } as data"
  >
    <ng-container *ngIf="data.rebate; else spinnerLoading">
      <ng-container *ngIf="data.isMobile; else templateTable">
        <div>
          <div
            class="cx-rebate-container__mobile-container"
            *ngFor="let rebate of data?.rebate?.rappels; index as i"
          >
            <h6 class="campaign-title">{{ rebate.campaign }}</h6>
            <span>{{ "customRebate.secondColumn" | cxTranslate }}</span>
            <div
              *ngIf="rebate.scales.length > 0 && rebate.totalFacturado"
              class="d-flex progress-bar-numbers"
            >
              <div
                class="progress-bar-numbers-container d-flex flex-column align-items-center"
                *ngFor="let scale of rebate.scales"
                [ngClass]="scale?.achieved && 'progress-bar-numbers__blue'"
              >
                <span>{{ scale.value }}%</span>
                <div
                  class="div-bar-progress"
                  [ngClass]="scale.achieved && 'div-bar-progress__blue'"
                ></div>
                <span>${{ scale.fromValue }}</span>
              </div>
            </div>
            <div
              class="d-flex"
              *ngIf="rebate.scales.length > 0 && rebate.totalFacturado"
            >
              <ngb-progressbar
                class="d-flex end-progress-bar"
                [value]="
                  valueMinProgressBar(rebate.scales, rebate.totalFacturado)
                "
              >
              </ngb-progressbar>
              <ngb-progressbar
                class="middle-progress-bar"
                [value]="valueProgressBar(rebate.scales, rebate.totalFacturado)"
              >
              </ngb-progressbar>
              <ngb-progressbar
                class="d-flex end-progress-bar"
                [value]="valueMaxProgressBar(rebate.scales)"
              >
              </ngb-progressbar>
            </div>
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column">
                <span>{{ "customRebate.thirdColumn" | cxTranslate }}</span>
                <span class="total-number">{{
                  rebate.totalFacturado | customCurrency
                }}</span>
              </div>
              <div class="d-flex flex-column">
                <span>{{ "customRebate.fourthColumn" | cxTranslate }}</span>
                <span class="rebate-number">{{
                  rebate.expectedBenefit | customCurrency
                }}</span>
              </div>
            </div>
            <div class="d-flex justify-content-between mt-3">
              <div class="d-flex">
                <span>{{ "customRebate.fifthColumn" | cxTranslate }}</span>
                <cx-icon
                  [type]="rebate.active ? 'CALENDAR_CHECK' : 'CALENDAR_MINUS'"
                ></cx-icon>

                <span
                  [ngClass]="
                    rebate.active && 'cx-rebate-container__active-rebate'
                  "
                  >{{ rebate.status }}</span
                >
              </div>
              <div class="d-flex">
                <span
                  class="cx-rebate-container__mobile-container__show-more"
                  (click)="setItem($event, i)"
                  >{{
                    itemCodeSelected == i + 1
                      ? ("common.showLess" | cxTranslate)
                      : ("common.showMore" | cxTranslate)
                  }}</span
                >
                <cx-icon
                  (click)="setItem($event, i)"
                  class="fas fa-chevron-down icon-blue"
                  [class]="itemCodeSelected == i + 1 ? 'rotate' : ''"
                ></cx-icon>
              </div>
            </div>
            <app-custom-rebate-detail
              *ngIf="itemCodeSelected == i + 1"
              class="app-custom-rebate-detail"
              [date]="rebate.dateTo"
              [description]="rebate.description"
              [clientId]="user"
              [rappelId]="rebate.id"
            ></app-custom-rebate-detail>
          </div>
        </div>
      </ng-container>

      <ng-template #templateTable>
        <table class="table table-hover custom-rebate-table">
          <thead>
            <tr>
              <th scope="col">
                {{ "customRebate.firstColumn" | cxTranslate }}
              </th>
              <th scope="col">
                {{ "customRebate.secondColumn" | cxTranslate }}
              </th>
              <th scope="col">
                {{ "customRebate.thirdColumn" | cxTranslate }}
              </th>
              <th scope="col">
                {{ "customRebate.fourthColumn" | cxTranslate }}
              </th>
              <th scope="col">
                {{ "customRebate.fifthColumn" | cxTranslate }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody [class]="{ 'row-selected': itemCodeSelected > 0 }">
            <tr
              *ngFor="let rebate of data?.rebate?.rappels; index as i"
              [class]="
                itemCodeSelected == i + 1 ? 'row-selected' : 'not-selected'
              "
            >
              <td class="campaign-title">
                {{ rebate.campaign }}
              </td>
              <td>
                <div
                  *ngIf="rebate.scales.length > 0 && rebate.totalFacturado"
                  class="d-flex progress-bar-numbers"
                >
                  <div
                    class="progress-bar-numbers-container d-flex flex-column justify-content-between align-items-center"
                    *ngFor="let scale of rebate.scales"
                    [ngClass]="scale?.achieved && 'progress-bar-numbers__blue'"
                  >
                    <span>{{ scale.value }}%</span>
                    <div
                      class="div-bar-progress"
                      [ngClass]="scale.achieved && 'div-bar-progress__blue'"
                    ></div>
                    <span>${{ scale.fromValue }}</span>
                  </div>
                </div>
                <ng-container
                  *ngIf="rebate.scales.length > 0 && rebate.totalFacturado"
                >
                  <div class="d-flex">
                    <ngb-progressbar
                      class="d-flex end-progress-bar"
                      [value]="
                        valueMinProgressBar(
                          rebate.scales,
                          rebate.totalFacturado
                        )
                      "
                    >
                    </ngb-progressbar>
                    <ngb-progressbar
                      class="middle-progress-bar"
                      [value]="
                        valueProgressBar(rebate.scales, rebate.totalFacturado)
                      "
                    >
                    </ngb-progressbar>
                    <ngb-progressbar
                      class="d-flex end-progress-bar"
                      [value]="valueMaxProgressBar(rebate.scales)"
                    >
                    </ngb-progressbar>
                  </div>
                </ng-container>
              </td>
              <td class="total-number">
                {{ rebate.totalFacturado | customCurrency }}
              </td>
              <td class="rebate-number">
                {{ rebate.expectedBenefit | customCurrency }}
              </td>
              <td>
                <div class="d-flex">
                  <cx-icon
                    [type]="rebate.active ? 'CALENDAR_CHECK' : 'CALENDAR_MINUS'"
                  ></cx-icon>
                  <span
                    class="cx-rebate-container__status-text"
                    [ngClass]="
                      rebate.active && 'cx-rebate-container__active-rebate'
                    "
                    >{{ rebate.status }}</span
                  >
                </div>
              </td>
              <td>
                <cx-icon
                  (click)="setItem($event, i)"
                  class="fas fa-chevron-down icon-blue"
                  [class]="itemCodeSelected == i + 1 ? 'rotate' : ''"
                ></cx-icon>
              </td>
            </tr>
          </tbody>
        </table>
        <app-custom-rebate-detail
          *ngIf="itemCodeSelected > 0"
          class="app-custom-rebate-detail"
          [date]="data?.rebate?.rappels[itemCodeSelected - 1].dateTo"
          [description]="
            data?.rebate?.rappels[itemCodeSelected - 1].description
          "
          [clientId]="user"
          [rappelId]="data?.rebate?.rappels?.[itemCodeSelected - 1]?.id"
        ></app-custom-rebate-detail>
        <div class="d-flex justify-content-end">
          <app-custom-pagination
            *ngIf="data?.rebate?.pagination.totalPages >= 1"
            [pagination]="data?.rebate?.pagination"
            (viewPageEvent)="customPageChangeSelected($event)"
            [pageSelected]="selectedPage"
          ></app-custom-pagination>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #spinnerLoading>
      <div class="cx-order-history-body cx-order-history-body__loading">
        <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
      </div>
    </ng-template>
  </ng-container>
</div>
