import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomImage360Component } from './custom-image360.component';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';

@NgModule({
  declarations: [CustomImage360Component],
  imports: [CommonModule, RouterModule, UrlModule, I18nModule],
  exports: [CustomImage360Component],
})
export class CustomImage360Module {}
