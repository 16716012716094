import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AsmAuthStorageService, CsAgentAuthService } from '@spartacus/asm/root';
import {
  AuthActions,
  AuthService,
  OAuthLibWrapperService,
  OCC_USER_ID_CURRENT,
  OCC_USER_ID_ANONYMOUS,
  UserIdService,
  UserService,
  AuthToken,
} from '@spartacus/core';
// import {TokenTarget} from '@spartacus/asm/root';

@Injectable({
  providedIn: 'root',
})
export class CustomCsAgentAuthService extends CsAgentAuthService {
  constructor(
    authService: AuthService,
    authStorageService: AsmAuthStorageService,
    userIdService: UserIdService,
    oAuthLibWrapperService: OAuthLibWrapperService,
    store: Store,
    userService: UserService
  ) {
    super(
      authService,
      authStorageService,
      userIdService,
      oAuthLibWrapperService,
      store,
      userService
    );
  }

    /**
   * Loads access token for a customer support agent.
   * @param userId
   * @param password
   */
     async authorizeCustomerSupportAgent(
      userId: string,
      password: string
    ): Promise<void> {
      let userToken: AuthToken | undefined;
      this.authStorageService
        .getToken()
        .subscribe((token) => (userToken = token))
        .unsubscribe();
  
        this.authStorageService.switchTokenTargetToCSAgent();
        console.log(userToken);
      try {
        await this.oAuthLibWrapperService.authorizeWithPasswordFlow(
          userId,
          password
        );
        // Start emulation for currently logged in user
        let customerId: string | undefined;
        this.userService
          .get()
          .subscribe((user) => (customerId = user?.customerId))
          .unsubscribe();
        this.store.dispatch(new AuthActions.Logout());
  
        if (customerId !== undefined && userToken !== undefined) {
          // OCC specific user id handling. Customize when implementing different backend
          this.userIdService.setUserId(customerId);
          this.authStorageService.setEmulatedUserToken(userToken);
          this.store.dispatch(new AuthActions.Login());
        } else {
          // When we can't get the customerId just end all current sessions
          this.userIdService.setUserId(OCC_USER_ID_ANONYMOUS);
          this.authStorageService.clearEmulatedUserToken();
        }
      } catch {
        this.authStorageService.switchTokenTargetToUser();
      }
    }

  /**
   * Logout a customer support agent.
   */
  // async logoutCustomerSupportAgent(): Promise<void> {
  //   const emulatedToken = this.authStorageService.getEmulatedUserToken();

  //   let isCustomerEmulated: boolean;
  //   this.userIdService
  //     .isEmulated()
  //     .subscribe((emulated) => (isCustomerEmulated = emulated))
  //     .unsubscribe();

  //   if (isCustomerEmulated && emulatedToken) {
  //     await this.oAuthLibWrapperService.revokeAndLogout();
  //   }

  //   this.store.dispatch({ type: '[Auth] Logout Customer Support Agent' });
  //   this.authStorageService.setTokenTarget(TokenTarget.User);

  //   if (isCustomerEmulated && emulatedToken) {
  //     this.store.dispatch(new AuthActions.Logout());
  //     this.authStorageService.setToken(emulatedToken);
  //     this.userIdService.setUserId(OCC_USER_ID_CURRENT);
  //     this.authStorageService.clearEmulatedUserToken();
  //     this.store.dispatch(new AuthActions.Login());
  //   } else {
  //     this.authService.logout();
  //   }
  // }
}
