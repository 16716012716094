import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  CmsBannerComponentMedia,
  CmsResponsiveBannerComponentMedia,
  WindowRef,
} from '@spartacus/core';
import {
  BREAKPOINT,
  BreakpointService,
  LayoutConfig,
} from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomBreakpointService extends BreakpointService {
  static readonly IMAGE_BY_FORMAT: Map<BREAKPOINT, any> = new Map([
    [BREAKPOINT.xl, (item: any) => item?.media?.widescreen || item?.media?.desktop],
    [BREAKPOINT.lg, (item) => item?.media?.desktop],
    [BREAKPOINT.md, (item) => item?.media?.tablet || item?.media?.mobile],
    [BREAKPOINT.sm, (item) => item?.media?.mobile],
    [BREAKPOINT.xs, (item) => item?.media?.mobile],
  ]);
  private mobileSizes = ['md', 'sm', 'xs'];
  private smallDesktopSizes = ['lg'];
  private mediumDesktopSizes = ['xl'];

  public isMobile$: Observable<boolean> = this.breakpoint$.pipe(
    map((size) => this.mobileSizes.includes(size)),
    distinctUntilChanged()
  );

  public isSmallDesktop$: Observable<boolean> = this.breakpoint$.pipe(
    map((size) => this.smallDesktopSizes.includes(size)),
    distinctUntilChanged()
  );

  public getImageByBreakpoint(
    responsiveMedia: CmsResponsiveBannerComponentMedia
  ): Observable<CmsBannerComponentMedia> {
    return this.breakpoint$.pipe(
      map((breakpoint) =>
        CustomBreakpointService.IMAGE_BY_FORMAT.get(breakpoint)(responsiveMedia)
      )
    );
  }

  constructor(
    protected winRef: WindowRef,
    protected layoutConfig: LayoutConfig,
    @Inject(PLATFORM_ID) protected platform: any
  ) {
    super(winRef, layoutConfig, platform);
  }

}
