<ng-container
  *ngIf="{
    thumbs: videosand360Thumbs$ | async,
    isMobile: isMobile$ | async,
    videos: videos$ | async,
    image360: image360$ | async
  } as carousel"
>
  <ng-container *ngIf="!isInPromeclub && thumbs$ | async as thumbs">
    <div
      class="slides"
      *ngIf="!carousel?.isMobile && carousel?.thumbs"
      [ngClass]="{
        'high-size': carousel?.thumbs?.length > 3,
        'small-size': carousel?.thumbs?.length <= 3
      }"
    >
      <ng-container *ngIf="carousel?.thumbs?.length > 0">
        <swiper #swiper [config]="configDesktop">
          <ng-template
            swiperSlide
            *ngFor="let item$ of carousel?.thumbs; index as i"
            class="mr-0"
          >
            <div class="custom-swipper-slide" *ngIf="item$ | async as product">
              <div *ngIf="product?.container">
                <cx-media
                  [container]="product.container"
                  tabindex="0"
                  (click)="openImageAndIndex(product.container, i)"
                  [class.is-active]="
                    (isActive(product.container) | async) &&
                    openVideo == false &&
                    openImage360 == false
                  "
                  format="product"
                >
                </cx-media>
              </div>
              <div
                *ngIf="
                  !product?.container &&
                  product?.name == undefined &&
                  carousel?.videos?.length > 0
                "
                class="custom-swipper-slide__video"
              >
                <div
                  class="video_img video_img_active"
                  (click)="openVideoMain(product, i)"
                >
                  <img
                    src="../../../../../assets/icons/play-video.svg"
                    loading="lazy"
                  />
                </div>
              </div>

              <div
                *ngIf="
                  !product?.container &&
                  product?.name?.includes('.xml') &&
                  carousel?.image360 != undefined
                "
                class="custom-swipper-slide__360-img"
                (click)="open360(product, i)"
                [class.is-active]="openImage360 == true"
              >
                <img
                  src="../../../../../assets/images/360 icon 1.png"
                  alt="360-icon"
                  loading="lazy"
                />
              </div>
            </div>
          </ng-template>
        </swiper>

        <button
          (click)="slideNext()"
          class="next-image"
          [disabled]="mainImageActive >= thumbsArray?.length - 1"
        >
          <cx-icon class="fas fa-chevron-down"></cx-icon>
        </button>
        <button
          (click)="slidePrev()"
          class="previous-image"
          [disabled]="mainImageActive <= 0"
        >
          <cx-icon class="fas fa-chevron-up"></cx-icon>
        </button>
      </ng-container>
    </div>

    <div class="slides" *ngIf="carousel?.isMobile && !isInPromeclub">
      <ng-container *ngIf="carousel?.thumbs?.length > 0; else imgMainMobile">
        <swiper
          #swiperMobile
          [config]="configMobile"
          [thumbs]="{ swiper: swiperMob.swiperRef }"
        >
          <ng-template
            swiperSlide
            *ngFor="let item$ of carousel?.thumbs"
            class="mr-0"
          >
            <div class="custom-swipper-slide" *ngIf="item$ | async as product">
              <div *ngIf="product?.container">
                <cx-media [container]="product?.container" class="main">
                </cx-media>
              </div>
              <div
                class="custom-swipper-slide__video"
                *ngIf="!product?.container && product?.name == undefined"
              >
                <video
                  #videoPlayer
                  *ngIf="baseSiteUrl + product.url"
                  width="100%"
                  height="100%"
                  [src]="baseSiteUrl + product.url"
                  [autoplay]="false"
                  [loop]="false"
                  [controls]="true"
                  [muted]="true"
                  [poster]="baseSiteUrl + product.url"
                ></video>
              </div>
              <div
                *ngIf="
                  product?.name?.includes('.xml') &&
                  carousel?.image360 != undefined
                "
              >
                <div>
                  <app-custom-image360></app-custom-image360>
                </div>
              </div>
            </div>
          </ng-template>
        </swiper>
        <swiper
          #swiperMob
          [config]="swiperMobileItems"
          [slidesPerView]="4"
          class="swiper-container gallery-thumbs"
        >
          <ng-template
            swiperSlide
            *ngFor="let item$ of carousel?.thumbs; index as i"
            class="mr-0"
          >
            <div class="custom-swipper-slide" *ngIf="item$ | async as product">
              <div *ngIf="product?.container">
                <cx-media
                  [container]="product.container"
                  tabindex="0"
                  [class.is-active]="i == swiperMobile.swiperRef.activeIndex"
                  format="product"
                >
                </cx-media>
              </div>
              <div
                *ngIf="
                  !product?.container &&
                  product?.name == undefined &&
                  carousel?.videos?.length > 0
                "
                class="custom-swipper-slide__video"
                [class.is-active]="i == swiperMobile.swiperRef.activeIndex"
              >
                <div class="video_img video_img_active">
                  <img
                    src="../../../../../assets/icons/play-video.svg"
                    loading="lazy"
                  />
                </div>
              </div>

              <div
                *ngIf="
                  !product?.container &&
                  product?.name?.includes('.xml') &&
                  carousel?.image360 != undefined
                "
                class="custom-swipper-slide__360-img"
                [class.is-active]="i == swiperMobile.swiperRef.activeIndex"
                (click)="open360(product, i)"
              >
                <img
                  src="../../../../../assets/images/360 icon 1.png"
                  alt="360-icon"
                  loading="lazy"
                />
              </div>
            </div>
          </ng-template>
        </swiper>
      </ng-container>
      <ng-template #imgMainMobile>
        <div *ngIf="mainImage$ | async as imageMain">
          <cx-media [container]="imageMain"></cx-media>
        </div>
      </ng-template>
    </div>
  </ng-container>
</ng-container>

<ng-container
  *ngIf="{
    main: mainImage$ | async,
    isMobile: isMobile$ | async,
    videos: videos$ | async,
    image360: image360$ | async
  } as imageMain"
>
  <div
    *ngIf="!openVideo && !imageMain?.isMobile && !openImage360"
    class="rotate"
  >
    <ng-container *ngIf="imageMain?.main">
      <cx-media
        [container]="imageMain?.main"
        class="product-images__image-ppal"
      ></cx-media>
    </ng-container>
  </div>

  <div *ngIf="imageMain?.isMobile && isInPromeclub">
    <ng-container *ngIf="imageMain?.main">
      <cx-media [container]="imageMain?.main"></cx-media>
    </ng-container>
  </div>

  <div *ngIf="imageMain.videos && openVideo && !imageMain?.isMobile">
    <div *ngIf="videoMain !== null" class="product-images__video">
      <video
        *ngIf="baseSiteUrl + videoMain.url"
        class="product-images__image-ppal"
        [src]="baseSiteUrl + videoMain.url"
        [autoplay]="false"
        [loop]="false"
        [controls]="true"
        [muted]="true"
      ></video>
    </div>
  </div>

  <div
    class="rotate web360"
    *ngIf="
      openImage360 && !imageMain?.isMobile && imageMain.image360 != undefined
    "
  >
    <div>
      <app-custom-image360
        class="product-images__image-ppal web360"
      ></app-custom-image360>
    </div>
  </div>
</ng-container>

<ng-template #thumb let-item="item">
  <cx-media
    [container]="item.container"
    tabindex="0"
    (focus)="openImage(item.container)"
    [class.is-active]="isActive(item.container) | async"
    format="product"
  >
  </cx-media>
</ng-template>
