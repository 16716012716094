import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SupplementHashAnchorsModule } from '@spartacus/storefront';
import { CustomSafeHtmlModule } from '../../../../../pipes/custom-safe-html/custom-safe-html.module';
import { PromesaSquareItemComponent } from './custom-square-item.component';

@NgModule({
  declarations: [
    PromesaSquareItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SupplementHashAnchorsModule,
    CustomSafeHtmlModule,
  ],
  exports: [
    PromesaSquareItemComponent
  ]
})
export class PromesaSquareItemModule { }
