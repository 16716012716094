<div class="pagination">
  <a
    *ngIf="buttons"
    [class.disabled]="isInactive(buttons[0])"
    [class.current]="isCurrent(buttons[0])"
    [routerLink]="pageRoute"
    [queryParams]="getQueryParams(buttons[0])"
    [tabIndex]="isInactive(buttons[0]) ? -1 : 0"
    (click)="isInactive(buttons[0]) ? false : pageChange(buttons[0])"
    [attr.aria-label]="getAriaLabel(buttons[0]?.label, buttons[0]?.type)"
  >
    <cx-icon class="fas fa-chevron-left"></cx-icon>
  </a>

  <p class="pagination-container" *ngIf="!isEditable">
    <span class="pagination-number"> {{ pagination?.currentPage! + 1 }} </span
    >{{ "common.of" | cxTranslate }}<span>{{ pagination?.totalPages }}</span>
  </p>

  <form [formGroup]="paginationForm" *ngIf="isEditable">
    <p class="pagination-container">
      <input
        class="pagination-number"
        type="number"
        formControlName="page"
        (keyup.enter)="onChangeOption($event.target.value)"
      />{{ "common.of" | cxTranslate }}<span>{{ pagination?.totalPages }}</span>
    </p>
  </form>
  <a
    *ngIf="buttons"
    [class.disabled]="isInactive(buttons[buttons.length - 1])"
    [class.current]="isCurrent(buttons[buttons.length - 1])"
    [routerLink]="pageRoute"
    [queryParams]="getQueryParams(buttons[buttons.length - 1])"
    [tabIndex]="isInactive(buttons[buttons.length - 1]) ? -1 : 0"
    (click)="
      isInactive(buttons[buttons.length - 1])
        ? false
        : pageChange(buttons[buttons.length - 1])
    "
    [attr.aria-label]="
      getAriaLabel(
        buttons[buttons.length - 1]?.label,
        buttons[buttons.length - 1]?.type
      )
    "
  >
    <cx-icon class="fas fa-chevron-right"></cx-icon>
  </a>
</div>
