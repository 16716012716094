<ng-container
  *ngIf="{
    cart: cart$ | async,
    error: error$ | async,
    pageCurrent: pageCurrent$ | async,
    isMobile: isMobile$ | async
  } as cart"
>
  <ng-container
    *ngIf="
      cart?.cart?.appliedVouchers?.length == 0 &&
        cart?.pageCurrent !== 'AccountPageTemplate';
      else AppliedVouchers
    "
  >
    <div class="form-group">
      <form (ngSubmit)="customApplyvouchers()" [formGroup]="couponForm">
        <div
          class="form-group__toggler"
          *ngIf="cart.isMobile"
          (click)="toggleInputVisibility()"
        >
          <cx-icon
            [class]="showCouponInput ? 'fas fa-minus' : 'fas fa-plus'"
          ></cx-icon>
          <span class="form-group__toggler">{{
            "voucher.addCouponMobile" | cxTranslate
          }}</span>
        </div>
        <div
          class="cx-cart-coupon-container"
          [ngClass]="{ hide: !showCouponInput }"
        >
          <input
            aria-required="true"
            type="text"
            class="form-control input-coupon-code m-0"
            formControlName="couponCode"
            placeholder="{{ 'voucher.placeholder' | cxTranslate }} "
            [class.invalid]="cart?.error?.length > 0 && submitCoupon"
            (keydown)="changeText($event.target)"
          />
          <button
            class="btn btn-block btn-action apply-coupon-button"
            type="submit"
            [disabled]="couponForm.invalid || submitCoupon"
            [class.disabled]="couponForm.invalid || submitCoupon"
          >
            {{ "voucher.apply" | cxTranslate }}
          </button>
        </div>
        <div
          class="cx-voucher-error"
          [ngClass]="{ hide: !showCouponInput }"
          *ngIf="cart?.error?.length > 0 && submitCoupon"
        >
          <ng-container *ngFor="let error of cart?.error">
            <p class="cx-voucher-error__label m-0">
              {{ error?.key | cxTranslate }}
            </p>
          </ng-container>
        </div>
      </form>
    </div>
  </ng-container>
  <ng-template #AppliedVouchers>
    <app-custom-applied-coupons
      [vouchers]="
        customCartVouchers ? customCartVouchers : cart?.cart?.appliedVouchers
      "
      [cartIsLoading]="cartIsLoading$ | async"
      [isReadOnly]="isReadOnlyApplied"
    >
    </app-custom-applied-coupons>
  </ng-template>
</ng-container>
