<ng-container *ngIf="stepOne$ | async">
  <div class="customer-employee-validation" [ngClass]="{'with-error': true}">
    <h3 class="customer-employee-validation__title">
      {{'registerEmployee.title' | cxTranslate}}
    </h3>
    <form class="customer-employee-validation__form" (ngSubmit)="customSubmitForm()" [formGroup]="customerValidatorForm">
      <div class="customer-employee-validation__form-input">
        <label>
          <span class="label-content">
            {{'registerEmployee.employeeCedula.label' | cxTranslate}}
          </span>
          <input
            aria-required="true"
            formControlName="ruc"
            type="text"
            class="form-control"
            placeholder="{{'registerEmployee.employeeCedula.placeholder' | cxTranslate}}"
            [ngClass]="{'error': submited && (!rucValid ||  customerValidatorForm.get('ruc')?.errors?.required)}" />
          <p class="customer-employee-validation-form-error" *ngIf="customerValidatorForm.get('ruc').hasError('numerosInvalidos') || !rucValid">
            {{'registerEmployee.employeeCedula.invalid' | cxTranslate}}
          </p>
          <p class="customer-employee-validation-form-error"
            *ngIf="submited && customerValidatorForm.get('ruc')?.errors?.required">
            {{'registerEmployee.employeeCedula.required' | cxTranslate}}
          </p>
        </label>
        <label>
          <span class="label-content">
            {{'registerEmployee.employeeCode.label' | cxTranslate}}
          </span>
          <input aria-required="true" formControlName="customerCode" type="text" class="form-control"
            placeholder="{{'registerEmployee.employeeCode.placeholder' | cxTranslate}}"
            [ngClass]="{'error': submited && (!clientCodeValid || customerValidatorForm.get('customerCode')?.errors?.required)}" />
          <p class="customer-employee-validation-form-error" *ngIf="customerValidatorForm.get('customerCode').hasError('numerosInvalidos') || !clientCodeValid">
            {{'registerEmployee.employeeCode.invalid' | cxTranslate}}
          </p>
          <p class="customer-employee-validation-form-error"
            *ngIf="submited && customerValidatorForm.get('customerCode')?.errors?.required">
            {{'registerEmployee.employeeCode.required' | cxTranslate}}
          </p>
        </label>
      </div>
      <div class="customer-employee-validation__form-action">
        <button class="customer-employee-validation__form-action-button btn btn-primary"
          [disabled]="!isFormValid">
          {{'common.continue' | cxTranslate}}
        </button>
      </div>
    </form>
  </div>
</ng-container>

