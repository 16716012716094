import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomMyCompanyComponent } from './custom-my-company.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CustomLoadingSpinnerModule } from '../custom-loading-spinner/custom-loading-spinner.module';
import { CustomEmptyCartModule } from '../custom-empty-cart/custom-empty-cart.module';
import { CustomTitleMyAccountModule } from '../custom-title-my-account/custom-title-my-account.module';



@NgModule({
  declarations: [
    CustomMyCompanyComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    CustomLoadingSpinnerModule,
    CustomEmptyCartModule,
    CustomTitleMyAccountModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        MyCompanyComponent: {
          component: CustomMyCompanyComponent,
        },
      },
    }),
  ],
  exports: [
    CustomMyCompanyComponent
  ]
})
export class CustomMyCompanyModule { }
//MyCompanyComponent
