import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { of, Subject, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomConfirmationPasswordService } from '../../services/custom-confirmation-password.service';
import { CustomConfirmationPasswordDialogComponent } from './custom-confirmation-password-dialog/custom-confirmation-password-dialog.component';

@Component({
  selector: 'app-custom-confirmation-password',
  templateUrl: './custom-confirmation-password.component.html',
  styleUrls: ['./custom-confirmation-password.component.scss']
})
export class CustomConfirmationPasswordComponent implements OnInit {
  modalRef: ModalRef;
  suscription: Subscription;
  suscriptionService: Subscription
  serviceBoolean$ = new Subject<boolean>();
  constructor(private service: CustomConfirmationPasswordService, protected modalService: ModalService, private route:ActivatedRoute) { }

  ngOnInit() {
    this.getTokenUrl()
  }

  getTokenUrl() {
   this.suscription = this.route.queryParams.subscribe(params => {
      let token = params['token'];
      this.suscriptionService = this.service.getConfirmationToken(token).pipe(
        catchError((error: any) => {
          return of(false);
        })
      ).subscribe(resp => {
        if(resp === null) {
          this.serviceBoolean$.next(true)
         } else if(resp === false ) {
          this.serviceBoolean$.next(false)
          }
      })
  });
  this.openModal()
  }

  protected openModal() {
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomConfirmationPasswordDialogComponent, {
      centered: true,
      size: 'lg',
    });
     this.modalRef.componentInstance.serviceBoolean$ = this.serviceBoolean$
  }

  ngOnDestroy(): void {
    this.suscription.unsubscribe()
    this.suscriptionService.unsubscribe()
  }

}
