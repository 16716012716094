<ng-container *ngIf="stepOne$ | async">

  <div class="customer-register" [ngClass]="{'with-error': true}">
    <h3 class="customer-register__title">
      {{'customerValidation.title' | cxTranslate}}
    </h3>
    <form action="" class="customer-register__form" (ngSubmit)="onSubmit()" [formGroup]="customerValidatorForm">
      <div class="customer-register__form-inputs">
        <label >
          <span class="label-content">
            {{'customerValidation.customerCode.label' | cxTranslate}}
          </span>
          <input
            aria-required="true"
            formControlName="customerCode"
            type="text"
            class="form-control"
            placeholder="{{'customerValidation.customerCode.placeholder' | cxTranslate}}"
            [ngClass]="{'error': !clientCodeValid || (submited && customerValidatorForm.get('customerCode')?.errors?.required)}"
          />
          <p  class="customer-register-form-error" *ngIf="!clientCodeValid">
            {{'customerValidation.customerCode.invalid' | cxTranslate}}
          </p>
          <p class="customer-register-form-error" *ngIf="submited && customerValidatorForm.get('customerCode')?.errors?.required">
            {{'customerValidation.customerCode.required' | cxTranslate}}
          </p>

        </label>

        <label >
          <span class="label-content">
            {{'customerValidation.ruc.label' | cxTranslate}}
          </span>
          <input
            aria-required="true"
            formControlName="ruc"
            type="text"
            class="form-control"
            placeholder="{{'customerValidation.ruc.placeholder' | cxTranslate}}"
            [ngClass]="{'error': !rucValid || (submited && customerValidatorForm.get('ruc')?.errors?.required)}"
          />
          <p  class="customer-register-form-error" *ngIf="!rucValid">
            {{'customerValidation.ruc.invalid' | cxTranslate}}
          </p>
          <p class="customer-register-form-error" *ngIf="submited && customerValidatorForm.get('ruc')?.errors?.required">
            {{'customerValidation.ruc.required' | cxTranslate}}
          </p>
        </label>
      </div>
      <div class="customer-register__form-action">
        <button
          class="customer-register__form-action-button"
          [disabled]="(submited && !rucValid || submited && !clientCodeValid)
          "
        >
          {{'customerValidation.button.text' | cxTranslate}}
        </button>
      </div>
    </form>
  </div>

</ng-container>
