<div class="custom-container">
  <div
    class="custom-container__info-container"
    *ngIf="componentData$ | async as info"
  >
    <app-custom-information
      [title]="info?.title"
      [description]="info?.description"
    ></app-custom-information>
  </div>

  <div [formGroup]="centerDistributionForm">
    <h2 class="custom-container__title">
      {{ "checkoutShipping.centerDistributionAddress" | cxTranslate }}
    </h2>
    <div class="checks-container">
      <ng-container *ngIf="true; else loading">
        <div class="form-check" *ngFor="let mode of centerDistributions">
          <label
            class="cx-delivery-label form-check-label form-radio-label"
            for="centerDistribution-{{ mode.code }}"
          >
            <div class="custom-container__input">
              <input
                class="form-check-input"
                role="radio"
                type="radio"
                id="centerDistribution-{{ mode.code }}"
                aria-checked="true"
                (change)="changeMode(mode.code)"
                [value]="mode.code"
                formControlName="addressId"
              />
            </div>
            <div class="custom-container__description">
              <div class="cx-delivery-mode">
                {{ mode.name }}
              </div>
              <div class="cx-delivery-details" *ngIf="showDescription">
                {{ mode.description }}
              </div>
            </div>
          </label>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="cx-spinner">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
