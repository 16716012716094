import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientTokenService, OccEndpointsService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CustomAuthorizedPerson } from '../models/custom-authorized-person';

@Injectable({
  providedIn: 'root',
})
export class CustomAuthorizedPersonService {
  showAddPerson$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  showButtonDeliveryMethod$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  public editAuthorizedPerson$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  public _listPersonAuthorized: BehaviorSubject<CustomAuthorizedPerson[]> =
    new BehaviorSubject<CustomAuthorizedPerson[]>([]);
  listPersonAuthorized$ = this._listPersonAuthorized.asObservable();
  userId: string;

  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
    protected clientTokenService: ClientTokenService,
    private userAccountFacade: UserAccountFacade
  ) {
    this.userAccountFacade
      .get()
      .pipe(map((user) => (this.userId = user?.uid!)));
  }

  setAuthorizedPerson(
    userId: string,
    authorizedPerson: CustomAuthorizedPerson
  ): Observable<any> {
    const url = this.occEndpointService.buildUrl('setAuthorizedPerson', {
      urlParams: {
        userId,
      },
    });
    return this.http.post<any>(url, authorizedPerson).pipe(
      tap(() => {
        const list = this._listPersonAuthorized.value;
        const indexPerson = list.findIndex(
          (personEdited: CustomAuthorizedPerson) =>
            personEdited.idCard === authorizedPerson.idCard
        );
        if (indexPerson > 0) {
          list[indexPerson] = authorizedPerson;
          this._listPersonAuthorized.next(list);
        } else {
          const listUpload = [...list, authorizedPerson];
          this._listPersonAuthorized.next(listUpload);
        }
      })
    );
  }

  getListAuthorizedPeople(
    userId: string
  ): Observable<CustomAuthorizedPerson[]> {
    const url = this.occEndpointService.buildUrl('getListAuthorizedPeople', {
      urlParams: {
        userId,
      },
    });
    return this.http
      .get<CustomAuthorizedPerson[]>(url)
      .pipe(tap((list) => this._listPersonAuthorized.next(list)));
  }

  editAuthorizedPerson(
    userId: string,
    authorizedPerson: CustomAuthorizedPerson
  ): Observable<any> {
    const url = this.occEndpointService.buildUrl('setAuthorizedPerson', {
      urlParams: {
        userId,
      },
    });
    return this.http.put<any>(url, authorizedPerson).pipe(
      tap(() => {
        const list = this._listPersonAuthorized.value;
        const indexPerson = list.findIndex(
          (personEdited: CustomAuthorizedPerson) =>
            personEdited.idCard === authorizedPerson.idCard
        );
        if (indexPerson >= 0) {
          list[indexPerson] = authorizedPerson;
          this._listPersonAuthorized.next(list);
        }
      })
    );
  }

  deleteAuthorizedPerson(
    userId: string,
    authorizedPerson: CustomAuthorizedPerson
  ): Observable<any> {
    const url = this.occEndpointService.buildUrl('deleteAuthorizedPerson', {
      urlParams: {
        userId,
      },
    });
    return this.http.post<any>(url, authorizedPerson).pipe(
      tap(() => {
        const list = this._listPersonAuthorized.value;
        const listUpload = list.filter(
          (lista: CustomAuthorizedPerson) =>
            lista.idCard !== authorizedPerson.idCard
        );
        this._listPersonAuthorized.next(listUpload);
      })
    );
  }

  setShowAddAuthorizedPerson(value: boolean): void {
    this.showAddPerson$.next(value);
    this.setShowButtonDeliveryMethod(!value);
  }

  getShowAddAuthorizedPerson(): Observable<boolean> {
    return this.showAddPerson$.asObservable();
  }

  setShowButtonDeliveryMethod(value: boolean): void {
    this.showButtonDeliveryMethod$.next(value);
  }

  getShowButtonDeliveryMethod(): Observable<boolean> {
    return this.showButtonDeliveryMethod$.asObservable();
  }

  setEditAuthorizedPerson(value: CustomAuthorizedPerson | null) {
    this.editAuthorizedPerson$.next(value);
  }
}
