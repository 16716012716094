import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CmsService } from '@spartacus/core';
import { OrderSummaryComponent } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';
import { CustomCheckoutDeliveryStepsService } from 'src/app/feature-libs/checkout/services/custom-checkout-delivery-steps.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomQuoteModalService } from '../../../my-account/components/custom-quotes/services/custom-quote-modal.service';
import { CustomCart } from '../../model/custom-cart';

@Component({
  selector: 'app-custom-order-summary',
  templateUrl: './custom-order-summary.component.html',
  styleUrls: ['./custom-order-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomOrderSummaryComponent
  extends OrderSummaryComponent
  implements OnInit
{
  isMobile$: Observable<boolean>;
  customDeliveryCost: number = 0;
  showColapse: boolean = false;
  showCustomDeliveryCost$: Observable<boolean> =
    this.customCheckoutStepsService.getIsDeliveryModeSelected();
  getIsAddressActive$: Observable<boolean> =
    this.customCheckoutStepsService.getIsAddressActive();
  isInPromeclub = this.service.isInPromeClub();
  @Input() customCart: CustomCart;
  @Input() showCoupon: boolean = true;
  @Input() section: string = 'cart';
  @Input() shoppingList: boolean = false;
  pageCurrent$: Observable<string | undefined> = this.cmsService
    .getCurrentPage()
    .pipe(pluck('pageId'));
  showSummaryDetails: boolean = false;
  constructor(
    private cmsService: CmsService,
    private customBreakpointService: CustomBreakpointService,
    private customCheckoutStepsService: CustomCheckoutDeliveryStepsService,
    private service: CustomNavigationService,
    private customQuoteModalService: CustomQuoteModalService,
  ) {
    super();
  }
  ngOnInit() {
    this.isMobile$ = this.customBreakpointService.isMobile$;
  }

  showQuoteDialog(code: string) {
    this.customQuoteModalService.openCancelCheckoutModal(code);
  }

  toggleSummaryDetailsVisibility(toggle: boolean): void {
    if (!toggle) {
      return;
    }
    this.showSummaryDetails = !this.showSummaryDetails;
  }
}
