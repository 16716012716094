<ng-container *ngIf="component.data$ | async as data">
  <ng-container *ngIf="data.title">
    <p class="title" [innerHTML]="data.title | safeHtml"></p>
    <div class="title-line"></div>
  </ng-container>
  <p
    *ngIf="data.question"
    class="question"
    [innerHTML]="data.question | safeHtml"
    [ngClass]="{ open: isOpen }"
    (click)="toggle()"
  ></p>
  <p
    *ngIf="data.answer && isOpen"
    class="answer"
    [innerHTML]="data.answer | safeHtml"
  ></p>
</ng-container>
