<ng-container
  *ngIf="{
    subcategories: subcategories$ | async,
    category: category$ | async
  } as data"
>
  <app-custom-product-variant-list-description
    [description]="data?.category?.description"
  ></app-custom-product-variant-list-description>

  <app-custom-product-variant-list-card
    [subcategories]="data.subcategories"
    *ngIf="showSubCategories"
  ></app-custom-product-variant-list-card>
</ng-container>
