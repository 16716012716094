export class CustomReturnOrder {
  orderCode: string;
  returnRequestEntryInputs: CustomReturnOrderDetail[];
  comments: string;
}

export class CustomReturnOrderDetail {
  codeProd?: string;
  itemSelected?: boolean;
  orderEntryNumber?: number;
  quantity?: number;
  reason?: string;
  images?: string[];
  files?: any[];
  isValid?: boolean;
}

export enum CurrentPage {
  REVISION_ORDER_RETURN = 'REVISION_ORDER_RETURN',
  EDIT_ORDER_RETURN = 'EDIT_ORDER_RETURN',
}
