import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomTalleresFinderComponent } from './custom-talleres-finder/custom-talleres-finder.component';
import { CustomTalleresComponent } from './custom-talleres.component';

@NgModule({
  declarations: [
    CustomTalleresComponent,
    CustomTalleresFinderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    GoogleMapsModule,
    ReactiveFormsModule,
    I18nModule,
    IconModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        TalleresComponent: {
          component: CustomTalleresComponent,
        },
      },
    })
  ],
  exports: [
    CustomTalleresFinderComponent
  ]
})
export class CustomTalleresModule { }
