import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductCardDetailsComponent } from './custom-product-card-details.component';



@NgModule({
  declarations: [CustomProductCardDetailsComponent],
  imports: [
    CommonModule
  ],
  exports: [
    CustomProductCardDetailsComponent
  ]
})
export class CustomProductCardDetailsModule { }
