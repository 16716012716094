<ng-container *ngIf="!((isMobile$ | async) && section === 'checkout')">

<div
  class="quote-summary"
  *ngIf="customCart?.isFromQuote && section !== 'order'"
>
  <div
    [innerHTML]="'orderCost.orderSummary' | cxTranslate | safeHtml"
    class="cx-order-summary-title"
    [ngClass]="{
      'cx-order-summary-title_checkout': section === 'checkout'
    }"
    *ngIf="!isInPromeclub && section !== 'order'"
  ></div>
  <div
    class="quote-summary__action"
    (click)="showQuoteDialog(customCart?.code)"
  >
    {{ "orderCost.cancel" | cxTranslate }}
  </div>
</div>
<div
  [innerHTML]="'orderCost.orderSummary' | cxTranslate | safeHtml"
  class="cx-order-summary-title"
  [ngClass]="{
    'cx-order-summary-title_checkout': section === 'checkout'
  }"
  *ngIf="
    !isInPromeclub &&
    section !== 'order' &&
    !customCart?.isFromQuote &&
    !(isMobile$ | async)
  "
></div>
<div
  class="cx-order-summary-title mobile"
  [ngClass]="{
    'cx-order-summary-title_checkout': section === 'checkout'
  }"
  (click)="toggleSummaryDetailsVisibility(section === 'cart')"
  *ngIf="
    !isInPromeclub &&
    section !== 'order' &&
    !customCart?.isFromQuote &&
    (isMobile$ | async)
  "
>
  <span [innerHTML]="'orderCost.orderSummary' | cxTranslate | safeHtml"></span>
  <cx-icon
    *ngIf="section === 'cart'"
    [class]="showSummaryDetails ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"
  ></cx-icon>
</div>
<div
  [innerHTML]="'orderCost.orderDetailSummary' | cxTranslate | safeHtml"
  class="cx-order-summary-title"
  [ngClass]="{
    'cx-order-summary-title_order': section === 'order'
  }"
  *ngIf="!isInPromeclub && section === 'order' && !shoppingList"
></div>

  <div [innerHTML]="'orderCost.orderSummary' | cxTranslate | safeHtml" class="cx-order-summary-title" [ngClass]="{
        'cx-order-summary-title_checkout': section === 'checkout'
      }" *ngIf="
        (isInPromeclub && section === 'checkout') ||
        (isInPromeclub && section === 'cart' && !(isMobile$ | async))
      "></div>
</ng-container>

<div class="cx-summary-partials" *ngIf="customCart as cart" [ngClass]="{
    'cx-summary-partials_cart': section === 'cart',
      'cx-summary-partials_checkout': section === 'checkout',
      'cx-summary-partials_order': section === 'order'
    }">
  <ng-container *ngIf="pageCurrent$ | async as pageCurrent">
    <ng-container *ngIf="!isInPromeclub">
      <div class="cx-summary-coupon" *ngIf="showCoupon && !shoppingList">
        <app-custom-cart-coupon [customCartVouchers]="
              cart.appliedVouchers ? cart.appliedVouchers : null
            " [isReadOnlyApplied]="section === 'order' ? true : false"></app-custom-cart-coupon>
      </div>
      <div
        class="cx-summary-details"
        [ngClass]="{
          'cx-summary-details_cart': section === 'cart',
          'cx-summary-details_checkout': section === 'checkout',
          'cx-summary-details_order': section === 'order',
          'shopping-list': shoppingList,
          hide: !showSummaryDetails
        }"
      >
        <div class="cx-summary-row">
          <div class="col-7 col-md-6 cx-summary-label">
            {{ "orderCost.products" | cxTranslate }}
          </div>
          <div class="col-5 col-md-6 cx-summary-amount">
            {{ cart.subTotal?.value | customCurrency }}
          </div>
        </div>
        <div class="cx-summary-row" *ngIf="!shoppingList">
          <div class="col-7 col-md-6 cx-summary-label">
            {{ "orderCost.discount" | cxTranslate }}
          </div>
          <div class="col-5 col-md-6 cx-summary-amount">
            {{ cart.totalDiscounts?.value | customCurrency }}
          </div>
        </div>
        <div class="cx-summary-row" *ngIf="
              cart.ivaConditionPriceMap?.length > 0 &&
              cart?.ivaConditionPriceMap[1]
            ">
          <div class="col-7 col-md-6 cx-summary-label">
            {{ "saveForLaterItems.salePercentage" | cxTranslate }}
            {{ cart.ivaConditionPriceMap[1]?.key }}
          </div>
          <div class="col-5 col-md-6 cx-summary-amount">
            {{ cart.ivaConditionPriceMap[1]?.value | customCurrency }}
          </div>
        </div>
        
        <div class="cx-summary-row" *ngIf="
              cart.ivaConditionPriceMap?.length > 0 &&
              cart?.ivaConditionPriceMap[2]
            ">
          <div class="col-7 col-md-6 cx-summary-label">
            {{ "saveForLaterItems.salePercentage" | cxTranslate }}
            {{ cart.ivaConditionPriceMap[2]?.key }}
          </div>
          <div class="col-5 col-md-6 cx-summary-amount">
            {{ cart.ivaConditionPriceMap[2]?.value | customCurrency }}
          </div>
        </div>

        <div class="cx-summary-row" *ngIf="
              cart.ivaConditionPriceMap?.length > 0 &&
              cart?.ivaConditionPriceMap[0]
            ">
          <div class="col-7 col-md-6 cx-summary-label">
            {{ "saveForLaterItems.salePercentage" | cxTranslate }}
            {{ cart.ivaConditionPriceMap[0]?.key }}
          </div>
          <div class="col-5 col-md-6 cx-summary-amount">
            {{ cart.ivaConditionPriceMap[0]?.value | customCurrency }}
          </div>
        </div>
        <ng-container *ngIf="
              (section === 'checkout' && (getIsAddressActive$ | async)) ||
              section === 'order' as showDeliveryCost
            ">
          <ng-container *ngIf="
                (cart.deliveryCost == undefined ||
                  !(showCustomDeliveryCost$ | async)) &&
                section != 'order'
              ">
            <div class="cx-summary-row delivery_cost_checkout">
              <div class="col-7 col-md-6 cx-summary-label">
                {{
                "orderDetails.consignmentTracking.dialog.carrier"
                | cxTranslate
                }}
              </div>
              <div class="col-5 col-md-6 cx-summary-amount">
                {{ customDeliveryCost | customCurrency }}
              </div>
            </div>
          </ng-container>
          <ng-container #deliveryCost *ngIf="(showCustomDeliveryCost$ | async) || section == 'order'">
            <div class="cx-summary-row delivery_cost_checkout" *ngIf="cart.deliveryCost">
              <div class="col-7 col-md-6 cx-summary-label">
                {{
                "orderDetails.consignmentTracking.dialog.carrier"
                | cxTranslate
                }}
              </div>
              <div class="col-5 col-md-6 cx-summary-amount">
                {{ cart.deliveryCost?.value | customCurrency }}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div
        class="cx-summary-row cx-summary-subtotal"
        [ngClass]="{
          'cx-summary-subtotal_cart': section === 'cart',
          'cx-summary-subtotal_checkout': section === 'checkout',
          'cx-summary-subtotal_order': section === 'order',
          hide: !showSummaryDetails
        }"
      >
        <div class="col-7 col-md-6 cx-summary-label">
          {{ "orderCost.subtotal" | cxTranslate }}
        </div>
        <div class="col-5 col-md-6 cx-summary-amount">
          {{
          pageCurrent == "cartPage"
          ? (cart.subTotal?.value | customCurrency)
          : (cart.totalPrice?.value | customCurrency)
          }}
        </div>
      </div>

      <div
        class="cx-summary-iva"
        [ngClass]="{
          'cx-summary-iva_cart': section === 'cart',
          'cx-summary-iva_checkout': section === 'checkout',
          'cx-summary-iva_order': section === 'order',
          hide: !showSummaryDetails
        }"
      >
        <div class="cx-summary-row">
          <div class="col-6 cx-summary-label">
            {{ "orderCost.grossTax" | cxTranslate }}
          </div>
          <div class="col-6 cx-summary-amount">
            {{
            pageCurrent == "cartPage"
            ? (cart.subtotalTax?.value | customCurrency)
            : (cart.totalTax?.value | customCurrency)
            }}
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="
        section === 'checkout' ||
        section === 'order' ||
        !(isMobile$ | async) as isMobile
      ">
    <div class="cx-summary-row cx-summary-total" [ngClass]="{
          'cx-summary-total_order': section === 'order',
          'cx-summary-total_checkout': section === 'checkout',
          'mt-0': isInPromeclub
        }">
      <div class="cx-summary-label" [ngClass]="{ 'col-6': !isInPromeclub, 'col-5': isInPromeclub }">
        {{ "orderCost.total" | cxTranslate }}
      </div>
      <div class="cx-summary-amount" [ngClass]="{ 'col-6': !isInPromeclub, 'col-7': isInPromeclub }">
        <ng-container *ngIf="pageCurrent$ | async as pageCurrent">
          <span class="w-100" *ngIf="!isInPromeclub">
            {{
            pageCurrent == "cartPage"
            ? (cart.subTotalWithTax?.value | customCurrency)
            : (cart.totalPriceWithTax?.value | customCurrency)
            }}
          </span>
          <span class="w-100" *ngIf="isInPromeclub">
            {{ cart.totalPrice?.value | number : "" : "es-AR" }}
            <span class="regular-points">{{
              "productList.points" | cxTranslate
              }}</span>
          </span>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
