import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FacetValue } from '@spartacus/core';
import { FacetList, FacetService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CustomBreakpointService } from '../../../services/custom-breakpoint.service';
import { Options } from '@angular-slider/ngx-slider';
import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RangeSliderComponent implements OnInit, OnDestroy {
  @Input() facet: any;
  @Input() min: any;
  @Input() max: any;
  @Input() name: string;
  @Input() startValues: Array<number>;
  @Output() filtersApplied = new EventEmitter<Array<number>>();
  private rangeValues: Array<number> = [0, 0];
  url: any;
  minValue: any;
  maxValue: any;
  filterDisabled: boolean = true;
  facetList$: Observable<FacetList> = this.facetService.facetList$;
  minFormValue: FormControl;
  maxFormValue: FormControl;
  rangeForm: FormGroup;
  isInPromeclub: boolean = this.service.isInPromeClub();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  options: Options;
  ceil: number;
  subscription: Subscription = new Subscription();
  promeclubType: string;
  activeMin: string | undefined;
  activeMax: string | undefined;
  constructor(
    protected facetService: FacetService,
    protected elementRef: ElementRef,
    protected fb: FormBuilder,
    protected customBreakpointService: CustomBreakpointService,
    private router: Router,
    private service: CustomNavigationService
  ) {}

  ngOnInit(): void {
    this.rangeForm = new FormGroup({
      sliderControl: new FormControl([
        Math.round(Number(this.min) * 100) / 100,
        Math.round(Number(this.max) * 100) / 100,
      ]),
    });
    this.minFormValue = new FormControl(0, Validators.required);
    this.maxFormValue = new FormControl(
      Math.round(Number(this.max) * 100) / 100,
      Validators.required
    );
    this.subscription.add(
      this.facetList$.subscribe((facets) => {
        this.getActiveValues(facets);
      })
    );
    this.optionsHandler();
  }

  public applyFilters() {
    this.filtersApplied.emit(this.rangeValues);
  }

  getLinkParams(value: FacetValue) {
    const linkParams = this.facetService.getLinkParams(
      value?.query?.query?.value!
    );

    if (this.router.url.includes('variant')) {
      linkParams.plpType = 'variant';
    }
    if (this.router.url.includes('classic')) {
      linkParams.plpType = 'classic';
    }
    if (this.isInPromeclub) {
      let paramsSplitted = linkParams.query.split(':');
      this.promeclubType = paramsSplitted.filter((param) =>
        param.includes('PMC-')
      )[0];
    }
    return linkParams;
  }

  optionsHandler() {
    if (!this.isInPromeclub) {
      this.options = {
        floor: 0,
        ceil: this.ceil,
        step: 0.5,
        maxLimit: this.ceil,
        translate: (value: number): string => {
          return (
            '$ ' +
            value.toLocaleString('es-AR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          );
        },
      };
    } else {
      this.options = {
        floor: 0,
        ceil: this.ceil,
        step: 1,
        maxLimit: this.ceil,
        translate: (value: number): string => {
          return value.toLocaleString('es-AR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
        },
      };
    }
  }

  createUrl(isMobile: boolean = false) {
    this.url = this.getLinkParams(this.facet.values[0]);
    const min = !isMobile
      ? this.minFormValue.value
      : this.rangeForm.get('sliderControl')?.value[0];
    const max = !isMobile
      ? this.maxFormValue.value
      : this.rangeForm.get('sliderControl')?.value[1];
    let indexCut = this.name.indexOf('_');
    let finalText = this.name.slice(0, indexCut);
    var finalUrl = finalText + ':[' + [min + ' TO ' + max] + ']';
    if (this.isInPromeclub) {
      return this.promeUrl(finalUrl);
    }
    this.url['query'] = this.url['query']
      .replace(/:priceValue:\[.*\]/g, '')
      .replace(/:priceValue:\d{1,100}[\,\.]{1}\d{1,100}/gm, '');
    this.url['query'] = this.url['query'] + ':' + finalUrl;
    return this.url;
  }

  promeUrl(finalUrl: string) {
    let url = this.getLinkParams(this.facet.values[0]);
    url['query'] = url['query']
      .replace(/\d{1,100}[\,\.]{1}\d{1,100}/gm, '')
      .replace('::', '');
    url['query'] = url['query'] + ':' + finalUrl;
    if (this.activeMin && this.activeMax) {
      url['query'] = url['query'].replace(
        `[${this.activeMin} TO ${this.activeMax}]`,
        ''
      );
      url['query'] = url['query'].replace(`${this.promeclubType}:`, '');
      url['query'] = url['query'].replace(`::`, ':');
    }
    return url;
  }

  getActiveValues(facetList: FacetList) {
    const priceFacet = facetList?.activeFacets?.filter(
      (f) => f?.facetName == 'Precio' || f?.facetName == 'Puntos'
    );
    if (priceFacet?.length) {
      const activeMin = priceFacet?.[0].facetValueName
        ?.split('[')[1]
        .split(' TO ')[0];
      const activeMax = priceFacet?.[0].facetValueName
        ?.split('[')[1]
        .split(' TO ')[1]
        .split(']')[0];
      this.minFormValue.setValue(Number(activeMin));
      this.maxFormValue.setValue(Number(activeMax));
      this.rangeForm
        .get('sliderControl')
        ?.setValue([Number(activeMin), Number(activeMax)]);
      this.activeMin = activeMin;
      this.activeMax = activeMax;
    }
  }

  disableButton(isMobile: boolean = false): boolean {
    if (!isMobile) {
      if (
        this.minFormValue.value >= this.maxFormValue.value ||
        this.minFormValue.value < 0
      ) {
        return true;
      }
      return false;
    } else {
      if (
        this.rangeForm.get('sliderControl')?.value[0] >=
          this.rangeForm.get('sliderControl')?.value[1] ||
        this.rangeForm.get('sliderControl')?.value[0] < 0 ||
        this.rangeForm.get('sliderControl')?.value[1] <= this.min
      ) {
        return true;
      }
      return false;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
