import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuoteForm } from '../models/quote-form.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomQuoteOrchestratorService {
  initialQuoteForm: QuoteForm = {
    comment: '',
    counted: false,
    countedMethod: null,
    credit: false,
    creditCondition: null,
    reference: '',
  };
  itemsInReview: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  itemsAlreadyReviewed: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );
  quoteForm: BehaviorSubject<QuoteForm> = new BehaviorSubject<QuoteForm>(
    this.initialQuoteForm
  );
  activeButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  goToSummary: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  inQuoteDetail: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  inQuoteOffer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  refreshQuoteDetail: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  activeCheckoutButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  checkQuoteDetailPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  constructor() {}

  setItemsInReview(value: number) {
    this.itemsInReview.next(this.itemsInReview.value + value);
  }

  resetItemsInReview() {
    this.itemsInReview.next(0);
  }

  resetFormData() {
    this.quoteForm.next(this.initialQuoteForm);
  }

  resetGoToSummary() {
    this.goToSummary.next(false);
  }

  getItemsInReview(): Observable<number> {
    return this.itemsInReview.asObservable();
  }

  setFormData(value: QuoteForm) {
    this.quoteForm.next(value);
  }

  getFormData(): Observable<QuoteForm> {
    return this.quoteForm.asObservable();
  }

  buttonIsActive(): Observable<boolean> {
    return combineLatest([
      this.quoteForm.asObservable(),
      this.itemsInReview.asObservable(),
    ]).pipe(
      map(([form, itemsInReview]) => {
        if (
          form?.comment?.length > 0 &&
          form?.reference?.length > 0 &&
          itemsInReview > 0
        ) {
          if (form?.counted && form?.countedMethod != null) {
            return true;
          } else if (form?.credit && form?.creditCondition != null) {
            return true;
          }
          return false;
        }
        return false;
      })
    );
  }

  setGoToSummary(value: boolean) {
    this.goToSummary.next(value);
  }

  getGoToSummary(): Observable<boolean> {
    return this.goToSummary.asObservable();
  }

  setInQuoteDetail(value: boolean) {
    this.inQuoteDetail.next(value);
  }

  getInQuoteDetail(): Observable<boolean> {
    return this.inQuoteDetail.asObservable();
  }

  setActiveCheckoutButton(value: boolean) {
    this.activeCheckoutButton.next(value);
  }

  getActiveCheckoutButton(): Observable<boolean> {
    return this.activeCheckoutButton.asObservable();
  }

  setItemsAlreadyReviewed(value: number) {
    this.itemsAlreadyReviewed.next(this.itemsAlreadyReviewed.value + value);
  }

  getItemsAlreadyReviewed(): Observable<number> {
    return this.itemsAlreadyReviewed.asObservable();
  }

  resetItemsAlreadyReviewed() {
    this.itemsAlreadyReviewed.next(0);
  }

  setInQuoteOffer(value: boolean) {
    this.inQuoteOffer.next(value);
  }

  getInQuoteOffer(): Observable<boolean> {
    return this.inQuoteOffer.asObservable();
  }

  setRefreshQuoteDetail(value: boolean) {
    this.refreshQuoteDetail.next(value);
  }

  getRefreshQuoteDetail(): Observable<boolean> {
    return this.refreshQuoteDetail.asObservable();
  }

  resetOrchestrator() {
    this.itemsAlreadyReviewed.next(0);
    this.itemsInReview.next(0);
    this.activeButton.next(false);
    this.quoteForm.next(this.initialQuoteForm);
    this.goToSummary.next(false);
    this.inQuoteDetail.next(false);
    this.inQuoteOffer.next(false);
    this.activeCheckoutButton.next(false);
    this.refreshQuoteDetail.next(false);
    this.checkQuoteDetailPage.next(false);
  }

  setCheckQuoteDetailPage(value: boolean) {
    this.checkQuoteDetailPage.next(value);
  }

  checkIfIsInQuoteDetail(): Observable<boolean> {
    return this.checkQuoteDetailPage.asObservable();
  }
}
