import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomLoginFormComponent } from './custom-login-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  UrlModule,
  I18nModule,
  AuthService,
  CmsConfig,
  GlobalMessageService,
  NotAuthGuard,
  WindowRef,
  ConfigModule,
} from '@spartacus/core';
import { FormErrorsModule, IconModule } from '@spartacus/storefront';
import { LoginFormComponentService } from '@spartacus/user/account/components';
import { CustomB2bValidationModule } from '../custom-b2b-validation-dialog/custom-b2b-validation-dialog.module';
import { CustomLoadingSpinnerModule } from '../../custom/components/custom-loading-spinner/custom-loading-spinner.module';

@NgModule({
  declarations: [CustomLoginFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    IconModule,
    CustomB2bValidationModule,
    CustomLoadingSpinnerModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ReturningCustomerLoginComponent: {
          component: CustomLoginFormComponent,
          guards: [NotAuthGuard],
          providers: [
            {
              provide: LoginFormComponentService,
              useClass: LoginFormComponentService,
              deps: [AuthService, GlobalMessageService, WindowRef],
            },
          ],
        },
      },
    }),
  ],
  exports: [CustomLoginFormComponent],
})
export class CustomLoginFormModule {}
