import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FeaturesConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { CardModule, CartSharedModule, IconModule, ModalModule, OrderOverviewModule, PromotionsModule, SpinnerModule } from '@spartacus/storefront';
import { CustomModalGenericModule } from 'src/app/cms-components/custom/components/custom-modal/custom-modal-generic.module';
import { CustomSafeHtmlModule } from 'src/app/pipes/custom-safe-html/custom-safe-html.module';
import { CustomOrderDetailsService } from '../../services/custom-order-details.service';
import { CustomOrderDetailActionsModule } from './custom-order-detail-actions/custom-order-detail-actions.module';
import { CustomOrderDetailItemsModule } from './custom-order-detail-items/custom-order-detail-items.module';
import { CustomOrderDetailShippingModule } from './custom-order-detail-shipping/custom-order-detail-shipping.module';
import { CustomOrderDetailTotalsModule } from './custom-order-detail-totals/custom-order-detail-totals.module';
import { CustomPromeclubOrderDetailsModule } from './custom-promeclub-order-details/custom-promeclub-order-details.module';
import { CustomReorderDialogComponent } from './custom-reorder/custom-reorder-dialog/custom-reorder-dialog.component';
import { CustomReorderInfoDialogComponent } from './custom-reorder/custom-reorder-info-dialog/custom-reorder-info-dialog.component';



@NgModule({
  declarations: [
    CustomReorderDialogComponent,
    CustomReorderInfoDialogComponent
  ],
  imports: [
    CommonModule,
    CartSharedModule,
    CardModule,
    CommonModule,
    I18nModule,
    FeaturesConfigModule,
    PromotionsModule,
    OrderOverviewModule,
    UrlModule,
    SpinnerModule,
    IconModule,
    ModalModule,
    RouterModule,
    UrlModule,
    CustomSafeHtmlModule,
    CustomOrderDetailActionsModule,
    CustomOrderDetailItemsModule,
    CustomOrderDetailShippingModule,
    CustomOrderDetailTotalsModule,
    CustomPromeclubOrderDetailsModule,
    CustomModalGenericModule
  ],
  providers: [
    CustomOrderDetailsService,
  ]
})
export class CustomOrderDetailsModule { }
