<ng-container>
  <div class="custom-order-history__title">
    <app-custom-title-my-account
      [title]="'orderDetails.cancellationAndReturn.cancelHistory' | cxTranslate"
    ></app-custom-title-my-account>
  </div>
  <ng-container *ngIf="orders$ | async as orders; else spinnerLoading">
    <div class="custom-order-history-body">
      <ng-container *ngIf="orders.pagination.totalResults > 0; else noOrder">
        <div class="custom-order-history-filters top">
          <div class="custom-order-history-pagination">
            <app-custom-filter
              [label]="'orderHistory.filterResults' | cxTranslate"
              [entries]="quantities"
              [classSelect]="'small-filter'"
              [valueDefault]="pageSize"
              (filterSelected)="filterQuantitySelected($event)"
            >
            </app-custom-filter>
            <app-custom-pagination
              *ngIf="orders.pagination.totalPages >= 1"
              [pagination]="orders.pagination"
              (viewPageEvent)="customPageChangeSelected($event)"
              [pageSelected]="selectedPage"
            ></app-custom-pagination>
          </div>
        </div>
        <!-- TABLE -->
        <app-custom-account-table
          [orders]="orders"
          [cancellationsPage]="cancellationsPage"
          (goToDetails)="goToOrderCancelledDetail($event)"
        ></app-custom-account-table>
        <!-- Select Form and Pagination Bottom -->
        <div
          class="custom-order-history-sort"
          *ngIf="orders.pagination?.totalResults > 0"
        >
          <div
            *ngIf="orders.pagination?.totalPages >= 1"
            class="custom-order-history-pagination"
          >
            <app-custom-pagination
              [pagination]="orders.pagination"
              (viewPageEvent)="customPageChangeSelected($event)"
              [pageSelected]="selectedPage"
            ></app-custom-pagination>
          </div>
        </div>
      </ng-container>

      <!-- NO ORDER CONTAINER -->
      <ng-template #noOrder>
        <div class="custom-order-history-no-order">
          <app-custom-empty-cart [fromMyAccount]="true"></app-custom-empty-cart>
        </div>
      </ng-template>
    </div>
  </ng-container>
</ng-container>
<ng-template #spinnerLoading>
  <div class="custom-order-history-body custom-order-history-body__loading">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
