<ng-container *ngIf="component.data$ | async as data">
  <div style="width: 100%">
    <app-custom-talleres-finder
      (workshops)="workshops($event, zoom)"
      (selectedWorkshop)="selectedWorkshop($event)"
    ></app-custom-talleres-finder>

    <google-map [zoom]="zoom" width="100%" [height]="height" [center]="center">
      <ng-container *ngIf="data$ | async as data">
        <ng-container *ngFor="let taller of data">
          <map-marker
            #someMarker="mapMarker"
            [position]="taller?.position"
            (mapClick)="openInfo(someMarker, taller?.info, taller?.title)"
          >
          </map-marker>

          <map-info-window>
            <div class="info-container">
              <p *ngIf="taller?.title" class="info-container__title">
                {{ infoTitle }}
              </p>
            </div>
          </map-info-window>
        </ng-container>
      </ng-container>
    </google-map>
  </div>
</ng-container>
