<ng-container
  *ngIf="(stepThree$ | async) || fromResetPassword || fromMyAccount"
>
  <ng-container
    *ngIf="{
      isMobile: isMobile$ | async
    } as data"
  >
    <div class="register-password">
      <h3 class="register-password__title" *ngIf="!fromResetPassword">
        {{ "customRegister.passwordConfig" | cxTranslate }}
      </h3>
      <h3 class="register-password__title" *ngIf="fromResetPassword">
        {{ "forgottenPassword.resetPassword" | cxTranslate }}
      </h3>
      <form
        class="register-password__form"
        (ngSubmit)="passwordSubmit(resetPasswordToken)"
        [formGroup]="passwordForm"
      >
        <div class="register-password__form-inputs">
          <label>
            <span class="label-content" *ngIf="fromRegisterEmployee">
              {{ "customRegister.enterPassword" | cxTranslate }}
            </span>
            <span class="label-content" *ngIf="!fromRegisterEmployee">
              {{ "forgottenPassword.enterNewPassword" | cxTranslate }}
            </span>
            <input
              aria-required="true"
              formControlName="password"
              [type]="passwordType"
              class="form-control"
              placeholder="{{
                'customRegister.enterPasswordPlaceholder' | cxTranslate
              }}"
            />
            <cx-icon
              [class]="!showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
              (click)="togglePasswordView()"
            ></cx-icon>
          </label>
          <ng-container *ngIf="data.isMobile">
            <ng-container *ngTemplateOutlet="validation"> </ng-container>
          </ng-container>

          <label>
            <span class="label-content" *ngIf="fromRegisterEmployee">
              {{ "customRegister.repeatPassword" | cxTranslate }}
            </span>
            <span class="label-content" *ngIf="!fromRegisterEmployee">
              {{ "forgottenPassword.repeatNewPassword" | cxTranslate }}
            </span>
            <input
              aria-required="true"
              formControlName="repeatPassword"
              [type]="repeatPasswordType"
              class="form-control"
              placeholder="{{
                'customRegister.repeatPasswordPlaceholder' | cxTranslate
              }}"
            />
            <cx-icon
              [class]="!showRepeatPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
              (click)="togglePasswordView(true)"
            ></cx-icon>
            <p
              class="customer-register-form-error"
              *ngIf="notTheSamePasswordError"
            >
              {{ "customRegister.passwordDontMatch" | cxTranslate }}
            </p>
          </label>
        </div>
        <ng-container *ngIf="!data.isMobile">
          <ng-container *ngTemplateOutlet="validation"> </ng-container>
        </ng-container>

        <div
          class="register-password__form-action"
          [ngClass]="{ 'with-error': notTheSamePasswordError,
          'register-password__form-action__update-profile': fromMyAccount }"
        >
          <button
            *ngIf="!fromMyAccount"
            class="register-password__form-action-button"
            type="submit"
            [disabled]="
              this.passwordForm.invalid ||
              this.passwordForm.get('repeatPassword').value.length < 8 ||
              disableButton
            "
          >
            {{ "customRegister.continue" | cxTranslate }}
          </button>
          <button
            *ngIf="fromMyAccount"
            class="register-password__form-action-button update-profile"
            type="submit"
            [disabled]="
              currentPassForm.invalid ||
              this.passwordForm.invalid ||
              this.passwordForm.get('repeatPassword').value.length < 8 ||
              disableButton
            "
          >
            {{ "customUpdateProfileForm.modifyPassword" | cxTranslate }}
          </button>
        </div>
      </form>
    </div>
  </ng-container>
</ng-container>

<ng-template #validation>
  <div class="register-password__form-validation">
    <p
      class="register-password__form-validation-item"
      [ngClass]="{
        valid:
          !passwordStepsErrors.stepOne && passwordForm.get('password')?.dirty
      }"
    >
      {{ "customRegister.passwordValidations.stepOne" | cxTranslate }}
      <ng-container *ngIf="passwordForm.get('password')?.dirty">
        <cx-icon
          [class]="
            !passwordStepsErrors.stepOne ? 'fas fa-check' : 'fas fa-times'
          "
        ></cx-icon>
      </ng-container>
    </p>
    <p
      class="register-password__form-validation-item"
      [ngClass]="{
        valid:
          !passwordStepsErrors.stepTwo && passwordForm.get('password')?.dirty
      }"
    >
      {{ "customRegister.passwordValidations.stepTwo" | cxTranslate }}
      <ng-container *ngIf="passwordForm.get('password')?.dirty">
        <cx-icon
          [class]="
            !passwordStepsErrors.stepTwo ? 'fas fa-check' : 'fas fa-times'
          "
        ></cx-icon>
      </ng-container>
    </p>
    <p
      class="register-password__form-validation-item"
      [ngClass]="{
        valid:
          !passwordStepsErrors.stepThree && passwordForm.get('password')?.dirty
      }"
    >
      {{ "customRegister.passwordValidations.stepThree" | cxTranslate }}
      <ng-container *ngIf="passwordForm.get('password')?.dirty">
        <cx-icon
          [class]="
            !passwordStepsErrors.stepThree ? 'fas fa-check' : 'fas fa-times'
          "
        ></cx-icon>
      </ng-container>
    </p>
    <p
      class="register-password__form-validation-item"
      [ngClass]="{
        valid:
          !passwordStepsErrors.stepFour && passwordForm.get('password')?.dirty
      }"
    >
      {{ "customRegister.passwordValidations.stepFour" | cxTranslate }}
      <ng-container *ngIf="passwordForm.get('password')?.dirty">
        <cx-icon
          [class]="
            !passwordStepsErrors.stepFour ? 'fas fa-check' : 'fas fa-times'
          "
        ></cx-icon>
      </ng-container>
    </p>
    <p
      class="register-password__form-validation-item"
      [ngClass]="{
        valid:
          !passwordStepsErrors.stepFive && passwordForm.get('password')?.dirty
      }"
    >
      {{ "customRegister.passwordValidations.stepFive" | cxTranslate }}
      <ng-container *ngIf="passwordForm.get('password')?.dirty">
        <cx-icon
          [class]="
            !passwordStepsErrors.stepFive ? 'fas fa-check' : 'fas fa-times'
          "
        ></cx-icon>
      </ng-container>
    </p>
  </div>
</ng-template>
