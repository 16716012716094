import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { CustomBreakpointService } from '../../../../../services/custom-breakpoint.service';
import { CustomRegisterErrorDialogComponent } from '../../custom-register-shared/custom-register-error/custom-register-error-dialog.component';
import { CustomRegisterNonCustomerConfirmationComponent } from '../../custom-register-shared/custom-register-non-customer-confirmation/custom-register-non-customer-confirmation.component';
import { CustomerAlreadyExistsDialogComponent } from '../../custom-register-shared/customer-already-exists-dialog/customer-already-exists-dialog.component';
import { CustomBusinessType } from '../../models/custom-business-type.interface';
import { CustomDoc } from '../../models/custom-doc-data.interface';
import { CustomRegisterNonCustomerFormData } from '../../models/custom-register-non-customer-form.interface';
import { CustomNonCustomerFormValidationService } from '../../services/custom-non-customer-form-validation.service';
import { CustomRecaptchaService } from '../../services/custom-recaptcha.service';
import { CustomRegisterDocsService } from '../../services/custom-register-docs.service';
import { CustomRegisterNonClientFormService } from '../../services/custom-register-non-client-form.service';
import { CustomRegisterRegionsService } from '../../services/custom-register-regions.service';
import { CustomRegisterStepOrchestratorService } from '../../services/custom-register-step-orchestrator.service';

@Component({
  selector: 'app-custom-register-non-customer-form',
  templateUrl: './custom-register-non-customer-form.component.html',
  styleUrls: ['./custom-register-non-customer-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomRegisterNonCustomerFormComponent
  implements OnInit, OnDestroy
{
  personTypeSelected: string;
  nonCustomerForm: FormGroup = this.fb.group(
    {
      naturalPerson: [false, [Validators.required]],
      juridicPerson: [false, [Validators.required]],
      socialReason: ['', [Validators.required]],
      comercialName: ['', [Validators.required]],
      ruc: [
        '',
        [
          Validators.required,
          this.customNonCustomerFormValidationService.customRucValidation,
        ],
      ],
      rucLastUpdate: [null, [Validators.required]],
      bussinessType: [null, [Validators.required]],
      email: [
        '',
        [
          Validators.required,
          this.customNonCustomerFormValidationService.customEmailValidation,
        ],
      ],
      phonePrefix: [null, Validators.required],
      phone: [
        '',
        [
          Validators.required,
          this.customNonCustomerFormValidationService.customPhoneValidation,
        ],
      ],
      cellphone: [
        '',
        [
          Validators.required,
          this.customNonCustomerFormValidationService.customCellhoneValidation,
        ],
      ],
      terms: [false, [Validators.required]],
      news: [false, [Validators.required]],
    },
    { updateOn: 'change' }
  );
  regionsPrefix$: Observable<any> =
    this.customRegisterRegionsService.getRegionsPrefixes();
  countryCode$: Observable<string> =
    this.customRegisterRegionsService.getCountryCode();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  modalRef: ModalRef;
  stepThree: boolean = false;
  captchaResolved$: Observable<boolean> =
    this.customRecaptchaService.getCaptchaStatus();
  docsValid$: Observable<CustomDoc[] | null> =
    this.customRegisterDocsService.getDocsData();
  idDocLabel: string = 'nonCustomerRegister.attachId';
  rucDocLabel: string = 'nonCustomerRegister.attachRuc';
  registeredAppoinmentDocLabel: string =
    'nonCustomerRegister.attachRegisteredAppoinment';
  letterDocLabel: string = 'nonCustomerRegister.attachLetter';
  businessType$: Observable<CustomBusinessType[]> =
    this.customRegisterNonClientFormService.getBussinessType();
  formSubmited: boolean = false;
  stepOne$: Observable<boolean> =
    this.customRegisterStepOrchestratorService.getStepOneStatus();
  stepThree$: Observable<boolean> =
    this.customRegisterStepOrchestratorService.getStepThreeStatus();
  subscription: Subscription = new Subscription();
  invalidPerson: boolean = false;
  formData$: Observable<CustomRegisterNonCustomerFormData | null> =
    this.customRegisterNonClientFormService.getNonCustomerFormData();
  disableButton: boolean = false;
  bussinessName: string;
  unsubscribe$: Subject<void> = new Subject();
  constructor(
    private fb: FormBuilder,
    private customRegisterRegionsService: CustomRegisterRegionsService,
    private customBreakpointService: CustomBreakpointService,
    private customRegisterNonClientFormService: CustomRegisterNonClientFormService,
    private modalService: ModalService,
    private customRecaptchaService: CustomRecaptchaService,
    private customRegisterDocsService: CustomRegisterDocsService,
    private customRegisterStepOrchestratorService: CustomRegisterStepOrchestratorService,
    private customNonCustomerFormValidationService: CustomNonCustomerFormValidationService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.nonCustomerForm.valueChanges.subscribe((changes) => {
        const pattern = /[0-9\ ]/;

        if (changes.ruc) {
          if (!pattern.test(changes.ruc[changes.ruc.length - 1])) {
            this.nonCustomerForm
              .get('ruc')!
              .setValue(
                changes.ruc.replace(changes.ruc[changes.ruc.length - 1], '')
              );
          }
        }

        if (changes.phone) {
          if (!pattern.test(changes.phone[changes.phone.length - 1])) {
            this.nonCustomerForm
              .get('phone')!
              .setValue(
                changes.phone.replace(
                  changes.phone[changes.phone.length - 1],
                  ''
                )
              );
          }
        }

        if (changes.cellphone) {
          if (!pattern.test(changes.cellphone[changes.cellphone.length - 1])) {
            this.nonCustomerForm
              .get('cellphone')!
              .setValue(
                changes.cellphone.replace(
                  changes.cellphone[changes.cellphone.length - 1],
                  ''
                )
              );
          }
        }

        this.formSubmited = false;
        this.invalidPerson = false;
        this.disableButton = false;
      })
    );
  }

  personTypeChange(type: string) {
    switch (type) {
      case 'naturalPerson':
        this.nonCustomerForm.get('naturalPerson')?.setValue(true);
        this.nonCustomerForm.get('juridicPerson')?.setValue(false);
        this.personTypeSelected = type;
        break;
      case 'juridicPerson':
        this.nonCustomerForm.get('juridicPerson')?.setValue(true);
        this.nonCustomerForm.get('naturalPerson')?.setValue(false);
        this.personTypeSelected = type;
        break;
      default:
        break;
    }
  }

  cambioBusiness(event: any) {
    this.bussinessName = event.businessType;
  }

  nonCustomerFormSubmit(countryCode: string) {
    if (
      this.nonCustomerForm.get('naturalPerson')?.value == false &&
      this.nonCustomerForm.get('juridicPerson')?.value == false
    ) {
      this.invalidPerson = true;
      this.formSubmited = true;
      this.disableButton = true;
      return;
    }
    if (this.nonCustomerForm.invalid) {
      this.formSubmited = true;
      this.disableButton = true;
      return;
    }

    const cellphoneWithCountryCode =
      countryCode + this.nonCustomerForm.get('cellphone')?.value;
    const formattedDate = this.datepipe.transform(
      this.nonCustomerForm.get('rucLastUpdate')?.value,
      'yyyy-MM-dd'
    );

    const phonePrefix = this.nonCustomerForm.get('phonePrefix')?.value.value;
    const province = this.nonCustomerForm.get('phonePrefix')?.value.key;
    this.subscription.add(
      this.validationFormUser().subscribe((value) => {
        if (value) {
          this.customRegisterNonClientFormService.setNonCustomerFormFirstStep({
            ...this.nonCustomerForm.value,
            cellphone: cellphoneWithCountryCode,
            bussinessName: this.bussinessName,
            phonePrefix,
            rucLastUpdate: formattedDate,
            province,
          });
          this.customRegisterStepOrchestratorService.setStepOneStatus(false);
          this.customRegisterStepOrchestratorService.setStepTwoStatus(true);
          window.scroll(0, 0);
        }
      })
    );
  }

  validationFormUser(): Observable<any> {
    return combineLatest([
      this.customRegisterNonClientFormService.getRucAlreadyExists(
        this.nonCustomerForm.get('ruc')?.value
      ),
      this.customRegisterNonClientFormService.getUidAlreadyExists(
        this.nonCustomerForm.get('email')?.value
      ),
    ]).pipe(
      switchMap(([ruc, uid]) => {
        if (ruc && !uid) {
          return of(true);
        } else if (!ruc && uid) {
          this.openAlreadyExistsModal(true);
          return of(false);
        } else if (ruc && uid) {
          this.openAlreadyExistsModal(false);
          return of(false);
        } else {
          this.openAlreadyExistsModal(true);
          return of(false);
        }
      })
    );
  }

  openAlreadyExistsModal(isRucMessage: boolean) {
    let modalInstance: any;

    this.modalRef = this.modalService.open(CustomerAlreadyExistsDialogComponent, {
      centered: false,
      size: 'lg',
    });

    modalInstance = this.modalRef.componentInstance;

    modalInstance.isRucMessage = isRucMessage;
    modalInstance.isUidMessage = !isRucMessage;
  }

  return() {
    this.customRegisterStepOrchestratorService.setStepThreeStatus(false);
    this.customRegisterStepOrchestratorService.setStepTwoStatus(true);
    this.customRecaptchaService.setCaptchaStatus(false);
    window.scroll(0, 0);
  }

  openConfirmationModal() {
    let modalInstance: any;

    this.modalRef = this.modalService.open(
      CustomRegisterNonCustomerConfirmationComponent,
      {
        centered: true,
        size: 'lg',
      }
    );

    modalInstance = this.modalRef.componentInstance;
  }

  openRegisterErrorModal() {
    let modalInstance: any;

    this.modalRef = this.modalService.open(CustomRegisterErrorDialogComponent, {
      centered: true,
      size: 'lg',
    });

    modalInstance = this.modalRef.componentInstance;
  }

  handleCheck(e: any) {
    this.nonCustomerForm.get('news')?.setValue(e.target.checked);
  }

  finalStepSubmit(formData: CustomRegisterNonCustomerFormData) {
    const news = this.nonCustomerForm.get('news')?.value;
    if (this.nonCustomerForm.get('terms')?.value == false) {
      return;
    }
    this.customRegisterNonClientFormService
      .submitForm({ ...formData, news })
      .pipe(
        catchError((error) => of(false)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((response) => {
        if (!response) {
          this.openRegisterErrorModal();
          return;
        }
        if (response) {
          this.openConfirmationModal();
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
