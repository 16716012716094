import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  CmsComponent,
  CmsResponsiveBannerComponentMedia,
  CmsService
} from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { map } from 'rxjs/operators';
import { CustomCmsBannerCarouselComponent } from 'src/app/cms-components/content/components/custom-banner-carousel/model/custom-banner-carousel';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomGetImageUrlService } from 'src/app/services/custom-get-image-url.service';
import { CustomBaseSiteCheckerService } from '../../../../services/custom-base-site-checker.service';
import { CustomTopLinkService } from '../../../../services/custom-top.link.service';
import { CustomSecondaryBannerComponent } from '../custom-secondary-banner/custom-secondary-banner.component';

@Component({
  selector: 'app-custom-values-list',
  templateUrl: './custom-values-list.component.html',
  styleUrls: ['./custom-values-list.component.scss'],
})
export class CustomValueListComponent
  extends CustomSecondaryBannerComponent
  implements OnInit
{
  isHover: boolean[] = [];

  constructor(
    protected componentData: CmsComponentData<CustomCmsBannerCarouselComponent>,
    protected cmsService: CmsService,
    protected customGetImageUrl: CustomGetImageUrlService,
    protected customBreakpoinService: CustomBreakpointService,
    protected customTopLinkService: CustomTopLinkService,
    protected router: Router,
    protected customBaseSiteCheckerService: CustomBaseSiteCheckerService
  ) {
    super(
      componentData,
      cmsService,
      customGetImageUrl,
      customBreakpoinService,
      customTopLinkService,
      router,
      customBaseSiteCheckerService
    );
  }

  ngOnInit(): void {
    this.items$ = this.componentData$.pipe(
      map((data) => data.responsiveBanners.trim().split(' ')),
      map((codes) =>
        codes.map((code) =>
          this.cmsService.getComponentData(code).pipe(
            map((bannerData: CmsComponent | null) => ({
              ...bannerData,
              image: this.customBreakpoinService?.getImageByBreakpoint(
                <CmsResponsiveBannerComponentMedia>bannerData
              ),
            }))
          )
        )
      )
    );
  }

  hover(i: number, hover: boolean) {
    this.isHover[i] = hover;
  }
}
