<div class="custom-confirmation-password">
  <app-custom-modal-generic></app-custom-modal-generic>

  <ng-container *ngIf="serviceBoolean === true">
    <div class="custom-confirmation-password__success">
      <p class="text-dialog">
        {{ "register.confirmationSuccesful" | cxTranslate }}
      </p>
      <a href="/" cxModal="dismiss" cxModalReason="Cross click">
        {{ "register.goToPromesa" | cxTranslate }}
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="serviceBoolean === false">
    <div class="custom-confirmation-password__error">
      <p class="text-dialog">
        {{ "register.errorConfirmation" | cxTranslate }}
      </p>
      <a href="/" cxModal="dismiss" cxModalReason="Cross click">
        {{ "register.backToPromesa" | cxTranslate }}
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="serviceBoolean === undefined">
    <div class="loading">
      <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
    </div>
  </ng-container>
</div>
