<ng-container *ngIf="{
  stepOne: stepOne$ | async,
  stepTwo: stepTwo$ | async,
  stepThree: stepThree$ | async
} as data">
  <div class="register-progress">
    <div
      class="register-progress__item"
      [ngClass]="{'active': data.stepOne || data.stepTwo || data.stepThree}"
      (click)="toggleStep('one')"
    >
      <span class="register-progress__item-active" *ngIf="data.stepOne || data.stepTwo || data.stepThree"></span>
    </div>
    <div class="register-progress__line" [ngClass]="{'active': data.stepTwo || data.stepThree}">

    </div>
    <div
      class="register-progress__item"
      [ngClass]="{'active': data.stepTwo || data.stepThree}"
      (click)="toggleStep('two')"
    >
      <span class="register-progress__item-active" *ngIf="data.stepTwo || data.stepThree"></span>
    </div>
    <div class="register-progress__line" [ngClass]="{'active': data.stepThree}">

    </div>
    <div class="register-progress__item" [ngClass]="{'active': data.stepThree}">
      <span
      class="register-progress__item-active"
      *ngIf="data.stepThree"
      ></span>
    </div>
  </div>
</ng-container>
