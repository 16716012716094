import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, RoutingService } from '@spartacus/core';
import { Observable } from "rxjs";
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomPromeclubAccountStatusService {

  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
    private routingService: RoutingService,
  ) { }

  getOrderStatus(requestType: number){
    const url = this.occEndpointService.buildUrl('accountStatus', {
      queryParams: {
        requestType
      }
    });

    return this.http.get<any>(url);
  }

  downloadAccountStatus(): Observable<any> {
    const url = this.occEndpointService.buildUrl('promeclubDownloadAccountStatus', {});

    return this.http.get(url,
      {
        responseType: 'blob' as 'text',
        observe: 'response'
      }
    )
  }

  getOrderDetails(){
    return this.routingService.getRouterState().pipe(
      switchMap((routingData) => {
        const url = this.occEndpointService.buildUrl('promeclubOrderDetail', {
          urlParams: {
            orderCode: routingData.state.params.orderCode
          }
        });
        return this.http.get<any>(url);
      }
      ),
      distinctUntilChanged(),
    )
  }

  getOrderCode(){
    return this.routingService.getRouterState().pipe(
      map(routingData => routingData?.state?.params?.orderCode)
    )
  }
}
