import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { take } from 'rxjs/operators';
import { CustomPromeclubUserService } from 'src/app/cms-components/my-account/services/custom-promeclub-user.service';
import { CustomUser } from 'src/app/cms-components/user/models/custom-user.interface';
import { CustomBaseSiteCheckerService } from 'src/app/services/custom-base-site-checker.service';
import { CustomLoadingSpinnerService } from '../../services/custom-loading-spinner.service';
import { CustomTermsAndConditionsModalComponent } from './custom-terms-and-conditions-modal.component';

@Injectable({
  providedIn: 'root',
})
export class CustomTermsAndConditionsModalService {
  private modalRef: ModalRef;
  private isPromeclub: boolean =
    this.customBaseSiteCheckerService.isInPromeClub();

  constructor(
    private modalService: ModalService,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    private customPromeclubUserService: CustomPromeclubUserService,
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
    private spinnerService: CustomLoadingSpinnerService,
    private userService: UserIdService
  ) {}

  openTermsAndConditionsModal(): void {
    const promeclubUser: CustomUser = this.customPromeclubUserService.getUser();

    if (
      !this.isPromeclub ||
      (this.isPromeclub && promeclubUser.isPromeclubTyCAccepted)
    ) {
      return;
    }

    this.modalRef = this.modalService.open(
      CustomTermsAndConditionsModalComponent,
      {
        centered: true,
        size: 'lg',
        windowClass: 'terms-and-conditions-modal',
      }
    );

    this.modalRef.dismissed.pipe(take(1)).subscribe(() => {
      const url = window.location.origin;
      window.open(url, '_self'); // If you test this redirect in local front-end, you have to add the baseSite in the url like this window.open(url + '?site=promesaSite', '_self');
    });
  }

  closeModal(): void {
    this.modalRef.dismiss();
  }

  submitForm(): void {
    let userId: string = '';

    this.userService
      .getUserId()
      .pipe(take(1))
      .subscribe((_userId) => (userId = _userId));

    const url = this.occEndpointService.buildUrl('termsAndConditions', {
      urlParams: { userId },
    });

    this.spinnerService.showSpinner('');

    this.http
      .post<CustomUser>(url, {})
      .pipe(take(1))
      .subscribe({
        next: (user: CustomUser) => {
          this.customPromeclubUserService.setCurrentUser(user);
          this.modalRef.close();
        },
        complete: () => this.spinnerService.hideSpinner(),
      });
  }
}
