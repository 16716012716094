<div
  class="inner"
  *ngIf="(facetList$ | async)?.facets as facets"
  [cxFocus]="isDialog ? dialogFocusConfig : {}"
  (esc)="close($event)"
  (click)="block($event)"
>
  <div class="title-filter" *ngIf="isMobile$ | async">
    <p>
      {{ "productList.filterBy.label" | cxTranslate }}
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
      </button>
    </p>
  </div>
  <ng-container *ngIf="isMobile$ | async">
    <div class="spinner" *ngIf="loading$ | async">
      <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
    </div>
  </ng-container>
  <div class="facets-filter">
    <div
      class="active-facets"
      *ngIf="
        (isMobile$ | async) &&
        !isVariantPlpType &&
        model.breadcrumbs.length > 0 &&
        !(
          model.breadcrumbs.length == 1 &&
          model.breadcrumbs[0]?.facetCode === 'allCategories'
        )
      "
    >
      <app-custom-active-facets [fromMenu]="true"></app-custom-active-facets>
    </div>
    <div
      class="cx-sorting top"
      *ngIf="(isMobile$ | async) && !isVariantPlpType"
    >
      <label class="filter-price-facets__custom"
        >{{ "productList.sortByMobile" | cxTranslate }}
        <cx-icon
          [class]="(showFacet$ | async) ? 'fas fa-minus' : 'fas fa-plus'"
          (click)="toggleFacet()"
        ></cx-icon
      ></label>
      <div class="row" [ngClass]="{ hide: !(showFacet$ | async) }">
        <label class="form-group cx-sort-dropdown col-12 col-lg-4 ml-auto">
          <app-custom-sorting
            *ngIf="!isVariantPlpType"
            [sortOptions]="model.sorts"
            (sortListEvent)="sortList($event)"
            [selectedOption]="model.pagination.sort"
          ></app-custom-sorting>
        </label>
      </div>
    </div>

    <div *ngIf="facets$ | async as facets" class="facets-filter__children">
      <div *ngFor="let facet of facets">
        <app-custom-price-facet
          *ngIf="facet.name === 'Precio' || facet.name === 'Puntos'"
          [facet]="facet"
          [isMobile]="isMobile$ | async"
        ></app-custom-price-facet>
      </div>
      <div *ngFor="let facet of facets">
        <app-custom-facet
          *ngIf="
            facet.name !== 'reviewAvgRating' &&
            facet.name !== 'Precio' &&
            facet.name !== 'Puntos'
          "
          #facetRef
          [facet]="facet"
          [cxFocus]="{ lock: true, trap: true, autofocus: 'a' }"
          (unlock)="expandFacetGroup(facet, facetRef)"
          [class.expanded]="true"
          [class.collapsed]="isCollapsed(facet) | async"
        ></app-custom-facet>
        <app-custom-facet-reviews
          *ngIf="facet.name === 'reviewAvgRating'"
          [facet]="facet"
          [isMobile]="isMobile$ | async"
        ></app-custom-facet-reviews>
      </div>
    </div>
  </div>
  <div class="facet-mobile-action" *ngIf="isMobile$ | async">
    <button
      class="facet-mobile-action__button custom-button primary"
      (click)="close()"
    >
      {{
        "productList.seeResults"
          | cxTranslate : { count: model?.pagination?.totalResults }
      }}
    </button>
    <button
      class="facet-mobile-action__button custom-button primary link"
      (click)="cancelFacets()"
    >
      {{ "productList.cleanFilters" | cxTranslate }}
    </button>
  </div>
</div>
