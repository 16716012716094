import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CmsService, User, WindowRef } from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subject } from 'rxjs';
import { map, pluck, tap } from 'rxjs/operators';
import { CustomModalService } from 'src/app/cms-components/shared/components/custom-modal/services/custom-modal.service';
import { CustomGtmLoginService } from 'src/app/common/services/custom-gtm-login-register-datalayer.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomCreditLineDialogComponent } from './custom-credit-line-dialog/custom-credit-line-dialog.component';

@Component({
  selector: 'app-custom-credit-line',
  templateUrl: './custom-credit-line.component.html',
  styleUrls: ['./custom-credit-line.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomCreditLineComponent implements OnInit {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  private showCreditLine = new Subject<boolean>();
  public showCreditLine$: Observable<boolean> =
    this.showCreditLine.asObservable();

  isCheckout$: Observable<boolean>;

  private showLogin = new Subject<boolean>();
  public showLogin$: Observable<boolean> = this.showLogin.asObservable();
  userLoged: string;
  modalRef: ModalRef;
  userLoged$: Observable<User | undefined> = this.userAccountFacade.get().pipe(tap(user => this.userLoged = user?.uid!));
  instance: string = 'Login_Form';
  constructor(
    private customBreakpointService: CustomBreakpointService,
    private winRef: WindowRef,
    protected modalService: ModalService,
    protected cmsService: CmsService,
    private userAccountFacade: UserAccountFacade,
    protected customModalService: CustomModalService,
    private customLayerService: CustomGtmLoginService
  ) { }

  ngOnInit(): void {
    this.isCheckout$ = this.cmsService.getCurrentPage().pipe(
      pluck('template'),
      map((template) => template === 'MultiStepCheckoutSummaryPageTemplate')
    );
  }

  dismissModal(reason?: any): void {
    this.modalService.dismissActiveModal(reason);
  }

  setShowCreditLine(show: boolean) {
    const popUpIsOpen = this.winRef.document.body.classList.contains(
      'custom-credit-line-pop-up-wrapper'
    );
    if (!popUpIsOpen) {
      this.showCreditLine.next(show);
    }
  }

  setShowLogin(show: boolean, event: string, eventMouse?: any) {
    if (event !== null) this.signInEvent(event) ;
    const popUpIsOpen = document.body.getElementsByClassName('custom-login-pop-up-wrapper')[0];
    const pinOpen = this.winRef.document.body.classList.contains(
      'modal-open'
    );
    if ((show || !!popUpIsOpen) && !pinOpen && eventMouse?.relatedTarget != null) {
      this.showLogin.next(show);
    } else if (show) {
      this.showLogin.next(show);
    }
  }

  signInEvent(event: string) {
    this.customLayerService.signInEvent(event);
  }

  openModal() {
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomCreditLineDialogComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = this.modalRef.componentInstance;
    modalInstance.user = this.userLoged;
  }

  setShowModalLoginForm(event: string) {
    this.signInEvent(event);
    this.customModalService.show(this.instance);
  }
}
