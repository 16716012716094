<ng-container *ngIf="{
    isMobile: isMobile$ | async,
    currentPage: currentPage$ | async
  } as data">
  <div class="carousel-title">
    <app-custom-component-title *ngIf="title$ | async as title" [title]="title | titlecase" [hasFirsWordInBold]="true">
    </app-custom-component-title>
    <a *ngIf="seeMoreLink$ | async as seeMore" class="carousel-link" [routerLink]="seeMore?.url">{{ seeMore?.linkName
      }}<cx-icon class="fas fa-chevron-right"></cx-icon></a>
  </div>
  <ng-container *ngIf="items$ | async as items">
    <ng-container *ngIf="data.isMobile === false; else mobile">
      <div class="product-items-container">
        <ng-container *ngFor="let item$ of items.slice(0, 5); let i = index">
          <app-custom-product-card [fromContentPage]="
                data?.currentPage?.template != 'LandingPage2Template'
              " [product]="item$ | async" [wideLabel]="true" [productPosition]="i"
            [clickedFrom]="clickedFrom"></app-custom-product-card>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #mobile>
      <div class="slides">
        <swiper [config]="config">
          <ng-template swiperSlide *ngFor="let item$ of items.slice(0, 6); let i = index">
            <app-custom-product-card [wideLabel]="true"
              [fromContentPage]="true" [product]="item$ | async"
              [productPosition]="i" [clickedFrom]="clickedFrom"></app-custom-product-card>
          </ng-template>
        </swiper>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
