<ng-container
  *ngIf="{
    isLoggedIn: isLoggedIn$ | async,
    isMobile: isMobile$ | async
  } as data"
>
  <ng-container *ngFor="let plpVariantData of plpVariantDataList; index as i">
    <div
      class="variant-list__header"
      *ngIf="!data.isMobile"
      [ngStyle]="{ 'z-index': i + 1 }"
    >
      <p class="variant-list__header-item name">
        {{ "productList.name" | cxTranslate }}
      </p>

      <p
        class="variant-list__header-item"
        *ngFor="let header of plpVariantData?.header?.slice(0, 8); index as i"
        [ngClass]="{'short': plpClass?.[i]?.value?.length < 6 && header?.length < 6, 'long': plpClass?.[i]?.value?.length > 8}"
      >
        {{ header }}
      </p>

      <p class="variant-list__header-item" *ngIf="data.isLoggedIn">
        {{ "productList.price" | cxTranslate }}
      </p>
    </div>
    <div class="variant__title" [ngStyle]="{ 'z-index': i + 1 }">
      <h4 class="variant__title-value">
        {{ plpVariantData?.key | titlecase }}
      </h4>
    </div>

    <ng-container
      *ngFor="let product of plpVariantData?.products; let i = index"
    >
      <app-custom-product-variant-list-item
        *ngIf="!data?.isMobile; else mobileList"
        [product]="product"
        [productPosition]="i"
        [headers]="plpVariantData?.header"
        [isLoggedIn]="data?.isLoggedIn"
      ></app-custom-product-variant-list-item>
      <ng-template #mobileList>
        <app-custom-product-variant-list-item-details-mobile
          [isLoggedIn]="data?.isLoggedIn"
          [product]="product"
        >
        </app-custom-product-variant-list-item-details-mobile>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
