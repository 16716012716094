import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CustomGtmLoginService } from 'src/app/common/services/custom-gtm-login-register-datalayer.service';
import { CustomTwilioValidatorService } from 'src/app/common/services/custom-twilio-validator.service';
import { CustomPinDialogComponent } from '../../../../../common/components/custom-pin-dialog/custom-pin-dialog.component';
import { CustomerAlreadyExistsDialogComponent } from '../../custom-register-shared/customer-already-exists-dialog/customer-already-exists-dialog.component';
import { PromesaRegisterClient } from '../../models/custom-cms-register.interface';
import { CustomRegisterFormData } from '../../models/custom-register-form-data.interface';
import { CustomNonCustomerFormValidationService } from '../../services/custom-non-customer-form-validation.service';
import { CustomRegisterCustomerValidatorService } from '../../services/custom-register-customer-validator.service';
import { CustomRegisterFormService } from '../../services/custom-register-form.service';
import { CustomRegisterRegionsService } from '../../services/custom-register-regions.service';
import { CustomRegisterStepOrchestratorService } from '../../services/custom-register-step-orchestrator.service';

@Component({
  selector: 'app-custom-register-customer-account',
  templateUrl: './custom-register-customer-account.component.html',
  styleUrls: ['./custom-register-customer-account.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomRegisterCustomerAccountComponent implements OnInit {
  countryCode: string;
  customerForm: FormGroup = this.fb.group(
    {
      name: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      cellphone: [
        '',
        [
          Validators.required,
          this.customNonCustomerFormValidationService.customCellhoneValidation,
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          this.customNonCustomerFormValidationService.customEmailValidation,
        ],
      ],
      confirmCheck: [false, { updateOn: 'change' }],
    },
    { updateOn: 'blur' }
  );
  stepTwo$: Observable<boolean> =
    this.customRegisterStepOrchestrator.getStepTwoStatus();
  modalRef: ModalRef;
  prefixes$: Observable<string[]> =
    this.customRegisterRegionsService.getRegionsPrefixes();
  validationStatus$: Observable<boolean>;
  formData: CustomRegisterFormData = {
    name: '',
    surname: '',
    cellphone: '',
    email: '',
    password: '',
  };
  clientInfo$: Observable<PromesaRegisterClient | null>;
  subscription: Subscription = new Subscription();
  constructor(
    private fb: FormBuilder,
    private customRegisterStepOrchestrator: CustomRegisterStepOrchestratorService,
    private modalService: ModalService,
    private customRegisterCustomerValidatorService: CustomRegisterCustomerValidatorService,
    private customRegisterRegionsService: CustomRegisterRegionsService,
    private customTwilioValidatorService: CustomTwilioValidatorService,
    private customRegisterFormService: CustomRegisterFormService,
    private customNonCustomerFormValidationService: CustomNonCustomerFormValidationService,
    private dataLayerRegister: CustomGtmLoginService
  ) {}

  ngOnInit(): void {
    this.validationStatus$ = this.customTwilioValidatorService
      .getValidationStatus()
      .pipe(map((status) => status === 'OK'));
    this.clientInfo$ = this.customRegisterCustomerValidatorService
      .getClientInfo()
      .pipe(tap((client) => (this.countryCode = client?.countryCode!)));
  }

  customerFormSubmit(clientInfo: PromesaRegisterClient) {
    if (this.customerForm.invalid) {
      return;
    }
    let client;
    client = {
      ...clientInfo,
      customerRegisterEmail: this.customerForm.get('email')?.value,
    };
    this.customRegisterCustomerValidatorService.setClientInfo(client);
    this.formData.name = this.customerForm.get('name')?.value;
    this.formData.surname = this.customerForm.get('surname')?.value;
    this.formData.cellphone =
      this.countryCode + this.customerForm.get('cellphone')?.value;
    this.formData.email = this.customerForm.get('email')?.value;
    this.customRegisterFormService.setCustomerFormData(this.formData);

    this.subscription.add(
      this.customRegisterFormService
        .validatePromesaClient(this.formData, clientInfo?.clientCode!)
        .subscribe((value) => {
          if (value === 'AlreadyExistsError') {
            this.openAlreadyExistsModal();
          } else {
            this.dataLayerRegister.createOldAccountEvent(
              'Crear_Cuenta_Cliente_Paso2',
              clientInfo.ruc,
              this.formData.email
            );
            this.customRegisterStepOrchestrator.setStepTwoStatus(false);
            this.customRegisterStepOrchestrator.setStepThreeStatus(true);
          }
        })
    );
  }

  openPinModal() {
    let modalInstance: any;

    const cellphone =
      this.countryCode + this.customerForm.get('cellphone')?.value;

    this.subscription.add(
      this.customTwilioValidatorService
        .getValidationStatus()
        .subscribe((status) => {
          if (status === 'FALSE') {
            this.customTwilioValidatorService.setValidationStatus('');
          }
        })
    );
    this.subscription.add(
      this.customTwilioValidatorService.sendCodeToPhone(cellphone).subscribe()
    );
    this.modalRef = this.modalService.open(CustomPinDialogComponent, {
      centered: false,
      size: 'lg',
      windowClass: 'twilio',
    });
    modalInstance = this.modalRef.componentInstance;
    modalInstance.cellphone = cellphone;
  }

  openAlreadyExistsModal() {
    let modalInstance: any;

    this.modalRef = this.modalService.open(
      CustomerAlreadyExistsDialogComponent,
      {
        centered: false,
        size: 'lg',
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.customRegisterStepOrchestrator.reset();
  }
}
