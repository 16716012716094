import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSavedCartDetailsItemsModule } from './custom-saved-cart-details-items/custom-saved-cart-details-items.module';
import { CustomSavedCartDetailsOverviewModule } from './custom-saved-cart-details-overview/custom-saved-cart-details-overview.module';
import { CustomSavedCartModalModule } from './custom-saved-cart-modal/custom-saved-cart-modal.module';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import { CustomSavedCartService } from 'src/app/feature-libs/checkout/services/custom-saved-cart.service';
import { EffectsModule } from '@ngrx/effects';
import { CustomSavedCartEffects } from 'src/app/feature-libs/checkout/store/effects/custom-saved-cart.effects';
import {
  OccSavedCartAdapter,
  SavedCartOccModule,
} from '@spartacus/cart/saved-cart/occ';
import {
  SavedCartAdapter,
  SavedCartConnector,
  SavedCartCoreModule,
} from '@spartacus/cart/saved-cart/core';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CustomSavedCartDetailsOverviewModule,
    CustomSavedCartDetailsItemsModule,
    CustomSavedCartModalModule,
    SavedCartCoreModule,
    SavedCartOccModule,
    EffectsModule.forFeature([CustomSavedCartEffects]),
  ],
  providers: [
    {
      provide: SavedCartFacade,
      useExisting: CustomSavedCartService,
    },
    SavedCartConnector,
    OccSavedCartAdapter,
    {
      provide: SavedCartAdapter,
      useExisting: OccSavedCartAdapter,
    },
  ],
})
export class CustomSavedCartModule {}
