import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActiveCartService, OccEndpointsService } from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { CustomLoadingSpinnerService } from 'src/app/cms-components/custom/services/custom-loading-spinner.service';
import { CustomStockInfoDialogComponent } from '../components/custom-stock-info/custom-stock-info-dialog.component';
import { CustomStock, Result } from '../models/custom-stock.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomCheckoutStockService {
  entriesToReview$: BehaviorSubject<Result[] | []> = new BehaviorSubject<
    Result[] | []
  >([]);
  modalRef: ModalRef;
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
    private activeCartService: ActiveCartService,
    private modalService: ModalService,
    private customLoadingSpinnerService: CustomLoadingSpinnerService
  ) {}

  setEntriesToReview(entries: Result[]) {
    this.entriesToReview$.next(entries);
  }

  checkStock(isPromeclub: boolean = false): Observable<boolean> {
    if (isPromeclub) {
      return of(true);
    }
    this.customLoadingSpinnerService.showSpinner('');
    return this.activeCartService.getActive().pipe(
      switchMap((cart) => {
        const url = this.occEndpointService.buildUrl('checkStock', {
          urlParams: {
            userId: 'current',
            cartId: cart?.code,
          },
        });
        return this.http.get<CustomStock>(url).pipe(
          take(1),
          map((response: CustomStock) => {
            if (cart && response?.data?.results) {
              let stock = response.data.results;
              let entriesResult: Result[] = cart?.entries!.map((entry) => {
                return {
                  code: entry?.product?.code!,
                  quantity: entry?.quantity!,
                  product: entry.product,
                  rejected: false,
                  availableStock: 0,
                };
              });
              stock?.sort(this.compare);
              entriesResult?.sort(this.compare);
              let rejected: boolean = this.mergeEntriesDataWithStock(
                stock,
                entriesResult
              );
              this.customLoadingSpinnerService.hideSpinner();
              return rejected ? false : true;
            }
            this.customLoadingSpinnerService.hideSpinner();
            return true;
          }),
          catchError((error) => {
            this.customLoadingSpinnerService.hideSpinner();
            return of(true);
          })
        );
      })
    );
  }

  getEntriesWithoutStock(): Observable<Result[] | []> {
    return this.entriesToReview$.asObservable();
  }

  compare(a: Result, b: Result) {
    return a.code.localeCompare(b.code);
  }

  mergeEntriesDataWithStock(stock: Result[], entriesResult: Result[]): boolean {
    let rejected = false;
    for (let i = 0; i < stock.length; i++) {
      entriesResult;
      if (
        stock.length == entriesResult?.length &&
        stock[i].code === entriesResult?.[i].code &&
        stock[i].quantity < entriesResult?.[i].quantity!
      ) {
        entriesResult[i].availableStock = stock[i].quantity;
        entriesResult[i].rejected = true;
        rejected = true;
      }
    }
    this.setEntriesToReview(entriesResult?.filter((entry) => entry?.rejected));
    return rejected;
  }

  openStockModal() {
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomStockInfoDialogComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'checkout-stock-modal',
    });

    modalInstance = this.modalRef.componentInstance;
  }
}
