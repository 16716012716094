import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActiveCartService } from '@spartacus/core';
import { CmsComponentData, CurrentProductService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, pluck, switchMap, tap } from 'rxjs/operators';
import { CustomOrderEntry } from 'src/app/cms-components/cart/model/custom-order-entry';
import { CustomRebateService } from 'src/app/cms-components/my-account/services/custom-rebate.service';
import { CustomProduct } from 'src/app/cms-components/product/model/custom-product.interface';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-pdp-rebate',
  templateUrl: './custom-pdp-rebate.component.html',
  styleUrls: ['./custom-pdp-rebate.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomPdpRebateComponent implements OnInit {
  public rebate$: Observable<any> = this.rebateGetData();
  public isMobile$: Observable<boolean>;
  currentProduct$: BehaviorSubject<CustomProduct | null> = new BehaviorSubject<CustomProduct | null>(null);
  openDetailMobile: boolean = false;

  constructor(
    private customBreakpointService: CustomBreakpointService,
    private currentProductService: CurrentProductService,
    private customRebateService: CustomRebateService,
    private componentData: CmsComponentData<any>,
    private activeCartService: ActiveCartService,
    protected userAccountFacade: UserAccountFacade
  ) {}

  ngOnInit(): void {
    this.isMobile$ = this.customBreakpointService.isMobile$;
  }

  getEntriesCartActive(): Observable<CustomOrderEntry[]> {
    return this.activeCartService.getEntries();
  }

  rebateGetData(): Observable<any> {
    return combineLatest([
      this.userAccountFacade.get(),
      this.componentData.uid == 'RebateCartComponent'
        ? this.activeCartService.getEntries().pipe(
            map((data: CustomOrderEntry[]) => {
              return data?.some((data) => { return data?.hasRappel == true && data?.product?.productType != 'ZPOP'});
            })
          )
        : this.currentProductService.getProduct().pipe(tap(product => {
          this.currentProduct$.next(product);
        }

        ),pluck('code')),
    ]).pipe(
      switchMap(([userId, rebateCall]) => {
        if (typeof rebateCall === 'boolean') {
          return rebateCall ? of(true) : of(false);
        } else {
          return this.customRebateService.getRebateByProductID(
            userId?.uid,
            rebateCall
          );
        }
      })
    );
  }

  setOpenMobile() {
    this.openDetailMobile = !this.openDetailMobile;
  }
}
