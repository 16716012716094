<ng-container *ngIf="isSuggest ? category : (category | async) as category">
  <ng-container *ngIf="isCategory; else brand">
    <div class="result-item result-item__categories">
      <a (click)="navigateToUrl(!!isSuggest ? category.query?.query?.value : category?.url)">
        <p>{{category.name}}</p>
      </a>
    </div>

  </ng-container>
  <ng-template #brand>
    <div class="result-item result-item__brands">
      <a (click)="navigateToUrl(!!isSuggest ? category.query?.query?.value : category?.url)">
        <div class="brand-item__img" *ngIf="!!category.image || !!category.media">
          <img [src]="getImageUrl(isSuggest ? category?.media.url : category?.image?.url)" [alt]="category?.name" loading="lazy" />
        </div>
      </a>
    </div>
  </ng-template>
</ng-container>
