<div class="quotes-summary">
  <h4 class="quotes-summary__title">{{'accountQuotes.summary.title' | cxTranslate}}</h4>
  <div class="quotes-summary__container">
    <div class="quotes-summary__container-subtotal">
      <p class="quotes-summary__container-subtotal__text">
        {{'accountQuotes.summary.subtotal' | cxTranslate}}
      </p>
      <p class="quotes-summary__container-subtotal__value" *ngIf="cart?.subTotal?.value">
        {{cart?.subTotal.value | customCurrency}}
      </p>
    </div>
    <div class="quotes-summary__container-iva">
      <p class="quotes-summary__container-iva__text">
        {{'accountQuotes.summary.iva' | cxTranslate}}
      </p>
      <p class="quotes-summary__container-iva__value" *ngIf="cart?.totalTax?.value">
        {{cart?.totalTax?.value | customCurrency}}
      </p>
    </div>
    <div class="quotes-summary__container-total">
      <p class="quotes-summary__container-total__text">
        {{'accountQuotes.summary.total' | cxTranslate}}
      </p>
      <p class="quotes-summary__container-total__value" *ngIf="cart?.totalPriceWithTax?.value">
        {{cart?.totalPriceWithTax?.value | customCurrency}}
      </p>
    </div>
  </div>

  <app-custom-quotes-summary-action
    *ngIf="!isMobile"
    [quote]="cart"
  ></app-custom-quotes-summary-action>
</div>
