export const customPaymentType = {
  creditPayment: {
    creditPayment: {
      yourLineOfCredit: 'Tu línea de crédito',
      availableBalance: 'Saldo disponible',
      orderValue: 'Valor del pedido',
      newBalance: 'Nuevo saldo',
      paymentCondition: 'Condición de pago',
      conditionThirtyDays: 'Pagadero a 30 días',
      typeOfCashPayment: 'Formas de pago al contado',
      listOfAccounts: 'Listado de cuentas para pagos al contado',
      banks: 'Banco',
      currentAccount: 'Cuenta Corriente',
      select: 'Seleccionar',
      counted: 'Contado',
      creditNotAvailableTitle: 'Sin cupo disponible',
      creditNotAvailableMessage:
        'Ajusta el monto del pedido a tu saldo disponible, selecciona otro método de pago o comunícate con tu Oficial de Crédito para poder revisar tu cupo.',
      expiredCreditTitle: 'Cartera vencida',
      expiredCreditMessage: 'Comunícate con tu oficial de crédito.',
      totalPoints: 'Total de puntos',
      available: 'Disponibles',
      points: 'Puntos',
      pointsErrorTitle: 'Sin puntos disponibles',
      pointsErrorDescription:
        'Ajusta el monto del pedido a tus puntos disponibles para poder continuar.',
      availablePoints: 'Puntos disponibles',
      total: 'Total: ',
      myPoints: 'Mis puntos',
      myPromeclubPoints: 'Mis puntos PromeClub',
      myPrometasPoints: 'Mis puntos ProMetas',
      promeclubStatusAccount: 'Estado de cuenta',
      myPointsTitle: 'Puntos PromeClub',
      myPointsTitleSeller: 'Puntos ProMetas',
      creditPaymentEmployeeText: 'Su órden será procesada por talento humano',
      creditPaymentEmployeeTextOne: 'Su órden será procesada',
      creditPaymentEmployeeTextTwo: 'por talento humano',
    },
  },
};
