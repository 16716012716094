import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCheckoutDeliveryMethodModule } from './custom-checkout-delivery-method/custom-checkout-delivery-method.module';
import { CustomCheckoutProgressModule } from './custom-checkout-progress/custom-checkout-progress.module';
import { CustomPaymentTypeModule } from './custom-payment-type/custom-payment-type.module';
import { CustomReviewSubmitModule } from './custom-review-submit/custom-review-submit.module';
import { PaymentTypeService } from '@spartacus/checkout/core';
import { CustomStockInfoModule } from './custom-stock-info/custom-stock-info.module';

@NgModule({
  imports: [
    CommonModule,
    CustomCheckoutProgressModule,
    CustomCheckoutDeliveryMethodModule,
    CustomPaymentTypeModule,
    CustomReviewSubmitModule,
    CustomStockInfoModule,
  ],
  providers: [PaymentTypeService],
})
export class CheckoutComponentsModule {}
