import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from "@spartacus/core";
import { SmartEditRootModule, SMART_EDIT_FEATURE, SmartEditConfig } from "@spartacus/smartedit/root";
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [],
  imports: [
    SmartEditRootModule
  ],
  providers: [provideConfig(<CmsConfig>{
    featureModules: {
      [SMART_EDIT_FEATURE]: {
        module: () =>
          import('@spartacus/smartedit').then((m) => m.SmartEditModule),
      },
    }
  }),
    provideConfig(<SmartEditConfig>{
     smartEdit: {
       allowOrigin: environment.smartEdit.allowOrigin
     },
   })
  ]
})
export class SmartEditFeatureModule { }
