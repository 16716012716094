import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideConfig, I18nConfig } from '@spartacus/core';
import { coreTranslations } from './core/translations';

import { savedCartTranslations } from './feature-libs/cart/saved-cart/translations';
import { productVariantsTranslations } from './feature-libs/product/variants/translations';
import { storeFinderTranslations } from './feature-libs/storefinder/translations';
import { userAccountTranslations } from './feature-libs/user/account/translations';
import { userProfileTranslations } from './feature-libs/user/profile/translations';

import { translationChunksConfig } from '@spartacus/assets';
import { asmTranslations } from './feature-libs/asm/translations';
import { checkoutTranslations } from './feature-libs/checkout/translations';
import { orderTranslations } from './feature-libs/order/translations';
import { productImageZoomTranslations } from './feature-libs/product/image-zoom/translations';
import { customTranslations } from './custom/translations';
import { customSearchRefiner } from './custom/es/custom-search-refiner';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    // Chunk config
    provideConfig(<I18nConfig>{
      i18n: {
        chunks: translationChunksConfig,
        fallbackLang: 'es',
      },
    }),
    // Custom i18n Core Translations
    provideConfig(<I18nConfig>{
      i18n: {
        resources: coreTranslations,
      },
    }),
    // Custom i18n Feature Lib Translations
    provideConfig(<I18nConfig>{
      i18n: {
        resources: asmTranslations,
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: savedCartTranslations,
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: checkoutTranslations,
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: orderTranslations,
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: productImageZoomTranslations,
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: productVariantsTranslations,
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: storeFinderTranslations,
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: userAccountTranslations,
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: userProfileTranslations,
      },
    }),
    // Custom i18n Translations
    provideConfig(<I18nConfig>{
          i18n: {
            resources: customTranslations,
            chunks: {
              ...translationChunksConfig,
              newsLetterForm: ['email', 'button', 'success'],
              searchFacets: ['searchBoxFacets', 'filterPrice', 'filterBrands', 'filterOptions', 'filterProductsReviews', 'searchPageBreadcrumb'],
              searchRefiner: ['searchRefiner'],
              creditLine: ['creditLine'],
            }
          },
        }),
  ],
})
export class CustomI18nModule {}
