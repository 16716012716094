import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customPhoneMask',
})
export class CustomPhoneMaskPipe implements PipeTransform {
  
  transform(value: string): any {    
    if(value.length > 3){
      var repeatAmount = value.length - 4;
      if(repeatAmount <= 0)
        repeatAmount = 3;

      var letter1 = value.slice(0, 3) + "*".repeat(repeatAmount);
   
       return letter1;
    }
  }
}
