<a
  [href]="baseSiteUrl + manualProduct?.url"
  target="_blank"
  class="product-card-downloads"
>
  <cx-icon type="PDF_DOWNLOAD"></cx-icon>
  <p class="product-card-downloads__title m-0">
    {{ title }}
    <span class="title-bold">{{ subtitle }}</span>
  </p>
  <cx-icon class="fas fa-chevron-right"></cx-icon>
</a>
