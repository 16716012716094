import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CheckoutReplenishmentFormService,
  PlaceOrderComponent,
} from '@spartacus/checkout/components';
import { CheckoutFacade } from '@spartacus/checkout/root';
import {
  ActiveCartService,
  Cart,
  ORDER_TYPE,
  recurrencePeriod,
  RoutingService,
  ScheduleReplenishmentForm,
} from '@spartacus/core';
import {
  LaunchDialogService,
  ModalRef,
  ModalService,
} from '@spartacus/storefront';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, map, take, tap } from 'rxjs/operators';
import { CustomPinDialogComponent } from 'src/app/common/components/custom-pin-dialog/custom-pin-dialog.component';
import { CustomTwilioValidatorService } from 'src/app/common/services/custom-twilio-validator.service';
import { CustomPromeclubCheckoutAlertDialogComponent } from '../../../../cms-components/custom/components/custom-promeclub-checkout-alert-dialog/custom-promeclub-checkout-alert-dialog.component';
import { CustomBaseSiteCheckerService } from '../../../../services/custom-base-site-checker.service';
import { CustomCheckoutStockService } from '../../services/custom-checkout-stock.service';
import { CustomReviewSubmitService } from '../../services/custom-review-submit.service';
import { CustomLoadingSpinnerService } from 'src/app/cms-components/custom/services/custom-loading-spinner.service';

@Component({
  selector: 'app-custom-place-order',
  templateUrl: './custom-place-order.component.html',
  styleUrls: ['./custom-place-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPlaceOrderComponent
  extends PlaceOrderComponent
  implements OnInit, OnDestroy
{
  private _subscription: Subscription = new Subscription();
  @ViewChild('submitButton') submitButton: ElementRef;
  modalRef: ModalRef;
  userId: string;
  currentOrderType: ORDER_TYPE;
  scheduleReplenishmentFormData: ScheduleReplenishmentForm;
  placedOrder: void | Observable<ComponentRef<any> | undefined>;
  daysOfWeekNotChecked$ = new BehaviorSubject<boolean>(false);
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  isReviewSubmitActive$: Observable<boolean> =
    this.customReviewSubmitService.getIsReviewSubmitActive();
  blockButton$ = new BehaviorSubject<boolean>(false);
  checkoutSubmitForm: FormGroup = this.fb.group({
    termsAndConditions: [false, Validators.requiredTrue],
  });
  success: boolean = false;
  validationStatus$: Observable<boolean> = this.customTwilioValidatorService
    .getValidationStatus()
    .pipe(map((status) => status === 'OK'));

  userId$: Observable<string> = this.activeCartService
    .getActive()
    .pipe(map((cart: Cart) => cart.user?.uid!));

  get termsAndConditionInvalid(): Boolean {
    return this.checkoutSubmitForm.invalid;
  }

  constructor(
    private activeCartService: ActiveCartService,
    private customTwilioValidatorService: CustomTwilioValidatorService,
    private modalService: ModalService,
    protected checkoutService: CheckoutFacade,
    protected routingService: RoutingService,
    protected fb: FormBuilder,
    protected checkoutReplenishmentFormService: CheckoutReplenishmentFormService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    protected customCheckoutStockService: CustomCheckoutStockService,
    private customReviewSubmitService: CustomReviewSubmitService,
    private customLoadingSpinnerService: CustomLoadingSpinnerService
  ) {
    super(
      checkoutService,
      routingService,
      fb,
      checkoutReplenishmentFormService,
      launchDialogService,
      vcr
    );
  }

  submitForm(): void {
    this.submitButton.nativeElement.blur();
    this.blockButton$.next(true);
    let modalInstance: any;
    const email = this.userId;

    this._subscription.add(
      this.customTwilioValidatorService
        .getValidationStatus()
        .pipe(debounceTime(1000))
        .subscribe((status) => {
          this.blockButton$.next(false);
          if (status === 'FALSE') {
            this.customTwilioValidatorService.setValidationStatus('');
          }
        })
    );

    this.modalRef = this.modalService.open(CustomPinDialogComponent, {
      centered: false,
      size: 'lg',
      windowClass: 'twilio',
    });

    modalInstance = this.modalRef.componentInstance;
    modalInstance.email = email;
    modalInstance.fromPlaceOrderButton = true;
  }

  confirmPayment(pinValid: boolean) {
    if (
      this.checkoutSubmitForm.valid &&
      Boolean(this.currentOrderType) &&
      pinValid
    ) {
      if (!this.isPromeclub) {
        this._subscription.add(
          this.customCheckoutStockService
            .checkStock(this.isPromeclub)
            .pipe(take(1))
            .subscribe((value) => {
              if (value) {
                this.doPlaceOrder();
              } else {
                this.customCheckoutStockService.openStockModal();
              }
            })
        );
      } else {
        this.doPlaceOrder();
      }
    } else {
      this.checkoutSubmitForm.markAllAsTouched();
      this.blockButton$.next(false);
    }
  }

  doPlaceOrder() {
    switch (this.currentOrderType) {
      case ORDER_TYPE.PLACE_ORDER: {
        this.checkoutService.placeOrder(this.checkoutSubmitForm.valid);
        break;
      }

      case ORDER_TYPE.SCHEDULE_REPLENISHMENT_ORDER: {
        this.checkoutService.scheduleReplenishmentOrder(
          this.scheduleReplenishmentFormData,
          this.checkoutSubmitForm.valid
        );
        break;
      }
    }
  }
  ngOnInit(): void {
    this._subscription.add(
      combineLatest([
        this.checkoutService.getPlaceOrderLoading(),
        this.checkoutService.getPlaceOrderSuccess(),
        this.checkoutService.getPlaceOrderError(),
      ]).subscribe(([orderLoading, orderSuccess, orderError]) => {
        if (orderLoading) {
          this.blockButton$.next(true);
          this.customLoadingSpinnerService.showSpinner('');
        }

        if (orderError && !this.success) {
          if (this.isPromeclub) {
            this.openPromeclubAlert();
            this.blockButton$.next(false);
          }
          if (this.placedOrder) {
            this.customLoadingSpinnerService.hideSpinner();
            this.checkoutService.clearPlaceOrderState();
          }
        }

        if (orderSuccess) {
          this.success = true;
          this.onSuccess(orderSuccess);
        }
      })
    );

    this.isReviewSubmitActive$
      .pipe(
        tap((value) => {
          if (!value) {
            this.checkoutSubmitForm.controls['termsAndConditions'].disable();
          } else {
            this.checkoutSubmitForm.controls['termsAndConditions'].enable();
          }
        })
      )
      .subscribe();

    this._subscription.add(
      this.checkoutService
        .getCurrentOrderType()
        .subscribe((orderType) => (this.currentOrderType = orderType))
    );

    this._subscription.add(
      this.checkoutReplenishmentFormService
        .getScheduleReplenishmentFormData()
        .subscribe((data) => {
          this.scheduleReplenishmentFormData = data;

          this.daysOfWeekNotChecked$.next(
            data.daysOfWeek?.length === 0 &&
              data.recurrencePeriod === recurrencePeriod.WEEKLY
          );
        })
    );

    this._subscription.add(
      this.validationStatus$.subscribe((isValid) => {
        if (isValid) {
          this.confirmPayment(isValid);
        } else {
          this.checkoutSubmitForm.markAllAsTouched();
        }
      })
    );

    this._subscription.add(
      this.userId$.subscribe((userId) => {
        this.userId = userId;
      })
    );
  }

  onSuccess(data: boolean): void {
    if (data) {
      switch (this.currentOrderType) {
        case ORDER_TYPE.PLACE_ORDER: {
          this.blockButton$.next(false);
          this.routingService.go({
            cxRoute: 'orderConfirmation',
          });
          break;
        }

        case ORDER_TYPE.SCHEDULE_REPLENISHMENT_ORDER: {
          this.blockButton$.next(false);
          this.routingService.go({ cxRoute: 'replenishmentConfirmation' });
          break;
        }
      }
      this.checkoutReplenishmentFormService.resetScheduleReplenishmentFormData();
      this.customTwilioValidatorService.resetValidationStatus();
    }
  }

  openPromeclubAlert() {
    this.modalRef = this.modalService.open(
      CustomPromeclubCheckoutAlertDialogComponent,
      {
        centered: false,
        size: 'lg',
        windowClass: 'promeclub-alert-modal',
      }
    );
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }
}
