import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AuthService, HttpErrorModel, ProductActions, ProductSearchPage, normalizeHttpError } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, filter, groupBy, map, mergeMap, switchMap } from 'rxjs/operators';
import { CustomOccProductSearchAdapter } from 'src/app/custom-occ/custom-adapters/custom-product/custom-occ-product-search.adapter';

@Injectable()
export class CustomProductsSearchEffects {
  @Effect()
  customSearchProducts$: Observable<
    ProductActions.SearchProductsSuccess | ProductActions.SearchProductsFail
  > = this.actions$.pipe(
    ofType(ProductActions.SEARCH_PRODUCTS),
    groupBy((action: ProductActions.SearchProducts) => action.auxiliary),
    mergeMap((group) =>
      group.pipe(
        switchMap((action: ProductActions.SearchProducts) => {
          return this.auth.isUserLoggedIn().pipe(
            filter((isLoggedIn: boolean) => isLoggedIn),
            switchMap(() => {
              return this.productSearchConnector
                .search(action.payload.queryText, action.payload.searchConfig, true)
                .pipe(
                  map((data: ProductSearchPage) => {
                    return new ProductActions.SearchProductsSuccess(
                      data,
                      action.auxiliary
                    );
                  }),
                  catchError((error: HttpErrorModel) =>
                    of(
                      new ProductActions.SearchProductsFail(
                        normalizeHttpError(error)!,
                        action.auxiliary
                      )
                    )
                  )
                );
            })
          )
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private productSearchConnector: CustomOccProductSearchAdapter,
    private auth: AuthService,
  ) { }
}
