import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customEmailMask',
})
export class CustomEmailMaskPipe implements PipeTransform {
  transform(value: string): any {
    var arr = value.split('@');

    if (arr.length > 1) {
      // process text before @
      var repeatAmount = arr[0].length - 2;
      if (repeatAmount <= 0) repeatAmount = 3;

      var letter1 = arr[0][0] + '*'.repeat(repeatAmount);
      // process text after @
      //  var letter2 = arr[1][0] + "*".repeat(arr[1].length - 2) + arr[1].slice(-1);
      var letter2 = arr[1];

      return letter1 + '@' + letter2;
    }
  }
}
