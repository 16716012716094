import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPlaceOrderComponent } from './custom-place-order.component';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import {
  CheckoutAuthGuard,
  CartNotEmptyGuard,
} from '@spartacus/checkout/components';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IconModule } from '@spartacus/storefront';
import { CustomPromeclubCheckoutAlertModule } from '../../../../cms-components/custom/components/custom-promeclub-checkout-alert-dialog/custom-promeclub-checkout-alert-dialog.module';

@NgModule({
  declarations: [CustomPlaceOrderComponent],
  exports: [CustomPlaceOrderComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    ReactiveFormsModule,
    IconModule,
    CustomPromeclubCheckoutAlertModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutPlaceOrder: {
          component: CustomPlaceOrderComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    }),
  ],
})
export class CustomPlaceOrderModule {}
