import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActiveCartService } from '@spartacus/core';
import {
  AddedToCartDialogComponent,
  ModalService,
} from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMapTo, take, takeUntil, tap } from 'rxjs/operators';
import { CustomDialogCartService } from 'src/app/cms-components/navigation/services/custom-dialog-cart.service';
import { CustomGtmEcommerceDatalayerService } from 'src/app/common/services/custom-gtm-ecommerce-datalayer.service';
import { AddToCartErrorsService } from '../../services/add-to-cart-errors.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomBaseSiteCheckerService } from 'src/app/services/custom-base-site-checker.service';

@Component({
  selector: 'app-custom-added-to-cart-dialog',
  templateUrl: './custom-added-to-cart-dialog.component.html',
  styleUrls: ['./custom-added-to-cart-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomAddedToCartDialogComponent
  extends AddedToCartDialogComponent
  implements OnInit, OnDestroy
{
  private errors: Array<string> = [];
  private unsubscribe$ = new Subject<void>();
  private ngbModalElement: HTMLElement;
  isPromeclub$: Observable<boolean> = this.service.isPromeclub$;
  clickedFrom: string;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    protected modalService: ModalService,
    protected cartService: ActiveCartService,
    protected addToCartErrorsService: AddToCartErrorsService,
    @Inject(DOCUMENT) private document: Document,
    private customDialogCartService: CustomDialogCartService,
    private service: CustomBaseSiteCheckerService,
    private customLayerService: CustomGtmEcommerceDatalayerService,
    private customBreakpointService: CustomBreakpointService
  ) {
    super(modalService, cartService);
  }

  ngOnInit(): void {
    this.addToCartErrorsService
      .getErrors()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((errorMessage) => {
        this.errors.push(errorMessage);
      });

    this.ngbModalElement = <HTMLElement>(
      this.document.getElementsByClassName('modal-content')[0]
    );
    this.setModalContentStyle();

    this.addedEntryWasMerged$ = this.loaded$.pipe(
      filter((loaded) => loaded),
      switchMapTo(this.cartService.getEntries()),
      map((entries) => entries.length === this.numberOfEntriesBeforeAdd)
    );
    this.entry$
      .pipe(
        take(1),
        tap((p) => {
          this.customLayerService.addToCartEvent(
            p?.product!,
            this.quantity,
            p,
            this.clickedFrom
          );
        })
      )
      .subscribe();
  }

  setModalContentStyle() {
    this.ngbModalElement.style.height = 'fit-content';
    this.ngbModalElement.style.margin = 'auto 0';
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  customDismissModal(reason?: any, closeDialog: boolean = true): void {
    this.modalService.dismissActiveModal(reason);
    if (closeDialog) {
      this.customDialogCartService.onCloseDialogToCart(reason);
    }
  }
}
