<div class="sort" *ngFor="let sort of sortOptions">
  <div class="sort-options" (mousedown)="sortList(sort.code)">
    <div
      class="sort-options__item"
      [ngClass]="{ active: sort.code === selectedOption }"
    >
      <div
        class="sort-options__item-active"
        *ngIf="sort.code === selectedOption"
      ></div>
    </div>
    <div
      class="sort-options__label"
      [ngClass]="{ active: sort.code === selectedOption }"
    >
      {{ sort.name ? sort.name : sortLabels ? sortLabels[sort.code] : "" }}
    </div>
  </div>
</div>
