import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ICON_TYPE, ModalService } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-credit-line-dialog',
  templateUrl: './custom-credit-line-dialog.component.html',
  styleUrls: ['./custom-credit-line-dialog.component.scss']
})
export class CustomCreditLineDialogComponent implements OnInit {
  private ngbModalElement: HTMLElement;
  iconTypes = ICON_TYPE;
  orderCode: string;
  @ViewChild('dialogConfCreditLine', { read: ElementRef })
  dialogOrderCancel: ElementRef;
  constructor(
    protected modalService: ModalService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.ngbModalElement = <HTMLElement>(
      this.document.getElementsByClassName('modal-content')[0]
    );
  }

}
