import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CmsService } from '@spartacus/core';
import { ICON_TYPE, ModalRef, ModalService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, pluck } from 'rxjs/operators';
import { CustomOrderEntry } from 'src/app/cms-components/cart/model/custom-order-entry';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomOrderReturnService } from '../../../services/custom-order-return.service';
import { CustomOrderRegisteredSuccessfullyDialogComponent } from '../../custom-payment-record/custom-order-registered-successfully-dialog/custom-order-registered-successfully-dialog.component';
import { CustomReturnOrderDetail } from '../model/custom-return-order';
import { EcvProductType } from 'src/app/cms-components/product/constants/product-type';

@Component({
  selector: 'app-custom-return-product-item',
  templateUrl: './custom-return-product-item.component.html',
  styleUrls: ['./custom-return-product-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomReturnProductItemComponent implements OnInit, OnDestroy {
  @Input() item: CustomOrderEntry;
  @Input() refundsReasons: any[] = [];
  @Input() currentPage: string;
  @Input() isEdit: CustomReturnOrderDetail;
  @Input() refundsReasonSelected: string;
  @Input() imagesReturn: any[] = [];
  @Input() expectedQuantityReturn: number;
  @Output() productReturn = new EventEmitter<any>();
  @Output() productsSelected = new EventEmitter<any>();
  modalRef: ModalRef;
  pageCurrent$: Observable<string | undefined> = this.cmsService
    .getCurrentPage()
    .pipe(pluck('pageId'));
  reasonSelected: string;
  valueReason$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  returnOrderForm: FormArray;
  itemReturnForm: FormGroup;
  iconTypes = ICON_TYPE;
  doc: string = 'product';
  acceptFile: string = 'image/*';
  files: any[] = [];
  sendFiles: any[] = [];
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  subscription: Subscription = new Subscription();
  showUnknowErrorFile: boolean = false;

  valueReason: boolean = false;
  inputFileForm: FormGroup;
  images: FormArray;
  EcvProductType: string = EcvProductType;
  constructor(
    private customBreakpointService: CustomBreakpointService,
    private customOrderReturnService: CustomOrderReturnService,
    private cd: ChangeDetectorRef,
    private cmsService: CmsService,
    protected modalService: ModalService,
    @Inject(DOCUMENT) document: Document,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.itemReturnForm = new FormGroup({
      codeProd: new FormControl(this.item.product?.code, Validators.required),
      orderEntry: new FormControl(this.item.entryNumber, Validators.required),
      itemSelected: new FormControl(
        this.isEdit?.itemSelected ? true : false,
        Validators.required
      ),
      quantity: new FormControl(
        {
          value:
            this.item?.returnableQuantity! > 1
              ? 1
              : this.item.returnableQuantity,
          disabled: true,
        },
        [Validators.required]
      ),
      reasonReturn: new FormControl(
        { value: null, disabled: true },
        Validators.required
      ),
      images: this.fb.array([]),
      files: new FormControl([]),
    });

    this.images = this.itemReturnForm.get('images') as FormArray;

    this.inputFileForm = new FormGroup({
      inputFile: new FormControl(),
    });

    this.inputFileForm.controls['inputFile'].disable();

    if (this.item.returnableQuantity! < 1) {
      this.itemReturnForm.controls['itemSelected'].setValue(false);
      this.itemReturnForm.controls['itemSelected'].disable();
    }

    if (this.isEdit) {
      this.itemReturnForm.controls['itemSelected'].setValue(true);
      this.itemReturnForm.controls['orderEntry'].setValue(
        this.isEdit.orderEntryNumber
      );
      this.itemReturnForm.controls['quantity'].setValue(this.isEdit.quantity);
      this.itemReturnForm.controls['reasonReturn'].setValue(this.isEdit.reason);

      const imageList: any = this.isEdit.files;
      if (imageList?.length > 0) {
        for (let i = 0; i < imageList?.length!; i++) {
          let data = this.fb.group({
            images: imageList[i]?.files?.code,
            files: imageList[i]?.files,
          });
          let uuid = imageList[i]?.files?.code;
          this.images.push(data);
          this.files.push(uuid);
          this.inputFileForm.controls['inputFile'].enable();
        }
      }

      this.reasonSelected = this.refundsReasons.find(
        (reason) => reason.code == this.isEdit.reason
      );
      this.enableOption();
      this.cd.detectChanges();

      const valueReason = this.itemReturnForm.controls['reasonReturn'].value;
      const nameReason = this.refundsReasons.find(
        (reason) => reason.code == valueReason
      );

      nameReason?.name == 'Garantía'
        ? this.valueReason$.next(true)
        : this.valueReason$.next(false);
    }

    if (this.refundsReasonSelected) {
      this.reasonSelected = this.refundsReasons.find(
        (reason) => reason.code == this.refundsReasonSelected
      );
    }

    if (this.imagesReturn) {
      for (let image of this.imagesReturn) {
        const data = this.fb.group({
          images: null,
          files: this.nameExtensionImage(
            image.url.split('/medias/')[1].split('?')[0]
          ),
        });
        this.images.push(data);
      }
    }
  }

  getValueReasonBoolean(): Observable<boolean> {
    return this.valueReason$.asObservable();
  }
  sendDataProductReturn(isReason?: boolean, event?: any) {
    if (isReason) {
      const nameReason = this.refundsReasons.find(
        (reason) => reason.code == event
      );

      if (nameReason?.name == 'Garantía') {
        this.valueReason$.next(true);
        this.inputFileForm.controls['inputFile'].enable();
      } else {
        this.valueReason$.next(false);
        this.inputFileForm.controls['inputFile'].disable();
        this.inputFileForm.controls['inputFile'].reset();
        let imageList: any = this.images.controls;

        for (let i = imageList.length; i > 0; --i) {
          this.onRemoveImage(imageList[i - 1]?.value?.images, i - 1, true);
        }
      }
    }
    this.productReturn.emit(this.itemReturnForm);
  }

  sendQuantityReturn() {
    if (
      this.itemReturnForm.controls['quantity'].value <= 0 ||
      this.itemReturnForm.controls['quantity'].value >
        this.item.returnableQuantity!
    ) {
      this.itemReturnForm.controls['quantity'].setErrors({ incorrect: true });
    }
    this.productReturn.emit(this.itemReturnForm);
  }

  uploadImage() {
    this.sendDataProductReturn();
  }

  enableOption() {
    if (this.itemReturnForm.controls['itemSelected'].value) {
      this.itemReturnForm.controls['quantity'].enable();
      this.itemReturnForm.controls['reasonReturn'].enable();
      this.productsSelected.emit({
        item: this.item.product?.code,
        enable: true,
      });
    } else {
      this.itemReturnForm.controls['quantity'].disable();
      this.itemReturnForm.controls['reasonReturn'].disable();
      this.valueReason$.next(false);
      this.inputFileForm.controls['inputFile'].disable();
      let imageList: any = this.images.controls;

      for (let i = imageList.length; i > 0; --i) {
        this.onRemoveImage(imageList[i - 1]?.value?.images, i - 1, true);
      }

      this.inputFileForm.controls['inputFile'].disable();
      this.itemReturnForm.reset(this.itemReturnForm.value);
      this.productsSelected.emit({
        item: this.item.product?.code,
        enable: false,
      });
    }
    this.productReturn.emit(this.itemReturnForm);
  }

  getFile(event: any) {
    let filesArray: any[] = [];
    if (event.target.files.length > 1) {
      for (var i = 0; i < event.target.files.length; i++) {
        filesArray.push(event.target.files[i][`'${i}'`]);
      }
    } else {
      filesArray.push(event.target.files['0']);
    }

    for (let image of filesArray) {
      const validFile = this.validateFileExtension(image?.type);
      const code = `${this.item.orderCode}${new Date(
        Date.now()
      ).toISOString()}_${this.doc}`;
      if (validFile) {
        this.subscription.add(
          this.customOrderReturnService
            .uploadDocument(image, code, this.doc)
            .pipe(
              catchError((error) => {
                this.showUnknowErrorFile = true;
                this.cd.detectChanges();
                return of(error?.error?.errors?.[0]?.type);
              })
            )
            .subscribe((response) => {
              if (!this.showUnknowErrorFile) {
                this.sendFiles.push(response!.uuid);
                this.cd.detectChanges();

                const data = this.fb.group({
                  images: response!.uuid,
                  files: this.nameExtensionImage(
                    event.target.files['0']?.name,
                    response!.uuid
                  ),
                });
                this.images.push(data);
                this.files.push(response!.uuid);
                this.itemReturnForm.controls['files'].setValue(this.files);

                if (this.images.length > 2) {
                  this.inputFileForm.controls['inputFile'].disable();
                }

                this.inputFileForm.controls['inputFile'].reset();

                this.sendDataProductReturn();
              } else {
                this.openModal(false, true);
                this.showUnknowErrorFile = false;
              }
            })
        );
      } else {
        this.openModal(true, false);
      }
    }
  }

  openModal(showFileTypeError: boolean, showUnknowErrorFile: boolean) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(
      CustomOrderRegisteredSuccessfullyDialogComponent,
      {
        centered: true,
        size: 'lg',
      }
    );
    if (showFileTypeError) {
      modalInstance = this.modalRef.componentInstance;
      modalInstance.showFileTypeError = showFileTypeError;
      modalInstance.showUnknowErrorFile = showUnknowErrorFile;
      return;
    }
    if (showUnknowErrorFile) {
      modalInstance = this.modalRef.componentInstance;
      modalInstance.showFileTypeError = showFileTypeError;
      modalInstance.showUnknowErrorFile = showUnknowErrorFile;
      return;
    }
    modalInstance = this.modalRef.componentInstance;
  }

  nameExtensionImage(
    image: any,
    code?: string
  ): { name: string; code: string | null } {
    const extention = image.split('.')[1];
    const name = image.slice(0, 6).trim() + `.${extention.toLowerCase()}`;
    return { name: name, code: code ? code : null };
  }

  validateFileExtension(fileType: string): boolean {
    if (
      fileType == 'image/jpeg' ||
      fileType == 'image/png' ||
      fileType == 'image/jpg'
    ) {
      return true;
    }
    return false;
  }

  onRemoveImage(
    imageUuid: any,
    index: number,
    isCheckboxActive: boolean = false
  ) {
    if (isCheckboxActive) {
      this.images.removeAt(index);
      this.files.splice(index, 1);
    }
    this.subscription.add(
      this.customOrderReturnService
        .deleteDocument(imageUuid)
        .subscribe((response) => {
          if (!isCheckboxActive) {
            this.images.removeAt(index);
            this.files.splice(index, 1);
          }
          this.cd.detectChanges();
          if (this.images.length > 0 && this.images.length < 3) {
            this.inputFileForm.controls['inputFile'].enable();
          }
          this.sendDataProductReturn();
        })
    );
  }

  ngOnDestroy(): void {
    if (this.itemReturnForm.valid == false) {
      this.productsSelected.emit({
        item: this.item.product?.code,
        enable: false,
      });
    }
    this.subscription.unsubscribe();
  }
}
