import { registerLocaleData } from '@angular/common';
import { NgModule } from '@angular/core';

import LocaleEsAR from '@angular/common/locales/es-AR';
import { CustomEmailRegisterMaskPipe } from './custom-email-register-mask.pipe';

registerLocaleData(LocaleEsAR);


@NgModule({
  declarations: [CustomEmailRegisterMaskPipe,
  ],
  exports: [CustomEmailRegisterMaskPipe],
})
export class CustomEmailRegisterMaskModule { }
