import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomModalGenericModule } from '../custom-modal/custom-modal-generic.module';
import { CustomUserBloquedDialogComponent } from './custom-user-bloqued-dialog.component';



@NgModule({
  declarations: [
    CustomUserBloquedDialogComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    CustomModalGenericModule
  ],
  exports: [
    CustomUserBloquedDialogComponent
  ]
})
export class CustomUserBloquedModule { }
