import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CheckoutStepService,
  PaymentTypeComponent,
} from '@spartacus/checkout/components';
import { PaymentTypeFacade } from '@spartacus/checkout/root';
import { ActiveCartService, Cart, PaymentType } from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CustomGtmInteractionDatalayerService } from 'src/app/common/services/custom-gtm-interaction-datalayer.service';
import { CustomBaseSiteCheckerService } from '../../../../services/custom-base-site-checker.service';
import { CustomUser } from '../../models/custom-user';
import { CustomCheckoutPaymentStepService } from '../../services/custom-checkout-payment-step.service';
import { CustomPaymentConditionsService } from '../../services/custom-payment-conditions.service';
import { CustomReviewSubmitService } from '../../services/custom-review-submit.service';
import { CustomPaymentCreditDialogComponent } from './custom-payment-credit/custom-payment-credit-dialog/custom-payment-credit-dialog.component';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-payment-type',
  templateUrl: './custom-payment-type.component.html',
  styleUrls: ['./custom-payment-type.component.scss'],
})
export class CustomPaymentTypeComponent
  extends PaymentTypeComponent
  implements OnInit
{
  modalRef: ModalRef;
  showCreditComponent: boolean = false;
  showCashComponent: boolean = false;
  showPointsComponent: boolean = false;
  customPaymentTypes$: Observable<PaymentType[]>;
  customCountedPaymentTypes: PaymentType[];
  paymentType: PaymentType = { code: 'COUNTED', displayName: 'Contado' };
  user$: Observable<CustomUser | undefined> = this.userAccountFacade.get();
  typeSelected$: Observable<string> =
    this.customCheckoutPaymentStepService.getPrincipalTypeSelected();
  subscription: Subscription = new Subscription();
  goToNextStep$: Observable<boolean> =
    this.customCheckoutPaymentStepService.getGoToNextStep();
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  codePaymentSelected: string;
  cart$: Observable<Cart | any> = this.activeCartService.getActive().pipe(
    tap((cart) => {
      if (cart?.paymentType?.code === 'CREDIT_LINE') {
        this.changeType(cart?.paymentType?.code);
      }
      if (
        cart?.paymentType?.code === 'DEPOSIT' ||
        cart?.paymentType?.code === 'TRANSFER'
      ) {
        this.changeType('COUNTED');
      }
    })
  );
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  showActionButton: boolean = false;
  constructor(
    private customReviewSubmitService: CustomReviewSubmitService,
    protected paymentTypeService: PaymentTypeFacade,
    protected checkoutStepService: CheckoutStepService,
    protected activatedRoute: ActivatedRoute,
    protected userAccountFacade: UserAccountFacade,
    protected customCheckoutPaymentStepService: CustomCheckoutPaymentStepService,
    protected customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    private customGtmDatalayerService: CustomGtmInteractionDatalayerService,
    private customPaymentConditionsService: CustomPaymentConditionsService,
    private activeCartService: ActiveCartService,
    protected modalService: ModalService,
    private customBreakpointService: CustomBreakpointService
  ) {
    super(paymentTypeService, checkoutStepService, activatedRoute);
  }

  ngOnInit(): void {
    if (this.isPromeclub) {
      this.customPaymentTypes$ = this.paymentTypeService.getPaymentTypes().pipe(
        map((types) =>
          types?.filter(
            (type) => type?.code !== 'CARD' && type?.code !== 'ACCOUNT'
          )
        ),
        map((types) => {
          types?.splice(1, 0, this.paymentType);
          this.customCountedPaymentTypes = types?.slice(2, 4);
          const principalTypes = types?.slice(4, 5);
          return principalTypes;
        })
      );
    } else {
      this.customPaymentTypes$ = this.user$.pipe(
        switchMap((user) =>
          combineLatest(
            this.paymentTypeService.getPaymentTypes(),
            this.customPaymentConditionsService.getCreditInfo(user?.uid!)
          )
        ),
        map(([types, data]) => {
          types = types?.filter(
            (type) => type?.code !== 'CARD' && type?.code !== 'ACCOUNT'
          );

          let sliceIndex = 2;
          if (data.carteraVencida || data.bloqueo) {
            sliceIndex = 1;
          }

          types?.splice(1, 0, this.paymentType);
          this.customCountedPaymentTypes = types?.slice(2, 4);
          const principalTypes = types?.slice(0, sliceIndex);
          return principalTypes;
        })
      );
    }

    this.subscription.add(
      this.typeSelected$
        .pipe(
          tap((type) => {
            if (type) {
              this.showComponent(type);
              this.showActionButton = true;
            } else {
              this.showActionButton = false;
            }
          })
        )
        .subscribe()
    );
  }

  changeType(code: string): void {
    this.typeSelected = code;
    this.customCheckoutPaymentStepService.setPrincipalTypeSelected(code);
    this.showComponent(code);
  }

  showComponent(code: string) {
    this.codePaymentSelected = code;
    if (code === 'CREDIT_LINE') {
      this.showCreditComponent = true;
      this.showCashComponent = false;
      this.customCheckoutPaymentStepService.cleanCountSelection();
    }
    if (code === 'COUNTED') {
      this.showCashComponent = true;
      this.showCreditComponent = false;
      this.customCheckoutPaymentStepService.cleanCreditSelection();
    }
    if (code === 'PROMECLUB') {
      this.showPointsComponent = true;
    }
  }

  /* los primeros 3 son para metodo de pago y los 2 segundos para forma de pago */

  paymentSelected: Map<string, string> = new Map([
    ['CREDIT_LINE', 'Credito'],
    ['COUNTED', 'Contado'],
    ['PROMECLUB', 'Puntos'],
    ['TRANSFER', 'Transferencia'],
    ['DEPOSIT', 'Deposito'],
  ]);

  onSubmit(user: CustomUser) {
    if (user.isEmployee && this.showCreditComponent) {
      let modalInstance: any;
      this.modalRef = this.modalService.open(
        CustomPaymentCreditDialogComponent,
        {
          centered: true,
          size: 'lg',
        }
      );
      this.subscription.add(
        this.modalRef.dismissed
          .pipe(
            tap(() => {
              this.next();
            })
          )
          .subscribe()
      );
    } else {
      this.next();
    }
  }

  next(): void {
    this.subscription.add(
      combineLatest([
        this.customCheckoutPaymentStepService.getCreditConditionSelectedValue(),
        this.customCheckoutPaymentStepService.getCountTypeSelected(),
      ])
        .pipe(
          map(([paymentCondition, countPaymentType]) => {
            this.customGtmDatalayerService.paymentModeEvent(
              this.paymentSelected.get(this.codePaymentSelected),
              this.codePaymentSelected == 'CREDIT_LINE'
                ? 'Credito'
                : this.paymentSelected.get(countPaymentType),
              paymentCondition == '' ? null : paymentCondition
            );
          })
        )
        .subscribe()
    );
    this.customReviewSubmitService.setIsReviewSubmitActive(true);
    this.checkoutStepService.next(this.activatedRoute);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
