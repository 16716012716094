import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {
  SemanticPathService,
  CmsService,
  RoutingService,
  CmsActivatedRouteSnapshot,
  PageContext,
  PageType,
} from '@spartacus/core';
import {
  CmsComponentsService,
  CmsGuardsService,
  CmsI18nService,
  CmsPageGuardService,
  CmsRoutesService,
} from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { switchMap, tap, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomCmsPageGuardService extends CmsPageGuardService {
  constructor(
    protected semanticPathService: SemanticPathService,
    protected cmsService: CmsService,
    protected cmsRoutes: CmsRoutesService,
    protected cmsI18n: CmsI18nService,
    protected cmsGuards: CmsGuardsService,
    protected cmsComponentsService: CmsComponentsService,
    protected routing: RoutingService,
    protected router: Router
  ) {
    super(
      semanticPathService,
      cmsService,
      cmsRoutes,
      cmsI18n,
      cmsGuards,
      cmsComponentsService,
      routing
    );
  }

  canActivateNotFoundPage(
    pageContext: PageContext,
    route: CmsActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const notFoundCmsPageContext: PageContext = {
      type: PageType.CONTENT_PAGE,
      id: this.semanticPathService.get('notFound'),
    };
    this.navigateToSearchByDeprecatedProductUrl();
    return this.cmsService.getPage(notFoundCmsPageContext).pipe(
      switchMap((notFoundPage) => {
        if (notFoundPage) {
          return this.cmsService.getPageIndex(notFoundCmsPageContext).pipe(
            tap((notFoundIndex) => {
              this.cmsService.setPageFailIndex(pageContext, notFoundIndex);
              this.routing.changeNextPageContext(notFoundCmsPageContext);
            }),
            switchMap((notFoundIndex) =>
              this.cmsService.getPageIndex(pageContext).pipe(
                // we have to wait for page index update
                filter((index) => index === notFoundIndex)
              )
            ),
            switchMap(() =>
              this.canActivatePage(pageContext, notFoundPage, route, state)
            )
          );
        }
        return of(false);
      })
    );
  }

  navigateToSearchByDeprecatedProductUrl() {
    const currentURL = window.location.href;
    if (currentURL.includes('/producto/')) {
      const searchTerm = currentURL
        .split('producto/')?.[1]
        ?.replace('?site=promesaSite', '');
      this.router?.navigateByUrl(`/search/${searchTerm}?query=${searchTerm}`);
    }
  }
}
