import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomOrderDetailTotalsComponent } from './custom-order-detail-totals.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomCartSharedModule } from 'src/app/cms-components/cart/custom-cart-shared/custom-cart-shared.module';



@NgModule({
  declarations: [
    CustomOrderDetailTotalsComponent
  ],
  imports: [
    CommonModule,
    CustomCartSharedModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        AccountOrderDetailsTotalsComponent: {
          component: CustomOrderDetailTotalsComponent,
        },
      },
    }),
  ],
  exports: [
    CustomOrderDetailTotalsComponent
  ]
})
export class CustomOrderDetailTotalsModule { }
