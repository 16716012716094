<div class="custom-filter" [class]="classSelect ? classSelect : 'big-filter'">
  <p>{{ label }}</p>
  <form [formGroup]="selectForm">
    <div [class]="classSelect ? classSelect : 'big-filter'">
      <ng-select
        id="filter"
        formControlName="filter"
        [searchable]="false"
        [clearable]="false"
        (change)="onChangeOption($event)"
        placeholder="{{ placeholder }}"
        required
        dropdownPosition="bottom"
      >
        <div *ngFor="let option of entries">
          <ng-option *ngIf="!option.name" [value]="option">
            {{ option }}
          </ng-option>
          <ng-option *ngIf="option.name" [value]="option.id" [diseabled]="!option.disabled" [aria-disabled]="!option.disabled">
            {{ option.name }}
          </ng-option>
        </div>
      </ng-select>
    </div>
  </form>
</div>
