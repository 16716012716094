import { Component, ViewEncapsulation } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { ModalService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';
import { Result } from '../../models/custom-stock.interface';
import { CustomCheckoutStockService } from '../../services/custom-checkout-stock.service';

@Component({
  selector: 'app-custom-stock-info-dialog',
  templateUrl: './custom-stock-info-dialog.component.html',
  styleUrls: ['./custom-stock-info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomStockInfoDialogComponent {
  entries$: Observable<Result[] | []> =
    this.customCheckoutStockService.getEntriesWithoutStock();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    private customCheckoutStockService: CustomCheckoutStockService,
    private modalService: ModalService,
    private routingService: RoutingService,
    private customBreakpointService: CustomBreakpointService
  ) {}

  closeModal(reason: string) {
    if (reason === 'redirect') {
      this.routingService.go({ cxRoute: 'cart' });
    }
    this.modalService.closeActiveModal(reason);
    this.modalService.dismissActiveModal(reason);
  }

  ngOnDestroy(): void {
    this.customCheckoutStockService.setEntriesToReview([]);
  }
}
