export const customSearchRefiner = {
  searchRefiner: {
    searchRefiner: {
      searchByVehicle: 'Buscar por vehículo',
      selectBrand: 'Marca',
      selectYear: 'Año',
      selectModel: 'Modelo',
      selectMotor: 'Motor',
    }
  },
}
