import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomPromeclubGuard } from '../../../../guards/custom-promeclub.guard';
import { CustomTopLinkComponent } from './custom-top-link.component';
import { IconModule } from '@spartacus/storefront';

@NgModule({
  declarations: [CustomTopLinkComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    IconModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromesaTopLinkContainer: {
          component: CustomTopLinkComponent,
          guards: [CustomPromeclubGuard],
        },
      },
    }),
  ],
  exports: [CustomTopLinkComponent],
  providers: [CustomPromeclubGuard],
})
export class CustomTopLinkModule {}
