import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CmsComponent, CmsService } from '@spartacus/core';
import { NavigationNode } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';

@Component({
  selector: 'app-custom-inner-header-contact',
  templateUrl: './custom-inner-header-contact.component.html',
  styleUrls: ['./custom-inner-header-contact.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomInnerHeaderContactComponent implements OnInit {
  footerComponentData$: Observable<CmsComponent> =
    this.cms.getComponentData('FooterNavComponent');
  contactNavigationNode$: Observable<NavigationNode | undefined>;
  constructor(
    private cms: CmsService,
    private CustomNavigationService: CustomNavigationService
  ) {}

  ngOnInit(): void {
    this.contactNavigationNode$ =
      this.CustomNavigationService.customGetNavigationNode(
        this.footerComponentData$,
        true
      ).pipe(
        map(
          (navNodes) =>
            navNodes?.children?.filter(
              (navNode) => navNode.title == 'Contactos'
            )[0]
        )
      );
  }
}
