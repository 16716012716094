import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCartTotalsComponent } from './custom-cart-totals.component';
import {
  CmsConfig,
  ConfigModule,
  FeaturesConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { CartCouponModule, ProgressButtonModule } from '@spartacus/storefront';
import { CustomCartSharedModule } from '../custom-cart-shared/custom-cart-shared.module';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomCartQuotesModule } from '../custom-cart-quotes/custom-cart-quotes.module';
import { CustomCartFromQuoteGuard } from '../guards/custom-cart-from-quote.guard';
import { IconModule } from '@spartacus/storefront';

@NgModule({
  declarations: [CustomCartTotalsComponent],
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    UrlModule,
    CartCouponModule,
    FeaturesConfigModule,
    ProgressButtonModule,
    CustomCartSharedModule,
    CustomCurrencyModule,
    CustomCartQuotesModule,
    IconModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CartTotalsComponent: {
          component: CustomCartTotalsComponent,
          guards: [CustomCartFromQuoteGuard],
        },
      },
    }),
  ],
})
export class CustomCartTotalsModule {}
