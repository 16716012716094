import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  ConfigModule,
  DeferLoadingStrategy,
  I18nModule
} from '@spartacus/core';
import {
  GenericLinkModule, IconModule, NavigationModule
} from '@spartacus/storefront';
import { CustomSafeHtmlModule } from '../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomFooterBarNavigationComponent } from './custom-footer-bar-navigation.component';

@NgModule({
  declarations: [CustomFooterBarNavigationComponent],
  imports: [
    CommonModule,
    RouterModule,
    NavigationModule,
    GenericLinkModule,
    I18nModule,
    IconModule,
    CustomSafeHtmlModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        FooterBarNavigationComponent: {
          component: CustomFooterBarNavigationComponent,
          deferLoading: DeferLoadingStrategy.DEFER,
        },
      },
    }),
  ],
})
export class CustomFooterBarNavigationModule {}
