import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import {
  Order,
  OrderHistoryList,
  PaginationModel,
  RoutingService,
} from '@spartacus/core';
import { OrderFacade } from '@spartacus/order/root';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomOrderHistoryService } from '../../services/custom-order-history.service';

@Component({
  selector: 'app-custom-order-cancel-history',
  templateUrl: './custom-order-cancel-history.component.html',
  styleUrls: ['./custom-order-cancel-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomOrderCancelHistoryComponent {
  cancellationsPage = true;
  sortType: string;
  quantities: any[] = [
    { id: 25, name: 25, disabled: true },
    { id: 50, name: 50, disabled: true },
    { id: 100, name: 100, disabled: true },
    { id: 200, name: 200, disabled: true },
    { id: 10000, name: 'Todos', disabled: true },
  ];
  pageSize: number = 10000;
  selectedPage: number = 1;
  orders$: Observable<OrderHistoryList | undefined> =
    this.customOrderHistoryService
      .getOrderFilterCancelled(this.pageSize, 0)
      .pipe(
        tap((orders: OrderHistoryList | undefined) => {
          if (orders?.pagination?.sort) {
            this.sortType = orders.pagination.sort;
          }
        })
      );

  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    private routing: RoutingService,
    private userOrderService: OrderFacade,
    private customOrderHistoryService: CustomOrderHistoryService,
    private customBreakpointService: CustomBreakpointService
  ) {}

  customPageChangeSelected(page: number, pagination?: PaginationModel): void {
    this.selectedPage = page;
    this.pageChange(page);
    this.orders$ = this.customOrderHistoryService.getOrderFilterCancelled(
      this.pageSize,
      page
    );
  }

  goToOrderCancelledDetail(order: Order): void {
    this.routing.go({
      cxRoute: 'orderCancelConfirmation',
      params: order,
    });
  }

  filterQuantitySelected(filter: any) {
    this.pageSize = filter;
    this.selectedPage = 0;
    this.orders$ = this.customOrderHistoryService.getOrderFilterCancelled(
      filter,
      0
    );
  }

  pageChange(page: number): void {
    const event: { sortCode: string; currentPage: number } = {
      sortCode: this.sortType,
      currentPage: page,
    };
    this.fetchOrders(event);
  }

  private fetchOrders(event: { sortCode: string; currentPage: number }): void {
    this.userOrderService.loadOrderList(
      this.pageSize,
      event.currentPage,
      event.sortCode
    );
  }
}
