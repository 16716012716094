import { CustomBaseSiteCheckerService } from '../../../../services/custom-base-site-checker.service';
import {
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  CmsService,
  CMSTabParagraphContainer,
  WindowRef,
} from '@spartacus/core';
import {
  BreakpointService,
  CmsComponentData,
  TabParagraphContainerComponent,
} from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-product-tab-paragraph-container',
  templateUrl: './custom-product-tab-paragraph-container.component.html',
  styleUrls: ['./custom-product-tab-paragraph-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomProductTabParagraphContainerComponent
  extends TabParagraphContainerComponent
  implements OnInit
{
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  isMobile$: Observable<boolean>;
  constructor(
    componentData: CmsComponentData<CMSTabParagraphContainer>,
    cmsService: CmsService,
    winRef: WindowRef,
    breakpointService: BreakpointService,
    private el: ElementRef,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    private customBreakpointService: CustomBreakpointService
  ) {
    super(componentData, cmsService, winRef, breakpointService);
  }

  ngOnInit(): void {
    this.isMobile$ = this.customBreakpointService.isMobile$;
    if (this.isPromeclub) {
      this.el?.nativeElement?.classList?.add('promeclub');
    }
  }
}
