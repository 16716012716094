<ng-container *ngIf="{ isExpanded: isExpanded | async } as data">
  <button
    class="cx-hamburger"
    type="button"
    (click)="customToggle(data.isExpanded)"
    [class.is-active]="data.isExpanded"
    [attr.aria-expanded]="data.isExpanded"
    [attr.aria-label]="'common.menu' | cxTranslate"
    aria-controls="header-account-container, header-categories-container, header-locale-container"
  >
    <span class="hamburger-box">
      <span class="hamburger-inner"></span>
    </span>
  </button>
</ng-container>
