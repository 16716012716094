<ng-container
  *ngIf="{
    isMobile: isMobile$ | async,
    quantity: quantity$ | async,
    user: user$ | async
  } as data"
>
  <div
    class="login-container"
    *ngIf="data.user; else login"
    [ngClass]="{ loggedin: !!data.user, 'modal-show': showMyAccount$ | async }"
    (mouseenter)="setShowMyAccount(true)"
    (mouseleave)="setShowMyAccount(false)"
  >
    <div *ngIf="!data.isMobile; else mobileGreeting" class="cx-login-greet">
      <span class="login-data-user">{{ data.user?.firstName }}</span>
      <cx-icon type="MY_ACCOUNT"></cx-icon>

      <div class="my-account" *ngIf="showMyAccount$ | async">
        <span class="account-text">{{
          "miniLogin.myAccount" | cxTranslate
        }}</span>
        <ng-container
          *ngFor="
            let link$ of myAccountComponent$ | async as myAccount;
            index as i
          "
        >
          <a
            class="list"
            *ngIf="link$ | async as link"
            [routerLink]="link?.url"
            (click)="setShowMyAccount(false)"
          >
            <cx-icon type="LOGOUT" *ngIf="myAccount?.length - 1 == i"></cx-icon>
            {{ link.linkName }}
          </a>
        </ng-container>
      </div>
    </div>

    <a
      role="link"
      class="logged-off points"
      *ngIf="data.isMobile && isInPromeclub"
    >
      <span class="singin-text" (click)="setShowModalPoints()">{{
        "creditPayment.myPoints" | cxTranslate
      }}</span>
    </a>
  </div>

  <app-custom-modal
    [instance]="instance"
    *ngIf="!data.user && (modalVisibility$ | async).visibility"
    [closeVisible]="data.isMobile ? true : false"
    [modalTitle]="
      data.isMobile ? ('loginForm.loginFormTitle' | cxTranslate) : ''
    "
  >
    <app-custom-login-form
      [fromModal]="true"
      [modalInstance]="instance"
    ></app-custom-login-form>
  </app-custom-modal>

  <app-custom-modal
    [instance]="'myAccount'"
    *ngIf="data.user && (modalVisibility$ | async).visibility"
    [closeVisible]="data.isMobile ? true : false"
    [modalTitle]="'customMyAccount.breadcrumb' | cxTranslate"
  >
    <app-custom-my-account
      [fromHeader]="true"
      [instance]="myAccount"
    ></app-custom-my-account>
  </app-custom-modal>

  <app-custom-modal
    [instance]="'points'"
    *ngIf="(modalVisibility$ | async).visibility"
    [closeVisible]="data.isMobile ? true : false"
    [modalTitle]="data.isMobile ? ('common.myPoints' | cxTranslate) : ''"
  >
    <div style="color: black" class="points-mobile">
      <p>{{ "creditPayment.availablePoints" | cxTranslate }}:</p>
      <p class="total-points-mobile" *ngIf="data.user">
        {{ "creditPayment.total" | cxTranslate }}
        {{ availablePoints$ | async | number : "" : "es-AR" }}
      </p>
    </div>
  </app-custom-modal>

  <ng-template #login>
    <a
      role="link"
      class="logged-off"
      (click)="setShowModalLoginForm('ingresar')"
      *ngIf="!data.isMobile"
    >
      <span class="my-account-text">{{
        "miniLogin.myAccount" | cxTranslate
      }}</span>
      <cx-icon type="MY_ACCOUNT"></cx-icon>
    </a>

    <a role="link" class="logged-off" *ngIf="data.isMobile && !isInPromeclub">
      <cx-icon
        type="MY_ACCOUNT"
        (click)="setShowModalLoginForm('ingresar')"
      ></cx-icon>
      <span class="singin-text" [routerLink]="{ cxRoute: 'login' } | cxUrl">{{
        "miniLogin.signInRegister" | cxTranslate
      }}</span>
    </a>

    <a
      role="link"
      class="logged-off points"
      *ngIf="data.isMobile && isInPromeclub"
    >
      <span class="singin-text" (click)="setShowModalPoints()">{{
        "creditPayment.myPoints" | cxTranslate
      }}</span>
    </a>

    <a role="link" class="logged-off" *ngIf="data.isMobile && isInPromeclub">
      <cx-icon
        type="MY_ACCOUNT"
        (click)="setShowModalLoginForm('ingresar')"
      ></cx-icon>
      <span class="singin-text" [routerLink]="{ cxRoute: 'login' } | cxUrl">{{
        "miniLogin.myAccount" | cxTranslate
      }}</span>
    </a>
  </ng-template>

  <ng-template #mobileGreeting>
    <ng-container *ngIf="data.user as info">
      <cx-icon type="MY_ACCOUNT" (click)="showMyAccountModal()"></cx-icon>
      <div *ngIf="!!info.user" class="px-3 user-name">
        {{
          "miniLogin.userGreeting"
            | cxTranslate : { name: info.user.firstName || info.user.name }
        }}
      </div>
    </ng-container>
  </ng-template>
</ng-container>
