<ng-container
  *ngIf="{
    currentPage: currentPage$ | async,
    isMobile: isMobile$ | async,
    mobileBack: mobileBackUrl$ | async,
    template: template$ | async,
    pageId: pageId$ | async,
    zeroResults: zeroResults$ | async
  } as page"
>
  <ng-container
    *ngIf="
      page.pageId !== 'register' &&
      page.pageId !== 'registerNonPromesaClient' &&
      page.pageId !== 'registerPromesaClient' &&
      page.pageId !== 'orderConfirmationPage' &&
      page?.pageId !== 'registerInternalPromesaClient' &&
      !(
        page.template == 'MultiStepCheckoutSummaryPageTemplate' && page.isMobile
      )
    "
  >
    <div
      class="custom-breadcrumb"
      [ngClass]="{
        positioning:
          page.mobileBack &&
          page.isMobile &&
          page.template !== 'CartPageTemplate' &&
          page.template !== 'MultiStepCheckoutSummaryPageTemplate',
        checkout: page.template === 'MultiStepCheckoutSummaryPageTemplate',
        'empty-search':
          (searchResultsEmpty$ | async) &&
          page?.template == 'SearchResultsListPageTemplate',
        pdp: page.template == 'ProductDetailsPageTemplate'
      }"
    >
      <div *ngIf="page.currentPage | async as crumbs">
        <ng-container
          *ngIf="
            (crumbs.length > 0 && !page.isMobile) ||
              (crumbs.length > 0 && page.template === 'CartPageTemplate') ||
              (crumbs.length > 0 &&
                page.template === 'MultiStepCheckoutSummaryPageTemplate') ||
              (crumbs.length > 0 && page.template === 'AccountPageTemplate') ||
              (crumbs.length > 0 && page.template === 'ErrorPageTemplate') ||
              (crumbs.length > 0 && page.template === 'ContentPage1Template');
            else mobileUrl
          "
        >
          <ng-container
            *ngIf="!(isReviewSubmitActive$ | async); else goBackToStepTwo"
          >
            <nav
              *ngIf="
                page.template !== 'ProductDetailsPageTemplate';
                else desktopPDP
              "
              class="custom-breadcrumb_text d-flex"
            >
              <span *ngFor="let crumb of crumbs; let i = index" class="d-flex">
                <a
                  [routerLink]="crumb.link"
                  *ngIf="i < crumbs.length - 1 || crumbs.length == 1"
                  >{{ crumb.label }}</a
                >
                <span
                  *ngIf="i == crumbs.length - 1 && crumbs.length != 1"
                  class="custom-breadcrumb__last-child"
                  >{{ crumb.label }}</span
                >
                <div
                  *ngIf="i < crumbs.length - 1"
                  class="custom-breadcrumb_slash"
                >
                  /
                </div>
              </span>
            </nav>
          </ng-container>
          <ng-template #desktopPDP>
            <nav
              *ngIf="
                crumbs.length > 0 &&
                page.template === 'ProductDetailsPageTemplate'
              "
              class="d-flex"
            >
              <span *ngFor="let crumb of crumbs; let i = index" class="d-flex">
                <a
                  [routerLink]="crumb.link"
                  *ngIf="i < crumbs.length || crumbs.length == 1"
                  >{{ crumb.label }}</a
                >
                <div *ngIf="i < crumbs.length" class="mx-1">/</div>
              </span>
              <span
                *ngIf="lastCrumb$ | async as lastCrumb"
                class="custom-breadcrumb__last-child"
                >{{ lastCrumb }}</span
              >
            </nav>
          </ng-template>
        </ng-container>
        <ng-template #mobileUrl>
          <ng-container
            *ngIf="
              crumbs.length > 0 &&
                page.template !== 'ProductDetailsPageTemplate';
              else mobilePDP
            "
          >
            <ng-container
              *ngIf="
                page.template !== 'CategoryPageTemplate' &&
                  page.template !== 'ProductGridPageTemplate';
                else CategoryPageMobile
              "
            >
              <a (click)="goBack()" *ngIf="page.mobileBack">
                <cx-icon class="fas fa-chevron-left"></cx-icon>
                <span class="custom-button-primary">{{
                  "navigation.goBack" | cxTranslate
                }}</span>
              </a>
            </ng-container>
            <ng-template #CategoryPageMobile>
              <a
                [routerLink]="crumbs[crumbs.length - 2].link"
                *ngIf="page.mobileBack"
              >
                <cx-icon class="fas fa-chevron-left"></cx-icon>
                <span class="custom-button-primary">{{
                  "navigation.goBack" | cxTranslate
                }}</span>
              </a>
              <ng-container
                *ngIf="
                  page.template == 'CategoryPageTemplate' ||
                  (page.template == 'ProductGridPageTemplate' &&
                    !page?.zeroResults)
                "
              >
                <div class="d-flex" id="domTitle">
                  <h1 class="custom-breadcrumb__title">
                    {{ crumbs[crumbs.length - 1].label }}
                  </h1>
                </div>
              </ng-container>
            </ng-template>
          </ng-container>
          <ng-template #mobilePDP>
            <nav class="d-flex pdp">
              <span *ngFor="let crumb of crumbs; let i = index" class="d-flex">
                <a
                  [routerLink]="crumb.link"
                  *ngIf="i < crumbs.length || crumbs.length == 1"
                  >{{ crumb.label }}</a
                >
                <div *ngIf="i < crumbs.length" class="mx-1">/</div>
              </span>
              <span
                *ngIf="lastCrumb$ | async as lastCrumb"
                class="custom-breadcrumb__last-child"
                >{{ lastCrumb }}</span
              >
            </nav>
          </ng-template>
        </ng-template>

        <ng-template #goBackToStepTwo>
          <nav class="d-flex custom-breadcrumb__review-submit">
            <a
              (click)="getBackToStepTwo()"
              [routerLink]="
                { cxRoute: getCheckoutStepUrl(checkoutStepType.PAYMENT_TYPE) }
                  | cxUrl
              "
            >
              <cx-icon class="fas fa-chevron-left"></cx-icon>
              <span> {{ "checkout.goBackToStepTwo" | cxTranslate }} </span>
            </a>
          </nav>
        </ng-template>
      </div>
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      (page.template !== 'AccountPageTemplate' &&
        (page.template == 'CategoryPageTemplate' ||
          page.template == 'ProductGridPageTemplate' ||
          page?.template == 'CartPageTemplate') &&
        !page.isMobile) ||
      (page?.template == 'ProductGridPageTemplate' && page?.zeroResults) ||
      page?.template == 'SearchResultsListPageTemplate' ||
      (page?.template == 'MultiStepCheckoutSummaryPageTemplate' &&
        !page.isMobile)
    "
  >
    <div *ngIf="titlePpal$ | async as title$">
      <ng-container
        *ngIf="{
          title: title$ | async
        } as data"
      >
        <div class="d-flex w-100" id="domTitle">
          <h1
            class="custom-breadcrumb__title"
            *ngIf="data.title && !page?.zeroResults"
            [innerHTML]="data.title | titlecase | safeHtml"
          ></h1>
          <h1
            class="custom-breadcrumb__title without-results"
            *ngIf="page?.zeroResults"
          >
            {{ "searchPageBreadcrumb.zeroResults" | cxTranslate }}
          </h1>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
