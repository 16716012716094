<ng-container *ngIf="componentData$ | async as data">
  <div class="newsletter">
    <div class="newsletter__info">
      <h5
        *ngIf="!!data.banner.headline"
        [innerHtml]="data.banner.headline | safeHtml"
        class="newsletter__info-title"
      ></h5>
      <form
        autocomplete="off"
        validateOnSubmit
        (ngSubmit)="onSubmit()"
        [formGroup]="emailInput"
        class="newsletter__info-action"
        *ngIf="!success"
      >
        <div class="newsletter__info-action-input">
          <input
            type="email"
            name="email"
            autocomplete="off"
            class="form-control"
            formControlName="email"
            placeholder="{{ 'email.placeholder' | cxTranslate }} "
          />
          <cx-form-errors [control]="emailInput.get('email')"></cx-form-errors>
        </div>
        <div class="newsletter__info-action-button">
          <button class="custom-button primary">
            <span class="button-text">
              {{ "button.text" | cxTranslate }}
            </span>
            <cx-icon class="fas fa-chevron-right"></cx-icon>
          </button>
        </div>
      </form>
      <div class="newsletter__info-action-success" *ngIf="success">
        <p>
          <cx-icon class="fas fa-solid fa-check"></cx-icon>
          {{ "success.text" | cxTranslate }}
        </p>
      </div>
    </div>
    <img
      *ngIf="!(isMobile$ | async)"
      class="newsletter__img"
      [src]="getImageUrl(data.banner.media.desktop.url)"
      [alt]="data.banner.media.desktop.altText"
      loading="lazy"
    />
  </div>
</ng-container>
