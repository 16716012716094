import { Injectable } from '@angular/core';
import { PromesaSection } from '../common/models/promesa-section';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  AUTOMOTIVE_LABEL_KEY,
  AUTOMOTIVE_LABEL_PDP,
} from '../constants/automotive-constants';

@Injectable({
  providedIn: 'root',
})
export class AutomotiveFilterService {
  PROMESA_SECTION = PromesaSection;
  automotiveFilterPdp: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor() {}

  getAutomotiveFilterApplied(): string {
    const automotiveFilter = localStorage.getItem('queryFilterAutomotriz');
    const currentSection = localStorage.getItem('currentSection')!;
    let parsedAutomotiveFilter = '';
    if (
      currentSection == this.PROMESA_SECTION.AUTOMOTRIZ.toLowerCase() &&
      automotiveFilter &&
      JSON.parse(automotiveFilter)?.length > 0
    ) {
      parsedAutomotiveFilter = JSON.parse(automotiveFilter)?.[0]?.name;
    }
    return parsedAutomotiveFilter;
  }

  getAutomotiveFilterQueryApplied(): string {
    const automotiveFilterQuery = localStorage.getItem('queryFilterAutomotriz');
    const currentSection = localStorage.getItem('currentSection')!;
    let parsedAutomotiveFilterQuery = '';
    if (
      currentSection == this.PROMESA_SECTION.AUTOMOTRIZ.toLowerCase() &&
      automotiveFilterQuery &&
      JSON.parse(automotiveFilterQuery)?.length > 0
    ) {
      parsedAutomotiveFilterQuery = JSON.parse(automotiveFilterQuery)?.[0]
        ?.query;
    }
    return parsedAutomotiveFilterQuery;
  }

  isAutomotiveSection(): Observable<boolean> {
    const currentSection = localStorage.getItem('currentSection')!;
    return of(currentSection == this.PROMESA_SECTION.AUTOMOTRIZ.toLowerCase());
  }

  getAutomotiveLabel(): string[] {
    const parsedAutomotiveFilter = this.getAutomotiveFilterApplied();
    return parsedAutomotiveFilter.split(' ');
  }

  setAutomotiveLabel(): void {
    const parsedAutomotiveFilter = this.getAutomotiveFilterApplied();
    localStorage.setItem(AUTOMOTIVE_LABEL_KEY, parsedAutomotiveFilter);
  }

  persistAutomotiveLabel(active: boolean): void {
    localStorage.setItem(AUTOMOTIVE_LABEL_PDP, String(active));
  }

  setAutomotiveFilterPdp(value: boolean, fromGetter: boolean = false): void {
    if (!fromGetter) {
      this.persistAutomotiveLabel(value);
    }

    this.automotiveFilterPdp.next(value);
  }

  getAutomotiveFilterPdp(): Observable<boolean> {
    const active = JSON.parse(localStorage.getItem(AUTOMOTIVE_LABEL_PDP)!);
    this.setAutomotiveFilterPdp(active, true);
    return this.automotiveFilterPdp.asObservable();
  }
}
