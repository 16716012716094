<ng-container
  *ngIf="{
    loggedIn: isUserLoggedIn$ | async,
    isMobile: isMobile$ | async
  } as data"
>
  <form
    *ngIf="productCode && data.loggedIn; else loginForm"
    [formGroup]="addToCartForm"
    (submit)="addToCart(data.isMobile)"
    [ngClass]="{
      'from-product-card': fromProductCard,
      content: fromContent,
      rebate: isRebate
    }"
    class="add-to-cart-form"
  >
    <div
      class="unit-price"
      *ngIf="!fromProductCard && product && !data.isMobile"
    >
      <p class="unit-price__title" *ngIf="!isInPromeclub">
        {{ "addToCart.unitPrice" | cxTranslate
        }}{{ product?.unit?.toLocaleString() }}
      </p>
      <p class="unit-price__title" *ngIf="isInPromeclub">
        {{ "addToCart.unitPricePromeclub" | cxTranslate
        }}{{ product?.unit?.toLocaleString() }}
      </p>
      <p
        class="unit-price__value previous"
        *ngIf="
          fromProductVariantPlpDesktop &&
          product?.previousPrice?.value &&
          !isInPromeclub
        "
      >
        {{ product?.previousPrice?.value | customCurrency }}
      </p>
      <p
        class="unit-price__value"
        [ngClass]="{
          discount: fromProductVariantPlp && product?.previousPrice?.value
        }"
      >
        <span *ngIf="!isInPromeclub">
          {{ product?.priceWithIVA?.value | customCurrency }}
        </span>
        <span *ngIf="isInPromeclub">
          {{ product?.price?.value | number : "1.0-0" : "es-AR" }}
        </span>
        <span
          class="unit-price__value-iva"
          *ngIf="isInPromeclub"
          [ngClass]="{
            discount: fromProductVariantPlp && product?.previousPrice?.value
          }"
        >
          {{ "productList.points" | cxTranslate }}
        </span>
      </p>
    </div>
    <div
      class="quantity"
      *ngIf="showQuantity"
      [ngClass]="{
        pdp: !fromProductCard,
        'out-of-stock': !hasStock,
        'with-error': addToCartForm.get('quantity').invalid
      }"
    >
      <label *ngIf="!fromProductCard && !data.isMobile">
        {{ "addToCart.quantity" | cxTranslate }}
      </label>

      <ng-container *ngIf="!fromProductCard; else productCard">
        <div class="quantity-container" *ngIf="hasStock">
          <app-custom-item-counter
            *ngIf="product"
            [fromProductVariantPlpDesktop]="true"
            [productStockLevel]="product?.stock?.stockLevel"
            [control]="addToCartForm.get('quantity')"
            [ngClass]="{ 'with-error': addToCartForm.get('quantity').invalid }"
          >
          </app-custom-item-counter>
          <span
            class="info"
            *ngIf="addToCartForm.get('quantity').invalid && !isInPromeclub"
          >
            {{
              "addToCart.availableStock"
                | cxTranslate : { stock: product?.stock?.stockLevel }
            }}
          </span>
        </div>

        <p
          class="total-price__value"
          *ngIf="hasStock && data.isMobile"
          [ngClass]="{
            promeclub: isInPromeclub,
            'with-previous': !!product?.previousPrice?.value
          }"
        >
          <ng-container *ngIf="!isInPromeclub">
            <span
              *ngIf="!!product?.previousPrice?.value"
              class="total-price__value-previous"
            >
              {{ product?.previousPrice?.value | customCurrency }}
            </span>
            {{ getTotal(product?.priceWithIVA?.value) | customCurrency }}
            <span
              class="total-price__value-iva"
              [ngClass]="{ 'with-previous': !!product?.previousPrice?.value }"
            >
              {{ "productList.plusIva" | cxTranslate }}
            </span>
          </ng-container>
          <ng-container *ngIf="isInPromeclub">
            {{ getTotal(product?.price?.value) | number : "1.0-0" : "es-AR" }}
            <span class="total-price__value-iva promeclub">
              {{ "productList.points" | cxTranslate }}
            </span>
          </ng-container>
        </p>

        <button
          #btnSubmit
          *ngIf="hasStock && !fromProductCard && data.isMobile"
          class="btn btn-primary btn-block"
          type="submit"
          [disabled]="
            (quantity <= 0 ||
              quantity > maxQuantity ||
              addToCartForm.get('quantity').invalid ||
              product.isGift ||
              product?.productType == EcvProductType) &&
            !isInPromeclub
          "
        >
          <div>{{ "addToCart.addToCartMobile" | cxTranslate }}</div>
          <cx-icon class="fas fa-shopping-cart"></cx-icon>
        </button>
        <ng-container *ngIf="data.isMobile && data.loggedIn && !isInPromeclub">
          <app-custom-promeclub-product-points
            [addToCartPdp]="true"
          ></app-custom-promeclub-product-points>
        </ng-container>
      </ng-container>

      <ng-template #productCard>
        <cx-item-counter
          *ngIf="!fromProductVariantPlp"
          [max]="maxQuantity"
          [control]="addToCartForm.get('quantity')"
        ></cx-item-counter>
        <div class="quantity-container" *ngIf="fromProductVariantPlp">
          <app-custom-item-counter
            *ngIf="product"
            [productStockLevel]="product?.stock?.stockLevel"
            [control]="addToCartForm.get('quantity')"
            [ngClass]="{ 'with-error': addToCartForm.get('quantity').invalid }"
          >
          </app-custom-item-counter>
          <span
            class="info"
            *ngIf="addToCartForm.get('quantity').invalid && !data.isMobile"
          >
            {{
              "addToCart.availableStock"
                | cxTranslate : { stock: product?.stock?.stockLevel }
            }}
          </span>
        </div>
      </ng-template>
    </div>
    <div
      class="product-quantity-without-stock"
      *ngIf="!hasStock && data.isMobile && !fromProductCard"
    >
      <p class="unit-price__title">
        {{ "addToCart.unitPrice" | cxTranslate
        }}{{ product?.unit?.toLocaleString() }}
      </p>
      <p class="unit-price__value">
        {{ product?.priceWithIVA?.value | customCurrency }}
        <span class="unit-price__value-iva">
          {{ "productList.plusIva" | cxTranslate }}
        </span>
      </p>
    </div>
    <div class="total-price" *ngIf="!fromProductCard && product && hasStock">
      <p class="total-price__title" *ngIf="!data.isMobile">
        {{ "addToCart.total" | cxTranslate }}
      </p>
      <p class="total-price__value" *ngIf="!data.isMobile && !isInPromeclub">
        {{ getTotal(product?.priceWithIVA?.value) | customCurrency }}
        <span class="total-price__value-iva">
          {{ "productList.plusIva" | cxTranslate }}
        </span>
      </p>
      <p
        class="total-price__value total-price__value-promeclub"
        *ngIf="!data.isMobile && isInPromeclub"
      >
        {{ getTotal(product?.price?.value) | number : "1.0-0" : "es-AR" }}
        <span class="total-price__value-iva">
          {{ "productList.points" | cxTranslate }}
        </span>
      </p>
    </div>
    <div
      *ngIf="!hasStock && isInPromeclub && product && !fromProductCard"
      class="total-price out-of-stock"
    >
      <p #outOfStock class="total-price__title out-of-stock">
        {{ "addToCart.outOfStock" | cxTranslate | uppercase }}
      </p>
    </div>
    <div
      class="total-price__out-of-stock"
      *ngIf="!fromProductCard && product && !hasStock && !isInPromeclub"
    >
      <ng-container
        *ngIf="
          !!product.stock?.tentativeDateToReposition;
          else noStockNorRepositionDate
        "
      >
        <button
          type="button"
          class="btn total-price__out-of-stock-title"
          (click)="addAlert()"
        >
          <p #outOfStock>
            {{ "futureStock.btnProductDetail" | cxTranslate }}
          </p>
        </button>
        <div
          class="total-price__out-of-stock-msg"
          *ngIf="!!product.stock?.tentativeDateToReposition"
          [innerHTML]="
            'futureStock.msgDateFutureStock'
              | cxTranslate : { date: product.stock?.tentativeDateToReposition }
              | safeHtml
          "
        ></div>
      </ng-container>
      <ng-template #noStockNorRepositionDate>
        <button type="button" class="btn total-price__out-of-stock-title">
          <p #outOfStock>
            {{ "addToCart.outOfStock" | cxTranslate | uppercase }}
          </p>
        </button>
      </ng-template>
      <app-custom-promeclub-product-points
        [addToCartPdp]="true"
        *ngIf="!isInPromeclub"
      ></app-custom-promeclub-product-points>
    </div>
    <button
      *ngIf="hasStock && !fromProductCard && !data.isMobile"
      class="btn btn-primary btn-block"
      type="submit"
      [disabled]="
        (quantity <= 0 ||
          quantity > maxQuantity ||
          addToCartForm.get('quantity').invalid ||
          product.isGift ||
          product?.productType == EcvProductType) &&
        !isInPromeclub
      "
    >
      <div>{{ "addToCart.addToCart" | cxTranslate }}</div>
      <cx-icon class="fas fa-shopping-cart"></cx-icon>
    </button>

    <ng-container *ngIf="fromProductCard">
      <button
        *ngIf="!fromProductVariantPlp; else variantButton"
        class="custom-button primary"
        type="submit"
        [disabled]="
          product.isGift ||
          ((product?.stock?.stockLevelStatus == OUT_OF_STOCK) &&
            !isInPromeclub)
        "
      >
        <div>
          {{ "productList.add" | cxTranslate }}
        </div>
        <cx-icon type="MINICART"></cx-icon>
      </button>
      <ng-template #variantButton>
        <button
          class="custom-button primary"
          type="submit"
          [disabled]="
            product.isGift ||
            ((product?.stock?.stockLevelStatus == OUT_OF_STOCK) &&
              !isInPromeclub)
          "
        >
          <div>{{ "productList.add" | cxTranslate }}</div>
          <cx-icon type="MINICART"></cx-icon>
        </button>
      </ng-template>
    </ng-container>
  </form>
  <div
    class="custom-promeclub-product-points-pdp"
    *ngIf="
      !isRebate &&
      !fromContent &&
      !isInPromeclub &&
      !fromProductCard &&
      !data.isMobile
    "
  >
    <app-custom-promeclub-product-points></app-custom-promeclub-product-points>
  </div>

  <ng-template #loginForm>
    <div>
      <app-custom-login-form
        *ngIf="productCode"
        [fromPdp]="true"
        class="p-0"
        [floatingSelector]="floatingSelectorLoginForm"
      ></app-custom-login-form>
    </div>
  </ng-template>
</ng-container>
