import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomDoc } from '../../models/custom-doc-data.interface';
import { CustomRegisterDocsService } from '../../services/custom-register-docs.service';
import { CustomRegisterNonClientFormService } from '../../services/custom-register-non-client-form.service';

@Component({
  selector: 'app-custom-register-non-customer-docs-readonly',
  templateUrl: './custom-register-non-customer-docs-readonly.component.html',
  styleUrls: ['./custom-register-non-customer-docs-readonly.component.scss']
})
export class CustomRegisterNonCustomerDocsReadonlyComponent implements OnInit {

  docsValid$: Observable<CustomDoc[] | null> = this.customRegisterDocsService.getDocsData();
  idDocLabel: string = 'nonCustomerRegister.attachId';
  rucDocLabel: string = 'nonCustomerRegister.attachRuc';
  registeredAppoinmentDocLabel: string = 'nonCustomerRegister.attachRegisteredAppoinment';
  letterDocLabel: string = 'nonCustomerRegister.attachLetter';
  isJuridicPerson$: Observable<boolean> = this.customRegisterNonClientFormService.getNonCustomerFormData().pipe(
    map(form => form?.juridicPerson!),
  );
  constructor(
    private customRegisterDocsService: CustomRegisterDocsService,
    private customRegisterNonClientFormService: CustomRegisterNonClientFormService,
  ) { }

  ngOnInit(): void {
  }

}
