<div class="custom-order-overview" *ngIf="order">
  <ng-container
    *ngIf="{
      pageCurrent: pageCurrent$ | async,
      isMobile: isMobile$ | async
    } as data"
  >
    <div class="custom-order-overview__order">
      <div class="custom-order-overview__order-data">
        <cx-card
          *ngIf="data.pageCurrent != 'claim-detail'"
          [content]="getOrderCodeCardContent(order?.code) | async"
        ></cx-card>
        <cx-card
          *ngIf="data.pageCurrent == 'claim-detail'"
          [content]="
            getOrderCodeCardContent(splitStringCode(order.associatedTo)) | async
          "
        ></cx-card>
        <cx-card
          *ngIf="data.pageCurrent == 'ConfirmCancelOrderPage'"
          [content]="
            getOrderCancellationCurrentDateCardContent(order, data.isMobile)
              | async
          "
        ></cx-card>

        <cx-card
          *ngIf="
            data.pageCurrent == 'order' ||
            data.pageCurrent == 'CancelDetailOrderPage' ||
            data.pageCurrent == 'paymentRecordPage' ||
            data.pageCurrent == 'ReturnRequestOrderPage' ||
            data.pageCurrent == 'add-claim'
          "
          [ngClass]="data.pageCurrent == 'add-claim' && 'cx-card-add-claim'"
          [content]="
            getOrderCurrentDateCardContent(
              order?.created || order?.creationDate | date : 'dd/MM/yyyy'
            ) | async
          "
        ></cx-card>
        <cx-card
          *ngIf="data.pageCurrent == 'ReturnDetailOrderPage'"
          [content]="
            getOrderReturnDate(
              order?.creationTime | date : 'dd/MM/yyyy',
              data.isMobile
            ) | async
          "
        ></cx-card>
        <cx-card
          *ngIf="data.pageCurrent == 'claim-detail'"
          class="cx-card-add-claim"
          [content]="
            getClaimDetailCurrentDateCardContent(
              order?.creationDate | date : 'dd/MM/yyyy'
            ) | async
          "
        ></cx-card>

        <cx-card
          [content]="
            getOrderTotalCardContent(
              data.pageCurrent == 'ReturnDetailOrderPage'
                ? (order.totalPrice?.value | customCurrency)
                : (order?.totalPriceWithTax?.value || order?.totalOrder
                    | customCurrency)
            ) | async
          "
        ></cx-card>

        <cx-card
          *ngIf="
            data.pageCurrent != 'ReturnDetailOrderPage' &&
            data.pageCurrent != 'claim-detail'
          "
          [content]="getOrderStatusCardContent(order.status) | async"
        ></cx-card>
        <cx-card
          *ngIf="data.pageCurrent == 'ReturnDetailOrderPage'"
          [content]="getOrderStatusDevolution(order.status) | async"
        ></cx-card>
        <cx-card
          *ngIf="data.pageCurrent == 'claim-detail'"
          [content]="getOrderStatusCardContent(order.status.id) | async"
        ></cx-card>
      </div>

      <ng-content select="[actions]"></ng-content>
    </div>

    <div
      class="custom-order-overview__order-details"
      *ngIf="
        !(
          data.pageCurrent == 'paymentRecordPage' ||
          data.pageCurrent == 'ReturnRequestOrderPage' ||
          data.pageCurrent == 'ReturnDetailOrderPage'
        ) && !isClaim
      "
    >
      <div
        class="custom-order-overview__order-details-cards"
        [ngClass]="{
          details_cancel:
            data.pageCurrent == 'CancelDetailOrderPage' ||
            data.pageCurrent == 'ConfirmCancelOrderPage'
        }"
      >
        <ng-container>
          <cx-card
            [content]="getInformationPaidCardContent(order) | async"
          ></cx-card>
        </ng-container>

        <ng-container *ngIf="order.deliveryMode">
          <cx-card
            class="custom-order-overview__subtitle-delivery-mode"
            [content]="getDeliveryModeCardContent(order) | async"
          ></cx-card>
        </ng-container>

        <ng-container>
          <div class="cx-overview-card">
            <cx-card [content]="getPaymentInfoContent(order) | async"></cx-card>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
