export const customMyCompany = {
  myCompany: {
    myCompany:{
      myCompanyTitle: 'Datos de mi empresa',
      myCompanySubtitle: 'Datos comerciales',
      socialReason: 'Razón social: ',
      comercialName: 'Nombre comercial: ',
      ruc: 'RUC: ',
      customerNumber: 'Número de cliente: ',
      phone: 'Teléfono: ',
      email: 'E-mail: '
    }
  }
};
