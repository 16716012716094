<ng-container *ngIf="{isMobile: isMobile$ | async} as data">
    <div
      *ngIf="!data.isMobile"
      class="cart-list__summary-title"
      [innerHTML]="'orderCost.orderSummary' | cxTranslate | safeHtml"
    >
    </div>
    <div class="cart-list__summary-box">
      <div class="cart-list__summary-box__title">
        <span>{{
          data.isMobile ?
          ('addToCart.emptyTotalMobile' | cxTranslate)
          : ('addToCart.total' | cxTranslate)
        }}
        <span class="cart-list__summary-box__title-paragraph">-</span>
      </span>
        <button
        *ngIf="data.isMobile"
        class="cart-list__summary-box__button"
        [disabled]="true"
      >
        <span>{{'cartDetails.proceedToCheckout' | cxTranslate}}</span>
      </button>
    </div>
      <button
        *ngIf="!data.isMobile"
        class="cart-list__summary-box__button"
        [disabled]="true"
      >
        <span>{{'cartDetails.proceedToCheckout' | cxTranslate}}</span>
        <cx-icon class="fas fa-chevron-right"></cx-icon>
      </button>
    </div>
</ng-container>
