<ng-container *ngIf="{isMobile: isMobile$ | async} as data">
  <div class="success-container">
    <div class="success-container__close">
      <cx-icon class="fas fa-times" (click)="closeModal('user-close')"></cx-icon>
    </div>
    <div class="success-container__info">
      <div class="success-container__info-text"[innerHTML]="(!data.isMobile ? 'customPromeclub.form.modalSuccessTextDesktop' : 'customPromeclub.form.modalSuccessTextMobile')| cxTranslate"></div>
      <a (click)="navigateToHome()" class="success-container__info-link">{{'customPromeclub.form.goHome' | cxTranslate}}</a>
    </div>
  </div>
</ng-container>


