import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomCheckoutPaymentStepService {
  principalTypeSelected$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  countTypeSelected$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  creditConditionSelectedCode$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  creditConditionSelectedValue$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  pointConditionSelected$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  goToNextStep$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() {}

  setPrincipalTypeSelected(code: string): void {
    this.principalTypeSelected$.next(code);
  }

  getPrincipalTypeSelected(): Observable<string> {
    return this.principalTypeSelected$.asObservable();
  }

  setCountTypeSelected(code: string): void {
    this.countTypeSelected$.next(code);
  }

  getCountTypeSelected(): Observable<string> {
    return this.countTypeSelected$.asObservable();
  }

  setCreditConditionSelectedCode(code: string): void {
    this.creditConditionSelectedCode$.next(code);
  }
  setCreditConditionSelectedValue(code: string): void {
    this.creditConditionSelectedValue$.next(code);
  }

  getCreditConditionSelectedCode(): Observable<string> {
    return this.creditConditionSelectedCode$.asObservable();
  }

  getCreditConditionSelectedValue(): Observable<string> {
    return this.creditConditionSelectedValue$.asObservable();
  }

  setPointConditionSelected(code: string): void {
    this.pointConditionSelected$.next(code);
  }

  getPointConditionSelected(): Observable<string> {
    return this.pointConditionSelected$.asObservable();
  }

  setGoToNextStep(value: boolean): void {
    this.goToNextStep$.next(value);
  }

  getGoToNextStep(): Observable<boolean> {
    return this.goToNextStep$.asObservable();
  }

  cleanStepSelection(): void {
    this.countTypeSelected$.next('');
    this.creditConditionSelectedCode$.next('');
    this.creditConditionSelectedValue$.next('');
    this.pointConditionSelected$.next('');
    this.principalTypeSelected$.next('');
    this.goToNextStep$.next(false);
  }

  cleanCountSelection(): void {
    this.countTypeSelected$.next('');
    this.goToNextStep$.next(false);
  }

  cleanCreditSelection(): void {
    this.creditConditionSelectedCode$.next('');
    this.creditConditionSelectedValue$.next('');
    this.goToNextStep$.next(false);
  }
}
