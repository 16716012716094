import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutletHeaderComponent } from './outlet-header.component';
import { RouterModule } from '@angular/router';
import { CustomLoadingSpinnerModule } from '../../cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';
import {
  OutletRefModule,
  PageLayoutModule,
  KeyboardFocusModule,
  PageSlotModule,
  SkipLinkModule,
} from '@spartacus/storefront';

@NgModule({
  declarations: [OutletHeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    OutletRefModule,
    PageLayoutModule,
    KeyboardFocusModule,
    PageLayoutModule,
    PageSlotModule,
    SkipLinkModule,
    CustomLoadingSpinnerModule,
  ],
  exports: [OutletHeaderComponent],
})
export class OutletHeaderModule {}
