<div class="custom-payment-credit-employee-dialog">
  <div class="custom-payment-credit-employee-dialog__icon">
    <cx-icon class="fas fa-times" (click)="closeModal('close')"></cx-icon>
  </div>
  <div class="custom-payment-credit-employee-dialog__text">
    <ng-container *ngIf="!(isMobile$ | async); else textForMobile">
      <p>{{ "creditPayment.creditPaymentEmployeeText" | cxTranslate }}</p>
      <button class="custom-payment-credit-employee-dialog__text--continue" (click)="continue('continue')">
        {{ "common.continue" | cxTranslate }}
      </button>
    </ng-container>
    <ng-template #textForMobile>
      <p>{{ "creditPayment.creditPaymentEmployeeTextOne" | cxTranslate }}</p>
      <p>{{ "creditPayment.creditPaymentEmployeeTextTwo" | cxTranslate }}</p>
      <a class="custom-payment-credit-employee-dialog__text--continue btn-link" (click)="continue('continue')">
        {{ "common.continue" | cxTranslate }}
      </a>
    </ng-template>
  </div>
</div>
