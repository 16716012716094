<div class="custom-register-employee-validation-container">
  <div class="custom-register-employee-validation-container__icon">
    <cx-icon class="fas fa-times" (click)="closeModal('close')"></cx-icon>
  </div>
  <div class="custom-register-employee-validation-container__text">
    <ng-container *ngIf="!(isMobile$ | async); else textForMobile">
      <p>{{ "registerEmployee.registerEmployeeValidation.employeeCodeError" | cxTranslate }}</p>
      <p>{{ "registerEmployee.registerEmployeeValidation.employeeCodeContactSupport" | cxTranslate }}</p>
    </ng-container>
    <ng-template #textForMobile>
      <p>{{ "registerEmployee.registerEmployeeValidation.employeeCodeErrorMobile" | cxTranslate }}</p>
    </ng-template>
    <a class="custom-register-employee-validation-container__text--return" (click)="closeModal('close')">
      {{ "registerEmployee.goBack" | cxTranslate }}
    </a>
  </div>
</div>
