<div class="quote-checkout-info">
  <app-custom-modal-generic></app-custom-modal-generic>

  <div class="quote-checkout-info__icon">
    <cx-icon class="fas fa-exclamation-triangle"></cx-icon>
  </div>
  <div
    class="quote-checkout-info__title"
    [innerHTML]="
      'accountQuotes.checkout.title' | cxTranslate : { code: code } | safeHtml
    "
  ></div>
  <ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
    <div
      *ngIf="!data.isMobile"
      class="quote-checkout-info__message"
      [innerHTML]="'accountQuotes.checkout.message' | cxTranslate | safeHtml"
    ></div>
    <div
      *ngIf="!data.isMobile"
      class="quote-checkout-info__message two"
      [innerHTML]="'accountQuotes.checkout.messageTwo' | cxTranslate | safeHtml"
    ></div>
    <div
      *ngIf="data.isMobile"
      class="quote-checkout-info__message"
      [innerHTML]="
        'accountQuotes.checkout.messageMobile' | cxTranslate | safeHtml
      "
    ></div>
    <div
      *ngIf="data.isMobile"
      class="quote-checkout-info__message two"
      [innerHTML]="
        'accountQuotes.checkout.messageTwoMobile' | cxTranslate | safeHtml
      "
    ></div>
  </ng-container>

  <div class="quote-checkout-info__action">
    <div
      class="quote-checkout-info__action-link"
      (click)="closeModal('cancel')"
    >
      {{ "accountQuotes.checkout.cancel" | cxTranslate }}
    </div>
    <button class="quote-checkout-info__button" (click)="createCartFromQuote()">
      {{ "accountQuotes.checkout.continue" | cxTranslate }}
    </button>
  </div>
</div>
