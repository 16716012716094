import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSavedCartModalComponent } from './custom-saved-cart-modal.component';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomSafeHtmlModule } from 'src/app/pipes/custom-safe-html/custom-safe-html.module';
import { CustomModalGenericModule } from 'src/app/cms-components/custom/components/custom-modal/custom-modal-generic.module';

@NgModule({
  declarations: [CustomSavedCartModalComponent],
  exports: [CustomSavedCartModalComponent],
  imports: [
    CommonModule,
    IconModule,
    I18nModule,
    RouterModule,
    UrlModule,
    CustomSafeHtmlModule,
    CustomModalGenericModule
  ],
})
export class CustomSavedCartModalModule {}
