import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPriceFacetComponent } from './custom-price-facet.component';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { RangeSliderModule } from 'src/app/common/components/range-slider/range-slider.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    IconModule,
    KeyboardFocusModule,
    RangeSliderModule,
  ],
  declarations: [CustomPriceFacetComponent],
  exports: [CustomPriceFacetComponent],
})
export class CustomPriceFacetModule {}
