import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CustomGetImageUrlService } from 'src/app/services/custom-get-image-url.service';
import { ProductService, Product } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CustomGtmEcommerceDatalayerService } from 'src/app/common/services/custom-gtm-ecommerce-datalayer.service';

@Component({
  selector: 'app-custom-product-variant-list-item-details',
  templateUrl: './custom-product-variant-list-item-details.component.html',
  styleUrls: ['./custom-product-variant-list-item-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomProductVariantListItemDetailsComponent implements OnInit {
  @Input() isLoggedIn: boolean = false;
  @Input() productCode: string;
  product$: Observable<Product>;
  @Input() productPosition: number;
  clickedFrom: string = window.location.href;

  constructor(
    private customGtmDatalayerService: CustomGtmEcommerceDatalayerService,
    private customGetImageUrl: CustomGetImageUrlService,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    this.product$ = this.productService.get(this.productCode);
  }

  getImageUrl(url: string): string {
    return this.customGetImageUrl.getImageUrl(url);
  }

  productClickEvent(product: Product) {
    this.customGtmDatalayerService.productClickEvent(
      product,
      this.productPosition,
      this.clickedFrom
    );
  }
}
