import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentTypeFacade } from '@spartacus/checkout/root';
import {
  ActiveCartService, OccEndpointsService,
  PaymentType
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { CustomCreditInfo } from '../models/custom-credit-info.interface';
import { CustomCredit } from '../models/custom-credit.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomPaymentConditionsService {
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
    private paymentTypeService: PaymentTypeFacade,
    private activeCartService: ActiveCartService
  ) {}

  getPaymentConditions(userId: string): Observable<CustomCredit[]> {
    const url = this.occEndpointService.buildUrl('paymentConditions', {
      urlParams: {
        userId,
      },
    });

    return this.http.get<CustomCredit[]>(url);
  }

  setCreditPaymentMode(userId: string) {
    let cartId = 'current';
    return this.activeCartService.getActiveCartId().pipe(
      switchMap((activeCartId) => {
        if (activeCartId) {
          cartId = activeCartId;
        }
        const url = this.occEndpointService.buildUrl(
          'setCreditLinePaymentMode',
          {
            urlParams: {
              userId,
              cartId,
            },
          }
        );

        return this.http.put(url, {});
      })
    );
  }

  setTransferPaymentMode(userId: string) {
    let cartId = 'current';
    return this.activeCartService.getActiveCartId().pipe(
      switchMap((activeCartId) => {
        if (activeCartId) {
          cartId = activeCartId;
        }
        const url = this.occEndpointService.buildUrl('setTransferPaymentMode', {
          urlParams: {
            userId,
            cartId,
          },
        });

        return this.http.put(url, {});
      })
    );
  }

  setDepositPaymentMode(userId: string) {
    let cartId = 'current';
    return this.activeCartService.getActiveCartId().pipe(
      switchMap((activeCartId) => {
        if (activeCartId) {
          cartId = activeCartId;
        }
        const url = this.occEndpointService.buildUrl('setDepositPaymentMode', {
          urlParams: {
            userId,
            cartId,
          },
        });

        return this.http.put(url, {});
      })
    );
  }

  putPaymentCondition(userId: string, code: string) {
    const paymentConditionCode = code;
    let cartId: string = 'current';
    return this.activeCartService.getActiveCartId().pipe(
      switchMap((activeCartId) => {
        if (activeCartId) {
          cartId = activeCartId;
        }
        const url = this.occEndpointService.buildUrl('setPaymentConditions', {
          urlParams: {
            userId,
            cartId,
          },
          queryParams: {
            paymentConditionCode,
          },
        });
        return this.http.put(url, {});
      })
    );
  }

  getCreditInfo(userId: string): Observable<CustomCreditInfo> {
    let cartId = 'current';
    return this.activeCartService.getActiveCartId().pipe(
      switchMap((activeCartId) => {
        if (activeCartId) {
          cartId = activeCartId;
        }
        const url = this.occEndpointService.buildUrl('getCreditInfo', {
          urlParams: {
            userId,
            cartId,
          },
        });
        return this.http.get<CustomCreditInfo>(url);
      })
    );
  }
  getCreditInfoWithoutCart(userId: string): Observable<CustomCreditInfo> {
    let cartId = 'current';
    const url = this.occEndpointService.buildUrl('getCreditInfoWithoutCart', {
      urlParams: {
        userId,
      },
    });

    return this.http.get<CustomCreditInfo>(url);
  }

  setPromeclubPaymentMode(userId: string) {
    const url = this.occEndpointService.buildUrl('setPromeclubPaymentMode', {
      urlParams: {
        userId,
      },
    });

    return this.http.put(url, {});
  }

  requestExtensionCredit(userId: string) {
    const url = this.occEndpointService.buildUrl('requestExtensionCredit', {
      urlParams: {
        userId,
      },
    });

    return this.http.post(url, {});
  }

  getCountedPayments(): Observable<PaymentType[]> {
    return this.paymentTypeService.getPaymentTypes().pipe(
      filter((types) => !!types),
      map((types) => {
        const counted = types.filter(
          (type) => type.code === 'DEPOSIT' || type.code === 'TRANSFER'
        );
        return counted;
      })
    );
  }
}
