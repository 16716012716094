import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-error',
  templateUrl: './custom-error.component.html',
  styleUrls: ['./custom-error.component.scss']
})
export class CustomErrorComponent implements OnInit {
  errorTitle: string;
  errorMessage: string;
  @Input() notAvailableCredit: boolean;
  @Input() expiredCredit: boolean;
  @Input() isPromeclub: boolean = false;
  constructor() { }

  ngOnInit(): void {
    this.setContentTranslations()
  }

  setContentTranslations(){
  if(!this.isPromeclub){
    this.errorTitle =
    this.notAvailableCredit ? 'creditPayment.creditNotAvailableTitle'
    : this.expiredCredit ? 'creditPayment.expiredCreditTitle' : '';

    this.errorMessage =
    this.notAvailableCredit ? 'creditPayment.creditNotAvailableMessage'
    : this.expiredCredit ? 'creditPayment.expiredCreditMessage' : '';
  }
  if(this.isPromeclub && this.notAvailableCredit){
    this.errorTitle = 'creditPayment.pointsErrorTitle';
    this.errorMessage = 'creditPayment.pointsErrorDescription';
  }

}

}
