import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BaseSite,
  BASE_SITE_NORMALIZER,
  ConverterService,
  OccEndpointsService,
  OccSiteAdapter,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CustomBaseSites } from '../models/custom-base-sites.interface';
import { environment } from '../../environments/environment.ci';

@Injectable({
  providedIn: 'root',
})
export class CustomOccSiteAdapterService extends OccSiteAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected converterService: ConverterService,
    protected router: Router
  ) {
    super(http, occEndpointsService, converterService);
  }

  loadBaseSites(): Observable<BaseSite[]> {
    return this.http
      .get<{ baseSites: BaseSite[] }>(
        this.occEndpointsService.buildUrl('baseSites', {}, { baseSite: false })
      )
      .pipe(
        tap((baseSites: CustomBaseSites) => {
          if (baseSites?.isUnderMaintenance) {
            const url = window.location.origin + environment.mainteanceURL;
            window.open(url, '_self');
          }
        }),
        map((baseSiteList) => baseSiteList.baseSites),
        this.converterService.pipeableMany(BASE_SITE_NORMALIZER)
      );
  }
}
