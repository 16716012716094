<ng-container
  *ngIf="{
    loaded: loaded$ | async,
    addedEntryWasMerged: addedEntryWasMerged$ | async,
    entry: entry$ | async,
    cart: cart$ | async,
    addSuccess: addSuccess$ | async,
    loading: loading$ | async,
    isMobile: isMobile$ | async
  } as data"
>
  <div #dialog>
    <ng-container
      *ngIf="data.loaded || modalIsOpen || !data.loading; else loading"
    >
      .
      <app-custom-modal-generic
        *ngIf="data.entry && !data.isMobile"
        (closeModal)="customDismissModal($event)"
        [isClose]="true"
      ></app-custom-modal-generic>
      <ng-container *ngIf="data.entry && !failAdded; else noStock">
        <div
          *ngIf="data.entry && !data.isMobile"
          class="cx-dialog-title modal-title"
          aria-live="polite"
          aria-atomic="true"
          [innerHTML]="
            !data.addSuccess
              ? ('addToCart.completeEntryPromeclub' | cxTranslate | safeHtml)
              : ('addToCart.itemsAddedToYourCartPromeclub'
                | cxTranslate
                | safeHtml)
          "
        ></div>
        <div class="promeclub-modal-header" *ngIf="data.entry && data.isMobile">
          <h4 class="promeclub-modal-header__title">
            <cx-icon class="fas fa-check" *ngIf="data.addSuccess"></cx-icon>
            {{
              (!data.addSuccess
                ? "addToCart.completeEntryPromeclubMobile"
                : "addToCart.itemsAddedToYourCartPromeclubMobile"
              ) | cxTranslate
            }}
          </h4>
          <cx-icon
            class="fas fa-times"
            (click)="customDismissModal('close')"
          ></cx-icon>
        </div>
        <div
          class="cx-dialog-title modal-title error-title"
          *ngIf="errors.length && !data.entry"
        >
          <ng-container *ngFor="let error of errors">
            <span>{{ error }}</span>
          </ng-container>
        </div>
        <!-- Modal Body -->
        <div class="cx-dialog-body modal-body" *ngIf="data.entry">
          <div class="cx-dialog-row">
            <app-custom-added-to-cart-entry
              [entry]="data.entry"
              [quantity]="quantity"
              [isPdp]="true"
              [fromWish]="fromWish"
            >
            </app-custom-added-to-cart-entry>

            <!-- Total container -->
            <ng-template #actionButtons>
              <div class="cx-dialog-actions">
                <div class="cx-dialog-buttons">
                  <a
                    [class.disabled]="form.dirty"
                    cxModal="dismiss"
                    cxModalReason="$event"
                    class="btn btn-secondary"
                    (click)="customDismissModal($event)"
                  >
                    {{ "addToCart.continueBuyingPromeclub" | cxTranslate }}
                    <cx-icon class="fas fa-chevron-right"></cx-icon>
                  </a>

                  <a
                    [class.disabled]="form.dirty"
                    [routerLink]="{ cxRoute: 'cart' } | cxUrl"
                    cxModal="dismiss"
                    cxModalReason="$event"
                    class="btn btn-primary"
                    autofocus
                    (click)="customDismissModal($event)"
                  >
                    {{ "addToCart.viewCart" | cxTranslate }}
                    <cx-icon class="fas fa-chevron-right"></cx-icon>
                  </a>
                </div>
              </div>
            </ng-template>

            <ng-container
              *ngIf="!data.addSuccess && !data.loading; else actionButtons"
            >
              <app-custom-promeclub-add-to-cart-form
                [product]="product"
                [quantity]="quantity"
                (success)="isAdded = $event"
                (addFail)="failAdded = $event"
                *ngIf="!isAdded && !failAdded"
              >
              </app-custom-promeclub-add-to-cart-form>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-template #noStock>
        <div
          class="custom-modal-container"
          [ngClass]="{ 'no-entry': failAdded }"
        >
          <div class="custom-modal-container__custom-modal-header"></div>
          <div class="custom-modal-container__exclamation-alert">
            <cx-icon type="EXCLAMATION_TRIANGLE"></cx-icon>
          </div>
          <div class="custom-modal-container__out-of-stock-message">
            <span>
              <b>{{ "addToCart.customOutOfStock" | cxTranslate }}</b>
            </span>
            <span class="custom-modal-container__out-of-stock-message-text">
              {{ "addToCart.outOfStockMessage" | cxTranslate }}
            </span>
          </div>
          <div class="custom-modal-container__btn">
            <button
              class="btn btn-primary"
              (click)="customDismissModal($event)"
            >
              {{ "customRegister.continue" | cxTranslate }}
            </button>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <ng-template #loading>
      <div class="cx-dialog-title modal-title loading-title">
        {{ "addToCart.updatingCart" | cxTranslate }}
      </div>
      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body">
        <div class="cx-dialog-row">
          <div class="col-sm-12 mb-5">
            <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>
