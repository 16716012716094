<ng-container
  *ngIf="{
    isMobile: isMobile$ | async,
    cart: quote$ | async,
    isInReview: isInReview$ | async,
    isInDetail: isInQuoteDetail$ | async,
    inQuoteOffer: isInQuoteOffer$ | async
  } as data"
>
  <div class="quotes-layout">
    <div class="quotes-layout__top">
      <div
        class="quotes-layout__top-left"
        [ngClass]="{ review: data.isInReview || data.inQuoteOffer, inOffer: data.inQuoteOffer }"
      >
        <cx-icon
          class="fas fa-chevron-left"
          *ngIf="data.isInReview || data.inQuoteOffer"
          (click)="goBack(data.inQuoteOffer)"
        ></cx-icon>
        <ng-container *ngIf="!data.inQuoteOffer; else offerTitle">
          <div class="quotes-layout__top-left-title">
            <app-custom-title-my-account
              *ngIf="!data.isMobile && !data.isInDetail"
              [title]="
                !data.isInReview
                  ? ('accountQuotes.title' | cxTranslate)
                  : ('accountQuotes.titleReview' | cxTranslate)
              "
            ></app-custom-title-my-account>
            <app-custom-title-my-account
              *ngIf="data.isMobile && !data.isInDetail"
              [title]="
                !data.isInReview
                  ? ('accountQuotes.title' | cxTranslate)
                  : ('accountQuotes.titleReviewMobile' | cxTranslate)
              "
            ></app-custom-title-my-account>
            <app-custom-title-my-account
              *ngIf="data.isInDetail"
              [title]="
                'accountQuotes.titleDetail'
                  | cxTranslate : { code: data?.cart?.code }
              "
            >
            </app-custom-title-my-account>
          </div>
        </ng-container>
        <div
          class="quotes-layout__top-left-info"
          *ngIf="showForm && !data.isInDetail && !data.isInReview"
        >
          <app-custom-information
            [title]="'accountQuotes.alertInfoTitle' | cxTranslate"
            [description]="'accountQuotes.alertInfoSubtitle' | cxTranslate"
            [addClass]="'quotes-info'"
          ></app-custom-information>
        </div>
        <ng-template #offerTitle>
          <app-custom-title-my-account
            [title]="
              !data.isMobile
                ? ('accountQuotes.titleDetailOffer'
                  | cxTranslate : { code: data?.cart?.code })
                : ('accountQuotes.titleDetailOfferMobile'
                  | cxTranslate : { code: data?.cart?.code })
            "
          >
          </app-custom-title-my-account>
        </ng-template>
        <app-custom-quotes-form
          *ngIf="showForm && !data.isInDetail"
          [code]="data.cart?.code"
          [isMobile]="data.isMobile"
        >
        </app-custom-quotes-form>
        <app-custom-quote-overview-header
          *ngIf="
            (data.isInReview || data.isInDetail || data.inQuoteOffer) &&
            data?.cart !== null
          "
          [code]="data.cart?.code"
          [date]="data.cart?.updatedTime"
          [status]="data.cart?.state"
          [isInDetail]="data.isInDetail"
          [name]="data.cart?.name"
          [comment]="data.cart?.comment"
          [paymentCondition]="data.cart?.paymentCondition"
          [isInOffer]="data.inQuoteOffer"
          [isInReview]="data.isInReview"
          [quote]="data?.cart"
        >
        </app-custom-quote-overview-header>
      </div>
      <div class="quotes-layout__top-right" *ngIf="!data.isMobile">
        <app-custom-quotes-summary
          *ngIf="data?.cart?.entries"
          [isMobile]="data.isMobile"
          [cart]="data.cart"
        >
        </app-custom-quotes-summary>
      </div>
    </div>
    <app-custom-quotes-entry-list
      *ngIf="data?.cart?.entries"
      [isMobile]="data.isMobile"
      [cart]="data.cart"
      [isInDetail]="data.isInDetail"
      [isInOffer]="data.inQuoteOffer"
    >
    </app-custom-quotes-entry-list>
  </div>
  <app-custom-quotes-summary
    *ngIf="data.isMobile && data?.cart?.entries"
    [isMobile]="data.isMobile"
    [cart]="data.cart"
  >
  </app-custom-quotes-summary>
  <app-custom-quotes-summary-action *ngIf="data.isMobile" [quote]="data.cart">
  </app-custom-quotes-summary-action>
</ng-container>
