import { Component, Input, ViewEncapsulation } from '@angular/core';
import { OccConfig } from '@spartacus/core';

import { ManualProduct } from '../../../model/custom-manual-products';

@Component({
  selector: 'app-custom-product-card-downlowads',
  templateUrl: './custom-product-card-downlowads.component.html',
  styleUrls: ['./custom-product-card-downlowads.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomProductCardDownlowadsComponent {
  baseSiteUrl: string | undefined;
  @Input() manualProduct: ManualProduct;
  @Input() title: string;
  @Input() subtitle: string;
  constructor(private occConfig: OccConfig) {}

  ngOnInit(): void {
    this.baseSiteUrl = this.occConfig?.backend?.occ?.baseUrl;
  }
}
