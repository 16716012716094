import { Component, ElementRef, OnInit } from '@angular/core';
import {
  CmsBannerComponentMedia,
  CmsResponsiveBannerComponentMedia,
} from '@spartacus/core';
import { BannerComponent, CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-simple-responsive-banner',
  templateUrl: './custom-simple-responsive-banner.component.html',
  styleUrls: ['./custom-simple-responsive-banner.component.scss'],
})
export class CustomSimpleResponsiveBanner
  extends BannerComponent
  implements OnInit
{
  readonly PROMECLUB_LANDING_BANNER = 'promeclubLandingBanner';
  imageByBreakpoint$: Observable<CmsBannerComponentMedia>;

  constructor(
    component: CmsComponentData<CustomSimpleResponsiveBanner>,
    public breakpointService: CustomBreakpointService,
    private el: ElementRef
  ) {
    super(component);
  }

  ngOnInit() {
    this.imageByBreakpoint$ = this.data$.pipe(
      tap((data) => {
        if (data?.uid == this.PROMECLUB_LANDING_BANNER) {
          this.el?.nativeElement?.classList?.add('landing-promeclub');
        }
      }),
      switchMap((data) =>
        this.breakpointService.getImageByBreakpoint(
          <CmsResponsiveBannerComponentMedia>data
        )
      )
    );
  }
}
