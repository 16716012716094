import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomUpdatePasswordComponent } from './custom-update-password.component';
import {
  ConfigModule,
  CmsConfig,
  AuthGuard,
  GlobalMessageService,
  RoutingService,
  I18nModule,
} from '@spartacus/core';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconModule, SpinnerModule } from '@spartacus/storefront';
import { CustomRegisterCustomerModule } from 'src/app/cms-components/user/custom-register/custom-register-customer/custom-register-customer.module';
import { CustomUpdatePasswordService } from './custom-update-password.service';
import { CustomUpdatedPasswordModalComponent } from './custom-updated-password-modal/custom-updated-password-modal.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    CustomUpdatePasswordComponent,
    CustomUpdatedPasswordModalComponent,
  ],
  exports: [CustomUpdatePasswordComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    I18nModule,
    IconModule,
    SpinnerModule,
    CustomRegisterCustomerModule,
    RouterModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        UpdatePasswordComponent: {
          component: CustomUpdatePasswordComponent,
          guards: [AuthGuard],
          providers: [
            {
              provide: CustomUpdatePasswordService,
              useClass: CustomUpdatePasswordService,
              deps: [UserPasswordFacade, RoutingService, GlobalMessageService],
            },
          ],
        },
      },
    }),
  ],
})
export class CustomUpdatePasswordModule {}
