import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RoutingService } from '@spartacus/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { OrderFacade } from '@spartacus/order/root';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { catchError, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CustomLoadingSpinnerService } from 'src/app/cms-components/custom/services/custom-loading-spinner.service';
import { CustomOrderReturnService } from '../../services/custom-order-return.service';
import { CustomReturnOrderConfirmDialogComponent } from './custom-return-order-confirm-dialog/custom-return-order-confirm-dialog.component';
import {
  CurrentPage,
  CustomReturnOrder,
  CustomReturnOrderDetail
} from './model/custom-return-order';

@Component({
  selector: 'app-custom-return-order',
  templateUrl: './custom-return-order.component.html',
  styleUrls: ['./custom-return-order.component.scss'],
})
export class CustomReturnOrderComponent implements OnInit, OnDestroy {
  order$: Observable<any>;
  sendDataEnabled: boolean = false;
  currentPage: string;
  modalRef: ModalRef;
  unsubscribe$: Subject<any> = new Subject();
  orderCode: string;
  commentForm: string;
  itemsCorrect: boolean = false;
  orderReturn: CustomReturnOrder;
  constructor(
    protected orderDetailsService: OrderDetailsService,
    protected activatedroute: ActivatedRoute,
    private userOrderService: OrderFacade,
    protected modalService: ModalService,
    protected routingService: RoutingService,
    private customOrderReturnService: CustomOrderReturnService,
    protected customLoadingSpinnerService: CustomLoadingSpinnerService
  ) {}

  ngOnInit(): void {
    this.order$ = this.activatedroute.url.pipe(
      map((params) => params[2]?.path),
      switchMap((orderCode: string) => {
        if (orderCode) {
          this.orderCode = orderCode;
          this.userOrderService.loadOrderDetails(orderCode);
        } else {
          this.userOrderService.clearOrderDetails();
        }
        return this.orderDetailsService.getOrderDetails();
      }),
      tap((order) => {
        if (order.returnable == false) {
          this.routingService.go({
            cxRoute: 'orderDetails',
            params: { code: this.orderCode },
          });
        }
      })
    );
    this.currentPage = CurrentPage.EDIT_ORDER_RETURN;
  }

  goDevolutionConfirm() {
    if (this.sendDataEnabled) {
      this.currentPage = CurrentPage.REVISION_ORDER_RETURN;
      this.customOrderReturnService.onSendOrderReturn(true);
    }
  }

  onBackEditReturn() {
    if (this.currentPage == CurrentPage.EDIT_ORDER_RETURN) {
      this.routingService.go({
        cxRoute: 'orders',
      });
    } else {
      this.currentPage = CurrentPage.EDIT_ORDER_RETURN;
      this.customOrderReturnService.onSendOrderReturn(false);
    }
  }

  commentReturnOrder(comment: FormGroup) {
    this.commentForm = comment.controls['comment'].value;

    this.sendOrderDevolution(this.itemsCorrect);
  }

  sendOrderDevolution(isEnabled: boolean) {
    this.itemsCorrect = isEnabled;

    if (isEnabled == true && this.commentForm?.length > 0) {
      this.sendDataEnabled = true;
    } else {
      this.sendDataEnabled = false;
    }
  }

  getOrderReturn(formOrder: CustomReturnOrder) {
    let returnRequestEntryInputs: CustomReturnOrderDetail[] = [];
    for (let item of formOrder.returnRequestEntryInputs) {
      const itemOrderReturn: CustomReturnOrderDetail = {
        orderEntryNumber: item.orderEntryNumber,
        quantity: item.quantity,
        reason: `${item.reason}`,
        images: item.images,
      };
      returnRequestEntryInputs.push(itemOrderReturn);
    }
    this.orderReturn = {
      orderCode: formOrder.orderCode,
      comments:
        formOrder.comments != '' ? formOrder.comments : this.commentForm,
      returnRequestEntryInputs: returnRequestEntryInputs,
    };
  }

  setOrderReturn() {
    this.customLoadingSpinnerService.showSpinner('')
    this.customOrderReturnService
      .setOrderReturn(this.orderReturn)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((order) => this.openModal(order.code)),
        finalize(() => this.customLoadingSpinnerService.hideSpinner()),
        catchError((error) => error)
      )
      .subscribe();
  }

  openModal(code: string) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(
      CustomReturnOrderConfirmDialogComponent,
      {
        centered: true,
        size: 'lg',
      }
    );
    modalInstance = this.modalRef.componentInstance;
    modalInstance.orderCode = this.orderCode;
    this.modalRef.dismissed
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((result) => {
          this.routingService.go({
            cxRoute: 'returnOrderDetail',
            params: { code: code },
          });
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.userOrderService.clearOrderDetails();
    this.unsubscribe$.unsubscribe();
  }
}
