import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { GenericLinkModule, IconModule, OutletRefModule } from '@spartacus/storefront';
import { CustomFirstLetterModule } from 'src/app/pipes/custom-first-letter/custom-first-letter.module';
import { TextChangerModule } from 'src/app/pipes/text-changer/text-changer.module';
import { CustomCategoryNavigationUiComponent } from './custom-category-navigation-ui.component';



@NgModule({
  declarations: [
    CustomCategoryNavigationUiComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    GenericLinkModule,
    I18nModule,
    TextChangerModule,
    CustomFirstLetterModule,
    I18nModule,
    OutletRefModule,
    UrlModule
  ],
  exports: [CustomCategoryNavigationUiComponent]
})
export class CustomCategoryNavigationUiModule { }
