import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CustomSafeHtmlModule } from '../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomComponentTitleModule } from '../custom-component-title/custom-component-title.module';
import { CustomCategoryItemModule } from './custom-category-item/custom-category-item.module';
import { CustomCategoryListComponent } from './custom-category-list.component';

@NgModule({
  declarations: [CustomCategoryListComponent],
  imports: [
    CommonModule,
    CustomCategoryItemModule,
    CustomSafeHtmlModule,
    CustomComponentTitleModule,
    I18nModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromesaCategoryListComponent: {
          component: CustomCategoryListComponent,
        },
      },
    }),
  ],
})
export class CustomCategoryListModule {}
