import { Injectable } from '@angular/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CustomUserBloquedDialogComponent } from '../cms-components/custom/components/custom-user-bloqued-dialog/custom-user-bloqued-dialog.component';
import { CustomModalService } from '../cms-components/shared/components/custom-modal/services/custom-modal.service';

@Injectable({ providedIn: 'root' })
export class CustomLoginFormErrorsService {
  modalRef: ModalRef;

  constructor(
    protected modalService: ModalService,
    protected customModalService: CustomModalService,
  ) {}
  errorMessage = new BehaviorSubject<string | null>(null);
  errorMessage$ = this.errorMessage.asObservable();
  public closeDialogLogin$: Subject<any> = new Subject<any>();

  populateMessage(message: string): void{
    this.errorMessage.next(message);
  }

  showMessage(): Observable<string | null>{
    return this.errorMessage$;
  }

  removeMessage(): void{
    this.errorMessage.next(null);
  }

  openModal() {
    this.onCloseDialogLogin();
    const instance = 'Login_Form';
    this.customModalService.hide(instance);
    this.modalRef = this.modalService.open(CustomUserBloquedDialogComponent, {
      centered: true,
      size: 'lg',
    });
  }


  onCloseDialogLogin() {
    this.closeDialogLogin$.next();
  }

}
