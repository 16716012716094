import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';
import { CustomGetImageUrlService } from 'src/app/services/custom-get-image-url.service';
import { PageTypeService } from '../../../services/page-type.service';
import { CustomCategoryData } from '../model/custom-category-list.interface';

@Component({
  selector: 'app-custom-category-item',
  templateUrl: './custom-category-item.component.html',
  styleUrls: ['./custom-category-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomCategoryItemComponent {
  @Input() category: CustomCategoryData;
  isInPromeclub: boolean = this.customNavigationService.isInPromeClub();
  isCategory$: Observable<boolean> = this.pageTypeService.isCategory();

  constructor(
    private customNavigationService: CustomNavigationService,
    private customGetImageUrl: CustomGetImageUrlService,
    private router: Router,
    private pageTypeService: PageTypeService,
  ) {}

  getImageUrl(url: string): string {
    return this.customGetImageUrl.getImageUrl(url);
  }

  navigate(url: string) {
    this.router.navigateByUrl(url).then((resolve) => {
      if (!resolve) {
        window.location.reload();
      }
    });
  }
}
