import { ValueProvider } from '@angular/core';
import { DeferLoadingStrategy } from '@spartacus/core';
import {
  DIALOG_TYPE,
  LayoutConfig,
  provideCmsStructure,
} from '@spartacus/storefront';
import { CustomSavedCartFormDialogComponent } from 'src/app/cms-components/cart/custom-add-to-saved-cart/custom-saved-cart-form-dialog/custom-saved-cart-form-dialog.component';

export const CustomLayoutConfig: LayoutConfig = {
  deferredLoading: {
    strategy: DeferLoadingStrategy.DEFER,
    intersectionMargin: '50px',
  },
  layoutSlots: {
    header: {
      slots: [
        'SiteLogo',
        'SiteLogin',
        'CreditOrPointsSlot',
        'MiniCart',
        'PreHeader',
        'SearchBox',
      ],
      lg: {
        slots: [
          'PreHeader',
          'SiteContext',
          'SiteLinks',
          'SiteLogo',
          'SearchBox',
          'SiteLogin',
          'CreditOrPointsSlot',
          'MiniCart',
          'NavigationBar',
        ],
      },
    },
    navigation: {
      lg: { slots: [] },
      slots: ['CustomSlot', 'CustomSlot1', 'CustomSlot2', 'NavigationBar'],
    },
    LandingPage2Template: {
      pageFold: 'Section3',
      lg: {
        slots: [
          'Section1',
          'Section2A',
          'Section2B',
          'Section2C',
          'Section3',
          'Section4',
          'Section5',
        ],
      },
      slots: [
        'Section1',
        'Section3',
        'Section2A',
        'Section4',
        'Section5',
        'Section2B',
        'Section2C',
      ],
    },
    CartPageTemplate: {
      slots: [
        'EmptyCartMiddleContent',
        'TopContent',
        'CenterRightContentSlot',
        'BottomCarrouselContentSlot',
      ],
    },
    LoginHeaderLessPageTemplate: {
      slots: ['LeftContentSlot', 'RightContentSlot'],
    },
    MultiStepCheckoutSummaryPageTemplate: {
      header: {
        slots: ['SiteLogo', 'MiniCart'],
      },
      slots: ['SideContent', 'BodyContent'],
      lg: {
        slots: ['BodyContent', 'SideContent']
      }
    },
    ContentPage1Template: {
      slots: ['Section1', 'Section2A', 'Section2B'],
    },
  },
  launch: {
    NEW_CART: {
      component: CustomSavedCartFormDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG,
      inline: true,
    },
  },
};

export const customCmsContentProviders: ValueProvider[] = [
  provideCmsStructure({
    componentId: 'InnerHeaderComponent',
    pageSlotPosition: 'CustomSlot',
  }),
  provideCmsStructure({
    componentId: 'InnerHeaderContactComponent',
    pageSlotPosition: 'CustomSlot1',
  }),
  provideCmsStructure({
    componentId: 'InnerHeaderWishlistComponent',
    pageSlotPosition: 'CustomSlot2',
  }),
];
