import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProgressButtonComponent } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-progress-button',
  templateUrl: './custom-progress-button.component.html',
  styleUrls: ['./custom-progress-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomProgressButtonComponent extends ProgressButtonComponent implements OnInit {

  constructor() {
    super()
  }

  ngOnInit(): void {
  }

}
