<ng-container
  *ngIf="{
    showAddPerson: showAddPerson$ | async,
    isMobile: isMobile$ | async,
    authorizedPeople: authorizedPeople$ | async
  } as data"
>
  <div class="custom-container container-data">
    <div class="custom-container__row-title">
      <h2 class="custom-container__title">
        {{ "addressForm.manageDelivery" | cxTranslate }}
      </h2>
      <a
        (click)="addAuthorizedPerson()"
        *ngIf="
          !data.isMobile &&
          data?.authorizedPeople?.length < 3 &&
          !data.showAddPerson
        "
        >{{ "addressForm.addManageButton" | cxTranslate }}</a
      >
    </div>
    <div class="custom-container__checks-container">
      <ng-container *ngIf="data?.authorizedPeople; else loading">
        <div
          class="form-info-authorized-person"
          *ngFor="let person of data?.authorizedPeople; let i = index"
        >
          <label
            class="cx-delivery-label"
            for="centerDistribution-{{ person.idCard }}"
          >
            <div class="cx-delivery-mode">
              {{ person.name | uppercase }}
            </div>
            <div class="cx-delivery-details">
              <span *ngIf="person.idCard"
                >{{ "addressForm.identificationCard.label" | cxTranslate }}
                {{ person.idCard }}</span
              >
              <span *ngIf="person.licencePlate"
                >{{ "addressForm.licensePlate.resume" | cxTranslate }}
                {{ person.licencePlate }}</span
              >
            </div>
            <div class="custom-actions">
              <a
                (click)="
                  editPersonAuthorized(person, isMobile); personSelected = i
                "
              >
                <cx-icon type="EDIT"></cx-icon>
              </a>
              <a
                (click)="deletePersonAuthorized(person, data.authorizedPeople)"
              >
                <cx-icon type="TRASH"></cx-icon>
              </a>
            </div>
          </label>
          <ng-container
            *ngIf="
              data.isMobile &&
              data.showAddPerson &&
              editPerson &&
              person.idCard == data?.authorizedPeople[personSelected].idCard
            "
          >
            <app-custom-add-authorized-person
              [personEditar]="person"
            ></app-custom-add-authorized-person>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div
      class="pb-0 pb-md-4"
      *ngIf="
        data.isMobile &&
        !data.showAddPerson &&
        data?.authorizedPeople?.length < 3
      "
    >
      <a (click)="addAuthorizedPerson(data.showAddPerson)">{{
        "addressForm.addManageButton" | cxTranslate
      }}</a>
    </div>
    <div
      *ngIf="
        data.showAddPerson && (!editPerson || (!data.isMobile && editPerson))
      "
    >
      <app-custom-add-authorized-person></app-custom-add-authorized-person>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <div class="cx-spinner">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
