<ng-container
  *ngIf="{
    loggedIn: isUserLoggedIn$ | async,
    isMobile: isMobile$ | async,
    points: availablePoints$ | async
  } as data"
>
  <div *ngIf="data.isMobile && data.loggedIn" class="pos-icon-mobile">
    <cx-icon
      type="AWARD"
      class="icon-points"
      (click)="setShowPoints(true)"
    ></cx-icon>
    <app-custom-modal
      [instance]="'default'"
      *ngIf="showMyPoints | async"
      [modalTitle]="data.isMobile ? ('common.myPoints' | cxTranslate) : ''"
    >
      <div style="color: black" class="points-mobile">
        <p>{{ "creditPayment.availablePoints" | cxTranslate }}:</p>
        <p class="total-points-mobile">
          {{ "creditPayment.total" | cxTranslate
          }}{{ data.points | number : "" : "es-AR" }}
        </p>
      </div>
    </app-custom-modal>
  </div>

  <div *ngIf="!data.isMobile && data.loggedIn">
    <a (mouseenter)="setShowPoints(true)" (mouseleave)="setShowPoints(false)">
      <div class="flex-menu">
        <span class="points-text">{{
          "creditPayment.myPoints" | cxTranslate
        }}</span>
        <cx-icon type="AWARD" class="icon-points"></cx-icon>
      </div>
      <div *ngIf="showMyPoints | async" class="points-menu">
        <div class="points-menu__position">
          <span class="points-menu__position__title">{{
            "creditPayment.myPoints" | cxTranslate
          }}</span>
          <span class="points-menu__position__available">{{
            "creditPayment.availablePoints" | cxTranslate
          }}</span>
          <span class="points-menu__position__actual-points"
            >{{ "creditPayment.total" | cxTranslate }} {{ data.points }}</span
          >
        </div>
      </div>
    </a>
  </div>

  <div *ngIf="!data.isMobile && !data.loggedIn" (mouseenter)="setShowPoints(true)" (mouseleave)="setShowPoints(false)">
    <a href="/my-account/update-profile">
      <div class="flex-menu">
        <span class="points-text">{{
          "creditPayment.myPoints" | cxTranslate
        }}</span>
        <cx-icon type="AWARD" class="icon-points"></cx-icon>
      </div>
    </a>
  </div>
</ng-container>
