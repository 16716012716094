import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CategoriesBreadcrumb } from '../cms-components/navigation/model/categoriesBreadcrumb';


@Injectable({
  providedIn: 'root',
})
export class CustomProductsAttributesService  {


  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
    private router: Router
  ) {}

  getAttributesProducts(productCode: string): Observable<any>{
    const url = this.occEndpointService.buildUrl('productAttributes', {
      urlParams: {
        productCode
      }
    });
    return this.http.get<any>(url);
  }
}
