import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  ActiveCartService,
  CmsService,
  OrderEntry,
  RoutingService,
  SelectiveCartService,
  WindowRef,
} from '@spartacus/core';
import { MiniCartComponent } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { CustomCheckoutCartService } from 'src/app/feature-libs/checkout/services/custom-checkout-cart.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomNavigationService } from '../../navigation/services/custom-navigation.service';
import { CustomCart } from '../model/custom-cart';
import { TEMPLATE } from 'src/app/common/models/page-template';

@Component({
  selector: 'app-custom-mini-cart',
  templateUrl: './custom-mini-cart.component.html',
  styleUrls: ['./custom-mini-cart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomMiniCartComponent
  extends MiniCartComponent
  implements OnInit, OnDestroy
{
  unsubscribe$: Subject<any> = new Subject<any>();
  quantity$: Observable<number>;
  _total$: Observable<number | undefined>;
  _totalPriceWithTax$: Observable<number | undefined>;
  headerHeight$: Observable<string>;
  isMobile$: Observable<boolean>;
  locale$: Observable<string>;
  entries$: Observable<OrderEntry[]>;
  scrollActive: boolean = false;
  cantMaxItems: number = 5;
  entriesQuantity: number = 0;
  isCartPage$: Observable<boolean>;
  isInPromeclub: boolean = this.service.isInPromeClub();
  private showCartSummary = new Subject<boolean>();
  public showCartSummary$: Observable<boolean> =
    this.showCartSummary.asObservable();
  currentPage$: Observable<string> = this.cmsService
    .getCurrentPage()
    .pipe(map((page) => page?.template || ''));
  TEMPLATE = TEMPLATE;
  quantityUpdated$: Observable<number> =
    this.customCheckoutCartService.getQuantityUpdated$();
  subscription: Subscription = new Subscription();

  constructor(
    activeCartService: ActiveCartService,
    private customBreakpointService: CustomBreakpointService,
    private winRef: WindowRef,
    protected selectiveCartService: SelectiveCartService,
    private cmsService: CmsService,
    private service: CustomNavigationService,
    private customCheckoutCartService: CustomCheckoutCartService,
    private userAccount: UserAccountFacade,
    private routingService: RoutingService
  ) {
    super(activeCartService);
  }

  ngOnInit() {
    this.isMobile$ = this.customBreakpointService.isMobile$;
    this._total$ = this.activeCartService.getActive().pipe(
      filter((cart) => !!cart.totalPrice),
      map((cart) => cart.totalPrice?.value)
    );
    this._totalPriceWithTax$ = this.activeCartService.getActive().pipe(
      filter((cart: CustomCart) => !!cart.subTotal),
      map((cart) => cart.totalPriceWithTax?.value)
    );
    this.showCartSummary$ = this.showCartSummary$.pipe(
      debounceTime(120),
      distinctUntilChanged()
    );

    this.isCartPage$ = this.cmsService.getCurrentPage().pipe(
      pluck('template'),
      map(
        (template) =>
          template === 'CartPageTemplate' ||
          template === 'MultiStepCheckoutSummaryPageTemplate'
      ),
      tap((isCartPage) => {
        if (isCartPage) {
          this.setShowCartSummary(false);
        }
      })
    );

    this.quantity$ = this.customCheckoutCartService.quantity$.pipe(
      switchMap((value) => {
        return this.cmsService.getCurrentPage().pipe(
          switchMap((page) => {
            if (
              page?.template === 'AccountPageTemplate' &&
              !this.isInPromeclub
            ) {
              this.subscription.add(
                this.activeCartService.requireLoadedCart().subscribe()
              );
              this.activeCartService.reloadActiveCart();
            }
            if (
              page?.template === 'MultiStepCheckoutSummaryPageTemplate' &&
              !this.isInPromeclub
            ) {
              return this.getEntriesCart().pipe(
                startWith({ deliveryItemsQuantity: 0 }),
                map((cart) => cart?.deliveryItemsQuantity || 0)
              );
            } else {
              return this.activeCartService.getActive().pipe(
                startWith({ deliveryItemsQuantity: 0 }),
                map((cart) => cart?.deliveryItemsQuantity || 0)
              );
            }
          })
        );
      })
    );
  }

  getEntriesCart(): Observable<any> {
    return combineLatest([
      this.userAccount.get(),
      this.activeCartService.getActiveCartId(),
    ]).pipe(
      switchMap(([user, cart]) => {
        return this.customCheckoutCartService.getCartUpdated(user?.uid!, cart);
      })
    );
  }

  setShowCartSummary(show: boolean) {
    const addedToCartIsOpen = this.winRef.document.body.classList.contains(
      'cart-pop-up-wrapper'
    );
    if (!addedToCartIsOpen) {
      this.showCartSummary.next(show);
      this.entries$ = this.activeCartService.getEntries().pipe(
        tap(() => (this.entriesQuantity = 0)),
        tap((entries) => (this.entriesQuantity = entries.length)),
        map((entries) => entries.slice(0, this.cantMaxItems))
      );
    }
  }

  goToCart() {
    setTimeout(() => {
      this.routingService.go({
        cxRoute: 'cart',
      });
    }, 1000);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscription.unsubscribe();
  }

  onShowMore(event: any) {
    this.scrollActive = event;
  }
}
