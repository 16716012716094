import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CustomProductCardModule } from '../custom-product-card/custom-product-card.module';
import { CustomRebateCarouselCartComponent } from './custom-rebate-carousel-cart.component';
import { CustomComponentTitleModule } from '../custom-component-title/custom-component-title.module';

@NgModule({
  declarations: [CustomRebateCarouselCartComponent],
  imports: [
    CommonModule,
    CustomProductCardModule,
    I18nModule,
    CustomComponentTitleModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        RebateProductCarouselComponent: {
          component: CustomRebateCarouselCartComponent,
        },
      },
    }),
  ],
})
export class CustomRebateCarouselCartModule {}
