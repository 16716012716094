import { Injectable } from '@angular/core';
import { ActiveCartService } from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { CustomProduct } from 'src/app/cms-components/product/model/custom-product.interface';
import { CustomAddedToCartDialogComponent } from '../custom-added-to-cart-dialog/custom-added-to-cart-dialog.component';
import { CustomQuoteModalService } from 'src/app/cms-components/my-account/components/custom-quotes/services/custom-quote-modal.service';
import { CustomPromeclubAddedToCartDialogComponent } from '../custom-promeclub-added-to-cart-dialog/custom-promeclub-added-to-cart-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class CustomPreAddToCartService {
  quantitySelected$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  modalRef: ModalRef;
  product$: BehaviorSubject<CustomProduct | null> =
    new BehaviorSubject<CustomProduct | null>(null);
  numberOfEntriesBeforeAdd: number;
  constructor(
    private activeCartService: ActiveCartService,
    private modalService: ModalService,
    private customQuoteModalService: CustomQuoteModalService
  ) {}

  setQuantity(value: number): void {
    this.quantitySelected$.next(value);
  }

  setProduct(value: CustomProduct): void {
    this.product$.next(value);
  }

  getQuantitySelected(): Observable<number> {
    return this.quantitySelected$.asObservable();
  }

  validatePromeclubProductType(product: CustomProduct): boolean {
    switch (product?.tipoRecargaPromotick) {
      case 1:
        return false;
      case 3:
        return true;
      case 4:
        return true;
      case 7:
        return true;
      default:
        return false;
    }
  }

  addToCartFromModal(
    isFromQuote: boolean = false,
    isPromeclub: boolean = false
  ) {
    if (isFromQuote && !isPromeclub) {
      this.customQuoteModalService.openQuoteInCourseModal();
      return;
    }
    if (
      !isPromeclub ||
      !this.validatePromeclubProductType(this.product$.value as CustomProduct)
    ) {
      this.addToCart();
    }
    if (
      isPromeclub &&
      this.validatePromeclubProductType(this.product$.value as CustomProduct)
    ) {
      this.openModalPromeclub(this.numberOfEntriesBeforeAdd, isPromeclub);
    }
    //this.addToCart();
  }

  protected openModal(productCode: string, numberOfEntriesBeforeAdd: number) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomAddedToCartDialogComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'add-to-cart',
    });

    modalInstance = this.modalRef.componentInstance;
    modalInstance.entry$ = this.activeCartService.getLastEntry(productCode);
    modalInstance.cart$ = this.activeCartService.getActive();
    modalInstance.loaded$ = this.activeCartService.isStable();
    modalInstance.quantity = this.quantitySelected$.value;
    modalInstance.numberOfEntriesBeforeAdd = numberOfEntriesBeforeAdd;
    modalInstance.isInPromeclub = false;
    // modalInstance.clickedFrom = this.fromProductCard
    //   ? this.clickedFrom
    //   : 'productDetails';
  }

  addToCart(): void {
    const quantity = this.quantitySelected$.value;
    if (!this.product$.value?.code || quantity <= 0) {
      return;
    }
    this.activeCartService
      .getEntries()
      .pipe(take(1))
      .subscribe((entries) => {
        this.numberOfEntriesBeforeAdd = entries.length;
        this.openModal(
          this.product$.value?.code!,
          this.numberOfEntriesBeforeAdd
        );
        this.activeCartService.addEntry(this.product$.value?.code!, quantity);
      });
  }

  protected openModalPromeclub(
    numberOfEntriesBeforeAdd: number,
    isPromeclub: boolean
  ) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(
      CustomPromeclubAddedToCartDialogComponent,
      {
        centered: true,
        size: 'lg',
        windowClass: 'promeclub-add-to-cart',
      }
    );

    modalInstance = this.modalRef.componentInstance;
    modalInstance.entry$ = of({
      product: this.product$.value,
      basePrice: { value: this.product$.value?.price?.value },
    });
    modalInstance.cart$ = this.activeCartService.getActive();
    modalInstance.loaded$ = this.activeCartService.isStable();
    modalInstance.quantity = this.quantitySelected$.value;
    modalInstance.numberOfEntriesBeforeAdd = numberOfEntriesBeforeAdd;
    modalInstance.isInPromeclub = isPromeclub;
    modalInstance.product = this.product$.value;
  }
}
