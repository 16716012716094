import { Component, Input } from '@angular/core';
import { CustomProduct } from '../../../model/custom-product.interface';

@Component({
  selector: 'app-custom-product-terms',
  templateUrl: './custom-product-terms.component.html',
  styleUrls: ['./custom-product-terms.component.scss'],
})
export class CustomProductTermsComponent {
  @Input() product: CustomProduct;
  constructor() {}
}
