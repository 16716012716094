import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSavedCartDetailsItemsComponent } from './custom-saved-cart-details-items.component';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  I18nModule,
} from '@spartacus/core';
import { CustomOrderDetailItemsModule } from '../../custom-order-details/custom-order-detail-items/custom-order-detail-items.module';
import { CustomCartSharedModule } from 'src/app/cms-components/cart/custom-cart-shared/custom-cart-shared.module';
import { CustomPaginationModule } from 'src/app/cms-components/custom/components/custom-pagination/custom-pagination.module';
import { CustomLoadingSpinnerModule } from 'src/app/cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';

@NgModule({
  declarations: [CustomSavedCartDetailsItemsComponent],
  imports: [
    CommonModule,
    I18nModule,
    CustomOrderDetailItemsModule,
    CustomCartSharedModule,
    CustomPaginationModule,
    CustomLoadingSpinnerModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        SavedCartDetailsItemsComponent: {
          component: CustomSavedCartDetailsItemsComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ],
})
export class CustomSavedCartDetailsItemsModule {}
