<ng-container *ngIf="item">
  <ng-container *ngIf="!(isMobile$ | async); else cardProdMobile">
    <div class="item-order-details">
      <div class="item-order-details__image-info">
        <div class="item-order-details__image">
          <cx-media
            [hidden]="item?.product?.productType == EcvProductType"
            [container]="item.product?.images?.PRIMARY"
            format="product"
            role="presentation"
          ></cx-media>
        </div>
        <div class="item-order-details__info">
          <p class="item-order-details__info-brand">
            {{ item.product?.brand?.name }}
          </p>
          <p class="item-order-details__info-name">
            {{ item.product?.name }}
          </p>
          <div *ngIf="item.product?.code" class="item-order-details__info-code">
            {{ "cartItems.id" | cxTranslate }} {{ item.product?.code }}
          </div>
        </div>
      </div>

      <div class="item-order-details__quantity">
        <label class="item-order-details__quantity-label">{{
          "orderDetails.quantity" | cxTranslate
        }}</label>
        <label class="item-order-details__quantity-value">{{
          item.quantity
        }}</label>
      </div>

      <div class="item-order-details__price">
        <label class="item-order-details__price-label">{{
          "orderDetails.itemPrice" | cxTranslate
        }}</label>
        <label class="item-order-details__price-value">{{
          item.basePrice?.value | customCurrency
        }}</label>
      </div>

      <div class="item-order-details__total-price">
        <label class="item-order-details__total-price-label">{{
          "orderDetails.totalItemPrice" | cxTranslate
        }}</label>
        <label class="item-order-details__total-price-value">{{
          item.totalPrice?.value | customCurrency
        }}</label>
      </div>
    </div>
  </ng-container>
  <ng-template #cardProdMobile>
    <div class="item-order-details">
      <div class="item-order-details__image">
        <cx-media
          [hidden]="item?.product?.productType == EcvProductType"
          [container]="item.product?.images?.PRIMARY"
          format="product"
          role="presentation"
        ></cx-media>
      </div>

      <div class="item-order-details__info_ppal">
        <div class="item-order-details__info">
          <p class="item-order-details__info-brand">
            {{ item.product?.brand?.name }}
          </p>
          <p class="item-order-details__info-name">
            {{ item.product?.name }}
          </p>
          <div *ngIf="item.product?.code" class="item-order-details__info-code">
            {{ "cartItems.id" | cxTranslate }} {{ item.product?.code }}
          </div>
        </div>

        <div class="item-order-details__info_quantity">
          <div class="item-order-details__quantity">
            <label class="item-order-details__quantity-label">{{
              "orderDetails.quantity" | cxTranslate
            }}</label>
            <label class="item-order-details__quantity-value">{{
              item.quantity
            }}</label>
          </div>

          <div class="item-order-details__price">
            <label class="item-order-details__price-label">{{
              "orderDetails.itemPrice" | cxTranslate
            }}</label>
            <label class="item-order-details__price-value">{{
              item.basePrice?.value | customCurrency
            }}</label>
          </div>

          <div class="item-order-details__total-price">
            <label class="item-order-details__total-price-label">{{
              "orderDetails.totalItemPrice" | cxTranslate
            }}</label>
            <label class="item-order-details__total-price-value">{{
              item.totalPrice?.value | customCurrency
            }}</label>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
