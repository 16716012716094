import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, of } from 'rxjs';
import { catchError, map, pluck, switchMap, tap } from 'rxjs/operators';
import { CustomCreditInfo } from 'src/app/feature-libs/checkout/models/custom-credit-info.interface';
import { CustomPaymentConditionsService } from 'src/app/feature-libs/checkout/services/custom-payment-conditions.service';
import { CustomCreditLineDialogComponent } from './custom-credit-line-dialog/custom-credit-line-dialog.component';

@Component({
  selector: 'app-custom-credit-line',
  templateUrl: './custom-credit-line.component.html',
  styleUrls: ['./custom-credit-line.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomCreditLineComponent implements OnInit {
  modalRef: ModalRef;
  creditInfo$: Observable<CustomCreditInfo>;
  constructor(
    protected modalService: ModalService,
    protected userAccountFacade: UserAccountFacade,
    private customPaymentConditionsService: CustomPaymentConditionsService
  ) { }

  ngOnInit(): void {
    this.creditInfo$ = this.userAccountFacade.get().pipe(
      map(user => user?.uid!),
      switchMap((user) => {
        return this.customPaymentConditionsService.getCreditInfoWithoutCart(user).pipe(
          catchError(error => of(error)),
          pluck('availableAmount'),
        )
      }
      ));
  }

  requestExtension(event: Event) {
    this.userAccountFacade.get().pipe(
      switchMap((user) => {
        return this.customPaymentConditionsService.requestExtensionCredit(user?.uid!).pipe(
          tap(() => this.openModal(event))
        )
      }
      )).subscribe();

  }

  openModal(event: Event) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomCreditLineDialogComponent, {
      centered: true,
      size: 'lg',
    });
  }
}
