<cx-generic-link
  *ngIf="data$ | async as data"
  [url]="data.urlLink"
  [target]="getTarget(data)"
>
  <p class="headline" *ngIf="data.headline" [innerHTML]="data.headline"></p>
  <cx-media
    [container]="imageByBreakpoint$ | async"
    [ngClass]="{ 'promeclub-landing': data?.uid == PROMECLUB_LANDING_BANNER }"
  ></cx-media>
  <p class="content" *ngIf="data.content" [innerHTML]="data.content"></p>
</cx-generic-link>
