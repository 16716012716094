import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService, Product, ProductService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { CustomProduct } from 'src/app/cms-components/product/model/custom-product.interface';
import { CustomGtmEcommerceDatalayerService } from 'src/app/common/services/custom-gtm-ecommerce-datalayer.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomNavigationService } from '../../../services/custom-navigation.service';

@Component({
  selector: 'app-custom-searchbox-product-item',
  templateUrl: './custom-searchbox-product-item.component.html',
  styleUrls: ['./custom-searchbox-product-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomSearchboxProductItemComponent implements OnInit {
  @Input() product: CustomProduct;
  @Input() productPosition: number;
  isUserLoggedIn$: Observable<boolean> = this.auth.isUserLoggedIn();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  isInPromeClub: boolean = this.service.isInPromeClub();
  subscription: Subscription = new Subscription();
  productClick: Product;

  clickedFrom: string = 'Buscador';

  constructor(
    private auth: AuthService,
    private customBreakpointService: CustomBreakpointService,
    private service: CustomNavigationService,
    private customGtmDatalayerService: CustomGtmEcommerceDatalayerService,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.productService.get(this.product?.code!).subscribe((p) => {
        this.productClick = p;
      })
    );
  }

  productClickEvent() {
    this.customGtmDatalayerService.productClickEvent(
      this.productClick,
      this.productPosition,
      this.clickedFrom
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
