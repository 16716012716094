import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule, ConfigModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { CustomProductCarouselComponent } from './custom-product-carousel.component';
import { RouterModule } from '@angular/router';
import { CustomProductCardModule } from '../../../custom/components/custom-product-card/custom-product-card.module';
import { CustomSafeHtmlModule } from '../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomComponentTitleModule } from 'src/app/cms-components/custom/components/custom-component-title/custom-component-title.module';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  declarations: [CustomProductCarouselComponent],
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    MediaModule,
    CustomProductCardModule,
    CustomSafeHtmlModule,
    SwiperModule,
    CustomComponentTitleModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductCarouselComponent: {
          component: CustomProductCarouselComponent,
        },
      },
    }),
  ],
})
export class CustomProductCarouselModule {}
