import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomLockScrollService } from 'src/app/services/custom-lock-scroll.service';
import { CustomLoadingSpinnerService } from '../../services/custom-loading-spinner.service';

@Component({
  selector: 'app-custom-loading-spinner',
  templateUrl: './custom-loading-spinner.component.html',
  styleUrls: ['./custom-loading-spinner.component.scss']
})
export class CustomLoadingSpinnerComponent implements OnInit {
  showSpinner$: Observable<boolean>;
  constructor(
    protected spinnerService: CustomLoadingSpinnerService,
    protected lockScrollService: CustomLockScrollService
  ) { }

  ngOnInit(): void {
    this.showSpinner$ = this.spinnerService.getSpinnerState().pipe(
      tap((visible) => {
        this.lockScrollService.handleLockBodyScroll(visible);
      })
    );
  }

}
