export const cart = {
  cartDetails: {
    id: 'SKU',
    code: 'Cod.',
    proceedToCheckout: 'Checkout',
    cartName: 'Carrito #{{code}}',
    product: 'Producto',
    presentation: 'Presentación',
    unitPrice: 'Precio unit',
    unitPoints: 'Puntos unit',
    quantity: 'Cantidad',
    totalPrice: 'Precio total',
    totalPoints: 'Puntos totales',
    noProducts: 'No hay productos en tu carrito',
    continueBuying: 'Seguir comprando',
    continueBuyingMobile: 'Continuar comprando',
    continueBuyingPromeclub: 'Seguir canjeando',
    title: 'Mi <b>Carrito</b>',
  },
  cartItems: {
    id: 'SKU',
    description: 'Descripción',
    item: 'Articulo',
    itemPrice: 'Precio',
    quantity: 'Cantidad',
    points: 'Puntos',
    quantityTitle:
      'La cantidad representa el número total de este artículo en su carrito.',
    total: 'Total',
    cartTotal: 'Total Carrito ({{count}} artículo)',
    cartTotal_plural: 'Total Carrito ({{count}} artículos)',
    unity: 'x ',
    update: 'Actualizar',
    products: '{{quantity}} productos',
    product: '{{quantity}} producto',
    myCart: 'Mi Carrito ({{quantity}})',
    myEmptyCart: 'Mi Carrito',
  },
  orderCost: {
    orderSummary: '<b>Resumen</b> de pedido',
    orderDetailSummary: 'Resumen de pedido',
    subtotal: 'Subtotal',
    shipping: 'Envío',
    estimatedShipping: 'Envío estimado',
    discount: 'Descuentos',
    salesTax: 'Impuestos',
    grossTax: 'IVA',
    grossIncludeTax: 'El total del pedido incluye impuestos de',
    total: 'Total:',
    toBeDetermined: 'TBD',
    cancel: 'Cancelar pedido',
  },
  voucher: {
    coupon: 'Agregar cupón de descuento',
    coupon_plural: 'Códigos de cupón',
    apply: 'Aplicar',
    placeholder: 'Ingresar cupón',
    applyVoucherSuccess: '{{voucherCode}} ha sido aplicado.',
    removeVoucherSuccess: '{{voucherCode}} has been removed.',
    anchorLabel: 'Ingrese o elimine su código de cupón',
    vouchersApplied: 'Aplicado',
    availableCoupons: 'Cupones disponibles',
    availableCouponsLabel: 'Puede agregar estos cupones a este pedido.',
    seeMore: 'Ver más',
    seeLess: 'Ver menos',
    addCouponMobile: 'Agregar cupón',
  },
  saveForLaterItems: {
    itemTotal: 'Guardado para después ({{count}} artículo)',
    itemTotal_plural: 'Guardado para después ({{count}} artículos)',
    cartTitle: 'Carrito',
    saveForLater: 'Guardar para después',
    moveToCart: 'Mover a la carrito',
    stock: 'Stock',
    forceInStock: 'En Stock',
  },
  validation: {
    cartEntriesChangeDuringCheckout:
      'Durante el pago encontramos problemas con sus entradas. Por favor revise su carrito.',
    cartEntryRemoved: '{{name}} se eliminó del carrito debido a que se agotó.',
    productOutOfStock:
      '{{name}} se ha eliminado del carrito debido a que no hay suficiente stock.',
    lowStock:
      'La cantidad se ha reducido a {{quantity}} debido a existencias insuficientes.',
    reviewConfiguration:
      'Resuelva primero los problemas en la configuración para la entrada al carrito.',
    pricingError:
      'El precio del configurador no está disponible actualmente. No es posible realizar el pago/envío de presupuesto. Por favor, inténtelo de nuevo más tarde.',
    unresolvableIssues:
      'La configuración del producto requiere entradas adicionales en el back-end. Como resultado, no puede continuar. Póngase en contacto con el soporte.',
  },
};
