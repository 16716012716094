<ng-container *ngIf="{stepTwo: stepTwo$ | async} as data">
  <div class="register-docs" [ngClass]="{'hide': !data.stepTwo}">
    <h3 class="register-docs__title">
      {{'nonCustomerRegister.docUploadTitle' | cxTranslate}}
    </h3>
    <p class="register-docs__paragraph">
      {{'nonCustomerRegister.registerDocParagraph' | cxTranslate}}
    </p>
    <ng-container *ngIf="{isJuridicPerson: isJuridicPerson$ | async} as data">
      <div class="register-docs__form">

        <app-custom-doc-item [FormControl]="rucDoc" [label]="rucDocLabel"></app-custom-doc-item>
        <app-custom-doc-item [FormControl]="idDoc" [label]="idDocLabel"></app-custom-doc-item>
        <app-custom-doc-item
          [ngClass]="{'not-allowed': !data.isJuridicPerson}"
          [FormControl]="registeredAppoinmentDoc"
          [label]="registeredAppoinmentDocLabel"
          [disableButton]="!data.isJuridicPerson"
        ></app-custom-doc-item>

      </div>
    </ng-container>
    <h4 class="register-docs__subtitle">
      {{'nonCustomerRegister.docLetterSubtitle' | cxTranslate}}
    </h4>
    <p class="register-docs__subtitle-paragraph">
      {{'nonCustomerRegister.docLetterSubtitleParagraph' | cxTranslate}}
    </p>
    <div class="register-docs__form">

      <app-custom-doc-item [label]="downloadLabel" [download]="true" [appointmentDocument]="appointmentDocument$ | async"></app-custom-doc-item>
      <app-custom-doc-item [FormControl]="letterDoc" [label]="letterDocLabel"></app-custom-doc-item>

    </div>
    <div class="register-docs__action">
      <p class="register-docs__action-link"(click)="return()">
        {{'nonCustomerRegister.return' | cxTranslate}}
      </p>
      <button class="register-docs__action-button" (click)="submitDocs()" [disabled]="(validDocs$ | async) !== documentsNeeded">
        {{'customRegister.continue' | cxTranslate}}
      </button>
    </div>
  </div>
</ng-container>

