import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule, provideConfig } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';
import { customDefaultAsmLayoutConfig } from './custom-default-asm-layout.config';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, I18nModule, FormErrorsModule],
  providers: [provideConfig(customDefaultAsmLayoutConfig)],
})
export class AsmComponentsModule {}
