import { NgModule } from '@angular/core';
import { ProductSearchAdapter } from '@spartacus/core';
import { CustomOccProductSearchAdapter } from './custom-occ-product-search.adapter';

@NgModule({
  providers: [
    {
      provide: ProductSearchAdapter,
      useClass: CustomOccProductSearchAdapter,
    },
  ],
})
export class CustomOccProductSearchModule {}
