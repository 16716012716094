import { registerLocaleData } from '@angular/common';
import { NgModule } from '@angular/core';

import LocaleEsAR from '@angular/common/locales/es-AR';
import { CustomPhoneMaskPipe } from './custom-phone-mask.pipe';

registerLocaleData(LocaleEsAR);


@NgModule({
  declarations: [	CustomPhoneMaskPipe,
   ],
  exports: [CustomPhoneMaskPipe],
})
export class CustomPhoneMaskModule {}
