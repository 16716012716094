import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CartModification, RoutingService } from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { CustomLoadingSpinnerService } from 'src/app/cms-components/custom/services/custom-loading-spinner.service';
import { CustomReorderService } from 'src/app/cms-components/my-account/services/custom-reorder.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomReorderDialogComponent } from '../custom-reorder-dialog/custom-reorder-dialog.component';

@Component({
  selector: 'app-custom-reorder-info-dialog',
  templateUrl: './custom-reorder-info-dialog.component.html',
  styleUrls: ['./custom-reorder-info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomReorderInfoDialogComponent implements OnDestroy {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  cartModifications: CartModification[] | undefined;
  modalRef: ModalRef;
  private unsubscribe$ = new Subject<void>();
  orderCode: string;
  constructor(
    private modalService: ModalService,
    private customBreakpointService: CustomBreakpointService,
    protected customLoadingSpinnerService: CustomLoadingSpinnerService,
    private customReorderService: CustomReorderService,
    private routingService: RoutingService
  ) {}

  dismissModal(reason?: any): void {
    this.modalService.dismissActiveModal(reason);
  }

  createCartFromOrder() {
    this.customLoadingSpinnerService.showSpinner('');
    this.customReorderService
      .reorder(this.orderCode)
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((error) => {
          this.routingService.goByUrl('/not-found');
          return error;
        }),
        finalize(() => {
          this.customLoadingSpinnerService.hideSpinner();
          let modalInstance: any;
          this.modalRef = this.modalService.open(CustomReorderDialogComponent, {
            centered: true,
            size: 'lg',
            windowClass: 'reorderDialog',
          });
          modalInstance = this.modalRef.componentInstance;
          modalInstance.orderCode = this.orderCode;
        })
      )
      .subscribe(() => {
        this.dismissModal();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
