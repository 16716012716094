import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { CustomRegisterFormData } from '../models/custom-register-form-data.interface';
import { catchError, tap } from 'rxjs/operators';
import {
  PromesaCustomerFormData,
  PromesaRegisterFormData,
} from '../models/custom-cms-register.interface';
import { ModalService, ModalRef } from '@spartacus/storefront';
import { CustomRegisterCustomerErrorInfoComponent } from '../custom-register-shared/custom-register-customer-error-info/custom-register-customer-error-info.component';
import { CustomCustomerRegisterSuccessfulComponent } from '../custom-register-shared/custom-customer-register-successful/custom-customer-register-successful.component';

@Injectable({
  providedIn: 'root',
})
export class CustomRegisterFormService {
  customerFormData$: BehaviorSubject<CustomRegisterFormData | null> =
    new BehaviorSubject<CustomRegisterFormData | null>(null);
  modalRef: ModalRef;
  promesaCustomerData$: BehaviorSubject<PromesaCustomerFormData | null> =
    new BehaviorSubject<PromesaCustomerFormData | null>(null);
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
    private modalService: ModalService
  ) {}

  setCustomerFormData(formData: CustomRegisterFormData) {
    this.customerFormData$.next(formData);
  }

  getCustomerFormData(): Observable<CustomRegisterFormData | null> {
    return this.customerFormData$.asObservable();
  }

  setCustomerFormPassword(password: string) {
    const currentForm = this.customerFormData$.value;
    currentForm!.password = password;
    this.setCustomerFormData(currentForm!);
  }

  postCustomerForm(password: string, b2bUnitMail: string) {
    const url = this.occEndpointService.buildUrl('promesaClientRegister', {
      queryParams: { password },
    });
    const customerData = {
      ...this.promesaCustomerData$.value,
      contactNumber: this.promesaCustomerData$.value?.cellphone
    }
    return this.http.post(url, customerData).pipe(
      catchError((error) => {
        if (error?.error?.errors?.[0]?.type === 'PromesaNonActiveClientError') {
          this.openErrorInfoModal();
        }
        return EMPTY;
      }),
      tap((value) => {
        if (value) {
          this.openSuccessfulModal(b2bUnitMail.toLowerCase());
        }
      })
    );
  }

  validatePromesaClient(form: PromesaRegisterFormData, clientCode: string) {
    const url = this.occEndpointService.buildUrl('validatePromesaClient');
    const customer: PromesaCustomerFormData = {
      email: form?.email.toLowerCase(),
      firstName: form?.name,
      lastName: form?.surname,
      cellphone: form?.cellphone,
      orgUnit: {
        uid: clientCode,
      },
    };
    this.promesaCustomerData$.next(customer);
    return this.http.post(url, customer).pipe(
      catchError((error) => {
        return of(error?.error?.errors?.[0]?.type);
      })
    );
  }

  updateCredentials(email: string, password: string, b2bUnitMail: string) {
    const url = this.occEndpointService.buildUrl(
      'promesaClientUpdateCredentials'
    );

    return this.http
      .post(url, {
        email: email.toLowerCase(),
        password: password,
      })
      .pipe(
        catchError((error) => {
          if (
            error?.error?.errors?.[0]?.type === 'PromesaNonActiveClientError'
          ) {
            this.openErrorInfoModal();
          }
          return of(error);
        }),
        tap((value) => {
          if (!value?.error) {
            this.openSuccessfulModal(b2bUnitMail.toLowerCase());
          }
        })
      );
  }

  openErrorInfoModal() {
    let modalInstance: any;

    this.modalRef = this.modalService.open(
      CustomRegisterCustomerErrorInfoComponent,
      {
        centered: true,
        size: 'lg',
      }
    );

    modalInstance = this.modalRef.componentInstance;
  }

  openSuccessfulModal(email: string) {
    let modalInstance: any;

    this.modalRef = this.modalService.open(
      CustomCustomerRegisterSuccessfulComponent,
      {
        centered: true,
        size: 'lg',
      }
    );
    modalInstance = this.modalRef.componentInstance;
    modalInstance.email = email;
  }
}
