import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomSafeHtmlModule } from '../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomCartListSummaryComponent } from './custom-cart-list-summary.component';



@NgModule({
  declarations: [
    CustomCartListSummaryComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    CustomSafeHtmlModule
  ],
  exports: [
    CustomCartListSummaryComponent
  ]
})
export class CustomCartListSummaryModule { }
