import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomHamburgerMenuComponent } from './custom-hamburger-menu.component';
import { CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';



@NgModule({
  declarations: [
    CustomHamburgerMenuComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        HamburgerMenuComponent: {
          component: CustomHamburgerMenuComponent,
        },
      },
    }),
  ],
})
export class CustomHamburgerMenuModule { }
