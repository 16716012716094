import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MultiCartService } from '@spartacus/core';
import { ICON_TYPE, ModalService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-quote-cancel-checkout-dialog',
  templateUrl: './custom-quote-cancel-checkout-dialog.component.html',
  styleUrls: ['./custom-quote-cancel-checkout-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomQuoteCancelCheckoutDialogComponent {
  iconTypes = ICON_TYPE;
  code: string;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    private modalService: ModalService,
    private customBreakpointService: CustomBreakpointService,
    private router: Router,
    private multicartService: MultiCartService
  ) {}

  closeModal(reason: string) {
    this.modalService.closeActiveModal(reason);
  }

  removeCart() {
    this.multicartService.deleteCart(this.code, 'current');
    this.router.navigateByUrl('/');
    this.closeModal('remove cart');
  }
  ngOnDestroy(): void {}
}
