import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  CmsBannerComponent,
  CmsComponent,
  CmsResponsiveBannerComponentMedia,
  CmsService,
} from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';
import { CustomGtmEcommerceDatalayerService } from 'src/app/common/services/custom-gtm-ecommerce-datalayer.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomGetImageUrlService } from 'src/app/services/custom-get-image-url.service';
import SwiperCore, {
  Autoplay,
  EffectFade,
  Pagination,
  SwiperOptions,
} from 'swiper';
import { CustomCmsBannerCarouselComponent } from './model/custom-banner-carousel';
import { BannerGradient } from '../../../custom/components/models/custom-banner-gradient.interface';

SwiperCore.use([EffectFade, Pagination, Autoplay]);

@Component({
  selector: 'app-custom-banner-carousel',
  templateUrl: './custom-banner-carousel.component.html',
  styleUrls: ['./custom-banner-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomBannerCarouselComponent implements OnInit, OnDestroy {
  defaultSwiperConfig: SwiperOptions;
  componentData$: Observable<CustomCmsBannerCarouselComponent>;
  items$: Observable<Observable<CmsBannerComponent>[]>;
  swiperConfig$ = new BehaviorSubject<SwiperOptions>({});
  isMobile$: Observable<boolean> = this.customBreakpoinService.isMobile$;
  isInPromeclub: boolean = this.service.isInPromeClub();

  subscription: Subscription = new Subscription();
  ArrayBanner: CmsComponent[] = [];
  constructor(
    protected componentData: CmsComponentData<CustomCmsBannerCarouselComponent>,
    protected cmsService: CmsService,
    private service: CustomNavigationService,
    protected customGetImageUrl: CustomGetImageUrlService,
    protected customBreakpoinService: CustomBreakpointService,
    private customGtmDatalayerService: CustomGtmEcommerceDatalayerService
  ) {}

  ngOnInit(): void {
    this.defaultSwiperConfig = {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 0,
      autoHeight: true,
      pagination: {
        clickable: true,
      },
      watchOverflow: true,
    };

    this.componentData$ = this.componentData.data$.pipe(
      take(1),
      tap((componentData: CustomCmsBannerCarouselComponent) => {
        this.swiperConfig$.next({
          ...this.defaultSwiperConfig,
          ...this.getSwiperConfig(componentData),
        });
      })
    );

    this.items$ = this.componentData$.pipe(
      map((data) => data.responsiveBanners.trim().split(' ')),
      map((codes) =>
        codes.map((code) =>
          this.cmsService.getComponentData(code).pipe(
            map((bannerData: CmsComponent | null) => ({
              ...bannerData,
              image: this.customBreakpoinService?.getImageByBreakpoint(
                <CmsResponsiveBannerComponentMedia>bannerData
              ),
            }))
          )
        )
      )
    );

    this.subscription.add(
      this.items$
        .pipe(
          map((banner: Observable<CmsComponent>[]) => {
            banner.forEach((ban) => {
              ban.subscribe((ban) => {
                this.ArrayBanner.push(ban);
              });
            });
          })
        )
        .subscribe(() =>
          setTimeout(
            () =>
              this.customGtmDatalayerService.promotionViewEvent(
                this.ArrayBanner
              ),
            3000
          )
        )
    );
  }

  getSwiperConfig(
    componentData: CustomCmsBannerCarouselComponent
  ): SwiperOptions {
    const autoplay = { delay: componentData.timeout * 1000 || 5000 };
    const paginationIsAvailable =
      componentData.responsiveBanners.trim().split(' ').length > 1;
    const pagination = paginationIsAvailable
      ? {
          clickable: true,
        }
      : false;
    const effect = <any>componentData?.effect?.toLowerCase() ?? '';
    return {
      autoplay,
      pagination,
      effect,
    };
  }

  getImageUrl(url: string): string {
    return this.customGetImageUrl.getImageUrl(url);
  }
  onEventClick(item: any, i: number) {
    this.customGtmDatalayerService.bannerClickEvent(item, i);
  }

  getStyles(
    i: number,
    isMobile: boolean,
    imageUrl: string,
    item: BannerGradient
  ): string {
    const gradientValue: string | undefined = !isMobile
      ? item?.desktopLinearGradientEffect?.trim()
      : item?.mobileLinearGradientEffect?.trim();
    const property = `--gradient${i}:${gradientValue} --bg-url${i}:url(${this.getImageUrl(
      imageUrl
    )})`;
    return property;
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
