import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SavedCartFormDialogComponent } from '@spartacus/cart/saved-cart/components';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import {
  EventService,
  GlobalMessageService,
  RoutingService,
} from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';

import { CustomGtmInteractionDatalayerService } from 'src/app/common/services/custom-gtm-interaction-datalayer.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-saved-cart-form-dialog',
  templateUrl: './custom-saved-cart-form-dialog.component.html',
  styleUrls: ['./custom-saved-cart-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CustomSavedCartFormDialogComponent
  extends SavedCartFormDialogComponent
  implements OnInit, OnDestroy
{
  formSaved: FormGroup;
  formEnabled: boolean = true;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
    protected savedCartService: SavedCartFacade,
    protected eventService: EventService,
    protected routingService: RoutingService,
    protected globalMessageService: GlobalMessageService,
    protected customGtmDatalayerService: CustomGtmInteractionDatalayerService,
    protected customBreakpointService: CustomBreakpointService
  ) {
    super(
      launchDialogService,
      el,
      savedCartService,
      eventService,
      routingService,
      globalMessageService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.formSaved = new FormGroup({
      nameCart: new FormControl('', [
        Validators.required,
        Validators.maxLength(this.nameMaxLength),
      ]),
    });
    this.patchData(this.cart);
  }

  onSaveCart(cartId: string): void {
    this.formEnabled = false;
    const name = this.formSaved.get('nameCart')?.value;
    this.customGtmDatalayerService.addToSavedCart(name);
    this.savedCartService.saveCart({
      cartId,
      saveCartName: name,
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
