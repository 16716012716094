import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  AuthService,
  ProductReviewService,
  Review,
  TranslationService,
} from '@spartacus/core';
import {
  CurrentProductService,
  CustomFormValidators,
  ModalRef,
  ModalService,
  ProductReviewsComponent,
} from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { CustomGtmInteractionDatalayerService } from 'src/app/common/services/custom-gtm-interaction-datalayer.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { EngagementProducto } from '../../../model/custom-gtmProduct.model';
import { CustomAddReviewDialogComponent } from './custom-add-review/custom-add-review-dialog.component';
import { CustomScrollService } from 'src/app/services/custom-scroll.service';
@Component({
  selector: 'app-custom-product-reviews',
  templateUrl: './custom-product-reviews.component.html',
  styleUrls: ['./custom-product-reviews.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomProductReviewsComponent
  extends ProductReviewsComponent
  implements OnInit, OnDestroy
{
  unsubscribe$ = new Subject<any>();
  reviewsLimit: number;
  title$: Observable<string>;

  initialMaxListItems = 4;
  initialMaxListItemsMobile = 3;
  maxListItemsMobile: number;

  reviews$: Observable<Review[]>;

  isUserLoggedIn$: Observable<boolean> = this.auth.isUserLoggedIn();
  isMobile$: Observable<boolean>;
  showOverall: boolean = false;

  fiveStarsReviews$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  fourStarsReviews$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  threeStarsReviews$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  twoStarsReviews$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  oneStarsReviews$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  fiveStarsPercent: number = 0;
  fourStarsPercent: number = 0;
  threeStarsPercent: number = 0;
  twoStarsPercent: number = 0;
  oneStarsPercent: number = 0;

  rating: any[] = [];

  modalRef: ModalRef;

  @ViewChild('productReviewElement')
  productReviewElementRef: ElementRef;
  moveToElementRef$: Observable<boolean> = this.scrollService.moveToReviews$;
  subscription: Subscription = new Subscription();

  constructor(
    protected reviewService: ProductReviewService,
    protected currentProductService: CurrentProductService,
    private _fb: FormBuilder,
    protected cd: ChangeDetectorRef,
    protected translation: TranslationService,
    protected modalService: ModalService,
    protected customBreakpointService: CustomBreakpointService,
    private auth: AuthService,
    private customGtmDatalayerService: CustomGtmInteractionDatalayerService,
    private scrollService: CustomScrollService
  ) {
    super(reviewService, currentProductService, _fb, cd);
  }

  ngOnInit(): void {
    this.title$ = this.translation.translate(
      'TabPanelContainer.tabs.ProductReviewsTabComponent'
    );
    this.isMobile$ = this.customBreakpointService.isMobile$.pipe(
      tap((isMobile) =>
        isMobile ? (this.showOverall = false) : (this.showOverall = true)
      )
    );
    this.reviews$ = this.product$.pipe(
      filter((p) => !!p),
      map((p) => p.code!),
      distinctUntilChanged(),
      switchMap((productCode: string) =>
        this.reviewService.getByProductCode(productCode)
      ),
      tap((reviews: Review[]) => {
        this._resetReviewForm();
        this.maxListItems = this.initialMaxListItems;
        this.initialMaxListItemsMobile > reviews?.length
          ? (this.maxListItemsMobile = reviews?.length)
          : (this.maxListItemsMobile = this.initialMaxListItemsMobile);
      }),
      debounceTime(2000),
      tap((reviews: Review[]) => {
        this.reviewsLimit = reviews?.length;
        reviews?.map((review: Review) =>
          this.rating.push({ rating: Array(review?.rating) })
        );
        this.mapArray(reviews);
      })
    );

    this.subscription.add(
      this.moveToElementRef$.pipe(filter(Boolean)).subscribe(() => {
        this.scrollService.scrollIntoView(
          'smooth',
          'center',
          this.productReviewElementRef
        );
      })
    );
  }

  mapArray(array: any) {
    let five: number = 0;
    this.fiveStarsReviews$.next(five);
    let four: number = 0;
    this.fourStarsReviews$.next(four);
    let three: number = 0;
    this.threeStarsReviews$.next(three);
    let two: number = 0;
    this.twoStarsReviews$.next(two);
    let one: number = 0;
    this.oneStarsReviews$.next(one);

    for (let index = 0; index < array?.length; index++) {
      if (array?.[index]?.rating === 5) {
        five = five + 1;
        this.fiveStarsReviews$.next(five);
        let percent = (five * 100) / array.length;
        this.fiveStarsPercent = percent > 100 ? 100 : percent;
      }
      if (array?.[index]?.rating === 4) {
        four = four + 1;
        this.fourStarsReviews$.next(four);
        let percent = (four * 100) / array.length;
        this.fourStarsPercent = percent > 100 ? 100 : percent;
      }
      if (array?.[index]?.rating === 3) {
        three = three + 1;
        this.threeStarsReviews$.next(three);
        let percent = (three * 100) / array.length;
        this.threeStarsPercent = percent > 100 ? 100 : percent;
      }
      if (array?.[index]?.rating === 2) {
        two = two + 1;
        this.twoStarsReviews$.next(two);
        let percent = (two * 100) / array.length;
        this.twoStarsPercent = percent > 100 ? 100 : percent;
      }
      if (array?.[index]?.rating === 1) {
        one = one + 1;
        this.oneStarsReviews$.next(one);
        let percent = (one * 100) / array.length;
        this.oneStarsPercent = percent > 100 ? 100 : percent;
      }
      this.cd.detectChanges();
    }
  }

  protected _resetReviewForm(): void {
    this.reviewForm = this._fb.group({
      comment: ['', Validators.required],
      rating: [null, CustomFormValidators.starRatingEmpty],
      reviewerName: '',
    });
  }

  openPopup() {
    this.customGtmDatalayerService.productInteractionEvent(
      EngagementProducto.NEW_REVIEW
    );
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomAddReviewDialogComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'add-review',
    });
    modalInstance = this.modalRef.componentInstance;
    modalInstance.product$ = this.product$;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
