import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductTermsComponent } from './custom-product-terms.component';
import { I18nModule } from '@spartacus/core';

@NgModule({
  imports: [
    CommonModule,
    I18nModule
  ],
  declarations: [CustomProductTermsComponent],
  exports: [CustomProductTermsComponent]
})
export class CustomProductTermsModule { }
