import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ModalService } from '@spartacus/storefront';
import { CustomPreAddToCartService } from './custom-pre-add-to-cart.service';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CustomBaseSiteCheckerService } from '../../../../services/custom-base-site-checker.service';

@Component({
  selector: 'app-custom-pre-add-to-cart',
  templateUrl: './custom-pre-add-to-cart.component.html',
  styleUrls: ['./custom-pre-add-to-cart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPreAddToCartComponent implements OnInit {
  @Input() productCode: string;
  @Input() quantitySelected: number;
  @Input() isFromQuote: boolean;
  @Input() isPromeclub: boolean;
  @Input() isOutOfStock: boolean = false;
  isPromeclub$: Observable<boolean> =
    this.customBaseSiteCheckerService.isPromeclub$;
  subscription: Subscription = new Subscription();
  constructor(
    private modalService: ModalService,
    private customPreAddToCartService: CustomPreAddToCartService,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService
  ) {}

  ngOnInit(): void {}

  closeModal(): void {
    this.modalService.closeActiveModal();
  }

  addToCartItem(): void {
    if (this.isOutOfStock) {
      return;
    }
    this.subscription.add(
      this.customPreAddToCartService
        .getQuantitySelected()
        .pipe(take(1))
        .subscribe(() => {
          this.closeModal();
          this.customPreAddToCartService.addToCartFromModal(
            this.isFromQuote,
            this.isPromeclub
          );
        })
    );
  }
}
