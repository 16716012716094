import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CustomRoundNumber',
})
export class CustomRoundNumberPipe implements PipeTransform {
  transform(value: number): number {
    const decimalPart = value % 1;

    if (decimalPart >= 0.6) {
      return Math.ceil(value);
    } else {
      return Math.floor(value);
    }
  }
}
