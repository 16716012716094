import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CmsComponentData, CustomFormValidators } from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CustomGtmLoginService } from 'src/app/common/services/custom-gtm-login-register-datalayer.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomGetImageUrlService } from 'src/app/services/custom-get-image-url.service';
import { CustomNewsletter } from './model/custom-newsletter.interface';
import { CustomNewsletterService } from './services/custom-newsletter.service';

@Component({
  selector: 'app-custom-newsletter',
  templateUrl: './custom-newsletter.component.html',
  styleUrls: ['./custom-newsletter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CustomNewsletterComponent implements OnInit, OnDestroy {
  componentData$: Observable<CustomNewsletter> = this.componentData.data$;
  success: boolean = false;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  private unsubscribe$ = new Subject<void>();
  emailInput: FormGroup = this.fb.group(
    {
      email: ['', [CustomFormValidators.emailValidator]],
    },
    { updateOn: 'blur' }
  );
  constructor(
    private componentData: CmsComponentData<CustomNewsletter>,
    private customBreakpointService: CustomBreakpointService,
    private fb: FormBuilder,
    private customGetImageUrl: CustomGetImageUrlService,
    private customNewsletterService: CustomNewsletterService,
    private customGtmDatalayerService: CustomGtmLoginService
  ) {}

  ngOnInit(): void {}

  getImageUrl(url: string): string {
    return this.customGetImageUrl.getImageUrl(url);
  }

  onSubmit() {
    const formIsValid = this.emailInput.valid;

    if (formIsValid) {
      const email = this.emailInput?.get('email')?.value;
      //TODO post the data to the endpoint when it´s done
      this.success = true;
      this.customNewsletterService
        .sendEmailToNewsletter(email)
        .pipe(
          takeUntil(this.unsubscribe$),
          tap(
            (ok) => {
              this.customGtmDatalayerService.registerToNewsletter(true);
            },
            (error) => {
              this.customGtmDatalayerService.registerToNewsletter(false);
            }
          )
        )
        .subscribe();
    } else {
      this.emailInput.markAllAsTouched();
      this.success = false;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
