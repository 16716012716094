import { Component } from '@angular/core';
import { CardComponent } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-card',
  templateUrl: './custom-card.component.html',
  styleUrls: ['./custom-card.component.scss'],
})
export class CustomCardComponent extends CardComponent{
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    private customBreakpointService: CustomBreakpointService) {
    super();
  }

}
