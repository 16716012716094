import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckoutStepService } from '@spartacus/checkout/components';
import { CheckoutDeliveryFacade } from '@spartacus/checkout/root';
import { TranslationService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { combineLatest, Observable, Subject } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { CustomGtmInteractionDatalayerService } from 'src/app/common/services/custom-gtm-interaction-datalayer.service';
import { CustomBaseSiteCheckerService } from 'src/app/services/custom-base-site-checker.service';
import { CenterDistributionService } from '../../services/center-distribution.service';
import { CustomAuthorizedPersonService } from '../../services/custom-authorized-person.service';
import { CustomCheckoutDeliveryStepsService } from '../../services/custom-checkout-delivery-steps.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-checkout-delivery-method',
  templateUrl: './custom-checkout-delivery-method.component.html',
  styleUrls: ['./custom-checkout-delivery-method.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomCheckoutDeliveryMethodComponent
  implements OnInit, OnDestroy
{
  isAddressActive$: Observable<boolean> =
    this.customCheckoutStepsService.getIsAddressActive();
  isAddressSelected$: Observable<boolean> =
    this.customCheckoutStepsService.getIsAddressSelected();
  isCenterDistributionActive$: Observable<boolean> =
    this.customCheckoutStepsService.getIsCenterDistributionActive();
  isCenterDistributionSelected$: Observable<boolean> =
    this.customCheckoutStepsService.getIsCenterDistributionSelected();
  isAuthorizedPersonSelected$: Observable<boolean> =
    this.customCheckoutStepsService.getAuthorizedPersonSelected();
  isDeliveryModeSelected$: Observable<boolean> =
    this.customCheckoutStepsService.getIsDeliveryModeSelected();
  showButton$: Observable<boolean> =
    this.customAuthorizedPersonService.getShowButtonDeliveryMethod();
  centerDistribution$: Observable<boolean>;
  userId$: Observable<string> = this.userAccountFacade
    .get()
    .pipe(map((user) => user?.uid!));
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  private unsubscribe$ = new Subject<void>();

  numberStep: number = 1;
  numberNextStep: number = 2;
  constructor(
    private centerDistribution: CenterDistributionService,
    private userAccountFacade: UserAccountFacade,
    private activatedRoute: ActivatedRoute,
    private checkoutStepService: CheckoutStepService,
    private customCheckoutStepsService: CustomCheckoutDeliveryStepsService,
    private customAuthorizedPersonService: CustomAuthorizedPersonService,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    protected translation: TranslationService,
    private _checkoutDeliveryService: CheckoutDeliveryFacade,
    private customGtmDatalayerService: CustomGtmInteractionDatalayerService,
    private customBreakpointService: CustomBreakpointService
  ) {}

  ngOnInit(): void {
    this.centerDistribution$ = this.userAccountFacade
      .get()
      .pipe(
        switchMap((user) =>
          this.centerDistribution.getCenterDistribution(user?.uid!)
        )
      );
  }

  next(
    isAddressSelected: boolean,
    isDeliveryModeSelected: boolean,
    isCenterDistributionSelected: boolean
  ): void {
    if (
      (isAddressSelected && isDeliveryModeSelected) ||
      isCenterDistributionSelected
    ) {
      combineLatest([
        isAddressSelected
          ? this.translation.translate('checkoutProgress.myAdress')
          : this.translation.translate('checkoutProgress.centerDistribution'),
        this._checkoutDeliveryService.getDeliveryAddress(),
        this._checkoutDeliveryService.getSelectedDeliveryModeCode(),
      ])
        .pipe(
          first(),
          takeUntil(this.unsubscribe$),
          map(([deliveryMethod, deliveryAddress, deliveryAddressCD]) => {
            /* delivery Address viene a veces como un objeto vacio */

            this.customGtmDatalayerService.deliveryModeEvent(
              deliveryMethod,
              deliveryAddress ||
                (deliveryAddress && Object.entries(deliveryAddress).length > 0)
                ? deliveryAddress.firstName!
                : deliveryAddressCD!
            );
          })
        )
        .subscribe();

      this.checkoutStepService.next(this.activatedRoute);
    }
  }

  toggleAddressActive(
    isAddressActive: boolean,
    isCenterDistributionActive: boolean
  ) {
    if (isAddressActive) {
      return;
    }

    if (isCenterDistributionActive) {
      this.customCheckoutStepsService.setIsCenterDistributionActive(
        !isCenterDistributionActive
      );

      this.toggleCenterDistributionActive(
        isCenterDistributionActive,
        isAddressActive
      );
    }

    this.customCheckoutStepsService.cleanCenterDistribution();
    this.customCheckoutStepsService.setIsAddressActive(!isAddressActive);
  }

  toggleCenterDistributionActive(
    isCenterDistributionActive: boolean,
    isAddressActive: boolean
  ) {
    if (isCenterDistributionActive) {
      return;
    }

    if (isAddressActive) {
      this.customCheckoutStepsService.setIsAddressActive(!isAddressActive);
      this.toggleAddressActive(isAddressActive, isCenterDistributionActive);
    }
    this.customCheckoutStepsService.cleanDeliveryMethod(false);
    this.customCheckoutStepsService.setIsCenterDistributionActive(
      !isCenterDistributionActive
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
