import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { Observable } from 'rxjs';
import { CustomOrderEntry } from 'src/app/cms-components/cart/model/custom-order-entry';
import { EcvProductType } from 'src/app/cms-components/product/constants/product-type';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-order-detail-item-product',
  templateUrl: './custom-order-detail-item-product.component.html',
  styleUrls: ['./custom-order-detail-item-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CustomOrderDetailItemProductComponent {
  @Input() item: CustomOrderEntry;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  EcvProductType: string = EcvProductType;
  constructor(private customBreakpointService: CustomBreakpointService) {}
}
