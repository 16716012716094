<ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
  <div class="range">
    <div class="range__box">
      <p class="range__box-label">{{ "filterPrice.min" | cxTranslate }}</p>
      <input
        type="number"
        [formControl]="minFormValue"
        [placeholder]="'filterPrice.min' | cxTranslate"
        class="range__box-input"
      />
    </div>

    <div class="range__box">
      <p class="range__box-label">{{ "filterPrice.max" | cxTranslate }}</p>
      <input
        type="number"
        [formControl]="maxFormValue"
        [placeholder]="'filterPrice.max' | cxTranslate"
        class="range__box-input"
      />
    </div>
    <button
      routerLink="./"
      [queryParams]="createUrl()"
      class="range__box-button"
      [disabled]="
        disableButton() || minFormValue.invalid || maxFormValue.invalid
      "
    >
      <cx-icon class="fas fa-chevron-right"></cx-icon>
    </button>
  </div>
</ng-container>
