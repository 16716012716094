<div class="product-container" *ngIf="entry.product">
  <div class="product-container__image">
    <cx-media
      *ngIf="entry.product.images && !fromWish"
      [container]="entry?.product?.images?.PRIMARY"
      format="thumbnail"
      role="presentation"
    ></cx-media>
    <cx-media
      *ngIf="entry.product.images && fromWish"
      [container]="entry?.product?.images"
      format="thumbnail"
      role="presentation"
    ></cx-media>
  </div>
  <div
    class="product-container__data"
    [ngClass]="{ 'data-promeclub': isInPromeclub }"
  >
    <ng-container *ngIf="isPdp">
      <ng-container
        *ngIf="
          entry.product?.stock?.stockLevelStatus === 'outOfStock' &&
          !isInPromeclub
        "
      >
        <div class="product-container__data__badges">
          <app-custom-gift-product
            label="{{ 'addToCart.outOfStock' | cxTranslate }}"
          ></app-custom-gift-product>
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          entry.product?.stock?.stockLevelStatus != 'outOfStock' &&
          entry.product?.stock?.stockLevel > 0 &&
          entry.product?.badge
        "
      >
        <div class="product-container__data__badges">
          <app-custom-gift-product
            [label]="entry.product?.badge"
          ></app-custom-gift-product>
        </div>
      </ng-container>
    </ng-container>

    <p
      *ngIf="entry?.product?.brand?.name"
      class="product-container__data-category"
    >
      {{ entry?.product?.brand?.name | titlecase }}
    </p>
    <p
      class="product-container__data-name"
      [ngClass]="{ 'name-promeclub': isInPromeclub }"
    >
      {{ entry?.product?.name }}
    </p>
    <p class="product-container__data-sku">
      <ng-container *ngIf="!isInPromeclub">{{
        "productList.sku" | cxTranslate
      }}</ng-container>
      <ng-container *ngIf="isInPromeclub">
        {{ "productList.code" | cxTranslate }}</ng-container
      >
      {{
        !isInPromeclub ? entry?.product?.code : entry?.product?.promotickWebCode
      }}
    </p>
  </div>
  <div
    class="product-container__price"
    [ngClass]="{
      'product-container__price-badge':
        entry.product?.badge ||
        entry.product?.stock?.stockLevelStatus === 'outOfStock',
      promeclub: isInPromeclub
    }"
  >
    <p class="product-container__price-quantity">
      {{ quantity }}
    </p>
    <p
      class="product-container__price-value"
      [ngClass]="{
        'price-promeclub': isInPromeclub,
        previous: entry?.product?.previousPrice?.value
      }"
    >
      <ng-container *ngIf="entry?.product?.previousPrice?.value">
        <div class="product-container__price-value-previous">
          {{ 50 | customCurrency }}
        </div>
      </ng-container>

      <ng-container *ngIf="!isInPromeclub">{{
        entry?.product?.priceWithIVA?.value | customCurrency
      }}</ng-container>
      <ng-container *ngIf="isInPromeclub">{{
        entry?.basePrice?.value | number : "1.0-0" : "es-AR"
      }}</ng-container>
      <span
        class="product-container__price-value-iva"
        [ngClass]="{ promeclub: isInPromeclub }"
      >
        <ng-container *ngIf="!isInPromeclub">
          {{ "productList.plusIva" | cxTranslate }}</ng-container
        >
        <ng-container *ngIf="isInPromeclub">
          {{ "productList.points" | cxTranslate }}</ng-container
        >
      </span>
    </p>
  </div>
</div>
