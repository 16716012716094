<div
  class="cx-gift-product"
  [ngClass]="{
    pdp: template === 'ProductDetailsPageTemplate',
    plp: template === 'productCategorySearchPageWsDTO',
    wideLabel: wideLabel
  }"
>
  <label>{{ label }}</label>
</div>
