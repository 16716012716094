import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '@spartacus/storefront';
import { CustomRegisterStepOrchestratorService } from '../services/custom-register-step-orchestrator.service';
import { Observable, Subscription } from 'rxjs';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-register-employee',
  templateUrl: './custom-register-employee.component.html',
  styleUrls: ['./custom-register-employee.component.scss']
})
export class CustomRegisterEmployeeComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();

  userId$: Observable<any> = this.userAccountFacade.get();
  constructor(
    protected userAccountFacade: UserAccountFacade,
    private router: Router,
    private customRegisterStepOrchestratorService: CustomRegisterStepOrchestratorService
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.userId$.subscribe((value) => {
        if (value) {
          this.customRegisterStepOrchestratorService.setStepOneStatus(true);
          this.customRegisterStepOrchestratorService.setStepTwoStatus(false);
          this.customRegisterStepOrchestratorService.setStepThreeStatus(false);
          this.router.navigateByUrl('/');
        }
      })
    );
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
