import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductVariantListComponent } from './custom-product-variant-list.component';
import { I18nModule } from '@spartacus/core';
import { CustomProductVariantListDescriptionComponent } from './custom-product-variant-list-description/custom-product-variant-list-description.component';
import { CustomProductVariantListCardComponent } from './custom-product-variant-list-card/custom-product-variant-list-card.component';
import { CustomProductVariantListHeaderComponent } from './custom-product-variant-list-header/custom-product-variant-list-header.component';
import { CustomViewSelectorsModule } from 'src/app/cms-components/custom/components/custom-view-selectors/custom-view-selectors.module';
import { CustomProductVariantListItemComponent } from './custom-product-variant-list-item/custom-product-variant-list-item.component';
import { CustomProductVariantListItemDetailsComponent } from './custom-product-variant-list-item/custom-product-variant-list-item-details/custom-product-variant-list-item-details.component';
import { RouterModule } from '@angular/router';
import { CustomAddToCartModule } from '../../../cart/custom-add-to-card/custom-add-to-cart.module';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { CustomProductVariantListItemDetailsMobileComponent } from './custom-product-variant-list-item/custom-product-variant-list-item-details-mobile/custom-product-variant-list-item-details-mobile.component';
import { CustomCurrencyModule } from '../../../../pipes/custom-currency/custom-currency.module';
import { SwiperModule } from 'swiper/angular';
import { CustomLoadingSpinnerModule } from '../../../custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomSafeHtmlModule } from '../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomGiftProductModule } from 'src/app/cms-components/custom/components/custom-gift-product/custom-gift-product.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';




@NgModule({
  declarations: [
    CustomProductVariantListComponent,
    CustomProductVariantListDescriptionComponent,
    CustomProductVariantListCardComponent,
    CustomProductVariantListHeaderComponent,
    CustomProductVariantListItemComponent,
    CustomProductVariantListItemDetailsComponent,
    CustomProductVariantListItemDetailsMobileComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    CustomViewSelectorsModule,
    RouterModule,
    CustomAddToCartModule,
    IconModule,
    CustomCurrencyModule,
    MediaModule,
    SwiperModule,
    CustomLoadingSpinnerModule,
    CustomSafeHtmlModule,
    CustomGiftProductModule,
    NgbModule,
  ],
  exports: [
    CustomProductVariantListComponent,
    CustomProductVariantListDescriptionComponent,
    CustomProductVariantListCardComponent,
    CustomProductVariantListHeaderComponent
  ]
})
export class CustomProductVariantListModule { }
