import { Injectable } from '@angular/core';
import { PaginationModel } from '@spartacus/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomOrderPaginationService {
  pageChange$: Subject<PaginationModel | null> = new Subject<PaginationModel | null>();

  onPageChange(pagination: PaginationModel) {
    this.pageChange$.next(pagination);
  }
}
