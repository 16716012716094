import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule, ProductListModule } from '@spartacus/storefront';
import { CustomViewSelectorsComponent } from './custom-view-selectors.component';



@NgModule({
  declarations: [
    CustomViewSelectorsComponent
  ],
  imports: [
    CommonModule,
    ProductListModule,
    I18nModule,
    IconModule
  ],
  exports: [
    CustomViewSelectorsComponent
  ]
})
export class CustomViewSelectorsModule { }
