export const asm = {
  asm: {
    mainLogoLabel: 'SAP',
    mainTitle: 'Assisted Service Mode',
    logout: 'Cerrar sesión',
    hideUi: 'Cerrar ASM',
    toggleUi: {
      collapse: 'Ocultar ASM',
      expand: 'Mostrar ASM',
    },
    loginForm: {
      submit: 'Iniciar sesión',
      userId: {
        label: 'ID del agente',
        required: 'Se requiere ID de agente',
      },
      password: {
        label: 'Contraseña',
        required: 'La contraseña es requerida',
      },
    },
    customerSearch: {
      searchTerm: {
        label: 'Nombre del cliente/Dirección de correo electrónico',
      },
      submit: 'Iniciar sesión',
      noMatch: 'No se encontró ningún cliente.',
    },
    csagentTokenExpired:
      'Su sesión de agente de atención al cliente ha caducado.',
    endSession: 'Finalizar sesión',
    agentSessionTimer: {
      label: 'Tiempo límite de sesión',
      minutes: 'min',
      reset: 'Reiniciar',
    },
    expiredSession: {
      title: 'Tiempo de sesión expirado',
      label: 'Por seguridad hemos finalizado tu sesión por inactividad'
    },
    auth: {
      agentLoggedInError:
        'No se puede iniciar sesión como usuario cuando hay una sesión de agente de CS activa. Emule al usuario o cierre la sesión del agente de CS.',
    },
    error: {
      noCustomerId:
        'No se encontró ningún ID de cliente para el usuario seleccionado. No se puede iniciar la sesión.',
    },
  },
};
