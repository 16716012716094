import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ResetPasswordComponent } from '@spartacus/user/profile/components';
import { Observable } from 'rxjs';
import { CustomResetPasswordService } from './custom-reset-password.service';

@Component({
  selector: 'app-custom-reset-password',
  templateUrl: './custom-reset-password.component.html',
  styleUrls: ['./custom-reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'user-form' },
  encapsulation: ViewEncapsulation.None,
})
export class CustomResetPasswordComponent extends ResetPasswordComponent {
  form: FormGroup = this.customResetPasswordService.form;
  isUpdating$ = this.customResetPasswordService.isUpdating$;
  token$: Observable<string> = this.customResetPasswordService.resetToken$;

  constructor(
    protected service: CustomResetPasswordService,
    private customResetPasswordService: CustomResetPasswordService
  ) {
    super(service);
  }
}
