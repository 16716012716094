import { Component, ViewEncapsulation } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomClaimAddService } from '../../../services/custom-claim-add.service';

@Component({
  selector: 'app-custom-claim-list',
  templateUrl: './custom-claim-list.component.html',
  styleUrls: ['./custom-claim-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomClaimListComponent {
  selectedPage: number = 1;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  quantities: any[] = [
    { id: 25, name: 25, disabled: true },
    { id: 50, name: 50, disabled: true },
    { id: 100, name: 100, disabled: true },
    { id: 200, name: 200, disabled: true },
    { id: 10000, name: 'Todos', disabled: true },
  ];
  pageSize: number = 10000;

  claims$: Observable<any> = this.addClaimService.getAllClaims(
    this.pageSize,
    undefined
  );
  constructor(
    private routing: RoutingService,
    private addClaimService: CustomClaimAddService,
    private customBreakpointService: CustomBreakpointService
  ) {}

  customPageChangeSelected(page: number): void {
    this.selectedPage = page;

    this.claims$ = this.addClaimService.getAllClaims(
      this.pageSize,
      this.selectedPage
    );
  }

  goToOrderDetail(order: any): void {
    this.routing.go({
      cxRoute: 'claimOrderDetail',
      params: order,
    });
  }

  filterQuantitySelected(filter: number) {
    this.pageSize = filter;
    this.selectedPage = 0;
    this.claims$ = this.addClaimService.getAllClaims(
      this.pageSize,
      this.selectedPage
    );
  }
}
