import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomRegisterStepOrchestratorService } from '../../services/custom-register-step-orchestrator.service';

@Component({
  selector: 'app-custom-register-progress',
  templateUrl: './custom-register-progress.component.html',
  styleUrls: ['./custom-register-progress.component.scss'],
})
export class CustomRegisterProgressComponent {
  stepOne$: Observable<boolean> =
    this.customRegisterStepOrchestrator.getStepOneStatus();
  stepTwo$: Observable<boolean> =
    this.customRegisterStepOrchestrator.getStepTwoStatus();
  stepThree$: Observable<boolean> =
    this.customRegisterStepOrchestrator.getStepThreeStatus();

  constructor(
    private customRegisterStepOrchestrator: CustomRegisterStepOrchestratorService
  ) {}

  toggleStep(step: string) {
    switch (step) {
      case 'one':
        this.customRegisterStepOrchestrator.setStepThreeStatus(false);
        this.customRegisterStepOrchestrator.setStepTwoStatus(false);
        this.customRegisterStepOrchestrator.setStepOneStatus(true);
        break;
      case 'two':
        this.customRegisterStepOrchestrator.setStepThreeStatus(false);
        this.customRegisterStepOrchestrator.setStepTwoStatus(true);
        break;
      default:
        break;
    }
  }
}
