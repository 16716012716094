import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomOrderEntry } from 'src/app/cms-components/cart/model/custom-order-entry';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-promeclub-order-entry-item',
  templateUrl: './custom-promeclub-order-entry-item.component.html',
  styleUrls: ['./custom-promeclub-order-entry-item.component.scss'],
})
export class CustomPromeclubOrderEntryItemComponent {
  @Input() item: CustomOrderEntry;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(private customBreakpointService: CustomBreakpointService) {}
}
