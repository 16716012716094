import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { LaunchDialogService, ModalService } from '@spartacus/storefront';
import {
  Cart,
  EventService,
  GlobalMessageService,
  RoutingService,
} from '@spartacus/core';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import { SavedCartFormDialogComponent } from '@spartacus/cart/saved-cart/components';
import { Observable } from 'rxjs';
import { CustomSavedCartService } from '../../../../../feature-libs/checkout/services/custom-saved-cart.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-saved-cart-modal',
  templateUrl: './custom-saved-cart-modal.component.html',
  styleUrls: ['./custom-saved-cart-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSavedCartModalComponent
  extends SavedCartFormDialogComponent
  implements OnInit, OnDestroy
{
  restoreCart: boolean = false;
  cartSavedName: string;
  cartSaved: Cart;
  isRestoreSavedCart: boolean = false;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
    protected savedCartService: SavedCartFacade,
    protected eventService: EventService,
    protected routingService: RoutingService,
    protected globalMessageService: GlobalMessageService,
    protected modalService: ModalService,
    private testing: CustomSavedCartService,
    private customBreakpointService: CustomBreakpointService,
    private router: Router
  ) {
    super(
      launchDialogService,
      el,
      savedCartService,
      eventService,
      routingService,
      globalMessageService
    );
  }

  ngOnInit(): void {
    if (this.isRestoreSavedCart) {
      this.testing.restoreSavedCart(this.cartSaved?.code!);
    }
  }

  customDismissModal(reason?: string, isRestoreCart?: boolean): void {
    if (isRestoreCart) this.routingService.go({ cxRoute: 'cart' });
    this.modalService.dismissActiveModal(reason);
  }

  customDeleteCart(reason?: string): void {
    this.savedCartService.deleteSavedCart(this.cartSaved?.code!);
    this.customDismissModal(reason);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.isRestoreSavedCart) {
      this.routingService.go({ cxRoute: 'cart' });
    }
  }
}
