
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';



@Injectable({
  providedIn: 'root',
})
export class CustomGetImageUrlService  {


  constructor(private occEndpointService: OccEndpointsService) {}

  getImageUrl(url: string): string{
    return this.occEndpointService.buildUrl(
      url,
      {},
      {
        baseSite: false,
        prefix: false,
      }
    );
  }

  getBaseUrl(): string{
    return this.occEndpointService.getBaseUrl(     {
      baseSite: false,
      prefix: false,
    });
  }
}
