import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  DeferLoadingStrategy,
  I18nModule,
} from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomSafeHtmlModule } from 'src/app/pipes/custom-safe-html/custom-safe-html.module';
import { CustomMyAccountComponent } from './custom-my-account.component';

@NgModule({
  declarations: [CustomMyAccountComponent],
  exports: [CustomMyAccountComponent],
  imports: [
    CommonModule,
    IconModule,
    RouterModule,
    CustomSafeHtmlModule,
    I18nModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromesaAccountNavigationComponent: {
          component: CustomMyAccountComponent,
          guards: [AuthGuard],
          deferLoading: DeferLoadingStrategy.INSTANT,
        },
      },
    }),
  ],
})
export class CustomMyAccountModule {}
