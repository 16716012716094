import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule, ModalModule } from '@spartacus/storefront';
import { CustomModalGenericModule } from 'src/app/cms-components/custom/components/custom-modal/custom-modal-generic.module';
import { CustomProductCardReviewModule } from '../../../custom/components/custom-product-card-review/custom-product-card-review.module';
import { CustomProductSocialShareComponent } from './custom-product-social-share.component';
import { CustomSocialShareContentDialogComponent } from './custom-social-share-content-dialog/custom-social-share-content-dialog.component';



@NgModule({
  declarations: [
    CustomProductSocialShareComponent,
    CustomSocialShareContentDialogComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    ModalModule,
    CustomProductCardReviewModule,
    CustomModalGenericModule
  ],
  exports: [
    CustomProductSocialShareComponent
  ]
})
export class CustomProductSocialShareModule { }
