import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ICON_TYPE, ModalService } from '@spartacus/storefront';
import { CustomAddressOrchestratorService } from '../../../services/custom-address-orchestrator.service';
import { CustomPromeclubAddressService } from '../../../services/custom-promeclub-address.service';
import { Observable, Subscription } from 'rxjs';
import { CustomTwilioValidatorService } from 'src/app/common/services/custom-twilio-validator.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-custom-promeclub-address-alert-dialog',
  templateUrl: './custom-promeclub-address-alert-dialog.component.html',
  styleUrls: ['./custom-promeclub-address-alert-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomPromeclubAddressAlertDialogComponent implements OnInit {
  iconTypes = ICON_TYPE;
  removeAddress: boolean = false;
  editAddress: boolean = false;
  addAddress: boolean = false;
  type: string;
  id: string;
  deleted$: Observable<boolean> =
    this.customAddressOrchestratorService.getDeleted();
  subscription: Subscription = new Subscription();
  showDeleteMessage: boolean = false;
  validationStatus$: Observable<boolean> = this.customTwilioValidatorService
    .getValidationStatus()
    .pipe(map((status) => status === 'OK'));
  constructor(
    private modalService: ModalService,
    private customAddressOrchestratorService: CustomAddressOrchestratorService,
    private customPromeclubAddressService: CustomPromeclubAddressService,
    private customTwilioValidatorService: CustomTwilioValidatorService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.validationStatus$.pipe().subscribe((isValid) => {
        if (isValid && this.removeAddress) {
          this.deleteAddress();
        }
      })
    );
    this.showDeleteMessage = this.removeAddress;
  }

  closeModal(reason: string) {
    this.modalService.closeActiveModal(reason);
    this.customAddressOrchestratorService.setAddNewMode(false);
    this.customAddressOrchestratorService.setEditMode(false);
  }

  deleteAddress() {
    this.customPromeclubAddressService.deleteAddress(this.id).subscribe(() => {
      this.customAddressOrchestratorService.setDeleted(true);
      this.removeAddress = false;
    });
  }

  openTwilioModal() {
    this.showDeleteMessage = false;
    this.customPromeclubAddressService.submitValidation();
  }

  ngOnDestroy(): void {
    this.customAddressOrchestratorService.setAddNewMode(false);
    this.customAddressOrchestratorService.setDeleted(false);
    this.customAddressOrchestratorService.setEditMode(false);
    this.customTwilioValidatorService.setValidationStatus('');
    this.subscription.unsubscribe();
  }
}
