import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomLoadingSpinnerService {
  spinnerState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentProductCode$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );

  loginInProgress: boolean = false;

  showSpinner(code: string) {
    this.spinnerState$.next(true);
    this.currentProductCode$.next(code);
  }

  hideSpinner() {
    this.spinnerState$.next(false);
  }

  getSpinnerState(): Observable<boolean> {
    return this.spinnerState$.pipe(map((spinnerState) => spinnerState));
  }

  getCurrentProductCode(): Observable<string> {
    return this.currentProductCode$.asObservable();
  }
}
