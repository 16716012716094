import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomTalleresService 
{
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService
  ) {}

  getBrands(): Observable<any> {
    const url = this.occEndpointService.buildUrl('getTalleresBrands');
    return this.http.get(url);
  }
  findTalleres(name: string, brands: string[]): Observable<any> {
    const url = this.occEndpointService.buildUrl('findTalleres', {
      urlParams: {
        name: name,
        brands: brands.join("#"),
      },
    });
    return this.http.get(url);
  }
}