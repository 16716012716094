<ng-container *ngIf="showTitle">
  <app-custom-title-my-account [title]="'orderDetails.orderTitle' | cxTranslate" [routerlink]="{ cxRoute: 'orders' }"></app-custom-title-my-account>
</ng-container>


<ng-container *ngIf="{order: order$ | async, pageCurrent: pageCurrent$ | async} as data">
  <app-custom-order-overview [order]="data.order">
    <app-custom-order-detail-actions actions *ngIf="data.pageCurrent === 'order' "></app-custom-order-detail-actions>
  </app-custom-order-overview>
</ng-container>
