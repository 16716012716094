import { Injectable } from '@angular/core';
import { Category, OrderEntry } from '@spartacus/core';
import {
  ActionField,
  AddToCart,
  CheckoutEvent,
  CustomGTMEvent,
  CustomGTMProduct,
  EventType,
  ProductClick,
  ProductGTM,
  ProductQuantityCustom,
  PromotioBannerViewEvent,
  Promotions,
  PromoViewEvent,
  RemoveFromCart,
  ViewProducts,
} from '../../cms-components/product/model/custom-gtmProduct.model';
import { CustomGtmDatalayerService } from './custom-gtm-datalayer.service';

@Injectable({
  providedIn: 'root',
})
export class CustomGtmEcommerceDatalayerService {
  constructor(private dataLayer: CustomGtmDatalayerService) {}

  pageIdClickProduct: string;

  //product
  productClickEvent(product: any, position?: number, pageId?: string): void {
    this.pageIdClickProduct = pageId!;
    let productClickEvent: CustomGTMEvent;
    let ecommerce: ProductClick;
    if (!!product) {
      ecommerce = {
        currency: 'USD',
        item_list_name: { list: pageId },
        items: [
          this.buildProductData(
            product,
            0,
            product.price?.value,
            product?.numberOfReviews,
            position,
            product?.badge,
            product?.previousPrice?.value,
            pageId
          ),
        ],
      };
      productClickEvent = {
        event: EventType.productClick,
        ...ecommerce,
      };
      this.dataLayer.pushEvent(productClickEvent);
    }
  }
  viewProductEvent(products: any, pageId?: string): void {
    let viewProductEvent: CustomGTMEvent;
    let ecommerce: ViewProducts;
    if (!!products) {
      (ecommerce = {
        currency: 'USD',
        items: products.map((product: any, index: number) => {
          return this.buildProductData(
            product,
            undefined,
            product?.price?.value,
            undefined,
            index,
            product.badge,
            undefined,
            pageId
          );
        }),
      }),
        (viewProductEvent = {
          event: EventType.productImpression,
          ...ecommerce,
        });

      this.dataLayer.pushEvent(viewProductEvent);
    }
  }
  transActionEvent(
    products: any,
    id?: string,
    affiliation?: string,
    revenue?: number,
    tax?: number,
    shipping?: number,
    coupon?: string,
    discounts?: number
  ): void {
    let purchaseEvent: CustomGTMEvent;
    let ecommerce: CheckoutEvent;
    if (!!products) {
      ecommerce = {
        currency: 'USD',
        transaction_id: id,
        value: revenue,
        tax: tax || undefined,
        shipping: shipping || undefined,
        coupon: coupon || '',
        discount: discounts || 0,
        items: products.map((product: any) => {
          return this.buildProductData(
            product.product,
            product.quantity,
            product.totalPrice.value,
            undefined,
            undefined,
            product.product.badge
          );
        }),
      };
      purchaseEvent = {
        event: EventType.transaction,
        ...ecommerce,
      };

      this.dataLayer.pushEvent(purchaseEvent);
    }
  }
  detailEvent(product: any, position?: number, pageId?: string): void {
    let detailEvent: CustomGTMEvent;
    let ecommerce: ProductClick;
    if (!!product) {
      ecommerce = {
        currency: 'USD',
        item_list_name: { list: pageId },
        items: [
          this.buildProductData(
            product,
            0,
            product.price?.value,
            product?.numberOfReviews,
            position,
            product?.badge,
            product?.previousPrice?.value,
            this.pageIdClickProduct
          ),
        ],
      };
      detailEvent = {
        event: EventType.productDetails,
        ...ecommerce,
      };
      this.dataLayer.pushEvent(detailEvent);
    }
  }

  //promotion
  promotionViewEvent(promotions: any) {
    let ecommerce: PromoViewEvent;
    let promoEvent: CustomGTMEvent;

    if (!!promotions && promotions.length > 0) {
      ecommerce = {
        items: promotions.map((promotion: any, index: number) => {
          return {
            promotion_id: promotion?.uid,
            promotion_name: promotion?.headline,
            creative_name: promotion?.creative,
            creative_slot: `${index + 1}`,
          };
        }),
      };
      promoEvent = {
        event: EventType.promotionView,
        ...ecommerce,
      };
      this.dataLayer.pushEvent(promoEvent);
    }
  }

  bannerClickEvent(promotion: any, index: number): void {
    let productClickEvent: CustomGTMEvent;
    let ecommerce: PromotioBannerViewEvent;
    let promotions: any[] = [];
    if (!!promotion) {
      promotions.push(
        (promotion = {
          promotion_id: promotion?.uid,
          promotion_name: promotion?.headline,
          creative_name: null,
          creative_slot: `${index + 1}`,
        })
      ),
        (ecommerce = {
          items: promotions,
        });
      productClickEvent = {
        event: EventType.promotionClick,
        ...ecommerce,
      };
      this.dataLayer.pushEvent(productClickEvent);
    }
  }

  //checkout
  checkoutEvent(
    products: any,
    position?: number,
    actionField?: ActionField
  ): void {
    let checkoutEvent: CustomGTMEvent;
    let ecommerce: CheckoutEvent;
    if (!!products) {
      ecommerce = {
        currency: 'USD',
        value: actionField?.revenue,
        tax: actionField?.tax,
        coupon: actionField?.coupon || '',
        discount: actionField?.discounts || 0,
        items: products.map((product: any) => {
          return this.buildProductData(
            product.product,
            product.quantity,
            product.totalPrice.value,
            undefined,
            position,
            product.product.badge
          );
        }),
      };
      let event;
      switch (actionField?.step) {
        case 1:
          event = EventType.checkout;
          break;
        case 2:
          event = EventType.addShippingInfo;
          break;
        case 3:
          event = EventType.addPaymentInfo;
          break;
        default:
          event = EventType.checkout;
          break;
      }
      checkoutEvent = {
        event,
        ...ecommerce,
      };

      this.dataLayer.pushEvent(checkoutEvent);
    }
  }

  //cart
  addToCartEvent(
    product: ProductGTM,
    quantity: number,
    entry: OrderEntry,
    idPage: string
  ): void {
    let cartEvent: CustomGTMEvent;
    let ecommerce: AddToCart;
    if (!!product) {
      ecommerce = {
        currency: product?.price?.currencyIso || entry?.totalPrice?.currencyIso,
        value: product?.price?.value || entry?.totalPrice?.value,
        items: [
          this.buildProductData(
            product,
            quantity,
            entry?.basePrice?.value!,
            entry?.product?.numberOfReviews,
            undefined,
            undefined,
            undefined,
            idPage
          ),
        ],
      };
      cartEvent = {
        event: EventType.addToCart,
        ...ecommerce,
      };

      this.dataLayer.pushEvent(cartEvent);
    }
  }

  removeFromCartEvent(entry: OrderEntry): void {
    let cartEvent: CustomGTMEvent;
    let ecommerce: RemoveFromCart;
    if (!!entry) {
      ecommerce = {
        currency: entry?.totalPrice?.currencyIso,
        value: entry?.totalPrice?.value,
        items: [
          this.buildProductData(
            entry?.product!,
            entry?.quantity!,
            entry?.basePrice?.value,
            entry?.product?.numberOfReviews
          ),
        ],
      };
      cartEvent = {
        event: EventType.removeFromCart,
        ...ecommerce,
      };
      this.dataLayer.pushEvent(cartEvent);
    }
  }

  addToCartEventQuantity(
    product: ProductQuantityCustom,
    clickedFrom: string
  ): void {
    let cartEvent: CustomGTMEvent;
    let ecommerce: AddToCart;
    if (!!product) {
      ecommerce = {
        currency: product?.totalPrice?.currencyIso,
        value: product?.totalPrice?.value,
        items: [
          this.buildProductData(
            product?.product!,
            product?.quantity,
            product.basePrice?.value,
            product?.product?.averageRating,
            undefined,
            undefined,
            undefined,
            clickedFrom
          ),
        ],
      };
      cartEvent = {
        event: EventType.addToCart,
        ...ecommerce,
      };
      this.dataLayer.pushEvent(cartEvent);
    }
  }

  removeFromCartEventQuantity(
    product: ProductQuantityCustom,
    quantity?: number,
    clickedFrom?: string
  ): void {
    let cartEvent: CustomGTMEvent;
    let ecommerce: RemoveFromCart;
    if (!!product) {
      ecommerce = {
        currency: product?.totalPrice?.currencyIso,
        value: product?.totalPrice?.value,
        items: [
          this.buildProductData(
            product?.product!,
            quantity,
            product.basePrice?.value,
            product?.product?.averageRating,
            undefined,
            undefined,
            undefined,
            clickedFrom
          ),
        ],
      };
      cartEvent = {
        event: EventType.removeFromCart,
        ...ecommerce,
      };
      this.dataLayer.pushEvent(cartEvent);
    }
  }

  checkRemoveOrAddEvent(
    itemPrevious: any,
    itemsActually: any,
    clickedFrom: string
  ) {
    if (itemsActually && itemPrevious !== undefined) {
      itemsActually?.forEach((items: any, index: number) => {
        if (itemPrevious[index]?.quantity < itemsActually[index].quantity) {
          this.addToCartEventQuantity(items, clickedFrom);
        } else if (
          itemPrevious[index]?.quantity > itemsActually[index].quantity
        ) {
          this.removeFromCartEventQuantity(
            items,
            itemPrevious[index]?.quantity - itemsActually[index].quantity,
            clickedFrom
          );
        }
      });
    }
  }

  buildProductData(
    product: ProductGTM,
    qty?: number | undefined,
    price?: number,
    reviews?: number | undefined,
    position?: number,
    badge?: string,
    previousPrice?: number,
    list?: string | undefined
  ): CustomGTMProduct {
    let gtmProductData: CustomGTMProduct = {
      item_name: product?.name || '',
      item_id: product?.code || '',
      price: product?.price?.value || price || undefined,
      item_brand: product?.brand?.name || '',
      item_category: this.decodeCategory(product?.categories!),
      item_variant: null,
      item_list_name: list || undefined,
      index: position,
      quantity: qty || undefined,
      dimension1: this.checkHaveStock(product?.stock?.stockLevelStatus!),
      dimension2: this.checkHaveStock(product?.stock?.stockLevelStatus!)
        ? badge || null
        : null,
      dimension3: previousPrice || null,
      dimension4: this.checkHaveReview(product?.numberOfReviews!, reviews),
      dimension5: product?.averageRating,
    };
    return gtmProductData;
  }
  decodeCategory(category: Category[]): string {
    let deleteLastCategory = category?.slice(0, 3);
    let categoryMap = deleteLastCategory?.map((p) => p.name?.concat('/'));
    let categoryToString = categoryMap?.join('');
    return categoryToString;
  }
  checkHaveStock(hasStock: string) {
    if (hasStock === 'inStock') {
      return true;
    } else {
      return false;
    }
  }

  checkHaveReview(reviewProduct: number, review: number | undefined) {
    if (reviewProduct || review) {
      return true;
    } else {
      return false;
    }
  }
}
