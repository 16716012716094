<div
  class="custom-title-account"
  [ngClass]="{ 'custom-title-account__less-margin': lessMargin,
  'custom-title-account__with-router': routerlink || isBackActive}"
>
  <a *ngIf="routerlink && !params" [routerLink]="routerlink | cxUrl">
    <cx-icon class="fas fa-chevron-left"></cx-icon>
  </a>
  <a
    *ngIf="routerlink && params"
    [routerLink]="{ cxRoute: routerlink, params: params } | cxUrl"
  >
    <cx-icon class="fas fa-chevron-left"></cx-icon>
  </a>
  <a *ngIf="isBackActive" (click)="onBack()">
    <cx-icon class="fas fa-chevron-left"></cx-icon>
  </a>
  <h3>
    {{ title }}
  </h3>
</div>
