<ng-container *ngIf="cart">
  <div class="important-message" *ngIf="promesaCartUpdatedPriceMessageComponentData$ | async as message">
    <div class="important-message__icon">
      <cx-icon class="fas fa-exclamation-circle"></cx-icon>
    </div>
    <div class="important-message__content">
      <div class="important-message__content__msj">
        {{message?.importantMessages}}
      </div>
      <div class="important-message__content__msj2">
        <ng-container
          *ngIf="
            cart.modifiedPreviousPriceMap.length === 1;
            else updatedProducts
          "
        >
          {{cart.modifiedPreviousPriceMap.length + " " + message?.updatedProduct}}
        </ng-container>
        <ng-template #updatedProducts>
          {{cart.modifiedPreviousPriceMap.length + " " + message?.updatedProducts}}
        </ng-template>
      </div>
      <div class="important-message__content__msj3">
        {{message?.text}}
      </div>
    </div>
  </div>
</ng-container>
