import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { AddToSavedCartComponent } from '@spartacus/cart/saved-cart/components';
import {
  ActiveCartService,
  AuthService,
  Cart,
  MultiCartService,
  RoutingService,
  UserIdService,
} from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { take } from 'rxjs/operators';
import { CustomNavigationService } from '../../navigation/services/custom-navigation.service';
import { CustomCartActionsModalService } from '../services/custom-cart-actions-modal.service';
import { CustomBreakpointService } from '../../../services/custom-breakpoint.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-add-to-saved-cart',
  templateUrl: './custom-add-to-saved-cart.component.html',
  styleUrls: ['./custom-add-to-saved-cart.component.scss'],
})
export class CustomAddToSavedCartComponent
  extends AddToSavedCartComponent
  implements OnInit
{
  protected userId: string;
  private readonly NEW_CART_CALLER = 'NEW_CART';
  @ViewChild('newCartElement') newCartElement: ElementRef;
  isInPromeclub = this.service.isInPromeClub();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    protected activeCartService: ActiveCartService,
    protected authService: AuthService,
    protected routingService: RoutingService,
    protected vcr: ViewContainerRef,
    protected launchDialogService: LaunchDialogService,
    protected multiCartService: MultiCartService,
    protected userIdService: UserIdService,
    protected service: CustomNavigationService,
    protected customCartActionsModalService: CustomCartActionsModalService,
    protected customBreakpointService: CustomBreakpointService
  ) {
    super(
      activeCartService,
      authService,
      routingService,
      vcr,
      launchDialogService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.userIdService
        ?.getUserId()
        .subscribe((userId) => (this.userId = userId))
    );
  }

  openDialog(cart: Cart) {
    if (this.launchDialogService) {
      const dialog = this.launchDialogService.openDialog(
        this.NEW_CART_CALLER,
        this.newCartElement,
        this.vcr,
        { cart }
      );
      if (dialog) {
        this.subscription.add(dialog.pipe(take(1)).subscribe());
      }
    }
  }

  removeCart(cartId: string) {
    this.customCartActionsModalService.openRemoveCartDialog(
      cartId,
      this.userId
    );
  }
}
