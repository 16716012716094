<ng-container
  *ngIf="{ order: order$ | async, isMobile: isMobile$ | async } as data"
>
  <div class="custom-order-cancel">
    <app-custom-title-my-account
      [title]="'orderDetails.cancellationAndReturn.cancelTitle' | cxTranslate"
      [routerlink]="{
        cxRoute: 'orderDetails',
        params: data.order
      }"
    ></app-custom-title-my-account>
    <label class="custom-order-cancel__subtitle"
      >{{ data.isMobile ? ('orderDetails.cancellationAndReturn.detailCancelMobile' | cxTranslate) :
      ('orderDetails.cancellationAndReturn.detailCancel' | cxTranslate )}}
    </label>

    <app-custom-order-detail-shipping
      [showTitle]="false"
    ></app-custom-order-detail-shipping>
    <div class="custom-order-cancel__details">
      <app-custom-order-detail-items></app-custom-order-detail-items>
      <app-custom-order-detail-totals></app-custom-order-detail-totals>
    </div>
  </div>
  <app-custom-amend-order-actions
    *ngIf="data.order?.code"
    [orderCode]="data.order?.code"
    backRoute="orders"
    forwardRoute="orderCancelConfirmation"
  ></app-custom-amend-order-actions>
</ng-container>
