export const customPromeclubAddress = {
  promeclubAddress: {
    promeclubAddress: {
      type: 'Tipo: ',
      typeError: '* Debe seleccionar un tipo.',
      province: 'Provincia: ',
      provinceError: '* Debe seleccionar una provincia.',
      city: 'Ciudad: ',
      cityError: '* Debe seleccionar una ciudad.',
      address: 'Dirección: ',
      addressError: '* Dirección requerida, máximo 50 caracteres.',
      reference: 'Referencia: ',
      referenceError: '* Referencia requerida, máximo 20 caracteres.',
      selectPlaceholder: 'Seleccionar',
      addressPlaceholder: 'Ingresar dirección',
      referencePlaceholder: 'Ingresar referencia',
      BEACH_HOUSE: 'Casa de playa',
      HOUSE: 'Casa',
      COTTAGE: 'Casa de campo',
      OTHER: 'Otro',
      OFFICE: 'Oficina',
      cancel: 'Cancelar',
      save: 'Guardar dirección',
      addSuccesful: '<p>Tu nueva dirección <b>{{type}}</b><p><p>ha sido agregada con éxito</p>',
      deleteSuccesful: '<p>Tu dirección <b>{{type}}</b><p><p>ha sido eliminada con éxito</p>',
      editSuccesful: '<p>Tu dirección ha sido</p><p>modificada con éxito</p>',
      close: 'Cerrar',
      deleteAddress: '<p>Deseas eliminar de forma permanente</p><p>tu dirección <b>{{type}}</b></p>',
      delete: 'Eliminar',
      saveChanges: 'Guardar cambios'
    }

  },
};
