<ng-container *ngIf="rappels">
  <div class="cx-rebate-container">
    <ng-container *ngIf="isMobile; else templateTable">
      <ng-container *ngIf="open">
        <div
          class="cx-rebate-container__mobile-container"
          *ngFor="let rebate of rappels; index as i"
        >
          <!-- por ahora solo toma el primer rappel -->
          <ng-container *ngIf="i < 1">
            <h6 class="campaign-title">{{ rebate.campaign }}</h6>
            <span>{{ "customRebate.secondColumn" | cxTranslate }}</span>
            <div
              *ngIf="rebate.scales.length > 0 && rebate.totalFacturado"
              class="d-flex progress-bar-numbers"
            >
              <div
                class="progress-bar-numbers-container d-flex flex-column align-items-center"
                *ngFor="let scale of rebate.scales"
                [ngClass]="scale.achieved && 'progress-bar-numbers__blue'"
              >
                <span>{{ scale.value }}%</span>
                <div
                  class="div-bar-progress"
                  [ngClass]="scale.achieved && 'div-bar-progress__blue'"
                ></div>
                <span>${{ scale.fromValue }}</span>
              </div>
            </div>
            <div
              *ngIf="rebate.scales.length > 0 && rebate.totalFacturado"
              class="d-flex"
              style="height: 0"
            >
              <ngb-progressbar
                class="d-flex end-progress-bar"
                [value]="
                  valueMinProgressBar(rebate.scales, rebate.totalFacturado)
                "
              >
              </ngb-progressbar>
              <ngb-progressbar
                class="middle-progress-bar"
                [value]="valueProgressBar(rebate.scales, rebate.totalFacturado)"
              >
              </ngb-progressbar>
              <ngb-progressbar
                class="d-flex end-progress-bar"
                [value]="valueMaxProgressBar(rebate.scales)"
              >
              </ngb-progressbar>
            </div>

            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column">
                <span>{{ "customRebate.thirdColumn" | cxTranslate }}</span>
                <span class="total-number">{{
                  rebate.totalFacturado | customCurrency
                }}</span>
              </div>
              <div class="d-flex flex-column">
                <span>{{ "customRebate.fourthColumn" | cxTranslate }}</span>
                <span class="rebate-number">{{
                  rebate.expectedBenefit | customCurrency
                }}</span>
              </div>
            </div>

            <div class="d-flex align-items-center mt-3">
              <span>{{ "customRebate.fifthColumn" | cxTranslate }}</span>
              <cx-icon
                [type]="rebate.active ? 'CALENDAR_CHECK' : 'CALENDAR_MINUS'"
              ></cx-icon>

              <span
                [ngClass]="
                  rebate.active && 'cx-rebate-container__active-rebate'
                "
                >{{ rebate.status }}</span
              >
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #templateTable>
      <table class="table table-hover custom-rebate-table-pdp">
        <thead>
          <tr>
            <th scope="col">{{ "customRebate.firstColumn" | cxTranslate }}</th>
            <th scope="col">{{ "customRebate.secondColumn" | cxTranslate }}</th>
            <th scope="col">{{ "customRebate.thirdColumn" | cxTranslate }}</th>
            <th scope="col">{{ "customRebate.fourthColumn" | cxTranslate }}</th>
            <th scope="col">{{ "customRebate.fifthColumn" | cxTranslate }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let rebate of rappels; index as i">
            <ng-container *ngIf="i < 1">
              <td class="campaign-title">
                {{ rebate.campaign }}
              </td>
              <td>
                <div
                  *ngIf="rebate.scales.length > 0 && rebate.totalFacturado"
                  class="d-flex progress-bar-numbers"
                >
                  <div
                    class="progress-bar-numbers-container d-flex flex-column justify-content-between align-items-center"
                    *ngFor="let scale of rebate.scales"
                    [ngClass]="scale.achieved && 'progress-bar-numbers__blue'"
                  >
                    <span>{{ scale.value }}%</span>
                    <div
                      class="div-bar-progress"
                      [ngClass]="scale.achieved && 'div-bar-progress__blue'"
                    ></div>
                    <span>${{ scale.fromValue }}</span>
                  </div>
                </div>
                <div
                  *ngIf="rebate.scales.length > 0 && rebate.totalFacturado"
                  class="d-flex"
                >
                  <ngb-progressbar
                    class="d-flex end-progress-bar"
                    [value]="
                      valueMinProgressBar(rebate.scales, rebate.totalFacturado)
                    "
                  >
                  </ngb-progressbar>
                  <ngb-progressbar
                    class="middle-progress-bar"
                    [value]="
                      valueProgressBar(rebate.scales, rebate.totalFacturado)
                    "
                  >
                  </ngb-progressbar>
                  <ngb-progressbar
                    class="d-flex end-progress-bar"
                    [value]="valueMaxProgressBar(rebate.scales)"
                  >
                  </ngb-progressbar>
                </div>
              </td>
              <td class="total-number">
                {{ rebate.totalFacturado | customCurrency }}
              </td>
              <td class="rebate-number">
                {{ rebate.expectedBenefit | customCurrency }}
              </td>
              <td>
                <div class="d-flex">
                  <cx-icon
                    [type]="rebate.active ? 'CALENDAR_CHECK' : 'CALENDAR_MINUS'"
                  ></cx-icon>
                  <span
                    class="cx-rebate-container__status-text"
                    [ngClass]="
                      rebate.active && 'cx-rebate-container__active-rebate'
                    "
                    >{{ rebate.status }}</span
                  >
                </div>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </div>
</ng-container>
