<ng-container
  *ngIf="{
    navigation: navigationNode$ | async,
    isMobile: isMobile$ | async,
    showLinks: showLinks$ | async
  } as data"
>
  <div class="footer-navigation" *ngIf="!!data?.navigation?.children">
    <ng-container *ngFor="let item of data.navigation.children; index as i">
      <nav
        class="footer-navigation__item"
        [ngClass]="{
          'footer-navigation__item-promeclub': isPromeclub,
          expanded: data.showLinks[i] && data.isMobile,
          hidden: !item?.children && isPromeclub && data.isMobile
        }"
        *ngIf="!data.isMobile || (data.isMobile && i != 0)"
      >
        <cx-generic-link
          [url]="item.urlLink"
          *ngIf="i == 0 && !!item.media"
          class="footer-navigation__item-logo"
          (click)="footerEventClick('logo_promesa')"
        >
          <img
            [src]="getImageUrl(item?.media?.url)"
            [alt]="item?.media?.altText"
            loading="lazy"
          />
        </cx-generic-link>
        <p
          class="footer-navigation__item-title"
          *ngIf="i != 0 && item.title"
          [ngClass]="{ hidden: !item?.children }"
          (click)="toggleVisibility(i, data.isMobile)"
        >
          {{ item?.title }}
        </p>
        <nav
          class="footer-navigation__item-links"
          *ngIf="item.children"
          [ngClass]="{
            address: i == data?.navigation?.children?.length - 1,
            hideLinks: !data.showLinks[i] && data.isMobile
          }"
        >
          <ng-container *ngFor="let link of item.children">
            <ng-container
              *ngIf="
                PromesaSection.AUTOMOTRIZ == link.linkName ||
                PromesaSection.FERRETERIA == link.linkName
              "
            >
              <cx-generic-link
                (click)="footerEventClick(link?.linkName); navigateToNode(link)"
              >
                <cx-icon
                  [class]="link?.styleClasses"
                  *ngIf="!!link?.styleClasses"
                ></cx-icon>
                {{ link?.linkName | slice : 0 : 39 }}
                {{ link?.linkName?.length >= 38 ? "..." : "" }}
              </cx-generic-link>
            </ng-container>
            <ng-container
              *ngIf="
                !(
                  PromesaSection.AUTOMOTRIZ == link.linkName ||
                  PromesaSection.FERRETERIA == link.linkName
                )
              "
            >
              <cx-generic-link
                [url]="link?.url"
                (click)="footerEventClick(link?.linkName)"
                *ngIf="link?.name !== 'footerContactAddressLink'; else address"
              >
                <cx-icon
                  [class]="link?.styleClasses"
                  *ngIf="!!link?.styleClasses"
                ></cx-icon>
                {{ link?.linkName | slice : 0 : 39 }}
                {{ link?.linkName?.length >= 38 ? "..." : "" }}
              </cx-generic-link>
            </ng-container>
            <ng-template #address>
              <cx-generic-link
                [ngClass]="{
                  'footer-navigation__item-links-address': !data.isMobile
                }"
                (click)="footerEventClick()"
              >
                <cx-icon
                  [class]="link.styleClasses"
                  *ngIf="!!link?.styleClasses"
                ></cx-icon>
                <cx-generic-link
                  [url]="link?.url"
                  (click)="footerEventClick(link?.linkName)"
                >
                  {{ link?.linkName }}
                </cx-generic-link>
              </cx-generic-link>
            </ng-template>
          </ng-container>
        </nav>
      </nav>
    </ng-container>
  </div>
  <ng-container *ngIf="footerData$ | async as footer"> </ng-container>
</ng-container>
