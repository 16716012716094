import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFlowRoutesService, AuthRedirectService, AuthRedirectStorageService, RoutingService } from '@spartacus/core';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomAuthRedirectService extends AuthRedirectService{

  constructor(
    protected routing: RoutingService,
    protected router: Router,
    protected authRedirectStorageService: AuthRedirectStorageService,
    protected authFlowRoutesService: AuthFlowRoutesService
  ) {
    super(routing, router, authRedirectStorageService, authFlowRoutesService);
    super.init();
   }

   redirect() {
    this.authRedirectStorageService
      .getRedirectUrl()
      .pipe(take(1))
      .subscribe((redirectUrl) => {
        if (redirectUrl === undefined) {
          this.routing.go('/');
        }else if(redirectUrl.includes('promeclubSite')){
          const url = window.location.origin + '/' + redirectUrl;
          this.clearRedirectUrl();
          window.open(url, '_self');
          return;
        } else {
          this.routing.goByUrl(redirectUrl);
        }
        this.clearRedirectUrl();
      });
  }
}
