import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomLoginComponent } from './custom-login.component';
import {
  AuthService,
  CmsConfig,
  ConfigModule,
  GlobalMessageService,
  I18nModule,
  UrlModule,
  WindowRef,
} from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { IconModule, PageSlotModule } from '@spartacus/storefront';
import { CustomModalModule } from '../../shared/components/custom-modal/custom-modal.module';
import { CustomLoginFormModule } from '../custom-login-form/custom-login-form.module';
import { UserAccountModule } from '@spartacus/user';
import { LoginFormComponentService } from '@spartacus/user/account/components';
import { CustomPointsHeaderModule } from '../../custom/components/custom-points-header/custom-points-header.module';
import { CustomMyAccountModule } from '../../my-account/components/custom-my-account/custom-my-account.module';

@NgModule({
  declarations: [CustomLoginComponent],
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    IconModule,
    PageSlotModule,
    UrlModule,
    CustomModalModule,
    CustomLoginFormModule,
    UserAccountModule,
    CustomPointsHeaderModule,
    CustomMyAccountModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        LoginComponent: {
          component: CustomLoginComponent,
        },
      },
    }),
  ],
  providers: [
    {
      provide: LoginFormComponentService,
      useClass: LoginFormComponentService,
      deps: [AuthService, GlobalMessageService, WindowRef],
    },
  ],
})
export class CustomLoginModule {}
