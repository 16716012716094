import { Component, Input, OnInit } from '@angular/core';
import { CmsComponent, CmsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

@Component({
  selector: 'app-custom-payment-cash-table',
  templateUrl: './custom-payment-cash-table.component.html',
  styleUrls: ['./custom-payment-cash-table.component.scss']
})
export class CustomPaymentCashTableComponent implements OnInit {

  bankItems$: Observable<Observable<CmsComponent | null>[]>;
  @Input() showTitleTable: boolean = true;

  constructor(private cmsService: CmsService) { }

  ngOnInit(): void {
    this.bankItems$ = this.cmsService.getComponentData<any>('promesaBankInfoComponent').pipe(
      pluck('promesaBankInfoDetail'),
      map((banks: string) => banks?.split(' ') ?? []),
      map(codes => codes.map(code => this.cmsService.getComponentData(code)))
    );
  }

}
