import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  CheckoutDeliveryFacade,
  CheckoutPaymentFacade
} from '@spartacus/checkout/root';
import { CmsService, Order, TranslationService } from '@spartacus/core';
import { Card, OrderOverviewComponent } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, pluck } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-order-overview',
  templateUrl: './custom-order-overview.component.html',
  styleUrls: ['./custom-order-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CustomOrderOverviewComponent
  extends OrderOverviewComponent
  implements OnInit
{
  retiroCD = 'Retiro en Centro de Distribución';
  nameCD = 'Centro de Distribución Guayaquil';
  descriptionCD = 'Av. Terminal Pascuales S/N junto a la gasolinera Terpel';
  pageCurrent$: Observable<string | undefined> = this.cmsService
    .getCurrentPage()
    .pipe(pluck('pageId'));
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  @Input() isClaim: boolean;

  constructor(
    protected translation: TranslationService,
    protected checkoutDeliveryService: CheckoutDeliveryFacade,
    protected checkoutPaymentService: CheckoutPaymentFacade,
    private cmsService: CmsService,
    private customBreakpointService: CustomBreakpointService
  ) {
    super(translation);
  }

  ngOnInit(): void {}
  getOrderTotalCardContent(total: string): Observable<Card> {
    return this.translation.translate('orderDetails.total').pipe(
      filter(() => Boolean(total)),
      map((textTitle) => {
        return {
          title: textTitle,
          text: [total],
        };
      })
    );
  }

  getOrderCancellationCurrentDateCardContent(
    order: any,
    isMobile: boolean
  ): Observable<Card> {
    let isoDate = order?.formattedCancelDate;
    if (isoDate == null) isoDate = '-';
    return combineLatest([
      isMobile
        ? this.translation.translate('orderDetails.placedOffMobile')
        : this.translation.translate('orderDetails.placedOff'),
    ]).pipe(
      filter(() => Boolean(isoDate)),
      map(([textTitle]) => {
        return {
          title: textTitle,
          text: [isoDate],
        };
      })
    );
  }
  getClaimDetailCurrentDateCardContent(date: string): Observable<Card> {
    return this.translation.translate('orderDetails.claim.dateCreation').pipe(
      map((textTitle) => {
        return {
          title: textTitle,
          text: [date],
        };
      })
    );
  }

  getInformationPaidCardContent(order: any): Observable<Card> {
    return this.translation.translate('orderDetails.informationPaid').pipe(
      filter(() => Boolean(order)),
      map((textTitle) => {
        return {
          title: textTitle,
          text: [
            order.b2bUnit?.businessName!,
            order.b2bUnit?.ruc,
            order.paymentAddress
              ? order.paymentAddress?.line1 +
                ' ' +
                (order.paymentAddress?.line2 ? order.paymentAddress?.line2 : '')
              : null,
            order.paymentAddress?.region?.name,
            order.b2bUnit?.phone,
          ],
        };
      })
    );
  }

  getDeliveryModeCardContent(order: Order): Observable<Card> {
    return combineLatest([
      this.translation.translate('orderDetails.shippingMethod'),
      this.translation.translate('checkoutProgress.myAdress'),
      this.translation.translate('checkoutProgress.centerDistribution'),
    ]).pipe(
      map(([textTitle, subtitleMyAddress, subtitleCenterD]) => ({
        title: textTitle,
        textBold:
          order.deliveryMode?.code === 'pickup_cd'
            ? subtitleCenterD
            : subtitleMyAddress,
        text: [
          order.deliveryMode?.code === 'pickup_cd'
            ? this.nameCD
            : order.deliveryAddress?.firstName!,
          order.deliveryMode?.code === 'pickup_cd'
            ? this.descriptionCD
            : order.deliveryAddress?.line1! +
              ' ' +
              (order.deliveryAddress?.line2
                ? order.deliveryAddress?.line2
                : ''),
          order.deliveryMode?.code === 'pickup_cd'
            ? ''
            : order.deliveryAddress?.region?.name!,
        ],
      }))
    );
  }

  getPaymentInfoContent(order: any): Observable<Card> {
    return this.translation.translate('paymentTypes.title').pipe(
      filter(() => Boolean(order)),
      map((textTitle) => ({
        title: textTitle,
        text: [
          order.paymentMode?.name,
          order.paymentMode?.code == 'creditLinePaymentMode'
            ? order.paymentCondition!
            : '',
        ],
      }))
    );
  }

  getOrderStatusDevolution(status: string): Observable<Card> {
    return combineLatest([
      this.translation.translate('orderDetails.status'),
      this.translation.translate('returnRequestList.statusDisplay_' + status),
    ]).pipe(
      map(([textTitle, textStatus]) => ({
        title: textTitle,
        text: [textStatus],
      }))
    );
  }

  getOrderReturnDate(isoDate: string, isMobile: boolean): Observable<Card> {
    return combineLatest([
      isMobile
        ? this.translation.translate('returnRequest.creationDateMobile')
        : this.translation.translate('returnRequest.creationDate'),
    ]).pipe(
      filter(() => Boolean(isoDate)),
      map(([textTitle]) => {
        return {
          title: textTitle,
          text: [isoDate],
        };
      })
    );
  }

  splitStringCode(associatedTo: string): string {
    const newString = associatedTo.split(' ')[1];
    const length = newString.length;
    return newString.slice(0, length - 1);
  }
}
