import { Component, OnDestroy } from '@angular/core';
import { ActiveCartService, CartModification } from '@spartacus/core';
import {
  OrderDetailActionsComponent,
  OrderDetailsService,
} from '@spartacus/order/components';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { finalize, take, takeUntil, tap } from 'rxjs/operators';
import { CustomLoadingSpinnerService } from 'src/app/cms-components/custom/services/custom-loading-spinner.service';
import { CustomReorderService } from '../../../services/custom-reorder.service';
import { CustomReorderDialogComponent } from '../custom-reorder/custom-reorder-dialog/custom-reorder-dialog.component';
import { CustomReorderInfoDialogComponent } from '../custom-reorder/custom-reorder-info-dialog/custom-reorder-info-dialog.component';
import { ORDER_STATE } from '../../custom-order-history/constansts/order-constansts';

@Component({
  selector: 'app-custom-order-detail-actions',
  templateUrl: './custom-order-detail-actions.component.html',
  styleUrls: ['./custom-order-detail-actions.component.scss'],
})
export class CustomOrderDetailActionsComponent
  extends OrderDetailActionsComponent
  implements OnDestroy
{
  cartModifications: CartModification[] | undefined;
  modalRef: ModalRef;
  private unsubscribe$ = new Subject<void>();
  ORDER_STATE = ORDER_STATE;
  constructor(
    orderDetailsService: OrderDetailsService,
    protected modalService: ModalService,
    private activeCartService: ActiveCartService,
    protected customLoadingSpinnerService: CustomLoadingSpinnerService,
    private customReorderService: CustomReorderService
  ) {
    super(orderDetailsService);
  }

  reorder(orderCode: string) {
    let modalInstance: any;
    this.activeCartService
      .getEntries()
      .pipe(
        take(1),
        tap((entries) => {
          if (entries.length > 0) {
            this.modalRef = this.modalService.open(
              CustomReorderInfoDialogComponent,
              {
                centered: true,
                size: 'lg',
                windowClass: 'reorderInfoDialog',
              }
            );
            modalInstance = this.modalRef.componentInstance;
            modalInstance.orderCode = orderCode;
          } else {
            this.customLoadingSpinnerService.showSpinner('');
            this.customReorderService
              .reorder(orderCode)
              .pipe(
                takeUntil(this.unsubscribe$),
                finalize(() => {
                  this.customLoadingSpinnerService.hideSpinner();
                })
              )
              .subscribe(() => {
                this.modalRef = this.modalService.open(
                  CustomReorderDialogComponent,
                  {
                    centered: true,
                    size: 'lg',
                    windowClass: 'reorderDialog',
                  }
                );
                modalInstance = this.modalRef.componentInstance;
                modalInstance.orderCode = orderCode;
              });
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
