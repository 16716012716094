import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-title-step-mobile',
  templateUrl: './custom-title-step-mobile.component.html',
  styleUrls: ['./custom-title-step-mobile.component.scss'],
})
export class CustomTitleStepMobileComponent {
  @Input() numberStep: number;
  @Input() titleDisabled: boolean = false;
}
