import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ICON_TYPE, ModalService } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-return-order-confirm-dialog',
  templateUrl: './custom-return-order-confirm-dialog.component.html',
  styleUrls: ['./custom-return-order-confirm-dialog.component.scss']
})
export class CustomReturnOrderConfirmDialogComponent implements OnInit {
  private ngbModalElement: HTMLElement;
  iconTypes = ICON_TYPE;
  orderCode: string;
  @ViewChild('dialogOrderReturn', { read: ElementRef })
  dialogOrderCancel: ElementRef;

  constructor(protected modalService: ModalService, @Inject(DOCUMENT) private document: Document,) { }

  ngOnInit(): void {
    this.ngbModalElement = <HTMLElement>(
      this.document.getElementsByClassName('modal-content')[0]
    );
  }

  dismissModal(reason?: any): void {
    this.modalService.dismissActiveModal(reason);
  }

}
