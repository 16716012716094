import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFloatingAddToCartComponent } from './custom-floating-add-to-cart.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomAddToCartModule } from '../custom-add-to-card/custom-add-to-cart.module';

@NgModule({
  declarations: [CustomFloatingAddToCartComponent],
  exports: [CustomFloatingAddToCartComponent],
  imports: [
    CommonModule,
    CustomAddToCartModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        FloatingAddToCartComponent: {
          component: CustomFloatingAddToCartComponent,
        },
      },
    }),
  ],
})
export class CustomFloatingAddToCartModule {}
