import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, ModalModule } from '@spartacus/storefront';
import { CustomModalGenericModule } from 'src/app/cms-components/custom/components/custom-modal/custom-modal-generic.module';
import { CustomSafeHtmlModule } from '../../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomQuoteCancelCheckoutDialogComponent } from './custom-quote-cancel-checkout/custom-quote-cancel-checkout-dialog.component';
import { CustomQuoteCheckoutInfoDialogComponent } from './custom-quote-checkout-info/custom-quote-checkout-info-dialog.component';
import { CustomQuoteCreateInfoComponent } from './custom-quote-create-info-dialog/custom-quote-create-info.component';
import { CustomQuoteInCourseDialogComponent } from './custom-quote-in-course-dialog/custom-quote-in-course-dialog.component';
import { CustomQuoteInfoDialogComponent } from './custom-quote-info-dialog/custom-quote-info-dialog.component';
import { CustomQuoteRejectDialogComponent } from './custom-quote-reject-dialog/custom-quote-reject-dialog.component';
import { CustomQuotesRemoveConfirmationDialogComponent } from './custom-quotes-remove-confirmation-dialog/custom-quotes-remove-confirmation-dialog.component';

@NgModule({
  declarations: [
    CustomQuoteInfoDialogComponent,
    CustomQuoteRejectDialogComponent,
    CustomQuotesRemoveConfirmationDialogComponent,
    CustomQuoteCheckoutInfoDialogComponent,
    CustomQuoteCancelCheckoutDialogComponent,
    CustomQuoteInCourseDialogComponent,
    CustomQuoteCreateInfoComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    UrlModule,
    ModalModule,
    ReactiveFormsModule,
    CustomSafeHtmlModule,
    CustomModalGenericModule
  ],
  exports: [
    CustomQuoteInfoDialogComponent,
    CustomQuoteRejectDialogComponent,
    CustomQuotesRemoveConfirmationDialogComponent,
    CustomQuoteCheckoutInfoDialogComponent,
    CustomQuoteCancelCheckoutDialogComponent,
    CustomQuoteInCourseDialogComponent,
    CustomQuoteCreateInfoComponent,
  ],
})
export class CustomQuotesSharedModule {}
