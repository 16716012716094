<ng-container *ngIf="{ value: isMobile$ | async } as isMobile">
  <h3 class="category-ui__title" *ngIf="isMobile.value">
    {{
      "common.categoryTitle." + (isPromeclub ? "categories" : currentSection)
        | cxTranslate
    }}
  </h3>
  <ng-container *ngFor="let child of node?.children">
    <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: 0 }">
    </ng-container>
  </ng-container>

  <!-- we generate links in a recursive manner -->
  <ng-template #nav let-node="node" let-depth="depth">
    <div class="separator" *ngIf="node.separator && isMobile.value"></div>
    <nav
      (click)="isMobile.value ? toggleOpen($event, node) : false"
      (mouseenter)="onMouseEnterCustom($event, node)"
      (keydown.space)="toggleOpen($event, node)"
      (keydown.esc)="back()"
    >
      <cx-generic-link
        *ngIf="
          node.url &&
            (!node.children || node.children?.length === 0) &&
            depth != 0;
          else heading
        "
        (click)="navigate(node.url, node, isMobile.value)"
        [url]="node?.url"
        [style]="node.styleAttributes"
        [class]="node.styleClasses"
      >
        <ng-container *ngIf="!isMobile.value && depth != 0">
          <div class="nav_image" *ngIf="showWrapper">
            <img
              *ngIf="node.categoryImage$ | async as image"
              [src]="baseUrl + '/' + image?.url"
              [alt]="node.title"
              loading="lazy"
              fetchPriority="low"
            />
          </div>
        </ng-container>
        <span class="node-title" *ngIf="!isMobile.value">
          {{ node.title }}
        </span>
        {{ isMobile.value ? (node?.title | changerText) : "" }}
        <cx-icon
          *ngIf="flyout && node.children?.length > 0"
          [type]="iconType.CARET_DOWN"
        ></cx-icon>
      </cx-generic-link>

      <ng-template #heading>
        <h2
          [ngClass]="!isMobile.value ? 'heading-title-desktop' : null"
          [attr.tabindex]="flyout && (depth === 0 || node.url) ? 0 : -1"
          (click)="
            node.title === 'Mis Favoritos' ||
            node.title === 'Términos y condiciones' ||
            node.title === 'Preguntas frecuentes'
              ? navigate(node.url, node, isMobile.value)
              : null
          "
        >
          <ng-container *ngIf="node?.categoryThumbnail$ | async as thumbnail">
            <div
              class="img-icon"
              *ngIf="isMobile.value && depth == 0 && thumbnail?.url"
            >
              <img [src]="baseUrl + '/' + thumbnail.url" [alt]="node.title" />
            </div>
          </ng-container>
          <span>{{ node.title | changerText }}</span>

          <cx-icon
            *ngIf="
              flyout &&
              node.children?.length > 0 &&
              isMobile.value &&
              node.toggle
            "
            class="fas fa-chevron-down"
          ></cx-icon>
          <cx-icon
            *ngIf="
              flyout &&
              node.children?.length > 0 &&
              isMobile.value &&
              !node.toggle
            "
            class="fas fa-chevron-right"
          ></cx-icon>
        </h2>
      </ng-template>

      <!-- we add a wrapper to allow for better layout handling in CSS -->
      <div
        class="wrapper"
        [ngClass]="{
          desktop: !isMobile.value,
          navigate: !isMobile.value && hideByNavigation
        }"
        *ngIf="node.children?.length > 0"
      >
        <div
          class="childs"
          [ngClass]="!isMobile.value ? 'desktop' : null"
          [attr.depth]="getTotalDepth(node)"
          [attr.wrap-after]="
            node.children?.length > wrapAfter ? wrapAfter : null
          "
          [attr.columns]="getColumnCount(node.children?.length)"
        >
          <ng-container *ngFor="let child of node.children">
            <ng-container
              *ngTemplateOutlet="
                nav;
                context: { node: child, depth: depth + 1 }
              "
            >
            </ng-container>
          </ng-container>
        </div>
        <cx-generic-link
          *ngIf="node.url && !isMobile.value"
          (click)="navigate(node.url, node)"
          [target]="node.target"
          class="all"
        >
          {{ "navigation.shopAll" | cxTranslate : { navNode: node.title } }}
          <cx-icon class="fas fa-chevron-right"></cx-icon>
        </cx-generic-link>
      </div>
    </nav>
  </ng-template>

  <ng-container *ngIf="componentData$ | async as data">
    <a
      [routerLink]="{ cxRoute: 'wishlist' } | cxUrl"
      (click)="navigate('my-account/wishlist')"
      class="favorites-grid"
      *ngIf="!isMobile.value"
    >
      <span class="heading-title-desktop">Mis Favoritos</span>
      <cx-icon type="HEART"></cx-icon>
    </a>
  </ng-container>
</ng-container>
