import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Product, TranslationService } from '@spartacus/core';
import {
  CurrentProductService,
  ProductAttributesComponent,
} from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map, pluck, switchMap } from 'rxjs/operators';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';
import { CustomProductsAttributesService } from 'src/app/services/custom-products-attributes.service';
import { ManualProduct } from '../../../model/custom-manual-products';
import { CustomProductAttributes } from '../../../model/custom-product-attributes-feature';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-product-attributes',
  templateUrl: './custom-product-attributes.component.html',
  styleUrls: ['./custom-product-attributes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomProductAttributesComponent
  extends ProductAttributesComponent
  implements OnInit
{
  attributes$: Observable<CustomProductAttributes[] | null>;
  title$: Observable<string>;
  manualProduct$: Observable<ManualProduct>;
  technicalDocuments$: Observable<ManualProduct[]>;
  customProduct$: Observable<Product | null>;
  isInPromeclub: boolean = this.service.isInPromeClub();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;

  constructor(
    protected currentProductService: CurrentProductService,
    protected translation: TranslationService,
    private customProductsAttributesService: CustomProductsAttributesService,
    private service: CustomNavigationService,
    private customBreakpointService: CustomBreakpointService
  ) {
    super(currentProductService);
  }

  ngOnInit(): void {
    this.title$ = this.translation.translate(
      'TabPanelContainer.downloadsDocuments'
    );
    this.attributes$ = this.currentProductService.getProduct().pipe(
      switchMap((producto) =>
        producto
          ? this.customProductsAttributesService.getAttributesProducts(
              producto.code!
            )
          : ''
      ),
      pluck('classifications'),
      map((classifications: CustomProductAttributes[]) =>
        classifications
          ? classifications.sort(function (a, b) {
              if (a.code > b.code) return 1;
              if (a.code < b.code) return -1;
              return 0;
            })
          : null
      )
    );

    this.manualProduct$ = this.currentProductService
      .getProduct()
      .pipe(pluck('productManual'));

    this.technicalDocuments$ = this.currentProductService
      .getProduct()
      .pipe(pluck('technicalDocuments'));
    this.customProduct$ = this.currentProductService.getProduct();
  }
}
