import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CustomAddress } from '../../models/custom-address.interface';
import { CustomAddressOrchestratorService } from '../../services/custom-address-orchestrator.service';
import { CustomPromeclubAddressService } from '../../services/custom-promeclub-address.service';

@Component({
  selector: 'app-custom-promeclub-address',
  templateUrl: './custom-promeclub-address.component.html',
  styleUrls: ['./custom-promeclub-address.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomPromeclubAddressComponent implements OnInit {
  isAvailable$: Observable<boolean> =
    this.customAddressOrchestratorService.getAvailableTypes();
  showNewForm$: Observable<boolean> =
    this.customAddressOrchestratorService.getAddNewMode();
  editMode$: Observable<boolean> =
    this.customAddressOrchestratorService.getEditMode();
  userAddress$: Observable<CustomAddress[]> =
    this.customPromeclubAddressService.getAddresses();
  addressToEdit$: Observable<CustomAddress | null> =
    this.customAddressOrchestratorService.getAddressToEdit();
  subscription: Subscription = new Subscription();
  constructor(
    private customPromeclubAddressService: CustomPromeclubAddressService,
    private customAddressOrchestratorService: CustomAddressOrchestratorService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.customAddressOrchestratorService.getDeleted().subscribe((value) => {
        if (value) {
          this.userAddress$ = this.customPromeclubAddressService.getAddresses();
        }
      })
    );
    this.subscription.add(
      this.customPromeclubAddressService
        .getAvailableAddressTypes()
        .pipe(take(1))
        .subscribe((value) => {
          if (value?.length > 0) {
            this.customAddressOrchestratorService.setAvailableTypes(true);
          }
        })
    );
  }

  showForm(value: boolean) {
    if (!value) {
      return;
    }
    this.customAddressOrchestratorService.setReadOnlyMode(false);
    this.customAddressOrchestratorService.setAddNewMode(value);
    window.scroll(0, 0);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
