import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPreAddToCartComponent } from './custom-pre-add-to-cart.component';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { I18nModule } from '@spartacus/core';
import { CustomPreAddToCartItemComponent } from './custom-pre-add-to-cart-item/custom-pre-add-to-cart-item.component';
import { CustomGiftProductModule } from 'src/app/cms-components/custom/components/custom-gift-product/custom-gift-product.module';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomProductVariantsContainerModule } from 'src/app/cms-components/product/components/custom-product-variants-container/custom-product-variants-container.module';
import { CustomLoadingSpinnerModule } from 'src/app/cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomItemCounterModule } from 'src/app/cms-components/shared/components/custom-item-counter/custom-item-counter.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CustomPreAddToCartComponent, CustomPreAddToCartItemComponent],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    MediaModule,
    CustomGiftProductModule,
    CustomCurrencyModule,
    CustomProductVariantsContainerModule,
    CustomLoadingSpinnerModule,
    CustomItemCounterModule,
    ReactiveFormsModule,
  ],
  exports: [CustomPreAddToCartComponent],
})
export class CustomPreAddToCartModule {}
