import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, User } from '@spartacus/core';
import { HamburgerMenuService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomHeaderService } from 'src/app/services/custom-header.service';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomBaseSiteCheckerService } from 'src/app/services/custom-base-site-checker.service';

@Component({
  selector: 'app-custom-inner-header',
  templateUrl: './custom-inner-header.component.html',
  styleUrls: ['./custom-inner-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomInnerHeaderComponent implements OnInit {
  user$: Observable<User | undefined> = this.userService.get();
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  constructor(
    private hamburgerMenuService: HamburgerMenuService,
    private router: Router,
    private customHeaderService: CustomHeaderService,
    protected authService: AuthService,
    private userService: UserAccountFacade,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService
  ) {}

  ngOnInit(): void {}

  closeMenu(): void {
    if (this.hamburgerMenuService.isExpanded.value) {
      this.customHeaderService.toggleVisibilityTopHeaderSlot();
    } else {
      this.customHeaderService.toggleVisibilityTopHeaderSlot(true);
    }
    this.hamburgerMenuService.toggle();
  }

  resetClassHeader() {
    this.customHeaderService.toggleHeaderPositionFixed();
    this.customHeaderService.toggleHeaderHeight(false);
  }

  navigateToUrl(url: string): void {
    this.router.navigateByUrl(url);
  }
}
