import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Config } from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CustomBreakpointService } from '../../../../../../services/custom-breakpoint.service';
import { CustomUploadRegisterProgressDialogComponent } from '../../../custom-register-shared/custom-upload-register-progress/custom-upload-register-progress-dialog.component';
import { CustomDocData } from '../../../models/custom-doc-data.interface';
import { CustomInputEvent } from '../../../models/custom-input-event.interface';
import { CustomRegisterDocsService } from '../../../services/custom-register-docs.service';
import { CustomRegisterNonClientFormService } from '../../../services/custom-register-non-client-form.service';
import { UploadProgressService } from '../../../services/upload-progress.service';

@Component({
  selector: 'app-custom-doc-item',
  templateUrl: './custom-doc-item.component.html',
  styleUrls: ['./custom-doc-item.component.scss'],
})
export class CustomDocItemComponent implements OnInit {
  @Input() FormControl: FormControl;
  @Input() label: string;
  @Input() download: boolean;
  @Input() onlyRead: boolean;
  @Input() docData: CustomDocData;
  @Input() disableButton: boolean;
  @Input() appointmentDocument: string;
  docName: string;
  docValid: boolean = false;
  docDate: string = new Date(Date.now()).toLocaleDateString();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  modalRef: ModalRef;
  doc: string;
  ruc: string;
  docUuid: string;
  subscription: Subscription = new Subscription();
  baseUrl: string =
    this.config.backend?.media?.baseUrl ??
    this.config.backend?.occ?.baseUrl ??
    '';
  appointmentDocument$: Observable<string> =
    this.customRegisterNonClientFormService?.getAppointmentDocument();
  uploadError: boolean = false;
  isJuridicPerson$: Observable<boolean> =
    this.customRegisterNonClientFormService
      .getNonCustomerFormData()
      .pipe(map((form) => form?.juridicPerson!));
  constructor(
    private customBreakpointService: CustomBreakpointService,
    private modalService: ModalService,
    private customRegisterNonClientFormService: CustomRegisterNonClientFormService,
    private customRegisterDocsService: CustomRegisterDocsService,
    private uploadProgressService: UploadProgressService,
    private cd: ChangeDetectorRef,
    private config: Config
  ) {}

  ngOnInit(): void {
    this.doc = this.label.toLowerCase().includes('ruc')
      ? 'ruc'
      : this.label.toLowerCase().includes('id')
      ? 'id'
      : this.label.toLowerCase().includes('letter')
      ? 'letter'
      : 'appoinment';

    if (this.onlyRead) {
      this.docName = this.docData.docName;
      this.docValid = this.docData.isValid;
      this.docDate = this.docData.docDate;
    }
    this.subscription.add(
      this.customRegisterNonClientFormService
        .getNonCustomerFormData()
        .subscribe((form) => {
          this.ruc = form?.ruc!;
        })
    );

    this.subscription.add(
      this.customRegisterDocsService.getRemovedDoc().subscribe((value) => {
        if (value?.removed && value?.uuid && this.docUuid === value?.uuid) {
          this.docValid = false;
          this.docUuid = '';
          this.FormControl.setValue('');
          this.customRegisterDocsService.setValidDocs(-1);
          this.cd.detectChanges();
        }
      })
    );

    this.subscription.add(
      combineLatest([
        this.isJuridicPerson$,
        this.customRegisterDocsService.getDocsData(),
      ]).subscribe(([isJuridic, value]) => {
        let filteredDocs;
        if (!isJuridic && this.doc === 'appoinment' && this.docValid === true) {
          this.FormControl.setValue('');
          this.customRegisterDocsService.setValidDocs(-1);
          this.docValid = false;
          this.docUuid = '';
          filteredDocs = value?.filter(
            (doc) => Object.keys(doc)[0] !== 'appoinment'
          );
          this.customRegisterDocsService.setDocDataArray(filteredDocs!);
          this.cd.detectChanges();
        }
      })
    );
  }

  getFile(e: CustomInputEvent, doc: string) {
    const file: File = e.target.files[0];
    const validFile = this.validateFileExtension(file.type);
    const size = file.size / 1024 / 1024;
    if (!validFile) {
      this.openProgressModal(true);
      return;
    }
    if (size > 25) {
      this.openProgressModal(true, true);
      return;
    }
    const code = `${this.ruc}${new Date(Date.now()).toISOString()}_${doc}`;

    this.openProgressModal(false, false, false, this.roundName(file.name));
    this.customRegisterNonClientFormService
      .uploadDocument(file, code, doc)
      .pipe(catchError((error) => of(error)))
      .subscribe((response) => {
        if (response?.error) {
          this.uploadProgressService.setUploadError(true);
          return;
        }
        this.validateDocType(this.doc, file.name, response!.uuid);
        this.docUuid = response!.uuid;
        this.customRegisterDocsService.setValidDocs(1);
        this.customRegisterNonClientFormService.setNonCustomerDocFormData(
          this.docUuid,
          doc
        );
      });
  }

  openProgressModal(
    invalidFile: boolean = false,
    invalidSize: boolean = false,
    removeFile: boolean = false,
    fileName: string = '',
    uuid: string = ''
  ) {
    let modalInstance: any;

    this.modalRef = this.modalService.open(
      CustomUploadRegisterProgressDialogComponent,
      {
        centered: invalidSize,
        size: 'lg',
      }
    );
    modalInstance = this.modalRef.componentInstance;
    modalInstance.invalidFileExtension = invalidFile;
    modalInstance.invalidFileSize = invalidSize;
    modalInstance.removeFile = removeFile;
    modalInstance.fileName = fileName;
    modalInstance.progress$ = this.uploadProgressService.getProgressSource();
    modalInstance.uploadError$ = this.uploadProgressService.getUploadError();
    modalInstance.uuid = uuid;
    modalInstance.docType = this.doc;
  }

  validateFileExtension(fileType: string): boolean {
    if (fileType !== 'application/pdf') {
      return false;
    }
    return true;
  }

  validateDocType(typeDoc: string, name: string, uuid: string = ''): void {
    switch (typeDoc) {
      case 'ruc':
        this.docValid = true;
        this.docName = this.roundName(name);
        this.customRegisterDocsService.setDocData(
          { docDate: this.docDate, docName: this.docName, isValid: true, uuid },
          'ruc'
        );
        break;
      case 'id':
        this.docValid = true;
        this.docName = this.roundName(name);
        this.customRegisterDocsService.setDocData(
          { docDate: this.docDate, docName: this.docName, isValid: true, uuid },
          'id'
        );
        break;
      case 'appoinment':
        this.docValid = true;
        this.docName = this.roundName(name);
        this.customRegisterDocsService.setDocData(
          { docDate: this.docDate, docName: this.docName, isValid: true, uuid },
          'appoinment'
        );
        break;
      case 'letter':
        this.docValid = true;
        this.docName = this.roundName(name);
        this.customRegisterDocsService.setDocData(
          { docDate: this.docDate, docName: this.docName, isValid: true, uuid },
          'letter'
        );
        break;
      default:
        break;
    }
  }

  removeFile() {
    this.openProgressModal(false, false, true, this.docName, this.docUuid);
  }

  roundName(name: string): string {
    if (name.length > 10) {
      return name.slice(0, 5) + '...pdf';
    }
    return name;
  }

  downloadPdf(download: boolean, documentUrl: string) {
    if (!download || !documentUrl) {
      return;
    }
    window.open(this.baseUrl + documentUrl, '_self');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
