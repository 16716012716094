<ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
  <app-custom-loading-spinner-item
    class="overlay"
    *ngIf="isUpdating$ | async"
  ></app-custom-loading-spinner-item>
  <h3 class="login-form-title__layout" *ngIf="!fromModal && !fromPdp">
    {{ "loginForm.loginFormTitle" | cxTranslate }}
  </h3>
  <h3 class="login-form-title" *ngIf="fromModal && !data.isMobile">
    {{ "common.loginTitle" | cxTranslate }}
  </h3>

  <form
    (ngSubmit)="onCustomSubmit('inicio_sesion')"
    [formGroup]="form"
    [ngClass]="{
      invalid: (submit && form.invalid) || (errorMessage$ | async)
    }"
  >
    <div class="login-form__body">
      <div
        class="close-form-pdp"
        *ngIf="fromPdp && data.isMobile && viewFormInPdp"
      >
        <cx-icon class="fas fa-times" (click)="toggleViewFormInPdp()"></cx-icon>
      </div>

      <h3 class="login-form-title" *ngIf="fromPdp">
        <span>{{ "loginForm.loginFormTitlePdp" | cxTranslate }}</span>
      </h3>
      <label
        [ngClass]="{ 'no-display': fromPdp && data.isMobile && !viewFormInPdp }"
      >
        <span class="label-content">{{
          fromModal || fromPdp
            ? ("loginForm.emailAddress.label" | cxTranslate)
            : ("loginForm.emailAddress.placeholder" | cxTranslate)
        }}</span>
        <input
          aria-required="true"
          type="email"
          class="form-control"
          formControlName="userId"
          placeholder="{{ 'loginForm.emailAddress.placeholder' | cxTranslate }}"
          (focus)="removeError()"
          (keydown)="onKey($event.target)"
          (blur)="blurValidationEmail()"
          [class.invalid]="form.controls['userId'].errors && submit"
        />
        <p
          *ngIf="form.controls['userId'].touched && form.controls['userId'].errors?.['required'] && submit"
          class="login-form-error"
        >
          {{ "formErrors.emailRequired" | cxTranslate }}
        </p>
        <p
          *ngIf="form.controls['userId'].dirty && form.controls['userId'].errors?.['cxInvalidEmail'] && submit && isEmailDirty"
          class="login-form-error"
        >
          {{ "formErrors.cxInvalidEmail" | cxTranslate }}
        </p>
      </label>

      <label
        [ngClass]="{ 'no-display': fromPdp && data.isMobile && !viewFormInPdp }"
      >
        <span class="label-content">{{
          fromModal || fromPdp
            ? ("loginForm.password.label" | cxTranslate)
            : ("loginForm.password.placeholder" | cxTranslate)
        }}</span>
        <ng-container
          *ngIf="{
            message: errorMessage$ | async,
            attempts: attempts$ | async
          } as error"
        >
          <input
            aria-required="true"
            type="password"
            class="form-control"
            [ngClass]="{
              error:
                error?.message === 'httpHandlers.badRequestPleaseLoginAgain'
            }"
            placeholder="{{ 'loginForm.password.placeholder' | cxTranslate }}"
            formControlName="password"
            (focus)="removeError()"
            [class.invalid]="form.controls['password'].touched && form.controls['password'].errors?.['required'] && submit"
          />
          <p
            *ngIf="form.controls['password'].touched && form.controls['password'].errors?.['required'] && submit"
            class="login-form-error"
          >
            {{ "formErrors.passwordRequired" | cxTranslate }}
          </p>
          <p
            *ngIf="!!error?.message"
            class="login-form-error login-form-error-password"
          >
            {{
              error.message === "httpHandlers.badRequestPleaseLoginAgain"
                ? (error.message
                  | cxTranslate
                    : {
                        count: error.attempts.attempts,
                        max: error.attempts.maxAttempts
                      })
                : (error.message | cxTranslate)
            }}
          </p>
        </ng-container>
      </label>

      <a
        [ngClass]="{ 'no-display': fromPdp && data.isMobile && !viewFormInPdp }"
        (click)="openModal('olvide_contraseña')"
        class="btn-link forgot"
      >
        {{ "loginForm.forgotUserOrPassword" | cxTranslate }}
      </a>

      <button
        *ngIf="!fromPdp"
        type="submit"
        class="btn btn-block btn-primary"
        [ngClass]="{ 'btn-login-form': fromModal }"
        [disabled]="form.disabled"
      >
        {{ "loginForm.signIn" | cxTranslate }}
        <cx-icon class="fas fa-chevron-right"></cx-icon>
      </button>
    </div>
    <button
      *ngIf="
        (fromPdp && data.isMobile && viewFormInPdp) ||
        (fromPdp && !data.isMobile)
      "
      type="submit"
      class="btn btn-block btn-primary"
      [disabled]="form.disabled"
    >
      {{ "loginForm.signIn" | cxTranslate }}
      <cx-icon class="fas fa-chevron-right"></cx-icon>
    </button>
  </form>

  <button
    #LoginFormBtnSubmit
    type="submit"
    *ngIf="fromPdp && data.isMobile && !viewFormInPdp"
    class="btn btn-block btn-primary"
    (click)="navigateOnClick('login', 'registrarse')"
  >
    {{ "loginForm.signIn" | cxTranslate }}
    <cx-icon class="fas fa-chevron-right"></cx-icon>
  </button>
  <a
    (click)="navigateOnClick('register', 'registrarse')"
    class="btn-link text-intermediate"
  >
    {{ "loginForm.dontHaveAccount" | cxTranslate }}
    <span class="btn-link btn-link-register">
      {{ "loginForm.registerHere" | cxTranslate }}</span
    >
  </a>

  <a
    *ngIf="!fromPdp"
    (click)="navigateOnClick('registerEmployees', 'registrarse')"
    class="btn-link no-underline"
  >
    <span class="btn-link">
      {{ "loginForm.employeeAccess" | cxTranslate }}</span
    >
  </a>

  <p *ngIf="!fromModal && !fromPdp" class="bottom-login-form-text">
    {{
      "loginForm.bottomLoginFormText"
        | cxTranslate : { currentYear: currentYear }
    }}
  </p>
</ng-container>
