<ng-container *ngIf="{
  isMobile: isMobile$ | async,
  isPromeclub: isPromeclub$ | async
} as data">
<ng-container *ngIf="!data.isMobile">
  <section *ngIf="(activeStepIndex$ | async) !== undefined">
    <div class="cx-nav progress-bar__header">
      <ul class="cx-list">
        <ng-container *ngFor="let step of steps$ | async; let i = index">
          <li
            class="cx-item"
            [class.active]="isActive(i)"
            [class.disabled]="isDisabled(i)"
          >
            <div class="progress-bar">
              <div
                class="progress-bar__bar"
                [class.active]="isActive(i)"
                [class.disabled]="isDisabled(i)"
              ></div>
            </div>
            <div
              class="circle-container"
              [class.active]="isActive(i)"
              [class.disabled]="isDisabled(i)"
            >
              <div
                class="circle-container__background"
                [class.active]="isActive(i)"
                [class.disabled]="isDisabled(i)"
              ></div>
            </div>
            <ng-container *ngIf="!data.isMobile">
              <a
                [routerLink]="{ cxRoute: step.routeName } | cxUrl"
                class="cx-link"
                [class.active]="isActive(i)"
                [class.disabled]="isDisabled(i)"
                [tabindex]="getTabIndex(i)"
                [innerHTML]="
                  data.isPromeclub && step.name == 'checkoutProgress.methodOfPayment' ?
                  ('checkoutProgress.exchangeMethod'| cxTranslate | customMultilineTitle: i + 1)
                  :(step.name | cxTranslate | customMultilineTitle: i + 1)
                "
              >
              </a>
            </ng-container>
          </li>
        </ng-container>
      </ul>
      <ng-container *ngIf="data.isMobile">
        <ul class="cx-list-mobile">
          <ng-container *ngFor="let step of steps$ | async; let i = index">
            <li class="cx-item">
              <a
                [routerLink]="{ cxRoute: step.routeName } | cxUrl"
                class="cx-link"
                [class.active]="isActive(i)"
                [class.disabled]="isDisabled(i)"
                [tabindex]="getTabIndex(i)"
                [innerHTML]="
                  data.isPromeclub && step.name == 'checkoutProgress.methodOfPayment' ?
                  ('checkoutProgress.exchangeMethod'| cxTranslate | customMultilineTitle: i + 1)
                  :(step.name | cxTranslate | customMultilineTitle: i + 1)
                "
              >
              </a>
            </li>
          </ng-container>
        </ul>
      </ng-container>
    </div>
  </section>
</ng-container>
</ng-container>
