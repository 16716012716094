<div class="contact" *ngIf="contactNavigationNode$ | async as contact">
  <h5 class="contact__title">{{ contact.title.slice(0, 8) }}</h5>
  <div class="contact__links">
    <ng-container *ngFor="let link of contact.children.slice(0, 3)">
      <cx-generic-link class="contact__links-item" [url]="link?.url">
        <cx-icon
          [class]="link.styleClasses"
          *ngIf="!!link?.styleClasses"
        ></cx-icon>
        <span>
          {{ link?.linkName }}
        </span>
      </cx-generic-link>
    </ng-container>
  </div>
  <div class="contact__border"></div>
</div>
