<ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
  <div class="cx-page" *ngIf="model$ | async as model">
    <section class="cx-page-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-12" *ngIf="viewMode$ | async as viewMode">
            <div class="cx-sorting top" *ngIf="!(isMobile$ | async)">
              <div class="row" *ngIf="viewMode !== ViewModes.List">
                <div
                  class="form-group cx-sort-dropdown col-12 col-lg-7 ml-auto"
                  *ngIf="!(model.products?.length! == 0)"
                >
                  <app-custom-view-selectors
                    (modeChange)="setViewMode($event)"
                    [mode]="viewModes.Grid"
                    *ngIf="!isSearchResultsList"
                  ></app-custom-view-selectors>
                  <cx-sorting
                    [sortOptions]="model.sorts"
                    (sortListEvent)="sortList($event)"
                    [selectedOption]="model.pagination.sort"
                    placeholder="{{ 'productList.sortBy' | cxTranslate }}"
                  ></cx-sorting>
                </div>
              </div>
            </div>
            <div
              class="cx-product-container"
              [ngClass]="{
                'no-products': !!!model?.products.length,
                'cx-product-container__custom-variants-view':
                  viewMode === ViewModes.List && !data.isMobile
              }"
            >
              <div
                class="row product-view-selector"
                *ngIf="
                  !isSearchResultsList &&
                  data.isMobile &&
                  viewMode !== ViewModes.List &&
                  model?.products.length > 0
                "
              >
                <app-custom-view-selectors
                  (modeChange)="setViewMode($event)"
                  [mode]="viewModes.Grid"
                ></app-custom-view-selectors>
              </div>
              <!-- Product list when using pagination -->
              <ng-container *ngIf="!isInfiniteScroll; else infiniteScroll">
                <ng-container *ngIf="viewMode === ViewModes.Grid">
                  <div class="cx-product-container__custom">
                    <app-custom-product-card
                      *ngFor="let product of model?.products; let i = index"
                      [product]="product"
                      [productPosition]="i"
                      [template]="model?.type"
                      [clickedFrom]="clickedFrom"
                      [haveAutomotiveLabel]="
                        automotiveFilterQuery == activeQuery
                      "
                    ></app-custom-product-card>
                  </div>
                </ng-container>
              </ng-container>

              <!-- Product list when using infinite scroll -->
              <ng-template #infiniteScroll>
                <ng-container *ngIf="viewMode === ViewModes.List">
                  <app-custom-product-variant-list-header
                    *ngIf="!data.isMobile"
                  ></app-custom-product-variant-list-header>
                  <div class="row product-view-selector" *ngIf="data.isMobile">
                    <app-custom-view-selectors
                      (modeChange)="setViewMode($event)"
                      [mode]="viewModes.List"
                    ></app-custom-view-selectors>
                  </div>

                  <div
                    *ngIf="!data.isMobile"
                    class="form-group cx-sort-dropdown col-12 col-lg-7 ml-auto"
                  >
                    <app-custom-view-selectors
                      (modeChange)="setViewMode($event)"
                      [mode]="viewModes.List"
                    ></app-custom-view-selectors>
                  </div>
                </ng-container>

                <app-custom-product-scroll
                  [scrollConfig]="scrollConfig"
                  [model]="model"
                  [inputViewMode]="viewMode"
                  [haveAutomotiveLabel]="automotiveFilterQuery == activeQuery"
                >
                </app-custom-product-scroll>
              </ng-template>
            </div>
            <div class="cx-sorting bottom">
              <div class="row">
                <div
                  *ngIf="!isInfiniteScroll"
                  class="col-auto pagination-container"
                  [attr.aria-label]="
                    'productList.productSearchPagination' | cxTranslate
                  "
                >
                  <div class="cx-pagination">
                    <cx-pagination
                      [pagination]="model.pagination"
                      queryParam="currentPage"
                      [defaultPage]="0"
                    ></cx-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</ng-container>
