import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { ICON_TYPE, ModalService } from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomQuoteOrchestratorService } from '../../services/custom-quote-orchestrator.service';
import { CustomQuotesService } from '../../services/custom-quotes.service';

@Component({
  selector: 'app-custom-quotes-remove-confirmation-dialog',
  templateUrl: './custom-quotes-remove-confirmation-dialog.component.html',
  styleUrls: ['./custom-quotes-remove-confirmation-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomQuotesRemoveConfirmationDialogComponent
  implements OnDestroy
{
  iconTypes = ICON_TYPE;
  code: string;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  unsubscribe$: Subject<void> = new Subject();
  constructor(
    private modalService: ModalService,
    private customBreakpointService: CustomBreakpointService,
    private customQuotesSevice: CustomQuotesService,
    private routingService: RoutingService,
    private customQuoteOrchestrator: CustomQuoteOrchestratorService
  ) {}

  closeModal(reason: string) {
    if (reason === 'removed') {
      this.customQuoteOrchestrator.resetGoToSummary();
      this.routingService.go({ cxRoute: 'quotes' });
    }
    this.modalService.closeActiveModal(reason);
  }

  deleteQuoteFromModal() {
    this.customQuotesSevice
      .deleteQuote(this.code)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.closeModal('removed'));
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
