<ng-container *ngIf="customProduct$ | async as product; else loading">
  <div class="product">
    <div class="product__img">
      <cx-media
        [container]="product.images?.PRIMARY || product.images"
        format="product"
        [alt]="product?.name"
      ></cx-media>
    </div>
    <div class="product__info">
      <p class="product__info-sku">
        {{ "productList.sku" | cxTranslate }} {{ product?.code }}
      </p>
      <p class="product__info-name">{{ product?.name }}</p>
      <app-custom-gift-product
        *ngIf="product?.stock?.stockLevelStatus === 'outOfStock'"
        [wideLabel]="wideLabel"
        label="{{ 'addToCart.outOfStock' | cxTranslate }}"
      ></app-custom-gift-product>
      <app-custom-gift-product
        *ngIf="
          product?.stock?.stockLevelStatus != 'outOfStock' && product?.badge
        "
        [wideLabel]="wideLabel"
        [label]="product?.badge"
      >
      </app-custom-gift-product>
      <p
        class="product__info-price previous"
        *ngIf="!!product?.previousPrice?.value"
      >
        {{ product?.previousPrice?.value | customCurrency }}
      </p>

      <p
        *ngIf="!isPromeclub"
        class="product__info-price"
        [ngClass]="{ discount: !!product?.previousPrice?.value }"
      >
        {{ product?.price?.value | customCurrency }}
        <span class="product__info-price-iva" *ngIf="!!product?.price?.value">
          {{ "productList.plusIva" | cxTranslate }}
        </span>
      </p>
      <p class="product__info-price" *ngIf="isPromeclub">
        {{ product?.price?.value | number : "1.0-0" : "es-AR" }}
        <span class="product__info-price-iva">
          {{ "productList.points" | cxTranslate }}
        </span>
      </p>
      <app-custom-item-counter
        *ngIf="product"
        [fromProductVariantPlpDesktop]="true"
        [productStockLevel]="product?.stock?.stockLevel"
        [control]="addToCartForm.get('quantity')"
        [ngClass]="{ 'with-error': addToCartForm.get('quantity').invalid }"
      >
      </app-custom-item-counter>
      <p
        class="counter-error"
        *ngIf="
          addToCartForm.get('quantity').invalid &&
          addToCartForm.get('quantity').value != 0
        "
      >
        {{
          "addToCart.availableStock"
            | cxTranslate : { stock: product?.stock?.stockLevel }
        }}
      </p>
    </div>

    <yrs-custom-product-variants-container
      [product$]="customProduct$"
      [fromCartItem]="true"
    ></yrs-custom-product-variants-container>
  </div>
</ng-container>
<ng-template #loading>
  <div class="spinner">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
