<ng-container *ngIf="{
  isInReview: isInReview$ | async,
  itemsInReview: itemsInReview$ | async,
  allAccepted: allAccepted$ | async,
  allRejected: allRejected$ | async
  } as data">
  <ng-container *ngIf="cart">
    <div class="quotes-entry-list">
      <h4 class="quotes-entry-list__title">
        {{'accountQuotes.list.title' | cxTranslate}}
      </h4>
      <p class="quotes-entry-list__subtitle" *ngIf="!data.isInReview && !isInDetail && !isInOffer">
        {{'accountQuotes.list.subtitle' | cxTranslate}}
      </p>
      <div class="quotes-entry-list__review-filter" [formGroup]="filterForm"
        *ngIf="data.isInReview || isInDetail || isInOffer">
        <div class="form-check">
          <input class="form-check-input filter" type="radio" name="all" [checked]="sortByReview == false"
            [value]="!sortByReview" (change)="filterByReview(false)" formControlName="all">
          <label class="form-check-label radio">
            {{'accountQuotes.list.filterAll' | cxTranslate}}
          </label>
        </div>
        <div class="form-check last">
          <input class="form-check-input filter" type="radio" name="byReview" [checked]="sortByReview"
            (change)="filterByReview(true)" formControlName="byReview">
          <label class="form-check-label radio">
            {{'accountQuotes.list.filterByReview' | cxTranslate}}
          </label>
        </div>
        <div class="quotes-entry-list__review-filter__buttons" *ngIf="!isMobile && isInOffer">
          <p class="quotes-entry-list__review-filter__buttons-action" (click)="openRejectAllModal()"
            [ngClass]="{'all': data.allRejected}">
            {{'accountQuotes.list.rejectAll' | cxTranslate}}
          </p>
          <p class="quotes-entry-list__review-filter__buttons-action last" (click)="acceptAll()"
            [ngClass]="{'all': data.allAccepted}">
            {{'accountQuotes.list.acceptAll' | cxTranslate}}
          </p>
        </div>
      </div>
      <div class="quotes-entry-list__header">
        <p class="quotes-entry-list__header-quantity">
          <span>
            {{'accountQuotes.list.quantity' | cxTranslate : {count: cart?.entries?.length} }}
            {{data.isInReview || isInDetail || isInOffer ? ' / ' : ''}}
          </span>
          <span class="medium" *ngIf="data.isInReview || isInDetail">
            {{'accountQuotes.list.review' | cxTranslate : {count: data.itemsInReview} }}
          </span>
          <span class="medium" *ngIf="isInOffer">
            {{'accountQuotes.list.reviewOffer' | cxTranslate : {count: data.itemsInReview} }}
          </span>
        </p>
        <div class="quotes-entry-list__review-filter__buttons" *ngIf="isMobile && isInOffer">
          <p class="quotes-entry-list__review-filter__buttons-action" (click)="openRejectAllModal()"
            [ngClass]="{'all': data.allRejected}">
            {{'accountQuotes.list.rejectAll' | cxTranslate}}
          </p>
          <p class="quotes-entry-list__review-filter__buttons-action last" (click)="acceptAll()"
            [ngClass]="{'all': data.allAccepted}">
            {{'accountQuotes.list.acceptAll' | cxTranslate}}
          </p>
        </div>
        <div class="quotes-entry-list__header-filter">
          <app-custom-filter [label]="'orderHistory.filterResults' | cxTranslate" [entries]="quantities"
            [classSelect]="'small-filter'" [valueDefault]="pageSize" (filterSelected)="filterQuantitySelected($event)">
          </app-custom-filter>
          <app-custom-pagination [pagination]="pagination" (viewPageEvent)="customPageChange($event)"
            [pageSelected]="selectedPage" [isEditable]="true"></app-custom-pagination>

        </div>

      </div>
      <div class="quotes-entry-list__container">
        <ng-container *ngFor="let item of quotesDetailsShow">
          <app-custom-quotes-entry-item [entry]="item" [isMobile]="isMobile" [quoteCode]="cart.code"
            [isInReview]="data.isInReview" [isInDetail]="isInDetail" [isInOffer]="isInOffer"
            [formOpened]="cart.state === 'BUYER_DRAFT' && !data.isInReview">
          </app-custom-quotes-entry-item>
        </ng-container>
        <div class="quotes-entry-list__container-pagination">
          <app-custom-pagination [pagination]="pagination" (viewPageEvent)="customPageChange($event)"
            [pageSelected]="selectedPage" [isEditable]="true"></app-custom-pagination>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
