import { Component, OnInit } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CustomRegisterDocsService } from '../../services/custom-register-docs.service';
import { CustomRegisterNonClientFormService } from '../../services/custom-register-non-client-form.service';
import { CustomRegisterStepOrchestratorService } from '../../services/custom-register-step-orchestrator.service';

@Component({
  selector: 'app-custom-register-non-customer-docs',
  templateUrl: './custom-register-non-customer-docs.component.html',
  styleUrls: ['./custom-register-non-customer-docs.component.scss']
})
export class CustomRegisterNonCustomerDocsComponent implements OnInit {

  rucDoc: FormControl = new FormControl(null, [Validators.required]);
  idDoc: FormControl = new FormControl(null, [Validators.required]);
  registeredAppoinmentDoc: FormControl = new FormControl(null, [Validators.required]);
  letterDoc: FormControl = new FormControl(null, [Validators.required]);
  idDocLabel: string = 'nonCustomerRegister.attachId';
  rucDocLabel: string = 'nonCustomerRegister.attachRuc';
  registeredAppoinmentDocLabel: string = 'nonCustomerRegister.attachRegisteredAppoinment';
  letterDocLabel: string = 'nonCustomerRegister.attachLetter';
  downloadLabel: string = 'nonCustomerRegister.downloadLetter';
  stepTwo$: Observable<boolean> = this.customRegisterStepOrchestratorService.getStepTwoStatus();
  validDocs$: Observable<number> = this.customRegisterDocsService.getValidDocs();
  documentsNeeded: number;
  isJuridicPerson$: Observable<boolean> = this.customRegisterNonClientFormService.getNonCustomerFormData().pipe(
    map(form => form?.juridicPerson!),
    tap(isJuridicPerson => {
      this.documentsNeeded = isJuridicPerson ? 4 : 3;
    })
  );
  appointmentDocument$: Observable<string> = this.customRegisterNonClientFormService?.getAppointmentDocument();
  constructor(
    private customRegisterNonClientFormService: CustomRegisterNonClientFormService,
    private customRegisterStepOrchestratorService: CustomRegisterStepOrchestratorService,
    private customRegisterDocsService: CustomRegisterDocsService,
  ) { }

  ngOnInit(): void {
  }

  submitDocs(){
    this.customRegisterStepOrchestratorService.setStepTwoStatus(false);
    this.customRegisterStepOrchestratorService.setStepThreeStatus(true);
    window.scroll(0, 0);
  }

  return(){
    this.customRegisterStepOrchestratorService.setStepTwoStatus(false);
    this.customRegisterStepOrchestratorService.setStepOneStatus(true);
    window.scroll(0, 0);
  }
}
