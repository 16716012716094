import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomModalService {
  constructor() {}
  visibilitySub = new BehaviorSubject<any>({ visibility: false, instance: '' });

  visibility$ = this.visibilitySub.asObservable();

  show(instance: string): void {
    this.visibilitySub.next({ visibility: true, instance });
    const scrollbarWidth = window.innerWidth - document.body.clientWidth;
    document.body.classList.add('modal-active');
    document.body.style.marginRight = `${scrollbarWidth}px`;
  }

  hide(instance: string): void {
    this.visibilitySub.next({ visibility: false, instance });
    document.body.classList.remove('modal-active');
    document.body.style.marginRight = `0px`;
  }
}
