<ng-container *ngIf="item">
  <ng-container *ngIf="!(isMobile$ | async); else cardProdMobile">
    <div class="item-order-details">
      <div class="item-order-details__image-info">
        <div class="item-order-details__image">
          <img [src]="item.product?.images?.[0]?.url" loading="lazy" />
        </div>
        <div class="item-order-details__info">
          <p class="item-order-details__info-brand">
            {{ item.product?.brand?.name }}
          </p>
          <p class="item-order-details__info-name">
            {{ item.product?.name | titlecase }}
          </p>
          <div *ngIf="item.product?.code" class="item-order-details__info-code">
            {{'orderDetails.promeclubDetail.sku' | cxTranslate}}
            {{ item.product?.code }}
          </div>
        </div>
      </div>

      <div class="item-order-details__quantity">
        <label class="item-order-details__quantity-label">{{
          "orderDetails.quantity" | cxTranslate
          }}</label>
        <label class="item-order-details__quantity-value">{{
          item.quantity
          }}</label>
      </div>

      <div class="item-order-details__price">
        <label class="item-order-details__price-label">
          {{'orderDetails.promeclubDetail.unitPoints' | cxTranslate}}
        </label>
        <label class="item-order-details__price-value">{{
          item?.totalPrice?.value | number:'':'es-AR'
          }}</label>
      </div>

      <div class="item-order-details__total-price">
        <label class="item-order-details__total-price-label">
          {{'orderDetails.promeclubDetail.totalPoints' | cxTranslate}}
        </label>
        <label class="item-order-details__total-price-value">{{
          (item.totalPrice?.value * item.quantity) | number:'':'es-AR'
          }}</label>
      </div>
    </div>
  </ng-container>
  <ng-template #cardProdMobile>
    <div class="item-order-details">
      <div class="item-order-details__image">
        <img [src]="item.product?.images?.[0]?.url" loading="lazy" />
      </div>

      <div class="item-order-details__info_ppal">
        <div class="item-order-details__info">
          <p class="item-order-details__info-brand">
            {{ item.product?.brand?.name }}
          </p>
          <p class="item-order-details__info-name">
            {{ item.product?.name | titlecase }}
          </p>
          <div *ngIf="item.product?.code" class="item-order-details__info-code">
            {{'orderDetails.promeclubDetail.sku' | cxTranslate}}
            {{ item.product?.code }}
          </div>
        </div>

        <div class="item-order-details__info_quantity">
          <div class="item-order-details__quantity">
            <label class="item-order-details__quantity-label">{{
              "orderDetails.quantity" | cxTranslate
              }}</label>
            <label class="item-order-details__quantity-value">{{
              item.quantity
              }}</label>
          </div>

          <div class="item-order-details__price">
            <label class="item-order-details__price-label">
              {{'orderDetails.promeclubDetail.unitPoints' | cxTranslate}}
            </label>
            <label class="item-order-details__price-value">{{
              item?.totalPrice?.value | number:'':'es-AR'
              }}</label>
          </div>

          <div class="item-order-details__total-price">
            <label class="item-order-details__total-price-label">
              {{'orderDetails.promeclubDetail.totalPoints' | cxTranslate}}
            </label>
            <label class="item-order-details__total-price-value">{{
              (item.totalPrice?.value * item.quantity) | number:'':'es-AR'
              }}</label>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
