import { Component, Input, OnInit } from '@angular/core';
import { CustomProductAttributes } from '../../../model/custom-product-attributes-feature';

@Component({
  selector: 'app-custom-product-card-details',
  templateUrl: './custom-product-card-details.component.html',
  styleUrls: ['./custom-product-card-details.component.scss']
})
export class CustomProductCardDetailsComponent implements OnInit {
  @Input() feature: CustomProductAttributes;
  @Input() cardName: string;

  colorCard: string;

  constructor() { }

  ngOnInit(): void {
    this.cardName.toLowerCase().includes('características') ? this.colorCard = 'dark' : this.colorCard = 'light';
  }

}
