<ng-container *ngIf="stepTwo$ | async">
  <ng-container *ngIf="{client: clientInfo$ | async, countryCode: countryCode$ | async} as data">
    <div class="customer-personal-data">
      <app-custom-register-employee-personal-data [clientInfo]="data.client">
      </app-custom-register-employee-personal-data>

      <form action="" class="customer-personal-data__form"
        (ngSubmit)="validateRegisterEmployeeSubmit(data.client, data.countryCode)" [formGroup]="registerEmployeeForm">
        <div class="customer-personal-data__form-info">
          <h4 class="customer-personal-data__form-info-title">
            {{ "registerEmployee.confirmationData" | cxTranslate }}
          </h4>
        </div>
        <div class="customer-personal-data__form-inputs">
          <label>
            <span class="label-content">
              {{ "customRegister.emailForm" | cxTranslate }}
            </span>
            <input aria-required="true" formControlName="email" type="text" class="form-control" placeholder="{{
                'customRegister.userEmailPlaceholder' | cxTranslate
              }}" />
            <p class="customer-register-form-error" *ngIf="
                registerEmployeeForm.get('email')?.errors?.invalidEmail &&
                registerEmployeeForm.get('email')?.touched
              ">
              {{ "customRegister.invalidUserEmail" | cxTranslate }}
            </p>
            <p class="customer-register-form-error" *ngIf="
                registerEmployeeForm.get('email')?.errors?.required &&
                registerEmployeeForm.get('email')?.touched
              ">
              {{ "customRegister.emailRequired" | cxTranslate }}
            </p>
          </label>
          <label>
            <span class="label-content">
              {{ "customRegister.cellphone" | cxTranslate }}
            </span>
            <div class="cellphone-container">
              <div class="cellphone-container__country-code" [ngClass]="{
                  'cellphone-error':
                    (registerEmployeeForm.get('cellphone')?.errors?.required &&
                      registerEmployeeForm.get('cellphone')?.touched) ||
                    (registerEmployeeForm.get('cellphone')?.errors?.maxlength &&
                      registerEmployeeForm.get('cellphone')?.touched) ||
                    (registerEmployeeForm.get('cellphone')?.errors?.invalidCellphone &&
                      registerEmployeeForm.get('cellphone')?.touched &&
                      !registerEmployeeForm.get('cellphone')?.errors?.required)
                }">
                <p class="cellphone-container__country-code-value">
                  {{ data.countryCode }}
                </p>
              </div>

              <input aria-required="true" formControlName="cellphone" type="text" class="form-control" placeholder="{{
                  'customRegister.cellphonePlaceholder' | cxTranslate
                }}" />
            </div>
            <p class="customer-register-form-error" *ngIf="
                registerEmployeeForm.get('cellphone')?.errors?.required &&
                registerEmployeeForm.get('cellphone')?.touched
              ">
              {{ "customRegister.cellphoneRequired" | cxTranslate }}
            </p>
            <p class="customer-register-form-error" *ngIf="
                registerEmployeeForm.get('cellphone')?.errors?.maxlength &&
                registerEmployeeForm.get('cellphone')?.touched
              ">
              {{ "nonCustomerRegister.invalidCellphone" | cxTranslate }}
            </p>
            <p class="customer-register-form-error" *ngIf="
                registerEmployeeForm.get('cellphone')?.errors?.invalidCellphone &&
                registerEmployeeForm.get('cellphone')?.touched &&
                !registerEmployeeForm.get('cellphone')?.errors?.required
              ">
              {{ "nonCustomerRegister.invalidCellphone" | cxTranslate }}
            </p>
          </label>


        </div>
        <div class="customer-personal-data__form-action">
          <button class="customer-personal-data__form-action-button" type="submit"
            [disabled]="registerEmployeeForm.invalid">
            {{ "common.continue" | cxTranslate }}
          </button>
        </div>
      </form>
    </div>
  </ng-container>
</ng-container>
