import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CmsComponent, CmsService } from "@spartacus/core";
import { Observable } from "rxjs";

@Component({
  selector: 'app-custom-cart-important-messages',
  templateUrl: './custom-cart-important-messages.component.html',
  styleUrls: ['./custom-cart-important-messages.component.scss'],
})
export class CustomCartImportantMessagesComponent implements OnInit {
  @Input() cart: any;
  @Output() cartEmitter = new EventEmitter<any>();
  promesaCartUpdatedPriceMessageComponentData$: Observable<CmsComponent> = this.cmsService.getComponentData('promesaCartUpdatedPriceMessageComponent');

  constructor(
    private cmsService: CmsService
  ) {}

  ngOnInit(): void {
    if (this.cart) {
      this.emmitCart();
    }
  }

  emmitCart() {
    this.cartEmitter.emit(this.cart);
  }
}
