import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { OrderDetailShippingComponent, OrderDetailsService } from '@spartacus/order/components';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
@Component({
  selector: 'app-custom-order-detail-shipping',
  templateUrl: './custom-order-detail-shipping.component.html',
  styleUrls: ['./custom-order-detail-shipping.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomOrderDetailShippingComponent extends OrderDetailShippingComponent implements OnInit {

  locationStatus: Location = this.location;
  @Input() showTitle: boolean = true;
  pageCurrent$: Observable<string | undefined> = this.cmsService.getCurrentPage().pipe(pluck('pageId'));
  constructor(orderDetailsService: OrderDetailsService,
    protected location: Location,
    private cmsService: CmsService) {
    super(orderDetailsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
