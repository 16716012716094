import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GenericLinkModule } from '@spartacus/storefront';
import { CustomCategoryItemComponent } from './custom-category-item.component';



@NgModule({
  declarations: [
    CustomCategoryItemComponent
  ],
  imports: [
    CommonModule,
    GenericLinkModule
  ],
  exports:[CustomCategoryItemComponent]
})
export class CustomCategoryItemModule { }
