export const customPromeclubLanding = {
  customPromeclub: {
    customPromeclub: {
      form: {
        title: 'Completa el formulario para participar por increíbles premios.',
        partnerNameInputLabel: 'Nombre de socio:',
        partnerNameInputPlaceholder: 'Ingresar nombre completo',
        partnerNameInputRequiredError: '* El nombre es obligatorio',
        socialReasonInputLabel: 'Razón social:',
        socialReasonInputPlaceholder: 'Ingresar razón social',
        socialReasonInputRequiredError: '* La razón social es obligatoria',
        emailInputLabel: 'Correo electrónico:',
        emailInputPlaceholder: 'Ingresar correo electrónico',
        emailInputRequiredError: '* El correo electrónico es obligatorio',
        emailInputSintaxError: '* Debe ingresar un correo electrónico válido',
        clientCodeInputLabel: 'Código de cliente:',
        clientCodeInputPlaceholder: 'Ingresar código',
        clientCodeInputRequiredError: '* El código de cliente es obligatorio',
        cellphoneInputLabel: 'Teléfono móvil:',
        cellphoneInputPlaceholder: 'Ingresar teléfono',
        cellphoneInputRequiredError: '* El teléfono es obligatorio',
        regionInputLabel: 'Región:',
        regionInputPlaceholder: 'Ingresar región',
        regionInputRequiredError: '* La región es obligatoria',
        submitButton: 'Asociarme a PromeClub',
        modalSuccessTextDesktop:
          '<p>Agradecemos tu interés en participar del programa Promeclub. <br />Un asesor comercial se estará comunicando contigo.</ p>',
        modalSuccessTextMobile:
          '<p>Agradecemos tu interés en participar <br />del programa Promeclub. <br />Un asesor comercial se estará <br />comunicando contigo.</ p>',
        goHome: 'Ir al inicio',
      },
    },
  },
};
