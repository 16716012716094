<div class="quote-course-info">
  <app-custom-modal-generic></app-custom-modal-generic>

  <div class="quote-course-info__icon">
    <cx-icon class="fas fa-exclamation-triangle"></cx-icon>
  </div>
  <div
    class="quote-course-info__title"
    [innerHTML]="'accountQuotes.inCourse.title' | cxTranslate | safeHtml"
  ></div>

  <ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
    <div
      *ngIf="!data.isMobile"
      class="quote-course-info__message"
      [innerHTML]="'accountQuotes.inCourse.message' | cxTranslate | safeHtml"
    ></div>
    <div
      *ngIf="data.isMobile"
      class="quote-course-info__message"
      [innerHTML]="
        'accountQuotes.inCourse.messageMobile' | cxTranslate | safeHtml
      "
    ></div>
  </ng-container>

  <div class="quote-course-info__action">
    <button class="quote-course-info__button" (click)="goToQuoteList()">
      {{ "accountQuotes.inCourse.seeProposals" | cxTranslate }}
    </button>
  </div>
</div>
