import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { GenericLinkModule, IconModule } from '@spartacus/storefront';
import { CustomSafeHtmlModule } from '../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomSplittedBannerComponent } from './custom-splitted-banner.component';



@NgModule({
  declarations: [
    CustomSplittedBannerComponent
  ],
  imports: [
    CommonModule,
    CustomSafeHtmlModule,
    GenericLinkModule,
    IconModule,
    RouterModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        SplittedBannerListComponent: {
          component: CustomSplittedBannerComponent,
        },
      },
    }),
  ]
})
export class CustomSplittedBannerModule { }
