import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, ModalModule } from '@spartacus/storefront';
import { CustomModalGenericModule } from 'src/app/cms-components/custom/components/custom-modal/custom-modal-generic.module';
import { CustomEmailMaskModule } from 'src/app/pipes/custom-email-mask/custom-email-mask.module';
import { CustomPhoneMaskModule } from 'src/app/pipes/custom-phone-mask/custom-phone-mask.module';
import { CustomSafeHtmlModule } from '../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomClientNotActiveDialogComponent } from './custom-client-not-active-dialog/custom-client-not-active-dialog.component';
import { CustomCustomerRegisterSuccessfulComponent } from './custom-customer-register-successful/custom-customer-register-successful.component';
import { CustomRegisterAccountDataComponent } from './custom-register-account-data/custom-register-account-data.component';
import { CustomRegisterCustomerErrorInfoComponent } from './custom-register-customer-error-info/custom-register-customer-error-info.component';
import { CustomRegisterErrorDialogComponent } from './custom-register-error/custom-register-error-dialog.component';
import { CustomRegisterNonCustomerConfirmationComponent } from './custom-register-non-customer-confirmation/custom-register-non-customer-confirmation.component';
import { CustomRegisterProgressComponent } from './custom-register-progress/custom-register-progress.component';
import { CustomUploadRegisterProgressDialogComponent } from './custom-upload-register-progress/custom-upload-register-progress-dialog.component';
import { CustomerAlreadyExistsDialogComponent } from './customer-already-exists-dialog/customer-already-exists-dialog.component';
import { CustomRegisterEmployeePersonalDataComponent } from './custom-register-employee-personal-data/custom-register-employee-personal-data.component';
import { CustomEmailRegisterMaskModule } from 'src/app/pipes/custom-email-register-mask/custom-email-register-mask.module';




@NgModule({
  declarations: [
    CustomRegisterProgressComponent,
    CustomRegisterAccountDataComponent,
    CustomerAlreadyExistsDialogComponent,
    CustomUploadRegisterProgressDialogComponent,
    CustomRegisterNonCustomerConfirmationComponent,
    CustomRegisterErrorDialogComponent,
    CustomRegisterCustomerErrorInfoComponent,
    CustomCustomerRegisterSuccessfulComponent,
    CustomClientNotActiveDialogComponent,
    CustomRegisterEmployeePersonalDataComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    RouterModule,
    NgbModule,
    ModalModule,
    UrlModule,
    CustomSafeHtmlModule,
    CustomEmailMaskModule,
    CustomPhoneMaskModule,
    CustomModalGenericModule,
    CustomEmailRegisterMaskModule
  ],
  exports: [
    CustomRegisterProgressComponent,
    CustomRegisterAccountDataComponent,
    CustomUploadRegisterProgressDialogComponent,
    CustomRegisterNonCustomerConfirmationComponent,
    CustomRegisterErrorDialogComponent,
    CustomRegisterCustomerErrorInfoComponent,
    CustomCustomerRegisterSuccessfulComponent,
    CustomClientNotActiveDialogComponent,
    CustomRegisterEmployeePersonalDataComponent
  ],
})
export class CustomRegisterSharedModule { }
