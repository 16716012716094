import { Component } from '@angular/core';
import { CurrentProductService } from '@spartacus/storefront';
import { pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-product-bundle',
  templateUrl: './custom-product-bundle.component.html',
  styleUrls: ['./custom-product-bundle.component.scss'],
})
export class CustomProductBundleComponent {
  productBundle$: Observable<any> = this.currentProduct
    .getProduct()
    .pipe(pluck('comboItems'));
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  isSmallDesktop$: Observable<boolean> = this.customBreakpointService.isSmallDesktop$;

  showComboItem: number | undefined;
  maxComboItems: number = 4;

  constructor(
    private currentProduct: CurrentProductService,
    private customBreakpointService: CustomBreakpointService
  ) {}

  showMore(i: number) {
    this.showComboItem = i;
  }
  showLess() {
    this.showComboItem = undefined;
  }
}
