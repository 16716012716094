import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFacetReviewsComponent } from './custom-facet-reviews.component';
import { I18nModule } from '@spartacus/core';
import { GenericLinkModule, IconModule } from '@spartacus/storefront';
import { CustomProductReviewsOverviewModule } from 'src/app/cms-components/custom/components/custom-product-reviews-overview/custom-product-reviews-overview.module';

@NgModule({
  declarations: [CustomFacetReviewsComponent],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    GenericLinkModule,
    CustomProductReviewsOverviewModule,
  ],
  exports: [CustomFacetReviewsComponent],
})
export class CustomFacetReviewsModule {}
