import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { ICON_TYPE, ModalService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomLoadingSpinnerService } from '../../custom/services/custom-loading-spinner.service';

@Component({
  selector: 'app-custom-session-expired-dialog',
  templateUrl: './custom-session-expired-dialog.component.html',
  styleUrls: ['./custom-session-expired-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomSessionExpiredDialogComponent implements OnInit {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  iconTypes = ICON_TYPE;
  constructor(
    protected modalService: ModalService,
    private customBreakpointService: CustomBreakpointService,
    protected routingService: RoutingService,
    protected customLoadingSpinnerService: CustomLoadingSpinnerService
  ) {}

  ngOnInit(): void {
    this.customLoadingSpinnerService.hideSpinner();
  }

  dismissModal(reason?: any): void {
    this.modalService.dismissActiveModal(reason);
  }

  goLogin(reason?: any): void {
    this.modalService.dismissActiveModal(reason);
    this.routingService.go({ cxRoute: 'login' });
  }
}
