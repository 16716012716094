import { Injectable } from '@angular/core';
import { BaseSiteService, OccConfig } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomBaseSiteCheckerService {
  /*
    Depends of your use case, you can need a boolean or an observable boolean,
    this custom service provide you the both of them
  */

  constructor(
    private baseSiteService: BaseSiteService,
    private occConfig: OccConfig
  ) { }

  //Return an observable boolean
  public isPromeclub$: Observable<boolean> =
    this.baseSiteService.getActive().pipe(
      map(activeBaseSite => activeBaseSite === 'promeclubSite')
    );

  //Return a boolean
  isInPromeClub(): boolean {
    return this.occConfig?.context?.baseSite![0] === 'promeclubSite';
  }

}
