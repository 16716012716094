<ng-container>
  <div class="container-order-history">
    <!-- HEADER -->
    <div class="cx-order-history-header">
      <app-custom-title-my-account
        [title]="'returnRequestList.returnTitle' | cxTranslate"
        [lessMargin]="true"
      ></app-custom-title-my-account>
    </div>
    <ng-container
      *ngIf="{
        isMobile: isMobile$ | async
      } as type"
    >
      <!-- BODY -->
      <ng-container *ngIf="returnRequests$ | async as returnRequests; else spinnerLoading">
        <div class="cx-order-history-body">
          <ng-container
            *ngIf="returnRequests.pagination?.totalResults > 0; else noOrder"
          >
            <!-- Select Form and Pagination Top -->
            <div class="cx-order-history-filters top">

              <div class="cx-order-history-pagination">
                <app-custom-filter
                  [label]="'orderHistory.filterResults' | cxTranslate"
                  [entries]="quantities"
                  [classSelect]="'small-filter'"
                  [valueDefault]="pageSize"
                  (filterSelected)="filterQuantitySelected($event)"
                >
                </app-custom-filter>
                <app-custom-pagination
                  *ngIf="returnRequests.pagination.totalPages >= 1"
                  [pagination]="returnRequests.pagination"
                  (viewPageEvent)="customPageChangeSelected($event)"
                  [pageSelected]="selectedPage"
                ></app-custom-pagination>
              </div>
            </div>
            <!-- TABLE -->
            <app-custom-account-table
              [orders]="returnRequests"
              [currentPage]="'returns'"
              (goToDetails)="goToOrderDetail($event)"
            ></app-custom-account-table>
            <!-- Select Form and Pagination Bottom -->
            <div class="cx-order-history-sort">
              <div
                *ngIf="returnRequests.pagination.totalPages >= 1"
                class="cx-order-history-pagination"
              >
                <app-custom-pagination
                  [pagination]="returnRequests.pagination"
                  (viewPageEvent)="customPageChangeSelected($event)"
                  [pageSelected]="selectedPage"
                ></app-custom-pagination>
              </div>
            </div>
          </ng-container>

          <!-- NO ORDER CONTAINER -->
          <ng-template #noOrder>
            <div class="cx-order-history-no-order">
              <app-custom-empty-cart
                [fromMyAccount]="true"
              ></app-custom-empty-cart>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
<ng-template #spinnerLoading>
  <div class="cx-order-history-body cx-order-history-body__loading">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
