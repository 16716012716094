import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomPlaceOrderErrorsService {

  placeOrderErrorMessage$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  placeOrderErrorType$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  setPlaceOrderErrorMessage(value: string): void{
    this.placeOrderErrorMessage$.next(value);
  }

  getPlaceOrderErrorMessage(): Observable<string>{
    return this.placeOrderErrorMessage$.asObservable();
  }

  setPlaceOrderErrorType(value: string): void{
    this.placeOrderErrorType$.next(value);
  }

  getPlaceOrderErrorType(): Observable<string>{
    return this.placeOrderErrorType$.asObservable();
  }
}
