<ng-container *ngIf="stepTwo$ | async">
  <ng-container *ngIf="clientInfo$ | async as client">
    <div class="customer-account">
      <app-custom-register-account-data [clientInfo]="client">
      </app-custom-register-account-data>

      <form
        action=""
        class="customer-account__form"
        (ngSubmit)="customerFormSubmit(client)"
        [formGroup]="customerForm"
      >
        <div class="customer-account__form-info">
          <h4 class="customer-account__form-info-title">
            {{ "customRegister.userData" | cxTranslate }}
          </h4>
          <p class="customer-account__form-info-paragraph">
            {{ "customRegister.userDataParagraph" | cxTranslate }}
          </p>
        </div>
        <div class="customer-account__form-inputs">
          <label>
            <span class="label-content">
              {{ "customRegister.name" | cxTranslate }}
            </span>
            <input
              aria-required="true"
              formControlName="name"
              type="text"
              class="form-control"
              placeholder="{{ 'customRegister.namePlaceholder' | cxTranslate }}"
            />
            <p
              class="customer-register-form-error"
              *ngIf="
                customerForm.get('name')?.errors?.required &&
                customerForm.get('name')?.touched
              "
            >
              {{ "customRegister.nameRequired" | cxTranslate }}
            </p>
          </label>

          <label>
            <span class="label-content">
              {{ "customRegister.surname" | cxTranslate }}
            </span>
            <input
              aria-required="true"
              formControlName="surname"
              type="text"
              class="form-control"
              placeholder="{{
                'customRegister.surnamePlaceholder' | cxTranslate
              }}"
            />
            <p
              class="customer-register-form-error"
              *ngIf="
                customerForm.get('surname')?.errors?.required &&
                customerForm.get('surname')?.touched
              "
            >
              {{ "customRegister.surnameRequired" | cxTranslate }}
            </p>
          </label>

          <label>
            <span class="label-content">
              {{ "customRegister.cellphone" | cxTranslate }}
            </span>
            <div class="cellphone-container">
              <div
                class="cellphone-container__country-code"
                [ngClass]="{
                  'cellphone-error':
                    (customerForm.get('cellphone')?.errors?.required &&
                      customerForm.get('cellphone')?.touched) ||
                    (customerForm.get('cellphone')?.errors?.maxlength &&
                      customerForm.get('cellphone')?.touched) ||
                    (customerForm.get('cellphone')?.errors?.invalidCellphone &&
                      customerForm.get('cellphone')?.touched &&
                      !customerForm.get('cellphone')?.errors?.required)
                }"
              >
                <p class="cellphone-container__country-code-value">
                  {{ countryCode }}
                </p>
              </div>

              <input
                aria-required="true"
                formControlName="cellphone"
                type="text"
                class="form-control"
                placeholder="{{
                  'customRegister.cellphonePlaceholder' | cxTranslate
                }}"
              />
            </div>
            <p
              class="customer-register-form-error"
              *ngIf="
                customerForm.get('cellphone')?.errors?.required &&
                customerForm.get('cellphone')?.touched
              "
            >
              {{ "customRegister.cellphoneRequired" | cxTranslate }}
            </p>
            <p
              class="customer-register-form-error"
              *ngIf="
                customerForm.get('cellphone')?.errors?.maxlength &&
                customerForm.get('cellphone')?.touched
              "
            >
              {{ "nonCustomerRegister.invalidCellphone" | cxTranslate }}
            </p>
            <p
              class="customer-register-form-error"
              *ngIf="
                customerForm.get('cellphone')?.errors?.invalidCellphone &&
                customerForm.get('cellphone')?.touched &&
                !customerForm.get('cellphone')?.errors?.required
              "
            >
              {{ "nonCustomerRegister.invalidCellphone" | cxTranslate }}
            </p>
          </label>

          <label>
            <span class="label-content">
              {{ "customRegister.userEmail" | cxTranslate }}
            </span>
            <input
              aria-required="true"
              formControlName="email"
              type="text"
              class="form-control"
              placeholder="{{
                'customRegister.userEmailPlaceholder' | cxTranslate
              }}"
            />
            <p
              class="customer-register-form-error"
              *ngIf="
                customerForm.get('email')?.errors?.invalidEmail &&
                customerForm.get('email')?.touched
              "
            >
              {{ "customRegister.invalidUserEmail" | cxTranslate }}
            </p>
            <p
              class="customer-register-form-error"
              *ngIf="
                customerForm.get('email')?.errors?.required &&
                customerForm.get('email')?.touched
              "
            >
              {{ "customRegister.emailRequired" | cxTranslate }}
            </p>
          </label>
        </div>
        <ng-container *ngIf="{ status: validationStatus$ | async } as data">
          <div class="customer-account__form-validation">
            <h4 class="customer-account__form-validation-title">
              {{ "customRegister.dataValidation" | cxTranslate }}
            </h4>
            <p class="customer-account__form-validation-paragraph">
              {{ "customRegister.dataValidationParagraph" | cxTranslate }}
            </p>

            <button
              *ngIf="!data.status"
              class="customer-account__form-validation-button"
              (click)="openPinModal()"
              type="button"
              [disabled]="customerForm.invalid"
            >
              {{ "customRegister.pinRequest" | cxTranslate }}
            </button>
            <div
              class="customer-account__form-validation-status"
              *ngIf="data.status"
            >
              <span class="customer-account__form-validation-status-successful">
                <cx-icon class="fas fa-check"></cx-icon>
              </span>
              <span class="customer-account__form-validation-status-text">
                {{ "customRegister.successfulValidation" | cxTranslate }}
              </span>
            </div>
          </div>
          <div class="customer-account__form-action">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                formControlName="confirmCheck"
              />
              <label class="form-check-label" for="flexCheckDefault">
                {{ "customRegister.userCheck" | cxTranslate }}
              </label>
            </div>
            <button
              class="customer-account__form-action-button"
              type="submit"
              [disabled]="
                customerForm.invalid ||
                !customerForm.get('confirmCheck').value ||
                !data.status
              "
            >
              {{ "customRegister.continue" | cxTranslate }}
            </button>
          </div>
        </ng-container>
      </form>
    </div>
  </ng-container>
</ng-container>
