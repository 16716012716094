import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { CustomRegisterStepOrchestratorService } from '../services/custom-register-step-orchestrator.service';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'app-custom-register-non-customer',
  templateUrl: './custom-register-non-customer.component.html',
  styleUrls: ['./custom-register-non-customer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomRegisterNonCustomerComponent implements OnInit {
  subscription: Subscription = new Subscription();
  userId$: Observable<any> = this.userAccountFacade.get();

  constructor(
    protected userAccountFacade: UserAccountFacade,
    private router: Router,
    private customRegisterStepOrchestratorService: CustomRegisterStepOrchestratorService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.userId$.subscribe((value) => {
        if (value) {
          this.customRegisterStepOrchestratorService.setStepOneStatus(true);
          this.customRegisterStepOrchestratorService.setStepTwoStatus(false);
          this.customRegisterStepOrchestratorService.setStepThreeStatus(false);
          this.router.navigateByUrl('/');
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
