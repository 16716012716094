import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSortingComponent } from './custom-sorting.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CustomSortingComponent],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [CustomSortingComponent],
})
export class CustomSortingModule {}
