<ng-container *ngIf="component.data$ | async as data">
  <ng-container *ngIf="data.title">
    <p class="title" [innerHTML]="data.title | safeHtml"></p>
    <div class="title"></div>
  </ng-container>
  <ng-container *ngIf="data.headline">
    <p class="title title-bold" [innerHTML]="data.headline | safeHtml"></p>
    <div class="title"></div>
  </ng-container>
  <p
    *ngIf="data.question"
    class="question"
    [innerHTML]="data.question | safeHtml"
  ></p>
  <p
    *ngIf="data.content"
    class="question"
    [innerHTML]="data.content | safeHtml"
  ></p>
</ng-container>
