import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomClaimForm } from '../models/custom-claim-form.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomClaimAddService {
  claimFormData$: BehaviorSubject<CustomClaimForm | null> =
    new BehaviorSubject<CustomClaimForm | null>(null);
  stepOne$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  stepTwo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService
  ) {}

  getReasonsClaim(): Observable<any> {
    const url = this.occEndpointService.buildUrl('claimReasons');
    return this.http.get(url);
  }

  getOrderDetails(orderCode: string): Observable<any> {
    const url = this.occEndpointService.buildUrl('orderDetail', {
      urlParams: {
        userId: 'current',
        orderId: orderCode,
      },
    });
    return this.http.get(url);
  }

  getStepOneStatus(): Observable<boolean> {
    return this.stepOne$.asObservable();
  }

  getStepTwoStatus(): Observable<boolean> {
    return this.stepTwo$.asObservable();
  }

  setStepOneStatus(status: boolean): void {
    this.stepOne$.next(status);
  }

  setStepTwoStatus(status: boolean): void {
    this.stepTwo$.next(status);
  }

  reset(): void {
    this.stepOne$.next(true);
    this.stepTwo$.next(false);
  }

  postClaim(body: any): Observable<any> {
    const url = this.occEndpointService.buildUrl('postClaim');
    return this.http.post(url, body);
  }

  getAllClaims(
    pageSize: number | undefined,
    currentPage: number | undefined
  ): Observable<any> {
    const url = this.occEndpointService.buildUrl('getAllClaims', {
      queryParams: {
        pageSize,
        currentPage,
      },
    });
    return this.http.get(url);
  }
  getClaim(ticketId: string): Observable<any> {
    const url = this.occEndpointService.buildUrl('getClaim', {
      urlParams: {
        ticketId,
      },
    });
    return this.http.get(url);
  }
  deleteClaim(ticketId: string): Observable<any> {
    const url = this.occEndpointService.buildUrl('deleteClaim', {
      queryParams: {
        ticketId,
      },
    });
    return this.http.post(url, '');
  }
  setclaimForm(form: CustomClaimForm | null) {
    this.claimFormData$.next(form);
  }

  getclaimFormData(): Observable<CustomClaimForm | null> {
    return this.claimFormData$.asObservable();
  }
}
