import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CmsLinkComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomBrandsAndCategoriesService } from 'src/app/services/custom-brands-and-categories.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomGetImageUrlService } from 'src/app/services/custom-get-image-url.service';
import {
  CustomCategoryData,
  CustomCategoryList,
} from '../custom-category-list/model/custom-category-list.interface';

@Component({
  selector: 'app-custom-brand-list',
  templateUrl: './custom-brand-list.component.html',
  styleUrls: ['./custom-brand-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CustomBrandListComponent implements OnInit {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  seeMoreLink$: Observable<CmsLinkComponent | null>;
  title$: Observable<string>;
  items$: Observable<Observable<CustomCategoryData>[]>;
  constructor(
    private componentData: CmsComponentData<CustomCategoryList>,
    private customBreakpointService: CustomBreakpointService,
    private customGetImageUrl: CustomGetImageUrlService,
    private customBrandsAndCategoriesService: CustomBrandsAndCategoriesService
  ) {}

  ngOnInit(): void {
    this.seeMoreLink$ = this.componentData.data$.pipe(
      map((data: CustomCategoryList) =>
        !!data?.seeMoreLink ? data.seeMoreLink : null
      )
    );
    this.title$ = this.componentData.data$.pipe(
      map((data: CustomCategoryList) => data?.title)
    );
    this.items$ = this.componentData.data$.pipe(
      map((data) => data.categories.trim().split(' ')),
      map((codes) =>
        codes.map((code) =>
          this.customBrandsAndCategoriesService.getCategoryDataFromApi(code)
        )
      )
    );
  }

  getImageUrl(url: string): string {
    return this.customGetImageUrl.getImageUrl(url);
  }
}
