import { Injectable } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CustomPromeclubRegisterFormValidationService {
  constructor() {}

  customEmailValidation(control: FormControl): ValidationErrors | null {
    const value: string = control.value;
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!!!value) {
      return null;
    }
    const email: string = value.toLowerCase();
    const result: boolean = expression.test(email);
    if (!result) {
      return { invalidEmail: true };
    }
    return null;
  }

  customPhoneValidation(control: FormControl): ValidationErrors | null {
    const value: string = control.value;
    const expression: RegExp = /^\+?[0-9]+$/; // Expresión regular que permite un símbolo "+" opcional seguido de uno o más números.

    if (!!!value) {
      return null;
    }

    const phoneNumber: string = value.trim();
    const result: boolean = expression.test(phoneNumber);

    if (!result) {
      return { invalidCellphone: true };
    }

    return null;
  }
}
