import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomEmptyCartComponent } from './custom-empty-cart.component';
import { I18nModule } from '@spartacus/core';
import { CustomSafeHtmlModule } from '../../../../pipes/custom-safe-html/custom-safe-html.module';
import { IconModule } from '@spartacus/storefront';
import { CustomCartListHeaderModule } from '../custom-cart-list-header/custom-cart-list-header.module';
import { CustomCartListSummaryModule } from '../custom-cart-list-summary/custom-cart-list-summary.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    CustomEmptyCartComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    RouterModule,
    CustomSafeHtmlModule,
    CustomCartListHeaderModule,
    CustomCartListSummaryModule
  ],
  exports: [
    CustomEmptyCartComponent
  ]
})
export class CustomEmptyCartModule { }
