import { Component, Input } from '@angular/core';
import { Product } from '@spartacus/core';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';

@Component({
  selector: 'app-custom-product-card-review',
  templateUrl: './custom-product-card-review.component.html',
  styleUrls: ['./custom-product-card-review.component.scss'],
})
export class CustomProductCardReviewComponent {
  @Input() product: Product;
  @Input() isMobile: boolean;
  isInPromeclub = this.service.isInPromeClub();

  constructor(private service: CustomNavigationService) {}
}
