import { Component, OnInit, Renderer2 } from '@angular/core';
import { CmsService, Page } from '@spartacus/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-schema-injector',
  template: '',
})
export class SchemaInjectorComponent implements OnInit {
  constructor(private renderer: Renderer2, protected cmsService: CmsService) {}

  schemas: { [key: string]: string } = {
    "LandingPage2Template": 'home-schema'
  };

  ngOnInit() {
    this.checkPageId();
  }

  injectSchema(schema: string) {
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'type', 'application/ld+json');
    const head = this.renderer.selectRootElement('html', true).querySelector('head');
    this.renderer.appendChild(head, script);
    fetch(`/schemas/${schema}.json`)
      .then(response => response.json())
      .then(data => {
        script.textContent = JSON.stringify(data);
      })
      .catch(error => console.log(`Error fetching JSON schema: ${error}`));
  }

  removeSchema(schema: string) {
    const script = document.querySelector(
      `script[type="application/ld+json"]`
    );
    script?.parentNode?.removeChild(script);
  }

  checkPageId() {
    this.cmsService
      .getCurrentPage()
      .pipe(filter<Page>(Boolean))
      .subscribe((cmsPage) => {
        // Iteramos sobre las claves y removemos cada schema
        Object.keys(this.schemas).forEach(key => this.removeSchema(this.schemas[key]));
        const pageTemplate = cmsPage.template;
        if (pageTemplate && this.schemas[pageTemplate]) {
          this.injectSchema(this.schemas[pageTemplate]);
        } else {
          console.log(`No schema defined for page ${pageTemplate}`);
        }
      });
  }
}