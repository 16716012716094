import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomProductReviewsOverviewComponent } from './custom-product-reviews-overview.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CustomProductReviewsOverviewComponent],
  imports: [CommonModule, IconModule, I18nModule, RouterModule],
  exports: [CustomProductReviewsOverviewComponent],
})
export class CustomProductReviewsOverviewModule {}
