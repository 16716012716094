<ng-container *ngIf="clickedFrom$ | async">
  <div *ngIf="hasHeader" class="d-none d-md-block d-lg-block d-xl-block">
    <app-custom-cart-list-header [cart]="cart"></app-custom-cart-list-header>
  </div>
  <h3 class="total-cart-items" *ngIf="isMobileValue">
    {{ "cartItems.myCart" | cxTranslate : { quantity: items.length } }}
  </h3>
  <ng-container
    *ngIf="(isMobile$ | async) && cart.modifiedPreviousPriceMap.length > 0"
  >
    <app-custom-cart-important-messages
      [cart]="cart"
      (cartEmitter)="cart = $event"
    ></app-custom-cart-important-messages>
  </ng-container>
  <div
    class="cx-item-list-row"
    *ngFor="let item of itemsNew; let i = index"
    [ngClass]="{ 'with-border': cierre[i] && !isMobileValue }"
  >
    <div
      *ngIf="getControl(item) | async as control"
      class="cx-item-list-items"
      [class.is-changed]="control.get('quantity').disabled"
    >
      <app-custom-cart-item
        [productPosition]="i"
        [customItem]="item"
        [item]="item"
        [quantityControl]="control.get('quantity')"
        [readonly]="readonly"
        [promotionLocation]="promotionLocation"
        [options]="options"
        (updateQuantityEmmiter)="updatedQuantity = $event"
        [updatedQuantity]="updatedQuantity"
        [isMobileValue]="isMobileValue"
        (isMobileEmmiter)="isMobileValue = $event"
        [cart]="cart"
      >
      </app-custom-cart-item>
    </div>
  </div>
</ng-container>
