<ng-container
  *ngIf="{
    loaded: loaded$ | async,
    addedEntryWasMerged: addedEntryWasMerged$ | async,
    entry: entry$ | async,
    cart: cart$ | async,
    isMobile: isMobile$ | async,
    isPromeclub: isPromeclub$ | async
  } as data"
>
  <div #dialog [ngClass]="{ 'no-entry-div': !data.entry }">
    <ng-container *ngIf="data.loaded || modalIsOpen; else loading">
      <app-custom-modal-generic
        *ngIf="data.entry && !data.isMobile"
        (closeModal)="customDismissModal($event)"
        [isClose]="true"
      ></app-custom-modal-generic>
      <ng-container *ngIf="data.entry; else noStock">
        <div
          *ngIf="data.entry && !data.isMobile"
          class="cx-dialog-title modal-title"
          aria-live="polite"
          aria-atomic="true"
          [innerHTML]="
            data.isPromeclub
              ? ('addToCart.itemsAddedToYourCartPromeclub'
                | cxTranslate
                | safeHtml)
              : ('addToCart.itemsAddedToYourCart' | cxTranslate | safeHtml)
          "
        ></div>
        <div class="modal-header" *ngIf="data.entry && data.isMobile">
          <h4 class="modal-header__title" *ngIf="data.isPromeclub">
            <cx-icon class="fas fa-check"></cx-icon>
            {{ "addToCart.itemsAddedToYourCartPromeclubMobile" | cxTranslate }}
          </h4>
          <h4 class="modal-header__title" *ngIf="!data.isPromeclub">
            <cx-icon class="fas fa-check"></cx-icon>
            {{ "addToCart.itemsAddedToYourCartMobile" | cxTranslate }}
          </h4>
          <cx-icon
            class="fas fa-times"
            (click)="customDismissModal('close')"
          ></cx-icon>
        </div>
        <div
          class="cx-dialog-title modal-title error-title"
          *ngIf="errors.length && !data.entry"
        >
          <ng-container *ngFor="let error of errors">
            <span>{{ error }}</span>
          </ng-container>
        </div>
        <!-- Modal Body -->
        <div class="cx-dialog-body modal-body" *ngIf="data.entry">
          <div class="cx-dialog-row">
            <app-custom-added-to-cart-entry
              [entry]="data.entry"
              [quantity]="quantity"
              [isPdp]="true"
              [isMobile]="data.isMobile"
            >
            </app-custom-added-to-cart-entry>

            <!-- Total container -->
            <div class="cx-dialog-actions" *ngIf="data.cart">
              <!-- Actions -->
              <div class="cx-dialog-buttons">
                <a
                  [class.disabled]="form.dirty"
                  cxModal="dismiss"
                  cxModalReason="$event"
                  class="btn btn-secondary"
                  (click)="customDismissModal($event, false)"
                >
                  <ng-container *ngIf="!data.isPromeclub">
                    {{
                      (data.isMobile
                        ? "addToCart.continueBuyingMobile"
                        : "addToCart.continueBuying"
                      ) | cxTranslate
                    }}
                  </ng-container>
                  <ng-container *ngIf="data.isPromeclub">
                    {{ "addToCart.continueBuyingPromeclub" | cxTranslate }}
                  </ng-container>
                  <cx-icon class="fas fa-chevron-right"></cx-icon>
                </a>

                <a
                  [class.disabled]="form.dirty"
                  [routerLink]="{ cxRoute: 'cart' } | cxUrl"
                  cxModal="dismiss"
                  cxModalReason="$event"
                  class="btn btn-primary"
                  autofocus
                  (click)="customDismissModal($event)"
                >
                  {{ "addToCart.viewCart" | cxTranslate }}
                  <cx-icon class="fas fa-chevron-right"></cx-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noStock>
        <div
          class="custom-modal-container"
          [ngClass]="{ 'no-entry': !data.entry }"
        >
          <app-custom-modal-generic
            (closeModal)="customDismissModal($event)"
            [isClose]="true"
          ></app-custom-modal-generic>

          <div class="custom-modal-container__exclamation-alert">
            <cx-icon type="EXCLAMATION_TRIANGLE"></cx-icon>
          </div>
          <div class="custom-modal-container__out-of-stock-message">
            <span>
              <b>{{ "addToCart.customOutOfStock" | cxTranslate }}</b>
            </span>
            <span class="custom-modal-container__out-of-stock-message-text">
              {{ "addToCart.outOfStockMessage" | cxTranslate }}
            </span>
          </div>
          <div class="custom-modal-container__btn">
            <button
              class="btn btn-primary"
              (click)="customDismissModal($event)"
            >
              {{ "customRegister.continue" | cxTranslate }}
            </button>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <ng-template #loading>
      <div class="cx-dialog-title modal-title loading-title">
        {{ "addToCart.updatingCart" | cxTranslate }}
      </div>
      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body spinner">
        <div class="cx-dialog-row">
          <div class="col-sm-12 mb-5">
            <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>
