import {
  Component,
  ViewContainerRef,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import {
  SavedCartDetailsOverviewComponent,
  SavedCartDetailsService,
} from '@spartacus/cart/saved-cart/components';
import { TranslationService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-saved-cart-details-overview',
  templateUrl: './custom-saved-cart-details-overview.component.html',
  styleUrls: ['./custom-saved-cart-details-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomSavedCartDetailsOverviewComponent
  extends SavedCartDetailsOverviewComponent
  implements OnDestroy
{
  constructor(
    protected savedCartDetailsService: SavedCartDetailsService,
    protected translation: TranslationService,
    protected vcr: ViewContainerRef,
    protected launchDialogService: LaunchDialogService
  ) {
    super(savedCartDetailsService, translation, vcr, launchDialogService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
