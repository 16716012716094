import { Injectable } from '@angular/core';
import { BreadcrumbMeta, Page } from '@spartacus/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomHeaderCategoriesService } from 'src/app/services/custom-header-categories.service';
import { HeaderCategory } from '../../product/model/custom-gtmProduct.model';
import { CategoriesBreadcrumb } from '../model/categoriesBreadcrumb';
import { TranslationService } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class CustomCategoryPageBreadcrumbService {
  readonly INITIAL_STATE_CATEGORY_BREADCRUMB: CategoriesBreadcrumb = {
    code: '',
    image: { url: '' },
    name: '',
    superCategories: [],
    url: '',
  };
  readonly HOMEPAGE_ID: string = 'homepage';
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  categoryData$: BehaviorSubject<CategoriesBreadcrumb> =
    new BehaviorSubject<CategoriesBreadcrumb>(
      this.INITIAL_STATE_CATEGORY_BREADCRUMB
    );
  constructor(
    private customHeaderCategoriesService: CustomHeaderCategoriesService,
    private customBreakpointService: CustomBreakpointService,
    private translation: TranslationService
  ) {}

  resolveCategoryPageBreadcrumbs(data?: Page): Observable<BreadcrumbMeta[]> {
    if (data?.pageId == this.HOMEPAGE_ID) {
      return of([]);
    }
    return combineLatest([
      this.customHeaderCategoriesService.getCategoryDataFromHeader().pipe(
        filter((categories) => !!categories?.code),
        catchError((error) => []),
        map((categories: CategoriesBreadcrumb) => {
          if (
            categories?.superCategories?.length > 0 &&
            categories?.superCategories?.[0]?.code == '1'
          ) {
            categories?.superCategories?.shift();
          }
          let superCategories: CategoriesBreadcrumb[] =
            categories?.superCategories;
          superCategories?.push(categories);
          return superCategories;
        }),
        take(1)
      ),
      this.translation.translate('common.home'),
      this.isMobile$,
    ]).pipe(
      map(
        ([crumbs, label, isMobile]: [
          CategoriesBreadcrumb[],
          string,
          boolean
        ]) => {
          return isMobile
            ? this.resolveCategoryBreadcrumbDataMobile(crumbs, label)
            : this.resolveCategoryBreadcrumbData(crumbs, label);
        }
      )
    );
  }

  resolveCategoryBreadcrumbDataMobile(
    categoryBreadcrumbs: any[],
    label: string
  ): BreadcrumbMeta[] {
    this.categoryData$.next(this.INITIAL_STATE_CATEGORY_BREADCRUMB);
    return [
      { label: label, link: '/' },
      ...categoryBreadcrumbs
        .filter(
          (crumb) =>
            crumb.name != HeaderCategory.AUTOMOTRIZ &&
            crumb.name != HeaderCategory.FERRETERIA
        )
        .map((crumb) => ({
          label: crumb.nameMobile ? crumb.nameMobile : crumb.name,
          link: crumb.url,
        })),
    ];
  }

  resolveCategoryBreadcrumbData(
    categoryBreadcrumbs: any[],
    label: string
  ): BreadcrumbMeta[] {
    this.categoryData$.next(this.INITIAL_STATE_CATEGORY_BREADCRUMB);
    return [
      { label: label, link: '/' },
      ...categoryBreadcrumbs.map((crumb, index) => ({
        label: crumb.name,
        link: index === categoryBreadcrumbs.length - 1 ? null : crumb.url,
      })),
    ];
  }
}
