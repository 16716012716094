import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomRecaptchaService {

  captchaStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  setCaptchaStatus(value: boolean){
    this.captchaStatus$.next(value);
  }

  getCaptchaStatus(): Observable<boolean>{
    return this.captchaStatus$.asObservable();
  }
}
