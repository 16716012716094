import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Order } from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { Observable, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';
import { CustomPaymentRecordService } from '../../services/custom-payment-record.service';
import { CustomOrderRegisteredSuccessfullyDialogComponent } from './custom-order-registered-successfully-dialog/custom-order-registered-successfully-dialog.component';
import { CustomPaymentBanksService } from './custom-payment-banks.service';
import { PaymentBanks, PaymentRecord } from './model/payment-banks.interface';

@Component({
  selector: 'app-custom-payment-record',
  templateUrl: './custom-payment-record.component.html',
  styleUrls: ['./custom-payment-record.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomPaymentRecordComponent implements OnInit, OnDestroy {
  maxDate: number = new Date().getDate();
  maxMonth: number = new Date().getMonth();
  maxYear: number = new Date().getFullYear();

  filterDate = (d: Date | null): boolean => {
    const day = (d || new Date()).getDate();
    const month = (d || new Date()).getMonth();
    const year = (d || new Date()).getFullYear();

    if (
      month !== this.maxMonth &&
      month <= this.maxMonth &&
      year == this.maxYear
    ) {
      return true;
    }
    return (
      day <= this.maxDate && month == this.maxMonth && year == this.maxYear
    );
  };

  subscription: Subscription = new Subscription();
  showAddPaymentsModes: boolean = false;
  orderCode: string;
  showErrors: boolean = false;

  showFileTypeError: boolean = false;
  showUnknowErrorFile: boolean = false;

  transferDocs: Array<string> = [];
  depositDocs: Array<string> = [];

  order$: Observable<Order>;
  paymentBanks$: Observable<PaymentBanks[]>;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;

  paymentsModesForm = this.fb.group({
    transfers: this.fb.array([]),
    deposits: this.fb.array([]),
  }, { updateOn: 'change' });

  modalRef: ModalRef;

  transferLengthValidation: boolean;
  depositLengthValidation: boolean;

  constructor(
    private fb: FormBuilder,
    private activedRoute: ActivatedRoute,
    private customPaymentRecord: CustomPaymentRecordService,
    private customPaymentBanksService: CustomPaymentBanksService,
    private customBreakpointService: CustomBreakpointService,
    private datePipe: DatePipe,
    private cd: ChangeDetectorRef,
    protected modalService: ModalService
  ) {
    this.subscription.add(
      this.activedRoute.url.subscribe((data) => {
        this.orderCode = data[2]?.path;
      })
    );
  }

  get transfers() {
    return this.paymentsModesForm.get('transfers') as FormArray;
  }

  get deposits() {
    return this.paymentsModesForm.get('deposits') as FormArray;
  }

  ngOnInit(): void {
    this.order$ = this.customPaymentRecord.getOrderDetails(this.orderCode);
    this.paymentBanks$ = this.customPaymentBanksService.getPaymentBanks();
    this.controlsLengthValidation();
  }

  controlsLengthValidation() {
    this.transferLengthValidation = this.transfers?.controls.length == 0;
    this.depositLengthValidation = this.deposits?.controls.length == 0;
  }

  submitForm() {
    let payments: any = [];
    if (this.paymentsModesForm.status === 'VALID') {
      for (let i = 0; i < this.transfers.length; i++) {
        payments.push({
          amount: this.transfers.controls[i]?.get('amount')?.value.replace(',', '.'),
          bankCode: this.transfers.controls[i]?.get('bankCode')?.value,
          fileUid: this.transfers.controls[i]?.get('fileUid')?.value,
          transferDate: this.datePipe.transform(
            this.transfers.controls[i]?.get('transferDate')?.value,
            'yyyy-MM-dd'
          ),
          voucherNumber:
            this.transfers.controls[i]?.get('voucherNumber')?.value,
        });
      }
      for (let i = 0; i < this.deposits.length; i++) {
        payments.push({
          amount: this.deposits.controls[i]?.get('amount')?.value.replace(',', '.'),
          bankCode: this.deposits.controls[i]?.get('bankCode')?.value,
          fileUid: this.deposits.controls[i]?.get('fileUid')?.value,
          transferDate: this.datePipe.transform(
            this.deposits.controls[i]?.get('transferDate')?.value,
            'yyyy-MM-dd'
          ),
          voucherNumber: this.deposits.controls[i]?.get('voucherNumber')?.value,
        });
      }

      let data: PaymentRecord = {
        orderCode: this.orderCode,
        paymentRecords: payments,
      };

      this.subscription.add(
        this.customPaymentBanksService
          .addPaymentRecord(data)
          .subscribe((success) => {
            if (success) {
              this.paymentsModesForm.controls['deposits'].reset();
              this.paymentsModesForm.controls['transfers'].reset();
              this.transferDocs = [];
              this.depositDocs = [];
              this.showErrors = false;
              this.openModal(false, false);
            }
          })
      );
    } else {
      this.showErrors = true;
    }
  }

  openModal(showFileTypeError: boolean, showUnknowErrorFile: boolean) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(
      CustomOrderRegisteredSuccessfullyDialogComponent,
      {
        centered: true,
        size: 'lg',
      }
    );
    if (showFileTypeError) {
      modalInstance = this.modalRef.componentInstance;
      modalInstance.showFileTypeError = showFileTypeError;
      modalInstance.showUnknowErrorFile = showUnknowErrorFile;
      return;
    }
    if (showUnknowErrorFile) {
      modalInstance = this.modalRef.componentInstance;
      modalInstance.showFileTypeError = showFileTypeError;
      modalInstance.showUnknowErrorFile = showUnknowErrorFile;
      return;
    }

    modalInstance = this.modalRef.componentInstance;
    modalInstance.order = this.orderCode;
    modalInstance.isFromPaymentRecord = true;
  }

  getFile(event: any, paymentType: string, form: FormGroup, type: string) {
    let file: File = event?.target?.files[0];
    let docName: string = event?.target?.files[0].name;
    const validFile = this.validateFileExtension(file.type);
    const code = `${this.orderCode}${new Date(Date.now()).toISOString()}_doc`;

    if (validFile) {
      this.subscription.add(
        this.customPaymentBanksService
          .uploadDocument(file, code, paymentType)
          .pipe(
            catchError((error) => {
              this.showUnknowErrorFile = true;
              this.cd.detectChanges();
              return of(error?.error?.errors?.[0]?.type);
            })
          )
          .subscribe((response) => {
            if (!this.showUnknowErrorFile) {
              this.docName(docName, file.type, type);
              form.controls['fileUid'].setValue(response?.uuid);
              this.cd.detectChanges();
            } else {
              this.openModal(false, true);
              this.showUnknowErrorFile = false;
            }
          })
      );
    } else {
      this.openModal(true, false);
    }
  }

  validateFileExtension(fileType: string): boolean {
    if (
      fileType == 'application/pdf' ||
      fileType == 'image/jpeg' ||
      fileType == 'image/png'
    ) {
      return true;
    }
    return false;
  }

  docName(name: string, fileType: string, type: string) {
    if (name.length > 16) {
      if (fileType == 'application/pdf') {
        let n = name.slice(0, 11) + '.pdf';
        this.pushDocs(n, type);
      }
      if (fileType == 'image/jpeg') {
        let n = name.slice(0, 11) + '.jpeg';
        this.pushDocs(n, type);
      }
      if (fileType == 'image/png') {
        let n = name.slice(0, 12) + '.png';
        this.pushDocs(n, type);
      }

      return;
    } else {
      if (fileType == 'application/pdf') {
        this.pushDocs(name, type);
      }
      if (fileType == 'image/jpeg') {
        this.pushDocs(name, type);
      }
      if (fileType == 'image/png') {
        this.pushDocs(name, type);
      }
    }
  }

  pushDocs(nameDoc: string, type: string) {
    if (type == 'transfer') {
      this.transferDocs.push(nameDoc);
    }
    if (type == 'deposit') {
      this.depositDocs.push(nameDoc);
    }
  }

  deleteTransferPaymentMode(
    index: number,
    form: FormGroup,
    deleteFile: boolean
  ) {
    if (deleteFile) {
      this.subscription.add(
        this.customPaymentBanksService
          .deleteDocument(form.controls['fileUid'].value)
          .subscribe((response) => {
            form.controls['fileUid'].setValue(null);
            this.transferDocs.splice(index, 1);
            this.cd.detectChanges();
          })
      );
      return;
    }
    this.transfers.removeAt(index);
    this.transferDocs.splice(index, 1);
    this.subscription.add(
      this.customPaymentBanksService
        .deleteDocument(form.controls['fileUid'].value)
        .subscribe((response) => {
          form.controls['fileUid'].setValue(null);
          this.cd.detectChanges();
        })
    );
    this.controlsLengthValidation();
  }

  deleteDepositPaymentMode(
    index: number,
    form: FormGroup,
    deleteFile: boolean
  ) {
    if (deleteFile) {
      this.subscription.add(
        this.customPaymentBanksService
          .deleteDocument(form.controls['fileUid'].value)
          .subscribe((response) => {
            form.controls['fileUid'].setValue(null);
            this.depositDocs.splice(index, 1);
            this.cd.detectChanges();
          })
      );
      return;
    }
    this.deposits.removeAt(index);
    this.depositDocs.splice(index, 1);
    this.subscription.add(
      this.customPaymentBanksService
        .deleteDocument(form.controls['fileUid'].value)
        .subscribe((response) => {
          form.controls['fileUid'].setValue(null);
          this.cd.detectChanges();
        })
    );
    this.controlsLengthValidation();
  }

  addTransferPaymentMode() {
    this.showAddPaymentsModes = false;
    this.showErrors = false;
    this.transfers.push(
      this.fb.group({
        bankCode: [null, Validators.required],
        amount: [
          null,
          [
            Validators.required,
            this.customPaymentBanksService.customAmount,
          ],
        ],
        voucherNumber: [
          null,
          [
            Validators.required,
            this.customPaymentBanksService.customVaucher,
          ],
        ],
        transferDate: [null, Validators.required],
        fileUid: [null, Validators.required],
      }, { updateOn: 'change' })
    );
    this.controlsLengthValidation();
  }
  addDepositPaymentMode() {
    this.showAddPaymentsModes = false;
    this.showErrors = false;
    this.deposits.push(
      this.fb.group({
        bankCode: [null, Validators.required],
        amount: [
          null,
          [
            Validators.required,
            this.customPaymentBanksService.customAmount,
          ],
        ],
        voucherNumber: [
          null,
          [
            Validators.required,
            this.customPaymentBanksService.customVaucher,
          ],
        ],
        transferDate: [null, Validators.required],
        fileUid: [null, Validators.required],
      }, { updateOn: 'change' })
    );
    this.controlsLengthValidation();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
