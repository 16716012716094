import {
  Component,
  ElementRef,
  ViewEncapsulation,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  CmsNavigationComponent,
  CmsService,
  CmsLinkComponent,
} from '@spartacus/core';
import {
  CmsComponentData,
  FooterNavigationComponent,
  NavigationNode,
} from '@spartacus/storefront';
import { CustomNavigationService } from '../../services/custom-navigation.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';
import { CustomGetImageUrlService } from 'src/app/services/custom-get-image-url.service';
import { CustomGtmSearchSelectionDatalayerService } from 'src/app/common/services/custom-gtm-search-selection-datalayer.service';
import { CustomBaseSiteCheckerService } from 'src/app/services/custom-base-site-checker.service';
import { CustomTopLinkService } from 'src/app/services/custom-top.link.service';
import { PromesaSection } from 'src/app/common/models/promesa-section';
import { CustomCmsLinkComponent } from 'src/app/cms-components/custom/components/custom-top-link/model/custom-top-link.interface';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-custom-footer-navigation',
  templateUrl: './custom-footer-navigation.component.html',
  styleUrls: ['./custom-footer-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CustomFooterNavigationComponent
  extends FooterNavigationComponent
  implements OnInit
{
  showLinks$: BehaviorSubject<boolean[]> = new BehaviorSubject<boolean[]>([]);
  showLinks: boolean[] = [];
  navigationNode$: Observable<NavigationNode | null> = this.service
    .customGetNavigationNode(this.componentData.data$)
    .pipe(
      tap((navigationNode) => {
        navigationNode?.children?.forEach(() => this.showLinks.push(false));
        this.showLinks$.next(this.showLinks);
      })
    );
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  footerData$: Observable<CmsNavigationComponent> = this.componentData.data$;
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  PromesaSection = PromesaSection;
  linkItems: CustomCmsLinkComponent[] =
    this.customTopLinkService.getLinkItems();
  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: CustomNavigationService,
    protected customGetImageUrl: CustomGetImageUrlService,
    protected customBreakpointService: CustomBreakpointService,
    protected cmsService: CmsService,
    private customGtmDatalayerService: CustomGtmSearchSelectionDatalayerService,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    private el: ElementRef,
    private customTopLinkService: CustomTopLinkService
  ) {
    super(componentData, service);
  }

  ngOnInit(): void {
    if (this.isPromeclub) {
      this.el?.nativeElement?.parentElement?.classList?.add('promeclub');
    }
  }

  getImageUrl(url: string): string {
    return this.customGetImageUrl.getImageUrl(url);
  }

  footerEventClick(event: string) {
    event ? this.customGtmDatalayerService.footerEvent(event) : false;
  }

  navigateToNode(item: CmsLinkComponent): void {
    const sameLinkWithSection: CustomCmsLinkComponent = this.linkItems?.filter(
      (link) => link?.linkName == item?.linkName
    )[0];
    if (sameLinkWithSection?.section) {
      this.customTopLinkService.changeCurrentSection(
        sameLinkWithSection.section
      );
      window.open(item?.url, '_self');
    }
  }

  toggleVisibility(index: number, isMobile: boolean = false): void {
    if (!isMobile) {
      return;
    }
    let showLinksCurrentValue = this.showLinks$.value;
    showLinksCurrentValue[index] = !showLinksCurrentValue[index];
    this.showLinks$.next(showLinksCurrentValue);
  }
}
