export const customCustomerRegisterValidationForm = {
  customerValidation: {
    customerValidation: {
      title: 'Cliente Promesa',
      customerCode: {
        label: 'Código de cliente',
        placeholder: 'Código Nro xxxx',
        required: '* Debe ingresar un código de cliente.',
        invalid: '* Código de cliente incorrecto.'
      },
      ruc: {
        label: 'RUC:',
        placeholder: 'Ingresar RUC',
        required: '* Debe ingresar un código de RUC.',
        invalid: '* Código de RUC incorrecto.'
      },
      button:{
        text: 'Continuar'
      },
    }

  },
};
