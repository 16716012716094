import { Component, ViewEncapsulation } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { ModalService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomPlaceOrderErrorsService } from 'src/app/feature-libs/checkout/services/custom-place-order-errors.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-promeclub-checkout-alert-dialog',
  templateUrl: './custom-promeclub-checkout-alert-dialog.component.html',
  styleUrls: ['./custom-promeclub-checkout-alert-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomPromeclubCheckoutAlertDialogComponent {
  errorMessage$: Observable<string> =
    this.customPlaceOrderErrorsService.getPlaceOrderErrorMessage();
  errorType$: Observable<string> =
    this.customPlaceOrderErrorsService.getPlaceOrderErrorType();
  errorCart: string = 'El carro no se ha calculado';
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    private modalService: ModalService,
    private routingService: RoutingService,
    private customPlaceOrderErrorsService: CustomPlaceOrderErrorsService,
    private customBreakpointService: CustomBreakpointService
  ) {}

  closeModal(reason: string) {
    this.modalService.dismissActiveModal(reason);
    this.modalService.closeActiveModal(reason);
    if (reason === 'navigate') {
      this.routingService.go({ cxRoute: 'cart' });
    }
  }
}
