import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutModule } from './checkout/checkout.module';

// Module for custom "Cms" and "Non-Cms" Components of Spartacus Feature Libs (Spartacus/feature-libs).
@NgModule({
  declarations: [],
  imports: [CommonModule, CheckoutModule],
})
export class FeatureLibsModule {}
