<ng-container #userBloqued >
  <div class="custom-user-bloqued">
    <app-custom-modal-generic></app-custom-modal-generic>
    <div class="custom-user-bloqued__body">
        <p class="custom-user-bloqued__body-title">{{
          "userBloqued.textBloqued" | cxTranslate
        }}
        <a (click)="goToForgetPassword()">{{
          "userBloqued.hereBloqued" | cxTranslate
        }}</a>
      </p>
    </div>
  </div>
</ng-container>
