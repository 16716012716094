<div class="wish-item" *ngIf="!isMobile; else mobileWishItem">
  <div class="wish-item__image">
    <app-custom-gift-product
      *ngIf="entry?.product?.approvalCode == UNAPPROVED_STATUS"
      label="{{ 'addToCart.outOfStock' | cxTranslate }}"
    ></app-custom-gift-product>
    <cx-media
      *ngIf="entry.product?.images"
      [container]="entry?.product?.images"
      format="thumbnail"
      role="presentation"
    >
    </cx-media>
  </div>
  <div class="wish-item__code">
    {{ entry?.product?.promotickWebCode }}
  </div>
  <div class="wish-item__name" [routerLink]="entry?.product?.url">
    {{ entry?.product?.name }}
  </div>
  <div class="wish-item__points">
    {{ entry?.product?.price?.value | number : "1.0-0" : "es-AR" }}
    <span class="wish-item__points-text">
      {{ "customWishlist.points" | cxTranslate }}
    </span>
  </div>
  <div class="wish-item__quantity">
    <app-custom-item-counter
      [control]="wishForm.get('quantity')"
      [allowZero]="true"
      [min]="0"
    >
    </app-custom-item-counter>
  </div>
  <div class="wish-item__points">
    {{
      entry?.product?.price?.value * wishForm?.get("quantity")?.value
        | number : "1.0-0" : "es-AR"
    }}
    <span class="wish-item__points-text">
      {{ "customWishlist.points" | cxTranslate }}
    </span>
  </div>
  <div class="wish-item__add">
    <button
      class="wish-item__add-button"
      type="button"
      [disabled]="
        wishForm.get('quantity').value < 1 ||
        entry?.product?.approvalCode == UNAPPROVED_STATUS
      "
      (click)="customAddToCart()"
    >
      <span class="wish-item__add-button-text">
        {{ "customWishlist.add" | cxTranslate }}
      </span>
      <cx-icon class="fas fa-shopping-cart"></cx-icon>
    </button>
  </div>
  <div class="wish-item__remove">
    <cx-icon class="far fa-trash-alt" (click)="deleteFromWishlist()"></cx-icon>
  </div>
</div>
<ng-template #mobileWishItem>
  <div class="mobile-item" *ngIf="!loading">
    <div class="mobile-item-top">
      <div class="mobile-item-top__image">
        <app-custom-gift-product
          *ngIf="entry?.product?.approvalCode == UNAPPROVED_STATUS"
          label="{{ 'addToCart.outOfStock' | cxTranslate }}"
        ></app-custom-gift-product>
        <cx-media
          *ngIf="entry.product?.images"
          [container]="entry?.product?.images"
          format="thumbnail"
          role="presentation"
        ></cx-media>
      </div>
      <div class="mobile-item-top__data">
        <div class="mobile-item-top__data-brand">
          {{ entry.product?.brand?.name }}
        </div>
        <div
          class="mobile-item-top__data-name"
          [routerLink]="entry?.product?.url"
        >
          {{ entry.product?.name }}
        </div>
        <div class="mobile-item-top__data-points">
          {{
            entry?.product?.price?.value * wishForm.get("quantity").value
              | number : "1.0-0" : "es-AR"
          }}
          <span class="mobile-item-top__data-points-text">
            {{ "customWishlist.points" | cxTranslate }}
          </span>
        </div>
      </div>
      <div class="mobile-item-top__remove">
        <cx-icon
          class="far fa-trash-alt"
          (click)="deleteFromWishlist()"
        ></cx-icon>
      </div>
    </div>
    <div class="mobile-item-bottom">
      <div class="mobile-item-bottom__quantity">
        <app-custom-item-counter
          [control]="wishForm.get('quantity')"
          [allowZero]="true"
          [min]="0"
        >
        </app-custom-item-counter>
      </div>

      <div class="mobile-item-botton__add">
        <button
          class="mobile-item-bottom__add-button"
          [disabled]="
            wishForm.get('quantity').value < 1 ||
            entry?.product?.approvalCode == UNAPPROVED_STATUS
          "
          (click)="openPreAddToCart()"
        >
          <span class="mobile-item-bottom__add-button-text">
            {{ "customWishlist.add" | cxTranslate }}
          </span>
          <cx-icon class="fas fa-shopping-cart"></cx-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
