import { Component, ViewEncapsulation } from '@angular/core';
import { AuthService, CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { filter, map, tap, take } from 'rxjs/operators';
import { CustomModalService } from 'src/app/cms-components/shared/components/custom-modal/services/custom-modal.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomUserPointsService } from 'src/app/services/custom-user-points.service';

@Component({
  selector: 'app-custom-points-header',
  templateUrl: './custom-points-header.component.html',
  styleUrls: ['./custom-points-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomPointsHeaderComponent {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  showMyPoints = new Subject<boolean>();
  isUserLoggedIn$: Observable<boolean> = this.auth.isUserLoggedIn();
  availablePoints$: Observable<number> = this.customUserPointsService
    .getUserPoints()
    .pipe(
      take(1),
      filter((userPoints) => userPoints?.code == 100),
      tap((userPoints) =>
        this.customUserPointsService.setUserPoints(userPoints)
      ),
      map((userPoints) => userPoints?.pointsData?.availablePoints!)
    );
  constructor(
    private customBreakpointService: CustomBreakpointService,
    protected cmsComponentData: CmsComponentData<any>,
    protected service: CmsService,
    public customModalService: CustomModalService,
    private auth: AuthService,
    private customUserPointsService: CustomUserPointsService
  ) {}

  setShowPoints(pointsBoolean: boolean) {
    this.customModalService.show('default');
    this.showMyPoints.next(pointsBoolean);
    if (!pointsBoolean) {
      this.customModalService.hide('default');
    }
  }
}
