<ng-container *ngIf="{
  points: points$ | async,
  product: product$ | async
} as data">
  <ng-container *ngIf="(hasStock || addToCartPdp) && data?.points > 0">
    <div class="points-promeclub" [ngClass]="{ 'add-to-cart-pdp-mobile': addToCartPdp }">
      <cx-icon class="fas fa-gift"></cx-icon>
      <span>
        {{
        "addToCart.bottomSubmitText"
        | cxTranslate : { points: data?.points | number : "1.0-2" : "es-AR" }
        }}</span>
    </div>
  </ng-container>
</ng-container>
