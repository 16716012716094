<ng-container *ngIf="isMobile$ | async as isMobile; else atributtesDesktop">
  <app-custom-product-bundle></app-custom-product-bundle>
  <ng-container *ngFor="let attr of attributes$ | async">
    <div class="product-sped" *ngIf="attr.name && attr.showInPdp">
      <app-custom-product-attributes-accordion
        [titleAttribute]="attr.name | titlecase"
        [collapseFromIcon]="true"
        attributes
      >
        <div
          class="product-spec__cards-details"
          *ngIf="attr.showInPdp"
          [ngClass]="{ technical: attr?.code == 'ET' }"
        >
          <div *ngFor="let feat of attr.features">
            <app-custom-product-card-details
              [cardName]="attr.name"
              [feature]="feat"
            ></app-custom-product-card-details>
          </div>
        </div>
      </app-custom-product-attributes-accordion>
    </div>
  </ng-container>
  <ng-container
    *ngIf="{
      manualProduct: manualProduct$ | async,
      technicalDocuments: technicalDocuments$ | async
    } as manualDownload"
  >
    <app-custom-product-attributes-accordion
      *ngIf="
        manualDownload.manualProduct ||
        manualDownload.technicalDocuments?.length > 0
      "
      [titleAttribute]="title$ | async"
      [collapseFromIcon]="true"
      attributes
    >
      <ng-container
        *ngTemplateOutlet="
          manuals;
          context: {
            manualDownload
          }
        "
      ></ng-container>
    </app-custom-product-attributes-accordion>
  </ng-container>
  <app-custom-product-reviews
    *ngIf="!isInPromeclub"
  ></app-custom-product-reviews>
</ng-container>

<ng-template #atributtesDesktop>
  <app-custom-product-bundle></app-custom-product-bundle>
  <div *ngFor="let attr of attributes$ | async" class="product-spec">
    <app-custom-component-title
      [title]="attr.name | titlecase"
      [hasFirsWordInBold]="true"
      *ngIf="attr.name && attr.showInPdp"
    >
    </app-custom-component-title>
    <div class="product-spec__cards-details" *ngIf="attr.showInPdp">
      <div *ngFor="let feat of attr.features">
        <app-custom-product-card-details
          [cardName]="attr.name"
          [feature]="feat"
        ></app-custom-product-card-details>
      </div>
    </div>
  </div>
  <ng-container
    *ngIf="{
      manualProduct: manualProduct$ | async,
      technicalDocuments: technicalDocuments$ | async
    } as manualDownload"
  >
    <app-custom-component-title
      *ngIf="
        (manualDownload.manualProduct ||
          manualDownload.technicalDocuments?.length > 0) &&
          title$ | async as title
      "
      [title]="title"
      [hasFirsWordInBold]="true"
    >
    </app-custom-component-title>
    <ng-container
      *ngTemplateOutlet="
        manuals;
        context: {
          manualDownload
        }
      "
    ></ng-container>
  </ng-container>
  <app-custom-product-reviews
    *ngIf="!isInPromeclub"
  ></app-custom-product-reviews>
  <ng-container *ngIf="customProduct$ | async as product">
    <app-custom-product-terms
      *ngIf="isInPromeclub"
      [product]="product"
    ></app-custom-product-terms>
  </ng-container>
</ng-template>
<ng-template #manuals let-manualDownload="manualDownload">
  <div
    class="product-spec__cards-downloads"
    *ngIf="
      manualDownload.manualProduct ||
      manualDownload.technicalDocuments?.length > 0
    "
  >
    <app-custom-product-card-downlowads
      *ngIf="manualDownload.manualProduct"
      [manualProduct]="manualDownload.manualProduct"
      [title]="'TabPanelContainer.download' | cxTranslate"
      [subtitle]="'TabPanelContainer.manualProducts' | cxTranslate | lowercase"
    ></app-custom-product-card-downlowads>

    <div *ngFor="let document of manualDownload.technicalDocuments">
      <app-custom-product-card-downlowads
        *ngIf="manualDownload.technicalDocuments?.length > 0"
        [manualProduct]="document"
        [title]="'TabPanelContainer.download' | cxTranslate"
        [subtitle]="
          'TabPanelContainer.technicalDocuments' | cxTranslate | lowercase
        "
      ></app-custom-product-card-downlowads>
    </div>
  </div>
</ng-template>
