<div class="entry-container" [ngClass]="{ one: onlyOne, mobile: isMobile }">
  <div class="entry-container__image">
    <cx-media
      *ngIf="entry.product?.images?.PRIMARY"
      [container]="entry.product.images?.PRIMARY"
      format="mobile"
    ></cx-media>
  </div>
  <div class="entry-container__data">
    <p class="entry-container__data-brand">
      {{ entry.product?.brand?.name }}
    </p>
    <ng-container *ngIf="!isMobile">
      <p class="entry-container__data-name">
        {{ entry?.product?.name }}
      </p>
      <p class="entry-container__data-sku">
        {{ "checkoutStock.entry.sku" | cxTranslate }}
        {{ entry?.product?.code }}
      </p>
    </ng-container>
    <ng-container *ngIf="isMobile">
      <p class="entry-container__data-sku">
        {{ "checkoutStock.entry.sku" | cxTranslate }}
        {{ entry?.product?.code }}
      </p>
      <p class="entry-container__data-name">
        {{ entry?.product?.name }}
      </p>
    </ng-container>
    <p class="entry-container__data-quantity" *ngIf="!isMobile">
      {{ "checkoutStock.entry.qtyRequested" | cxTranslate }}

      <span class="entry-container__data-quantity value">{{
        entry?.quantity
      }}</span>
    </p>
    <p class="entry-container__data-quantity stock" *ngIf="!isMobile">
      {{ "checkoutStock.entry.stockAvailable" | cxTranslate }}
      <span class="entry-container__data-quantity value stock">{{
        entry?.availableStock
      }}</span>
    </p>
  </div>
</div>
<ng-container *ngIf="isMobile">
  <div class="entry__container__quantities" [ngClass]="{ one: onlyOne }">
    <div class="entry__container__quantities__box">
      <p>
        {{ entry?.quantity }}
      </p>
      <span>
        {{ "checkoutStock.entry.qtyRequestedMobile" | cxTranslate }}
      </span>
    </div>
    <div class="entry__container__quantities__box">
      <p class="stock">
        {{ entry?.availableStock }}
      </p>
      <span class="stock">{{
        "checkoutStock.entry.stockAvailableMobile" | cxTranslate
      }}</span>
    </div>
  </div>
</ng-container>
