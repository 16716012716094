<h2 class="custom-component-title">
  <ng-container *ngIf="boldTitle">
    <span
      class="custom-component-title-bold"
      >
      {{boldTitle}}
    </span>
  </ng-container>

  {{ !!title ? title : '' }}
</h2>
