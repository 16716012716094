<ng-container *ngIf="pageTemplate$ | async as pageTemplate">
  <div *ngIf="isReadOnly; else editableCoupons">
    <div *ngIf="sortedVouchers.length > 0">
      <div
        *ngFor="let voucher of sortedVouchers"
        class="cx-coupon-card-grid"
        role="filter"
      >
        <div class="cx-coupon-apply cx-coupon-card cx-coupon-list-wrap">
          <span class="cx-cart-coupon-code cx-cart-coupon-code__account mr-2">{{
            voucher.voucherCode
          }}</span>
        </div>
        <div class="cx-coupon-card-state read-only-state">
          {{ "voucher.vouchersApplied" | cxTranslate }}
        </div>
      </div>
    </div>
  </div>

  <ng-template #editableCoupons>
    <div
      *ngFor="let voucher of sortedVouchers"
      class="cx-coupon-card-grid"
      role="filter"
    >
      <div class="cx-coupon-apply cx-coupon-card cx-coupon-list-wrap">
        <span class="cx-cart-coupon-code">{{ voucher.voucherCode }}</span>
        <button
          *ngIf="pageTemplate.template != 'AccountPageTemplate'"
          type="button"
          class="close"
          [attr.aria-label]="'common.close' | cxTranslate"
          (click)="removeVoucher(voucher.voucherCode)"
          [disabled]="cartIsLoading"
          [class.disabled]="cartIsLoading"
        >
          <span aria-hidden="true">
            <cx-icon class="far fa-times-circle"></cx-icon>
          </span>
        </button>
      </div>
      <div class="cx-coupon-card-state">
        {{ "voucher.vouchersApplied" | cxTranslate }}
      </div>
    </div>
  </ng-template>
</ng-container>
