import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { CustomBreakpointService } from '../../services/custom-breakpoint.service';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

@Component({
  selector: 'app-header-secure-checkout-outlet',
  templateUrl: './header-secure-checkout-outlet.component.html',
  styleUrls: ['./header-secure-checkout-outlet.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderSecureCheckoutOutletComponent implements OnInit {

  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  isCheckout$: Observable<boolean>
  constructor(
    private customBreakpointService: CustomBreakpointService,
    private cmsService: CmsService
  ) { }

  ngOnInit(): void {
    this.isCheckout$ = this.cmsService.getCurrentPage().pipe(
      pluck('template'),
      map(template => template === 'MultiStepCheckoutSummaryPageTemplate')
    );
  }

}
