import { SAVED_CART_RESTORE_CART_PROCESS_ID } from '@spartacus/cart/saved-cart/core';
import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';

export const CUSTOM_RESTORE_SAVED_CART =
  '[Saved Cart] Custom Restore Saved Cart';

export class CustomRestoreSavedCart extends StateUtils.EntityLoadAction {
  readonly type = CUSTOM_RESTORE_SAVED_CART;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
    }
  ) {
    super(PROCESS_FEATURE, SAVED_CART_RESTORE_CART_PROCESS_ID);
  }
}

export type CustomSavedCartActions = CustomRestoreSavedCart;
