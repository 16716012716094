import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { LoginFormComponent } from '@spartacus/user/account/components';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CustomPinDialogComponent } from 'src/app/common/components/custom-pin-dialog/custom-pin-dialog.component';
import { CustomB2bUnitValidationService } from 'src/app/common/services/custom-b2b-unit-validation.service';
import { CustomGtmLoginService } from 'src/app/common/services/custom-gtm-login-register-datalayer.service';
import { CustomTwilioValidatorService } from 'src/app/common/services/custom-twilio-validator.service';
import { CustomBreakpointService } from '../../../services/custom-breakpoint.service';
import { CustomLoginFormErrorsService } from '../../../services/custom-login-form-errors.service';
import { EventSingInType } from '../../product/model/custom-gtmProduct.model';
import { CustomModalService } from '../../shared/components/custom-modal/services/custom-modal.service';
import { CustomB2bValidationDialogComponent } from '../custom-b2b-validation-dialog/custom-b2b-validation-dialog.component';
import { CustomForgotPasswordComponent } from '../custom-forgot-password/custom-forgot-password.component';
import { Attempts } from '../models/custom-login-attemps.interface';
import { CustomLoginAttempsHandlerService } from './services/custom-login-attemps-handler.service';
import { CustomLoginFormComponentService } from './services/custom-login-form-component.service';
import { CustomScrollService } from 'src/app/services/custom-scroll.service';

@Component({
  selector: 'app-custom-login-form',
  templateUrl: './custom-login-form.component.html',
  styleUrls: ['./custom-login-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomLoginFormComponent
  extends LoginFormComponent
  implements OnDestroy, OnInit
{
  @Input() fromModal: boolean = false;
  @Input() modalInstance: string;
  @Input() fromPdp: boolean = false;
  errorMessage$: Observable<string | null> =
    this.customLoginFormErrorsService.showMessage();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  currentYear = new Date(Date.now()).getFullYear();
  viewFormInPdp: boolean = false;
  submit: boolean = false;
  isEmailDirty: boolean = false;
  modalRef: ModalRef;
  instance = 'Login_Form';
  private unsubscribe$ = new Subject<void>();
  attempts$: Observable<Attempts | null> =
    this.customLoginAttempsHandlerService.getAttempts();

  subscription: Subscription = new Subscription();
  validationStatus$: Observable<boolean> = this.customTwilioValidatorService
    .getValidationStatus()
    .pipe(map((status) => status === 'OK'));
  eventSingInType = EventSingInType;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkedCoords = false;
    if (this.LoginFormBtnSubmit?.nativeElement) {
      this.btnSubmitInitialCoords =
        this.LoginFormBtnSubmit.nativeElement.getBoundingClientRect();
      this.checkAddToCartCoords(this.btnSubmitInitialCoords, window.scrollY);
    }
  }

  @ViewChild('LoginFormBtnSubmit') LoginFormBtnSubmit: ElementRef;
  btnSubmitInitialCoords: DOMRect;
  btnSubmitInitialCoordsBottom: number;
  checkedCoords: boolean = false;
  scrollPositionY: number = 0;
  @Input() floatingSelector: boolean;

  constructor(
    private modalService: ModalService,
    private customTwilioValidatorService: CustomTwilioValidatorService,
    protected service: CustomLoginFormComponentService,
    protected customModalService: CustomModalService,
    protected customLoginFormErrorsService: CustomLoginFormErrorsService,
    protected customBreakpointService: CustomBreakpointService,
    private routingService: RoutingService,
    protected cd: ChangeDetectorRef,
    protected customLoginAttempsHandlerService: CustomLoginAttempsHandlerService,
    private customLoginLayerService: CustomGtmLoginService,
    private customB2bUnitValidationService: CustomB2bUnitValidationService,
    private customScrollService: CustomScrollService
  ) {
    super(service);
  }

  ngOnInit(): void {
    this.submit = false;
    this.form.reset();
    this.removeError();
    this.customLoginFormErrorsService.closeDialogLogin$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => this.customModalService.hide(this.instance))
      )
      .subscribe();

    this.subscription.add(
      this.validationStatus$.subscribe((isValid) => {
        if (isValid) {
          this.service.login();
        }
      })
    );

    this.customScrollService.getScrollY().subscribe((scrollY) => {
      if (this.LoginFormBtnSubmit?.nativeElement) {
        this.checkAddToCartCoords(
          this.LoginFormBtnSubmit?.nativeElement.getBoundingClientRect(),
          scrollY
        );
      }
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.openTwilioValidation();
    }
  }

  onLogInEvent(userId: string) {
    this.service.loginSuccess$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((value) => {
          if (value) {
            this.customLoginLayerService.signInSuccessfullyEvent(
              this.eventSingInType.LOGIN_SUCCESSFULLY,
              userId
            );
          } else {
            this.customLoginLayerService.signInSuccessfullyEvent(
              this.eventSingInType.LOGIN_ERROR,
              userId
            );
          }
        })
      )
      .subscribe();
  }

  removeError() {
    this.customLoginFormErrorsService.removeMessage();
  }

  navigateOnClick(url: any, event: string) {
    this.signInEvent(event);
    this.customModalService.hide(this.modalInstance);
    this.routingService.go({ cxRoute: url });
  }

  openModal(event: string) {
    this.signInEvent(event);
    this.customModalService.hide(this.instance);
    let modalInstance: any;

    this.modalRef = this.modalService.open(CustomForgotPasswordComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = this.modalRef.componentInstance;
  }

  openTwilioValidation() {
    let modalInstance: any;
    const email = this.form.controls['userId']?.value;

    this.subscription.add(
      this.customB2bUnitValidationService
        .getb2bUnitValidation(email)
        .pipe(
          switchMap((isB2bUnitActive) => {
            if (!isB2bUnitActive) {
              this.modalService.closeActiveModal();
              this.modalRef = this.modalService.open(
                CustomB2bValidationDialogComponent,
                {
                  centered: true,
                  size: 'lg',
                }
              );
              return of();
            } else {
              this.modalService.closeActiveModal();
              this.modalRef = this.modalService.open(CustomPinDialogComponent, {
                centered: false,
                size: 'lg',
                windowClass: 'twilio',
              });

              modalInstance = this.modalRef.componentInstance;
              modalInstance.email = email;

              return combineLatest([
                this.customTwilioValidatorService.getValidationStatus().pipe(
                  take(1),
                  map((status) => {
                    if (status === 'FALSE') {
                      this.customTwilioValidatorService.setValidationStatus('');
                    }
                  })
                ),
                this.customTwilioValidatorService
                  .sendCodeToPhoneUsingEmail(email)
                  .pipe(take(1)),
              ]);
            }
          })
        )
        .subscribe()
    );
  }

  toggleViewFormInPdp() {
    this.viewFormInPdp = !this.viewFormInPdp;
    this.cd.detectChanges();
  }

  signInEvent(event: string) {
    this.customLoginLayerService.signInEvent(event);
  }

  onCustomSubmit(event: string) {
    this.submit = true;
    if (
      this.form.controls['userId'].errors?.['cxInvalidEmail'] &&
      !this.form.controls['userId'].errors?.['required']
    )
      this.isEmailDirty = true;
    this.signInEvent(event);
    this.onSubmit();
    this.customLoginAttempsHandlerService.setEmail(
      this.form.controls['userId'].value
    );
    this.onLogInEvent(this.form.controls['userId'].value);
  }

  onKey(event: any) {
    this.submit = false;
    this.isEmailDirty = false;
  }

  blurValidationEmail(): void {
    if (this.form.controls['userId'].invalid) {
      this.submit = true;
      this.isEmailDirty = true;
    }
  }

  checkAddToCartCoords(
    btnSubmitCoords: DOMRect,
    currentScrollPosition: number
  ): void {
    let currentClientHeightHeader =
      document.getElementsByTagName('header')?.[0]?.clientHeight;
    if (
      this.btnSubmitInitialCoords?.bottom !== btnSubmitCoords.bottom &&
      !this.checkedCoords &&
      !this.floatingSelector
    ) {
      this.scrollPositionY = window.scrollY;
      this.btnSubmitInitialCoords =
        this.LoginFormBtnSubmit?.nativeElement.getBoundingClientRect();
      this.btnSubmitInitialCoordsBottom =
        this.btnSubmitInitialCoords.bottom - currentClientHeightHeader;
      this.checkedCoords = true;
    } else {
      if (!this.floatingSelector) {
        this.customScrollService.showFloatingAddToCartBottom(
          this.btnSubmitInitialCoordsBottom,
          this.scrollPositionY,
          currentScrollPosition
        );
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscription.unsubscribe();
  }
}
