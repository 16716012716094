export const pwa = {
  pwa: {
    addToHomeScreenDescription:
      'Agregue el storefront de SAP a la pantalla de inicio de su dispositivo para una visita de regreso más rápida',
    noInstallationNeeded: 'No se necesita instalación',
    fastAccessToApplication: 'Acceso rápido a la aplicación',
    addToHomeScreen: 'Añadir a la pantalla principal',
    addedToHomeScreen: 'Se agregó SAP Storefront a su pantalla de inicio',
  },
};
