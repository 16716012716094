import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CustomWishList } from '../models/custom-wishlist.interface';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { CustomRemoveFromCartDialogComponent } from '../../cart/custom-remove-from-cart-dialog/custom-remove-from-cart-dialog.component';
import { ActiveCartService } from '@spartacus/core';
import { CustomBaseSiteCheckerService } from '../../../services/custom-base-site-checker.service';
import { CustomProduct } from '../../product/model/custom-product.interface';
import { CustomPromeclubAddedToCartDialogComponent } from '../../cart/custom-add-to-card/custom-promeclub-added-to-cart-dialog/custom-promeclub-added-to-cart-dialog.component';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import { CustomAddedToCartDialogComponent } from '../../cart/custom-add-to-card/custom-added-to-cart-dialog/custom-added-to-cart-dialog.component';
import { CustomPreAddToCartComponent } from '../../cart/custom-add-to-card/custom-pre-add-to-cart/custom-pre-add-to-cart.component';

@Component({
  selector: 'app-custom-wishlist-item',
  templateUrl: './custom-wishlist-item.component.html',
  styleUrls: ['./custom-wishlist-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomWishlistItemComponent {
  readonly UNAPPROVED_STATUS: string = 'unapproved';
  @Input() entry: CustomWishList;
  @Input() isMobile: boolean = false;
  wishForm = new FormGroup({
    quantity: new FormControl(1, { updateOn: 'change' }),
  });
  modalRef: ModalRef;
  isInPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  protected numberOfEntriesBeforeAdd = 0;
  constructor(
    private modalService: ModalService,
    private activeCartService: ActiveCartService,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService
  ) {}

  customAddToCart(): void {
    if (this.entry?.product?.approvalCode == this.UNAPPROVED_STATUS) {
      return;
    }
    if (
      !this.isInPromeclub ||
      !this.validatePromeclubProductType(this.entry.product as CustomProduct)
    ) {
      this.customAdd();
    }
    if (
      this.isInPromeclub &&
      this.validatePromeclubProductType(this.entry.product as CustomProduct)
    ) {
      this.openModalPromeclub();
    }
  }

  protected openModalPromeclub(): void {
    let modalInstance: any;
    this.modalRef = this.modalService.open(
      CustomPromeclubAddedToCartDialogComponent,
      {
        centered: true,
        size: 'lg',
        windowClass: 'promeclub-add-to-cart',
      }
    );

    modalInstance = this.modalRef.componentInstance;
    modalInstance.entry$ = of({
      product: this.entry.product,
      basePrice: { value: this.entry.product?.price?.value },
    });
    modalInstance.cart$ = this.activeCartService.getActive();
    modalInstance.loaded$ = this.activeCartService.isStable();
    modalInstance.quantity = this.wishForm.get('quantity')!.value;
    modalInstance.numberOfEntriesBeforeAdd = this.numberOfEntriesBeforeAdd;
    modalInstance.isInPromeclub = this.isInPromeclub;
    modalInstance.product = this.entry.product;
    modalInstance.fromWish = true;
  }

  validatePromeclubProductType(product: CustomProduct): boolean {
    switch (product?.tipoRecargaPromotick) {
      case 1:
        return false;
      case 3:
        return true;
      case 4:
        return true;
      case 7:
        return true;
      default:
        return false;
    }
  }

  deleteFromWishlist(): void {
    let modalInstance: any;
    this.modalRef = this.modalService.open(
      CustomRemoveFromCartDialogComponent,
      {
        centered: true,
        size: 'lg',
        windowClass: 'add-to-cart',
      }
    );

    modalInstance = this.modalRef.componentInstance;
    modalInstance.cartEntry = {
      product: this.entry.product,
      basePrice: { value: this.entry.product?.price?.value },
    };
    modalInstance.quantity = this.wishForm.get('quantity')!.value;
    modalInstance.quantityControl = this.wishForm.get('quantity');
    modalInstance.fromWish = true;
  }

  customAdd(): void {
    const quantity = this.wishForm.get('quantity')!.value;
    if (!this.entry.product!.code || quantity <= 0) {
      return;
    }
    this.activeCartService
      .getEntries()
      .pipe(take(1))
      .subscribe((entries) => {
        this.numberOfEntriesBeforeAdd = entries.length;
        this.openModal();
        this.activeCartService.addEntry(this.entry.product!.code!, quantity);
      });
  }

  protected openModal(): void {
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomAddedToCartDialogComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'promeclub-add-to-cart',
    });

    modalInstance = this.modalRef.componentInstance;
    modalInstance.entry$ = this.activeCartService.getLastEntry(
      this.entry.product!.code!
    );
    modalInstance.cart$ = this.activeCartService.getActive();
    modalInstance.loaded$ = this.activeCartService.isStable();
    modalInstance.quantity = this.wishForm.get('quantity')!.value;
    modalInstance.numberOfEntriesBeforeAdd = this.numberOfEntriesBeforeAdd;
    modalInstance.isInPromeclub = this.isInPromeclub;
  }

  openPreAddToCart(): void {
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomPreAddToCartComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'pre-add-to-cart',
    });

    modalInstance = this.modalRef.componentInstance;
    modalInstance.productCode = this.entry.product!.code;
    modalInstance.quantitySelected = this.wishForm.get('quantity')!.value;
    modalInstance.isFromQuote = false;
    modalInstance.isPromeclub = this.isInPromeclub;
  }
}
