import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { AuthRedirectService, AuthService } from '@spartacus/core';
import { Observable, combineLatest } from 'rxjs';
import { CustomNavigationService } from '../cms-components/navigation/services/custom-navigation.service';
import { map, filter } from 'rxjs/operators';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomUser } from '../cms-components/user/models/custom-user.interface';
import { environment } from '../../environments/environment';
import { CustomPromeclubUserService } from '../cms-components/my-account/services/custom-promeclub-user.service';

@Injectable({
  providedIn: 'root',
})
export class CustomPromeclubGuard implements CanActivate {
  isPromeclub: boolean = this.customNavigationService.isInPromeClub();
  constructor(
    protected authService: AuthService,
    protected authRedirectService: AuthRedirectService,
    private customNavigationService: CustomNavigationService,
    private userService: UserAccountFacade,
    private customPromeclubUserService: CustomPromeclubUserService
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return combineLatest([
      this.authService.isUserLoggedIn(),
      this.userService.get(),
    ]).pipe(
      filter(([isLoggedIn, user]) => (!!isLoggedIn && !!user) || !isLoggedIn),
      map(([isLoggedIn, user]) => {
        if (this.isPromeclub && !isLoggedIn) {
          window.open(`${environment.promesaUrl}/login`, '_self');
          return false;
        } else if (isLoggedIn) {
          return this.handleUserAccess(user as CustomUser);
        }
        return true;
      })
    );
  }

  handleUserAccess(user: CustomUser) {
    this.customPromeclubUserService.setCurrentUser(user);
    if (!!user?.accessDetails?.redirectUri) {
      window.open(user?.accessDetails?.redirectUri, '_self');
      return false;
    }
    return true;
  }
}
