import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-custom-confirmation-password-dialog',
  templateUrl: './custom-confirmation-password-dialog.component.html',
  styleUrls: ['./custom-confirmation-password-dialog.component.scss'],
})
export class CustomConfirmationPasswordDialogComponent
  implements OnInit, OnDestroy
{
  @Input() serviceBoolean$ = new Subject<boolean>();
  serviceBoolean: boolean;
  unsubscribe$: Subject<void> = new Subject();

  constructor(protected modalService: ModalService) {}

  ngOnInit() {
    this.serviceBoolean$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: boolean) => {
        this.serviceBoolean = response;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
