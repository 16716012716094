<ng-container *ngIf="rebate$ | async as rebate">
  <ng-container *ngIf="{currentProduct: currentProduct$ | async} as data">
  
  <div
    *ngIf="
      (rebate.length > 0 && componentData?.uid != 'RebateCartComponent' && data?.currentProduct?.productType != 'ZPOP') ||
      (rebate == true && componentData.uid === 'RebateCartComponent')
    "
    class="cx-rebate-pdp"
  >
    <div
      class="pdp-rebate-container"
      [ngClass]="{
        'pdp-rebate-container__open': openDetailMobile,
        'pdp-rebate-container__rebate-cart':
          componentData.uid === 'RebateCartComponent'
      }"
    >
      <div class="pdp-rebate-container__header">
        <cx-icon type="TAG"></cx-icon>
        <span>{{
          componentData.uid == "RebateCartComponent"
            ? ("customRebate.customPDPRebate.titleCart" | cxTranslate)
            : ("customRebate.customPDPRebate.titlePdp" | cxTranslate)
        }}</span>
        <ng-container
          *ngIf="
            componentData.uid != 'RebateCartComponent' && (isMobile$ | async)
          "
        >
          <cx-icon
            (click)="setOpenMobile()"
            class="fas fa-chevron-down"
            [ngClass]="openDetailMobile && 'rotate'"
          ></cx-icon>
        </ng-container>
      </div>
      <ng-container *ngIf="componentData.uid != 'RebateCartComponent'"
      ><app-custom-pdp-rebate-detail
      [rappels]="rebate"
      [open]="openDetailMobile"
      [isMobile]="isMobile$ | async"
    ></app-custom-pdp-rebate-detail
  >
      </ng-container>
    </div></div
>
</ng-container>
</ng-container>
