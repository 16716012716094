<ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
  <div
    *ngIf="cart"
    [cxFocus]="focusConfig"
    (esc)="close('Escape clicked')"
    class="cx-saved-cart-form-dialog"
  >
    <form
      [formGroup]="formSaved"
      class="cx-saved-cart-form-container"
      [ngClass]="{ 'list-created': !formEnabled }"
      (ngSubmit)="onSaveCart(cart?.code)"
    >
      <app-custom-modal-generic
        (closeModal)="close('Close Save Cart Dialog')"
        [isClose]="true"
        *ngIf="!data.isMobile"
      ></app-custom-modal-generic>
      <div class="cx-saved-cart-form-container__header" *ngIf="data.isMobile">
        <cx-icon class="fas fa-check" *ngIf="!formEnabled"></cx-icon>
        <h5 class="cx-saved-cart-form-container__header-title">
          {{
            (formEnabled
              ? "savedCartDialog.saveForLater"
              : "saveForLaterItems.listCreatedTitle"
            ) | cxTranslate
          }}
        </h5>
        <cx-icon
          type="CLOSE"
          (click)="close('Close Save Cart Dialog')"
        ></cx-icon>
      </div>
      <ng-container *ngIf="formEnabled; else listCreated">
        <!-- Modal title -->
        <div
          class="cx-saved-cart-form-title modal-title"
          *ngIf="!data.isMobile"
        >
          {{ "savedCartDialog.saveForLater" | cxTranslate }}
        </div>

        <!-- Modal Body -->
        <div class="cx-saved-cart-form-body">
          <p class="cx-saved-cart-form-subtitle">
            {{ "savedCartDialog.itemsSavedForLater" | cxTranslate }}
          </p>

          <div class="cx-saved-cart-form-row">
            <ng-container>
              <label>
                <span class="cx-saved-carts-label label-content">{{
                  "savedCartDialog.savedCartName" | cxTranslate
                }}</span>
                <input
                  aria-required="true"
                  [maxLength]="nameMaxLength"
                  class="form-control"
                  formControlName="nameCart"
                  type="text"
                  placeholder="{{
                    'saveForLaterItems.savedCartNamePlaceholder' | cxTranslate
                  }}"
                />
                <cx-form-errors
                  aria-live="assertive"
                  aria-atomic="true"
                  *ngIf="formSaved.get('nameCart').touched"
                  [control]="formSaved.get('nameCart')"
                  [prefix]="'formErrors.cxInvalidNameListCart'"
                ></cx-form-errors>
              </label>
            </ng-container>
          </div>

          <div class="cx-saved-cart-form-footer">
            <button
              (click)="close('Close Save Cart Dialog')"
              [attr.aria-label]="'common.close' | cxTranslate"
              [disabled]="isLoading$ | async"
              class="btn btn-secondary"
              type="button"
            >
              {{ "savedCartDialog.cancel" | cxTranslate }}
            </button>
            <button
              [attr.aria-label]="'common.save' | cxTranslate"
              [disabled]="formSaved.invalid || (isLoading$ | async)"
              class="btn btn-primary"
              type="submit"
            >
              {{ "savedCartDialog.save" | cxTranslate }}
            </button>
          </div>
        </div>
      </ng-container>
      <ng-template #listCreated>
        <!-- Modal title -->
        <div class="cx-saved-cart-form-created">
          <div
            class="cx-saved-cart-form-title modal-title"
            *ngIf="!data.isMobile"
          >
            {{ "saveForLaterItems.listCreatedTitle" | cxTranslate }}
          </div>
          <!-- Modal Body -->
          <p class="cx-saved-cart-form-subtitle mb-0">
            {{ "saveForLaterItems.listCreated" | cxTranslate }}
          </p>
          <a
            *ngIf="!data.isMobile"
            [routerLink]="
              {
                cxRoute: 'savedCarts'
              } | cxUrl
            "
            class="cx-saved-cart-form-link"
          >
            {{ "saveForLaterItems.linkCartList" | cxTranslate }}
          </a>
          <div class="cx-saved-cart-form-footer" *ngIf="data.isMobile">
            <button
              (click)="close('Close Save Cart Dialog')"
              [attr.aria-label]="'common.close' | cxTranslate"
              class="btn btn-secondary"
              type="button"
            >
              {{ "common.close" | cxTranslate }}
            </button>
            <button
              [routerLink]="
                {
                  cxRoute: 'savedCarts'
                } | cxUrl
              "
              [attr.aria-label]="'common.save' | cxTranslate"
              class="btn btn-primary"
              type="button"
            >
              {{ "saveForLaterItems.linkCartList" | cxTranslate }}
            </button>
          </div>
        </div>
      </ng-template>
    </form>
  </div>
</ng-container>
