import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageMetaResolver } from '@spartacus/core';
import { CustomProductPageMetaResolver } from './custom-product-page-meta.resolver';
import { CustomCategoryPageMetaResolver } from './custom-category-page-meta.resolver';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: PageMetaResolver,
      useClass: CustomCategoryPageMetaResolver,
      multi: true,
    },
    {
      provide: PageMetaResolver,
      useClass: CustomProductPageMetaResolver,
      multi: true,
    },
  ],
})
export class MetaResolversModule { }
