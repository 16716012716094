export const customMyAccount = {
  customUpdateProfileForm: {
    customUpdateProfileForm: {
      title: 'Datos personales',
      subtitle: 'Mis Datos',
      firstName: {
        label: 'Nombre:',
        placeholder: 'Nombre',
      },
      lastName: {
        label: 'Apellido:',
        placeholder: 'Apellido',
      },
      mobilePhone: {
        label: 'Teléfono móvil:',
        placeholder: 'Teléfono móvil',
      },
      userEmail: {
        label: 'E-mail de usuario:',
        placeholder: 'E-mail de usuario',
      },
      enterNewPassword: 'Ingresar nueva contraseña:',
      repeatNewPassword: 'Repetir nueva contraseña:',
      enterCurrentPassword: 'Ingresar contraseña actual:',
      modifyPassword: 'Modificar contraseña',
      passwordMismatchError: 'Tu contraseña actual es incorrecta',
      updatedPassword: 'Tu contraseña se ha restablecido.',
      loginWithNewPassword:
        'Ingresa a tu cuenta con la nueva contraseña generada.',
      goToLogin: 'Ingresar',
      changePassword: 'Cambiar contraseña',
      invalidToken: 'Su código PIN ha expirado, solicite uno nuevo',
    },
  },
  shoppingList: {
    shoppingList: {
      title: 'Listas de compras',
      dateSaved: 'Fecha de creación',
      moveToCart: 'Mover al carrito',
      delete: 'Eliminar',
      summary: 'Resumen de lista de compras',
      restoreText: 'Lista de compra {{cartName}} agregada al carrito',
      deleteText:
        'Deseas eliminar de forma permanente la lista de compras <b>{{cartName}}</b>',
      detailTitle: 'Detalle de lista de compras',
      notFound: 'No contamos con registros para esta cuenta',
    },
  },
};
