import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { CustomLoadingSpinnerService } from 'src/app/cms-components/custom/services/custom-loading-spinner.service';
import { CustomGtmLoginService } from 'src/app/common/services/custom-gtm-login-register-datalayer.service';
import { CustomClientNotActiveDialogComponent } from '../custom-register-shared/custom-client-not-active-dialog/custom-client-not-active-dialog.component';
import { PromesaRegisterClient } from '../models/custom-cms-register.interface';
import { CustomRegisterCustomerValidatorService } from '../services/custom-register-customer-validator.service';
import { CustomRegisterStepOrchestratorService } from '../services/custom-register-step-orchestrator.service';
import { CustomerAlreadyExistsDialogComponent } from '../custom-register-shared/customer-already-exists-dialog/customer-already-exists-dialog.component';
import { CustomRegisterEmployeeValidationDialogComponent } from '../custom-register-employee/custom-register-employee-validation-dialog/custom-register-employee-validation-dialog.component';

@Component({
  selector: 'app-custom-register-validation',
  templateUrl: './custom-register-validation.component.html',
})
export class CustomRegisterValidationComponent implements OnInit {
  customerValidatorForm: FormGroup = this.fb.group(
    {
      customerCode: ['', [Validators.required, this.numerosValidos]],
      ruc: ['', [Validators.required, this.numerosValidos]],
    },
    { updateOn: 'change' }
  );
  stepOne$: Observable<boolean> =
    this.customRegisterStepOrchestrator.getStepOneStatus();
  clientInfo: PromesaRegisterClient;
  rucValid: boolean = true;
  clientCodeValid: boolean = true;
  submited: boolean = false;
  subscription: Subscription = new Subscription();
  modalRef: ModalRef;
  isFormValid: boolean = false;
  isFormEmployee: boolean = false;
  constructor(
    private fb: FormBuilder,
    private customRegisterCustomerValidatorService: CustomRegisterCustomerValidatorService,
    private customRegisterStepOrchestrator: CustomRegisterStepOrchestratorService,
    protected cd: ChangeDetectorRef,
    protected dataLayerRegister: CustomGtmLoginService,
    private modalService: ModalService,
    protected spinnerService: CustomLoadingSpinnerService
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.customerValidatorForm.valueChanges.subscribe((changes) => {
        const pattern = /[0-9\ ]/;

        if (!this.isFormEmployee) {
          if (changes.ruc) {
            if (!pattern.test(changes.ruc[changes.ruc.length - 1])) {
              this.customerValidatorForm
                .get('ruc')!
                .setValue(
                  changes.ruc.replace(changes.ruc[changes.ruc.length - 1], '')
                );
            }
          }

          if (changes.customerCode) {
            if (
              !pattern.test(changes.customerCode[changes.customerCode.length - 1])
            ) {
              this.customerValidatorForm
                .get('customerCode')!
                .setValue(
                  changes.customerCode.replace(
                    changes.customerCode[changes.customerCode.length - 1],
                    ''
                  )
                );
            }
          }
        }

        if (this.customerValidatorForm.get('customerCode')?.valid
          && this.customerValidatorForm.get('ruc')?.valid) {
          this.isFormValid = true;
        } else {
          this.isFormValid = false;
        }

        if (this.submited) {
          this.rucValid = true;
          this.clientCodeValid = true;
          this.submited = false;
        }
      })
    );
  }

  onSubmit() {
    this.spinnerService.showSpinner('');
    this.submited = true;
    this.cd.detectChanges();
    if (this.customerValidatorForm.invalid) {
      return;
    }
    this.subscription.add(
      this.customRegisterCustomerValidatorService
        .submitForm(
          this.customerValidatorForm?.value?.customerCode,
          this.customerValidatorForm?.value?.ruc
        )
        .subscribe((clientInfo) => {
          this.spinnerService.hideSpinner();
          if (clientInfo === 'PromesaNonActiveClientError') {
            this.openClientInactiveModal();
          }
          this.dataLayerRegister.createOldAccountEvent(
            'Crear_Cuenta_Cliente_Paso1',
            clientInfo.ruc
          );
          this.clientInfo = clientInfo;
          this.formValidations(clientInfo);
        })
    );
  }

  openClientInactiveModal() {
    this.modalRef = this.modalService.open(CustomClientNotActiveDialogComponent, {
      centered: false,
      size: 'lg',
    });
    this.customerValidatorForm.reset();
  }

  openEmployeeInactiveModal(clientInfo: string) {
    let modalInstance: any;
    this.customerValidatorForm.reset();
    this.modalRef = this.modalService.open(CustomRegisterEmployeeValidationDialogComponent, {
      centered: false,
      size: 'lg',
    });
    modalInstance.errorType = clientInfo;
  }

  openAlreadyExistsModal() {
    let modalInstance: any;

    this.modalRef = this.modalService.open(
      CustomerAlreadyExistsDialogComponent,
      {
        centered: true,
        size: 'lg',
      }
    );
    modalInstance = this.modalRef.componentInstance;
    modalInstance.isRucMessageEmployee = true;
  }


  formValidations(clientInfo: any) {
    if (clientInfo.clientCodeValid && clientInfo.rucValid) {
      this.customRegisterCustomerValidatorService.setClientInfo(clientInfo);
      this.rucValid = true;
      this.clientCodeValid = true;
      this.customRegisterStepOrchestrator.setStepOneStatus(false);
      this.customRegisterStepOrchestrator.setStepTwoStatus(true);
      this.cd.detectChanges();
      return;
    }
    if (clientInfo?.clientCodeValid === false) {
      this.clientCodeValid = false;
    }
    if (clientInfo?.clientCodeValid === true) {
      this.rucValid = false;
    }
    this.cd.detectChanges();
  }

  numerosValidos(control: FormControl) {
    const valor = control.value;
    if (!valor || /^[0-9]+$/.test(valor)) {
      return null; // La validación es exitosa
    } else {
      return { numerosInvalidos: true }; // La validación falla
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
