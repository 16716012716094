<ng-container
  *ngIf="{
    isMobile: isMobile$ | async,
    isUserLoged: userLoged$ | async,
    isCheckout: isCheckout$ | async
  } as data"
>
  <ng-container *ngIf="!data.isCheckout">
    <ng-container *ngIf="data.isUserLoged; else modalLogin">
      <ng-container *ngIf="!data.isMobile; else modalCreditLine">
        <a
          class="credit-line-button"
          (mouseenter)="setShowCreditLine(true)"
          (mouseleave)="setShowCreditLine(false)"
        >
          <span class="credit-line-name">{{
            "creditLine.myCupo" | cxTranslate
          }}</span>
          <cx-icon class="fas fa-coins"></cx-icon>
          <div class="custom-credit-line">
            <ng-container *ngIf="!data.isMobile && (showCreditLine$ | async)">
              <div class="custom-credit-line-pop-up-wrapper">
                <app-custom-credit-line-dialog
                  [user]="data?.isUserLoged?.uid"
                ></app-custom-credit-line-dialog>
              </div>
            </ng-container>
          </div>
        </a>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #modalCreditLine>
  <ng-container>
    <a class="credit-line-button" (click)="openModal()">
      <cx-icon class="fas fa-coins"></cx-icon>
    </a>
  </ng-container>
</ng-template>

<ng-template #modalLogin>
  <ng-container *ngIf="isMobile$ | async as isMobile; else modalLoginDesktop">
    <a role="link" class="logged-off">
      <cx-icon
        class="fas fa-coins"
        (click)="setShowModalLoginForm('ingresar')"
      ></cx-icon>
    </a>
  </ng-container>
</ng-template>

<ng-template #modalLoginDesktop>
  <a
    class="credit-line-button"
    (click)="setShowLogin(true, 'ingresar')"
    (mouseleave)="setShowLogin(false, null, $event)"
  >
    <span class="credit-line-name">{{
      "creditLine.myCupo" | cxTranslate
    }}</span>
    <cx-icon class="fas fa-coins"></cx-icon>
    <div class="custom-credit-line">
      <ng-container *ngIf="showLogin$ | async">
        <div class="custom-login-pop-up-wrapper">
          <app-custom-login-form
            [fromModal]="true"
            [modalInstance]="instance"
          ></app-custom-login-form>
        </div>
      </ng-container>
    </div>
  </a>
</ng-template>
