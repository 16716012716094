import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomMiniCartComponent } from './custom-mini-cart.component';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule, ConfigModule, CmsConfig } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomEntriesMiniCartModule } from '../../custom/components/custom-entries-mini-cart/custom-entries-mini-cart.module';



@NgModule({
  declarations: [
    CustomMiniCartComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    RouterModule,
    I18nModule,
    UrlModule,
    MediaModule,
    CustomCurrencyModule,
    CustomEntriesMiniCartModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        MiniCartComponent: {
          component: CustomMiniCartComponent,
        },
      },
    }),
  ],
  exports: [
    CustomMiniCartComponent
  ]
})
export class CustomMiniCartModule { }
