import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomPromeclubRegisterSuccessComponent } from './custom-promeclub-register-success/custom-promeclub-register-success.component';
import { CustomPromeclubRegisterFormValidationService } from './services/custom-promeclub-register-form-validation.service';
import { CustomPromeclubRegisterFormService } from './services/custom-promeclub-register-form.service';

@Component({
  selector: 'app-custom-promeclub-register-form',
  templateUrl: './custom-promeclub-register-form.component.html',
  styleUrls: ['./custom-promeclub-register-form.component.scss'],
})
export class CustomPromeclubRegisterFormComponent implements OnInit, OnDestroy {
  static readonly LANDING_PROMECLUB = 'custom-landing-promeclub';

  modalRef: ModalRef;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private modalService: ModalService,
    private renderer: Renderer2,
    private customPromeclubRegisterFormService: CustomPromeclubRegisterFormService,
    private customPromeclubRegisterFormValidationService: CustomPromeclubRegisterFormValidationService
  ) { }

  ngOnInit(): void {
    this.renderer.addClass(
      document.body,
      CustomPromeclubRegisterFormComponent.LANDING_PROMECLUB
    );
  }

  onSubmit() {
    this.customPromeclubRegisterFormService.requestPromeclubAccess()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.openSuccessModal());
  }

  openSuccessModal() {
    let modalInstance: any;
    this.modalRef = this.modalService.open(
      CustomPromeclubRegisterSuccessComponent,
      {
        centered: true,
        size: 'lg',
        windowClass: 'promeclub-register-success',
      }
    );

    modalInstance = this.modalRef.componentInstance;
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.body,
      CustomPromeclubRegisterFormComponent.LANDING_PROMECLUB
    );
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
