import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { CustomFacetComponent } from './custom-facet.component';
import { CustomSearchFacetsModule } from 'src/app/common/components/custom-search-facets/custom-search-facets.module';

@NgModule({
  declarations: [CustomFacetComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    IconModule,
    KeyboardFocusModule,
    CustomSearchFacetsModule,
  ],
  exports: [CustomFacetComponent],
})
export class CustomFacetModule {}
