import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Product } from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';
import { CustomSocialShareContentDialogComponent } from './custom-social-share-content-dialog/custom-social-share-content-dialog.component';

@Component({
  selector: 'app-custom-product-social-share',
  templateUrl: './custom-product-social-share.component.html',
  styleUrls: ['./custom-product-social-share.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomProductSocialShareComponent {
  @Input() product: Product;
  @Input() isMobile: boolean = false;
  modalRef: ModalRef;
  isInPromeclub: boolean = this.service.isInPromeClub();

  constructor(
    private modalService: ModalService,
    private service: CustomNavigationService
  ) {}

  openPopup() {
    if (!this.isMobile) {
      let modalInstance: any;
      this.modalRef = this.modalService.open(
        CustomSocialShareContentDialogComponent,
        {
          centered: !this.isMobile,
          size: 'lg',
        }
      );
      modalInstance = this.modalRef.componentInstance;
      modalInstance.product = this.product;
      modalInstance.isMobile = this.isMobile;
      modalInstance.isInPromeclub = this.isInPromeclub;
    } else {
      let modalInstance: any;
      this.modalRef = this.modalService.open(
        CustomSocialShareContentDialogComponent,
        {
          centered: true,
          size: 'lg',
          windowClass: 'pre-add-to-cart',
        }
      );
      modalInstance = this.modalRef.componentInstance;
      modalInstance.product = this.product;
      modalInstance.isMobile = this.isMobile;
      modalInstance.isInPromeclub = this.isInPromeclub;
    }
  }
}
