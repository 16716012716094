import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OccEndpointsService,
  RoutingService,
  Cart,
  ActiveCartService,
  MultiCartService,
} from '@spartacus/core';
import { map, switchMap, tap, take, catchError, delay } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, of, EMPTY } from 'rxjs';
import { CustomQuoteOrchestratorService } from './custom-quote-orchestrator.service';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomLoadingSpinnerService } from 'src/app/cms-components/custom/services/custom-loading-spinner.service';
import { QUOTE_STATE } from '../constansts/quotes-constansts';

@Injectable({
  providedIn: 'root',
})
export class CustomQuotesService {
  quoteConverted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
    private routingService: RoutingService,
    private customQuoteOrchestrator: CustomQuoteOrchestratorService,
    private userService: UserAccountFacade,
    private activeCartService: ActiveCartService,
    private multicartService: MultiCartService,
    private spinnerService: CustomLoadingSpinnerService
  ) {}

  getList(pageSize: number = 24, currentPage: number = 0) {
    const url = this.occEndpointService.buildUrl('getQuoteList', {
      urlParams: {
        userId: 'current',
      },
      queryParams: {
        pageSize,
        currentPage,
      },
    });
    return this.http.get(url);
  }

  getQuoteByParam() {
    this.customQuoteOrchestrator.setInQuoteDetail(false);
    return this.routingService.getRouterState().pipe(
      map((routingData) => routingData.state.context.id.split('/')[3]),
      switchMap((code) => {
        const url = this.occEndpointService.buildUrl('getQuoteByCode', {
          urlParams: {
            userId: 'current',
            code,
          },
        });
        if (code === undefined) {
          return of({});
        }
        if (this.quoteConverted.value) {
          return of({});
        }
        return this.http.get(url).pipe(
          tap((quote: any) => {
            if (quote.state === QUOTE_STATE.BUYER_SUBMITTED) {
              this.customQuoteOrchestrator.setInQuoteDetail(true);
            } else if (quote.state === QUOTE_STATE.BUYER_OFFER) {
              this.customQuoteOrchestrator.setInQuoteOffer(true);
            } else {
              this.customQuoteOrchestrator.setInQuoteDetail(false);
            }
          }),
          catchError((error) => {
            this.routingService.goByUrl('/not-found');
            return error;
          })
        );
      }),
      tap(() => this.spinnerService.hideSpinner())
    );
  }

  getQuoteByCode(code: string) {
    const url = this.occEndpointService.buildUrl('getQuoteByCode', {
      urlParams: {
        userId: 'current',
        code,
      },
    });
    return this.http.get(url).pipe(
      tap((quote: any) => {
        if (quote.state === QUOTE_STATE.BUYER_SUBMITTED) {
          this.customQuoteOrchestrator.setInQuoteDetail(true);
        } else if (quote.state === QUOTE_STATE.BUYER_OFFER) {
          this.customQuoteOrchestrator.setInQuoteOffer(true);
        } else {
          this.customQuoteOrchestrator.setInQuoteDetail(false);
          this.customQuoteOrchestrator.setInQuoteOffer(false);
        }
      }),
      catchError((error) => {
        this.routingService.goByUrl('/not-found');
        return error;
      }),
      tap(() => this.spinnerService.hideSpinner())
    );
  }

  createQuote(cartId: string) {
    /*
   add the spinner when we call in the modal in the new feature
   this.spinnerService.showSpinner('');*/
    const url = this.occEndpointService.buildUrl('createQuote', {
      urlParams: {
        userId: 'current',
        cartId,
      },
    });
    return this.http.post(url, {}).pipe(
      catchError((error) => {
        this.spinnerService.hideSpinner();
        return error;
      })
    );
  }

  requestPriceForEntry(
    quoteCode: string,
    entryNumber: number,
    requestedPrice: number | null,
    comments: string
  ) {
    const url = this.occEndpointService.buildUrl('quoteRequestPrice', {
      urlParams: {
        userId: 'current',
        quoteCode,
      },
    });
    return this.http.put(url, {
      entryNumber,
      requestedPrice,
      comments,
    });
  }

  sendQuoteProposal(cart: Cart) {
    const url = this.occEndpointService.buildUrl('submitQuote', {
      urlParams: {
        userId: 'current',
      },
    });

    return combineLatest([
      this.customQuoteOrchestrator.getFormData(),
      this.userService.get(),
    ]).pipe(
      switchMap(([form, user]) => {
        let paymentCondition;
        if (form?.creditCondition != null) {
          paymentCondition = form.creditCondition.code;
        }
        let quote = {
          code: cart.code,
          comments: form.comment,
          entries: cart.entries,
          reference: form.reference,
          userId: user?.uid,
        };
        return this.http.post(url, quote).pipe(
          catchError((error) => {
            this.spinnerService.hideSpinner();
            return EMPTY;
          })
        );
      })
    );
  }

  deleteQuote(code: string) {
    const url = this.occEndpointService.buildUrl('deleteQuote', {
      urlParams: {
        userId: 'current',
        code,
      },
    });
    return this.http.delete(url);
  }

  acceptRejectQuoteProposal(code: string, quoteData: any) {
    this.spinnerService.showSpinner(code);
    const url = this.occEndpointService.buildUrl('acceptRejectQuoteProposal', {
      urlParams: {
        userId: 'current',
        code,
      },
    });

    return this.http.put(url, quoteData).pipe(
      catchError((error) => {
        this.spinnerService.hideSpinner();
        return error;
      })
    );
  }

  setPaymentMode(code: string, paymentMode: string) {
    this.spinnerService.showSpinner(code);
    const url = this.occEndpointService.buildUrl('setQuotePaymentMode', {
      urlParams: {
        userId: 'current',
        code,
        paymentMode,
      },
    });
    return this.http.put(url, {}).pipe(
      catchError((error) => {
        this.spinnerService.hideSpinner();
        return error;
      })
    );
  }

  createCartFromQuote(code: string) {
    this.spinnerService.showSpinner('');
    const userId: string = 'current';
    const url = this.occEndpointService.buildUrl('createCartFromQuote', {
      urlParams: {
        userId,
        code,
      },
    });
    return this.activeCartService.getActiveCartId().pipe(
      take(1),
      switchMap((cartId) => {
        if (cartId) {
          this.multicartService.deleteCart(cartId, userId);
        }
        return this.http.post(url, {}).pipe(
          tap((quote: Cart | any) => {
            if (quote?.code) {
              this.quoteConverted.next(true);
            }
          }),
          switchMap((cart) => of(cart).pipe(delay(2500))),
          tap(() => {
            this.spinnerService.hideSpinner();
          }),
          catchError((error) => {
            this.spinnerService.hideSpinner();
            if (error) {
              this.quoteConverted.next(false);
            }
            return error;
          })
        );
      })
    );
  }

  setQuotePaymentCondition(code: string, paymentCondition: string) {
    this.spinnerService.showSpinner(code);
    const url = this.occEndpointService.buildUrl('setQuotePaymentCondition', {
      urlParams: {
        userId: 'current',
        code,
      },
    });

    return this.http
      .put(url, {
        code,
        paymentCondition,
      })
      .pipe(
        catchError((error) => {
          this.spinnerService.hideSpinner();
          return error;
        })
      );
  }
}
