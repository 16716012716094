import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  ConfigModule,
  FeaturesConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import {
  AddToCartModule,
  CartSharedModule,
  IconModule,
  ItemCounterModule,
  KeyboardFocusModule,
  MediaModule,
  ModalModule,
  PromotionsModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { CustomCurrencyModule } from '../../../pipes/custom-currency/custom-currency.module';
import { CustomSafeHtmlModule } from '../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomGiftProductModule } from '../../custom/components/custom-gift-product/custom-gift-product.module';
import { CustomLoadingSpinnerModule } from '../../custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomModalGenericModule } from '../../custom/components/custom-modal/custom-modal-generic.module';
import { CustomItemCounterModule } from '../../shared/components/custom-item-counter/custom-item-counter.module';
import { CustomLoginFormModule } from '../../user/custom-login-form/custom-login-form.module';
import { CustomAddToCartComponent } from './custom-add-to-cart.component';
import { CustomAddedToCartDialogComponent } from './custom-added-to-cart-dialog/custom-added-to-cart-dialog.component';
import { CustomAddedToCartEntryComponent } from './custom-added-to-cart-entry/custom-added-to-cart-entry.component';
import { CustomPromeclubAddToCartFormComponent } from './custom-promeclub-add-to-cart-form/custom-promeclub-add-to-cart-form.component';
import { CustomPromeclubAddedToCartDialogComponent } from './custom-promeclub-added-to-cart-dialog/custom-promeclub-added-to-cart-dialog.component';
import { CustomPreAddToCartModule } from './custom-pre-add-to-cart/custom-pre-add-to-cart.module';
import { CustomPromeclubPointsModule } from '../../custom/components/custom-promeclub-product-points/custom-promeclub-product-points.module';
import { CustomFutureStockDialogModule } from '../custom-future-stock-dialog/custom-future-stock-dialog.module';

@NgModule({
  declarations: [
    CustomAddToCartComponent,
    CustomAddedToCartDialogComponent,
    CustomAddedToCartEntryComponent,
    CustomPromeclubAddedToCartDialogComponent,
    CustomPromeclubAddToCartFormComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CartSharedModule,
    RouterModule,
    SpinnerModule,
    PromotionsModule,
    FeaturesConfigModule,
    UrlModule,
    IconModule,
    I18nModule,
    ItemCounterModule,
    KeyboardFocusModule,
    ModalModule,
    AddToCartModule,
    CustomSafeHtmlModule,
    MediaModule,
    CustomCurrencyModule,
    CustomLoadingSpinnerModule,
    CustomItemCounterModule,
    CustomLoginFormModule,
    CustomGiftProductModule,
    CustomModalGenericModule,
    CustomPreAddToCartModule,
    CustomPromeclubPointsModule,
    CustomFutureStockDialogModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ProductAddToCartComponent: {
          component: CustomAddToCartComponent,
          data: {
            inventoryDisplay: false,
          },
        },
      },
    }),
  ],
  providers: [],
  exports: [
    CustomAddToCartComponent,
    CustomAddedToCartDialogComponent,
    CustomAddedToCartEntryComponent,
  ],
})
export class CustomAddToCartModule {}
