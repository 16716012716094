import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { GenericLinkModule } from '@spartacus/storefront';
import { CustomSafeHtmlModule } from '../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomComponentTitleModule } from '../custom-component-title/custom-component-title.module';
import { CustomBrandListComponent } from './custom-brand-list.component';

@NgModule({
  declarations: [CustomBrandListComponent],
  imports: [
    CommonModule,
    GenericLinkModule,
    CustomSafeHtmlModule,
    CustomComponentTitleModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromesaBrandCategoryListComponent: {
          component: CustomBrandListComponent,
        },
      },
    }),
  ],
})
export class CustomBrandListModule {}
