<ng-container #creditLine>
  <div class="my-credit-line_content">
    <app-custom-modal-generic *ngIf="isMobile$ | async"></app-custom-modal-generic>
    <ng-container
      *ngIf="creditInfo$ | async as credit; else spinnerLoading"
    >
      <div class="my-credit-line_body">
        <div class="my-credit-line__header">
          <span class="my-credit-line__header-title">{{
            "creditLine.myCupo" | cxTranslate
          }}</span>
          <span class="my-credit-line__header-subtitle">{{
            "creditLine.availableCredit" | cxTranslate
          }}</span>
        </div>
        <div class="my-credit-line__footer" *ngIf="credit">
          <span>{{
            "creditLine.total"
              | cxTranslate : { count: credit.availableAmount | customCurrency }
          }}</span>
        </div>
        <div class="my-credit-line__footer" *ngIf="!credit" >
          <span>{{
            "creditLine.total"
              | cxTranslate : { count: '-'  }
          }}</span>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #spinnerLoading>
  <div class="cx-order-history-body cx-order-history-body__loading">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
