import { Injectable } from '@angular/core';
import { CheckoutDeliveryFacade } from '@spartacus/checkout/root';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomCheckoutDeliveryStepsService {
  showAddresses$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  maxAddresses$: BehaviorSubject<number> = new BehaviorSubject<number>(3);
  isAddressActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isAddressSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isCenterDistributionActive$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  isDeliveryModeSelected$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  isCenterDistributionSelected$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  isAuthorizedPersonSelected$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  // SHIPPING ADDRESS
  getShowAddresses(): Observable<boolean> {
    return this.showAddresses$.asObservable();
  }
  getMaxAddresses(): Observable<number> {
    return this.maxAddresses$.asObservable();
  }
  getIsAddressActive(): Observable<boolean> {
    return this.isAddressActive$.asObservable();
  }
  getIsAddressSelected(): Observable<boolean> {
    return this.isAddressSelected$.asObservable();
  }

  setShowAddresses(value: boolean): void {
    this.showAddresses$.next(value);
  }
  setMaxAddresses(value: number): void {
    this.maxAddresses$.next(value);
  }

  setIsAddressActive(value: boolean): void {
    this.isAddressActive$.next(value);
  }
  setIsAddressSelected(value: boolean): void {
    this.isAddressSelected$.next(value);
  }

  // DELIVERY MODES
  getIsDeliveryModeSelected(): Observable<boolean> {
    return this.isDeliveryModeSelected$.asObservable();
  }
  setIsDeliveryModeSelected(value: boolean): void {
    this.isDeliveryModeSelected$.next(value);
  }

  // CENTER DISTRIBUTION
  getIsCenterDistributionActive(): Observable<boolean> {
    return this.isCenterDistributionActive$.asObservable();
  }
  getIsCenterDistributionSelected(): Observable<boolean> {
    return this.isCenterDistributionSelected$.asObservable();
  }

  setIsCenterDistributionSelected(value: boolean): void {
    this.isCenterDistributionSelected$.next(value);
  }

  setIsCenterDistributionActive(value: boolean): void {
    this.isCenterDistributionActive$.next(value);
  }

  setAuthorizedPersonSelected(value: boolean): void {
    this.isAuthorizedPersonSelected$.next(value);
  }

  getAuthorizedPersonSelected(): Observable<boolean> {
    return this.isAuthorizedPersonSelected$.asObservable();
  }

  constructor(private checkoutDeliveryService: CheckoutDeliveryFacade) {}

  cleanDeliveryMethod(isLogout: boolean) {
    if (!isLogout) {
      this.checkoutDeliveryService.clearCheckoutDeliveryAddress();
      this.checkoutDeliveryService.clearCheckoutDeliveryModes();
    }
    this.setIsAddressActive(false);
    this.setIsAddressSelected(false);
    this.setIsDeliveryModeSelected(false);
    this.setShowAddresses(false);
    this.setMaxAddresses(3);
  }

  cleanCenterDistribution() {
    this.setIsCenterDistributionActive(false);
    this.setIsCenterDistributionSelected(false);
  }
}
