export const userProfile = {
  updateEmailForm: {
    newEmailAddress: {
      label: 'Nueva dirección de correo electrónico',
      placeholder: 'Ingrese correo electrónico',
    },
    confirmNewEmailAddress: {
      label: 'Confirme nueva dirección de correo electrónico',
      placeholder: 'Ingrese correo electrónico',
    },
    enterValidEmail:
      'Por favor introduzca una dirección de correo electrónico válida.',
    bothEmailMustMatch: 'Ambos correos electrónicos deben coincidir',
    password: {
      label: 'Contraseña',
      placeholder: 'Introducir la contraseña',
    },
    pleaseInputPassword: 'Por favor ingrese la contraseña',
    emailUpdateSuccess: 'Éxito. Inicie sesión con {{ newUid }}',
  },
  register: {
    confirmPassword: {
      action: 'Confirmar contraseña',
      label: 'Confirmar contraseña',
      placeholder: 'Confirmar Contraseña',
    },
    managmentInMyAccount: 'Gestión en Mi cuenta.',
    termsAndConditions: 'Términos y Condiciones',
    signIn: 'Ya tengo una cuenta. Iniciar sesión',
    register: 'Registrarse',
    confirmNewPassword: 'Confirmar Nueva Contraseña',
    resetPassword: 'Restablecer la Contraseña',
    confirmationSuccesful: 'El usuario ha sido activado con éxito.',
    errorConfirmation: 'Ha ocurrido un error, contáctate con el soporte.',
    backToPromesa: 'Ir al inicio',
    goToPromesa: 'Ingresar',
    createAccount: 'Crea una cuenta',
    title: 'Título',
    firstName: {
      label: 'Nombre',
      placeholder: 'Nombre',
    },
    lastName: {
      label: 'Apellido',
      placeholder: 'Apellido',
    },
    emailAddress: {
      label: 'Dirección de correo electrónico',
      placeholder: 'Dirección de correo electrónico',
    },
    password: {
      label: 'Contraseña',
      placeholder: 'Contraseña',
    },
    newPassword: 'Nueva contraseña',
    emailMarketing:
      'Utilizar mis datos personales para recibir boletines informativos por correo electrónico para campañas de marketing. Para cambiar su configuración, vaya a Gestión de consentimiento en Mi cuenta.',
    confirmThatRead: 'Confirmo que he leído y estoy de acuerdo con el',
    selectTitle: 'Seleccionar Título',
    passwordMinRequirements:
      'La contraseña debe tener un mínimo de seis caracteres, con una letra mayúscula, un número, un símbolo',
    bothPasswordMustMatch: 'Ambas contraseñas deben coincidir',
    titleRequired: 'El título es obligatorio.',
    postRegisterMessage: 'Inicie sesión con las credenciales proporcionadas.',
  },
  forgottenPassword: {
    resetPassword: 'Restablecer contraseña',
    enterNewPassword: 'Ingresar nueva contraseña',
    repeatNewPassword: 'Repetir nueva contraseña',
    enterEmailAddressAssociatedWithYourAccount:
      'Ingrese la dirección de correo electrónico asociada a su cuenta',
    emailAddress: {
      label: 'E-mail',
      placeholder: 'Ingresá tu e-mail',
      customPlaceHolder: 'juan@promesa.com',
    },
    enterValidEmail:
      'Por favor introduzca una dirección de correo electrónico válida.',
    passwordResetEmailSent:
      'Se le ha enviado un correo electrónico con información sobre cómo restablecer su contraseña.',
    passwordResetSuccess:
      '¡Éxito! Ahora puede iniciar sesión con su nueva contraseña.',
    forgottenPasswordMessage:
      'Completa tu e-mail de usuario y te enviaremos un link para restablecer tu contraseña.',
    firstEmailMessage:
      'Te hemos enviado un e-mail a  <b>{{ email }}</b> con el link para restablecer tu contraseña.',
    secondEmailMessage:
      'Si no lo encuentras en tu bandeja de entrada, revisa en la carpeta de correos no deseados.',
  },
};
