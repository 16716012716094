import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductAttributesComponent } from './custom-product-attributes.component';
import { IconModule } from '@spartacus/storefront';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CustomProductCardDetailsModule } from '../custom-product-card-details/custom-product-card-details.module';
import { CustomComponentTitleModule } from 'src/app/cms-components/custom/components/custom-component-title/custom-component-title.module';
import { CustomProductCardDownlowadsModule } from '../custom-product-card-downlowads/custom-product-card-downlowads.module';
import { CustomProductBundleModule } from '../../custom-product-bundle/custom-product-bundle.module';
import { CustomProductReviewsModule } from '../custom-product-reviews/custom-product-reviews.module';
import { CustomProductTermsModule } from '../custom-product-terms/custom-product-terms.module';
import { CustomProductAttributesAccordionModule } from '../custom-product-attributes-accordion/custom-product-attributes-accordion.module';

@NgModule({
  declarations: [CustomProductAttributesComponent],
  imports: [
    CommonModule,
    IconModule,
    I18nModule,
    CustomProductCardDetailsModule,
    CustomComponentTitleModule,
    CustomProductTermsModule,
    CustomProductCardDownlowadsModule,
    CustomProductBundleModule,
    CustomProductReviewsModule,
    CustomProductAttributesAccordionModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ProductSpecsTabComponent: {
          component: CustomProductAttributesComponent,
        },
      },
    }),
  ],
  exports: [CustomProductAttributesComponent],
})
export class CustomProductAttributesModule {}
