<ng-container *ngIf="viewMode === ViewModes.Grid">
  <div
    infiniteScroll
    [infiniteScrollDistance]="5"
    [infiniteScrollThrottle]="50"
    [infiniteScrollDisabled]="isMaxProducts || isLastPage || isEmpty"
    (scrolled)="scrollPage(model?.pagination?.currentPage + 1)"
  >
    <div class="cx-product-container__custom">
      <app-custom-product-card
        *ngFor="let product of model?.products; let i = index"
        [product]="product"
        [productPosition]="i"
        [isPdpMobile]="true"
        [clickedFrom]="clickedFrom"
        [haveAutomotiveLabel]="haveAutomotiveLabel"
      ></app-custom-product-card>
    </div>
    <div
      [className]="
        !isLastPage && model?.pagination?.currentPage > 0
          ? 'cx-double-btn-container grid-btn-padding'
          : 'cx-single-btn-container grid-btn-padding'
      "
    ></div>
    <div *ngIf="appendProducts" class="cx-spinner">
      <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="viewMode === ViewModes.List">
  <div
    infiniteScroll
    [infiniteScrollDistance]="3"
    [infiniteScrollThrottle]="50"
    [infiniteScrollDisabled]="isMaxProducts || isLastPage || isEmpty"
    (scrolled)="scrollPage(model?.pagination?.currentPage + 1)"
  >
    <div class="cx-product-container__custom-variants">
      <app-custom-product-variant-list
        [plpVariantDataList]="model?.plpVariantDataList"
      ></app-custom-product-variant-list>
    </div>
    <div
      [className]="
        !isLastPage && model?.pagination?.currentPage > 0
          ? 'cx-double-btn-container'
          : 'cx-single-btn-container'
      "
    ></div>
    <div *ngIf="appendProducts" class="cx-spinner">
      <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
    </div>
  </div>
</ng-container>
