import { Component, OnInit, OnDestroy } from '@angular/core';
import { CmsComponent, CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subscription } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { CustomCmsRegisterComponent } from './models/custom-cms-register.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-register',
  templateUrl: './custom-register.component.html',
  styleUrls: ['./custom-register.component.scss'],
})
export class CustomRegisterComponent implements OnInit, OnDestroy {
  data$: Observable<CustomCmsRegisterComponent> = this.componentData.data$;
  registerLinks$: Observable<Observable<CmsComponent | null>[]>;
  subscription: Subscription = new Subscription();
  userId$: Observable<any> = this.userAccountFacade.get();
  constructor(
    private componentData: CmsComponentData<CustomCmsRegisterComponent>,
    private cmsService: CmsService,
    private router: Router,
    protected userAccountFacade: UserAccountFacade
  ) {}

  ngOnInit(): void {
    this.registerLinks$ = this.data$.pipe(
      pluck('links'),
      map((links) => links.split(' ') ?? []),
      map((codes) =>
        codes.map((code: string) => this.cmsService.getComponentData(code))
      )
    );

    this.subscription.add(
      this.userId$.subscribe((value) => {
        if (value) {
          this.router.navigateByUrl('/');
        }
      })
    );
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
