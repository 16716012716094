import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutComponentsModule } from './components/checkout-components.module';
import { provideConfig } from '@spartacus/core';
import { customCheckoutConfig } from './config/custom-checkout-config';
import { EffectsModule } from '@ngrx/effects';
import { CustomCheckoutEffects } from './store/effects/custom-checkout.effects';
import { CustomSavedCartEffects } from './store/effects/custom-saved-cart.effects';
import {
  SavedCartAdapter,
  SavedCartConnector,
} from '@spartacus/cart/saved-cart/core';
import { OccSavedCartAdapter } from '@spartacus/cart/saved-cart/occ';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CheckoutComponentsModule,
    EffectsModule.forFeature([CustomCheckoutEffects, CustomSavedCartEffects]),
  ],
  providers: [
    provideConfig(customCheckoutConfig),
    SavedCartConnector,
    OccSavedCartAdapter,
    {
      provide: SavedCartAdapter,
      useExisting: OccSavedCartAdapter,
    },
  ],
})
export class CheckoutModule {}
