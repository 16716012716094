import { Injectable } from '@angular/core';
import { CmsService, Page } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PageTypeService {
  constructor(protected cmsService: CmsService) {}

  isHomepage(): Observable<boolean> {
    return this.cmsService.getCurrentPage().pipe(
      filter<Page>(Boolean),
      switchMap((cmsPage) => {
        return of(cmsPage.pageId == 'homepage');
      })
    );
  }

  isCategory(): Observable<boolean> {
    return this.cmsService.getCurrentPage().pipe(
      filter<Page>(Boolean),
      switchMap((cmsPage) => {
        return of(cmsPage.template == 'CategoryPageTemplate');
      })
    );
  }
  showCategoryText(): Observable<boolean> {
    return this.cmsService.getCurrentPage().pipe(
      filter<Page>(Boolean),
      switchMap((cmsPage) => {
        return of(
          cmsPage.pageId == 'homepage' || cmsPage.pageId == 'homepageAutomotriz'
        );
      })
    );
  }
}
