<ng-container
  *ngIf="{
    order: order$ | async,
    userId: userId$ | async,
    paymentType: paymentType$ | async,
    isMobile: isMobile$ | async
  } as confirmation"
>
  <div class="cx-order-confirmation" [ngClass]="{ prome: isPromeclub }">
    <div class="cx-order-confirmation__icon" *ngIf="confirmation.isMobile">
      <cx-icon class="far fa-check-circle"></cx-icon>
    </div>
    <div class="cx-order-confirmation__title">
      <h1>
        {{
          isPromeclub
            ? ("checkoutOrderConfirmation.thankYouPromeclub" | cxTranslate)
            : ("checkoutOrderConfirmation.thankYou" | cxTranslate)
        }}
      </h1>
    </div>
    <div class="cx-order-confirmation__message">
      <p class="cx-page-title">
        {{ "checkoutOrderConfirmation.confirmationOfOrder" | cxTranslate }}
        {{
          !isPromeclub
            ? (isReplenishmentOrderType$ | async)
              ? confirmation.order?.replenishmentOrderCode
              : confirmation.order?.code
            : confirmation?.order?.promotickId
        }}
      </p>
      <ng-container *ngIf="confirmation.paymentType == 'COUNTED'">
        <div class="cx-order-confirmation__body">
          <div
            class="cx-page-message"
            [innerHTML]="
              'checkoutOrderConfirmation.mesaggeCounted'
                | cxTranslate
                | safeHtml
            "
          ></div>
          <div
            class="cx-page-message second"
            [innerHTML]="
              'checkoutOrderConfirmation.banksAvailables'
                | cxTranslate
                | safeHtml
            "
          ></div>
        </div>
        <app-custom-payment-cash-table
          [showTitleTable]="false"
        ></app-custom-payment-cash-table>
      </ng-container>
      <div class="cx-order-confirmation__body">
        <div
          class="cx-page-message"
          [innerHTML]="
            'checkoutOrderConfirmation.invoiceHasBeenSentByEmail'
              | cxTranslate : { email: confirmation.order?.orgCustomer?.email }
              | safeHtml
          "
        ></div>
        <div class="cx-order-confirmation__text" *ngIf="!isPromeclub">
          <div
            class="cx-page-message"
            [innerHTML]="
              'checkoutOrderConfirmation.checkOrder' | cxTranslate | safeHtml
            "
          ></div>
          <p class="cx-page-message">
            <a [routerLink]="{ cxRoute: 'orders' } | cxUrl">aquí</a>
          </p>
        </div>
      </div>
    </div>

    <div class="cx-order-confirmation__print-pdf" *ngIf="!isPromeclub">
      <a
        (click)="getOrderTicket(confirmation.userId, confirmation.order?.code)"
      >
        <cx-icon class="fas fa-print"></cx-icon>
        <span>{{
          "checkoutOrderConfirmation.printReceipt" | cxTranslate
        }}</span>
      </a>
    </div>
  </div>
</ng-container>
