import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { filter, map, switchMap } from 'rxjs/operators';
import { CustomUser } from '../../user/models/custom-user.interface';
import { Observable } from 'rxjs';
import { CustomCompany } from '../components/models/custom-company.interface';

@Injectable({
  providedIn: 'root'
})
export class CustomMyCompanyService {

  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
    private user: UserAccountFacade
  ) { }

  getCompanyData(): Observable<CustomCompany>{
   return this.user.get().pipe(
    filter(user => !!user),
    map(user => user as CustomUser),
    switchMap((user: CustomUser) => {
      const url = this.occEndpointService.buildUrl('companyData', {
        queryParams: {
          unitCode: user.orgUnit.uid
        }
      })
      return this.http.get<CustomCompany>(url)
    }),
   );
  }

}
