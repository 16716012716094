import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customMultilineTitle',
})
export class CustomMultilineTitlePipe implements PipeTransform {
  transform(value: string, index: number): string {
    const lastIndex = value.lastIndexOf(' ');

    if (lastIndex === -1) return 'Paso ' + index + ': ' + value;

    return (
      'Paso ' +
      index +
      ': ' +
      value.substring(0, lastIndex) +
      ' ' +
      value.substring(lastIndex, value.length).trim()
    );
  }
}
