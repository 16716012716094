<ng-container
  *ngIf="{
    typeSelected: typeSelected$ | async,
    isMobile: isMobile$ | async
  } as data"
>
  <div class="payment-cash">
    <div class="payment-cash__info" *ngIf="componentData$ | async as info">
      <app-custom-information
        [title]="info?.title"
        [description]="info?.description"
      ></app-custom-information>
    </div>
    <ng-container *ngIf="!data.isMobile">
      <ng-container *ngTemplateOutlet="cashPayment"></ng-container>
    </ng-container>
    <app-custom-payment-cash-table></app-custom-payment-cash-table>
    <ng-container *ngIf="data.isMobile">
      <ng-container *ngTemplateOutlet="cashPayment"></ng-container>
    </ng-container>
    <ng-container></ng-container>
  </div>
  <ng-template #cashPayment>
    <h3 class="payment-cash__title">
      {{ "creditPayment.typeOfCashPayment" | cxTranslate }}
    </h3>

    <div
      class="payment-cash__selectors"
      [ngClass]="{ quote: cart?.isFromQuote }"
    >
      <div class="form-check" *ngFor="let type of paymentTypes">
        <input
          id="paymentType-{{ type.code }}"
          class="form-check-input"
          role="radio"
          type="radio"
          aria-checked="true"
          (change)="changeType(type.code)"
          [value]="type.code"
          [checked]="type.code == data.typeSelected"
          formControlName="paymentType"
        />
        <label
          class="cx-payment-type-label form-check-label form-radio-label"
          for="paymentType-{{ type.code }}"
        >
          <div class="cx-payment-type">
            {{ type?.displayName }}
          </div>
        </label>
      </div>
    </div>
  </ng-template>
</ng-container>
