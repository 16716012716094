import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CmsService, Order } from '@spartacus/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Component({
  selector: 'app-custom-account-table',
  templateUrl: './custom-account-table.component.html',
  styleUrls: ['./custom-account-table.component.scss'],
})
export class CustomAccountTableComponent implements OnInit {
  @Input() cancellationsPage: boolean = false;
  @Input() orders: any;
  @Input() currentPage: string = 'order';
  @Input() fromQuote: boolean = false;
  @Output() goToDetails = new EventEmitter<Order>();
  ordersItems: any[] = [];
  pageCurrent$: Observable<string | undefined> = this.cmsService
    .getCurrentPage()
    .pipe(pluck('pageId'));
  constructor(private cmsService: CmsService) {}

  ngOnInit(): void {
    if (this.orders.orders?.length > 0 || this.orders.results?.length > 0) {
      this.ordersItems = this.orders.orders || this.orders.results;
    } else if (this.orders.returnRequests?.length > 0) {
      this.ordersItems = this.orders.returnRequests;
    } else if (this.orders?.quotes?.length > 0) {
      this.ordersItems = this.orders.quotes;
    }
  }

  goToOrderDetail(order: Order) {
    this.goToDetails.emit(order);
  }
}
