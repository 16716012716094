import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-custom-search-facets',
  templateUrl: './custom-search-facets.component.html',
  styleUrls: ['./custom-search-facets.component.scss'],
})
export class CustomSearchFacetsComponent implements OnInit, OnDestroy {
  private debouncer: Subject<string> = new Subject<string>();
  private debouncerSubscription: Subscription;
  inputSearch: string;

  @Input() placeholder: string;
  @Input() ariaLabel: string;
  @Input() ariaLabelBtn: string;

  @Output() debounceEmitt = new EventEmitter<string>();

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.debouncerSubscription = this.debouncer
      .pipe(debounceTime(500))
      .subscribe((value) => {
        this.debounceEmitt.emit(value);
      });
  }

  inputText(inputText: string) {
    this.debouncer.next(inputText);
  }

  ngOnDestroy(): void {
    this.debouncerSubscription.unsubscribe();
  }
}
