import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { StarRatingComponent } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-star-rating',
  templateUrl: './custom-star-rating.component.html',
  styleUrls: ['./custom-star-rating.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomStarRatingComponent
  extends StarRatingComponent
  implements OnInit
{
  iconArray: string[] = [];
  @Input() fromOverallRating: boolean = false;
  ngOnInit() {
    this.iconArray = Array.from({ length: 5 }, (_, index) =>
      index < this.rating ? 'STAR' : 'EMPTY_STAR'
    );
  }

  customSaveRate(rating: number) {
    if (this.fromOverallRating) {
      return;
    }
    this.iconArray = Array.from({ length: 5 }, (_, index) =>
      index < rating ? 'STAR' : 'EMPTY_STAR'
    );
    this.saveRate(rating);
  }
}
