import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductCardDownlowadsComponent } from './custom-product-card-downlowads.component';
import { IconModule } from '@spartacus/storefront';



@NgModule({
  declarations: [
    CustomProductCardDownlowadsComponent
  ],
  imports: [
    CommonModule,
    IconModule
  ],
  exports: [
    CustomProductCardDownlowadsComponent
  ]
})
export class CustomProductCardDownlowadsModule { }
