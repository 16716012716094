import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentType } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { CustomCredit } from 'src/app/feature-libs/checkout/models/custom-credit.interface';
import { CustomPaymentConditionsService } from 'src/app/feature-libs/checkout/services/custom-payment-conditions.service';
import { QuoteForm } from '../models/quote-form.interface';
import { CustomQuoteOrchestratorService } from '../services/custom-quote-orchestrator.service';
import { CustomQuotesService } from '../services/custom-quotes.service';

@Component({
  selector: 'app-custom-quotes-form',
  templateUrl: './custom-quotes-form.component.html',
  styleUrls: ['./custom-quotes-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomQuotesFormComponent implements OnInit, OnDestroy {
  @Input() code: string;
  @Input() isMobile: boolean = false;
  quotesForm: FormGroup = this.fb.group(
    {
      reference: ['', [Validators.required]],
      credit: [false],
      counted: [false],
      creditCondition: [null, [Validators.required]],
      countedMethod: [null, [Validators.required]],
      comment: [null, [Validators.required]],
    },
    { updateOn: 'change' }
  );
  creditPayments$: Observable<CustomCredit[]> =
    this.customPaymentConditionsService.getPaymentConditions('current');
  countedPayments$: Observable<PaymentType[]> =
    this.customPaymentConditionsService.getCountedPayments().pipe(
      filter((payments) => !!payments.length),
      tap((payments) => {
        this.quotesForm.get('countedMethod')?.setValue(payments[1]);
        this.quotesForm.get('creditCondition')?.setValue(null);
        this.customQuoteOrchestratorService.setRefreshQuoteDetail(true);
      })
    );
  paymentTypeSelected: string;
  unsubscribe$: Subject<void> = new Subject();
  constructor(
    private fb: FormBuilder,
    private customPaymentConditionsService: CustomPaymentConditionsService,
    private customQuoteOrchestratorService: CustomQuoteOrchestratorService,
    private customQuotesService: CustomQuotesService
  ) {}

  ngOnInit(): void {
    this.quotesForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: QuoteForm) => {
        this.customQuoteOrchestratorService.setFormData(value);
      });

    this.quotesForm.get('countedMethod')?.valueChanges.subscribe((value) => {
      if (value?.code === 'DEPOSIT') {
        this.customQuotesService
          .setPaymentMode(this.code, 'setDepositPaymentMode')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() =>
            this.customQuoteOrchestratorService.setRefreshQuoteDetail(true)
          );
      }
      if (value?.code === 'TRANSFER') {
        this.customQuotesService
          .setPaymentMode(this.code, 'setTransferPaymentMode')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() =>
            this.customQuoteOrchestratorService.setRefreshQuoteDetail(true)
          );
      }
    });

    this.quotesForm.get('creditCondition')?.valueChanges.subscribe((value) => {
      if (value?.code) {
        this.customQuotesService
          .setQuotePaymentCondition(this.code, value.code)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() =>
            this.customQuoteOrchestratorService.setRefreshQuoteDetail(true)
          );
      }
    });
  }

  paymentTypeChange(type: string) {
    switch (type) {
      case 'credit':
        this.quotesForm.get('credit')?.setValue(true);
        this.quotesForm.get('counted')?.setValue(false);
        this.paymentTypeSelected = type;
        this.customQuotesService
          .setPaymentMode(this.code, 'setCreditLinePaymentMode')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() =>
            this.customQuoteOrchestratorService.setRefreshQuoteDetail(true)
          );
        break;
      case 'counted':
        this.quotesForm.get('counted')?.setValue(true);
        this.quotesForm.get('credit')?.setValue(false);
        this.paymentTypeSelected = type;
        break;
      default:
        break;
    }
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
