import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CustomQuoteOrchestratorService } from '../services/custom-quote-orchestrator.service';
import { Observable } from 'rxjs';
import { QuoteForm } from '../models/quote-form.interface';
import { CustomQuoteModalService } from '../services/custom-quote-modal.service';
import { Cart } from '@spartacus/core';
import { QUOTE_STATE } from '../constansts/quotes-constansts';

@Component({
  selector: 'app-custom-quote-overview-header',
  templateUrl: './custom-quote-overview-header.component.html',
  styleUrls: ['./custom-quote-overview-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomQuoteOverviewHeaderComponent {
  @Input() code: string;
  @Input() name: string;
  @Input() comment: string;
  @Input() paymentCondition: string;
  @Input() date: Date;
  @Input() status: string;
  @Input() isInDetail: boolean = false;
  @Input() isInOffer: boolean = false;
  @Input() isInReview: boolean = false;
  @Input() quote: Cart | any;
  quoteForm$: Observable<QuoteForm> =
    this.customQuoteOrchestrator.getFormData();
  QUOTE_STATE = QUOTE_STATE;
  constructor(
    private customQuoteOrchestrator: CustomQuoteOrchestratorService,
    private customQuoteModalService: CustomQuoteModalService
  ) {}

  openRemoveConfirmationModal() {
    this.customQuoteModalService.openRemoveModal(this.code);
  }
}
