<div class="custom-container">
  <div class="custom-container__content">
    <app-custom-modal-generic
      (closeModal)="closeModal('close')"
      [isClose]="true"
    ></app-custom-modal-generic>
    <div
      class="custom-container__content__message"
      [ngClass]="{ record: isFromPaymentRecord }"
    >
      <p *ngIf="!showFileTypeError && !showUnknowErrorFile">
        {{
          "orderDetails.registerPaid.successfulRegistration"
            | cxTranslate : { order: order }
        }}
      </p>
      <p *ngIf="showFileTypeError" class="error-message">
        {{ "orderDetails.registerPaid.errorFileType" | cxTranslate }}
      </p>
      <p *ngIf="showUnknowErrorFile" class="error-message">
        {{ "orderDetails.registerPaid.uploadFileError" | cxTranslate }}
      </p>
    </div>
    <div class="custom-container__content__actions">
      <button class="btn btn-primary" (click)="closeModal($event)">
        <span>
          {{ "customerValidation.button.text" | cxTranslate }}
        </span>
      </button>
    </div>
  </div>
</div>
