import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class CustomZagitaProductService  {


  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
  ) {}

  getZagitaProductsByProduct(productCode: string): Observable<any>{
    const url = this.occEndpointService.buildUrl('zagitaProductByProduct', {
      urlParams: {
        productCode
      }
    });
    return this.http.get<any>(url);
  }
}
