import { User } from '@spartacus/core';
export interface CustomUser extends User {
  accessDetails: CustomUserAccessDetails;
  orgUnit: OrgUnit;
  email?: string;
  phone?: string;
  isEmployee?: boolean;
  roles?: string[];
  isPromeclubTyCAccepted?: boolean;
}

interface CustomUserAccessDetails {
  accessPromeclub: boolean;
  accessPromesa: boolean;
  accessPrometas: boolean;
  redirectUri?: string;
}

interface OrgUnit {
  active: boolean;
  name: string;
  uid: string;
}

export enum ROLE {
  SELLER = 'promesaSeller',
  ADMINISTRATIVE = 'promesaAdministrative',
  LOGISTIC = 'promesaLogistic',
}

export enum POINTS {
  PROMECLUB = 'PromeClub',
  PROMETAS = 'ProMetas',
}
