import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CartValidationStateService, ICON_TYPE } from '@spartacus/storefront';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-custom-cart-item-validation-warning',
  templateUrl: './custom-cart-item-validation-warning.component.html',
  styleUrls: ['./custom-cart-item-validation-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCartItemValidationWarningComponent {

  @Input()
  code: string;

  iconTypes = ICON_TYPE;
  isVisible = true;

  cartModification$ =
    this.cartValidationStateService.cartValidationResult$.pipe(
      map((modificationList) =>
        modificationList.find(
          (modification) => modification!.entry!.product!.code === this.code
        )
      )
    );

  constructor(
    protected cartValidationStateService: CartValidationStateService
  ) {}

}
