import { Component, ViewEncapsulation } from '@angular/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomModalService } from 'src/app/cms-components/shared/components/custom-modal/services/custom-modal.service';
import { CustomForgotPasswordComponent } from 'src/app/cms-components/user/custom-forgot-password/custom-forgot-password.component';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-user-bloqued-dialog',
  templateUrl: './custom-user-bloqued-dialog.component.html',
  styleUrls: ['./custom-user-bloqued-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomUserBloquedDialogComponent {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  modalRef: ModalRef;
  constructor(
    protected modalService: ModalService,
    private customBreakpointService: CustomBreakpointService,
    protected customModalService: CustomModalService
  ) {}

  dismissModal(reason?: any): void {
    this.modalService.dismissActiveModal(reason);
  }

  goToForgetPassword() {
    this.dismissModal();
    let modalInstance: any;
    this.modalRef = this.modalService.open(CustomForgotPasswordComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = this.modalRef.componentInstance;
  }
}
