<div
  class="quotes-entry"
  [ngClass]="{
    edit: editItem,
    'with-badge': entry?.requestedPrice && !editItem,
    'view-note': viewNote,
    offer: isInOffer,
    action: entry?.finalPrice,
    form: formOpened
  }"
>
  <div
    class="quotes-entry__image"
    [ngClass]="{ refused: entry?.quoteAcceptedStatus == QUOTE_STATE.REFUSED }"
  >
    <cx-media
      *ngIf="entry.product?.images"
      [container]="entry?.product?.images?.[2]"
      format="thumbnail"
      role="presentation"
    ></cx-media>
    <div
      class="quotes-entry__badge-text"
      *ngIf="
        !editItem && entry?.requestedPrice && !entry.finalPrice && !isMobile
      "
    >
      {{ "accountQuotes.item.inReviewBadge" | cxTranslate }}
    </div>
    <div
      class="quotes-entry__badge-text reviewed"
      *ngIf="!editItem && entry.finalPrice && !isMobile"
    >
      {{ "accountQuotes.item.reviewedBadge" | cxTranslate }}
    </div>
  </div>
  <div class="quotes-entry__data">
    <p
      class="quotes-entry__data-brand"
      [ngClass]="{ refused: entry?.quoteAcceptedStatus == QUOTE_STATE.REFUSED }"
    >
      {{ entry?.product?.brand?.name }}
    </p>
    <p
      class="quotes-entry__data-name"
      [ngClass]="{ refused: entry?.quoteAcceptedStatus == QUOTE_STATE.REFUSED }"
    >
      {{ entry?.product?.name }}
    </p>
    <p
      class="quotes-entry__data-sku"
      [ngClass]="{ refused: entry?.quoteAcceptedStatus == QUOTE_STATE.REFUSED }"
    >
      {{ "accountQuotes.item.sku" | cxTranslate }}
      {{ entry?.product?.code }}
    </p>
    <p
      class="quotes-entry__data-presentation"
      [ngClass]="{ refused: entry?.quoteAcceptedStatus == QUOTE_STATE.REFUSED }"
    >
      {{ "accountQuotes.item.presentation" | cxTranslate }}
      {{ entry?.product?.unit }}
      <span
        *ngIf="isMobile && isInOffer"
        class="quotes-entry__data-presentation__quantity"
      >
        {{ "accountQuotes.item.quantity" | cxTranslate }} :
        {{ entry?.quantity }}
      </span>
    </p>
    <div
      class="badge-mobile"
      *ngIf="!editItem && entry?.requestedPrice && isMobile"
    >
      <div
        class="quotes-entry__badge-text"
        *ngIf="
          !editItem && entry?.requestedPrice && !entry.finalPrice && isMobile
        "
      >
        {{ "accountQuotes.item.inReviewBadge" | cxTranslate }}
      </div>
      <div
        class="quotes-entry__badge-text reviewed"
        [ngClass]="{
          refused: entry?.quoteAcceptedStatus == QUOTE_STATE.REFUSED
        }"
        *ngIf="!editItem && entry.finalPrice && isMobile"
      >
        {{ "accountQuotes.item.reviewedBadge" | cxTranslate }}
      </div>
      <div
        class="quotes-entry__badge-note"
        *ngIf="
          (!editItem && entry?.quoteComments && isMobile) ||
          (entry?.quoteRejectedReason && !editItem && isMobile)
        "
      >
        <span (click)="toggleNote()">
          {{
            entry?.quoteRejectedReason
              ? ("accountQuotes.item.viewRejectedReasonMobile" | cxTranslate)
              : ("accountQuotes.item.viewNote" | cxTranslate)
          }}
        </span>
        <cx-icon
          class="fas fa-chevron-down"
          (click)="toggleNote()"
          *ngIf="!viewNote"
        ></cx-icon>
        <cx-icon
          class="fas fa-chevron-up"
          (click)="toggleNote()"
          *ngIf="viewNote"
        ></cx-icon>
        <div class="quotes-entry__badge-note-comment" *ngIf="viewNote">
          <p class="quotes-entry__badge-note-comment-text">
            {{
              entry?.quoteRejectedReason
                ? entry?.quoteRejectedReason
                : entry?.quoteComments
            }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="quotes-entry__badge-note"
      [ngClass]="{ refuse: entry?.quoteRejectedReason }"
      *ngIf="
        (!editItem && entry?.quoteComments && !isMobile) ||
        (entry?.quoteRejectedReason && !editItem && !isMobile)
      "
    >
      <span (click)="toggleNote()">
        {{
          entry?.quoteRejectedReason
            ? ("accountQuotes.item.viewRejectedReason" | cxTranslate)
            : ("accountQuotes.item.viewNote" | cxTranslate)
        }}
      </span>
      <cx-icon
        class="fas fa-chevron-down"
        (click)="toggleNote()"
        *ngIf="!viewNote"
      ></cx-icon>
      <cx-icon
        class="fas fa-chevron-up"
        (click)="toggleNote()"
        *ngIf="viewNote"
      ></cx-icon>
      <div class="quotes-entry__badge-note-comment" *ngIf="viewNote">
        <p class="quotes-entry__badge-note-comment-text">
          {{
            entry?.quoteRejectedReason
              ? entry?.quoteRejectedReason
              : entry?.quoteComments
          }}
        </p>
      </div>
    </div>
  </div>
  <div
    class="quotes-entry__quantity"
    [ngClass]="{
      none: isInOffer,
      refused: entry?.quoteAcceptedStatus == QUOTE_STATE.REFUSED
    }"
  >
    <p class="quotes-entry__quantity-text">
      {{ "accountQuotes.item.quantity" | cxTranslate }}
    </p>
    <div
      class="quotes-entry__quantity-box"
      [ngClass]="{ review: isInReview || isInDetail || isInOffer }"
    >
      <div class="quotes-entry__quantity-box-value">
        {{ entry?.quantity }}
      </div>
    </div>
  </div>
  <div
    class="quotes-entry__price"
    [ngClass]="{
      offer: isInOffer,
      first: isInOffer,
      refused: entry?.quoteAcceptedStatus == QUOTE_STATE.REFUSED
    }"
  >
    <div class="quotes-entry__price-text">
      {{
        isInOffer
          ? ("accountQuotes.item.originalPrice" | cxTranslate)
          : ("accountQuotes.item.price" | cxTranslate)
      }}
    </div>
    <div class="quotes-entry__price-unit">
      {{ entry?.originalPrice | customCurrency }}
    </div>
    <div class="quotes-entry__price-total">
      {{ "accountQuotes.item.total" | cxTranslate }}
      {{ entry?.totalPrice?.value | customCurrency }}
    </div>
  </div>
  <div
    class="quotes-entry__request"
    [ngClass]="{
      offer: isInOffer,
      refused: entry?.quoteAcceptedStatus == QUOTE_STATE.REFUSED
    }"
  >
    <div
      class="quotes-entry__request-text"
      [ngClass]="{
        readonly: !editItem && !entry?.finalPrice,
        hide:
          (isInReview && !!entry?.requestedPrice == false) ||
          (isInDetail && !!entry?.requestedPrice == false) ||
          (isInOffer && !!entry?.requestedPrice == false),
        offer: isInOffer
      }"
    >
      {{
        entry?.finalPrice
          ? ("accountQuotes.item.requestedPrice" | cxTranslate)
          : ("accountQuotes.item.priceGoal" | cxTranslate)
      }}
      <cx-icon
        *ngIf="!isInOffer"
        class="fas fa-info-circle"
        ngbTooltip="{{ 'accountQuotes.item.reviewPriceInfo' | cxTranslate }}"
        placement="top"
        tooltipClass="tooltip-color"
        [triggers]="isMobile ? 'click' : 'hover'"
      ></cx-icon>
    </div>
    <div
      *ngIf="!isInReview && !isInDetail && !isInOffer"
      class="quotes-entry__request-control"
      [ngClass]="{
        readonly:
          !editItem && !isInReview && !isInDetail && !entry?.requestedPrice,
        error: invalidPriceFormat || invalidPriceNumber || invalidZeroPrice
      }"
    >
      <span>$</span>
      <input
        type="text"
        placeholder="00"
        [formControl]="priceGoal"
        [readonly]="!editItem && !isInReview"
      />
    </div>
    <div
      *ngIf="isInReview || isInDetail || isInOffer"
      class="quotes-entry__request-control review"
      [ngClass]="{ refused: entry?.quoteAcceptedStatus == QUOTE_STATE.REFUSED }"
    >
      <span *ngIf="entry?.requestedPrice; else empty">
        {{ entry?.requestedPrice | customCurrency }}
      </span>
      <ng-template #empty>
        <span class="empty">-</span>
      </ng-template>
    </div>
    <div
      class="quotes-entry__request-total"
      [ngClass]="{
        readonly: !editItem && !entry?.requestedPrice,
        error: invalidPriceFormat || invalidPriceNumber || invalidZeroPrice,
        review: isInReview || isInDetail || isInOffer,
        hide:
          (isInReview && !!entry?.requestedPrice == false) ||
          (isInDetail && !!entry?.requestedPrice == false) ||
          (isInOffer && !!entry?.requestedPrice == false),
        refused: entry?.quoteAcceptedStatus == QUOTE_STATE.REFUSED
      }"
    >
      <span
        *ngIf="!invalidPriceFormat && !invalidPriceNumber && !invalidZeroPrice"
      >
        {{ "accountQuotes.item.total" | cxTranslate }}
        {{
          entry?.requestedPrice && !editItem
            ? (entry?.requestedPrice * entry.quantity | customCurrency)
            : (totalGoalValue | customCurrency)
        }}
      </span>
      <span class="message" *ngIf="invalidPriceFormat || invalidPriceNumber">
        {{
          (invalidPriceFormat
            ? "accountQuotes.item.invalidPriceFormat"
            : "accountQuotes.item.invalidPriceNumber"
          ) | cxTranslate : { result }
        }}
      </span>
      <span
        class="message"
        *ngIf="invalidZeroPrice && !(invalidPriceFormat || invalidPriceNumber)"
      >
        {{ "accountQuotes.item.invalidZeroPrice" | cxTranslate }}
      </span>
    </div>
  </div>
  <div
    class="quotes-entry__review"
    *ngIf="!isInOffer"
    [ngClass]="{
      hide:
        (editItem && !isMobile) ||
        isInReview ||
        isInDetail ||
        entry?.basePrice?.value == 0 ||
        entry?.product?.productType === EcvProductType
    }"
  >
    <cx-icon class="fas fa-edit" (click)="toggleEditItem()"></cx-icon>
    <p
      class="quotes-entry__review-text"
      (click)="toggleEditItem()"
      *ngIf="!isMobile"
    >
      {{ "accountQuotes.item.reviewPrice" | cxTranslate }}
    </p>
  </div>
  <div
    class="quotes-entry__price offer"
    [ngClass]="{ refused: entry?.quoteAcceptedStatus == QUOTE_STATE.REFUSED }"
    *ngIf="isInOffer"
  >
    <div
      class="quotes-entry__price-text"
      [ngClass]="{ hide: !entry?.finalPrice }"
    >
      {{ "accountQuotes.item.approvedPrice" | cxTranslate }}
    </div>
    <div
      class="quotes-entry__price-unit"
      [ngClass]="{
        refused: entry?.quoteAcceptedStatus == QUOTE_STATE.REFUSED,
        accepted: entry?.quoteAcceptedStatus == QUOTE_STATE.ACCEPTED
      }"
    >
      {{ entry?.finalPrice ? (entry?.finalPrice | customCurrency) : "-" }}
    </div>
    <div
      class="quotes-entry__price-total"
      [ngClass]="{
        hide: !entry?.finalPrice,
        refused: entry?.quoteAcceptedStatus == QUOTE_STATE.REFUSED,
        accepted: entry?.quoteAcceptedStatus == QUOTE_STATE.ACCEPTED
      }"
    >
      {{ "accountQuotes.item.total" | cxTranslate }}
      {{ entry?.finalPrice * entry.quantity | customCurrency }}
    </div>
  </div>
  <div
    class="quotes-entry__final-action"
    [ngClass]="{
      hide: !entry?.finalPrice || editItem,
      spaced: entry?.quoteAcceptedStatus != QUOTE_STATE.WAITING,
      button: entry?.quoteAcceptedStatus == QUOTE_STATE.WAITING || editOffer
    }"
    *ngIf="isInOffer"
  >
    <div class="quotes-entry__final-action__status">
      <ng-container
        *ngIf="entry?.quoteAcceptedStatus == QUOTE_STATE.ACCEPTED && !editOffer"
      >
        <div class="quotes-entry__final-action__status-icon">
          <cx-icon class="fas fa-check"></cx-icon>
        </div>
        <p class="quotes-entry__final-action__status-text">
          {{ "accountQuotes.item.priceAccepted" | cxTranslate }}
        </p>
      </ng-container>
      <ng-container
        *ngIf="entry.quoteAcceptedStatus == QUOTE_STATE.REFUSED && !editOffer"
      >
        <div class="quotes-entry__final-action__status-icon rejected">
          <cx-icon class="fas fa-times rejected"></cx-icon>
        </div>
        <p class="quotes-entry__final-action__status-text">
          {{ "accountQuotes.item.priceRefused" | cxTranslate }}
        </p>
      </ng-container>
    </div>
    <ng-container
      *ngIf="entry?.quoteAcceptedStatus != QUOTE_STATE.WAITING && !editOffer"
    >
      <div
        class="quotes-entry__final-action__status-action"
        (click)="toggleEditOffer()"
      >
        <cx-icon class="fas fa-edit"></cx-icon>
        <p class="quotes-entry__final-action__status-action-text">
          {{ "accountQuotes.item.modify" | cxTranslate }}
        </p>
      </div>
    </ng-container>
    <ng-container
      *ngIf="entry?.quoteAcceptedStatus == QUOTE_STATE.WAITING || editOffer"
    >
      <button
        class="quotes-entry__final-action-button"
        (click)="acceptEntryOffer()"
      >
        {{ "accountQuotes.item.acceptPrice" | cxTranslate }}
      </button>
      <button
        class="quotes-entry__final-action-button reject"
        (click)="toggleEditItem()"
      >
        {{ "accountQuotes.item.rejectPrice" | cxTranslate }}
      </button>
    </ng-container>
  </div>
</div>
<div class="quotes-entry__action" *ngIf="editItem">
  <div class="quotes-entry__action-form" [ngClass]="{ offer: isInOffer }">
    <p class="quotes-entry__action-form-text" *ngIf="!isInOffer">
      {{ "accountQuotes.item.note" | cxTranslate }}
    </p>
    <div class="quotes-entry__action-form__offer" *ngIf="isInOffer">
      <h4 class="quotes-entry__action-form__offer-title">
        {{ "accountQuotes.item.rejectPrice" | cxTranslate }}
      </h4>
      <h5 class="quotes-entry__action-form__offer-subtitle">
        {{ "accountQuotes.item.rejectReason" | cxTranslate }}
      </h5>
    </div>
    <textarea
      class="form-control"
      [formControl]="comment"
      rows="3"
      placeholder="{{ 'accountQuotes.form.commentsPlaceholder' | cxTranslate }}"
    ></textarea>
  </div>
  <div class="quotes-entry__action-link" (click)="toggleEditItem()">
    {{ "accountQuotes.item.cancel" | cxTranslate }}
  </div>
  <button
    *ngIf="!isInOffer"
    [disabled]="
      invalidPriceFormat ||
      invalidPriceNumber ||
      !priceGoal.value ||
      invalidZeroPrice
    "
    class="quotes-entry__action-button"
    (click)="submitEntryRequest()"
  >
    {{ "accountQuotes.item.save" | cxTranslate }}
  </button>
  <button
    *ngIf="isInOffer"
    [disabled]="!comment?.value?.length"
    class="quotes-entry__action-button reject"
    (click)="rejectEntryOffer()"
  >
    {{ "accountQuotes.item.rejectPrice" | cxTranslate }}
  </button>
</div>
