import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCheckButtonComponent } from './custom-check-button.component';
import { IconModule } from '@spartacus/storefront';

@NgModule({
  declarations: [CustomCheckButtonComponent],
  exports: [CustomCheckButtonComponent],
  imports: [CommonModule, IconModule],
})
export class CustomCheckButtonModule {}
