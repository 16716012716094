import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CustomClaimAddService } from '../../../services/custom-claim-add.service';
import { ORDER_STATE } from '../../custom-order-history/constansts/order-constansts';

@Injectable({
  providedIn: 'root',
})
export class CustomClaimGuard implements CanActivate {
  constructor(
    protected customClaimAddService: CustomClaimAddService,
    protected routingService: RoutingService
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.routingService.getRouterState().pipe(
      switchMap((state) => {
        const orderCode: string =
          state.nextState?.url
            ?.split('/')[3]
            ?.replace('?site=promesaSite', '') || '';

        return this.customClaimAddService.getOrderDetails(orderCode).pipe(
          map((order) => {
            if (order?.status !== ORDER_STATE.CANCELLED) {
              return true;
            } else {
              this.routingService.go({ cxRoute: 'orders' });
              return false;
            }
          })
        );
      })
    );
  }
}
