import { address } from './address.i18n';
import { cart } from './cart.i18n';
import { common } from './common.i18n';
import { myAccount } from './my-account.i18n';
import { payment } from './payment.i18n';
import { product } from './product.i18n';
import { pwa } from './pwa.i18n';
import { user } from './user.i18n';

export const es = {
  address,
  cart,
  common,
  myAccount,
  payment,
  product,
  pwa,
  user,
};
