import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService, CustomerCouponSearchResult, OccCustomerCouponAdapter, OccEndpointsService } from '@spartacus/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomOccCustomerCouponAdapterService extends OccCustomerCouponAdapter {

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {
    super(http, occEndpoints, converter)
  }

  getCustomerCoupons(
    userId: string,
    pageSize: number,
    currentPage: number,
    sort: string
  ): Observable<CustomerCouponSearchResult> {
    return of({});
  }
}
