import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Product, ProductService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CustomProduct } from 'src/app/cms-components/product/model/custom-product.interface';
import { CustomBreakpointService } from '../../../../../../services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-product-variant-list-item-details-mobile',
  templateUrl: './custom-product-variant-list-item-details-mobile.component.html',
  styleUrls: ['./custom-product-variant-list-item-details-mobile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomProductVariantListItemDetailsMobileComponent implements OnInit {

  @Input() product: CustomProduct;
  @Input() isLoggedIn: boolean = true;
  @Input() fromContent: boolean = false;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  productDetail$: Observable<Product>;
  constructor(
      private customBreakpointService: CustomBreakpointService,
      private productService: ProductService
    ) { }

  ngOnInit(): void {
    this.productDetail$ = this.productService.get(this.product?.code!);
  }

}
