import { Injectable } from '@angular/core';
import { CustomGtmDatalayerService } from './custom-gtm-datalayer.service';
import {
  CustomGTMCheckoutEvent,
  CustomGTMEvent,
  EventType,
} from '../../cms-components/product/model/custom-gtmProduct.model';
@Injectable({
  providedIn: 'root',
})
export class CustomGtmInteractionDatalayerService {
  constructor(private dataLayer: CustomGtmDatalayerService) {}

  // Checkout
  deliveryModeEvent(Metodo_Entrega: string, Direccion_Envio: string): void {
    let deliveryModeEvent: CustomGTMCheckoutEvent;
    deliveryModeEvent = {
      event: EventType.checkout_paso1,
      Metodo_Entrega,
      Direccion_Envio,
    };

    this.dataLayer.pushEvent(deliveryModeEvent);
  }

  paymentModeEvent(
    Metodo_Pago: string | undefined,
    forma_pago: string | undefined,
    Condicion_Pago: string | null
  ): void {
    let paymentModeEvent: CustomGTMCheckoutEvent;
    paymentModeEvent = {
      event: EventType.checkout_paso2,
      Metodo_Pago,
      forma_pago,
      Condicion_Pago,
    };

    this.dataLayer.pushEvent(paymentModeEvent);
  }

  editShowOrderEvent(Ver_pedido: boolean, Editar?: string): void {
    let editShowOrderEvent: CustomGTMCheckoutEvent;
    editShowOrderEvent = {
      event: EventType.checkout_order,
      Ver_pedido,
      Editar,
    };

    this.dataLayer.pushEvent(editShowOrderEvent);
  }

  productInteractionEvent(option: string, socialMedia?: string) {
    let productInteractionEvent: CustomGTMEvent;
    socialMedia
      ? (productInteractionEvent = {
          event: EventType.engagementProduct,
          engagement_product: option,
          compartir_ficha: socialMedia,
        })
      : (productInteractionEvent = {
          event: EventType.engagementProduct,
          engagement_product: option,
        });

    this.dataLayer.pushEvent(productInteractionEvent);
  }

  addToSavedCart(nameList: string) {
    let addToSavedCart: CustomGTMEvent;
    addToSavedCart = {
      event: EventType.eventClick,
      crearLista: nameList,
    };

    this.dataLayer.pushEvent(addToSavedCart);
  }
}
