import { Injectable } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomNonCustomerFormValidationService {

  constructor() { }

  customRucValidation(control: FormControl): ValidationErrors | null {
    const value: string = control.value;
    if(!!!value){
      return null;
    }
    if(isNaN(Number(value)) || value.length != 13){
      return { invalidRuc: true };
    }
    const lastThreeDigits = value.slice(10, 13);

    if (lastThreeDigits !== '001') {
      return { invalidRuc: true };
    }
    return null;
  }

  customPhoneValidation(control: FormControl): ValidationErrors | null {
    const value: string = control.value;
    if(isNaN(Number(value)) || value.length != 7){
      return { invalidPhone: true };
    }
    return null;
  }

  customCellhoneValidation(control: FormControl): ValidationErrors | null {
    const value: string = control.value;
    if(isNaN(Number(value)) || value.length != 9){
      return { invalidCellphone: true };
    }
    return null;
  }

  customEmailValidation(control: FormControl): ValidationErrors | null {
    const value: string = control.value;
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if(!!!value){
      return null;
    }
    const email: string = value.toLowerCase();
    const result: boolean = expression.test(email);
    if(!result){
      return {invalidEmail: true}
    }
    return null;
  }

}
