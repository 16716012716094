import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActiveCartService, OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomCheckoutCartService {
  quantity$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  quantityUpdated$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
    protected activeCartService: ActiveCartService
  ) {}

  getCartUpdated(userId: string, cartId: string): Observable<any> {
    const url = this.occEndpointService.buildUrl('updateCartWithoutStock', {
      urlParams: { userId, cartId },
    });
    if (!userId || !cartId) {
      return this.activeCartService.getActive();
    }
    return this.http
      .get<any>(url)
      .pipe(
        tap((cart) => this.setQuantityUpdated(cart?.deliveryItemsQuantity || 0))
      );
  }

  getQuantityCart(): void {
    this.quantity$.next(true);
  }

  setQuantityUpdated(value: number) {
    this.quantityUpdated$.next(value);
  }

  getQuantityUpdated$(): Observable<number> {
    return this.quantityUpdated$.asObservable();
  }
}
