import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  HamburgerMenuComponent,
  HamburgerMenuService,
} from '@spartacus/storefront';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomBreakpointService } from '../../../services/custom-breakpoint.service';
import { CustomScrollService } from '../../../services/custom-scroll.service';

@Component({
  selector: 'app-custom-hamburger-menu',
  templateUrl: './custom-hamburger-menu.component.html',
  styleUrls: ['./custom-hamburger-menu.component.scss'],
})
export class CustomHamburgerMenuComponent extends HamburgerMenuComponent {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  subscription: Subscription = new Subscription();
  constructor(
    hamburgerMenuService: HamburgerMenuService,
    @Inject(DOCUMENT) private document: Document,
    private customBreakpointService: CustomBreakpointService,
    private customScrollService: CustomScrollService
  ) {
    super(hamburgerMenuService);
  }

  customToggle(isExpanded: boolean): void {
    super.toggle();
    this.toggleTopHeaderSlotVisibility(isExpanded);
  }
  get isExpanded(): Observable<boolean> {
    return super.isExpanded;
  }

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([this.isMobile$, this.isExpanded])
        .pipe(
          tap(([isMobile, isExpanded]) => {
            if (!isMobile && isExpanded) {
              this.customToggle(isExpanded);
            }
          })
        )
        .subscribe()
    );
  }
  toggleTopHeaderSlotVisibility(isExpanded: boolean) {
    if (!isExpanded) {
      const navElement: any =
        this.document.getElementsByClassName('navigation')[0];
      this.subscription.add(
        this.customScrollService
          .getLastScrollPosition()
          .pipe(
            tap(
              (lastScrollPosition) =>
                (navElement.style.top = +(lastScrollPosition - 90) + 'px')
            )
          )
          .subscribe()
      );

      this.document
        .getElementsByClassName('TopHeaderSlot')[0]
        .classList.add('hide');
      this.document?.body?.classList?.add('menu-active');
    } else {
      this.document
        .getElementsByClassName('TopHeaderSlot')[0]
        .classList.remove('hide');
      this.document.body.classList.remove('menu-active');
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
