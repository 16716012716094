import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { SupplementHashAnchorsModule } from '@spartacus/storefront';
import { CustomSafeHtmlModule } from '../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomEmptyCartModule } from '../../../custom/components/custom-empty-cart/custom-empty-cart.module';
import { CustomParagraphComponent } from './custom-paragraph.component';



@NgModule({
  declarations: [
    CustomParagraphComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SupplementHashAnchorsModule,
    CustomSafeHtmlModule,
    CustomEmptyCartModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CMSParagraphComponent: {
          component: CustomParagraphComponent,
        },
        CMSTabParagraphComponent: {
          component: CustomParagraphComponent,
        },
      },
    })
  ]
})
export class CustomParagraphModule { }
