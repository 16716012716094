import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import {
  GenericLinkModule,
  IconModule,
  MediaModule,
} from '@spartacus/storefront';
import { CustomStaticResponsiveBanner } from './custom-static-responsive-banner.component';

@NgModule({
  declarations: [CustomStaticResponsiveBanner],
  imports: [
    CommonModule,
    RouterModule,
    GenericLinkModule,
    MediaModule,
    IconModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromesaStaticResponsiveBannerComponent: {
          component: CustomStaticResponsiveBanner,
        },
        PromesaOurcompanyResponsiveBannerComponent: {
          component: CustomStaticResponsiveBanner,
        },
      },
    }),
  ],
})
export class CustomStaticResponsiveBannerModule {}
