import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ICON_TYPE, ModalService } from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomQuoteOrchestratorService } from '../../services/custom-quote-orchestrator.service';
import { CustomQuotesService } from '../../services/custom-quotes.service';

@Component({
  selector: 'app-custom-quote-checkout-info-dialog',
  templateUrl: './custom-quote-checkout-info-dialog.component.html',
  styleUrls: ['./custom-quote-checkout-info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomQuoteCheckoutInfoDialogComponent implements OnDestroy {
  iconTypes = ICON_TYPE;
  code: string;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  unsubscribe$: Subject<void> = new Subject();
  constructor(
    private modalService: ModalService,
    private customBreakpointService: CustomBreakpointService,
    private router: Router,
    private customQuoteOrchestrator: CustomQuoteOrchestratorService,
    private customQuotesService: CustomQuotesService
  ) {}

  closeModal(reason: string) {
    this.modalService.closeActiveModal(reason);
  }

  createCartFromQuote() {
    this.customQuotesService
      .createCartFromQuote(this.code)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((cart: any) => {
        if (!cart?.error) {
          const url = window.location.origin + '/cart';
          window.open(url, '_self');
          this.customQuoteOrchestrator.resetOrchestrator();
          this.closeModal('navigate to checkout');
        }
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
