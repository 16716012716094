import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Result } from '../../../models/custom-stock.interface';

@Component({
  selector: 'app-custom-stock-info-entry',
  templateUrl: './custom-stock-info-entry.component.html',
  styleUrls: ['./custom-stock-info-entry.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomStockInfoEntryComponent {
  @Input() entry: Result;
  @Input() onlyOne: boolean = false;
  @Input() isMobile: boolean = false;
}
