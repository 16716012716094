<ng-container
  *ngIf="{
    isMobile: isMobile$ | async,
    stepOne: stepOne$ | async,
    stepThree: stepThree$ | async,
    formData: formData$ | async,
    captchaResolved: captchaResolved$ | async,
    countryCode: countryCode$ | async
  } as data"
>
  <div
    class="new-customer"
    [ngClass]="{
      final: data.stepThree,
      'with-error':
        (nonCustomerForm.invalid && formSubmited) ||
        (formSubmited && invalidPerson),
      hide: !data.stepOne && !data.stepThree
    }"
  >
    <h3 class="new-customer__title">
      {{
        (data.stepThree
          ? "nonCustomerRegister.titleConfirmation"
          : "nonCustomerRegister.title"
        ) | cxTranslate
      }}
    </h3>

    <form
      class="new-customer__form"
      (ngSubmit)="nonCustomerFormSubmit(data.countryCode)"
      [formGroup]="nonCustomerForm"
    >
      <div
        class="new-customer__form-radios"
        [ngClass]="{ readonly: data.stepThree }"
      >
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            formControlName="naturalPerson"
            (change)="personTypeChange('naturalPerson')"
            [checked]="personTypeSelected === 'naturalPerson'"
            [readonly]="data.stepThree"
            [ngClass]="{ readonly: data.stepThree }"
          />
          <label class="form-check-label first">
            {{ "nonCustomerRegister.naturalPerson" | cxTranslate }}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            formControlName="juridicPerson"
            (change)="personTypeChange('juridicPerson')"
            [checked]="personTypeSelected === 'juridicPerson'"
            [readonly]="data.stepThree"
            [ngClass]="{ readonly: data.stepThree }"
          />
          <label class="form-check-label">
            {{ "nonCustomerRegister.juridicPerson" | cxTranslate }}
          </label>
        </div>
      </div>
      <p
        class="customer-register-form-error check"
        *ngIf="invalidPerson && formSubmited"
      >
        {{ "nonCustomerRegister.uncheckedPerson" | cxTranslate }}
      </p>
      <div class="new-customer__form-inputs">
        <label>
          <span class="label-content">
            {{ "customRegister.socialReason" | cxTranslate }}
          </span>
          <input
            aria-required="true"
            formControlName="socialReason"
            type="text"
            class="form-control"
            placeholder="{{
              'customRegister.socialReasonPlaceholder' | cxTranslate
            }}"
            [ngClass]="{ readonly: data.stepThree, submited: formSubmited }"
            [readonly]="data.stepThree"
          />
          <cx-icon
            class="fas fa-info-circle"
            *ngIf="!data.stepThree"
            ngbTooltip="{{
              'nonCustomerRegister.socialReasonTooltip' | cxTranslate
            }}"
            placement="bottom"
            tooltipClass="social-reason"
            [triggers]="data.isMobile ? 'click' : 'hover'"
          ></cx-icon>
          <p
            class="customer-register-form-error"
            *ngIf="
              nonCustomerForm.get('socialReason')?.errors?.required &&
              formSubmited
            "
          >
            {{ "nonCustomerRegister.socialReasonRequired" | cxTranslate }}
          </p>
        </label>

        <label>
          <span class="label-content">
            {{ "customRegister.comercialName" | cxTranslate }}
          </span>
          <input
            aria-required="true"
            formControlName="comercialName"
            type="text"
            class="form-control"
            placeholder="{{
              'customRegister.comercialNamePlaceholder' | cxTranslate
            }}"
            [ngClass]="{ readonly: data.stepThree, submited: formSubmited }"
            [readonly]="data.stepThree"
          />
          <p
            class="customer-register-form-error"
            *ngIf="
              nonCustomerForm.get('comercialName')?.errors?.required &&
              formSubmited
            "
          >
            {{ "nonCustomerRegister.comercialNameRequired" | cxTranslate }}
          </p>
        </label>

        <label>
          <span class="label-content">
            {{ "nonCustomerRegister.ruc" | cxTranslate }}
          </span>
          <input
            aria-required="true"
            formControlName="ruc"
            type="text"
            class="form-control"
            maxlength="13"
            placeholder="{{
              'nonCustomerRegister.rucPlaceholder' | cxTranslate
            }}"
            [ngClass]="{ readonly: data.stepThree, submited: formSubmited }"
            [readonly]="data.stepThree"
          />
          <cx-icon
            class="fas fa-info-circle"
            *ngIf="!data.stepThree"
            ngbTooltip="{{ 'nonCustomerRegister.rucTooltip' | cxTranslate }}"
            placement="bottom"
            tooltipClass="ruc"
            [triggers]="data.isMobile ? 'click' : 'hover'"
          ></cx-icon>
          <p
            class="customer-register-form-error"
            *ngIf="nonCustomerForm.get('ruc')?.errors?.required && formSubmited"
          >
            {{ "nonCustomerRegister.rucRequired" | cxTranslate }}
          </p>
          <p
            class="customer-register-form-error"
            *ngIf="
              nonCustomerForm.get('ruc')?.errors?.minlength && formSubmited
            "
          >
            {{ "nonCustomerRegister.rucIncompleted" | cxTranslate }}
          </p>
          <p
            class="customer-register-form-error"
            *ngIf="
              nonCustomerForm.get('ruc')?.errors?.invalidRuc && formSubmited
            "
          >
            {{ "nonCustomerRegister.invalidRuc" | cxTranslate }}
          </p>
        </label>

        <label>
          <span class="label-content">
            {{ "nonCustomerRegister.rucLastUpdate" | cxTranslate }}
          </span>

          <mat-form-field
            class="full-width"
            appearance="fill"
            [ngClass]="{
              readonly: data.stepThree,
              submited: formSubmited,
              error:
                nonCustomerForm.get('rucLastUpdate')?.errors && formSubmited
            }"
          >
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
              [readonly]="data.stepThree"
            ></mat-datepicker-toggle>
            <mat-label>{{
              !nonCustomerForm.get("rucLastUpdate").value
                ? ("customRegister.select" | cxTranslate)
                : ""
            }}</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="rucLastUpdate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
              class="arrow"
              *ngIf="!data.stepThree"
            >
              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <p
            class="customer-register-form-error"
            *ngIf="
              nonCustomerForm.get('rucLastUpdate')?.errors?.required &&
              formSubmited
            "
          >
            {{ "nonCustomerRegister.dateRequired" | cxTranslate }}
          </p>
        </label>

        <label>
          <span class="label-content">
            {{ "nonCustomerRegister.bussinessType" | cxTranslate }}
          </span>
          <ng-select
            id="bussinessType"
            [notFoundText]="'common.notFoundText' | cxTranslate"
            formControlName="bussinessType"
            [searchable]="true"
            [clearable]="false"
            placeholder="{{ 'customRegister.select' | cxTranslate }}"
            required
            dropdownPosition="bottom"
            (change)="cambioBusiness($event)"
            [ngClass]="{
              readonly: data.stepThree,
              submited: formSubmited,
              error:
                nonCustomerForm.get('bussinessType')?.errors && formSubmited
            }"
            [readonly]="data.stepThree"
            bindLabel="businessType"
            bindValue="uid"
          >
            <ng-container *ngIf="businessType$ | async as businesses">
              <ng-option *ngFor="let business of businesses" [value]="business">
                <div class="business-option">
                  <div class="business-option__key">
                    {{ business.businessType }}
                  </div>
                </div>
              </ng-option>
            </ng-container>
          </ng-select>
          <p
            class="customer-register-form-error"
            *ngIf="
              nonCustomerForm.get('bussinessType')?.errors?.required &&
              formSubmited
            "
          >
            {{ "nonCustomerRegister.businessTypeRequired" | cxTranslate }}
          </p>
        </label>

        <label>
          <span class="label-content">
            {{ "nonCustomerRegister.email" | cxTranslate }}
          </span>
          <input
            aria-required="true"
            formControlName="email"
            type="text"
            class="form-control"
            placeholder="{{
              'nonCustomerRegister.emailPlaceholder' | cxTranslate
            }}"
            [ngClass]="{ readonly: data.stepThree, submited: formSubmited }"
            [readonly]="data.stepThree"
          />
          <p
            class="customer-register-form-error"
            *ngIf="
              nonCustomerForm.get('email')?.errors?.required && formSubmited
            "
          >
            {{ "nonCustomerRegister.emailRequired" | cxTranslate }}
          </p>
          <p
            class="customer-register-form-error"
            *ngIf="
              nonCustomerForm.get('email')?.errors?.invalidEmail && formSubmited
            "
          >
            {{ "nonCustomerRegister.invalidEmail" | cxTranslate }}
          </p>
        </label>

        <label>
          <span class="label-content">
            {{ "nonCustomerRegister.phone" | cxTranslate }}
          </span>
          <div class="phone-container">
            <ng-select
              id="phonePrefix"
              formControlName="phonePrefix"
              [searchable]="true"
              [clearable]="false"
              [notFoundText]="'common.notFoundText' | cxTranslate"
              placeholder="{{ 'nonCustomerRegister.province' | cxTranslate }}"
              required
              dropdownPosition="bottom"
              [ngClass]="{
                readonly: data.stepThree,
                submited: formSubmited,
                error:
                  (nonCustomerForm.get('phone')?.errors ||
                    nonCustomerForm.get('phonePrefix')?.errors) &&
                  formSubmited
              }"
              [readonly]="data.stepThree"
            >
              <ng-container *ngIf="regionsPrefix$ | async as regions">
                <ng-option
                  *ngFor="let region of regions | keyvalue"
                  [value]="region"
                >
                  <div class="region-option">
                    <div class="region-option__key">
                      {{ region.key | titlecase }}
                    </div>
                    <div class="region-option__value">{{ region.value }}</div>
                  </div>
                </ng-option>
              </ng-container>
            </ng-select>
            <input
              aria-required="true"
              formControlName="phone"
              type="text"
              class="form-control"
              placeholder="{{
                'nonCustomerRegister.phonePlaceholder' | cxTranslate
              }}"
              [ngClass]="{
                readonly: data.stepThree,
                submited: formSubmited,
                error:
                  (nonCustomerForm.get('phone')?.errors ||
                    nonCustomerForm.get('phonePrefix')?.errors) &&
                  formSubmited
              }"
              [readonly]="data.stepThree"
            />
            <cx-icon
              class="fas fa-info-circle"
              *ngIf="!data.stepThree"
              ngbTooltip="{{
                'nonCustomerRegister.phoneTooltip' | cxTranslate
              }}"
              placement="bottom"
              tooltipClass="phone"
              [triggers]="data.isMobile ? 'click' : 'hover'"
            ></cx-icon>
          </div>
          <p
            class="customer-register-form-error"
            *ngIf="
              nonCustomerForm.get('phone')?.errors?.required && formSubmited
            "
          >
            {{ "nonCustomerRegister.phoneRequired" | cxTranslate }}
          </p>
          <p
            class="customer-register-form-error"
            *ngIf="
              nonCustomerForm.get('phone')?.errors?.maxlength && formSubmited
            "
          >
            {{ "nonCustomerRegister.invalidPhone" | cxTranslate }}
          </p>
          <p
            class="customer-register-form-error"
            *ngIf="
              nonCustomerForm.get('phone')?.errors?.invalidPhone &&
              formSubmited &&
              !nonCustomerForm.get('phone')?.errors?.required
            "
          >
            {{ "nonCustomerRegister.invalidPhone" | cxTranslate }}
          </p>
        </label>

        <label>
          <span class="label-content">
            {{ "customRegister.cellphone" | cxTranslate }}
          </span>
          <div class="cellphone-container">
            <div
              class="cellphone-container__country-code"
              [ngClass]="{
                'cellphone-error':
                  nonCustomerForm.get('cellphone')?.errors && formSubmited,
                readonly: data.stepThree
              }"
            >
              <p class="cellphone-container__country-code-value">
                {{ data?.countryCode }}
              </p>
            </div>

            <input
              aria-required="true"
              formControlName="cellphone"
              type="text"
              class="form-control"
              placeholder="{{
                'nonCustomerRegister.cellphonePlaceholder' | cxTranslate
              }}"
              [ngClass]="{ readonly: data.stepThree, submited: formSubmited }"
              [readonly]="data.stepThree"
            />
            <cx-icon
              class="fas fa-info-circle"
              *ngIf="!data.stepThree"
              ngbTooltip="{{
                'nonCustomerRegister.cellphoneTooltip' | cxTranslate
              }}"
              placement="bottom"
              tooltipClass="cellphone"
              [triggers]="data.isMobile ? 'click' : 'hover'"
            ></cx-icon>
          </div>
          <p
            class="customer-register-form-error"
            *ngIf="
              nonCustomerForm.get('cellphone')?.errors?.required && formSubmited
            "
          >
            {{ "nonCustomerRegister.cellphoneRequired" | cxTranslate }}
          </p>
          <p
            class="customer-register-form-error"
            *ngIf="
              nonCustomerForm.get('cellphone')?.errors?.maxlength &&
              formSubmited
            "
          >
            {{ "nonCustomerRegister.invalidCellphone" | cxTranslate }}
          </p>
          <p
            class="customer-register-form-error"
            *ngIf="
              nonCustomerForm.get('cellphone')?.errors?.invalidCellphone &&
              formSubmited &&
              !nonCustomerForm.get('cellphone')?.errors?.required
            "
          >
            {{ "nonCustomerRegister.invalidCellphone" | cxTranslate }}
          </p>
        </label>
      </div>

      <app-custom-register-non-customer-docs-readonly
        [ngClass]="{ hide: !data.stepThree }"
      >
      </app-custom-register-non-customer-docs-readonly>

      <app-security-validation *ngIf="data.stepThree"></app-security-validation>

      <div class="form-check-container" *ngIf="data.stepThree">
        <div class="checks">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="terms"
            />
            <label class="form-check-label">
              {{ "nonCustomerRegister.accept" | cxTranslate }}
              <a
                class="form-check-label-link"
                [routerLink]="{ cxRoute: 'termsAndConditions' } | cxUrl"
              >
                {{ "nonCustomerRegister.termsAndConditions" | cxTranslate }}
              </a>
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="news"
              (change)="handleCheck($event)"
            />
            <label class="form-check-label">
              {{ "nonCustomerRegister.newsletter" | cxTranslate }}
            </label>
          </div>
        </div>
        <div class="form-check-container__action">
          <a class="form-check-container__action-link" (click)="return()">{{
            "nonCustomerRegister.return" | cxTranslate
          }}</a>
          <button
            class="form-check-container__action-button"
            type="button"
            (click)="finalStepSubmit(data.formData)"
            [disabled]="
              !data.captchaResolved || !nonCustomerForm.get('terms').value
            "
          >
            {{ "nonCustomerRegister.confirm" | cxTranslate }}
          </button>
        </div>
      </div>
      <div class="new-customer__form-action" *ngIf="!data.stepThree">
        <button
          class="new-customer__form-action-button"
          type="submit"
          [disabled]="disableButton"
        >
          {{ "customRegister.continue" | cxTranslate }}
        </button>
      </div>
    </form>
  </div>
</ng-container>
