<ng-container *ngIf="{ user: user$ | async, isEditPerson: editPerson$ | async, isMobile: isMobile$ | async } as data">
  <div class="custom-form-add-person">
    <h3>
      {{
      isEditForm
      ? ("addressForm.editManage" | cxTranslate)
      : ("addressForm.addManage" | cxTranslate)
      }}
    </h3>
    <form [formGroup]="formAddAuthorizedPerson" (ngSubmit)="sendNewAndEditAttendant(data.user)">
      <div class="row">
        <div class="col-md-4">
          <label>
            <span class="custom-form-add-person__label label-content">{{
              "addressForm.firstName.label" | cxTranslate
              }}</span>
            <div class="custom-form-add-person__label-input d-flex">
              <input aria-required="true" class="form-control" formControlName="name" type="text" placeholder="{{
                  'addressForm.firstName.placeholder' | cxTranslate
                }}" />
              <cx-icon class="fas fa-info-circle" ngbTooltip="{{ 'addressForm.firstName.tooltip' | cxTranslate }}"
                placement="top-right" tooltipClass="tooltip-color"></cx-icon>
            </div>
          </label>
        </div>
        <div class="col-md-4">
          <label>
            <span class="custom-form-add-person__label label-content">{{
              "addressForm.identificationCard.label" | cxTranslate
              }}</span>
            <input aria-required="true" class="form-control" formControlName="identificationCard" type="text"
              placeholder="{{
                'addressForm.identificationCard.placeholder' | cxTranslate
              }}" />
          </label>
        </div>
        <div class="col-md-4">
          <label>
            <span class="custom-form-add-person__label label-content">{{
              "addressForm.licensePlate.label" | cxTranslate
              }}</span>
            <input aria-required="true" class="form-control" formControlName="licensePlate" type="text" placeholder="{{
                'addressForm.licensePlate.placeholder' | cxTranslate
              }}" />
          </label>
        </div>
      </div>
      <div class="row mx-0 custom-form-add-person__btn-action">
        <div class="col-md-4">
          <div class="custom-form-add-person__btn">
            <button type="button" class="btn btn-secondary" (click)="closeShowAddPerson()" *ngIf="!data.isMobile">
              {{ "common.cancel" | cxTranslate }}
            </button>
            <a type="button" class="btn" (click)="closeShowAddPerson()" *ngIf="data.isMobile">
              {{ "common.cancel" | cxTranslate }}
            </a>
            <button type="submit" [disabled]="formAddAuthorizedPerson.invalid" class="btn btn-primary">
              {{
              isEditForm
              ? ("common.save" | cxTranslate)
              : ("common.add" | cxTranslate)
              }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-container>
