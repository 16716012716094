<div class="b2b-unit-validation-container">
  <div class="b2b-unit-validation-container__icon">
    <cx-icon class="fas fa-times" (click)="closeModal('close')"></cx-icon>
  </div>
  <div class="b2b-unit-validation-container__text">
    <ng-container *ngIf="!(isMobile$ | async); else textForMobile">
      <p>{{ "b2bUnit.inactiveCode" | cxTranslate }}</p>
      <p>{{ "b2bUnit.contactSupport" | cxTranslate }}</p>
    </ng-container>
    <ng-template #textForMobile>
      <p>{{ "b2bUnit.textMobile" | cxTranslate }}</p>
    </ng-template>
    <p
      class="b2b-unit-validation-container__text--return"
      (click)="closeModal('close')"
    >
      {{ "b2bUnit.goBack" | cxTranslate }}
    </p>
  </div>
</div>
