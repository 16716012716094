<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entries$ | async as entries">
    <ng-container *ngIf="!(isMobile$ | async)">
      <ng-container *ngIf="cart.modifiedPreviousPriceMap?.length > 0">
        <app-custom-cart-important-messages
          [cart]="cart"
        ></app-custom-cart-important-messages>
      </ng-container>
    </ng-container>

    <div *ngIf="cart.totalItems > 0" class="cart-details-wrapper">
      <ng-container *cxFeatureLevel="'4.2'">
        <cx-cart-validation-warnings></cx-cart-validation-warnings>
      </ng-container>

      <ng-container *ngIf="cart?.appliedOrderPromotions?.length > 0">
        <cx-promotions
          [promotions]="
            (cart.appliedOrderPromotions || []).concat(
              cart.potentialOrderPromotions || []
            )
          "
        ></cx-promotions>
      </ng-container>

      <app-custom-cart-item-list
        [cartId]="cart.code"
        [cart]="cart"
        [items]="entries"
        [cartIsLoading]="!(cartLoaded$ | async)"
        [promotionLocation]="promotionLocation"
        [options]="{
          isSaveForLater: false,
          optionalBtn: saveForLaterBtn
        }"
      ></app-custom-cart-item-list>
    </div>
  </ng-container>
</ng-container>

<ng-template let-ctx #saveForLaterBtn>
  <div
    *ngIf="selectiveCartEnabled"
    class="col-md-3 col-lg-3 col-xl-3 cx-sfl-btn"
  >
    <button
      class="link cx-action-link"
      [disabled]="ctx.loading"
      (click)="saveForLater(ctx.item)"
      type="button"
    >
      {{ "saveForLaterItems.saveForLater" | cxTranslate }}
    </button>
  </div>
</ng-template>
