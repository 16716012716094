<ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
  <div class="register-docs__form-item">
    <span class="register-docs__form-item-label">
      {{ label | cxTranslate }}
    </span>
    <div
      class="register-docs__form-item-container"
      [ngClass]="{
        valid: docValid && !download,
        read: onlyRead,
        download: download
      }"
    >
      <div
        class="register-docs__form-item-container__icon-remove"
        [ngClass]="{ read: onlyRead }"
        (click)="removeFile()"
        *ngIf="docValid && !download"
      >
        <cx-icon class="fas fa-times"></cx-icon>
      </div>
      <div class="register-docs__form-item-container__icon">
        <cx-icon class="far fa-file-alt"></cx-icon>
        <p class="register-docs__form-item-container__icon-text">
          {{
            docValid && !download
              ? docName
              : !download
              ? ("nonCustomerRegister.doc" | cxTranslate)
              : ("nonCustomerRegister.pdfDocumentName" | cxTranslate)
          }}
        </p>
      </div>
      <div
        class="register-docs__form-item-container__placeholder"
        [ngClass]="{
          valid: docValid && !download,
          download: !docValid && download
        }"
      >
        {{
          docValid || download
            ? docDate
            : ((data.isMobile
                ? "nonCustomerRegister.uploadPdf"
                : "nonCustomerRegister.dragOrUpload"
              ) | cxTranslate)
        }}
      </div>
      <div class="register-docs__form-item-container__action">
        <ng-container *ngIf="!docValid; else validIdDoc">
          <input
            *ngIf="!download"
            aria-required="true"
            [formControl]="FormControl"
            type="file"
            (change)="getFile($event, doc)"
          />
          <button
            class="register-docs__form-item-container__action-button"
            [ngClass]="{ download: download }"
            [disabled]="disableButton"
            (click)="downloadPdf(download, appointmentDocument)"
          >
            <cx-icon
              [class]="
                !download
                  ? 'far fa-arrow-alt-circle-up'
                  : 'far fa-arrow-alt-circle-down'
              "
            >
            </cx-icon>
            <span
              >{{
                !download
                  ? ("nonCustomerRegister.upload" | cxTranslate)
                  : ("nonCustomerRegister.download" | cxTranslate)
              }}
            </span>
          </button>
        </ng-container>
        <ng-template #validIdDoc>
          <div
            class="register-docs__form-item-container__action-valid"
            [ngClass]="{ read: onlyRead }"
          >
            <cx-icon class="fas fa-check"></cx-icon>
            <p class="register-docs__form-item-container__action-valid-text">
              {{ "nonCustomerRegister.validDoc" | cxTranslate }}
            </p>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
