import { registerLocaleData } from '@angular/common';
import { NgModule } from '@angular/core';

import LocaleEsAR from '@angular/common/locales/es-AR';
import { CustomCurrencyPipe } from './custom-currency.pipe';

registerLocaleData(LocaleEsAR);


@NgModule({
  declarations: [	CustomCurrencyPipe,
   ],
  exports: [CustomCurrencyPipe],
})
export class CustomCurrencyModule {}
