import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomUpdateProfileComponent } from './custom-update-profile.component';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  GlobalMessageService,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { CustomUpdateProfileService } from './custom-update-profile.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FormErrorsModule, IconModule } from '@spartacus/storefront';
import { CustomUpdatePasswordModule } from './custom-update-password/custom-update-password.module';
import { CustomTitleMyAccountModule } from 'src/app/cms-components/custom/components/custom-title-my-account/custom-title-my-account.module';
import { CustomLoadingSpinnerModule } from 'src/app/cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';

@NgModule({
  declarations: [CustomUpdateProfileComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    I18nModule,
    IconModule,
    FormErrorsModule,
    RouterModule,
    UrlModule,
    CustomUpdatePasswordModule,
    CustomTitleMyAccountModule,
    CustomLoadingSpinnerModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        UpdateProfileComponent: {
          component: CustomUpdateProfileComponent,
          guards: [AuthGuard],
          providers: [
            {
              provide: CustomUpdateProfileService,
              useClass: CustomUpdateProfileService,
              deps: [UserProfileFacade, GlobalMessageService],
            },
          ],
        },
      },
    }),
  ],
})
export class CustomUpdateProfileModule {}
