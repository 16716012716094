import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFacetListComponent } from './custom-facet-list.component';
import { I18nModule } from '@spartacus/core';
import {
  FacetModule,
  IconModule,
  KeyboardFocusModule,
  ListNavigationModule,
} from '@spartacus/storefront';
import { CustomActiveFacetsModule } from '../custom-active-facets/custom-active-facets.module';
import { CustomFacetReviewsModule } from './custom-facet-reviews/custom-facet-reviews.module';
import { CustomFacetModule } from './custom-facet/custom-facet.module';
import { CustomPriceFacetModule } from './custom-price-facet/custom-price-facet.module';
import { CustomSortingModule } from 'src/app/cms-components/custom/components/custom-sorting/custom-sorting.module';
import { CustomLoadingSpinnerModule } from 'src/app/cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';

@NgModule({
  declarations: [CustomFacetListComponent],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    FacetModule,
    KeyboardFocusModule,
    CustomFacetReviewsModule,
    CustomFacetModule,
    ListNavigationModule,
    CustomPriceFacetModule,
    CustomSortingModule,
    CustomActiveFacetsModule,
    CustomLoadingSpinnerModule,
  ],
  exports: [CustomFacetListComponent],
})
export class CustomFacetListModule {}
