import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { RoutingService } from '@spartacus/core';
import { OrderDetailsService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomOrderReturnGuard implements CanActivate {
  constructor(
    protected orderDetailsService: OrderDetailsService,
    protected routingService: RoutingService
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.orderDetailsService.getOrderDetails().pipe(
      map((order) => {
        if (order?.returnable) {
          return true;
        } else {
          this.routingService.go({ cxRoute: 'orders' });
          return false;
        }
      })
    );
  }
}
