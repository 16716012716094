import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomAddress } from '../models/custom-address.interface';

@Injectable({
  providedIn: 'root'
})
export class CustomAddressOrchestratorService {

  editMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  addNewMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readOnlyMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  deleted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  addressToEdit$: BehaviorSubject<CustomAddress | null>= new BehaviorSubject<CustomAddress | null>(null);
  availableTypes$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }

  setEditMode(value: boolean){
    this.editMode$.next(value);
  }

  setAddNewMode(value: boolean){
    this.addNewMode$.next(value);
  }

  setReadOnlyMode( value: boolean){
    this.readOnlyMode$.next(value);
  }

  getEditMode(): Observable<boolean>{
    return this.editMode$.asObservable();
  }

  getAddNewMode(): Observable<boolean>{
    return this.addNewMode$.asObservable();
  }

  getReadOnlyMode(): Observable<boolean>{
    return this.readOnlyMode$.asObservable();
  }

  setDeleted(value: boolean){
    this.deleted$.next(value);
  }

  getDeleted(): Observable<boolean>{
    return this.deleted$.asObservable();
  }

  setAddressToEdit(value: CustomAddress){
    this.addressToEdit$.next(value);
  }

  getAddressToEdit(): Observable<CustomAddress | null>{
    return this.addressToEdit$.asObservable();
  }

  setAvailableTypes(value: boolean){
    this.availableTypes$.next(value);
  }

  getAvailableTypes(): Observable<boolean>{
    return this.availableTypes$.asObservable();
  }
}
