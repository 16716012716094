import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import {
  CmsComponentData,
  ProductListComponentService,
  ProductScrollComponent,
} from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomProductSearchPage } from 'src/app/cms-components/product/model/custom-product-search-page.interface';

@Component({
  selector: 'app-custom-product-scroll',
  templateUrl: './custom-product-scroll.component.html',
  styleUrls: ['./custom-product-scroll.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomProductScrollComponent
  extends ProductScrollComponent
  implements OnDestroy
{
  @Input() haveAutomotiveLabel: boolean = false;
  model: CustomProductSearchPage;
  clickedFrom: string;
  @Input('model')
  set setModel(inputModel: CustomProductSearchPage) {
    this.customInfiniteScrollOperations(inputModel);
  }
  unsubscribe$: Subject<void> = new Subject();
  constructor(
    protected componentData: CmsComponentData<any>,
    productListComponentService: ProductListComponentService,
    private _ref: ChangeDetectorRef
  ) {
    super(productListComponentService, _ref);
    componentData.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => (this.clickedFrom = data.name));
  }

  private customInfiniteScrollOperations(
    inputModel: CustomProductSearchPage
  ): void {
    if (this.appendProducts) {
      this.model = {
        ...inputModel,
        products: this.model?.products?.concat(inputModel?.products!),
        plpVariantDataList: this.model?.plpVariantDataList?.concat(
          inputModel?.plpVariantDataList!
        ),
      };
    } else {
      this.model = inputModel;
      this.maxProducts = this.productLimit;
    }
    this.customSetConditions();
    this._ref.markForCheck();
  }

  private customSetConditions(): void {
    this.isEmpty = !this.model.products || this.model.products.length === 0;

    this.isLastPage =
      this.model?.pagination?.currentPage ===
      this.model?.pagination?.totalPages! - 1;

    this.isMaxProducts =
      !!this.productLimit &&
      this.productLimit !== 0 &&
      this.model?.products?.length! >= this.maxProducts;

    //Add the productLimit to the current number of products to determine the next max number of products
    if (this.isMaxProducts) {
      this.maxProducts = this.model?.products?.length! + this.productLimit;
    }

    //Only change viewMode once the new model is set
    //This prevents flickering issues
    if (this.viewMode !== this.inputViewMode) {
      this.viewMode = this.inputViewMode;
    }

    this.resetList = false;
    this.appendProducts = false;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    super.ngOnDestroy();
  }
}
