import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CartNotEmptyGuard,
  CheckoutAuthGuard,
  CheckoutStepsSetGuard,
} from '@spartacus/checkout/components';
import {
  CmsConfig,
  ConfigModule,
  DeferLoadingStrategy,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import {
  CardModule,
  CartSharedModule,
  IconModule,
  PromotionsModule,
} from '@spartacus/storefront';
import { CustomReviewSubmitComponent } from './custom-review-submit.component';
import { CustomPlaceOrderModule } from '../custom-place-order/custom-place-order.module';

@NgModule({
  declarations: [CustomReviewSubmitComponent],
  exports: [CustomReviewSubmitComponent],
  imports: [
    CommonModule,
    CardModule,
    CartSharedModule,
    I18nModule,
    UrlModule,
    RouterModule,
    PromotionsModule,
    CustomPlaceOrderModule,
    IconModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutReviewOrder: {
          component: CustomReviewSubmitComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, CheckoutStepsSetGuard],
          deferLoading: DeferLoadingStrategy.DEFER,
        },
      },
    }),
  ],
})
export class CustomReviewSubmitModule {}
