import { Inject, Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';

import { TmsCollectorConfig, WindowObject } from '@spartacus/tracking/tms/core';
import { GtmCollectorService } from '@spartacus/tracking/tms/gtm';
import { CustomGTMEvent } from '../../cms-components/product/model/custom-gtmProduct.model';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomGtmDatalayerService {
  dataLayer: WindowObject = this.winRef.nativeWindow!;
  customTmsCollectorConfig: TmsCollectorConfig = {
    debug: false,
    events: [],
    dataLayerProperty: 'dataLayer',
  };
  clearEvent = { items: null };
  constructor(
    protected winRef: WindowRef,
    private gtmCollectorService: GtmCollectorService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  pushEvent(event: CustomGTMEvent): void {
    this.gtmCollectorService.pushEvent(
      this.customTmsCollectorConfig,
      this.dataLayer,
      event
    );
    this.gtmCollectorService.pushEvent(
      this.customTmsCollectorConfig,
      this.dataLayer,
      this.clearEvent
    );
  }

  setGTMScript(): void {
    const script: HTMLScriptElement = this.document.createElement('script');
    const noScript = this.document.createElement('noscript');
    const gtmId: string = environment.gtmId;
    script.innerHTML = `(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(d.createComment('GTM'), f);
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "${gtmId}");`;
    this.document.head.insertBefore(
      this.document.createComment('End GTM'),
      this.document.head.firstChild
    );

    this.document.head.insertBefore(script, this.document.head.firstChild);
    const iframe = this.document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    iframe.setAttribute('loading', 'lazy');
    noScript.append && noScript.append(iframe);
    this.document.body.insertBefore(
      this.document.createComment('End GTM (No-Script)'),
      this.document.body.firstChild
    );
    this.document.body.insertBefore(noScript, this.document.body.firstChild);
    this.document.body.insertBefore(
      this.document.createComment('GTM (No-Script)'),
      this.document.body.firstChild
    );
  }
}
