import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { CustomRegisterNonClientFormService } from '../../services/custom-register-non-client-form.service';
import { UploadProgressService } from '../../services/upload-progress.service';
import { CustomRegisterDocsService } from '../../services/custom-register-docs.service';


@Component({
  selector: 'app-custom-upload-register-progress-dialog',
  templateUrl: './custom-upload-register-progress-dialog.component.html',
  styleUrls: ['./custom-upload-register-progress-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomUploadRegisterProgressDialogComponent implements OnInit {

  invalidFileExtension: boolean = false;
  invalidFileSize: boolean = false;
  removeFile: boolean = false;
  fileName: string;
  progress$: Observable<number>;
  uploadError$: Observable<boolean>;
  uuid: string;
  docType: string;
  subscription: Subscription = new Subscription();
  constructor(
    private modalService: ModalService,
    private uploadProgressService: UploadProgressService,
    private customRegisterNonClientFormService: CustomRegisterNonClientFormService,
    private customRegisterDocsService: CustomRegisterDocsService
  ) { }

  ngOnInit(): void {
    this.subscription.add(this.progress$.subscribe(
      progress => {
        if(progress){
          if(progress == 100){
            this.closeModal('upload completed')
            this.uploadProgressService.setProgressSource(0);
          }
        }
      }
    ));
  }

  closeModal(reason: string){
    this.modalService.dismissActiveModal(reason);
    this.uploadProgressService.setUploadError(false);
  }

  deleteDocument(){
    this.subscription.add(this.customRegisterNonClientFormService.deleteDocument(this.uuid).subscribe(
      (response: boolean) => {

        if(response){
          this.customRegisterDocsService.setRemovedDoc(
            {removed: response, uuid: this.uuid, docType: this.docType}
          );
          this.closeModal('document removed');
        }
      }
    ));

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
