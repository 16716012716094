import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CustomBreakpointService } from '../../../../../services/custom-breakpoint.service';
import { Observable } from 'rxjs';
import { ModalService } from '@spartacus/storefront';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-promeclub-register-success',
  templateUrl: './custom-promeclub-register-success.component.html',
  styleUrls: ['./custom-promeclub-register-success.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomPromeclubRegisterSuccessComponent implements OnInit {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;

  constructor(
    private customBreakpointService: CustomBreakpointService,
    private modalService: ModalService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  closeModal(reason?: string): void {
    this.modalService.closeActiveModal(reason);
  }

  navigateToHome(): void {
    this.router
      .navigateByUrl('/')
      .then((navigate) => this.closeModal('navigation'));
  }

  ngOnDestroy(): void {
    this.navigateToHome();
  }
}
