import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OccEndpointsService } from '@spartacus/core';
import { PromesaRegisterClient } from "../models/custom-cms-register.interface";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomRegisterCustomerValidatorService {
  clientInfo$: BehaviorSubject<PromesaRegisterClient | null> = new BehaviorSubject<PromesaRegisterClient | null>(null);

  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
  ) { }

  submitForm(clientCode: string, RUC: string) {
    const url = this.occEndpointService.buildUrl('promesaClientInfo', {
      queryParams: {
        clientCode,
        RUC
      },
    });
    return this.http.get<PromesaRegisterClient>(url).pipe(
      catchError(error => { return of(error?.error?.errors?.[0]?.type) })
    );
  }

  setClientInfo(clientInfo: PromesaRegisterClient) {
    this.clientInfo$.next(clientInfo);
  }

  getClientInfo(): Observable<PromesaRegisterClient | null> {
    return this.clientInfo$.asObservable();
  }
}
