import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  CmsLinkComponent,
  CmsService,
  Page,
  Product,
  ProductService,
} from '@spartacus/core';
import {
  CmsComponentData,
  ProductCarouselComponent,
} from '@spartacus/storefront';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CustomGtmEcommerceDatalayerService } from 'src/app/common/services/custom-gtm-ecommerce-datalayer.service';
import SwiperCore, {
  Autoplay,
  EffectFade,
  Pagination,
  SwiperOptions,
} from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';
import { CustomCmsProductCarouselComponent as model } from '../../model/custom-cms.model';
import { CustomCmxProductCarousel } from '../../model/custom-product-carousel.interface';
import { CustomProductScope } from '../../model/custom-product-scope';

SwiperCore.use([EffectFade, Pagination, Autoplay]);
@Component({
  selector: 'app-custom-product-carousel',
  templateUrl: './custom-product-carousel.component.html',
  styleUrls: ['./custom-product-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomProductCarouselComponent
  extends ProductCarouselComponent
  implements OnInit, AfterViewInit
{
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  seeMoreLink$: Observable<CmsLinkComponent | null>;
  currentPage$: Observable<Page> = this.cms.getCurrentPage();
  private unsubscribe$ = new Subject<void>();
  arrayProduct: Product[] = [];

  config: SwiperOptions;
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  private componentDataFixed$: Observable<model> =
    this.componentData.data$.pipe(filter((data) => Boolean(data)));

  clickedFrom: string | undefined;

  constructor(
    protected componentData: CmsComponentData<CustomCmxProductCarousel>,
    protected productService: ProductService,
    protected customBreakpointService: CustomBreakpointService,
    protected cms: CmsService,
    protected customGtmDatalayerService: CustomGtmEcommerceDatalayerService
  ) {
    super(componentData, productService);
  }

  ngOnInit(): void {
    this.config = {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 8,
      pagination: {
        clickable: true,
      },
      watchOverflow: true,
    };

    this.seeMoreLink$ = this.componentData.data$.pipe(
      tap((data) =>
        data?.name
          ? (this.clickedFrom = data?.name)
          : (this.clickedFrom = data?.uid)
      ),
      map((data: CustomCmxProductCarousel) =>
        !!data?.seeMoreLink ? data.seeMoreLink : null
      )
    );

    this.items$
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((products) => {
          return combineLatest(products);
        })
      )
      .subscribe((products) => {
        // hasta que no esten todos los prouctos resueltos no se manda el evento
        if (products.every((prod: any) => !!prod)) {
          this.customGtmDatalayerService.viewProductEvent(
            products.slice(0, 5),
            this.clickedFrom
          );
        }
      });
  }

  /**
   * Observable that holds an Array of Observables. This is done, so that
   * the component UI could consider to lazy load the UI components when they're
   * in the viewpoint.
   *
   * NOTE: Sobreescribo el metodo para tomar el atributo productCodesFixed que viene ordenado
   */
  items$: Observable<Observable<Product>[]> = this.componentDataFixed$.pipe(
    map(
      (data) =>
        (data?.productCodesFixed
          ? data.productCodesFixed?.trim().split(' ')
          : data?.productCodes?.trim().split(' ')) ?? []
    ),
    map((codes) =>
      codes.map((code: string) =>
        this.productService.get(code, [
          this.PRODUCT_SCOPE,
          CustomProductScope.PRICE,
          CustomProductScope.URL,
        ])
      )
    )
  );

  ngAfterViewInit(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
