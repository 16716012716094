import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { FacetValue } from '../../../models/custom-search-facets.model';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-searchbox-suggests-item',
  templateUrl: './custom-searchbox-suggests-item.component.html',
  styleUrls: ['./custom-searchbox-suggests-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomSearchboxSuggestsItemComponent {
  @Input() suggestions: string[];
  @Input() searchInputValue: string;
  @Input() categories: FacetValue[];
  @Input() brands: FacetValue[];
  @Input() isInPromeclub: boolean;
  @Output() onOverCategoryEvent = new EventEmitter<FacetValue>();
  @Output() onOverBrandEvent = new EventEmitter<FacetValue>();

  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    private router: Router,
    protected customBreakpointService: CustomBreakpointService
  ) {}

  navigateToUrl(value: FacetValue) {
    this.router.navigateByUrl(
      `/search/${this.searchInputValue}?query=${value?.query?.query?.value}`
    );
  }

  onCategoryMouseOver(value: FacetValue) {
    this.onOverCategoryEvent.emit(value);
  }
  onBrandMouseOver(value: FacetValue) {
    this.onOverBrandEvent.emit(value);
  }
}
