import { OrderEntry, Product } from '@spartacus/core';

export interface CustomGTMProduct {
  item_name?: string;
  item_id?: string;
  price?: number;
  item_brand?: string;
  item_category?: string;
  item_variant?: string | null;
  quantity?: number;
  item_list_name?: string;
  index?: number | null;
  dimension1?: boolean;
  dimension2?: string | null;
  dimension3?: number | null;
  dimension4?: boolean;
  dimension5?: number;
}

export interface CustomGTMEvent {
  event?: string;
  ecommerce?:
    | AddToCart
    | RemoveFromCart
    | ProductClick
    | CheckoutEvent
    | ViewProducts
    | PromoViewEvent
    | PromotioBannerViewEvent
    | null;
  myAccount?: string;
  crearLista?: string;
  suscriptionNewsletter?: boolean;
  status?: string;
  UserId?: string;
  engagement_product?: string;
  compartir_ficha?: string;
  footer?: string;
}

export interface CustomGTMCheckoutEvent {
  event?: string;
  Metodo_Entrega?: string;
  Direccion_Envio?: string;
  Metodo_Pago?: string;
  forma_pago?: string;
  Condicion_Pago?: string | null;
  Ver_pedido?: boolean;
  Editar?: string;
}

export interface CustomGTMNewAccountEvent {
  event?: string;
  Tipo_Persona?: string;
  Tipo_Negocio?: string;
  Provincia?: string;
  Newsletter?: string;
  Id_Empresa?: string;
  UserID?: string;
  Cuenta_Creada?: boolean;
}

export interface CustomGTMSearchEvent {
  event?: string;
  keyword?: string;
  search_result?: string;
}
export interface CustomGTMSearchRefinerEvent {
  event?: string;
  marca?: string;
  modelo?: string;
  anio?: string;
  motor?: string | null;
}

export interface CustomGTMHeaderEvent {
  event?: string;
  CategoriaHeader?: string;
  MenuHeader?: string;
  subMenuHeader?: string;
}

export interface ProductClick {
  currency?: string;
  item_list_name?: ActionField;
  items?: CustomGTMProduct[];
}

export interface CheckoutEvent {
  currency?: string;
  value?: number;
  coupon?: string;
  transaction_id?: string;
  discount?: number;
  shipping?: number;
  tax?: number;
  items?: CustomGTMProduct[];
}
export interface PromotioBannerViewEvent {
  items?: Promotions[];
}
export interface PromoViewEvent {
  items?: Promotions[];
}
export interface AddToCart {
  currency?: string;
  value?: number;
  items?: CustomGTMProduct[];
}

export interface RemoveFromCart {
  currency?: string;
  value?: number;
  items?: CustomGTMProduct[];
}

export interface ViewProducts {
  currency?: string;
  list?: string;
  items?: CustomGTMProduct[];
}

export enum EventType {
  addToCart = 'add_to_cart',
  removeFromCart = 'remove_from_cart',
  productClick = 'select_item',
  login = 'login',
  checkout = 'begin_checkout',
  eventClick = 'eventClick',
  suscripcion_newsletter = 'suscripcion_newsletter',
  trackLogin = 'trackLogin',
  engagementProduct = 'engagementProducto',
  checkout_paso1 = 'checkout_paso1',
  checkout_paso2 = 'checkout_paso2',
  checkout_order = 'checkout_order',
  promotionClick = 'select_promotion',
  newClient = 'Crear-Cuenta-No-Cliente',
  search_refiner = 'Buscar_por_vehiculo',
  search_result = 'searchResult',
  productDetails = 'view_item',
  transaction = 'purchase',
  promotionView = 'view_promotion',
  productImpression = 'view_item_list',
  addShippingInfo = 'add_shipping_info',
  addPaymentInfo = 'add_payment_info',
  userTracking = 'user_tracking',
}

export enum SocialMedia {
  FACEBOOK = 'Facebook',
  EMAIL = 'Email',
  WHATSAPP = 'WhatsApp',
  COPY_LINK = 'Copiar link',
}

export enum EngagementProducto {
  SHARE_CARD = 'compartir_ficha',
  PRODUCT_PHOTO = 'fotos_producto',
  NEW_REVIEW = 'crear_reseña',
  SEND_REVIEW = 'enviar_reseña',
}

export enum HeaderCategory {
  FERRETERIA = 'Ferreteria',
  AUTOMOTRIZ = 'Automotriz',
}

export enum UserAnonymous {
  ANONYMOUS = 'anonymous',
}

export interface ProductGTM extends Product {
  brand?: {
    code: string;
    name: string;
  };
}

export interface ProductQuantityCustom {
  basePrice?: {
    formattedValue: string;
    value: number;
  };
  cancellableQuantity: null;
  configurationInfos: null;
  entryNumber: number;
  product?: ProductGTM;
  promotions: null;
  quantity: number;
  returnableQuantity: number;
  statusSummaryList: null;
  totalPrice: {
    currencyIso: string;
    formattedValue: string;
    value: number;
  };
  updateable: boolean;
}
export interface orderEntryGtm extends OrderEntry {
  product?: ProductGTM;
}

export interface ActionField {
  list?: string;
  id?: string;
  affiliation?: string;
  revenue?: number;
  tax?: number;
  discounts?: number;
  shipping?: number;
  coupon?: string;
  step?: number;
  option?: string;
}

export enum EventSingInType {
  LOGIN_SUCCESSFULLY = 'login-exitoso',
  LOGIN_ERROR = 'login-noexitoso',
}

export interface Promotions {
  promotion_id?: string;
  promotion_name?: string;
  creative_name?: string | null;
  creative_slot?: string;
}
