import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Cart, RoutingService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomQuoteOrchestratorService } from './services/custom-quote-orchestrator.service';
import { CustomQuotesService } from './services/custom-quotes.service';
import { QUOTE_STATE } from './constansts/quotes-constansts';

@Component({
  selector: 'app-custom-quotes',
  templateUrl: './custom-quotes.component.html',
  styleUrls: ['./custom-quotes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomQuotesComponent implements OnInit {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  showForm: boolean;
  quote$: Observable<Cart> = this.customQuotesService.getQuoteByParam().pipe(
    tap((quote: any) => {
      this.showForm = quote?.state === QUOTE_STATE.BUYER_DRAFT;
      this.setItemsInReview(quote);
    })
  );
  isInReview$: Observable<boolean> =
    this.customQuoteOrchestrator.getGoToSummary();
  isInQuoteDetail$: Observable<boolean> =
    this.customQuoteOrchestrator.getInQuoteDetail();
  isInQuoteOffer$: Observable<boolean> =
    this.customQuoteOrchestrator.getInQuoteOffer();
  refreshQuoteDetail$: Observable<boolean> =
    this.customQuoteOrchestrator.getRefreshQuoteDetail();
  subscription: Subscription = new Subscription();
  constructor(
    private customBreakpointService: CustomBreakpointService,
    private customQuotesService: CustomQuotesService,
    private customQuoteOrchestrator: CustomQuoteOrchestratorService,
    private routingService: RoutingService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.isInReview$.subscribe((value) => {
        if (value) {
          this.quote$ = this.customQuotesService
            .getQuoteByParam()
            .pipe(tap((quote) => this.setItemsInReview(quote)));
        }
      })
    );
    this.subscription.add(
      this.refreshQuoteDetail$.subscribe((value) => {
        if (value) {
          this.quote$ = this.customQuotesService
            .getQuoteByParam()
            .pipe(tap((quote) => this.setItemsInReview(quote)));
          this.customQuoteOrchestrator.setRefreshQuoteDetail(false);
        }
      })
    );
    this.customQuoteOrchestrator.setCheckQuoteDetailPage(true);
  }

  goBack(inOffer: boolean = false) {
    if (!inOffer) {
      this.customQuoteOrchestrator.setRefreshQuoteDetail(true);
      this.customQuoteOrchestrator.setGoToSummary(false);
    } else {
      this.routingService.go({ cxRoute: 'quotes' });
    }
  }

  countItemsInReview(entries: any) {
    this.customQuoteOrchestrator.resetItemsInReview();
    entries?.map((entry: any) => {
      if (entry.requestedPrice) {
        this.customQuoteOrchestrator.setItemsInReview(1);
      }
    });
  }

  setItemsInReview(quote: Cart | any) {
    this.customQuoteOrchestrator.resetItemsInReview();
    quote?.entries?.map((entry: any) => {
      if (entry?.requestedPrice) {
        this.customQuoteOrchestrator.setItemsInReview(1);
      }
    });
    this.customQuoteOrchestrator.setActiveCheckoutButton(
      quote?.allEntriesRevised && quote?.state === QUOTE_STATE.BUYER_OFFER
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.customQuoteOrchestrator.resetOrchestrator();
  }
}
