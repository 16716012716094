<ng-container *ngIf="{isMobile: isMobile$ | async} as data">
  <div #dialogClientInactive>
    <app-custom-modal-generic></app-custom-modal-generic>
  </div>
  <div class="custom-client-inactive">
    <p class="custom-client-inactive__message" *ngIf="!data.isMobile">
      {{ "nonCustomerRegister.clientInactive" | cxTranslate }}
    </p>
    <p class="custom-client-inactive__message" *ngIf="!data.isMobile">
      {{ "nonCustomerRegister.clientInactiveMessagge" | cxTranslate }}
    </p>
    <p class="custom-client-inactive__message" *ngIf="data.isMobile">
      {{ "nonCustomerRegister.clientInactiveMobile" | cxTranslate }}
    </p>
    <a [routerLink]="'/'" cxModal="dismiss" cxModalReason="Click return">
      {{ "nonCustomerRegister.goHome" | cxTranslate }}
    </a>
  </div>
</ng-container>
