import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CartPageLayoutHandler } from '@spartacus/storefront';
import { CustomAddToCartModule } from './custom-add-to-card/custom-add-to-cart.module';
import { CustomAddToSavedCartModule } from './custom-add-to-saved-cart/custom-add-to-saved-cart.module';
import { CustomCartCouponModule } from './custom-cart-coupon/custom-cart-coupon.module';
import { CustomCartDetailsModule } from './custom-cart-details/custom-cart-details.module';
import { CustomCartSharedModule } from './custom-cart-shared/custom-cart-shared.module';
import { CustomCartTotalsModule } from './custom-cart-totals/custom-cart-totals.module';
import { CustomMiniCartModule } from './custom-mini-cart/custom-mini-cart.module';
import { CustomCartPageLayoutHandler } from './services/custom-cart-page-layout-handler';
import { CustomFloatingAddToCartModule } from './custom-floating-add-to-cart/custom-floating-add-to-cart.module';
import { CustomCartEffects } from './store/effects/custom-cart.effect';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CustomAddToCartModule,
    CustomMiniCartModule,
    CustomCartSharedModule,
    CustomCartDetailsModule,
    CustomAddToSavedCartModule,
    CustomCartCouponModule,
    CustomCartTotalsModule,
    EffectsModule.forFeature([CustomCartEffects]),
    CustomFloatingAddToCartModule,
  ],
  providers: [
    {
      provide: CartPageLayoutHandler,
      useExisting: CustomCartPageLayoutHandler,
    },
  ],
})
export class CartModule {}
