import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CustomLoadingSpinnerModule } from 'src/app/cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomTitleMyAccountModule } from 'src/app/cms-components/custom/components/custom-title-my-account/custom-title-my-account.module';
import { CustomPromeclubUpdateProfileComponent } from './custom-promeclub-update-profile.component';



@NgModule({
  declarations: [
    CustomPromeclubUpdateProfileComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    CustomLoadingSpinnerModule,
    CustomTitleMyAccountModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromeclubUpdateProfileComponent: {
          component: CustomPromeclubUpdateProfileComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ]
})
export class CustomPromeclubUpdateProfileModule { }
