import { Component } from '@angular/core';
import { CustomBaseSiteCheckerService } from '../../../../services/custom-base-site-checker.service';
import { HamburgerMenuService } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-wishlist-navigation',
  templateUrl: './custom-wishlist-navigation.component.html',
  styleUrls: ['./custom-wishlist-navigation.component.scss'],
})
export class CustomWishlistNavigationComponent {
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();

  constructor(
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    private hamburgerMenuService: HamburgerMenuService
  ) {}

  closeMenu(): void {
    if (this.hamburgerMenuService?.isExpanded?.value) {
      this.hamburgerMenuService.toggle();
    }
  }
}
