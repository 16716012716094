<ng-container
  *ngIf="{
    isUserLoggedIn: isUserLoggedIn$ | async,
    isMobile: isMobile$ | async,
    userId: userId$ | async
  } as data"
>
  <div
    *ngIf="!!product"
    class="product-card"
    [ngClass]="{
      'logged-out': !data.isUserLoggedIn,
      content: fromContentPage || isPdpMobile,
      rebate: isRebate
    }"
  >
    <ng-container
      *ngIf="
        !(isMobile$ | async) &&
        product?.stock?.stockLevelStatus === 'outOfStock'
      "
    >
      <div class="product-card__badges" *ngIf="!isPromeClubActive">
        <app-custom-gift-product
          label="{{ 'addToCart.outOfStock' | cxTranslate }}"
          [template]="page?.template"
        >
        </app-custom-gift-product>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        !(isMobile$ | async) &&
        product?.stock?.stockLevelStatus != 'outOfStock' &&
        product?.badge
      "
    >
      <div class="product-card__badges">
        <app-custom-gift-product
          [wideLabel]="wideLabel"
          [label]="product?.badge"
          [template]="page?.template"
        >
        </app-custom-gift-product>
      </div>
    </ng-container>
    <ng-container *ngIf="fromContentPage || isPdpMobile">
      <ng-container
        *ngIf="
          (isMobile$ | async) &&
          product?.stock?.stockLevelStatus === 'outOfStock' &&
          !isPromeClubActive
        "
      >
        <div
          class="product-card__badges"
          [ngClass]="{
            content: fromContentPage || isPdpMobile,
            rebate: isRebate
          }"
        >
          <app-custom-gift-product
            [wideLabel]="wideLabel"
            label="{{ 'addToCart.outOfStock' | cxTranslate }}"
            [template]="page?.template"
          ></app-custom-gift-product>
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          (isMobile$ | async) &&
          product?.stock?.stockLevelStatus != 'outOfStock' &&
          product?.badge
        "
      >
        <div
          class="product-card__badges"
          [ngClass]="{
            content: fromContentPage || isPdpMobile,
            rebate: isRebate
          }"
        >
          <app-custom-gift-product
            [wideLabel]="wideLabel"
            [label]="product?.badge"
            [template]="page?.template"
          >
          </app-custom-gift-product>
        </div>
      </ng-container>
    </ng-container>

    <div
      class="product-card__image"
      [ngClass]="{
        'logged-out': !data.isUserLoggedIn,
        content: fromContentPage || isPdpMobile,
        rebate: isRebate,
        'product-card__image-promeclub': isPromeClubActive
      }"
    >
      <ng-container *ngIf="isPromeClubActive && !data.isMobile">
        <div class="icon-fav">
          <app-custom-wishlist
            [product]="product"
            [userId]="data.userId"
            [isUserLoggedIn]="data.isUserLoggedIn"
          ></app-custom-wishlist>
        </div>
      </ng-container>
      <a
        tabindex="0"
        [routerLink]="product?.url"
        (click)="productClickEvent(product)"
      >
        <cx-generic-link [url]="product?.url">
          <cx-media
            [container]="product.images?.PRIMARY || product.images"
            format="product"
            [alt]="product?.name"
            [ngClass]="{ 'product-card__image--promeclub': isPromeClubActive }"
          ></cx-media>
        </cx-generic-link>
      </a>
    </div>

    <div
      class="grid-container"
      [ngClass]="{
        'full-width': isPromeClubActive,
        'logged-out': !data.isUserLoggedIn
      }"
    >
      <div
        class="product-card__info"
        [ngClass]="{
          'logged-out': !data.isUserLoggedIn && !isPromeClubActive,
          'logged-out-promeclub': !data.isUserLoggedIn && isPromeClubActive,
          content: fromContentPage || isPdpMobile,
          rebate: isRebate,
          'with-badge': product.badge
        }"
        [routerLink]="product?.url"
        (click)="productClickEvent(product)"
      >
        <div
          class="product-card__info__brand-promeclub"
          *ngIf="isPromeClubActive"
        >
          <span>
            {{ product?.brand?.name | uppercase }}
          </span>
        </div>
        <ng-container *ngIf="!isPromeClubActive">
          <p class="product-card__info-sku">
            {{ "productList.sku" | cxTranslate }}
            {{ product?.code }}
          </p>
        </ng-container>
        <a
          tabindex="0"
          class="product-card__info-name"
          [ngClass]="{ rebate: isRebate }"
          (click)="productClickEvent(product)"
        >
          <h3>
            {{ isRebate ? (product?.name | lowercase) : product?.name }}
          </h3>
        </a>
        <ng-container *ngIf="!fromContentPage && !isPdpMobile">
          <ng-container
            *ngIf="
              (isMobile$ | async) &&
              product?.stock?.stockLevelStatus === 'outOfStock'
            "
          >
            <div class="product-card__badges">
              <app-custom-gift-product
                [wideLabel]="wideLabel"
                label="{{ 'addToCart.outOfStock' | cxTranslate }}"
                [template]="page?.template"
              ></app-custom-gift-product>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              (isMobile$ | async) &&
              product?.stock?.stockLevelStatus != 'outOfStock' &&
              product?.badge
            "
          >
            <div class="product-card__badges">
              <app-custom-gift-product
                [wideLabel]="wideLabel"
                [label]="product?.badge"
                [template]="page?.template"
              >
              </app-custom-gift-product>
            </div>
          </ng-container>
        </ng-container>
        <div
          class="product-card__info-price"
          *ngIf="data.isUserLoggedIn"
          [ngClass]="{
            content: fromContentPage || isPdpMobile,
            rebate: isRebate
          }"
        >
          <ng-container *ngIf="product?.price?.currencyIso === 'PC_POINTS'">
            <a
              tabindex="0"
              class="product-card__info-price__current-points"
              (click)="productClickEvent(product)"
            >
              <span class="product-card__info-price__current-points-amount">{{
                product?.price?.value | number : "1.0-0" : "es-AR"
              }}</span>
              <span
                *ngIf="!!product?.price?.value"
                class="product-card__info-price__current-points-text"
              >
                {{ "productList.points" | cxTranslate }}
              </span>
            </a>
          </ng-container>
          <ng-container *ngIf="product?.price?.currencyIso !== 'PC_POINTS'">
            <ng-container *ngIf="product?.priceWithIVA?.value; else loading">
              <p
                class="product-card__info-price__previous"
                *ngIf="!!product?.previousPrice?.value"
              >
                {{ product?.previousPrice?.value | customCurrency }}
              </p>

              <a
                tabindex="0"
                class="product-card__info-price__current"
                [ngClass]="
                  !!product?.previousPrice?.value ? 'with-previous' : ''
                "
                (click)="productClickEvent(product)"
              >
                {{ product?.priceWithIVA?.value | customCurrency }}
                <span
                  class="product-card__info-price__current-iva"
                  *ngIf="!!product?.price?.value"
                >
                  {{ "productList.plusIva" | cxTranslate }}
                </span>
              </a>
            </ng-container>
            <ng-template #loading>
              <span class="product-card__info-price__current">
                {{ "spinner.loading" | cxTranslate }}
              </span>
            </ng-template>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="isPromeClubActive">
        <app-custom-wishlist
          [product]="product"
          [userId]="data.userId"
          [isUserLoggedIn]="data.isUserLoggedIn"
          *ngIf="data.isMobile"
          [ngClass]="{
            'wishlist__feature-products': fromContentPage || isPdpMobile
          }"
        ></app-custom-wishlist>
      </ng-container>
    </div>
    <div
      class="product-card__action"
      [ngClass]="{
        'logged-out': !data.isUserLoggedIn,
        content: fromContentPage || isPdpMobile,
        rebate: isRebate
      }"
    >
      <div
        class="product-card__action-button"
        [routerLink]="product?.url"
        *ngIf="!data.isUserLoggedIn; else addToCartTemplate"
      >
        <button
          class="custom-button primary logged-out"
          (click)="productClickEvent(product)"
        >
          <span class="button-text" *ngIf="data.isMobile">
            {{ "productList.seeMore" | cxTranslate }}
          </span>
          <cx-icon class="fas fa-chevron-right"></cx-icon>
        </button>
      </div>

      <ng-template #addToCartTemplate>
        <custom-add-to-cart
          [product]="product"
          [fromProductCard]="true"
          [fromContent]="fromContentPage || isPdpMobile"
          [isRebate]="isRebate"
          [clickedFrom]="clickedFrom"
        ></custom-add-to-cart>
      </ng-template>
    </div>
  </div>
</ng-container>
