import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CategoriesBreadcrumb } from 'src/app/cms-components/navigation/model/categoriesBreadcrumb';
import { CustomGetImageUrlService } from 'src/app/services/custom-get-image-url.service';
import { CustomHeaderCategoriesService } from 'src/app/services/custom-header-categories.service';
import { CustomSubcategory } from '../../../model/custom-subcategory.interface';
import { CustomSubcategoriesService } from '../services/custom-subcategories.service';

@Component({
  selector: 'app-custom-product-variant-list-header',
  templateUrl: './custom-product-variant-list-header.component.html',
  styleUrls: ['./custom-product-variant-list-header.component.scss'],
})
export class CustomProductVariantListHeaderComponent implements OnInit {
  subscription: Subscription = new Subscription();
  subcategories$: Observable<CustomSubcategory[]>;
  category$: Observable<CategoriesBreadcrumb>;
  @Input() showSubCategories: boolean = true;
  constructor(
    private customHeaderCategoriesService: CustomHeaderCategoriesService,
    private customSubcategoriesService: CustomSubcategoriesService,
    private customGetImageUrl: CustomGetImageUrlService
  ) {}

  ngOnInit(): void {
    this.subcategories$ = this.customHeaderCategoriesService
      .getCategoryCode()
      .pipe(
        switchMap((code) =>
          this.customSubcategoriesService.getCategoryDataFromApi(code!)
        )
      );

    this.category$ =
      this.customHeaderCategoriesService.getCategoryDataFromHeader();
  }
}
