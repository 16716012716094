import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomRoutingConfig } from './custom-routing.config';
import { provideConfig } from '@spartacus/core';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [provideConfig(CustomRoutingConfig)],
})
export class CustomRoutingModule {}
