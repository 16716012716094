<div class="customer-account__data">
  <h3 class="customer-account__data-title">
    {{'customRegister.accountData' | cxTranslate}}
  </h3>
  <h4 class="customer-account__data-subtitle">
    {{'customRegister.comercialData' | cxTranslate}}
  </h4>
  <div class="customer-account__data-container">
    <p class="customer-account__data-container-item" *ngIf="clientInfo?.businessName">
      {{'customRegister.socialReason' | cxTranslate}} {{clientInfo?.businessName}}
    </p>

    <p class="customer-account__data-container-item" *ngIf="clientInfo?.commercialName">
      {{'customRegister.comercialName' | cxTranslate}} {{clientInfo?.commercialName}}
    </p>

    <p class="customer-account__data-container-item" *ngIf="clientInfo?.ruc">
      {{'customRegister.ruc' | cxTranslate}} {{clientInfo?.ruc}}
    </p>

    <p class="customer-account__data-container-item" *ngIf="clientInfo?.clientCode">
      {{'customRegister.customerNumber' | cxTranslate}} {{clientInfo?.clientCode}}
    </p>

    <p class="customer-account__data-container-item" *ngIf="clientInfo?.phone">
      {{'customRegister.phone' | cxTranslate}} {{clientInfo?.phone | customPhoneMask}}
    </p>

    <p class="customer-account__data-container-item" *ngIf="clientInfo?.clientEmail">
      {{'customRegister.customerEmail' | cxTranslate}} {{clientInfo?.clientEmail | customEmailMask}}
    </p>
  </div>
</div>
