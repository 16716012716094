import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomTermsAndConditionsModalService } from './custom-terms-and-conditions-modal.service';

@Component({
  selector: 'app-custom-terms-and-conditions-modal',
  templateUrl: './custom-terms-and-conditions-modal.component.html',
  styleUrls: ['./custom-terms-and-conditions-modal.component.scss'],
})
export class CustomTermsAndConditionsModalComponent {
  readonly TERMS_AND_CONDITIONS_URL: string =
    '/terms-and-conditions-promeclub?site=promesaSite';
  readonly MOBILE_IMAGE: string =
    '../../../../../assets/images/terms-mobile.webp';
  readonly DESKTOP_IMAGE: string =
    '../../../../../assets/images/terms-desktop.webp';
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  form: FormGroup = this.fb.group({
    termsAndConditions: [false, Validators.required],
  });
  constructor(
    private customBreakpointService: CustomBreakpointService,
    private customTermsAndConditionsModalService: CustomTermsAndConditionsModalService,
    private fb: FormBuilder
  ) {}

  closeModal(): void {
    this.customTermsAndConditionsModalService.closeModal();
  }

  submit(): void {
    if (!this.form.get('termsAndConditions')?.value) {
      return;
    }
    this.customTermsAndConditionsModalService.submitForm();
  }
}
