import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomCheckButtonModule } from '../custom-check-button/custom-check-button.module';
import { CustomCardComponent } from './custom-card.component';

@NgModule({
  declarations: [CustomCardComponent],
  exports: [CustomCardComponent],
  imports: [CommonModule, CustomCheckButtonModule],
})
export class CustomCardModule {}
