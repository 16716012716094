import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CustomUser,
  ROLE,
} from 'src/app/cms-components/user/models/custom-user.interface';
import { CustomCreditInfo } from 'src/app/feature-libs/checkout/models/custom-credit-info.interface';
import { CustomUserPointsService } from 'src/app/services/custom-user-points.service';
import { environment } from 'src/environments/environment';
import { CustomPromeclubUserService } from '../../services/custom-promeclub-user.service';

@Component({
  selector: 'app-custom-my-points',
  templateUrl: './custom-my-points.component.html',
  styleUrls: ['./custom-my-points.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomMyPointsComponent implements OnInit {
  modalRef: ModalRef;
  creditInfo$: Observable<CustomCreditInfo>;
  availablePoints$: Observable<number> = this.customUserPointsService
    .getUserPoints()
    .pipe(map((userPoints) => userPoints?.pointsData?.availablePoints!));

  promeClubOrders: string = environment.promeClubOrders;
  ROLE = ROLE;
  user: CustomUser = this.customPromeclubUserService.getUser();
  isSeller: boolean;
  constructor(
    protected modalService: ModalService,
    protected userAccountFacade: UserAccountFacade,
    private customUserPointsService: CustomUserPointsService,
    private customPromeclubUserService: CustomPromeclubUserService
  ) {}

  ngOnInit(): void {
    this.isSeller = this.user?.roles?.includes(ROLE.SELLER) || false;
  }
}
