<ng-container
  *ngIf="{
    paymentBanks: paymentBanks$ | async,
    isMobile: isMobile$ | async
  } as data"
>
  <div class="custom-payment-record">
    <app-custom-title-my-account
      [title]="'orderDetails.registerPaid.registerAction' | cxTranslate"
      [routerlink]="{ cxRoute: 'orders' }"
    ></app-custom-title-my-account>
    <div class="custom-payment-record__header-payment-mode">
      <ng-container *ngIf="order$ | async as order">
        <app-custom-order-overview [order]="order"></app-custom-order-overview>
      </ng-container>
    </div>

    <div class="custom-payment-record__add-payment-mode">
      <div class="custom-payment-record__add-payment-mode__titles">
        <ng-container *ngIf="!data.isMobile">
          <h3>
            {{ "orderDetails.registerPaid.registerAction" | cxTranslate }}
          </h3>
        </ng-container>
        <div
          class="custom-payment-record__add-payment-mode__titles-add-payment-mode"
        >
          <p (click)="showAddPaymentsModes = !showAddPaymentsModes">
            {{ "orderDetails.registerPaid.addPaymentModes" | cxTranslate }}
          </p>
          <div
            *ngIf="showAddPaymentsModes"
            class="custom-payment-record__add-payment-mode__titles-payments-modes"
            [ngClass]="{
              'no-position':
                transferLengthValidation &&
                depositLengthValidation &&
                data.isMobile
            }"
          >
            <span (click)="addTransferPaymentMode()">{{
              "orderDetails.registerPaid.transfer" | cxTranslate
            }}</span>
            <span (click)="addDepositPaymentMode()">{{
              "orderDetails.registerPaid.deposit" | cxTranslate
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="custom-payment-record__form"
      *ngIf="transfers?.controls.length > 0 || deposits?.controls.length > 0"
    >
      <form [formGroup]="paymentsModesForm" (submit)="submitForm()">
        <!-- Transfer Payment Mode  -->
        <div formArrayName="transfers">
          <ng-container
            *ngFor="let transfer of transfers.controls; let i = index"
          >
            <div [formGroupName]="i">
              <div class="custom-payment-record__form__payments">
                <div class="custom-payment-record__form__payments__title">
                  <h3>
                    {{ "orderDetails.registerPaid.transfer" | cxTranslate }}
                  </h3>
                  <cx-icon
                    class="fas fa-trash"
                    (click)="deleteTransferPaymentMode(i, transfer, false)"
                  ></cx-icon>
                </div>
                <div class="custom-payment-record__form__payments__files">
                  <div class="custom-payment-record__form__payments__inputs">
                    <label>
                      <span>{{
                        "orderDetails.registerPaid.bank" | cxTranslate
                      }}</span>
                      <div class="payments-banks-container">
                        <ng-select
                          id="paymentBanks"
                          formControlName="bankCode"
                          [searchable]="true"
                          [clearable]="false"
                          placeholder="{{
                            'customRegister.select' | cxTranslate
                          }}"
                          required
                          dropdownPosition="bottom"
                          [items]="data.paymentBanks"
                          bindLabel="bankName"
                          bindValue="code"
                        >
                        </ng-select>
                      </div>
                      <span
                        *ngIf="
                          transfer.get('bankCode')?.errors?.required &&
                          showErrors
                        "
                        class="error-message"
                      >
                        {{ "orderDetails.registerPaid.addBank" | cxTranslate }}
                      </span>
                    </label>
                    <label>
                      <span>{{
                        "orderDetails.registerPaid.amount" | cxTranslate
                      }}</span>
                      <input
                        type="text"
                        placeholder="Ingresar importe"
                        formControlName="amount"
                      />
                      <span
                        *ngIf="
                          transfer.get('amount')?.errors?.required && showErrors
                        "
                        class="error-message"
                      >
                        {{
                          "orderDetails.registerPaid.addAmount" | cxTranslate
                        }}
                      </span>
                      <span
                        *ngIf="
                          !transfer.get('amount')?.errors?.required &&
                          transfer.get('amount')?.errors?.invalidVoucherOrAmout
                        "
                        class="error-message"
                      >
                        {{
                          "orderDetails.registerPaid.invalidVoucher"
                            | cxTranslate
                        }}
                      </span>
                    </label>
                    <label>
                      <span>{{
                        "orderDetails.registerPaid.voucherNumber" | cxTranslate
                      }}</span>
                      <input
                        type="text"
                        placeholder="Ingresar número"
                        formControlName="voucherNumber"
                      />
                      <span
                        *ngIf="
                          transfer.get('voucherNumber')?.errors?.required &&
                          showErrors
                        "
                        class="error-message"
                      >
                        {{
                          "orderDetails.registerPaid.addVoucher" | cxTranslate
                        }}
                      </span>
                      <span
                        *ngIf="
                          !transfer.get('voucherNumber')?.errors?.required &&
                          transfer.get('voucherNumber')?.errors?.invalidVoucherOrAmout
                        "
                        class="error-message"
                      >
                        {{
                          "orderDetails.registerPaid.invalidVoucher"
                            | cxTranslate
                        }}
                      </span>
                    </label>
                    <label>
                      <span>{{
                        "orderDetails.registerPaid.transferDate" | cxTranslate
                      }}</span>

                      <mat-form-field class="full-width" appearance="fill">
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-label>{{
                          !transfer.get("transferDate").value
                            ? ("customRegister.select" | cxTranslate)
                            : ""
                        }}</mat-label>
                        <input
                          matInput
                          [matDatepickerFilter]="filterDate"
                          [matDatepicker]="picker"
                          formControlName="transferDate"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker"
                          class="arrow"
                        >
                          <mat-icon matDatepickerToggleIcon
                            >keyboard_arrow_down</mat-icon
                          >
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                      <span
                        *ngIf="
                          transfer.get('transferDate')?.errors?.required &&
                          showErrors
                        "
                        class="error-message"
                      >
                        {{ "nonCustomerRegister.dateRequired" | cxTranslate }}
                      </span>
                    </label>
                    <label class="add-file">
                      <ng-container
                        *ngIf="
                          transfer.get('fileUid')?.status === 'INVALID';
                          else downloadedFile
                        "
                      >
                        <cx-icon class="far fa-file-alt"></cx-icon>
                        <span>{{
                          "orderDetails.registerPaid.addDocument" | cxTranslate
                        }}</span>
                        <input
                          type="file"
                          (change)="
                            getFile(
                              $event,
                              'TransferPaymentMode',
                              transfer,
                              'transfer'
                            )
                          "
                        />
                      </ng-container>
                      <ng-template #downloadedFile>
                        <cx-icon
                          class="far fa-file-alt downloaded-file"
                        ></cx-icon>
                        <span class="downloaded-file">
                          {{ transferDocs[i] }}
                        </span>

                        <cx-icon
                          class="far fa-times-circle downloaded-file"
                          (click)="deleteTransferPaymentMode(i, transfer, true)"
                        ></cx-icon>
                      </ng-template>
                      <input type="text" formControlName="fileUid" hidden />
                      <span
                        *ngIf="
                          transfer.get('fileUid')?.errors?.required &&
                          showErrors
                        "
                        class="error-message"
                      >
                        {{ "orderDetails.registerPaid.addFile" | cxTranslate }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <!-- Deposit Payment Mode  -->
        <ng-container *ngFor="let deposit of deposits.controls; let i = index">
          <div formArrayName="deposits">
            <div [formGroupName]="i">
              <div class="custom-payment-record__form__payments">
                <div class="custom-payment-record__form__payments__title">
                  <h3>
                    {{ "orderDetails.registerPaid.deposit" | cxTranslate }}
                  </h3>
                  <cx-icon
                    class="fas fa-trash"
                    (click)="deleteDepositPaymentMode(i, deposit, false)"
                  ></cx-icon>
                </div>
                <div class="custom-payment-record__form__payments__files">
                  <div class="custom-payment-record__form__payments__inputs">
                    <label>
                      <span>{{
                        "orderDetails.registerPaid.bank" | cxTranslate
                      }}</span>
                      <div class="payments-banks-container">
                        <ng-select
                          id="paymentBanks"
                          formControlName="bankCode"
                          [searchable]="true"
                          [clearable]="false"
                          placeholder="{{
                            'customRegister.select' | cxTranslate
                          }}"
                          required
                          dropdownPosition="bottom"
                          [items]="data.paymentBanks"
                          bindLabel="bankName"
                          bindValue="code"
                        >
                        </ng-select>
                      </div>
                      <span
                        *ngIf="
                          deposit.get('bankCode')?.errors?.required &&
                          showErrors
                        "
                        class="error-message"
                      >
                        {{ "orderDetails.registerPaid.addBank" | cxTranslate }}
                      </span>
                    </label>

                    <label>
                      <span>{{
                        "orderDetails.registerPaid.amount" | cxTranslate
                      }}</span>
                      <input
                        type="text"
                        placeholder="Ingresar importe"
                        formControlName="amount"
                      />
                      <span
                        *ngIf="
                          deposit.get('amount')?.errors?.required && showErrors
                        "
                        class="error-message"
                      >
                        {{
                          "orderDetails.registerPaid.addAmount" | cxTranslate
                        }}
                      </span>
                      <span
                        *ngIf="
                          !deposit.get('amount')?.errors?.required &&
                          deposit.get('amount')?.errors?.invalidVoucherOrAmout
                        "
                        class="error-message"
                      >
                        {{
                          "orderDetails.registerPaid.invalidVoucher"
                            | cxTranslate
                        }}
                      </span>
                    </label>
                    <label>
                      <span>{{
                        "orderDetails.registerPaid.voucherNumber" | cxTranslate
                      }}</span>
                      <input
                        type="text"
                        placeholder="Ingresar número"
                        formControlName="voucherNumber"
                      />
                      <span
                        *ngIf="
                          deposit.get('voucherNumber')?.errors?.required &&
                          showErrors
                        "
                        class="error-message"
                      >
                        {{
                          "orderDetails.registerPaid.addVoucher" | cxTranslate
                        }}
                      </span>
                      <span
                        *ngIf="
                          !deposit.get('voucherNumber')?.errors?.required &&
                          deposit.get('voucherNumber')?.errors?.invalidVoucherOrAmout
                        "
                        class="error-message"
                      >
                        {{
                          "orderDetails.registerPaid.invalidVoucher"
                            | cxTranslate
                        }}
                      </span>
                    </label>
                    <label>
                      <span>{{
                        "orderDetails.registerPaid.depositDate" | cxTranslate
                      }}</span>

                      <mat-form-field class="full-width" appearance="fill">
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-label>{{
                          !deposit.get("transferDate").value
                            ? ("customRegister.select" | cxTranslate)
                            : ""
                        }}</mat-label>
                        <input
                          matInput
                          [matDatepickerFilter]="filterDate"
                          [matDatepicker]="picker"
                          formControlName="transferDate"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker"
                          class="arrow"
                        >
                          <mat-icon matDatepickerToggleIcon
                            >keyboard_arrow_down</mat-icon
                          >
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                      <span
                        *ngIf="
                          deposit.get('transferDate')?.errors?.required &&
                          showErrors
                        "
                        class="error-message"
                      >
                        {{ "nonCustomerRegister.dateRequired" | cxTranslate }}
                      </span>
                    </label>
                    <label class="add-file">
                      <ng-container
                        *ngIf="
                          deposit.get('fileUid')?.status === 'INVALID';
                          else downloadedDepositFile
                        "
                      >
                        <cx-icon class="far fa-file-alt"></cx-icon>
                        <span>{{
                          "orderDetails.registerPaid.addDocument" | cxTranslate
                        }}</span>
                        <input
                          type="file"
                          (change)="
                            getFile(
                              $event,
                              'DepositPaymentMode',
                              deposit,
                              'deposit'
                            )
                          "
                        />
                      </ng-container>
                      <ng-template #downloadedDepositFile>
                        <cx-icon
                          class="far fa-file-alt downloaded-file"
                        ></cx-icon>
                        <span class="downloaded-file">
                          {{ depositDocs[i] }}
                        </span>

                        <cx-icon
                          class="far fa-times-circle downloaded-file"
                          (click)="deleteDepositPaymentMode(i, deposit, true)"
                        ></cx-icon>
                      </ng-template>
                      <input type="text" formControlName="fileUid" hidden />
                      <span
                        *ngIf="
                          deposit.get('fileUid')?.errors?.required && showErrors
                        "
                        class="error-message"
                      >
                        {{ "orderDetails.registerPaid.addFile" | cxTranslate }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- btn actions -->
        <div
          class="custom-payment-record__form__actions"
          *ngIf="
            transfers?.controls.length > 0 || deposits?.controls.length > 0
          "
        >
          <button
            type="submit"
            class="btn btn-primary btn-block custom-submit"
            [disabled]="paymentsModesForm.invalid"
          >
            <p>
              {{ "orderDetails.registerPaid.submitRegister" | cxTranslate }}
            </p>
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-container>
