import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductFacetNavigationComponent } from './custom-product-facet-navigation.component';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { CustomFacetListModule } from './custom-facet-list/custom-facet-list.module';
import { CustomActiveFacetsModule } from './custom-active-facets/custom-active-facets.module';
import { CustomProductVariantListModule } from '../../custom-product-variant-list/custom-product-variant-list.module';

@NgModule({
  declarations: [CustomProductFacetNavigationComponent],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    RouterModule,
    CustomFacetListModule,
    CustomActiveFacetsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductRefinementComponent: {
          component: CustomProductFacetNavigationComponent,
        },
      },
    }),
    CustomProductVariantListModule,
  ],
})
export class CustomProductFacetNavigationModule {}
