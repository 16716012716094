<ng-container *ngIf="component.data$ | async as data">
  <p
    *ngIf="data.content"
    [innerHTML]="data.content | safeHtml"
></p>
  <ng-container *ngIf="data.uid === 'EmptyCartParagraphComponent'">
    <app-custom-empty-cart></app-custom-empty-cart>
  </ng-container>

</ng-container>


