import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CustomOrderConfirmationService {
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
  ) { }

  getTicketOrder(userId: string, orderCode: string): Observable<any> {
    const url = this.occEndpointService.buildUrl('getTicketOrder', {
      urlParams: {
        userId,
        orderCode,
      },
    });

    return this.http.get(url,
      {
        responseType: 'blob' as 'json',
        observe: 'response'
      }
    )
  }




}
