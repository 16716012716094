import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomPromeclubAccountStatusService } from '../../../services/custom-promeclub-account-status.service';

@Component({
  selector: 'app-custom-promeclub-order-details',
  templateUrl: './custom-promeclub-order-details.component.html',
  styleUrls: ['./custom-promeclub-order-details.component.scss'],
})
export class CustomPromeclubOrderDetailsComponent {
  order$: Observable<any> =
    this.customPromeclubAccountStatusService.getOrderDetails();
  constructor(
    private customPromeclubAccountStatusService: CustomPromeclubAccountStatusService
  ) {}
}
