import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomTopLinkService } from './custom-top.link.service';
import { environment } from '../../environments/environment.ci';

@Injectable()
export class CustomSectionInterceptor implements HttpInterceptor {
  constructor(private customTopLinkService: CustomTopLinkService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let currentSection = this.customTopLinkService.getCurrentSection();
    if (currentSection !== null) {
      req = req.clone({
        setHeaders: {
          currentSection:
            window.location.pathname != '/'
              ? currentSection
              : environment.defaultSection,
        },
      });
    }

    return next.handle(req);
  }
}
