import { Component, ViewEncapsulation } from '@angular/core';
import { ICON_TYPE, ModalService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-confirmated-review-dialog',
  templateUrl: './custom-confirmated-review-dialog.component.html',
  styleUrls: ['./custom-confirmated-review-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomConfirmatedReviewDialogComponent {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  iconTypes = ICON_TYPE;
  constructor(
    protected modalService: ModalService,
    private customBreakpointService: CustomBreakpointService
  ) {}

  dismissModal(reason?: any): void {
    this.modalService.dismissActiveModal(reason);
  }
}
