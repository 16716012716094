import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomShippingAddressComponent } from './custom-shipping-address.component';
import { RouterModule } from '@angular/router';
import { AddressFormModule, CardModule } from '@spartacus/storefront';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CustomCardModule } from '../../../../../cms-components/custom/components/custom-card/custom-card.module';
import { CustomLoadingSpinnerModule } from 'src/app/cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';

@NgModule({
  declarations: [CustomShippingAddressComponent],
  exports: [CustomShippingAddressComponent],
  imports: [
    CommonModule,
    RouterModule,
    AddressFormModule,
    CardModule,
    CustomLoadingSpinnerModule,
    I18nModule,
    CustomCardModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutShippingAddress: {
          component: CustomShippingAddressComponent,
        },
      },
    }),
  ],
})
export class CustomShippingAddressModule {}
