import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OccEndpointsService } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class CustomPromeclubRegisterFormService {
  constructor(
    private http: HttpClient,
    private occEndpointsService: OccEndpointsService
  ) {}

  postFormData(data: any) {
    const url = this.occEndpointsService.buildUrl('landingFormPromeclub');
    return this.http.post(url, data);
  }

  requestPromeclubAccess() {
    const url = this.occEndpointsService.buildUrl('requestPromeclubAccess', {
      urlParams: {
        userId: 'current',
      },
    });

    return this.http.post(url, {});
  }
}
