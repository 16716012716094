<ng-container *ngIf="savedCart$ | async as cart">
  <div class="actions-container">
    <span (click)="openDialog(cart, savedCartFormType.RESTORE)">{{
      "shoppingList.moveToCart" | cxTranslate
    }}</span>
    <div class="actions-container__separator"></div>
    <cx-icon class="far fa-trash-alt"></cx-icon>
    <span (click)="openDialog(cart, savedCartFormType.DELETE)">{{
      "shoppingList.delete" | cxTranslate
    }}</span>
  </div>
</ng-container>
