import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ICON_TYPE, ModalService } from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomQuotesService } from '../../services/custom-quotes.service';
import { ActiveCartService } from '@spartacus/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-custom-quote-create-info',
  templateUrl: './custom-quote-create-info.component.html',
  styleUrls: ['./custom-quote-create-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomQuoteCreateInfoComponent implements OnDestroy {
  iconTypes = ICON_TYPE;
  code: string;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  unsubscribe$: Subject<void> = new Subject();
  constructor(
    private modalService: ModalService,
    private customBreakpointService: CustomBreakpointService,
    private customQuotesService: CustomQuotesService,
    private ActiveCartService: ActiveCartService,
    private router: Router
  ) {}

  closeModal(reason: string) {
    this.modalService.closeActiveModal(reason);
  }

  createQuote() {
    this.customQuotesService
      .createQuote(this.code)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((quote: any) => {
        if (quote?.code!) {
          this.ActiveCartService.reloadActiveCart();
          const url = `/my-account/quote-detail/${quote.code}`;
          this.router
            .navigateByUrl(url)
            .then(() => this.closeModal('redirect'));
        }
      });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
