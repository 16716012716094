import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomB2bUnitValidationService {
  constructor(
    private occEndpointService: OccEndpointsService,
    private http: HttpClient
  ) {}

  getb2bUnitValidation(clientUid: string): Observable<boolean> {
    const url = this.occEndpointService.buildUrl('b2bUnitValidation', {
      queryParams: {
        clientUid,
      },
    });
    return this.http.get<boolean>(url);
  }
}
