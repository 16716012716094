import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { CustomAuthorizedPersonService } from 'src/app/feature-libs/checkout/services/custom-authorized-person.service';
import { CustomAuthorizedPerson } from '../../../models/custom-authorized-person';
import { CustomUser } from '../../../models/custom-user';
import { CustomCheckoutDeliveryStepsService } from '../../../services/custom-checkout-delivery-steps.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-add-authorized-person',
  templateUrl: './custom-add-authorized-person.component.html',
  styleUrls: ['./custom-add-authorized-person.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomAddAuthorizedPersonComponent implements OnInit, OnDestroy {
  userUid: string;
  formAddAuthorizedPerson: FormGroup;
  user$: Observable<User | undefined> = this.userAccountFacade.get();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  showAddPerson$: Observable<boolean> =
    this.customAuthorizedPersonService.getShowAddAuthorizedPerson();
  editPerson$: Observable<CustomAuthorizedPerson>;
  isEditForm: boolean = false;
  private unsubscribe$ = new Subject<void>();
  @Input() personEditar: CustomAuthorizedPerson | null = null;

  constructor(
    protected customAuthorizedPersonService: CustomAuthorizedPersonService,
    protected userAccountFacade: UserAccountFacade,
    private cd: ChangeDetectorRef,
    private customCheckoutStepsService: CustomCheckoutDeliveryStepsService,
    private customBreakpointService: CustomBreakpointService
  ) {}

  ngOnInit() {
    this.createForm();
    if (this.personEditar) {
      this.isEditForm = true;
      this.editedPersonForm(this.personEditar);
    } else {
      this.isEditForm = false;
      this.userAccountFacade
        .get()
        .pipe(
          takeUntil(this.unsubscribe$),
          switchMap((userId: any) => {
            return this.customAuthorizedPersonService.getListAuthorizedPeople(
              userId.uid
            );
          })
        )
        .subscribe();
    }

    this.customAuthorizedPersonService.editAuthorizedPerson$
      .pipe(
        takeUntil(this.unsubscribe$),
        map((isEditPerson) => {
          if (isEditPerson != null) {
            this.createForm();
            this.editedPersonForm(isEditPerson);
          } else {
            this.isEditForm = false;
          }
        })
      )
      .subscribe();
  }

  editedPersonForm(isEditPerson: CustomAuthorizedPerson) {
    this.isEditForm = true;
    this.formAddAuthorizedPerson.controls['name'].setValue(isEditPerson.name);
    this.formAddAuthorizedPerson.controls['lastname'].setValue('Apellido');
    this.formAddAuthorizedPerson.controls['identificationCard'].setValue(
      isEditPerson.idCard
    );
    this.formAddAuthorizedPerson.controls['identificationCard'].disable();
    this.formAddAuthorizedPerson.controls['licensePlate'].setValue(
      isEditPerson.licencePlate
    );
    this.formAddAuthorizedPerson.controls['b2bUnit'].setValue(
      isEditPerson.b2bUnit
    );
  }

  createForm() {
    this.formAddAuthorizedPerson = new FormGroup({
      name: new FormControl('', [Validators.required]),
      lastname: new FormControl('Apellido'),
      b2bUnit: new FormControl(''),
      identificationCard: new FormControl('', [Validators.required]),
      licensePlate: new FormControl('', [Validators.required]),
    });
  }

  sendNewAndEditAttendant(user: CustomUser) {
    if (this.formAddAuthorizedPerson.invalid) {
      return;
    }
    const newAuthorizedPerson: CustomAuthorizedPerson = {
      name: this.formAddAuthorizedPerson.controls['name'].value,
      lastName: this.formAddAuthorizedPerson.controls['lastname'].value,
      b2bUnit: user?.orgUnit?.uid!,
      idCard: this.formAddAuthorizedPerson.controls['identificationCard'].value,
      licencePlate: this.formAddAuthorizedPerson.controls['licensePlate'].value,
    };
    if (this.isEditForm) {
      this.customAuthorizedPersonService
        .editAuthorizedPerson(user?.uid!, newAuthorizedPerson)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.customAuthorizedPersonService.setShowAddAuthorizedPerson(false);
          this.customCheckoutStepsService.setAuthorizedPersonSelected(true);
        });
    } else {
      this.customAuthorizedPersonService
        .setAuthorizedPerson(user?.uid!, newAuthorizedPerson)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.customAuthorizedPersonService.setShowAddAuthorizedPerson(false);
          this.customCheckoutStepsService.setAuthorizedPersonSelected(true);
        });
    }
  }

  closeShowAddPerson() {
    this.customAuthorizedPersonService.setShowAddAuthorizedPerson(false);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
