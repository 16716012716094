import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  CmsComponent,
  CmsService,
  OrderEntry,
  Product,
  ProductService,
} from '@spartacus/core';
import {
  CartItemComponent,
  CartItemContext,
  CartItemContextSource,
  ModalRef,
  ModalService,
} from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { pluck, tap } from 'rxjs/operators';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';
import { CustomGtmEcommerceDatalayerService } from 'src/app/common/services/custom-gtm-ecommerce-datalayer.service';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';
import { CustomRemoveFromCartDialogComponent } from '../../custom-remove-from-cart-dialog/custom-remove-from-cart-dialog.component';
import { CustomOrderEntry } from '../../model/custom-order-entry';
import { EcvProductType } from '../../../product/constants/product-type';

@Component({
  selector: 'app-custom-cart-item',
  templateUrl: './custom-cart-item.component.html',
  styleUrls: ['./custom-cart-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    CartItemContextSource,
    { provide: CartItemContext, useExisting: CartItemContextSource },
  ],
})
export class CustomCartItemComponent
  extends CartItemComponent
  implements OnInit, OnDestroy
{
  EcvProductType: string = EcvProductType;
  modalRef: ModalRef;
  @Output() updateQuantityEmmiter = new EventEmitter<boolean>();
  @Output() isMobileEmmiter = new EventEmitter<boolean>();
  @Input() updatedQuantity: boolean;
  @Input() isMobileValue: boolean;
  @Input() nameGift: string;
  @Input() cart: any;
  @Input() customItem: CustomOrderEntry;
  nameGift$: Observable<Product>;
  isUpdated: boolean = false;
  outOfStock: boolean = false;
  isInPromeclub = this.service.isInPromeClub();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  subscription: Subscription = new Subscription();
  subscriptionMobile: Subscription = new Subscription();
  clickedFrom$: Observable<string | undefined> = this.cmsService
    .getCurrentPage()
    .pipe(pluck('pageId'));

  @Input() productPosition: number;
  promesaCartUpdatedPriceMessageComponentData$: Observable<CmsComponent> =
    this.cmsService.getComponentData('promesaCartUpdatedPriceMessageComponent');

  constructor(
    protected cartItemContextSource: CartItemContextSource,
    protected modalService: ModalService,
    protected customBreakpointService: CustomBreakpointService,
    protected productService: ProductService,
    private service: CustomNavigationService,
    private customGtmDatalayerService: CustomGtmEcommerceDatalayerService,
    private cmsService: CmsService
  ) {
    super(cartItemContextSource);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.isMobile$
        .pipe(
          tap((isMobile) => {
            this.isMobileValue = isMobile;
            this.isMobileEmmiter.emit(this.isMobileValue);
          })
        )
        .subscribe()
    );
    if (this.customItem?.qualifiedProductCode)
      this.nameGift$ = this.productService.get(
        this.customItem?.qualifiedProductCode
      );
    this.updatedProduct(this.item);
  }

  updatedProduct(item: OrderEntry) {
    let quantity = item.quantity;
    let stock = item.product?.stock?.stockLevel;
    if (stock! === 0) {
      this.outOfStock = true;
      return;
    }
    if (stock! < quantity!) {
      this.isUpdated = true;
      this.updateQuantityEmmiter.emit(true);
      this.quantityControl.setValue(stock);
    }
    if (stock! === quantity!) {
      this.isUpdated = true;
    }
  }
  setIsUpdated() {
    if (this.isUpdated === true) {
      this.isUpdated = false;
    }
    return;
  }

  openModal() {
    let modalInstance: any;
    this.modalRef = this.modalService.open(
      CustomRemoveFromCartDialogComponent,
      {
        centered: true,
        size: 'lg',
        windowClass: 'add-to-cart',
      }
    );

    modalInstance = this.modalRef.componentInstance;
    modalInstance.cartEntry = this.item;
    modalInstance.quantity = this.quantityControl.value;
    modalInstance.quantityControl = this.quantityControl;
    this.subscriptionMobile = this.isMobile$.subscribe((isMobile) => {
      if (!isMobile) {
        this.subscription.add(
          this.modalRef.dismissed
            .pipe(
              tap((result) => {
                if (result == 'View Cart click') {
                  this.quantityControl.setValue(0);
                  this.quantityControl.markAsDirty();
                  this.emitUpdate(true);
                }
              })
            )
            .subscribe()
        );
      }
    });
  }

  emitUpdate(emitChange: boolean) {
    if (!emitChange) {
      return;
    }
    this.updatedQuantity = true;
    this.updateQuantityEmmiter.emit(this.updatedQuantity);
    this.quantityControl.updateValueAndValidity();
  }

  productClickEvent(clickedFrom: string) {
    this.customGtmDatalayerService.productClickEvent(
      null,
      this.productPosition,
      clickedFrom
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionMobile.unsubscribe();
  }
}
