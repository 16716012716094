<div class="payment-cash__table" *ngIf="(bankItems$ | async) as banks">
  <ng-container *ngIf="banks?.length">
    <h3 class="payment-cash__table-title" *ngIf="showTitleTable">
      {{'creditPayment.listOfAccounts' | cxTranslate}}
    </h3>

    <div class="payment-cash__table-header">
        <p class="payment-cash__table-header-value">
          {{'creditPayment.banks' | cxTranslate}}
        </p>
        <p class="payment-cash__table-header-value">
          {{'creditPayment.currentAccount' | cxTranslate}}
        </p>
    </div>
    <div class="payment-cash__table-content" *ngFor="let item$ of banks">
      <ng-container *ngIf="item$ | async as item">
        <p class="payment-cash__table-content-value">
          {{item?.bankName}}
        </p>
        <p class="payment-cash__table-content-value">
          {{item?.bankAccount}}
        </p>
    </ng-container>
    </div>
  </ng-container>
</div>
