<ng-container *ngIf="!PasswordMismatchError; else PasswordMismatchErrorModal">
  <div class="updated-password-modal">
    <div class="updated-password-modal__icon">
      <cx-icon class="fas fa-times" (click)="closeModal($event)"></cx-icon>
    </div>
    <div class="updated-password-modal__text">
      <p>
        {{ "customUpdateProfileForm.updatedPassword" | cxTranslate }}
      </p>
      <p>
        {{ "customUpdateProfileForm.loginWithNewPassword" | cxTranslate }}
      </p>
      <a
        class="updated-password-modal__text--goToLogin"
        routerLink="/"
        (click)="closeModal($event)"
      >
        <p>
          {{ "customUpdateProfileForm.goToLogin" | cxTranslate }}
        </p>
      </a>
    </div>
  </div>
</ng-container>
<ng-template #PasswordMismatchErrorModal>
  <div class="updated-password-modal">
    <div class="updated-password-modal__password-mismatch">
      <p *ngIf="!fromReset">
        {{ "customUpdateProfileForm.passwordMismatchError" | cxTranslate }}
      </p>
      <p *ngIf="fromReset">
        {{ "customUpdateProfileForm.invalidToken" | cxTranslate }}
      </p>
      <button class="btn btn-block btn-primary" (click)="closeModal($event)">
        {{ "common.close" | cxTranslate }}
      </button>
    </div>
  </div>
</ng-template>
