<ng-container
  *ngIf="{
    order: order$ | async,
    refundsReasons: refundsReasons$ | async,
    pageCurrent: pageCurrent$ | async
  } as data"
>
  <div
    *ngIf="data.order"
    class="custom-return-details"
    [ngClass]="{
      'custom-return-details-revision': currentPage == 'REVISION_ORDER_RETURN'
    }"
  >
    <div class="custom-return-details__filters top">
      <label class="custom-return-details__subtitle">{{
        currentPage == ORDER_STATE.EDIT_ORDER_RETURN
          ? ("returnRequest.productsToReturn" | cxTranslate)
          : ("returnRequest.productsRevision" | cxTranslate)
      }}</label>
      <div class="custom-return-details__pagination">
        <app-custom-filter
          [label]="'orderHistory.filterResults' | cxTranslate"
          [entries]="quantities"
          [classSelect]="'small-filter'"
          [valueDefault]="pageSize"
          (filterSelected)="filterQuantitySelected($event)"
        >
        </app-custom-filter>
        <app-custom-pagination
          *ngIf="pagination.totalPages >= 1"
          [pagination]="pagination"
          (viewPageEvent)="customPageChange($event)"
          [pageSelected]="selectedPage"
        ></app-custom-pagination>
      </div>
    </div>
    <div class="cx-list">
      <div class="d-none d-md-block cx-item-list-header">
        <div class="custom-return-details__header">
          <div class="custom-return-details__header-items-info">
            <div
              class="custom-return-details__header-items text-left"
              [ngClass]="{
                'color-black': itemsSelected.length > 0
              }"
            >
              {{
                currentPage == ORDER_STATE.EDIT_ORDER_RETURN
                  ? ("returnRequest.selectedItems" | cxTranslate)
                  : ""
              }}
              {{
                itemsSelected.length > 0 &&
                currentPage == ORDER_STATEEDIT_ORDER_RETURN
                  ? ("returnRequest.descriptionSelectedItems"
                    | cxTranslate
                      : {
                          param1: itemsSelected.length,
                          param2: data.order?.entries?.length
                        })
                  : null
              }}
            </div>
            <div class="custom-return-details__header-info text-left">
              {{ "returnRequest.productsReturn" | cxTranslate }}
            </div>
          </div>
          <div
            *ngIf="currentPage == ORDER_STATE.EDIT_ORDER_RETURN"
            class="custom-return-details__header-quantity text-left"
          >
            {{ "returnRequest.recived" | cxTranslate }}
          </div>
          <div class="custom-return-details__header-quantity-counter text-left">
            {{
              currentPage == ORDER_STATE.EDIT_ORDER_RETURN
                ? ("returnRequest.toDevolutions" | cxTranslate)
                : ("returnRequest.devolutionTitle" | cxTranslate)
            }}
          </div>
          <div class="custom-return-details__header-unit-price text-left">
            {{ "returnRequest.unitPrice" | cxTranslate }}
          </div>
          <div class="custom-return-details__header-reason text-left">
            {{ "returnRequest.reasonDevolution" | cxTranslate }}
          </div>
          <div class="custom-return-details__header-images text-left">
            {{ "returnRequest.productImages" | cxTranslate }}
          </div>
        </div>
      </div>
      <ng-container *ngFor="let item of orderDetailsShow">
        <app-custom-return-product-item
          *ngIf="
            currentPage === ORDER_STATE.EDIT_ORDER_RETURN &&
            data.pageCurrent !== 'ReturnDetailOrderPage'
          "
          [item]="item"
          [isEdit]="itemLoaded(item)"
          [currentPage]="currentPage"
          [refundsReasons]="data.refundsReasons"
          (productsSelected)="productsSelected($event)"
          (productReturn)="submitForm($event)"
        ></app-custom-return-product-item>
        <app-custom-return-product-item
          *ngIf="
            currentPage === ORDER_STATE.REVISION_ORDER_RETURN &&
            data.pageCurrent !== 'ReturnDetailOrderPage'
          "
          [item]="item"
          [currentPage]="currentPage"
          [isEdit]="itemLoaded(item)"
          [refundsReasons]="data.refundsReasons"
          (productsSelected)="productsSelected($event)"
          (productReturn)="submitForm($event)"
        ></app-custom-return-product-item>
        <app-custom-return-product-item
          *ngIf="
            currentPage === ORDER_STATE.REVISION_ORDER_RETURN &&
            data.pageCurrent == 'ReturnDetailOrderPage' &&
            data.refundsReasons
          "
          [item]="item.orderEntry"
          [currentPage]="currentPage"
          [refundsReasons]="data.refundsReasons"
          [refundsReasonSelected]="item.refundReason"
          [imagesReturn]="item.images"
          [expectedQuantityReturn]="item.expectedQuantity"
          (productReturn)="submitForm($event)"
        ></app-custom-return-product-item>
      </ng-container>
    </div>
    <div
      *ngIf="pagination.totalPages >= 1"
      class="custom-return-details__pagination-bottom"
    >
      <app-custom-pagination
        *ngIf="pagination.totalPages >= 1"
        [pagination]="pagination"
        (viewPageEvent)="customPageChange($event)"
        [pageSelected]="selectedPage"
      ></app-custom-pagination>
    </div>
  </div>
</ng-container>
