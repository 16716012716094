import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-b2b-validation-dialog',
  templateUrl: './custom-b2b-validation-dialog.component.html',
  styleUrls: ['./custom-b2b-validation-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomB2bValidationDialogComponent implements OnInit {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;

  constructor(
    private modalService: ModalService,
    private customBreakpointService: CustomBreakpointService
  ) {}

  ngOnInit(): void {}

  closeModal(reason: string) {
    this.modalService.dismissActiveModal(reason);
  }
}
