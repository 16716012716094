import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomRegisterStepOrchestratorService {


  stepOne$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  stepTwo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  stepThree$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  getStepOneStatus(): Observable<boolean>{
    return this.stepOne$.asObservable();
  }

  getStepTwoStatus(): Observable<boolean>{
    return this.stepTwo$.asObservable();
  }

  getStepThreeStatus(): Observable<boolean>{
    return this.stepThree$.asObservable();
  }

  setStepOneStatus(status: boolean): void{
    this.stepOne$.next(status);
  }

  setStepTwoStatus(status: boolean): void{
    this.stepTwo$.next(status);
  }

  setStepThreeStatus(status: boolean): void{
    this.stepThree$.next(status);
  }

  reset(): void {
    this.stepOne$.next(true);
    this.stepTwo$.next(false);
    this.stepThree$.next(false);
  }
}
