<ng-container
  *ngIf="{
    quantity: (quantity$ | async),
    isMobile: (isMobile$ | async),
    currentPage: currentPage$ | async,
    quantityUpdated: quantityUpdated$ | async
  } as data"
>
  <a
    class="mini-cart-button"
    [attr.aria-label]="'miniCart.item' | cxTranslate : { count: data.quantity }"
    (mouseenter)="setShowCartSummary(true)"
    (mouseleave)="setShowCartSummary(false)"
    (click)="goToCart()"
  >
    <span class="mini-cart-name">{{ "miniCart.name" | cxTranslate }}</span>
    <cx-icon type="MINICART"></cx-icon>
  </a>
  <div class="custom-minicart">
    <div
      class="custom-minicart__count"
      (mouseover)="setShowCartSummary(true)"
      (mouseleave)="setShowCartSummary(false)"
    >
      <span class="count" (click)="goToCart()">
        <ng-container
          *ngIf="data.currentPage != TEMPLATE.CHECKOUT; else qtyUpdated"
        >
          <span class="count__number" *ngIf="data.quantity < 100">
            {{ "miniCart.count" | cxTranslate : { count: data.quantity } }}
          </span>
          <span class="count__number" *ngIf="data.quantity > 99">
            {{ "miniCart.bigCounter" | cxTranslate }}
          </span>
        </ng-container>
        <ng-template #qtyUpdated>
          <span class="count__number" *ngIf="data.quantityUpdated < 100">
            {{
              "miniCart.count" | cxTranslate : { count: data.quantityUpdated }
            }}
          </span>
          <span class="count__number" *ngIf="data.quantityUpdated > 99">
            {{ "miniCart.bigCounter" | cxTranslate }}
          </span>
        </ng-template>
      </span>
      <ng-container
        *ngIf="
          !(isMobile$ | async) &&
          (showCartSummary$ | async) &&
          !(isCartPage$ | async)
        "
      >
        <div class="cart-pop-up-wrapper">
          <div class="cart-pop-up-scroll">
            <div class="cart-summary">
              <div class="cart-summary__header">
                <span
                  >{{ "miniCart.name" | cxTranslate }} ({{
                    "miniCart.count" | cxTranslate : { count: data.quantity }
                  }})
                </span>
              </div>

              <div
                class="cart-summary__info"
                [ngClass]="{
                  'cart-summary__info_scroll': scrollActive === true
                }"
              >
                <ng-container
                  *ngIf="(entries$ | async).length === 0; else notEmptyCart"
                >
                  <div class="cart-summary__info_empty-cart">
                    <p>{{ "miniCart.emptyCart" | cxTranslate }}</p>
                  </div>
                </ng-container>

                <ng-template #notEmptyCart>
                  <ng-container *ngIf="entries$ | async as entries">
                    <app-custom-entries-mini-cart
                      [cantMaxItems]="cantMaxItems"
                      (scrollShowMore)="onShowMore($event)"
                      [entriesQuantity]="entriesQuantity"
                      [clickedFrom]="'Mini cart'"
                    ></app-custom-entries-mini-cart>
                  </ng-container>
                </ng-template>
              </div>

              <div class="cart-summary__footer" *ngIf="data.quantity > 0">
                <div
                  class="cart-summary__footer_total"
                  [ngClass]="{ promesa: !isInPromeclub }"
                >
                  <span>{{ "miniCart.totalPrice" | cxTranslate }}</span>
                  <div
                    class="cart-summary__footer_total_number"
                    [ngClass]="{ promesa: !isInPromeclub }"
                  >
                    <strong *ngIf="!isInPromeclub">{{
                      _totalPriceWithTax$ | async | customCurrency
                    }}</strong>
                    <strong *ngIf="isInPromeclub">{{ _total$ | async }}</strong>
                    <span
                      class="cart-summary__footer_total_iva"
                      *ngIf="!isInPromeclub"
                      >{{ "productList.plusIva" | cxTranslate }}</span
                    >
                    <span
                      class="cart-summary__footer_total_iva"
                      *ngIf="isInPromeclub"
                      >{{ "productList.points" | cxTranslate }}</span
                    >
                  </div>
                </div>
                <a (click)="goToCart()" class="cart-summary__footer_confirm">
                  <strong>{{ "miniCart.showCart" | cxTranslate }}</strong>
                  <cx-icon class="fas fa-chevron-right"></cx-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
