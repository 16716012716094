<button
  type="button"
  (click)="decrement()"
  [disabled]="control.disabled || control.value <= min"
  [tabindex]="control.disabled || control.value <= min ? -1 : 0"
  attr.aria-label="{{ 'itemCounter.removeOne' | cxTranslate }}"
  (blur)="blurEvent ? onBlurCounter() : false"
>
  -
</button>
<input
  #qty
  type="number"
  [min]="min"
  [step]="step"
  [readonly]="readonly"
  [tabindex]="readonly ? -1 : 0"
  [formControl]="control"
  attr.aria-label="{{ 'itemCounter.quantity' | cxTranslate }}"
  (change)="blurEvent ? onBlurCounter() : false"
/>
<button
  type="button"
  (click)="increment()"
  [disabled]="control.disabled || control.value >= max"
  tabindex="0"
  attr.aria-label="{{ 'itemCounter.addOneMore' | cxTranslate }}"
  (blur)="blurEvent ? onBlurCounter() : false"
>
  +
</button>
