import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-cart-list-summary',
  templateUrl: './custom-cart-list-summary.component.html',
  styleUrls: ['./custom-cart-list-summary.component.scss'],
})
export class CustomCartListSummaryComponent {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;

  constructor(private customBreakpointService: CustomBreakpointService) {}
}
