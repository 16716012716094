<div class="cart-list__header">
  <div class="cart-list__header-item__image">
    <p class="cart-list__header-item__tag-space" *ngIf="cart"></p>
    <p class="cart-list__header-item__image-space"></p>
  </div>
  <p
    class="cart-list__header-item"
    [ngClass]="{ sku: !isInPromeclub, 'sku-promeclub': isInPromeclub }"
  >
    {{
      isInPromeclub
        ? ("cartDetails.code" | cxTranslate)
        : ("cartDetails.id" | cxTranslate)
    }}
  </p>
  <p
    class="cart-list__header-item"
    [ngClass]="{ name: !isInPromeclub, 'name-promeclub': isInPromeclub }"
  >
    {{ "cartDetails.product" | cxTranslate }}
  </p>
  <p class="cart-list__header-item presentation" *ngIf="!isInPromeclub">
    {{ "cartDetails.presentation" | cxTranslate }}
  </p>
  <p
    class="cart-list__header-item price"
    [ngClass]="{
      'padding-header-points': isInPromeclub,
      'price-message': cart?.modifiedPreviousPriceMap?.length > 0,
      'price-promeclub': isInPromeclub
    }"
  >
    {{
      isInPromeclub
        ? ("cartDetails.unitPoints" | cxTranslate)
        : ("cartDetails.unitPrice" | cxTranslate)
    }}
    <ng-container *ngIf="!(isMobile$ | async); else mobile">
      <ng-container *ngIf="cart && cart.modifiedPreviousPriceMap.length > 0">
        <ng-container
          *ngIf="
            promesaCartUpdatedPriceMessageComponentData$ | async as message
          "
        >
          <cx-icon
            class="fas fa-info-circle"
            ngbTooltip="{{ message?.priceVariation }}"
            placement="top-right"
            tooltipClass="tooltip-color"
          ></cx-icon>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #mobile>
      <ng-container *ngIf="cart && cart.modifiedPreviousPriceMap.length > 0">
        <cx-icon
          class="fas fa-info-circle"
          ngbTooltip="{{ 'updatedPrice.priceVariation' | cxTranslate }}"
          placement="top"
          tooltipClass="tooltip-color"
          triggers="click:blur"
        ></cx-icon>
      </ng-container>
    </ng-template>
  </p>
  <p
    class="cart-list__header-item"
    [ngClass]="{
      quantity: !isInPromeclub,
      'quantity-promeclub': isInPromeclub
    }"
  >
    {{ "cartDetails.quantity" | cxTranslate }}
  </p>
  <p
    class="cart-list__header-item"
    [ngClass]="{ total: !isInPromeclub, 'total-promeclub': isInPromeclub }"
  >
    <ng-container *ngIf="!isInPromeclub">{{
      "cartDetails.totalPrice" | cxTranslate
    }}</ng-container>
    <ng-container *ngIf="isInPromeclub">{{
      "cartDetails.totalPoints" | cxTranslate
    }}</ng-container>
  </p>
  <p
    class="cart-list__header-item"
    [ngClass]="{
      'action-space': !isInPromeclub,
      'action-space-promeclub': isInPromeclub
    }"
  ></p>
</div>
