<ng-container
  *ngIf="{
    isMobile: isMobile$ | async,
    result: customResults$ | async
  } as data"
>
  <label class="searchbox" [class.dirty]="!!searchInput.value">
    <div
      class="search-input"
      *ngIf="
        !data.isMobile &&
        filterAutomotriz.length > 1 &&
        isAutomotriz == 'automotriz' &&
        filteredSelected &&
        !isInPromeclub
      "
    >
      <cx-icon
        class="fas fa-car"
        id="iconCar"
        [ngClass]="{ filtered: iconFilter }"
      ></cx-icon>
      <ng-select
        id="filteredSelected"
        [(ngModel)]="filteredSelected"
        [searchable]="false"
        [clearable]="false"
        (change)="onChangeFiltered($event)"
        (open)="iconFilter = false; changeClassFilter = false"
        (close)="iconFilter = true; changeClassFilter = true"
        class="custom-select-searchbox"
        [ngClass]="{ filtered: changeClassFilter }"
      >
        <ng-option [value]="null"> - </ng-option>
        <ng-option
          *ngFor="let filter of filterAutomotriz"
          [value]="filter.name"
        >
          {{ filter.name }}
        </ng-option>
      </ng-select>
      <div class="remove-item">
        <cx-icon
          class="icon-close"
          type="CLOSE"
          (click)="clearFromSelected()"
        ></cx-icon>
      </div>
    </div>
    <div
      class="search-input-text"
      *ngIf="
        (!data.isMobile &&
          filterAutomotriz.length == 1 &&
          isAutomotriz == 'automotriz' &&
          filteredSelected &&
          !isInPromeclub) ||
        changed
      "
    >
      <cx-icon
        class="fas fa-car"
        id="iconCar"
        [ngClass]="{ filtered: iconFilter && !isInPromeclub }"
      ></cx-icon>
      <div class="search-input-text__label">
        {{ !changed ? filteredSelected : changedSelected }}
      </div>
      <button class="btn btn-accordion" type="button" (click)="clearFilter()">
        <cx-icon class="icon-close" type="CLOSE"></cx-icon>
      </button>
    </div>
    <cx-icon
      [type]="iconTypes.SEARCH"
      class="desktop"
      [ngClass]="{
        'margin-extra':
          filterAutomotriz?.length == 0 ||
          isAutomotriz !== 'automotriz' ||
          isInPromeclub
      }"
    ></cx-icon>
    <button
      [attr.aria-label]="'common.search' | cxTranslate"
      class="search"
      (click)="customLaunchSearchResult($event, searchInput.value)"
    >
      <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
    </button>
    <input
      #searchInput
      [placeholder]="
        isInPromeclub
          ? ('searchBox.placeholderPromeclub' | cxTranslate)
          : ('searchBox.placeholder' | cxTranslate)
      "
      autocomplete="off"
      aria-describedby="initialDescription"
      aria-controls="results"
      [attr.aria-label]="'common.search' | cxTranslate"
      (focus)="open()"
      (click)="openSearchbox(data?.isMobile)"
      (input)="customSearch(searchInput.value, data?.isMobile)"
      (keydown.escape)="customClose($event, data?.isMobile)"
      (keydown.enter)="
        customClose($event, data?.isMobile, true);
        customLaunchSearchResult($event, searchInput.value);
        updateChosenWord(searchInput.value)
      "
      (keydown.arrowup)="focusPreviousChild($event)"
      (keydown.arrowdown)="focusNextChild($event)"
      value="{{ chosenWord }}"
    />

    <div role="presentation" class="search-icon">
      <button
        *ngIf="!data?.result.products && !data.isMobile"
        class="custom-button primary"
        (click)="customLaunchSearchResult($event, searchInput.value)"
      >
        <cx-icon class="fas fa-chevron-right"></cx-icon>
      </button>
      <button
        *ngIf="data?.result.products || (data?.isMobile && openResultsMobile)"
        class="custom-button primary"
        (click)="customClose($event, data?.isMobile); clear(searchInput)"
      >
        <cx-icon class="fas fa-times"></cx-icon>
      </button>
    </div>
  </label>

  <div *ngIf="data.result" class="results" id="results" role="listbox">
    <div
      class="results-container"
      [ngClass]="{ 'no-results': data?.result?.message }"
      (mouseleave)="resetTextSearch()"
    >
      <div
        *ngIf="data?.result?.message && searchInput?.value?.length > 0"
        class="message"
      >
        {{ data?.result?.message }} <b>{{ searchInput.value }}</b>
      </div>
      <div class="results-container__left">
        <app-custom-searchbox-suggests-item
          [suggestions]="suggestionToShow"
          [searchInputValue]="searchInput.value"
          [categories]="categoriesToShow"
          [brands]="brandsToShow"
          (onOverCategoryEvent)="lookCategoryProducts($event, 'categories')"
          (onOverBrandEvent)="lookCategoryProducts($event, 'brand')"
        >
        </app-custom-searchbox-suggests-item>
      </div>
      <!-- Diseño nuevo mobile -->
      <ng-container *ngIf="data?.isMobile && !!!data?.result.totalResults">
        <app-custom-searchbox-extras></app-custom-searchbox-extras>
      </ng-container>
      <ul
        class="products"
        *ngIf="data?.result?.products && !selectedCategory$"
        attr.aria-label="{{ 'searchBox.ariaLabelProducts' | cxTranslate }}"
        tabindex="0"
      >
        <h3
          class="products__title"
          *ngIf="
            !!data.result.suggestions.length &&
            data?.result?.products.length > 0 &&
            !brandSelected
          "
        >
          {{ "searchBox.recommendedProducts" | cxTranslate }}
        </h3>
        <h3
          class="products__title"
          *ngIf="!!data.result.suggestions.length && !!brandSelected"
        >
          {{
            "searchBox.recommendedProductsFor"
              | cxTranslate : { category: brandSelected?.name | titlecase }
          }}
        </h3>
        <ng-container *ngIf="!categoryFacetSelected">
          <li
            *ngFor="
              let product of !data.isMobile
                ? data?.result.products.slice(0, 3)
                : data?.result.products.slice(0, 4);
              let i = index
            "
          >
            <app-custom-searchbox-product-item
              [product]="product"
              [productPosition]="i"
            ></app-custom-searchbox-product-item>
          </li>
        </ng-container>
        <ng-container *ngIf="!!categoryFacetSelected">
          <li>
            <app-custom-searchbox-category-item
              [category]="categoryFacetSelected"
            ></app-custom-searchbox-category-item>
          </li>
        </ng-container>
      </ul>

      <ul
        class="products custom-searchbox-suggestion"
        *ngIf="selectedCategory$ | async as selectedCategory"
        attr.aria-label="{{ 'searchBox.ariaLabelProducts' | cxTranslate }}"
        tabindex="0"
      >
        <div class="row custom-searchbox-suggestion__title">
          <div class="custom-searchbox-suggestion__title-image">
            <img
              [src]="getImageUrl(selectedCategory?.image?.url)"
              [alt]="selectedCategory?.name"
              loading="lazy"
              fetchPriority="low"
            />
          </div>
          <div class="custom-searchbox-suggestion__title-name">
            <ng-container *ngIf="selectedCategory?.superCategories">
              <div class="custom-searchbox-suggestion__breadcrumb">
                <span
                  *ngFor="
                    let crumb of selectedCategory?.superCategories;
                    let i = index
                  "
                  class="d-flex"
                >
                  <a
                    [routerLink]="crumb.url"
                    *ngIf="
                      i < selectedCategory?.superCategories?.length ||
                      selectedCategory?.superCategories?.length == 1
                    "
                    >{{ crumb.name | titlecase }}</a
                  >
                  <div
                    *ngIf="i < selectedCategory?.superCategories?.length"
                    class="mx-1 my-0 custom-searchbox-suggestion__breadcrumb-slash"
                  >
                    /
                  </div>
                </span>
                <span
                  class="custom-searchbox-suggestion__breadcrumb-last-child"
                >
                  {{ selectedCategory?.name | titlecase }}
                </span>
              </div>
            </ng-container>
            <h3>{{ selectedCategory?.name }}</h3>
          </div>
        </div>

        <div class="custom-searchbox-suggestion__description">
          <p [innerHTML]="selectedCategory?.description"></p>
        </div>
        <div
          class="custom-searchbox-suggestion__button"
          *ngIf="typeOverFacet$ | async as typeOverFacet"
        >
          <button
            class="custom-button btn primary"
            *ngIf="typeOverFacet == 'categories'"
            [routerLink]="selectedCategory?.url"
          >
            <span class="button-text">
              {{ "searchBoxFacets.showCategory" | cxTranslate }}
            </span>
            <cx-icon class="fas fa-chevron-right"></cx-icon>
          </button>
          <button
            class="custom-button btn primary"
            *ngIf="typeOverFacet == 'brand'"
            [routerLink]="selectedCategory?.url"
          >
            <span class="button-text">
              {{ "searchBoxFacets.showBrand" | cxTranslate }}
            </span>
            <cx-icon class="fas fa-chevron-right"></cx-icon>
          </button>
        </div>
      </ul>

      <span id="initialDescription" class="cx-visually-hidden">
        {{ "searchBox.initialDescription" | cxTranslate }}
      </span>
      <div
        *ngIf="
          data?.result.suggestions?.length || data?.result.products?.length
        "
        aria-live="assertive"
        class="cx-visually-hidden"
      >
        {{
          "searchBox.suggestionsResult"
            | cxTranslate : { count: data?.result.suggestions?.length }
        }}
        {{
          "searchBox.productsResult"
            | cxTranslate : { count: data?.result.products?.length }
        }}
        {{ "searchBox.initialDescription" | cxTranslate }}
      </div>
    </div>
    <p
      class="results__total"
      (click)="customLaunchSearchResult($event, searchInput.value)"
      *ngIf="data?.result?.totalResults"
    >
      {{
        "searchBox.seeAllTheResults"
          | cxTranslate : { quantity: data?.result.totalResults }
      }}<cx-icon class="fas fa-chevron-right"></cx-icon>
    </p>
  </div>
</ng-container>
