import { Component, OnDestroy, OnInit } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable, Subject, Subscription } from 'rxjs';
import { pluck, takeUntil } from 'rxjs/operators';
import WR360 from '@webrotate360/imagerotator';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-custom-image360',
  templateUrl: './custom-image360.component.html',
  styleUrls: ['./custom-image360.component.scss'],
})
export class CustomImage360Component implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  viewerApi: any;
  image360: string;
  name: string;
  unsubscribe$: Subject<void> = new Subject();

  constructor(
    private currentProductService: CurrentProductService,
    private http: HttpClient,
    private occEndpointService: OccEndpointsService
  ) {
    this.currentProductService
      .getProduct()
      .pipe(pluck('code'), takeUntil(this.unsubscribe$))
      .subscribe((web: any) => (this.image360 = web));
  }

  ngOnInit(): void {
    const viewer = WR360.ImageRotator.Create('webrotate360');

    /* viewer.settings.configFileURL =
      'https://hybris.dev-promesa.qubikdigital.com/occ/v2/promesaSite/image360/FB17178/SiteCustomizations'; */

    this.subscription.add(
      this.getImage360(this.image360).subscribe((data: any) => {
        viewer.settings.configFileURL = data.url;
        viewer.settings.graphicsPath = '../assets/graphics';
        viewer.settings.alt = 'Imagen 360';
        viewer.settings.responsiveBaseWidth = 800;
        viewer.settings.responsiveMinHeight = 450;

        viewer.settings.apiReadyCallback = (api: any, isFullScreen: any) => {
          this.viewerApi = api;
          this.viewerApi.images.onDrag((event: any) => {
            console.log(
              `${
                event.action
              }; current image index = ${this.viewerApi.images.getCurrentImageIndex()}`
            );
          });
        };

        viewer.runImageRotator();
      })
    );
  }

  getImage360(productCode: string): Observable<any> {
    const url = this.occEndpointService.buildUrl('product360', {
      urlParams: {
        productCode: productCode,
      },
    });

    return this.http.get(url, {
      responseType: 'blob' as 'text',
      observe: 'response',
    });
  }

  ngOnDestroy(): void {
    this.viewerApi.delete();
    this.subscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
