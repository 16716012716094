import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-custom-account-comment',
  templateUrl: './custom-account-comment.component.html',
  styleUrls: ['./custom-account-comment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomAccountCommentComponent implements OnInit {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() valueDefault: any;
  @Input() currentPage: any;
  @Output() sendComment = new EventEmitter<any>();
  commentForm: FormGroup;
  constructor() {}

  ngOnInit(): void {
    this.commentForm = new FormGroup({
      comment: new FormControl(this.valueDefault ? this.valueDefault : ''),
    });
  }

  onSendComment() {
    this.sendComment.emit(this.commentForm);
  }

  resetForm() {
    this.commentForm.reset();
  }
}
