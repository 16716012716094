import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { OrderDetailsModule } from '@spartacus/order/components';
import { MediaModule, PromotionsModule } from '@spartacus/storefront';
import { CustomCartSharedModule } from 'src/app/cms-components/cart/custom-cart-shared/custom-cart-shared.module';
import { CustomPaginationModule } from 'src/app/cms-components/custom/components/custom-pagination/custom-pagination.module';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomOrderDetailTotalsModule } from '../custom-order-detail-totals/custom-order-detail-totals.module';
import { CustomOrderDetailItemProductComponent } from './custom-order-detail-item-product/custom-order-detail-item-product.component';
import { CustomOrderDetailItemsComponent } from './custom-order-detail-items.component';



@NgModule({
  declarations: [
    CustomOrderDetailItemsComponent,
    CustomOrderDetailItemProductComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    CustomCartSharedModule,
    PromotionsModule,
    OrderDetailsModule,
    CustomCurrencyModule,
    MediaModule,
    CustomPaginationModule,
    CustomOrderDetailTotalsModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        AccountOrderDetailsItemsComponent: {
          component: CustomOrderDetailItemsComponent,
        },
      },
    }),
  ],
  exports: [
    CustomOrderDetailItemsComponent,
    CustomOrderDetailItemProductComponent
  ]
})
export class CustomOrderDetailItemsModule { }
