<div class="rebate-detail-component">
  <h6>{{ "customRebate.description" | cxTranslate }}</h6>
  <span *ngIf="description">{{ description }}</span>
  <div class="d-flex align-items-center">
    <cx-icon type="STOPWATCH"></cx-icon>
    <span class="rebate-detail-component__available-text">{{
      "customRebate.availableUntil"
        | cxTranslate : { date: date | date : "dd/MM/yyyy" }
    }}</span>
  </div>
  <h6>{{ "customRebate.recommendedProducts" | cxTranslate }}</h6>
  <ng-container *ngIf="recommendedProducts$ | async as recommendedProducts; else loading">
    <div class="rebate-detail-component__card-container">
      <app-custom-product-card *ngFor="let product of recommendedProducts?.slice(0, 6)" [product]="product"
        [fromContentPage]="true" [isRebate]="true" [wideLabel]="true"></app-custom-product-card>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <div class="cx-spinner">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
