import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomLoginModule } from './custom-login/custom-login.module';
import { CustomLoginFormModule } from './custom-login-form/custom-login-form.module';
import { CustomRegisterModule } from './custom-register/custom-register.module';
import { CustomRegisterCustomerModule } from './custom-register/custom-register-customer/custom-register-customer.module';
import { CustomRegisterNonCustomerModule } from './custom-register/custom-register-non-customer/custom-register-non-customer.module';
import { CustomResetPasswordModule } from './custom-reset-password/custom-reset-password.module';
import { EffectsModule } from '@ngrx/effects';
import { CustomUserEffects } from './effects/custom-users.effect';
import { ForgotPasswordComponentService } from '@spartacus/user/profile/components';
import { CustomForgotPasswordModule } from './custom-forgot-password/custom-forgot-password.module';
import { CustomRegisterEmployeeModule } from './custom-register/custom-register-employee/custom-register-employee.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CustomLoginModule,
    CustomLoginFormModule,
    CustomRegisterModule,
    CustomRegisterCustomerModule,
    CustomRegisterEmployeeModule,
    CustomRegisterNonCustomerModule,
    CustomResetPasswordModule,
    EffectsModule.forFeature([CustomUserEffects]),
    CustomForgotPasswordModule,
  ],
  providers: [
    {
      provide: ForgotPasswordComponentService,
    },
  ],
})
export class UserModule {}
