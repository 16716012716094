import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OccEndpointsService } from '@spartacus/core';
import { catchError } from 'rxjs/operators';
import { CustomPoints } from '../models/custom-points.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomPromeclubPointsService {
  isActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  points$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService
  ) {}

  setActiveStatus(value: boolean): void {
    this.isActive$.next(value);
  }

  getIsActiveStatus(): Observable<boolean> {
    return this.isActive$.asObservable();
  }

  getPointsByProductCode(productCode: string): Observable<CustomPoints | null> {
    const url = this.occEndpointService.buildUrl('promeclubProductPoints', {
      urlParams: { productCode },
    });
    return this.http
      .get<CustomPoints | null>(url)
      .pipe(catchError((error) => EMPTY));
  }

  setPoints(value: number): void {
    this.points$.next(value);
  }
  getPoints(): Observable<number> {
    return this.points$.asObservable();
  }
}
