<div class="container-order-history">
  <!-- HEADER -->
  <div class="cx-order-history-header">
    <app-custom-title-my-account
      [title]="'orderHistory.orderHistoryPromeclub' | cxTranslate"
      [lessMargin]="true"
    ></app-custom-title-my-account>
  </div>
  <ng-container
    *ngIf="{
      orders: promeclubOrders$ | async,
      isMobile: isMobile$ | async
    } as type"
  >
    <!-- BODY -->
    <ng-container *ngIf="type.orders; else spinnerLoading">
      <div class="cx-order-history-body">
        <ng-container *ngIf="type.orders?.orders; else noOrder">
          <!-- Select Form and Pagination Top -->

          <span class="cx-order-history-subtitle" *ngIf="type.isMobile">
            {{ "orderHistory.totalPoints" | cxTranslate }}
            {{ type?.orders?.promeclubPoints | number : "" : "es-AR" }}
          </span>
          <div class="order-subtitle" *ngIf="!type.isMobile">
            <h3 class="order-subtitle__value">
              {{ "orderHistory.totalPoints" | cxTranslate }}
              {{ type?.orders?.promeclubPoints | number : "" : "es-AR" }}
            </h3>
            <a
              class="download-account-status"
              (click)="downloadAccountStatus()"
            >
              {{ "orderHistory.downloadStatus" | cxTranslate }}
            </a>
          </div>
          <a
            class="download-account-status"
            *ngIf="type.isMobile"
            (click)="downloadAccountStatus()"
          >
            {{ "orderHistory.downloadStatus" | cxTranslate }}
          </a>
          <div class="cx-order-history-filters top">
            <div class="cx-order-history-form-group"></div>
            <div class="cx-order-history-pagination" *ngIf="!type.isMobile">
              <app-custom-filter
                [label]="'orderHistory.filterResults' | cxTranslate"
                [entries]="quantities"
                [classSelect]="'small-filter'"
                [valueDefault]="pageSize"
                (filterSelected)="filterOrders($event)"
              >
              </app-custom-filter>
              <app-custom-pagination
                *ngIf="promeclubPagination.totalPages >= 1"
                [pagination]="promeclubPagination"
                (viewPageEvent)="customPageChangeSelected($event)"
                [pageSelected]="selectedPage"
                [isEditable]="true"
              ></app-custom-pagination>
            </div>
          </div>

          <div class="tabs">
            <div
              class="tabs__item"
              (click)="changeStatusRequest(exchangeType)"
              [ngClass]="{ active: selectedTab === exchangeType }"
            >
              {{ "orderHistory.exchanges" | cxTranslate }}
            </div>
            <div
              class="tabs__item one"
              (click)="changeStatusRequest(uploadType)"
              [ngClass]="{ active: selectedTab === uploadType }"
            >
              {{ "orderHistory.uploads" | cxTranslate }}
            </div>
            <div
              class="tabs__item two"
              (click)="changeStatusRequest(downloadType)"
              [ngClass]="{ active: selectedTab === downloadType }"
            >
              {{ "orderHistory.downloads" | cxTranslate }}
            </div>
          </div>
          <div class="pagination-mobile" *ngIf="type.isMobile">
            <app-custom-filter
              [label]="'orderHistory.filterResults' | cxTranslate"
              [entries]="quantities"
              [classSelect]="'small-filter'"
              [valueDefault]="pageSize"
              (filterSelected)="filterOrders($event)"
            >
            </app-custom-filter>
            <app-custom-pagination
              *ngIf="promeclubPagination.totalPages >= 1"
              [pagination]="promeclubPagination"
              (viewPageEvent)="customPageChangeSelected($event)"
              [pageSelected]="selectedPage"
              [isEditable]="true"
            ></app-custom-pagination>
          </div>

          <table
            class="table table-hover custom-order-history-table"
            *ngIf="!type.isMobile"
          >
            <thead>
              <tr>
                <th scope="col">
                  {{ "orderHistory.datePromeclub" | cxTranslate }}
                </th>
                <th scope="col">
                  {{ "orderHistory.orderIdPromeclub" | cxTranslate }}
                </th>
                <th scope="col">
                  {{ "orderHistory.totalPromeclub" | cxTranslate }}
                </th>
                <th scope="col">
                  {{ "orderHistory.statusPromeclub" | cxTranslate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngIf="type?.orders?.orders?.length > 0; else noOrder"
              >
                <tr
                  *ngFor="let order of type?.orders?.orders"
                  (click)="goToOrderDetail(order)"
                >
                  <td class="cx-order-history-placed">
                    <div class="d-md-none cx-order-history-label">
                      {{ "orderHistory.datePromeclub" | cxTranslate }}
                    </div>
                    <a
                      [routerLink]="
                        selectedTab === exchangeType
                          ? ({
                              cxRoute: 'orderDetails',
                              params: order
                            } | cxUrl)
                          : null
                      "
                      class="cx-order-history-value"
                      >{{ order?.placed | date : "dd/MM/yyyy" }}</a
                    >
                  </td>

                  <td class="cx-order-history-code">
                    <div class="d-md-none cx-order-history-label">
                      {{ "orderHistory.orderIdPromeclub" | cxTranslate }}
                    </div>
                    <a
                      [routerLink]="
                        selectedTab === exchangeType
                          ? ({
                              cxRoute: 'orderDetails',
                              params: order
                            } | cxUrl)
                          : null
                      "
                      class="cx-order-history-value"
                      [ngClass]="{ underline: selectedTab === exchangeType }"
                    >
                      {{ selectedTab === exchangeType ? order?.code : "-" }}
                    </a>
                  </td>

                  <td class="cx-order-history-total">
                    <div class="d-md-none cx-order-history-label">
                      {{ "orderHistory.totalPromeclub" | cxTranslate }}
                    </div>
                    <a
                      [routerLink]="
                        selectedTab === exchangeType
                          ? ({
                              cxRoute: 'orderDetails',
                              params: order
                            } | cxUrl)
                          : null
                      "
                      class="cx-order-history-value"
                      [ngClass]="{ negative: selectedTab !== uploadType }"
                    >
                      {{ selectedTab !== uploadType ? "-" : "" }}
                      {{ order?.total?.value | number : "" : "es-AR" }}</a
                    >
                  </td>

                  <td class="cx-order-history-status">
                    <div class="d-md-none cx-order-history-label">
                      {{ "orderHistory.statusPromeclub" | cxTranslate }}
                    </div>
                    <a
                      [routerLink]="
                        selectedTab === exchangeType
                          ? ({
                              cxRoute: 'orderDetails',
                              params: order
                            } | cxUrl)
                          : null
                      "
                      class="cx-order-history-value"
                    >
                      {{ order?.reason }}</a
                    >
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <ng-container *ngIf="type.isMobile">
            <ng-container
              *ngIf="type?.orders?.orders?.length > 0; else noOrder"
            >
              <div
                class="order-mobile"
                *ngFor="let order of type?.orders?.orders"
                (click)="goToOrderDetail(order)"
              >
                <div class="order-mobile__date">
                  <span class="order-mobile__date-title">
                    {{ "orderHistory.datePromeclub" | cxTranslate }}
                  </span>
                  <span class="order-mobile__date-value">
                    {{ order?.placed | date : "dd/MM/yyyy" }}
                  </span>
                </div>
                <div class="order-mobile__number">
                  <span class="order-mobile__number-title">
                    {{ "orderHistory.orderIdPromeclub" | cxTranslate }}
                  </span>
                  <span
                    class="order-mobile__number-value"
                    [ngClass]="{ underline: selectedTab === exchangeType }"
                  >
                    {{ selectedTab === exchangeType ? order?.code : "-" }}
                  </span>
                </div>
                <div class="order-mobile__points">
                  <span class="order-mobile__points-title">
                    {{ "orderHistory.totalPromeclub" | cxTranslate }}
                  </span>
                  <span
                    class="order-mobile__points-value"
                    [ngClass]="{ negative: selectedTab !== uploadType }"
                  >
                    {{ selectedTab !== uploadType ? "-" : "" }}
                    {{ order?.total?.value | number : "" : "es-AR" }}
                  </span>
                </div>
                <div class="order-mobile__reason">
                  <span class="order-mobile__reason-title">
                    {{ "orderHistory.statusPromeclub" | cxTranslate }}
                  </span>
                  <span class="order-mobile__reason-value">
                    {{ order?.reason }}
                  </span>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <!-- Select Form and Pagination Bottom -->
          <div
            class="cx-order-history-sort"
            *ngIf="type?.orders?.orders?.length > 0"
          >
            <div class="cx-order-history-pagination">
              <app-custom-pagination
                *ngIf="promeclubPagination.totalPages >= 1"
                [pagination]="promeclubPagination"
                (viewPageEvent)="customPageChangeSelected($event)"
                [pageSelected]="selectedPage"
                [isEditable]="true"
              ></app-custom-pagination>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #spinnerLoading>
  <div class="cx-order-history-body cx-order-history-body__loading">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>

<!-- NO ORDER CONTAINER -->
<ng-template #noOrder>
  <div class="cx-order-history-no-order">
    <app-custom-empty-cart [fromMyAccount]="true"></app-custom-empty-cart>
  </div>
</ng-template>
