import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomReturnOrderListComponent } from './custom-return-order-list.component';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { IconModule, ListNavigationModule } from '@spartacus/storefront';
import { CustomPaginationModule } from 'src/app/cms-components/custom/components/custom-pagination/custom-pagination.module';
import { CustomFilterModule } from 'src/app/cms-components/custom/components/custom-filter/custom-filter.module';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomLoadingSpinnerModule } from 'src/app/cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomAccountTableModule } from 'src/app/cms-components/custom/components/custom-account-table/custom-account-table.module';
import { RouterModule } from '@angular/router';
import { CustomEmptyCartModule } from 'src/app/cms-components/custom/components/custom-empty-cart/custom-empty-cart.module';
import { CustomTitleMyAccountModule } from 'src/app/cms-components/custom/components/custom-title-my-account/custom-title-my-account.module';



@NgModule({
  declarations: [
    CustomReturnOrderListComponent
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    UrlModule,
    I18nModule,
    IconModule,
    ListNavigationModule,
    CustomPaginationModule,
    CustomFilterModule,
    CustomCurrencyModule,
    CustomLoadingSpinnerModule,
    CustomAccountTableModule,
    RouterModule,
    CustomEmptyCartModule,
    CustomTitleMyAccountModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ReturnOrderComponent: {
          component: CustomReturnOrderListComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ]
})
export class CustomReturnOrderListModule { }
