<ng-container
  *ngIf="{
    isInReview: isInReview$ | async,
    isInDetail: isInDetail$ | async,
    isInOffer: isInOffer$ | async,
    activeCheckoutButton: activeCheckoutButton$ | async,
    isActiveButton: isActive$ | async
  } as data"
>
  <div class="quotes-summary__action">
    <button
      class="quotes-summary__action__button"
      id="summaryButtonReview"
      *ngIf="!data.isInDetail && !data.isInOffer"
      [ngbTooltip]="
        data.isActiveButton
          ? ''
          : ('accountQuotes.summary.tooltipReviewProposal' | cxTranslate)
      "
      placement="top-center"
      tooltipClass="tooltip-color"
      [triggers]="'click'"
      (click)="data.isActiveButton ? goToSummary(data.isInReview) : false"
      [ngClass]="{ confirm: data.isInReview }"
      [disabled]="blockButton"
    >
      <span
        class="quotes-summary__action__button-text"
        *ngIf="!data.isInReview"
      >
        {{ "accountQuotes.summary.reviewProposal" | cxTranslate }}
      </span>
      <span
        class="quotes-summary__action__button-text center"
        *ngIf="data.isInReview"
      >
        {{ "accountQuotes.summary.confirmProposal" | cxTranslate }}
      </span>
      <cx-icon class="fas fa-chevron-right" *ngIf="!data.isInReview"></cx-icon>
    </button>
    <button
      *ngIf="
        !data.isInDetail &&
        !data.isInOffer &&
        quote?.state === QUOTE_STATE.BUYER_DRAFT
      "
      id="summaryButtonRemove"
      class="quotes-summary__action__button button-secondary"
      (click)="openRemoveConfirmationModal(quote?.code)"
    >
      <span class="quotes-summary__action__button-text button-secondary center">
        {{ "accountQuotes.overview.removeProposal" | cxTranslate }}
      </span>
    </button>

    <button
      *ngIf="data.isInDetail && !data.isInOffer"
      id="summaryButtonSee"
      class="quotes-summary__action__button"
      (click)="goToQuotesList()"
    >
      <span class="quotes-summary__action__button-text center">
        {{ "accountQuotes.summary.seeProposals" | cxTranslate }}
      </span>
    </button>

    <button
      class="quotes-summary__action__button offer"
      id="summaryButtonCheckout"
      *ngIf="data.isInOffer"
      (click)="goToCheckout()"
      [disabled]="!data.activeCheckoutButton"
    >
      <span class="quotes-summary__action__button-text offer">
        {{ "accountQuotes.summary.checkout" | cxTranslate }}
      </span>
      <cx-icon class="fas fa-chevron-right offer"></cx-icon>
    </button>
  </div>
</ng-container>
