import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ProductScope, ProductService, isNotNullable } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { CustomProductVariantsService } from 'src/app/cms-components/product/components/custom-product-variants-container/product-variants.service';
import { CustomProduct } from 'src/app/cms-components/product/model/custom-product.interface';
import { CustomPreAddToCartService } from '../custom-pre-add-to-cart.service';
import { CustomBaseSiteCheckerService } from '../../../../../services/custom-base-site-checker.service';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-custom-pre-add-to-cart-item',
  templateUrl: './custom-pre-add-to-cart-item.component.html',
  styleUrls: ['./custom-pre-add-to-cart-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPreAddToCartItemComponent implements OnInit, OnDestroy {
  @Input() productCodeProps: string;
  @Input() quantitySelected: number;
  customProduct$: Observable<CustomProduct | null>;
  productCode: string;
  loading$: Observable<boolean>;
  subscription: Subscription = new Subscription();
  addToCartForm = new FormGroup({
    quantity: new FormControl(1, { updateOn: 'change' }),
  });
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  constructor(
    private productService: ProductService,
    private variantsService: CustomProductVariantsService,
    private customPreAddToCartService: CustomPreAddToCartService,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService
  ) {}

  ngOnInit(): void {
    this.addToCartForm.get('quantity')?.setValue(this.quantitySelected);
    this.customPreAddToCartService.setQuantity(this.quantitySelected);
    this.productCode = this.productCodeProps;
    this.loading$ = this.variantsService.getLoading();
    this.customProduct$ = this.getCurrentProduct(this.productCode);
    this.subscription.add(
      this.variantsService
        .getSelectedVariantCode()
        .subscribe((variantProductCode) => {
          this.customProduct$ = this.getCurrentProduct(variantProductCode);
          this.variantsService.setLoading(false);
        })
    );
    this.subscription.add(
      this.addToCartForm
        .get('quantity')
        ?.valueChanges.subscribe((value) =>
          this.customPreAddToCartService.setQuantity(value)
        )
    );
  }

  getCurrentProduct(code: string): Observable<CustomProduct> {
    return this.productService.get(code, ProductScope.DETAILS).pipe(
      filter(isNotNullable),
      tap((product) => this.customPreAddToCartService.setProduct(product))
    );
  }
  ngOnDestroy(): void {
    this.variantsService.setVariantCode(null);
    this.subscription.unsubscribe();
  }
}
