<ng-container *ngIf="product$ | async as product">
  <ng-container *ngIf="reviews$ | async as reviews">
    <ng-container *ngIf="!(isMobile$ | async); else mobile">
      <div *ngIf="title$ | async as title" class="product-spec" id="reviews">
        <app-custom-component-title [title]="title" [hasFirsWordInBold]="true">
        </app-custom-component-title>
      </div>

      <div class="custom-review__scores">
        <div class="custom-review__scores__globals-rankings">
          <div class="custom-review__scores__globals-rankings__cont">
            <ng-container *ngIf="product$ | async as product">
              <span *ngIf="product.averageRating">{{
                product.averageRating | CustomRoundNumber
              }}</span>
              <span *ngIf="!product.averageRating">0</span>
            </ng-container>
            <div class="custom-review__scores__globals-rankings__star">
              <app-custom-product-reviews-overview
                *ngIf="product.averageRating"
                [fromOverallRating]="true"
              ></app-custom-product-reviews-overview>
              <app-custom-star-rating
                [rating]="0"
                *ngIf="!product.averageRating"
                [fromOverallRating]="true"
              ></app-custom-star-rating>
            </div>
            <ng-container *ngIf="isMobile$ | async">
              <div *ngIf="!showOverall" (click)="showOverall = true">
                <cx-icon class="fas fa-chevron-down"></cx-icon>
              </div>
              <div *ngIf="showOverall" (click)="showOverall = false">
                <cx-icon class="fas fa-chevron-up"></cx-icon>
              </div>
            </ng-container>
          </div>
          <span class="custom-review__scores__globals-rankings__overallRating"
            >{{ reviews.length }}
            {{ "productReview.globalRating" | cxTranslate }}</span
          >
        </div>
        <div *ngIf="showOverall" class="fadeIn custom-review__scores-content">
          <div class="custom-review__scores__score">
            5 <cx-icon type="STAR"></cx-icon>
            <app-custom-review-progress
              [progress]="fiveStarsPercent"
              total="initialMaxListItems"
            ></app-custom-review-progress>
            <span>{{ fiveStarsPercent | number : "1.0-0" }}%</span>
          </div>
          <div class="custom-review__scores__score">
            4 <cx-icon type="STAR"></cx-icon>
            <app-custom-review-progress
              [progress]="fourStarsPercent"
            ></app-custom-review-progress>
            <span>{{ fourStarsPercent | number : "1.0-0" }}%</span>
          </div>
          <div class="custom-review__scores__score">
            3 <cx-icon type="STAR"></cx-icon>
            <app-custom-review-progress
              [progress]="threeStarsPercent"
            ></app-custom-review-progress>
            <span>{{ threeStarsPercent | number : "1.0-0" }}%</span>
          </div>
          <div class="custom-review__scores__score">
            2 <cx-icon type="STAR"></cx-icon>
            <app-custom-review-progress
              [progress]="twoStarsPercent"
            ></app-custom-review-progress>
            <span>{{ twoStarsPercent | number : "1.0-0" }}%</span>
          </div>
          <div class="custom-review__scores__score">
            1 <cx-icon type="STAR"></cx-icon>
            <app-custom-review-progress
              [progress]="oneStarsPercent"
            ></app-custom-review-progress>
            <span>{{ oneStarsPercent | number : "1.0-0" }}%</span>
          </div>
        </div>
      </div>
      <button
        class="btn btn-primary custom-review__scores-button"
        (click)="openPopup()"
        [disabled]="!(isUserLoggedIn$ | async)"
      >
        <span
          >{{ "productReview.createReview" | cxTranslate }}
          <span class="custom-review__scores-button-bold">{{
            "productReview.newReview" | cxTranslate | lowercase
          }}</span>
        </span>
        <cx-icon class="fas fa-chevron-right"></cx-icon>
      </button>
    </ng-container>
    <ng-template #mobile>
      <div #productReviewElement>
        <app-custom-product-attributes-accordion
          [titleAttribute]="title$ | async"
          attributes
        >
          <div class="custom-review__scores">
            <div class="custom-review__scores__globals-rankings">
              <div class="custom-review__scores__globals-rankings__cont">
                <ng-container *ngIf="product$ | async as product">
                  <div class="custom-review__scores__globals-rankings__star">
                    <app-custom-product-reviews-overview
                      *ngIf="product.averageRating"
                      [fromOverallRating]="true"
                    ></app-custom-product-reviews-overview>
                    <app-custom-star-rating
                      [rating]="0"
                      *ngIf="!product.averageRating"
                      [fromOverallRating]="true"
                    ></app-custom-star-rating>
                  </div>
                  <span *ngIf="product.averageRating"
                    >{{ product.averageRating | CustomRoundNumber }}/5</span
                  >
                  <span *ngIf="!product.averageRating">0</span>
                </ng-container>
              </div>
              <span
                class="custom-review__scores__globals-rankings__overallRating"
                >({{ reviews.length }})</span
              >
            </div>
            <div class="fadeIn custom-review__scores-content">
              <div class="custom-review__scores__score">
                <app-custom-review-progress
                  [progress]="oneStarsPercent"
                  total="initialMaxListItems"
                ></app-custom-review-progress>
                <app-custom-product-reviews-overview
                  [reviewStars]="1"
                  [reviewsMobilePDP]="true"
                  *ngIf="product.averageRating"
                ></app-custom-product-reviews-overview>
                <div class="custom-review__scores__score-percentage">
                  <span>{{ oneStarsPercent | number : "1.0-0" }}%</span>
                </div>
              </div>
              <div class="custom-review__scores__score">
                <app-custom-review-progress
                  [progress]="twoStarsPercent"
                  total="initialMaxListItems"
                ></app-custom-review-progress>
                <app-custom-product-reviews-overview
                  [reviewStars]="2"
                  [reviewsMobilePDP]="true"
                  *ngIf="product.averageRating"
                ></app-custom-product-reviews-overview>
                <div class="custom-review__scores__score-percentage">
                  <span>{{ twoStarsPercent | number : "1.0-0" }}%</span>
                </div>
              </div>
              <div class="custom-review__scores__score">
                <app-custom-review-progress
                  [progress]="threeStarsPercent"
                  total="initialMaxListItems"
                ></app-custom-review-progress>
                <app-custom-product-reviews-overview
                  [reviewStars]="3"
                  [reviewsMobilePDP]="true"
                  *ngIf="product.averageRating"
                ></app-custom-product-reviews-overview>
                <div class="custom-review__scores__score-percentage">
                  <span>{{ threeStarsPercent | number : "1.0-0" }}%</span>
                </div>
              </div>
              <div class="custom-review__scores__score">
                <app-custom-review-progress
                  [progress]="fourStarsPercent"
                  total="initialMaxListItems"
                ></app-custom-review-progress>
                <app-custom-product-reviews-overview
                  [reviewStars]="4"
                  [reviewsMobilePDP]="true"
                  *ngIf="product.averageRating"
                ></app-custom-product-reviews-overview>
                <div class="custom-review__scores__score-percentage">
                  <span>{{ fourStarsPercent | number : "1.0-0" }}%</span>
                </div>
              </div>
              <div class="custom-review__scores__score">
                <app-custom-review-progress
                  [progress]="fiveStarsPercent"
                  total="initialMaxListItems"
                ></app-custom-review-progress>
                <app-custom-product-reviews-overview
                  [reviewStars]="5"
                  [reviewsMobilePDP]="true"
                  *ngIf="product.averageRating"
                ></app-custom-product-reviews-overview>
                <div class="custom-review__scores__score-percentage">
                  <span>{{ fiveStarsPercent | number : "1.0-0" }}%</span>
                </div>
              </div>
            </div>
            <button
              class="btn btn-primary custom-review__scores-button"
              (click)="openPopup()"
              [disabled]="!(isUserLoggedIn$ | async)"
            >
              <span
                >{{ "productReview.createReview" | cxTranslate }}
                <span class="custom-review__scores-button-bold">{{
                  "productReview.newReview" | cxTranslate | lowercase
                }}</span>
              </span>
              <cx-icon class="fas fa-chevron-right"></cx-icon>
            </button>
          </div>
        </app-custom-product-attributes-accordion>
      </div>
    </ng-template>

    <ng-container *ngIf="!(isMobile$ | async); else isMobileLastReviews">
      <div
        class="custom-review__last-reviews__title"
        *ngIf="reviews.length > 0"
      >
        <app-custom-component-title
          [title]="'productReview.lastReviews' | cxTranslate"
          [hasFirsWordInBold]="true"
        >
        </app-custom-component-title>
        <ng-container
          *ngIf="!(isMobile$ | async) && reviews.length > initialMaxListItems"
        >
          <div
            class="custom-review__last-reviews__title__showMoreReviews"
            (click)="maxListItems = 8"
            *ngIf="maxListItems === initialMaxListItems"
          >
            {{ "productReview.showMoreReviews" | cxTranslate }}
            <cx-icon class="fas fa-chevron-down"></cx-icon>
          </div>
          <div
            class="custom-review__last-reviews__title__showMoreReviews"
            (click)="maxListItems = initialMaxListItems"
            *ngIf="maxListItems !== initialMaxListItems"
          >
            {{ "productReview.showLessReviews" | cxTranslate }}
            <cx-icon class="fas fa-chevron-up"></cx-icon>
          </div>
        </ng-container>
      </div>
      <div class="custom-review__last-reviews" *ngIf="reviews.length > 0">
        <div
          class="review"
          tabindex="0"
          *ngFor="let review of reviews | slice : 0 : maxListItems"
        >
          <div class="custom-review__last-reviews__card">
            <div class="custom-review__last-reviews__card-header">
              <span>{{ review.rating }}</span>
              <app-custom-star-rating
                [rating]="review.rating"
              ></app-custom-star-rating>
              <span> {{ review.alias }} </span>
            </div>
            <div class="custom-review__last-reviews__card-body">
              {{ review.comment }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #isMobileLastReviews>
      <app-custom-product-attributes-accordion
        *ngIf="reviews?.length > 0"
        [titleAttribute]="'productReview.lastReviews' | cxTranslate"
        attributes
      >
        <div class="custom-review__last-reviews">
          <div
            class="review"
            tabindex="0"
            *ngFor="
              let review of reviews | slice : 0 : maxListItemsMobile;
              let i = index
            "
            [ngClass]="{ 'none-border': i == maxListItemsMobile - 1 }"
          >
            <div class="custom-review__last-reviews__card">
              <div class="custom-review__last-reviews__card-header">
                <div
                  *ngIf="review?.rating"
                  class="custom-review__last-reviews__card-header-stars"
                >
                  <app-custom-product-reviews-overview
                    [reviewStars]="review.rating"
                    [reviewsMobilePDP]="true"
                  ></app-custom-product-reviews-overview>
                  <span>{{ review.rating }}/5</span>
                </div>
                <span
                  *ngIf="review?.alias"
                  class="custom-review__last-reviews__card-header-alias"
                >
                  {{ review.alias }}
                </span>
              </div>
              <div
                class="custom-review__last-reviews__card-body"
                *ngIf="review?.comment"
              >
                {{ review.comment | slice : 0 : 117 }}
                {{ review.comment.length >= 117 ? "..." : "" }}
              </div>
            </div>
          </div>
          <div *ngIf="reviews.length > initialMaxListItemsMobile">
            <div
              class="custom-review__last-reviews__title__showMoreReviews"
              (click)="maxListItemsMobile = reviews.length"
              *ngIf="maxListItemsMobile === initialMaxListItemsMobile"
            >
              {{ "productReview.showMoreReviews" | cxTranslate }}
              <cx-icon class="fas fa-chevron-down"></cx-icon>
            </div>
            <div
              class="custom-review__last-reviews__title__showMoreReviews"
              (click)="maxListItemsMobile = initialMaxListItemsMobile"
              *ngIf="maxListItemsMobile !== initialMaxListItemsMobile"
            >
              {{ "productReview.showLessReviews" | cxTranslate }}
              <cx-icon class="fas fa-chevron-up"></cx-icon>
            </div>
          </div>
        </div>
      </app-custom-product-attributes-accordion>
    </ng-template>
  </ng-container>
</ng-container>
