import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomConfirmationPasswordComponent } from './custom-confirmation-password.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CustomConfirmationPasswordDialogComponent } from './custom-confirmation-password-dialog/custom-confirmation-password-dialog.component';
import { CustomLoadingSpinnerModule } from '../../../custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { IconModule, ModalModule } from '@spartacus/storefront';
import { CustomModalGenericModule } from 'src/app/cms-components/custom/components/custom-modal/custom-modal-generic.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    ModalModule,
    CustomModalGenericModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromesaCustomerActivationComponent: {
          component: CustomConfirmationPasswordComponent,
        },
      },
    }),
    CustomLoadingSpinnerModule
  ],
  declarations: [CustomConfirmationPasswordComponent, CustomConfirmationPasswordDialogComponent],
  exports: [CustomConfirmationPasswordComponent, CustomConfirmationPasswordDialogComponent]

})
export class CustomConfirmationPasswordModule { }
