import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductBundleComponent } from './custom-product-bundle.component';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@spartacus/core';
import { CustomProductAttributesAccordionModule } from '../custom-product-tabs/custom-product-attributes-accordion/custom-product-attributes-accordion.module';

@NgModule({
  declarations: [CustomProductBundleComponent],
  imports: [CommonModule, IconModule, MediaModule, RouterModule, I18nModule, CustomProductAttributesAccordionModule],
  exports: [CustomProductBundleComponent],
})
export class CustomProductBundleModule { }
