export const CustomEndpointsConfig = {
  backend: {
    occ: {
      endpoints: {
        baseSites:
          'basesites?fields=isUnderMaintenance,baseSites(uid,defaultLanguage(isocode),urlEncodingAttributes,urlPatterns,stores(currencies(isocode),defaultCurrency(isocode),languages(isocode),defaultLanguage(isocode)),theme,defaultPreviewCatalogId,defaultPreviewCategoryCode,defaultPreviewProductCode)',
        carts:
          'users/${userId}/carts?fields=carts(DEFAULT,isFromQuote,potentialProductPromotions,appliedProductPromotions,deliveryAddress(FULL),formattedDeliveryDate,paymentAddress(FULL),deliveryMode(FULL),potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL),brand(code,name),badge,promotickWebCode,productType,previousPrice),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description,b2bUnit)&lang=es&refreshCart=true&removeOutOfStockEntries=false',
        cart: 'users/${userId}/carts/${cartId}?fields=DEFAULT,potentialProductPromotions,deliveryAddress(FULL),formattedDeliveryDate,paymentAddress(FULL),deliveryMode(FULL),appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL),brand(code,name),badge,promotickWebCode,productType,previousPrice),basePrice(formattedValue,value),updateable,qualifiedEntryNumber,qualifiedProductCode),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(FULL),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description,isFromQuote,subTotalWithTax,subtotalTax&lang=es&refreshCart=true&removeOutOfStockEntries=false',
        productSearch:
          'products/search?fields=products(DEFAULT,code,name,summary,configurable,configuratorType,price(FULL),images(DEFAULT),stock(FULL),averageRating,variantOptions,previousPrice,categories(FULL),firstCategoryName,isGift,url,plpClassCatData,firstCategoryName,badge,tipoRecargaPromotick,promotickWebCode,brand(name)),plpVariantDataList(key,header,products(code,name,summary,configurable,configuratorType,price(FULL),images(DEFAULT),stock(FULL),averageRating,variantOptions,previousPrice,categories(FULL),firstCategoryName,isGift,url,plpClassCatData)),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery&plpType=classic',
        product: {
          default:
            'products/test/${productCode}?fields=DEFAULT,averageRating,stock(DEFAULT,tentativeDateToReposition),images(FULL),classifications,manufacturer,numberOfReviews,categories(FULL),baseOptions,baseProduct,isGift,variantOptions,variantType,simpleVariantSelectors,pointsPMC',
          list: 'products/test/${productCode}?fields=DEFAULT,code,stock(DEFAULT),name,summary,price(DEFAULT),url,previousPrice,isGift,images(DEFAULT,galleryIndex),categories(FULL),tipoRecargaPromotick,promotickWebCode',
          details:
            'products/test/${productCode}?fields=averageRating,stock(DEFAULT),description,availableForPickup,code,url,price(DEFAULT),previousPrice,numberOfReviews,manufacturer,categories(FULL),priceRange,multidimensional,isGift,tags,images(FULL),brand(code,name),productManual,technicalDocuments,comboItems(quantity,target(FULL)),summary,productVideos,badge,termsAndConditions,tipoRecargaPromotick,promotickWebCode,unit,pointsPMC,webRotatingXml,productType',
          attributes: 'products/test/${productCode}?fields=classifications',
          variants:
            'products/test/${productCode}?fields=name,purchasable,isGift,baseOptions(DEFAULT),baseProduct,variantOptions(DEFAULT),variantType,simpleVariantSelectors',
        },
        user: 'orgUsers/${userId}?fields=accessDetails(accessPromesa,accessPromeclub,accessPrometas,redirectUri),phone,FULL',
        categoriesBreadcrumb: 'categories/${categoryCode}?fields=FULL',
        categoryData: 'categories/${categoryCode}?fields=FULL',
        centerDistribution:
          'users/${userId}/carts/${cartId}/isPickupCDAvailable',
        productAttributes:
          'products/test/${productCode}?fields=classifications',
        subcategoriesData:
          'categories/subcategories/${categoryCode}?fields=FULL',
        firstLevelSubcategoriesData:
          'categories/firstlevel/subcategories/${categoryCode}?fields=FULL',
        saveCart:
          '/users/${userId}/carts/${cartId}/save?fields=savedCartData(BASIC)&saveCartName=${saveCartName}&saveCartDescription=${saveCartDescription}',
        deliveryModes: 'users/${userId}/carts/${cartId}/deliveryOptions',
        addresses: 'orgUsers/${userId}/addresses?fields=FULL',
        paymentConditions: 'orgUsers/${userId}/paymentConditions',
        setPaymentConditions:
          'orgUsers/${userId}/carts/${cartId}/setPaymentCondition',
        setCreditLinePaymentMode:
          'orgUsers/${userId}/carts/${cartId}/setCreditLinePaymentMode',
        setTransferPaymentMode:
          'orgUsers/${userId}/carts/${cartId}/setTransferPaymentMode',
        setDepositPaymentMode:
          'orgUsers/${userId}/carts/${cartId}/setDepositPaymentMode',
        setPromeclubPaymentMode:
          'orgUsers/${userId}/carts/current/setPromeclubPaymentMode',
        getCreditInfo: 'orgUsers/${userId}/carts/${cartId}/getCreditInfo',
        getCreditInfoWithoutCart: 'orgUsers/${userId}/getCreditInfo',
        promesaClientInfo: '/promesaRegister/promesaClientInfo',
        promesaClientRegister:
          '/promesaRegister/registerPromesaClient?password=${password}',
        validatePromesaClient: '/promesaRegister/validatePromesaClient',
        promesaInternalClientInfo: '/promesaRegister/promesaInternalClientInfo',
        validatePromesaInternalClientInfo:
          '/promesaRegister/validatePromesaInternalClientInfo',
        registerPromesaInternalClient:
          '/promesaRegister/registerPromesaInternalClient',
        promesaClientUpdateCredentials: '/promesaRegister/updateCredentials',
        confirmationAccount:
          '/promesaRegister/customerActivation?token=${token}',
        getWishList: '/users/${userId}/wishlists',
        postWishList: '/users/${userId}/wishlists',
        deleteWishList: '/users/${userId}/wishlists',
        clearWishlist: '/users/${userId}/wishlists/clear',
        regionsPrefix: '/countries/region-prefixes',
        sendCodeToCellphone: '/twilio/sendCodeSMSWithPhone',
        sendCodeToCellPhoneUsingEmail: '/twilio/sendCodeSMS',
        checkCode: '/twilio/checkCode',
        checkCodeCustomer: '/twilio/checkCodeCustomer',
        productCheckWishList: '/users/${userId}/wishlists/productCheck',
        placeOrder:
          'orgUsers/${userId}/orders?fields=DEFAULT,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL),brand(code,name),badge),basePrice(formattedValue,value)),totalPrice(formattedValue),totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),deliveryCost(FULL),totalTax(formattedValue, value),appliedVouchers,productDiscounts(formattedValue)',
        getTicketOrder: 'users/current/orders/${orderCode}/ticket',
        getCountryCode: '/countries/countryNumberCode',
        isValidRuc: '/promesaRegister/isValidRUC',
        isValidUid: '/promesaRegister/isUidInUse',
        getAppointmentDocumentTemplate:
          '/promesaRegister/downloadAuthorizationCardTemplate',
        getBussinessType: '/promesaRegister/promesaBusinessType',
        deleteRegisterDocument: '/media/deleteMedia',
        uploadRegisterDocument:
          '/media/uploadMedia/promesaContentCatalog/Staged',
        registerNonCustomer: '/promesaRegister/registerPromesaNonClient',
        setAuthorizedPerson: 'users/${userId}/authorizedPerson/manage',
        getListAuthorizedPeople: 'users/${userId}/authorizedPerson/list',
        deleteAuthorizedPerson:
          'users/${userId}/authorizedPerson/manage/delete',
        updateCartWithoutStock:
          'users/${userId}/carts/${cartId}/removeOutOfStock?fields=DEFAULT,potentialProductPromotions,deliveryAddress(FULL),formattedDeliveryDate,paymentAddress(FULL),deliveryMode(FULL),appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL),brand(code,name),badge),basePrice(formattedValue,value),updateable,qualifiedEntryNumber,qualifiedProductCode),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(FULL),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description',
        customerPoints: '/promeclub/points',
        orderDetail:
          'users/${userId}/orders/${orderId}?fields=DEFAULT,isFromQuote,b2bUnit,deliveryAddress(FULL),formattedCancelDate,formattedDeliveryDate,paymentMode,paymentAddress(FULL),deliveryMode(FULL),appliedProductPromotions,appliedOrderPromotions,unconsignedEntries(FULL),entries(totalPrice(formattedValue),product(images(FULL),stock(FULL),brand(code,name),badge),basePrice(formattedValue,value),updateable,qualifiedEntryNumber,qualifiedProductCode),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(FULL),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user',
        orderHistory: 'users/current/orders',
        orderCancellation: 'orgUsers/current/orders/${code}/cancel',
        claimReasons: '/my-account/ticket-reasons',
        postClaim: '/my-account/add-support-ticket',
        getAllClaims: '/my-account/support-tickets',
        getClaim: '/my-account/support-ticket/${ticketId}',
        deleteClaim: '/my-account/remove-support-ticket',
        companyData: 'myAccount/promesaCompanyInfo',
        requestExtensionCredit: 'orgUsers/${userId}/requestExtensionCredit',
        loginAttemps: 'authorization/attempts/${email}',
        accountStatus: 'promeclub/account-status',
        promeclubOrderDetail: 'promeclub/account-status/${orderCode}',
        promeclubDownloadAccountStatus: 'promeclub/download-account-status',
        paymentBanks: '/myAccount/paymentBanks',
        addPaymentRecord: '/myAccount/addPaymentRecord',
        promeclubAddToCart: 'orgUsers/current/carts/${cartId}/entriesPMC',
        getRebate: 'integration/rappel/list',
        getRebateByProductID: 'integration/rappel/product',
        getRebateByRappelID: 'integration/rappel/suggested',
        getRebateDetails:
          'integration/rappel/suggested?clientId=${clientId}&rappelId=${rappelId}',
        zagitaProductByProduct:
          'products/${productCode}/references/zagita?fields=BASIC,images(FULL)',
        registerNewsletter: 'mailchimp/newsletter/register',
        promeclubProvinces: 'countries/${countryIsoCode}/provinces',
        promeclubDistricts:
          'countries/${countryIsoCode}/provinces/${regionIsoCode}',
        promeclubAddressType: 'promeclub/available-address-types',
        promeclubCreateAddress: 'users/current/addresses',
        getPromeclubUserAddresses: 'orgUsers/current/addresses?fields=FULL',
        promeclubDeleteAddress: 'users/current/addresses/${addressId}',
        promeclubUpdateAddress: 'users/current/addresses/${addressId}',
        getRefundReasonsDevolutions:
          'users/current/orderReturns/getRefundReasons',
        setOrderReturn: 'users/current/orderReturns',
        orderReturns: 'users/current/orderReturns?fields=DEFAULT',
        orderReturnDetail:
          'users/${userId}/orderReturns/${returnRequestCode}?fields=BASIC,status,order(status,entries(product(images(FULL),stock(FULL),brand(code,name),badge))),comment,creationTime,returnEntries(FULL,refundAmount(formattedValue),orderEntry(basePrice(formattedValue),product(BASIC, images(FULL)))),totalPrice(formattedValue)',
        getQuoteList:
          'users/${userId}/quote/list?fields=DEFAULT,quotes(code,creationTime,name,state)',
        createQuote: 'users/${userId}/quote/carts/${cartId}',
        submitQuote: 'users/${userId}/quote/submit',
        getQuoteByCode:
          'users/${userId}/quote/${code}?fields=FULL,entries(FULL,originalPrice,requestedPrice,finalPrice,quoteAcceptedStatus)',
        quoteRequestPrice:
          'users/${userId}/quote/${quoteCode}/update?fields=FULL,entries(FULL,originalPrice,requestedPrice,finalPrice)',
        deleteQuote: 'users/${userId}/quote/${code}/remove',
        setQuotePaymentMode: 'users/${userId}/quote/${code}/${paymentMode}',
        createCartFromQuote: 'users/${userId}/quote/${code}/create-cart',
        setQuotePaymentCondition:
          'users/${userId}/quote/${code}/setPaymentCondition',
        acceptRejectQuoteProposal:
          'users/${userId}/quote/${code}/accept-refuse-proposal?fields=DEFAULT,entries(FULL,originalPrice,requestedPrice,quoteComments,quoteAcceptedStatus,quoteRejectedReason)',
        cancelOrderReturn: 'users/current/orderReturns/${returnId}/cancel',
        uploadReturnDocument: '/media/uploadMedia/Default/Online',
        checkStockPrice: 'orgUsers/${userId}/carts/${cartId}/stock/check',
        getTalleresBrands: 'talleres/getBrands',
        findTalleres: 'talleres/find?name=${name}&brands=${brands}',
        savedCart:
          '/users/${userId}/carts/${cartId}/savedcart?fields=savedCartData(DEFAULT,name,saveTime)',
        product360: '/image360/${productCode}',
        checkStock: 'orgUsers/${userId}/carts/${cartId}/stock/check',
        b2bUnitValidation: '/authorization/isUnitActive',
        reorder:
          'orgUsers/current/orders/createCartFromOrder?orderCode=${orderCode}',
        landingFormPromeclub: 'promeclub/register',
        promeclubProductPoints: 'promeclub/product-points/${productCode}',
        requestPromeclubAccess: 'orgUsers/${userId}/requestPromeclubAccess',
        addStockAlert: 'products/addStockAlert/${productCode}',
        termsAndConditions: 'promeclub/${userId}/acceptTyC',
      },
    },
  },
};
