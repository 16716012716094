<ng-container *ngIf="isMobile$ | async as isMobile">
  <ng-container *ngIf="componentData$ | async">

    <ng-container *ngIf="categories$ | async as categories">
      <app-custom-searchbox-list-items [isCategory]="true" [categories]="categories"></app-custom-searchbox-list-items>
    </ng-container>
    <ng-container *ngIf="brands$ | async as brands">
      <app-custom-searchbox-list-items [isCategory]="false" [categories]="brands"></app-custom-searchbox-list-items>
    </ng-container>
  </ng-container>
</ng-container>
