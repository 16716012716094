import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-return-order-actions',
  templateUrl: './custom-return-order-actions.component.html',
  styleUrls: ['./custom-return-order-actions.component.scss'],
})
export class CustomReturnOrderActionsComponent {
  @Input() orderCode: string;
  @Input() backRoute: string;
  @Input() forwardRoute: string;
  @Input() currentPage: string;
  @Input() isEnabled: boolean = false;
  @Output() goToRevisionOrder = new EventEmitter<any>();
  @Output() onBackAction = new EventEmitter<any>();
  @Output() confirmOrder = new EventEmitter<any>();
  onPressed: boolean = false;
  constructor() {}

  onRevisionOrder() {
    this.goToRevisionOrder.emit();
  }

  onBack() {
    this.onBackAction.emit();
  }

  onConfirmOrder() {
    this.onPressed = true;
    this.confirmOrder.emit();
  }
}
