import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CardWithAddress,
  CheckoutStepService,
  ShippingAddressComponent,
} from '@spartacus/checkout/components';
import {
  CheckoutCostCenterFacade,
  CheckoutDeliveryFacade,
  PaymentTypeFacade,
} from '@spartacus/checkout/root';
import {
  ActiveCartService,
  Address,
  Region,
  TranslationService,
  UserAddressService,
  UserCostCenterService,
} from '@spartacus/core';
import { Card } from '@spartacus/storefront';
import { Observable, combineLatest } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';
import { CustomPromeclubAddressService } from 'src/app/cms-components/my-account/services/custom-promeclub-address.service';
import { CustomBaseSiteCheckerService } from 'src/app/services/custom-base-site-checker.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomCheckoutDeliveryStepsService } from '../../../services/custom-checkout-delivery-steps.service';

interface CustomAddress extends Address {
  region?: CustomRegion;
  promotickAddressType?: string;
}

interface CustomRegion {
  countryIso?: string;
  isocode?: string;
  isocodeShort?: string;
  name?: string;
  province?: Region;
}

@Component({
  selector: 'app-custom-shipping-address',
  templateUrl: './custom-shipping-address.component.html',
  styleUrls: ['./custom-shipping-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomShippingAddressComponent
  extends ShippingAddressComponent
  implements OnInit, OnDestroy
{
  isAddressActive: boolean = false;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  showAddresses$: Observable<boolean> =
    this.customCheckoutStepsService.getShowAddresses();
  maxAddresses$: Observable<number> =
    this.customCheckoutStepsService.getMaxAddresses();
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  constructor(
    private customCheckoutStepsService: CustomCheckoutDeliveryStepsService,
    private customBreakpointService: CustomBreakpointService,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    protected userAddressService: UserAddressService,
    protected checkoutDeliveryService: CheckoutDeliveryFacade,
    protected activatedRoute: ActivatedRoute,
    protected translation: TranslationService,
    protected customPromeclubAddressService: CustomPromeclubAddressService,
    protected activeCartService: ActiveCartService,
    protected checkoutStepService: CheckoutStepService,
    protected paymentTypeService?: PaymentTypeFacade,
    protected userCostCenterService?: UserCostCenterService,
    protected checkoutCostCenterService?: CheckoutCostCenterFacade
  ) {
    super(
      userAddressService,
      checkoutDeliveryService,
      activatedRoute,
      translation,
      activeCartService,
      checkoutStepService,
      paymentTypeService,
      userCostCenterService,
      checkoutCostCenterService
    );
  }

  get selectedAddress$(): Observable<Address> {
    return this.checkoutDeliveryService.getDeliveryAddress().pipe(
      tap((address) => {
        if (
          address &&
          this.isPromeclub &&
          address.id &&
          (this.selectedAddress === undefined ||
            this.selectedAddress.id !== address.id)
        ) {
          this.selectedAddress = address;
          if (this.isPromeclub) {
            this.customCheckoutStepsService.setIsAddressSelected(true);
            this.selectAddress(this.selectedAddress);
          } else {
            this.SelectAddress(this.selectedAddress);
          }
          if (this.forceLoader) {
            this.next();
          }
        }
      })
    );
  }

  getCardContent(
    address: CustomAddress,
    selected: any,
    textShipToThisAddress: string,
    textSelected: string
  ): Card {
    return {
      title: !this.isPromeclub
        ? address.firstName
        : this.customPromeclubAddressService.typeTranslation(address),
      text: [
        'Dirección: ' +
          address.line1 +
          ' ' +
          (address.line2 ? address.line2 : ''),
        'Población: ' +
          (!this.isPromeclub ? address.region?.name : address?.district),
        'Región: ' +
          (!this.isPromeclub
            ? address.region?.province?.name
            : address.region?.name),
      ],
      actions: [{ name: textShipToThisAddress, event: 'send' }],
      header: selected && selected.id === address.id ? textSelected : '',
    } as Card;
  }

  get cards$(): Observable<CardWithAddress[]> {
    return combineLatest([
      this.userAddressService.getAddresses(),
      this.selectedAddress$,
      this.translation.translate('checkoutAddress.shipToThisAddress'),
      this.translation.translate('addressCard.selected'),
    ]).pipe(
      tap(([addresses, selected]) => {
        this.selectDefaultAddress(addresses, selected);
      }),
      map(([addresses, selected, textShipTo, textSelected]) =>
        (<any>addresses).map((address: Address) => ({
          address,
          card: this.getCardContent(
            address,
            selected,
            textShipTo,
            textSelected
          ),
        }))
      )
    );
  }

  showAddresses(showAddresses: boolean, maxAddress: number) {
    if (showAddresses && maxAddress > 3) {
      this.customCheckoutStepsService.setMaxAddresses(maxAddress);
      this.customCheckoutStepsService.setShowAddresses(showAddresses);
    } else {
      this.customCheckoutStepsService.setMaxAddresses(3);
      this.customCheckoutStepsService.setShowAddresses(showAddresses);
    }
  }

  SelectAddress(address: Address): void {
    if (this.isPromeclub && !!this.selectedAddress?.country) {
      this.customCheckoutStepsService.setIsAddressSelected(false);
      super.selectAddress(address);
      setTimeout(() => {
        this.customCheckoutStepsService.setIsAddressSelected(true);
      }, 3200);
      return;
    }
    super.selectAddress(address);
    this.customCheckoutStepsService.setIsAddressSelected(true);
  }

  selectDefaultAddress(addresses: Address[], selected: Address | undefined) {
    if (
      !this.doneAutoSelect &&
      addresses &&
      addresses.length &&
      (!selected || Object.keys(selected).length === 0)
    ) {
      if (this.isAccountPayment) {
        if (addresses.length === 1) {
          this.selectAddress(addresses[0]);
        }
      } else {
        selected = addresses.find((address) => address.defaultAddress);
        if (selected) {
          this.selectAddress(selected);
        }
      }

      this.doneAutoSelect = true;
    }
  }

  ngOnInit(): void {
    this.userAddressService.loadAddresses();
    if (!this.isPromeclub) {
      this.subscriptions.add(
        this.selectedAddress$.pipe(take(1)).subscribe((address) => {
          if (address?.id) {
            this.customCheckoutStepsService.setIsAddressSelected(true);
          }
        })
      );
    }
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
