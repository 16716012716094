import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import {
  FormErrorsModule,
  IconModule,
  MediaModule,
  ModalModule,
} from '@spartacus/storefront';
import { CustomAccountCommentModule } from 'src/app/cms-components/custom/components/custom-account-comment/custom-account-comment.module';
import { CustomFilterModule } from 'src/app/cms-components/custom/components/custom-filter/custom-filter.module';
import { CustomModalGenericModule } from 'src/app/cms-components/custom/components/custom-modal/custom-modal-generic.module';
import { CustomOrderOverviewModule } from 'src/app/cms-components/custom/components/custom-order-overview/custom-order-overview.module';
import { CustomPaginationModule } from 'src/app/cms-components/custom/components/custom-pagination/custom-pagination.module';
import { CustomTitleMyAccountModule } from 'src/app/cms-components/custom/components/custom-title-my-account/custom-title-my-account.module';
import { CustomItemCounterModule } from 'src/app/cms-components/shared/components/custom-item-counter/custom-item-counter.module';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomSafeHtmlModule } from 'src/app/pipes/custom-safe-html/custom-safe-html.module';
import { CustomOrderDetailActionsModule } from '../custom-order-details/custom-order-detail-actions/custom-order-detail-actions.module';
import { CustomOrderDetailShippingModule } from '../custom-order-details/custom-order-detail-shipping/custom-order-detail-shipping.module';
import { CustomReturnOrderActionsCancelComponent } from './custom-return-order-actions-cancel/custom-return-order-actions-cancel.component';
import { CustomReturnOrderActionsComponent } from './custom-return-order-actions/custom-return-order-actions.component';
import { CustomReturnOrderCancelDialogComponent } from './custom-return-order-cancel-dialog/custom-return-order-cancel-dialog.component';
import { CustomReturnOrderConfirmDialogComponent } from './custom-return-order-confirm-dialog/custom-return-order-confirm-dialog.component';
import { CustomReturnOrderDetailComponent } from './custom-return-order-detail/custom-return-order-detail.component';
import { CustomReturnOrderHeaderDetailComponent } from './custom-return-order-header-detail/custom-return-order-header-detail.component';
import { CustomReturnOrderComponent } from './custom-return-order.component';
import { CustomReturnProductItemComponent } from './custom-return-product-item/custom-return-product-item.component';
import { CustomOrderReturnGuard } from './guards/custom-order-return.guard';

@NgModule({
  declarations: [
    CustomReturnOrderComponent,
    CustomReturnProductItemComponent,
    CustomReturnOrderActionsComponent,
    CustomReturnOrderHeaderDetailComponent,
    CustomReturnOrderDetailComponent,
    CustomReturnOrderConfirmDialogComponent,
    CustomReturnOrderActionsCancelComponent,
    CustomReturnOrderCancelDialogComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    UrlModule,
    FormErrorsModule,
    ReactiveFormsModule,
    FormsModule,
    MediaModule,
    IconModule,
    ModalModule,
    CustomSafeHtmlModule,
    CustomOrderDetailShippingModule,
    CustomOrderDetailActionsModule,
    CustomTitleMyAccountModule,
    CustomOrderOverviewModule,
    CustomCurrencyModule,
    CustomItemCounterModule,
    NgSelectModule,
    CustomAccountCommentModule,
    CustomPaginationModule,
    CustomFilterModule,
    CustomModalGenericModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ReturnRequestOrderComponent: {
          component: CustomReturnOrderComponent,
          guards: [AuthGuard, CustomOrderReturnGuard],
        },
      },
    }),
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ReturnDetailOrderComponent: {
          component: CustomReturnOrderDetailComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ],
  exports: [
    CustomReturnOrderComponent,
    CustomReturnProductItemComponent,
    CustomReturnOrderActionsComponent,
    CustomReturnOrderHeaderDetailComponent,
    CustomReturnOrderDetailComponent,
    CustomReturnOrderConfirmDialogComponent,
    CustomReturnOrderActionsCancelComponent,
    CustomReturnOrderCancelDialogComponent,
  ],
})
export class CustomReturnOrderModule {}
