import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule, DeferLoadingStrategy } from '@spartacus/core';
import { GenericLinkModule, IconModule } from '@spartacus/storefront';
import { SwiperModule } from 'swiper/angular';
import { CustomSafeHtmlModule } from '../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomBannerCarouselComponent } from './custom-banner-carousel.component';

@NgModule({
  declarations: [CustomBannerCarouselComponent],
  imports: [
    CommonModule,
    SwiperModule,
    GenericLinkModule,
    IconModule,
    CustomSafeHtmlModule,
    ConfigModule.withConfig({
      cmsComponents: {
        RotatingImagesComponent: {
          component: CustomBannerCarouselComponent,
          deferLoading: DeferLoadingStrategy.INSTANT,
        },
      },
    }),
  ],
})
export class CustomBannerCarouselModule {}
