<ng-container *ngIf="{ value: isInWishlist | async } as data">
  <ng-container *ngIf="!isMobilePdp">
    <cx-icon
      *ngIf="data.value && isInPromeClub"
      type="HEART"
      class="icon-filled"
      (click)="heartActionEnabled ? deleteWishlist(product.code) : false"
    >
    </cx-icon>
    <cx-icon
      *ngIf="!data.value && isInPromeClub"
      type="EMPTY_HEART"
      class="icon-not-filled"
      (click)="heartActionEnabled ? postWishlist(product.code) : false"
    >
    </cx-icon>
  </ng-container>
  <a
    *ngIf="isMobilePdp"
    class="favs-mobile-pdp"
    [ngClass]="{ promeclub: isInPromeClub }"
    (click)="heartActionEnabled ? checkCondition(data.value) : false"
  >
    <cx-icon
      *ngIf="data.value && isInPromeClub"
      type="HEART"
      class="icon-filled"
    >
    </cx-icon>
    <cx-icon
      *ngIf="!data.value && isInPromeClub"
      type="EMPTY_HEART"
      class="icon-not-filled"
    >
    </cx-icon>
    <span *ngIf="!data.value && isInPromeClub">Agregar a favoritos</span>
    <span *ngIf="data.value && isInPromeClub">Quitar de favoritos</span>
  </a>
</ng-container>
