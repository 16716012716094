import { Component, Input } from '@angular/core';
import { CmsComponent, CmsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-information',
  templateUrl: './custom-information.component.html',
  styleUrls: ['./custom-information.component.scss'],
})
export class CustomInformationComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() addClass: string;
  componentData$: Observable<CmsComponent> = this.cmsService.getComponentData(
    'promesaInfoCashPaymentComponent'
  );
  constructor(private cmsService: CmsService) {}
}
