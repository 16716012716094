<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entriesCart?.length > 0; else entriesMiniCart">
    <ng-container *ngFor="let entry of entriesCart; let i = index">
      <app-product-summary id="entries-checkout" [entry]="entry" [productPosition]="i" [clickedFrom]="clickedFrom" [enableRedirect]="true"
        [locale]="locale" [isInPromeclub]="isInPromeclub" (onRemoveItem)="removeItem($event)" [cart]="cart"
        [isInCheckout]="isInCheckout"></app-product-summary>
    </ng-container>
  </ng-container>
  <ng-template #entriesMiniCart>
    <ng-container *ngIf="entries$ | async as entries">
      <ng-container *ngFor="let entry of entries; let i = index">
        <app-product-summary [entry]="entry" [productPosition]="i" [clickedFrom]="clickedFrom" [enableRedirect]="true"
          [locale]="locale" [isInPromeclub]="isInPromeclub" (onRemoveItem)="removeItem($event)" [cart]="cart"
          [isInCheckout]="isInCheckout"></app-product-summary>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-container>
<ng-container *ngIf="isInCheckout && (isMobile$ | async) && (entriesQuantity > cantIncrDesc); else listProducts">
  <ng-container *ngIf="entriesQuantity > cantMaxItems; else showLess">
    <div class="cart-summary__info_showMore">
      <a (click)="onShowAll()">
        {{ "productList.showMoreProducts" | cxTranslate }}
      </a>
    </div>
  </ng-container>
  <ng-template #showLess>
    <div class="cart-summary__info_showMore">
      <a (click)="onShowLess()">
        {{ "productList.showLessProducts" | cxTranslate }}
      </a>
    </div>
  </ng-template>
</ng-container>
<ng-template #listProducts>
  <ng-container *ngIf="entriesQuantity > cantMaxItems">
    <div class="cart-summary__info_showMore">
      <button (click)="onShowMore()">
        <span>{{ "common.showMore" | cxTranslate }}</span>
        <cx-icon class="fas fa-chevron-down"></cx-icon>
      </button>
    </div>
  </ng-container>
</ng-template>
