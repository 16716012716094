import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomModalGenericModule } from '../custom-modal/custom-modal-generic.module';
import { CustomPromeclubCheckoutAlertDialogComponent } from './custom-promeclub-checkout-alert-dialog.component';



@NgModule({
  declarations: [
    CustomPromeclubCheckoutAlertDialogComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    RouterModule,
    UrlModule,
    CustomModalGenericModule
  ]
})
export class CustomPromeclubCheckoutAlertModule { }
