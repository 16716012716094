import { AsmMainUiComponent } from '@spartacus/asm/components';
import { LayoutConfig } from '@spartacus/storefront';

export const customDefaultAsmLayoutConfig: LayoutConfig = {
  // launch: {
  //   ASM: {
  //     outlet: 'TopHeaderSlot',
  //     component: AsmMainUiComponent,
  //   },
  // },
};
