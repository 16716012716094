import { Component, Input, OnInit } from '@angular/core';
import { AuthService, Product } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subject, Subscription, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';
import { CustomWishlistService } from 'src/app/services/custom-wishlist.service';

@Component({
  selector: 'app-custom-wishlist',
  templateUrl: './custom-wishlist.component.html',
  styleUrls: ['./custom-wishlist.component.scss'],
})
export class CustomWishlistComponent implements OnInit {
  @Input() product: Product;
  @Input() isUserLoggedIn: boolean;
  @Input() userId: string;
  @Input() isMobilePdp: boolean;
  wishListClose: Subscription = new Subscription();
  isInPromeClub: boolean = this.navigationService.isInPromeClub();
  isInWishlist = new Subject<boolean>();
  heartActionEnabled: boolean = false;
  constructor(
    private auth: AuthService,
    private service: CustomWishlistService,
    protected userAccountFacade: UserAccountFacade,
    protected navigationService: CustomNavigationService
  ) {
  }
  ngOnInit() {
    if(this.isMobilePdp){
      setTimeout(() => {
        this.getWlData()
      }, 1000);
    } else{
      this.getWlData()
    }

  }

  getWlData() {
    if (this.isInPromeClub && this.isUserLoggedIn) {
      this.wishListClose.add(
        this.service
          .productCheckWishList(this.userId, this.product.code!)
          .pipe(
            catchError((error: any) => {
              return of(false);
            })
          )
          .subscribe((response) => {
            if (response === null || response == true) {
              this.isInWishlist.next(true);
              this.heartActionEnabled = true;
            } else{
              this.isInWishlist.next(false);
              this.heartActionEnabled = true;
            }
          })
      );
    }
  }

  postWishlist(code: any) {
    this.heartActionEnabled = false;
    if (this.isUserLoggedIn) {
      this.service.postWishlist(this.userId, code).subscribe((p) => {
        this.isInWishlist.next(true);
        this.heartActionEnabled = true;
      });
    } else {
      alert('Necesitas loguearte');
      this.heartActionEnabled = true;
    }
  }

  deleteWishlist(code: any) {
    this.heartActionEnabled = false;
    if (this.isUserLoggedIn) {
      this.service.deleteWishList(this.userId, code).subscribe((p) => {
        this.isInWishlist.next(false);
        this.heartActionEnabled = true;
      });
    } else {
      alert('Necesitas loguearte');
      this.heartActionEnabled = true;
    }
  }


  checkCondition(isInWl: boolean) {
    if(isInWl){
      this.deleteWishlist(this.product.code)
    } else {
      this.postWishlist(this.product.code)
    }
  }

  ngOnDestroy(): void {
    this.wishListClose.unsubscribe();
  }
}
