import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-title-my-account',
  templateUrl: './custom-title-my-account.component.html',
  styleUrls: ['./custom-title-my-account.component.scss'],
})
export class CustomTitleMyAccountComponent {
  @Input() title: string;
  @Input() routerlink: any;
  @Input() isBackActive: boolean;
  @Input() params: any;
  @Input() lessMargin: boolean = false;
  @Output() onBackAction = new EventEmitter<any>();
  constructor() {}

  onBack() {
    this.onBackAction.emit();
  }
}
