import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomResetPasswordComponent } from './custom-reset-password.component';
import {
  CmsConfig,
  ConfigModule,
  GlobalMessageService,
  I18nModule,
  NotAuthGuard,
  RoutingService,
} from '@spartacus/core';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FormErrorsModule, IconModule } from '@spartacus/storefront';
import { CustomRegisterCustomerModule } from '../custom-register/custom-register-customer/custom-register-customer.module';
import { CustomResetPasswordService } from './custom-reset-password.service';
import { CustomLoadingSpinnerModule } from '../../custom/components/custom-loading-spinner/custom-loading-spinner.module';

@NgModule({
  declarations: [CustomResetPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    I18nModule,
    FormErrorsModule,
    IconModule,
    CustomRegisterCustomerModule,
    CustomLoadingSpinnerModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ResetPasswordComponent: {
          component: CustomResetPasswordComponent,
          guards: [NotAuthGuard],
          providers: [
            {
              provide: CustomResetPasswordService,
              useClass: CustomResetPasswordService,
              deps: [UserPasswordFacade, RoutingService, GlobalMessageService],
            },
          ],
        },
      },
    }),
  ],
})
export class CustomResetPasswordModule {}
