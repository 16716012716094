import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomHamburgerMenuModule } from './custom-hamburger-menu/custom-hamburger-menu.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CustomHamburgerMenuModule,
  ]
})
export class HeaderModule { }
