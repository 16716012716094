import {
  ChangeDetectionStrategy, Component, OnDestroy, OnInit,
  ViewContainerRef, ViewEncapsulation
} from '@angular/core';
import { SavedCartListComponent } from '@spartacus/cart/saved-cart/components';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import { Cart, PaginationModel, RoutingService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomOrderPaginationService } from '../../services/custom-order-pagination.service';

@Component({
  selector: 'app-custom-shopping-list',
  templateUrl: './custom-shopping-list.component.html',
  styleUrls: ['./custom-shopping-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomShoppingListComponent
  extends SavedCartListComponent
  implements OnInit, OnDestroy
{
  shoppingList: [] = [];
  pageSize: number = 10000;
  selectedPage: number = 1;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  cartsSaved: any[] = [];
  cartsSavedShow: any[];

  quantities: any[] = [
    { id: 25, name: 25, disabled: true },
    { id: 50, name: 50, disabled: true },
    { id: 10000, name: 'Todos', disabled: true },
  ];

  pagination: PaginationModel = {
    currentPage: 0,
    pageSize: this.pageSize,
    sort: '',
    totalPages: 1,
    totalResults: 0,
  };

  savedCarts$: Observable<Cart[]> = this.savedCartService.getList().pipe(
    tap((carts) => {
      this.carts(carts);
    }),
    tap((carts) => {
      this.selectedPage = this.pagination.currentPage!;
      this.pagination.totalResults = carts?.length;
      this.pagination.totalPages =
        Math.round(carts?.length! / this.pagination.pageSize! + 0.4) > 1
          ? Math.round(carts?.length! / this.pagination.pageSize! + 0.4)
          : 1;
    }),
    map((lists) =>
      lists.sort((a: Cart, b: Cart) => {
        let date1: number = a.saveTime
          ? new Date(a.saveTime).getTime()
          : new Date().getTime();
        let date2: number = b.saveTime
          ? new Date(b.saveTime).getTime()
          : new Date().getTime();
        return date2 - date1;
      })
    )
  );

  constructor(
    protected routing: RoutingService,
    protected savedCartService: SavedCartFacade,
    protected vcr: ViewContainerRef,
    protected launchDialogService: LaunchDialogService,
    private customBreakpointService: CustomBreakpointService,
    private customOrderPaginationService: CustomOrderPaginationService
  ) {
    super(routing, savedCartService, vcr, launchDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  carts(carts?: any[], value?: number) {
    this.selectedPage = this.pagination.currentPage!;
    carts ? (this.cartsSaved = carts) : this.cartsSaved;
    if (this.pagination.currentPage == 0) {
      this.cartsSavedShow = this.cartsSaved.slice(0, this.pageSize);
    } else {
      this.cartsSavedShow = this.cartsSaved.slice(
        value,
        value! + this.pageSize
      );
    }
    this.pagination.totalResults = carts?.length;
    this.pagination.pageSize = this.pageSize;
    this.pagination.totalPages =
      Math.round(carts?.length! / this.pagination.pageSize! + 0.4) > 1
        ? Math.round(carts?.length! / this.pagination.pageSize! + 0.4)
        : 1;
    this.customOrderPaginationService.onPageChange(this.pagination);
  }

  filterQuantitySelected(filter: any) {
    this.pageSize = filter;
    this.pagination.currentPage = 0;
    this.pageChange(0);
    this.carts(this.cartsSaved, filter);
  }

  customPageChange(event: any) {
    this.pageChange(event);
    this.selectedPage < this.pagination.pageSize!
      ? (this.selectedPage = this.pagination.currentPage!)
      : (this.selectedPage = this.pagination.currentPage!);
    this.carts(this.cartsSaved, this.pageSize * event);
  }

  pageChange(page: number) {
    this.pagination.currentPage = page;
    this.customOrderPaginationService.onPageChange(this.pagination);
  }

  goToSavedCartDetails(cart: Cart): void {
    this.routing.go({
      cxRoute: 'savedCartsDetails',
      params: { savedCartId: cart?.code },
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
