<ng-container *ngIf="{isMobile: isMobile$ | async} as data">
  <div class="cx-product-view" *ngIf="!isInPromeClub">
    <button
      class="btn cx-product-layout list-view"
      [ngClass]="buttonClass"
      (click)="customChangeMode(viewModesChange.List)"
      tabindex="0"
      attr.aria-label="{{
        iconTypes.LIST ? ('productView.listView' | cxTranslate) : null
      }}"
      [class.is-active]="selectedMode === viewModesChange.List ? true : false"
    >
      <cx-icon class="fas fa-align-justify" [class.is-active]="selectedMode === viewModesChange.List ? true : false"></cx-icon>
      <ng-container *ngIf="!data?.isMobile">
        <label>
          {{ "productView.listView" | cxTranslate }}
        </label>
      </ng-container>

    </button>

    <button
      class="btn cx-product-layout grid-view"
      [ngClass]="buttonClass"
      (click)="customChangeMode(viewModesChange.Grid)"
      tabindex="0"
      attr.aria-label="{{
        iconTypes.GRID ? ('productView.gridView' | cxTranslate) : null
      }}"
      [class.is-active]="selectedMode === viewModesChange.Grid ? true : false"
    >
      <cx-icon class="fas fa-th-large" [ngClass]="data?.isMobile ? 'fa-th-large' : 'fa-th'" [class.is-active]="selectedMode === viewModesChange.Grid ? true : false"></cx-icon>
      <ng-container *ngIf="!data?.isMobile">
        <label>
          {{ "productView.gridView" | cxTranslate }}
        </label>
      </ng-container>
    </button>
  </div>

</ng-container>

