import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomDialogCartService {
  public closeDialog$: Subject<any> = new Subject<any>();

  onCloseDialogToCart(event: UIEvent) {
    this.closeDialog$.next(event);
  }
}
