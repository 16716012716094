import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SortModel } from '@spartacus/core';

@Component({
  selector: 'app-custom-sorting',
  templateUrl: './custom-sorting.component.html',
  styleUrls: ['./custom-sorting.component.scss'],
})
export class CustomSortingComponent implements OnInit {
  @Input()
  sortOptions: SortModel[];
  @Input()
  selectedOption: string;
  @Input()
  placeholder: string;
  @Input()
  sortLabels: { [code: string]: string };

  @Output()
  sortListEvent: EventEmitter<string>;

  constructor() {
    this.sortListEvent = new EventEmitter<string>();
  }

  sortList(sortCode: string): void {
    this.sortListEvent.emit(sortCode);
  }

  get selectedLabel() {
    return (
      this.sortOptions?.find((sort) => sort.code === this.selectedOption)
        ?.name ?? this.sortLabels?.[this.selectedOption]
    );
  }

  ngOnInit(): void {}
}
