<div class="close">
  <cx-icon class="fas fa-times" (click)="closeModal('close')"></cx-icon>
</div>
<div class="pin">
  <div class="pin__info">
    <p class="pin__info-paragraph">
      {{ "customRegister.pinText" | cxTranslate }}
    </p>
    <div class="pin__info-action">
      <ng-container
        *ngIf="{
          number: countDown$ | async,
          isMobile: isMobile$ | async
        } as seconds"
      >
        <span
          class="pin__info-action-text"
          (click)="requestNewPin()"
          [ngClass]="{ active: seconds.number === 1 }"
        >
          {{ "customRegister.pinNewRequest" | cxTranslate }}
        </span>
        <cx-icon
          class="fas fa-info-circle"
          *ngIf="seconds.number > 1 && seconds.isMobile"
          ngbTooltip="{{
            'customRegister.newPinAvailable'
              | cxTranslate : { seconds: seconds?.number }
          }}"
          placement="bottom"
          triggers="click"
          tooltipClass="tooltip-color"
        ></cx-icon>
        <cx-icon
          class="fas fa-info-circle"
          *ngIf="seconds.number > 1 && !seconds.isMobile"
          ngbTooltip="{{
            'customRegister.newPinAvailable'
              | cxTranslate : { seconds: seconds?.number }
          }}"
          placement="bottom"
          tooltipClass="tooltip-color"
        ></cx-icon>
      </ng-container>
    </div>
  </div>
  <form class="pin__form" (ngSubmit)="pinSubmit()" [formGroup]="pinForm">
    <h3 class="pin__form-title">
      {{ "customRegister.enterPinCode" | cxTranslate }}
    </h3>
    <ng-container
      *ngIf="{
        pinStatus: pinStatus$ | async,
        isMobile: isMobile$ | async
      } as data"
    >
      <div
        class="pin__form-inputs"
        [ngClass]="{ error: data.pinStatus === false }"
      >
        <input
          [type]="data.isMobile ? 'number' : 'text'"
          [inputMode]="data.isMobile ? 'numeric' : 'text'"
          class="form-control"
          formControlName="characterOne"
          maxlength="1"
          #characterOne
          (keyup)="focusNextInput('one', $event)"
          (keydown)="keydownInput('one', $event)"
        />
        <input
          [type]="data.isMobile ? 'number' : 'text'"
          [inputMode]="data.isMobile ? 'numeric' : 'text'"
          class="form-control"
          formControlName="characterTwo"
          maxlength="1"
          #characterTwo
          (keyup)="focusNextInput('two', $event)"
          (keydown)="keydownInput('two', $event)"
        />
        <input
          [type]="data.isMobile ? 'number' : 'text'"
          [inputMode]="data.isMobile ? 'numeric' : 'text'"
          class="form-control"
          formControlName="characterThree"
          maxlength="1"
          #characterThree
          (keyup)="focusNextInput('three', $event)"
          (keydown)="keydownInput('three', $event)"
        />
        <input
          [type]="data.isMobile ? 'number' : 'text'"
          [inputMode]="data.isMobile ? 'numeric' : 'text'"
          class="form-control"
          formControlName="characterFour"
          maxlength="1"
          #characterFour
          (keyup)="focusNextInput('four', $event)"
          (keydown)="keydownInput('four', $event)"
        />
        <input
          [type]="data.isMobile ? 'number' : 'text'"
          [inputMode]="data.isMobile ? 'numeric' : 'text'"
          class="form-control"
          formControlName="characterFive"
          maxlength="1"
          #characterFive
          (keyup)="focusNextInput('five', $event)"
          (keydown)="keydownInput('five', $event)"
        />
        <input
          [type]="data.isMobile ? 'number' : 'text'"
          [inputMode]="data.isMobile ? 'numeric' : 'text'"
          class="form-control"
          formControlName="characterSix"
          maxlength="1"
          #characterSix
          (keyup)="focusNextInput('six', $event)"
          (keydown)="keydownInput('six', $event)"
        />
      </div>
      <div class="pin__form-error">
        <p *ngIf="data.pinStatus === false">
          {{ "customRegister.pinError" | cxTranslate }}
        </p>
      </div>
    </ng-container>
    <div class="pin__form-action">
      <button
        #submitButton
        class="pin__form-action-button"
        type="submit"
        [disabled]="pinForm.invalid || (disableButton$ | async)"
        (keyup)="focusNextInput('button', $event)"
        (keydown)="keydownInput('button', $event)"
      >
        {{ "customRegister.verify" | cxTranslate }}
      </button>
    </div>
  </form>
</div>
