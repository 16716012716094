export const checkout = {
  checkoutAddress: {
    shippingAddress: 'Dirección de envío',
    selectYourShippingAddress: 'Seleccione su dirección de envío',
    defaultShippingAddress: 'Dirección de envío por defecto',
    addNewAddress: 'Agregar Nueva Dirección',
    shipToThisAddress: 'Enviar a esta dirección',
    showMore: 'Ver todas',
    showLess: 'Ver menos',
  },
  checkoutOrderConfirmation: {
    confirmationOfOrder: 'Pedido número:',
    thankYou: 'Muchas gracias por tu compra',
    thankYouPromeclub: 'Muchas gracias por tu canje',
    invoiceHasBeenSentByEmail:
      '<p>En los próximos minutos recibirás un email a <b>{{email}}</b> con la confirmación de tu pedido.</p>',
    checkOrder: '<p>Puedes consultar tu pedido </p>',
    mesaggeCounted:
      '<p>Tu pedido estará reservado por un periodo de 72 horas laborales hasta que se efectúe el pago y registres el comprobante en la sección mi cuenta.</p>',
    banksAvailables: 'Puedes efectuar el pago a los siguientes bancos:',
    printReceipt: 'Imprimir comprobante',
    orderItems: 'Pedir artículos',
    orderPlacedSuccessfully: 'Pedido realizado correctamente',
    createAccount: '¿Desea crear una cuenta?',
    createAccountForNext:
      'Cree una cuenta para <{{email}}> para un pago más rápido en su próxima visita.',
    withoutStock: 'Stock no disponible',
    genericErrorTitle: 'Se ha producido un error',
    promeclubAlertMessage: 'Lo sentimos, para poder concluir el pedido es necesario modificar los productos del carrito',
    reviewCart: 'Revisar carrito',
  },
  checkoutReview: {
    review: 'Resumen y confirmación',
    orderItems: 'Pedir artículos',
    autoReplenishOrder: 'Pedido de reabastecimiento automático',
    confirmThatRead: 'Confirmo que he leído y acepto los',
    placeOrder: 'Confirmar compra',
    customPlaceOrder: 'Confirmar pedido',
    scheduleReplenishmentOrder: 'Programar pedido de reabastecimiento',
    orderType_PLACE_ORDER: 'Niguno (por única vez)',
    orderType_SCHEDULE_REPLENISHMENT_ORDER: 'Pedido de reabastecimiento',
    termsAndConditions: 'Acepto términos y condiciones',
    editShippingAddress: 'Editar método de envío',
    editShippingAddressDetails:
      'Edita los detalles de la dirección de envío, abre la página Dirección de envío',
    editPaymentDetails:
      'Editar detalles de pago, abre la página Detalles de pago',
    editPaymentType: 'Editar método de pago, abre la página Método de pago',
    editDeliveryMode: 'Editar modo de entrega, abre la página Modo de entrega',
    poNumber: 'Número de pedido de compra',
    every: 'Cada',
    startOn: 'Empieza el',
    dayOfMonth: 'El dia',
    repeatOnDays: 'Repetir los siguientes días',
    recurrencePeriodType_DAILY: 'Dia(s)',
    recurrencePeriodType_WEEKLY: 'Semana(s)',
    recurrencePeriodType_MONTHLY: 'Mes',
  },
  checkoutShipping: {
    shippingMethod: 'Tipo de envío',
    standardDelivery: 'Envío Estándar',
    premiumDelivery: 'Envío Premium',
    deliveryMethod: 'Método de entrega',
    centerDistributionAddress: 'Dirección de retiro',
    deliveryOn: 'Entrega el ',
    numberStep: 'Paso {{number}} de 2'
  },
  checkout: {
    backToCart: 'Volver al carrito',
    invalid: {
      accountType: 'Tu cuenta no admite proceder al pago desde aquí.',
    },
    cashTitle: 'Contado',
    cashPayment: 'Pago al contado',
    cashMessage:
      'Tu pedido estará reservado por un periodo de 72 horas laborables hasta que se efectúe el pago y registres el comprobante en la sección mi cuenta.',
    goBackToStepTwo: 'Regresar al paso 2',
    billingInformation: 'Información de facturación',
  },
  checkoutPO: {
    noPoNumber: 'Ninguno',
    poNumber: 'Número de orden de compra (opcional)',
    costCenter: 'Centro de Costo',
    placeholder: 'Ingrese el número orden de pedido',
    availableLabel:
      'Las direcciones de envío disponibles para usted dependen de la unidad del centro de costos',
  },
  checkoutProgress: {
    methodOfPayment: 'Método de pago',
    exchangeMethod: 'Método de canje',
    shippingAddress: 'Dirección de envío',
    deliveryMode: 'Método de entrega',
    paymentDetails: 'Método de pago',
    reviewOrder: 'Confirmación',
    myAdress: 'Envío a mi dirección',
    centerDistribution: 'Retiro en Centro de Distribución',
  },
};
