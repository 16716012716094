import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSavedCartDetailsOverviewComponent } from './custom-saved-cart-details-overview.component';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  I18nModule,
} from '@spartacus/core';
import { CustomSavedCartDetailsActionModule } from '../custom-saved-cart-details-action/custom-saved-cart-details-action.module';
import { IconModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { UrlModule } from '@spartacus/core';
import { CustomTitleMyAccountModule } from 'src/app/cms-components/custom/components/custom-title-my-account/custom-title-my-account.module';

@NgModule({
  declarations: [CustomSavedCartDetailsOverviewComponent],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    RouterModule,
    UrlModule,
    CustomSavedCartDetailsActionModule,
    CustomTitleMyAccountModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        SavedCartDetailsOverviewComponent: {
          component: CustomSavedCartDetailsOverviewComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ],
})
export class CustomSavedCartDetailsOverviewModule {}
