<ng-container *ngIf="order">
  <div class="custom-order-details">
    <div class="custom-order-details__items">

        <ng-container >
          <div class="cx-list">
            <div class="cx-list-title">
              {{ "orderDetails.myProducts" | cxTranslate }}
            </div>

            <div class="cx-list-item">
              <div *ngIf="orderDetailsShow.length > 0">
                <app-custom-promeclub-order-entry-item
                  *ngFor="let item of orderDetailsShow"
                  [item]="item"
                >
                </app-custom-promeclub-order-entry-item>
              </div>
            </div>
          </div>
        </ng-container>
        <div *ngIf="pagination.totalPages > 1" class="cx-pagination-order-details">
          <app-custom-pagination
            [pagination]="pagination"
            (viewPageEvent)="customPageChange($event)"
            [pageSelected]="selectedPage"
            [isEditable]="true"
          ></app-custom-pagination>
        </div>

    </div>
    <div class="custom-order-details__totals">
      <app-custom-promeclub-order-details-total [order]="order"></app-custom-promeclub-order-details-total>
    </div>
  </div>
</ng-container>
