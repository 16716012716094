import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { AuthService, Product } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';
import { CustomGtmEcommerceDatalayerService } from 'src/app/common/services/custom-gtm-ecommerce-datalayer.service';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';
import { AutomotiveFilterService } from '../../../../services/automotive-filter.service';
import { CustomScrollService } from 'src/app/services/custom-scroll.service';

@Component({
  selector: 'app-custom-product-card',
  templateUrl: './custom-product-card.component.html',
  styleUrls: ['./custom-product-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomProductCardComponent {
  @Input() wideLabel: boolean;
  @Input() product: Product;
  @Input() productPosition: number;
  @Input() fromContentPage: boolean;
  @Input() template: string;
  @Input() clickedFrom: string;
  @Input() isRebate: boolean;
  @Input() isPdpMobile: boolean;
  @Input() haveAutomotiveLabel: boolean = false;
  userId: string;
  isUserLoggedIn$: Observable<boolean> = this.auth.isUserLoggedIn();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  userId$: Observable<string> = this.userAccountFacade
    .get()
    .pipe(map((user) => user?.uid!));
  isPromeClubActive: boolean = this.customNavigationService.isInPromeClub();
  @Input() resetScrollPosition: boolean = false;

  constructor(
    private customGtmDatalayerService: CustomGtmEcommerceDatalayerService,
    private customNavigationService: CustomNavigationService,
    private auth: AuthService,
    private customBreakpointService: CustomBreakpointService,
    protected userAccountFacade: UserAccountFacade,
    private automotiveFilterService: AutomotiveFilterService,
    private scrollService: CustomScrollService
  ) {}

  productClickEvent(product: Product) {
    this.automotiveFilterService.setAutomotiveFilterPdp(
      this.haveAutomotiveLabel
    );
    this.customGtmDatalayerService.productClickEvent(
      product,
      this.productPosition,
      this.clickedFrom
    );
    if (this.resetScrollPosition) {
      this.scrollService.resetScrollPosition();
    }
  }
}
