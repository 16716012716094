import {
  Component,
  Input, OnChanges, OnInit,
  SimpleChanges
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  ActiveCartService, CmsService, MultiCartService,
  OrderEntry, SelectiveCartService,
  UserIdService
} from '@spartacus/core';
import { CartItemListComponent } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map, pluck, startWith, tap } from 'rxjs/operators';
import { CustomGtmEcommerceDatalayerService } from 'src/app/common/services/custom-gtm-ecommerce-datalayer.service';
import { CustomBaseSiteCheckerService } from '../../../../services/custom-base-site-checker.service';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';
import { CustomOrderEntry } from '../../model/custom-order-entry';
import { CustomReloadCartService } from '../../services/custom-reload-cart.service';

@Component({
  selector: 'app-custom-cart-item-list',
  templateUrl: './custom-cart-item-list.component.html',
  styleUrls: ['./custom-cart-item-list.component.scss'],
})
export class CustomCartItemListComponent
  extends CartItemListComponent
  implements OnInit, OnChanges {
  updatedQuantity: boolean = false;
  isMobileValue: boolean = false;
  itemsNew: CustomOrderEntry[] = [];
  cierre: boolean[] = [];
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  @Input() cart: any;
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  pageId: string;
  clickedFrom$: Observable<string | undefined> = this.cmsService
    .getCurrentPage()
    .pipe(pluck('pageId'), tap(pageId => this.pageId = pageId!));
  constructor(
    private customReloadCartService: CustomReloadCartService,
    protected activeCartService: ActiveCartService,
    protected selectiveCartService: SelectiveCartService,
    protected userIdService: UserIdService,
    protected multiCartService: MultiCartService,
    private customBreakpointService: CustomBreakpointService,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    private customGtmService: CustomGtmEcommerceDatalayerService,
    private cmsService: CmsService
  ) {
    super(
      activeCartService,
      selectiveCartService,
      userIdService,
      multiCartService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.updateItemsNew();
    if (!this.isPromeclub) {
      this.customReloadCartService.reloadCart(this.cartId);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.customGtmService.checkRemoveOrAddEvent(
      changes?.items?.previousValue,
      changes?.items?.currentValue,
      this.pageId
    );
    if (!!changes?.items?.previousValue && !!changes?.items?.currentValue) {
      this.updateItemsNew();
      if (
        changes.items.previousValue.length > changes?.items?.currentValue.length
      ) {
        !this.isPromeclub ? this.activeCartService.reloadActiveCart() : null;
      }
    }
  }

  updateItemsNew() {
    this.itemsNew = this.items.map((itemQualified: CustomOrderEntry, i) => {
      this.cierre[i] = true;
      if (itemQualified.qualifiedEntryNumber == null) {
        if (
          this.items.filter(
            (e: CustomOrderEntry) =>
              e.qualifiedEntryNumber == itemQualified.entryNumber
          ).length > 0
        ) {
          this.cierre[i] = false;
        }
      } else {
        let maxEntryNumber = this.items
          .filter(
            (e: CustomOrderEntry) =>
              e.qualifiedEntryNumber == itemQualified.qualifiedEntryNumber
          )
          .sort(
            (e1: CustomOrderEntry, e2: CustomOrderEntry) =>
              e2.entryNumber! - e1.entryNumber!
          )[0].entryNumber;
        if (maxEntryNumber != itemQualified.entryNumber) {
          this.cierre[i] = false;
        }
      }

      return itemQualified?.qualifiedEntryNumber
        ? (itemQualified = {
          ...itemQualified,
          nameGiftItem: this.items.find(
            (i) => i.product?.code == itemQualified.qualifiedProductCode
          )?.product?.name,
        })
        : itemQualified;
    });
  }

  getControl(item: OrderEntry): Observable<FormGroup> {
    return this.form.get(this.getControlName(item))!?.valueChanges.pipe(
      // eslint-disable-next-line import/no-deprecated
      startWith(null),
      tap((value) => {
        if (
          (item.updateable &&
            value &&
            !this.readonly &&
            this.updatedQuantity) ||
          (item.updateable && value && !this.readonly && this.isMobileValue)
        ) {
          if (this.selectiveCartService && this.options.isSaveForLater) {
            this.selectiveCartService.updateEntry(
              value.entryNumber,
              value.quantity
            );
          } else if (this.cartId && this.userId) {
            this.multiCartService?.updateEntry(
              this.userId,
              this.cartId,
              value.entryNumber,
              value.quantity
            );
          } else {
            this.activeCartService.updateEntry(
              value.entryNumber,
              value.quantity
            );
          }
        }
        this.updatedQuantity = false;
      }),
      map(() => <FormGroup>this.form.get(this.getControlName(item)))
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
