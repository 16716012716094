import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, ConfigModule, I18nModule, OccProductSearchAdapter } from '@spartacus/core';
import { FormErrorsModule, IconModule } from '@spartacus/storefront';
import { NgpSortModule } from 'ngp-sort-pipe';
import { CustomSafeHtmlModule } from '../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomSearchRefinerComponent } from './custom-search-refiner.component';


@NgModule({
  declarations: [
    CustomSearchRefinerComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormErrorsModule,
    I18nModule,
    IconModule,
    CustomSafeHtmlModule,
    NgSelectModule,
    NgpSortModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromesaSearchRefinerComponent: {
          component: CustomSearchRefinerComponent,
        },
      },
    }),
  ],
  providers: [
    OccProductSearchAdapter
  ]
})
export class CustomSearchRefinerModule { }
