import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomModalModule } from './components/custom-modal/custom-modal.module';
import { CustomItemCounterModule } from './components/custom-item-counter/custom-item-counter.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, CustomModalModule, CustomItemCounterModule],
})
export class CustomSharedModule {}
