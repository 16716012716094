import { Component, Input, OnInit } from '@angular/core';
import { ActiveCartService } from '@spartacus/core';
import { CustomPaymentConditionsService } from '../../../services/custom-payment-conditions.service';
import { Observable, combineLatest, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomUserPointsService } from 'src/app/services/custom-user-points.service';
import { CustomCheckoutPaymentStepService } from '../../../services/custom-checkout-payment-step.service';

@Component({
  selector: 'app-custom-payment-point',
  templateUrl: './custom-payment-point.component.html',
  styleUrls: ['./custom-payment-point.component.scss'],
})
export class CustomPaymentPointComponent implements OnInit {
  @Input() userId: string;
  @Input() customCheckoutPaymentStepService: CustomCheckoutPaymentStepService;
  @Input() isPromeclub: boolean = false;
  @Input() availablePoints: number;
  orderTotal: number;
  available: number;
  balance$: Observable<number | null> = of(null);
  totalAmount$: Observable<number> = this.activeCartService
    .getActive()
    .pipe(map((cart) => cart.totalPrice?.value!));
  availablePoints$: Observable<number> = this.customUserPointsService
    .getUserPoints()
    .pipe(map((userPoints) => userPoints?.pointsData?.availablePoints!));
  subscription: Subscription = new Subscription();
  constructor(
    private activeCartService: ActiveCartService,
    private customPaymentConditionsService: CustomPaymentConditionsService,
    private customUserPointsService: CustomUserPointsService
  ) {}

  ngOnInit(): void {
    this.balance$ = combineLatest([
      this.totalAmount$,
      this.availablePoints$,
    ]).pipe(
      map(([total, available]) => {
        let totalBalance;
        this.orderTotal = total;
        this.available = available;
        totalBalance = available - total;
        if (totalBalance >= 0) {
          this.subscription.add(
            this.customPaymentConditionsService
              .setPromeclubPaymentMode(this.userId)
              .subscribe(() =>
                this.customCheckoutPaymentStepService.setGoToNextStep(true)
              )
          );
        } else {
          this.customCheckoutPaymentStepService.setGoToNextStep(false);
        }
        return totalBalance;
      })
    );
  }
}
