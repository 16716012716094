<div class="quote-overview" *ngIf="quoteForm$ | async as form">
  <h4
    class="quote-overview__title"
    [ngClass]="{ detail: status === QUOTE_STATE.BUYER_OFFER }"
  >
    {{ "accountQuotes.overview.title" | cxTranslate }}
  </h4>
  <div class="quote-overview__header">
    <div class="quote-overview__header__item">
      <p class="quote-overview__header__item-key">
        {{ "accountQuotes.overview.order" | cxTranslate }}
      </p>
      <p class="quote-overview__header__item-value">
        {{ code }}
      </p>
    </div>
    <div class="quote-overview__header__item">
      <p class="quote-overview__header__item-key">
        {{ "accountQuotes.overview.dateUpdate" | cxTranslate }}
      </p>
      <p class="quote-overview__header__item-value">
        {{ date | date : "dd/MM/yyyy" }}
      </p>
    </div>
    <div class="quote-overview__header__item">
      <p class="quote-overview__header__item-key">
        {{ "accountQuotes.overview.proposalName" | cxTranslate }}
      </p>
      <p class="quote-overview__header__item-value">
        {{ form.reference || name }}
      </p>
    </div>
    <div class="quote-overview__header__item">
      <p class="quote-overview__header__item-key">
        {{ "accountQuotes.overview.paymentMethod" | cxTranslate }}
      </p>
      <p
        class="quote-overview__header__item-value"
        *ngIf="form.counted && isInReview"
      >
        {{ "accountQuotes.overview.counted" | cxTranslate }} -
        {{ form.countedMethod.displayName | lowercase }}
      </p>
      <p
        class="quote-overview__header__item-value"
        *ngIf="form.credit && isInReview"
      >
        {{ "accountQuotes.overview.credit" | cxTranslate }} -
        {{ form.creditCondition.name | lowercase }}
      </p>
      <p
        class="quote-overview__header__item-value"
        *ngIf="
          paymentCondition &&
          quote?.paymentMode?.code === 'creditLinePaymentMode' &&
          !isInReview
        "
      >
        {{ "accountQuotes.overview.credit" | cxTranslate }} -
        {{ paymentCondition }}
      </p>
      <p
        class="quote-overview__header__item-value"
        *ngIf="quote?.paymentMode?.code === 'depositPaymentMode' && !isInReview"
      >
        {{ "accountQuotes.overview.counted" | cxTranslate }} -
        {{ quote?.paymentMode?.name | slice : 0 : 8 }}
      </p>
      <p
        class="quote-overview__header__item-value"
        *ngIf="
          quote?.paymentMode?.code === 'transferPaymentMode' && !isInReview
        "
      >
        {{ "accountQuotes.overview.counted" | cxTranslate }} -
        {{ quote?.paymentMode?.name }}
      </p>
    </div>
    <div class="quote-overview__header__item">
      <p class="quote-overview__header__item-key">
        {{ "accountQuotes.overview.status" | cxTranslate }}
      </p>
      <p class="quote-overview__header__item-value">
        {{ "accountQuotes.quotesTable.statusDisplay_" + status | cxTranslate }}
      </p>
    </div>
  </div>
  <div class="quote-overview__comment">
    <h5 class="quote-overview__comment-title">
      {{ "accountQuotes.overview.commentOfProposal" | cxTranslate }}
    </h5>
    <p class="quote-overview__comment-value">
      {{ form.comment || comment }}
    </p>
  </div>
</div>
