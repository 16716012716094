<ng-container *ngIf="title && description">
  <div class="information" [ngClass]="addClass">
    <div class="information__header">
      <div class="information__header-icon">
        <cx-icon class="fas fa-exclamation-circle"></cx-icon>
      </div>
      <h4 class="information__header-title">
        {{title}}
      </h4>
    </div>
    <div class="information__content">
      <p class="information__content-value">
        {{description}}
      </p>
    </div>
  </div>
</ng-container>
