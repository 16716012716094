import { Injectable } from '@angular/core';
import {
  CmsNavigationComponent,
  CmsService,
  OccConfig,
  SemanticPathService,
} from '@spartacus/core';
import { NavigationNode, NavigationService } from '@spartacus/storefront';
import { Observable, of, Subject, BehaviorSubject, combineLatest } from 'rxjs';
import {
  filter,
  switchMap,
  tap,
  map,
  distinctUntilChanged,
} from 'rxjs/operators';
import { CustomBrandsAndCategoriesService } from '../../../services/custom-brands-and-categories.service';
import { CustomUser } from '../../user/models/custom-user.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomNavigationService extends NavigationService {
  isTrue = new Subject<boolean>();
  headerCachedNavigationNodes$: BehaviorSubject<NavigationNode | null> =
    new BehaviorSubject<NavigationNode | null>(null);
  constructor(
    protected cmsService: CmsService,
    protected semanticPathService: SemanticPathService,
    protected customCategoryService: CustomBrandsAndCategoriesService,
    private occConfig: OccConfig
  ) {
    super(cmsService, semanticPathService);
  }

  /**
   * returns an observable with the `NavigationNode` for the given `CmsNavigationComponent`.
   * This function will load the navigation underlying entries and children if they haven't been
   * loaded so far.
   */
  setHeaderCachedNavigationNodes(value: NavigationNode | null) {
    this.headerCachedNavigationNodes$.next(value);
  }
  public customGetNavigationNode(
    data$: Observable<CmsNavigationComponent>,
    isMobile: boolean = false,
    fromHeader: boolean = false
  ): Observable<NavigationNode | null> {
    if (!data$) {
      return of();
    }
    if (fromHeader && this.headerCachedNavigationNodes$.value != null) {
      return this.headerCachedNavigationNodes$.asObservable();
    }
    return data$.pipe(
      distinctUntilChanged(),
      filter((data) => !!data),
      switchMap((data) => {
        const navigation = data.navigationNode ? data.navigationNode : data;
        return this.cmsService.getNavigationEntryItems(navigation?.uid!).pipe(
          tap((items) => {
            if (items === undefined) {
              this._loadNavigationEntryItems(navigation, true);
            } else {
              // we should check whether the existing node items are what expected
              const expectedItems: any[] = [];
              this._loadNavigationEntryItems(navigation, false, expectedItems);
              const existingItems = Object.keys(items).map(
                (key) => items[key].uid
              );
              const missingItems = expectedItems?.filter(
                (it) => !existingItems.includes(it.id)
              );
              if (missingItems.length > 0) {
                this.cmsService.loadNavigationItems(
                  navigation?.uid!,
                  missingItems
                );
              }
            }
          }),
          filter(Boolean),
          map((items) =>
            this._populateNavigationNode(navigation, items, isMobile)
          )
        );
      })
    );
  }
  /**
   * Loads all navigation entry items' type and id. Dispatch action to load all these items
   * @param nodeData
   * @param root
   * @param itemsList
   */
  private _loadNavigationEntryItems(
    nodeData: any,
    root: boolean,
    itemsList: any[] = []
  ): void {
    if (nodeData?.entries && nodeData?.entries?.length > 0) {
      nodeData.entries.forEach((entry: any) => {
        itemsList.push({
          superType: entry.itemSuperType,
          id: entry.itemId,
        });
      });
    }
    if (nodeData.children && nodeData.children.length > 0) {
      nodeData.children.forEach((child: any) =>
        this._loadNavigationEntryItems(child, false, itemsList)
      );
    }
    if (root) {
      this.cmsService.loadNavigationItems(nodeData.uid, itemsList);
    }
  }
  /**
   * Create a new node tree for the view
   * @param nodeData
   * @param items
   */
  private _populateNavigationNode(
    nodeData: any,
    items: any,
    isMobile: boolean = false
  ): NavigationNode | null {
    let node: NavigationNode = {};

    if (nodeData.title) {
      // the node title will be populated by the first entry (if any)
      // if there's no nodeData.title available
      node.title = nodeData.title;
    }
    // populate style classes to apply CMS driven styling
    if (nodeData.styleClasses) {
      node.styleClasses = nodeData.styleClasses;
    }
    // populate style attributes to apply CMS driven styling
    if (nodeData.styleAttributes) {
      node.styleAttributes = nodeData.styleAttributes;
    }
    if (nodeData.entries && nodeData.entries.length == 1) {
      node.children = [];
      this._populateLink(
        node,
        nodeData.entries[0],
        items,
        !!node?.title,
        false,
        isMobile
      );
    }
    if (nodeData.entries && nodeData.entries.length > 1) {
      node.children = [];
      nodeData.entries.forEach((entry: any) =>
        this._populateLink(node, entry, items, true, false, isMobile)
      );
    }
    if (nodeData.children?.length > 0) {
      const children = nodeData.children
        .map((child: any) => this._populateNavigationNode(child, items))
        .filter(Boolean);
      if (children.length > 0) {
        node.children = children;
      }
    }
    // return null in case there are no children

    return Object?.keys(node)?.length === 0 ? null : node;
  }
  /**
   * The node link is driven by the first entry.
   */
  private _populateLink(
    node: any,
    entry: any,
    items: any,
    depth: boolean = false,
    withTitle: boolean = false,
    isMobile: boolean = false
  ) {
    const item = items[`${entry.itemId}_${entry.itemSuperType}`];
    // now we only consider CMSLinkComponent
    if (
      item &&
      (entry.itemType === 'CMSLinkComponent' ||
        entry.itemType === 'SimpleBannerComponent')
    ) {
      if (!node.title) {
        node.title = item.linkName || item.name;
      }
      const url = this.getLink(item);
      // only populate the node link if we have a visible node

      if (node.title && url) {
        node.url = url;
        // the backend provide boolean value for the target
        // in case the link should be opened in a new window
        if (item.target === 'true' || item.target === true) {
          node.target = '_blank';
        }
      }
      // populate style classes to apply CMS driven styling
      if (item.styleClasses) {
        node.styleClasses = item.styleClasses;
      }
      // populate style attributes to apply CMS driven styling
      if (item.styleAttributes) {
        node.styleAttributes = item.styleAttributes;
      }
      if (item.icon) {
        node.icon = item.icon;
      }
      if (item.media) {
        node.media = item.media;
      }
      if (item.childDepth) {
        node.childDepth = item.childDepth;
      }
      if (item.external) {
        node.external = item.external;
      }
      if (item.urlLink) {
        node.urlLink = item.urlLink;
      }
      if (item?.categoryCode) {
        node.categoryThumbnail$ = this.customCategoryService
          .getCategoryDataFromApi(item.categoryCode)
          .pipe(
            filter((category) => !!category?.thumbnail),
            map((category) => category?.thumbnail)
          );
      }
      if (
        item.categoryCode &&
        !isMobile &&
        this.headerCachedNavigationNodes$.value == null
      ) {
        node.categoryImage$ = this.customCategoryService
          .getCategoryDataFromApi(item.categoryCode)
          .pipe(
            filter((category) => !!category.image),
            map((category) => category.image)
          );
      }
    }
    if (depth) {
      node?.children.push(item);
    }

    if (withTitle) {
      node?.children?.push(item);
    }
  }

  public computeTargetAttribute<T extends NavigationNode>(link: T): T {
    return {
      ...link,
      target: link.target === true || link.target === 'true' ? '_blank' : null,
    };
  }

  isInPromeClub() {
    if (this.occConfig?.context?.baseSite![0] === 'promeclubSite') {
      return true;
    } else {
      return false;
    }
  }

  _createNavigation(
    data$: Observable<CmsNavigationComponent>,
    user: CustomUser
  ): Observable<NavigationNode | undefined> {
    return combineLatest([data$, this.getNavigationNode(data$)]).pipe(
      map(([data, nav]) => {
        let customData;
        if (!user?.isEmployee) {
          customData = {
            title: data.name,
            children: [nav],
          };
        } else {
          let filterNav: NavigationNode | any = nav.children!.map((child) => {
            return {
              title: child.title,
              children: [
                ...child.children!.filter(
                  (node) =>
                    !node.url?.includes('quotes') &&
                    !node.url?.includes('rebate') &&
                    !node.url?.includes('credit') &&
                    !node.url?.includes('company')
                ),
              ],
            };
          });
          let resultNav = { children: [...filterNav] };
          customData = {
            title: data.name,
            children: [resultNav],
          };
        }
        return data ? customData : undefined;
      })
    );
  }
}
