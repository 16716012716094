import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from '../../../services/custom-breakpoint.service';
import { CustomQuoteModalService } from '../../my-account/components/custom-quotes/services/custom-quote-modal.service';
import { ActiveCartService } from '@spartacus/core';
import { take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-custom-cart-quotes',
  templateUrl: './custom-cart-quotes.component.html',
  styleUrls: ['./custom-cart-quotes.component.scss'],
})
export class CustomCartQuotesComponent implements OnInit, OnDestroy {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  cartId: string;

  constructor(
    private customBreakpointService: CustomBreakpointService,
    private customQuoteModalService: CustomQuoteModalService,
    private activeCart: ActiveCartService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.activeCart
      .getActiveCartId()
      .pipe(take(1))
      .subscribe((cartId) => (this.cartId = cartId));

    this.addClassToFooterElement();
  }

  createQuoteFromCart() {
    this.customQuoteModalService.openCreateQuoteModal(this.cartId);
  }

  addClassToFooterElement(destroy: boolean = false): void {
    const footer = this.document.getElementsByClassName('Footer')[0];
    if (!destroy) {
      footer?.classList?.add('quotes');
    } else {
      footer?.classList?.remove('quotes');
    }
  }

  ngOnDestroy(): void {
    this.addClassToFooterElement(true);
  }
}
