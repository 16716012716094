import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomModalModule } from 'src/app/cms-components/shared/components/custom-modal/custom-modal.module';
import { CustomLoginFormModule } from 'src/app/cms-components/user/custom-login-form/custom-login-form.module';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomLoadingSpinnerModule } from '../custom-loading-spinner/custom-loading-spinner.module';
import { CustomModalGenericModule } from '../custom-modal/custom-modal-generic.module';
import { CustomCreditLineDialogComponent } from './custom-credit-line-dialog/custom-credit-line-dialog.component';
import { CustomCreditLineComponent } from './custom-credit-line.component';



@NgModule({
  declarations: [
    CustomCreditLineComponent,
    CustomCreditLineDialogComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    I18nModule,
    CustomCurrencyModule,
    CustomLoginFormModule,
    CustomModalModule,
    RouterModule,
    UrlModule,
    CustomLoadingSpinnerModule,
    CustomModalGenericModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CreditLineHeaderComponent: {
          component: CustomCreditLineComponent,
        }
      }
    })
  ],
  exports: [
    CustomCreditLineComponent,
    CustomCreditLineDialogComponent
  ]
})
export class CustomCreditLineModule { }
