import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EventService, Facet, ProductSelectors, RoutingService, SearchboxService, StateWithProduct, TranslationService, WindowRef } from '@spartacus/core';
import { SearchBoxComponentService, SearchBoxConfig, SearchResults } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CustomSearchResults } from '../../../models/custom-product-search-results.model';
const HAS_SEARCH_RESULT_CLASS = 'has-searchbox-results';
@Injectable({
  providedIn: 'root'
})
export class CustomSearchboxService extends SearchBoxComponentService{

  constructor(
    public searchService: SearchboxService,
    protected routingService: RoutingService,
    protected translationService: TranslationService,
    protected winRef: WindowRef,
    protected eventService: EventService,
    protected store: Store<StateWithProduct>,
  ) {
    super(searchService, routingService, translationService, winRef, eventService);
   }

     /**
   * Returns an observable with the SearchResults. When there's any
   * result, the body tag will get a classname, so that specific style
   * rules can be applied.
   */
  customGetResults(config: SearchBoxConfig): Observable<CustomSearchResults> {
    return combineLatest([
      this.getProductResults(config),
      this.getProductSuggestions(config),
      this.getSearchMessage(config),
      this.customGetSearchFacets(),
      this.customGetTotalResults(),
    ]).pipe(
      map(([productResults, suggestions, message, facets, totalResults]) => {
        return {
          products: productResults ? productResults.products : null,
          suggestions,
          message,
          facets,
          totalResults
        };
      }),
      tap((results: SearchResults | any) =>
        this.toggleBodyClass(HAS_SEARCH_RESULT_CLASS, this.hasResults(results))
      )
    );
  }

  customOpenSearchbox() {
    this.toggleBodyClass(HAS_SEARCH_RESULT_CLASS, true)
  }

  customGetSearchFacets(): Observable<Facet[] | null>{
    return this.store.pipe(select(ProductSelectors.getAuxSearchResults)).pipe(
      map(results => results.facets ? results.facets : null)
    )
  }

  customGetTotalResults(): Observable<number | null>{
    return this.store.pipe(select(ProductSelectors.getAuxSearchResults)).pipe(
      map(results => results?.pagination?.totalResults ? results.pagination.totalResults : null)
    )
  }
}


