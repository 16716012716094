import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomUser } from '../../user/models/custom-user.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomPromeclubUserService {
  public user$: BehaviorSubject<CustomUser>;

  constructor() {}

  setCurrentUser(user: CustomUser) {
    this.user$ = new BehaviorSubject<CustomUser>(user);
  }

  getUser(): CustomUser {
    return this.user$?.value;
  }
}
