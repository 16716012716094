import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ProductActions, RoutingService } from '@spartacus/core';
import { tap } from 'rxjs/operators';
import { EcvProductType, ECOVALOR } from '../constants/product-type';

@Injectable()
export class CustomProductDataEffects {
  EcvProductType: string = EcvProductType;
  ECOVALOR: string = ECOVALOR;
  constructor(
    private actions$: Actions,
    private routingService: RoutingService
  ) {}

  @Effect({ dispatch: false })
  CustomProductData$ = this.actions$.pipe(
    ofType(ProductActions.LOAD_PRODUCT_SUCCESS),
    tap((product: any) => {
      if (
        product?.payload?.productType == this.EcvProductType ||
        product?.payload?.name?.toLowerCase() == this.ECOVALOR
      ) {
        this.routingService.goByUrl('/not-found');
      }
    })
  );
}
