import { Component, Inject, OnInit } from '@angular/core';
import { Product, ProductReviewService, Review } from '@spartacus/core';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  CustomFormValidators,
  ICON_TYPE,
  ModalService,
} from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { take, filter, map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { EngagementProducto } from 'src/app/cms-components/product/model/custom-gtmProduct.model';
import { CustomGtmInteractionDatalayerService } from 'src/app/common/services/custom-gtm-interaction-datalayer.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-add-review-dialog',
  templateUrl: './custom-add-review-dialog.component.html',
  styleUrls: ['./custom-add-review-dialog.component.scss'],
})
export class CustomAddReviewDialogComponent implements OnInit {
  iconTypes = ICON_TYPE;
  product$: Observable<Product | null>;
  addReviewForm: FormGroup = this.fb.group({
    comment: ['', Validators.required],
    rating: [null, CustomFormValidators.starRatingEmpty],
  });
  isSubmit: boolean = false;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  private userName: string = '';
  private ngModalElement: HTMLElement;

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private reviewService: ProductReviewService,
    private userAccount: UserAccountFacade,
    @Inject(DOCUMENT) private document: Document,
    private customBreakpointService: CustomBreakpointService,
    private customGtmDatalayerService: CustomGtmInteractionDatalayerService
  ) { }

  ngOnInit(): void {
    this.ngModalElement = <HTMLElement>(
      this.document.getElementsByClassName('modal-content')[0]
    );

    this.userAccount
      .get()
      .pipe(
        take(1),
        filter((user) => !!user?.firstName),
        map((user) => user?.name)
      )
      .subscribe((name) => {
        if (name) {
          this.userName = name;
        } else {
          this.userName = '';
        }
      });

    this.setModalContentStyle();
  }

  setRating(rating: number) {
    this.addReviewForm.controls.rating.setValue(rating);
  }

  addReview(product: Product): void {
    this.isSubmit = true;
    if (this.addReviewForm.status === 'INVALID') {
      this.addReviewForm.markAllAsTouched();
      return;
    } else {
      const reviewFormControls = this.addReviewForm.controls;
      const review: Review = {
        comment: reviewFormControls.comment.value,
        rating: reviewFormControls.rating.value,
        alias: this.userName,
      };
      this.reviewService.add(product.code!, review);
      this.resetReviewForm();
      this.closePopup();
      this.customGtmDatalayerService.productInteractionEvent(
        EngagementProducto.SEND_REVIEW
      );
    }
  }
  resetReviewForm(): void {
    this.addReviewForm = this.fb.group({
      comment: ['', Validators.required],
      rating: [null, CustomFormValidators.starRatingEmpty],
      reviewerName: '',
    });
    this.isSubmit = false;
  }

  setModalContentStyle() {
    this.ngModalElement.style.height = 'fit-content';
    this.ngModalElement.style.margin = 'auto 8px';
  }

  closePopup() {
    this.resetReviewForm();
    this.modalService.closeActiveModal();
  }
}
