<ng-container>
  <div
    [ngClass]="showDetailsCard ? 'show' : 'hide'"
    class="variant-item"
    (click)="setShow()"
  >
    <div class="variant-item__name-container">
      <p
        class="variant-item__name-container-value"
        [ngClass]="{ active: showDetailsCard }"
        [innerHTML]="!!product?.summary ? (product?.summary | safeHtml) : ''"
      ></p>
      <cx-icon
        #tooltip="ngbTooltip"
        *ngIf="product?.badge && isLoggedIn"
        class="fas fa-info-circle"
        ngbTooltip="{{ product?.badge }}"
        placement="top-start"
        tooltipClass="tooltip-color"
        (mouseenter)="toggleTooltip(tooltip)"
      ></cx-icon>
    </div>

    <p
      class="variant-item__value"
      *ngFor="
        let plpClassCat of product?.plpClassCatData?.entry?.slice(0, 8);
        index as i
      "
      [ngClass]="{'short': plpClassCat?.value.length < 6 &&  headers?.[i]?.length < 6, 'long': plpClassCat?.value.length > 8 }"
    >
      {{ plpClassCat?.value }}
    </p>

    <p
      class="variant-item__price"
      [ngClass]="{ badge: product?.badge }"
      *ngIf="isLoggedIn"
    >
      {{ product?.priceWithIVA?.value | customCurrency }}
    </p>
  </div>
  <app-custom-product-variant-list-item-details
    *ngIf="showDetailsCard"
    [isLoggedIn]="isLoggedIn"
    [productCode]="product?.code"
    [productPosition]="productPosition"
  >
  </app-custom-product-variant-list-item-details>
</ng-container>
