import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Facet, OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FacetReviews } from 'src/app/cms-components/product/model/custom-facet-reviews';

@Component({
  selector: 'app-custom-facet-reviews',
  templateUrl: './custom-facet-reviews.component.html',
  styleUrls: ['./custom-facet-reviews.component.scss'],
})
export class CustomFacetReviewsComponent implements OnInit {
  @Input() facet: Facet;
  @Input() isMobile: boolean;
  facetReviews: Observable<any>;
  facetName: FacetReviews;
  urlFacet: string;
  showFacet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  constructor(
    protected occEndpointService: OccEndpointsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.facetReviews = of(this.facet.values).pipe(
      map((facets) =>
        facets?.map((facet) => {
          return (this.facetName = {
            ...facet,
            name: Math.round(Number(facet.name!) * 100) / 100,
          });
        })
      ),
      tap((facets) =>
        facets
          ?.sort((a, b) =>
            a.name != undefined && b.name != undefined ? a.name - b.name : 1
          )
          .reverse()
      )
    );
  }

  getUrl(url: string) {
    const arrRouterUrl = this.router.url.split('?');
    this.router.navigateByUrl(`${arrRouterUrl[0]}?query=${url}`);
  }

  toggleFacet(): void {
    this.showFacet$.next(!this.showFacet$.value);
  }
}
