import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomRebateListModule } from 'src/app/cms-components/my-account/components/custom-rebate-list/custom-rebate-list.module';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomPdpRebateDetailComponent } from './custom-pdp-rebate-detail/custom-pdp-rebate-detail.component';
import { CustomPdpRebateComponent } from './custom-pdp-rebate.component';

@NgModule({
  declarations: [CustomPdpRebateComponent, CustomPdpRebateDetailComponent],
  imports: [
    CommonModule,
    IconModule,
    I18nModule,
    NgbModule,
    CustomRebateListModule,
    CustomCurrencyModule,

    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        RebatePdpComponent: {
          component: CustomPdpRebateComponent,
        },
        RebateCartComponent: {
          component: CustomPdpRebateComponent,
        },
      },
    }),
  ],
  exports: [CustomPdpRebateComponent],
})
export class CustomPdpRebateModule {}
