import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, MediaModule, ModalModule } from '@spartacus/storefront';
import { CustomEmptyCartModule } from 'src/app/cms-components/custom/components/custom-empty-cart/custom-empty-cart.module';
import { CustomFilterModule } from 'src/app/cms-components/custom/components/custom-filter/custom-filter.module';
import { CustomInformationModule } from 'src/app/cms-components/custom/components/custom-information/custom-information.module';
import { CustomPaginationModule } from 'src/app/cms-components/custom/components/custom-pagination/custom-pagination.module';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomAccountTableModule } from '../../../custom/components/custom-account-table/custom-account-table.module';
import { CustomLoadingSpinnerModule } from '../../../custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomTitleMyAccountModule } from '../../../custom/components/custom-title-my-account/custom-title-my-account.module';
import { CustomQuoteOverviewHeaderComponent } from './custom-quote-overview-header/custom-quote-overview-header.component';
import { CustomQuotesEntryItemComponent } from './custom-quotes-entry-item/custom-quotes-entry-item.component';
import { CustomQuotesEntryListComponent } from './custom-quotes-entry-list/custom-quotes-entry-list.component';
import { CustomQuotesFormComponent } from './custom-quotes-form/custom-quotes-form.component';
import { CustomQuotesHistoryComponent } from './custom-quotes-history/custom-quotes-history.component';
import { CustomQuotesSharedModule } from './custom-quotes-shared/custom-quotes-shared.module';
import { CustomQuotesSummaryActionComponent } from './custom-quotes-summary-action/custom-quotes-summary-action.component';
import { CustomQuotesSummaryComponent } from './custom-quotes-summary/custom-quotes-summary.component';
import { CustomQuotesComponent } from './custom-quotes.component';



@NgModule({
  declarations: [
    CustomQuotesComponent,
    CustomQuotesFormComponent,
    CustomQuotesSummaryComponent,
    CustomQuotesSummaryActionComponent,
    CustomQuotesEntryListComponent,
    CustomQuotesEntryItemComponent,
    CustomQuotesHistoryComponent,
    CustomQuoteOverviewHeaderComponent
  ],
  imports: [
    CommonModule,
    CustomTitleMyAccountModule,
    I18nModule,
    ReactiveFormsModule,
    NgSelectModule,
    IconModule,
    UrlModule,
    CustomPaginationModule,
    CustomFilterModule,
    CustomCurrencyModule,
    MediaModule,
    NgbTooltipModule,
    CustomAccountTableModule,
    CustomEmptyCartModule,
    CustomLoadingSpinnerModule,
    ModalModule,
    CustomQuotesSharedModule,
    CustomInformationModule,
    CustomTitleMyAccountModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        QuoteDetailOrderComponent: {
          component: CustomQuotesComponent,
          guards: [AuthGuard],
        },
        QuoteComponent: {
          component: CustomQuotesHistoryComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ],
  exports: [
    CustomQuotesComponent
  ]
})
export class CustomQuotesModule { }
