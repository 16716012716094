import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-promeclub-order-details-total',
  templateUrl: './custom-promeclub-order-details-total.component.html',
  styleUrls: ['./custom-promeclub-order-details-total.component.scss'],
})
export class CustomPromeclubOrderDetailsTotalComponent {
  @Input() order: any;
}
