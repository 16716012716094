<div class="doc-valid-container" *ngIf="docsValid$ | async as docs">
  <ng-container *ngFor="let doc of docs">
    <ng-container *ngFor="let item of doc | keyvalue">
    <div [ngSwitch]="item.key" class="doc-valid-container__item">

        <app-custom-doc-item
          *ngSwitchCase="'ruc'"
          [onlyRead]="true"
          [label]="rucDocLabel"
          [docData]="item.value"
        ></app-custom-doc-item>

        <app-custom-doc-item
          *ngSwitchCase="'id'"
          [onlyRead]="true"
          [label]="idDocLabel"
          [docData]="item.value"
        ></app-custom-doc-item>
        <ng-container *ngIf="isJuridicPerson$ | async">
          <app-custom-doc-item
            *ngSwitchCase="'appoinment'"
            [onlyRead]="true"
            [label]="registeredAppoinmentDocLabel"
            [docData]="item.value"
          ></app-custom-doc-item>
        </ng-container>
          <app-custom-doc-item
            *ngSwitchCase="'letter'"
            [onlyRead]="true"
            [label]="letterDocLabel"
            [docData]="item.value"
        ></app-custom-doc-item>
      <ng-container *ngSwitchDefault></ng-container>
    </div>
  </ng-container>
  </ng-container>

</div>
