<div class="error" [ngClass]="{'promeclub': isPromeclub}">
  <div class="error__header">
    <div class="error__header-icon">
      <cx-icon class="fas fa-exclamation-circle"></cx-icon>
    </div>
    <h4 class="error__header-title">
      {{errorTitle | cxTranslate}}
    </h4>
  </div>
  <div class="error__content">
    <p class="error__content-value">
      {{errorMessage | cxTranslate}}
    </p>
  </div>
</div>
