import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  UpdateProfileComponent,
  UpdateProfileComponentService,
} from '@spartacus/user/profile/components';
import { CustomUpdatePasswordService } from './custom-update-password/custom-update-password.service';
import { CustomUpdateProfileService } from './custom-update-profile.service';

@Component({
  selector: 'app-custom-update-profile',
  templateUrl: './custom-update-profile.component.html',
  styleUrls: ['./custom-update-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'user-form' },
})
export class CustomUpdateProfileComponent extends UpdateProfileComponent {
  constructor(
    protected service: UpdateProfileComponentService,
    private customUpdatePasswordService: CustomUpdatePasswordService,
    private customUpdateProfileService: CustomUpdateProfileService
  ) {
    super(service);
  }

  form: FormGroup = this.customUpdateProfileService.form;
  isUpdating$ = this.customUpdateProfileService.isUpdating$;
  isUpdatingPassword$ = this.customUpdatePasswordService.isUpdating$;
}
