import { Component, Input } from '@angular/core';
import { Cart } from '@spartacus/core';

@Component({
  selector: 'app-custom-quotes-summary',
  templateUrl: './custom-quotes-summary.component.html',
  styleUrls: ['./custom-quotes-summary.component.scss'],
})
export class CustomQuotesSummaryComponent {
  @Input() isMobile: boolean;
  @Input() cart: Cart;
  constructor() {}
}
