import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-custom-filter',
  templateUrl: './custom-filter.component.html',
  styleUrls: ['./custom-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomFilterComponent implements OnInit {

  @Input() label: string;
  @Input() placeholder: string;
  @Input() entries: any[] = [];
  @Input() classSelect: string;
  @Input() valueDefault: any;
  @Output() filterSelected = new EventEmitter<boolean>();
  selectForm: FormGroup;
  constructor() { }

  ngOnInit(): void {
    this.selectForm = new FormGroup({
      filter: new FormControl(this.valueDefault ? this.valueDefault : null, [Validators.required]),
    });
  }

  onChangeOption(filter: any) {
    this.filterSelected.emit(filter);
  }

  resetForm() {
    this.selectForm.reset();
  }
}
