import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomBannerCarouselModule } from './components/custom-banner-carousel/custom-banner-carousel.module';
import { CustomFaqItemModule } from './components/custom-faq-item/custom-faq-item.module';
import { CustomParagraphModule } from './components/custom-paragraph/custom-paragraph.module';
import { PromesaSquareItemListModule } from './components/custom-square-item-list/custom-square-item-list.module';
import { CustomStaticBlockModule } from './components/custom-static-block/custom-static-block.module';
import { CustomTalleresModule } from './components/custom-talleres/custom-talleres.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CustomBannerCarouselModule,
    CustomParagraphModule,
    CustomFaqItemModule,
    CustomStaticBlockModule,
    PromesaSquareItemListModule,
    CustomTalleresModule,
  ],
})
export class CustomContentModule {}
