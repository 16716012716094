<ng-container *ngIf="componentData$ | async as data">
  <ng-container *ngIf="!(isMobile$ | async) as isMobile; else searchMobile">
    <div class="search-refiner">
      <div class="search-refiner__info">
        <img
          class="search-refiner__info-img"
          [src]="getImageUrl(data.banner.media.desktop.url)"
          loading="lazy"
        />
        <div class="search-refiner__info-content">
          <div class="search-refiner__info-title">
            <cx-icon class="fas fa-car"></cx-icon>
            <label>{{ "searchRefiner.searchByVehicle" | cxTranslate }}</label>
          </div>
          <ng-container
            *ngTemplateOutlet="formAutomotriz; context: { isMobile: !isMobile }"
          ></ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #searchMobile>
    <div class="search-refiner-accordion" id="accordionExample">
      <div class="search-refiner-accordion__card">
        <div class="search-refiner-accordion__card-header" id="headingOne">
          <div
            class="search-refiner__info-title"
            *ngIf="!dataFiltered && !filteredSelected"
          >
            <cx-icon class="fas fa-car"></cx-icon>
            <label>{{ "searchRefiner.searchByVehicle" | cxTranslate }}</label>
            <button
              *ngIf="!showInfo"
              class="btn btn-accordion"
              type="button"
              (click)="dataFiltered ? (showInfo = false) : (showInfo = true)"
            >
              <cx-icon class="fas fa-chevron-down"></cx-icon>
            </button>
            <button
              *ngIf="showInfo"
              class="btn btn-accordion"
              type="button"
              (click)="showInfo = false; clearForm()"
            >
              <cx-icon class="fas fa-chevron-up"></cx-icon>
            </button>
          </div>

          <div
            class="search-refiner__info-title"
            *ngIf="(dataFiltered && queryFilter) || filteredSelected"
          >
            <cx-icon class="fas fa-car"></cx-icon>
            <label *ngIf="queryFilter?.name">{{ queryFilter?.name }}</label>
            <label *ngIf="filteredSelected">{{ filteredSelected }}</label>
            <button
              class="btn btn-accordion"
              type="button"
              (click)="dataFiltered = false; showInfo = true; clearForm()"
            >
              <cx-icon class="icon-close" type="CLOSE"></cx-icon>
            </button>
          </div>
        </div>

        <div *ngIf="showInfo">
          <div class="card-body">
            <ng-container
              *ngTemplateOutlet="formAutomotriz; context: { isMobile: true }"
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #formAutomotriz let-isMobile="isMobile" let-depth="depth">
  <ng-container
    *ngIf="{
      yearsValues: yearsValues$ | async,
      brandsValues: brandsValues$ | async,
      modelsValues: modelsValues$ | async,
      motorsValues: motorsValues$ | async
    } as selectValues"
  >
    <form (ngSubmit)="setFilterAutomotriz()" [formGroup]="automotrizForm">
      <div class="search-refiner__info-input">
        <ng-select
          id="brandFilter"
          formControlName="brandFilter"
          [searchable]="true"
          [clearable]="false"
          (change)="onChangeOption('brandFilter', $event)"
          placeholder="{{ 'searchRefiner.selectBrand' | cxTranslate }}"
          required
          dropdownPosition="bottom"
        >
          <ng-option
            *ngFor="
              let brand of selectValues.brandsValues | sortBy : 'asc' : 'name'
            "
            [value]="brand"
          >
            {{ brand.name | titlecase }}
          </ng-option>
        </ng-select>
      </div>

      <div class="search-refiner__info-input">
        <ng-select
          id="modelFilter"
          formControlName="modelFilter"
          [searchable]="true"
          [clearable]="false"
          (change)="onChangeOption('modelFilter', $event)"
          placeholder="{{ 'searchRefiner.selectModel' | cxTranslate }}"
          required
          dropdownPosition="bottom"
        >
          <ng-option
            *ngFor="
              let model of selectValues.modelsValues | sortBy : 'asc' : 'name'
            "
            [value]="model"
          >
            {{ model.name | titlecase }}
          </ng-option>
        </ng-select>
      </div>

      <div class="search-refiner__info-input">
        <ng-select
          id="yearFilter"
          formControlName="yearFilter"
          [searchable]="true"
          [clearable]="false"
          (change)="onChangeOption('yearFilter', $event)"
          placeholder="{{ 'searchRefiner.selectYear' | cxTranslate }}"
          required
          dropdownPosition="bottom"
        >
          <ng-option
            *ngFor="
              let year of selectValues.yearsValues | sortBy : 'desc' : 'name'
            "
            [value]="year"
          >
            {{ year.name | titlecase }}
          </ng-option>
        </ng-select>
      </div>

      <div class="search-refiner__info-input">
        <ng-select
          id="motorFilter"
          formControlName="motorFilter"
          [searchable]="true"
          [clearable]="false"
          (change)="
            onChangeOption('motorFilter', $event); motorFilterSelected = true
          "
          placeholder="{{ 'searchRefiner.selectMotor' | cxTranslate }}"
          dropdownPosition="bottom"
        >
          <ng-option
            *ngFor="
              let motor of selectValues.motorsValues | sortBy : 'asc' : 'name'
            "
            [value]="motor"
          >
            {{ motor.name | titlecase }}
          </ng-option>
        </ng-select>
      </div>

      <button
        *ngIf="!isMobile"
        type="submit"
        class="custom-button btn primary"
        [disabled]="automotrizForm.invalid"
      >
        <cx-icon class="fas fa-chevron-right"></cx-icon>
      </button>

      <div class="search-refiner__info-button" *ngIf="isMobile">
        <button
          type="button"
          class="custom-button btn cx-action-link"
          (click)="showInfo = false; clearForm()"
        >
          {{ "common.cancel" | cxTranslate }}
        </button>

        <button
          type="submit"
          class="custom-button btn primary"
          [disabled]="automotrizForm.invalid"
        >
          {{ "common.confirm" | cxTranslate }}
        </button>
      </div>
    </form>
  </ng-container>
</ng-template>
