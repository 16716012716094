<app-custom-loading-spinner-item
  class="overlay"
  *ngIf="isUpdating$ | async"
></app-custom-loading-spinner-item>
<ng-container *ngIf="token$ | async as token">
  <div class="reset-password-container">
    <app-custom-register-customer-password
      [fromResetPassword]="true"
      [resetPasswordToken]="token"
      [form]="form"
    ></app-custom-register-customer-password>
  </div>
</ng-container>
