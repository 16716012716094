import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { GenericLinkModule, IconModule, MediaModule, SearchBoxModule } from '@spartacus/storefront';
import { CustomAddToCartModule } from 'src/app/cms-components/cart/custom-add-to-card/custom-add-to-cart.module';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomSafeHtmlModule } from '../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomBreadcrumbModule } from '../custom-breadcrumb/custom-breadcrumb.module';
import { CustomSearchboxCategoryItemComponent } from './custom-searchbox-category-item/custom-searchbox-category-item.component';
import { CustomSearchboxProductItemComponent } from './custom-searchbox-product-item/custom-searchbox-product-item.component';
import { CustomSearchboxSuggestsItemComponent } from './custom-searchbox-suggests-item/custom-searchbox-suggests-item.component';
import { CustomSearchboxComponent } from './custom-searchbox.component';
import { CustomSearchboxListItemsComponent } from './custom-searchbox-list-items/custom-searchbox-list-items.component';
import { CustomSearchboxItemComponent } from './custom-searchbox-item/custom-searchbox-item.component';
import { CustomSearchboxExtrasComponent } from './custom-searchbox-extras/custom-searchbox-extras.component';


@NgModule({
  declarations: [
    CustomSearchboxComponent,
    CustomSearchboxProductItemComponent,
    CustomSearchboxSuggestsItemComponent,
    CustomSearchboxCategoryItemComponent,
    CustomSearchboxListItemsComponent,
    CustomSearchboxItemComponent,
    CustomSearchboxExtrasComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    RouterModule,
    FormsModule,
    UrlModule,
    MediaModule,
    SearchBoxModule,
    CustomAddToCartModule,
    CustomCurrencyModule,
    CustomSafeHtmlModule,
    NgSelectModule,
    CustomBreadcrumbModule,
    GenericLinkModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        SearchBoxComponent: {
          component: CustomSearchboxComponent,
        },
      },
    }),
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        PromesaSearchBoxExtrasComponent: {
          component: CustomSearchboxExtrasComponent
        },
      },
    }),
  ]
})
export class CustomSearchboxModule { }
