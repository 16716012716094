import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserIdService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { CustomClaimAddService } from '../../services/custom-claim-add.service';

@Component({
  selector: 'app-custom-claim',
  templateUrl: './custom-claim.component.html',
  styleUrls: ['./custom-claim.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomClaimComponent implements OnInit, OnDestroy {
  orderCode: string;
  subscription: Subscription = new Subscription();
  order$: Observable<any>;
  userId: string;

  constructor(
    private addClaimService: CustomClaimAddService,
    private activedRoute: ActivatedRoute,
    protected userIdService: UserIdService
  ) {}
  ngOnInit(): void {
    this.subscription.add(
      this.activedRoute.url.subscribe((data) => {
        this.orderCode = data[2]?.path;
      })
    );
    this.subscription.add(
      this.userIdService
        ?.getUserId()
        .subscribe((userId) => (this.userId = userId))
    );
    this.order$ = this.addClaimService.getOrderDetails(this.orderCode);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
