<ng-container *ngIf="{
  readonly: readonly$ | async
} as data">
  <div class="readonly" *ngIf="data.readonly">
    <span class="readonly-text">{{'promeclubAddress.' + userAddress.promotickAddressType | cxTranslate}}</span>
    <cx-icon class="fas fa-edit" (click)="toggleEditMode()"></cx-icon>
    <cx-icon class="fas fa-trash" (click)="deleteAddress()"></cx-icon>
  </div>
    <form class="address__form" [formGroup]="addressForm">
      <label >
        <span class="label-content">
          {{'promeclubAddress.type' | cxTranslate}}
        </span>
          <ng-select
            formControlName="promotickAddressType"
            [searchable]="false"
            [clearable]="false"
            placeholder="{{'promeclubAddress.selectPlaceholder' | cxTranslate}}"
            required
            [ngClass]="{'error': addressSubmited && addressForm.get('promotickAddressType')?.invalid}"
            dropdownPosition="bottom"
          >
          <ng-option
            *ngFor="let type of addressTypes$ | async"
            [value]="type"
          >
            {{'promeclubAddress.' + type | cxTranslate}}

          </ng-option>
        </ng-select>
        <p  class="label-form-error" *ngIf="addressSubmited && addressForm.get('promotickAddressType')?.invalid">
          {{'promeclubAddress.typeError' | cxTranslate}}
        </p>
      </label>

      <label >
        <span class="label-content">
          {{'promeclubAddress.province' | cxTranslate}}
        </span>
        <ng-select
          formControlName="region"
          [searchable]="true"
          [clearable]="false"
          placeholder="{{ 'customRegister.select' | cxTranslate }}"
          required
          dropdownPosition="bottom"
          [ngClass]="{'error': addressSubmited && addressForm.get('region')?.invalid}"
          [items]="provinces$ | async"
          bindLabel="name"
      >
      </ng-select>
      <p  class="label-form-error" *ngIf="addressSubmited && addressForm.get('region')?.invalid">
        {{'promeclubAddress.provinceError' | cxTranslate}}
      </p>
    </label>

    <label >
      <span class="label-content">
        {{'promeclubAddress.city' | cxTranslate}}
      </span>
      <ng-select
        formControlName="district"
        [searchable]="true"
        [clearable]="false"
        placeholder="{{ 'customRegister.select' | cxTranslate }}"
        required
        dropdownPosition="bottom"
        [ngClass]="{'error': addressSubmited && addressForm.get('district')?.invalid}"
        [items]="districts$ | async"
        bindLabel="name"
      >
      </ng-select>
      <p  class="label-form-error" *ngIf="addressSubmited && addressForm.get('district')?.invalid">
        {{'promeclubAddress.cityError' | cxTranslate}}
      </p>
    </label>

    <label >
      <span class="label-content">
        {{'promeclubAddress.address' | cxTranslate}}
      </span>
      <input
        class="form-control"
        [ngClass]="{'error': addressSubmited && addressForm.get('line1')?.invalid}"
        formControlName="line1"
        placeholder="{{'promeclubAddress.addressPlaceholder' | cxTranslate}}"
        required
        dropdownPosition="bottom"
      />
      <p  class="label-form-error" *ngIf="addressSubmited && addressForm.get('line1')?.invalid">
        {{'promeclubAddress.addressError' | cxTranslate}}
      </p>
    </label>

    <label >
      <span class="label-content">
        {{'promeclubAddress.reference' | cxTranslate}}
      </span>
      <input
        class="form-control"
        [ngClass]="{'error': addressSubmited && addressForm.get('line2')?.invalid}"
        formControlName="line2"
        placeholder="{{'promeclubAddress.referencePlaceholder' | cxTranslate}}"
        required
        dropdownPosition="bottom"
      />

      <p  class="label-form-error" *ngIf="addressSubmited && addressForm.get('line2')?.invalid">
        {{'promeclubAddress.referenceError' | cxTranslate}}
      </p>
    </label>
  </form>
  <div class="address__form__action" *ngIf="!data.readonly">
    <div class="address__form__action-link" (click)="toggleAddMode(false)">
      {{'promeclubAddress.cancel' | cxTranslate}}
    </div>
    <button class="address__form__action-button" type="button" (click)="openTwilioValidation()">
      {{(edit ? 'promeclubAddress.saveChanges' : 'promeclubAddress.save')
        | cxTranslate
      }}
    </button>
  </div>
</ng-container>
