import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomAutomotiveLabelComponent } from './custom-automotive-label.component';

@NgModule({
  declarations: [CustomAutomotiveLabelComponent],
  imports: [CommonModule],
  exports: [CustomAutomotiveLabelComponent],
})
export class CustomAutomotiveLabelModule {}
