<div class="remove-cart">
  <div class="remove-cart__header">
    <h5 class="remove-cart__header-title">
      {{
        (fromWishlist
          ? "saveForLaterItems.areYouSureWishlist"
          : "saveForLaterItems.areYouSure"
        ) | cxTranslate
      }}
    </h5>
    <cx-icon type="CLOSE" (click)="closeModal()"></cx-icon>
  </div>
  <div class="remove-cart__action">
    <button class="remove-cart__action-btn" (click)="closeModal()">
      {{ "saveForLaterItems.cancel" | cxTranslate }}
    </button>
    <a class="remove-cart__action-link" (click)="removeCart()">{{
      (fromWishlist
        ? "saveForLaterItems.emptyWish"
        : "saveForLaterItems.emptyCart"
      ) | cxTranslate
    }}</a>
  </div>
</div>
