import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import {
  GenericLinkModule,
  MediaModule,
  PageSlotModule,
} from '@spartacus/storefront';
import { CustomHeaderLogoComponent } from './custom-header-logo.component';

@NgModule({
  imports: [CommonModule, GenericLinkModule, MediaModule, PageSlotModule],
  declarations: [CustomHeaderLogoComponent],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        SimpleBannerComponent: {
          component: CustomHeaderLogoComponent,
        },
      },
    }),
  ],
  exports: [CustomHeaderLogoComponent],
})
export class CustomHeaderLogoModule {}
