import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CustomFilterAutomotrizService } from '../cms-components/custom/services/custom-filter-automotriz.service';
import { CustomCmsLinkComponent } from '../cms-components/custom/components/custom-top-link/model/custom-top-link.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomTopLinkService {
  private linkItems: CustomCmsLinkComponent[] = [];
  constructor(
    private customFilterAutomotrizService: CustomFilterAutomotrizService
  ) {}

  changeCurrentSection(section: string) {
    localStorage.setItem('currentSection', section);
    this.customFilterAutomotrizService.setChangeLocalStorage();
  }
  getCurrentSection() {
    return localStorage.getItem('currentSection');
  }

  initializeSection() {
    let currentSection = this.getCurrentSection();
    if (currentSection !== null) {
      this.changeCurrentSection(
        window.location.pathname != '/'
          ? currentSection
          : environment.defaultSection
      );
    } else {
      this.changeCurrentSection(environment.defaultSection);
    }
  }

  setLinkItems(linkItem: CustomCmsLinkComponent): void {
    if (this.linkItems.length < 2) {
      this.linkItems.push(linkItem);
    }
  }

  getLinkItems(): CustomCmsLinkComponent[] {
    return this.linkItems;
  }
}
