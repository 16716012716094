import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPromeclubRegisterFormComponent } from './custom-promeclub-register-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule, provideConfig, CmsConfig, AuthGuard } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomPromeclubRegisterSuccessComponent } from './custom-promeclub-register-success/custom-promeclub-register-success.component';

@NgModule({
  declarations: [CustomPromeclubRegisterFormComponent, CustomPromeclubRegisterSuccessComponent],
  imports: [CommonModule, ReactiveFormsModule, I18nModule, IconModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        promeclubLandingComponent: {
          component: CustomPromeclubRegisterFormComponent,
          guards: [AuthGuard]
        },
      },
    }),
  ],
})
export class CustomPromeclubRegisterFormModule {}
