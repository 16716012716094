import { Component, ViewEncapsulation } from '@angular/core';
import { ModalService } from '@spartacus/storefront';
import { ModalRef } from '@spartacus/storefront/shared';
import { CustomTwilioValidatorService } from 'src/app/common/services/custom-twilio-validator.service';
import { CustomForgotPasswordComponent } from '../../../custom-forgot-password/custom-forgot-password.component';
import { CustomRegisterStepOrchestratorService } from '../../services/custom-register-step-orchestrator.service';

@Component({
  selector: 'app-customer-already-exists-dialog',
  templateUrl: './customer-already-exists-dialog.component.html',
  styleUrls: ['./customer-already-exists-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerAlreadyExistsDialogComponent {
  isRucMessage: boolean = false;
  isUidMessage: boolean = false;
  isRucMessageEmployee: boolean = false;
  modalRef: ModalRef;
  constructor(
    private modalService: ModalService,
    private customTwilioValidatorService: CustomTwilioValidatorService,
    private customRegisterStepOrchestrator: CustomRegisterStepOrchestratorService
  ) {}

  closeModal(reason: string) {
    if (reason === 'redirect') {
      this.customRegisterStepOrchestrator.setStepOneStatus(true);
      this.customRegisterStepOrchestrator.setStepTwoStatus(false);
      this.customRegisterStepOrchestrator.setStepThreeStatus(false);
      this.customTwilioValidatorService.setValidationStatus('');
    }
    this.modalService.dismissActiveModal(reason);
  }

  openModal(event: string) {
    this.closeModal(event);
    let modalInstance: any;

    this.modalRef = this.modalService.open(CustomForgotPasswordComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = this.modalRef.componentInstance;
    modalInstance.isRegisterUser = true;
  }
}
