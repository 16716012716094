<ng-container
  *ngIf="{
    componentData: componentData$ | async,
    isMobile: isMobile$ | async
  } as info"
>
  <ng-container *ngIf="!!info.componentData">
    <div class="title-page">
      <h1 [innerHTML]="'ourCompany.ourValues' | cxTranslate | safeHtml"></h1>
    </div>
    <div class="custom-secondary-banner">
      <ng-container *ngFor="let item$ of items$ | async; index as i">
        <ng-container>
          <div
            class="custom-secondary-banner__img"
            *ngIf="item$ | async as item"
            tabindex="0"
            (mouseover)="info.isMobile ? null : hover(i, true)"
          >
            <div class="custom-secondary-banner__info" *ngIf="!!item.headline">
              <h1
                *ngIf="!!item.headline && !isHover[i]"
                class="custom-secondary-banner__info-title"
                [innerHTML]="item.headline | safeHtml"
              ></h1>
              <h1
                *ngIf="!!item.content && isHover[i]"
                class="custom-secondary-banner__info-subtitle"
                [innerHTML]="item.content | safeHtml"
                (mouseleave)="info.isMobile ? null : hover(i, false)"
              ></h1>
            </div>

            <ng-container *ngIf="item.image | async as itemImage">
              <img
                [src]="getImageUrl(itemImage.url)"
                [alt]="itemImage.altText"
                loading="lazy"
              />
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
