<div class="close" >
  <cx-icon class="fas fa-times" (click)="closeModal('close')"></cx-icon>
</div>


  <div class="invalid-extension">
    <cx-icon class="fas fa-exclamation-triangle"></cx-icon>
      <div class="invalid-extension__message">
      {{'nonCustomerRegister.registerFailed' | cxTranslate}}
    </div>
  </div>

