<ng-container *ngIf="{
    isMobile: isMobile$ | async,
    cards: cards$ | async,
    showAddresses: showAddresses$ | async,
    maxAddresses: maxAddresses$ | async
  } as data">
  <div class="custom-shipping-address">
    <div class="top-content">
      <h2 class="d-lg-block d-xl-block custom-title">
        {{ "checkoutAddress.shippingAddress" | cxTranslate }}
      </h2>
      <ng-container *ngIf="!data.isMobile && data.cards?.length > data.maxAddresses">
        <div class="top-content__show-more">
          <span (click)="showAddresses(true, data.cards?.length)" *ngIf="!data.showAddresses">
            {{ "checkoutAddress.showMore" | cxTranslate }}
          </span>
          <span (click)="showAddresses(false, 3)" *ngIf="data.showAddresses">
            {{ "checkoutAddress.showLess" | cxTranslate }}
          </span>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="!forceLoader && !(isLoading$ | async); else loading">
      <ng-container *ngIf="
        isAccountPayment || (data.cards?.length && !addressFormOpened);
        else newAddressForm
      ">
        <div class="cx-checkout-body row">
          <div class="cx-shipping-address-card col-md-4 col-lg-4" *ngFor="
            let card of data.cards | slice: 0:data.maxAddresses;
            let i = index
          ">
            <div class="cx-shipping-address-card-inner" (click)="SelectAddress(card.address)">
              <app-custom-card [border]="true" [fitToContainer]="true" [content]="card.card"
                (sendCard)="SelectAddress(card.address)"></app-custom-card>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="data.isMobile && data.cards?.length > data.maxAddresses">
        <div class="top-content__show-more">
            <span (click)="showAddresses(true, data.cards?.length)" *ngIf="!data.showAddresses">
              {{ "common.showMore" | cxTranslate }}
            </span>
            <span (click)="showAddresses(false, 3)" *ngIf="data.showAddresses">
              {{ "checkoutAddress.showLess" | cxTranslate }}
            </span>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <ng-template #loading>
    <div class="cx-spinner">
      <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
    </div>
  </ng-template>
</ng-container>
