<ng-container *ngIf="rebate$ | async as rebates">
  <ng-container *ngIf="rebates.length > 0 && (entries$ | async).length > 0">
    <div class="rebate-carousel-cart">
      <h6 *ngIf="!(isMobile$ | async)">
        {{ "customRebate.cartCarouselTitle" | cxTranslate }}
      </h6>
      <app-custom-component-title
        *ngIf="isMobile$ | async"
        [title]="'customRebate.cartCarouselTitle' | cxTranslate"
        [hasFirsWordInBold]="true"
      ></app-custom-component-title>
      <div
        class="rebate-carousel-cart__card-container"
        [ngClass]="{
          'rebate-carousel-cart__card-container_quantity-major':
            rebates.length > 5
        }"
      >
        <app-custom-product-card
          *ngFor="let rebate of rebates.slice(0, (isMobile$ | async) ? 4 : 6)"
          [product]="rebate"
          [fromContentPage]="true"
          [isRebate]="true"
          [wideLabel]="true"
        ></app-custom-product-card>
      </div>
    </div>
  </ng-container>
</ng-container>
