export const customRegisterSelector = {
  customRegister: {
    customRegister: {
      title: 'Registro Promesa',
      customer: 'Soy cliente de Promesa',
      noCustomer: 'No soy cliente de Promesa',
      accountData: 'Datos de la cuenta',
      companyData: 'Datos de mi empresa',
      comercialData: 'Datos comerciales',
      socialReason: 'Razón social: ',
      socialReasonPlaceholder: 'Ingresa tu razón social',
      ruc: 'Ruc: ',
      phone: 'Teléfono: ',
      comercialName: 'Nombre comercial: ',
      comercialNamePlaceholder: 'Ingresa tu nombre comercial',
      customerNumber: 'Número de cliente: ',
      customerEmail: 'E-mail cliente: ',
      userData: 'Datos de usuarios',
      userDataParagraph:
        'Registra tu información de usuario para el ingreso a la página',
      name: 'Nombre: ',
      namePlaceholder: 'Ingresar nombre',
      nameRequired: '* El nombre es obligatorio.',
      surname: 'Apellido: ',
      surnamePlaceholder: 'Ingresar apellido',
      surnameRequired: '* El apellido es obligatorio.',
      cellphone: 'Teléfono móvil: ',
      cellphonePlaceholder: 'Ingresa teléfono móvil',
      cellphoneRequired: '* El teléfono es obligatorio.',
      userEmail: 'E-mail de usuario: ',
      emailForm: 'E-mail:',
      userEmailPlaceholder: 'Ingresar e-mail',
      invalidUserEmail: '* Debe ingresar un e-mail válido',
      emailRequired: '* El e-mail es obligatorio.',
      enterEmail: '*Debe ingresar un email',
      dataValidation: 'Validación de datos',
      dataValidationParagraph:
        'Para continuar con la creación de tu usuario, te enviaremos un PIN al teléfono móvil ingresado',
      pinRequest: 'Solicitar PIN',
      userCheck:
        'He revisado y confirmo que los datos ingresados son correctos',
      continue: 'Continuar',
      passwordConfig: 'Configurar contraseña',
      enterPassword: 'Ingresar contraseña: ',
      enterPasswordPlaceholder: '********',
      repeatPassword: 'Repetir contraseña: ',
      repeatPasswordPlaceholder: '********',
      passwordValidations: {
        stepOne: '- Al menos 8 caracteres',
        stepTwo: '- Al menos 1 número',
        stepThree: '- Al menos 1 mayúscula',
        stepFour: '- Al menos 1 minúscula',
        stepFive: '- Al menos 1 carácter especial _ @ - * #',
      },
      passwordDontMatch: '* Sus contraseñas no coinciden.',
      pinText:
        'Recibirás un mensaje SMS a tu celular con tu código PIN. Si no has recibido tu código PIN en tu teléfono móvil, haz clic en el siguiente enlace.',
      pinNewRequest: 'Reenviar código PIN',
      enterPinCode: 'Ingresa el código PIN',
      verify: 'Verificar',
      pinError: '* Código PIN incorrecto.',
      cellphonePrefixPlaceholder: '+593',
      successfulValidation: 'Validación exitosa',
      customerAlreadyExists:
        'El e-mail ya se encuentra asociado a una cuenta activa. Si no recuerdas tu contraseña, restablécela ',
      customerAlreadyExistsLink: 'aquí.',
      newPinAvailable: 'Intenta de nuevo en {{seconds}} segundos',
      select: 'Seleccionar',
      customerError:
        'Comunícate con tu oficial de crédito para concluir el proceso de registro.',
      goToHome: 'Ir al inicio',
      successfulRegistration: 'Recibirás un e-mail a {{email}}',
      successfulRegistrationLine: 'para finalizar tu proceso de registro.',
    },
  },
};
