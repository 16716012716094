import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { CheckoutFacade } from '@spartacus/checkout/root';
import {
  BadRequestHandler,
  GlobalMessageService,
  GlobalMessageType,
  isCartNotFoundError
} from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomPromeclubCheckoutAlertDialogComponent } from 'src/app/cms-components/custom/components/custom-promeclub-checkout-alert-dialog/custom-promeclub-checkout-alert-dialog.component';
import { CustomUpdatePasswordService } from 'src/app/cms-components/my-account/components/custom-update-profile/custom-update-password/custom-update-password.service';
import { CustomLoginAttempsHandlerService } from 'src/app/cms-components/user/custom-login-form/services/custom-login-attemps-handler.service';
import { CustomLoginFormComponentService } from 'src/app/cms-components/user/custom-login-form/services/custom-login-form-component.service';
import { CustomLoginFormErrorsService } from 'src/app/services/custom-login-form-errors.service';
import { AddToCartErrorsService } from '../../../cms-components/cart/services/add-to-cart-errors.service';
import { CustomLoginAttempts } from '../../../cms-components/user/models/custom-login-attemps.interface';

const OAUTH_ENDPOINT = '/authorizationserver/oauth/token';

@Injectable({
  providedIn: 'root',
})
export class CustomBadRequestHandler
  extends BadRequestHandler
  implements OnDestroy
{
  modalRef: ModalRef;
  unsubscribe$: Subject<void> = new Subject();
  constructor(
    protected globalMessageService: GlobalMessageService,
    protected customLoginFormErrorsService: CustomLoginFormErrorsService,
    protected customUpdatePasswordService: CustomUpdatePasswordService,
    protected addToCartErrorsService: AddToCartErrorsService,
    protected customLoginAttempsHandlerService: CustomLoginAttempsHandlerService,
    protected customLoginFormComponentService: CustomLoginFormComponentService,
    private modalService: ModalService,
    protected checkoutService: CheckoutFacade,
  ) {
    super(globalMessageService);
  }

  handleError(request: HttpRequest<any>, response: HttpErrorResponse): void {
    this.handleBadPassword(request, response);
    this.handleUserBloqued(request, response);
    this.handleBadLoginResponse(request, response);
    this.handleBadCartRequest(request, response);
    this.handleValidationError(request, response);
    this.handleVoucherOperationErrorCustom(request, response);
    this.handleGuestDuplicateEmail(request, response);
    this.handleCartEntryError(request, response);
    this.handleVoucherOperationErrorExpired(request, response);
    this.handleVoucherOperationErrorRedeemed(request, response);
    this.handleCartErrorCheckoutStepThree(request, response);
  }
  protected handleBadPassword(
    request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    if (
      response.url?.includes(OAUTH_ENDPOINT) &&
      response.error?.error === 'invalid_grant' &&
      request.body?.get('grant_type') === 'password' &&
      response.error?.error_description === 'Bad credentials'
    ) {
      this.customLoginFormComponentService.onLoginSuccess(false);
      this.customLoginAttempsHandlerService
        .getLoginAttemps()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((loginAttempts) => {
          if (!!loginAttempts) {
            this.handleLoginAttempts(loginAttempts);
          } else {
            this.customLoginFormErrorsService.populateMessage(
              'httpHandlers.badRequestLoginAgain'
            );
          }
        });
    }
  }

  protected handleBadCartRequest(
    _request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    this.getErrors(response)
      .filter((e) => isCartNotFoundError(e))
      .forEach(() => {
        this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
      });
  }

  protected handleLoginAttempts(loginAttempts: CustomLoginAttempts) {
    if (loginAttempts.isLoginDisabled) {
      this.customLoginAttempsHandlerService.setAttempts(
        loginAttempts.maxAttempts,
        loginAttempts.maxAttempts
      );
    } else {
      this.customLoginAttempsHandlerService.setAttempts(
        loginAttempts.attempts,
        loginAttempts.maxAttempts
      );
    }
    this.customLoginFormErrorsService.populateMessage(
      'httpHandlers.badRequestPleaseLoginAgain'
    );
  }

  protected handleUserBloqued(
    request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    if (
      response.url?.includes(OAUTH_ENDPOINT) &&
      response.error?.error === 'invalid_grant' &&
      request.body?.get('grant_type') === 'password' &&
      (response.error?.error_description === 'El usuario está deshabilitado' ||
        response.error?.error_description === 'User is disabled')
    ) {
      this.customLoginFormErrorsService.openModal();
    }
  }

  protected handleCartEntryError(
    _request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    this.getErrors(response)
      .filter(
        (e) =>
          e.type === 'CartEntryError' ||
          e.type === 'InsufficientStockError' ||
          e.message === 'Se ha producido un error al añadir a la bandeja'
      )
      .forEach((error) => this.addToCartErrorsService.next(error));
  }

  protected handleVoucherOperationErrorExpired(
    _request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    this.getErrors(response)
      .filter(
        (e) =>
          e.message === 'coupon.not.active.expired' &&
          e.type === 'VoucherOperationError'
      )
      .forEach(() => {
        this.globalMessageService.add(
          { key: 'httpHandlers.invalidCodeExpired' },
          GlobalMessageType.MSG_TYPE_ERROR,
          1000000000
        );
      });
  }

  protected handleVoucherOperationErrorRedeemed(
    _request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    this.getErrors(response)
      .filter(
        (e) =>
          e.message === 'coupon.already.redeemed' &&
          e.type === 'VoucherOperationError'
      )
      .forEach(() => {
        this.globalMessageService.add(
          { key: 'httpHandlers.invalidCodeRedeemed' },
          GlobalMessageType.MSG_TYPE_ERROR,
          1000000000
        );
      });
  }

  protected handleVoucherOperationErrorCustom(
    _request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    this.getErrors(response)
      .filter(
        (e) =>
          e.message === 'coupon.invalid.code.provided' &&
          e.type === 'VoucherOperationError'
      )
      .forEach(() => {
        this.globalMessageService.add(
          { key: 'httpHandlers.invalidCodeProvided' },
          GlobalMessageType.MSG_TYPE_ERROR,
          1000000000
        );
      });
  }

  protected handleBadLoginResponse(
    _request: HttpRequest<any>,
    response: HttpErrorResponse
  ) {
    this.getErrors(response)
      .filter((error) => error.type === 'PasswordMismatchError')
      .forEach(() => {
        this.customLoginFormComponentService.onLoginSuccess(false);
        this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
        this.customUpdatePasswordService.openModal(true);
      });
  }

  protected handleCartErrorCheckoutStepThree(
    _request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    this.getErrors(response)
      .filter((e) => {
        return e.type === 'ValidationError';
      })
      .forEach((error) => {
        if (error?.message == 'El carro no se ha calculado' && !this.checkoutService.getPlaceOrderError()) {
          let modalInstance: any;

          this.modalRef = this.modalService.open(
            CustomPromeclubCheckoutAlertDialogComponent,
            {
              centered: false,
              size: 'lg',
            }
          );

          modalInstance = this.modalRef.componentInstance;
        }
      });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
