import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CustomBreakpointService } from '../../../../../services/custom-breakpoint.service';
import { Observable } from 'rxjs';
import { CustomProduct } from 'src/app/cms-components/product/model/custom-product.interface';

@Component({
  selector: 'app-custom-product-variant-list-item',
  templateUrl: './custom-product-variant-list-item.component.html',
  styleUrls: ['./custom-product-variant-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomProductVariantListItemComponent implements OnInit {
  @Input() product: CustomProduct;
  @Input() productPosition: number;
  @Input() headers: string[];
  @Input() isLoggedIn: boolean = false;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  showDetailsCard: boolean = false;
  constructor(private customBreakpointService: CustomBreakpointService) {}

  ngOnInit(): void {}

  toggleTooltip(tooltip: any): void{
    setTimeout(() => {
      tooltip?.close();
    }, 600);
  }

  setShow() {
    this.showDetailsCard = !this.showDetailsCard;
  }
}
