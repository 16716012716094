export const customWishlist = {
  customWishlist: {
    customWishlist: {
      breadTitle: 'Favoritos',
      title: '<p>Mis <b>Favoritos ({{count}})</b></p>',
      mobileTitle: '<p>Mis <b>Favoritos</b></p>',
      mobileSubtitle: '{{count}} producto',
      mobileSubtitle_plural: '{{count}} productos',
      code: 'Código',
      product: 'Producto',
      unitPoints: 'Puntos unitarios',
      quantity: 'Cantidad',
      totalPoints: 'Puntos totales',
      addToCart: 'Agregar al carrito',
      returnToHome: 'Regresar al inicio',
      noProducts: 'No hay productos en tus favoritos',
      add: 'Agregar',
      points: 'Puntos',
      empty: 'Vaciar favoritos',
      confirmRemove: 'Si, eliminar',
      confirmRemoveTitle:
        '¿Seguro quieres <b>eliminar el producto de tus favoritos?</b>',
      confirmRemoveTitleMobile:
        '¿Seguro quieres eliminar el producto de tus favoritos?',
    },
  },
};
