<ng-container>
  <div #dialogOrderReturn class="custom-order-devolution">
    <app-custom-modal-generic></app-custom-modal-generic>
    <div class="custom-order-devolution_body-footer">
      <div class="custom-order-devolution_body">
        <span>{{
          "returnRequest.devolutionSuccess"
            | cxTranslate: { orderCode: orderCode }
        }}</span>
      </div>
      <div class="custom-order-devolution__footer">
        <button
          type="button"
          class="close"
          cxModal="dismiss"
          cxModalReason="Click continue"
        >
          <span aria-hidden="true">
            {{
              "common.continue" | cxTranslate
            }}
          </span>
        </button>
      </div>
    </div>
  </div>
</ng-container>
