import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-check-button',
  templateUrl: './custom-check-button.component.html',
  styleUrls: ['./custom-check-button.component.scss'],
})
export class CustomCheckButtonComponent {
  @Input() isActive: boolean;
  @Input() isIconActive: boolean;
  @Input() isIconCircle: boolean = false;

}
