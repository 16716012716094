import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomTermsAndConditionsModalComponent } from './custom-terms-and-conditions-modal.component';
import { IconModule } from '@spartacus/storefront';
import { CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { CustomTermsAndConditionsComponent } from './custom-terms-and-conditions/custom-terms-and-conditions.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CustomTermsAndConditionsModalComponent,
    CustomTermsAndConditionsComponent,
  ],
  imports: [CommonModule, IconModule, I18nModule, ReactiveFormsModule],
  exports: [CustomTermsAndConditionsModalComponent],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        TyCPromeclubPopup: {
          component: CustomTermsAndConditionsComponent,
        },
      },
    }),
  ],
})
export class CustomTermsAndConditionsModalModule {}
