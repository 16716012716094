import { IconConfig, IconResourceType } from '@spartacus/storefront';

export const CustomIconsConfig: IconConfig = {
  icon: {
    symbols: {
      MY_ACCOUNT: 'my_account',
      LOGOUT: 'LOGOUT',
      FILTER_MOBILE: 'filter_alt',
      PDF_DOWNLOAD: 'pdf',
      STAR: 'star',
      EMPTY_STAR: 'empty-star',
      HALF_STAR: 'half-star',
      EMAIL: 'EMAIL',
      LOCK: 'LOCK',
      MINICART: 'MINICART',
      AWARD: 'award',
      HAMBURGER: 'hamburger',
      FACEBOOK: 'FACEBOOK',
      WHATSAPP: 'WHATSAPP',
      EXCLAMATION_TRIANGLE: 'exclamation-triangle',
      CALENDAR_CHECK: 'calendar-check',
      CALENDAR_MINUS: 'calendar-minus',
      STOPWATCH: 'stopwatch',
      FILE_ICON: 'file-icon',
      CLOSE_CIRCLE: 'close-circle',
      TAG_BLUE: 'tag-blue',
      TAG: 'tag',
      FACEBOOK_SQUARE: 'facebook-sq',
      LINKEDIN: 'linkedin',
      INSTAGRAM: 'instagram',
      SIGN_OUT: 'sign-out',
      CAR: 'car',
      CAR_ACTIVE: 'car-active',
      WRENCH: 'wrench',
      WRENCH_ACTIVE: 'wrench-active',
      CHECK_MENU: 'check-menu',
      ICON_SHARE: 'icon-share',
      CHECK_CHECKOUT: 'check-checkout',
      BILLING_CHECKOUT: 'billing-checkout',
      EDIT: 'edit',
      TRASH: 'trash',
      LOCK_ALT: 'lock-alt',
    },
    resources: [
      {
        type: IconResourceType.LINK,
        url: 'https://use.fontawesome.com/releases/v5.8.1/css/all.css',
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/my_account.svg',
        types: ['MY_ACCOUNT'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/logout-icon.svg',
        types: ['LOGOUT'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/filter-alt.svg',
        types: ['FILTER_MOBILE'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/pdf.svg',
        types: ['PDF_DOWNLOAD'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/star.svg',
        types: ['STAR'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/empty-star.svg',
        types: ['EMPTY_STAR'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/half-star.svg',
        types: ['HALF_STAR'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/email.svg',
        types: ['EMAIL'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/lock.svg',
        types: ['LOCK'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/minicart.svg',
        types: ['MINICART'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/award.svg',
        types: ['AWARD'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/bars-solid.svg',
        types: ['HAMBURGER'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/facebook.svg',
        types: ['FACEBOOK'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/facebook-square.svg',
        types: ['FACEBOOK_SQUARE'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/linkedin.svg',
        types: ['LINKEDIN'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/instagram.svg',
        types: ['INSTAGRAM'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/whatsapp.svg',
        types: ['WHATSAPP'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/exclamation-triangle.svg',
        types: ['EXCLAMATION_TRIANGLE'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/calendar-check.svg',
        types: ['CALENDAR_CHECK'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/calendar-minus.svg',
        types: ['CALENDAR_MINUS'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/stopwatch.svg',
        types: ['STOPWATCH'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/icon-tag-blue.svg',
        types: ['TAG_BLUE'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/file.svg',
        types: ['FILE_ICON'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/times-circle.svg',
        types: ['CLOSE_CIRCLE'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/icon-tag.svg',
        types: ['TAG'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/sign-out.svg',
        types: ['SIGN_OUT'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/car.svg',
        types: ['CAR'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/car_active.svg',
        types: ['CAR_ACTIVE'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/wrench.svg',
        types: ['WRENCH'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/wrench_active.svg',
        types: ['WRENCH_ACTIVE'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/check-menu.svg',
        types: ['CHECK_MENU'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/icon-share.svg',
        types: ['ICON_SHARE'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/edit.svg',
        types: ['EDIT'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/trash-alt.svg',
        types: ['TRASH'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/lock-alt.svg',
        types: ['LOCK_ALT'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/check-checkout.svg',
        types: ['CHECK_CHECKOUT'],
      },
      {
        type: IconResourceType.SVG,
        url: '../../assets/icons/billing-checkout.svg',
        types: ['BILLING_CHECKOUT'],
      },
    ],
  },
};
