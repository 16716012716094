import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomShoppingListComponent } from './custom-shopping-list.component';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { CustomFilterModule } from '../../../custom/components/custom-filter/custom-filter.module';
import { CustomPaginationModule } from '../../../custom/components/custom-pagination/custom-pagination.module';
import { RouterModule } from '@angular/router';
import { ListNavigationModule } from '@spartacus/storefront';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';
import { CustomEmptyCartModule } from 'src/app/cms-components/custom/components/custom-empty-cart/custom-empty-cart.module';
import { CustomTitleMyAccountModule } from 'src/app/cms-components/custom/components/custom-title-my-account/custom-title-my-account.module';
import { CustomLoadingSpinnerModule } from 'src/app/cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';

@NgModule({
  declarations: [CustomShoppingListComponent],
  imports: [
    CommonModule,
    I18nModule,
    CustomFilterModule,
    CustomPaginationModule,
    UrlModule,
    RouterModule,
    ListNavigationModule,
    CustomCurrencyModule,
    CustomEmptyCartModule,
    CustomTitleMyAccountModule,
    CustomLoadingSpinnerModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ShoppingListComponent: {
          component: CustomShoppingListComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ],
})
export class CustomShoppingListModule {}
