import { Injectable } from '@angular/core';
import { AuthService, GlobalMessageService, GlobalMessageType, WindowRef } from '@spartacus/core';
import { LoginFormComponentService } from '@spartacus/user/account/components';
import { Subject } from 'rxjs';
import { CustomModalService } from '../../../shared/components/custom-modal/services/custom-modal.service';

@Injectable({
  providedIn: 'root'
})
export class CustomLoginFormComponentService extends LoginFormComponentService {
  loginSuccess$: Subject<any> = new Subject<any>();
  modalLoginFormComponentInstance: string = 'Login_Form'
  constructor(
    protected auth: AuthService,
    protected globalMessage: GlobalMessageService,
    protected winRef: WindowRef,
    protected customModalService: CustomModalService,
  ) {
    super(auth, globalMessage, winRef);
  }

  protected onSuccess(isLoggedIn: boolean): void {
    if (isLoggedIn) {
      this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
      this.form.reset();
      this.customModalService.hide(this.modalLoginFormComponentInstance)
      this.onLoginSuccess(true);
    }

    this.busy$.next(false);
  }

  onLoginSuccess(value: boolean){
    this.loginSuccess$.next(value);
  }



}
