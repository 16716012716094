import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-future-stock-dialog',
  templateUrl: './custom-future-stock-dialog.component.html',
  styleUrls: ['./custom-future-stock-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomFutureStockDialogComponent  {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    protected modalService: ModalService,
    private customBreakpointService: CustomBreakpointService,
  ) { }

  customDismissModal(reason?: any): void {
    this.modalService.dismissActiveModal(reason);
  }

}
