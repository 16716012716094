import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomInnerHeaderComponent } from './custom-inner-header.component';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { CustomHeaderLogoModule } from '../custom-header-logo/custom-header-logo.module';
import { IconModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CustomInnerHeaderComponent],
  imports: [
    CommonModule,
    IconModule,
    I18nModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        InnerHeaderComponent: {
          component: CustomInnerHeaderComponent,
        },
      },
    }),
    CustomHeaderLogoModule,
    RouterModule,
    UrlModule,
  ],
})
export class CustomInnerHeaderModule {}
