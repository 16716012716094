<div class="inner">
  <div class="inner__top">
    <ng-container *ngIf="user$ | async as user; else anonymousUser">
      <a [routerLink]="'/my-account/orders'" class="inner__top-link user"
        ><cx-icon type="MY_ACCOUNT"></cx-icon
        ><span class="inner__top-link-name">{{
          user.firstName || user.name
        }}</span></a
      >
      <a
        [routerLink]="{ cxRoute: 'logout' } | cxUrl"
        class="inner__top-link out"
      >
        {{ "loginForm.signOut" | cxTranslate
        }}<cx-icon type="SIGN_OUT"></cx-icon
      ></a>
    </ng-container>
  </div>
  <div class="inner__bottom">
    <app-custom-header-logo
      [fromInnerHeader]="true"
      [ngClass]="{ promeclub: isPromeclub }"
    ></app-custom-header-logo>
    <cx-icon [type]="'CLOSE'" (click)="closeMenu()"></cx-icon>
  </div>
</div>

<ng-template #anonymousUser>
  <a
    (click)="resetClassHeader()"
    [routerLink]="{ cxRoute: 'login' } | cxUrl"
    class="inner__top-link"
    ><cx-icon type="MY_ACCOUNT"></cx-icon
    >{{ "loginForm.login" | cxTranslate }}</a
  >
  <a [routerLink]="{ cxRoute: 'register' } | cxUrl" class="inner__top-link">{{
    "loginForm.registerLink" | cxTranslate
  }}</a>
</ng-template>
