import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomCartListHeaderComponent } from './custom-cart-list-header.component';

@NgModule({
  declarations: [CustomCartListHeaderComponent],
  imports: [CommonModule, I18nModule, IconModule, NgbModule],
  exports: [CustomCartListHeaderComponent],
})
export class CustomCartListHeaderModule {}
