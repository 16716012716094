<ng-container *ngIf="{
    cart: cart$ | async,
    quantity: quantity$ | async,
    isReviewSubmitActive: isReviewSubmitActive$ | async,
    isMobile: isMobile$ | async
  } as data">
  <ng-container *ngIf="data.isMobile; else summaryDesktop">
    <a class="custom-summary" (click)="toggleShowCollapse($event)">
      <div class="custom-summary__accordion">
        <div class="custom-summary__accordion-header" [ngClass]="{ 'accordion-colapsed': showColapse }">

          <div class="custom-summary__accordion-header-title">
            {{'checkoutProgress.orderSummary' | cxTranslate}}
          </div>

          <div class="custom-summary__accordion-header-price">
            <span>
              {{ isPromeclub ? (data.cart.totalPrice?.value | number : "" : "es-AR") :
              (data.cart.totalPriceWithTax?.value
              | customCurrency)}}
            </span>
            <cx-icon (click)="collapseFromIcon && (showColapse = !showColapse)" class="fas" [ngClass]="{
            'fa-chevron-down': !showColapse,
            'fa-chevron-up': showColapse
          }"></cx-icon>
          </div>
        </div>
      </div>
    </a>
    <div class="custom-summary__accordion-body" *ngIf="showColapse">
      <app-custom-order-summary [customCart]="data.cart" [showCoupon]="false"
        [section]="'checkout'"></app-custom-order-summary>
      <div class="accordion-body" [ngClass]="{'pb-0': entriesQuantity > entriesCart.length}">
        <div class="custom-summary__accordion-body-title">
          {{'checkoutProgress.productsSummaryCheckout' | cxTranslate}}
        </div>
        <app-custom-entries-mini-cart [cantMaxItems]="cantMaxItems"
          [entriesQuantity]="entriesQuantity" [clickedFrom]="'Checkout - Ver detalle del pedido'"
          [entriesCart]="entriesCart" [isInCheckout]="true"></app-custom-entries-mini-cart>
      </div>
    </div>
  </ng-container>

  <ng-template #summaryDesktop>
    <app-custom-order-summary [customCart]="data.cart" [showCoupon]="false"
      [section]="'checkout'"></app-custom-order-summary>

    <app-custom-place-order *ngIf="data.isReviewSubmitActive"></app-custom-place-order>

    <div class="checkout-order-summary-accordion" id="accordionExample">
      <div class="checkout-order-summary-accordion__card">
        <button class="btn btn-accordion" [ngClass]="{
              'checkout-order-summary-accordion__card-scroll': showInfo === true
            }" type="button" (click)="showInfo = !showInfo; showCartEvent()">
          <div class="checkout-order-summary-accordion__card-header" id="headingOne">
            <div class="checkout-order-summary-accordion__info-title">
              <label>
                {{
                "checkoutProgress.orderSummaryCheckout"
                | cxTranslate : { count: data.quantity }
                }}
              </label>
              <cx-icon class="fas fa-chevron-down"></cx-icon>
            </div>
          </div>
        </button>

        <div *ngIf="showInfo" class="card-body">
          <div class="cart-summary__info" [ngClass]="{
                'cart-summary__info_scroll': scrollActive === true
              }">
            <app-custom-entries-mini-cart [cantMaxItems]="cantMaxItems" (scrollShowMore)="onShowMore($event)"
              [entriesQuantity]="entriesQuantity" [clickedFrom]="'Checkout - Ver detalle del pedido'"
              [entriesCart]="entriesCart" [isInCheckout]="true"></app-custom-entries-mini-cart>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
