<div class="quote-info">
  <app-custom-modal-generic></app-custom-modal-generic>

  <div
    *ngIf="!(isMobile$ | async); else mobileMessage"
    class="quote-info__message"
    [innerHTML]="
      'accountQuotes.success.message' | cxTranslate : { code: code } | safeHtml
    "
  ></div>
  <ng-template #mobileMessage>
    <div
      class="quote-info__message"
      [innerHTML]="
        'accountQuotes.success.messageMobile'
          | cxTranslate : { code: code }
          | safeHtml
      "
    ></div>
  </ng-template>

  <button class="quote-info__button" (click)="closeModal('continue')">
    {{ "accountQuotes.success.continue" | cxTranslate }}
  </button>
</div>
