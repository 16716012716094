export const payment = {
  paymentForm: {
    payment: 'Medio de pago',
    choosePaymentMethod: 'Elija un método de pago',
    paymentType: 'Tarjetas de crédito o débito disponibles',
    accountHolderName: {
      label: 'Nombre del titular',
      placeholder: 'Nombre del titular de la Cuenta',
    },
    cardNumber: 'Número de tarjeta',
    expirationDate: 'Fecha de Vencimiento',
    securityCode: 'Código de Seguridad',
    securityCodeTitle: 'Valor de Verificación de la Tarjeta',
    saveAsDefault: 'Guardar por defecto',
    setAsDefault: 'Establecer como predeterminado',
    billingAddress: 'Dirección de Envío',
    sameAsShippingAddress: 'Igual que la Dirección de Envío',
    selectOne: 'Seleccione uno...',
    monthMask: 'MM',
    yearMask: 'YYYY',
    expirationYear: 'Año de caducidad {{ selected }}',
    expirationMonth: 'Mes de expiración {{ selected }}',
    useThisPayment: 'Utilizar este pago',
    addNewPayment: 'Agregar Nuevo Pago',
    changePayment: 'Cambiar Pago',
  },
  paymentMethods: {
    paymentMethods: 'Métodos de pago',
    newPaymentMethodsAreAddedDuringCheckout:
      'Se agregan nuevos métodos de pago durante el pago.',
    invalidField: 'Campo inválido: {{ field }}',
  },
  paymentCard: {
    deleteConfirmation:
      '¿Está seguro de que desea eliminar esta forma de pago?',
    setAsDefault: 'Establecer como predeterminado',
    expires: 'Expira: {{ month }}/{{ year }}',
    defaultPaymentMethod: 'Método de Pago por Defecto',
    selected: 'Seleccionado',
  },
  paymentTypes: {
    title: 'Método de pago',
    promeclubTitle: 'Método de canje',
    stepOne: 'Paso 1 de 2',
    stepTwo: 'Paso 2 de 2',
    paymentType_CARD: 'Tarjeta de crédito',
    paymentType_ACCOUNT: 'Cuenta bancaria',
    counted: 'Contado',
  },
};
