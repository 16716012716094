import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CustomDeliveryModeComponent } from './custom-delivery-mode.component';

import { ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '@spartacus/storefront';
import { CustomCheckButtonModule } from 'src/app/cms-components/custom/components/custom-check-button/custom-check-button.module';
import { CustomLoadingSpinnerModule } from 'src/app/cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';

@NgModule({
  declarations: [CustomDeliveryModeComponent],
  exports: [CustomDeliveryModeComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    IconModule,
    CustomLoadingSpinnerModule,
    CustomCheckButtonModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutDeliveryMode: {
          component: CustomDeliveryModeComponent,
        },
      },
    }),
  ],
})
export class CustomDeliveryModeModule {}
