<div class="update-password">
  <h2 class="update-password__subtitle">
    {{ "customUpdateProfileForm.changePassword" | cxTranslate }}
  </h2>
  <div class="update-password__input">
    <form [formGroup]="currentPassForm">
      <label>
        <span>
          {{ "customUpdateProfileForm.enterCurrentPassword" | cxTranslate }}
        </span>
        <input
          aria-required="true"
          [type]="passwordType"
          formControlName="oldPassword"
          placeholder="{{
            'customRegister.enterPasswordPlaceholder' | cxTranslate
          }}"
          class="form-control"
        />
        <cx-icon
          [class]="!showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
          (click)="togglePasswordView()"
        ></cx-icon>
      </label>
    </form>
    <app-custom-register-customer-password
      [fromMyAccount]="true"
      (updatePasswordFormEmmiter)="updatePassword($event)"
    ></app-custom-register-customer-password>
  </div>
</div>
