import { Injectable } from '@angular/core';
import {
  ProductScope,
  RoutingService,
  SemanticPathService,
  TranslationService,
  isNotNullable,
} from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, pluck, filter } from 'rxjs/operators';
import { SimpleVariantSelectorsList } from '../../model/custom-variant-element.interface';
import { CustomProduct } from 'src/app/cms-components/product/model/custom-product.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomProductVariantsService {
  product$: Observable<CustomProduct | null>;
  productCode: string;
  variantCode$: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private _currentProductService: CurrentProductService,
    protected routingService: RoutingService,
    protected semanthicPathService: SemanticPathService,
    protected translation: TranslationService
  ) {
    this.product$ = this._currentProductService.getProduct(
      ProductScope.DETAILS
    );
  }

  getVariantOptionsMapped(
    productInput$?: Observable<CustomProduct | null>
  ): Observable<SimpleVariantSelectorsList[]> {
    productInput$ = productInput$ ? productInput$ : this.product$;
    return productInput$.pipe(
      tap((product) => {
        this.productCode = product?.code!;
      }),
      pluck('simpleVariantSelectors')
    );
  }

  setVariantCode(value: string | null): void {
    this.variantCode$.next(value);
  }

  getSelectedVariantCode(): Observable<string> {
    return this.variantCode$.asObservable().pipe(filter(isNotNullable));
  }

  setLoading(value: boolean): void {
    this.loading$.next(value);
  }

  getLoading(): Observable<boolean> {
    return this.loading$.asObservable();
  }
}
