import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CmsActions } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CustomGtmLoginService } from 'src/app/common/services/custom-gtm-login-register-datalayer.service';

@Injectable()
export class CustomPageEffects {
  @Effect({
    dispatch: false,
  })
  loadPageData$: Observable<any> = this.actions$.pipe(
    ofType(CmsActions.LOAD_CMS_PAGE_DATA),
    map((action: CmsActions.LoadCmsPageData) => {}),
    tap(() => this.customGtmLoginService.userTrackingData())
  );

  constructor(
    private actions$: Actions,
    private customGtmLoginService: CustomGtmLoginService
  ) {}
}
