import { Location } from '@angular/common';
import {
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CheckoutStepService } from '@spartacus/checkout/components';
import { CheckoutStepType } from '@spartacus/checkout/root';
import {
  BreadcrumbMeta,
  CmsBreadcrumbsComponent,
  CmsService,
  Page,
  PageMetaService,
  TranslationService,
} from '@spartacus/core';
import { BreadcrumbComponent, CmsComponentData } from '@spartacus/storefront';
import { Observable, combineLatest } from 'rxjs';
import { map, pluck, tap } from 'rxjs/operators';
import { CustomReviewSubmitService } from 'src/app/feature-libs/checkout/services/custom-review-submit.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomBreadcrumbService } from '../../services/custom-breadcrumb.service';

@Component({
  selector: 'app-custom-breadcrumb',
  templateUrl: './custom-breadcrumb.component.html',
  styleUrls: ['./custom-breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomBreadcrumbComponent
  extends BreadcrumbComponent
  implements OnInit
{
  currentPage$: Observable<Observable<BreadcrumbMeta[] | undefined> | null>;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  titlePpal$: Observable<Observable<string> | string>;
  title: Observable<BreadcrumbMeta[]> | undefined;
  mobileBackUrl$: Observable<boolean>;
  lastCrumb$: any;
  template$: Observable<string | undefined> = this.cmsService
    .getCurrentPage()
    .pipe(
      tap((page) => this.resolveClassForHost(page)),
      tap((page) => {
        if (page?.pageId == 'landingPromeclub') {
          this.el?.nativeElement?.parentNode?.classList?.add('promeclub-form');
        } else {
          setTimeout(() => {
            this.el?.nativeElement?.parentNode?.classList?.remove(
              'promeclub-form'
            );
          }, 1000);
        }
      }),
      pluck('template'),
      tap((template) => {
        if (template != 'SearchResultsListPageTemplate') {
          this.el?.nativeElement.classList.remove('empty-search');
        }
      })
    );
  pageId$: Observable<string | undefined> = this.cmsService
    .getCurrentPage()
    .pipe(pluck('pageId'));
  isReviewSubmitActive$: Observable<boolean>;
  checkoutStepType = CheckoutStepType;
  searchResultsEmpty$: Observable<boolean> = this.customBreadcrumbService
    .getEmptySearchPage()
    .pipe(
      tap((value) => {
        if (value) {
          this.el?.nativeElement?.classList?.add('empty-search');
        } else {
          this.el?.nativeElement.classList.remove('empty-search');
        }
      })
    );
  zeroResults$: Observable<boolean> =
    this.customBreadcrumbService.getZeroResults();
  constructor(
    private customReviewSubmitService: CustomReviewSubmitService,
    protected checkoutStepService: CheckoutStepService,
    protected componentData: CmsComponentData<CmsBreadcrumbsComponent>,
    protected pageMetaService: PageMetaService,
    translation: TranslationService,
    protected cmsService: CmsService,
    protected customBreakpointService: CustomBreakpointService,
    protected el: ElementRef,
    protected location: Location,
    protected customBreadcrumbService: CustomBreadcrumbService
  ) {
    super(componentData, pageMetaService, translation);
  }
  ngOnInit(): void {
    this.titlePpal$ = this.customBreadcrumbService.resolvePrincipalTitle();
    this.lastCrumb$ = this.pageMetaService.getMeta().pipe(pluck('heading'));
    this.currentPage$ = this.customBreadcrumbService.getCurrentPage();

    this.mobileBackUrl$ = combineLatest([
      this.cmsService.getCurrentPage(),
      this.isMobile$,
    ]).pipe(
      tap(([page, isMobile]) => {
        if (page?.template != 'SearchResultsListPageTemplate') {
          this.customBreadcrumbService.setEmptySearchPage(false);
          this.el?.nativeElement.classList.remove('empty-search');
        }
      }),
      map(([page, isMobile]) => {
        if (
          page?.template === 'CategoryPageTemplate' ||
          page?.template === 'ProductGridPageTemplate' ||
          page?.template === 'SearchResultsListPageTemplate' ||
          page?.template === 'AccountPageTemplate' ||
          page?.template === 'ErrorPageTemplate' ||
          (page?.template === 'MultiStepCheckoutSummaryPageTemplate' &&
            !isMobile)
        ) {
          this.el?.nativeElement?.parentNode?.classList?.add('category-page');
          this.el?.nativeElement?.parentNode?.classList?.remove(
            'checkout-page'
          );
          return true;
        } else if (
          page.template === 'MultiStepCheckoutSummaryPageTemplate' &&
          isMobile
        ) {
          this.el?.nativeElement?.parentNode?.classList?.add('checkout-page');
          this.el?.nativeElement?.parentNode?.classList?.remove(
            'category-page'
          );
          return true;
        } else {
          this.el?.nativeElement?.parentNode?.classList?.remove(
            'category-page'
          );
          this.el?.nativeElement?.parentNode?.classList?.remove(
            'checkout-page'
          );
          return false;
        }
      })
    );

    this.isReviewSubmitActive$ =
      this.customReviewSubmitService.getIsReviewSubmitActive();
  }

  goBack() {
    this.location.back();
  }

  getCheckoutStepUrl(stepType: CheckoutStepType): string | undefined {
    const step = this.checkoutStepService.getCheckoutStep(stepType);
    return step && step.routeName;
  }

  getBackToStepTwo() {
    this.customReviewSubmitService.setIsReviewSubmitActive(false);
  }

  resolveClassForHost(page: Page): void {
    if (page?.template === 'CartPageTemplate') {
      this.el?.nativeElement?.parentNode?.classList?.add('category-page');
      this.el?.nativeElement.classList.remove('empty-search');
    }
    if (
      (page?.template !== 'ProductGridPageTemplate' &&
        page?.template !== 'SearchResultsListPageTemplate') ||
      window?.location?.href?.includes('plpType=variant')
    ) {
      this.el?.nativeElement?.classList?.remove('with-title');
      this.el?.nativeElement.classList.remove('empty-search');
    } else {
      this.el?.nativeElement?.classList?.add('with-title');
    }
    if (
      page?.pageId == 'registerPromesaClient' ||
      page?.pageId == 'register' ||
      page?.pageId == 'registerNonPromesaClient' ||
      page?.pageId == 'registerInternalPromesaClient'
    ) {
      this.el?.nativeElement?.classList?.add('register');
    } else {
      this.el?.nativeElement?.classList?.remove('register');
    }
  }
}
