import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomCategoryNavigationUiModule } from '../custom-category-navigation-ui/custom-category-navigation-ui.module';
import { CustomCategoryNavigationComponent } from './custom-category-navigation.component';



@NgModule({
  declarations: [
    CustomCategoryNavigationComponent
  ],
  imports: [
    CommonModule,
    CustomCategoryNavigationUiModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CategoryNavigationComponent: {
          component: CustomCategoryNavigationComponent,
          data: {
            resetMenuOnClose: true,
          },
        },
      },
    }),
  ]
})
export class CustomCategoryNavigationModule { }
