import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '@spartacus/storefront';
import { CustomTwilioValidatorService } from '../../services/custom-twilio-validator.service';
import { Subscription, Observable, timer, BehaviorSubject } from 'rxjs';
import { take, debounceTime, tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { CustomBreakpointService } from '../../../services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-pin-dialog',
  templateUrl: './custom-pin-dialog.component.html',
  styleUrls: ['./custom-pin-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomPinDialogComponent implements OnInit {
  @ViewChild('characterOne') one: ElementRef;
  @ViewChild('characterTwo') two: ElementRef;
  @ViewChild('characterThree') three: ElementRef;
  @ViewChild('characterFour') four: ElementRef;
  @ViewChild('characterFive') five: ElementRef;
  @ViewChild('characterSix') six: ElementRef;
  @ViewChild('submitButton') submitButton: ElementRef;
  pinForm: FormGroup = this.fb.group(
    {
      characterOne: ['', [Validators.required]],
      characterTwo: ['', [Validators.required]],
      characterThree: ['', [Validators.required]],
      characterFour: ['', [Validators.required]],
      characterFive: ['', [Validators.required]],
      characterSix: ['', [Validators.required]],
    },
    { updateOn: 'change' }
  );
  static readonly SECONDS_TO_RESEND = 60;
  cellphone: string;
  email: string;
  subscription: Subscription = new Subscription();
  pinStatus$: Observable<boolean | null>;
  timerStart: number = CustomPinDialogComponent.SECONDS_TO_RESEND;
  countDown$: Observable<number> = timer(0, 1000).pipe(
    take(CustomPinDialogComponent.SECONDS_TO_RESEND),
    map((n) => this.timerStart - n)
  );
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  fromPlaceOrderButton: boolean = false;
  disableButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  constructor(
    private modalService: ModalService,
    private fb: FormBuilder,
    private customTwilioValidatorService: CustomTwilioValidatorService,
    private customBreakpointService: CustomBreakpointService
  ) {}

  ngOnInit(): void {
    this.addClass('modal-without-space');

    this.pinStatus$ = this.customTwilioValidatorService
      .getValidationStatus()
      .pipe(map((status) => this.returnPinStatus(status)));

    if (this.fromPlaceOrderButton) {
      this.subscription.add(
        this.customTwilioValidatorService
          .sendCodeToPhoneUsingEmail(this.email)
          .subscribe()
      );
    }
  }

  closeModal(reason: string) {
    this.modalService.dismissActiveModal(reason);
    if (reason !== 'validation-ssucceful') {
      this.customTwilioValidatorService.setValidationStatus('');
    }
  }

  requestNewPin() {
    if (!!this.cellphone) {
      this.subscription.add(
        this.customTwilioValidatorService
          .sendCodeToPhone(this.cellphone)
          .subscribe()
      );
    } else {
      this.subscription.add(
        this.customTwilioValidatorService
          .sendCodeToPhoneUsingEmail(this.email)
          .subscribe()
      );
    }
    this.countDown$ = timer(0, 1000).pipe(
      take(CustomPinDialogComponent.SECONDS_TO_RESEND),
      map((n) => this.timerStart - n)
    );
  }

  pinSubmit() {
    this.submitButton?.nativeElement?.blur();
    this.disableButton$.next(true);
    if (this.pinForm.invalid) {
      this.disableButton$.next(false);
      return;
    }
    let code =
      this.pinForm.get('characterOne')?.value +
      this.pinForm.get('characterTwo')?.value +
      this.pinForm.get('characterThree')?.value +
      this.pinForm.get('characterFour')?.value +
      this.pinForm.get('characterFive')?.value +
      this.pinForm.get('characterSix')?.value;

    this.subscription.add(this.validatePinSubscription(!!this.cellphone, code));
    code = '';
  }

  returnPinStatus(status: string): boolean | null {
    if (status === 'OK') {
      return true;
    } else if (status === 'FALSE') {
      return false;
    }
    return null;
  }

  focusNextInput(input: string, e: KeyboardEvent) {
    if (e?.code === 'Delete' || e?.code === 'Backspace') {
      this.customTwilioValidatorService.setValidationStatus('');
      return;
    }
    if (e?.code === 'ArrowLeft') {
      switch (input) {
        case 'one':
          break;
        case 'two':
          this.one?.nativeElement?.focus();
          break;
        case 'three':
          this.two?.nativeElement?.focus();
          break;
        case 'four':
          this.three?.nativeElement?.focus();
          break;
        case 'five':
          this.four?.nativeElement?.focus();
          break;
        case 'six':
          this.five?.nativeElement?.focus();
          break;
        case 'button':
          this.six?.nativeElement?.focus();
          break;
        default:
          break;
      }
    } else if (e?.code == 'Enter' || e?.code == 'NumpadEnter') {
      if (input == 'six') {
        //this.pinSubmit();
      }
    } else {
      switch (input) {
        case 'one':
          this.two?.nativeElement?.focus();
          break;
        case 'two':
          this.three?.nativeElement?.focus();
          break;
        case 'three':
          this.four?.nativeElement?.focus();
          break;
        case 'four':
          this.five?.nativeElement?.focus();
          break;
        case 'five':
          this.six?.nativeElement?.focus();
          break;
        case 'six':
          this.submitButton?.nativeElement?.focus();
          break;
        default:
          break;
      }
    }
  }

  keydownInput(input: string, e: KeyboardEvent) {
    if (e?.code === 'Backspace') {
      switch (input) {
        case 'one':
          break;
        case 'two':
          if (this.pinForm.get('characterTwo')?.value == '')
            this.one?.nativeElement?.focus();
          break;
        case 'three':
          if (this.pinForm.get('characterThree')?.value == '')
            this.two?.nativeElement?.focus();
          break;
        case 'four':
          if (this.pinForm.get('characterFour')?.value == '')
            this.three?.nativeElement?.focus();
          break;
        case 'five':
          if (this.pinForm.get('characterFive')?.value == '')
            this.four?.nativeElement?.focus();
          break;
        case 'six':
          if (this.pinForm.get('characterSix')?.value == '')
            this.five?.nativeElement?.focus();
          break;
        default:
          break;
      }
    }
  }

  validateResponse(response: string) {
    if (response === 'OK') {
      this.customTwilioValidatorService.setValidationStatus('OK');
      this.closeModal('validation-ssucceful');
    } else {
      this.customTwilioValidatorService.setValidationStatus('FALSE');
    }
  }
  validatePinSubscription(isCellphone: boolean, code: string) {
    return isCellphone
      ? this.customTwilioValidatorService
          .checkCode(this.cellphone, code)
          .pipe(
            debounceTime(1000),
            tap(() => this.disableButton$.next(false))
          )
          .subscribe((response: string) => {
            this.validateResponse(response);
          })
      : this.customTwilioValidatorService
          .checkCodeCustomer(this.email, code)
          .pipe(
            debounceTime(1000),
            tap(() => this.disableButton$.next(false))
          )
          .subscribe((response) => {
            this.validateResponse(response);
          });
  }

  addClass(className: string) {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add(className);
  }

  removeClass(className: string) {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove(className);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.removeClass('modal-without-space');
  }
}
