import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICON_TYPE, ModalService } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-modal-generic',
  templateUrl: './custom-modal-generic.component.html',
  styleUrls: ['./custom-modal-generic.component.scss']
})
export class CustomModalGenericComponent implements OnInit {
  iconTypes = ICON_TYPE;
  @Output() closeModal = new EventEmitter<boolean>();
  @Input() isClose: boolean = false;
  @Input() isPopUpV2: boolean = false;
  @Input() titlePopUp: string;
  @Input() iconHeaderType: string;
  @Input() iconHeaderClass: string;

  constructor(protected modalService: ModalService) { }

  ngOnInit(): void {
  }

  onCloseModal() {
    this.closeModal.emit();
  }

  customDismissModal(reason?: any): void {
    this.modalService.dismissActiveModal(reason);
  }

}
