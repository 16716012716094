import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AuthActions, CartActions } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomCheckoutDeliveryStepsService } from '../../services/custom-checkout-delivery-steps.service';
import { CustomCheckoutPaymentStepService } from '../../services/custom-checkout-payment-step.service';
import { CustomBaseSiteCheckerService } from '../../../../services/custom-base-site-checker.service';
import { CheckoutActions } from '@spartacus/checkout/core';
import { CustomPlaceOrderErrorsService } from '../../services/custom-place-order-errors.service';

@Injectable()
export class CustomCheckoutEffects {

  @Effect({ dispatch: false })
  clearCheckoutDataOnLogout$: Observable<any> = this.actions$.pipe(
    ofType(AuthActions.LOGOUT),
    map((action) => {
      const isPromeclub = this.customBaseSiteCheckerService.isInPromeClub();
      this.customCheckoutDeliveryStepsService.cleanDeliveryMethod(true);
      this.customCheckoutDeliveryStepsService.cleanCenterDistribution();
      if(!isPromeclub){
        this.customCheckoutPaymentStepService.cleanStepSelection();
      }
    })
  );

  @Effect({ dispatch: false })
  addEntry$: Observable<any> = this.actions$.pipe(
    ofType(CartActions.CART_ADD_ENTRY),
    map((action: CartActions.CartAddEntry) => {
      const isPromeclub = this.customBaseSiteCheckerService.isInPromeClub();
      this.customCheckoutDeliveryStepsService.cleanDeliveryMethod(false);
      this.customCheckoutDeliveryStepsService.cleanCenterDistribution();
      if(!isPromeclub){
        this.customCheckoutPaymentStepService.cleanStepSelection();
      }
    })
  );

  @Effect({ dispatch: false })
  removeEntry$: Observable<any> = this.actions$.pipe(
    ofType(CartActions.CART_REMOVE_ENTRY),
    map((action: CartActions.CartRemoveEntry) => {
      const isPromeclub = this.customBaseSiteCheckerService.isInPromeClub();
      this.customCheckoutDeliveryStepsService.cleanDeliveryMethod(false);
      this.customCheckoutDeliveryStepsService.cleanCenterDistribution();
      if(!isPromeclub){
        this.customCheckoutPaymentStepService.cleanStepSelection();
      }
    })
  );

  @Effect({ dispatch: false })
  updateEntry$: Observable<any> = this.actions$.pipe(
    ofType(CartActions.CART_UPDATE_ENTRY),
    map((action: CartActions.CartUpdateEntry) => {
      const isPromeclub = this.customBaseSiteCheckerService.isInPromeClub();
      this.customCheckoutDeliveryStepsService.cleanDeliveryMethod(false);
      this.customCheckoutDeliveryStepsService.cleanCenterDistribution();
      if(!isPromeclub){
        this.customCheckoutPaymentStepService.cleanStepSelection();
      }

    })
  );

  @Effect({ dispatch: false })
  customPlaceOrder$: Observable<any> = this.actions$.pipe(
    ofType(CheckoutActions.PLACE_ORDER_FAIL),
    map((action: any) => {
      if(action?.payload?.details?.[0]?.message){
        this.customPlaceOrderErrorsService.setPlaceOrderErrorMessage(action?.payload?.details?.[0]?.message);
      }
      if(action?.payload?.details?.[0]?.type === 'PromeclubStockError'){
        this.customPlaceOrderErrorsService.setPlaceOrderErrorType(action?.payload?.details?.[0]?.type);
      }
    }),
  );

  constructor(
    private actions$: Actions,
    private customCheckoutDeliveryStepsService: CustomCheckoutDeliveryStepsService,
    protected customCheckoutPaymentStepService: CustomCheckoutPaymentStepService,
    protected customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    private customPlaceOrderErrorsService: CustomPlaceOrderErrorsService
  ) {}
}
