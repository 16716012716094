<div class="quote-checkout-cancel">
  <app-custom-modal-generic></app-custom-modal-generic>

  <div class="quote-checkout-cancel__icon">
    <cx-icon class="fas fa-exclamation-triangle"></cx-icon>
  </div>
  <div
    class="quote-checkout-cancel__title"
    [innerHTML]="
      'accountQuotes.checkoutCancel.title'
        | cxTranslate : { code: code }
        | safeHtml
    "
  ></div>

  <div
    class="quote-checkout-cancel__message"
    [innerHTML]="
      'accountQuotes.checkoutCancel.message' | cxTranslate | safeHtml
    "
  ></div>

  <div class="quote-checkout-cancel__action">
    <div class="quote-checkout-cancel__action-link" (click)="removeCart()">
      {{ "accountQuotes.checkoutCancel.cancel" | cxTranslate }}
    </div>
    <button
      class="quote-checkout-cancel__button"
      (click)="closeModal('continue')"
    >
      {{ "accountQuotes.checkoutCancel.continueCheckout" | cxTranslate }}
    </button>
  </div>
</div>
