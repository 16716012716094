import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  GlobalMessageService,
  GlobalMessageType,
  ProductActions,
} from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { tap } from 'rxjs/operators';
import { CustomConfirmatedReviewDialogComponent } from '../components/custom-product-tabs/custom-product-reviews/custom-confirmated-review-dialog/custom-confirmated-review-dialog.component';

@Injectable()
export class CustomProductReviewsEffects {
  modalRef: ModalRef;
  constructor(
    private actions$: Actions,
    private globalMessageService: GlobalMessageService,
    protected modalService: ModalService
  ) {}

  @Effect({ dispatch: false })
  showGlobalMessageOnPostProductReviewSuccess$ = this.actions$.pipe(
    ofType(ProductActions.POST_PRODUCT_REVIEW_SUCCESS),
    tap(() => {
      this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_CONFIRMATION);

      this.modalRef = this.modalService.open(
        CustomConfirmatedReviewDialogComponent,
        {
          centered: true,
          size: 'lg',
          windowClass: 'review-confirmated',
        }
      );
    })
  );
}
