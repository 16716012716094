import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, ActiveCartService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CenterDistributionService {
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
    private activeCartService: ActiveCartService
  ) {}

  getCenterDistribution(userId: string): Observable<boolean> {
    return this.activeCartService.getActiveCartId().pipe(
      filter((cartId) => !!cartId),
      switchMap((cartId) => {
        const url = this.occEndpointService.buildUrl('centerDistribution', {
          urlParams: {
            userId,
            cartId,
          },
        });
        return this.http.get<boolean>(url);
      })
    );
  }
}
