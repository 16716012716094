import {
  Component,
  Input,
  OnInit,
  Renderer2,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { CustomBreakpointService } from '../../../../../services/custom-breakpoint.service';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomBreadcrumbService } from '../../../../navigation/services/custom-breadcrumb.service';
import { CustomSubcategory } from '../../../model/custom-subcategory.interface';

@Component({
  selector: 'app-custom-product-variant-list-description',
  templateUrl: './custom-product-variant-list-description.component.html',
  styleUrls: ['./custom-product-variant-list-description.component.scss'],
})
export class CustomProductVariantListDescriptionComponent
  implements OnInit, OnDestroy
{
  isMobile: boolean;
  @Input() description: string;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  pageTitle$: Observable<Observable<string> | string>;
  domTitle: ElementRef;
  subscription: Subscription = new Subscription();
  subcategories$: Observable<CustomSubcategory[]>;
  showDescription: boolean = false;
  constructor(
    private customBreakpointService: CustomBreakpointService,
    private customBreadcrumbService: CustomBreadcrumbService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.isMobile$
        .pipe(
          tap((isMobile) => {
            this.isMobile = isMobile;
          })
        )
        .subscribe()
    );
    this.pageTitle$ = this.customBreadcrumbService.resolvePrincipalTitle();
    this.domTitle = this.renderer.selectRootElement('#domTitle', true);
    this.toggleDomTitle(false);
  }

  toggleDomTitle(show: boolean): void {
    if (this.isMobile) {
      return;
    }
    if (!show) {
      this.renderer.removeClass(this.domTitle, 'd-flex');
      this.renderer.addClass(this.domTitle, 'd-none');
    } else {
      this.renderer.removeClass(this.domTitle, 'd-none');
      this.renderer.addClass(this.domTitle, 'd-flex');
    }
  }

  showDesc() {
    this.showDescription = !this.showDescription;
  }

  ngOnDestroy(): void {
    this.toggleDomTitle(true);
    this.subscription.unsubscribe();
  }
}
