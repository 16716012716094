import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductIntroComponent } from './custom-product-intro.component';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { CustomSafeHtmlModule } from 'src/app/pipes/custom-safe-html/custom-safe-html.module';
import { CustomProductSocialShareModule } from '../custom-product-social-share/custom-product-social-share.module';
import { CustomGiftProductModule } from 'src/app/cms-components/custom/components/custom-gift-product/custom-gift-product.module';
import { CustomProductVariantsContainerModule } from '../custom-product-variants-container/custom-product-variants-container.module';
import { CustomWishlistModule } from 'src/app/cms-components/custom/components/custom-wishlist/custom-wishlist.module';
import { CustomProductReviewsOverviewModule } from 'src/app/cms-components/custom/components/custom-product-reviews-overview/custom-product-reviews-overview.module';
import { CustomAutomotiveLabelModule } from 'src/app/cms-components/custom/components/custom-automotive-label/custom-automotive-label.module';

@NgModule({
  declarations: [CustomProductIntroComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    CustomSafeHtmlModule,
    CustomProductSocialShareModule,
    CustomGiftProductModule,
    CustomProductVariantsContainerModule,
    CustomWishlistModule,
    CustomProductReviewsOverviewModule,
    CustomAutomotiveLabelModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductIntroComponent: {
          component: CustomProductIntroComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [CustomProductIntroComponent],
})
export class CustomProductIntroModule {}
