import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { GenericLinkModule, IconModule } from '@spartacus/storefront';
import { CustomCartSharedModule } from 'src/app/cms-components/cart/custom-cart-shared/custom-cart-shared.module';
import { CustomMiniCartModule } from 'src/app/cms-components/cart/custom-mini-cart/custom-mini-cart.module';
import { CustomPlaceOrderModule } from 'src/app/feature-libs/checkout/components/custom-place-order/custom-place-order.module';
import { CustomEntriesMiniCartModule } from '../custom-entries-mini-cart/custom-entries-mini-cart.module';
import { CustomCheckoutOrderSummaryComponent } from './custom-checkout-order-summary.component';
import { CustomCurrencyModule } from 'src/app/pipes/custom-currency/custom-currency.module';

@NgModule({
  declarations: [CustomCheckoutOrderSummaryComponent],
  imports: [
    CommonModule,
    RouterModule,
    GenericLinkModule,
    I18nModule,
    IconModule,
    CustomMiniCartModule,
    CustomCartSharedModule,
    CustomEntriesMiniCartModule,
    CustomPlaceOrderModule,
    CustomCurrencyModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutOrderSummary: {
          component: CustomCheckoutOrderSummaryComponent,
        },
      },
    }),
  ],
  exports: [CustomCheckoutOrderSummaryComponent],
})
export class CustomCheckoutOrderSummaryModule {}
