import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomCart } from 'src/app/cms-components/cart/model/custom-cart';

@Injectable({
  providedIn: 'root',
})
export class CustomQuoteUtilService {
  constructor(private router: Router) {}

  handleCustomCartFromQuote(cart: CustomCart) {
    if (cart?.isFromQuote) {
      this.router.navigateByUrl('/checkout/delivery-mode');
      return false;
    } else {
      return true;
    }
  }
}
