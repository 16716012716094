import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CmsService, Page, ProductSearchPage } from '@spartacus/core';
import {
  ProductFacetNavigationComponent,
  ProductListComponentService,
} from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomGtmSearchSelectionDatalayerService } from 'src/app/common/services/custom-gtm-search-selection-datalayer.service';
import { CustomBaseSiteCheckerService } from 'src/app/services/custom-base-site-checker.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomProductListComponentService } from '../services/custom-product-list-component.service';

@Component({
  selector: 'app-custom-product-facet-navigation',
  templateUrl: './custom-product-facet-navigation.component.html',
  styleUrls: ['./custom-product-facet-navigation.component.scss'],
})
export class CustomProductFacetNavigationComponent
  extends ProductFacetNavigationComponent
  implements OnInit
{
  isMobile$: Observable<boolean>;
  model$: Observable<ProductSearchPage>;
  inputSearch: string;
  pageTemplate$: Observable<Page> = this.cmsService.getCurrentPage();
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  actualViewSelectors$: Observable<string>;
  constructor(
    protected breakpointService: CustomBreakpointService,
    private productListComponentService: ProductListComponentService,
    private router: Router,
    private gtmLayoutService: CustomGtmSearchSelectionDatalayerService,
    private cmsService: CmsService,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    private customProductListComponentService: CustomProductListComponentService
  ) {
    super(breakpointService);
  }

  ngOnInit(): void {
    this.model$ = this.productListComponentService.model$.pipe(
      tap((data: any) => {
        this.gtmLayoutService.searchEvent(
          data.freeTextSearch,
          data.products.length > 0
        );
      })
    );
    this.isMobile$ = this.breakpointService.isMobile$;
    this.actualViewSelectors$ =
      this.customProductListComponentService.actualViewSelectors$;
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  searchProducts(query: any, search: any) {
    if (search === undefined) search = '';

    let newQuery: string;
    const arrQuery = query.query?.value.split(':');
    let plpType;
    plpType = '&plpType=classic';
    if (this.router.url.includes('variant')) {
      plpType = '&plpType=variant&searchQueryContext=VARIANTS';
    }
    if (!arrQuery[0]) {
      newQuery = `?query=${search}${query.query?.value}`;
    } else {
      arrQuery[0] = search;
      newQuery = `?query=${arrQuery.join(':')}`;
    }
    const arrRouterUrl = this.router.url.split('/');
    if (arrRouterUrl[arrRouterUrl.length - 2] == 'c') {
      const arrRouteUrlWithoutQuery = this.router.url.split('?');
      this.router.navigateByUrl(
        `${arrRouteUrlWithoutQuery[0]}${newQuery}${plpType}`
      );
    } else {
      arrRouterUrl[arrRouterUrl.length - 1] = search;
      this.router.navigateByUrl(
        `${arrRouterUrl.join('/')}${newQuery}${plpType}`
      );
    }
  }
  get hasTrigger() {
    return window.innerWidth < 992;
  }
}
