import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomModalService } from './services/custom-modal.service';

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss'],
})
export class CustomModalComponent {
  constructor(private customModalService: CustomModalService) {}
  @Input('instance') instance: string;
  @Input() modalTitle: string;
  @Output() closeAndReset = new EventEmitter<string>();
  @Input() closeVisible: boolean = true;

  reset = 'reset';

  visibiliy$ = this.customModalService.visibility$;

  close(instance: string): void {
    this.customModalService.hide(instance);
    this.closeAndReset.emit(this.reset);
  }

  stopPropagation(e: any): void {
    e.stopPropagation();
  }
}
