import { Component, ViewEncapsulation } from '@angular/core';
import { ModalService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-payment-credit-dialog',
  templateUrl: './custom-payment-credit-dialog.component.html',
  styleUrls: ['./custom-payment-credit-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomPaymentCreditDialogComponent {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;

  constructor(
    private modalService: ModalService,
    private customBreakpointService: CustomBreakpointService
  ) {}

  closeModal(reason: string) {
    this.modalService.dismissActiveModal(reason);
  }

  continue(reason: string) {
    this.closeModal(reason);
  }
}
