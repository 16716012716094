import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Cart, OrderEntry, PaginationModel } from '@spartacus/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CustomOrderPaginationService } from '../../../services/custom-order-pagination.service';
import { QUOTE_STATE } from '../constansts/quotes-constansts';
import { CustomQuoteModalService } from '../services/custom-quote-modal.service';
import { CustomQuoteOrchestratorService } from '../services/custom-quote-orchestrator.service';
import { CustomQuotesService } from '../services/custom-quotes.service';

@Component({
  selector: 'app-custom-quotes-entry-list',
  templateUrl: './custom-quotes-entry-list.component.html',
  styleUrls: ['./custom-quotes-entry-list.component.scss'],
})
export class CustomQuotesEntryListComponent implements OnInit, OnDestroy {
  @Input() isMobile: boolean = false;
  @Input() isInDetail: boolean = false;
  @Input() isInOffer: boolean = false;
  @Input() cart: Cart;
  selectedPage: number;
  quotesDetails: any[] = [];
  quotesDetailsShow: any[] | OrderEntry[] | undefined;
  PAGE_SIZE: number = 10000;
  allRejected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  allAccepted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  pagination: PaginationModel = {
    currentPage: 0,
    pageSize: this.PAGE_SIZE,
    sort: '',
    totalPages: 0,
    totalResults: 0,
  };
  quantities: any[] = [
    { id: 10, name: 10, disabled: true },
    { id: 25, name: 25, disabled: false },
    { id: 50, name: 50, disabled: true },
    { id: 100, name: 100, disabled: true },
    { id: 10000, name: 'Todos', disabled: true },
  ];
  pageSize: number = 10000;
  isInReview$: Observable<boolean> =
    this.customQuoteOrchestrator.getGoToSummary();
  itemsInReview$: Observable<number> = this.customQuoteOrchestrator
    .getItemsInReview()
    .pipe(
      tap((inReview) => {
        let countRejected = 0;
        let countAccepted = 0;
        this.cart.entries?.map((entry: any) => {
          if (entry?.quoteAcceptedStatus == QUOTE_STATE.REFUSED) {
            countRejected = countRejected + 1;
          }
          if (entry?.quoteAcceptedStatus == QUOTE_STATE.ACCEPTED) {
            countAccepted = countAccepted + 1;
          }
        });
        if (inReview == countRejected) {
          this.allRejected$.next(true);
        } else if (inReview == countAccepted) {
          this.allAccepted$.next(true);
        }
      })
    );

  filterForm: FormGroup = this.fb.group(
    {
      all: [true],
      byReview: [false],
    },
    { updateOn: 'change' }
  );
  sortByReview: boolean = false;
  unsubscribe$: Subject<void> = new Subject();
  constructor(
    private customOrderPaginationService: CustomOrderPaginationService,
    private customQuoteOrchestrator: CustomQuoteOrchestratorService,
    private fb: FormBuilder,
    private customQuoteModalService: CustomQuoteModalService,
    private customQuotesService: CustomQuotesService
  ) {}

  ngOnInit(): void {
    if (this.cart) {
      this.selectedPage = this.pagination.currentPage!;
      this.pagination.totalResults = this.cart?.entries?.length;
      this.pagination.totalPages = this.setPaginationTotalPages(
        this.cart?.entries?.length!,
        this.pagination?.pageSize!
      );
      this.entries(this.cart?.entries!);
    }
  }

  entries(entries?: any[], value?: number) {
    entries ? (this.quotesDetails = entries) : this.quotesDetails;
    if (this.pagination.currentPage == 0) {
      this.quotesDetailsShow = this.quotesDetails?.slice(0, this.PAGE_SIZE);
    } else {
      this.quotesDetailsShow = this.quotesDetails?.slice(
        value,
        value! + this.PAGE_SIZE
      );
    }
  }

  customPageChange(event: any) {
    this.pageChange(event);
    this.selectedPage < this.pagination.pageSize!
      ? (this.selectedPage = this.pagination.currentPage!)
      : (this.selectedPage = this.pagination.currentPage!);
    this.entries(this.quotesDetails, this.PAGE_SIZE * event);
  }

  pageChange(page: number) {
    this.pagination.currentPage = page;
    this.customOrderPaginationService.onPageChange(this.pagination);
  }

  filterQuantitySelected(filter: any) {
    this.pageSize = filter;
    this.PAGE_SIZE = filter;
    this.pagination.pageSize = filter;
    this.selectedPage = 0;
    this.pagination.currentPage = 0;
    this.pagination.totalResults = this.cart?.entries?.length;
    this.pagination.totalPages = this.setPaginationTotalPages(
      this.cart?.entries?.length!,
      this.pagination?.pageSize!
    );
    this.entries(this.cart?.entries!);
    this.customOrderPaginationService.onPageChange(this.pagination);
    if (this.sortByReview) {
      this.filterByReview(true);
    }
  }

  filterByReview(value: boolean) {
    this.sortByReview = value;
    if (this.sortByReview) {
      this.quotesDetailsShow = this.cart?.entries?.filter(
        (entry: any) => !!entry?.requestedPrice
      );
      this.selectedPage = 0;
      this.pagination.currentPage = 0;
      this.pagination.totalResults = this.quotesDetailsShow?.length;
      this.pagination.totalPages = this.setPaginationTotalPages(
        this.quotesDetailsShow?.length!,
        this.pagination?.pageSize!
      );
      this.customOrderPaginationService.onPageChange(this.pagination);
    } else {
      this.selectedPage = 0;
      this.pagination.currentPage = 0;
      this.pagination.totalResults = this.cart?.entries?.length;
      this.pagination.totalPages = this.setPaginationTotalPages(
        this.cart?.entries?.length!,
        this.pagination?.pageSize!
      );
      this.entries(this.cart.entries);
      this.customOrderPaginationService.onPageChange(this.pagination);
    }
  }

  openRejectAllModal() {
    this.customQuoteModalService.openRejectModal(this.cart.code!);
  }

  acceptAll() {
    let quoteData = {
      accept: true,
    };
    this.customQuotesService
      .acceptRejectQuoteProposal(this.cart.code!, quoteData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() =>
        this.customQuoteOrchestrator.setRefreshQuoteDetail(true)
      );
  }

  setPaginationTotalPages(length: number, size: number): number {
    const result = length / size;

    return Number.isInteger(result)
      ? Math.round(result)
      : Math.round(result + 0.5);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
