import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Observable } from 'rxjs';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-custom-empty-cart',
  templateUrl: './custom-empty-cart.component.html',
  styleUrls: ['./custom-empty-cart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomEmptyCartComponent implements OnInit, OnDestroy {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  isInPromeclub = this.service.isInPromeClub();
  @Input() fromWishlist: boolean = false;
  @Input() fromMyAccount: boolean = false;

  constructor(
    private customBreakpointService: CustomBreakpointService,
    private service: CustomNavigationService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.addClassToFooterElement();
  }

  addClassToFooterElement(destroy: boolean = false): void {
    const footer = this.document.getElementsByTagName('footer')[0];
    if (!destroy) {
      footer?.classList?.add('empty-cart-footer');
    } else {
      footer?.classList?.remove('empty-cart-footer');
    }
  }

  ngOnDestroy(): void {
    this.addClassToFooterElement(true);
  }
}
