<div class="wishlist__header" >
  <p class="wishlist__header-item__image-space"></p>
  <p class="wishlist__header-item sku">
      {{"customWishlist.code" | cxTranslate}}
  </p>
  <p class="wishlist__header-item name">
    {{ "customWishlist.product" | cxTranslate }}
  </p>
  <p class="wishlist__header-item points">
    {{ "customWishlist.unitPoints" | cxTranslate}}
  </p>
  <p  class="wishlist__header-item counter">
    {{ "customWishlist.quantity" | cxTranslate }}
  </p>
  <p class="wishlist__header-item points">
    {{"customWishlist.totalPoints" | cxTranslate}}
  </p>
  <p class="wishlist__header-item add">
    {{"customWishlist.addToCart" | cxTranslate}}
  </p>
  <p class="wishlist__header-item trash"></p>
</div>
