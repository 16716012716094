import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoutingService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { CustomClaimAddService } from '../../../services/custom-claim-add.service';

@Component({
  selector: 'app-custom-claim-add',
  templateUrl: './custom-claim-add.component.html',
  styleUrls: ['./custom-claim-add.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomClaimAddComponent {
  @Input() order: any;
  claimForm: FormGroup = this.fb.group({
    motive: [null, Validators.required],
    comments: ['', Validators.required],
  });

  subscription: Subscription = new Subscription();

  reasons$: Observable<any> = this.addClaimService.getReasonsClaim();
  stepOne$: Observable<boolean> = this.addClaimService.getStepOneStatus();

  constructor(
    private fb: FormBuilder,
    private addClaimService: CustomClaimAddService,
    private routingService: RoutingService
  ) {}

  returnPage(): void {
    this.routingService.go({
      cxRoute: 'orderDetails',
      params: this.order,
    });
  }
  submitForm(): void {
    if (this.claimForm.valid) {
      const motive = this.claimForm.get('motive')?.value;
      const comments = this.claimForm.get('comments')?.value;

      this.addClaimService.setclaimForm({
        subject: motive.value,
        ticketCategory: motive.key,
        message: comments,
      });
      this.addClaimService.setStepOneStatus(false);
      this.addClaimService.setStepTwoStatus(true);
    }
  }
}
