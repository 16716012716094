import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule
} from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomModalGenericModule } from 'src/app/cms-components/custom/components/custom-modal/custom-modal-generic.module';
import { CustomOrderOverviewModule } from 'src/app/cms-components/custom/components/custom-order-overview/custom-order-overview.module';
import { CustomTitleMyAccountModule } from 'src/app/cms-components/custom/components/custom-title-my-account/custom-title-my-account.module';
import { CustomOrderRegisteredSuccessfullyDialogComponent } from './custom-order-registered-successfully-dialog/custom-order-registered-successfully-dialog.component';
import { CustomPaymentRecordComponent } from './custom-payment-record.component';

@NgModule({
  declarations: [CustomPaymentRecordComponent, CustomOrderRegisteredSuccessfullyDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    IconModule,
    I18nModule,
    UrlModule,
    RouterModule,
    CustomTitleMyAccountModule,
    CustomOrderOverviewModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    CustomModalGenericModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        AccountPaymentRecordComponent: {
          component: CustomPaymentRecordComponent,
        },
      },
    }),
  ],
})
export class CustomPaymentRecordModule {}
