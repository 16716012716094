import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Order, PaginationModel, RoutingService } from '@spartacus/core';
import { ICON_TYPE } from '@spartacus/storefront';
import { saveAs } from "file-saver";
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { CustomFilterComponent } from 'src/app/cms-components/custom/components/custom-filter/custom-filter.component';
import { CustomUserPointsService } from 'src/app/services/custom-user-points.service';
import { CustomBreakpointService } from '../../../../../services/custom-breakpoint.service';
import { CustomPromeclubAccountStatusService } from '../../../services/custom-promeclub-account-status.service';

@Component({
  selector: 'app-custom-account-status-promeclub',
  templateUrl: './custom-account-status-promeclub.component.html',
  styleUrls: ['./custom-account-status-promeclub.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomAccountStatusPromeclubComponent implements OnInit {

  @ViewChild('customFilterComponent') customFilterComponent: CustomFilterComponent;
  iconTypes = ICON_TYPE;
  selectedPage: number = 0;
  years: number[] = [];
  quantities: any[] = [
    { id: 25, name: 25, disabled: true },
    { id: 50, name: 50, disabled: true },
    { id: 100, name: 100, disabled: true },
    { id: 200, name: 200, disabled: true },
    { id: 10000, name: 'Todos', disabled: true }
  ];
  pageSize: number = 10000;
  promeclubOrders$: Observable<any>;
  availablePoints$: Observable<number> = this.customUserPointsService.getUserPoints().pipe(
    map(userPoints => userPoints?.pointsData?.availablePoints!),
  );
  uploadType: number = 1;
  exchangeType: number = 2;
  downloadType: number = 3;
  selectedTab: number = 2
  totalPages: number = 1;
  promeclubPagination: PaginationModel;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    private routing: RoutingService,
    private customPromeclubAccountStatusService: CustomPromeclubAccountStatusService,
    private customUserPointsService: CustomUserPointsService,
    private customBreakpointService: CustomBreakpointService
  ) { }
  unsubscribe$: Subject<any> = new Subject();


  ngOnInit(): void {
    this.promeclubOrders$ = this.customPromeclubAccountStatusService.getOrderStatus(this.selectedTab).pipe(
      tap(orders => {
        this.setPromeclubPagination(orders);
      })
    );
  }

  downloadAccountStatus() {
    this.customPromeclubAccountStatusService
      .downloadAccountStatus()
      .pipe(
        takeUntil(this.unsubscribe$),
        map((resp) => {
          const filename = `accountStatus.xlsx`;
          const blob = new Blob([resp.body], {
            type: 'application/xml',
          });

          const blobURL = window.URL.createObjectURL(blob);
          const tempLink = document.createElement('a');
          tempLink.style.display = 'none';
          tempLink.href = blobURL;
          tempLink.setAttribute('target', '_blank');
          saveAs(blob, filename);
        })
      )
      .subscribe();
  }

  customPageChangeSelected(page: number, pagination?: PaginationModel): void {
    this.selectedPage = page;
    this.pageChange(page);
  }


  goToOrderDetail(order: Order): void {
    if (this.selectedTab === this.exchangeType) {
      this.routing.go({
        cxRoute: 'orderDetails',
        params: order,
      });
    }

  }


  pageChange(page: number): void {
    this.selectedPage = page;
    this.promeclubPagination.currentPage = page;
    this.filterOrderPageChange()
  }


  changeStatusRequest(type: number) {
    this.selectedTab = type;
    this.pageSize = this.quantities[this.quantities.length - 1].id;
    this.promeclubOrders$ = this.customPromeclubAccountStatusService.getOrderStatus(type).pipe(
      tap(orders => {
        this.setPromeclubPagination(orders);
      })
    );;

  }

  filterOrders(filter: any) {
    this.pageSize = filter;
    this.selectedPage = 0;
    this.promeclubOrders$ = this.customPromeclubAccountStatusService.getOrderStatus(this.selectedTab).pipe(
      map(orders => {
        this.setPromeclubPagination(orders);
        if (orders?.orders?.length > this.pageSize) {
          this.totalPages = Math.ceil(orders?.orders?.length / this.pageSize);
          return { orders: orders?.orders?.slice(0, this.pageSize) }
        }
        return orders;
      })
    );
  }

  filterOrderPageChange() {
    this.promeclubOrders$ = this.customPromeclubAccountStatusService.getOrderStatus(this.selectedTab).pipe(
      map(orders => {
        if (orders?.orders?.length > this.pageSize) {
          this.totalPages = Math.ceil(orders?.orders?.length / this.pageSize);
          let sliceBegin = this.promeclubPagination.pageSize! * this.promeclubPagination.currentPage!;
          let sliceEnd = sliceBegin + this.pageSize;
          return { orders: orders?.orders?.slice(sliceBegin, sliceEnd) }
        }
        return orders;
      })
    );
  }

  setPromeclubPagination(orders: any) {
    this.promeclubPagination = {
      currentPage: 0,
      pageSize: this.pageSize,
      totalPages: Math.ceil(orders?.orders?.length / this.pageSize),
      totalResults: orders?.orders?.length
    }
  }
}
