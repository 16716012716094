import { Component, ViewEncapsulation } from '@angular/core';
import { Order } from '@spartacus/core';
import {
  CancelOrderConfirmationComponent,
  OrderAmendService,
  OrderDetailsService,
} from '@spartacus/order/components';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-order-cancel-confirmation',
  templateUrl: './custom-order-cancel-confirmation.component.html',
  styleUrls: ['./custom-order-cancel-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomOrderCancelConfirmationComponent extends CancelOrderConfirmationComponent {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  order$: Observable<Order> = this.orderDetailsService.getOrderDetails();
  constructor(
    orderAmendService: OrderAmendService,
    protected orderDetailsService: OrderDetailsService,
    private customBreakpointService: CustomBreakpointService
  ) {
    super(orderAmendService);
  }
}
