import { Component } from '@angular/core';
import { User } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { CustomUser } from 'src/app/feature-libs/checkout/models/custom-user';

@Component({
  selector: 'app-custom-promeclub-update-profile',
  templateUrl: './custom-promeclub-update-profile.component.html',
  styleUrls: ['./custom-promeclub-update-profile.component.scss'],
})
export class CustomPromeclubUpdateProfileComponent {
  user$: Observable<CustomUser | User | undefined> = this.userService.get();
  constructor(private userService: UserAccountFacade) {}
}
