export const myAccount = {
  closeAccount: {
    confirmAccountClosure: 'Confirmar Cierre de Cuenta',
    confirmAccountClosureMessage:
      '¿Estás seguro de que quieres cerrar tu cuenta?',
    closeMyAccount: 'CERRAR MI CUENTA',
    accountClosedSuccessfully: 'Cuenta cerrada con éxito',
    accountClosedFailure: 'No se pudo cerrar la cuenta',
  },
  updatePasswordForm: {
    oldPassword: {
      label: 'Contraseña actual',
      placeholder: 'Contraseña actual',
    },
    oldPasswordIsRequired: 'Se requiere contraseña actual.',
    newPassword: {
      label: 'Nueva contraseña',
      placeholder: 'Nueva contraseña',
    },
    passwordMinRequirements:
      'La contraseña debe tener un mínimo de seis caracteres, con una letra mayúscula, un número, un símbolo',
    confirmPassword: {
      label: 'Repetir nueva contraseña',
      placeholder: 'Repetir nueva contraseña',
    },
    bothPasswordMustMatch: 'Ambas contraseñas deben coincidir',
    passwordUpdateSuccess: 'Contraseña actualizada con éxito',
  },
  updateProfileForm: {
    title: '',
    none: '',
    firstName: {
      label: 'Nombre',
      placeholder: 'Nombre',
    },
    firstNameIsRequired: 'Se requiere el nombre.',
    lastName: {
      label: 'Apellido',
      placeholder: 'Apellido',
    },
    lastNameIsRequired: 'Se requiere apellido.',
    profileUpdateSuccess: 'Datos personales actualizados correctamente',
    customerId: 'Cliente #',
  },
  consentManagementForm: {
    clearAll: 'Limpiar todo',
    selectAll: 'Seleccionar todo',
    message: {
      success: {
        given: 'Consentimiento dado con éxito.',
        withdrawn: 'Consentimiento retirado con éxito.',
      },
    },
  },
  myCoupons: {
    noCouponsMessage: 'No tienes cupones disponibles.',
    effectiveTitle: 'Efectivo:',
    Effective: 'EFECTIVO',
    PreSession: 'EFECTIVO PRONTO',
    ExpireSoon: 'EXPIRARÁ PRONTO',
    readMore: 'Lee más',
    notification: 'Notificación',
    findProducts: 'Buscar Productos',
    status: 'Estado:',
    dialogTitle: 'Cupón',
    claimCustomerCoupon: 'Has reclamado este cupón con éxito.',
    myCoupons: 'Mis cupones',
    startDateAsc: 'Fecha de inicio (ascendente)',
    startDateDesc: 'Fecha de inicio (descendente)',
    endDateAsc: 'Fecha de finalización (ascendente)',
    endDateDesc: 'Fecha de finalización (descendente)',
    sortBy: 'Ordenar por',
    notesPreffix:
      'Puede configurar sus canales preferidos para recibir notificaciones de cupones en el',
    notesLink: 'Canales de Notificación',
    notesSuffix: ' página.',
  },
  notificationPreference: {
    message: 'Seleccione sus canales de notificación preferidos',
    note: 'Nota: ',
    noteMessage:
      'Si desactiva todos los canales, no podrá recibir más notificaciones.',
    EMAIL: 'Email:',
    SMS: 'SMS:',
    SITE_MESSAGE: 'SiteMessage',
  },
  myInterests: {
    header: 'Mis intereses',
    item: 'ARTICULO',
    price: 'PRECIO',
    notifications: 'NOTIFICACIONES',
    noInterests: 'Todavía no tiene intereses registrados.',
    inStock: 'En stock',
    lowStock: 'Stock bajo',
    outOfStock: 'Agotado',
    BACK_IN_STOCK: 'Nuevamente en Stock',
    sortBy: 'Ordenar por',
    expirationDate: ' - Hasta {{ expirationDate }}',
    productId: 'ID {{ code }}',
    remove: 'ELIMINAR',
    sorting: {
      byNameAsc: 'Nombre (ascending)',
      byNameDesc: 'Nombre (descending)',
    },
  },

  wishlist: {
    empty: 'No hay productos en su lista de deseos todavía',
  },
};
