import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomRegisterPasswordValidationsService } from '../../services/custom-register-password-validations.service';
import { CustomRegisterStepOrchestratorService } from '../../services/custom-register-step-orchestrator.service';
import { CustomRegisterFormService } from '../../services/custom-register-form.service';
import { CustomRegisterCustomerValidatorService } from '../../services/custom-register-customer-validator.service';
import { PromesaRegisterClient } from '../../models/custom-cms-register.interface';
import { Router } from '@angular/router';
import { CustomTwilioValidatorService } from '../../../../../common/services/custom-twilio-validator.service';
import { CustomUpdateProfileService } from 'src/app/cms-components/my-account/components/custom-update-profile/custom-update-profile.service';
import { CustomResetPasswordService } from '../../../custom-reset-password/custom-reset-password.service';
import { CustomUpdatePasswordService } from 'src/app/cms-components/my-account/components/custom-update-profile/custom-update-password/custom-update-password.service';
import { CustomGtmLoginService } from 'src/app/common/services/custom-gtm-login-register-datalayer.service';
import { CustomRegisterEmployeeService } from '../../services/custom-register-employee.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { CustomUser } from 'src/app/feature-libs/checkout/models/custom-user';

@Component({
  selector: 'app-custom-register-customer-password',
  templateUrl: './custom-register-customer-password.component.html',
  styleUrls: ['./custom-register-customer-password.component.scss'],
})
export class CustomRegisterCustomerPasswordComponent implements OnInit {
  showPassword: boolean = false;
  passwordType: string = 'password';
  showRepeatPassword: boolean = false;
  repeatPasswordType: string = 'password';
  notTheSamePasswordError: boolean;
  disableButton: boolean;
  isMobile$: Observable<boolean> = this.customBreakpointservice.isMobile$;
  passwordForm: FormGroup = this.fb.group(
    {
      password: [
        '',
        [
          this.customRegisterPasswordValidationsService.minLengthValidation,
          this.customRegisterPasswordValidationsService.oneLowerCaseValidation,
          this.customRegisterPasswordValidationsService.oneNumberValidation,
          this.customRegisterPasswordValidationsService
            .oneSpecialCharacterValidation,
          this.customRegisterPasswordValidationsService.oneUpperCaseValidation,
        ],
      ],
      repeatPassword: [''],
    },
    { updateOn: 'change' }
  );
  passwordStepsErrors = {
    stepOne: false,
    stepTwo: false,
    stepThree: false,
    stepFour: false,
    stepFive: false,
  };
  stepThree$: Observable<boolean> =
    this.customRegisterStepOrchestrator.getStepThreeStatus();
  subscription: Subscription = new Subscription();
  private unsubscribe$ = new Subject<void>();
  currentPassForm: FormGroup = this.customUpdateProfileService.currentPassword;
  updatePasswordForm: FormGroup =
    this.customUpdateProfileService.updatePasswordForm;
  @Input() fromMyAccount: boolean = false;
  @Input() fromResetPassword: boolean = false;
  @Input() fromRegisterEmployee: boolean = false;
  @Input() resetPasswordToken: string;
  @Input() form: FormGroup;
  @Output() updatePasswordFormEmmiter: EventEmitter<FormGroup> =
    new EventEmitter<FormGroup>();
  constructor(
    private customBreakpointservice: CustomBreakpointService,
    private fb: FormBuilder,
    private customRegisterPasswordValidationsService: CustomRegisterPasswordValidationsService,
    private customRegisterStepOrchestrator: CustomRegisterStepOrchestratorService,
    private customRegisterFormService: CustomRegisterFormService,
    private customRegisterCustomerValidatorService: CustomRegisterCustomerValidatorService,
    private router: Router,
    private customTwilioValidatorService: CustomTwilioValidatorService,
    private customResetPasswordService: CustomResetPasswordService,
    private customUpdateProfileService: CustomUpdateProfileService,
    private customUpdatePassword: CustomUpdatePasswordService,
    private dataLayerRegister: CustomGtmLoginService,
    private customRegisterEmployeeService: CustomRegisterEmployeeService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.passwordForm.valueChanges.subscribe((changes) => {
        this.passwordStepsErrors.stepOne =
          !!this.passwordForm.get('password')?.errors?.noMinLength;
        this.passwordStepsErrors.stepTwo =
          !!this.passwordForm.get('password')?.errors?.noOneNumber;
        this.passwordStepsErrors.stepThree =
          !!this.passwordForm.get('password')?.errors?.noOneUpperCaseCharacter;
        this.passwordStepsErrors.stepFour =
          !!this.passwordForm.get('password')?.errors?.noOneLowerCaseCharacter;
        this.passwordStepsErrors.stepFive =
          !!this.passwordForm.get('password')?.errors?.noOneSpecialCharacter;
        this.notTheSamePasswordError = false;
        this.disableButton = false;
      })
    );
  }

  togglePasswordView(fromRepeat: boolean = false) {
    if (!fromRepeat) {
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        this.passwordType = 'text';
      } else {
        this.passwordType = 'password';
      }
    } else {
      this.showRepeatPassword = !this.showRepeatPassword;
      if (this.showRepeatPassword) {
        this.repeatPasswordType = 'text';
      } else {
        this.repeatPasswordType = 'password';
      }
    }
  }

  passwordSubmit(
    resetPasswordToken: string
  ) {
    if (
      this.passwordForm.valid &&
      this.comparePasswords(
        this.passwordForm.get('password')?.value,
        this.passwordForm.get('repeatPassword')?.value
      ) &&
      !resetPasswordToken &&
      !this.fromMyAccount
    ) {
      this.customRegisterFormService.setCustomerFormPassword(
        this.passwordForm.value
      );
      this.customRegisterCustomerValidatorService.getClientInfo().pipe(
        takeUntil(this.unsubscribe$),
        switchMap((clientInfo: PromesaRegisterClient | null) => {
          return this.customRegisterFormService.postCustomerForm(
            this.passwordForm.get('password')?.value,
            clientInfo?.clientEmail!
          ).pipe(
            tap(value => {
              if (value) {
                this.dataLayerRegister.createOldAccountEvent(
                  'Crear_Cuenta_Cliente_Paso3',
                  clientInfo!.ruc,
                  clientInfo!.clientEmail,
                  false
                );
                this.customRegisterStepOrchestrator.setStepOneStatus(true);
                this.customRegisterStepOrchestrator.setStepTwoStatus(false);
                this.customRegisterStepOrchestrator.setStepThreeStatus(false);
                this.customTwilioValidatorService.setValidationStatus('');
              } else {
                this.dataLayerRegister.createOldAccountEvent(
                  'Crear_Cuenta_Cliente_Paso3',
                  clientInfo!.ruc,
                  clientInfo!.customerRegisterEmail,
                  true
                );
              }
            })
          )
        })
      ).subscribe();
    } else if (
      this.passwordForm.valid &&
      this.comparePasswords(
        this.passwordForm.get('password')?.value,
        this.passwordForm.get('repeatPassword')?.value
      ) &&
      !resetPasswordToken && !this.fromRegisterEmployee &&
      this.fromMyAccount
    ) {
      this.updateProfile();
    } else {
      this.resetPassword(resetPasswordToken);
    }
  }

  updateProfile() {
    if (this.currentPassForm) {
      this.updatePasswordForm.controls['oldPassword'].setValue(
        this.currentPassForm.get('oldPassword')?.value
      );
      this.updatePasswordForm.controls['newPassword'].setValue(
        this.passwordForm.get('password')?.value
      );
      this.updatePasswordForm.controls['newPasswordConfirm'].setValue(
        this.passwordForm.get('repeatPassword')?.value
      );

      this.updatePasswordFormEmmiter.emit(this.updatePasswordForm);
    }
  }

  resetPassword(resetPasswordToken: string) {
    this.form.controls['password'].setValue(
      this.passwordForm.get('password')?.value
    );
    this.form.controls['passwordConfirm'].setValue(
      this.passwordForm.get('repeatPassword')?.value
    );

    if (this.passwordForm.valid && resetPasswordToken && this.form.valid) {
      this.customResetPasswordService
        .customResetPassword(resetPasswordToken)
        ?.subscribe((value) => {
          if (value) {
            this.customUpdatePassword.openModal(false, true);
          } else {
            this.customUpdatePassword.openModal(true, true);
          }
        });
    }
  }

  comparePasswords(password: string, repeatPassword: string): boolean {
    if (password !== repeatPassword) {
      this.notTheSamePasswordError = true;
      this.disableButton = true;
      return false;
    } else {
      this.notTheSamePasswordError = false;
      this.disableButton = false;
      return true;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.customTwilioValidatorService.resetValidationStatus();
  }
}
