import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomModalModule } from 'src/app/cms-components/shared/components/custom-modal/custom-modal.module';
import { CustomMyPointsComponent } from './custom-my-points.component';
import { CustomTitleMyAccountModule } from 'src/app/cms-components/custom/components/custom-title-my-account/custom-title-my-account.module';

@NgModule({
  imports: [
    CommonModule,
    CustomTitleMyAccountModule,
    IconModule,
    CustomModalModule,
    I18nModule,
  ],
  declarations: [CustomMyPointsComponent],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        MyAccountPointsComponent: {
          component: CustomMyPointsComponent,
        },
      },
    }),
  ],
})
export class CustomMyPointsModule {}
