import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomFilterComponent } from './custom-filter.component';



@NgModule({
  declarations: [
    CustomFilterComponent
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    I18nModule,
    IconModule,
    ReactiveFormsModule,
  ],
  exports: [
    CustomFilterComponent
  ]
})
export class CustomFilterModule { }
