export const customRegisterEmployeeForm = {
  registerEmployee: {
    registerEmployee: {
      title: 'Registro colaboradores',
      employeeCedula: {
        label: 'Ingresar número de cédula:',
        placeholder: 'Ingresar número',
        required: '* Debe ingresar un código de cédula.',
        invalid: '* Número de cédula incorrecto.'
      },
      employeeCode: {
        label: 'Código de empleado:',
        placeholder: 'Ingresar código',
        required: '* Debe ingresar un código de cédula.',
        invalid: '* Código de empleado incorrecto.'
      },
      registerEmployeeValidation: {
        employeeCodeError: 'El número de cédula es incorrecto o no corresponde a un empleado de Promesa.',
        employeeCodeContactSupport: 'Comunícate con el departamento de talento humano para poder acceder.',
        employeeCodeErrorMobile: 'El número de cédula es incorrecto o no corresponde a un empleado de Promesa. Comunícate con el departamento de talento humano para poder acceder.'
      },
      registerEmployeeAlreadyExists: {
        alreadyExistsError: 'El e-mail ya se encuentra asociado a una cuenta activa.',
        alreadyExistsContactSupport: 'Comunícate con talento humano para poder acceder.',
      },
      registerEmployeeData: {
        employeeEmailError: 'La dirección de email es incorrecta o no corresponde a un empleado de Promesa.',
        employeeEmailContactSupport: 'Comunícate con el departamento de talento humano para poder acceder.',
        employeeEmailErrorMobile: 'La dirección de e-mail es incorrecta o no corresponde a un empleado de Promesa. Comunícate con el departamento de talento humano para poder acceder.',
      },
      goBack: 'Regresar',
      personalData: 'Datos personales',
      firstNameEmployee: 'Nombre:',
      lastNameEmployee: 'Apellido:',
      idEmployee: 'Cédula:',
      emailEmployee: 'Email:',
      confirmationData: 'Confirmación de datos',
    },

  },
};
