import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OccEndpointsService, Product
} from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomFutureStockService {
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService
  ) {}


  addStockAlert(productCode: string): Observable<any> {
    const url = this.occEndpointService.buildUrl('addStockAlert', {
      urlParams: { productCode },
    });

    return this.http.post(url, null);
  }
}
