import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule
} from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomAddToSavedCartComponent } from './custom-add-to-saved-cart.component';
import { CustomSavedCartFormDialogModule } from './custom-saved-cart-form-dialog/custom-saved-cart-form-dialog.module';
import { CustomRemoveCartComponent } from './custom-remove-cart/custom-remove-cart.component';

@NgModule({
  declarations: [CustomAddToSavedCartComponent, CustomRemoveCartComponent],
  imports: [
    CommonModule,
    IconModule,
    RouterModule,
    I18nModule,
    UrlModule,
    CustomSavedCartFormDialogModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        AddToSavedCartsComponent: {
          component: CustomAddToSavedCartComponent,
        },
      },
    }),
  ],
})
export class CustomAddToSavedCartModule {}
