import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { CustomGiftProductModule } from '../custom-gift-product/custom-gift-product.module';
import { CustomProductCardReviewComponent } from './custom-product-card-review.component';

@NgModule({
  declarations: [CustomProductCardReviewComponent],
  imports: [CommonModule, MediaModule, I18nModule, CustomGiftProductModule],
  exports: [CustomProductCardReviewComponent],
})
export class CustomProductCardReviewModule {}
