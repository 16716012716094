export const customNewsLetterForm = {
  newsLetterForm: {
    email: {
      placeholder: 'Ingresa tu email',
    },
    button:{
      text: 'Enviar'
    },
    success: {
      text: 'Gracias por tu registro.'
    }
  },
};
