<div class="cx-dialog-header modal-header">
  <button
    type="button"
    class="close"
    attr.aria-label="{{ 'addToCart.closeModal' | cxTranslate }}"
    (click)="closeModal('close')"
  >
    <span aria-hidden="true">
      <cx-icon type="CLOSE"></cx-icon>
    </span>
  </button>
</div>
<div
  class="upload-progress"
  *ngIf="
    !invalidFileExtension && !removeFile && !(uploadError$ | async);
    else invalidMessage
  "
>
  <h4 class="upload-progress__title">
    {{ "nonCustomerRegister.uploadingFile" | cxTranslate }}
  </h4>
  <p class="upload-progress__name">
    {{ fileName }}
  </p>
  <div class="upload-progress__content">
    <ngb-progressbar
      type="primary"
      [value]="progress$ | async"
      [striped]="true"
      [animated]="true"
    >
      <i class="progress-label">{{ progress$ | async }}%</i>
    </ngb-progressbar>
  </div>
</div>

<ng-template #invalidMessage>
  <div class="invalid-extension">
    <cx-icon class="fas fa-exclamation-triangle"></cx-icon>
    <ng-container *ngIf="!invalidFileSize">
      <div
        class="invalid-extension__message"
        [innerHTML]="
          !(uploadError$ | async)
            ? ('nonCustomerRegister.invalidFileUpload' | cxTranslate | safeHtml)
            : ('nonCustomerRegister.uploadFailed' | cxTranslate)
        "
        *ngIf="!removeFile; else remove"
      ></div>
    </ng-container>
    <ng-container *ngIf="invalidFileSize">
      <div
        class="invalid-extension__message"
        [innerHTML]="
          'nonCustomerRegister.invalidFileSize' | cxTranslate | safeHtml
        "
      ></div>
    </ng-container>
    <ng-template #remove>
      <div
        class="invalid-extension__message"
        [ngClass]="{ remove: removeFile }"
      >
        {{ "nonCustomerRegister.deleteFile" | cxTranslate }}
      </div>
      <div class="invalid-extension__file">
        {{ fileName }}
      </div>
    </ng-template>
    <div class="action" *ngIf="removeFile">
      <a (click)="deleteDocument()" class="action__link">
        {{ "nonCustomerRegister.delete" | cxTranslate }}
      </a>
      <button class="action__button" (click)="closeModal('close')">
        {{ "nonCustomerRegister.cancel" | cxTranslate }}
      </button>
    </div>
  </div>
</ng-template>
