import { Component, OnInit } from '@angular/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomScrollService } from 'src/app/services/custom-scroll.service';

@Component({
  selector: 'app-custom-floating-add-to-cart',
  templateUrl: './custom-floating-add-to-cart.component.html',
  styleUrls: ['./custom-floating-add-to-cart.component.scss'],
})
export class CustomFloatingAddToCartComponent implements OnInit {
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  showFloatingAddToCart$: Observable<boolean> =
    this.customScrollService.showFloatingAddToCart$;
  hasStock$: Observable<boolean>;

  constructor(
    private customScrollService: CustomScrollService,
    private customBreakpointService: CustomBreakpointService,
    protected currentProductService: CurrentProductService
  ) {}

  ngOnInit(): void {
    this.hasStock$ = this.currentProductService
      .getProduct()
      .pipe(
        map((product: any) => product.stock?.stockLevelStatus !== 'outOfStock')
      );
  }
}
