import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDisplayConfigModule } from './custom-display-config/custom-display-config.module';
import { CustomEndpointsModule } from './custom-endpoints/custom-endpoints.module';
import { CustomIconsModule } from './custom-icons/custom-icons.module';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { CustomRoutingModule } from './custom-routing/custom-routing.module';
import { CustomI18nModule } from './custom-i18n/custom-i18n.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CustomDisplayConfigModule,
    CustomEndpointsModule,
    CustomIconsModule,
    CustomLayoutModule,
    CustomRoutingModule,
    CustomI18nModule,
  ],
  exports: [CustomDisplayConfigModule],
})
export class ConfigModule {}
