import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, map, last } from 'rxjs/operators';
import { CustomGtmLoginService } from 'src/app/common/services/custom-gtm-login-register-datalayer.service';
import { CustomBusinessType } from '../models/custom-business-type.interface';
import { CustomRegisterNonCustomerFormData } from '../models/custom-register-non-customer-form.interface';
import { UploadResponse } from '../models/custom-upload.interface';
import { UploadProgressService } from './upload-progress.service';

@Injectable({
  providedIn: 'root',
})
export class CustomRegisterNonClientFormService {
  nonCustomerFormData$: BehaviorSubject<CustomRegisterNonCustomerFormData | null> =
    new BehaviorSubject<CustomRegisterNonCustomerFormData | null>(null);
  public progressSource = new BehaviorSubject<number>(0);
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
    private uploadProgressService: UploadProgressService,
    private customLayerService: CustomGtmLoginService
  ) {}

  getRucAlreadyExists(rucCode: string) {
    const url = this.occEndpointService.buildUrl('isValidRuc', {
      queryParams: {
        ruc: rucCode,
      },
    });
    return this.http.get(url);
  }

  getUidAlreadyExists(uidCode: string) {
    const url = this.occEndpointService.buildUrl('isValidUid', {
      queryParams: {
        uid: uidCode,
      },
    });
    return this.http.get(url);
  }

  getAppointmentDocument(): Observable<string> {
    const url = this.occEndpointService.buildUrl(
      'getAppointmentDocumentTemplate',
      {}
    );
    return this.http.get<string>(url);
  }

  getBussinessType(): Observable<CustomBusinessType[]> {
    const url = this.occEndpointService.buildUrl('getBussinessType');
    return this.http.get<CustomBusinessType[]>(url);
  }

  uploadDocument(
    file: File,
    code: string,
    type: string
  ): Observable<UploadResponse> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const url = this.occEndpointService.buildUrl('uploadRegisterDocument', {
      queryParams: {
        altText: `${type} document`,
        code: code,
        description: type,
      },
    });
    return this.http
      .post<UploadResponse>(url, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event) => this.getEventMessage(event, file)),
        tap((envelope: any) => this.processProgress(envelope)),
        last()
      );
  }

  deleteDocument(uuid: string): Observable<boolean> {
    const url = this.occEndpointService.buildUrl('deleteRegisterDocument', {
      queryParams: {
        uid: uuid,
      },
    });
    return this.http.post<boolean>(url, {});
  }

  setNonCustomerFormFirstStep(form: CustomRegisterNonCustomerFormData) {
    this.nonCustomerFormData$.next(form);
  }

  getNonCustomerFormData(): Observable<CustomRegisterNonCustomerFormData | null> {
    return this.nonCustomerFormData$.asObservable();
  }

  setNonCustomerDocFormData(docUid: string, type: string) {
    let formData = this.nonCustomerFormData$.value;
    switch (type) {
      case 'ruc':
        formData = { ...formData!, rucDoc: docUid };
        break;
      case 'id':
        formData = { ...formData!, idDoc: docUid };
        break;
      case 'appoinment':
        formData = { ...formData!, appoinmentDoc: docUid };
        break;
      case 'letter':
        formData = { ...formData!, letterDoc: docUid };
        break;
      default:
        break;
    }
    this.nonCustomerFormData$.next(formData);
  }

  submitForm(form: CustomRegisterNonCustomerFormData) {
    const url = this.occEndpointService.buildUrl('registerNonCustomer');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const body = {
      uid: form.email,
      parentOrgUnit: '',
      name: form.socialReason,
      nombreComercial: form.comercialName,
      businessType: form.bussinessType,
      active: true,
      ruc: form.ruc,
      naturalPerson: form.naturalPerson,
      modifiedDateRuc: form.rucLastUpdate,
      news: form.news,
      addresses: [
        {
          email: form.email,
          cellphone: form.cellphone,
          companyName: form.comercialName,
          phone: form.phonePrefix + form.phone,
          contactAddress: true,
          billingAddress: false,
          shippingAddress: false,
        },
      ],
      rucDocument: {
        code: form?.rucDoc,
      },
      ccDocument: {
        code: form?.idDoc,
      },
      burocreditDocument: {
        code: form?.letterDoc,
      },
      appointmentDocument: {
        code: form?.appoinmentDoc,
      },
    };
    const { naturalPerson, bussinessName, news, ruc, province } = form;
    this.customLayerService.createNewAccountEvent(
      naturalPerson ? 'Persona Natural' : 'Persona Juridica',
      bussinessName,
      province,
      news ? 'Si' : 'No',
      ruc
    );

    return this.http.post(url, body, { headers });
  }

  private getEventMessage(event: HttpEvent<any>, file: File) {
    switch (event.type) {
      case HttpEventType.Sent:
        return `Uploading file "${file.name}" of size ${file.size}.`;
      case HttpEventType.UploadProgress:
        return Math.round((100 * event.loaded) / event?.total!);
      case HttpEventType.Response:
        return event.body;
      default:
        return `File "${file.name}" surprising upload event: ${event.type}.`;
    }
  }

  processProgress(envelope: any): void {
    if (typeof envelope === 'number') {
      this.uploadProgressService.setProgressSource(envelope);
    }
  }

  resetNonCustomerFormData() {
    this.nonCustomerFormData$.next(null);
  }
}
