import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  FacetValue,
  ProductSearchPage,
  SearchboxService,
} from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CustomSubcategoriesService } from 'src/app/cms-components/product/components/custom-product-variant-list/services/custom-subcategories.service';
import { CustomSubcategory } from 'src/app/cms-components/product/model/custom-subcategory.interface';
import { CustomGtmSearchSelectionDatalayerService } from 'src/app/common/services/custom-gtm-search-selection-datalayer.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomGetImageUrlService } from 'src/app/services/custom-get-image-url.service';
import { CustomFilterAutomotrizService } from '../../services/custom-filter-automotriz.service';
import {
  CustomQueryFilter,
  CustomSearchRefiner,
} from './model/custom-search-refiner.interface';

@Component({
  selector: 'app-custom-search-refiner',
  templateUrl: './custom-search-refiner.component.html',
  styleUrls: ['./custom-search-refiner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomSearchRefinerComponent implements OnInit, OnDestroy {
  componentData$: Observable<CustomSearchRefiner> = this.componentData.data$;
  resultsData$: Observable<ProductSearchPage>;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  unsubscribe$: Subject<any> = new Subject();

  yearsValues$: Observable<FacetValue[] | null>;
  brandsValues$: Observable<FacetValue[] | null>;
  modelsValues$: Observable<FacetValue[] | null>;
  motorsValues$: Observable<FacetValue[] | null>;

  automotrizForm: FormGroup;
  baseCategory = '11';
  baseQuery = ':relevance';
  dataFiltered: boolean = false;
  showInfo: boolean = false;
  queryFilter: CustomQueryFilter;
  motorFilterSelected: boolean = false;
  filteredSelected: string | null;

  constructor(
    private componentData: CmsComponentData<CustomSearchRefiner>,
    private customBreakpointService: CustomBreakpointService,
    protected searchboxService: SearchboxService,
    private customFilterAutomotrizService: CustomFilterAutomotrizService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private customSubcategoriesService: CustomSubcategoriesService,
    private customGtmDatalayerService: CustomGtmSearchSelectionDatalayerService,
    private customGetImageUrl: CustomGetImageUrlService
  ) {}

  ngOnInit(): void {
    this.resultsData$ = this.searchboxService.getResults();
    this.getFilterAutomotriz();
    this.automotrizForm = new FormGroup({
      brandFilter: new FormControl(null, [Validators.required]),
      modelFilter: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      yearFilter: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      motorFilter: new FormControl({ value: null, disabled: true }, []),
    });

    this.brandsValues$ = this.getCategoriesValues(this.baseCategory);

    this.customFilterAutomotrizService.appliedFilter$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => this.getFilterAutomotriz())
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getCategoriesValues(
    categoryCode: string
  ): Observable<CustomSubcategory[] | null> {
    return this.customSubcategoriesService.getFirstLevelSubCategoryDataFromApi(
      categoryCode!,
      null
    );
  }
  getCategoriesValues2(
    categoryCode: string,
    categoryCode2: string
  ): Observable<CustomSubcategory[] | null> {
    return this.customSubcategoriesService.getFirstLevelSubCategoryDataFromApi(
      categoryCode!,
      categoryCode2!
    );
  }

  onChangeOption(element: string, event: any) {
    switch (element) {
      case 'brandFilter': {
        this.automotrizForm.controls['modelFilter'].enable();
        if (this.automotrizForm.controls['brandFilter'].valid)
          this.document
            .getElementById('brandFilter')!
            .classList.add('filtered');
        this.modelsValues$ = this.getCategoriesValues(
          this.automotrizForm.value.brandFilter.code
        );

        this.automotrizForm.controls.modelFilter.reset();
        this.automotrizForm.controls.yearFilter.reset();
        this.automotrizForm.controls.motorFilter.reset();
        this.automotrizForm.controls['yearFilter'].disable();
        this.automotrizForm.controls['motorFilter'].disable();
        this.document
          .getElementById('modelFilter')!
          .classList.remove('filtered');
        this.document
          .getElementById('yearFilter')!
          .classList.remove('filtered');
        this.document
          .getElementById('motorFilter')!
          .classList.remove('filtered');

        break;
      }
      case 'modelFilter': {
        this.automotrizForm.controls['yearFilter'].enable();
        if (this.automotrizForm.controls['modelFilter'].valid)
          this.document
            .getElementById('modelFilter')!
            .classList.add('filtered');

        this.automotrizForm.controls.yearFilter.reset();
        this.automotrizForm.controls.motorFilter.reset();
        this.automotrizForm.controls['motorFilter'].disable();
        this.document
          .getElementById('yearFilter')!
          .classList.remove('filtered');
        this.document
          .getElementById('motorFilter')!
          .classList.remove('filtered');

        this.yearsValues$ = this.getCategoriesValues(
          this.automotrizForm.value.modelFilter.code
        );
        break;
      }
      case 'yearFilter': {
        this.automotrizForm.controls['motorFilter'].enable();
        if (this.automotrizForm.controls['yearFilter'].valid)
          this.document.getElementById('yearFilter')!.classList.add('filtered');

        this.automotrizForm.controls.motorFilter.reset();
        this.document
          .getElementById('motorFilter')!
          .classList.remove('filtered');

        this.motorsValues$ = this.getCategoriesValues(
          this.automotrizForm.value.yearFilter.code
        );
        break;
      }
      case 'motorFilter': {
        if (this.automotrizForm.controls['motorFilter'].valid)
          this.document
            .getElementById('motorFilter')!
            .classList.add('filtered');
        break;
      }
    }
  }

  addFilter(query: string, value: string): string {
    return query + ':allAutoFilterCategories:' + value;
  }

  buildQuery() {
    let brandValue = this.automotrizForm.value.brandFilter.code;
    let modelValue = this.automotrizForm.value.modelFilter.code;
    let yearValue = this.automotrizForm.value.yearFilter.code;
    let motorValue = this.automotrizForm.value.motorFilter?.code;
    let brandName = this.automotrizForm.value.brandFilter.name;
    let modelName = this.automotrizForm.value.modelFilter.name;
    let yearName = this.automotrizForm.value.yearFilter.name;
    let motorName = this.automotrizForm.value.motorFilter?.name;

    let query = this.baseQuery;

    if (brandValue != '' && brandValue !== null && brandValue !== undefined) {
      query = this.addFilter(query, brandValue);
    }
    if (modelValue != '' && modelValue !== null && modelValue !== undefined) {
      query = this.addFilter(query, modelValue);
    }
    if (yearValue != '' && yearValue !== null && yearValue !== undefined) {
      query = this.addFilter(query, yearValue);
    }
    if (motorValue != '' && motorValue !== null && motorValue !== undefined) {
      query = this.addFilter(query, motorValue);
    }
    if (!!yearValue && !!brandValue && !!modelValue) {
      this.queryFilter = {
        name: !motorValue
          ? `${brandName} ${modelName} ${yearName}`
          : `${brandName} ${modelName} ${yearName} ${motorName}`,
        query: query,
      };
    }

    return query;
  }

  filter(): void {
    this.searchboxService.search(this.buildQuery());
  }

  setFilterAutomotriz() {
    this.filter();
    this.customGtmDatalayerService.searchRefinerEvent(
      this.automotrizForm.controls['brandFilter'].value?.name,
      this.automotrizForm.controls['modelFilter'].value?.name,
      this.automotrizForm.controls['yearFilter'].value?.name,
      this.automotrizForm.controls['motorFilter'].value?.name
    );
    this.setFilterLocalStorage();
    this.router.navigateByUrl(`/search/?query=${this.queryFilter.query}`);
    this.searchboxService.search(this.queryFilter.query);
    this.dataFiltered = true;
    this.showInfo = false;
    this.customFilterAutomotrizService.setFilterLocalStorage();
    this.clearForm();
  }

  setFilterLocalStorage() {
    const filterLocalStorage: string | null = localStorage.getItem(
      'queryFilterAutomotriz'
    );
    let arrFilter: CustomQueryFilter[] = [];
    if (filterLocalStorage) {
      arrFilter = JSON.parse(filterLocalStorage);
      if (arrFilter.length == 3) arrFilter.pop();
    }
    arrFilter.unshift(this.queryFilter);
    localStorage.setItem('queryFilterAutomotriz', JSON.stringify(arrFilter));
  }

  clearForm() {
    this.automotrizForm.reset();
    this.motorFilterSelected = false;
    this.filteredSelected = null;
    if (
      this.document.getElementById('yearFilter') &&
      this.document.getElementById('brandFilter')
    ) {
      this.document.getElementById('yearFilter')!.classList.remove('filtered');
      this.document.getElementById('brandFilter')!.classList.remove('filtered');
      this.document.getElementById('modelFilter')!.classList.remove('filtered');
      this.document.getElementById('motorFilter')!.classList.remove('filtered');
    }
    this.automotrizForm.controls['yearFilter'].disable();
    this.automotrizForm.controls['modelFilter'].disable();
    this.automotrizForm.controls['motorFilter'].disable();
    this.searchboxService.search('');
  }

  getFilterAutomotriz() {
    const filterAutomotriz = localStorage.getItem('queryFilterAutomotriz');
    if (filterAutomotriz && JSON.parse(filterAutomotriz).length > 0) {
      const filterAutomotrizArray: CustomQueryFilter[] =
        JSON.parse(filterAutomotriz);
      this.filteredSelected = filterAutomotrizArray[0].name;
    }
  }

  getImageUrl(url: string): string {
    return this.customGetImageUrl.getImageUrl(url);
  }
}
