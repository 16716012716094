import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomBreadcrumbComponent } from './custom-breadcrumb.component';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { CustomSafeHtmlModule } from '../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomPdpRebateModule } from 'src/app/cms-components/custom/components/custom-pdp-rebate/custom-pdp-rebate.module';

@NgModule({
  declarations: [CustomBreadcrumbComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    CustomSafeHtmlModule,
    CustomPdpRebateModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        BreadcrumbComponent: {
          component: CustomBreadcrumbComponent,
        },
      },
    }),
  ],
})
export class CustomBreadcrumbModule {}
