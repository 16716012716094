<ng-container>
  <div class="title-checkout__step">
    {{ "checkoutShipping.numberStep" | cxTranslate: {number: numberStep} }}
  </div>
</ng-container>
<div class="title-checkout__progress" [ngClass]="{'step-disabled': titleDisabled}">
  <div class="title-checkout__progress-number">
    {{numberStep}}
  </div>
  <h2 class="title-checkout__progress-title d-lg-block d-xl-block" >
    {{ numberStep == 1 ? ("checkoutShipping.deliveryMethod" | cxTranslate) : ("paymentTypes.title" | cxTranslate)}}
  </h2>
</div>
