import { Injectable } from '@angular/core';
import {
  HttpErrorHandler,
  HttpResponseStatus,
  Priority,
} from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class CustomInternalServerErrorHandler extends HttpErrorHandler {
  responseStatus = HttpResponseStatus.INTERNAL_SERVER_ERROR;

  handleError() {}

  getPriority(): Priority {
    return Priority.LOW;
  }
}
