<ng-container *ngIf="visibiliy$ | async as visibility">
  <ng-container
    *ngIf="visibility.visibility && visibility.instance === instance"
  >
    <div class="modal__container" (click)="close()">
      <div class="modal__content" (click)="stopPropagation($event)">
        <div class="modal__header" *ngIf="closeVisible">
          <h3 class="modal__header-title" *ngIf="modalTitle">
            {{ modalTitle }}
          </h3>
          <button type="button" (click)="close()">
            <cx-icon type="CLOSE"></cx-icon>
          </button>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
    <div class="modal__external-container" (click)="close()">
      <!-- Container used to recognize the click event outside of the "modal__container" of the custom-login component. -->
    </div>
  </ng-container>
</ng-container>
