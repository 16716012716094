import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalMessageService } from '@spartacus/core';
import { CustomFormValidators } from '@spartacus/storefront';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap, switchMap } from 'rxjs/operators';

interface CustomUser {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  uid?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CustomUpdateProfileService {
  constructor(
    protected userProfile: UserProfileFacade,
    protected globalMessageService: GlobalMessageService
  ) {}

  user$ = this.userProfile
    .get()
    .pipe(filter((user): user is CustomUser => Boolean(user)));

  protected busy$ = new BehaviorSubject(false);

  isUpdating$: Observable<boolean> = this.user$.pipe(
    tap((user) => this.form.patchValue(user)),
    switchMap((_user: CustomUser) => this.busy$),
    tap((state) => (state === true ? this.form.disable() : this.form.enable()))
  );

  form: FormGroup = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    phone: new FormControl(''),
    email: new FormControl(''),
  });

  currentPassword: FormGroup = new FormGroup({
    oldPassword: new FormControl('', Validators.required),
  });

  updatePasswordForm: FormGroup = new FormGroup(
    {
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', [
        Validators.required,
        CustomFormValidators.passwordValidator,
      ]),
      newPasswordConfirm: new FormControl('', Validators.required),
    },
    {
      validators: CustomFormValidators.passwordsMustMatch(
        'newPassword',
        'newPasswordConfirm'
      ),
    }
  );

  resetForms() {
    this.currentPassword.reset();
    this.updatePasswordForm.reset();
  }
}
