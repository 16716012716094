<ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
  <span class="label-content">
    {{ inputLabel | cxTranslate }}
  </span>
  <form
    class="add-to-cart-form-promeclub"
    [formGroup]="entryForm"
    (ngSubmit)="submitForm()"
  >
    <label>
      <input
        aria-required="true"
        formControlName="value"
        type="text"
        class="form-control"
        placeholder="{{ inputPlaceholder | cxTranslate }}"
        [ngClass]="{ error: !formValid }"
      />
      <p class="form-control-error" *ngIf="!formValid">
        {{ errorMessage | cxTranslate }}
      </p>
    </label>

    <button
      *ngIf="!data.isMobile"
      class="btn btn-primary"
      autofocus
      type="submit"
      [disabled]="!(entryForm?.get('value'))!.value?.length || !formValid"
    >
      <span>{{ "common.continue" | cxTranslate }}</span>
      <cx-icon class="fas fa-chevron-right"></cx-icon>
    </button>
    <div class="add-to-cart-form-promeclub__action" *ngIf="data.isMobile">
      <button
        class="btn btn-primary"
        autofocus
        type="submit"
        [disabled]="!(entryForm?.get('value'))!.value?.length || !formValid"
      >
        <span>{{ "common.continue" | cxTranslate }}</span>
      </button>
      <button class="btn btn-primary cancel" (click)="closeModal()">
        <span>{{ "common.cancel" | cxTranslate }}</span>
      </button>
    </div>
  </form>
</ng-container>
