import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CheckoutDetailsService } from '@spartacus/checkout/components';
import { CheckoutFacade } from '@spartacus/checkout/root';
import { ORDER_TYPE } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { saveAs } from 'file-saver';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { CustomGtmEcommerceDatalayerService } from 'src/app/common/services/custom-gtm-ecommerce-datalayer.service';
import { CustomCheckoutPaymentStepService } from 'src/app/feature-libs/checkout/services/custom-checkout-payment-step.service';
import { CustomBaseSiteCheckerService } from '../../../../services/custom-base-site-checker.service';
import { CustomOrderConfirmationService } from '../../services/custom-order-confirmation.service';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';
@Component({
  selector: 'app-custom-order-confirmation-than-you-message',
  templateUrl: './custom-order-confirmation-than-you-message.component.html',
  styleUrls: ['./custom-order-confirmation-than-you-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CustomOrderConfirmationThanYouMessageComponent
  implements OnInit, OnDestroy
{
  order$: Observable<any>;
  isReplenishmentOrderType$: Observable<boolean>;
  unsubscribe$: Subject<any> = new Subject();
  isGuestCustomer = false;
  orderGuid: string;
  paymentType$: Observable<string | undefined>;
  userId$: Observable<string> = this.userAccountFacade
    .get()
    .pipe(map((user) => user?.uid!));
  isPromeclub: boolean = this.customBaseSiteCheckerService.isInPromeClub();
  isMobile$: Observable<boolean>;
  constructor(
    protected checkoutService: CheckoutFacade,
    protected checkoutDetailsService: CheckoutDetailsService,
    protected customCheckoutPaymentStepService: CustomCheckoutPaymentStepService,
    protected customOrderConfirmationService: CustomOrderConfirmationService,
    protected userAccountFacade: UserAccountFacade,
    protected customBaseSiteCheckerService: CustomBaseSiteCheckerService,
    private customGtmDatalayerService: CustomGtmEcommerceDatalayerService,
    private customBreakpointService: CustomBreakpointService
  ) {}

  ngOnInit() {
    this.isMobile$ = this.customBreakpointService.isMobile$;
    this.order$ = this.checkoutService.getOrderDetails().pipe(
      tap((order) => {
        this.isGuestCustomer =
          'guestCustomer' in order ? order.guestCustomer ?? false : false;
        this.orderGuid = order.guid as string;
      }),
      tap((order: any) => {
        const {
          code,
          entries,
          deliveryMode,
          totalPriceWithTax,
          totalTax,
          deliveryCost,
          appliedVouchers,
          totalDiscounts,
        } = order;
        this.customGtmDatalayerService.transActionEvent(
          entries,
          code,
          deliveryMode?.name,
          Number(totalPriceWithTax?.value),
          Number(totalTax?.value),
          Number(deliveryCost?.value),
          appliedVouchers,
          Number(totalDiscounts?.value)
        );
      })
    );

    this.isReplenishmentOrderType$ = this.checkoutService
      .getCurrentOrderType()
      .pipe(
        map(
          (orderType) => ORDER_TYPE.SCHEDULE_REPLENISHMENT_ORDER === orderType
        )
      );

    this.paymentType$ =
      this.customCheckoutPaymentStepService.getPrincipalTypeSelected();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.checkoutService.clearCheckoutData();
  }

  getOrderTicket(userId: string, orderCode: string) {
    this.customOrderConfirmationService
      .getTicketOrder(userId, orderCode)
      .pipe(
        takeUntil(this.unsubscribe$),
        map((resp) => {
          const filename = `ticket_${orderCode}`;
          const blob = new Blob([resp.body], {
            type: 'application/pdf',
          });

          const blobURL = window.URL.createObjectURL(blob);
          const tempLink = document.createElement('a');
          tempLink.style.display = 'none';
          tempLink.href = blobURL;
          tempLink.setAttribute('target', '_blank');
          saveAs(blob, filename);
        })
      )
      .subscribe();
  }
}
