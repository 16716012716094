import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CurrencyService,
  LanguageService,
  OccEndpointsService,
  ProductSearchService,
  RoutingService,
} from '@spartacus/core';
import { ProductListComponentService, ViewConfig } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomProductListComponentService extends ProductListComponentService {
  currentView$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  viewChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  lastCategoryCode$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  actualViewSelectors$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  constructor(
    protected productSearchService: ProductSearchService,
    protected routing: RoutingService,
    protected activatedRoute: ActivatedRoute,
    protected currencyService: CurrencyService,
    protected languageService: LanguageService,
    protected router: Router,
    protected config: ViewConfig,
    protected http: HttpClient,
    protected occEndpointService: OccEndpointsService
  ) {
    super(
      productSearchService,
      routing,
      activatedRoute,
      currencyService,
      languageService,
      router,
      config
    );
  }

  setActualViewSelectors(view: string): void {
    this.actualViewSelectors$.next(view);
  }

  setCurrentView(mode: string): void {
    this.currentView$.next(mode);
  }

  getCurrentView(): Observable<string> {
    return this.currentView$;
  }

  setViewChange(change: boolean) {
    this.viewChanged$.next(change);
  }

  getViewChange(): Observable<boolean> {
    return this.viewChanged$;
  }

  setLastCategoryCode(categoryCode: string): void {
    this.lastCategoryCode$.next(categoryCode);
  }

  getLastCategoryCode(): Observable<string> {
    return this.lastCategoryCode$;
  }
}
