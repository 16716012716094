import { Component, Input, OnInit } from '@angular/core';
import { Cart, CmsComponent, CmsService, PaymentType } from '@spartacus/core';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { CustomCheckoutPaymentStepService } from '../../../services/custom-checkout-payment-step.service';
import { CustomPaymentConditionsService } from '../../../services/custom-payment-conditions.service';
import { CustomUser } from '../../../models/custom-user';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-payment-cash',
  templateUrl: './custom-payment-cash.component.html',
  styleUrls: ['./custom-payment-cash.component.scss'],
})
export class CustomPaymentCashComponent implements OnInit {
  @Input() user: CustomUser;
  @Input() paymentTypes: PaymentType[];
  @Input() customCheckoutPaymentStepService: CustomCheckoutPaymentStepService;
  @Input() cart: Cart | any;
  typeSelected$: Observable<string>;
  componentData$: Observable<CmsComponent>;
  subscription: Subscription = new Subscription();
  dataComponent: string;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    private customPaymentCondition: CustomPaymentConditionsService,
    private cmsService: CmsService,
    private customBreakpointService: CustomBreakpointService
  ) {}

  ngOnInit(): void {
    this.user.isEmployee
      ? (this.dataComponent = 'promesaInfoCashPaymentEmployeeComponent')
      : (this.dataComponent = 'promesaInfoCashPaymentComponent');

    this.componentData$ = this.cmsService.getComponentData(this.dataComponent);
    this.typeSelected$ = this.customCheckoutPaymentStepService
      .getCountTypeSelected()
      .pipe(
        tap((selected) => {
          if (selected) {
            this.setPaymentType(selected);
          }
        })
      );
    if (
      this.cart?.isFromQuote &&
      (this.cart?.paymentType?.code === 'DEPOSIT' ||
        this.cart?.paymentType?.code === 'TRANSFER')
    ) {
      this.setPaymentType(this.cart?.paymentType?.code);
      this.changeType(this.cart?.paymentType?.code);
    }
  }

  changeType(code: string): void {
    this.customCheckoutPaymentStepService.setCountTypeSelected(code);
  }

  setPaymentType(code: string) {
    if (code === 'DEPOSIT') {
      this.subscription.add(
        this.customPaymentCondition
          .setDepositPaymentMode(this.user?.uid!)
          .pipe(
            take(1),
            catchError((error) => of(error))
          )
          .subscribe((error) => {
            if (error) {
              this.customCheckoutPaymentStepService.setGoToNextStep(false);
            } else {
              this.customCheckoutPaymentStepService.setGoToNextStep(true);
            }
          })
      );
    }
    if (code === 'TRANSFER') {
      this.subscription.add(
        this.customPaymentCondition
          .setTransferPaymentMode(this.user?.uid!)
          .pipe(
            take(1),
            catchError((error) => of(error))
          )
          .subscribe((error) => {
            if (error) {
              this.customCheckoutPaymentStepService.setGoToNextStep(false);
            } else {
              this.customCheckoutPaymentStepService.setGoToNextStep(true);
            }
          })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
