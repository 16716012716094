import {
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CustomWishlistService } from './services/custom-wishlist.service';
import { Observable, Subscription } from 'rxjs';
import {
  CustomWishList,
  ItemUpdated,
} from './models/custom-wishlist.interface';
import { tap, filter } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomCartActionsModalService } from '../cart/services/custom-cart-actions-modal.service';

@Component({
  selector: 'app-custom-wishlist',
  templateUrl: './custom-wishlist.component.html',
  styleUrls: ['./custom-wishlist.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomWishlistComponent implements OnInit {
  wishlist$: Observable<CustomWishList[]> | null = this.customWishlistService
    .getWishlist()
    .pipe(
      filter((wishlist) => !!wishlist),
      tap((wishlist) => this.isEmptyWishlist(wishlist))
    );
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  subscription: Subscription = new Subscription();
  updated$: Observable<ItemUpdated> =
    this.customWishlistService.updateddResult();
  itsEmpty: boolean = false;
  itemsQuantity: number;
  constructor(
    private customWishlistService: CustomWishlistService,
    private customBreakpointService: CustomBreakpointService,
    private el: ElementRef,
    private customCartActionsModalService: CustomCartActionsModalService
  ) {}

  ngOnInit(): void {
    this.el?.nativeElement?.parentNode?.classList?.add('wishlist');
    this.subscription.add(
      this.updated$
        .pipe(
          tap((updated) => {
            if (updated.value) {
              this.wishlist$ = this.customWishlistService.getWishlist().pipe(
                filter((wishlist) => !!wishlist),
                tap((wishlist) => this.isEmptyWishlist(wishlist))
              );
            }
          })
        )
        .subscribe()
    );
  }

  clearWishlist(): void {
    this.customCartActionsModalService.openRemoveCartDialog('', '', true);
  }

  isEmptyWishlist(wishlist: CustomWishList[]) {
    this.itsEmpty = wishlist.length > 0;
    this.itemsQuantity = wishlist.length;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
