import { NgModule } from '@angular/core';
import { CustomOccProductSearchModule } from './custom-product/custom-occ-product-search.module';



@NgModule({
  imports: [
    CustomOccProductSearchModule
  ]
})
export class CustomAdaptersModule { }
