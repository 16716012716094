import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { User } from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import {
  UpdatePasswordComponent,
  UpdatePasswordComponentService,
} from '@spartacus/user/profile/components';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomPinDialogComponent } from 'src/app/common/components/custom-pin-dialog/custom-pin-dialog.component';
import { CustomTwilioValidatorService } from 'src/app/common/services/custom-twilio-validator.service';
import { CustomUpdateProfileService } from '../custom-update-profile.service';
import { CustomUpdatePasswordService } from './custom-update-password.service';

@Component({
  selector: 'app-custom-update-password',
  templateUrl: './custom-update-password.component.html',
  styleUrls: ['./custom-update-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'user-form' },
})
export class CustomUpdatePasswordComponent
  extends UpdatePasswordComponent
  implements OnInit, OnDestroy
{
  constructor(
    protected service: UpdatePasswordComponentService,
    private customUpdatePasswordService: CustomUpdatePasswordService,
    private customUpdateProfileService: CustomUpdateProfileService,
    private customUpdateProfile: CustomUpdateProfileService,
    private customTwilioValidatorService: CustomTwilioValidatorService,
    private modalService: ModalService
  ) {
    super(service);
  }

  form: FormGroup = this.customUpdatePasswordService.form;
  isUpdating$ = this.customUpdatePasswordService.isUpdating$;

  currentPassForm: FormGroup = this.customUpdateProfileService.currentPassword;
  showPassword: boolean = false;
  passwordType: string = 'password';
  subscription: Subscription = new Subscription();
  modalRef: ModalRef;
  userEmail: string;
  validationStatus$: Observable<boolean> = this.customTwilioValidatorService
    .getValidationStatus()
    .pipe(map((status) => status === 'OK'));

  ngOnInit(): void {
    this.subscription.add(
      this.customUpdateProfile.user$.subscribe((data: User) => {
        this.userEmail = data.uid!;
      })
    );

    this.subscription.add(
      this.validationStatus$.subscribe((isValid) => {
        if (isValid) {
          this.onSubmit();
        }
      })
    );
  }

  onSubmit(): void {
    this.customUpdatePasswordService.updatePassword();
  }

  updatePassword(event: FormGroup) {
    const oldPassword = event.get('oldPassword')?.value;
    const newPassword = event.get('newPassword')?.value;
    const newPasswordConfirm = event.get('newPasswordConfirm')?.value;

    this.form.controls['oldPassword'].setValue(oldPassword);
    this.form.controls['newPassword'].setValue(newPassword);
    this.form.controls['newPasswordConfirm'].setValue(newPasswordConfirm);

    this.openPinValidator();
  }

  openPinValidator() {
    let modalInstance: any;
    const email = this.userEmail;

    this.subscription.add(
      this.customTwilioValidatorService
        .getValidationStatus()
        .subscribe((status) => {
          if (status === 'FALSE') {
            this.customTwilioValidatorService.setValidationStatus('');
          }
        })
    );
    this.subscription.add(
      this.customTwilioValidatorService
        .sendCodeToPhoneUsingEmail(email)
        .subscribe()
    );
    this.modalRef = this.modalService.open(CustomPinDialogComponent, {
      centered: false,
      size: 'lg',
      windowClass: 'twilio',
    });

    modalInstance = this.modalRef.componentInstance;
    modalInstance.email = email;
  }

  togglePasswordView(fromRepeat: boolean = false) {
    if (!fromRepeat) {
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        this.passwordType = 'text';
      } else {
        this.passwordType = 'password';
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
