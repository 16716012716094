export const customCommon = {
  common: {
    common: {
      loginTitle: 'Acceso clientes',
      showMore: 'Ver más',
      showLess: 'Ver menos',
      showAllCategories: 'Ver todas las categorías',
      showLessCategories: 'Ver menos categorías',
      of: 'de',
    },
    miniCart: {
      emptyCart: 'No tienes ningún producto en tu carrito.',
      totalPrice: 'Total',
      showCart: 'Ver carrito',
      secureCheckout: 'Secure Checkout',
      checkout: 'Checkout',
    },
    httpHandlers: {
      invalidCodeExpired: 'El cupón ingresado está vencido',
      invalidCodeRedeemed: 'El cupón ingresado fue utilizado',
    },
    formErrors: {
      cxInvalidNameListCart: {
        required: 'Debe ingresar un nombre a la lista',
      },
    },
  },
  rattingComment: {
    rattingComment: {
      required: 'Debe agregar un comentario.',
    },
  },
  rating: {
    rating: {
      cxStarRatingEmpty: 'Debe ingresar una calificación.',
    },
  },
  customMyAccount: {
    customMyAccount: {
      title: 'Mi <b>Cuenta</b>',
      breadcrumb: 'Mi cuenta',
      logout: 'Cerrar sesión',
    },
  },
  ourCompany: {
    ourCompany: {
      ourValues: 'Nuestros <b>Valores</b>',
    },
  },
};
