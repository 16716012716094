import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CmsParagraphComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-square-item',
  templateUrl: './custom-square-item.component.html',
  styleUrls: ['./custom-square-item.component.scss'],
})
export class PromesaSquareItemComponent {
  @Input() component$: any;
  @Input() isInWarrantyPage: boolean;
  constructor(
    public componentData: CmsComponentData<CmsParagraphComponent>,
    protected router?: Router
  ) {}
}
