<ng-container>
  <div #dialogOrderReturn class="custom-order-devolution-cancel">
    <app-custom-modal-generic></app-custom-modal-generic>
    <div class="custom-order-devolution-cancel_body-footer">
      <div class="custom-order-devolution-cancel_body">
        <cx-icon class="fas fa-exclamation-triangle"></cx-icon>
        <div
          class="custom-order-devolution-cancel_body-text"
          [innerHTML]="
            'returnRequest.cancelDevolutionText'
              | cxTranslate : { code: orderCode }
              | safeHtml
          "
        ></div>
      </div>
      <div class="custom-order-devolution-cancel__footer">
        <button
          type="button"
          class="button-action"
          (click)="onCancelDevolution()"
        >
          <span aria-hidden="true">
            {{ "common.cancel" | cxTranslate }}
          </span>
        </button>
        <button type="button" cxModal="dismiss" cxModalReason="Click continue">
          <span aria-hidden="true">
            {{ "returnRequest.goBack" | cxTranslate }}
          </span>
        </button>
      </div>
    </div>
  </div>
</ng-container>
