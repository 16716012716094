<div class="quote-remove">
  <app-custom-modal-generic></app-custom-modal-generic>
  <div class="quote-remove__icon">
    <cx-icon class="fas fa-exclamation-triangle"></cx-icon>
  </div>
  <div
    class="quote-remove__message"
    [innerHTML]="
      'accountQuotes.remove.message' | cxTranslate : { code: code } | safeHtml
    "
  ></div>
  <div class="quote-remove__action">
    <div class="quote-remove__action-link" (click)="deleteQuoteFromModal()">
      {{ "accountQuotes.remove.delete" | cxTranslate }}
    </div>
    <button class="quote-remove__button" (click)="closeModal('continue')">
      {{ "accountQuotes.remove.cancel" | cxTranslate }}
    </button>
  </div>
</div>
