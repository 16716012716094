<ng-container>
  <a class="custom-product-attributes" (click)="toggleShowCollapse($event)">
    <div class="custom-product-attributes__accordion">
      <div
        class="custom-product-attributes__accordion-header"
        [ngClass]="{ 'accordion-colapsed': showColapse }"
      >
        <app-custom-component-title
          (click)="collapseFromIcon && (showColapse = !showColapse)"
          [title]="titleAttribute"
          [hasFirsWordInBold]="true"
        ></app-custom-component-title>
        <cx-icon
          (click)="collapseFromIcon && (showColapse = !showColapse)"
          class="fas"
          [ngClass]="{
            'fa-chevron-down': !showColapse,
            'fa-chevron-up': showColapse
          }"
        ></cx-icon>
      </div>
    </div>
  </a>
  <div
    class="custom-product-attributes__accordion-body"
    *ngIf="showColapse"
  >
    <div class="accordion-body">
      <ng-content #attributes></ng-content>
    </div>
  </div>
</ng-container>
