import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomPromeclubAddressService } from 'src/app/cms-components/my-account/services/custom-promeclub-address.service';
import { CustomBreakpointService } from '../../../../../../services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-promeclub-order-overview',
  templateUrl: './custom-promeclub-order-overview.component.html',
  styleUrls: ['./custom-promeclub-order-overview.component.scss'],
})
export class CustomPromeclubOrderOverviewComponent {
  @Input() order: any;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    private customBreakpointService: CustomBreakpointService,
    protected customPromeclubAddressService: CustomPromeclubAddressService
  ) {}
}
