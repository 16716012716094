import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CustomBreakpointService } from '../../../../../services/custom-breakpoint.service';
import { Observable } from 'rxjs';
import SwiperCore, { Mousewheel, Navigation, SwiperOptions } from 'swiper';
import { CustomGetImageUrlService } from 'src/app/services/custom-get-image-url.service';
import { CustomSubcategory } from '../../../model/custom-subcategory.interface';
import { Router } from '@angular/router';
SwiperCore.use([Mousewheel]);
SwiperCore.use([Navigation]);
@Component({
  selector: 'app-custom-product-variant-list-card',
  templateUrl: './custom-product-variant-list-card.component.html',
  styleUrls: ['./custom-product-variant-list-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomProductVariantListCardComponent implements OnInit {

  @Input() subcategories: CustomSubcategory[];
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  currentUrl: string = this.router.url;
  swiperConfig: SwiperOptions = {
    slidesPerView: 3.3,
    centeredSlides: false,
    spaceBetween: 20,
    freeMode: true,
    mousewheel: {
      releaseOnEdges: true,
    },
    pagination: false,
    breakpoints: {
      526: {
        slidesPerView: 4.2,
      },
      696: {
        slidesPerView: 5.2,
      },
      866: {
        slidesPerView: 6.2,
      },
    },
  };

  swiperConfigDesktop: SwiperOptions = {
    centeredSlides: false,
    spaceBetween: 15,
    freeMode: false,
    navigation: true,
    pagination: false,
    observer: true,
    observeParents: true,
    breakpoints: {
      526: {
        slidesPerView: 4.2,
      },
      696: {
        slidesPerView: 5.2,
      },
      866: {
        slidesPerView: 5,
      },
      1100: {
        slidesPerView: 5.4,
      },
      1289: {
        slidesPerView: 7,
      },
      1600: {
        slidesPerView: 9,
      },
      1750: {
        slidesPerView: 10,
      }
    },
  };
  constructor(
    private customBreakpointService: CustomBreakpointService,
    private customGetImageUrl: CustomGetImageUrlService,
    private router: Router
  ) { }

  ngOnInit(): void {

  }

  getImage(url: string): string {
    return this.customGetImageUrl.getImageUrl(url);
  }

  isActiveUrl(categoryCode: string) {
    return this.currentUrl.includes(categoryCode);
  }
}
