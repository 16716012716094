import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FacetValue } from '../../../models/custom-search-facets.model';
import { CustomCategoryData } from 'src/app/cms-components/custom/components/custom-category-list/model/custom-category-list.interface';

@Component({
  selector: 'app-custom-searchbox-list-items',
  templateUrl: './custom-searchbox-list-items.component.html',
  styleUrls: ['./custom-searchbox-list-items.component.scss']
})
export class CustomSearchboxListItemsComponent {

  @Input() isCategory: boolean = false;
  @Input() isSuggest: string = '';
  @Input() categories: Observable<CustomCategoryData>[] | FacetValue[];

}
