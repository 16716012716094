import { Component, Inject, OnInit } from '@angular/core';
import { CmsBannerComponent, CmsComponent, CmsResponsiveBannerComponentMedia, CmsService, Config } from '@spartacus/core';
import { CmsComponentData, ICON_TYPE } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomCmsBannerCarouselComponent } from 'src/app/cms-components/content/components/custom-banner-carousel/model/custom-banner-carousel';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomGetImageUrlService } from 'src/app/services/custom-get-image-url.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-custom-splitted-banner',
  templateUrl: './custom-splitted-banner.component.html',
  styleUrls: ['./custom-splitted-banner.component.scss'],
})
export class CustomSplittedBannerComponent implements OnInit {
  iconTypes = ICON_TYPE;
  componentData$: Observable<CustomCmsBannerCarouselComponent> = this.componentData.data$;
  items$: Observable<Observable<CmsBannerComponent>[]>;
  swiperConfig$ = new BehaviorSubject<SwiperOptions>({});
  isMobile$: Observable<boolean> = this.customBreakpoinService.isMobile$;
  constructor(
    protected componentData: CmsComponentData<CustomCmsBannerCarouselComponent>,
    protected cmsService: CmsService,
    protected customGetImageUrl: CustomGetImageUrlService,
    protected customBreakpoinService: CustomBreakpointService,
    @Inject(Config) protected config: any
  ) { }

  ngOnInit(): void {
    this.items$ = this.componentData$.pipe(
      map((data) => data.responsiveBanners.trim().split(' ')),
      map((codes) =>
        codes.map((code) =>
          this.cmsService
            .getComponentData(code)
            .pipe(
              map((bannerData: CmsComponent | null) => ({
                ...bannerData,
                image: this.customBreakpoinService?.getImageByBreakpoint(
                  <CmsResponsiveBannerComponentMedia>bannerData
                ),
              })),
            )
        )
      ),
      map(items => items?.slice(0, 3))
    );
  }


  getImageUrl(url: string): string {
    return this.customGetImageUrl.getImageUrl(url);
  }

}
