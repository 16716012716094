<ng-container *ngIf="categories.length > 0">
  <div class="results-list">
    <h3 class="results-list__title">
      {{ isCategory ?  ("searchBox.categories" | cxTranslate) : ("searchBox.brands" | cxTranslate) }}
    </h3>
    <div class="results-list__items">
      <div class="results-list__items-scroll" [ngClass]="{'brands': !isCategory}">
        <ng-container *ngFor="let categ of categories">
          <app-custom-searchbox-item [isCategory]="isCategory" [isSuggest]="isSuggest" [category]="categ"></app-custom-searchbox-item>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
