import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule, ModalModule } from '@spartacus/storefront';
import { CustomModalGenericComponent } from './custom-modal-generic.component';



@NgModule({
  declarations: [
    CustomModalGenericComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    ModalModule,
    I18nModule,
  ],
  exports: [
    CustomModalGenericComponent
  ]
})
export class CustomModalGenericModule { }
