import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { CmsParagraphComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomTalleresService } from 'src/app/services/custom-talleres.service';

interface Position {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-custom-talleres',
  templateUrl: './custom-talleres.component.html',
  styleUrls: ['./custom-talleres.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
// https://smaillns.medium.com/integrating-google-maps-in-angular-application-using-angular-google-maps-280a66c32c57
// https://developers.google.com/maps/documentation/javascript/adding-a-google-map
export class CustomTalleresComponent implements OnInit, OnDestroy {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;

  static readonly BREAKPOINT_SCREEN_LG = 1600;
  static readonly WIDTH_MAP_LARGE_SCREEN = '943px';
  static readonly WIDTH_MAP_MEDIUM_SCREEN = '768px';
  static readonly ZOOM_MAP_LARGE_SCREEN = 7.5;
  static readonly ZOOM_MAP_MEDIUM_SCREEN = 7;

  findTalleresForm: FormGroup = this.fb.group(
    {
      value: [''],
    },
    { updateOn: 'change' }
  );

  ecuadorLocation: Position = {
    lat: -1.5538333891733667,
    lng: -78.32849639355092,
  };

  center: google.maps.LatLngLiteral;
  iconMarker =
    'https://developers.google.com/static/maps/documentation/javascript/images/default-marker.png';
  selectedBrands: string[] = [];
  infoContent: string = '';
  infoTitle: string = '';
  data: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  data$: Observable<any> = this.data.asObservable();
  allWorkshops: any[] = [];
  height: string =
    window.outerWidth > CustomTalleresComponent.BREAKPOINT_SCREEN_LG
      ? CustomTalleresComponent.WIDTH_MAP_LARGE_SCREEN
      : CustomTalleresComponent.WIDTH_MAP_MEDIUM_SCREEN;
  zoom: number =
    window.outerWidth > CustomTalleresComponent.BREAKPOINT_SCREEN_LG
      ? CustomTalleresComponent.ZOOM_MAP_LARGE_SCREEN
      : CustomTalleresComponent.ZOOM_MAP_MEDIUM_SCREEN;
  unsubscribe$: Subject<void> = new Subject();

  constructor(
    public component: CmsComponentData<CmsParagraphComponent>,
    protected customTalleresService: CustomTalleresService,
    private fb: FormBuilder
  ) {}

  setData(data: Array<any>) {
    this.data.next(data);
  }

  ngOnInit(): void {
    this.center = {
      lat: this.ecuadorLocation.lat,
      lng: this.ecuadorLocation.lng,
    };

    this.customTalleresService
      .findTalleres('', [])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        for (let i = 0; i < data.length; i++) {
          let taller = {
            position: {
              lat: Number(data[i].latitude),
              lng: Number(data[i].longitude),
            },
            label: {
              color: 'red',
              text: data[i].name,
              fontSize: '16px',
              className: 'content_text',
            },
            title: data[i].name,
            info: 'Av. Nombre Falsa 1122, CABA',
            icon: {
              url: this.iconMarker,
              labelOrigin: new google.maps.Point(10, -7),
            },
          };
          this.allWorkshops.push(taller);
        }

        this.setData(this.allWorkshops);
      });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    innerWidth > CustomTalleresComponent.BREAKPOINT_SCREEN_LG
      ? (this.height = CustomTalleresComponent.WIDTH_MAP_LARGE_SCREEN)
      : (this.height = CustomTalleresComponent.WIDTH_MAP_MEDIUM_SCREEN);
    innerWidth > CustomTalleresComponent.BREAKPOINT_SCREEN_LG
      ? (this.zoom = CustomTalleresComponent.ZOOM_MAP_LARGE_SCREEN)
      : (this.zoom = CustomTalleresComponent.ZOOM_MAP_MEDIUM_SCREEN);
  }

  workshops(event: any[]) {
    this.allWorkshops = [];
    this.setData([]);
    this.zoom = 6;
    this.center = {
      lat: this.ecuadorLocation.lat,
      lng: this.ecuadorLocation.lng,
    };

    for (let i = 0; i < event.length; i++) {
      let taller = {
        position: {
          lat: Number(event[i].latitude),
          lng: Number(event[i].longitude),
        },
        label: {
          color: 'red',
          text: event[i].name,
          fontSize: '16px',
          className: 'content_text',
        },
        title: event[i].name,
        info: 'Av. Nombre Falsa 1122, CABA', // colocar campo con domicilio de la sucursal
        icon: {
          url: this.iconMarker,
          labelOrigin: new google.maps.Point(10, -7),
        },
      };
      this.allWorkshops.push(taller);
    }
    this.setData(this.allWorkshops);
  }

  selectedWorkshop(event: any) {
    this.allWorkshops = [];
    this.setData([]);

    let taller = {
      position: {
        lat: Number(event.latitude),
        lng: Number(event.longitude),
      },
      label: {
        color: 'red',
        text: event.name,
        fontSize: '16px',
        className: 'content_text',
      },
      title: event.name,
      info: 'Av. Nombre Falsa 1122, CABA', // colocar campo con domicilio de la sucursal
      icon: {
        url: this.iconMarker,
        labelOrigin: new google.maps.Point(10, -7),
      },
    };

    this.allWorkshops.push(taller);
    this.setData(this.allWorkshops);

    this.center = {
      lat: taller?.position?.lat,
      lng: taller?.position?.lng,
    };
    this.zoom = 10;
  }

  openInfo(marker: MapMarker, info: string, title: string) {
    this.infoTitle = title;
    this.infoContent = info;
    this.info.open(marker);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
