<ng-container *ngIf="returnRequest$ | async as returnRequest">
  <div class="custom-return-order-detail">
    <app-custom-title-my-account
      [title]="
        'returnRequest.confirmReturnTitle'
          | cxTranslate : { orderCode: returnRequest?.code }
      "
    ></app-custom-title-my-account>
    <div class="custom-return-order-detail__actions">
      <div>
        <p class="custom-return-order-detail__subtitle">
          {{
            "returnRequest.subtitleRevisionDetail"
              | cxTranslate : { orderCode: returnRequest?.order?.code }
          }}
        </p>
        <p class="custom-return-order-detail__subtitle-description">
          {{ "returnRequest.descriptionRevisionDetail" | cxTranslate }}
        </p>
      </div>
      <div>
        <app-custom-return-order-actions-cancel
          *ngIf="returnRequest.status == 'APPROVAL_PENDING'"
          [orderCode]="returnRequest?.code"
        ></app-custom-return-order-actions-cancel>
      </div>
    </div>

    <p class="custom-return-order-detail__subtitle-revision">
      {{ "returnRequest.subtitleDetailReturn" | cxTranslate }}
    </p>
  </div>
  <app-custom-order-overview [order]="returnRequest">
  </app-custom-order-overview>
  <div class="custom-return-products">
    <ng-container>
      <app-custom-return-order-header-detail
        [orderReturn]="returnRequest$"
        [currentPage]="currentPage"
        (nextPage)="sendOrderDevolution($event)"
      ></app-custom-return-order-header-detail>
    </ng-container>
  </div>

  <app-custom-account-comment
    [label]="'returnRequest.commentDevolution' | cxTranslate"
    [placeholder]="'returnRequest.placeholderComment' | cxTranslate"
    (sendComment)="commentReturnOrder($event)"
    [currentPage]="currentPage"
    [valueDefault]="returnRequest.comment"
  ></app-custom-account-comment>

  <app-custom-return-order-actions
    forwardRoute="ordersDevolution"
  ></app-custom-return-order-actions>
</ng-container>
