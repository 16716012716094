<div [formGroup]="mode">
  <hr *ngIf="(isMobile$ | async)">
  <div class="row custom-delivery-mode">
    <div class="col-md-12 col-lg-12 custom-delivery-mode__title">
      <h2 class="cx-checkout-title">
        {{ "checkoutShipping.shippingMethod" | cxTranslate }}
      </h2>
    </div>
    <div class="col-md-12 col-lg-12 p-0">
      <ng-container *ngIf="(supportedDeliveryModes$ | async)?.length; else loading">
        <div class="custom-content">
          <div class="form-check col-md-4 col-lg-4" *ngFor="let mode of supportedDeliveryModes$ | async">
            <ng-container *ngIf="mode.code !== 'pickup_cd'">
              <label class="cx-delivery-label form-check-label form-radio-label" for="deliveryMode-{{ mode.code }}">
                <div class="custom-delivery-mode__input">
                  <input class="form-check-input" role="radio" type="radio" id="deliveryMode-{{ mode.code }}"
                    aria-checked="true" (change)="changeMode(mode.code)" [value]="mode.code"
                    formControlName="deliveryModeId" />
                </div>

                <div>
                  <div class="cx-delivery-mode">{{ mode.name }}</div>

                  <div class="cx-delivery-details" *ngIf="mode.formattedDeliveryDate">
                    {{ "checkoutShipping.deliveryOn" | cxTranslate
                    }}{{ mode.formattedDeliveryDate }}
                  </div>
                </div>
              </label>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="cx-spinner">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
