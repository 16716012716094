import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@spartacus/storefront';
import { CustomWishlistComponent } from './custom-wishlist.component';

@NgModule({
  imports: [
    CommonModule,
    IconModule
  ],
  declarations: [CustomWishlistComponent],
  exports: [CustomWishlistComponent]
})
export class CustomWishlistModule { }
