import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomCartQuotesComponent } from './custom-cart-quotes.component';

@NgModule({
  declarations: [CustomCartQuotesComponent],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    RouterModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CustomCartQuotes: {
          component: CustomCartQuotesComponent,
        },
      },
    }),
  ],
  exports: [CustomCartQuotesComponent],
})
export class CustomCartQuotesModule {}
