import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { OccEndpointsService, Region } from '@spartacus/core';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { pluck, takeUntil } from 'rxjs/operators';
import { CustomPinDialogComponent } from 'src/app/common/components/custom-pin-dialog/custom-pin-dialog.component';
import { CustomTwilioValidatorService } from 'src/app/common/services/custom-twilio-validator.service';
import { CustomUser } from '../../user/models/custom-user.interface';
import { CustomAddress, FormAddress } from '../models/custom-address.interface';
import { CustomPromeclubUserService } from './custom-promeclub-user.service';

@Injectable({
  providedIn: 'root',
})
export class CustomPromeclubAddressService implements OnDestroy {
  user$: BehaviorSubject<CustomUser>;
  countryIsoCode: string = 'PEC';
  country: Region = { isocode: 'PEC', name: 'Ecuador Promeclub' };
  modalRef: ModalRef;
  unsubscribe$: Subject<void> = new Subject();
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
    private customPromeclubUserService: CustomPromeclubUserService,
    private modalService: ModalService,
    private customTwilioValidatorService: CustomTwilioValidatorService
  ) {}

  getAvailableAddressTypes(): Observable<string[]> {
    const url = this.occEndpointService.buildUrl('promeclubAddressType');
    return this.http.get<string[]>(url);
  }

  getAddresses(): Observable<CustomAddress[]> {
    const url = this.occEndpointService.buildUrl('getPromeclubUserAddresses');
    return this.http.get<CustomAddress[]>(url).pipe(pluck('addresses'));
  }
  addAddress(address: FormAddress) {
    this.user$ = new BehaviorSubject<CustomUser>(
      this.customPromeclubUserService.getUser()
    );
    const url = this.occEndpointService.buildUrl('promeclubCreateAddress');
    let customAddress = this.mapToCustomAddress(address);

    return this.http.post(url, customAddress);
  }

  getProvinces(): Observable<Region[]> {
    const url = this.occEndpointService.buildUrl('promeclubProvinces', {
      urlParams: {
        countryIsoCode: this.countryIsoCode,
      },
    });

    return this.http.get<Region[]>(url).pipe(pluck('regions'));
  }

  getDistricts(regionIsoCode: string): Observable<Region[]> {
    const url = this.occEndpointService.buildUrl('promeclubDistricts', {
      urlParams: {
        countryIsoCode: this.countryIsoCode,
        regionIsoCode,
      },
    });

    return this.http.get<Region[]>(url).pipe(pluck('regions'));
  }

  deleteAddress(addressId: string) {
    const url = this.occEndpointService.buildUrl('promeclubDeleteAddress', {
      urlParams: {
        addressId,
      },
    });

    return this.http.delete(url);
  }

  updateAddress(address: FormAddress, addressId: string) {
    this.user$ = new BehaviorSubject<CustomUser>(
      this.customPromeclubUserService.getUser()
    );
    const url = this.occEndpointService.buildUrl('promeclubUpdateAddress', {
      urlParams: {
        addressId,
      },
    });
    let customAddress = this.mapToCustomAddress(address);
    return this.http.put(url, customAddress);
  }

  typeTranslation(address: CustomAddress): string {
    switch (address.promotickAddressType) {
      case 'COTTAGE':
        return 'Casa de campo';
      case 'BEACH_HOUSE':
        return 'Casa de playa';
      case 'HOUSE':
        return 'Casa';
      case 'OFFICE':
        return 'Oficina';
      default:
        return 'Otro';
    }
  }

  mapToCustomAddress(address: FormAddress) {
    let region = {
      ...address.region,
      countryIso: this.countryIsoCode,
    };
    let customAddress = {
      ...address,
      district: address.district.name,
      town: address?.district?.name || address?.district,
      postalCode: address?.district?.isocode || address?.region?.isocode,
      region,
      country: this.country,
      email: this.user$.value?.email || '',
      phone: this.user$.value?.phone || '',
      firstName: this.user$.value?.firstName || '',
      lastName: this.user$.value?.lastName || '',
      companyName: this.user$.value?.orgUnit?.name || '',
      shippingAddress: true,
    };

    return customAddress;
  }

  submitValidation() {
    let modalInstance: any;
    this.user$ = new BehaviorSubject<CustomUser>(
      this.customPromeclubUserService.getUser()
    );
    const email = this.user$.value.email;

    this.customTwilioValidatorService
      .getValidationStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((status) => {
        if (status === 'FALSE') {
          this.customTwilioValidatorService.setValidationStatus('');
        }
      });

    this.customTwilioValidatorService
      .sendCodeToPhoneUsingEmail(email!)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();

    this.modalRef = this.modalService.open(CustomPinDialogComponent, {
      centered: false,
      size: 'lg',
      windowClass: 'twilio',
    });

    modalInstance = this.modalRef.componentInstance;
    modalInstance.email = email;
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
