import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomReloadCartService } from './cms-components/cart/services/custom-reload-cart.service';
import { CustomTopLinkService } from './services/custom-top.link.service';
import { CustomRouterEvent } from './models/custom-router-event.interface';
import { CustomGtmDatalayerService } from './common/services/custom-gtm-datalayer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  isProduction: boolean = environment.production;
  previousUrl: string = 'undefined';
  currentUrl: string = 'undefined';

  constructor(
    private customRealoadCartService: CustomReloadCartService,
    private router: Router,
    private customTopLinkService: CustomTopLinkService,
    private customGtmDatalayerService: CustomGtmDatalayerService
  ) {
    this.customGtmDatalayerService.setGTMScript();
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationCancel
        )
      )
      .subscribe((event: any) => {
        this.manageNavigationEvent(event as CustomRouterEvent);
      });
    this.customTopLinkService.initializeSection();
  }

  manageNavigationEvent(event: CustomRouterEvent): void {
    if (
      event?.url === '/logout' &&
      event?.reason?.includes('NavigationCancelingError')
    ) {
      const url = window.location.origin;
      window.open(url, '_self');
    }
    this.previousUrl = this.currentUrl;
    this.currentUrl = event.url;

    this.customRealoadCartService.setPreviousUrl(this.previousUrl);
    this.customRealoadCartService.setcurrentUrl(this.currentUrl);
  }
}
