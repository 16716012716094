import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AuthService, isNotNullable, ProductScope } from '@spartacus/core';
import {
  CurrentProductService,
  ProductSummaryComponent,
} from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, map, filter, tap } from 'rxjs/operators';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';
import { CustomBaseSiteCheckerService } from '../../../../services/custom-base-site-checker.service';

@Component({
  selector: 'app-custom-product-summary',
  templateUrl: './custom-product-summary.component.html',
  styleUrls: ['./custom-product-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomProductSummaryComponent
  extends ProductSummaryComponent
  implements OnInit
{
  @ViewChild('summaryElement') summaryElement: ElementRef;
  @ViewChild('termsElement') termsElement: ElementRef;
  isUserLoggedIn$: Observable<boolean> = this.auth.isUserLoggedIn();
  userId$: Observable<string> = this.userAccountFacade
    .get()
    .pipe(map((user) => user?.uid!));
  isMobile$: Observable<boolean>;
  isMobilePdp: boolean = true;
  showMore$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  addToggler$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showMoreTerms$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  addTermsToggler$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  productCode: string | undefined;
  hideElement: boolean = false;
  hideTermsElement: boolean = false;
  isPromeclub: boolean = false;
  isPromeclub$: Observable<boolean> =
    this.customBaseSiteCheckerService.isPromeclub$.pipe(
      tap((isPromeclub) => (this.isPromeclub = isPromeclub))
    );
  constructor(
    currentProductService: CurrentProductService,
    private customBreakpointService: CustomBreakpointService,
    private auth: AuthService,
    private userAccountFacade: UserAccountFacade,
    private customBaseSiteCheckerService: CustomBaseSiteCheckerService
  ) {
    super(currentProductService);
  }

  ngOnInit() {
    this.isMobile$ = this.customBreakpointService.isMobile$;
    this.product$ = this.currentProductService
      .getProduct([ProductScope.DETAILS])
      .pipe(
        filter(isNotNullable),
        tap((product) => {
          this.hideElement = false;
          this.hideTermsElement = false;
          this.initializeTogglers();
          this.showMore$.next(true);
          this.addToggler$.next(false);
          this.showMoreTerms$.next(true);
          this.addTermsToggler$.next(false);
          this.getElementHeight();
        })
      );
  }

  ngAfterViewInit(): void {
    this.getElementHeight();
  }

  toggleShowMore(): void {
    this.showMore$.next(!this.showMore$.value);
  }
  toggleShowMoreTerms(): void {
    this.showMoreTerms$.next(!this.showMoreTerms$.value);
  }
  initializeTogglers(): void {
    this.showMore$.next(true);
    this.addToggler$.next(false);
    if (this.isPromeclub) {
      this.showMoreTerms$.next(true);
      this.addTermsToggler$.next(false);
    }
  }
  getElementHeight(): void {
    of({})
      .pipe(delay(1500))
      .subscribe(() => {
        if (this.summaryElement?.nativeElement?.clientHeight > 54) {
          this.addToggler$.next(true);
        }
        if (
          this.termsElement?.nativeElement?.clientHeight > 54 &&
          this.isPromeclub
        ) {
          this.addTermsToggler$.next(true);
        }
        this.showMoreTerms$.next(false);
        this.hideTermsElement = true;
        this.showMore$.next(false);
        this.hideElement = true;
      });
  }
}
