import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPaymentTypeComponent } from './custom-payment-type.component';
import {
  CheckoutAuthGuard,
  CartNotEmptyGuard,
  CheckoutStepsSetGuard,
} from '@spartacus/checkout/components';
import { ConfigModule, CmsConfig, I18nModule } from '@spartacus/core';
import { CartValidationGuard } from '@spartacus/storefront';
import { CustomPaymentCashComponent } from './custom-payment-cash/custom-payment-cash.component';
import { CustomInformationModule } from 'src/app/cms-components/custom/components/custom-information/custom-information.module';
import { CustomErrorModule } from '../../../../cms-components/custom/components/custom-error/custom-error.module';
import { CustomPaymentCashTableComponent } from './custom-payment-cash/custom-payment-cash-table/custom-payment-cash-table.component';
import { CustomPaymentCreditModule } from './custom-payment-credit/custom-payment-credit.module';
import { CustomPaymentPointComponent } from './custom-payment-point/custom-payment-point.component';
import { CustomLoadingSpinnerModule } from 'src/app/cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomReviewSubmitModule } from '../custom-review-submit/custom-review-submit.module';
import { CustomPlaceOrderModule } from '../custom-place-order/custom-place-order.module';

@NgModule({
  declarations: [
    CustomPaymentTypeComponent,
    CustomPaymentCashComponent,
    CustomPaymentCashTableComponent,
    CustomPaymentPointComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    CustomInformationModule,
    CustomErrorModule,
    CustomPaymentCreditModule,
    CustomLoadingSpinnerModule,
    CustomReviewSubmitModule,
    CustomPlaceOrderModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutPaymentType: {
          component: CustomPaymentTypeComponent,
          guards: [
            CheckoutAuthGuard,
            CartNotEmptyGuard,
            CartValidationGuard,
            CheckoutStepsSetGuard,
          ],
        },
      },
    }),
  ],
  exports: [CustomPaymentCashTableComponent],
})
export class CustomPaymentTypeModule {}
