import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCenterDistributionComponent } from './custom-center-distribution.component';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomInformationModule } from 'src/app/cms-components/custom/components/custom-information/custom-information.module';
import { CustomLoadingSpinnerModule } from 'src/app/cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';

@NgModule({
  declarations: [CustomCenterDistributionComponent],
  exports: [CustomCenterDistributionComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    IconModule,
    CustomInformationModule,
    CustomLoadingSpinnerModule,
  ],
})
export class CustomCenterDistributionModule {}
