<app-custom-modal-generic [iconHeaderClass]="'fas fa-check-circle'" [titlePopUp]="'futureStock.title' | cxTranslate"
  [isPopUpV2]="true" (closeModal)="customDismissModal($event)" [isClose]="true"
  *ngIf="isMobile$ | async"></app-custom-modal-generic>
<app-custom-modal-generic (closeModal)="customDismissModal($event)" [isClose]="true"
  *ngIf="!(isMobile$ | async)"></app-custom-modal-generic>
<div class="custom-future-stock">
  <div class="custom-future-stock__body">
    <ng-container *ngIf="!(isMobile$ | async)">
      <div class="custom-future-stock__body-title">
        <cx-icon class="fas fa-check-circle"></cx-icon>
        {{ "futureStock.title" | cxTranslate }}
      </div>
    </ng-container>
    <div class="custom-future-stock__body-text">
      {{ "futureStock.text" | cxTranslate }}
    </div>
  </div>
  <div class="custom-future-stock__footer">
    <button type="button" class="btn btn-primary" (click)="customDismissModal()">
      {{ "common.close" | cxTranslate }}
    </button>
  </div>
</div>
