<ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
  <ng-container *ngIf="!data.isMobile">
    <div *ngIf="pageTitle$ | async as title$" class="page-title">
      <h3
        class="page-title-value"
        *ngIf="title$ | async as title"
        [innerHTML]="title | titlecase | safeHtml"
      ></h3>
    </div>
    <div class="description" *ngIf="description">
      <p class="description-text" [innerHTML]="description | safeHtml"></p>
    </div>
  </ng-container>
  <ng-container *ngIf="description && data.isMobile">
    <div [ngClass]="{ 'description-mobile': data.isMobile }">
      <h3 class="title" *ngIf="data.isMobile && description">
        {{ "productList.description" | cxTranslate }}
        <div class="icon" (click)="showDesc()">
          <cx-icon
            class="fas fa-chevron-down"
            *ngIf="!showDescription"
          ></cx-icon>
          <cx-icon class="fas fa-chevron-up" *ngIf="showDescription"></cx-icon>
        </div>
      </h3>
      <div class="description" *ngIf="showDescription">
        <p class="description-text" [innerHTML]="description | safeHtml"></p>
      </div>
    </div>
  </ng-container>
</ng-container>
