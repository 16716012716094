import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomActiveFacetsComponent } from './custom-active-facets.component';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { CustomCurrencyModule } from '../../../../../../pipes/custom-currency/custom-currency.module';

@NgModule({
  declarations: [CustomActiveFacetsComponent],
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    IconModule,
    KeyboardFocusModule,
    CustomCurrencyModule
  ],
  exports: [CustomActiveFacetsComponent],
})
export class CustomActiveFacetsModule {}
