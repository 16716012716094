import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CmsComponent, CmsService } from '@spartacus/core';
import {
  HamburgerMenuService,
  NavigationUIComponent,
} from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { CustomGtmSearchSelectionDatalayerService } from 'src/app/common/services/custom-gtm-search-selection-datalayer.service';
import { CustomGetImageUrlService } from 'src/app/services/custom-get-image-url.service';
import { CustomTopLinkService } from 'src/app/services/custom-top.link.service';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-category-navigation-ui',
  templateUrl: './custom-category-navigation-ui.component.html',
  styleUrls: ['./custom-category-navigation-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CustomCategoryNavigationUiComponent
  extends NavigationUIComponent
  implements OnInit
{
  @Input() isPromeclub: boolean = false;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$.pipe(
    tap((value) => (value ? this.clear() : null))
  );
  componentData$: Observable<CmsComponent> =
    this.cmsService.getComponentData('WishlistComponent');
  private customOpenNodes: HTMLElement[] = [];
  private customSubscription = new Subscription();
  lastChild: string;
  hideByNavigation: boolean = false;
  baseUrl: string = this.customGetImageUrl.getBaseUrl();
  showWrapper: boolean = false;
  currentSection: string = this.customTopLinkService.getCurrentSection() || '';
  constructor(
    protected customRouter: Router,
    protected customRenderer: Renderer2,
    protected customElemRef: ElementRef,
    hamburgerMenuService: HamburgerMenuService,
    protected customBreakpointService: CustomBreakpointService,
    protected customGetImageUrl: CustomGetImageUrlService,
    protected cmsService: CmsService,
    private customGtmService: CustomGtmSearchSelectionDatalayerService,
    private customTopLinkService: CustomTopLinkService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(customRouter, customRenderer, customElemRef, hamburgerMenuService);
    this.customSubscription.add(
      this.customRouter.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => this.showTopLinkByHamburguerClose())
    );
    this.customSubscription.add(
      this.hamburgerMenuService.isExpanded.subscribe((value) => {
        if (!value) {
          this.unlockBodyScroll();
        }
      })
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getImageUrl(url: string): string {
    return this.customGetImageUrl.getImageUrl(url);
  }

  onMouseEnterCustom(event: MouseEvent, node: any) {
    this.hideByNavigation = false;
    this.showWrapper = true;
    if (node?.children?.length > 0) {
      this.lastChild = node.title;
    }
    this.onMouseEnter(event);
  }

  toggleOpen(event: UIEvent, nodeIU?: any): void {
    this.lastChild = nodeIU.title;
    if (!nodeIU.toggle) {
      this.customRouter.navigateByUrl(nodeIU.url);
      this.reinitalizeMenu();
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
    }
    const node = <HTMLElement>event.currentTarget;
    if (this.customOpenNodes.includes(node)) {
      if (event.type === 'keydown') {
        this.back();
      } else {
        this.customOpenNodes = this.customOpenNodes.filter((n) => n !== node);
        this.customRenderer.removeClass(node, 'is-open');
      }
    } else {
      this.customOpenNodes.push(node);
    }
    this.customUpdateClasses();
    event.stopImmediatePropagation();
    event.stopPropagation();
  }

  customUpdateClasses(): void {
    this.customOpenNodes.forEach((node, i) => {
      this.customRenderer.removeClass(node, 'is-opened');
      this.customRenderer.addClass(node, 'is-open');
    });

    this.isOpen = this.customOpenNodes.length > 0;
  }

  showTopLinkByHamburguerClose() {
    const topBarIsHide = this.document
      .getElementsByClassName('TopHeaderSlot')[0]
      .classList.contains('hide');
    if (topBarIsHide) {
      this.document
        .getElementsByClassName('TopHeaderSlot')[0]
        .classList.remove('hide');
      this.unlockBodyScroll();
    }
  }

  unlockBodyScroll() {
    const bodyIsBlocked: boolean =
      this.document.body.classList.contains('menu-active');
    if (bodyIsBlocked) {
      this.document.body.classList.remove('menu-active');
    }
  }

  navigate(url: string, node?: any, isMobile: boolean = false) {
    this.hideByNavigation = true;
    const categoryHeader = localStorage.getItem('currentSection')!;
    this.customGtmService.headerEvent(
      categoryHeader,
      this.lastChild,
      node.title
    );
    this.customRouter.navigateByUrl(url).then((resolve) => {
      if (!resolve && !this.isPromeclub && !isMobile) {
        window.location.reload();
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.customSubscription.unsubscribe();
  }
}
