import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomModalComponent } from './custom-modal.component';
import { IconModule } from '@spartacus/storefront';

@NgModule({
  declarations: [CustomModalComponent],
  imports: [CommonModule, IconModule],
  exports: [CustomModalComponent],
})
export class CustomModalModule {}
