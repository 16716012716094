export const savedCartDetails = {
  cartName: 'Nombre',
  cartDescription: 'Descripción',
  cartId: 'ID',
  dateSaved: 'Fecha de carrito guardado',
  items: 'Artículos',
  quantity: 'Cantidad',
  total: 'Total',
  editSavedCart: 'Editar Carrito Guardado',
  deleteSavedCart: 'Borrar Carrito Guardado',
  addSelectedItems: 'Agregar artículos seleccionados',
  emptyCartItems: 'Carrito sin artículos',
};

export const savedCartList = {
  savedCarts: 'Carritos Guardados ({{count}})',
  cartName: 'Nombre',
  cartId: 'Id',
  dateSaved: 'Fecha de carrito guardado',
  cartDescription: 'Descripción',
  quantity: 'Cant',
  total: 'Total',
  actions: 'Acciones',
  makeCartActive: 'Establecer como carrito activo',
  notFound: 'No se encontraron carritos guardados',
  swapCartNoActiveCart:
    'El carrito {{cartName}} existente se ha activado exitosamente.',
  swapCartWithActiveCart:
    'El carrito {{cartName}} existente se ha activado exitosamente. Los artículos anteiores fueron guardados en el carrito {{previousCartName}}.',
};

export const savedCartCartPage = {
  messages: {
    cartSaved:
      'Los artículos de tu carrito han sido guardados exitosamente en el carrito "{{cartName}}"',
  },
};

export const savedCartDialog = {
  saveForLater: 'Crear lista de compra',
  itemsSavedForLater:
    'La lista de compra será guardada para tus compras futuras.',
  savedCartName: 'Nombre de la lista',
  savedCartDescription: 'Descripción del Carrito Guardado',
  optional: 'opcional',
  charactersLeft: 'caracteres restantes: {{count}}',
  cancel: 'Cancelar',
  save: 'Crear lista',
  restore: 'Restaurar',
  followingCartDelete: 'Se eliminará el siguiente carrito guardado',
  followingCartRestore:
    'El siguiente carrito guardado se restaurará como el carrito activo',
  delete: 'Borrar',
  deleteCartSuccess: 'Carrito Borrado Exitosamente',
  editCartSuccess: 'Carrito Editado Exitosamente',
  editSavedCart: 'Editar Carrito Guardado',
  deleteSavedCart: 'Borrar Carrito Guardado',
  restoreSavedCart: 'Restaurar carrito guardado',
  name: 'Nombre',
  id: 'ID',
  description: 'Descripción',
  quantity: 'CANT',
  total: 'Total',
  keepCopySavedCart:
    'Mantener una copia de este carrito en la lista de carritos guardados',
  defaultCloneCartName: 'Copia de {{nombre}}',
  nameOfCloneCart: 'Nombre del carrito copiado',
};

export const addToSavedCart = {
  savedCarts: 'Crear lista de compra',
  saveCartForLater: 'Crear lista de compra',
};

export const savedCart = {
  savedCartDetails,
  savedCartList,
  savedCartCartPage,
  savedCartDialog,
  addToSavedCart,
};
