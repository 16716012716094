export const customNonCustomerRegister = {
  nonCustomerRegister: {
    nonCustomerRegister: {
      title: 'Registro de nuevo cliente',
      titleConfirmation: 'Confirmación de datos',
      naturalPerson: 'Persona natural',
      juridicPerson: 'Persona jurídica',
      rucLastUpdate: 'Última actualización del RUC:',
      bussinessType: 'Tipo de negocio:',
      phone: 'Teléfono:',
      phonePlaceholder: 'Ingresa tu teléfono',
      cellphone: 'Teléfono móvil:',
      cellphonePlaceholder: 'Ingresa tu teléfono móvil',
      ruc: 'RUC:',
      rucPlaceholder: 'Ingresa tu RUC',
      email: 'E-mail:',
      emailPlaceholder: 'Ingresa tu e-mail',
      province: 'Provincia',
      socialReasonTooltip:
        'El nombre debe coincidir con el registrado en el SRI',
      rucTooltip: 'Número de 13 dígitos terminado en 001',
      phoneTooltip: 'Ingresar hasta 7 dígitos',
      cellphoneTooltip: 'Ingresar hasta 9 dígitos',
      docUploadTitle: 'Carga de documentación',
      registerDocParagraph:
        'Los documentos deben de ser de tipo .pdf con un peso máximo de 25 mb.',
      attachRuc: 'Adjuntar RUC:',
      attachId: 'Adjuntar cédula:',
      attachRegisteredAppoinment: 'Adjuntar nombramiento inscrito:',
      docLetterSubtitle: 'Carta de autorización buró',
      docLetterSubtitleParagraph:
        'Descarga la carta de autorización para luego adjuntar el documento firmado.',
      downloadLetter: 'Descargar carta de autorización buró:',
      attachLetter: 'Adjuntar carta de autorización buró:',
      download: 'Descargar',
      upload: 'Cargar',
      dragOrUpload: 'Arrastrar o cargar archivo .pdf',
      uploadPdf: 'Cargar archivo .pdf',
      validFile: 'Documento válido',
      invalidFileUpload: 'El documento adjunto debe de ser de tipo <b>.pdf</b>',
      invalidFileSize: 'El archivo no puede superar los 25Mb',
      uploadingFile: 'Cargando archivo',
      deleteFile: 'Deseas eliminar de forma permanente el archivo',
      delete: 'Eliminar',
      cancel: 'Cancelar',
      doc: 'Documento',
      return: 'Regresar',
      rucAlreadyExists:
        'El número de RUC ya se encuentra en nuestra base de datos. Continúa el proceso de registro seleccionando <b>Soy cliente de Promesa</b>',
      uidAlreadyExists:
        '<p>El e-mail ya se encuentra asociado a una cuenta activa.</p><p>Ingresa con otro e-mail o ponte en contacto con un asesor comercial.</p>',
      rucAlreadyExistsEmployee: '<p>El código de empleado ya se encuentra asociado a una cuenta activa.</p><p>Ingresa otro código o ponte en contacto con un asesor comercial.</p>',
      registerLink: 'Registro',
      securityValidation: 'Validación de seguridad',
      accept: 'Acepto ',
      termsAndConditions: 'Términos y condiciones',
      newsletter: 'Suscribirme al Newsletter',
      confirm: 'Confirmar',
      socialReasonRequired: '* Debe ingresar una razón social.',
      comercialNameRequired: '* Debe ingresar un nombre comercial.',
      rucRequired: '* Debe ingresar un RUC.',
      rucIncompleted: '* El número de RUC está incompleto.',
      invalidRuc: '* Debe ingresar un RUC válido.',
      dateRequired: '* Debe seleccionar una fecha',
      businessTypeRequired: '* Debe seleccionar un tipo de negocio',
      emailRequired: '* Debe ingresar un e-mail.',
      invalidEmail: '* Debe ingresar un e-mail válido.',
      phoneRequired: '* Debe ingresar un teléfono.',
      invalidPhone: '* Debe ingresar un teléfono válido',
      cellphoneRequired: '* Debe ingresar un teléfono móvil.',
      invalidCellphone: '* Debe ingresar un teléfono móvil válido.',
      uncheckedPerson: '* Debe elegir un tipo',
      pdfDocumentName: 'Carta Aut.pdf',
      registerConfirmation:
        'Agradecemos tu interés en trabajar con nosotros. Un asesor comercial se estará comunicando contigo.',
      registerConfirmationLink: 'Ir al inicio',
      validDoc: 'Documento válido',
      uploadFailed: 'Ocurrió un error al subir el archivo al servidor',
      registerFailed: 'Ocurrió un error en el servidor al registrar el usuario',
      clientInactive: 'El código de cliente no se encuentra activo.',
      clientInactiveMessagge:
        'Comunícate con un oficial de crédito para que puedas registrarte.',
      clientInactiveMobile:
        'El código de cliente no se encuentra activo. Comunícate con un oficial de crédito para que puedas registrarte.',
      goHome: 'Ir al inicio',
    },
  },
};
