import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomClaimComponent } from './custom-claim.component';
import { CustomOrderOverviewModule } from '../../../custom/components/custom-order-overview/custom-order-overview.module';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { IconModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { CustomClaimListComponent } from './custom-claim-list/custom-claim-list.component';
import { CustomAccountTableModule } from 'src/app/cms-components/custom/components/custom-account-table/custom-account-table.module';
import { CustomPaginationModule } from 'src/app/cms-components/custom/components/custom-pagination/custom-pagination.module';
import { CustomEmptyCartModule } from 'src/app/cms-components/custom/components/custom-empty-cart/custom-empty-cart.module';
import { CustomClaimAddComponent } from './custom-claim-add/custom-claim-add.component';
import { CustomClaimDetailComponent } from './custom-claim-detail/custom-claim-detail.component';
import { CustomClaimDialogComponent } from './custom-claim-dialog/custom-claim-dialog.component';
import { CustomLoadingSpinnerModule } from 'src/app/cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomFilterModule } from 'src/app/cms-components/custom/components/custom-filter/custom-filter.module';
import { CustomTitleMyAccountModule } from 'src/app/cms-components/custom/components/custom-title-my-account/custom-title-my-account.module';
import { CustomModalGenericModule } from 'src/app/cms-components/custom/components/custom-modal/custom-modal-generic.module';
import { AuthGuard } from '@spartacus/core';
import { CustomClaimGuard } from './guards/custom-claim.guard';

@NgModule({
  declarations: [
    CustomClaimComponent,
    CustomClaimAddComponent,
    CustomClaimDetailComponent,
    CustomClaimDialogComponent,
    CustomClaimListComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    FormsModule,
    IconModule,
    UrlModule,
    RouterModule,
    ReactiveFormsModule,
    NgSelectModule,
    CustomLoadingSpinnerModule,
    CustomOrderOverviewModule,
    CustomAccountTableModule,
    CustomPaginationModule,
    CustomEmptyCartModule,
    CustomFilterModule,
    CustomModalGenericModule,
    CustomTitleMyAccountModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        AddClaimComponent: {
          component: CustomClaimComponent,
          guards: [AuthGuard, CustomClaimGuard],
        },
        ClaimsComponent: {
          component: CustomClaimListComponent,
        },
        ClaimComponentDetail: {
          component: CustomClaimDetailComponent,
        },
      },
    }),
  ],
  exports: [CustomClaimComponent],
})
export class CustomClaimModule {}
