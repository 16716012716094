<ng-container
  *ngIf="{
    isMobile: isMobile$ | async,
    items: items$ | async,
    isPromeclub: isPromeclub
  } as data"
>
  <ng-container *ngIf="!isPromeclub">
    <div
      *ngIf="
        !!data.items && !data.isMobile && !isPromeclub;
        else mobileSelector
      "
      class="top-bar-container"
    >
      <ng-container *ngFor="let item$ of data.items">
        <ng-container *ngIf="item$ | async as item">
          <a
            *ngIf="item?.external === 'false'"
            class="top-bar-item"
            [ngClass]="item.section == currentSection ? 'active-link' : ''"
            [routerLink]="item.url"
            (click)="changeSection(item)"
          >
            {{ item?.linkName | slice : 0 : 12 }}
            {{ item?.linkName?.length > 11 ? "..." : "" }}
          </a>
          <a
            class="top-bar-item"
            *ngIf="item?.external === 'true'"
            [ngClass]="item.section == currentSection ? 'active-link' : ''"
            [href]="item.url"
            [target]="item?.target === 'true' ? '_blank' : '_self'"
            (click)="changeSection(item)"
          >
            {{ item?.linkName | slice : 0 : 12 }}
            {{ item?.linkName?.length > 11 ? "..." : "" }}
          </a>
        </ng-container>
      </ng-container>
    </div>

    <ng-template #mobileSelector>
      <div class="mobile-selector">
        <ng-container *ngFor="let item$ of data.items">
          <ng-container *ngIf="item$ | async as item">
            <a
              *ngIf="item?.external === 'false'"
              class="mobile-selector__item"
              [ngClass]="item.section == currentSection ? 'active-link' : ''"
              [routerLink]="item.url"
              (click)="changeSection(item)"
            >
              <cx-icon
                type="WRENCH_ACTIVE"
                *ngIf="
                  item.section == 'ferreteria' && item.section == currentSection
                "
              ></cx-icon>
              <cx-icon
                type="WRENCH"
                *ngIf="
                  item.section == 'ferreteria' && item.section != currentSection
                "
              ></cx-icon>
              <cx-icon
                type="CAR_ACTIVE"
                *ngIf="
                  item.section != 'ferreteria' && item.section == currentSection
                "
              ></cx-icon>
              <cx-icon
                type="CAR"
                *ngIf="
                  item.section != 'ferreteria' && item.section != currentSection
                "
              ></cx-icon>
              {{ item?.linkName | slice : 0 : 12 }}
              {{ item?.linkName?.length > 11 ? "..." : "" }}
            </a>
            <a
              class="mobile-selector__item"
              *ngIf="item?.external === 'true'"
              [ngClass]="item.section == currentSection ? 'active-link' : ''"
              [href]="item.url"
              [target]="item?.target === 'true' ? '_blank' : '_self'"
              (click)="changeSection(item)"
            >
              <cx-icon
                type="WRENCH_ACTIVE"
                *ngIf="
                  item?.linkName == PromesaSection.FERRETERIA &&
                  item.section == currentSection
                "
              ></cx-icon>
              <cx-icon
                type="WRENCH"
                *ngIf="
                  item?.linkName == PromesaSection.FERRETERIA &&
                  item.section != currentSection
                "
              ></cx-icon>
              <cx-icon
                type="CAR_ACTIVE"
                *ngIf="
                  item?.linkName == PromesaSection.AUTOMOTRIZ &&
                  item.section == currentSection
                "
              ></cx-icon>
              <cx-icon
                type="CAR"
                *ngIf="
                  item?.linkName == PromesaSection.AUTOMOTRIZ &&
                  item.section != currentSection
                "
              ></cx-icon>
              {{ item?.linkName | slice : 0 : 12 }}
              {{ item?.linkName?.length > 11 ? "..." : "" }}
            </a>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>

  <div
    class="top-bar-container--promeclub"
    *ngIf="!data.isMobile && isPromeclub"
  >
    <ng-container *ngFor="let item$ of data.items">
      <ng-container *ngIf="item$ | async as item">
        <a
          *ngIf="item?.external === 'false'"
          [ngClass]="item.section == currentSection ? 'active-link' : ''"
          [routerLink]="item.url"
          (click)="changeSection(item)"
        >
          <span class="text-ferreteria">
            {{ item?.linkName | slice : 0 : 12 }}
            {{ item?.linkName?.length > 11 ? "..." : "" }}</span
          >
        </a>
        <a
          class="top-bar-item"
          *ngIf="item?.external === 'true'"
          [href]="item.url"
          [target]="item?.target === 'true' ? '_blank' : '_self'"
          (click)="changeSection(item)"
        >
          <span class="text-ferreteria">
            {{ item?.linkName | slice : 0 : 12 }}
            {{ item?.linkName?.length > 11 ? "..." : "" }}</span
          >
        </a>
      </ng-container>
    </ng-container>
  </div>

  <div class="custom-top-link--promeclub" *ngIf="data.isMobile && isPromeclub">
    <ng-container *ngFor="let item$ of data.items">
      <ng-container *ngIf="item$ | async as item">
        <a
          *ngIf="item?.external === 'false'"
          [routerLink]="item.url"
          (click)="changeSection(item)"
        >
          <cx-icon
            type="WRENCH"
            *ngIf="item?.linkName == PromesaSection.FERRETERIA"
          ></cx-icon>
          <cx-icon
            type="CAR"
            *ngIf="item?.linkName == PromesaSection.AUTOMOTRIZ"
          ></cx-icon>
          <span class="text-ferreteria">
            {{ item?.linkName | slice : 0 : 12 }}
            {{ item?.linkName?.length > 11 ? "..." : "" }}
          </span>
        </a>
        <a
          class="top-bar-item"
          *ngIf="item?.external === 'true'"
          [ngClass]="item.section == currentSection ? 'active-link' : ''"
          [href]="item.url"
          [target]="item?.target === 'true' ? '_blank' : '_self'"
          (click)="changeSection(item)"
        >
          <cx-icon
            type="CAR"
            *ngIf="item?.linkName == PromesaSection.AUTOMOTRIZ"
          ></cx-icon>
          <cx-icon
            type="WRENCH"
            *ngIf="item?.linkName == PromesaSection.FERRETERIA"
          ></cx-icon>
          <span class="text-ferreteria">
            {{ item?.linkName | slice : 0 : 12 }}
            {{ item?.linkName?.length > 11 ? "..." : "" }}
          </span>
        </a>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
