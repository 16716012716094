import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductAttributesAccordionComponent } from './custom-product-attributes-accordion.component';
import { IconModule } from '@spartacus/storefront';
import { I18nModule } from '@spartacus/core';
import { CustomComponentTitleModule } from 'src/app/cms-components/custom/components/custom-component-title/custom-component-title.module';



@NgModule({
  declarations: [
    CustomProductAttributesAccordionComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    I18nModule,
    CustomComponentTitleModule
  ],
  exports: [
    CustomProductAttributesAccordionComponent
  ]
})
export class CustomProductAttributesAccordionModule { }
