import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, Product } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';
import { RebateList } from '../models/custom-rebate.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomRebateService {
  rebateList$: BehaviorSubject<RebateList | null> =
    new BehaviorSubject<RebateList | null>(null);
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService
  ) {}

  getRebate(
    clientId: string,
    currentPage: number | undefined,
    pageSize: number | undefined,
    year: string | number | undefined,
    month: string | number | undefined
  ): Observable<RebateList | null> {
    const url = this.occEndpointService.buildUrl('getRebate', {
      queryParams: {
        clientId,
        currentPage,
        pageSize,
        year,
        month,
      },
    });
    return this.http.get<RebateList>(url).pipe(
      tap((rebateList) => this.rebateList$.next(rebateList)),
      shareReplay()
    );
  }

  getRebateByProductID(
    clientId: string | undefined,
    productCode: string | undefined | unknown
  ) {
    const url = this.occEndpointService.buildUrl('getRebateByProductID', {
      queryParams: {
        clientId,
        productCode,
      },
    });

    return this.http.get<any>(url);
  }

  getRebateByRappelID(
    clientId: string | undefined,
    rappelId: string | undefined | unknown
  ) {
    const url = this.occEndpointService.buildUrl('getRebateByRappelID', {
      queryParams: {
        clientId,
        rappelId,
      },
    });

    return this.http.get<any>(url);
  }

  /* la barra se divide en 3 para tener una mejor posicion del avance azul */
  valueProgressBar(arrayScale: any[], totalFacturado: string): number {
    const valueArray = arrayScale?.filter(
      (value) => value.achieved && value.value
    );
    const numFacturado = parseInt(totalFacturado);

    const lastValue = arrayScale?.length - 1;

    /*
    se calcula el porcentaje dentro del valor de la primera escala
    y el valor de la ultima escala
    */

    const calculationResult = () => {
      return (
        ((numFacturado - arrayScale[0]?.fromValue) /
          (arrayScale[lastValue]?.fromValue - arrayScale[0]?.fromValue)) *
        100
      );
    };

    return valueArray.length > 0
      ? arrayScale?.length > valueArray.length
        ? calculationResult()
        : 100
      : 0;
  }

  valueMinProgressBar(arrayScale: any[], totalFacturado: string): number {
    const valueArray = arrayScale?.filter(
      (value) => value.achieved && value.value
    );
    const numFacturado = parseInt(totalFacturado);

    const calculationUnderachieved = numFacturado / arrayScale[0]?.fromValue;

    return valueArray.length == 0
      ? numFacturado > 0
        ? calculationUnderachieved * 100
        : 0
      : 100;
  }

  valueMaxProgressBar(arrayScale: any[]): number {
    const valueArray = arrayScale?.filter(
      (value) => value.achieved && value.value
    );

    return arrayScale.length == valueArray.length && arrayScale.length > 0
      ? 100
      : 0;
  }

  getRebateDetails(clientId: string, rappelId: string): Observable<Product[]> {
    const url = this.occEndpointService.buildUrl('getRebateDetails', {
      queryParams: { clientId, rappelId },
    });
    return this.http.get<Product[]>(url);
  }
}
