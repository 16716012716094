import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomBrandListModule } from './components/custom-brand-list/custom-brand-list.module';
import { CustomCartListHeaderModule } from './components/custom-cart-list-header/custom-cart-list-header.module';
import { CustomCartListSummaryModule } from './components/custom-cart-list-summary/custom-cart-list-summary.module';
import { CustomCategoryListModule } from './components/custom-category-list/custom-category-list.module';
import { CustomCheckoutOrderSummaryModule } from './components/custom-checkout-order-summary/custom-checkout-order-summary.module';
import { CustomErrorModule } from './components/custom-error/custom-error.module';
import { CustomFooterBarNavigationModule } from './components/custom-footer-bar-navigation/custom-footer-bar-navigation.module';
import { CustomHeaderLogoModule } from './components/custom-header-logo/custom-header-logo.module';
import { CustomInformationModule } from './components/custom-information/custom-information.module';
import { CustomCreditLineModule } from './components/custom-credit-line/custom-credit-line.module';
import { CustomLoadingSpinnerModule } from './components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomNewsletterModule } from './components/custom-newsletter/custom-newsletter.module';
import { CustomPointsHeaderModule } from './components/custom-points-header/custom-points-header.module';
import { CustomSearchRefinerModule } from './components/custom-search-refiner/custom-search-refiner.module';
import { CustomSecondaryBannerModule } from './components/custom-secondary-banner/custom-secondary-banner.module';
import { CustomSimpleResponsiveBannerModule } from './components/custom-simple-responsive-banner/custom-simple-responsive-banner.module';
import { CustomSplittedBannerModule } from './components/custom-splitted-banner/custom-splitted-banner.module';
import { CustomTopLinkModule } from './components/custom-top-link/custom-top-link.module';
import { CustomUserBloquedModule } from './components/custom-user-bloqued-dialog/custom-user-bloqued-dialog.module';
import { CustomTitleMyAccountModule } from './components/custom-title-my-account/custom-title-my-account.module';
import { CustomMyCompanyModule } from './components/custom-my-company/custom-my-company.module';
import { CustomZagitaProductCarouselModule } from './components/custom-zagita-product-carousel/custom-zagita-product-carousel.module';
import { CustomRebateCarouselCartModule } from './components/custom-rebate-carousel-cart/custom-rebate-carousel-cart.module';
import { CustomStaticResponsiveBannerModule } from './components/custom-static-responsive-banner/custom-static-responsive-banner.module';
import { CustomValueListModule } from './components/custom-values-list/custom-values-list.module';
import { CustomPromeclubRegisterFormModule } from './components/custom-promeclub-register-form/custom-promeclub-register-form.module';
import { CustomPromeclubPointsModule } from './components/custom-promeclub-product-points/custom-promeclub-product-points.module';
import { CustomInnerHeaderModule } from './components/custom-inner-header/custom-inner-header.module';
import { CustomInnerHeaderContactModule } from './components/custom-inner-header-contact/custom-inner-header-contact.module';
import { CustomWishlistNavigationModule } from './components/custom-wishlist-navigation/custom-wishlist-navigation.module';
import { CustomTermsAndConditionsModalModule } from './components/custom-terms-and-conditions-modal/custom-terms-and-conditions-modal.module';

// Module for new "Cms" Components
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CustomTopLinkModule,
    CustomNewsletterModule,
    CustomCategoryListModule,
    CustomBrandListModule,
    CustomSecondaryBannerModule,
    CustomLoadingSpinnerModule,
    CustomCartListHeaderModule,
    CustomCartListSummaryModule,
    CustomSearchRefinerModule,
    CustomSimpleResponsiveBannerModule,
    CustomFooterBarNavigationModule,
    CustomCheckoutOrderSummaryModule,
    CustomInformationModule,
    CustomErrorModule,
    CustomSplittedBannerModule,
    CustomCheckoutOrderSummaryModule,
    CustomPointsHeaderModule,
    CustomHeaderLogoModule,
    CustomCreditLineModule,
    CustomUserBloquedModule,
    CustomTitleMyAccountModule,
    CustomMyCompanyModule,
    CustomRebateCarouselCartModule,
    CustomZagitaProductCarouselModule,
    CustomStaticResponsiveBannerModule,
    CustomValueListModule,
    CustomPromeclubRegisterFormModule,
    CustomPromeclubPointsModule,
    CustomInnerHeaderModule,
    CustomInnerHeaderContactModule,
    CustomWishlistNavigationModule,
    CustomTermsAndConditionsModalModule,
  ],
})
export class CustomModule {}
