<ng-container
  *ngIf="{
    showNewForm: showNewForm$ | async,
    edit: editMode$ | async,
    available: isAvailable$ | async
  } as data"
>
  <div class="address">
    <app-custom-title-my-account
      [title]="'personalData.addressTitle' | cxTranslate"
    ></app-custom-title-my-account>
    <div class="address__subtitle" *ngIf="!data.showNewForm && !data.edit">
      <h5 class="address__subtitle-value">
        {{ "personalData.addressSubtitle" | cxTranslate }}
      </h5>
      <div
        class="address__subtitle-action"
        (click)="showForm(data.available)"
        [ngClass]="{ disabled: !data.available }"
      >
        {{ "personalData.addAddress" | cxTranslate }}
      </div>
    </div>
    <h5 class="address__subtitle-value add" *ngIf="data.showNewForm">
      {{ "personalData.addNewAddress" | cxTranslate }}
    </h5>
    <h5 class="address__subtitle-value add" *ngIf="data.edit">
      {{ "personalData.editAddress" | cxTranslate }}
    </h5>
  </div>
  <ng-container *ngIf="!data.showNewForm && !data.edit">
    <ng-container *ngFor="let address of userAddress$ | async; index as i">
      <app-custom-promeclub-address-form [userAddress]="address">
      </app-custom-promeclub-address-form>
    </ng-container>
  </ng-container>
  <app-custom-promeclub-address-form *ngIf="data.showNewForm && !data.edit">
  </app-custom-promeclub-address-form>
  <ng-container *ngIf="addressToEdit$ | async as addressToEdit">
    <h5 class="address__subtitle-value edit" *ngIf="data.edit">
      {{
        "promeclubAddress." + addressToEdit.promotickAddressType | cxTranslate
      }}
    </h5>
    <app-custom-promeclub-address-form
      *ngIf="data.edit"
      [addressToEdit]="addressToEdit"
    >
    </app-custom-promeclub-address-form>
  </ng-container>
  <ng-template #spinnerLoading>
    <div class="address">
      <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
    </div>
  </ng-template>
</ng-container>
