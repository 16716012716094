import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { PaginationModel } from '@spartacus/core';
import { PaginationItem, PaginationItemType } from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CustomOrderPaginationService } from 'src/app/cms-components/my-account/services/custom-order-pagination.service';
import { CustomPaginationBuilder } from './custom-pagination.builder';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomPaginationComponent implements OnInit, OnDestroy {

  @Input() pageRoute: string;

  @Input() queryParam: string;

  @Input() defaultPage: any;

  @Input() isEditable: boolean = false;

  paginationForm: FormGroup;
  private _pagination: PaginationModel;
  get pagination(): PaginationModel {
    return this._pagination;
  }
  @Input() set pagination(value: PaginationModel) {
    this._pagination = value;
    this.render(value);
  }

  @Output() viewPageEvent: EventEmitter<number> = new EventEmitter<number>();
  buttons: PaginationItem[] = [];
  pages: PaginationItem[] = [];
  private unsubscribe$ = new Subject<void>();

  constructor(
    private paginationBuilder: CustomPaginationBuilder,
    private activatedRoute: ActivatedRoute,
    private customOrderPaginationService: CustomOrderPaginationService,
    private cd: ChangeDetectorRef,
  ) {
    this.paginationForm = new FormGroup({
      page: new FormControl(1, [Validators.required]),
    });
  }

  ngOnInit() {
    this.customOrderPaginationService.pageChange$.pipe(
      takeUntil(this.unsubscribe$),
      tap(pagination => {
        this.paginationForm.controls['page']!.setValue(this.pagination.currentPage!+1);
        this.render(pagination!);
      })
      ).subscribe()
      this.cd.detectChanges();
  }

  ngOnChanges() {
    this.paginationForm.controls['page']!.setValue(this.pagination.currentPage!+1)
  }
  protected render(pagination: PaginationModel): void {
    if (!pagination) {
      return;
    }
    this.pages = this.paginationBuilder.paginate(
      pagination.totalPages!,
      pagination.currentPage!
    );
    this.buttons = this.paginationBuilder.buttons(
      pagination.totalPages!,
      pagination.currentPage!
    );

  }

  onChangeOption(event: any) {
    if (event !== '' && event <= this.pagination.totalPages! && event > 0) {
      this.paginationForm.controls['page']!.setValue(this.pagination.currentPage!+1)
      this.viewPageEvent.emit(event-1);
    } else if (event !== '' && ((event > this.pagination.totalPages!) || (event<1))) {
      this.paginationForm.controls['page']!.setValue(this.pagination.currentPage!+1)
      this.viewPageEvent.emit(this.pagination.currentPage);
    }
  }

  getAriaLabel(label: string, type: PaginationItemType): string {
    return type === PaginationItemType.PAGE
      ? `${type} ${label}`
      : `${type} ${PaginationItemType.PAGE}`;
  }


  isCurrent(item: PaginationItem): boolean {
    return (
      item.type === PaginationItemType.PAGE &&
      item.number === this.pagination.currentPage
    );
  }


  isInactive(item: PaginationItem): boolean {
    return (
      !item.hasOwnProperty('number') ||
      item.number === this.pagination.currentPage
    );
  }

  getQueryParams(item: PaginationItem): Params {
    const queryParams = Object.assign(
      {},
      this.activatedRoute.snapshot.queryParams
    );
    if (
      this.queryParam &&
      item.number! < this.pagination.totalPages! &&
      !this.isCurrent(item)
    ) {
      queryParams[this.queryParam] = item.number;
    }

    if (queryParams[this.queryParam] === this.defaultPage) {
      delete queryParams[this.queryParam];
    }
    return queryParams;
  }

  pageChange(page: PaginationItem): void {
    this.viewPageEvent.emit(page.number);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribe$.unsubscribe();
  }
}
