import { Injectable } from '@angular/core';
import { PaginationConfig, PaginationItem, PaginationItemType, PaginationNavigationPosition, PaginationOptions } from '@spartacus/storefront';

const FALLBACK_PAGINATION_OPTIONS: PaginationOptions = {
  rangeCount: 1,
  previousLabel: '‹',
  nextLabel: '›',
};

@Injectable({
  providedIn: 'root',
})
export class CustomPaginationBuilder {
  constructor(protected paginationConfig: PaginationConfig) { }

  paginate(pageCount: number, current: number): PaginationItem[] {
    const pages: PaginationItem[] = [];
    if (!pageCount || pageCount < 2) {
      return pages;
    }
    this.addPages(pages, pageCount, current);
    return pages;
  }

  buttons(pageCount: number, current: number): PaginationItem[] {
    const buttons: PaginationItem[] = [];
    this.addNavigation(buttons, pageCount, current);
    return buttons;
  }

  protected addPages(
    pages: PaginationItem[],
    pageCount: number,
    current: number
  ): void {
    const start = this.getStartOfRange(pageCount, current);
    Array.from(Array(1)).forEach((_, i) => {
      pages.push({
        number: i + start,
        label: String(i + start + 1),
        type: PaginationItemType.PAGE,
      });
    });
  }



  protected getBeforeLinks(current: number): PaginationItem[] {
    const list = [];
    if (this.config.addPrevious) {
      const previous = () => {
        return Object.assign(
          {
            label: this.config.previousLabel,
            type: PaginationItemType.PREVIOUS,
          },
          current > 0 ? { number: current - 1 } : null
        );
      };
      list.push(previous());
    }
    return list;
  }

  protected getAfterLinks(
    pageCount: number,
    current: number
  ): PaginationItem[] {
    const list = [];

    if (this.config.addNext) {
      const next = () => {
        return Object.assign(
          {
            label: this.config.nextLabel,
            type: PaginationItemType.NEXT,
          },
          current < pageCount - 1 ? { number: current + 1 } : null
        );
      };
      list.push(next());
    }

    return list;
  }

  protected addFirstLast(pages: PaginationItem[], pageCount: number) {
    if (
      this.config.addLast &&
      pages[pages.length - 1].number !== pageCount - 1
    ) {
      pages.push({
        number: pageCount - 1,
        label: String(pageCount),
        type: PaginationItemType.LAST,
      });
    }
  }


  protected addNavigation(
    pages: PaginationItem[],
    pageCount: number,
    current: number
  ): void {
    const before = this.getBeforeLinks(current);
    const after = this.getAfterLinks(pageCount, current);
    const pos = this.config.navigationPosition;
    if (!pos || pos === PaginationNavigationPosition.ASIDE) {
      pages.unshift(...before);
      pages.push(...after);
    } else {
      if (pos === PaginationNavigationPosition.BEFORE) {
        pages.unshift(...before, ...after);
      }
      if (pos === PaginationNavigationPosition.AFTER) {
        pages.push(...before, ...after);
      }
    }
  }

  protected getStartOfRange(pageCount: number, current: number): number {
    const count = this.config.rangeCount! - 1;
    const delta = Math.round(count / 2);
    const minStart = Math.max(0, current - delta);
    const maxStart = Math.max(0, pageCount - count - 1);
    return Math.min(maxStart, minStart);
  }


  protected get config(): PaginationOptions {
    return Object.assign(
      FALLBACK_PAGINATION_OPTIONS,
      this.paginationConfig.pagination
    );
  }
}
