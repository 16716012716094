<ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
  <ng-container *ngIf="state$ | async as state">
    <ng-container *ngIf="facet.name !== 'Puntos'">
      <div *ngIf="!isPromeclub">
        <div class="custom-facets__labels">
          <label *ngIf="!data.isMobile; else mobileLabel">
            {{ "searchBoxFacets.filterBy" | cxTranslate }}
            {{ facet?.name | lowercase }}
          </label>
          <ng-template #mobileLabel>
            <label> {{ facet?.name | titlecase }} ({{ groupCount }}) </label>
            <cx-icon
              [class]="(showFacet$ | async) ? 'fas fa-minus' : 'fas fa-plus'"
              (click)="toggleFacet()"
            ></cx-icon>
          </ng-template>
        </div>
      </div>
      <div *ngIf="isPromeclub">
        <div
          class="custom-facets__labels"
          *ngIf="!data.isMobile; else mobilePromeclubLabel"
        >
          <label
            >{{ "searchBoxFacets.filterBy" | cxTranslate }}
            {{ facet.name | lowercase }}</label
          >
        </div>
        <ng-template #mobilePromeclubLabel>
          <div class="custom-facets__labels">
            <label> {{ facet?.name | titlecase }} ({{ groupCount }}) </label>
            <cx-icon
              [class]="(showFacet$ | async) ? 'fas fa-minus' : 'fas fa-plus'"
              (click)="toggleFacet()"
            ></cx-icon>
          </div>
        </ng-template>
      </div>

      <ng-container
        *ngIf="facet?.name === 'Categorías' || facet?.name === 'Marcas'"
      >
        <app-custom-search-facets
          [placeholder]="'searchBoxFacets.placeholderFacets' | cxTranslate"
          [ariaLabel]="'searchBoxFacets.placeholderFacets' | cxTranslate"
          [ariaLabelBtn]="'searchBoxFacets.placeholderFacets' | cxTranslate"
          (debounceEmitt)="searchFacet($event)"
        ></app-custom-search-facets>
      </ng-container>

      <div
        *ngIf="currentFacetArray$ | async as currentFacetArray"
        [ngClass]="{
          'custom-facets__scroll-container':
            facet?.name === 'Categorías' ||
            facet?.name === 'Marcas' ||
            facet?.name === 'Categoría' ||
            facet?.name === 'Marca',
          'custom-facets__scroll-active':
            currentFacetArray?.values?.length > 15 && scrollActive
        }"
      >
        <a
          *ngFor="
            let value of currentFacetArray?.values
              | slice : 0 : state.topVisible
          "
          #facetValue
          routerLink="./"
          [queryParams]="getLinkParams(value)"
          class="value custom-facets__items"
          [class.selected]="value.selected"
          [cxFocus]="value.name"
          (keydown.space)="openLink($event)"
          [ngClass]="{ hide: !(showFacet$ | async) }"
        >
          <cx-icon type="CHECK_MENU" *ngIf="value.selected"></cx-icon>
          <span>
            <span class="label">{{ value.name | titlecase }}</span>
            <span class="count">{{ value.count }}</span>
          </span>
        </a>

        <div
          class="more"
          [ngClass]="{ hide: !(showFacet$ | async) }"
          *ngIf="currentFacetArray$ | async as currentFacetArray"
        >
          <a
            *ngFor="
              let value of currentFacetArray?.values
                | slice : state.topVisible : state.maxVisible
            "
            #facetValue
            routerLink="./"
            [queryParams]="getLinkParams(value)"
            class="value custom-facets__items"
            [class.selected]="value.selected"
            [cxFocus]="value.name"
            (keydown.space)="openLink($event)"
          >
            <cx-icon type="CHECK_MENU" *ngIf="value.selected"></cx-icon>
            <span>
              <span class="label">{{ value.name | titlecase }}</span>
              <span class="count">{{ value.count }}</span>
            </span>
          </a>
        </div>
        <span *ngIf="withoutResults" class="without-results"
          >{{ "searchPageBreadcrumb.zeroResults" | cxTranslate }}
        </span>
      </div>

      <ng-container *ngIf="currentFacetArray$ | async as currentFacetArray">
        <button
          *ngIf="
            state.maxVisible > state.topVisible &&
            !withoutResults &&
            state.topVisible < currentFacetArray?.values.length
          "
          (click)="decreaseVisibleValues()"
          class="cx-action-link"
          cxFocus="moreorless"
        >
          <cx-icon class="fas fa-minus"></cx-icon>
          {{ "productList.showLess" | cxTranslate }}
        </button>

        <button
          *ngIf="
            state.maxVisible > 0 &&
            state.maxVisible < currentFacetArray?.values.length &&
            !withoutResults
          "
          (click)="increaseVisibleValues()"
          class="cx-action-link"
          cxFocus="moreorless"
        >
          <cx-icon class="fas fa-plus"></cx-icon>
          {{
            (data.isMobile ? "productList.showAll" : "productList.showMore")
              | cxTranslate
          }}
        </button>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
