import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomForgotPasswordComponent } from './custom-forgot-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  UrlModule,
  I18nModule,
  CmsConfig,
  ConfigModule,
  AuthConfigService,
  GlobalMessageService,
  NotAuthGuard,
  RoutingService,
} from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { CustomForgotPasswordService } from './custom-forgot-password.service';
import { CustomSafeHtmlModule } from 'src/app/pipes/custom-safe-html/custom-safe-html.module';
import { CustomLoadingSpinnerModule } from '../../custom/components/custom-loading-spinner/custom-loading-spinner.module';

@NgModule({
  declarations: [CustomForgotPasswordComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    IconModule,
    CustomSafeHtmlModule,
    CustomLoadingSpinnerModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ForgotPasswordComponent: {
          component: CustomForgotPasswordComponent,
          guards: [NotAuthGuard],
          providers: [
            {
              provide: CustomForgotPasswordService,
              useClass: CustomForgotPasswordService,
              deps: [
                UserPasswordFacade,
                RoutingService,
                AuthConfigService,
                GlobalMessageService,
              ],
            },
          ],
        },
      },
    }),
  ],
})
export class CustomForgotPasswordModule {}
