import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GlobalMessageService, RoutingService } from '@spartacus/core';
import { ResetPasswordComponentService } from '@spartacus/user/profile/components';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomResetPasswordService extends ResetPasswordComponentService {
  updatedPassword: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    protected userPasswordService: UserPasswordFacade,
    protected routingService: RoutingService,
    protected globalMessage: GlobalMessageService
  ) {
    super(userPasswordService, routingService, globalMessage);
  }

  customResetPassword(token: string) {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.busy$.next(true);

    const password = (this.form.get('password') as FormControl).value;

    return this.userPasswordService.reset(token, password).pipe(
      map((data) => {
        this.updatedPassword.next(true);
        this.busy$.next(false);
        return this.updatedPassword.asObservable();
      }),
      catchError((error) => {
        this.updatedPassword.next(false);
        this.busy$.next(false);
        return this.updatedPassword.asObservable();
      })
    );
  }
}
