<div class="wishlist-navigation" *ngIf="isPromeclub">
  <a
    routerLink="/my-account/wishlist"
    class="wishlist-navigation__item"
    (click)="closeMenu()"
    ><cx-icon type="EMPTY_HEART"></cx-icon>
    <span>{{ "customWishlist.breadTitle" | cxTranslate }}</span>
    <cx-icon class="fas fa-chevron-right"></cx-icon
  ></a>
</div>
