<ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
  <ng-container *ngIf="cartEntry">
    <div #dialog>
      <ng-container>
        <app-custom-modal-generic
          *ngIf="!data.isMobile"
        ></app-custom-modal-generic>
        <div
          *ngIf="cartEntry && !fromWish && !data.isMobile"
          class="cx-dialog-title modal-title"
          aria-live="polite"
          aria-atomic="true"
          [innerHTML]="
            !data.isMobile
              ? ('addToCart.removeItem' | cxTranslate | safeHtml)
              : ('addToCart.removeItemMobile' | cxTranslate | safeHtml)
          "
        ></div>
        <div class="dialog-header" *ngIf="data.isMobile">
          <h4 class="dialog-header__title">
            {{
              (fromWish
                ? "customWishlist.confirmRemoveTitleMobile"
                : "addToCart.removeItemMobile"
              ) | cxTranslate
            }}
          </h4>
          <cx-icon
            type="CLOSE"
            cxModal="dismiss"
            cxModalReason="Cross click"
          ></cx-icon>
        </div>
        <div
          *ngIf="cartEntry && fromWish && !data.isMobile"
          class="cx-dialog-title modal-title"
          aria-live="polite"
          aria-atomic="true"
          [innerHTML]="
            !data.isMobile
              ? ('customWishlist.confirmRemoveTitle' | cxTranslate | safeHtml)
              : ('customWishlist.confirmRemoveTitleMobile'
                | cxTranslate
                | safeHtml)
          "
        ></div>
        <!-- Modal Body -->
        <div class="cx-dialog-body modal-body" *ngIf="cartEntry">
          <div class="cx-dialog-row">
            <app-custom-added-to-cart-entry
              [entry]="cartEntry"
              [quantity]="quantity"
              [fromWish]="fromWish"
            >
            </app-custom-added-to-cart-entry>

            <!-- Total container -->
            <div class="cx-dialog-actions" *ngIf="cartEntry">
              <!-- Actions -->
              <div class="cx-dialog-buttons">
                <a
                  (click)="customRemoveItemFromModal()"
                  [cxModal]="!fromWish ? 'dismiss' : null"
                  [cxModalReason]="!fromWish ? 'View Cart click' : null"
                  class="btn btn-primary"
                  autofocus
                >
                  <cx-icon class="fas fa-trash-alt"></cx-icon>
                  <ng-container *ngIf="!isInPromeclub">
                    {{
                      data.isMobile
                        ? ("addToCart.confirmRemoveItemMobile" | cxTranslate)
                        : ("addToCart.confirmRemoveItem" | cxTranslate)
                    }}
                  </ng-container>
                  <ng-container *ngIf="isInPromeclub">
                    <ng-container *ngIf="!fromWish">
                      {{
                        data.isMobile
                          ? ("addToCart.confirmRemoveItemPromeclubMobile"
                            | cxTranslate)
                          : ("addToCart.confirmRemoveItemPromeclub"
                            | cxTranslate)
                      }}
                    </ng-container>
                    <ng-container *ngIf="fromWish">
                      {{
                        data.isMobile
                          ? ("addToCart.confirmRemoveItemPromeclubMobile"
                            | cxTranslate)
                          : ("customWishlist.confirmRemove" | cxTranslate)
                      }}
                    </ng-container>
                  </ng-container>
                </a>
                <a
                  cxModal="dismiss"
                  cxModalReason="Cross click"
                  class="btn btn-secondary"
                >
                  {{ "addToCart.cancelRemove" | cxTranslate }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
