<ng-container
  *ngIf="{
    deleted: deleted$ | async
  } as data"
>
  <app-custom-modal-generic
    (closeModal)="closeModal('close')"
    [isClose]="true"
  ></app-custom-modal-generic>

  <div
    class="address-alert"
    *ngIf="addAddress || data.deleted || editAddress; else removeAddress"
  >
    <div
      *ngIf="addAddress"
      class="address-alert__text"
      [innerHTML]="
        'promeclubAddress.addSuccesful'
          | cxTranslate : { type: type | cxTranslate }
          | safeHtml
      "
    ></div>
    <div
      *ngIf="data.deleted"
      class="address-alert__text"
      [innerHTML]="
        'promeclubAddress.deleteSuccesful'
          | cxTranslate : { type: type | cxTranslate }
          | safeHtml
      "
    ></div>
    <div
      *ngIf="editAddress"
      class="address-alert__text"
      [innerHTML]="
        'promeclubAddress.editSuccesful'
          | cxTranslate : { type: type | cxTranslate }
          | safeHtml
      "
    ></div>
    <div class="address-alert__action">
      <button
        class="address-alert__action-button"
        (click)="closeModal('close')"
      >
        {{ "promeclubAddress.close" | cxTranslate }}
      </button>
    </div>
  </div>
  <ng-template #removeAddress>
    <div class="address-alert remove" [ngClass]="{ hide: !showDeleteMessage }">
      <ng-container *ngIf="showDeleteMessage">
        <div class="address-alert__icon">
          <cx-icon class="fas fa-exclamation-triangle"></cx-icon>
        </div>
        <div
          class="address-alert__text"
          [innerHTML]="
            'promeclubAddress.deleteAddress'
              | cxTranslate : { type: type | cxTranslate }
              | safeHtml
          "
        ></div>
        <div class="address-alert__action remove">
          <div
            class="address-alert__action-link remove"
            (click)="openTwilioModal()"
          >
            {{ "promeclubAddress.delete" | cxTranslate }}
          </div>
          <button
            class="address-alert__action-button remove"
            (click)="closeModal('close')"
          >
            {{ "promeclubAddress.cancel" | cxTranslate }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="!showDeleteMessage">
        <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
      </ng-container>
    </div>
  </ng-template>
</ng-container>
