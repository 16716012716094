import { Injectable } from '@angular/core';

import {
  CustomGTMNewAccountEvent,
  CustomGTMEvent,
  EventType,
  UserAnonymous,
} from '../../cms-components/product/model/custom-gtmProduct.model';
import { CustomGtmDatalayerService } from './custom-gtm-datalayer.service';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomUser } from 'src/app/feature-libs/checkout/models/custom-user';

@Injectable({
  providedIn: 'root',
})
export class CustomGtmLoginService {
  constructor(
    private dataLayer: CustomGtmDatalayerService,
    private userAccountFacade: UserAccountFacade
  ) {}

  signInSuccessfullyEvent(status: string, userId: string) {
    const userIdBase64 = btoa(userId);
    let signInSuccessfullyEvent: CustomGTMEvent;
    signInSuccessfullyEvent = {
      event: EventType.trackLogin,
      status: status,
      UserId: userIdBase64,
    };

    this.dataLayer.pushEvent(signInSuccessfullyEvent);
  }

  createNewAccountEvent(
    Tipo_Persona: string,
    Tipo_Negocio: string,
    Provincia: string,
    Newsletter: string,
    Id_Empresa: string
  ): void {
    const userIdBase64 = btoa(Id_Empresa);
    let createAccountEvent: CustomGTMNewAccountEvent;
    createAccountEvent = {
      event: EventType.newClient,
      Tipo_Persona,
      Tipo_Negocio,
      Provincia,
      Newsletter,
      Id_Empresa: userIdBase64,
    };

    this.dataLayer.pushEvent(createAccountEvent);
  }

  createOldAccountEvent(
    event: string,
    Id_Empresa?: string,
    UserID?: string,
    Cuenta_Creada?: boolean
  ): void {
    const userIdBase64 = Id_Empresa && btoa(Id_Empresa);
    const mailBase65 = UserID && btoa(UserID);
    let createAccountEvent: CustomGTMNewAccountEvent;
    createAccountEvent = {
      event,
      Id_Empresa: userIdBase64,
      UserID: mailBase65,
      Cuenta_Creada,
    };

    this.dataLayer.pushEvent(createAccountEvent);
  }

  signInEvent(myAccount: string): void {
    let signInEvent: CustomGTMEvent;
    signInEvent = {
      event: EventType.login,
      myAccount,
    };

    this.dataLayer.pushEvent(signInEvent);
  }

  registerToNewsletter(suscriptionSuccessfully: boolean) {
    let signInEvent: CustomGTMEvent;
    signInEvent = {
      event: EventType.suscripcion_newsletter,
      suscriptionNewsletter: suscriptionSuccessfully,
    };

    this.dataLayer.pushEvent(signInEvent);
  }

  userTrackingData(): void {
    this.userAccountFacade
      .get()
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe((data: CustomUser | undefined) => {
        if (!data) {
          this.userTrackingEvent(UserAnonymous.ANONYMOUS);
        } else {
          this.userTrackingEvent(data.email!);
        }
      });
  }
  userTrackingEvent(user: string): void {
    const userEmailHash = btoa(user);

    let userTracking: CustomGTMEvent;

    userTracking = {
      event: EventType.userTracking,
      UserId: userEmailHash,
    };

    this.dataLayer.pushEvent(userTracking);
  }
}
