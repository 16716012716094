import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import {
  IconModule,
  ItemCounterModule, MediaModule
} from '@spartacus/storefront';
import { CustomCurrencyModule } from '../../../../pipes/custom-currency/custom-currency.module';
import { CustomAddToCartModule } from '../../../cart/custom-add-to-card/custom-add-to-cart.module';
import { CustomGiftProductModule } from '../custom-gift-product/custom-gift-product.module';
import { CustomWishlistModule } from '../custom-wishlist/custom-wishlist.module';
import { CustomProductCardComponent } from './custom-product-card.component';

@NgModule({
  declarations: [CustomProductCardComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MediaModule,
    RouterModule,
    UrlModule,
    I18nModule,
    IconModule,
    ItemCounterModule,
    CustomAddToCartModule,
    CustomCurrencyModule,
    CustomGiftProductModule,
    CustomWishlistModule
  ],
  exports: [CustomProductCardComponent],
})
export class CustomProductCardModule {}
