import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CategoriesBreadcrumb } from '../cms-components/navigation/model/categoriesBreadcrumb';
import { shareReplay, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class CustomHeaderCategoriesService  {
  categoryData$ = new BehaviorSubject<CategoriesBreadcrumb | undefined>(undefined);
  categoryCode$ = new BehaviorSubject<string | undefined>(undefined);
  constructor(
    private http: HttpClient,
    private occEndpointService: OccEndpointsService,
    private router: Router
  ) {}

  getCategoryDataFromHeader(): Observable<CategoriesBreadcrumb>{
    const arrRouter = this.router.url.split('/');
    let categoryCode = arrRouter[arrRouter.length - 1];
    if(categoryCode.includes('?')) {
      categoryCode = categoryCode.split('?')[0];
    }
    this.categoryCode$.next(categoryCode);
    const url = this.occEndpointService.buildUrl('categoriesBreadcrumb', {
      urlParams: {
        categoryCode
      }
    });
    if(!window?.location?.href?.includes('/c/')){
      return of({code: '', image: {url: ''}, name: '', superCategories: [], url: ''});
    }
    return this.http.get<CategoriesBreadcrumb>(url).pipe(
      tap(
        category => this.categoryData$.next(category)
      ),
      shareReplay(1)
    );
  }

  getCategoryData(): Observable<CategoriesBreadcrumb | undefined>{
    return this.categoryData$
  }

  setCategoryData(category: CategoriesBreadcrumb): void{
    this.categoryData$.next(category);
  }

  getCategoryCode(): Observable<string | undefined>{
    return this.categoryCode$;
  }

}
