import { Component, ViewEncapsulation } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { ICON_TYPE, ModalService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from '../../../../../../services/custom-breakpoint.service';
import { CustomQuoteOrchestratorService } from '../../services/custom-quote-orchestrator.service';

@Component({
  selector: 'app-custom-quote-info-dialog',
  templateUrl: './custom-quote-info-dialog.component.html',
  styleUrls: ['./custom-quote-info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomQuoteInfoDialogComponent {
  iconTypes = ICON_TYPE;
  code: string;
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    private modalService: ModalService,
    private customBreakpointService: CustomBreakpointService,
    private customQuoteOrchestrator: CustomQuoteOrchestratorService,
    private routingService: RoutingService
  ) {}

  closeModal(reason: string) {
    this.modalService.closeActiveModal(reason);
  }

  ngOnDestroy(): void {
    this.customQuoteOrchestrator.resetGoToSummary();
    this.customQuoteOrchestrator.setInQuoteDetail(true);
    this.routingService.go({ cxRoute: 'quotes' });
  }
}
