import { Component, OnInit } from '@angular/core';
import { ProductViewComponent, ViewModes } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomNavigationService } from 'src/app/cms-components/navigation/services/custom-navigation.service';
import { CustomBreakpointService } from 'src/app/services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-view-selectors',
  templateUrl: './custom-view-selectors.component.html',
  styleUrls: ['./custom-view-selectors.component.scss'],
})
export class CustomViewSelectorsComponent
  extends ProductViewComponent
  implements OnInit
{
  selectedMode: ViewModes;
  viewModesChange = ViewModes;
  isInPromeClub: boolean = this.service.isInPromeClub();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;

  constructor(
    private service: CustomNavigationService,
    private customBreakpointService: CustomBreakpointService
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedMode = this.mode;
  }
  get buttonClass() {
    const viewName: string = this.customViewMode.toLowerCase();
    return `cx-product-${viewName}`;
  }

  get customViewMode() {
    if (this.mode === 'list') {
      return this.iconTypes.LIST;
    } else if (this.mode === 'grid') {
      return this.iconTypes.GRID;
    } else {
      return this.iconTypes.LIST;
    }
  }

  customChangeMode(modeChange: string) {
    this.selectedMode =
      modeChange === ViewModes.Grid ? ViewModes.Grid : ViewModes.List;
    this.modeChange.emit(this.selectedMode);
  }
}
