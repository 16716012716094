<!-- TABLE -->
<ng-container *ngIf="claims$ | async as claims">
  <ng-container *ngIf="claims.results.length > 0; else noClaims">
    <div class="claims-list-container">
      <app-custom-title-my-account
        [title]="'orderDetails.claim.claims' | cxTranslate"
        [lessMargin]="true"
      ></app-custom-title-my-account>
      <div class="cx-claim-history-sort">
        <div
          *ngIf="claims.pagination.totalPages >= 1"
          class="cx-claim-history-pagination"
        >
          <app-custom-filter
            *ngIf="claims.pagination.totalPages >= 1"
            [label]="'orderHistory.filterResults' | cxTranslate"
            [entries]="quantities"
            [classSelect]="'small-filter'"
            [valueDefault]="pageSize"
            (filterSelected)="filterQuantitySelected($event)"
          >
          </app-custom-filter>
          <app-custom-pagination
            *ngIf="claims.pagination.totalPages >= 1"
            [pagination]="claims.pagination"
            (viewPageEvent)="customPageChangeSelected($event)"
            [pageSelected]="selectedPage"
          ></app-custom-pagination>
        </div>
      </div>
      <app-custom-account-table
        [orders]="claims"
        [currentPage]="'claimsPage'"
        (goToDetails)="goToOrderDetail($event)"
      ></app-custom-account-table>

      <div class="cx-claim-history-sort">
        <div
          *ngIf="claims.pagination.totalPages >= 1"
          class="cx-claim-history-pagination cx-claim-history-pagination__end"
        >
          <app-custom-pagination
            [pagination]="claims.pagination"
            (viewPageEvent)="customPageChangeSelected($event)"
            [pageSelected]="selectedPage"
          ></app-custom-pagination>
        </div>
      </div></div
  ></ng-container>
  <ng-template #noClaims>
    <div class="cx-claim-history-no-claim">
      <div>
        <app-custom-title-my-account
          [title]="'orderDetails.claim.claims' | cxTranslate"
        ></app-custom-title-my-account>
      </div>
      <app-custom-empty-cart [fromMyAccount]="true"></app-custom-empty-cart>
    </div>
  </ng-template>
</ng-container>
