import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductVariantsContainerComponent } from './custom-product-variants-container.component';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { CustomProductSocialShareModule } from '../custom-product-social-share/custom-product-social-share.module';
import { CustomWishlistModule } from 'src/app/cms-components/custom/components/custom-wishlist/custom-wishlist.module';
@NgModule({
  declarations: [CustomProductVariantsContainerComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    CustomProductSocialShareModule,
    CustomWishlistModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductVariantSelectorComponent: {
          component: CustomProductVariantsContainerComponent,
          guards: [],
        },
      },
    } as CmsConfig),
  ],
  exports: [CustomProductVariantsContainerComponent],
})
export class CustomProductVariantsContainerModule {}
