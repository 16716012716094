<ng-container
  *ngIf="{
    seeMore: seeMoreLink$ | async,
    isMobile: isMobile$ | async,
    items: items$ | async,
    title: title$ | async
  } as data"
>
  <div class="brand-list">
    <div class="brand-list__title">
      <app-custom-component-title
        [title]="data.title | titlecase"
        [hasFirsWordInBold]="true"
        *ngIf="!!data.title"
      >
      </app-custom-component-title>

      <a
        *ngIf="!!data.seeMore && !data.isMobile"
        class="brand-link"
        [routerLink]="data?.seeMore?.url"
      >
        {{ data?.seeMore?.linkName }}
        <cx-icon class="fas fa-chevron-right"></cx-icon>
      </a>
    </div>
    <ng-container *ngIf="!!data.items">
      <div class="brand-items-container">
        <ng-container *ngFor="let item$ of data.items.slice(0, 6)">
          <div class="brand-item" *ngIf="item$ | async as brand">
            <cx-generic-link [url]="brand?.url">
              <div class="brand-item__img" *ngIf="!!brand.image">
                <img
                  [src]="getImageUrl(brand?.image?.url)"
                  [alt]="brand?.name"
                  loading="lazy"
                />
              </div>
            </cx-generic-link>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <a
      *ngIf="!!data.seeMore && data.isMobile"
      class="brand-link"
      [routerLink]="data?.seeMore?.url"
    >
      {{ data?.seeMore?.linkName }}
      <cx-icon class="fas fa-chevron-right"></cx-icon>
    </a>
  </div>
</ng-container>
