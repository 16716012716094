import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  ConfigModule, FeaturesConfigModule,
  I18nModule, UrlModule
} from '@spartacus/core';
import {
  CartCouponModule, CartValidationWarningsModule, PromotionsModule
} from '@spartacus/storefront';
import { CustomCartSharedModule } from '../custom-cart-shared/custom-cart-shared.module';
import { CustomCartFromQuoteGuard } from '../guards/custom-cart-from-quote.guard';
import { CustomCartDetailsComponent } from './custom-cart-details.component';

@NgModule({
  declarations: [CustomCartDetailsComponent],
  imports: [
    CustomCartSharedModule,
    CommonModule,
    CartCouponModule,
    RouterModule,
    UrlModule,
    PromotionsModule,
    FeaturesConfigModule,
    I18nModule,
    CartValidationWarningsModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CartComponent: {
          component: CustomCartDetailsComponent,
          guards: [CustomCartFromQuoteGuard],
        },
      },
    }),
  ],
  exports: [CustomCartDetailsComponent],
})
export class CustomCartDetailsModule {}
