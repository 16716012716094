import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CartActions, CartConnector } from '@spartacus/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { MultiCartService } from '@spartacus/core';
import { map } from 'rxjs/operators';
import { CustomBaseSiteCheckerService } from 'src/app/services/custom-base-site-checker.service';

@Injectable()
export class CustomCartEffects {
  @Effect({ dispatch: false })
  customDeleteCart$: Observable<any> = this.actions$.pipe(
    ofType(CartActions.DELETE_CART_SUCCESS),
    map((action: CartActions.DeleteCartSuccess) => {
      const isPromeclub: boolean = this.customBaseSiteChecker.isInPromeClub();
      if (!isPromeclub) {
        this.cartConnector
          .loadAll(action?.payload?.userId)
          .pipe(take(1))
          .subscribe((carts) => {
            if (carts?.length > 0) {
              this.multiCartService.loadCart({
                cartId: carts?.[0]?.code!,
                userId: action?.payload?.userId,
                extraData: { active: true },
              });
            }
          });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private multiCartService: MultiCartService,
    private cartConnector: CartConnector,
    private customBaseSiteChecker: CustomBaseSiteCheckerService
  ) {}
}
