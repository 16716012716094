import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';
import {
  CustomEntry,
  ProductVariantList,
} from '../../model/custom-product-variant-list.interface';

@Component({
  selector: 'app-custom-product-variant-list',
  templateUrl: './custom-product-variant-list.component.html',
  styleUrls: ['./custom-product-variant-list.component.scss'],
})
export class CustomProductVariantListComponent implements OnInit {
  @Input() plpVariantDataList: ProductVariantList[];
  plpClass: CustomEntry[];
  isLoggedIn$: Observable<boolean> = this.auth.isUserLoggedIn();
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  constructor(
    private auth: AuthService,
    private customBreakpointService: CustomBreakpointService
  ) {}

  ngOnInit(): void {
    this.plpClass =
      this.plpVariantDataList?.[0]?.products?.[0]?.plpClassCatData?.entry;
  }
}
