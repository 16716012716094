import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSearchFacetsComponent } from './custom-search-facets.component';
import { IconModule } from '@spartacus/storefront';
import { I18nModule } from '@spartacus/core';

@NgModule({
  declarations: [CustomSearchFacetsComponent],
  exports: [CustomSearchFacetsComponent],
  imports: [CommonModule, IconModule, I18nModule],
})
export class CustomSearchFacetsModule {}
