import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActiveCartService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { CustomOrderEntry } from 'src/app/cms-components/cart/model/custom-order-entry';
import { CustomRebateService } from 'src/app/cms-components/my-account/services/custom-rebate.service';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';

@Component({
  selector: 'app-custom-rebate-carousel-cart',
  templateUrl: './custom-rebate-carousel-cart.component.html',
  styleUrls: ['./custom-rebate-carousel-cart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomRebateCarouselCartComponent implements OnInit, OnDestroy {
  public rebate$: Observable<any> = this.rebateCartGet();
  private unsubscribe$ = new Subject<void>();
  public entries$: Observable<any> = this.getEntriesCartActive();
  user: string;
  userId$: Observable<string> = this.userAccountFacade.get().pipe(
    takeUntil(this.unsubscribe$),
    map((user) => user?.uid!)
  );
  isMobile$: Observable<boolean>;
  constructor(
    private customRebateService: CustomRebateService,
    private activeCartService: ActiveCartService,
    protected userAccountFacade: UserAccountFacade,
    protected customBreakpointService: CustomBreakpointService
  ) {}

  ngOnInit(): void {
    this.userId$.subscribe((userId) => (this.user = userId));
    this.isMobile$ = this.customBreakpointService.isMobile$;
  }

  getEntriesCartActive(): Observable<CustomOrderEntry[]> {
    return this.activeCartService.getEntries();
  }

  rebateCartGet(): Observable<any> {
    return this.getEntriesCartActive().pipe(
      map((data: CustomOrderEntry[]) =>
        data.find((data) => data.hasRappel == true)
      ),
      switchMap((data) => {
        if (data) {
          return this.customRebateService.getRebateByRappelID(
            this.user,
            data?.rappelId
          );
        } else {
          return of([]);
        }
      })
    );
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
