import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, DeferLoadingStrategy } from '@spartacus/core';
import { GenericLinkModule, MediaModule } from '@spartacus/storefront';
import { CustomSafeHtmlModule } from '../../../../pipes/custom-safe-html/custom-safe-html.module';
import { CustomLoadingSpinnerModule } from '../../../custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomProductCardModule } from '../../../custom/components/custom-product-card/custom-product-card.module';
import { CustomZagitaProductCarouselComponent } from './custom-zagita-product-carousel.component';

@NgModule({
  declarations: [CustomZagitaProductCarouselComponent],
  imports: [
    CommonModule,
    RouterModule,
    GenericLinkModule,
    MediaModule,
    CustomProductCardModule,
    CustomSafeHtmlModule,
    CustomLoadingSpinnerModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ZagitaProductCarouselComponent: {
          component: CustomZagitaProductCarouselComponent,
          deferLoading: DeferLoadingStrategy.DEFER,
        },
      },
    }),
  ],
})
export class CustomZagitaProductCarouselModule {}
