export const common = {
  common: {
    cancel: 'Cancelar',
    delete: 'Eliminar',
    remove: 'Remover',
    edit: 'Editar',
    restore: 'Restaurar',
    back: 'Atrás',
    submit: 'Enviar',
    continue: 'Continuar',
    save: 'Guardar',
    done: 'Hecho',
    home: 'Home',
    cart: 'Carrito',
    noData: 'Sin datos.',
    confirm: 'Confirmar',
    more: 'más',
    close: 'Cerrar',
    loading: 'Cargando',
    menu: 'Menú',
    reset: 'Reiniciar',
    search: 'Búsqueda',
    add: 'Agregar',
    selectFile: 'Seleccione Archivo',
    products: 'Productos',
    aboutUs: 'Sobre Nosotros',
    customerService: 'Servicio al Cliente',
    categoryTitle: {
      ferreteria: 'Categorías Ferretería',
      automotriz: 'Categorías Automotriz',
      categories: 'Categorías',
    },
    myPoints: 'Mis Puntos',
    notFoundText: 'No hay resultados',
  },
  pageMetaResolver: {
    category: {
      title: '{{count}} resultado para {{query}}',
      title_plural: '{{count}} resultados para {{query}}',
    },
    checkout: {
      title: 'Pagar {{count}} artículo',
    },
    search: {
      title: '{{count}} resultado para "{{query}}"',
      title_plural: '{{count}} resultados para "{{query}}"',
      findProductTitle: '{{count}} resultado para el cupón "{{coupon}}"',
      findProductTitle_plural: '{{count}} resultados del cupón "{{coupon}}"',
      default_title: 'Todos los productos',
    },
    product: {
      description: '{{description}}',
      heading: '{{heading}}',
      title: '{{title}}',
    },
  },
  spinner: {
    loading: 'Cargando...',
  },
  navigation: {
    shopAll: 'Ver todos los productos de {{navNode}}',
    goBack: 'Atrás',
  },
  searchBox: {
    placeholder: 'Buscar por marca, producto, SKU…',
    placeholderPromeclub: 'Buscar Premios',
    ariaLabelSuggestions: 'Sugerencias de búsqueda',
    suggestions: 'Sugerencias',
    suggestionsBySearch: 'Sugerencias por búsqueda',
    categories: 'Categorías',
    brands: 'Marcas',
    initialDescription:
      'Cuando los resultados de autocompletar estén disponibles, use las flechas hacia arriba y hacia abajo para revisar y enter para seleccionar. Usuarios con dispositivos touch, pueden explorar con gesturas touch o swipe',
    help: {
      insufficientChars: 'Escriba más caracteres',
      noMatch: 'No hay resultados disponibles para ',
      exactMatch: '{{ term }}',
      empty: 'Pregúntenos cualquier cosa',
    },
    sku: 'SKU: ',
    recommendedProducts: 'Productos recomendados',
    recommendedProductsFor: 'Productos recomendados para {{category}}',
    seeAllTheResults: 'Ver todos los resultados ({{quantity}})',
  },
  sorting: {
    date: 'Fecha',
    orderNumber: 'Número de Orden',
    rma: 'Número de devolución',
    replenishmentNumber: 'Número de reabastecimiento',
    nextOrderDate: 'Próxima fecha de pedido',
  },
  httpHandlers: {
    badGateway:
      'Se produjo un error en el servidor. Por favor, inténtelo de nuevo más tarde.',
    badRequestLoginAgain: '* Tu nombre de usuario o contraseña no coinciden.',
    badRequestPleaseLoginAgain: '* Intento fallido {{count}} de {{max}}.',
    badRequestOldPasswordIncorrect: 'Contraseña anterior incorrecta.',
    badRequestGuestDuplicateEmail:
      'El email ya existe. Por favor proceda al pago con una dirección de email diferente.',
    conflict: 'Ya existe',
    forbidden: 'No estás autorizado para realizar esta acción.',
    gatewayTimeout: 'El servidor no respondió. Vuelva a intentarlo más tarde.',
    internalServerError:
      'Se produjo un error interno del servidor. Por favor, inténtelo de nuevo más tarde.',
    sessionExpired:
      'Tu sesión ha expirado. Por favor inicia sesión nuevamente.',
    unknownError: 'Se produjo un error desconocido',
    unauthorized: {
      common: 'Se produjo un error de autorización desconocido',
      invalid_client: 'Credenciales de cliente incorrectas',
    },
    validationErrors: {
      missing: {
        card_cardType:
          'La tarjeta de crédito seleccionada no es compatible. Por favor seleccione otra.',
        card_accountNumber:
          'El número de tarjeta de crédito introducido no es válido.',
        card_cvNumber: 'El código de seguridad introducido no es válido.',
        card_expirationMonth:
          'La fecha de vencimiento de la tarjeta de crédito ingresada no es válida.',
        card_expirationYear:
          'La fecha de vencimiento de la tarjeta de crédito ingresada no es válida.',
        billTo_firstName: 'El nombre ingresado no es válido.',
        billTo_lastName: 'El apellido ingresado no es válido.',
        billTo_street1: 'La dirección ingresada no es válida.',
        billTo_street2: 'La dirección ingresada no es válida.',
        billTo_city:
          'La ciudad ingresada no es válida para esta tarjeta de crédito.',
        billTo_state:
          'El estado/provincia ingresado no es válido para esta tarjeta de crédito.',
        billTo_country:
          'El país introducido no es válido para esta tarjeta de crédito.',
        billTo_postalCode:
          'El código postal no es válido para esta tarjeta de crédito.',
        country: {
          isocode: 'País no encontrado',
        },
      },
      invalid: {
        card_expirationMonth:
          'La fecha de vencimiento de la tarjeta de crédito ingresada no es válida.',
        firstName: 'El nombre ingresado no es válido.',
        lastName: 'El apellido ingresado no es válido.',
        password: 'La contraseña ingresada no es válida.',
        uid: 'El UID ingresado no es válido.',
        code: 'El código ingresado no es válido',
        email: 'El e-mail ingresado no es válido',
      },
    },
    cartNotFound: 'No se ha encontrado el carrito.',
    invalidCodeProvided: 'El cupón ingresado no es válido',
  },
  miniCart: {
    item: '{{count}} artículo actualmente en su carrito',
    item_plural: '{{count}} artículos actualmente en su carrito',
    total: '{{total}}',
    count: '{{count}}',
    name: 'Mi carrito',
    bigCounter: '+99',
  },
  skipLink: {
    skipTo: 'Saltar a',
    labels: {
      header: 'Encabezado',
      main: 'Contenido Principal',
      footer: 'Pie de Página',
      productFacets: 'Facetas del Producto',
      productList: 'Lista de Productos',
    },
  },
  carousel: {
    previousSlide: 'Diapositiva anterior',
    nextSlide: 'Diapositiva siguiente',
    slideNumber: 'Diapositiva {{currentSlideNumber}}',
  },
  formErrors: {
    globalMessage: 'El formulario que está intentando enviar contiene errores.',
    required: '* Completá este dato.',
    emailRequired: '* Debe ingresar un e-mail.',
    cxInvalidEmail: '* Por favor, ingresa una dirección de correo válida.',
    cxInvalidPassword: 'Este no es un formato de contraseña válido',
    passwordRequired: '* Debe ingresar una contraseña.',
    cxPasswordsMustMatch: 'Tu nueva contraseña y la confirmación no coinciden',
    cxEmailsMustMatch: 'Los campos de correo electrónico deben coincidir',
    cxStarRatingEmpty: 'El campo de calificación es obligatorio',
    cxNoSelectedItemToCancel: 'Seleccione al menos un elemento para cancelar',
    cxNegativeAmount: 'El monto debe ser igual o mayor que 0',
    cxContainsSpecialCharacters:
      'El campo no puede contener caracteres especiales',
    date: {
      required: 'Este campo es requerido',
      min: 'La fecha no puede ser anteior a {{min}}',
      max: 'La fecha no puede ser posterior a {{max}}',
      pattern: 'El formato de fecha debe ser yyyy-mm-dd',
      invalid: 'Usa un formato de fecha válido',
    },
    file: {
      required: 'Se requiere archivo',
      empty: 'El archivo no debe estar vacío',
      invalidExtension: 'La extensión del archivo no es válida',
      tooLarge: 'El tamaño del archivo no debe exceder {{ maxSize }} MB',
      tooManyEntries: 'El número de elementos es mayor que {{ maxEntries }}',
      notParsable: 'El archivo no es analizable',
    },
  },
  errorHandlers: {
    scriptFailedToLoad: 'No se pudo cargar el script.',
    refreshThePage: 'Por favor, actualice la página.',
  },
};
