export const checkoutStockTranslation = {
  checkoutStock: {
    checkoutStock: {
      title: 'Stock no disponible',
      text: 'Lo sentimos, para poder concluir con la compra es necesario modificar',
      textMobile:
        'Lo sentimos, para poder concluir con la compra es necesario modificar los siguientes productos del carrito:',
      textProducts: '{{quantity}} producto del carrito',
      textProducts_plural: '{{quantity}} productos del carrito',
      reviewCart: 'Revisar carrito',
      entry: {
        sku: 'SKU: ',
        qtyRequested: 'Cantidad solicitada: ',
        stockAvailable: 'Stock disponible: ',
        qtyRequestedMobile: 'Cantidad solicitada',
        stockAvailableMobile: 'Stock disponible',
      },
    },
  },
};
