<div class="reorder-info">
  <app-custom-modal-generic></app-custom-modal-generic>
  <div class="reorder-info__icon">
    <cx-icon class="fas fa-exclamation-triangle"></cx-icon>
  </div>
  <div
    class="reorder-info__title"
    [innerHTML]="
      'orderDetails.reorder.fromCart.title'
        | cxTranslate : { code: orderCode }
        | safeHtml
    "
  ></div>
  <ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
    <div
      *ngIf="!data.isMobile"
      class="reorder-info__message"
      [innerHTML]="
        'orderDetails.reorder.fromCart.message' | cxTranslate | safeHtml
      "
    ></div>
    <div
      *ngIf="!data.isMobile"
      class="reorder-info__message two"
      [innerHTML]="
        'orderDetails.reorder.fromCart.messageTwo' | cxTranslate | safeHtml
      "
    ></div>
    <div
      *ngIf="data.isMobile"
      class="reorder-info__message"
      [innerHTML]="
        'orderDetails.reorder.fromCart.messageMobile' | cxTranslate | safeHtml
      "
    ></div>
    <div
      *ngIf="data.isMobile"
      class="reorder-info__message two"
      [innerHTML]="
        'orderDetails.reorder.fromCart.messageTwoMobile'
          | cxTranslate
          | safeHtml
      "
    ></div>
  </ng-container>

  <div class="reorder-info__action">
    <div
      class="reorder-info__action-link"
      (click)="dismissModal()"
    >
      {{ "orderDetails.reorder.fromCart.cancel" | cxTranslate }}
    </div>
    <button class="reorder-info__button" (click)="createCartFromOrder()">
      {{ "orderDetails.reorder.fromCart.continue" | cxTranslate }}
    </button>
  </div>
</div>
