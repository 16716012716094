import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductTabParagraphContainerComponent } from './custom-product-tab-paragraph-container.component';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  ConfigModule,
  DeferLoadingStrategy,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { CustomSafeHtmlModule } from 'src/app/pipes/custom-safe-html/custom-safe-html.module';
import { CustomProductAttributesModule } from '../custom-product-tabs/custom-product-attributes/custom-product-attributes.module';
import { CustomAutomotiveApplicationsModule } from 'src/app/cms-components/custom/components/custom-automotive-applications/custom-automotive-applications.module';

@NgModule({
  declarations: [CustomProductTabParagraphContainerComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    CustomSafeHtmlModule,
    CustomProductAttributesModule,
    CustomAutomotiveApplicationsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        PromesaCMSTabParagraphContainer: {
          component: CustomProductTabParagraphContainerComponent,
          deferLoading: DeferLoadingStrategy.INSTANT,
        },
      },
    } as CmsConfig),
  ],
  exports: [CustomProductTabParagraphContainerComponent],
})
export class CustomProductTabParagraphContainerModule {}
