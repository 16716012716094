import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Product } from '@spartacus/core';
import { CustomRebateService } from '../../../services/custom-rebate.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-rebate-detail',
  templateUrl: './custom-rebate-detail.component.html',
  styleUrls: ['./custom-rebate-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomRebateDetailComponent implements OnInit {
  @Input() date: string;
  @Input() description: string | undefined;
  @Input() clientId: string;
  @Input() rappelId: string;
  recommendedProducts$: Observable<Product[]>;
  constructor(private customRebateService: CustomRebateService) {}

  ngOnInit(): void {
    this.recommendedProducts$ = this.customRebateService.getRebateDetails(
      this.clientId,
      this.rappelId
    );
  }
}
