import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { OrderAmendService, OrderCancellationService } from '@spartacus/order/components';
import { CustomTitleMyAccountModule } from 'src/app/cms-components/custom/components/custom-title-my-account/custom-title-my-account.module';
import { CustomOrderCancellationGuard } from '../../guards/custom-order-cancellation.guard';
import { CustomOrderCancelModule } from '../custom-order-cancel/custom-order-cancel.module';
import { CustomOrderDetailItemsModule } from '../custom-order-details/custom-order-detail-items/custom-order-detail-items.module';
import { CustomOrderDetailShippingModule } from '../custom-order-details/custom-order-detail-shipping/custom-order-detail-shipping.module';
import { CustomOrderDetailTotalsModule } from '../custom-order-details/custom-order-detail-totals/custom-order-detail-totals.module';
import { CustomOrderCancelConfirmationComponent } from './custom-order-cancel-confirmation.component';



@NgModule({
  declarations: [
    CustomOrderCancelConfirmationComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    UrlModule,
    CustomOrderDetailItemsModule,
    CustomOrderDetailTotalsModule,
    CustomOrderDetailShippingModule,
    CustomTitleMyAccountModule,
    CustomOrderCancelModule,
    ReactiveFormsModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CancelOrderConfirmationComponent: {
          component: CustomOrderCancelConfirmationComponent,
          guards: [AuthGuard, CustomOrderCancellationGuard],
          providers: [
            {
              provide: OrderAmendService,
              useExisting: OrderCancellationService,
            },
          ],
        },
      },
    }),
  ],
  exports: [
    CustomOrderCancelConfirmationComponent
  ]
})
export class CustomOrderCancelConfirmationModule { }
