import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPaymentCreditComponent } from './custom-payment-credit.component';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomCurrencyModule } from '../../../../../pipes/custom-currency/custom-currency.module';
import { CustomErrorModule } from 'src/app/cms-components/custom/components/custom-error/custom-error.module';
import { CustomLoadingSpinnerModule } from '../../../../../cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';
import { CustomPaymentCreditDialogComponent } from './custom-payment-credit-dialog/custom-payment-credit-dialog.component';
import { IconModule } from '@spartacus/storefront';



@NgModule({
  declarations: [CustomPaymentCreditComponent, CustomPaymentCreditDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    IconModule,
    NgSelectModule,
    CustomCurrencyModule,
    CustomErrorModule,
    CustomLoadingSpinnerModule
  ],
  exports: [
    CustomPaymentCreditComponent
  ]
})
export class CustomPaymentCreditModule { }
