import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  CmsComponent,
  CmsNavigationComponent,
  CmsService,
  TranslationService,
} from '@spartacus/core';
import {
  CategoryNavigationComponent,
  CmsComponentData,
  NavigationNode,
} from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';
import { CustomBreakpointService } from '../../../../services/custom-breakpoint.service';
import { CustomNavigationService } from '../../services/custom-navigation.service';
import { CustomNavigationNode } from '../../models/custom-navigation-node.interface';

@Component({
  selector: 'app-custom-category-navigation',
  templateUrl: './custom-category-navigation.component.html',
  styleUrls: ['./custom-category-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCategoryNavigationComponent
  extends CategoryNavigationComponent
  implements OnInit
{
  isMobile$: Observable<boolean> = this.customBreakpointService.isMobile$;
  isInPromeclub: boolean = this.service.isInPromeClub();
  desktopCustomNode$: Observable<NavigationNode | null> = this.service
    .customGetNavigationNode(this.componentData.data$, false, true)
    .pipe(
      take(1),
      tap((nodes) => this.service.setHeaderCachedNavigationNodes(nodes))
    );
  footerComponentData$: Observable<CmsComponent> =
    this.cms.getComponentData('FooterNavComponent');
  mobileCustomNode$: Observable<CustomNavigationNode>;

  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: CustomNavigationService,
    protected customBreakpointService: CustomBreakpointService,
    protected cms: CmsService,
    protected translation: TranslationService
  ) {
    super(componentData, service);
  }

  ngOnInit(): void {
    this.mobileCustomNode$ = combineLatest([
      this.service.customGetNavigationNode(this.componentData.data$, true),
      this.service.customGetNavigationNode(this.footerComponentData$, true),
      this.translation.translate('common.aboutUs'),
      this.translation.translate('common.customerService'),
    ]).pipe(
      map(
        ([
          node,
          footerNode,
          translationAboutUs,
          translationCustomerService,
        ]) => {
          let productsNode: CustomNavigationNode;
          productsNode = {
            children: node?.children,
          };
          if (!this.isInPromeclub) {
            productsNode.children?.push({
              title: translationAboutUs,
              url: '',
              children: [],
              toggle: true,
              separator: true,
            });
            footerNode?.children?.[1]?.children?.map((child: any) =>
              productsNode.children?.[
                productsNode?.children?.length! - 1
              ].children?.push({
                title: child?.linkName,
                url: child.url,
              })
            );
            productsNode.children?.push({
              title: translationCustomerService,
              url: '',
              children: [],
              toggle: true,
            });
            footerNode?.children?.[2].children?.map((child: any) =>
              productsNode.children?.[
                productsNode?.children?.length! - 1
              ].children?.push({
                title: child?.linkName,
                url: child.url,
              })
            );
          } else {
            productsNode.children?.push({
              title: footerNode?.children?.[2]?.title,
              url: footerNode?.children?.[2]?.url || '',
              children: footerNode?.children?.[2]?.children?.map(
                (child: any) => ({ title: child?.linkName, url: child?.url })
              ),
              toggle: true,
              separator: true,
            });
          }
          return productsNode;
        }
      )
    );
  }
}
