<ng-container *ngIf="order$ | async as order">
  <!-- unconsigned entries OR any entries -->
  <div class="custom-order-details">
    <div class="custom-order-details__items">
      <ng-container
        *ngIf="
          order?.entries?.length ||
          (order?.entries && order?.replenishmentOrderCode)
        "
      >
        <ng-container *ngIf="orderDetail$ | async as orderEntries">
          <div class="cx-list">
            <div class="cx-list-title">
              {{ "orderDetails.myProducts" | cxTranslate }}
            </div>

            <div class="cx-list-item">
              <div *ngIf="orderDetailsShow.length > 0">
                <app-custom-order-detail-item-product
                  *ngFor="let item of orderDetailsShow"
                  [item]="item"
                ></app-custom-order-detail-item-product>
              </div>
            </div>
          </div>
        </ng-container>
        <div *ngIf="orderDetailsShow.length > 0 && pagination.totalPages >= 1" class="cx-pagination-order-details">
          <app-custom-pagination
            [pagination]="pagination"
            (viewPageEvent)="customPageChange($event)"
            [pageSelected]="selectedPage"
          ></app-custom-pagination>
        </div>
      </ng-container>
    </div>
    <div class="custom-order-details__totals">
      <app-custom-order-detail-totals></app-custom-order-detail-totals>
    </div>
  </div>
</ng-container>
