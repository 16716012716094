<ng-container *ngIf="data$ | async as data">
  <cx-media
    [container]="imageByBreakpoint$ | async"
    [ngClass]="{
      'work-with-us': data.name == 'promesaTrabajaConNosotrosBanner'
    }"
  ></cx-media>
  <div class="title-page">
    <p
      class="headline"
      *ngIf="data.headline"
      [innerHTML]="data.headline"
      [class]="
        data.name == 'promesaTrabajaConNosotrosBanner'
          ? 'headline-work-with-us'
          : 'headline-our-company'
      "
    ></p>
    <p class="content" *ngIf="data.content" [innerHTML]="data.content"></p>
    <p
      class="content content-our-company"
      *ngIf="data.contentDesktop && !(isMobile$ | async)"
      [innerHTML]="data.contentDesktop"
    ></p>
    <p
      class="content content-our-company"
      *ngIf="data.contentMobile && (isMobile$ | async)"
      [innerHTML]="data.contentMobile"
    ></p>
    <ng-container *ngIf="data.linkTitle">
      <div class="button-container">
        <button class="btn btn-primary btn-block actions">
          <a [href]="data.urlLink" target="_blank" rel="noopener noreferrer">
            <span>{{ data.linkTitle }}</span>
            <cx-icon class="fas fa-chevron-right"></cx-icon>
          </a>
        </button>
      </div>
    </ng-container>
  </div>

</ng-container>
