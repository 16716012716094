import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderSecureCheckoutOutletComponent } from './header-secure-checkout-outlet.component';
import { IconModule, OutletRefModule } from '@spartacus/storefront';
import { I18nModule } from '@spartacus/core';



@NgModule({
  declarations: [
    HeaderSecureCheckoutOutletComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    I18nModule,
    OutletRefModule,
  ],
  exports: [
    HeaderSecureCheckoutOutletComponent
  ]
})
export class HeaderSecureCheckoutOutletModule { }
