<ng-container *ngIf="user$ | async as user; else spinnerLoading">
  <div class="user">
    <app-custom-title-my-account
      [title]="'personalData.title' | cxTranslate"
    ></app-custom-title-my-account>
    <h5 class="user__subtitle">{{ "personalData.subtitle" | cxTranslate }}</h5>
    <div class="user__info">
      <div class="user__info-data" *ngIf="user?.name">
        <p class="user__info-data-key">
          {{ "personalData.fullname" | cxTranslate }}
        </p>
        <p class="user__info-data-value">{{ user?.name }}</p>
      </div>
      <div class="user__info-data" *ngIf="user?.phone">
        <p class="user__info-data-key">
          {{ "personalData.cellphone" | cxTranslate }}
        </p>
        <p class="user__info-data-value">{{ user?.phone }}</p>
      </div>
      <div class="user__info-data" *ngIf="user?.email">
        <p class="user__info-data-key">
          {{ "personalData.email" | cxTranslate }}
        </p>
        <p class="user__info-data-value">{{ user?.email }}</p>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #spinnerLoading>
  <div class="user">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
