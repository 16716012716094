<ng-container *ngIf="savedCart$ | async as cart">
  <ng-container *ngIf="cart?.entries?.length > 0; else emptyCartItems">
    <div class="separator"></div>
    <div class="custom-item">
      <div class="custom-item__products-container">
        <div class="titles">
          {{ "orderDetails.myProducts" | cxTranslate }}
        </div>
        <app-custom-order-detail-item-product
          *ngFor="let item of cartsSavedShow"
          [item]="item"
        ></app-custom-order-detail-item-product>
        <div class="custom-item__products-container__pagination">
          <app-custom-pagination
            *ngIf="true"
            [pagination]="pagination"
            (viewPageEvent)="customPageChangeSelected($event)"
            [pageSelected]="selectedPage"
          >
          </app-custom-pagination>
        </div>
      </div>
      <div class="custom-item__summary-container">
        <div class="titles">
          {{ "shoppingList.summary" | cxTranslate }}
        </div>
        <app-custom-order-summary
          [customCart]="cart"
          [shoppingList]="true"
          [section]="'order'"
        ></app-custom-order-summary>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #emptyCartItems>
  <div class="cx-spinner">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
