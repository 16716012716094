import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadProgressService {

  progressSource$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  uploadError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }

  setProgressSource(value: number){
    this.progressSource$.next(value);
  }

  getProgressSource(): Observable<number>{
    return this.progressSource$.asObservable();
  }

  setUploadError(value: boolean){
    this.uploadError$.next(value);
  }

  getUploadError(): Observable<boolean>{
    return this.uploadError$.asObservable();
  }
}
