import { formatCurrency, getLocaleCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(
    value: number,
    locale: string = 'es-AR',
    digitsInfo: string = '1.2-2',
    currencyCode: string,
    display: 'narrow' | 'wide'
  ): string | null {
    if (!!value || value === 0) {
      let formattedCurrency = formatCurrency(
        value,
        locale,
        getLocaleCurrencySymbol(locale)!,
        currencyCode,
        digitsInfo
      ).replace(/\s/g, '');
      return formattedCurrency;
    }
    return null;
  }
}
