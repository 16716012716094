import { Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class CustomLockScrollService {
  constructor(protected winRef: WindowRef) {}

  handleLockBodyScroll(isVisible: boolean): void {
    this.winRef.document.body.classList.toggle('lock-scroll', isVisible);
  }

  handleMenuNavigationLockScroll(subMenuIsOpen: boolean): void {
    const menuNavigation =
      this.winRef.document.querySelector('header .navigation');
    menuNavigation!.classList.toggle('navigation--lock-scroll', subMenuIsOpen);
    menuNavigation!.scrollTo(0, 0);
  }
}
