import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomAuthorizedPersonComponent } from './custom-authorized-person.component';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomAddAuthorizedPersonModule } from '../custom-add-authorized-person/custom-add-authorized-person.module';
import { CustomLoadingSpinnerModule } from 'src/app/cms-components/custom/components/custom-loading-spinner/custom-loading-spinner.module';

@NgModule({
  declarations: [CustomAuthorizedPersonComponent],
  exports: [CustomAuthorizedPersonComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    IconModule,
    CustomAddAuthorizedPersonModule,
    CustomLoadingSpinnerModule,
  ],
})
export class CustomAuthorizedPersonModule {}
