<div class="container-order-history">
  <!-- HEADER -->
  <div class="cx-order-history-header">
    <app-custom-title-my-account
      [title]="'accountQuotes.titleList' | cxTranslate"
      [lessMargin]="true"
    ></app-custom-title-my-account>
  </div>
  <ng-container
    *ngIf="{
      orderHistory: quotes$ | async,
      isMobile: isMobile$ | async
    } as type"
  >
    <!-- BODY -->
    <ng-container *ngIf="type.orderHistory; else spinnerLoading">
      <div class="cx-order-history-body">
        <ng-container
          *ngIf="type.orderHistory.pagination?.totalResults > 0; else noOrder"
        >
          <!-- Select Form and Pagination Top -->
          <div class="cx-order-history-filters top">
            <div class="cx-order-history-form-group"></div>
            <div class="cx-order-history-pagination top">
              <app-custom-filter
                [label]="'orderHistory.filterResults' | cxTranslate"
                [entries]="quantities"
                [classSelect]="'small-filter'"
                [valueDefault]="pageSize"
                (filterSelected)="filterQuantitySelected($event)"
              >
              </app-custom-filter>
              <app-custom-pagination
                *ngIf="type.orderHistory.pagination.totalPages >= 1"
                [pagination]="type.orderHistory.pagination"
                (viewPageEvent)="customPageChangeSelected($event)"
                [pageSelected]="selectedPage"
              ></app-custom-pagination>
            </div>
          </div>
          <!-- TABLE -->
          <app-custom-account-table
            *ngIf="!type.isMobile"
            [orders]="type.orderHistory"
            (goToDetails)="goToQuoteDetail($event)"
            [fromQuote]="true"
          ></app-custom-account-table>

          <ng-container *ngIf="type.isMobile">
            <ng-container
              *ngIf="type?.orderHistory?.quotes?.length > 0; else noOrder"
            >
              <div
                class="order-mobile"
                *ngFor="let order of type?.orderHistory?.quotes"
                (click)="goToQuoteDetail(order)"
              >
                <div class="order-mobile__date">
                  <span class="order-mobile__date-title">
                    {{ "accountQuotes.quotesTable.dateUpdate" | cxTranslate }}
                  </span>
                  <span class="order-mobile__date-value">
                    {{ order?.updatedTime | date : "dd/MM/yyyy" }}
                  </span>
                </div>
                <div class="order-mobile__number">
                  <span class="order-mobile__number-title">
                    {{ "accountQuotes.quotesTable.quoteNumber" | cxTranslate }}
                  </span>
                  <span class="order-mobile__number-value underline">
                    {{ order?.code }}
                  </span>
                </div>
                <div class="order-mobile__points">
                  <span class="order-mobile__points-title">
                    {{ "accountQuotes.quotesTable.quoteName" | cxTranslate }}
                  </span>
                  <span class="order-mobile__points-value">
                    {{ order?.name }}
                  </span>
                </div>
                <div class="order-mobile__reason">
                  <span class="order-mobile__reason-title">
                    {{ "accountQuotes.quotesTable.status" | cxTranslate }}
                  </span>
                  <span class="order-mobile__reason-value">
                    {{
                      "accountQuotes.quotesTable.statusDisplay_" + order?.state
                        | cxTranslate
                    }}
                  </span>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <!-- Select Form and Pagination Bottom -->
          <div
            class="cx-order-history-sort"
            *ngIf="type.orderHistory.pagination?.totalResults > 0"
          >
            <div
              *ngIf="type.orderHistory.pagination.totalPages >= 1"
              class="cx-order-history-pagination bottom"
            >
              <app-custom-pagination
                [pagination]="type.orderHistory.pagination"
                (viewPageEvent)="customPageChangeSelected($event)"
                [pageSelected]="selectedPage"
              ></app-custom-pagination>
            </div>
          </div>
        </ng-container>

        <!-- NO ORDER CONTAINER -->
        <ng-template #noOrder>
          <div class="cx-order-history-no-order">
            <app-custom-empty-cart
              [fromMyAccount]="true"
            ></app-custom-empty-cart>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #spinnerLoading>
  <div class="cx-order-history-body cx-order-history-body__loading">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
