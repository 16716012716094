import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActiveCartService,
  EMAIL_PATTERN,
  OccEndpointsService,
  OCC_USER_ID_ANONYMOUS,
  OCC_USER_ID_GUEST,
} from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomPromeclubAddToCartService {
  productAdded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private activeCartService: ActiveCartService,
    private http: HttpClient,
    private occEndpointService: OccEndpointsService
  ) {}

  addEntry(quantity: number, code: string, promotickRechargeNumber: string) {
    return this.activeCartService.requireLoadedCart().pipe(
      map((loadedCart) => {
        const cartUser = loadedCart.value?.user;
        if (
          cartUser &&
          (cartUser.uid === OCC_USER_ID_ANONYMOUS ||
            cartUser.uid === OCC_USER_ID_GUEST ||
            !!cartUser.uid?.split('|').slice(1).join('|').match(EMAIL_PATTERN))
        ) {
          return loadedCart.value?.guid as string;
        }
        return loadedCart.value?.code as string;
      }),
      filter((cartId) => !!cartId),
      switchMap((cartId) => {
        const url = this.occEndpointService.buildUrl('promeclubAddToCart', {
          urlParams: {
            cartId,
          },
          queryParams: {
            quantity,
            code,
            promotickRechargeNumber,
          },
        });
        return this.http.post(url, {});
      })
    );
  }

  setProductAdded(value: boolean): void {
    this.productAdded.next(value);
  }

  getProductAdded(): Observable<boolean> {
    return this.productAdded.asObservable();
  }

  setLoading(value: boolean): void {
    this.loading$.next(value);
  }

  getLoading(): Observable<boolean> {
    return this.loading$.asObservable();
  }
}
