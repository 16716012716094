<ng-container *ngIf="order$ | async as order">
  <div class="custom-order-details-actions" *ngIf="order.status">
    <a
      *ngIf="order?.cancellable"
      [routerLink]="
        {
          cxRoute: 'orderCancel',
          params: order
        } | cxUrl
      "
    >
      {{ "orderDetails.cancellationAndReturn.cancelAction" | cxTranslate }}
    </a>
    <a
      *ngIf="order?.returnable"
      [routerLink]="
        {
          cxRoute: 'returnOrder',
          params: order
        } | cxUrl
      "
    >
      {{ "returnRequest.returnAction" | cxTranslate }}
    </a>
    <hr *ngIf="order?.returnable || order?.cancellable" />
    <a
      *ngIf="order?.status !== ORDER_STATE.CANCELLED"
      [routerLink]="
        {
          cxRoute: 'claimOrder',
          params: order
        } | cxUrl
      "
    >
      {{ "orderDetails.claim.claim" | cxTranslate }}
    </a>
    <hr
      *ngIf="
        order?.status === ORDER_STATE.ORDER_SENT_TO_ERP &&
        (order?.paymentMode?.code === 'transferPaymentMode' ||
          order?.paymentMode?.code === 'depositPaymentMode') &&
        order.cancellable
      "
    />
    <a
      *ngIf="
        order?.status === ORDER_STATE.ORDER_SENT_TO_ERP &&
        (order?.paymentMode?.code === 'transferPaymentMode' ||
          order?.paymentMode?.code === 'depositPaymentMode')
      "
      [routerLink]="
        {
          cxRoute: 'paymentRecord',
          params: order
        } | cxUrl
      "
    >
      {{ "orderDetails.registerPaid.registerAction" | cxTranslate }}
    </a>

    <hr *ngIf="!order?.isFromQuote" />
    <a *ngIf="!order?.isFromQuote" (click)="reorder(order?.code)" data-cy="reorder">
      {{ "orderDetails.reorder.reorderAction" | cxTranslate }}
    </a>
  </div>
</ng-container>
